import { Component, Prop, Vue } from 'vue-property-decorator'

import event from '@/client/utils/event'

type TChartHorizontalOptions = {
  display_value: boolean
  display_legend: boolean
  legend_position: 'top' | 'bottom'
  label_type: string
  categories: string[]
  colors: string[]
  series: any[]
}

@Component({
  name: 'ChartHorizontal'
})
export default class ChartHorizontal extends Vue {
  @Prop({ type: Object, required: true })
  options: TChartHorizontalOptions

  is_show = false

  async mounted() {
    this.is_show = false
    await this.$nextTick()
    await event.delay(0)
    this.is_show = true
  }

  get chart_height() {
    const base = 20 * 2

    return this.options.display_legend
      ? this.options.categories.length * base + 60
      : this.options.categories.length * base
  }

  get chart_options() {
    const label_type = this.options.label_type ? this.options.label_type : ''

    return {
      chart: {
        animation: true,
        type: 'bar',
        marginRight: 50,
        height: this.chart_height
      },
      credits: {
        enabled: false
      },
      colors: this.options.colors,
      title: {
        text: null
      },
      xAxis: {
        offset: 0,
        categories: this.options.categories,
        labels: {
          formatter: function () {
            let ret = this.value
            const len = ret.length

            if (len > 10) {
              ret = ret.slice(0, 10) + '<br/>' + ret.slice(10, len)
            }

            if (len > 25) {
              ret = ret.slice(0, 25) + '...'
            }

            return ret
          }
        },
        lineWidth: 0,
        gridLineWidth: 0,
        tickWidth: 0
      },
      yAxis: {
        offset: -70,
        min: 0,
        title: {
          text: null
        },
        labels: {
          enabled: false
        },
        gridLineWidth: 0,
        stackLabels: {
          enabled: this.options.display_value,
          style: {
            fontWeight: 'normal'
          },
          formatter: function () {
            return `${this.total.toLocaleString()}${label_type}`
          }
        }
      },
      legend: {
        verticalAlign: this.options.legend_position,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
        reversed: true,
        enabled: this.options.display_legend,
        symbolRadius: 0
      },
      tooltip: {
        shared: true,
        borderColor: '#b5b5b5',
        style: {
          fontSize: '13px'
        },
        pointFormatter: function () {
          return `<span style="color:${this.color}">●</span> ${
            this.series.name
          }: <b>${this.y.toLocaleString()}${label_type}</b><br/>`
        }
      },
      plotOptions: {
        series: {
          animation: false,
          pointWidth: 20,
          borderWidth: 1,
          stacking: 'normal'
        }
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      series: this.options.series,
      loading: false
    }
  }
}
