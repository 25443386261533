
import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'

@Component({
  name: 'Dropdown',
  components: {
    Icon,
    Tooltip
  }
})
export default class Dropdown extends Vue {
  @Prop({ type: String, default: 'normal' })
  size!: 'normal' | 'full'

  @Prop({ type: Array, required: true })
  options!: {
    icon: string
    text: string
    value: string
    disabled: false
    tooltip: string
    sns?: string[]
  }[]

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  is_show = false

  get is_mobile() {
    return this.$mq === 'sm'
  }

  /**
   * ドロップダウンを開く
   */
  openDropdown() {
    this.is_show = true
  }

  /**
   * ドロップダウンを閉じる
   */
  closeDropdown() {
    this.is_show = false
  }

  /**
   * ドロップダウンを選択
   */
  selectDropdown(option: string | null): void {
    if (option === null) {
      return
    }

    this.$emit('input', option)
    this.is_show = false
  }

  /**
   * ドロップダウンの変更
   */
  visibleChange(visible: boolean) {
    this.is_show = visible
  }
}
