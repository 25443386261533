import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/instagram_hashtags'
import * as calculation from '@/client/components-old/_utils/calculation'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Icon from '@/client/components-old/atoms/Icon'
import Label from '@/client/components-old/atoms/Label'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsList from '@/client/components-old/molecules/AnalyticsList'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartSingleBar from '@/client/components-old/molecules/ChartSingleBar'
import { TrackingService } from '@/client/services'
import { IState as IInstagramState } from '@/client/store/modules/instagram_hashtag'
import { IInstagramHashtagsSummary } from '@/client/utils/api/instagram_hashtags'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'
import { getHeaderClass, getTableSetting } from '@/client/utils/table'

const instagram_hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagSummary',
  components: {
    ButtonGroup,
    Icon,
    Label,
    Message,
    Panel,
    Tooltip,
    AnalyticsList,
    AnalyticsPanel,
    ChartSingleBar
  }
})
export default class InstagramHashtagSummary extends Vue {
  @instagram_hashtag.State('api_settings') api_settings: IInstagramState['api_settings']
  @instagram_hashtag.State('api_summary') api_summary: IInstagramState['api_summary']
  @instagram_hashtag.State('start_date') start_date: IInstagramState['start_date']
  @instagram_hashtag.State('end_date') end_date: IInstagramState['end_date']
  @instagram_hashtag.State('summary_display_format')
  summary_display_format: IInstagramState['summary_display_format']
  @instagram_hashtag.State('summary_display_order')
  summary_display_order: IInstagramState['summary_display_order']
  @instagram_hashtag.State('is_summary_loading')
  is_summary_loading: IInstagramState['is_summary_loading']
  @instagram_hashtag.Action('changeSummaryDisplayFormat') changeSummaryDisplayFormat
  @instagram_hashtag.Action('changeSummaryDisplayOrder') changeSummaryDisplayOrder

  get display_options() {
    return [
      { text: '平均', value: 'average' },
      { text: '合計', value: 'total' }
    ]
  }

  get display_data() {
    const data = this.api_summary.map((item, index) => {
      const hashtag = this.api_settings.find(v => v.id === item.hashtag_id)

      const last_processed_datetime = hashtag ? hashtag.last_processed_datetime : null

      return {
        hashtag_name: hashtag ? hashtag.name : '',
        hashtag_order_no: index + 1,
        last_processed_datetime: last_processed_datetime,
        post_count: last_processed_datetime ? item.post_count : -1,
        positive_count: last_processed_datetime ? item.positive_count : -1,
        negative_count: last_processed_datetime ? item.negative_count : -1,
        reaction_count: this.getMetricData(
          last_processed_datetime,
          item.post_count,
          item.reaction_count
        ),
        like_count: this.getMetricData(last_processed_datetime, item.post_count, item.like_count),
        comment_count: this.getMetricData(
          last_processed_datetime,
          item.post_count,
          item.comment_count
        ),
        reaction_graph:
          hashtag && hashtag.last_processed_datetime ? this.getReactionGraph(item) : null,
        reaction_list:
          hashtag && hashtag.last_processed_datetime ? this.getReactionList(item) : null
      }
    })

    const sort_order = this.summary_display_order[0] === '-' ? 'descending' : 'ascending'
    let sort_metric = this.summary_display_order.replace('-', '')

    if (sort_metric === constants.HASHTAG_NAME.value) {
      sort_metric = constants.HASHTAG_ORDER_NO.value
    }

    return data.sort((a, b) => {
      switch (sort_order) {
        case 'ascending':
          return a[sort_metric] - b[sort_metric]
        case 'descending':
          return b[sort_metric] - a[sort_metric]
        default:
          return 0
      }
    })
  }

  get table_columns() {
    return constants.SUMMARY_TABLE_COLUMNS
  }

  get is_empty_summary() {
    return !!this.api_summary.length
  }

  /**
   * ソートによってテーブルのスタイル追加
   */
  addSortStyle(val: { column: { property: string } }) {
    const order = this.summary_display_order[0] === '-' ? 'descending' : 'ascending'
    const metric = this.summary_display_order.replace('-', '')

    return getHeaderClass(this.table_columns, val.column.property, metric, order)
  }

  /**
   * テーブルの順序を変更
   */
  async changeSortTable(val: { property: string }) {
    if (!this.table_columns.includes(val.property)) {
      return
    }

    const setting = getTableSetting(val.property, this.summary_display_order)

    this.changeSummaryDisplayOrder(setting.sort)
  }

  /**
   * 指標のデータを取得
   */
  getMetricData(last_processed_datetime: Date, post_count: number, metric_count: number) {
    if (!last_processed_datetime) {
      return -1
    }

    if (this.summary_display_format === 'average' && post_count > 0) {
      return calculation.division(metric_count, post_count, 1)
    }

    return metric_count
  }

  /**
   * 指標の表示名を取得
   */
  getMetricName(name: string) {
    const metric = constants.SUMMARY_METRICS.find(v => v.value === name)

    return metric ? metric.text : ''
  }

  /**
   * 反応数のグラフを取得
   */
  getReactionGraph(summary_item: IInstagramHashtagsSummary) {
    const series =
      this.summary_display_format === 'average' && summary_item.post_count
        ? [
            calculation.division(summary_item.like_count, summary_item.post_count, 1),
            calculation.division(summary_item.comment_count, summary_item.post_count, 1)
          ]
        : [summary_item.like_count, summary_item.comment_count]

    const chart_colors = ['#1c84c6', '#ed5565']

    let max = 0

    if (this.api_summary.length > 0 && this.api_summary.some(v => v.post_count > 0)) {
      max =
        this.summary_display_format === 'average'
          ? Math.max(
              ...this.api_summary
                .filter(v => Boolean(v.post_count))
                .map(v => calculation.division(v.reaction_count, v.post_count, 1))
            )
          : Math.max(...this.api_summary.map(v => v.reaction_count))
    }

    return {
      series,
      colors: chart_colors,
      display_total: true,
      total:
        this.summary_display_format === 'average' && summary_item.post_count
          ? calculation.division(summary_item.reaction_count, summary_item.post_count, 1)
          : summary_item.reaction_count,
      max
    }
  }

  /**
   * 反応数のリストを取得
   */
  getReactionList(summary_item: IInstagramHashtagsSummary) {
    return [
      {
        title: constants.REACTION_COUNT.text,
        options: [
          {
            key: constants.LIKE_COUNT.text,
            value:
              this.summary_display_format === 'average' && summary_item.post_count
                ? calculation.division(summary_item.like_count, summary_item.post_count, 1)
                : summary_item.like_count,
            point: { type: 'color', value: '#1c84c6' }
          },
          {
            key: constants.COMMENT_COUNT.text,
            value:
              this.summary_display_format === 'average' && summary_item.post_count
                ? calculation.division(summary_item.comment_count, summary_item.post_count, 1)
                : summary_item.comment_count,
            point: { type: 'color', value: '#ed5565' }
          }
        ]
      }
    ]
  }

  /**
   * ポジティブ・ネガティブ率を取得
   */
  getPercentageCount(post_count: number, sentiment_count: number) {
    return calculation.percentage(sentiment_count, post_count) + '%'
  }

  /**
   * CSVをダウンロード
   */
  downloadCSV(): void {
    const page_name = i18n.t('Instagramクチコミ分析')
    const component_name = i18n.t('基本データ')

    const text = i18n.t(
      this.display_options.find(v => v.value === this.summary_display_format).text
    )

    const csv_filename = [page_name, component_name, text, this.start_date, this.end_date].join('_')

    const fields = [
      i18n.t(constants.HASHTAG_NAME.text),
      i18n.t(constants.POST_COUNT.text),
      i18n.t('ポジティブ数'),
      i18n.t('ポジティブ率'),
      i18n.t('ネガティブ数'),
      i18n.t('ネガティブ率'),
      i18n.t(constants.REACTION_COUNT.text),
      i18n.t(constants.LIKE_COUNT.text),
      i18n.t(constants.COMMENT_COUNT.text)
    ]

    const data = this.display_data.map(table => [
      table.hashtag_name,
      table.last_processed_datetime ? table.post_count : '-',
      table.last_processed_datetime ? table.positive_count : '-',
      table.last_processed_datetime
        ? this.getPercentageCount(table.post_count, table.positive_count)
        : '-',
      table.last_processed_datetime ? table.negative_count : '-',
      table.last_processed_datetime
        ? this.getPercentageCount(table.post_count, table.negative_count)
        : '-',
      table.last_processed_datetime ? table.reaction_count : '-',
      table.last_processed_datetime ? table.like_count : '-',
      table.last_processed_datetime ? table.comment_count : '-'
    ])

    const csv_data = { fields, data }

    csv.download(csv_data, csv_filename)

    TrackingService.sendEvent(`click:クチコミ(IG)>サマリー|基本データ:CSVダウンロード`)
  }

  /**
   * サマリーの基本データの平均・合計の切り替え
   */
  async onChangeSummaryDisplayFormat(payload: string) {
    await this.changeSummaryDisplayFormat(payload)

    const display_option = this.display_options.find(v => v.value === this.summary_display_format)
    const format_label = display_option?.text || ''

    TrackingService.sendEvent(`click:クチコミ(IG)>サマリー|基本データ:${format_label}`)
  }
}
