
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import FacebookAnalyticsHeader from '@/client/components-old/organisms/FacebookAnalyticsHeader'
import NotificationHandler from '@/client/components-old/organisms/NotificationHandler'
import FacebookAnalyticsAction from '@/client/components-old/templates/FacebookAnalyticsAction'
import FacebookAnalyticsFan from '@/client/components-old/templates/FacebookAnalyticsFan'
import FacebookAnalyticsNodata from '@/client/components-old/templates/FacebookAnalyticsNodata'
import FacebookAnalyticsPost from '@/client/components-old/templates/FacebookAnalyticsPost'
import FacebookAnalyticsReach from '@/client/components-old/templates/FacebookAnalyticsReach'
import FacebookAnalyticsSummary from '@/client/components-old/templates/FacebookAnalyticsSummary'
import FacebookAnalyticsVideo from '@/client/components-old/templates/FacebookAnalyticsVideo'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import { IState as IFacebookState } from '@/client/store/modules/facebook_analytics'
import event from '@/client/utils/event'

const accounts = namespace('accounts')
const facebook = namespace('facebook_analytics')

@Component({
  name: 'FacebookAnalytics',
  components: {
    NotificationHandler,
    FacebookAnalyticsHeader,
    FacebookAnalyticsNodata,
    FacebookAnalyticsSummary,
    FacebookAnalyticsPost,
    FacebookAnalyticsFan,
    FacebookAnalyticsAction,
    FacebookAnalyticsReach,
    FacebookAnalyticsVideo
  }
})
export default class FacebookAnalytics extends Vue {
  @accounts.Getter('facebook_analytics') accounts!: IAccountsGetter['facebook_analytics']
  @facebook.State('is_loading') is_loading!: IFacebookState['is_loading']
  @facebook.State('account_id') account_id!: IFacebookState['account_id']
  @facebook.State('screen_name') screen_name!: IFacebookState['screen_name']
  @facebook.Action('createdFacebookAnalyticsPage') createdFacebookAnalyticsPage!: any
  @facebook.Action('destroyedFacebookAnalyticsPage') destroyedFacebookAnalyticsPage!: any
  @facebook.Action('changeGroupFacebookAnalyticsPage') changeGroupFacebookAnalyticsPage!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  get is_empty() {
    const account = this.accounts.find(account => account.id === this.account_id)

    return account ? account.is_empty : false
  }

  get is_summary() {
    return this.screen_name === 'summary'
  }

  get is_post() {
    return this.screen_name === 'post'
  }

  get is_fan() {
    return this.screen_name === 'fan'
  }

  get is_action() {
    return this.screen_name === 'action'
  }

  get is_reach() {
    return this.screen_name === 'reach'
  }

  get is_video() {
    return this.screen_name === 'video'
  }

  @Watch('is_loading')
  async changeIsLoading() {
    await this.$nextTick()
    await event.delay(500)
    event.resize()
  }

  @Watch('screen_name')
  async changeScreenName() {
    await this.$nextTick()
    await event.delay(500)
    event.resize()
  }

  @Watch('group')
  async watchGroup() {
    await this.changeGroupFacebookAnalyticsPage()
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  @Emit('change-project')
  changeProject(payload: any) {
    return payload
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.createdFacebookAnalyticsPage(this.$route.query)

    if (this.$route.query?.account_id) {
      await this.$router.replace({ name: 'analytics_fb' })
    }
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedFacebookAnalyticsPage()
  }
}
