
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Action, namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Checkbox from '@/client/components-old/atoms/Checkbox'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Label from '@/client/components-old/atoms/Label'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  IInvitation,
  IPostInvitationByIdApprovalParams,
  IPostInvitationByIdApprovalResponse
} from '@/client/utils/api/invitations'
import { IGetUserWorkResponse, IUserWork } from '@/client/utils/api/user_works'
import i18n from '@/client/utils/i18n'
import { PASSWORD_CHARACTER, PASSWORD_REQUIRED } from '@/client/utils/regex'
import storage from '@/client/utils/storage'

const notification = namespace('notification')

@Component({
  name: 'InvitationForm',
  components: {
    Button,
    ButtonLink,
    Checkbox,
    Icon,
    Input,
    Label,
    Message,
    Panel,
    SelectMulti
  }
})
export default class InvitationForm extends Vue {
  @State('user') user!: IRootState['user']
  @Action('login') login!: any
  @notification.Action('showNotification') showNotification: any

  @Prop({ type: String, required: true })
  invitationId!: string

  @Prop({ type: Object, required: true })
  invitation!: IInvitation

  user_works: IUserWork[] = []

  form: {
    name: string
    password: string
    user_work_ids: number[]
    terms_of_service: boolean
  } = {
    name: '',
    password: '',
    user_work_ids: [],
    terms_of_service: false
  }

  is_name_error = false
  is_password_error = false
  is_password_format_error = false
  is_password_character_error = false
  is_password_required_error = false

  loading = false

  get is_new_user() {
    return this.invitation.user === null
  }

  get is_lang_ja() {
    return this.user.language === 'ja'
  }

  get error_name() {
    return this.is_name_error
  }

  get error_password() {
    if (this.is_new_user) {
      return (
        this.is_password_error ||
        this.is_password_format_error ||
        this.is_password_character_error ||
        this.is_password_required_error
      )
    } else {
      return this.is_password_error
    }
  }

  get disabled() {
    const is_name = !this.form.name || this.error_name
    const is_password = !this.form.password || this.error_password

    if (this.is_new_user) {
      return this.loading || is_name || is_password || !this.form.terms_of_service
    } else {
      return this.loading || is_password
    }
  }

  get user_work_options() {
    return this.user_works.map(v => ({
      text: i18n.t(v.name),
      value: v.id
    }))
  }

  @Emit('change-route')
  changeRoute(payload: any): any {
    return payload
  }

  /**
   * ページ表示時
   */
  async created() {
    if (this.is_new_user) {
      await this.fetchUserWorks()
    }
  }

  /**
   * 氏名の変更
   */
  changeName(value: string) {
    this.is_name_error = !value
  }

  /**
   * パスワードの変更
   */
  changePassword(value: string) {
    this.is_password_error = !value
    this.is_password_format_error = value.length < 8 || value.length > 60
    this.is_password_character_error = !PASSWORD_CHARACTER.test(value)
    this.is_password_required_error = !PASSWORD_REQUIRED.test(value)
  }

  /**
   * 主な業務の取得
   */
  async fetchUserWorks() {
    this.loading = true

    const response = await API.get<IGetUserWorkResponse>('user_works')

    this.loading = false

    if (response.data.data) {
      this.user_works = response.data.data
    }
  }

  /**
   * 招待されたグループに参加
   */
  async joinInviteGroup() {
    if (this.disabled) return

    const params: IPostInvitationByIdApprovalParams = {
      password: this.form.password
    }

    if (this.is_new_user) {
      params.name = this.form.name.trim()
      params.language = this.user.language
      params.timezone = this.user.timezone
      params.user_work_ids = this.form.user_work_ids
    }

    this.loading = true

    const response = await API.post<IPostInvitationByIdApprovalResponse>(
      `invitations/${this.invitationId}/approval`,
      params
    )

    if (response.data.data) {
      storage.setUserId(response.data.data.user_id, response.data.data.project_id)

      await this.login()

      await this.showNotification({ title: 'グループに参加しました。' })

      await this.changeRoute({ name: 'dashboard' })

      return
    }

    this.loading = false

    if (response.data.error && response.data.error.type === 'REQUIRED_PARAM_NOT_EXIST') {
      return this.showNotification({
        title: 'グループに参加に失敗しました。',
        message: '入力項目を再度ご確認ください。',
        type: 'error'
      })
    }

    if (response.data.error && response.data.error.type === 'INVITATION_EXPIRED') {
      return this.showNotification({
        title: 'グループ招待の有効期限が切れています。',
        message: '有効期限は招待後72時間以内となります。',
        type: 'error'
      })
    }

    if (response.data.error && response.data.error.type === 'INVITATION_MAX_OPERATOR_OVER') {
      return this.showNotification({
        title: 'グループに参加に失敗しました。',
        message: 'オペレーター登録可能数を超えているため、グループに参加できません。',
        type: 'error'
      })
    }

    this.showNotification({
      title: 'グループに参加に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }
}
