
import { computed, defineComponent, PropType } from 'vue'

import ExternalLink from '@/client/components-old/atoms/ExternalLink'
import { getTranslateText as t } from '@/client/utils/filters'

type HelpLinkType =
  | 'ABOUT_ACCESS_TOKEN'
  | 'ACCOUNT_SUSPEND'
  | 'CHECK_COMPETITOR_ANALYSIS'
  | 'CHECK_MONITORING'
  | 'CHECK_OWN_ANALYSIS'
  | 'CHECK_POST'
  | 'CREATE_POST'
  | 'DOWNLOAD_EXCEL_REPORT_OWN_ANALYSIS'
  | 'FAQ_ENVIRONMENT'
  | 'IMPORT_TWITTER_CSV_OWN_ANALYSIS'
  | 'INVITE_OPERATOR'
  | 'IP_ADDRESS_ACCESS_RESTRICTIONS'
  | 'METRICS_FACEBOOK_OWN_ANALYSIS'
  | 'METRICS_INSTAGRAM_OWN_ANALYSIS'
  | 'METRICS_TWITTER_OWN_ANALYSIS'
  | 'METRICS_TIKTOK_OWN_ANALYSIS'
  | 'POST_APPROVAL'
  | 'POST_CREATE_INSTAGRAM_PUBLISH_STEP'
  | 'POST_IMAGE'
  | 'POST_VIDEO'
  | 'REGISTER_SNS_FACEBOOK'
  | 'REGISTER_SNS_INSTAGRAM'
  | 'REGISTER_SNS_TWITTER'
  | 'REGISTER_SNS_TIKTOK'
  | 'SETTING_FACEBOOK_APP_AUTH'
  | 'SETTING_OPERATOR_PERMISSION'
  | 'VIRAL_ANALYSIS_MAX_KEYWORD_COUNT'
  | 'CHECK_VIRAL'
  | 'SETTING_VIRAL_KEYWORD'
  | 'REGISTER_MONITORING'

type HelpLinkColor = 'link' | 'warning'

export default defineComponent({
  name: 'HelpLink',
  components: {
    ExternalLink
  },
  props: {
    type: {
      type: String as PropType<HelpLinkType>,
      default: 'ABOUT_ACCESS_TOKEN'
    },
    color: {
      type: String as PropType<HelpLinkColor>,
      default: 'link'
    }
  },
  setup(props) {
    const title = computed(() => t(`${props.type}>title`))
    const url = computed(() => t(`${props.type}>url`))
    return { t, title, url }
  }
})
