
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import InstagramComparisonAnalyticsHeader from '@/client/components-old/organisms/InstagramComparisonAnalyticsHeader'
import InstagramComparisonAnalyticsBenchmark from '@/client/components-old/templates/InstagramComparisonAnalyticsBenchmark'
import InstagramComparisonAnalyticsManagement from '@/client/components-old/templates/InstagramComparisonAnalyticsManagement'
import InstagramComparisonAnalyticsNodata from '@/client/components-old/templates/InstagramComparisonAnalyticsNodata'
import InstagramComparisonAnalyticsPost from '@/client/components-old/templates/InstagramComparisonAnalyticsPost'
import { TrackingService } from '@/client/services'
import { IState as IInstagramState } from '@/client/store/modules/instagram_comparison_analytics'
import event from '@/client/utils/event'

const instagram = namespace('instagram_comparison_analytics')

@Component({
  name: 'InstagramComparisonAnalytics',
  components: {
    InstagramComparisonAnalyticsHeader,
    InstagramComparisonAnalyticsNodata,
    InstagramComparisonAnalyticsBenchmark,
    InstagramComparisonAnalyticsPost,
    InstagramComparisonAnalyticsManagement
  }
})
export default class InstagramComparisonAnalytics extends Vue {
  @instagram.State('api_sns_comparison_accounts')
  accounts!: IInstagramState['api_sns_comparison_accounts']
  @instagram.State('account_ids') account_ids!: IInstagramState['account_ids']
  @instagram.State('screen_name') screen_name!: IInstagramState['screen_name']
  @instagram.State('is_loading') is_loading!: IInstagramState['is_loading']
  @instagram.Action('createdInstagramComparisonAnalyticsPage')
  createdInstagramComparisonAnalyticsPage!: any
  @instagram.Action('destroyedInstagramComparisonAnalyticsPage')
  destroyedInstagramComparisonAnalyticsPage!: any
  @instagram.Action('changeGroupInstagramComparisonAnalyticsPage')
  changeGroupInstagramComparisonAnalyticsPage!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  get is_empty() {
    if (this.is_management) return false

    if (!this.account_ids.length) return true

    return this.account_ids.every(account_id => {
      const account = this.accounts.find(account => account.id === account_id)

      return account ? Boolean(account.is_empty) : false
    })
  }

  get is_benchmark() {
    return this.screen_name === 'benchmark'
  }

  get is_post() {
    return this.screen_name === 'post'
  }

  get is_management() {
    return this.screen_name === 'management'
  }

  @Watch('is_loading', { immediate: true })
  async changeIsLoading() {
    await this.$nextTick()
    event.resize()
  }

  @Watch('screen_name', { immediate: true })
  async changeScreenName() {
    TrackingService.sendEvent(`click:競合比較(IG)|タブ:${this.screen_name}`)

    await this.$nextTick()
    event.resize()
  }

  @Watch('group')
  async watchGroup() {
    await this.changeGroupInstagramComparisonAnalyticsPage()
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.createdInstagramComparisonAnalyticsPage()
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedInstagramComparisonAnalyticsPage()
  }
}
