import { RouteConfig } from 'vue-router'

import LibraryTwitterTrend from '../pages/LibraryTwitterTrend.vue'

export const library_twitter_trend: RouteConfig = {
  component: LibraryTwitterTrend,
  path: '/library_twitter_trend',
  name: 'library_twitter_trend',
  meta: {
    auth: true,
    title: 'Xトレンド',
    description:
      '1時間ごとのXトレンドを収集しています。指定した期間のトレンド入り回数などを確認できます。'
  }
}
