import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Button from '@/client/components-old/atoms/Button'
import Flex from '@/client/components-old/atoms/Flex'
import Input from '@/client/components-old/atoms/Input'

@Component({
  name: 'Search',
  components: {
    Flex,
    Input,
    Button
  }
})
export default class Search extends Vue {
  @Prop({ type: String, default: '' })
  value: string

  @Prop({ type: String, default: 'normal' })
  size: 'normal' | 'full'

  get input_value() {
    return this.value
  }

  set input_value(val) {
    this.$emit('input', val)
  }

  get classes() {
    return ['size-' + this.size]
  }

  @Emit('submit')
  handleSubmit() {
    return this.input_value
  }
}
