
import { Component, Emit, Vue } from 'vue-property-decorator'
import { Action, namespace, State } from 'vuex-class'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import SignupComplete from '@/client/components-old/organisms/SignupComplete'
import SignupConfirm from '@/client/components-old/organisms/SignupConfirm'
import SignupForm from '@/client/components-old/organisms/SignupForm'
import SignupHeader from '@/client/components-old/organisms/SignupHeader'
import { IRootState } from '@/client/store/global'
import { IState } from '@/client/store/modules/signup'

const signup = namespace('signup')

@Component({
  name: 'Signup',
  components: {
    ButtonLink,
    SignupHeader,
    SignupComplete,
    SignupConfirm,
    SignupForm
  }
})
export default class Signup extends Vue {
  @State('user') user!: IRootState['user']
  @Action('fetchLanguage') fetchLanguage: any
  @Action('forceReRender') forceReRender: any
  @signup.State('form_step') form_step!: IState['form_step']
  @signup.Action('createdSignupPage') createdSignupPage!: any
  @signup.Action('destroyedSignupPage') destroyedSignupPage!: any
  @signup.Action('cancelFormData') cancelFormData!: any

  email = ''

  get disabled_ja() {
    return this.user.language === 'ja'
  }

  get disabled_en() {
    return this.user.language === 'en'
  }

  get color_ja() {
    return this.disabled_ja ? 'default' : 'info'
  }

  get color_en() {
    return this.disabled_en ? 'default' : 'info'
  }

  get is_confirm() {
    return this.form_step === 1
  }

  get is_complete() {
    return this.form_step === 2
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.createdSignupPage()
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedSignupPage()
  }

  /**
   * ログインに移動
   */
  moveLogin() {
    this.changeRoute({ name: 'login' })
  }

  /**
   * 言語の変更
   */
  async changeLanguage(language: 'ja' | 'en') {
    await this.fetchLanguage(language)
    await this.forceReRender()
  }
}
