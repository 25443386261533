
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Slider'
})
export default class Slider extends Vue {
  @Prop({ type: Number, default: 0 })
  value!: number

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: Number, default: 1 })
  step!: number

  @Prop({ type: Number, required: false })
  min!: number

  @Prop({ type: Number, required: false })
  max!: number

  get input_value() {
    return this.value
  }

  set input_value(val) {
    this.$emit('input', val)
  }
}
