import moment from 'moment-timezone'
import { computed, ref } from 'vue'

import { useNotificationStore, useStore } from '@/client/hooks'
import API from '@/client/utils/api'
import { getTranslateText as t } from '@/client/utils/filters'

import {
  DeleteTwitterViralSettingByIdResponse,
  GetTwitterViralSettingsQuery,
  GetTwitterViralSettingsResponse,
  PostTwitterViralSettingsBody,
  PostTwitterViralSettingsResponse,
  PutTwitterViralSettingByIdBody,
  PutTwitterViralSettingByIdResponse,
  PutTwitterViralSettingOrdersBody,
  PutTwitterViralSettingOrdersResponse,
  TwitterViralSetting
} from '../api'

export function useTwitterViralSetting() {
  const store = useStore()
  const notification = useNotificationStore()

  const api_settings = ref<TwitterViralSetting[]>([])

  const is_setting_loading = ref(false)

  const collection_count = computed(
    () =>
      api_settings.value
        .filter(v => v.is_active)
        .filter(v => moment().isSameOrAfter(v.start_date))
        .filter(v => (v.end_date ? moment().isSameOrBefore(v.end_date) : true)).length
  )

  /**
   * 設定の一覧を取得する
   */
  async function fetchTwitterViralSettings() {
    const query: GetTwitterViralSettingsQuery = {
      project_id: store.state.project.id
    }

    is_setting_loading.value = true

    const response = await API.get<GetTwitterViralSettingsResponse>('twitter_viral/settings', {
      params: query
    })

    is_setting_loading.value = false

    if (response.data.data) {
      api_settings.value = response.data.data
    }

    return response
  }

  /**
   * クチコミ推移設定を作成する
   */
  async function createTwitterViralSetting(
    payload: Omit<PostTwitterViralSettingsBody, 'project_id'>
  ) {
    const body: PostTwitterViralSettingsBody = {
      project_id: store.state.project.id,
      ...payload
    }

    const response = await API.post<PostTwitterViralSettingsResponse>(
      'twitter_viral/settings',
      body
    )

    if (response.data.data) {
      await notification.success({
        title: t('設定を保存しました。')
      })

      await fetchTwitterViralSettings()
    } else {
      await notification.error({
        title: t('設定の保存に失敗しました。'),
        message: t('恐れ入りますが、時間をおいて再度お試しください。')
      })
    }

    return response
  }

  /**
   * クチコミ推移設定を編集する
   */
  async function updateTwitterViralSetting(
    setting_id: number,
    payload: PutTwitterViralSettingByIdBody
  ) {
    const body: PutTwitterViralSettingByIdBody = {
      ...payload
    }

    const response = await API.put<PutTwitterViralSettingByIdResponse>(
      `twitter_viral/settings/${setting_id}`,
      body
    )

    if (response.data.data) {
      await notification.success({
        title: t('設定を保存しました。')
      })
    }

    if (response.data.error) {
      if (response.data.error.type === 'NOT_EXISTS') {
        await notification.error({
          title: t('対象のキーワード分類はすでに削除されています。')
        })
      } else {
        await notification.error({
          title: t('設定の保存に失敗しました。'),
          message: t('恐れ入りますが、時間をおいて再度お試しください。')
        })
      }
    }

    await fetchTwitterViralSettings()

    return response
  }

  /**
   * クチコミ推移設定を削除する
   */
  async function deleteTwitterViralSetting(setting_id: number) {
    const response = await API.delete<DeleteTwitterViralSettingByIdResponse>(
      `twitter_viral/settings/${setting_id}`
    )

    if (response.data.data) {
      await notification.success({
        title: t('キーワード分類を削除しました。')
      })
    }

    if (response.data.error) {
      if (response.data.error.type === 'NOT_EXISTS') {
        await notification.error({
          title: t('対象のキーワード分類はすでに削除されています。')
        })
      } else {
        await notification.error({
          title: t('キーワード分類の削除に失敗しました。'),
          message: t('恐れ入りますが、時間をおいて再度お試しください。')
        })
      }
    }

    await fetchTwitterViralSettings()

    return response
  }

  /**
   * クチコミ推移設定の並び替えを同期する
   */
  async function syncTwitterViralSettingOrders() {
    const body: PutTwitterViralSettingOrdersBody = {
      setting_ids: api_settings.value.map(v => v.id)
    }

    const response = await API.put<PutTwitterViralSettingOrdersResponse>(
      'twitter_viral/setting_orders',
      body
    )

    if (response.data.data) {
      await notification.success({
        title: t('キーワード分類の並び順を更新しました。')
      })
    }

    if (response.data.error) {
      await notification.error({
        title: t('キーワード分類の並び順の更新に失敗しました。'),
        message: t('恐れ入りますが、時間をおいて再度お試しください。')
      })

      await fetchTwitterViralSettings()
    }

    return response.data
  }

  return {
    api_settings,
    is_setting_loading,
    collection_count,
    fetchTwitterViralSettings,
    createTwitterViralSetting,
    updateTwitterViralSetting,
    deleteTwitterViralSetting,
    syncTwitterViralSettingOrders
  }
}
