
import { Component, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import LibrarySearchTwitterTrend from '@/client/components-old/organisms/LibrarySearchTwitterTrend'

@Component({
  name: 'LibraryTwitterTrend',
  components: {
    Scroll,
    LibrarySearchTwitterTrend
  }
})
export default class LibraryTwitterTrend extends Vue {
  height = 0

  /**
   * スクロールの高さ変更
   */
  changeHeight(height: number) {
    this.height = height - 130
  }
}
