import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Notification from '@/client/components-old/atoms/Notification'

const notification = namespace('notification')

@Component({
  name: 'NotificationHandler',
  components: {
    Notification
  }
})
export default class NotificationHandler extends Vue {
  @notification.State('title') title
  @notification.State('message') message
  @notification.State('type') type
  @notification.State('loading') loading
  @notification.State('is_show') is_show
  @notification.Action('hideNotification') hideNotification

  /**
   * 閉じる
   */
  async onHide() {
    await this.hideNotification()
  }
}
