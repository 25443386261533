import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'

@Component({
  name: 'SwitchRow',
  components: {
    Icon,
    Tooltip,
    SwitchButton
  }
})
export default class SwitchRow extends Vue {
  @Prop({ type: String, default: '' })
  iconType: string

  @Prop({ type: String, default: '' })
  text: string

  @Prop({ type: String, default: '' })
  description: string

  @Prop({ type: Boolean, default: false })
  value: boolean

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  get is_icon() {
    return this.iconType !== ''
  }

  get is_tooltip() {
    return this.description !== ''
  }

  get input_value() {
    return this.value
  }

  set input_value(val) {
    this.$emit('input', val)
  }
}
