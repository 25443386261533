import { RouteConfig } from 'vue-router'

import Monitoring from '../pages/Monitoring.vue'

export const monitoring: RouteConfig = {
  component: Monitoring,
  path: '/monitoring',
  name: 'monitoring',
  meta: {
    auth: true,
    title: 'モニタリング',
    description: 'SNSアカウントに寄せられたコメントやメッセージを確認できます。',
    color: 'warning',
    type: 'CHECK_MONITORING'
  }
}
