
import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import CategoryList from '@/client/components-old/molecules/CategoryList'
import IncidentEventItem from '@/client/components-old/molecules/IncidentEventItem'
import PostMedia from '@/client/components-old/molecules/PostMedia'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState } from '@/client/store/modules/post_management'
import { IPost, IPostCategory } from '@/client/utils/api/posts'

const post_management = namespace('post_management')

@Component({
  name: 'PostManagementWeek',
  components: {
    Button,
    Icon,
    Message,
    Tooltip,
    Account,
    IncidentEventItem,
    PostMedia,
    CategoryList
  }
})
export default class PostManagementWeek extends Vue {
  @State('user') user: IRootState['user']
  @post_management.State('api_posts') api_posts: IState['api_posts']
  @post_management.State('api_incident_events') api_incident_events: IState['api_incident_events']
  @post_management.State('is_loading') is_loading: IState['is_loading']
  @post_management.State('is_get_post_failed') is_get_post_failed: IState['is_get_post_failed']
  @post_management.State('start_date') start_date: IState['start_date']
  @post_management.Action('openPostDetailDialog') openPostDetailDialog

  @Prop({ type: Number, default: 800 })
  height: number

  @Watch('is_loading', { immediate: true })
  watchLoading() {
    // オフセットがリセットされた場合は、上にスクロールする
    if (this.is_loading && this.$el && this.$el.querySelector('.el-table__body-wrapper')) {
      this.$el.querySelector('.el-table__body-wrapper').scrollTop = 0
    }
  }

  get header_data() {
    const target_week = moment(this.start_date)

    const weekdays = 7

    return [...Array(weekdays)].map((_, weekday) =>
      target_week
        .clone()
        .isoWeekday(weekday + 1)
        .format()
    )
  }

  get table_data() {
    // アクション日時または変更日時でソートする（秒のズレを直すために2回ソートする）
    const posts = [...this.api_posts]
      .sort((a, b) => moment(a.modified).unix() - moment(b.modified).unix())
      .sort(
        (a, b) =>
          moment(a.action_datetime || a.modified).unix() -
          moment(b.action_datetime || b.modified).unix()
      )

    const incident_events = [...this.api_incident_events]

    const hours = 24

    const today = moment()

    return [...Array(hours)].map((_, hour) =>
      this.header_data.map(weekday => {
        const target_hour = moment(weekday).add(hour, 'hour')

        const target_posts = posts.filter(post =>
          moment(post.action_datetime || post.modified).isSame(target_hour, 'hour')
        )

        const target_incident_events = incident_events.filter(event =>
          moment(event.date).isSame(target_hour, 'hour')
        )

        return {
          hour: target_hour.format(),
          posts: target_posts,
          incident_events: target_incident_events,
          is_start_of_day: target_hour.isSame(target_hour.clone().startOf('day')),
          is_past: today.isAfter(target_hour, 'hour'),
          is_first: target_hour.isoWeekday() === 1
        }
      })
    )
  }

  get header_class() {
    return 'not-sort white'
  }

  get cell_class() {
    return 'no-padding'
  }

  get is_incident_event() {
    return this.api_incident_events.length
  }

  get max_incident_event() {
    if (!this.is_incident_event) {
      return 0
    }

    const events = this.api_incident_events
      .reduce((pre_events, current_event) => {
        const exist_event_index = pre_events.findIndex(event => event.date === current_event.date)

        if (exist_event_index === -1) {
          pre_events.push({ date: current_event.date, count: 1 })
        } else {
          pre_events[exist_event_index].count++
        }

        return pre_events
      }, [])
      .sort((pre, next) => next.count - pre.count)

    return events[0].count
  }

  /**
   * ラベル名の取得
   */
  getLabelTitle(date: string): string {
    const format = this.user.language === 'ja' ? 'M月D日(dd)' : 'MMM DD(ddd)'

    return moment(date).format(format)
  }

  /**
   * ラベルのクラス判別
   */
  isLabelClass(date: string): boolean {
    return moment().isSame(date, 'day')
  }

  /**
   * カレンダーのタイトル取得
   */
  getCalendarTitle(date: string): string {
    return moment(date).format('H:mm')
  }

  /**
   * タグ名の一覧取得
   */
  getCategoryNames(post_categories: IPostCategory[] | null) {
    return post_categories && post_categories.length
      ? post_categories.map(category => category.name)
      : []
  }

  /**
   * ステータスを取得
   */
  getStatus(post: IPost): string {
    if (post.status !== 'scheduled') {
      return post.status
    }

    return post.type === 'fb_scheduled' ? 'fb_scheduled' : post.status
  }

  /**
   * 投稿の新規作成
   */
  createPost(date: string): void {
    TrackingService.sendEvent('click:投稿管理|週|新規作成(時刻)')

    let datetime = moment(date).startOf('hour')

    // 当日の時間帯の場合
    if (moment().isSame(datetime, 'hour')) {
      datetime = moment().add(10, 'minute')

      const minute = Math.ceil(datetime.minute() / 5) * 5

      datetime.set('minute', minute)
    }

    this.changeRoute({
      name: 'posts/create',
      query: {
        scheduled_datetime: datetime.format()
      }
    })
  }

  /**
   * 投稿情報をダイアログ表示
   */
  async openPostDialog(post: IPost) {
    TrackingService.sendEvent('click:投稿管理|週|プレビュー')

    if (post.status === 'scheduled' && post.type === 'fb_scheduled') {
      open(
        `https://www.facebook.com/${post.fb_schedule.account_id}_${post.fb_schedule.fb_post_id}`,
        '_blank'
      )
    } else {
      await this.openPostDetailDialog({
        sns_post_id: post.id,
        mode: 'approval',
        tab: 'preview'
      })
    }
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
