// ? 権限系の型定義

/**
 * SNSアカウントの投稿権限パターン
 * 0=権限なし 1=管理者 2=編集者
 */
export type RolePost = 0 | 1 | 2
export type RolePostName = 'no_authority' | 'editor' | 'admin'

/**
 * SNSアカウントの分析権限パターン
 * 0=権限なし 1=管理者
 */
export type RoleAnalytics = 0 | 1
export type RoleAnalyticsName = 'no_authority' | 'admin'

/**
 * SNSアカウントのモニタリング権限パターン
 * 0=権限なし 1=管理者 2=監視者
 */
export type RoleMonitoring = 0 | 1 | 2
export type RoleMonitoringName = 'no_authority' | 'observer' | 'admin'
