
import { computed, defineComponent, PropType } from 'vue'

type NameProps = 'comnico' | 'facebook' | 'instagram' | 'twitter'

type SizeProps = 'small' | 'normal' | 'large'

export default defineComponent({
  name: 'AvatarService',
  props: {
    name: {
      type: String as PropType<NameProps>,
      default: 'comnico'
    },
    size: {
      type: String as PropType<SizeProps>,
      default: 'normal'
    }
  },
  setup(props) {
    const icon = computed(() => `ci-${props.name}-with-circle`)

    return {
      icon
    }
  }
})
