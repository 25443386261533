
import { Component, Emit, Vue } from 'vue-property-decorator'
import { Getter, namespace, State } from 'vuex-class'

import { DashboardIPAddressRestriction } from '@/client/components/organisms/Dashboard'
import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Scroll from '@/client/components-old/atoms/Scroll'
import Select from '@/client/components-old/atoms/Select'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import DashboardAccountRegister from '@/client/components-old/organisms/DashboardAccountRegister'
import DashboardGroupRegister from '@/client/components-old/organisms/DashboardGroupRegister'
import DashboardMonitoringHistory from '@/client/components-old/organisms/DashboardMonitoringHistory'
import DashboardMonitoringMemo from '@/client/components-old/organisms/DashboardMonitoringMemo'
import DashboardMonitoringPending from '@/client/components-old/organisms/DashboardMonitoringPending'
import DashboardMonitoringUnread from '@/client/components-old/organisms/DashboardMonitoringUnread'
import DashboardNotice from '@/client/components-old/organisms/DashboardNotice'
import DashboardPostApprove from '@/client/components-old/organisms/DashboardPostApprove'
import DashboardPostHistory from '@/client/components-old/organisms/DashboardPostHistory'
import DashboardPostMemo from '@/client/components-old/organisms/DashboardPostMemo'
import DashboardPostPublished from '@/client/components-old/organisms/DashboardPostPublished'
import DashboardPostReject from '@/client/components-old/organisms/DashboardPostReject'
import DashboardPostScheduled from '@/client/components-old/organisms/DashboardPostScheduled'
import DashboardTutorial from '@/client/components-old/organisms/DashboardTutorial'
import { CrispService } from '@/client/services'
import { IRootGetter, IRootState } from '@/client/store/global'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import { IState as IDashboardState } from '@/client/store/modules/dashboard'
import { TDashboardSettingItem } from '@/client/utils/api/user_dashboard_settings'

const accounts = namespace('accounts')
const dashboard = namespace('dashboard')

@Component({
  name: 'DashboardLayout',
  components: {
    Button,
    Icon,
    Scroll,
    Select,
    SwitchButton,
    DashboardIPAddressRestriction,
    DashboardAccountRegister,
    DashboardGroupRegister,
    DashboardMonitoringHistory,
    DashboardMonitoringMemo,
    DashboardMonitoringPending,
    DashboardMonitoringUnread,
    DashboardNotice,
    DashboardPostApprove,
    DashboardPostHistory,
    DashboardPostMemo,
    DashboardPostPublished,
    DashboardPostReject,
    DashboardPostScheduled,
    DashboardTutorial
  }
})
export default class DashboardLayout extends Vue {
  @State('project') project: IRootState['project']
  @Getter('is_restricted') is_restricted: IRootGetter['is_restricted']
  @accounts.Getter('is_sns_accounts') is_sns_accounts: IAccountsGetter['is_sns_accounts']
  @dashboard.State('api_dashboard_settings')
  api_dashboard_settings: IDashboardState['api_dashboard_settings']
  @dashboard.State('api_operators')
  api_operators: IDashboardState['api_operators']
  @dashboard.State('filter_operator_id')
  filter_operator_id: IDashboardState['filter_operator_id']
  @dashboard.State('filter_project_all')
  filter_project_all: IDashboardState['filter_project_all']
  @dashboard.State('is_loading')
  is_loading: IDashboardState['is_loading']
  @dashboard.Action('refreshDashboardPage') refreshDashboardPage
  @dashboard.Action('changeOperatorId') changeOperatorId
  @dashboard.Action('changeProjectAll') changeProjectAll

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  @Emit('open-post')
  openPost(payload: any) {
    return payload
  }

  @Emit('open-monitoring')
  openMonitoring(payload: any) {
    return payload
  }

  get settings() {
    return this.api_dashboard_settings
      .filter(v => v.is_show)
      .map(v => v.item)
      .filter(v => v !== 'tutorial_help')
  }

  get operators() {
    const operators = this.api_operators.map(v => ({ value: v.id, text: v.name }))

    operators.unshift({ value: '', text: this.$options.filters.translate('全員') })

    return operators
  }

  get activity() {
    if (this.filter_project_all) {
      return this.$options.filters.translate('全グループのアクティビティ')
    }

    return this.$options.filters.translate('[[name]] のアクティビティ', {
      name: this.project.project_name
    })
  }

  get is_not_group() {
    return !this.project.id
  }

  get is_not_accounts() {
    return !this.is_sns_accounts
  }

  get is_tutorial_help() {
    return this.api_dashboard_settings
      .filter(v => v.is_show)
      .map(v => v.item)
      .includes('tutorial_help')
  }

  /**
   * コンポーネント名を取得
   */
  getComponentName(setting: TDashboardSettingItem) {
    switch (setting) {
      case 'tutorial_help':
        return 'DashboardTutorial'
      case 'post_scheduled':
        return 'DashboardPostScheduled'
      case 'post_approve':
        return 'DashboardPostApprove'
      case 'post_published':
        return 'DashboardPostPublished'
      case 'post_reject':
        return 'DashboardPostReject'
      case 'post_memo':
        return 'DashboardPostMemo'
      case 'post_history':
        return 'DashboardPostHistory'
      case 'monitoring_unread':
        return 'DashboardMonitoringUnread'
      case 'monitoring_pending':
        return 'DashboardMonitoringPending'
      case 'monitoring_memo':
        return 'DashboardMonitoringMemo'
      case 'monitoring_history':
        return 'DashboardMonitoringHistory'
      default:
        return ''
    }
  }

  /**
   * トライアル終了グループのデータ引き継ぎで契約の申し込み
   */
  sendApplyContract(): void {
    CrispService.sendMessage('トライアル終了グループのデータ引き継ぎで契約の申し込み')
  }

  /**
   * 再トライアルの申し込み
   */
  sendApplyRetrial(): void {
    CrispService.sendMessage('再トライアルの申し込み')
  }
}
