import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Account from '@/client/components-old/molecules/Account'
import { TDashboardsMonitoring } from '@/client/utils/api/dashboards'
import i18n from '@/client/utils/i18n'

@Component({
  name: 'DashboardMonitoringItem',
  components: {
    Icon,
    Account
  }
})
export default class DashboardMonitoringItem extends Vue {
  @Prop({ type: Object, required: true })
  monitoring: TDashboardsMonitoring

  @Prop({ type: Boolean, default: false })
  isShowGroup: boolean

  get datetime() {
    return moment(this.monitoring.datetime).format('lll')
  }

  get message() {
    return this.monitoring.message || i18n.t('このアクションはメッセージがありません')
  }

  @Emit('open')
  open() {
    return {
      monitoring_id: this.monitoring.id,
      monitoring_sns: this.monitoring.sns,
      project_id: this.monitoring.project.id
    }
  }
}
