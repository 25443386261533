import { Component, Prop, Vue } from 'vue-property-decorator'

import Label from '@/client/components-old/atoms/Label'
import Radio from '@/client/components-old/atoms/Radio'
import Account from '@/client/components-old/molecules/Account'

@Component({
  name: 'SnsRegisterItem',
  components: {
    Radio,
    Account,
    Label
  }
})
export default class SnsRegisterItem extends Vue {
  @Prop({ type: String, required: true })
  value: string

  @Prop({ type: String, required: true })
  sns: string

  @Prop({ type: Object, required: true })
  account: {
    account_id: string
    name: string
    username: string
    image_url: string
    is_exist: boolean
    is_same_project: boolean
  }

  get checked() {
    return this.value
  }

  set checked(val) {
    this.$emit('input', val)
  }

  get disabled() {
    return this.account.is_exist
  }

  get label() {
    if (this.account.is_exist && !this.account.is_same_project) {
      return { type: 'warning', text: '別グループで登録済' }
    }
    if (this.account.is_exist && this.account.is_same_project) {
      return { type: 'default', text: '登録済' }
    }
    return null
  }
}
