import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  IGetPostCategoriesParams,
  IGetPostCategoriesResponse,
  IPostCategories,
  IPostPostCategoriesParams,
  IPostPostCategoriesResponse,
  IPutPostCategoryChangeOrderNoParams,
  IPutPostCategoryChangeOrderNoResponse,
  IPutPostCategoryParams,
  IPutPostCategoryResponse
} from '@/client/utils/api/post_categories'
import {
  IGetSnsCategoryPostsParams,
  IGetSnsCategoryPostsResponse,
  IPostSnsCategoryPostsParams,
  IPostSnsCategoryPostsResponse,
  ISnsCategoryPost,
  TSnsType,
  TTarget
} from '@/client/utils/api/sns_category_posts'

export interface IState {
  api_post_categories: IPostCategories[]
  api_category_posts: ISnsCategoryPost[]
}

const state: IState = {
  api_post_categories: [],
  api_category_posts: []
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  SET_API_POST_CATEGORIES(state, payload: IPostCategories[]) {
    state.api_post_categories = payload
  },
  SET_API_CATEGORY_POSTS(state, payload: ISnsCategoryPost[]) {
    state.api_category_posts = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /*
   * タグの取得
   */
  async fetchCategory(context) {
    const params: IGetPostCategoriesParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<IGetPostCategoriesResponse>('post_categories', { params })

    if (response.data.data) {
      context.commit('SET_API_POST_CATEGORIES', response.data.data)
    }
  },

  /*
   * タグの作成
   */
  async createCategory(context, payload: { category_name: string }) {
    const params: IPostPostCategoriesParams = {
      project_id: context.rootState.project.id,
      category_name: payload.category_name
    }

    const response = await API.post<IPostPostCategoriesResponse>('post_categories', params)

    if (response.data.data) {
      const category = {
        id: response.data.data.id,
        name: payload.category_name
      }

      const data = context.state.api_post_categories.concat([category])

      context.commit('SET_API_POST_CATEGORIES', data)
    }

    return response.data
  },

  /*
   * タグの更新
   */
  async updateCategory(
    context,
    payload: {
      category_id: number
      category_name: string
    }
  ) {
    const params: IPutPostCategoryParams = {
      category_name: payload.category_name
    }

    const response = await API.put<IPutPostCategoryResponse>(
      `post_categories/${payload.category_id}`,
      params
    )

    if (response.data.data) {
      const data = context.state.api_post_categories.map(v => {
        if (payload.category_id === v.id) {
          return { id: v.id, name: payload.category_name }
        }

        return { ...v }
      })

      context.commit('SET_API_POST_CATEGORIES', data)
    }

    if (response.data.error && response.data.error.type === 'NOT_EXISTS') {
      await context.dispatch('fetchCategory')
    }

    return response.data
  },

  /*
   * タグの削除
   */
  async removeCategory(context, payload: { category_id: number }) {
    const response = await API.delete(`post_categories/${payload.category_id}`)

    if (response.data.data) {
      const data = context.state.api_post_categories.filter(v => v.id !== payload.category_id)

      context.commit('SET_API_POST_CATEGORIES', data)

      if (context.state.api_category_posts.length) {
        const data = context.state.api_category_posts
          .map(v => ({
            post_id: v.post_id,
            category_ids: v.category_ids.filter(id => id !== payload.category_id)
          }))
          .filter(v => v.category_ids.length)

        context.commit('SET_API_CATEGORY_POSTS', data)
      }
    }

    if (response.data.error && response.data.error.type === 'NOT_EXISTS') {
      await context.dispatch('fetchCategory')
    }

    return response.data
  },

  /*
   * タグの順番を同期
   */
  async syncCategoryOrder(context, payload: IPostCategories[]) {
    context.commit('SET_API_POST_CATEGORIES', payload)

    const params: IPutPostCategoryChangeOrderNoParams = {
      project_id: context.rootState.project.id,
      category_ids: payload.map(v => v.id)
    }

    const response = await API.put<IPutPostCategoryChangeOrderNoResponse>(
      'post_categories/change_order_no',
      params
    )

    if (!response.data.data) {
      await context.dispatch('fetchCategory')
    }

    return response.data
  },

  /*
   * 投稿分析のタグを取得
   */
  async fetchCategoryAnalysisPost(context, payload: { target: TTarget; account_ids: string[] }) {
    const params: IGetSnsCategoryPostsParams = {
      project_id: context.rootState.project.id,
      target: payload.target,
      account_ids: payload.account_ids,
      start_date: context.rootState.analytics.start_date,
      end_date: context.rootState.analytics.end_date
    }

    const response = await API.get<IGetSnsCategoryPostsResponse>('sns_category_posts', { params })

    if (response.data.data) {
      context.commit('SET_API_CATEGORY_POSTS', response.data.data)
    }
  },

  /*
   * 投稿分析のタグを更新
   */
  async updatePostAnalysisCategory(
    context,
    payload: {
      sns_type: TSnsType
      account_id: string
      post_id: string
      category_ids: number[]
    }
  ) {
    const params: IPostSnsCategoryPostsParams = {
      project_id: context.rootState.project.id,
      sns_type: payload.sns_type,
      account_id: payload.account_id,
      post_id: payload.post_id,
      category_ids: payload.category_ids
    }

    const response = await API.post<IPostSnsCategoryPostsResponse>('sns_category_posts', params)

    if (response.data.data) {
      const data = context.state.api_category_posts.filter(v => v.post_id !== payload.post_id)

      if (payload.category_ids.length) {
        data.push({ post_id: payload.post_id, category_ids: payload.category_ids })
      }

      context.commit('SET_API_CATEGORY_POSTS', data)
    }

    return response.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
