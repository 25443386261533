import { render, staticRenderFns } from "./FacebookExcelReport.vue?vue&type=template&id=47012329&scoped=true"
import script from "./FacebookExcelReport.ts?vue&type=script&lang=ts&external"
export * from "./FacebookExcelReport.ts?vue&type=script&lang=ts&external"
import style0 from "./FacebookExcelReport.vue?vue&type=style&index=0&id=47012329&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47012329",
  null
  
)

export default component.exports