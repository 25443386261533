const MB = 2 ** 20
const MBPS = 1_000_000

/**
 * 画像のファイル形式をチェック
 */
export function checkImageType(type: string): boolean {
  return ['image/jpeg', 'image/png'].includes(type)
}

/**
 * 画像のファイルサイズをチェック
 */
export function checkImageSize(size: number): boolean {
  return size <= 8 * MB
}

/**
 * 画像のアスペクト比率をチェック
 */
export function checkImageAspectRatio(width: number, height: number): boolean {
  return 4 / 5 <= width / height && width / height <= 1.91 / 1
}

/**
 * 動画のファイルサイズをチェック
 */
export function checkVideoSize(size: number): boolean {
  return size <= 100 * MB // API仕様だと1GBまで 失敗するケースがある為
}

/**
 * ストーリーズ動画のファイルサイズをチェック
 */
export function checkVideoSizeByStory(size: number): boolean {
  return size <= 100 * MB
}

/**
 * 動画の再生時間をチェック
 */
export function checkVideoDuration(duration: number): boolean {
  return 3 <= duration && duration <= 15 * 60 // ? 3秒以上15分以下
}

/**
 * カルーセル動画の再生時間をチェック
 */
export function checkVideoDurationForCarousel(duration: number): boolean {
  return duration < 60 // ? 60秒以内
}

/**
 * ストーリーズ動画の再生時間をチェック
 */
export function checkVideoDurationByStory(duration: number): boolean {
  return 3 <= duration && duration <= 60
}

/**
 * 動画の解像度をチェック
 */
export function checkVideoResolution(width: number, height: number): boolean {
  return width <= 1920 && height <= 1920 && width * height <= 1920 * 1080
}

/**
 * リール動画のアスペクト比率をチェック
 */
export function checkVideoAspectRatioForReels(width: number, height: number): boolean {
  return 0.01 / 1 <= width / height && width / height <= 10 / 1
}

/**
 * カルーセル動画のアスペクト比率をチェック
 * ? 画像のアスペクト比と同様のチェックを入れる
 */
export function checkVideoAspectRatioForCarousel(width: number, height: number): boolean {
  return 4 / 5 <= width / height && width / height <= 1.91 / 1
}

/**
 * ストーリーズ動画のアスペクト比率をチェック
 */
export function checkVideoAspectRatioForStories(width: number, height: number): boolean {
  return 0.01 / 1 <= width / height && width / height <= 10 / 1
}

/**
 * 動画のフレームレートをチェック
 */
export function checkVideoFrameRate(frame_rate: number | null): boolean {
  return frame_rate ? 23 <= frame_rate && frame_rate <= 60 : false
}

/**
 * 動画の音声チャンネル数をチェック
 */
export function checkVideoAudioChannels(audio_channels: number | null): boolean {
  return !audio_channels || audio_channels <= 2
}

/**
 * 動画のビットレートをチェック
 */
export function checkVideoBitRate(bit_rate: number | null): boolean {
  return bit_rate ? bit_rate <= 25 * MBPS : false
}

/**
 * Instagramのリール基準に該当する動画か判定する
 */
export function checkVideoReels(video: HTMLVideoElement) {
  const aspect_ratio = video ? video.videoWidth / video.videoHeight : 0

  const MIN_ASPECT_RATIO = 4 / 5
  const MAX_ASPECT_RATIO = 1.91 / 1

  const is_aspect_ratio_reels = video
    ? !(MIN_ASPECT_RATIO <= aspect_ratio && aspect_ratio <= MAX_ASPECT_RATIO)
    : false

  return video.duration >= 60 || is_aspect_ratio_reels
}
