
import { Component, Vue } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import { Action, State } from 'vuex-class'

// import { RevisionAlertHandler } from '@/client/components/molecules'
import { GlobalEmergencyNotification } from '@/client/components/organisms/Global'
import GlobalHeader from '@/client/components-old/organisms/GlobalHeader'
import GlobalSidebar from '@/client/components-old/organisms/GlobalSidebar'
import NotificationHandler from '@/client/components-old/organisms/NotificationHandler'
import { CrispService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import event from '@/client/utils/event'
import { checkPagePermissions } from '@/client/utils/permissions'

@Component({
  name: 'App',
  components: {
    GlobalEmergencyNotification,
    GlobalHeader,
    GlobalSidebar,
    NotificationHandler
    // RevisionAlertHandler
  }
})
export default class App extends Vue {
  @State('user') user!: IRootState['user']
  @State('project') project!: IRootState['project']
  @State('emergency_notification') emergency_notification!: IRootState['emergency_notification']
  @State('render') render!: IRootState['render']
  @Action('changeGroup') changeGroup!: any
  @Action('addEmergencyNotificationRead') addEmergencyNotificationRead!: any

  sidebar = false
  group = 0

  async mounted() {
    await this.$nextTick()
    await event.delay(1000)
    event.resize()
  }

  /**
   * サイドバーを開く
   */
  openSidebar() {
    this.sidebar = true
  }

  /**
   * サイドバーを閉じる
   */
  closeSidebar() {
    this.sidebar = false
  }

  /**
   * サポートチャットを開く
   */
  openSupportChat() {
    CrispService.showSupportChat()
  }

  /**
   * グループの変更
   */
  async changeProject(payload: { project_id: number }) {
    if (payload.project_id === this.project.id) return

    await this.changeGroup(payload.project_id)

    if (!checkPagePermissions(this.$route.name)) {
      await this.changeRoute({ name: 'dashboard' })
    }

    // ? グループの変更終了を他コンポーネントに伝えるためにgroupの値を書き換える。重複しないように一意のグループIDを挿入する。
    this.group = payload.project_id
  }

  /**
   * ルーティングの変更
   */
  changeRoute(payload: RawLocation) {
    return this.$router.push(payload)
  }

  async closeEmergencyNotification() {
    await this.addEmergencyNotificationRead()
    await this.$nextTick()
    await event.delay(1000)
    event.resize()
  }
}
