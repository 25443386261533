
import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Dialog from '@/client/components-old/atoms/Dialog'
import PostDetail from '@/client/components-old/organisms/PostDetail'
import { IRootState } from '@/client/store/global'
import { IState } from '@/client/store/modules/post_management'

const post_management = namespace('post_management')

@Component({
  name: 'PostDetailDialog',
  components: {
    Dialog,
    PostDetail
  }
})
export default class PostDetailDialog extends Vue {
  @State('project') project: IRootState['project']
  @post_management.State('api_post') api_post: IState['api_post']
  @post_management.State('dialog_post_id') dialog_post_id: IState['dialog_post_id']
  @post_management.State('dialog_post_mode') dialog_post_mode: IState['dialog_post_mode']
  @post_management.State('dialog_post_error') dialog_post_error: IState['dialog_post_error']
  @post_management.State('is_dialog_visible') is_dialog_visible: IState['is_dialog_visible']
  @post_management.State('is_dialog_loading') is_dialog_loading: IState['is_dialog_loading']
  @post_management.Action('closePostDetailDialog') closePostDetailDialog!: any

  is_change = false

  get dialog_size() {
    if (this.is_dialog_loading) return 'normal'

    if (
      this.dialog_post_mode === 'approval' ||
      this.dialog_post_mode === 'release_procedure' ||
      this.$mq !== 'lg'
    ) {
      return 'large'
    }

    return 'normal'
  }

  get show_close() {
    return !this.dialog_post_error || this.$device === 'mobile'
  }

  get show_preview_title() {
    return this.dialog_post_error && this.$device === 'mobile'
  }

  @Watch('api_post.id', { immediate: true })
  async watchApiPostId() {
    if (this.api_post && this.project.id !== this.api_post.project_id) {
      await this.changeProject({ project_id: this.api_post.project_id })
    }
  }

  async onApproval() {
    this.$emit('update-post-approved', this.dialog_post_id)
    this.is_change = true
    await this.hide()
  }

  async onUpdateMemo() {
    this.$emit('update-post-memo', this.dialog_post_id)
    this.is_change = true
  }

  async onDelete() {
    this.$emit('update-post-remove', this.dialog_post_id)
    this.is_change = true
    await this.hide()
  }

  async onScheduledCancel() {
    this.$emit('update-post-scheduled-cancel', this.dialog_post_id)
    this.is_change = true
  }

  async onApproveCancel() {
    this.$emit('update-post-approve-cancel', this.dialog_post_id)
    this.is_change = true
  }

  async onRepublish() {
    this.$emit('update-post-publish', this.dialog_post_id)
    this.is_change = true
    await this.hide()
  }

  async onPublishPost() {
    this.$emit('update-post-publish', this.dialog_post_id)
    this.is_change = true
    await this.hide()
  }

  /**
   * ダイアログの非表示
   */
  async hide() {
    this.$emit('close', { is_change: this.is_change })
    this.is_change = false
    await this.closePostDetailDialog()
  }

  @Emit('change-project')
  changeProject(payload: any) {
    return payload
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
