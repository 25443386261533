import { RouteConfig } from 'vue-router'

import InstagramHashtag from '../pages/InstagramHashtag.vue'

export const instagram_hashtag: RouteConfig = {
  component: InstagramHashtag,
  path: '/instagram_hashtag',
  name: 'instagram_hashtag',
  meta: {
    auth: true,
    title: 'クチコミ分析 (Instagram)'
  }
}
