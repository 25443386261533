/* eslint-disable @typescript-eslint/no-var-requires */

/**
 * Global
 */
require('reflect-metadata')

window.jQuery = require('jquery')

const highcharts = require('highcharts')
require('highcharts/highcharts-more')(highcharts)
require('highcharts/modules/map')(highcharts)
require('highcharts/modules/solid-gauge')(highcharts)
require('highcharts/modules/exporting')(highcharts)
require('highcharts/modules/wordcloud')(highcharts)

highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
})

window.Highcharts = highcharts

/**
 * Moment
 */
require('moment/locale/ja')

/**
 * Highcharts Library
 */
require('@/client/assets/vendor/highcharts/mapdata/custom/world.js') // 地図データ（翻訳拡張）
require('@/client/assets/vendor/highcharts/mapdata/countries/jp/jp-all.js') // 地図データ（翻訳拡張）

/**
 * jQuery Library
 */
require('jquery-slimscroll')
