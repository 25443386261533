import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import InputNumber from '@/client/components-old/atoms/InputNumber'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import { IRootState } from '@/client/store/global'
import i18n from '@/client/utils/i18n'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

@Component({
  name: 'GroupSettingAnalyticsTwitterKeyword',
  components: {
    Button,
    Icon,
    InputNumber,
    Message,
    Panel
  }
})
export default class GroupSettingAnalyticsTwitterKeyword extends Vue {
  @State('project') project: IRootState['project']
  @group_setting.Action('updateAnalyticsKeywordHashtagSettings')
  updateAnalyticsKeywordHashtagSettings
  @notification.Action('showNotification') showNotification

  max_post_count = 0

  @Watch('project', { immediate: true, deep: true })
  syncKeywordSetting() {
    this.max_post_count = this.project.keyword_hashtag_max_post_count
  }

  get is_trial_group() {
    return this.project.contract_status === 0
  }

  get is_disabled() {
    return this.project.keyword_hashtag_max_post_count === 0
  }

  get free_count() {
    return this.project.keyword_hashtag_free_post_count
  }

  get keyword_count() {
    return 10000
  }

  get keyword_min_count() {
    if (this.current_post_count < this.free_count) {
      return this.free_count
    }

    return this.current_post_count
  }

  get keyword_fee() {
    return this.project.charge_keyword_hashtag
  }

  get current_post_count() {
    return this.project.keyword_hashtag_current_post_count
  }

  get keyword_option_fee() {
    return (
      Math.ceil((this.max_post_count - this.free_count) / this.keyword_count) * this.keyword_fee
    )
  }

  get collection_text() {
    return i18n.t('現在の収集件数: [[current_count]] 件', {
      current_count: `<b>${this.project.keyword_hashtag_current_post_count.toLocaleString()}</b>`
    })
  }

  /**
   * キーワード設定の保存
   */
  async saveKeywordSetting() {
    const result = await this.updateAnalyticsKeywordHashtagSettings({
      keyword_hashtag_max_post_count: this.max_post_count
    })

    if (result.data) {
      this.showNotification({ title: '設定を保存しました。' })
      return
    }

    this.showNotification({
      title: '設定の保存に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }
}
