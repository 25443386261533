import { RouteConfig } from 'vue-router'

export const google_drive_callback: RouteConfig = {
  path: '/google_drive_callback',
  name: 'google_drive_callback',
  meta: {
    auth: true
  },
  beforeEnter(to, from, next) {
    if (to.query.type === 'error') {
      window.opener.onGoogleDriveAuth({
        error: {
          type: 'AUTHENTICATION_FAILED'
        }
      })
    } else {
      window.opener.onGoogleDriveAuth({
        access_token: to.query.access_token
      })
    }

    next()
  }
}
