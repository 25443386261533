import { RouteConfig } from 'vue-router'

export const twitter_callback: RouteConfig = {
  path: '/twitter_callback',
  name: 'twitter_callback',
  meta: {
    auth: true
  },
  beforeEnter(to, from, next) {
    if (to.query.type === 'error') {
      window.opener.onTwitterAuth({
        error: {
          type: 'AUTHENTICATION_FAILED'
        }
      })
    } else {
      window.opener.onTwitterAuth({
        account_id: to.query.account_id,
        access_token: to.query.access_token,
        access_token_secret: to.query.access_token_secret
      })
    }

    next()
  }
}
