
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Button'
})
export default class Button extends Vue {
  @Prop({ type: String, default: 'default' })
  type!:
    | 'default'
    | 'primary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'inactive'
    | 'link'
    | 'facebook'

  @Prop({ type: String, default: 'button' })
  nativeType!: string

  @Prop({ type: String, default: 'normal' })
  size!: 'normal' | 'minimum' | 'small' | 'large' | 'full'

  @Prop({ type: String, default: '' })
  href!: string

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: Boolean, default: false })
  outline!: boolean

  @Prop({ type: Array, default: () => [] })
  borderCircle!: ['left' | 'right' | 'top' | 'bottom']

  get is_href() {
    return this.href !== ''
  }

  get container_class() {
    return ['button', `size-${this.size}`]
  }

  get button_class() {
    const temp = ['button-item', `type-${this.type}`, `size-${this.size}`]

    if (this.borderCircle.length > 0) {
      temp.push('border-circle')

      this.borderCircle.map(position => {
        temp.push(`border-circle-${position}`)
      })
    }

    if (this.outline) {
      temp.push('is-outline')
    }

    return temp
  }

  @Emit('click')
  onClick(event: Event) {
    return event
  }
}
