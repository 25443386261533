import { RouteConfig } from 'vue-router'

import SnsAnalytics from './pages/SnsAnalytics.vue'

export const sns_analytics: RouteConfig = {
  component: SnsAnalytics,
  path: '/sns_analytics',
  name: 'sns_analytics',
  meta: {
    auth: true,
    title: '全アカウント一括分析'
  }
}
