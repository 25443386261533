
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import TextareaLineNumber from '@/client/components-old/molecules/TextareaLineNumber'
import PostTemplateDialog from '@/client/components-old/organisms/PostTemplateDialog'
import { TrackingService } from '@/client/services'
import {
  IGetter as IPostCreateGetter,
  IState as IPostCreateState
} from '@/client/store/modules/post_create'
import { IState as IPostTemplateState } from '@/client/store/modules/post_templates'
import i18n from '@/client/utils/i18n'

const post_create = namespace('post_create')
const post_templates = namespace('post_templates')

@Component({
  name: 'PostCreateInstagramFirstCommentMessage',
  components: {
    ButtonLink,
    Dropdown,
    Icon,
    Message,
    Panel,
    Tooltip,
    SwitchButton,
    TextareaLineNumber,
    PostTemplateDialog
  }
})
export default class PostCreateInstagramFirstCommentMessage extends Vue {
  @post_create.State('api_post_setting') api_post_setting!: IPostCreateState['api_post_setting']
  @post_create.State('api_post_keywords') api_post_keywords!: IPostCreateState['api_post_keywords']
  @post_create.State('instagram_first_comment_message')
  instagram_first_comment_message!: IPostCreateState['instagram_first_comment_message']
  @post_create.State('instagram_comment_enabled')
  instagram_comment_enabled!: IPostCreateState['instagram_comment_enabled']
  @post_create.Getter('is_instagram_first_comment_message_count_over')
  is_instagram_first_comment_message_count_over!: IPostCreateGetter['is_instagram_first_comment_message_count_over']
  @post_create.Action('changeInstagramFirstCommentMessage') changeInstagramFirstCommentMessage: any
  @post_create.Action('changeInstagramCommentEnabled') changeInstagramCommentEnabled
  @post_templates.State('templates') templates!: IPostTemplateState['templates']

  template: string | number = ''

  $refs: {
    PostTemplateDialog: any
  }

  @Watch('templates')
  watchTemplates() {
    if (!this.templates.some(template => template.id === this.template)) {
      this.template = ''
    }
  }

  get is_message_comment() {
    return this.instagram_first_comment_message !== null
  }

  get highlight_options() {
    if (!this.api_post_setting) {
      return {
        check_double_byte_alphanumeric: false,
        check_double_byte_symbol: false,
        check_kana: false,
        check_punctuation: false,
        check_space: false
      }
    }

    return {
      check_double_byte_alphanumeric: this.api_post_setting.check_2byte1,
      check_double_byte_symbol: this.api_post_setting.check_2byte2,
      check_kana: this.api_post_setting.check_kana,
      check_punctuation: this.api_post_setting.check_punctuation,
      check_space: this.api_post_setting.check_space
    }
  }

  get highlight_words() {
    return this.api_post_keywords
      .filter(keyword => keyword.is_valid)
      .map(keyword => keyword.keyword)
      .filter(word => word.trim())
  }

  get templates_options() {
    return this.templates.map(template => ({
      text: template.name,
      value: template.id,
      content: template.content
    }))
  }

  /**
   * テンプレートの内容を投稿内容に追加する
   */
  changeTemplate(): void {
    TrackingService.sendEvent('click:投稿作成|IG最初のコメント|テンプレートの挿⼊')

    const template = this.templates.find(v => v.id === this.template)
    const new_line = '\n'
    const template_content = template ? template.content : ''

    const message = this.instagram_first_comment_message
      ? this.instagram_first_comment_message + new_line + template_content
      : template_content

    this.changeInstagramFirstCommentMessage(message)
  }

  /**
   * 最初のコメントの有効フラグを変更
   */
  changeIsMessageComment(is_message_comment: boolean) {
    TrackingService.sendEvent('switch:投稿作成|IG最初のコメント')

    const first_comment_message = is_message_comment ? '' : null

    if (is_message_comment && !this.instagram_comment_enabled) {
      const message =
        i18n.t('最初のコメントをONにすると、コメントを制限がOFFになります。') +
        '\n' +
        i18n.t('ONにしてよろしいですか?')

      if (!confirm(message)) return

      this.changeInstagramCommentEnabled(true)
    }

    this.changeInstagramFirstCommentMessage(first_comment_message)
  }

  /**
   * テンプレートの管理ダイアログを開く
   */
  openPostTemplateDialog() {
    TrackingService.sendEvent('click:投稿作成|投稿内容|テンプレートの管理')

    this.$refs.PostTemplateDialog.open()
  }
}
