import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import {
  DeleteTikTokInsightsWidgetSettingsResponse,
  GetTikTokInsightsAccountsParams,
  GetTikTokInsightsAccountsResponse,
  GetTikTokInsightsPostsParams,
  GetTikTokInsightsPostsResponse,
  GetTikTokInsightsTableSettingsParams,
  GetTikTokInsightsTableSettingsResponse,
  GetTikTokInsightsWidgetSettingsByIdResponse,
  GetTikTokInsightsWidgetSettingsParams,
  GetTikTokInsightsWidgetSettingsResponse,
  PostTikTokInsightsTableSettingParams,
  PostTikTokInsightsTableSettingResponse,
  PostTikTokInsightsWidgetSettingsLayoutsParams,
  PostTikTokInsightsWidgetSettingsLayoutsResponse,
  PostTikTokInsightsWidgetSettingsParams,
  PostTikTokInsightsWidgetSettingsResponse,
  PutTikTokInsightsWidgetSettingsParams,
  PutTikTokInsightsWidgetSettingsResponse,
  TikTokInsightsAccounts,
  TikTokInsightsPosts,
  TikTokInsightsTableSettings,
  TikTokInsightsWidgetSettings,
  TikTokPostType
} from '@/client/features/tiktok_analytics/api'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import storage from '@/client/utils/storage'

import * as m from './tiktok_analytics.mutations'

export interface IState {
  api_accounts: TikTokInsightsAccounts[]
  api_posts: TikTokInsightsPosts[]
  api_widget_settings: TikTokInsightsWidgetSettings[]
  api_table_settings: TikTokInsightsTableSettings[]
  account_id: string
  screen_name: 'summary' | 'post' | 'follower' | 'action' | 'reach' | 'video'
  search_type: TikTokPostType[]
  search_category: number[]
  search_message: string
  is_loading: boolean
  post_display_count: 10 | 25 | 50 | 100
}

const state: IState = {
  api_accounts: [],
  api_posts: [],
  api_widget_settings: [],
  api_table_settings: [],
  account_id: '',
  screen_name: 'summary',
  search_type: [],
  search_category: [],
  search_message: '',
  is_loading: false,
  post_display_count: 25
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  [m.SET_API_INSIGHTS](state, payload) {
    state.api_accounts = payload.accounts
    state.api_posts = payload.posts
  },
  [m.SET_API_WIDGET_SETTINGS](state, payload: TikTokInsightsWidgetSettings[]) {
    state.api_widget_settings = payload
  },
  [m.SET_API_TABLE_SETTING](state, payload: TikTokInsightsTableSettings[]) {
    state.api_table_settings = payload
  },
  [m.SET_PARAMS](state, payload) {
    state.account_id = payload
    storage.set('tiktok_analytics', { account_id: payload })
  },
  [m.SET_SCREEN](state, payload) {
    state.screen_name = payload
  },
  [m.SET_SEARCH_POST_TYPE](state, payload) {
    state.search_type = payload
  },
  [m.SET_SEARCH_CATEGORY](state, payload) {
    state.search_category = payload
  },
  [m.SET_SEARCH_MESSAGE](state, payload) {
    state.search_message = payload
  },
  [m.SET_LOADING](state, payload) {
    state.is_loading = payload
  },
  [m.SET_POST_DISPLAY_COUNT](state, payload: 10 | 25 | 50 | 100) {
    state.post_display_count = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * ページ表示時の処理
   */
  async createdTikTokAnalyticsPage(context, payload?: { account_id?: string }) {
    const account_ids = context.rootGetters['accounts/tiktok_analytics'].map(v => v.id)
    const local = storage.get('tiktok_analytics')

    let account_id = ''

    // ストレージに保存されていたら使用する
    if (local && local.account_id && account_ids.includes(local.account_id)) {
      account_id = local.account_id
    }

    // ストレージに無い場合はアカウント一覧の最初のアカウントをデフォルトにする
    if (account_id === '' && account_ids[0]) {
      account_id = account_ids[0]
    }

    if (payload && payload.account_id && account_ids.includes(payload.account_id)) {
      account_id = payload.account_id
    }

    await context.dispatch('getInsights', { account_id })
  },

  /**
   * ページ離脱時の処理
   */
  async destroyedTikTokAnalyticsPage(context) {
    context.commit(m.SET_API_INSIGHTS, { accounts: [], posts: [] })
    context.commit(m.SET_API_TABLE_SETTING, [])
    context.commit(m.SET_API_WIDGET_SETTINGS, [])
  },

  /**
   * グループ変更時の処理
   */
  async changeGroupTikTokAnalyticsPage(context) {
    await context.dispatch('destroyedTikTokAnalyticsPage')

    context.commit(m.SET_SEARCH_POST_TYPE, [])
    context.commit(m.SET_SEARCH_CATEGORY, [])
    context.commit(m.SET_SEARCH_MESSAGE, '')

    await context.dispatch('createdTikTokAnalyticsPage')
  },

  /**
   * 投稿の表示件数の変更
   */
  changePostDisplayCount(context, payload: number) {
    context.commit(m.SET_POST_DISPLAY_COUNT, payload)
  },

  /**
   * 分析データの取得
   */
  async getInsights(context, payload) {
    context.commit(m.SET_PARAMS, payload.account_id)

    if (context.state.account_id === '') return

    context.commit(m.SET_LOADING, true)

    const category = { target: 'tiktok', account_ids: [context.state.account_id] }

    await Promise.all([
      context.dispatch('categories/fetchCategoryAnalysisPost', category, { root: true }),
      context.dispatch('getWidgetSettings'),
      context.dispatch('getTableSetting')
    ])

    const params: GetTikTokInsightsAccountsParams | GetTikTokInsightsPostsParams = {
      account_id: context.state.account_id,
      start_date: context.rootState.analytics.start_date,
      end_date: context.rootState.analytics.end_date
    }

    const [accounts, posts] = await Promise.all([
      API.get<GetTikTokInsightsAccountsResponse>('tiktok_insights/accounts', { params }),
      API.get<GetTikTokInsightsPostsResponse>('tiktok_insights/posts', { params })
    ])

    context.commit(m.SET_API_INSIGHTS, {
      accounts: accounts.data.data,
      posts: posts.data.data
    })

    context.commit(m.SET_LOADING, false)
  },

  /**
   * スクリーン情報の取得
   */
  async getScreen(context, payload) {
    context.commit(m.SET_SCREEN, payload)
  },

  /**
   * テーブル設定の取得
   */
  async getTableSetting(context) {
    const params: GetTikTokInsightsTableSettingsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<GetTikTokInsightsTableSettingsResponse>(
      'tiktok_insights/table_settings',
      { params }
    )

    if (response.data?.data) {
      context.commit(m.SET_API_TABLE_SETTING, response.data.data)
    }

    return response
  },

  /**
   * テーブル設定の更新
   */
  async postTableSetting(context, payload: { columns: TikTokInsightsTableSettings[] }) {
    const params: PostTikTokInsightsTableSettingParams = {
      project_id: context.rootState.project.id,
      columns: payload.columns
    }

    const response = await API.post<PostTikTokInsightsTableSettingResponse>(
      'tiktok_insights/table_settings',
      params
    )

    if (response?.data?.data) {
      await context.dispatch('getTableSetting')
    }

    return response.data
  },

  /**
   * ウィジェットの取得
   */
  async getWidgetSettings(context) {
    const params: GetTikTokInsightsWidgetSettingsParams = {
      project_id: context.rootState.project.id,
      account_id: context.state.account_id
    }

    const response = await API.get<GetTikTokInsightsWidgetSettingsResponse>(
      'tiktok_insights/widget_settings',
      {
        params
      }
    )

    if (response.data?.data) {
      context.commit(m.SET_API_WIDGET_SETTINGS, response.data.data)
    }

    return response.data
  },

  /**
   * IDによるウィジェットの取得
   */
  async getWidgetSettingById(context, payload) {
    const response = await API.get<GetTikTokInsightsWidgetSettingsByIdResponse>(
      `tiktok_insights/widget_settings/${payload.id}`
    )

    if (!response?.data?.data?.id) {
      await context.dispatch('getWidgetSettings')
    }

    return response.data
  },

  /**
   * ウィジェットの作成
   */
  async postWidgetSettings(context, payload) {
    const params: PostTikTokInsightsWidgetSettingsParams = {
      project_id: context.rootState.project.id,
      account_id: context.state.account_id,
      type: payload.type,
      options: payload.options
    }

    const response = await API.post<PostTikTokInsightsWidgetSettingsResponse>(
      'tiktok_insights/widget_settings',
      params
    )

    if (response?.data?.data) {
      await context.dispatch('getWidgetSettings')
    }

    return response.data
  },

  /**
   * ウィジェットの更新
   */
  async putWidgetSettings(context, payload) {
    const params: PutTikTokInsightsWidgetSettingsParams = {
      type: payload.type,
      options: payload.options
    }

    const response = await API.put<PutTikTokInsightsWidgetSettingsResponse>(
      `tiktok_insights/widget_settings/${payload.id}`,
      params
    )

    if (response?.data?.data || response?.data?.error?.type === 'NOT_EXISTS') {
      await context.dispatch('getWidgetSettings')
    }

    return response.data
  },

  /**
   * ウィジェットのレイアウト更新
   */
  async postWidgetSettingsLayouts(context, payload) {
    const params: PostTikTokInsightsWidgetSettingsLayoutsParams = {
      layouts: payload.layouts,
      project_id: context.rootState.project.id
    }

    const response = await API.post<PostTikTokInsightsWidgetSettingsLayoutsResponse>(
      'tiktok_insights/widget_settings/layouts',
      params
    )

    if (response?.data?.data) {
      await context.dispatch('getWidgetSettings')
    }

    return response.data
  },

  /**
   * ウィジェットの削除
   */
  async deleteWidgetSettings(context, payload) {
    const response = await API.delete<DeleteTikTokInsightsWidgetSettingsResponse>(
      `tiktok_insights/widget_settings/${payload.id}`
    )

    if (response.data.data || response?.data?.error?.type === 'NOT_EXISTS') {
      await context.dispatch('getWidgetSettings')
    }

    return response.data
  },

  setSearchPostType(context, payload) {
    context.commit(m.SET_SEARCH_POST_TYPE, payload)
  },

  setSearchPostCategoryIds(context, payload) {
    context.commit(m.SET_SEARCH_CATEGORY, payload)
  },

  setSearchPostMessage(context, payload) {
    context.commit(m.SET_SEARCH_MESSAGE, payload)
  }
}

export const tiktok_analytics = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
