
import { computed, defineComponent, PropType, ref, watchEffect } from 'vue'
import { mapState } from 'vuex'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AccountSelect from '@/client/components-old/molecules/AccountSelect'
import { TrackingService } from '@/client/services'
import { ITwitterMonth, ITwitterPost, ITwitterTweet } from '@/client/utils/api/posts'
import { getTimeFormat as f, getTranslateText as t } from '@/client/utils/filters'
import * as regex from '@/client/utils/regex'

import { convertTextToEllipsis, getPostStatus } from './utils'

export default defineComponent({
  name: 'PostCreateTweetSelectFrom',
  components: {
    Button,
    Input,
    Panel,
    Select,
    Icon,
    SwitchButton,
    Tooltip,
    AccountSelect
  },
  props: {
    accountsOptions: {
      type: Array,
      default: () => []
    },
    months: {
      type: Array as PropType<ITwitterMonth[]>,
      default: () => []
    },
    posts: {
      type: Array as PropType<ITwitterPost[]>,
      default: () => []
    },
    targetTweet: {
      type: Object as PropType<ITwitterTweet>,
      default: null
    },
    errorMessage: {
      type: String,
      default: ''
    },
    mode: {
      type: String as PropType<'reply' | 'quote'>,
      required: true
    },
    targetMonth: {
      type: String,
      required: true
    },
    targetAccount: {
      type: String,
      required: true
    },
    targetPost: {
      type: [String, Number] as PropType<number | ''>,
      required: true
    },
    targetUrl: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    isShowUrlInput: {
      type: Boolean,
      required: true
    }
  },
  setup(props, context) {
    const is_panel_open = ref(false)

    watchEffect(() => {
      if (!!props.targetPost || !!props.targetTweet) {
        is_panel_open.value = true
      }
    })

    const is_add_button_disabled = computed(() => props.loading || invalid_quote_tweet_url.value)

    const is_reply = computed(() => props.mode === 'reply')

    const invalid_quote_tweet_url = computed(
      () => !regex.TWITTER_STATUS_URL_FORMAT.test(props.targetUrl)
    )

    const event_text = computed(() => (props.mode === 'reply' ? 'TW返信・スレッド' : 'TW引⽤'))

    function togglePanel() {
      is_panel_open.value = !is_panel_open.value

      // 閉じるときにデータ初期化のためemitする
      if (!is_panel_open.value) {
        context.emit('close-panel')
      }
    }

    return {
      is_panel_open,
      is_add_button_disabled,
      is_reply,
      event_text,
      togglePanel,
      t,
      f
    }
  },
  computed: {
    ...mapState(['user']),
    monthsOptions() {
      return this.months.map(v => {
        const format = this.user.language === 'ja' ? 'YYYY年M月' : 'YYYY-MM'
        const target_month = f(v.target_month, format)

        return {
          text: `${target_month}(${v.count})`,
          value: v.target_month
        }
      })
    },
    postOptions() {
      return this.posts.map(v => {
        const status = getPostStatus(v.status)

        const format = this.user.language === 'ja' ? 'MM月DD日 HH:mm' : 'MM/DD HH:mm'
        const action_datetime = f(v.action_datetime, format)

        const message = v.message ? convertTextToEllipsis(v.message) : t('投稿内容がありません')

        return {
          value: v.tw_post_id,
          text: `${status} - ${action_datetime} - ${message}`
        }
      })
    }
  },
  methods: {
    /**
     * URL入力を表示
     */
    showUrlInput() {
      TrackingService.sendEvent(`switch:投稿作成|${this.event_text}|投稿URLを⼊⼒`)

      this.$emit('change-form-mode')
    },

    /**
     * URLを追加
     */
    addUrl() {
      TrackingService.sendEvent(`click:投稿作成|${this.event_text}|投稿URL追加`)

      this.$emit('add-url')
    },

    /**
     * アカウントを選択
     */
    changeAccount(e: Event) {
      TrackingService.sendEvent(`click:投稿作成|${this.event_text}|アカウント選択`)

      this.$emit('update:target-account', e)
    },

    /**
     * 公開⽉を選択
     */
    changeTargetMonth(e: Event) {
      TrackingService.sendEvent(`click:投稿作成|${this.event_text}|公開⽉を選択`)

      this.$emit('update:target-post', e)
    },

    /**
     * 返信・スレッド追加先の投稿を選択
     */
    changeTargetPost(e: Event) {
      TrackingService.sendEvent(`click:投稿作成|${this.event_text}|追加先の投稿を選択`)

      this.$emit('update:target-post', e)
    }
  }
})
