
import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import { TIncidentEvent } from '@/client/utils/api/incident_events'

@Component({
  name: 'PostCreateIncidentAlert',
  components: {
    Icon
  }
})
export default class PostCreateIncidentAlert extends Vue {
  @Prop({ type: Object, required: true })
  event!: TIncidentEvent
}
