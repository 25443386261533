
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import PersonalSettingHeader from '@/client/components-old/organisms/PersonalSettingHeader'
import PersonalSettingDisplay from '@/client/components-old/templates/PersonalSettingDisplay'
import PersonalSettingNotification from '@/client/components-old/templates/PersonalSettingNotification'
import PersonalSettingRole from '@/client/components-old/templates/PersonalSettingRole'
import PersonalSettingUser from '@/client/components-old/templates/PersonalSettingUser'
import { IState as ISettingState } from '@/client/store/modules/personal_setting'

const personal_setting = namespace('personal_setting')

@Component({
  name: 'PersonalSetting',
  components: {
    PersonalSettingHeader,
    PersonalSettingUser,
    PersonalSettingDisplay,
    PersonalSettingNotification,
    PersonalSettingRole
  }
})
export default class PersonalSetting extends Vue {
  @personal_setting.State('api_user') api_user!: ISettingState['api_user']
  @personal_setting.State('screen_name') screen_name!: ISettingState['screen_name']
  @personal_setting.State('is_loading') is_loading!: ISettingState['is_loading']
  @personal_setting.Action('fetchInitialData') fetchInitialData!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  get is_user() {
    return this.screen_name === 'user'
  }

  get is_display() {
    return this.screen_name === 'display'
  }

  get is_notification() {
    return this.screen_name === 'notification'
  }

  get is_role() {
    return this.screen_name === 'role'
  }

  @Watch('group')
  async watchGroup() {
    await this.fetchInitialData()
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.fetchInitialData()
  }
}
