import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/instagram_analytics'
import * as calculation from '@/client/components-old/_utils/calculation'
import * as instagram_insight from '@/client/components-old/_utils/instagram_insight'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsNumbers from '@/client/components-old/molecules/AnalyticsNumbers'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import { IRootState } from '@/client/store/global'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as IInstagramState } from '@/client/store/modules/instagram_analytics'

type TInstagramAnalyticsTotalOptions = {
  data_type: 'daily_data' | 'post_data' | 'stories_data'
  metric: string
  unit: 'total' | 'average' | 'major'
  is_view_extra: boolean
  post_types?: string[]
  post_tags?: number[]
}

const instagram = namespace('instagram_analytics')
const categories = namespace('categories')

@Component({
  name: 'InstagramAnalyticsTotal',
  components: {
    AnalyticsPanel,
    AnalyticsPanelHeader,
    AnalyticsNumbers,
    Icon,
    Tooltip
  }
})
export default class InstagramAnalyticsTotal extends Vue {
  @State('user') user: IRootState['user']
  @categories.State('api_post_categories')
  api_post_categories: ICategoriesState['api_post_categories']
  @categories.State('api_category_posts') api_category_posts: ICategoriesState['api_category_posts']
  @instagram.State('api_accounts') daily_data: IInstagramState['api_accounts']
  @instagram.State('api_posts') post_data: IInstagramState['api_posts']
  @instagram.State('api_stories') stories_data: IInstagramState['api_stories']

  @Prop({ type: Object, required: true })
  options: TInstagramAnalyticsTotalOptions

  @Prop({ type: Boolean, default: false })
  isPreview: boolean

  @Prop({ type: Boolean, default: false })
  isEdit: boolean

  @Emit('edit')
  onEdit() {
    return this.options
  }

  @Emit('delete')
  onDelete(event) {
    return event
  }

  getPostTagNames(post_tag_ids?: number[]): string[] {
    if (post_tag_ids === undefined || !post_tag_ids.length) return []

    return (
      this.api_post_categories.filter(tag => post_tag_ids.includes(tag.id)).map(v => v.name) || []
    )
  }

  getSnsPostTags(post_id: string): number[] {
    const post_tag = this.api_category_posts.find(post_tag => post_tag.post_id === post_id)
    return post_tag ? post_tag.category_ids : []
  }

  get analytics(): any[] {
    const { data_type, post_types, post_tags } = this.options

    switch (data_type) {
      case 'daily_data':
        return instagram_insight.filterDailyData(
          this.daily_data,
          this.post_data,
          this.options.metric
        )
      case 'post_data': {
        let temp_insights = instagram_insight.filterPostData(this.post_data, this.options.metric)

        if (post_types?.length) {
          temp_insights = temp_insights.filter(post => post_types.includes(post.type))
        }
        if (post_tags?.length) {
          temp_insights = temp_insights.filter(post => {
            const post_tag_ids = this.getSnsPostTags(post.post_id)
            return post_tag_ids.some(post_tag_id => post_tags.includes(post_tag_id))
          })
        }

        return temp_insights
      }
      case 'stories_data': {
        let temp_insights = [...this.stories_data]

        if (post_types?.length) {
          temp_insights = temp_insights.filter(post => post_types.includes(post.type))
        }
        if (post_tags?.length) {
          temp_insights = temp_insights.filter(story => {
            const story_tag_ids = this.getSnsPostTags(story.stories_id)
            return story_tag_ids.some(story_tag_id => post_tags.includes(story_tag_id))
          })
        }

        return temp_insights
      }
      default:
        return []
    }
  }

  get is_data() {
    switch (this.options.metric) {
      case 'audience_gender_age':
      case 'audience_country':
      case 'audience_city':
      case 'online_followers':
        return this.analytics.some(v => Object.keys(v[this.options.metric]).length > 0)

      default:
        return this.analytics.length > 0
    }
  }

  get is_edit() {
    return !this.isPreview && this.isEdit
  }

  get show_post_types() {
    switch (this.options.data_type) {
      case 'post_data':
        return ['image', 'video', 'carousel', 'reels'].filter(v =>
          this.options.post_types.includes(v)
        )

      case 'stories_data':
        return ['image', 'video'].filter(v => this.options.post_types.includes(v))

      default:
        return []
    }
  }

  get post_tags_names() {
    if (this.options.data_type === 'daily_data') return []

    return this.getPostTagNames(this.options.post_tags)
  }

  get panel_type() {
    const data_type = constants.IN_DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get metric_name() {
    const metric = this.metrics.find(options => options.value === this.options.metric)
    const metric_name = metric ? this.$options.filters.translate(metric.text) : ''

    const unit = constants.UNITS.find(unit => unit.value === this.options.unit)
    const unit_name = unit ? this.$options.filters.translate(unit.text) : ''

    if (this.options.unit === 'major') {
      return metric_name
    }

    switch (this.options.metric) {
      case 'followers_count':
      case 'followers_count_up_down':
      case 'follows_count':
      case 'follows_count_up_down':
        return metric_name
      default:
        return `${metric_name} [${unit_name}]`
    }
  }

  get metrics() {
    switch (this.options.data_type) {
      case 'daily_data':
        return constants.TOTAL_DAILY_DATA_METRICS
      case 'post_data':
        return constants.TOTAL_POST_DATA_METRICS
      case 'stories_data':
        return constants.TOTAL_STORIES_DATA_METRICS
      default:
        return []
    }
  }

  get metric_tooltip() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? metric.tooltip : ''
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }

  get result() {
    let result

    switch (this.options.unit) {
      case 'total':
        result = instagram_insight.getMetricTotal(this.analytics, this.options.metric)
        return instagram_insight.convertValueWithMetric(result, this.options.metric)
      case 'average':
        result = instagram_insight.getMetricAverage(this.analytics, this.options.metric)
        return instagram_insight.convertValueWithMetric(result, this.options.metric)
      case 'major':
        return this.getMajorKeyView(this.extras_major.key)
      default:
        return instagram_insight.convertValueWithMetric(result, this.options.metric)
    }
  }

  get extras() {
    if (!this.options.is_view_extra) {
      return []
    }

    switch (this.options.unit) {
      case 'total':
        return [this.extras_max, this.extras_min, this.extras_average]
      case 'average':
        return [this.extras_max, this.extras_min]
      case 'major':
        return [this.extras_total, this.extras_rate]
    }
  }

  get extras_major() {
    // 直近でデータが存在する日を対象とする
    const metric_data = instagram_insight
      .getMetricArray(this.analytics, this.options.metric)
      .find(v => Object.keys(v).length > 0)

    if (!metric_data) {
      return { key: '', value: 0 }
    }

    const filter = (key: string) => {
      switch (this.options.metric) {
        // 主な性別・年代の場合、undefined性別を解除
        case 'audience_gender_age':
          return key.match('U') ? false : true

        // 主な都道府県の場合、対象を日本のみに限定する
        case 'audience_city': {
          const cities = Highcharts.maps['countries/jp/jp-all'].features
          const city = cities.map(f => f.properties).find(p => p['name'] === key)
          return city ? true : false
        }
      }

      return true
    }

    const value_list = Object.keys(metric_data)
      .filter(key => filter(key))
      .map(key => metric_data[key])

    const max_value = Math.max.apply(null, value_list)

    const max_key = Object.keys(metric_data)
      .filter(key => filter(key))
      .find(key => metric_data[key] === max_value)

    return { key: max_key, value: max_value }
  }

  get extras_total() {
    return {
      title: '合計',
      value: this.$options.filters.number(this.extras_major.value)
    }
  }

  get extras_rate() {
    const followers_count = instagram_insight.getMetricTotal(this.analytics, 'followers_count')

    return {
      title: '率',
      value: this.$options.filters.percent(
        calculation.percentage(this.extras_major.value, followers_count)
      )
    }
  }

  get extras_max() {
    const metric_array = instagram_insight.getMetricArray<number>(
      this.analytics,
      this.options.metric
    )
    const max = metric_array.length ? Math.max.apply(null, metric_array) : 0

    return {
      title: '最大',
      value: instagram_insight.convertValueWithMetric(max, this.options.metric)
    }
  }

  get extras_min() {
    const metric_array = instagram_insight.getMetricArray<number>(
      this.analytics,
      this.options.metric
    )
    const min = metric_array.length ? Math.min.apply(null, metric_array) : 0

    return {
      title: '最小',
      value: instagram_insight.convertValueWithMetric(min, this.options.metric)
    }
  }

  get extras_average() {
    let title

    switch (this.options.data_type) {
      case 'daily_data':
        title = '1日平均'
        break
      case 'post_data':
      case 'stories_data':
        title = '1投稿あたり'
        break
      default:
        title = ''
    }

    return {
      title,
      value: instagram_insight.convertValueWithMetric(
        instagram_insight.getMetricAverage(this.analytics, this.options.metric),
        this.options.metric
      )
    }
  }

  /**
   * 主なキーの表示をセット
   * @param {string} key 主なキー
   * @returns {string} 主なキー
   */
  getMajorKeyView(key: string): string {
    switch (this.options.metric) {
      case 'audience_gender_age': {
        const gender_age_text = {
          M: ['歳', '男性'].map(value => this.$options.filters.translate(value)).join(' '),
          F: ['歳', '女性'].map(value => this.$options.filters.translate(value)).join(' ')
        }
        return key.slice(2) + gender_age_text[key.slice(0, 1)]
      }

      case 'audience_country': {
        const countries = Highcharts.maps['custom/world'].features
        const country = countries.map(f => f.properties).find(p => p['hc-a2'] === key)

        if (this.user.language === 'ja') {
          return country ? country['jp-name'] : ''
        } else {
          return country ? country['name'] : ''
        }
      }

      case 'audience_city': {
        const cities = Highcharts.maps['countries/jp/jp-all'].features
        const city = cities.map(f => f.properties).find(p => p['name'] === key)

        if (this.user.language === 'ja') {
          return city ? city['jp-name'] : ''
        } else {
          return city ? city['name'] : ''
        }
      }

      case 'online_followers':
        return key + ':00'

      default:
        return key
    }
  }
}
