import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  IDeleteFacebookInsightsWidgetSettingsResponse,
  IFacebookInsightsAccounts,
  IFacebookInsightsPosts,
  IFacebookInsightsTableSettings,
  IFacebookInsightsWidgetSettings,
  IGetFacebookInsightsAccountsParams,
  IGetFacebookInsightsAccountsResponse,
  IGetFacebookInsightsPostsParams,
  IGetFacebookInsightsPostsResponse,
  IGetFacebookInsightsTableSettingsParams,
  IGetFacebookInsightsTableSettingsResponse,
  IGetFacebookInsightsWidgetSettingsByIdResponse,
  IGetFacebookInsightsWidgetSettingsParams,
  IGetFacebookInsightsWidgetSettingsResponse,
  IPostFacebookInsightsWidgetSettingsLayoutsParams,
  IPostFacebookInsightsWidgetSettingsLayoutsResponse,
  IPostFacebookInsightsWidgetSettingsParams,
  IPostFacebookInsightsWidgetSettingsResponse,
  IPutFacebookInsightsWidgetSettingsParams,
  IPutFacebookInsightsWidgetSettingsResponse
} from '@/client/utils/api/facebook_insights'
import storage from '@/client/utils/storage'

export interface IState {
  api_accounts: IFacebookInsightsAccounts[]
  api_posts: IFacebookInsightsPosts[]
  api_widget_settings: IFacebookInsightsWidgetSettings[]
  api_table_setting: IFacebookInsightsTableSettings
  account_id: string
  screen_name: 'summary' | 'post' | 'fan' | 'action' | 'reach' | 'video'
  search_type: string[]
  search_category: number[]
  search_message: string
  is_loading: boolean
  post_display_count: 10 | 25 | 50 | 100
}

const state: IState = {
  api_accounts: [],
  api_posts: [],
  api_widget_settings: [],
  api_table_setting: { id: 0, columns: [] },
  account_id: '',
  screen_name: 'summary',
  search_type: [],
  search_category: [],
  search_message: '',
  is_loading: false,
  post_display_count: 25
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  SET_API_INSIGHTS(state, payload) {
    state.api_accounts = payload.accounts
    state.api_posts = payload.posts
  },
  SET_API_TABLE_SETTING(state, payload: IGetFacebookInsightsTableSettingsResponse) {
    state.api_table_setting = payload.data
  },
  SET_API_WIDGET_SETTINGS(state, payload: IGetFacebookInsightsWidgetSettingsResponse) {
    state.api_widget_settings = payload.data
  },
  SET_PARAMS(state, payload) {
    state.account_id = payload
    storage.set('facebook_analytics', { account_id: payload })
  },
  SET_SCREEN(state, payload) {
    state.screen_name = payload
  },
  SET_SEARCH_TYPE(state, payload) {
    state.search_type = payload
  },
  SET_SEARCH_CATEGORY(state, payload) {
    state.search_category = payload
  },
  SET_SEARCH_MESSAGE(state, payload) {
    state.search_message = payload
  },
  SET_LOADING(state, payload) {
    state.is_loading = payload
  },
  SET_POST_DISPLAY_COUNT(state, payload: 10 | 25 | 50 | 100) {
    state.post_display_count = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * ページ表示時の処理
   */
  async createdFacebookAnalyticsPage(context, payload?: { account_id?: string }) {
    const api: string[] = context.rootGetters['accounts/facebook_analytics'].map(v => v.id)
    const local: { account_id?: string } = storage.get('facebook_analytics')

    let account_id = ''

    // ストレージに保存されていたら使用する
    if (local && local.account_id && api.includes(local.account_id)) {
      account_id = local.account_id
    }

    // ストレージに無い場合はアカウント一覧の最初のアカウントをデフォルトにする
    if (account_id === '' && api[0]) {
      account_id = api[0]
    }

    if (payload && payload.account_id && api.includes(payload.account_id)) {
      account_id = payload.account_id
    }

    await context.dispatch('getInsights', { account_id })
  },

  /**
   * ページ離脱時の処理
   */
  async destroyedFacebookAnalyticsPage(context) {
    context.commit('SET_API_INSIGHTS', { accounts: [], posts: [] })
    context.commit('SET_API_TABLE_SETTING', { data: { id: 0, columns: [] } })
    context.commit('SET_API_WIDGET_SETTINGS', { data: [] })
  },

  /**
   * グループ変更時の処理
   */
  async changeGroupFacebookAnalyticsPage(context) {
    await context.dispatch('destroyedFacebookAnalyticsPage')

    context.commit('SET_SEARCH_TYPE', [])
    context.commit('SET_SEARCH_CATEGORY', [])
    context.commit('SET_SEARCH_MESSAGE', '')

    await context.dispatch('createdFacebookAnalyticsPage')
  },

  /**
   * 投稿の表示件数の変更
   */
  changePostDisplayCount(context, payload: number) {
    context.commit('SET_POST_DISPLAY_COUNT', payload)
  },

  /**
   * 分析データの取得
   */
  async getInsights(context, payload) {
    context.commit('SET_PARAMS', payload.account_id)

    if (context.state.account_id === '') return

    context.commit('SET_LOADING', true)

    const category = { target: 'facebook', account_ids: [context.state.account_id] }

    await Promise.all([
      context.dispatch('categories/fetchCategoryAnalysisPost', category, { root: true }),
      context.dispatch('getWidgetSettings'),
      context.dispatch('getTableSetting')
    ])

    const params: IGetFacebookInsightsAccountsParams | IGetFacebookInsightsPostsParams = {
      account_id: context.state.account_id,
      start_date: context.rootState.analytics.start_date,
      end_date: context.rootState.analytics.end_date,
      project_id: context.rootState.project.id
    }

    const [accounts, posts] = await Promise.all([
      API.get<IGetFacebookInsightsAccountsResponse>('facebook_insights/accounts', { params }),
      API.get<IGetFacebookInsightsPostsResponse>('facebook_insights/posts', {
        params
      })
    ])

    context.commit('SET_API_INSIGHTS', {
      accounts: accounts.data.data,
      posts: posts.data.data
    })

    context.commit('SET_LOADING', false)
  },

  /**
   * スクリーン情報の取得
   */
  async getScreen(context, payload) {
    context.commit('SET_SCREEN', payload)
  },

  /**
   * テーブル設定の取得
   */
  async getTableSetting(context) {
    const params: IGetFacebookInsightsTableSettingsParams = {
      project_id: context.rootState.project.id
    }

    const { data } = await API.get<IGetFacebookInsightsTableSettingsResponse>(
      'facebook_insights/table_settings',
      { params }
    )

    context.commit('SET_API_TABLE_SETTING', data)
  },

  /**
   * テーブル設定の更新
   */
  async putTableSetting(context, payload) {
    const params = {
      id: context.state.api_table_setting.id,
      columns: payload.columns
    }

    const { data } = await API.put('facebook_insights/table_settings', params)

    // 更新に成功した場合
    if (data && data.data) {
      await context.dispatch('getTableSetting')
    }

    return data
  },

  /**
   * ウィジェットの取得
   */
  async getWidgetSettings(context) {
    const params: IGetFacebookInsightsWidgetSettingsParams = {
      project_id: context.rootState.project.id,
      account_id: context.state.account_id
    }

    const { data } = await API.get<IGetFacebookInsightsWidgetSettingsResponse>(
      'facebook_insights/widget_settings',
      { params }
    )

    context.commit('SET_API_WIDGET_SETTINGS', data)
  },

  /**
   * IDによるウィジェットの取得
   */
  async getWidgetSettingById(context, payload) {
    const { data } = await API.get<IGetFacebookInsightsWidgetSettingsByIdResponse>(
      `facebook_insights/widget_settings/${payload.id}`
    )

    // 取得に失敗した場合
    if (!data.data || !data.data.id) {
      await context.dispatch('getWidgetSettings')
    }

    return data
  },

  /**
   * ウィジェットの作成
   */
  async postWidgetSettings(context, payload) {
    const params: IPostFacebookInsightsWidgetSettingsParams = {
      project_id: context.rootState.project.id,
      account_id: context.state.account_id,
      type: payload.type,
      options: payload.options
    }

    const { data } = await API.post<IPostFacebookInsightsWidgetSettingsResponse>(
      'facebook_insights/widget_settings',
      params
    )

    // 作成に成功した場合
    if (data && data.data) {
      await context.dispatch('getWidgetSettings')
    }

    return data
  },

  /**
   * ウィジェットの更新
   */
  async putWidgetSettings(context, payload) {
    const params: IPutFacebookInsightsWidgetSettingsParams = {
      type: payload.type,
      options: payload.options
    }

    const { data } = await API.put<IPutFacebookInsightsWidgetSettingsResponse>(
      `facebook_insights/widget_settings/${payload.id}`,
      params
    )

    // 更新に成功した場合、またはすでに削除されている場合
    if ((data && data.data) || (data.error && data.error.type === 'NOT_EXISTS')) {
      await context.dispatch('getWidgetSettings')
    }

    return data
  },

  /**
   * ウィジェットのレイアウト更新
   */
  async putWidgetSettingsLayouts(context, payload) {
    const params: IPostFacebookInsightsWidgetSettingsLayoutsParams = {
      project_id: context.rootState.project.id,
      layouts: payload.layouts
    }

    const { data } = await API.post<IPostFacebookInsightsWidgetSettingsLayoutsResponse>(
      'facebook_insights/widget_settings/layouts',
      params
    )

    // レイアウト更新に成功した場合
    if (data && data.data) {
      await context.dispatch('getWidgetSettings')
    }

    return data
  },

  /**
   * ウィジェットの削除
   */
  async deleteWidgetSettings(context, payload) {
    const { data } = await API.delete<IDeleteFacebookInsightsWidgetSettingsResponse>(
      `facebook_insights/widget_settings/${payload.id}`
    )

    // 削除に成功した場合、またはすでに削除されている場合
    if ((data && data.data) || (data.error && data.error.type === 'NOT_EXISTS')) {
      await context.dispatch('getWidgetSettings')
    }

    return data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
