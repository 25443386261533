
import { Component, Prop, Vue } from 'vue-property-decorator'

type TMessageType = 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'inactive'

@Component({
  name: 'Message'
})
export default class Message extends Vue {
  @Prop({ type: String, default: 'default' })
  type!: TMessageType
}
