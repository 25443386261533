import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Scroll from '@/client/components-old/atoms/Scroll'
import InstagramHashtagDialog from '@/client/components-old/organisms/InstagramHashtagDialog'
import InstagramHashtagManagement from '@/client/components-old/organisms/InstagramHashtagManagement'
import { IState as IInstagramHashtagState } from '@/client/store/modules/instagram_hashtag'

const instagram_hashtag = namespace('instagram_hashtag')
@Component({
  name: 'InstagramHashtagManagementLayout',
  components: {
    Scroll,
    InstagramHashtagDialog,
    InstagramHashtagManagement
  }
})
export default class InstagramHashtagManagementLayout extends Vue {
  @instagram_hashtag.State('api_settings') api_settings: IInstagramHashtagState['api_settings']

  $refs: {
    InstagramHashtagDialog: any
  }

  /**
   * ハッシュタグ作成ダイアログを開く
   */
  async openAddHashtag(): Promise<void> {
    await this.$refs.InstagramHashtagDialog.open()
  }

  /**
   * ハッシュタグ編集ダイアログを開く
   */
  async openEditHashtag(event: any): Promise<void> {
    await this.$refs.InstagramHashtagDialog.open(event)
  }
}
