import moment from 'moment-timezone'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import PostApprovalRequestDialog from '@/client/components-old/organisms/PostApprovalRequestDialog'
import { TrackingService } from '@/client/services'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import {
  IGetter as IPostCreateGetter,
  IState as IPostCreateState
} from '@/client/store/modules/post_create'
import i18n from '@/client/utils/i18n'
import { convertHelpMessage } from '@/client/utils/notification'

type TMemoData = {
  message: string
  users: number[]
} | null

const accounts = namespace('accounts')
const post_create = namespace('post_create')
const post_management = namespace('post_management')
const notification = namespace('notification')

@Component({
  name: 'PostCreateSubmit',
  components: {
    Button,
    Icon,
    Flex,
    Tooltip,
    PostApprovalRequestDialog
  }
})
export default class PostCreateSubmit extends Vue {
  @accounts.Action('fetchSnsAccounts') fetchSnsAccounts!: any
  @accounts.Getter('post') sns_accounts!: IAccountsGetter['post']
  @post_create.State('api_post') api_post!: IPostCreateState['api_post']
  @post_create.State('api_post_setting') api_post_setting!: IPostCreateState['api_post_setting']
  @post_create.State('images') images!: IPostCreateState['images']
  @post_create.State('videos') videos!: IPostCreateState['videos']
  @post_create.State('facebook_links') facebook_links!: IPostCreateState['facebook_links']
  @post_create.State('type') type!: IPostCreateState['type']
  @post_create.State('accounts') accounts!: IPostCreateState['accounts']
  @post_create.State('scheduled_datetime')
  scheduled_datetime!: IPostCreateState['scheduled_datetime']
  @post_create.State('page_type') page_type!: IPostCreateState['page_type']
  @post_create.State('is_auto_publish')
  is_auto_publish!: IPostCreateState['is_auto_publish']
  @post_create.State('project_post_approval_flow_id')
  project_post_approval_flow_id!: IPostCreateState['project_post_approval_flow_id']
  @post_create.Getter('disabled_draft') disabled_draft!: IPostCreateGetter['disabled_draft']
  @post_create.Getter('disabled_approve_request')
  disabled_approve_request!: IPostCreateGetter['disabled_approve_request']
  @post_create.Getter('disabled_schedule')
  disabled_schedule!: IPostCreateGetter['disabled_schedule']
  @post_create.Getter('disabled_publish') disabled_publish!: IPostCreateGetter['disabled_publish']
  @post_create.Getter('use_instagram') use_instagram!: IPostCreateGetter['use_instagram']
  @post_create.Mutation('SET_IS_LOADING') setLoading!: any
  @post_create.Action('getPageType') getPageType!: any
  @post_create.Action('saveSnsPost') saveSnsPost!: any
  @post_create.Action('publishSnsPost') publishSnsPost!: any
  @post_management.Action('submitErrorForPostCreate') submitErrorForPostCreate!: any
  @notification.Action('showNotification') showNotification!: any

  $refs!: {
    approval_request_dialog: any
  }

  get reset_approval() {
    const status = this.api_post?.status
    const current_step = this.api_post?.post_approval_flow?.current_step ?? 0

    return this.page_type === 'edit' && status === 'approval' && current_step > 1
  }

  get reset_schedule() {
    return this.page_type === 'edit' && this.api_post && this.api_post.status === 'scheduled'
  }

  get errorFormatMediaMessage() {
    const type = this.type === 'video' ? '動画' : '画像'

    const help_url =
      this.type === 'video'
        ? 'https://help-cms.comnico.jp/post-video'
        : 'https://help-cms.comnico.jp/post-image'

    return (
      i18n.t(`${type}形式に問題があります。こちらの`) +
      ' ' +
      `<a href="${help_url}" target="_blank">` +
      i18n.t('ヘルプ') +
      '</a>' +
      ' ' +
      i18n.t(`を参考に最適化を行ってください。`)
    )
  }

  get is_admin() {
    return this.sns_accounts
      .filter(v => this.accounts.some(account => account.id === v.id && account.sns === v.sns))
      .map(v => v.role)
      .every(role => role === 'admin')
  }

  get is_publish() {
    return !this.scheduled_datetime && this.is_admin
  }

  get is_reservation() {
    return this.scheduled_datetime && this.is_admin
  }

  get is_tiktok() {
    return this.accounts.some(v => v.sns === 'tiktok')
  }

  /**
   * 下書き投稿を行う
   */
  async draftPost(): Promise<void> {
    TrackingService.sendEvent('click:投稿作成|画面内ヘッダー|下書き')

    if (this.reset_approval) {
      const message =
        i18n.t('承認申請のステータスはリセットされます。') +
        '\n' +
        i18n.t('投稿を下書き保存してよろしいですか？')

      const confirm = window.confirm(message)

      if (!confirm) return
    }

    if (this.reset_schedule) {
      const message =
        i18n.t('予約投稿はリセットされます。') +
        '\n' +
        i18n.t('投稿を下書き保存してよろしいですか？')

      const confirm = window.confirm(message)

      if (!confirm) return
    }

    this.setLoading(true)

    const history = {
      type: this.page_type === 'edit' ? 'post_update' : 'post_create',
      is_edit_media: this.isEditMedia()
    }

    const response = await this.saveSnsPost({ submit_type: 'draft', memo: null, history })

    this.setLoading(false)

    if (response.data) {
      this.showNotification({ title: '投稿を保存しました。' })

      // 編集画面の場合、投稿情報が古いので再取得する、それ以外の画面の場合は編集画面に移動する
      if (this.page_type === 'edit') {
        await this.submitErrorForPostCreate(response.data.id)
      } else {
        await this.getPageType('edit')
        this.changeRoute({
          name: 'posts/detail/edit',
          params: { sns_post_id: response.data.id }
        })
      }

      return
    }

    if (response.error && response.error.type === 'MAX_CATEGORY_OVER') {
      return this.showNotification({
        title: 'タグを10個以下に設定してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'MAX_PERSON_OVER') {
      return this.showNotification({
        title: '担当者を3人以下に設定してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'POST_LINK_INVALID') {
      return this.showNotification({
        title: 'Facebook・InstagramへのリンクURLを含められません。',
        message: 'Facebookリンク情報のリンク先URLを変更してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'NOT_EXISTS') {
      return this.showNotification({
        title: '対象の投稿が削除されています。',
        message: 'ブラウザを再読み込みしてください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'POST_PUBLISHED') {
      this.showNotification({
        title: '該当の投稿はすでに公開されています。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    if (response.error && response.error.type === 'POST_RESERVED') {
      this.showNotification({
        title: '該当の投稿はすでに予約されています。',
        message: '編集する場合は、先に予約取消をしてください。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    if (response.error && response.error.type === 'POST_APPROVED') {
      this.showNotification({
        title: '該当の投稿はすでに承認されているため、変更できません。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    if (response.error && response.error.type === 'APPROVAL_PERMISSION_DENIED') {
      this.showNotification({
        title: '該当の投稿はすでに承認申請されています。',
        message: '編集する場合は、先に申請取消をしてください。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    this.showNotification({
      title: '投稿の保存に失敗しました。',
      message: '通信エラーが発生しました。恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * 承認申請ボタンクリック
   */
  approvePost(): void {
    TrackingService.sendEvent('click:投稿作成|画面内ヘッダー|承認申請')

    this.$refs.approval_request_dialog.open()
  }

  /**
   * 承認申請を行う
   */
  async approveRequestPost(memo: TMemoData): Promise<void> {
    this.setLoading(true)

    const history = {
      type: 'post_approve',
      is_edit_media: this.isEditMedia()
    }

    const response = await this.saveSnsPost({ submit_type: 'approve_request', memo, history })

    this.setLoading(false)

    if (response.data) {
      this.showNotification({ title: '投稿の承認を申請しました。' })

      const query: any = {}

      if (this.scheduled_datetime) {
        query.scheduled_datetime = moment(this.scheduled_datetime).format()
      }

      this.changeRoute({ name: 'posts', query })
      return
    }

    if (response.error && response.error.type === 'MEDIA_UPLOAD_FAILED') {
      return this.showNotification({
        title: '承認申請に失敗しました',
        message: convertHelpMessage({
          message: `${this.type}形式に問題があります。ヘルプを参考に最適化を行ってください。`,
          help_color: 'warning',
          help_type: this.type === 'video' ? 'POST_VIDEO' : 'POST_IMAGE'
        }),
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'MAX_CATEGORY_OVER') {
      return this.showNotification({
        title: 'タグを10個以下に設定してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'MAX_PERSON_OVER') {
      return this.showNotification({
        title: '担当者を3人以下に設定してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'POST_LINK_INVALID') {
      return this.showNotification({
        title: 'Facebook・InstagramへのリンクURLを含められません。',
        message: 'Facebookリンク情報のリンク先URLを変更してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'POST_TIME_DUPLICATED') {
      return this.showNotification({
        title: '同時刻に投稿予約をしています。',
        message: '公開日時を変更してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'NOT_EXISTS') {
      return this.showNotification({
        title: '対象の投稿が削除されています。',
        message: 'ブラウザを再読み込みしてください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'POST_PUBLISHED') {
      this.showNotification({
        title: '該当の投稿はすでに公開されています。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    if (response.error && response.error.type === 'POST_RESERVED') {
      this.showNotification({
        title: '該当の投稿はすでに予約されています。',
        message: '編集する場合は、先に予約取消をしてください。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    if (response.error && response.error.type === 'POST_APPROVED') {
      this.showNotification({
        title: '該当の投稿はすでに承認されているため、変更できません。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    if (response.error && response.error.type === 'APPROVAL_PERMISSION_DENIED') {
      this.showNotification({
        title: '該当の投稿はすでに承認申請されています。',
        message: '編集する場合は、先に申請取消をしてください。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    this.showNotification({
      title: '承認申請に失敗しました',
      message: '通信エラーが発生しました。恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * 予約投稿を行う
   */
  async schedulePost(): Promise<void> {
    TrackingService.sendEvent('click:投稿作成|画面内ヘッダー|投稿予約')

    if (this.reset_approval) {
      const message =
        i18n.t('承認申請のステータスはリセットされます。') +
        '\n' +
        i18n.t('投稿を予約してよろしいですか？')

      const confirm = window.confirm(message)

      if (!confirm) return
    }

    this.setLoading(true)

    const history = {
      type: 'post_scheduled',
      is_edit_media: this.isEditMedia()
    }
    const response = await this.saveSnsPost({ submit_type: 'schedule', memo: null, history })

    this.setLoading(false)

    if (response.data) {
      this.showNotification({ title: '投稿を予約しました。' })

      const query: any = {}

      if (this.scheduled_datetime) {
        query.scheduled_datetime = moment(this.scheduled_datetime).format()
      }

      this.changeRoute({ name: 'posts', query })
      return
    }

    if (response.error && response.error.type === 'MEDIA_UPLOAD_FAILED') {
      return this.showNotification({
        title: '投稿の予約に失敗しました',
        message: this.errorFormatMediaMessage,
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'MAX_CATEGORY_OVER') {
      return this.showNotification({
        title: 'タグを10個以下に設定してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'MAX_PERSON_OVER') {
      return this.showNotification({
        title: '担当者を3人以下に設定してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'POST_LINK_INVALID') {
      return this.showNotification({
        title: 'Facebook・InstagramへのリンクURLを含められません。',
        message: 'Facebookリンク情報のリンク先URLを変更してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'POST_TIME_DUPLICATED') {
      return this.showNotification({
        title: '同時刻に投稿予約をしています。',
        message: '公開日時を変更してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'NOT_EXISTS') {
      return this.showNotification({
        title: '対象の投稿が削除されています。',
        message: 'ブラウザを再読み込みしてください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'POST_PUBLISHED') {
      this.showNotification({
        title: '該当の投稿はすでに公開されています。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    if (response.error && response.error.type === 'POST_RESERVED') {
      this.showNotification({
        title: '該当の投稿はすでに予約されています。',
        message: '編集する場合は、先に予約取消をしてください。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    if (response.error && response.error.type === 'POST_APPROVED') {
      this.showNotification({
        title: '該当の投稿はすでに承認されているため、変更できません。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    if (response.error && response.error.type === 'APPROVAL_PERMISSION_DENIED') {
      this.showNotification({
        title: '該当の投稿はすでに承認申請されています。',
        message: '編集する場合は、先に申請取消をしてください。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    this.showNotification({
      title: '投稿の予約に失敗しました',
      message: '通信エラーが発生しました。恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * 即時投稿を行う
   */
  async publishPost(): Promise<void> {
    TrackingService.sendEvent('click:投稿作成|画面内ヘッダー|今すぐ投稿')

    if (this.reset_approval) {
      const message =
        i18n.t('承認申請のステータスはリセットされます。') +
        '\n' +
        i18n.t('投稿を公開してよろしいですか？')

      const confirm = window.confirm(message)

      if (!confirm) return
    }

    if (this.reset_schedule) {
      const message =
        i18n.t('予約投稿はリセットされます。') + '\n' + i18n.t('投稿を公開してよろしいですか？')

      const confirm = window.confirm(message)

      if (!confirm) return
    }

    this.setLoading(true)

    const is_edit_media = this.isEditMedia()
    let history = null

    if (!this.is_auto_publish) {
      history = {
        type: 'post_scheduled',
        is_edit_media
      }
    }

    const response = await this.saveSnsPost({ submit_type: 'publish', memo: null, history })

    if (response.data) {
      if (!this.is_auto_publish) {
        this.setLoading(false)

        this.changeRoute({
          name: 'posts',
          query: {
            sns_post_id: response.data.id,
            mode: 'release_procedure'
          }
        })
        return
      }

      const publish_response = await this.publishSnsPost({ id: response.data.id, is_edit_media })

      this.setLoading(false)

      if (publish_response.data) {
        // ? TikTok投稿のみ外部APIの仕様で公開指示までしか出せないため、公開時のメッセージを変更する
        if (this.is_tiktok) {
          this.showNotification({
            title: '投稿を公開します。',
            message: 'TikTokの投稿は約1分ほど公開処理に時間がかかります。'
          })
        } else {
          this.showNotification({ title: '投稿の公開が完了しました。' })
        }

        this.changeRoute({ name: 'posts' })
        return
      }

      if (publish_response.error) {
        if (publish_response.error.type === 'MEDIA_UPLOAD_FAILED') {
          this.showNotification({
            title: '投稿の公開に失敗しました',
            message: this.errorFormatMediaMessage,
            type: 'error'
          })
        } else if (publish_response.error.type === 'TOKEN_EXPIRED') {
          this.showNotification({
            title: 'アクセストークンが失効しています。',
            message: convertHelpMessage({
              message: 'アクセストークンを更新してください。',
              help_color: 'warning',
              help_type: 'ABOUT_ACCESS_TOKEN'
            }),
            type: 'error'
          })

          await this.fetchSnsAccounts()

          this.changeRoute({ name: 'posts' })

          return
        } else {
          this.showNotification({ ...publish_response.error, type: 'error' })
        }

        if (publish_response.status === 'ALL_ACCOUNT_FAILED') {
          // 編集画面の場合、投稿情報が古いので再取得する、それ以外の画面の場合は編集画面に移動する
          if (this.page_type === 'edit') {
            await this.submitErrorForPostCreate(response.data.id)
          } else {
            await this.getPageType('edit')
            this.changeRoute({
              name: 'posts/detail/edit',
              params: { sns_post_id: response.data.id }
            })
          }
        } else {
          this.changeRoute({ name: 'posts' })
        }

        return
      }

      return this.showNotification({ title: '投稿の公開に失敗しました。', type: 'error' })
    }

    this.setLoading(false)

    if (response.error && response.error.type === 'MAX_CATEGORY_OVER') {
      return this.showNotification({
        title: 'タグを10個以下に設定してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'MAX_PERSON_OVER') {
      return this.showNotification({
        title: '担当者を3人以下に設定してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'POST_LINK_INVALID') {
      return this.showNotification({
        title: 'Facebook・InstagramへのリンクURLを含められません。',
        message: 'Facebookリンク情報のリンク先URLを変更してください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'NOT_EXISTS') {
      return this.showNotification({
        title: '対象の投稿が削除されています。',
        message: 'ブラウザを再読み込みしてください。',
        type: 'error'
      })
    }

    if (response.error && response.error.type === 'POST_PUBLISHED') {
      this.showNotification({
        title: '該当の投稿はすでに公開されています。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    if (response.error && response.error.type === 'POST_RESERVED') {
      this.showNotification({
        title: '該当の投稿はすでに予約されています。',
        message: '編集する場合は、先に予約取消をしてください。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    if (response.error && response.error.type === 'POST_APPROVED') {
      this.showNotification({
        title: '該当の投稿はすでに承認されているため、変更できません。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    if (response.error && response.error.type === 'APPROVAL_PERMISSION_DENIED') {
      this.showNotification({
        title: '該当の投稿はすでに承認申請されています。',
        message: '編集する場合は、先に申請取消をしてください。',
        type: 'error'
      })

      this.changeRoute({ name: 'posts' })

      return
    }

    this.showNotification({
      title: '投稿の公開に失敗しました',
      message: '通信エラーが発生しました。恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * メディア変更フラグセット
   */
  isEditMedia() {
    if (this.page_type !== 'edit' || !this.api_post) {
      return false
    }

    const image_urls_old = this.api_post.post_images.map(v => v.image_url)
    const images_urls_new = this.images.map(v => v.image_url)

    const video_urls_old = this.api_post.post_videos.map(v => v.video_url)
    const video_urls_new = this.videos.map(v => v.video_url)

    const video_thumbnails_old = this.api_post.post_videos.map(v => v.video_thumbnail)
    const video_thumbnails_new = this.videos.map(v => v.video_thumbnail)

    const link_urls_old = this.api_post.post_links.map(v => v.image_url)
    const link_urls_new = this.facebook_links.map(v => v.image_url)

    if (
      image_urls_old.toString() !== images_urls_new.toString() ||
      video_urls_old.toString() !== video_urls_new.toString() ||
      video_thumbnails_old.toString() !== video_thumbnails_new.toString() ||
      link_urls_old.toString() !== link_urls_new.toString()
    ) {
      return true
    }

    return false
  }

  /**
   * 編集を終了
   */
  finishEdit() {
    TrackingService.sendEvent('click:投稿作成|画面内ヘッダー|編集を終了')

    const query: any = {}

    if (this.page_type === 'edit' && this.scheduled_datetime) {
      query.scheduled_datetime = moment(this.scheduled_datetime).format()
    }

    this.changeRoute({ name: 'posts', query })
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
