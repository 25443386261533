const MB = 2 ** 20

/**
 * 画像のファイルサイズをチェック
 */
export function checkImageSize(size: number): boolean {
  return size <= 5 * MB
}

/**
 * アニメーションGIFのファイルサイズをチェック
 */
export function checkAnimationGifSize(size: number): boolean {
  return size <= 15 * MB
}

/**
 * アニメーションGIFの解像度をチェック
 */
export function checkAnimationGifResolution(width: number, height: number): boolean {
  return width <= 2048 && height <= 2048 // 実際は1280x1080以下だが大きいGIFを処理できるようになってる為
}

/**
 * 動画のファイルサイズをチェック
 */
export function checkVideoSize(size: number): boolean {
  return size <= 100 * MB // API仕様だと512MBまで 失敗するケースがある為
}

/**
 * 動画の再生時間をチェック
 */
export function checkVideoDuration(duration: number): boolean {
  return 0.5 <= duration && duration <= 140
}

/**
 * 動画の解像度をチェック
 */
export function checkVideoResolution(width: number, height: number): boolean {
  return (
    width <= 1920 &&
    height <= 1920 &&
    width * height <= 1920 * 1080 &&
    32 <= width &&
    32 <= height &&
    32 * 32 <= width * height
  )
}

/**
 * 動画のアスペクト比率をチェック
 */
export function checkVideoAspectRatio(width: number, height: number): boolean {
  return 1 / 3 <= width / height && width / height <= 3 / 1
}

/**
 * 動画のフレームレートをチェック
 */
export function checkVideoFrameRate(frame_rate: number | null): boolean {
  return frame_rate ? 1 <= frame_rate && frame_rate <= 60 : false
}

/**
 * 動画の音声チャンネル数をチェック
 */
export function checkVideoAudioChannels(audio_channels: number | null): boolean {
  return !audio_channels || audio_channels <= 2
}

/**
 * 動画の音声形式をチェック
 */
export function checkVideoAudioFormat(audio_format: string | null): boolean {
  return !audio_format || audio_format === 'LC'
}
