
import twttr from 'twitter-text'
import { computed, defineComponent } from 'vue'

import { PostListSubItemBase } from '@/client/components/organisms/PostList'
import { autoInstagramMentions } from '@/client/utils/regex'

import { htmlEscape } from './utils'

export default defineComponent({
  name: 'PostListSubItemInstagram',
  components: {
    PostListSubItemBase
  },
  props: {
    comment: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const message = computed(() => {
      if (!props.comment) return ''

      let text = props.comment

      text = text.replace(/\n\n\s*\n/g, '\n\n')

      text = htmlEscape(text)

      text = autoInstagramMentions(text, {
        usernameUrlBase: 'https://instagram.com/',
        usernameClass: 'instagram-url username'
      })

      text = twttr.autoLinkHashtags(text, {
        hashtagUrlBase: 'https://instagram.com/explore/tags/',
        hashtagClass: 'instagram-url hashtag',
        targetBlank: true
      })

      return text
    })

    return {
      message
    }
  }
})
