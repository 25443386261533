import { render, staticRenderFns } from "./DashboardPostScheduled.vue?vue&type=template&id=21a742af&scoped=true"
import script from "./DashboardPostScheduled.ts?vue&type=script&lang=ts&external"
export * from "./DashboardPostScheduled.ts?vue&type=script&lang=ts&external"
import style0 from "./DashboardPostScheduled.vue?vue&type=style&index=0&id=21a742af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a742af",
  null
  
)

export default component.exports