import moment from 'moment-timezone'
import twttr from 'twitter-text'
import { Component, Prop, Vue } from 'vue-property-decorator'

import Badge from '@/client/components-old/atoms/Badge'
import PostMedia from '@/client/components-old/molecules/PostMedia'
import { ITwitterPost } from '@/client/utils/api/posts'

@Component({
  name: 'TwitterPost',
  components: {
    Badge,
    PostMedia
  }
})
export default class TwitterPost extends Vue {
  @Prop({ type: Object, required: true })
  twitterPost: ITwitterPost

  @Prop({ type: String, default: '' })
  text: string

  get datetime() {
    if (!this.twitterPost.action_datetime) return ''

    return moment(this.twitterPost.action_datetime).format('lll')
  }

  get message() {
    if (!this.twitterPost.message) return ''

    let message = this.twitterPost.message

    message = this.multilineEscape(message)

    message = this.htmlEscape(message)

    const entities = twttr.extractEntitiesWithIndices(message, {
      extractUrlsWithoutProtocol: true
    })

    message = twttr.autoLinkEntities(message, entities, {
      hashtagUrlBase: 'https://x.com/hashtag/',
      usernameUrlBase: 'https://x.com/',
      usernameIncludeSymbol: true,
      targetBlank: true
    })

    return message
  }

  get status_type() {
    switch (this.twitterPost.status) {
      case 'approval':
        return 'warning'
      case 'draft':
        return 'default'
      case 'published':
        return 'success'
      case 'scheduled':
        return 'info'
      case 'reject':
        return 'inactive'
      case 'failure':
        return 'danger'
      default:
        return ''
    }
  }

  get status_text() {
    switch (this.twitterPost.status) {
      case 'approval':
        return '承認待ち'
      case 'draft':
        return '下書き'
      case 'published':
        return '公開'
      case 'scheduled':
        return '予約'
      case 'reject':
        return '差し戻し'
      case 'failure':
        return '失敗'
      default:
        return ''
    }
  }

  /**
   * マルチ改行を除去する
   */
  multilineEscape(text: string): string {
    return text.replace(/\n\n\s*\n/g, '\n\n')
  }

  /**
   * HTMLで使用する特殊文字を除去する
   */
  htmlEscape(text: string): string {
    const entities = {
      '>': '&gt;',
      '<': '&lt;',
      '"': '&quot;',
      "'": '&#39;'
    }

    return text.replace(/["'><]/g, character => entities[character])
  }
}
