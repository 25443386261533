import { RouteConfig } from 'vue-router'

import FacebookReport from '../pages/FacebookReport.vue'

export const report_fb: RouteConfig = {
  component: FacebookReport,
  path: '/report_fb',
  name: 'report_fb',
  meta: {
    auth: true,
    title: 'Excelレポート (Facebook)',
    description: 'SNSアカウントのExcelレポートがダウンロードできます。',
    color: 'warning',
    type: 'DOWNLOAD_EXCEL_REPORT_OWN_ANALYSIS'
  }
}
