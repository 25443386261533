import { Component, Emit, Vue } from 'vue-property-decorator'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'

@Component({
  name: 'PaymentContractCompleted',
  components: {
    Button,
    Icon,
    Panel
  }
})
export default class PaymentContractCompleted extends Vue {
  /**
   * ダッシュボードに移動
   */
  changeRouteDashboard() {
    this.changeRoute({ name: 'dashboard' })
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
