import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import DateTime from '@/client/components-old/atoms/DateTime'
import Dialog from '@/client/components-old/atoms/Dialog'
import EmbedFacebook from '@/client/components-old/atoms/EmbedFacebook'
import Account from '@/client/components-old/molecules/Account'
import MonitoringContent from '@/client/components-old/molecules/MonitoringContent'
import { IState as IMonitoringState } from '@/client/store/modules/monitoring'
import API from '@/client/utils/api'
import { TComment, TGetMonitoringPostComments } from '@/client/utils/api/monitoring_post_comments'

const monitoring = namespace('monitoring')

@Component({
  name: 'MonitoringPostDialog',
  components: {
    Dialog,
    DateTime,
    EmbedFacebook,
    Account,
    MonitoringContent
  }
})
export default class MonitoringPostDialog extends Vue {
  @monitoring.State('api_words') words: IMonitoringState['api_words']

  visible = false
  post_comment: TComment | null = null
  sns_type = ''
  monitoring_id = 0

  /**
   * ダイアログを開く
   */
  async open(params: { monitoring_id: number; sns_type: string }): Promise<void> {
    this.visible = true
    this.sns_type = params.sns_type
    this.monitoring_id = params.monitoring_id

    await this.getPostComments(params)
  }

  /**
   * ダイアログを非表示
   */
  hide(): void {
    this.visible = false
  }

  /**
   * 投稿コメントを取得
   */
  async getPostComments(params: { monitoring_id: number; sns_type: string }): Promise<void> {
    const response = await API.get<TGetMonitoringPostComments>(
      `monitoring_post_comments/${params.monitoring_id}`,
      { params: { sns_type: params.sns_type } }
    )

    if (response.status === 200 && response.data.data) {
      this.post_comment = response.data.data
    }
  }
}
