import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'

@Component({
  name: 'PaginationTable',
  components: {
    Icon,
    Tooltip
  }
})
export default class PaginationTable extends Vue {
  @Prop({ type: Number, default: 1 })
  value: number

  @Prop({ type: Number, default: 0 })
  total: number

  @Prop({ type: Number, default: 25 })
  pageSize: number

  @Prop({ type: String, default: '' })
  tooltip: string

  @Prop({ type: Number, default: 0 })
  displayTotal: number

  get display_total() {
    return this.displayTotal || this.total
  }

  @Emit('input')
  changeCurrentPage(val: number) {
    return val
  }
}
