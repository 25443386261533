
import { computed, defineComponent, PropType } from 'vue'

import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import CategoryList from '@/client/components-old/molecules/CategoryList'
import { getTranslateText as t } from '@/client/utils/filters'

type TooltipPostType = {
  text: string
  icon: string
}

const POST_TYPES = {
  text: { text: 'テキスト', icon: 'format-text' },
  status: { text: 'テキスト', icon: 'format-text' },
  photo: { text: '画像', icon: 'photo-camera' },
  image: { text: '画像', icon: 'photo-camera' },
  video: { text: '動画', icon: 'videocam' },
  carousel: { text: 'カルーセル', icon: 'perm-media' },
  reels: { text: 'リール', icon: 'ig-reel' },
  post: { text: '投稿', icon: 'rate-review' },
  summary: { text: 'サマリー', icon: 'dashboard' },
  fan: { text: 'ファン', icon: 'group' },
  action: { text: 'アクション', icon: 'action' },
  reach: { text: 'リーチ', icon: 'megaphone' },
  link: { text: 'リンク', icon: 'link' },
  shared: { text: 'シェア', icon: 'reply' },
  'animation-gif': { text: 'アニメーションGIF', icon: 'gif' }
}

export default defineComponent({
  name: 'AnalyticsPanelHeader',
  components: {
    Tooltip,
    CategoryList,
    Icon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      default: null
    },
    postTags: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    postTypes: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup(props, context) {
    const post_types = computed<TooltipPostType[]>(() =>
      props.postTypes.map(post_type => POST_TYPES[post_type])
    )

    const is_label = computed(() => !!(props.postTypes.length || props.postTags.length))

    return {
      props,
      context,
      post_types,
      is_label,
      Tooltip,
      CategoryList,
      Icon,
      t
    }
  }
})
