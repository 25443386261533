import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import {
  GroupSettingMonitoringAddDialog,
  GroupSettingMonitoringList,
  GroupSettingMonitoringUnsubscribeCancelDialog,
  GroupSettingMonitoringUnsubscribeDialog
} from '@/client/components/organisms/GroupSetting'
import Scroll from '@/client/components-old/atoms/Scroll'
import GroupSettingMonitoringExclude from '@/client/components-old/organisms/GroupSettingMonitoringExclude'
import GroupSettingMonitoringFlag from '@/client/components-old/organisms/GroupSettingMonitoringFlag'
import GroupSettingMonitoringGlobalKeyword from '@/client/components-old/organisms/GroupSettingMonitoringGlobalKeyword'
import GroupSettingMonitoringKeyword from '@/client/components-old/organisms/GroupSettingMonitoringKeyword'
import GroupSettingMonitoringTemplate from '@/client/components-old/organisms/GroupSettingMonitoringTemplate'
import MonitoringExcludeKeywordDialog from '@/client/components-old/organisms/MonitoringExcludeKeywordDialog'
import MonitoringKeywordDialog from '@/client/components-old/organisms/MonitoringKeywordDialog'
import MonitoringTemplateDialog from '@/client/components-old/organisms/MonitoringTemplateDialog'
import { IRootState } from '@/client/store/global'

@Component({
  name: 'GroupSettingMonitoringLayout',
  components: {
    Scroll,
    GroupSettingMonitoringTemplate,
    GroupSettingMonitoringKeyword,
    GroupSettingMonitoringGlobalKeyword,
    GroupSettingMonitoringExclude,
    GroupSettingMonitoringFlag,
    MonitoringTemplateDialog,
    MonitoringKeywordDialog,
    MonitoringExcludeKeywordDialog,
    GroupSettingMonitoringList,
    GroupSettingMonitoringAddDialog,
    GroupSettingMonitoringUnsubscribeDialog,
    GroupSettingMonitoringUnsubscribeCancelDialog
  }
})
export default class GroupSettingMonitoringLayout extends Vue {
  @State('project') project: IRootState['project']
  @State('project_role') project_role: IRootState['project_role']

  $refs: {
    MonitoringTemplateDialog: any
    MonitoringKeywordDialog: any
    MonitoringExcludeKeywordDialog: any
    GroupSettingMonitoringAddDialog: any
    GroupSettingMonitoringUnsubscribeDialog: any
    GroupSettingMonitoringUnsubscribeCancelDialog: any
  }

  /**
   * モニタリングの追加ダイアログを表示
   */
  openMonitoringAddDialog() {
    this.$refs.GroupSettingMonitoringAddDialog.open()
  }

  /**
   * モニタリングの購読解除ダイアログを表示
   */
  openMonitoringUnsubscribeDialog(payload: any) {
    this.$refs.GroupSettingMonitoringUnsubscribeDialog.open(payload)
  }

  /**
   * モニタリングの購読解除キャンセルダイアログを表示
   */
  openMonitoringUnsubscribeCancelDialog(payload: any) {
    this.$refs.GroupSettingMonitoringUnsubscribeCancelDialog.open(payload)
  }

  /**
   * モニタリングテンプレートダイアログを表示
   */
  openMonitoringTemplateDialog(payload: any) {
    this.$refs.MonitoringTemplateDialog.open(payload)
  }

  /**
   * モニタリング監視ワード追加ダイアログの表示
   */
  openMonitoringKeywordCreateDialog() {
    this.$refs.MonitoringKeywordDialog.open()
  }

  /**
   * モニタリング監視ワード変更ダイアログの表示
   */
  openMonitoringKeywordUpdateDialog(payload: any) {
    this.$refs.MonitoringKeywordDialog.open(payload)
  }

  /**
   * モニタリング除外キーワード追加ダイアログの表示
   */
  openMonitoringExcludeKeywordDialog(payload: any) {
    this.$refs.MonitoringExcludeKeywordDialog.open(payload)
  }
}
