import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import DateTime from '@/client/components-old/atoms/DateTime'
import Icon from '@/client/components-old/atoms/Icon'
import Account from '@/client/components-old/molecules/Account'
import { TrackingService } from '@/client/services'
import { convertMemoMessage } from '@/client/utils/regex'

@Component({
  name: 'RecentMemo',
  components: {
    ButtonLink,
    DateTime,
    Icon,
    Account
  }
})
export default class RecentMemo extends Vue {
  @Prop({ type: Object, default: null })
  recentMemo: {
    message: string
    created: string
    name: string
    picture_url: string
  } | null

  @Prop({ type: Number, default: 0 })
  memoCount: number

  @Prop({ type: Number, default: 0 })
  historyCount: number

  get memo_message() {
    if (!this.recentMemo) return ''

    return convertMemoMessage(this.recentMemo.message)
  }

  @Emit('open-memo')
  openMemo(event: Event) {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|メモ')

    return event
  }

  @Emit('open-history')
  openHistory(event: Event) {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|履歴')

    return event
  }
}
