import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import HelpLink from '@/client/components/molecules/HelpLink'
import * as constants from '@/client/components-old/_constants/instagram_analytics'
import Button from '@/client/components-old/atoms/Button'
import Checkbox from '@/client/components-old/atoms/Checkbox'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Select from '@/client/components-old/atoms/Select'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import InstagramAnalyticsChartVertical from '@/client/components-old/organisms/InstagramAnalyticsChartVertical'
import InstagramAnalyticsGoal from '@/client/components-old/organisms/InstagramAnalyticsGoal'
import InstagramAnalyticsRanking from '@/client/components-old/organisms/InstagramAnalyticsRanking'
import InstagramAnalyticsStoriesRanking from '@/client/components-old/organisms/InstagramAnalyticsStoriesRanking'
import InstagramAnalyticsTotal from '@/client/components-old/organisms/InstagramAnalyticsTotal'
import InstagramAnalyticsTotalTable from '@/client/components-old/organisms/InstagramAnalyticsTotalTable'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import event from '@/client/utils/event'
import i18n from '@/client/utils/i18n'

const instagram = namespace('instagram_analytics')
const notification = namespace('notification')
const categories = namespace('categories')

@Component({
  name: 'InstagramAnalyticsWidgetDialog',
  components: {
    InstagramAnalyticsChartVertical,
    InstagramAnalyticsRanking,
    InstagramAnalyticsStoriesRanking,
    InstagramAnalyticsTotal,
    InstagramAnalyticsGoal,
    InstagramAnalyticsTotalTable,
    Dialog,
    Button,
    HelpLink,
    Icon,
    Tooltip,
    Select,
    SelectMulti,
    Checkbox,
    Input,
    Message
  }
})
export default class InstagramAnalyticsWidgetDialog extends Vue {
  @instagram.Action('getWidgetSettingById') getWidgetSettingById
  @instagram.Action('postWidgetSettings') postWidgetSettings
  @instagram.Action('putWidgetSettings') putWidgetSettings
  @notification.Action('showNotification') showNotification
  @categories.State('api_post_categories')
  api_post_categories: ICategoriesState['api_post_categories']

  is_submit = false
  visible = false
  widget_id: number | null = null
  widget_type = ''

  data_type = 'daily_data'
  metric = 'followers_count'
  unit = 'total'

  post_types = []
  post_tags = []

  // 集計設定
  total_is_view_extra = true

  // チャート設定
  chart_interval = '1_day'

  // 投稿ランキング
  post_ranking_main_metric = 'reactions'
  post_ranking_sort = 'descending'
  post_ranking_limit = 5
  post_ranking_sub_metric_1 = 'likes'
  post_ranking_sub_metric_2 = 'comments'
  post_ranking_sub_metric_3 = 'saved_count'
  post_ranking_sub_metric_4 = 'reactions_rate'
  post_ranking_sub_metric_5 = ''
  post_ranking_sub_metric_6 = ''
  post_ranking_sub_metric_7 = ''
  post_ranking_sub_metric_8 = ''
  post_ranking_sub_metric_9 = ''
  post_ranking_sub_metric_10 = ''
  post_ranking_sub_metric_options_1 = this.post_ranking_sub_metric_options
  post_ranking_sub_metric_options_2 = this.post_ranking_sub_metric_options
  post_ranking_sub_metric_options_3 = this.post_ranking_sub_metric_options
  post_ranking_sub_metric_options_4 = this.post_ranking_sub_metric_options
  post_ranking_sub_metric_options_5 = this.post_ranking_sub_metric_options
  post_ranking_sub_metric_options_6 = this.post_ranking_sub_metric_options
  post_ranking_sub_metric_options_7 = this.post_ranking_sub_metric_options
  post_ranking_sub_metric_options_8 = this.post_ranking_sub_metric_options
  post_ranking_sub_metric_options_9 = this.post_ranking_sub_metric_options
  post_ranking_sub_metric_options_10 = this.post_ranking_sub_metric_options

  // ストーリーズランキング
  stories_ranking_main_metric = 'reach'
  stories_ranking_sort = 'descending'
  stories_ranking_limit = 5
  stories_ranking_sub_metric_1 = 'impressions'
  stories_ranking_sub_metric_2 = 'taps_back'
  stories_ranking_sub_metric_3 = 'taps_forward'
  stories_ranking_sub_metric_4 = 'exits'
  stories_ranking_sub_metric_5 = ''
  stories_ranking_sub_metric_6 = ''
  stories_ranking_sub_metric_7 = ''
  stories_ranking_sub_metric_8 = ''
  stories_ranking_sub_metric_9 = ''
  stories_ranking_sub_metric_10 = ''
  stories_ranking_sub_metric_options_1 = this.stories_ranking_sub_metric_options
  stories_ranking_sub_metric_options_2 = this.stories_ranking_sub_metric_options
  stories_ranking_sub_metric_options_3 = this.stories_ranking_sub_metric_options
  stories_ranking_sub_metric_options_4 = this.stories_ranking_sub_metric_options
  stories_ranking_sub_metric_options_5 = this.stories_ranking_sub_metric_options
  stories_ranking_sub_metric_options_6 = this.stories_ranking_sub_metric_options
  stories_ranking_sub_metric_options_7 = this.stories_ranking_sub_metric_options
  stories_ranking_sub_metric_options_8 = this.stories_ranking_sub_metric_options
  stories_ranking_sub_metric_options_9 = this.stories_ranking_sub_metric_options
  stories_ranking_sub_metric_options_10 = this.stories_ranking_sub_metric_options

  // 目標値
  goal = ''
  goal_first = false
  goal_error = false
  goal_error_message = ''

  // 表設定
  table_title = ''
  table_title_first = false
  table_title_error = false
  table_interval = '1_month'
  table_metric_1 = 'followers_count'
  table_metric_2 = ''
  table_metric_3 = ''
  table_metric_4 = ''
  table_metric_5 = ''
  table_metric_6 = ''
  table_metric_7 = ''
  table_metric_8 = ''
  table_metric_9 = ''
  table_metric_10 = ''
  table_metric_options_1 = this.table_metrics_options
  table_metric_options_2 = this.table_metrics_options
  table_metric_options_3 = this.table_metrics_options
  table_metric_options_4 = this.table_metrics_options
  table_metric_options_5 = this.table_metrics_options
  table_metric_options_6 = this.table_metrics_options
  table_metric_options_7 = this.table_metrics_options
  table_metric_options_8 = this.table_metrics_options
  table_metric_options_9 = this.table_metrics_options
  table_metric_options_10 = this.table_metrics_options

  get indexs() {
    return Array.from(new Array(10)).map((v, i) => i + 1) // [1...10]
  }

  get widget_type_options() {
    return this.translateOptions(constants.TYPES)
  }

  get data_type_options() {
    switch (this.widget_type) {
      case 'total':
      case 'chart':
      case 'stories_ranking':
        return this.translateOptions(constants.IN_DATA_TYPES)
      default:
        return this.translateOptions(constants.DATA_TYPES)
    }
  }

  get unit_options() {
    return this.translateOptions(constants.UNITS).filter(v => v.value !== 'major')
  }

  get metric_options() {
    switch (this.data_type) {
      case 'daily_data':
        return this.translateOptions(constants.DAILY_DATA_METRICS)
      case 'post_data':
        return this.translateOptions(constants.POST_DATA_METRICS)
      case 'stories_data':
        return this.translateOptions(constants.STORIES_DATA_METRICS)
      default:
        return []
    }
  }

  get chart_interval_options() {
    return this.translateOptions(constants.INTERVALS)
  }

  get post_ranking_main_metric_options() {
    return this.translateOptions(constants.POST_RANKING_MAIN_METRICS)
  }

  get post_ranking_sort_options() {
    return this.translateOptions(constants.POST_RANKING_SORTS)
  }

  get post_ranking_limit_options() {
    return this.translateOptions(constants.POST_RANKING_LIMITS)
  }

  get post_ranking_sub_metric_options() {
    return this.translateOptions(constants.POST_RANKING_SUB_METRICS)
  }

  get stories_ranking_main_metric_options() {
    return this.translateOptions(constants.STORIES_POST_RANKING_MAIN_METRICS)
  }

  get stories_ranking_sort_options() {
    return this.translateOptions(constants.POST_RANKING_SORTS)
  }

  get stories_ranking_limit_options() {
    return this.translateOptions(constants.POST_RANKING_LIMITS)
  }

  get stories_ranking_sub_metric_options() {
    return this.translateOptions(constants.STORIES_POST_RANKING_SUB_METRICS)
  }

  get table_interval_options() {
    const blacklist = ['1_day', '28_day', '6_month']
    return this.translateOptions(constants.INTERVALS).filter(v => blacklist.indexOf(v.value) === -1)
  }

  get table_metrics_options() {
    let metrics = []

    switch (this.data_type) {
      case 'daily_data':
        metrics = constants.TABLE_DAILY_DATA_METRICS
        break
      case 'post_data':
        metrics = constants.TABLE_POST_DATA_METRICS
        break
      case 'stories_data':
        metrics = constants.TABLE_STORIES_DATA_METRICS
        break
    }

    // 期間合計の場合は、率に関する指標を除外する
    if (this.unit === 'total') {
      metrics = metrics.filter(metric => metric.value.indexOf('_rate') === -1)
    }

    return this.translateOptions(metrics)
  }

  get is_edit_mode() {
    return this.widget_id !== null
  }

  get is_type_total() {
    return this.widget_type === 'total'
  }

  get is_type_chart() {
    return this.widget_type === 'chart'
  }

  get is_type_post_ranking() {
    return this.widget_type === 'post_ranking'
  }

  get is_type_stories_ranking() {
    return this.widget_type === 'stories_ranking'
  }

  get is_type_goal() {
    return this.widget_type === 'goal'
  }

  get is_type_table() {
    return this.widget_type === 'table'
  }

  get is_unit_total() {
    return this.unit === 'total'
  }

  get is_show_unit() {
    return !(
      this.metric === 'followers_count' ||
      this.metric === 'followers_count_up_down' ||
      this.metric === 'follows_count' ||
      this.metric === 'follows_count_up_down'
    )
  }

  get is_disabled_unit() {
    return this.data_type === 'daily_data' || this.metric.indexOf('_rate') > 0
  }

  get is_show_post_types_and_tags() {
    return this.data_type === 'post_data' || this.data_type === 'stories_data'
  }

  get is_disabled_submit() {
    switch (this.widget_type) {
      case 'goal':
        return this.goal_error || (!this.goal_first && !this.widget_id)
      case 'table':
        return this.table_title_error || (!this.table_title_first && !this.widget_id)
      default:
        return this.widget_type === ''
    }
  }

  get post_ranking_sub_metrics() {
    return this.indexs.map(v => this[`post_ranking_sub_metric_${v}`]).filter(v => v !== '')
  }

  get stories_ranking_sub_metrics() {
    return this.indexs.map(v => this[`stories_ranking_sub_metric_${v}`]).filter(v => v !== '')
  }

  get goal_value() {
    let value = Number(this.goal)

    if (isNaN(value)) {
      value = 0
    }

    return value
  }

  get table_metrics() {
    return this.indexs.map(v => this[`table_metric_${v}`]).filter(v => v !== '')
  }

  get options() {
    switch (this.widget_type) {
      case 'total': {
        const base = {
          data_type: this.data_type,
          metric: this.metric,
          unit: this.unit,
          is_view_extra: this.total_is_view_extra
        }

        if (this.data_type === 'post_data' || this.data_type === 'stories_data') {
          return {
            ...base,
            post_types: this.post_types,
            post_tags: this.post_tags
          }
        } else {
          return base
        }
      }
      case 'chart': {
        const base = {
          data_type: this.data_type,
          metric: this.metric,
          interval: this.chart_interval,
          unit: this.unit
        }

        if (this.data_type === 'post_data' || this.data_type === 'stories_data') {
          return {
            ...base,
            post_types: this.post_types,
            post_tags: this.post_tags
          }
        } else {
          return base
        }
      }
      case 'post_ranking':
        return {
          main_metric: this.post_ranking_main_metric,
          sort: this.post_ranking_sort,
          sub_metrics: this.post_ranking_sub_metrics,
          post_types: this.post_types,
          post_tags: this.post_tags,
          limit: this.post_ranking_limit
        }
      case 'stories_ranking':
        return {
          main_metric: this.stories_ranking_main_metric,
          sort: this.stories_ranking_sort,
          post_types: this.post_types,
          post_tags: this.post_tags,
          sub_metrics: this.stories_ranking_sub_metrics,
          limit: this.stories_ranking_limit
        }
      case 'goal': {
        const base = {
          goal: this.goal_value,
          data_type: this.data_type,
          metric: this.metric,
          unit: this.unit
        }

        if (this.data_type === 'post_data' || this.data_type === 'stories_data') {
          return {
            ...base,
            post_types: this.post_types,
            post_tags: this.post_tags
          }
        } else {
          return base
        }
      }
      case 'table': {
        const base = {
          title: this.table_title,
          data_type: this.data_type,
          metrics: this.table_metrics,
          interval: this.table_interval,
          unit: this.unit
        }

        if (this.data_type === 'post_data') {
          return {
            ...base,
            post_types: this.post_types,
            post_tags: this.post_tags
          }
        } else {
          return base
        }
      }
      default:
        return {}
    }
  }

  get posts_search_type_options() {
    return constants.POST_TYPE.map(type => ({
      text: i18n.t(type.text),
      value: type.value
    }))
  }

  get stories_search_type_options() {
    return constants.STORIES_TYPE.map(type => ({
      text: i18n.t(type.text),
      value: type.value
    }))
  }

  get search_type_options() {
    if (this.data_type === 'post_data') {
      return constants.POST_TYPE.map(type => ({
        text: i18n.t(type.text),
        value: type.value
      }))
    }
    if (this.data_type === 'stories_data') {
      return constants.STORIES_TYPE.map(type => ({
        text: i18n.t(type.text),
        value: type.value
      }))
    }
    return []
  }

  get search_category_options() {
    return this.api_post_categories.map(v => ({ text: v.name, value: v.id }))
  }

  get preview_component() {
    switch (this.widget_type) {
      case 'total':
        return 'InstagramAnalyticsTotal'
      case 'chart':
        return 'InstagramAnalyticsChartVertical'
      case 'post_ranking':
        return 'InstagramAnalyticsRanking'
      case 'stories_ranking':
        return 'InstagramAnalyticsStoriesRanking'
      case 'goal':
        return 'InstagramAnalyticsGoal'
      case 'table':
        return 'InstagramAnalyticsTotalTable'
      default:
        return ''
    }
  }

  get preview_component_style() {
    switch (this.widget_type) {
      case 'total':
        return { width: '350px' }
      case 'chart':
        return { width: '600px' }
      case 'post_ranking':
      case 'stories_ranking':
        return { width: '100%' }
      case 'goal':
        return { width: '350px' }
      case 'table':
        return { width: '600px' }
      default:
        return {}
    }
  }

  @Watch('post_ranking_main_metric')
  changePostRankingMainMetric() {
    this.indexs.forEach(v => {
      if (this[`post_ranking_sub_metric_${v}`] === this.post_ranking_main_metric) {
        this[`post_ranking_sub_metric_${v}`] = ''
      }
    })

    this.changePostRankingSubMetrics()
  }

  @Watch('post_ranking_sub_metrics', { deep: true, immediate: true })
  changePostRankingSubMetrics() {
    this.indexs.forEach(v => {
      this[`post_ranking_sub_metric_options_${v}`] = this.post_ranking_sub_metric_options.filter(
        metric => {
          if (
            this.post_ranking_sub_metrics.indexOf(metric.value) === -1 &&
            this.post_ranking_main_metric !== metric.value
          ) {
            return true
          } else if (this[`post_ranking_sub_metric_${v}`] === metric.value) {
            return true
          } else {
            return false
          }
        }
      )
    })
  }

  @Watch('stories_ranking_main_metric')
  changeStoriesRankingMainMetric() {
    this.indexs.forEach(v => {
      if (this[`stories_ranking_sub_metric_${v}`] === this.stories_ranking_main_metric) {
        this[`stories_ranking_sub_metric_${v}`] = ''
      }
    })

    this.changeStoriesRankingSubMetrics()
  }

  @Watch('stories_ranking_sub_metrics', { deep: true, immediate: true })
  changeStoriesRankingSubMetrics() {
    this.indexs.forEach(v => {
      this[`stories_ranking_sub_metric_options_${v}`] =
        this.stories_ranking_sub_metric_options.filter(metric => {
          if (
            this.stories_ranking_sub_metrics.indexOf(metric.value) === -1 &&
            this.stories_ranking_main_metric !== metric.value
          ) {
            return true
          } else if (this[`stories_ranking_sub_metric_${v}`] === metric.value) {
            return true
          } else {
            return false
          }
        })
    })
  }

  @Watch('table_metrics', { deep: true, immediate: true })
  changeTableMetrics() {
    this.indexs.forEach(v => {
      this[`table_metric_options_${v}`] = this.table_metrics_options.filter(metric => {
        if (this.table_metrics.indexOf(metric.value) === -1) {
          return true
        } else if (this[`table_metric_${v}`] === metric.value) {
          return true
        } else {
          return false
        }
      })
    })
  }

  /**
   * ウィジェットタイプを変更した時
   * @returns {void}
   */
  changeWidgetType(): void {
    switch (this.widget_type) {
      case 'goal':
      case 'table':
        if (this.data_type === 'stories_data') {
          this.data_type = 'daily_data'
          this.metric = 'followers_count'
          this.unit = 'total'
          this.table_metric_1 = 'followers_count'
        }

        break
    }
  }

  /**
   * データ種別を変更した時
   * @returns {void}
   */
  changeDataType(): void {
    this.unit = 'total'
    this.post_types = []
    this.post_tags = []

    this.resetTableMetrics()
  }

  /**
   * 集計方法を変更した時
   * @returns {void}
   */
  changeUnit(): void {
    this.resetTableMetrics()
  }

  /**
   * 指標を変更した時
   * @returns {void}
   */
  changeMetric(): void {
    if (this.data_type === 'daily_data') {
      this.unit = 'total'
    }

    if (this.metric.indexOf('_rate') > 0) {
      this.unit = 'average'
    }
  }

  /**
   * 目標値を検証
   * @returns {void}
   */
  goalValidation(): void {
    this.goal_first = true
    this.goal_error = false

    const goal_number = Number(this.goal)

    // 数値であるかチェックする
    if (isNaN(goal_number)) {
      this.goal_error = true
      this.goal_error_message = '数値を入力してください。'
    }

    // 0以上であるかチェックする
    if (goal_number <= 0) {
      this.goal_error = true
      this.goal_error_message = '0以上の値を入力してください。'
    }
  }

  /**
   * 表タイトルを検証
   * @returns {void}
   */
  tableTitleValidation(): void {
    this.table_title_first = true
    this.table_title_error = false

    // 文字列があるかチェックする
    if (this.table_title === '') {
      this.table_title_error = true
    }
  }

  /**
   * 選択オプションの項目のを翻訳する汎用関数
   * @param {any[]} options textがあるオブジェクト配列
   * @returns {any[]} 翻訳されたオプション
   */
  translateOptions(options: any[]): { [key: string]: string }[] {
    return options.map(el => ({ ...el, text: this.$options.filters.translate(el.text) }))
  }

  /**
   * 初期化
   * @returns {void}
   */
  reset(): void {
    this.is_submit = false
    this.widget_id = null
    this.widget_type = ''
    this.data_type = 'daily_data'
    this.metric = 'followers_count'
    this.unit = 'total'
    this.post_types = []
    this.post_tags = []

    // 集計
    this.total_is_view_extra = true

    // チャート
    this.chart_interval = '1_day'

    // 投稿ランキング
    this.post_ranking_main_metric = 'reactions'
    this.post_ranking_sort = 'descending'
    this.post_ranking_limit = 5
    this.post_ranking_sub_metric_1 = 'likes'
    this.post_ranking_sub_metric_2 = 'comments'
    this.post_ranking_sub_metric_3 = 'saved_count'
    this.post_ranking_sub_metric_4 = 'reactions_rate'
    this.post_ranking_sub_metric_5 = ''
    this.post_ranking_sub_metric_6 = ''
    this.post_ranking_sub_metric_7 = ''
    this.post_ranking_sub_metric_8 = ''
    this.post_ranking_sub_metric_9 = ''
    this.post_ranking_sub_metric_10 = ''

    // ストーリーズランキング
    this.stories_ranking_sub_metric_1 = 'impressions'
    this.stories_ranking_sub_metric_2 = 'taps_back'
    this.stories_ranking_sub_metric_3 = 'taps_forward'
    this.stories_ranking_sub_metric_4 = 'exits'
    this.stories_ranking_sub_metric_5 = ''
    this.stories_ranking_sub_metric_6 = ''
    this.stories_ranking_sub_metric_7 = ''
    this.stories_ranking_sub_metric_8 = ''
    this.stories_ranking_sub_metric_9 = ''
    this.stories_ranking_sub_metric_10 = ''

    // 目標値
    this.goal = ''
    this.goal_first = false
    this.goal_error = false

    // 表
    this.table_title = ''
    this.table_interval = '1_month'
    this.table_metric_1 = 'followers_count'
    this.table_metric_2 = ''
    this.table_metric_3 = ''
    this.table_metric_4 = ''
    this.table_metric_5 = ''
    this.table_metric_6 = ''
    this.table_metric_7 = ''
    this.table_metric_8 = ''
    this.table_metric_9 = ''
    this.table_metric_10 = ''
  }

  /**
   * 選択された指標の初期化
   * @returns {void}
   */
  resetTableMetrics(): void {
    switch (this.data_type) {
      case 'daily_data':
        this.indexs.forEach(v => {
          this[`table_metric_${v}`] = ''
          this[`table_metric_options_${v}`] = [...this.table_metrics_options]
        })

        this.metric = 'followers_count'
        this.table_metric_1 = 'followers_count'
        break

      case 'post_data':
        this.indexs.forEach(v => {
          this[`table_metric_${v}`] = ''
          this[`table_metric_options_${v}`] = [...this.table_metrics_options]
        })

        this.metric = 'impressions_unique'
        this.table_metric_1 = 'impressions_unique'
        break
      case 'stories_data':
        this.indexs.forEach(v => {
          this[`table_metric_${v}`] = ''
          this[`table_metric_options_${v}`] = [...this.table_metrics_options]
        })

        this.metric = 'reach'
        this.table_metric_1 = 'reach'
        break
    }
  }

  /**
   * ダイアログの非表示
   * @returns {void}
   */
  hide(): void {
    this.visible = false
  }

  /**
   * ダイアログの表示
   * @param {number} widget_id
   * @returns {void}
   */
  async open(widget_id?: number): Promise<void> {
    this.visible = true
    this.reset()

    if (widget_id) {
      this.widget_id = widget_id
      await this.getEditWidget(widget_id)
    }
  }

  /**
   * コンポーネント設定の保存
   * @returns {void}
   */
  async submit(): Promise<void> {
    if (!this.widget_id) {
      await this.create()
    } else {
      await this.update()
    }
  }

  /**
   * コンポーネント設定の新規作成
   * @returns {void}
   */
  async create(): Promise<void> {
    if (!this.widget_type) return
    if (this.is_submit) return

    this.is_submit = true

    const response = await this.postWidgetSettings({
      type: this.widget_type,
      options: JSON.stringify(this.options)
    })

    setTimeout(() => {
      this.is_submit = false
    }, 2000)

    if (!response.data) {
      this.showNotification({ title: 'コンポーネントの追加に失敗しました。', type: 'error' })
    } else {
      this.showNotification({ title: 'コンポーネントを追加しました。' })
      this.hide()
      this.reset()
      event.resize()
    }
  }

  /**
   * コンポーネント設定の更新
   * @returns {void}
   */
  async update(): Promise<void> {
    if (!this.widget_type) return
    if (this.is_submit) return

    this.is_submit = true

    const response = await this.putWidgetSettings({
      id: this.widget_id,
      type: this.widget_type,
      options: JSON.stringify(this.options)
    })

    setTimeout(() => {
      this.is_submit = false
    }, 2000)

    if (response.error && response.error.type === 'NOT_EXISTS') {
      this.showNotification({
        title: '対象のコンポーネントはすでに削除されています。',
        type: 'error'
      })
    } else if (!response.data) {
      const text_01 = this.$options.filters.translate('コンポーネント設定の保存に失敗しました。')
      const text_02 = this.$options.filters.translate(
        '恐れ入りますが、時間をおいて再度お試しください。'
      )
      this.showNotification({ title: `${text_01}<br>${text_02}`, type: 'error' })
    } else {
      this.showNotification({ title: 'コンポーネント設定を保存しました。' })
      this.hide()
      this.reset()
      event.resize()
    }
  }

  /**
   * 編集ウィジェットデータを取得
   * @param {number} widget_id
   * @returns {void}
   */
  async getEditWidget(widget_id: number): Promise<void> {
    const response = await this.getWidgetSettingById({ id: widget_id })

    // 取得に失敗した場合
    if (!response.data || !response.data.id) {
      if (response.error && response.error.type === 'NOT_EXISTS') {
        this.showNotification({
          title: '対象のコンポーネントはすでに削除されています。',
          type: 'error'
        })
      } else {
        this.showNotification({
          title: 'コンポーネントデータの取得に失敗しました。',
          type: 'error'
        })
      }

      this.hide()
      return
    }

    const setting = response.data

    this.is_submit = false
    this.widget_type = setting.type
    this.data_type = setting.options.data_type || 'daily_data'
    this.metric = setting.options.metric || 'followers_count'
    this.unit = setting.options.unit || 'total'
    this.post_types = setting.options.post_types || []
    this.post_tags = setting.options.post_tags?.length
      ? this.api_post_categories
          .filter(post_tag => setting.options.post_tags.includes(post_tag.id))
          .map(post_tag => post_tag.id)
      : []

    // 集計
    this.total_is_view_extra = Boolean(setting.options.is_view_extra)

    // チャート
    this.chart_interval = setting.options.interval || '1_day'

    // 投稿ランキング
    this.post_ranking_main_metric = setting.options.main_metric || 'reactions'
    this.post_ranking_sort = setting.options.sort || 'descending'
    this.post_ranking_limit = Number(setting.options.limit) || 5
    this.post_ranking_sub_metric_1 = ''
    this.post_ranking_sub_metric_2 = ''
    this.post_ranking_sub_metric_3 = ''
    this.post_ranking_sub_metric_4 = ''
    this.post_ranking_sub_metric_5 = ''
    this.post_ranking_sub_metric_6 = ''
    this.post_ranking_sub_metric_7 = ''
    this.post_ranking_sub_metric_8 = ''
    this.post_ranking_sub_metric_9 = ''
    this.post_ranking_sub_metric_10 = ''

    if (this.widget_type === 'post_ranking') {
      setting.options.sub_metrics.forEach((metric: string, index: number) => {
        this[`post_ranking_sub_metric_${index + 1}`] = metric
      })
    }

    // ストーリーズランキング
    this.stories_ranking_main_metric = setting.options.main_metric || 'reach'
    this.stories_ranking_sort = setting.options.sort || 'descending'
    this.stories_ranking_limit = Number(setting.options.limit) || 5
    this.stories_ranking_sub_metric_1 = ''
    this.stories_ranking_sub_metric_2 = ''
    this.stories_ranking_sub_metric_3 = ''
    this.stories_ranking_sub_metric_4 = ''
    this.stories_ranking_sub_metric_5 = ''
    this.stories_ranking_sub_metric_6 = ''
    this.stories_ranking_sub_metric_7 = ''
    this.stories_ranking_sub_metric_8 = ''
    this.stories_ranking_sub_metric_9 = ''
    this.stories_ranking_sub_metric_10 = ''

    if (this.widget_type === 'stories_ranking') {
      setting.options.sub_metrics.forEach((metric: string, index: number) => {
        this[`stories_ranking_sub_metric_${index + 1}`] = metric
      })
    }

    // 集計値
    this.goal = String(setting.options.goal) || ''

    // 表
    this.table_title = setting.options.title || ''
    this.table_interval = setting.options.interval || '1_month'
    this.table_metric_1 = ''
    this.table_metric_2 = ''
    this.table_metric_3 = ''
    this.table_metric_4 = ''
    this.table_metric_5 = ''
    this.table_metric_6 = ''
    this.table_metric_7 = ''
    this.table_metric_8 = ''
    this.table_metric_9 = ''
    this.table_metric_10 = ''

    if (this.widget_type === 'table') {
      setting.options.metrics.forEach((metric: string, index: number) => {
        this[`table_metric_${index + 1}`] = metric
      })
    }
  }
}
