import { RouteConfig } from 'vue-router'

import Signup from '../pages/Signup.vue'

export const signup: RouteConfig = {
  component: Signup,
  path: '/signup',
  name: 'signup',
  meta: {
    auth: false,
    title: 'トライアル登録'
  }
}
