import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  TDashboardPost,
  TDashboardsMonitoring,
  TDashboardsMonitoringHistory,
  TDashboardsMonitoringMemo,
  TDashboardsPostHistory,
  TDashboardsPostMemo,
  TGetDashboardsMonitoringHistoriesParams,
  TGetDashboardsMonitoringHistoriesResponse,
  TGetDashboardsMonitoringMemosParams,
  TGetDashboardsMonitoringMemosResponse,
  TGetDashboardsMonitoringsParams,
  TGetDashboardsMonitoringsResponse,
  TGetDashboardsOperatorsParams,
  TGetDashboardsOperatorsResponse,
  TGetDashboardsPostHistoriesParams,
  TGetDashboardsPostHistoriesResponse,
  TGetDashboardsPostMemosParams,
  TGetDashboardsPostMemosResponse,
  TGetDashboardsPostsParams,
  TGetDashboardsPostsResponse,
  TUser
} from '@/client/utils/api/dashboards'
import { IGetPostResponse } from '@/client/utils/api/posts'
import {
  TGetUserDashboardSettingsResponse,
  TPutUserDashboardSettingsParams,
  TPutUserDashboardSettingsResponse,
  TUserDashboardSettings
} from '@/client/utils/api/user_dashboard_settings'

export interface IState {
  api_dashboard_settings: TUserDashboardSettings[]
  api_post_scheduled: TDashboardPost[]
  api_post_approve: TDashboardPost[]
  api_post_reject: TDashboardPost[]
  api_post_published: TDashboardPost[]
  api_post_memo: TDashboardsPostMemo[]
  api_post_history: TDashboardsPostHistory[]
  api_monitoring_unread: TDashboardsMonitoring[]
  api_monitoring_pending: TDashboardsMonitoring[]
  api_monitoring_memo: TDashboardsMonitoringMemo[]
  api_monitoring_history: TDashboardsMonitoringHistory[]
  api_operators: TUser[]
  filter_operator_id: '' | number
  filter_project_all: boolean
  is_loading: boolean
  is_scroll_post_scheduled: boolean
  is_scroll_post_approve: boolean
  is_scroll_post_reject: boolean
  is_scroll_post_published: boolean
  is_scroll_post_memo: boolean
  is_scroll_post_history: boolean
  is_scroll_monitoring_unread: boolean
  is_scroll_monitoring_pending: boolean
  is_scroll_monitoring_memo: boolean
  is_scroll_monitoring_history: boolean
  is_post_scheduled_loading: boolean
  is_post_approval_loading: boolean
  is_post_reject_loading: boolean
  is_post_published_loading: boolean
  is_post_memo_loading: boolean
  is_post_history_loading: boolean
  is_monitoring_unread_loading: boolean
  is_monitoring_pending_loading: boolean
  is_monitoring_memo_loading: boolean
  is_monitoring_history_loading: boolean
}

const state: IState = {
  api_dashboard_settings: [],
  api_post_scheduled: [],
  api_post_approve: [],
  api_post_reject: [],
  api_post_published: [],
  api_post_memo: [],
  api_post_history: [],
  api_monitoring_unread: [],
  api_monitoring_pending: [],
  api_monitoring_memo: [],
  api_monitoring_history: [],
  api_operators: [],
  filter_operator_id: '',
  filter_project_all: false,
  is_loading: true,
  is_scroll_post_scheduled: true,
  is_scroll_post_approve: true,
  is_scroll_post_reject: true,
  is_scroll_post_published: true,
  is_scroll_post_memo: true,
  is_scroll_post_history: true,
  is_scroll_monitoring_unread: true,
  is_scroll_monitoring_pending: true,
  is_scroll_monitoring_memo: true,
  is_scroll_monitoring_history: true,
  is_post_scheduled_loading: true,
  is_post_approval_loading: true,
  is_post_reject_loading: true,
  is_post_published_loading: true,
  is_post_memo_loading: true,
  is_post_history_loading: true,
  is_monitoring_unread_loading: true,
  is_monitoring_pending_loading: true,
  is_monitoring_memo_loading: true,
  is_monitoring_history_loading: true
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  SET_API_DASHBOARD_SETTINGS(state, payload) {
    state.api_dashboard_settings = payload
  },
  SET_API_POST_SCHEDULED(state, payload) {
    state.api_post_scheduled = payload
  },
  SET_API_POST_APPROVE(state, payload) {
    state.api_post_approve = payload
  },
  SET_API_POST_REJECT(state, payload) {
    state.api_post_reject = payload
  },
  SET_API_POST_PUBLISHED(state, payload) {
    state.api_post_published = payload
  },
  SET_API_POST_MEMO(state, payload) {
    state.api_post_memo = payload
  },
  SET_API_POST_HISTORY(state, payload) {
    state.api_post_history = payload
  },
  SET_API_MONITORING_UNREAD(state, payload) {
    state.api_monitoring_unread = payload
  },
  SET_API_MONITORING_PENDING(state, payload) {
    state.api_monitoring_pending = payload
  },
  SET_API_MONITORING_MEMO(state, payload) {
    state.api_monitoring_memo = payload
  },
  SET_API_MONITORING_HISTORY(state, payload) {
    state.api_monitoring_history = payload
  },
  SET_API_OPERATORS(state, payload) {
    state.api_operators = payload
  },
  SET_FILTER_OPERATOR_ID(state, payload) {
    state.filter_operator_id = payload
  },
  SET_FILTER_PROJECT_ALL(state, payload) {
    state.filter_project_all = payload
  },
  SET_IS_LOADING(state, payload) {
    state.is_loading = payload
  },
  SET_IS_SCROLL_POST_SCHEDULED(state, payload) {
    state.is_scroll_post_scheduled = payload
  },
  SET_IS_SCROLL_POST_APPROVE(state, payload) {
    state.is_scroll_post_approve = payload
  },
  SET_IS_SCROLL_POST_REJECT(state, payload) {
    state.is_scroll_post_reject = payload
  },
  SET_IS_SCROLL_POST_PUBLISHED(state, payload) {
    state.is_scroll_post_published = payload
  },
  SET_IS_SCROLL_POST_MEMO(state, payload) {
    state.is_scroll_post_memo = payload
  },
  SET_IS_SCROLL_POST_HISTORY(state, payload) {
    state.is_scroll_post_history = payload
  },
  SET_IS_SCROLL_MONITORING_UNREAD(state, payload) {
    state.is_scroll_monitoring_unread = payload
  },
  SET_IS_SCROLL_MONITORING_PENDING(state, payload) {
    state.is_scroll_monitoring_pending = payload
  },
  SET_IS_SCROLL_MONITORING_MEMO(state, payload) {
    state.is_scroll_monitoring_memo = payload
  },
  SET_IS_SCROLL_MONITORING_HISTORY(state, payload) {
    state.is_scroll_monitoring_history = payload
  },
  SET_IS_POST_SCHEDULED_LOADING(state, payload) {
    state.is_post_scheduled_loading = payload
  },
  SET_IS_POST_APPROVAL_LOADING(state, payload) {
    state.is_post_approval_loading = payload
  },
  SET_IS_POST_REJECT_LOADING(state, payload) {
    state.is_post_reject_loading = payload
  },
  SET_IS_POST_PUBLISHED_LOADING(state, payload) {
    state.is_post_published_loading = payload
  },
  SET_IS_POST_MEMO_LOADING(state, payload) {
    state.is_post_memo_loading = payload
  },
  SET_IS_POST_HISTORY_LOADING(state, payload) {
    state.is_post_history_loading = payload
  },
  SET_IS_MONITORING_UNREAD_LOADING(state, payload) {
    state.is_monitoring_unread_loading = payload
  },
  SET_IS_MONITORING_PENDING_LOADING(state, payload) {
    state.is_monitoring_pending_loading = payload
  },
  SET_IS_MONITORING_MEMO_LOADING(state, payload) {
    state.is_monitoring_memo_loading = payload
  },
  SET_IS_MONITORING_HISTORY_LOADING(state, payload) {
    state.is_monitoring_history_loading = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * 予約の投稿を取得
   */
  async fetchPostScheduled(context, payload: number) {
    context.commit('SET_IS_POST_SCHEDULED_LOADING', true)

    const params: TGetDashboardsPostsParams = {
      status: 'scheduled',
      project_id: context.state.filter_project_all ? null : context.rootState.project.id,
      user_id: context.state.filter_operator_id ? context.state.filter_operator_id : null,
      offset: payload || 0
    }

    const response = await API.get<TGetDashboardsPostsResponse>('dashboards/posts', { params })

    context.commit('SET_IS_POST_SCHEDULED_LOADING', false)

    if (response.data.error && response.data.error.type === 'PERMISSION_DENIED') {
      context.commit('SET_API_POST_SCHEDULED', [])
    }

    if (response.data.data) {
      if (payload) {
        const posts = context.state.api_post_scheduled
          .concat(response.data.data)
          .filter((x, i, self) => self.map(y => y.id).indexOf(x.id) === i)

        context.commit('SET_API_POST_SCHEDULED', posts)
      } else {
        const posts = response.data.data

        context.commit('SET_API_POST_SCHEDULED', posts)
        context.commit('SET_IS_SCROLL_POST_SCHEDULED', true)
      }
    }

    return response.data
  },

  /**
   * 承認待ちの投稿を取得
   */
  async fetchPostApproval(context, payload: number) {
    context.commit('SET_IS_POST_APPROVAL_LOADING', true)

    const params: TGetDashboardsPostsParams = {
      status: 'approval',
      project_id: context.state.filter_project_all ? null : context.rootState.project.id,
      user_id: context.state.filter_operator_id ? context.state.filter_operator_id : null,
      offset: payload || 0
    }

    const response = await API.get<TGetDashboardsPostsResponse>('dashboards/posts', { params })

    context.commit('SET_IS_POST_APPROVAL_LOADING', false)

    if (response.data.error && response.data.error.type === 'PERMISSION_DENIED') {
      context.commit('SET_API_POST_APPROVE', [])
    }

    if (response.data.data) {
      if (payload) {
        const posts = context.state.api_post_approve
          .concat(response.data.data)
          .filter((x, i, self) => self.map(y => y.id).indexOf(x.id) === i)

        context.commit('SET_API_POST_APPROVE', posts)
      } else {
        const posts = response.data.data

        context.commit('SET_API_POST_APPROVE', posts)
        context.commit('SET_IS_SCROLL_POST_APPROVE', true)
      }
    }

    return response.data
  },

  /**
   * 差し戻しの投稿を取得
   */
  async fetchPostReject(context, payload: number) {
    context.commit('SET_IS_POST_REJECT_LOADING', true)

    const params: TGetDashboardsPostsParams = {
      status: 'reject',
      project_id: context.state.filter_project_all ? null : context.rootState.project.id,
      user_id: context.state.filter_operator_id ? context.state.filter_operator_id : null,
      offset: payload || 0
    }

    const response = await API.get<TGetDashboardsPostsResponse>('dashboards/posts', { params })

    context.commit('SET_IS_POST_REJECT_LOADING', false)

    if (response.data.error && response.data.error.type === 'PERMISSION_DENIED') {
      context.commit('SET_API_POST_REJECT', [])
    }

    if (response.data.data) {
      if (payload) {
        const posts = context.state.api_post_reject
          .concat(response.data.data)
          .filter((x, i, self) => self.map(y => y.id).indexOf(x.id) === i)

        context.commit('SET_API_POST_REJECT', posts)
      } else {
        const posts = response.data.data

        context.commit('SET_API_POST_REJECT', posts)
        context.commit('SET_IS_SCROLL_POST_REJECT', true)
      }
    }

    return response.data
  },

  /**
   * 公開済みの投稿を取得
   */
  async fetchPostPublished(context, payload: number) {
    context.commit('SET_IS_POST_PUBLISHED_LOADING', true)

    const params: TGetDashboardsPostsParams = {
      status: 'published',
      project_id: context.state.filter_project_all ? null : context.rootState.project.id,
      user_id: context.state.filter_operator_id ? context.state.filter_operator_id : null,
      offset: payload || 0
    }

    const response = await API.get<TGetDashboardsPostsResponse>('dashboards/posts', { params })

    context.commit('SET_IS_POST_PUBLISHED_LOADING', false)

    if (response.data.error && response.data.error.type === 'PERMISSION_DENIED') {
      context.commit('SET_API_POST_PUBLISHED', [])
    }

    if (response.data.data) {
      if (payload) {
        const posts = context.state.api_post_published
          .concat(response.data.data)
          .filter((x, i, self) => self.map(y => y.id).indexOf(x.id) === i)

        context.commit('SET_API_POST_PUBLISHED', posts)
      } else {
        const posts = response.data.data

        context.commit('SET_API_POST_PUBLISHED', posts)
        context.commit('SET_IS_SCROLL_POST_PUBLISHED', true)
      }
    }

    return response.data
  },

  /**
   * 投稿のメモを取得
   */
  async fetchPostMemo(context, payload: number) {
    context.commit('SET_IS_POST_MEMO_LOADING', true)

    const params: TGetDashboardsPostMemosParams = {
      project_id: context.state.filter_project_all ? null : context.rootState.project.id,
      user_id: context.state.filter_operator_id ? context.state.filter_operator_id : null,
      offset: payload || 0
    }

    const response = await API.get<TGetDashboardsPostMemosResponse>('dashboards/post_memos', {
      params
    })

    context.commit('SET_IS_POST_MEMO_LOADING', false)

    if (response.data.error && response.data.error.type === 'PERMISSION_DENIED') {
      context.commit('SET_API_POST_MEMO', [])
    }

    if (response.data.data) {
      if (payload) {
        const memos = context.state.api_post_memo
          .concat(response.data.data)
          .filter((x, i, self) => self.map(y => y.id).indexOf(x.id) === i)

        context.commit('SET_API_POST_MEMO', memos)
      } else {
        const memos = response.data.data

        context.commit('SET_API_POST_MEMO', memos)
        context.commit('SET_IS_SCROLL_POST_MEMO', true)
      }
    }

    return response.data
  },

  /**
   * 投稿の履歴を取得
   */
  async fetchPostHistory(context, payload: number) {
    context.commit('SET_IS_POST_HISTORY_LOADING', true)

    const params: TGetDashboardsPostHistoriesParams = {
      project_id: context.state.filter_project_all ? null : context.rootState.project.id,
      user_id: context.state.filter_operator_id ? context.state.filter_operator_id : null,
      offset: payload || 0
    }

    const response = await API.get<TGetDashboardsPostHistoriesResponse>(
      'dashboards/post_histories',
      { params }
    )

    context.commit('SET_IS_POST_HISTORY_LOADING', false)

    if (response.data.error && response.data.error.type === 'PERMISSION_DENIED') {
      context.commit('SET_API_POST_HISTORY', [])
    }

    if (response.data.data) {
      if (payload) {
        const histories = context.state.api_post_history
          .concat(response.data.data)
          .filter((x, i, self) => self.map(y => y.id).indexOf(x.id) === i)

        context.commit('SET_API_POST_HISTORY', histories)
      } else {
        const histories = response.data.data

        context.commit('SET_API_POST_HISTORY', histories)
        context.commit('SET_IS_SCROLL_POST_HISTORY', true)
      }
    }

    return response.data
  },

  /**
   * 未読のモニタリングを取得
   */
  async fetchMonitoringUnread(context, payload: number) {
    context.commit('SET_IS_MONITORING_UNREAD_LOADING', true)

    const params: TGetDashboardsMonitoringsParams = {
      status: 'unread',
      project_id: context.state.filter_project_all ? null : context.rootState.project.id,
      user_id: context.state.filter_operator_id ? context.state.filter_operator_id : null,
      offset: payload || 0
    }

    const response = await API.get<TGetDashboardsMonitoringsResponse>('dashboards/monitorings', {
      params
    })

    context.commit('SET_IS_MONITORING_UNREAD_LOADING', false)

    if (response.data.error && response.data.error.type === 'PERMISSION_DENIED') {
      context.commit('SET_API_MONITORING_UNREAD', [])
    }

    if (response.data.data) {
      if (payload) {
        const monitorings = context.state.api_monitoring_unread
          .concat(response.data.data)
          .filter((x, i, self) => self.map(y => y.id + y.sns).indexOf(x.id + x.sns) === i)

        context.commit('SET_API_MONITORING_UNREAD', monitorings)
      } else {
        const monitorings = response.data.data

        context.commit('SET_API_MONITORING_UNREAD', monitorings)
        context.commit('SET_IS_SCROLL_MONITORING_UNREAD', true)
      }
    }

    return response.data
  },

  /**
   * 保留中のモニタリングを取得
   */
  async fetchMonitoringPending(context, payload: number) {
    context.commit('SET_IS_MONITORING_PENDING_LOADING', true)

    const params: TGetDashboardsMonitoringsParams = {
      status: 'pending',
      project_id: context.state.filter_project_all ? null : context.rootState.project.id,
      user_id: context.state.filter_operator_id ? context.state.filter_operator_id : null,
      offset: payload || 0
    }

    const response = await API.get<TGetDashboardsMonitoringsResponse>('dashboards/monitorings', {
      params
    })

    context.commit('SET_IS_MONITORING_PENDING_LOADING', false)

    if (response.data.error && response.data.error.type === 'PERMISSION_DENIED') {
      context.commit('SET_API_MONITORING_PENDING', [])
    }

    if (response.data.data) {
      if (payload) {
        const monitorings = context.state.api_monitoring_pending
          .concat(response.data.data)
          .filter((x, i, self) => self.map(y => y.id + y.sns).indexOf(x.id + x.sns) === i)

        context.commit('SET_API_MONITORING_PENDING', monitorings)
      } else {
        const monitorings = response.data.data

        context.commit('SET_API_MONITORING_PENDING', monitorings)
        context.commit('SET_IS_SCROLL_MONITORING_PENDING', true)
      }
    }

    return response.data
  },

  /**
   * モニタリングのメモを取得
   */
  async fetchMonitoringMemo(context, payload: number) {
    context.commit('SET_IS_MONITORING_MEMO_LOADING', true)

    const params: TGetDashboardsMonitoringMemosParams = {
      project_id: context.state.filter_project_all ? null : context.rootState.project.id,
      user_id: context.state.filter_operator_id ? context.state.filter_operator_id : null,
      offset: payload || 0
    }

    const response = await API.get<TGetDashboardsMonitoringMemosResponse>(
      'dashboards/monitoring_memos',
      { params }
    )

    context.commit('SET_IS_MONITORING_MEMO_LOADING', false)

    if (response.data.error && response.data.error.type === 'PERMISSION_DENIED') {
      context.commit('SET_API_MONITORING_MEMO', [])
    }

    if (response.data.data) {
      if (payload) {
        const memos = context.state.api_monitoring_memo
          .concat(response.data.data)
          .filter(
            (x, i, self) =>
              self.map(y => y.id + y.monitoring.sns).indexOf(x.id + x.monitoring.sns) === i
          )

        context.commit('SET_API_MONITORING_MEMO', memos)
      } else {
        const memos = response.data.data

        context.commit('SET_API_MONITORING_MEMO', memos)
        context.commit('SET_IS_SCROLL_MONITORING_MEMO', true)
      }
    }

    return response.data
  },

  /**
   * モニタリングの履歴を取得
   */
  async fetchMonitoringHistory(context, payload: number) {
    context.commit('SET_IS_MONITORING_HISTORY_LOADING', true)

    const params: TGetDashboardsMonitoringHistoriesParams = {
      project_id: context.state.filter_project_all ? null : context.rootState.project.id,
      user_id: context.state.filter_operator_id ? context.state.filter_operator_id : null,
      offset: payload || 0
    }

    const response = await API.get<TGetDashboardsMonitoringHistoriesResponse>(
      'dashboards/monitoring_histories',
      { params }
    )

    context.commit('SET_IS_MONITORING_HISTORY_LOADING', false)

    if (response.data.error && response.data.error.type === 'PERMISSION_DENIED') {
      context.commit('SET_API_MONITORING_HISTORY', [])
    }

    if (response.data.data) {
      if (payload) {
        const histories = context.state.api_monitoring_history
          .concat(response.data.data)
          .filter(
            (x, i, self) =>
              self.map(y => y.id + y.monitoring.sns).indexOf(x.id + x.monitoring.sns) === i
          )

        context.commit('SET_API_MONITORING_HISTORY', histories)
      } else {
        const histories = response.data.data

        context.commit('SET_API_MONITORING_HISTORY', histories)
        context.commit('SET_IS_SCROLL_MONITORING_HISTORY', true)
      }
    }

    return response.data
  },

  /**
   * ダッシュボードのオペレーターを取得
   */
  async fetchDashboardOperators(context) {
    const params: TGetDashboardsOperatorsParams = {
      project_id: context.state.filter_project_all ? null : context.rootState.project.id
    }

    const response = await API.get<TGetDashboardsOperatorsResponse>('dashboards/operators', {
      params
    })

    if (response.data.data) {
      const operators = response.data.data

      context.commit('SET_API_OPERATORS', operators)

      const is_exist_operator = operators.some(v => v.id === context.state.filter_operator_id)

      const filter_operator_id = is_exist_operator ? context.state.filter_operator_id : ''

      context.commit('SET_FILTER_OPERATOR_ID', filter_operator_id)
    }
  },

  /**
   * ダッシュボード情報を取得
   */
  async fetchDashboardPage(context) {
    context.commit('SET_IS_LOADING', true)

    const response = await API.get<TGetUserDashboardSettingsResponse>('user_dashboard_settings')

    context.commit('SET_IS_LOADING', false)

    if (response.data.data) {
      const settings = response.data.data

      context.commit('SET_API_DASHBOARD_SETTINGS', settings)
    }

    const setting = context.state.api_dashboard_settings.filter(v => v.is_show)

    await Promise.all(
      setting.map(v => {
        switch (v.item) {
          case 'post_scheduled':
            return context.dispatch('fetchPostScheduled')
          case 'post_approve':
            return context.dispatch('fetchPostApproval')
          case 'post_reject':
            return context.dispatch('fetchPostReject')
          case 'post_published':
            return context.dispatch('fetchPostPublished')
          case 'post_memo':
            return context.dispatch('fetchPostMemo')
          case 'post_history':
            return context.dispatch('fetchPostHistory')
          case 'monitoring_unread':
            return context.dispatch('fetchMonitoringUnread')
          case 'monitoring_pending':
            return context.dispatch('fetchMonitoringPending')
          case 'monitoring_memo':
            return context.dispatch('fetchMonitoringMemo')
          case 'monitoring_history':
            return context.dispatch('fetchMonitoringHistory')
          default:
            return Promise.resolve()
        }
      })
    )
  },

  /**
   * ページ表示時の処理
   */
  async createdDashboardPage(context) {
    await context.dispatch('fetchDashboardOperators')
    await context.dispatch('fetchDashboardPage')
  },

  /**
   * ページ離脱時の処理
   */
  async destroyedDashboardPage(context) {
    context.commit('SET_API_DASHBOARD_SETTINGS', [])
    context.commit('SET_API_POST_SCHEDULED', [])
    context.commit('SET_API_POST_APPROVE', [])
    context.commit('SET_API_POST_REJECT', [])
    context.commit('SET_API_POST_PUBLISHED', [])
    context.commit('SET_API_POST_MEMO', [])
    context.commit('SET_API_POST_HISTORY', [])
    context.commit('SET_API_MONITORING_UNREAD', [])
    context.commit('SET_API_MONITORING_PENDING', [])
    context.commit('SET_API_MONITORING_MEMO', [])
    context.commit('SET_API_MONITORING_HISTORY', [])
    context.commit('SET_API_OPERATORS', [])
    context.commit('SET_FILTER_OPERATOR_ID', '')
    context.commit('SET_FILTER_PROJECT_ALL', false)
    context.commit('SET_IS_LOADING', true)
    context.commit('SET_IS_SCROLL_POST_SCHEDULED', true)
    context.commit('SET_IS_SCROLL_POST_APPROVE', true)
    context.commit('SET_IS_SCROLL_POST_REJECT', true)
    context.commit('SET_IS_SCROLL_POST_PUBLISHED', true)
    context.commit('SET_IS_SCROLL_POST_MEMO', true)
    context.commit('SET_IS_SCROLL_POST_HISTORY', true)
    context.commit('SET_IS_SCROLL_MONITORING_UNREAD', true)
    context.commit('SET_IS_SCROLL_MONITORING_PENDING', true)
    context.commit('SET_IS_SCROLL_MONITORING_MEMO', true)
    context.commit('SET_IS_SCROLL_MONITORING_HISTORY', true)
  },

  /**
   * ダッシュボードページの更新
   */
  async refreshDashboardPage(context) {
    await context.dispatch('createdDashboardPage')
  },

  /**
   * オペレーターを変更
   */
  async changeOperatorId(context, payload: '' | number) {
    context.commit('SET_FILTER_OPERATOR_ID', payload)

    await context.dispatch('fetchDashboardPage')
  },

  /**
   * 全グループのフラグを変更
   */
  async changeProjectAll(context, payload: boolean) {
    context.commit('SET_FILTER_PROJECT_ALL', payload)

    await context.dispatch('fetchDashboardOperators')
    await context.dispatch('fetchDashboardPage')
  },

  /**
   * チュートリアルを閉じる
   */
  async closeTutorialHelp(context) {
    const params: TPutUserDashboardSettingsParams = {
      item: 'tutorial_help',
      is_show: false
    }

    const response = await API.put<TPutUserDashboardSettingsResponse>(
      'user_dashboard_settings',
      params
    )

    if (response.data.data) {
      const settings = context.state.api_dashboard_settings.map(v => {
        if (v.item === 'tutorial_help') {
          return { ...v, is_show: false }
        }
        return v
      })

      context.commit('SET_API_DASHBOARD_SETTINGS', settings)
    }
  },

  /**
   * 予約の投稿をスクロール
   */
  async scrollPostScheduled(context) {
    let count = context.state.api_post_scheduled.length

    await context.dispatch('fetchPostScheduled', context.state.api_post_scheduled.length)

    count = context.state.api_post_scheduled.length - count

    if (!count) {
      context.commit('SET_IS_SCROLL_POST_SCHEDULED', false)
    }
  },

  /**
   * 承認待ちの投稿をスクロール
   */
  async scrollPostApproval(context) {
    let count = context.state.api_post_approve.length

    await context.dispatch('fetchPostApproval', context.state.api_post_approve.length)

    count = context.state.api_post_approve.length - count

    if (!count) {
      context.commit('SET_IS_SCROLL_POST_APPROVE', false)
    }
  },

  /**
   * 差し戻しの投稿をスクロール
   */
  async scrollPostReject(context) {
    let count = context.state.api_post_reject.length

    await context.dispatch('fetchPostReject', context.state.api_post_reject.length)

    count = context.state.api_post_reject.length - count

    if (!count) {
      context.commit('SET_IS_SCROLL_POST_REJECT', false)
    }
  },

  /**
   * 公開済みの投稿をスクロール
   */
  async scrollPostPublished(context) {
    let count = context.state.api_post_published.length

    await context.dispatch('fetchPostPublished', context.state.api_post_published.length)

    count = context.state.api_post_published.length - count

    if (!count) {
      context.commit('SET_IS_SCROLL_POST_PUBLISHED', false)
    }
  },

  /**
   * 投稿のメモをスクロール
   */
  async scrollPostMemo(context) {
    let count = context.state.api_post_memo.length

    await context.dispatch('fetchPostMemo', context.state.api_post_memo.length)

    count = context.state.api_post_memo.length - count

    if (!count) {
      context.commit('SET_IS_SCROLL_POST_MEMO', false)
    }
  },

  /**
   * 投稿の履歴をスクロール
   */
  async scrollPostHistory(context) {
    let count = context.state.api_post_history.length

    await context.dispatch('fetchPostHistory', context.state.api_post_history.length)

    count = context.state.api_post_history.length - count

    if (!count) {
      context.commit('SET_IS_SCROLL_POST_HISTORY', false)
    }
  },

  /**
   * 未読のモニタリングをスクロール
   */
  async scrollMonitoringUnread(context) {
    let count = context.state.api_monitoring_unread.length

    await context.dispatch('fetchMonitoringUnread', context.state.api_monitoring_unread.length)

    count = context.state.api_monitoring_unread.length - count

    if (!count) {
      context.commit('SET_IS_SCROLL_MONITORING_UNREAD', false)
    }
  },

  /**
   * 保留中のモニタリングをスクロール
   */
  async scrollMonitoringPending(context) {
    let count = context.state.api_monitoring_pending.length

    await context.dispatch('fetchMonitoringPending', context.state.api_monitoring_pending.length)

    count = context.state.api_monitoring_pending.length - count

    if (!count) {
      context.commit('SET_IS_SCROLL_MONITORING_PENDING', false)
    }
  },

  /**
   * モニタリングのメモをスクロール
   */
  async scrollMonitoringMemo(context) {
    let count = context.state.api_monitoring_memo.length

    await context.dispatch('fetchMonitoringMemo', context.state.api_monitoring_memo.length)

    count = context.state.api_monitoring_memo.length - count

    if (!count) {
      context.commit('SET_IS_SCROLL_MONITORING_MEMO', false)
    }
  },

  /**
   * モニタリングの履歴をスクロール
   */
  async scrollMonitoringHistory(context) {
    let count = context.state.api_monitoring_history.length

    await context.dispatch('fetchMonitoringHistory', context.state.api_monitoring_history.length)

    count = context.state.api_monitoring_history.length - count

    if (!count) {
      context.commit('SET_IS_SCROLL_MONITORING_HISTORY', false)
    }
  },

  /**
   * 投稿ダイアログの更新時
   */
  async updatePostDialog(context) {
    await Promise.all([
      context.dispatch('fetchPostScheduled'),
      context.dispatch('fetchPostApproval'),
      context.dispatch('fetchPostPublished'),
      context.dispatch('fetchPostReject'),
      context.dispatch('fetchPostMemo'),
      context.dispatch('fetchPostHistory')
    ])
  },

  /**
   * 投稿ダイアログの承認時
   */
  async approvedPostDialog(context, payload: number) {
    const response = await API.get<IGetPostResponse>(`posts/${payload}`)

    if (response.data.data) {
      let posts = []

      if (response.data.data.status === 'approval') {
        posts = context.state.api_post_approve.map(v => {
          if (v.id === response.data.data.id) {
            return { ...v, post_approval_flow: response.data.data.post_approval_flow }
          } else {
            return v
          }
        })
      } else {
        posts = context.state.api_post_approve.filter(v => v.id !== response.data.data.id)
      }

      context.commit('SET_API_POST_APPROVE', posts)
    }

    await Promise.all([
      context.dispatch('fetchPostScheduled'),
      context.dispatch('fetchPostPublished'),
      context.dispatch('fetchPostReject'),
      context.dispatch('fetchPostMemo'),
      context.dispatch('fetchPostHistory')
    ])
  },

  /**
   * モニタリングダイアログの更新時
   */
  async updateMonitoringDialog(context) {
    await Promise.all([
      context.dispatch('fetchMonitoringUnread'),
      context.dispatch('fetchMonitoringPending'),
      context.dispatch('fetchMonitoringMemo'),
      context.dispatch('fetchMonitoringHistory')
    ])
  },

  /**
   * グループ変更時の処理
   */
  async changeGroupDashboardPage(context) {
    await context.dispatch('fetchDashboardOperators')

    if (!context.state.filter_project_all) {
      await context.dispatch('fetchDashboardPage')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
