import { Component, Emit, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import ButtonCta from '@/client/components-old/atoms/ButtonCta'
import ExternalLink from '@/client/components-old/atoms/ExternalLink'
import Panel from '@/client/components-old/atoms/Panel'
import { IRootState } from '@/client/store/global'

@Component({
  name: 'DashboardAccountRegister',
  components: {
    Panel,
    ButtonCta,
    ExternalLink
  }
})
export default class DashboardAccountRegister extends Vue {
  @State('project_role') project_role: IRootState['project_role']

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  /**
   * SNSアカウントの設定画面に移動
   */
  moveAccountSetting() {
    this.changeRoute({
      name: 'setting_group',
      query: { screen_name: 'account', dialog: true }
    })
  }

  /**
   * オペレーターの設定画面に移動
   */
  moveOperatorSetting() {
    this.changeRoute({
      name: 'setting_group',
      query: { screen_name: 'operator' }
    })
  }
}
