
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Action, namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import EmbedTwitterTimeline from '@/client/components-old/atoms/EmbedTwitterTimeline'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  IPostLoginFacebookParams,
  IPostLoginFacebookResponse,
  IPostLoginParams,
  IPostLoginResponse
} from '@/client/utils/api/login'
import { getLoginStatus, login } from '@/client/utils/facebook-sdk'
import { MAIL_FORMAT } from '@/client/utils/regex'

const notification = namespace('notification')

@Component({
  name: 'LoginForm',
  components: {
    Button,
    ButtonLink,
    EmbedTwitterTimeline,
    Message,
    Input,
    Icon
  }
})
export default class LoginForm extends Vue {
  @State('user') user!: IRootState['user']
  @Action('login') login!: any
  @notification.Action('showNotification') showNotification!: any

  @Prop({ type: String, default: null })
  redirect!: string | null

  form: {
    email: string
    password: string
  } = {
    email: '',
    password: ''
  }

  is_email_error = false
  is_email_format_error = false
  is_password_error = false

  loading = false

  get is_lang_ja() {
    return this.user.language === 'ja'
  }

  get error_email() {
    return this.is_email_error || this.is_email_format_error
  }

  get error_password() {
    return this.is_password_error
  }

  get disabled() {
    const is_email = !this.form.email || this.error_email
    const is_password = !this.form.password || this.error_password

    return this.loading || is_email || is_password
  }

  /**
   * メールアドレスの変更
   */
  changeEmail(value: string) {
    this.is_email_error = !value
    this.is_email_format_error = !MAIL_FORMAT.test(value)
  }

  /**
   * メールアドレスの変更
   */
  changePassword(value: string) {
    this.is_password_error = !value
  }

  /**
   * パスワード再設定に移動
   */
  movePasswordRequest() {
    this.changeRoute({ name: 'password_request' })
  }

  /**
   * フォームでログイン
   */
  async loginForm() {
    if (this.disabled) return

    const params: IPostLoginParams = {
      ...this.form
    }

    this.loading = true

    const response = await API.post<IPostLoginResponse>('login', params)

    this.loading = false

    if (response.data.data) {
      if (response.data.data?.status === 'success') {
        await this.login()

        await this.showNotification({ title: 'ログインしました。' })

        await this.changeRoute(this.redirect ? this.redirect : { name: 'dashboard' })

        return
      }

      if (response.data.data?.status === 'two_factor') {
        return this.changeTwoFactor(params.email)
      }

      if (response.data.data?.status === 'lock') {
        return this.showNotification({
          title: 'ログインを一時的にロックしました。',
          message: 'しばらく時間を空けるか、パスワードを再設定してからログインしてください。',
          type: 'error'
        })
      }
    }

    this.showNotification({
      title: 'ログインに失敗しました。',
      message: 'メールアドレスまたはパスワードが間違っています。',
      type: 'error'
    })
  }

  /**
   * フェイスブックでログイン
   */
  async loginFacebook() {
    let res = await getLoginStatus()

    if (res.status !== 'connected') {
      res = await login()
    }

    if (res.status === 'connected') {
      const params: IPostLoginFacebookParams = {
        access_token: res.authResponse.accessToken
      }

      if (this.form.email) {
        params.email = this.form.email
      }

      this.loading = true

      const response = await API.post<IPostLoginFacebookResponse>('login/facebook', params)

      if (response.data.data) {
        if (response.data.data.status === 'success') {
          await this.login()

          await this.showNotification({ title: 'ログインしました。' })

          await this.changeRoute(this.redirect ? this.redirect : { name: 'dashboard' })

          return
        }
      }
    }

    this.loading = false

    this.showNotification({
      title: 'Facebookログインに失敗しました。',
      type: 'error'
    })
  }

  @Emit('change-route')
  changeRoute(payload: any): any {
    return payload
  }

  @Emit('change-two-factor')
  changeTwoFactor(payload: string): any {
    return payload
  }
}
