import { RouteConfig } from 'vue-router'

import TikTokAnalytics from './pages/TikTokAnalytics.vue'

export const analytics_tt: RouteConfig = {
  component: TikTokAnalytics,
  path: '/analytics_tt',
  name: 'analytics_tt',
  meta: {
    auth: true,
    title: '自社アカウント分析 (TikTok)',
    description: '選択したアカウントの分析結果を確認できます。',
    color: 'warning',
    type: 'CHECK_OWN_ANALYSIS'
  }
}
