import moment from 'moment-timezone'
import { Component, Vue } from 'vue-property-decorator'

import * as calculation from '@/client/components-old/_utils/calculation'
import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import ChartVertical from '@/client/components-old/molecules/ChartVertical'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'
type IHourlyChartDialogOption = {
  title: string
  description?: string
  time_range: {
    start: string
    end: string
  }
  categories: string[]
  series: {
    name: string
    data: number[]
    index: number
  }[]
  colors: string[]
  display_legend: boolean
}

@Component({
  name: 'HourlyChartDialog',
  components: {
    ChartVertical,
    Dialog,
    Flex,
    Button,
    Icon,
    Tooltip
  }
})
export default class HourlyChartDialog extends Vue {
  visible = false
  viewTitle = ''
  options: IHourlyChartDialogOption = {
    title: '',
    description: '',
    time_range: {
      start: '',
      end: ''
    },
    series: [
      {
        name: '',
        data: [],
        index: 0
      }
    ],
    colors: [],
    display_legend: false,
    categories: []
  }

  get start_date() {
    return moment(this.options.time_range.start).format('YYYY-MM-DD')
  }

  get end_date() {
    return moment(this.options.time_range.end).format('YYYY-MM-DD')
  }

  get time_range_name() {
    const start = moment(this.options.time_range.start).format('lll')
    const end = moment(this.options.time_range.end).format('lll')
    return `${start} - ${end}`
  }

  get chart_options() {
    return {
      chart_type: 'column',
      y_label: i18n.t('回数'),
      display_value: false,
      display_legend: this.options.display_legend,
      display_line: true,
      display_average: false,
      categories: this.options.categories,
      colors: this.options.colors,
      series: this.options.series
    }
  }

  get is_data() {
    const { start, end } = this.options.time_range

    return start && end
  }

  /**
   * ダイアログの表示
   * @param {IHourlyChartDialogOption} options オプション
   * @returns {void}
   */
  open(options: IHourlyChartDialogOption): void {
    this.visible = true

    if (options) {
      this.viewTitle = `${i18n.t(options.title)} [${i18n.t('時間帯別')}]`
      this.options = options
    }
  }

  /**
   * ダイアログの非表示
   * @returns {void}
   */
  hide(): void {
    this.visible = false
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    const fields = [i18n.t('日時'), this.options.title + `[${i18n.t('時間帯別')}]`]

    if (this.chart_options.series.length > 1) {
      fields.push(...this.chart_options.series.map(element => element.name))
    }

    const data = this.options.categories.map((time, key) => {
      if (this.chart_options.series.length === 1) {
        return [
          moment(this.options.time_range.start).add(key, 'hours').format('YYYY-MM-DD HH:00'),
          ...this.chart_options.series.map(element => element.data[key])
        ]
      }

      // 複数ターゲットの場合、合計を計算
      let total = calculation.addition(this.options.series.map(element => element.data[key]))
      if (this.options.series.every(element => element.data[key] === null)) {
        total = null
      }

      return [
        moment(this.options.time_range.start).add(key, 'hours').format('YYYY-MM-DD HH:00'),
        total,
        ...this.chart_options.series.map(element => element.data[key])
      ]
    })

    const csv_data = { fields, data }

    const component_name = `${i18n.t('グラフ')}[${this.$options.filters
      .translate('時間帯別')
      .replace(/\s/g, '_')}]`
    const metric_name = i18n.t(this.options.title)

    const csv_filename = [component_name, metric_name, this.start_date, this.end_date].join('_')

    csv.download(csv_data, csv_filename)
  }
}
