import * as calculation from '@/client/components-old/_utils/calculation'

import { TikTokInsightsPosts } from '../api'
import { IMPRESSION_SOURCE_METRICS } from '../constants'

export function getAggregateImpressionSources(posts: TikTokInsightsPosts[]) {
  return IMPRESSION_SOURCE_METRICS.map(metric => {
    const sources = posts.flatMap(v =>
      v.impression_sources.filter(source => source.impression_source === metric.value)
    )

    const total_percentage = sources.map(v => v.percentage).reduce((p, c) => p + c, 0)
    const total_count = sources.map(v => v.count).reduce((p, c) => p + c, 0)

    return {
      impression_source: metric.value,
      percentage: calculation.division(total_percentage, posts.length, 4),
      count: total_count
    }
  })
}
