import { render, staticRenderFns } from "./PostDetailApprovalForm.vue?vue&type=template&id=0d05226d&scoped=true"
import script from "./PostDetailApprovalForm.ts?vue&type=script&lang=ts&external"
export * from "./PostDetailApprovalForm.ts?vue&type=script&lang=ts&external"
import style0 from "./PostDetailApprovalForm.vue?vue&type=style&index=0&id=0d05226d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d05226d",
  null
  
)

export default component.exports