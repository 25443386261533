
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Badge'
})
export default class Badge extends Vue {
  @Prop({ type: String, default: 'default' })
  type!: boolean

  get badge_class() {
    return ['badge-root', `badge-root-${this.type}`]
  }
}
