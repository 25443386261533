import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import DashboardLabel from '@/client/components-old/molecules/DashboardLabel'
import { TDashboardsMonitoringHistory, TDashboardsPostHistory } from '@/client/utils/api/dashboards'
import i18n from '@/client/utils/i18n'

@Component({
  name: 'DashboardHistoryItem',
  components: {
    Icon,
    Tooltip,
    Account,
    DashboardLabel
  }
})
export default class DashboardHistoryItem extends Vue {
  @Prop({ type: String, required: true })
  type!: 'post' | 'monitoring'

  @Prop({ type: Object, required: true })
  history!: TDashboardsPostHistory & TDashboardsMonitoringHistory

  @Prop({ type: Boolean, required: false })
  isShowGroup!: boolean

  get datetime() {
    return moment(this.history.datetime).format('lll')
  }

  get is_scheduled_time() {
    return this.type === 'post' && this.history.post.scheduled_time
  }

  get scheduled_time() {
    return moment(this.history.post.scheduled_time).format('lll')
  }

  get is_remove_post() {
    return this.type === 'post' && this.history.post.id === null
  }

  get is_system_publish() {
    return (
      this.history.type === 'monitoring_comment_edit' ||
      ((this.history.type === 'post_published' || this.history.type === 'post_failed') &&
        (!this.history.user || !this.history.user.id))
    )
  }

  get message() {
    switch (this.type) {
      case 'post':
        return this.history.post.message || i18n.t('この投稿は内容がありません')

      case 'monitoring':
        return this.history.monitoring.message || i18n.t('このアクションはメッセージがありません')

      default:
        return ''
    }
  }

  get is_story_by_post() {
    return this.type === 'post' && this.history.post.type === 'story'
  }

  @Emit('open')
  open() {
    switch (this.type) {
      case 'post':
        return {
          sns_post_id: this.history.post.id,
          project_id: this.history.project.id
        }

      case 'monitoring':
        return {
          monitoring_id: this.history.monitoring.id,
          monitoring_sns: this.history.monitoring.sns,
          project_id: this.history.project.id
        }

      default:
        return {}
    }
  }
}
