import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/twitter_analytics'
import * as calculation from '@/client/components-old/_utils/calculation'
import * as twitter_insight from '@/client/components-old/_utils/twitter_insight'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import AnalyticsTotalTable from '@/client/components-old/molecules/AnalyticsTotalTable'
import { IRootState } from '@/client/store/global'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as ITwitterState } from '@/client/store/modules/twitter_analytics'
import csv from '@/client/utils/csv'

type TTwitterAnalyticsTotalTableOptions = {
  title: string
  data_type: 'daily_data' | 'post_data'
  metrics: string[]
  unit: 'total' | 'average'
  interval: '7_day' | '14_day' | '1_month' | '3_month'
  post_types: string[]
  post_tags: number[]
}

const analytics = namespace('analytics')
const twitter = namespace('twitter_analytics')
const categories = namespace('categories')

@Component({
  name: 'TwitterAnalyticsTotalTable',
  components: {
    AnalyticsPanel,
    AnalyticsPanelHeader,
    AnalyticsTotalTable
  }
})
export default class TwitterAnalyticsTotalTable extends Vue {
  @State('user') user: IRootState['user']
  @categories.State('api_post_categories')
  api_post_categories: ICategoriesState['api_post_categories']
  @categories.State('api_category_posts') api_category_posts: ICategoriesState['api_category_posts']
  @twitter.State('api_accounts') daily_data: ITwitterState['api_accounts']
  @twitter.State('api_posts') post_data: ITwitterState['api_posts']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TTwitterAnalyticsTotalTableOptions

  @Prop({ type: Boolean, default: false })
  isPreview: boolean

  @Prop({ type: Boolean, default: false })
  isEdit: boolean

  get is_csv_import() {
    return this.post_data.some(post => post.is_csv_import)
  }

  get is_data() {
    switch (this.options.data_type) {
      case 'daily_data':
        return this.daily_data.length > 0 && this.options.metrics.length > 0
      case 'post_data':
        return this.post_data.length > 0 && this.options.metrics.length > 0
      default:
        return false
    }
  }

  get is_download() {
    return this.is_data && !this.isPreview
  }

  get is_edit() {
    return !this.isPreview && this.isEdit
  }

  get title_name() {
    return this.options.title
  }

  get show_post_types() {
    if (this.options.data_type !== 'post_data') return []

    return ['text', 'photo', 'link', 'video', 'animation-gif'].filter(v =>
      this.options.post_types.includes(v)
    )
  }

  get post_tags_names() {
    if (this.options.data_type !== 'post_data') return []

    return this.getPostTagNames(this.options.post_tags)
  }

  get panel_type() {
    const data_type = constants.DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get interval_date() {
    const temp_date = moment(this.start_date)

    const date_interval: [string, string][] = []

    while (temp_date.isSameOrBefore(this.end_date)) {
      const start_date = temp_date.format()

      switch (this.options.interval) {
        case '7_day':
          temp_date.add(6, 'days').endOf('day')
          break
        case '14_day':
          temp_date.add(13, 'days').endOf('day')
          break
        case '1_month':
          temp_date.endOf('month')
          break
        case '3_month':
          temp_date.add(2, 'months').endOf('month')
          break
      }

      const end_date = temp_date.format()

      date_interval.push([start_date, end_date])

      temp_date.add(1, 'days').startOf('day')
    }

    return date_interval
  }

  get table_data() {
    return { title: this.table_title, headers: this.table_header, rows: this.table_rows }
  }

  get table_title() {
    const unit = constants.UNITS.find(unit => unit.value === this.options.unit)
    const unit_name = unit ? this.$options.filters.translate(unit.text) : ''
    return `${this.$options.filters.translate('指標')} [${unit_name}]`
  }

  get table_header() {
    return this.interval_date.map(interval => {
      let format = ''
      let start_text = ''
      let end_text = ''

      switch (this.options.interval) {
        case '7_day':
        case '14_day':
          start_text = moment(interval[0]).format('M/D')
          end_text = moment(interval[1]).format('M/D')
          return `${start_text} - ${end_text}`

        case '1_month':
          format = this.user.language === 'en' ? `YYYY/M` : 'YYYY年M月'
          return moment(interval[0]).format(format)

        case '3_month':
          format = this.user.language === 'en' ? `YYYY/M` : 'YYYY年M月'
          start_text = moment(interval[0]).format(format)
          end_text = moment(interval[1]).format(format)
          return `${start_text} - ${end_text}`

        default:
          return ''
      }
    })
  }

  get table_rows() {
    return this.options.metrics.map(metric => {
      const { data_type, unit, post_types, post_tags } = this.options

      let temp_metric
      let temp_insights

      switch (data_type) {
        case 'daily_data':
          temp_metric = constants.DAILY_DATA_METRICS.find(item => item.value === metric)
          temp_insights = twitter_insight.filterDailyData(this.daily_data, this.post_data, metric)
          break
        case 'post_data':
          temp_metric = constants.POST_DATA_METRICS.find(item => item.value === metric)
          temp_insights = twitter_insight.filterPostData(this.post_data, metric)

          if (post_types?.length) {
            temp_insights = temp_insights.filter(post => post_types.includes(post.type))
          }

          if (post_tags?.length) {
            temp_insights = temp_insights.filter(post => {
              const post_tag_ids = this.getSnsPostTags(post.post_id)

              return post_tag_ids.some(post_tag_id => post_tags.includes(post_tag_id))
            })
          }
          break
      }

      const metric_name = temp_metric ? temp_metric.text : ''
      const metric_value = temp_metric ? temp_metric.value : ''

      const total_values = this.interval_date.map(interval => {
        const interval_insights = temp_insights.filter(insight => {
          const insight_time: number = insight.created_time || insight.end_time
          return moment(insight_time).isBetween(interval[0], interval[1], null, '[]')
        })

        switch (unit) {
          case 'total':
            switch (metric) {
              case 'reactions_rate':
              case 'engagements_rate':
                return null
              default:
                return twitter_insight.getMetricTotal(interval_insights, metric)
            }
          case 'average':
            return twitter_insight.getMetricAverage(interval_insights, metric)
          default:
            return null
        }
      })

      return {
        metric: this.$options.filters.translate(metric_name),
        metric_value,
        columns: total_values.map((total_value, index, array) => {
          if (total_value === null) {
            return { value: '-', updown: '-' }
          }

          const value = twitter_insight.convertValueWithMetric(total_value, metric)

          if (index === 0) {
            return { value, updown: '-' }
          }

          let updown = '-'
          const temp_total_current = total_value
          const temp_total_prev = array[index === 0 ? index : index - 1]

          const numerator = temp_total_current - temp_total_prev
          // 数値がマイナスの場合、増減率が必ずマイナスとなる為、絶対値に変換する
          const denominator = temp_total_prev === 0 ? 100 : Math.abs(temp_total_prev)
          const result = calculation.percentage(numerator, denominator)

          if (metric.endsWith('_rate')) {
            if (Math.sign(result) === 1) {
              updown = `+${(temp_total_current - temp_total_prev).toFixed(2)}pt`
            }

            if (Math.sign(result) === -1) {
              updown = `${(temp_total_current - temp_total_prev).toFixed(2)}pt`
            }
          } else {
            if (Math.sign(result) === 1) {
              updown = `+${this.$options.filters.percent(result)}`
            }

            if (Math.sign(result) === -1) {
              updown = this.$options.filters.percent(result)
            }
          }

          if (Math.sign(result) === 0) {
            updown = `-`
          }

          return { value, updown }
        })
      }
    })
  }

  @Emit('edit')
  onEdit() {
    return this.options
  }

  @Emit('delete')
  onDelete(event) {
    return event
  }

  getPostTagNames(post_tag_ids: number[]): string[] {
    if (!post_tag_ids.length) return []

    return this.api_post_categories.filter(tag => post_tag_ids.includes(tag.id)).map(v => v.name)
  }

  getSnsPostTags(post_id: string): number[] {
    const post_tag = this.api_category_posts.find(post_tag => post_tag.post_id === post_id)

    return post_tag ? post_tag.category_ids : []
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    const fields = [this.$options.filters.translate('指標'), ...this.table_header]

    const data = this.options.metrics.map(metric => {
      let temp_metric
      let temp_insights

      switch (this.options.data_type) {
        case 'daily_data':
          temp_metric = constants.DAILY_DATA_METRICS.find(item => item.value === metric)
          temp_insights = twitter_insight.filterDailyData(this.daily_data, this.post_data, metric)
          break
        case 'post_data':
          temp_metric = constants.POST_DATA_METRICS.find(item => item.value === metric)
          temp_insights = twitter_insight.filterPostData(this.post_data, metric)
          break
      }

      const metric_name = temp_metric ? temp_metric.text : ''

      const total_values = this.interval_date.map(interval => {
        const interval_insights = temp_insights.filter(insight => {
          const insight_time: number = insight.created_time || insight.end_time
          return moment(insight_time).isBetween(interval[0], interval[1], null, '[]')
        })

        switch (this.options.unit) {
          case 'total':
            switch (metric) {
              case 'reactions_rate':
              case 'engagements_rate':
                return null
              default:
                return twitter_insight.getMetricTotal(interval_insights, metric)
            }
          case 'average':
            return twitter_insight.getMetricAverage(interval_insights, metric)
          default:
            return null
        }
      })

      return [
        this.$options.filters.translate(metric_name),
        ...total_values.map(v => {
          return v === null ? '-' : v
        })
      ]
    })

    const csv_data = { fields, data }

    const component_name = this.$options.filters.translate('テーブル')

    const csv_filename = [component_name, this.table_title, this.start_date, this.end_date].join(
      '_'
    )

    csv.download(csv_data, csv_filename)
  }
}
