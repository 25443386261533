import { RouteConfig } from 'vue-router'

import PostManagement from '../pages/PostManagement.vue'

export const posts: RouteConfig = {
  component: PostManagement,
  path: '/posts',
  name: 'posts',
  meta: {
    auth: true,
    title: '投稿の管理',
    description: '本ツールで作成した投稿を一覧で確認できます。',
    color: 'warning',
    type: 'CHECK_POST'
  }
}
