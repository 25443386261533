import { render, staticRenderFns } from "./PersonalSettingNotificationList.vue?vue&type=template&id=2c777fe0&scoped=true"
import script from "./PersonalSettingNotificationList.ts?vue&type=script&lang=ts&external"
export * from "./PersonalSettingNotificationList.ts?vue&type=script&lang=ts&external"
import style0 from "./PersonalSettingNotificationList.vue?vue&type=style&index=0&id=2c777fe0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c777fe0",
  null
  
)

export default component.exports