/**
 * ヘッダーのクラス名を取得する
 */
export function getHeaderClass(
  sort_label: string[],
  column_property: string,
  sort_metric: string,
  sort_order: 'descending' | 'ascending'
): string {
  if (!sort_label.includes(column_property)) {
    return 'not-sort'
  }
  if (sort_metric === column_property) {
    return `sortable sort-metric sort-${sort_order}`
  }

  return ''
}

/**
 * テーブルの設定（並び順）を取得する
 */
export function getTableSetting(
  column_property: string,
  current_sort: string
): { sort: string; order: 'descending' | 'ascending' } {
  const sort = current_sort === `-${column_property}` ? column_property : `-${column_property}`

  const order = sort.slice(0, 1) === '-' ? 'descending' : 'ascending'

  return { sort, order }
}
