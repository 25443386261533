import { Component, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import GroupSettingSns from '@/client/components-old/organisms/GroupSettingSns'
import SnsAccountRegisterDialog from '@/client/components-old/organisms/SnsAccountRegisterDialog'
import SnsAccountUpdateDialog from '@/client/components-old/organisms/SnsAccountUpdateDialog'
import SnsRoleSettingDialog from '@/client/components-old/organisms/SnsRoleSettingDialog'
import SnsUnregisterCancelDialog from '@/client/components-old/organisms/SnsUnregisterCancelDialog'
import SnsUnregisterDialog from '@/client/components-old/organisms/SnsUnregisterDialog'
import { TrackingService } from '@/client/services'
import event from '@/client/utils/event'

@Component({
  name: 'GroupSettingSnsLayout',
  components: {
    Scroll,
    GroupSettingSns,
    SnsAccountRegisterDialog,
    SnsAccountUpdateDialog,
    SnsRoleSettingDialog,
    SnsUnregisterDialog,
    SnsUnregisterCancelDialog
  }
})
export default class GroupSettingSnsLayout extends Vue {
  $refs: {
    SnsAccountRegisterDialog: any
    SnsAccountUpdateDialog: any
    SnsRoleSettingDialog: any
    SnsUnregisterDialog: any
    SnsUnregisterCancelDialog: any
  }

  /**
   * ページ描写時
   */
  async mounted() {
    await this.$nextTick()

    if (this.$route.query.dialog) {
      const query = { ...this.$route.query }

      delete query.dialog

      this.$router.replace({ query })

      await event.delay(500)

      this.openAccountRegisterDialog()
    }
  }

  /**
   * SNSアカウント追加ダイアログを表示
   */
  openAccountRegisterDialog() {
    this.$refs.SnsAccountRegisterDialog.open()
    TrackingService.sendEvent('click:グループ設定|SNSアカウント|追加')
  }

  /**
   * SNSアカウント権限設定ダイアログを表示
   */
  openAccountRoleDialog(payload: any) {
    this.$refs.SnsRoleSettingDialog.open(payload)
  }

  /**
   * SNSアカウント更新ダイアログを表示
   */
  openAccountTokenUpdateDialog(payload: any) {
    this.$refs.SnsAccountUpdateDialog.open(payload)
  }

  /**
   * SNSアカウント登録解除ダイアログを表示
   */
  openAccountUnregisterDialog(payload: any) {
    this.$refs.SnsUnregisterDialog.open(payload)
  }

  /**
   * SNSアカウント登録解除取消ダイアログを表示
   */
  openAccountUnregisterCancelDialog(payload: any) {
    this.$refs.SnsUnregisterCancelDialog.open(payload)
  }
}
