import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import { IState as IStateCategory } from '@/client/store/modules/categories'

type TOptions = {
  hashtag_id: number
  es_in_hashtag_post_id: string
  category_ids: number[]
}

const categories = namespace('categories')
const notification = namespace('notification')
const instagram_hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagPostCategoryDialog',
  components: {
    Button,
    Dialog,
    Icon,
    SelectMulti
  }
})
export default class InstagramHashtagPostCategoryDialog extends Vue {
  @categories.State('api_post_categories')
  api_post_categories: IStateCategory['api_post_categories']
  @instagram_hashtag.Action('updatePostCategory') updatePostCategory
  @notification.Action('showNotification') showNotification

  visible = false
  hashtag_id: number | null = null
  es_in_hashtag_post_id: string | null = null
  category_ids: number[] = []
  is_submit = false

  get category_options() {
    return this.api_post_categories.map(category => ({ value: category.id, text: category.name }))
  }

  /**
   * ダイアログを開く
   */
  open(options: TOptions) {
    this.hashtag_id = options.hashtag_id
    this.es_in_hashtag_post_id = options.es_in_hashtag_post_id
    this.category_ids = [...options.category_ids]

    this.visible = true
  }

  /**
   * ダイアログを閉じる
   */
  closeDialog() {
    this.visible = false
    this.hashtag_id = null
    this.es_in_hashtag_post_id = null
    this.category_ids = []
    this.is_submit = false
  }

  /**
   * タグを保存
   */
  async updateCategories(): Promise<void> {
    if (this.is_submit) {
      return
    }

    this.is_submit = true

    const params = {
      hashtag_id: this.hashtag_id,
      es_in_hashtag_post_id: this.es_in_hashtag_post_id,
      category_ids: this.category_ids
    }

    const result = await this.updatePostCategory(params)

    setTimeout(() => {
      this.is_submit = false
    }, 2000)

    if (result && result.data) {
      this.showNotification({ title: 'タグ設定を保存しました。' })
      this.closeDialog()

      return
    }

    if (result && result.error && result.error.type === 'MAX_CATEGORY_OVER') {
      this.showNotification({
        title: 'タグを10個以下に設定してください。',
        type: 'error'
      })

      return
    }

    this.showNotification({
      title: 'タグ設定の保存に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }
}
