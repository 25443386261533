import { Component, Emit, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import { CONTRACT_STATUS_CANCEL } from '@/client/components-old/_constants/project'
import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import { IRootState } from '@/client/store/global'

@Component({
  name: 'GroupSettingContract',
  components: {
    Button,
    ButtonLink,
    Message,
    Panel
  }
})
export default class GroupSettingContract extends Vue {
  @State('project') project: IRootState['project']

  get disabled() {
    return this.project.contract_status === CONTRACT_STATUS_CANCEL
  }

  @Emit('open-contract-cancel-dialog')
  open(payload: any) {
    return payload
  }
}
