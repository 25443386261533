
import { defineComponent } from 'vue'

import Panel from '@/client/components-old/atoms/Panel'
import { getTranslateText as t } from '@/client/utils/filters'

export default defineComponent({
  name: 'DashboardIPAddressRestriction',
  components: {
    Panel
  },
  setup() {
    return {
      t
    }
  }
})
