import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { CONTRACT_STATUS_TRIAL } from '@/client/components-old/_constants/project'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Tabs from '@/client/components-old/atoms/Tabs'
import { IRootState } from '@/client/store/global'
import { IState as IGroupSettingState } from '@/client/store/modules/group_setting'

const group_setting = namespace('group_setting')

@Component({
  name: 'GroupSettingHeader',
  components: {
    ButtonGroup,
    Tabs
  }
})
export default class GroupSettingHeader extends Vue {
  @State('project') project: IRootState['project']
  @group_setting.State('screen_name') screen_name: IGroupSettingState['screen_name']
  @group_setting.State('function_tab_name')
  function_tab_name: IGroupSettingState['function_tab_name']
  @group_setting.Action('changeScreenName') changeScreenName
  @group_setting.Action('changeFunctionTabName') changeFunctionTabName

  @Watch('button_options', { deep: true, immediate: true })
  async changeFunctionList() {
    const functions = this.button_options.map(v => v.value)

    if (functions.every(v => v !== this.function_tab_name)) {
      await this.changeFunctionTabName(functions[0])
    }
  }

  get tab_options() {
    const tabs = [
      {
        value: 'account',
        text: 'SNSアカウント',
        icon: 'network'
      },
      {
        value: 'operator',
        text: 'オペレーター',
        icon: 'account-box'
      },
      {
        value: 'function',
        text: '機能設定',
        icon: 'tools'
      }
    ]

    if (this.project.contract_status !== CONTRACT_STATUS_TRIAL) {
      tabs.push({
        value: 'contract',
        text: '契約内容',
        icon: 'description'
      })
    }

    return tabs
  }

  get button_options() {
    const options = [{ value: 'group', text: 'グループ' }]

    if (this.project.is_use_monitoring) {
      options.unshift({ value: 'monitoring', text: 'モニタリング' })
    }

    if (this.project.is_use_analytics) {
      options.unshift({ value: 'analytics', text: '分析' })
    }

    if (this.project.is_use_post) {
      options.unshift({ value: 'post', text: '投稿' })
    }

    return options
  }

  get is_sub_header() {
    return this.screen_name === 'function'
  }
}
