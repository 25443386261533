import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import * as facebook_util from '@/client/components-old/_utils/facebook_authentication'
import * as instagram_util from '@/client/components-old/_utils/instagram_authentication'
import { TikTokAuthService } from '@/client/components-old/_utils/tiktok_authentication'
import * as twitter_util from '@/client/components-old/_utils/twitter_authentication'
import { TwitterEnterpriseAuthService } from '@/client/components-old/_utils/twitter_enterprise_authentication'
import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import SnsAccountAbout from '@/client/components-old/molecules/SnsAccountAbout'
import SnsAccountError from '@/client/components-old/molecules/SnsAccountError'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { TPutFacebookAccountsResponses } from '@/client/utils/api/facebook_accounts'
import { TPutInstagramAccountsResponses } from '@/client/utils/api/instagram_accounts'
import { PutTikTokAccountsTokenResponse } from '@/client/utils/api/tiktok_accounts'
import { TPutTwitterAccountsResponses } from '@/client/utils/api/twitter_accounts'
import { SnsType } from '@/common/types'

type TSnsAccountUpdateDialogOptions = {
  account_id: string
  sns_type: SnsType
}

// TODO: TikTokのアカウントのやつも追加する
type TErrorType =
  | 'authentication_canceled'
  | 'permission_denied'
  | 'account_protected'
  | 'facebook_page_not_exist'
  | 'instagram_account_not_exist'
  | 'different_account'
  | 'access_token_update_failed'
  | 'oauth_failed'
  | null

const accounts = namespace('accounts')
const notification = namespace('notification')

@Component({
  name: 'SnsAccountUpdateDialog',
  components: {
    Button,
    Dialog,
    Icon,
    Message,
    SnsAccountAbout,
    SnsAccountError
  }
})
export default class SnsAccountUpdateDialog extends Vue {
  @State('project') project: IRootState['project']
  @accounts.Action('updateFacebookAccountAccessToken') updateFacebookAccountAccessToken
  @accounts.Action('updateTwitterAccountAccessToken') updateTwitterAccountAccessToken
  @accounts.Action('updateTwitterEnterpriseVerified') updateTwitterEnterpriseVerified
  @accounts.Action('updateInstagramAccountAccessToken') updateInstagramAccountAccessToken
  @accounts.Action('updateTikTokAccountAccessToken') updateTikTokAccountAccessToken
  @notification.Action('showNotification') showNotification

  visible = false
  loading = false
  account_id = ''
  user_access_token = ''
  sns_type: TSnsAccountUpdateDialogOptions['sns_type'] = 'facebook'
  error_type: TErrorType = null

  get is_facebook() {
    return this.sns_type === 'facebook'
  }

  get is_twitter() {
    return this.sns_type === 'twitter'
  }

  get is_instagram() {
    return this.sns_type === 'instagram'
  }

  get is_tiktok() {
    return this.sns_type === 'tiktok'
  }

  /**
   * ダイアログを表示
   */
  open(options: TSnsAccountUpdateDialogOptions) {
    this.visible = true
    this.loading = false
    this.error_type = null
    this.account_id = options.account_id
    this.sns_type = options.sns_type
  }

  /**
   * ダイアログを非表示
   */
  close() {
    this.visible = false
  }

  /**
   * SNSアカウントの更新
   */
  async updateAccountToken() {
    switch (this.sns_type) {
      case 'facebook': {
        const accounts = await this.authFacebookAccount()

        if (!accounts.length) return

        const account = accounts.find(v => v.account_id === this.account_id)

        if (!account) {
          this.error_type = 'different_account'
          return
        }

        this.loading = true

        const result: TPutFacebookAccountsResponses = await this.updateFacebookAccountAccessToken({
          account_id: account.account_id,
          user_access_token: this.user_access_token,
          fb_user_id: account.user.id,
          fb_user_name: account.user.name
        })

        this.loading = false

        if (result.data) {
          TrackingService.sendEvent('click:グループ設定|FB|アクセストークン更新成功')
          this.showNotification({ title: 'アクセストークンを更新しました。' })
          this.close()
          return
        }

        this.error_type = 'access_token_update_failed'
        return
      }

      case 'twitter': {
        const accounts = await this.authTwitterAccount()

        if (!accounts.length) return

        const account = accounts.find(v => v.account_id === this.account_id)

        if (!account) {
          this.error_type = 'different_account'
          return
        }

        this.loading = true

        const result: TPutTwitterAccountsResponses = await this.updateTwitterAccountAccessToken({
          account_id: account.account_id,
          access_token: account.access_token,
          access_token_secret: account.access_token_secret
        })

        if (account.is_use_monitoring) {
          const is_enterprise_verified = await this.authTwitterEnterpriseAccount()

          this.loading = false

          if (!is_enterprise_verified) {
            this.error_type = 'access_token_update_failed'
            return
          }
        }

        this.loading = false

        if (result.data) {
          TrackingService.sendEvent('click:グループ設定|X|アクセストークン更新成功')
          this.showNotification({ title: 'アクセストークンを更新しました。' })
          this.close()
          return
        }

        this.error_type = 'access_token_update_failed'
        return
      }

      case 'instagram': {
        const accounts = await this.authInstagramAccount()

        if (!accounts.length) return

        const account = accounts.find(v => v.account_id === this.account_id)

        if (!account) {
          this.error_type = 'different_account'
          return
        }

        this.loading = true

        const result: TPutInstagramAccountsResponses = await this.updateInstagramAccountAccessToken(
          {
            account_id: account.account_id,
            business_account_id: account.business_account_id,
            access_token: account.access_token,
            fb_user_id: account.user.id,
            fb_user_name: account.user.name
          }
        )

        this.loading = false

        if (result.data) {
          TrackingService.sendEvent('click:グループ設定|IG|アクセストークン更新成功')
          this.showNotification({ title: 'アクセストークンを更新しました。' })
          this.close()
          return
        }

        this.error_type = 'access_token_update_failed'
        return
      }

      case 'tiktok': {
        const account = await this.authTikTokAccount()

        if (account.type !== 'SUCCESS') return

        if (account.account_id !== this.account_id) {
          this.error_type = 'different_account'
          return
        }

        this.loading = true

        const result: PutTikTokAccountsTokenResponse = await this.updateTikTokAccountAccessToken({
          account_id: account.account_id,
          access_token: account.access_token,
          refresh_token: account.refresh_token
        })

        this.loading = false

        if (result.data) {
          TrackingService.sendEvent('click:グループ設定|TikTok|アクセストークン更新成功')
          this.showNotification({ title: 'アクセストークンを更新しました。' })
          this.close()
          return
        }

        if (result.error && result.error.type === 'INVALID_ACCOUNT') {
          this.error_type = 'different_account'
          return
        }

        this.error_type = 'access_token_update_failed'
        return
      }

      default:
        this.error_type = 'access_token_update_failed'
        return
    }
  }

  /**
   * Facebookアカウントを認証
   */
  async authFacebookAccount() {
    const result = await facebook_util.execAuth(this.project.id)

    if (result.error) {
      switch (result.error.type) {
        case 'AUTHENTICATION_CANCELED':
          this.error_type = 'authentication_canceled'
          break
        case 'PERMISSION_DENIED':
          this.error_type = 'permission_denied'
          break
        case 'OAUTH_FACEBOOK_PAGE_NOT_EXIST':
          this.error_type = 'facebook_page_not_exist'
          break
        case 'OAUTH_FAILED':
          this.error_type = 'oauth_failed'
          break
      }

      return []
    }

    if (!result.data) return []

    this.user_access_token = result.data.user_access_token

    return result.data.accounts
  }

  /**
   * Twitterアカウントを認証
   */
  async authTwitterAccount() {
    const result = await twitter_util.execAuth(this.project.id)

    if (result.error) {
      switch (result.error.type) {
        case 'AUTHENTICATION_CANCELED':
          this.error_type = 'authentication_canceled'
          break
        case 'OAUTH_FAILED':
          this.error_type = 'oauth_failed'
          break
      }

      return []
    }

    const is_protected = result.data.some(v => v.is_protected)

    if (is_protected) {
      this.error_type = 'account_protected'

      return []
    }

    return result.data ? result.data : []
  }

  /**
   * NTT AAA認証をする
   */
  async authTwitterEnterpriseAccount(): Promise<boolean> {
    const response = await TwitterEnterpriseAuthService.openAuthWindow(this.account_id)

    if (response.type === 'SUCCESS') {
      await this.updateTwitterEnterpriseVerified({ account_id: this.account_id })
      await this.showNotification({ title: 'アクセストークンを更新しました。' })
      return true
    }

    if (response.type === 'AUTHENTICATION_FAILED') {
      await this.showNotification({
        title: '認証に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })

      return false
    }

    if (response.type === 'INVALID_ACCOUNT') {
      await this.showNotification({
        title: 'アカウントが一致していません。',
        message: '認証したいアカウントにログインしてください。',
        type: 'error'
      })

      return false
    }

    await this.showNotification({
      title: '認証に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })

    return false
  }

  /**
   * Instagramアカウントを認証
   */
  async authInstagramAccount() {
    const result = await instagram_util.execAuth(this.project.id)

    if (result.error) {
      switch (result.error.type) {
        case 'AUTHENTICATION_CANCELED':
          this.error_type = 'authentication_canceled'
          break
        case 'PERMISSION_DENIED':
          this.error_type = 'permission_denied'
          break
        case 'OAUTH_FACEBOOK_PAGE_NOT_EXIST':
          this.error_type = 'facebook_page_not_exist'
          break
        case 'OAUTH_INSTAGRAM_ACCOUNT_NOT_EXIST':
          this.error_type = 'instagram_account_not_exist'
          break
        case 'OAUTH_FAILED':
          this.error_type = 'oauth_failed'
          break
      }

      return []
    }

    return result.data ? result.data : []
  }

  /**
   * TikTokアカウントを認証
   */
  async authTikTokAccount() {
    const result = await TikTokAuthService.openAuthWindow()

    if (result.type !== 'SUCCESS') {
      switch (result.type) {
        case 'AUTHENTICATION_CANCELED':
          this.error_type = 'authentication_canceled'
          break
        case 'PERMISSION_DENIED':
          this.error_type = 'permission_denied'
          break
        case 'OAUTH_FAILED':
          this.error_type = 'oauth_failed'
          break
      }
    }

    return result
  }
}
