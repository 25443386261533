import { RouteConfig } from 'vue-router'

import InstagramComparisonAnalytics from '../pages/InstagramComparisonAnalytics.vue'

export const comparison_in: RouteConfig = {
  component: InstagramComparisonAnalytics,
  path: '/comparison_in',
  name: 'comparison_in',
  meta: {
    auth: true,
    title: '競合比較 (Instagram)',
    description: '複数アカウントの分析結果を同時に10アカウントまで比較できます。',
    color: 'warning',
    type: 'CHECK_COMPETITOR_ANALYSIS'
  }
}
