import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import Tabs from '@/client/components-old/atoms/Tabs'
import DateRangePicker from '@/client/components-old/molecules/DateRangePicker'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import {
  IGetter as IInstagramHashtagGetter,
  IState as IInstagramHashtag
} from '@/client/store/modules/instagram_hashtag'

const hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagHeader',
  components: {
    Button,
    Icon,
    SelectMulti,
    Tabs,
    DateRangePicker
  }
})
export default class InstagramHashtagHeader extends Vue {
  @State('project') project: IRootState['project']
  @hashtag.State('api_settings') api_settings: IInstagramHashtag['api_settings']
  @hashtag.State('is_loading') is_loading: IInstagramHashtag['is_loading']
  @hashtag.State('hashtag_ids') hashtag_ids: IInstagramHashtag['hashtag_ids']
  @hashtag.State('start_date') start_date: IInstagramHashtag['start_date']
  @hashtag.State('end_date') end_date: IInstagramHashtag['end_date']
  @hashtag.State('screen_name') screen_name: IInstagramHashtag['screen_name']
  @hashtag.Getter('is_fetch_loading') is_fetch_loading: IInstagramHashtagGetter['is_fetch_loading']
  @hashtag.Action('changeDateRange') changeDateRange
  @hashtag.Action('changeHashtagIds') changeHashtagIds
  @hashtag.Action('changeScreenName') changeScreenName
  @hashtag.Action('refreshInstagramHashtag') refreshInstagramHashtag

  local_hashtag_ids: number[] = []

  @Watch('local_hashtag_ids')
  watchLocalHashtagIds() {
    TrackingService.sendEvent(`select:クチコミ(IG)|画面内ヘッダー|キーワード分類選択`)
  }

  get current_count() {
    return this.project.keyword_hashtag_current_post_count.toLocaleString()
  }

  get max_count() {
    return this.project.keyword_hashtag_max_post_count.toLocaleString()
  }

  get date_range() {
    return [this.start_date, this.end_date]
  }

  get hashtag_options() {
    return this.api_settings.map(v => ({
      text: v.name,
      value: v.id
    }))
  }

  get tab_options() {
    return [
      {
        value: 'summary',
        text: 'サマリー',
        icon: 'line-graph',
        disabled: this.is_hashstag_empty
      },
      {
        value: 'post',
        text: '投稿',
        icon: 'rate-review',
        disabled: this.is_hashstag_empty
      },
      {
        value: 'management',
        text: '収集設定',
        icon: 'settings',
        disabled: false
      }
    ]
  }

  get is_hashstag_empty() {
    return !this.is_loading && !this.api_settings.length
  }

  get is_disabled_apply() {
    if (this.is_fetch_loading) return true

    if (this.local_hashtag_ids.length !== this.hashtag_ids.length) return false

    return this.local_hashtag_ids.every(local_hashtag_id =>
      this.hashtag_ids.includes(local_hashtag_id)
    )
  }

  @Watch('hashtag_ids')
  watchHashtagIds() {
    this.local_hashtag_ids = this.hashtag_ids
  }

  /**
   * ハッシュタグを変更
   */
  async changeHashtags() {
    await this.changeHashtagIds(this.local_hashtag_ids)

    TrackingService.sendEvent(`click:クチコミ(IG)|画面内ヘッダー|適用`)
  }

  /**
   * 分析データの更新
   */
  async onRefreshInstagramHashtag() {
    await this.refreshInstagramHashtag()

    TrackingService.sendEvent(`click:クチコミ(IG)|画面内ヘッダー|更新`)
  }

  /**
   * 日付範囲の変更
   */
  async onChangeDateRange(payload: [string, string]) {
    await this.changeDateRange(payload)

    TrackingService.sendEvent(`select:クチコミ(IG)|画面内ヘッダー|期間指定`)
  }

  /**
   * 表示するタブの変更
   */
  async onChangeScreenName(payload: string) {
    await this.changeScreenName(payload)

    const tab_name = this.tab_options.find(option => option.value === this.screen_name)?.text || ''
    TrackingService.sendEvent(`click:クチコミ(IG)|タブ:${tab_name}`)
  }
}
