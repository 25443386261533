import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/twitter_keywords'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Select from '@/client/components-old/atoms/Select'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartSingleBar from '@/client/components-old/molecules/ChartSingleBar'
import { TrackingService } from '@/client/services'
import {
  IGetter as IInstagramHashtagGetter,
  IState as IInstagramHashtagState
} from '@/client/store/modules/instagram_hashtag'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'

const instagram_hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagWordList',
  components: {
    ButtonLink,
    Icon,
    Select,
    AnalyticsPanel,
    ChartSingleBar
  }
})
export default class InstagramHashtagWordList extends Vue {
  @instagram_hashtag.State('api_settings') api_settings: IInstagramHashtagState['api_settings']
  @instagram_hashtag.State('api_words') api_words: IInstagramHashtagState['api_words']
  @instagram_hashtag.State('hashtag_ids') hashtag_ids: IInstagramHashtagState['hashtag_ids']
  @instagram_hashtag.State('words_hashtag_id')
  words_hashtag_id: IInstagramHashtagState['words_hashtag_id']
  @instagram_hashtag.State('words_size') words_size: IInstagramHashtagState['words_size']
  @instagram_hashtag.State('start_date') start_date: IInstagramHashtagState['start_date']
  @instagram_hashtag.State('end_date') end_date: IInstagramHashtagState['end_date']
  @instagram_hashtag.State('is_words_loading')
  is_words_loading: IInstagramHashtagState['is_words_loading']
  @instagram_hashtag.Getter('is_data') is_data: IInstagramHashtagGetter['is_data']
  @instagram_hashtag.Action('changeWordsHashtagId') changeWordsHashtagId
  @instagram_hashtag.Action('changeWordsSize') changeWordsSize
  @instagram_hashtag.Action('checkHashtagPosts') checkHashtagPosts

  get words_options() {
    const options = this.hashtag_ids.map(hashtag_id => {
      const hashtag = this.api_settings.find(v => v.id === hashtag_id)

      return { value: hashtag_id, text: hashtag ? hashtag.name : '' }
    })

    return [{ value: '', text: i18n.t('全分類') }, ...options]
  }

  get word_size_options() {
    return constants.WORD_SIZE_OPTIONS.map(v => ({
      value: v,
      text: i18n.t('上位[[count]]件', { count: v })
    }))
  }

  get display_data() {
    const chart_colors = ['#1c84c6']
    const max = Math.max(...this.api_words.map(word => word.post_count))

    return this.api_words.map(word => {
      const series = [word.post_count]

      const post_count = {
        series,
        colors: chart_colors,
        display_total: true,
        total: word.post_count,
        max
      }

      return {
        word: word.word,
        post_count
      }
    })
  }

  get is_select_data() {
    return this.api_words.length > 0
  }

  /**
   * CSVダウンロード
   */
  downloadCSV() {
    const fields = [i18n.t('ワード名'), i18n.t('投稿数')]

    const data = this.api_words.map(word => {
      return [word.word, word.post_count]
    })

    const csv_data = { fields, data }

    const page_name = i18n.t('Instagramクチコミ分析')
    const component_name = i18n.t('投稿ワード')

    const hashtag = this.api_settings.find(v => v.id === this.words_hashtag_id)
    const hashtag_name = hashtag ? hashtag.name : i18n.t('全分類')

    const csv_filename = [
      page_name,
      component_name,
      hashtag_name,
      this.words_size,
      this.start_date,
      this.end_date
    ].join('_')

    csv.download(csv_data, csv_filename)

    TrackingService.sendEvent(`click:クチコミ(IG)>サマリー|ワード一覧:CSVダウンロード`)
  }

  /**
   * ワード一覧で指定しているハッシュタグの変更
   */
  async onChangeWordsHashtagId(payload: number) {
    await this.changeWordsHashtagId(payload)

    const words_option = this.words_options.find(v => v.value === this.words_hashtag_id)
    const hashtag_size = words_option?.value || ''

    TrackingService.sendEvent(`select:クチコミ(IG)>サマリー|ワード一覧:KW分類:${hashtag_size}`)
  }

  /**
   * ワード一覧の件数変更
   */
  async onChangeWordsSize(payload: number) {
    await this.changeWordsSize(payload)

    const word_size_option = this.word_size_options.find(v => v.value === this.words_size)
    const words_size_label = word_size_option?.text || ''

    TrackingService.sendEvent(`select:クチコミ(IG)>サマリー|ワード一覧:${words_size_label}`)
  }

  /**
   * 指定ハッシュタグの投稿の確認
   */
  async onCheckHashtagPosts(payload: string) {
    await this.checkHashtagPosts(payload)

    TrackingService.sendEvent(`click:クチコミ(IG)>サマリー|ワード一覧:投稿を確認`)
  }
}
