
import { Component, Prop, Vue } from 'vue-property-decorator'

type TStepsOption = {
  label: string
}

@Component({
  name: 'Steps'
})
export default class Steps extends Vue {
  @Prop({ type: Number, default: 1 })
  active!: number

  @Prop({ type: Array, default: () => [] })
  options!: TStepsOption[]

  get currentStep() {
    return this.active - 1
  }
}
