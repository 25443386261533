import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/instagram_comparison_analytics'
import Message from '@/client/components-old/atoms/Message'
import Account from '@/client/components-old/molecules/Account'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import AnalyticsRanking from '@/client/components-old/molecules/AnalyticsRanking'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as IInstagramState } from '@/client/store/modules/instagram_comparison_analytics'
import csv from '@/client/utils/csv'

type TInstagramComparisonAnalyticsRankingOptions = {
  main_metric: string
  sub_metrics: string[]
  sort: 'ascending' | 'descending'
  limit: number
}

const analytics = namespace('analytics')
const instagram = namespace('instagram_comparison_analytics')

@Component({
  name: 'InstagramComparisonAnalyticsRanking',
  components: {
    Message,
    AnalyticsPanel,
    AnalyticsRanking,
    Account
  }
})
export default class InstagramComparisonAnalyticsRanking extends Vue {
  @State('user') user: IRootState['user']
  @instagram.State('api_sns_comparison_accounts')
  sns_comparison_accounts: IInstagramState['api_sns_comparison_accounts']
  @instagram.State('api_posts') posts_data: IInstagramState['api_posts']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TInstagramComparisonAnalyticsRankingOptions

  get is_data() {
    return this.posts_data.length > 0 && this.posts_data.some(posts => posts.data.length > 0)
  }

  get metrics_name() {
    return this.getMetricsName(this.options.main_metric)
  }

  get ranking_count() {
    return this.options.limit
  }

  get ranking_posts() {
    const { main_metric, sub_metrics, sort, limit } = this.options

    // ランキングに必要なものを追加
    return this.posts_data.map(account => {
      const account_info = this.sns_comparison_accounts.find(item => item.id === account.account_id)
      const is_data = account.data.length > 0

      if (!is_data) {
        return { account: account_info, posts: account.data, is_data }
      }

      let posts = account.data.map(post => {
        const main = {
          key: this.getMetricsName(main_metric),
          value: this.getResultValue(main_metric, post)
        }

        const sub = sub_metrics.map(metrics => {
          return {
            key: this.getMetricsName(metrics),
            value: this.getResultValue(metrics, post)
          }
        })
        return { ...post, results: [main].concat(sub) }
      })

      posts = [...posts]
        .sort((a, b) => {
          switch (sort) {
            case 'ascending':
              return a[main_metric] - b[main_metric]
            case 'descending':
              return b[main_metric] - a[main_metric]
            default:
              return 1
          }
        })
        .map((post, i) => ({ ...post, rank_no: i + 1 }))

      posts = posts.slice(0, limit)

      return { account: account_info, posts, is_data }
    })
  }

  get ranking_type() {
    if (this.options.sort === 'descending') return 'BEST'

    if (this.options.sort === 'ascending') return 'WORST'

    return ''
  }

  @Emit('open-embed')
  openEmbed(account_id: string, post_id: string, sns_link: string) {
    TrackingService.sendEvent(`click:競合比較(IG)>BM|反応数Rank(${this.ranking_type}):プレビュー`)

    return { account_id, post_id, sns_link }
  }

  /**
   * 指標から指標名を取得
   * @param {string} value 指標
   * @returns {string} 指標名
   */
  getMetricsName(value: string): string {
    const metric = constants.POST_RANKING_MAIN_METRICS.find(item => item.value === value)
    return metric ? metric.text : ''
  }

  /**
   * 指標から表示用の値を取得
   * @param {string} value 指標
   * @param {any} data 指標データ
   * @returns {string} 表示用の値
   */
  getResultValue(value: string, data: any): string {
    switch (value) {
      case 'created_time': {
        const format = this.user.language === 'ja' ? 'YYYY年M月D日H:mm' : 'M/D/YYYY<br>H:mm'
        return moment(data[value]).format(format)
      }
      case 'reactions_rate':
        return this.$options.filters.percent(data[value])
      case 'likes':
        return data.is_like_hidden ? '-' : data.likes
      default:
        return this.$options.filters.number(data[value])
    }
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    const { main_metric, sub_metrics } = this.options

    const conversion = (metric, post) => {
      switch (metric) {
        case 'created_time':
          return csv.format(post[metric])
        default:
          return post[metric]
      }
    }

    const fields = [
      this.$options.filters.translate('公開日時'),
      this.$options.filters.translate('アカウント名'),
      this.$options.filters.translate('投稿タイプ'),
      this.$options.filters.translate('投稿内容'),
      this.$options.filters.translate(this.metrics_name),
      ...sub_metrics.map(metric => this.$options.filters.translate(this.getMetricsName(metric))),
      this.$options.filters.translate('サムネイルURL'),
      this.$options.filters.translate('投稿URL'),
      this.$options.filters.translate('投稿ID')
    ]

    const data = this.ranking_posts.reduce((arr, current_item) => {
      const v = current_item.posts.map(post => [
        conversion('created_time', post),
        current_item.account.name,
        post.type,
        post.message,
        conversion(main_metric, post),
        ...sub_metrics.map(metric => conversion(metric, post)),
        post.picture_url,
        post.sns_link,
        post.post_id
      ])
      const new_arr = arr.concat(v)
      return new_arr
    }, [])

    const csv_data = { fields, data }

    const component_name = this.$options.filters.translate('投稿ランキング')
    const metrics_name = this.$options.filters.translate(this.metrics_name)
    const sort_name = this.options.sort === 'descending' ? 'BEST' : 'WORST'

    const csv_filename = [
      component_name,
      metrics_name,
      `TOP${this.ranking_count}(${sort_name})`,
      this.start_date,
      this.end_date
    ].join('_')
    csv.download(csv_data, csv_filename)

    TrackingService.sendEvent(
      `click:競合比較(IG)>BM|反応数Rank(${this.ranking_type}):CSVダウンロード`
    )
  }
}
