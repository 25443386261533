import i18n from '@/client/utils/i18n'

type Options = {
  message?: string
  help_color: 'link' | 'warning'
  help_type:
    | 'ABOUT_ACCESS_TOKEN'
    | 'ACCOUNT_SUSPEND'
    | 'CHECK_COMPETITOR_ANALYSIS'
    | 'CHECK_MONITORING'
    | 'CHECK_OWN_ANALYSIS'
    | 'CHECK_POST'
    | 'CREATE_POST'
    | 'DOWNLOAD_EXCEL_REPORT_OWN_ANALYSIS'
    | 'FAQ_ENVIRONMENT'
    | 'IMPORT_TWITTER_CSV_OWN_ANALYSIS'
    | 'INVITE_OPERATOR'
    | 'IP_ADDRESS_ACCESS_RESTRICTIONS'
    | 'METRICS_FACEBOOK_OWN_ANALYSIS'
    | 'METRICS_INSTAGRAM_OWN_ANALYSIS'
    | 'METRICS_TWITTER_OWN_ANALYSIS'
    | 'METRICS_TIKTOK_OWN_ANALYSIS'
    | 'POST_APPROVAL'
    | 'POST_CREATE_INSTAGRAM_PUBLISH_STEP'
    | 'POST_IMAGE'
    | 'POST_VIDEO'
    | 'POST_PUBLISH_FAILED'
    | 'REGISTER_SNS_FACEBOOK'
    | 'REGISTER_SNS_INSTAGRAM'
    | 'REGISTER_SNS_TWITTER'
    | 'SETTING_FACEBOOK_APP_AUTH'
    | 'SETTING_OPERATOR_PERMISSION'
    | 'VIRAL_ANALYSIS_MAX_KEYWORD_COUNT'
}

/**
 * ヘルプ用に通知のメッセージを変換
 */
export function convertHelpMessage(options: Options): string {
  let color = '#428bca'

  if (options.help_color === 'warning') {
    color = '#F5F859'
  }

  let message = ''

  if (options.message) {
    message = i18n.t(options.message) + '<br />'
  }

  const text = i18n.t(`${options.help_type}>title`)
  const href = i18n.t(`${options.help_type}>url`)

  return (
    message +
    `<a href="${href}" target="_blank" style="color:${color}; text-decoration: none; vertical-align: middle;">` +
    `${text}` +
    '<i class="ci-open-in-new" style="font-size: 16px; vertical-align: text-top;"></i>' +
    '</a>'
  )
}
