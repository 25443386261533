import moment from 'moment-timezone'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Account from '@/client/components-old/molecules/Account'
import ApproveFlow from '@/client/components-old/molecules/ApproveFlow'
import TextareaEmoji from '@/client/components-old/molecules/TextareaEmoji'
import UserMultiSelect from '@/client/components-old/molecules/UserMultiSelect'
import { TrackingService } from '@/client/services'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import { IGetter, IState } from '@/client/store/modules/post_management'
import i18n from '@/client/utils/i18n'
import { convertHelpMessage } from '@/client/utils/notification'
import storage from '@/client/utils/storage'

const accounts = namespace('accounts')
const post_management = namespace('post_management')
const notification = namespace('notification')

@Component({
  name: 'PostDetailApprovalForm',
  components: {
    Button,
    Icon,
    Flex,
    Message,
    Account,
    ApproveFlow,
    TextareaEmoji,
    UserMultiSelect
  }
})
export default class PostDetailApprovalForm extends Vue {
  @accounts.Getter('post') sns_accounts: IAccountsGetter['post']
  @accounts.Action('fetchSnsAccounts') fetchSnsAccounts
  @post_management.State('api_post') api_post: IState['api_post']
  @post_management.State('memo_cache') memo_cache: IState['memo_cache']
  @post_management.Getter('dialog_memo_users') dialog_memo_users: IGetter['dialog_memo_users']
  @post_management.Getter('is_instagram_manual_publish')
  is_instagram_manual_publish: IGetter['is_instagram_manual_publish']
  @post_management.Action('postApproval') postApproval
  @post_management.Action('postReject') postReject
  @post_management.Action('changePostMemoCache') changePostMemoCache
  @notification.Action('showNotification') showNotification

  message = ''
  to_user_ids: number[] = []
  is_submit = false

  mounted() {
    const sns_post_id = this.api_post ? this.api_post.id : 0

    if (this.memo_cache.memo_type === 'approval' && this.memo_cache.sns_post_id === sns_post_id) {
      this.message = this.memo_cache.message
    }

    const local: { to_user_ids?: number[] } = storage.get('post_management')

    // ストレージに保存されていたら使用する
    if (local && local.to_user_ids && Array.isArray(local.to_user_ids)) {
      this.to_user_ids = local.to_user_ids.filter(v =>
        this.dialog_memo_users.map(v => v.id).includes(v)
      )
    }
  }

  get warning_message() {
    const datetime = moment(this.api_post?.action_datetime).format('lll')
    const current_step = this.api_post?.post_approval_flow?.current_step ?? 0
    const total_step = this.api_post?.post_approval_flow?.total_step ?? 0

    if (moment().isBefore(moment(this.api_post?.action_datetime))) {
      let message = '最終ステップ承認後、[[datetime]] '

      if (current_step >= total_step) {
        message = '承認後、[[datetime]] '
      }

      if (this.is_instagram_manual_publish) {
        message += 'に公開手続をしてください。'
      } else {
        message += 'に公開予定です。'
      }

      return i18n.t(message, { datetime })
    }

    if (current_step >= total_step) {
      if (this.is_instagram_manual_publish) {
        return i18n.t('承認後に公開手続をすると、この投稿は公開されます。')
      }

      return i18n.t('承認後、この投稿はすぐに公開されます。')
    }

    if (this.is_instagram_manual_publish) {
      return i18n.t('最終ステップ承認後に公開手続をすると、この投稿は公開されます。')
    }

    return i18n.t('最終ステップ承認後、この投稿は公開されます。')
  }

  get accounts() {
    if (!this.api_post) {
      return []
    }

    const fb_accounts = this.api_post.fb_posts.map(post => ({
      id: post.account.id,
      name: post.account.name,
      image_url: post.account.image_url,
      sns: 'facebook'
    }))

    const tw_accounts = this.api_post.tw_posts.map(post => ({
      id: post.account.id,
      name: post.account.name,
      image_url: post.account.image_url,
      sns: 'twitter'
    }))

    const in_accounts = this.api_post.in_posts.map(post => ({
      id: post.account.id,
      name: post.account.name,
      image_url: post.account.image_url,
      sns: 'instagram'
    }))

    const tt_accounts = this.api_post.tt_posts.map(post => ({
      id: post.account.id,
      name: post.account.name,
      image_url: post.account.image_url,
      sns: 'tiktok'
    }))

    return [...fb_accounts, ...tw_accounts, ...in_accounts, ...tt_accounts]
  }

  get errorFormatMediaMessage() {
    const type = this.api_post?.type === 'video' ? '動画' : '画像'

    const help_url =
      this.api_post?.type === 'video'
        ? 'https://help-cms.comnico.jp/post-video'
        : 'https://help-cms.comnico.jp/post-image'

    return (
      i18n.t(`${type}形式に問題があります。こちらの`) +
      ' ' +
      `<a href="${help_url}" target="_blank">` +
      i18n.t('ヘルプ') +
      '</a>' +
      ' ' +
      i18n.t(`を参考に最適化を行ってください。`)
    )
  }

  get is_expired() {
    if (!this.accounts || !this.accounts.length) {
      return false
    }

    return this.sns_accounts.some(
      sns_account =>
        this.accounts.findIndex(account => sns_account.id === account.id && sns_account.expired) >
        -1
    )
  }

  get is_disabled_approve() {
    if (this.is_submit || this.is_expired) {
      return true
    }

    // 最終承認ステップの時
    if (
      this.api_post?.post_approval_flow &&
      this.api_post?.post_approval_flow.current_step >=
        this.api_post?.post_approval_flow.total_step &&
      this.api_post?.tw_posts &&
      this.api_post?.tw_posts.length === 1
    ) {
      if (this.api_post.tw_posts[0].twitter_quote_error_type) {
        // 即時公開でない場合
        if (moment().isBefore(moment(this.api_post.action_datetime))) {
          return this.api_post.tw_posts[0].twitter_quote_error_type !== 'UNPUBLISH'
        }

        return (
          this.api_post.tw_posts[0].twitter_quote_error_type &&
          this.api_post.tw_posts[0].twitter_quote &&
          this.api_post.tw_posts[0].twitter_quote.target_tw_post &&
          !['published'].includes(this.api_post.tw_posts[0].twitter_quote.target_tw_post.status)
        )
      }

      if (this.api_post.tw_posts[0].twitter_reply_error_type) {
        // 即時公開でない場合
        if (moment().isBefore(moment(this.api_post.action_datetime))) {
          return this.api_post.tw_posts[0].twitter_reply_error_type !== 'UNPUBLISH'
        }

        return (
          this.api_post.tw_posts[0].twitter_reply_error_type &&
          this.api_post.tw_posts[0].twitter_reply &&
          this.api_post.tw_posts[0].twitter_reply.target_tw_post &&
          !['published'].includes(this.api_post.tw_posts[0].twitter_reply.target_tw_post.status)
        )
      }
    }

    return false
  }

  get is_tiktok() {
    return this.accounts.some(account => account.sns === 'tiktok')
  }

  /**
   * 投稿承認処理
   */
  async onApprove(): Promise<void> {
    TrackingService.sendEvent('click:承認/差し戻し(ダイアログ)|承認する')

    if (this.is_disabled_approve) {
      return
    }

    this.is_submit = true

    const data = await this.postApproval({
      message: this.message.trim(),
      to_user_ids: this.to_user_ids
    })

    if (data && data.data) {
      this.is_submit = false

      if (data.data === 'approved') {
        this.showNotification({ title: '投稿を承認しました。' })
      } else if (data.data === 'scheduled' && !this.is_instagram_manual_publish) {
        const title = '投稿の承認と予約が完了しました。'
        this.showNotification({ title })
      } else if (data.data === 'scheduled' && this.is_instagram_manual_publish) {
        const title = '投稿の承認が完了しました。'
        const message = '公開予定時間に公開手続をしてください。'

        this.showNotification({ title, message })
      } else if (data.data === 'publish') {
        // ? TikTok投稿のみ外部APIの仕様で公開指示までしか出せないため、公開時のメッセージを変更する
        if (this.is_tiktok) {
          this.showNotification({
            title: '投稿の承認が完了しました。投稿を公開します。',
            message: 'TikTokの投稿は約1分ほど公開処理に時間がかかります。'
          })
        } else {
          this.showNotification({ title: '投稿の承認と公開が完了しました。' })
        }
      } else if (data.data === 'publish_remind') {
        const title = '投稿の承認が完了しました。'
        const message = '引き続き公開手続をしてください。'

        this.showNotification({ title, message })
      }
    } else {
      let title = '投稿の承認に失敗しました。'
      let message = ''

      if (data && data.error) {
        const error = data.error

        if (error.type === 'DUPLICATE') {
          title = 'すでに投稿の承認処理が他の承認者により行なわれています。'
        }

        if (error.type === 'NOT_EXISTS') {
          title = '該当の投稿が削除されています。'
        }

        if (error.type === 'PERMISSION_DENIED') {
          title = '投稿権限がないため、承認に失敗しました。'
        }

        if (error.type === 'POST_STATUS_CHANGED') {
          title = '投稿のステータスが変更されています。'
        }

        if (error.type === 'MEDIA_UPLOAD_FAILED') {
          title = 'アップロードに失敗しました。'
          message = convertHelpMessage({
            message: `${this.api_post?.type}形式に問題があります。ヘルプを参考に最適化を行ってください。`,
            help_color: 'warning',
            help_type: this.api_post?.type === 'video' ? 'POST_VIDEO' : 'POST_IMAGE'
          })
        }

        if (error.title) {
          title = error.title
          message = error.message

          if (error.type === 'TOKEN_EXPIRED') {
            title = 'アクセストークンが失効しています。'
            message = convertHelpMessage({
              message: 'アクセストークンを更新してください。',
              help_color: 'warning',
              help_type: 'ABOUT_ACCESS_TOKEN'
            })
          }
        }
      }

      this.showNotification({ title, message, type: 'error' })
    }

    if (data && data.error && data.error.type === 'TOKEN_EXPIRED') {
      await this.fetchSnsAccounts()
    }

    this.$emit('approval', data)
  }

  /**
   * 投稿差し戻し処理
   */
  async onReject(): Promise<void> {
    TrackingService.sendEvent('click:承認/差し戻し(ダイアログ)|承認する')

    this.is_submit = true

    const data = await this.postReject({
      message: this.message.trim(),
      to_user_ids: this.to_user_ids
    })

    if (data && data.data) {
      this.$emit('approval', data)
      this.is_submit = false

      this.showNotification({ title: '投稿が差し戻されました。' })
      return
    }

    let title = '投稿の差し戻しに失敗しました。'

    if (data && data.error) {
      const error = data.error
      if (error.type === 'DUPLICATE') {
        title = 'すでに投稿の差し戻し処理が他の承認者により行なわれています。'
      }

      if (error.type === 'NOT_EXISTS') {
        title = '該当の投稿が削除されています。'
      }

      if (error.type === 'PERMISSION_DENIED') {
        title = '投稿権限がないため、差し戻しに失敗しました。'
      }

      if (error.type === 'POST_STATUS_CHANGED') {
        title = '投稿のステータスが変更されています。'
      }
    }

    this.showNotification({ title, type: 'error' })

    this.$emit('approval', data)
  }

  /**
   * 投稿メモの保存
   */
  async savePostMemoCache(message: string): Promise<void> {
    await this.changePostMemoCache({
      sns_post_id: this.api_post ? this.api_post.id : 0,
      memo_type: 'approval',
      message
    })
  }
}
