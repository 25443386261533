import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'MonthPicker'
})
export default class MonthPicker extends Vue {
  @Prop({ type: String, required: true })
  value: string

  @Prop({ type: Boolean, default: false })
  disabled: boolean

  @Prop({ type: String, default: null })
  since: string

  @Prop({ type: String, default: null })
  until: string

  get picker_options() {
    const start_date = this.since
    const end_date = this.until

    return {
      disabledDate(time: Date): boolean {
        if (!start_date && !end_date) return

        const data = moment(time).tz('Asia/Tokyo').startOf('month').format('YYYY-MM')

        if (start_date && !end_date) {
          return !moment(data).isSameOrAfter(start_date, 'month')
        }

        if (!start_date && end_date) {
          return !moment(data).isSameOrBefore(end_date, 'month')
        }

        if (start_date && end_date) {
          return !moment(data).isBetween(start_date, end_date, 'month', '[]')
        }
      }
    }
  }

  @Emit('input')
  changeMonth(event: any) {
    return event
  }
}
