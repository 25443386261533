
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Radio'
})
export default class Radio extends Vue {
  @Prop({ type: [String, Number], default: '' })
  value!: string | number // checked

  @Prop({ type: [String, Number], default: '' })
  label!: string | number // value

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  get checked() {
    return this.value
  }

  set checked(val) {
    this.$emit('input', val)
  }
}
