import API from '@/client/utils/api'
import {
  GetOauthTikTokAccountsParams,
  GetOauthTikTokAccountsResponse
} from '@/client/utils/api/oauth'

interface TikTokCallbackSuccess {
  type: 'SUCCESS'
  account_id: string
  access_token: string
  refresh_token: string
}

interface TikTokCallbackError {
  type: 'AUTHENTICATION_CANCELED' | 'PERMISSION_DENIED' | 'OAUTH_FAILED'
}

type TikTokCallbackQuery = TikTokCallbackSuccess | TikTokCallbackError

export class TikTokAuthService {
  static async openAuthWindow() {
    const width = 600
    const height = 800
    const top = (screen.height - height) / 4
    const left = (screen.width - width) / 2

    const auth = window.open(
      '/api/v1/oauth/tiktok',
      'oauth_tiktok',
      `width=${width}, height=${height}, top=${top}, left=${left}`
    )

    const callback = await new Promise<TikTokCallbackQuery>(resolve => {
      window.onTikTokAuth = resolve
    })

    if (auth) auth.close()

    return callback
  }

  static async getAccountInfo(callback: TikTokCallbackSuccess, project_id: number) {
    const params: GetOauthTikTokAccountsParams = {
      account_id: callback.account_id,
      project_id,
      access_token: callback.access_token,
      refresh_token: callback.refresh_token
    }

    const accounts = await API.get<GetOauthTikTokAccountsResponse>('oauth/tiktok/accounts', {
      params
    })

    return accounts.data
  }
}
