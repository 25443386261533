
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'

type TOption = {
  value: string
  text?: string
  icon?: string
}

@Component({
  name: 'ButtonGroup',
  components: {
    Icon
  }
})
export default class ButtonGroup extends Vue {
  @Prop({ type: String, required: true })
  value!: string

  @Prop({ type: Array, required: true })
  options!: TOption[]

  @Prop({ type: String, default: 'default' })
  type!: 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'inactive'

  @Prop({ type: String, default: 'normal' })
  size!: 'normal' | 'minimum' | 'small' | 'large' | 'full'

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Emit('input')
  onChange(value: string) {
    return value
  }

  get is_single() {
    return this.options.length === 1
  }

  /**
   * アクティブかどうか
   */
  isActive(value: string): boolean {
    return this.value === value
  }
}
