import { TranslationOptions } from 'i18next'

import i18n from '@/client/utils/i18n'

/**
 * 翻訳したテキストを取得
 */
export function getTranslateText(value: string, options?: TranslationOptions): string {
  return options ? i18n.t(value, options) : i18n.t(value)
}
