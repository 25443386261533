import moment from 'moment-timezone'

import * as calculation from './calculation'

/**
 * アカウント分析をキーによってフィルタリング
 * @param {any[]} daily_data ページ分析データ
 * @param {any[]} posts_data 投稿分析データ
 * @param {string} key 取得するキー
 * @returns {any[]} 合計値
 */
export function filterDailyData(daily_data: any[], posts_data: any[], key: string): any[] {
  switch (key) {
    case 'post_count':
      return daily_data.map(account => {
        account.data.map(post => {
          const post_data = posts_data.find(item => item.account_id === account.account_id)
          const post_count = post_data.data.filter(post =>
            moment(post.created_time).isSame(moment(post.created_time), 'day')
          ).length
          return { ...post, post_count }
        })
      })
    default:
      return [...daily_data]
  }
}

/**
 * 指標と期間で関連データを取得
 * @param {any} analytics
 * @param {any} time
 * @returns {any} 関連データ
 */
export function getDataByMetric(analytics: any, time?: any): any {
  if (!time) {
    return analytics
  }
  return analytics.map(account => {
    return {
      account_id: account.account_id,
      data: account.data.filter(
        post =>
          moment(post.created_time).unix() >= time.start &&
          moment(post.created_time).unix() <= time.end
      )
    }
  })
}

/**
 * 分析データから値の配列を取得（数値化）
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {number} 指標データの配列（数値化）
 */
export function getMetricArrayNumber(insights: any[], metric: string): number[] {
  return insights.map(v => {
    const value = getMetricValue(v, metric)

    // オブジェクトの場合は全ての値を合計する
    if (Object.prototype.toString.call(value) === '[object Object]') {
      return calculation.addition(Object.keys(value).map(k => value[k]))
    }

    return value
  })
}

/**
 * 分析データから値を取得
 * @param {any} insight 分析データ（単体）
 * @param {string} metric 指標名
 * @returns {any} 値
 */
export function getMetricValue(insight: any, metric: string): any {
  const names = metric.split('.')

  let temp = insight

  names.forEach(name => {
    temp = temp[name]
  })

  return temp
}

/**
 * 分析データから値の合計を取得
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {number} 指標データの合計
 */
export function getMetricTotal(insights: any[], metric: string): number {
  switch (metric) {
    // 累計数の場合、最終日の値を返す
    case 'followers_count':
    case 'friends_count':
    case 'listed_count': {
      const metric_array = getMetricArrayNumber(insights, metric)
      return metric_array.length > 0 ? metric_array[0] : 0
    }

    default:
      return calculation.addition(getMetricArrayNumber(insights, metric))
  }
}

/**
 * 分析データから値の平均を取得
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {number} 指標データの平均
 */
export function getMetricAverage(insights: any[], metric: string): number {
  let numerator = 0
  let denominator = 0

  switch (metric) {
    // 累計数
    case 'followers_count':
    case 'friends_count':
    case 'listed_count':
      numerator = calculation.addition(getMetricArrayNumber(insights, metric))
      denominator = insights.length
      return calculation.division(numerator, denominator, 1)

    // 反応率
    case 'reactions_rate':
      numerator = getMetricTotal(insights, 'reactions')
      denominator = getMetricTotal(insights, 'followers_at_that_time')
      return calculation.percentage(numerator, denominator)

    default:
      numerator = getMetricTotal(insights, metric)
      denominator = insights.length
      return calculation.division(numerator, denominator, 1)
  }
}

/**
 * 指標によって値の表示方法を変換する
 * @param {number} value 値
 * @param {string} metric 指標
 * @returns {string} 加工した値文字列
 */
export function convertValueWithMetric(value: number, metric: string): string {
  switch (metric) {
    case 'reactions_rate':
      if (!value) return '0%'
      return `${value.toLocaleString()}%`
    case 'followers_count_up_down':
    case 'friends_count_up_down':
    case 'listed_count_up_down':
      if (!value) return '0'
      if (Math.sign(value) === 1) return `+${value.toLocaleString()}`
      return value.toLocaleString()
    default:
      if (!value) return '0'
      return value.toLocaleString()
  }
}
