import qs from 'qs'

/**
 * XトレンドのURLを生成
 */
export function createTrendUrl(value: string): string {
  const keyword = value.startsWith('#') ? value : `"${value}"`

  const query = qs.stringify({ q: `${keyword} OR @i -@i` })

  return `https://x.com/search?${query}`
}
