import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import HelpLink from '@/client/components/molecules/HelpLink'
import {
  PostCreateInstagramCommentSetting,
  PostCreateTikTokOption,
  PostCreateTwitterQuote,
  PostCreateTwitterReply
} from '@/client/components/organisms/PostCreate'
import PostCreateApprovalSetting from '@/client/components/organisms/PostCreate/PostCreateApprovalSetting'
import PostCreateInstagramPostType from '@/client/components/organisms/PostCreate/PostCreateInstagramPostType'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import Account from '@/client/components-old/molecules/Account'
import PostCreateCategory from '@/client/components-old/organisms/PostCreateCategory'
import PostCreateContent from '@/client/components-old/organisms/PostCreateContent'
import PostCreateFacebookLink from '@/client/components-old/organisms/PostCreateFacebookLink'
import PostCreateFacebookTarget from '@/client/components-old/organisms/PostCreateFacebookTarget'
import PostCreateIncidentAlert from '@/client/components-old/organisms/PostCreateIncidentAlert'
import PostCreateInstagramFirstCommentMessage from '@/client/components-old/organisms/PostCreateInstagramFirstCommentMessage'
import PostCreateMedia from '@/client/components-old/organisms/PostCreateMedia'
import PostCreateNav from '@/client/components-old/organisms/PostCreateNav'
import PostCreatePerson from '@/client/components-old/organisms/PostCreatePerson'
import PostCreateSetting from '@/client/components-old/organisms/PostCreateSetting'
import PostCreateShortUrl from '@/client/components-old/organisms/PostCreateShortUrl'
import PostCreateSubmit from '@/client/components-old/organisms/PostCreateSubmit'
import PostCreateTwitterTrend from '@/client/components-old/organisms/PostCreateTwitterTrend'
import PostDetail from '@/client/components-old/organisms/PostDetail'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import {
  IGetter as IPostCreateGetter,
  IState as IPostCreateState,
  TikTokOption
} from '@/client/store/modules/post_create'

const accounts = namespace('accounts')
const post_create = namespace('post_create')

@Component({
  name: 'PostCreateLayout',
  components: {
    HelpLink,
    Message,
    Scroll,
    Account,
    PostCreateIncidentAlert,
    PostCreateSetting,
    PostCreateCategory,
    PostCreateContent,
    PostCreateFacebookLink,
    PostCreateFacebookTarget,
    PostCreateInstagramCommentSetting,
    PostCreateInstagramFirstCommentMessage,
    PostCreateInstagramPostType,
    PostCreateMedia,
    PostCreateNav,
    PostCreatePerson,
    PostCreateApprovalSetting,
    PostCreateTikTokOption,
    PostCreateTwitterReply,
    PostCreateTwitterQuote,
    PostCreateShortUrl,
    PostCreateSubmit,
    PostCreateTwitterTrend,
    PostDetail
  }
})
export default class PostCreateLayout extends Vue {
  @accounts.Getter('post') sns_accounts!: IAccountsGetter['post']
  @post_create.State('api_post') api_post!: IPostCreateState['api_post']
  @post_create.State('api_incident_events')
  api_incident_events!: IPostCreateState['api_incident_events']
  @post_create.State('accounts') accounts!: IPostCreateState['accounts']
  @post_create.State('is_loading') is_loading!: IPostCreateState['is_loading']
  @post_create.State('loading_message') loading_message!: IPostCreateState['loading_message']
  @post_create.State('scheduled_datetime')
  scheduled_datetime!: IPostCreateState['scheduled_datetime']
  @post_create.State('type') type!: IPostCreateState['type']
  @post_create.State('images') images!: IPostCreateState['images']
  @post_create.State('videos') videos!: IPostCreateState['videos']
  @post_create.State('facebook_links') facebook_links!: IPostCreateState['facebook_links']
  @post_create.State('instagram_post_type')
  instagram_post_type!: IPostCreateState['instagram_post_type']
  @post_create.State('tiktok_option') tiktok_option!: IPostCreateState['tiktok_option']
  @post_create.Getter('use_facebook') use_facebook!: IPostCreateGetter['use_facebook']
  @post_create.Getter('use_twitter') use_twitter!: IPostCreateGetter['use_twitter']
  @post_create.Getter('use_instagram') use_instagram!: IPostCreateGetter['use_instagram']
  @post_create.Getter('use_tiktok') use_tiktok!: IPostCreateGetter['use_tiktok']
  @post_create.Action('changeTikTokOption') changeTikTokOption!: (option: TikTokOption) => void

  get is_instagram_publish_message() {
    const is_publish_permission = this.sns_accounts
      .filter(a => this.accounts.some(b => a.id === b.id && a.sns === b.sns))
      .some(v => v.is_publish_permission)

    return this.use_instagram && !is_publish_permission
  }

  get is_instagram_publish() {
    return this.use_instagram
  }

  get is_short_url() {
    return !this.use_instagram && !this.use_tiktok
  }

  get is_media() {
    return this.accounts.length > 0 && this.facebook_links.length === 0
  }

  get is_video() {
    return this.videos.length > 0
  }

  get is_facebook_link() {
    return this.type === 'link' || this.type === 'carousel'
  }

  get is_only_one_twitter() {
    return (
      !this.use_facebook && !this.use_instagram && this.use_twitter && this.accounts.length === 1
    )
  }

  get sns_post_id() {
    return this.api_post ? this.api_post.id : null
  }

  get expired_accounts() {
    return this.sns_accounts.filter(
      a => this.accounts.some(b => a.id === b.id && a.sns === b.sns) && a.expired
    )
  }

  get is_instagram_post() {
    return this.instagram_post_type === 'post'
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
