
import moment from 'moment-timezone'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'DateTime'
})
export default class DateTime extends Vue {
  @Prop({ type: String, required: true })
  time!: string

  @Prop({ type: Boolean, default: false })
  isDisplayTimeAgo!: boolean

  get date_time() {
    return moment(this.time).format('lll')
  }

  get locale() {
    return moment.locale()
  }
}
