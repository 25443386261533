import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import { EmbedTikTok } from '@/client/components/atoms'
import Dialog from '@/client/components-old/atoms/Dialog'
import EmbedFacebook from '@/client/components-old/atoms/EmbedFacebook'
import EmbedInstagram from '@/client/components-old/atoms/EmbedInstagram'
import EmbedTwitter from '@/client/components-old/atoms/EmbedTwitter'
import { IRootState } from '@/client/store/global'
import { SnsType } from '@/common/types'

type TSnsEmbedDialogOptions = {
  account_id?: string
  post_id?: string
  sns_link?: string
  embed_url?: string
}

@Component({
  name: 'SnsEmbedDialog',
  components: {
    Dialog,
    EmbedFacebook,
    EmbedInstagram,
    EmbedTwitter,
    EmbedTikTok
  }
})
export default class SnsEmbedDialog extends Vue {
  @State('user') user: IRootState['user']

  @Prop({ type: String, required: true })
  snsType!: SnsType

  visible = false
  page_id = ''
  post_id = ''
  tweet_id = ''
  url = ''
  embed_url = ''

  get is_embed() {
    switch (this.snsType) {
      case 'facebook':
        return this.page_id !== '' && this.post_id !== ''
      case 'twitter':
        return this.tweet_id !== ''
      case 'instagram':
        return this.url !== ''
      case 'tiktok':
        return this.post_id !== ''
      default:
        return false
    }
  }

  get is_facebook() {
    return this.snsType === 'facebook'
  }

  get is_twitter() {
    return this.snsType === 'twitter'
  }

  get is_instagram() {
    return this.snsType === 'instagram'
  }

  get is_tiktok() {
    return this.snsType === 'tiktok'
  }

  /**
   * ダイアログの表示
   * @param {TSnsEmbedDialogOptions} options ダイアログ設定
   * @returns {void}
   */
  open({
    account_id = '',
    post_id = '',
    sns_link = '',
    embed_url = ''
  }: TSnsEmbedDialogOptions): void {
    switch (this.snsType) {
      case 'facebook':
        this.page_id = account_id
        this.post_id = post_id
        break
      case 'twitter':
        this.tweet_id = post_id
        break
      case 'instagram':
        this.url = sns_link
        break
      case 'tiktok':
        this.post_id = post_id
        this.embed_url = embed_url
        break
    }

    this.visible = true
  }

  /**
   * ダイアログの非表示
   * @returns {void}
   */
  hide(): void {
    this.page_id = ''
    this.post_id = ''
    this.tweet_id = ''
    this.url = ''
  }
}
