import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/instagram_analytics'
import * as instagram_insight from '@/client/components-old/_utils/instagram_insight'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartDonutHalf from '@/client/components-old/molecules/ChartDonutHalf'
import { TrackingService } from '@/client/services'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as IInstagramState } from '@/client/store/modules/instagram_analytics'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'

type TPostData = {
  data_type: 'post_data'
  metric: 'reactions' | 'engagements'
}

type TInstagramAnalyticsChartDonutHalfOptions = TPostData

const analytics = namespace('analytics')
const instagram = namespace('instagram_analytics')

@Component({
  name: 'InstagramAnalyticsChartDonutHalf',
  components: {
    ChartDonutHalf,
    AnalyticsPanel,
    AnalyticsPanelHeader,
    Icon,
    Tooltip
  }
})
export default class InstagramAnalyticsChartDonutHalf extends Vue {
  @instagram.State('api_posts') post_data: IInstagramState['api_posts']
  @instagram.State('screen_name') screen_name: IInstagramState['screen_name']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TInstagramAnalyticsChartDonutHalfOptions

  get is_data() {
    switch (this.options.data_type) {
      case 'post_data':
        return this.post_data.length > 0
      default:
        return false
    }
  }

  get metrics() {
    switch (this.options.data_type) {
      case 'post_data':
        return constants.HALF_DOUGHNUT_CHART_POST_DATA_METRICS
      default:
        return []
    }
  }

  get metric_name() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? i18n.t(metric.text) : ''
  }

  get metric_tooltip() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? metric.tooltip : ''
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }

  get panel_type() {
    const data_type = constants.DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get chart_options() {
    const chart_color1 = '#1c84c6'
    const chart_color2 = '#ed5565'
    const chart_color3 = '#2ec881'
    const chart_color4 = '#f8ac59'

    switch (this.options.metric) {
      case 'reactions':
        return {
          title: i18n.t('反応数'),
          colors: [chart_color1, chart_color2],
          data: [
            [i18n.t('いいね！'), instagram_insight.getMetricTotal(this.post_data, 'likes')],
            [i18n.t('コメント'), instagram_insight.getMetricTotal(this.post_data, 'comments')]
          ]
        }

      case 'engagements':
        return {
          title: i18n.t('エンゲージメント数'),
          colors: [chart_color1, chart_color2, chart_color3, chart_color4],
          data: [
            [i18n.t('いいね！'), instagram_insight.getMetricTotal(this.post_data, 'likes')],
            [i18n.t('コメント'), instagram_insight.getMetricTotal(this.post_data, 'comments')],
            [i18n.t('保存'), instagram_insight.getMetricTotal(this.post_data, 'saved_count')],
            [i18n.t('シェア(リール)'), instagram_insight.getMetricTotal(this.post_data, 'shares')]
          ]
        }

      default:
        return {
          title: '',
          colors: [],
          data: []
        }
    }
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    TrackingService.sendEvent(
      `click:自社分析(IG)>${this.screen_name}|${this.options.metric}:CSVダウンロード`
    )

    const fields = [i18n.t('種別'), i18n.t('回数')]

    const data = this.chart_options.data

    const csv_data = { fields, data }

    const component_name = i18n.t('グラフ')
    const metric_name = i18n.t(this.metric_name)

    const csv_filename = [component_name, metric_name, this.start_date, this.end_date].join('_')

    csv.download(csv_data, csv_filename)
  }
}
