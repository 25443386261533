
import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'

import HelpLink from '@/client/components/molecules/HelpLink'
import {
  CONTRACT_STATUS_CANCEL,
  CONTRACT_STATUS_TRIAL
} from '@/client/components-old/_constants/project'
import Button from '@/client/components-old/atoms/Button'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import ExternalLink from '@/client/components-old/atoms/ExternalLink'
import Icon from '@/client/components-old/atoms/Icon'
import Select from '@/client/components-old/atoms/Select'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import { TrackingService } from '@/client/services'
import { IRootGetter, IRootState } from '@/client/store/global'

@Component({
  name: 'GlobalHeader',
  components: {
    Button,
    Dropdown,
    Select,
    ExternalLink,
    Icon,
    Tooltip,
    Account,
    HelpLink
  }
})
export default class GlobalHeader extends Vue {
  @State('user') user: IRootState['user']
  @State('projects') projects: IRootState['projects']
  @State('project') project: IRootState['project']
  @State('project_role') project_role: IRootState['project_role']
  @Getter('is_restricted') is_restricted: IRootGetter['is_restricted']
  @Action('logout') logout

  @Prop({ type: String, required: true })
  name: string

  @Prop({ type: Object, required: true })
  meta: {
    title: string
    description?: string
    color: string
    type: string
  }

  get is_deprecated_browser() {
    const ua = navigator.userAgent.toLowerCase()

    const IE = /MSIE|Trident\/7/i
    const EDGE = /Edge/i
    const OPERA = /Opera/i

    return [IE, EDGE, OPERA].some(browser => browser.test(ua))
  }

  get is_trial() {
    return this.project.contract_status === CONTRACT_STATUS_TRIAL
  }

  get is_waitlist() {
    return this.project.contract_status === CONTRACT_STATUS_CANCEL
  }

  get is_mobile() {
    return this.$mq === 'sm'
  }

  get is_description() {
    return !this.is_mobile && this.meta.description
  }

  get is_group() {
    return this.projects.length
  }

  get is_group_setting() {
    return this.project_role.role_admin
  }

  get is_create_group() {
    return this.project_role.role_admin || !this.projects.length
  }

  get rest_trial_date() {
    let temp = 0

    if (this.project.trial_end_date) {
      const current = moment().unix()
      const end = moment(this.project.trial_end_date).unix()

      // (トライアル期間終了日 - 現在日) / (1日のミリ秒)
      // 現在日が1ミリ秒以上経過していると1日減るためceil関数で切り上げ
      temp = Math.ceil((end - current) / (60 * 60 * 24))
    }

    return temp
  }

  get group_options() {
    const options = this.projects.map(v => ({
      icon: 'project',
      text: v.project_name,
      value: v.id
    }))

    const current_projects = options.filter(v => v.value === this.project.id)
    const other_projects = options.filter(v => v.value !== this.project.id)

    return current_projects.concat(other_projects)
  }

  get user_options() {
    const options = []

    if (!this.is_restricted && !this.user.is_demo_user) {
      options.push({
        icon: 'account-box',
        text: this.$options.filters.translate('個人設定'),
        value: 'setting_personal'
      })
    }

    if (this.is_group_setting) {
      options.push({
        icon: 'project',
        text: this.$options.filters.translate('グループ設定'),
        value: 'setting_group'
      })
    }

    if (this.is_create_group) {
      options.push({
        icon: 'add',
        text: this.$options.filters.translate('グループ新規作成'),
        value: 'setting_billing_info'
      })
    }

    options.push({
      icon: 'log-out',
      text: this.$options.filters.translate('ログアウト'),
      value: 'logout'
    })

    return options
  }

  @Emit('change-project')
  changeProject(payload: any) {
    return payload
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  @Emit('open-sidebar')
  openSidebar(payload: any) {
    return payload
  }

  /**
   * トライアル経由のご契約手続
   */
  async paymentContractforTrial() {
    TrackingService.sendEvent('View Payment Information')

    await this.changeRoute({ name: 'setting_billing_info' })
  }

  /**
   * 日付変換
   */
  convertDate(value: string | null) {
    if (!value) return ''

    return moment(value).format('ll')
  }

  /**
   * グループメニューの選択
   */
  async selectGroupMenu(command: number) {
    TrackingService.sendEvent('click: ヘッダー|グループ選択')
    await this.changeProject({ project_id: command })
  }

  /**
   * ユーザーメニューの選択
   */
  async selectUserMenu(command: string) {
    switch (command) {
      case 'setting_personal':
        if (this.name !== 'setting_personal') {
          await this.changeRoute({ name: 'setting_personal' })
        }
        break

      case 'setting_group':
        if (this.name !== 'setting_group') {
          await this.changeRoute({ name: 'setting_group' })
        }
        break

      case 'setting_billing_info':
        if (this.name !== 'setting_billing_info') {
          await this.changeRoute({ name: 'setting_billing_info' })
        }
        break

      case 'logout':
        await this.logout()
        await this.changeRoute({ name: 'login' })
        break

      default:
        break
    }
  }
}
