
import { Component, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import InstagramExcelReport from '@/client/components-old/organisms/InstagramExcelReport'

@Component({
  name: 'InstagramReport',
  components: {
    Scroll,
    InstagramExcelReport
  }
})
export default class InstagramReport extends Vue {}
