import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Label from '@/client/components-old/atoms/Label'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import { TMonitoringKeyword } from '@/client/utils/api/monitoring_keywords'

@Component({
  name: 'MonitoringKeywordItem',
  components: {
    ButtonLink,
    Label,
    Icon,
    SwitchButton
  }
})
export default class MonitoringKeywordItem extends Vue {
  @Prop({ type: Object, required: true })
  keyword: TMonitoringKeyword

  get keyword_type() {
    switch (this.keyword.type) {
      case 'positive':
        return 'success'
      case 'negative':
        return 'warning'
      default:
        return 'info'
    }
  }

  get keyword_text() {
    switch (this.keyword.type) {
      case 'positive':
        return 'ポジティブ'
      case 'negative':
        return 'ネガティブ'
      default:
        return 'その他'
    }
  }

  @Emit('change-valid')
  changeValid(is_valid: boolean) {
    return {
      keyword_id: this.keyword.id,
      is_valid
    }
  }

  @Emit('open-edit')
  openEdit() {
    return {
      keyword_id: this.keyword.id
    }
  }
}
