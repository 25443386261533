import moment from 'moment-timezone'
import twttr from 'twitter-text'
import { Component, Prop, Vue } from 'vue-property-decorator'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import PostMedia from '@/client/components-old/molecules/PostMedia'
import { ITwitterTweet } from '@/client/utils/api/posts'

@Component({
  name: 'TwitterTweet',
  components: {
    ButtonLink,
    Icon,
    PostMedia
  }
})
export default class TwitterTweet extends Vue {
  @Prop({ type: Object, required: true })
  twitterTweet: ITwitterTweet

  @Prop({ type: String, default: '' })
  text: string

  get created_at() {
    if (!this.twitterTweet.created_at) return ''

    return moment(this.twitterTweet.created_at).format('lll')
  }

  get full_text() {
    if (!this.twitterTweet.full_text) return ''

    let full_text = this.twitterTweet.full_text

    full_text = this.multilineEscape(full_text)

    full_text = this.htmlEscape(full_text)

    const entities = twttr.extractEntitiesWithIndices(full_text, {
      extractUrlsWithoutProtocol: true
    })

    full_text = twttr.autoLinkEntities(full_text, entities, {
      hashtagUrlBase: 'https://x.com/hashtag/',
      usernameUrlBase: 'https://x.com/',
      usernameIncludeSymbol: true,
      targetBlank: true
    })

    return full_text
  }

  /**
   * マルチ改行を除去する
   */
  multilineEscape(text: string): string {
    return text.replace(/\n\n\s*\n/g, '\n\n')
  }

  /**
   * HTMLで使用する特殊文字を除去する
   */
  htmlEscape(text: string): string {
    const entities = {
      '>': '&gt;',
      '<': '&lt;',
      '"': '&quot;',
      "'": '&#39;'
    }

    return text.replace(/["'><]/g, character => entities[character])
  }
}
