import { render, staticRenderFns } from "./PresetManagement.vue?vue&type=template&id=2666d723&scoped=true"
import script from "./PresetManagement.ts?vue&type=script&lang=ts&external"
export * from "./PresetManagement.ts?vue&type=script&lang=ts&external"
import style0 from "./PresetManagement.vue?vue&type=style&index=0&id=2666d723&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2666d723",
  null
  
)

export default component.exports