const MB = 2 ** 20

/**
 * 動画のファイルサイズをチェック
 */
export const validateVideoFileSize = (size: number) => {
  return size <= 100 * MB // API仕様だと1GBまで 失敗するケースがある為
}

/**
 * 動画の再生時間をチェック
 */
export function validateVideoDuration(duration: number): boolean {
  return 3 <= duration && duration <= 10 * 60 // ? 3秒以上10分以下
}

/**
 * 動画の解像度をチェック
 */
export function validateVideoResolution(width: number, height: number): boolean {
  return width >= 360 && height >= 360
}

/**
 * 動画のフレームレートをチェック
 */
export function validateVideoFrameRate(frameRate: number | null): boolean {
  return frameRate ? frameRate >= 23 && frameRate <= 60 : false
}
