
import { defineComponent, ref } from 'vue'
import { mapActions, mapState } from 'vuex'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Message from '@/client/components-old/atoms/Message'
import Account from '@/client/components-old/molecules/Account'
import { TrackingService } from '@/client/services'
import { getTranslateText as t } from '@/client/utils/filters'

type Options = {
  account_id: string
  sns_type: string
}

export default defineComponent({
  name: 'GroupSettingMonitoringUnsubscribeDialog',
  components: {
    Button,
    Dialog,
    Message,
    Account
  },
  setup() {
    const visible = ref(false)
    const loading = ref(false)
    const sns_type = ref('')
    const account_id = ref('')

    const close = () => {
      visible.value = false
      loading.value = false
      sns_type.value = ''
      account_id.value = ''

      TrackingService.sendEvent('click:モニタリングアカウント解除|キャンセル')
    }

    return {
      close,
      loading,
      visible,
      sns_type,
      account_id,
      t
    }
  },
  computed: {
    ...mapState('accounts', ['facebook', 'instagram', 'twitter']),

    account_info() {
      switch (this.sns_type) {
        case 'facebook': {
          return this.facebook.find(account => account.id === this.account_id) || null
        }

        case 'instagram': {
          return this.instagram.find(account => account.id === this.account_id) || null
        }

        case 'twitter': {
          return this.twitter.find(account => account.id === this.account_id) || null
        }

        default: {
          return null
        }
      }
    }
  },
  methods: {
    ...mapActions('accounts', ['unsubscribeSnsAccount']),
    ...mapActions('notification', ['showNotification']),

    /**
     * ダイアログの表示
     */
    open(options: Options) {
      this.visible = true
      this.loading = false
      this.sns_type = options.sns_type
      this.account_id = options.account_id

      TrackingService.sendEvent('click:グループ設定>モニタリング|モニタリングアカウント|登録解除')
    },

    /**
     * フォームの送信
     */
    async unsubscribeAccount() {
      if (!this.account_id) return

      this.loading = true

      const result = await this.unsubscribeSnsAccount({
        account_id: this.account_id,
        sns_type: this.sns_type
      })

      this.loading = false

      if (result.data) {
        TrackingService.sendEvent('click:モニタリングアカウント解除|月末に登録解除')

        this.showNotification({
          title: t('[[name]]を登録解除待ちに変更しました。', { name: this.account_info?.name })
        })
        this.close()
      } else {
        this.showNotification({
          title: t('[[name]]の登録解除に失敗しました。', { name: this.account_info?.name }),
          type: 'error'
        })
      }
    }
  }
})
