import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Label from '@/client/components-old/atoms/Label'
import { NO_IMAGE_URL } from '@/client/constants/media'

type TweetType = 'tweet' | 'quote_tweet' | 'mention' | 'reply'

@Component({
  name: 'PostMedia',
  components: {
    Icon,
    Label
  }
})
export default class PostMedia extends Vue {
  @Prop({ type: String, default: 'status' })
  type:
    | 'none'
    | 'status'
    | 'photo'
    | 'link'
    | 'video'
    | 'shared'
    | 'text'
    | 'animation-gif'
    | 'image'
    | 'carousel'
    | 'reels'
    | 'story'
    | 'none'

  @Prop({ type: String, default: null })
  tweetType: TweetType

  @Prop({ type: String, default: NO_IMAGE_URL })
  src: string

  @Prop({ type: Number, default: 0 })
  count: number

  @Prop({ type: Boolean, default: false })
  isCursor: boolean

  @Prop({ type: Boolean, default: true })
  isText: boolean

  @Prop({ type: Boolean, default: false })
  isTopMedia: boolean

  get icon_name() {
    switch (this.type) {
      case 'status':
      case 'text':
        return 'format-text'
      case 'photo':
      case 'image':
        return 'photo-camera'
      case 'animation-gif':
        return 'gif'
      case 'link':
        return 'link'
      case 'video':
        return 'videocam'
      case 'shared':
        return 'forward'
      case 'carousel':
        return 'perm-media'
      case 'reels':
        return 'ig-reel'
      case 'story':
        return 'story'
      default:
        return ''
    }
  }

  get media_text() {
    switch (this.type) {
      case 'status':
      case 'text':
        return 'テキスト'
      case 'photo':
      case 'image':
        return '画像'
      case 'link':
        return 'リンク'
      case 'video':
        return '動画'
      case 'shared':
        return 'シェア'
      case 'animation-gif':
        return 'アニメーションGIF'
      case 'carousel':
        return 'カルーセル'
      case 'reels':
        return 'リール'
      case 'story':
        return 'ストーリーズ'
      default:
        return ''
    }
  }

  get is_text() {
    return this.isText && this.media_text !== ''
  }

  get is_count() {
    switch (this.type) {
      case 'image':
      case 'photo':
      case 'carousel':
      case 'link':
        return this.count > 1
      default:
        return false
    }
  }

  get style_object() {
    return { backgroundImage: `url(${!this.src ? NO_IMAGE_URL : this.src})` }
  }

  @Emit('click')
  click(event: Event) {
    return event
  }
}
