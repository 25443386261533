
import moment from 'moment-timezone'
import DatePick from 'vue-date-pick'
import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { PostTagsManagementDialog } from '@/client/components/organisms/Tags'
import { MIN_DATE } from '@/client/components-old/_constants/global'
import Button from '@/client/components-old/atoms/Button'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Icon from '@/client/components-old/atoms/Icon'
import Select from '@/client/components-old/atoms/Select'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tabs from '@/client/components-old/atoms/Tabs'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AccountSelect from '@/client/components-old/molecules/AccountSelect'
import DateRangePicker from '@/client/components-old/molecules/DateRangePicker'
import Search from '@/client/components-old/molecules/Search'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IGetter as IAccountGetter } from '@/client/store/modules/accounts'
import { IState as ICategoryState } from '@/client/store/modules/categories'
import { IState as IPostState } from '@/client/store/modules/post_management'
import { IPost } from '@/client/utils/api/posts'
import { getSnsName } from '@/client/utils/character'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'
import { convertHelpMessage } from '@/client/utils/notification'
import { createTikTokVideoUrl } from '@/client/utils/tiktok'
import { SnsType } from '@/common/types'

const post_management = namespace('post_management')
const categories = namespace('categories')
const accounts = namespace('accounts')
const notification = namespace('notification')

@Component({
  name: 'PostManagementHeader',
  components: {
    PostTagsManagementDialog,
    DatePick,
    Button,
    ButtonGroup,
    Icon,
    Select,
    SelectMulti,
    SwitchButton,
    Tabs,
    Tooltip,
    AccountSelect,
    DateRangePicker,
    Search
  }
})
export default class PostManagementHeader extends Vue {
  @State('user') user!: IRootState['user']
  @State('project_role') project_role!: IRootState['project_role']
  @accounts.Getter('post') accounts!: IAccountGetter['post']
  @categories.State('api_post_categories') post_categories!: ICategoryState['api_post_categories']
  @post_management.State('api_count') api_count!: IPostState['api_count']
  @post_management.State('api_persons') api_persons!: IPostState['api_persons']
  @post_management.State('start_date') start_date!: IPostState['start_date']
  @post_management.State('end_date') end_date!: IPostState['end_date']
  @post_management.State('order') order!: IPostState['order']
  @post_management.State('display_mode') display_mode!: IPostState['display_mode']
  @post_management.State('filter_status') filter_status!: IPostState['filter_status']
  @post_management.State('filter_account') filter_account!: IPostState['filter_account']
  @post_management.State('filter_categories') filter_categories!: IPostState['filter_categories']
  @post_management.State('filter_persons') filter_persons!: IPostState['filter_persons']
  @post_management.State('filter_keyword') filter_keyword!: IPostState['filter_keyword']
  @post_management.State('is_get_post_failed') is_get_post_failed!: IPostState['is_get_post_failed']
  @post_management.State('is_twitter_trend') is_twitter_trend!: IPostState['is_twitter_trend']
  @post_management.Action('getAPIPostsByCsv') getAPIPostsByCsv!: () => IPost[]
  @post_management.Action('reloadAllPosts') reloadAllPosts!: any
  @post_management.Action('changePostDateRange') changePostDateRange!: any
  @post_management.Action('changePostOrder') changePostOrder!: any
  @post_management.Action('changePostDisplayMode') changePostDisplayMode!: any
  @post_management.Action('filterPostStatus') filterPostStatus!: any
  @post_management.Action('filterPostAccount') filterPostAccount!: any
  @post_management.Action('filterPostCategories') filterPostCategories!: any
  @post_management.Action('filterPostPersons') filterPostPersons!: any
  @post_management.Action('filterPostKeyword') filterPostKeyword!: any
  @post_management.Action('changeIsTwitterTrend') changeIsTwitterTrend!: any
  @notification.Action('showNotification') showNotification!: any

  is_active = false
  is_show_date_picker = false
  date_range_selected = 'before_30_day_after_60_day'

  $refs!: {
    PostTagsManagementDialog: any
  }

  @Watch('post_categories', { immediate: true })
  async watchCategories() {
    if (
      this.filter_categories.length &&
      !this.filter_categories.every(x => this.post_categories.map(y => y.id).includes(x))
    ) {
      await this.filterPostCategories([])
    }
  }

  get display_mode_options() {
    return [
      {
        text: i18n.t('リスト'),
        value: 'list'
      },
      {
        text: i18n.t('週'),
        value: 'week'
      },
      {
        text: i18n.t('月'),
        value: 'month'
      }
    ]
  }

  get display_mode_text() {
    switch (this.display_mode) {
      case 'week':
        return '週'
      case 'month':
        return '月'
      default:
        return 'リスト'
    }
  }

  get filter_status_options() {
    return [
      {
        value: 'all',
        text: `${i18n.t('全て')} (${this.api_count.all})`,
        icon: 'language'
      },
      {
        value: 'draft',
        text: `${i18n.t('下書き')} (${this.api_count.draft})`,
        icon: 'save'
      },
      {
        value: 'scheduled',
        text: `${i18n.t('予約')} (${this.api_count.scheduled})`,
        icon: 'query-builder'
      },
      {
        value: 'published',
        text: `${i18n.t('公開')} (${this.api_count.published})`,
        icon: 'done'
      },
      {
        value: 'failure',
        text: `${i18n.t('失敗')} (${this.api_count.failure})`,
        icon: 'clear'
      },
      {
        value: 'approval',
        text: `${i18n.t('承認待ち')} (${this.api_count.approval})`,
        icon: 'assignment-late'
      },
      {
        value: 'reject',
        text: `${i18n.t('差し戻し')} (${this.api_count.reject})`,
        icon: 'assignment-reject'
      }
    ]
  }

  get calendar_text() {
    const start_date = moment(this.start_date)
    const end_date = moment(this.end_date)

    if (start_date.month() === end_date.month()) {
      const format = this.user.language === 'ja' ? 'YYYY年M月' : 'MMM YYYY'
      return start_date.format(format)
    } else {
      const format_year = this.user.language === 'ja' ? 'YYYY年' : 'YYYY'
      const format_month = this.user.language === 'ja' ? 'M月' : 'MMM'

      if (start_date.year() === end_date.year()) {
        // YYYY年M月 - M月
        if (this.user.language === 'ja') {
          return (
            start_date.format(format_year) +
            start_date.format(format_month) +
            ' - ' +
            end_date.format(format_month)
          )
        } else {
          // MMM - MMM YYYY
          return (
            start_date.format(format_month) +
            ' - ' +
            end_date.format(format_month) +
            ' ' +
            start_date.format(format_year)
          )
        }
      } else {
        if (this.user.language === 'ja') {
          // YYYY年M月 - YYYY年M月
          return (
            start_date.format(format_year) +
            start_date.format(format_month) +
            ' - ' +
            end_date.format(format_year) +
            end_date.format(format_month)
          )
        } else {
          // YYYY MMM - YYYY MMMM
          return (
            start_date.format(format_month) +
            start_date.format(format_year) +
            ' - ' +
            end_date.format(format_month) +
            ' ' +
            end_date.format(format_year)
          )
        }
      }
    }
  }

  get weekdays() {
    return this.user.language === 'ja'
      ? ['月', '火', '水', '木', '金', '土', '日']
      : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  }

  get months() {
    return this.user.language === 'ja'
      ? ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
      : [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ]
  }

  get account_id() {
    return this.filter_account && this.filter_account.id ? this.filter_account.id : ''
  }

  get account_options() {
    const accounts = this.accounts
      .filter((account, index, accounts) => accounts.findIndex(p => p.id === account.id) === index)
      .map(account => ({
        id: account.id,
        username: account.username,
        img: account.img,
        name: account.name,
        type: account.sns,
        expired: account.expired
      }))

    const default_account = {
      id: '',
      name: i18n.t('全アカウント'),
      img: require('@/client/assets/images/icon_sns_all.svg'),
      type: '',
      expired: false
    }

    return [default_account, ...accounts]
  }

  get filter_person_options() {
    const me = this.api_persons.filter(v => v.user.id === this.user.id)
    const other_users = this.api_persons.filter(v => v.user.id !== this.user.id)

    // 自分を1番上にする
    return [...me, ...other_users].map(person => ({
      value: person.user.id,
      text: person.user.name
    }))
  }

  get filter_category_options() {
    return this.post_categories.map(category => ({
      value: category.id,
      text: category.name
    }))
  }

  get mobile_date_range_options() {
    return [
      {
        value: 'before_30_day_after_60_day',
        text: `${i18n.t('30日前')} - ${i18n.t('60日後')}`
      },
      {
        value: 'today',
        text: i18n.t('今日')
      },
      {
        value: 'current_week',
        text: i18n.t('今週')
      },
      {
        value: 'current_month',
        text: i18n.t('今月')
      },
      {
        value: 'next_week',
        text: i18n.t('来週')
      },
      {
        value: 'next_month',
        text: i18n.t('来月')
      },
      {
        value: 'last_week',
        text: i18n.t('先週')
      },
      {
        value: 'last_month',
        text: i18n.t('先月')
      },
      {
        value: 'half_year_after',
        text: i18n.t('半年後')
      },
      {
        value: '1_year_ago',
        text: i18n.t('1年前')
      }
    ]
  }

  get order_options() {
    return [
      {
        value: 'publish_desc',
        text: i18n.t('公開日時(新しい順)')
      },
      {
        value: 'publish_asc',
        text: i18n.t('公開日時(古い順)')
      },
      {
        value: 'modified_desc',
        text: i18n.t('更新日時(新しい順)')
      },
      {
        value: 'modified_asc',
        text: i18n.t('更新日時(古い順)')
      }
    ]
  }

  get is_list() {
    return this.display_mode === 'list'
  }

  get is_week() {
    return this.display_mode === 'week'
  }

  get is_month() {
    return this.display_mode === 'month'
  }

  get disabled_csv() {
    return (
      !this.api_count[this.filter_status] ||
      (this.is_month && this.is_twitter_trend) ||
      this.is_get_post_failed
    )
  }

  get disabled_prev() {
    const start_date = moment(this.start_date)

    const min_date = moment(MIN_DATE)

    if (this.is_week) {
      return min_date.startOf('isoWeek').isSameOrAfter(start_date)
    }

    if (this.is_month) {
      return min_date.startOf('month').isSameOrAfter(start_date)
    }

    return false
  }

  get disabled_next() {
    const end_date = moment(this.end_date)

    const max_date = moment().add(6, 'months')

    if (this.is_week) {
      return max_date.startOf('isoWeek').isSameOrBefore(end_date)
    }

    if (this.is_month) {
      return max_date.startOf('month').isSameOrBefore(end_date)
    }

    return false
  }

  /**
   * 日付が無効かチェック
   */
  isDateDisabled(date: string): boolean {
    const start = moment(MIN_DATE).startOf('day')
    const end = moment().startOf('day').add(6, 'month')
    const selected_date = moment(date).tz('Asia/Tokyo').startOf('day')

    return !moment(selected_date).isBetween(start, end, 'day', '[]')
  }

  /**
   * 表示する
   */
  openDatePicker(): void {
    this.is_show_date_picker = true

    document.addEventListener('click', this.handleClick, false)
    document.addEventListener('touchstart', this.handleClick, false)
    document.addEventListener('keyup', this.handleKeyUp, false)
  }

  /**
   * 閉じる
   */
  closeDatePicker(): void {
    this.is_show_date_picker = false

    document.removeEventListener('click', this.handleClick)
    document.removeEventListener('touchstart', this.handleClick)
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  /**
   * アカウントの変更
   */
  async changeAccount(accountId: string): Promise<void> {
    TrackingService.sendEvent(
      `select:投稿管理|画面内ヘッダー(${this.display_mode_text})|アカウント選択`
    )

    const account = this.account_options.find(account => account.id === accountId)

    if (account.expired) {
      this.showNotification({
        type: 'error',
        title:
          'アクセストークンが失効しているため、データ取得および返信などの操作ができない状態です。',
        message: convertHelpMessage({
          message: '至急、アクセストークンを更新してください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    }

    await this.filterPostAccount({ id: account.id, sns: account.type })
  }

  /**
   * 担当者の変更
   */
  changePostPersons(persons: number[]) {
    TrackingService.sendEvent(
      `select:投稿管理|画面内ヘッダー(${this.display_mode_text})|フィルター:担当者`
    )

    this.filterPostPersons(persons)
  }

  /**
   * 投稿タグの変更
   */
  changePostTags(tags: string[]) {
    TrackingService.sendEvent(
      `select:投稿管理|画面内ヘッダー(${this.display_mode_text})|フィルター:タグ`
    )

    this.filterPostCategories(tags)
  }

  /**
   * キーワードの変更
   */
  changePostKeyword() {
    TrackingService.sendEvent(
      `click:投稿管理|画面内ヘッダー(${this.display_mode_text})|フィルター:キーワード検索`
    )

    this.reloadAllPosts()
  }

  /**
   * 投稿一覧の再読み込み
   */
  reloadPosts() {
    TrackingService.sendEvent(`click:投稿管理|画面内ヘッダー(${this.display_mode_text})|更新`)

    this.reloadAllPosts()
  }

  /**
   * 投稿の表示モードの変更
   */
  changeDisplayMode(mode: string) {
    switch (mode) {
      case 'week':
        TrackingService.sendEvent('click:投稿管理|タブ内ヘッダー|表示切替:週')
        break
      case 'month':
        TrackingService.sendEvent('click:投稿管理|画面内ヘッダー(月)|表示中の月')
        break
      default:
        TrackingService.sendEvent('click:投稿管理|タブ内ヘッダー|表示切替:月')
        break
    }

    this.changeDisplayMode(mode)
  }

  /**
   * 投稿の日付範囲を変更
   */
  changeDateRange(range: string[]) {
    TrackingService.sendEvent('select:投稿管理|リスト|期間指定')

    return this.changePostDateRange(range)
  }

  /**
   * 投稿ステータスを変更する
   */
  changePostStatus(status: string) {
    switch (status) {
      case 'draft':
        TrackingService.sendEvent('click:投稿管理|タブ:下書き')
        break
      case 'scheduled':
        TrackingService.sendEvent('click:投稿管理|タブ:予約')
        break
      case 'published':
        TrackingService.sendEvent('click:投稿管理|タブ:公開')
        break
      case 'failure':
        TrackingService.sendEvent('click:投稿管理|タブ:失敗')
        break
      case 'approval':
        TrackingService.sendEvent('click:投稿管理|タブ:承認待ち')
        break
      case 'reject':
        TrackingService.sendEvent('click:投稿管理|タブ:差し戻し')
        break
      default:
        TrackingService.sendEvent('click:投稿管理|タブ:全て')
        break
    }

    this.filterPostStatus(status)
  }

  /**
   * 投稿の並び順を変更
   */
  changeOrder(order: string) {
    switch (order) {
      case 'publish_desc':
        TrackingService.sendEvent('select:投稿管理|リスト|並び順:公開日時(新しい順)')
        break
      case 'publish_asc':
        TrackingService.sendEvent('select:投稿管理|リスト|並び順:公開日時(古い順)')
        break
      case 'modified_desc':
        TrackingService.sendEvent('select:投稿管理|リスト|並び順:更新日時(新しい順)')
        break
      case 'modified_asc':
        TrackingService.sendEvent('select:投稿管理|リスト|並び順:更新日時(古い順)')
        break
    }

    this.changePostOrder(order)
  }

  /**
   * トレンド実績の表示フラグ変更
   */
  switchTwitterTrend(value: boolean) {
    TrackingService.sendEvent('switch:投稿管理|月|トレンド実績')

    this.changeIsTwitterTrend(value)
  }

  /**
   * スマホの選択期間の変更
   */
  async changeDateRangeSelect(date_range: string): Promise<void> {
    const start_date = moment()
    const end_date = moment()

    switch (date_range) {
      case 'before_30_day_after_60_day':
        start_date.subtract(30, 'day')
        end_date.add(60, 'day')
        break
      case 'current_week':
        start_date.startOf('isoWeek')
        end_date.endOf('isoWeek')
        break
      case 'current_month':
        start_date.startOf('month')
        end_date.endOf('month')
        break
      case 'next_week':
        start_date.add(1, 'week').startOf('isoWeek')
        end_date.add(1, 'week').endOf('isoWeek')
        break
      case 'next_month':
        start_date.add(1, 'month').startOf('month')
        end_date.add(1, 'month').endOf('month')
        break
      case 'last_week':
        start_date.subtract(1, 'week').startOf('isoWeek')
        end_date.subtract(1, 'week').endOf('isoWeek')
        break
      case 'last_month':
        start_date.subtract(1, 'month').startOf('month')
        end_date.subtract(1, 'month').endOf('month')
        break
      case 'half_year_after':
        end_date.add(6, 'month')
        break
      case '1_year_ago':
        start_date.subtract(1, 'year')
        break
    }

    await this.changeDateRange([start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD')])
  }

  /**
   * 選択した日付の週/月に変更
   */
  async selectDatePick(date: string): Promise<void> {
    this.is_show_date_picker = false

    if (this.is_week) {
      await this.getSelectWeek(date)
    }
    if (this.is_month) {
      await this.getSelectMonth(date)
    }
  }

  /**
   * カレンダーの前ボタンのクリック
   */
  async getPrev(): Promise<void> {
    if (this.is_week) {
      return this.getPrevWeek()
    }

    if (this.is_month) {
      return this.getPrevMonth()
    }
  }

  /**
   * カレンダーの次ボタンのクリック
   */
  async getNext(): Promise<void> {
    if (this.is_week) {
      return this.getNextWeek()
    }

    if (this.is_month) {
      return this.getNextMonth()
    }
  }

  /**
   * 選択した週を取得
   */
  async getSelectWeek(current_date: string): Promise<void> {
    TrackingService.sendEvent('click:投稿管理|画面内ヘッダー(週)|表示中の週')

    const start_date = moment(current_date).startOf('isoWeek')
    const end_date = start_date.clone().endOf('isoWeek')

    const old_start_date = moment(this.start_date).startOf('isoWeek')

    if (start_date.isSame(old_start_date)) return

    await this.changePostDateRange([start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD')])
  }

  /**
   * 選択している週の前の週に変更する
   */
  async getPrevWeek(): Promise<void> {
    TrackingService.sendEvent('click:投稿管理|画面内ヘッダー(週)|前週')

    const start_date = moment(this.start_date).subtract(1, 'week').startOf('isoWeek')
    const end_date = start_date.clone().endOf('isoWeek')

    await this.changePostDateRange([start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD')])
  }

  /**
   * 選択している週の次の週に変更する
   */
  async getNextWeek(): Promise<void> {
    TrackingService.sendEvent('click:投稿管理|画面内ヘッダー(週)|次週')

    const start_date = moment(this.start_date).add(1, 'week').startOf('isoWeek')
    const end_date = start_date.clone().endOf('isoWeek')

    await this.changePostDateRange([start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD')])
  }

  /**
   * 選択した月を取得
   */
  async getSelectMonth(current_date: string): Promise<void> {
    TrackingService.sendEvent('click:投稿管理|画面内ヘッダー(月)|表示中の月')

    const start_date = moment(current_date).startOf('month')
    const end_date = start_date.clone().endOf('month')

    const old_start_date = moment(this.start_date).startOf('month')

    if (start_date.isSame(old_start_date)) return

    await this.changePostDateRange([start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD')])
  }

  /**
   * 選択した月の前の月に変更する
   */
  async getPrevMonth(): Promise<void> {
    TrackingService.sendEvent('click:投稿管理|画面内ヘッダー(月)|前週')

    const start_date = moment(this.start_date).subtract(1, 'month').startOf('month')
    const end_date = start_date.clone().endOf('month')

    await this.changeDateRange([start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD')])
  }

  /**
   * 選択した月の翌月に変更
   */
  async getNextMonth(): Promise<void> {
    TrackingService.sendEvent('click:投稿管理|画面内ヘッダー(月)|次週')

    const start_date = moment(this.start_date).add(1, 'month').startOf('month')
    const end_date = start_date.clone().endOf('month')

    await this.changeDateRange([start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD')])
  }

  /**
   * 今日の日付に変更
   */
  async getPostToday(): Promise<void> {
    const start_date = moment()
    const end_date = moment()

    if (this.is_week) {
      TrackingService.sendEvent('click:投稿管理|画面内ヘッダー(週)|今日')

      start_date.startOf('isoWeek')
      end_date.startOf('isoWeek').endOf('isoWeek')

      await this.changePostDateRange([
        start_date.format('YYYY-MM-DD'),
        end_date.format('YYYY-MM-DD')
      ])
    }

    if (this.is_month) {
      TrackingService.sendEvent('click:投稿管理|画面内ヘッダー(月)|今日')

      start_date.startOf('month')
      end_date.startOf('month').endOf('month')

      await this.changePostDateRange([
        start_date.format('YYYY-MM-DD'),
        end_date.format('YYYY-MM-DD')
      ])
    }
  }

  /**
   * CSVダウンロード処理
   */
  async downloadCsv(): Promise<void> {
    try {
      TrackingService.sendEvent('click:投稿管理|タブ内ヘッダー|CSVダウンロード')

      const posts = await this.getAPIPostsByCsv()

      const csv_filename = ['posts', moment().format('YYYY-MM-DD')].join('_')
      const csv_data = []

      csv_data.push([
        i18n.t('予定または公開日時'),
        i18n.t('作成日時'),
        i18n.t('最終変更日時'),
        i18n.t('ステータス'),
        i18n.t('SNS種別'),
        i18n.t('タイプ'),
        i18n.t('アカウント名'),
        i18n.t('投稿内容'),
        i18n.t('画像URL'),
        i18n.t('ビデオURL'),
        i18n.t('いいね！') + '(Facebook)',
        i18n.t('コメント') + '(Facebook)',
        i18n.t('シェア') + '(Facebook)',
        i18n.t('反応率') + '(Facebook)',
        i18n.t('投稿時のファン数') + '(Facebook)',
        i18n.t('ファンのリーチ数') + '(Facebook)',
        i18n.t('エンゲージメント数') + '(Facebook)',
        i18n.t('エンゲージメント率') + '(Facebook)',
        i18n.t('リポスト') + '(X)',
        i18n.t('引用') + '(X)',
        i18n.t('いいね') + '(X)',
        i18n.t('返信') + '(X)',
        i18n.t('反応率') + '(X)',
        i18n.t('投稿時のフォロワー数') + '(X)',
        i18n.t('インプレッション数') + '(X)',
        i18n.t('いいね！') + '(Instagram)',
        i18n.t('コメント') + '(Instagram)',
        i18n.t('反応率') + '(Instagram)',
        i18n.t('保存') + '(Instagram)',
        i18n.t('リーチ') + '(Instagram)',
        i18n.t('エンゲージメント数') + '(Instagram)',
        i18n.t('エンゲージメント率') + '(Instagram)',
        i18n.t('前へ') + '(Instagram)',
        i18n.t('次へ') + '(Instagram)',
        i18n.t('ストーリーズから移動') + '(Instagram)',
        i18n.t('動画再生数') + '(TikTok)',
        i18n.t('リーチ') + '(TikTok)',
        i18n.t('視聴完了率') + '(TikTok)',
        i18n.t('平均視聴秒数') + '(TikTok)',
        i18n.t('合計視聴秒数') + '(TikTok)',
        i18n.t('いいね') + '(TikTok)',
        i18n.t('コメント') + '(TikTok)',
        i18n.t('シェア') + '(TikTok)',
        i18n.t('反応数') + '(TikTok)',
        i18n.t('反応率') + '(TikTok)',
        i18n.t('エンゲージメント率') + '(TikTok)',
        i18n.t('短縮URLクリック数'),
        i18n.t('投稿先URL'),
        i18n.t('タグ')
      ])

      for (const post of posts) {
        for (const fb_posts of post.fb_posts) {
          csv_data.push(this.convertPostForCsv(post, 'facebook', fb_posts))
        }

        for (const tw_posts of post.tw_posts) {
          csv_data.push(this.convertPostForCsv(post, 'twitter', tw_posts))
        }

        for (const in_posts of post.in_posts) {
          csv_data.push(this.convertPostForCsv(post, 'instagram', in_posts))
        }

        for (const in_stories of post.in_stories) {
          csv_data.push(this.convertPostForCsv(post, 'instagram', in_stories))
        }

        if (post.fb_schedule) {
          csv_data.push(this.convertPostForCsv(post, 'facebook', post.fb_schedule))
        }

        for (const tt_post of post.tt_posts) {
          csv_data.push(this.convertPostForCsv(post, 'tiktok', tt_post))
        }
      }

      csv.download(csv_data, csv_filename)
    } catch (error) {
      this.showNotification({ title: 'CSVダウンロードに失敗しました。', type: 'error' })
    }
  }

  /**
   * 投稿データをCSVに変換
   */
  convertPostForCsv(post: IPost, sns: SnsType, account: any): any {
    // 予定または公開日時
    let action_datetime = account.publish_datetime ? account.publish_datetime : post.action_datetime
    action_datetime = action_datetime ? csv.format(post.action_datetime) : ''

    // 作成日時
    const created_datetime = csv.format(post.created)
    // 最終更新日時
    const modified_datetime = csv.format(post.modified)
    // 投稿ステータス
    const post_status = this.getPostStatus(post.status)
    // SNS種別 公式SNSの表記に合わせる
    const sns_type = getSnsName(sns)
    // タイプ
    const post_type = this.getPostType(post.type)
    // アカウント名
    const account_name = account.account.name
    // 投稿内容
    const post_message = account.message
    // 画像URL
    const image_url = post.post_images[0] ? post.post_images[0].image_url : ''
    // ビデオURL
    const video_url = post.post_videos[0] ? post.post_videos[0].video_url : ''
    // いいね！数(FB)
    const like_fb =
      sns === 'facebook' && account.insight && this.isNotNull(account.insight.likes_insights)
        ? account.insight.likes_insights
        : ''
    // コメント数(FB)
    const comment_fb =
      sns === 'facebook' && account.insight && this.isNotNull(account.insight.comments_insights)
        ? account.insight.comments_insights
        : ''
    // シェア数(FB)
    const share_fb =
      sns === 'facebook' && account.insight && this.isNotNull(account.insight.shares_insights)
        ? account.insight.shares_insights
        : ''
    // 反応率(FB)
    const reactions_fb =
      sns === 'facebook' && account.insight && this.isNotNull(account.insight.reactions_rate)
        ? account.insight.reactions_rate
        : ''
    // 投稿時のファン数(FB)
    const fans_fb =
      sns === 'facebook' &&
      account.insight &&
      this.isNotNull(account.insight.page_fans_at_that_time)
        ? account.insight.page_fans_at_that_time
        : ''
    // リーチ数(FB)
    const reach_fb =
      sns === 'facebook' &&
      account.insight &&
      this.isNotNull(account.insight.post_impressions_unique)
        ? account.insight.post_impressions_unique
        : ''
    // エンゲージメント数(FB)
    const no_actions_fb =
      sns === 'facebook' && account.insight && this.isNotNull(account.insight.post_engaged_users)
        ? account.insight.post_engaged_users
        : ''
    // エンゲージメント率(FB)
    const action_fb =
      sns === 'facebook' &&
      account.insight &&
      this.isNotNull(account.insight.post_engaged_users_rate)
        ? account.insight.post_engaged_users_rate
        : ''

    // リポスト数(TW)
    const no_retweet =
      sns === 'twitter' && account.insight && this.isNotNull(account.insight.retweets)
        ? account.insight.retweets
        : ''
    // 引用数(TW)
    const quotes_tw =
      sns === 'twitter' && account.insight && this.isNotNull(account.insight.quotes)
        ? account.insight.quotes
        : ''
    // いいね数(TW)
    const like_tw =
      sns === 'twitter' && account.insight && this.isNotNull(account.insight.favorites)
        ? account.insight.favorites
        : ''
    // 返信数(TW)
    const reply_tw =
      sns === 'twitter' && account.insight && this.isNotNull(account.insight.replies)
        ? account.insight.replies
        : ''
    // 反応率(TW)
    const reactions_tw =
      sns === 'twitter' && account.insight && this.isNotNull(account.insight.reactions_rate)
        ? account.insight.reactions_rate
        : ''
    // 投稿時のフォロワー数(TW)
    const follower_tw =
      sns === 'twitter' && account.insight && this.isNotNull(account.insight.followers_at_that_time)
        ? account.insight.followers_at_that_time
        : ''
    // インプレッション数(TW)
    const impressions_tw =
      sns === 'twitter' && account.insight && this.isNotNull(account.insight.impressions)
        ? account.insight.impressions
        : ''

    // いいね(IN)
    const like_in =
      sns === 'instagram' && account.insight && this.isNotNull(account.insight.likes)
        ? account.insight.likes
        : ''
    // コメント(IN)
    const comment_in =
      sns === 'instagram' && account.insight && this.isNotNull(account.insight.comments)
        ? account.insight.comments
        : ''
    // 反応率(IN)
    const reactions_in =
      sns === 'instagram' && account.insight && this.isNotNull(account.insight.reactions_rate)
        ? account.insight.reactions_rate
        : ''
    // 保存(IN)
    const save_in =
      sns === 'instagram' && account.insight && this.isNotNull(account.insight.saved_count)
        ? account.insight.saved_count
        : ''

    // リーチ(IN)
    const is_story = post.type === 'story'

    const metrics_reach_in = is_story ? 'reach' : 'impressions_unique'
    const reach_in =
      sns === 'instagram' && account.insight && this.isNotNull(account.insight[metrics_reach_in])
        ? account.insight[metrics_reach_in]
        : ''
    // エンゲージメント数(IN)
    const no_actions_in =
      sns === 'instagram' && account.insight && this.isNotNull(account.insight.engagements)
        ? account.insight.engagements
        : ''
    // エンゲージメント率(IN)
    const actions_in =
      sns === 'instagram' && account.insight && this.isNotNull(account.insight.engagements_rate)
        ? account.insight.engagements_rate
        : ''
    // 前へ(IN)
    const taps_back_in =
      sns === 'instagram' && account.insight && this.isNotNull(account.insight.taps_back)
        ? account.insight.taps_back
        : ''
    // 次へ(IN)
    const taps_forward_in =
      sns === 'instagram' && account.insight && this.isNotNull(account.insight.taps_forward)
        ? account.insight.taps_forward
        : ''
    // ストーリーズから移動(IN)
    const exits_in =
      sns === 'instagram' && account.insight && this.isNotNull(account.insight.exits)
        ? account.insight.exits
        : ''

    // 動画再生数(TT)
    const video_views_tt =
      sns === 'tiktok' && account.insight && this.isNotNull(account.insight.video_views)
        ? account.insight.video_views
        : ''
    // リーチ(TT)
    const reach_tt =
      sns === 'tiktok' && account.insight && this.isNotNull(account.insight.reach)
        ? account.insight.reach
        : ''
    // 視聴完了率(TT)
    const full_video_watched_rate_tt =
      sns === 'tiktok' && account.insight && this.isNotNull(account.insight.full_video_watched_rate)
        ? account.insight.full_video_watched_rate
        : ''
    // 平均視聴秒数(TT)
    const average_time_watched_tt =
      sns === 'tiktok' && account.insight && this.isNotNull(account.insight.average_time_watched)
        ? account.insight.average_time_watched
        : ''
    // 合計視聴秒数(TT)
    const total_time_watched_tt =
      sns === 'tiktok' && account.insight && this.isNotNull(account.insight.total_time_watched)
        ? account.insight.total_time_watched
        : ''
    // いいね(TT)
    const likes_tt =
      sns === 'tiktok' && account.insight && this.isNotNull(account.insight.likes)
        ? account.insight.likes
        : ''
    // コメント(TT)
    const comments_tt =
      sns === 'tiktok' && account.insight && this.isNotNull(account.insight.comments)
        ? account.insight.comments
        : ''
    // シェア(TT)
    const shares_tt =
      sns === 'tiktok' && account.insight && this.isNotNull(account.insight.shares)
        ? account.insight.shares
        : ''
    // 反応数(TT)
    const reactions_tt =
      sns === 'tiktok' && account.insight && this.isNotNull(account.insight.reactions)
        ? account.insight.reactions
        : ''
    // 反応率(TT)
    const reactions_rate_tt =
      sns === 'tiktok' && account.insight && this.isNotNull(account.insight.reactions_rate)
        ? account.insight.reactions_rate
        : ''
    // エンゲージメント率(TT)
    const engagements_rate_tt =
      sns === 'tiktok' && account.insight && this.isNotNull(account.insight.engagements_rate)
        ? account.insight.engagements_rate
        : ''

    // 投稿先URL
    let posted_url = ''

    if (sns === 'facebook' && account.fb_posted_id) {
      posted_url = `https://www.facebook.com/${account.fb_posted_id}`
    }
    if (sns === 'twitter' && account.tw_posted_id) {
      posted_url = `https://www.x.com/${account.account.id}/status/${account.tw_posted_id}`
    }
    if (sns === 'instagram' && account.in_posted_id) {
      posted_url = is_story ? account.permalink : account.link_url
    }
    if (sns === 'tiktok' && account.tt_posted_id) {
      posted_url = createTikTokVideoUrl(account.account.username, account.tt_posted_id)
    }

    // 短縮URLクリック数
    const short_url_clicks =
      account.insight && this.isNotNull(account.insight.short_url_clicks)
        ? account.insight.short_url_clicks
        : ''
    // タグ
    const categories =
      post.post_categories && post.post_categories.map(category => category.name).join(',')

    return [
      action_datetime,
      created_datetime,
      modified_datetime,
      post_status,
      sns_type,
      post_type,
      account_name,
      post_message,
      image_url,
      video_url,
      like_fb,
      comment_fb,
      share_fb,
      reactions_fb,
      fans_fb,
      reach_fb,
      no_actions_fb,
      action_fb,
      no_retweet,
      quotes_tw,
      like_tw,
      reply_tw,
      reactions_tw,
      follower_tw,
      impressions_tw,
      like_in,
      comment_in,
      reactions_in,
      save_in,
      reach_in,
      no_actions_in,
      actions_in,
      taps_back_in,
      taps_forward_in,
      exits_in,
      video_views_tt,
      reach_tt,
      full_video_watched_rate_tt,
      average_time_watched_tt,
      total_time_watched_tt,
      likes_tt,
      comments_tt,
      shares_tt,
      reactions_tt,
      reactions_rate_tt,
      engagements_rate_tt,
      short_url_clicks,
      posted_url,
      categories
    ]
  }

  isNotNull(value) {
    return value !== null && value !== undefined
  }

  getPostStatus(status: string) {
    switch (status) {
      case 'draft':
        return i18n.t('下書き')
      case 'scheduled':
        return i18n.t('予約')
      case 'published':
        return i18n.t('公開')
      case 'failure':
        return i18n.t('失敗')
      case 'approval':
        return i18n.t('承認待ち')
      case 'reject':
        return i18n.t('差し戻し')
    }
  }

  getPostType(type: string) {
    switch (type) {
      case 'status':
      case 'text':
        return i18n.t('テキスト')
      case 'photo':
      case 'image':
        return i18n.t('画像')
      case 'link':
        return i18n.t('リンク')
      case 'video':
        return i18n.t('動画')
      case 'carousel':
        return i18n.t('カルーセル')
      case 'story':
        return i18n.t('ストーリーズ')
      default:
        return ''
    }
  }

  /**
   * フィルタアクティブボタン
   */
  filterActive(): void {
    this.is_active = !this.is_active
  }

  /**
   * 投稿作成に移動
   */
  changeRoutePostCreate() {
    TrackingService.sendEvent('click:投稿管理|タブ内ヘッダー|新規作成')

    this.changeRoute({
      name: 'posts/create',
      params: {}
    })
  }

  /**
   * グループ設定に移動
   */
  changeRouteGroupSetting() {
    TrackingService.sendEvent('click:投稿管理|タブ内ヘッダー|機能設定')

    this.changeRoute({
      name: 'setting_group',
      query: { screen_name: 'function', function_tab_name: 'post' }
    })
  }

  /**
   * クリックイベント
   */
  handleClick(event: any): void {
    if (!this.$el.querySelector('.date-time-picker').contains(event.target)) {
      this.closeDatePicker()
    }
  }

  /**
   * キーボードイベント
   */
  handleKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeDatePicker()
    }
  }

  /**
   * ダイアログを開く
   */
  openPostTagsManagementDialog() {
    TrackingService.sendEvent('click:投稿管理|タブ内ヘッダー|タグの管理')

    this.$refs.PostTagsManagementDialog.open()
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
