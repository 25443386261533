import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Account from '@/client/components-old/molecules/Account'
import i18n from '@/client/utils/i18n'

const instagram = namespace('instagram_comparison_analytics')
const notification = namespace('notification')

@Component({
  name: 'InstagramComparisonAccountDialog',
  components: {
    Dialog,
    Button,
    Message,
    Icon,
    Input,
    Account
  }
})
export default class InstagramComparisonAccountDialog extends Vue {
  @instagram.Action('postSnsComparisonAccounts') postSnsComparisonAccounts
  @instagram.Action('searchSnsAccount') searchSnsAccount
  @notification.Action('showNotification') showNotification

  visible = false
  is_submit = false

  url = ''
  url_cache = ''

  account: {
    id: string
    in_business_account_id: string
    img: string
    name: string
    user_name: string
    followers_count: number
  } | null = null

  get description() {
    const text = this.$options.filters.translate('フォロワー数')
    const count = this.$options.filters.number(Number(this.account.followers_count))

    return `${text}: ${count}`
  }

  get disabled() {
    return this.account === null || this.is_submit
  }

  /**
   * ダイアログの表示
   */
  open(): void {
    this.visible = true
    this.reset()
  }

  /**
   * 対象アカウントを検索
   */
  async searchAccount(): Promise<void> {
    if (this.is_submit) return

    const url = this.url.trim()

    if (url === '' || url === this.url_cache) return

    this.is_submit = true

    const result = await this.searchSnsAccount({ url })

    setTimeout(() => {
      this.is_submit = false
    }, 2000)

    this.url_cache = url

    if (result.error && result.error.type === 'INVALID_ACCOUNT' && this.visible === true) {
      return this.showNotification({
        title: '入力したアカウントはビジネスアカウントではありません。',
        type: 'error'
      })
    }

    if (result.error && result.error.type === 'EXISTS_ACCOUNT' && this.visible === true) {
      return this.showNotification({
        title: '入力したアカウントはすでに登録されています。',
        type: 'error'
      })
    }

    if (!result || (!result.data && this.visible === true)) {
      return this.showNotification({
        title: 'アカウントの検索に失敗しました。',
        type: 'error'
      })
    }

    this.account = result.data
  }

  /**
   * 競合アカウントを追加
   */
  async submit(): Promise<void> {
    const params = {
      account_id: this.account.id,
      in_business_account_id: this.account.in_business_account_id,
      account_name: this.account.name,
      user_name: this.account.user_name,
      image_url: this.account.img
    }

    const result = await this.postSnsComparisonAccounts(params)

    if (result && result.data) {
      this.showNotification({ title: '競合アカウントを追加しました。' })
    } else if (result.error && result.error.type === 'EXISTS_ACCOUNT') {
      this.showNotification({
        title: '入力したアカウントはすでに登録されています。',
        type: 'error'
      })
    } else {
      this.showNotification({ title: '競合アカウントの追加に失敗しました。', type: 'error' })
    }

    this.visible = false
  }

  /**
   * 初期化
   */
  reset(): void {
    this.is_submit = false
    this.url = ''
    this.url_cache = ''
    this.account = null
  }

  /**
   * ダイアログの非表示
   */
  close(): void {
    if (this.url !== '' || this.account) {
      const message =
        i18n.t('編集内容が保存されていません。') +
        '\n' +
        i18n.t('ダイアログを閉じてよろしいですか？')

      const is_confirm = confirm(message)

      if (!is_confirm) {
        this.visible = true
        return
      }
    }

    this.visible = false
  }
}
