
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import { getTranslateText as t } from '@/client/utils/filters'
import * as regex from '@/client/utils/regex'

import PostCreateTweetSelectForm from '../PostCreateTweetSelectForm'

type Data = {
  target_url: string
  loading: boolean
  error_message: string
}

export default defineComponent({
  name: 'PostCreateTwitterQuote',
  components: {
    PostCreateTweetSelectForm
  },
  data(): Data {
    return {
      target_url: '',
      loading: false,
      error_message: ''
    }
  },
  computed: {
    ...mapState('post_create', [
      'api_post',
      'api_twitter_quote_months',
      'api_twitter_quote_posts',
      'twitter_quote_account_id',
      'twitter_quote_target_month',
      'twitter_quote_target_tw_post_id',
      'twitter_quote_target_tweet',
      'accounts',
      'is_show_url_input_in_quote'
    ]),

    ...mapGetters('accounts', ['post']),

    accounts_options() {
      return this.post
        .filter(account => account.sns === 'twitter')
        .map(account => ({
          id: account.id,
          img: account.img,
          name: account.name,
          sns: account.sns
        }))
    }
  },
  methods: {
    ...mapActions('post_create', [
      'changeTwitterQuoteTargetMonth',
      'changeTwitterQuoteTwPostId',
      'changeTwitterQuoteAccountId',
      'fetchQuoteTweet',
      'changeIsShowUrlInputInQuote',
      'resetTwitterQuote'
    ]),

    /**
     * URLから投稿を取得する
     */
    async getTargetTweet() {
      if (this.loading) return

      const tweet_url = this.target_url.match(regex.TWITTER_STATUS_URL_FORMAT)

      if (!tweet_url || !tweet_url[0]) return

      this.target_url = tweet_url[0]

      this.error_message = ''

      this.loading = true

      const result = await this.fetchQuoteTweet(this.target_url)

      this.loading = false

      if (!result.error) return

      if (result.error && result.error.type === 'INVALID_ACCOUNT') {
        this.error_message = t('アクセストークンが失効しているため投稿を取得できません。')
        return
      }

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.error_message = t('入力したURLの投稿が存在しません。')
        return
      }

      this.error_message = t('通信に失敗しました。再度時間をおいてお試しください。')
    },

    /**
     * バツボタン押下、選択方法切り替えのときデータを初期化する
     */
    async clearInputData() {
      await this.changeIsShowUrlInputInQuote(false)

      // 初期値を公開先に指定
      await this.resetTwitterQuote(this.accounts[0].id)

      this.target_url = ''
    },

    /**
     * 入力フォームを変更する
     */
    async changeFormMode() {
      await this.changeIsShowUrlInputInQuote(!this.is_show_url_input_in_quote)

      await this.resetTwitterQuote(this.accounts[0].id)

      this.target_url = ''
    }
  }
})
