/**
 * 増減値ロケールを取得
 */
export function getNumberFluctuation(value: number): string {
  if (Math.sign(value) === 0) return '±0'

  if (Math.sign(value) === 1) return `+${value.toLocaleString()}`

  return value.toLocaleString()
}
