import { Component, Vue } from 'vue-property-decorator'

import {
  GroupSettingPostApproval,
  GroupSettingPostApprovalFlowDetailDialog,
  GroupSettingPostApprovalFlowsDialog,
  GroupSettingPostSettingOption
} from '@/client/components/organisms/GroupSetting'
import { PostTagsManagementDialog } from '@/client/components/organisms/Tags'
import Scroll from '@/client/components-old/atoms/Scroll'
import GroupSettingPostCategory from '@/client/components-old/organisms/GroupSettingPostCategory'
import GroupSettingPostContent from '@/client/components-old/organisms/GroupSettingPostContent'
import GroupSettingPostShortUrl from '@/client/components-old/organisms/GroupSettingPostShortUrl'
import GroupSettingPostTemplate from '@/client/components-old/organisms/GroupSettingPostTemplate'
import PostKeywordCreateDialog from '@/client/components-old/organisms/PostKeywordCreateDialog'
import PostTemplateDialog from '@/client/components-old/organisms/PostTemplateDialog'
import ShortUrlSettingDialog from '@/client/components-old/organisms/ShortUrlSettingDialog'

@Component({
  name: 'GroupSettingPostLayout',
  components: {
    Scroll,
    GroupSettingPostApproval,
    GroupSettingPostApprovalFlowsDialog,
    GroupSettingPostApprovalFlowDetailDialog,
    GroupSettingPostSettingOption,
    PostTagsManagementDialog,
    GroupSettingPostCategory,
    GroupSettingPostTemplate,
    GroupSettingPostShortUrl,
    GroupSettingPostContent,
    PostTemplateDialog,
    PostKeywordCreateDialog,
    ShortUrlSettingDialog
  }
})
export default class GroupSettingPostLayout extends Vue {
  $refs: {
    GroupSettingPostApprovalFlowsDialog: any
    GroupSettingPostApprovalFlowDetailDialog: any
    PostTagsManagementDialog: any
    PostTemplateDialog: any
    PostKeywordCreateDialog: any
    ShortUrlSettingDialog: any
  }

  /**
   * 承認フローダイアログを表示
   */
  openPostApprovalFlowDialog(payload: any) {
    this.$refs.GroupSettingPostApprovalFlowsDialog.open(payload)
  }

  /**
   * 承認フロー詳細テンプレートダイアログを表示
   */
  openPostApprovalFlowDetailDialog(payload: any) {
    this.$refs.GroupSettingPostApprovalFlowDetailDialog.open(payload)
  }

  /**
   * 投稿タグダイアログを表示
   */
  openPostCategoryDialog(payload: any) {
    this.$refs.PostTagsManagementDialog.open(payload)
  }

  /**
   * 投稿テンプレートダイアログを表示
   */
  openPostTemplateDialog(payload: any) {
    this.$refs.PostTemplateDialog.open(payload)
  }

  /**
   * 短縮URL設定ダイアログを表示
   */
  openShortUrlDialog() {
    this.$refs.ShortUrlSettingDialog.open()
  }

  /**
   * 投稿キーワードダイアログを表示
   */
  openPostKeywordDialog() {
    this.$refs.PostKeywordCreateDialog.open()
  }
}
