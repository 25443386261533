import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Account from '@/client/components-old/molecules/Account'

type Sns = 'facebook' | 'instagram' | 'twitter' | 'tiktok'

type TSnsMultiSelectOptions = {
  id: string
  name: string
  username?: string
  img: string
  sns: Sns
}

type TAccount = {
  id: string
  sns: Sns
}

@Component({
  name: 'SnsMultiSelect',
  components: {
    Account
  }
})
export default class SnsMultiSelect extends Vue {
  @Prop({ type: Array, required: true })
  value!: TAccount[]

  @Prop({ type: Array, required: true })
  options!: TSnsMultiSelectOptions[]

  @Prop({ type: Number, default: 4 })
  maxTotal!: number

  @Prop({ type: Number, default: 2 })
  maxFacebook!: number

  @Prop({ type: Number, default: 2 })
  maxTwitter!: number

  @Prop({ type: Number, default: 1 })
  maxInstagram!: number

  @Prop({ type: Number, default: 1 })
  maxTiktok!: number

  is_show = false

  get style() {
    if (this.$mq === 'sm') {
      return { width: '100%' }
    }

    const width = 36 * this.maxTotal + 6

    return { width: width + 'px' }
  }

  get accounts() {
    return this.options.filter(a => this.value.some(b => a.id === b.id && a.sns === b.sns))
  }

  get suggest() {
    let options = this.options.filter(a => !this.value.some(b => a.id === b.id && a.sns === b.sns))

    const facebook = this.value.filter(v => v.sns === 'facebook')
    const twitter = this.value.filter(v => v.sns === 'twitter')
    const instagram = this.value.filter(v => v.sns === 'instagram')
    const tiktok = this.value.filter(v => v.sns === 'tiktok')

    if (facebook.length >= this.maxFacebook) {
      options = options.filter(v => v.sns !== 'facebook')
    }

    if (twitter.length >= this.maxTwitter) {
      options = options.filter(v => v.sns !== 'twitter')
    }

    if (instagram.length >= this.maxInstagram) {
      options = options.filter(v => v.sns !== 'instagram')
    }

    if (tiktok.length >= this.maxTiktok) {
      options = options.filter(v => v.sns !== 'tiktok')
    }

    return options
  }

  @Emit('input')
  changeAccounts(event: TAccount[]) {
    return event
  }

  /**
   * アカウントを追加
   */
  addAccount(account: TAccount): void {
    const accounts = this.value
      .filter(v => v.id !== account.id || v.sns !== account.sns)
      .concat(account)

    if (accounts.length >= this.maxTotal) {
      this.close()
    }

    this.changeAccounts(accounts)
  }

  /**
   * アカウントを削除
   */
  removeAccount(account: TAccount): void {
    const accounts = this.value.filter(v => v.id !== account.id || v.sns !== account.sns)

    this.close()

    this.changeAccounts(accounts)
  }

  /**
   * 表示する
   */
  open(): void {
    if (this.value.length >= this.maxTotal) return

    this.is_show = true
    document.addEventListener('click', this.handleClick, false)
    document.addEventListener('touchstart', this.handleClick, false)
    document.addEventListener('keyup', this.handleKeyUp, false)
  }

  /**
   * 閉じる
   */
  close(): void {
    this.is_show = false
    document.removeEventListener('click', this.handleClick)
    document.removeEventListener('touchstart', this.handleClick)
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  /**
   * クリックイベント
   */
  handleClick(event: any): void {
    if (!this.$el.contains(event.target)) {
      this.close()
    }
  }

  /**
   * キーボードイベント
   */
  handleKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close()
    }
  }
}
