
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Checkbox'
})
export default class Checkbox extends Vue {
  @Prop({ type: Boolean, default: false })
  value!: boolean

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  get checked() {
    return this.value
  }

  set checked(val) {
    this.$emit('input', val)
  }
}
