import HyperDX from '@hyperdx/browser'
import device from 'current-device'
import moment from 'moment-timezone'

import { store } from '@/client/store'

/**
 * Crisp
 */
export class CrispService {
  /**
   * 初期化
   */
  static init(): void {
    if (process.env.NODE_ENV === 'test') return

    $crisp.push(['safe', true]) // 警告対策

    if (device.mobile()) {
      $crisp.push(['do', 'chat:hide'])
      $crisp.push([
        'on',
        'chat:closed',
        () => {
          $crisp.push(['do', 'chat:hide'])
        }
      ])
    }
  }

  /**
   * ログイン情報の送信
   */
  static sendLoginData(): void {
    if (process.env.NODE_ENV === 'test') return

    if (store.state.user.is_demo_user || store.state.user.is_super_login) return

    if (store.state.user.id) {
      $crisp.push(['set', 'user:email', store.state.user.mail])
      $crisp.push(['set', 'user:nickname', store.state.user.name])
      $crisp.push(['set', 'session:data', ['cms_user_id', store.state.user.id]])
      $crisp.push(['set', 'session:data', ['cms_user_works', store.state.user.works.join()]])
      $crisp.push(['set', 'session:segments', [['cms_user']]])

      const now = moment()

      const before_five_minutes = moment().subtract(5, 'minutes')

      // ユーザーが登録後5分以内の場合
      if (moment(store.state.user.created).isBetween(before_five_minutes, now)) {
        $crisp.push(['set', 'session:event', ['cms:signup']])
      } else {
        $crisp.push(['set', 'session:event', ['cms:login']])
      }
    }

    if (store.state.project.id) {
      $crisp.push(['set', 'session:data', ['cms_recent_group_id', store.state.project.id]])
      $crisp.push([
        'set',
        'session:data',
        ['cms_recent_group_name', store.state.project.project_name]
      ])
    }

    $crisp.push(['set', 'session:event', ['cms:pageview']])

    // ? 開発環境の動作確認時のみコメントアウト
    if (process.env.NODE_ENV !== 'production') return

    // ? CrispにHyperDXのセッションURLを関連付ける
    $crisp.push(['set', 'session:data', [[['SessionURL', HyperDX.getSessionUrl()]]]])
  }

  /**
   * メッセージを送信
   */
  static sendMessage(message: string): void {
    if (process.env.NODE_ENV === 'test') return

    this.showSupportChat()

    $crisp.push(['do', 'message:send', ['text', message]])
  }

  /**
   * サポートチャットの表示
   */
  static showSupportChat(): void {
    if (process.env.NODE_ENV === 'test') return

    if (device.mobile()) {
      $crisp.push(['do', 'chat:show'])
    }

    $crisp.push(['do', 'chat:toggle'])
  }
}
