import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import OperatorRoleSns from '@/client/components-old/molecules/OperatorRoleSns'
import { IState as IStatePersonalSetting } from '@/client/store/modules/personal_setting'

const personal_setting = namespace('personal_setting')

@Component({
  name: 'PersonalSettingRoleSnsAccount',
  components: {
    Icon,
    Message,
    Panel,
    OperatorRoleSns
  }
})
export default class PersonalSettingRoleSnsAccount extends Vue {
  @personal_setting.State('api_role') api_role: IStatePersonalSetting['api_role']

  get accounts() {
    if (!this.api_role) {
      return []
    }

    const fb_accounts = this.api_role.fb_accounts
      .filter(
        account =>
          (account.role_analytics && account.role_analytics !== 'no_authority') ||
          (account.role_monitoring && account.role_monitoring !== 'no_authority') ||
          (account.role_post && account.role_post !== 'no_authority')
      )
      .map(account => ({ sns: 'facebook', account }))

    const tw_accounts = this.api_role.tw_accounts
      .filter(
        account =>
          (account.role_analytics && account.role_analytics !== 'no_authority') ||
          (account.role_monitoring && account.role_monitoring !== 'no_authority') ||
          (account.role_post && account.role_post !== 'no_authority')
      )
      .map(account => ({ sns: 'twitter', account }))

    const in_accounts = this.api_role.in_accounts
      .filter(
        account =>
          (account.role_analytics && account.role_analytics !== 'no_authority') ||
          (account.role_monitoring && account.role_monitoring !== 'no_authority') ||
          (account.role_post && account.role_post !== 'no_authority')
      )
      .map(account => ({ sns: 'instagram', account }))

    const tt_accounts = this.api_role.tt_accounts
      .filter(
        account =>
          (account.role_analytics && account.role_analytics !== 'no_authority') ||
          (account.role_post && account.role_post !== 'no_authority')
      )
      .map(account => ({ sns: 'tiktok', account }))

    return [...fb_accounts, ...tw_accounts, ...in_accounts, ...tt_accounts]
  }

  get is_account() {
    return this.accounts.length > 0
  }
}
