import { GridItem, GridLayout } from 'vue-grid-layout'
import { Component, Vue } from 'vue-property-decorator'

import * as constants from '@/client/components-old/_constants/twitter_analytics'
import Box from '@/client/components-old/atoms/Box'
import Legend from '@/client/components-old/atoms/Legend'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import TwitterAnalyticsChartDonutHalf from '@/client/components-old/organisms/TwitterAnalyticsChartDonutHalf'
import TwitterAnalyticsChartVertical from '@/client/components-old/organisms/TwitterAnalyticsChartVertical'
import TwitterAnalyticsTotal from '@/client/components-old/organisms/TwitterAnalyticsTotal'

@Component({
  name: 'TwitterAnalyticsAction',
  components: {
    GridLayout,
    GridItem,
    TwitterAnalyticsChartDonutHalf,
    TwitterAnalyticsChartVertical,
    TwitterAnalyticsTotal,
    Legend,
    Box,
    Message,
    Scroll
  }
})
export default class TwitterAnalyticsAction extends Vue {
  get legend_data() {
    return constants.DATA_TYPES.map(type => ({ text: type.text, color: type.color }))
  }

  get grid_layout() {
    return [
      {
        x: 0,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:エンゲージメント数',
        name: 'TwitterAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'engagements',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 3,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:エンゲージメント率',
        name: 'TwitterAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'engagements_rate',
          unit: 'average',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 6,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:反応数',
        name: 'TwitterAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'reactions',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 9,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:反応率',
        name: 'TwitterAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'reactions_rate',
          unit: 'average',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 2,
        w: 12,
        h: 4,
        i: '垂直チャート:エンゲージメント数',
        name: 'TwitterAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'engagements',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 6,
        w: 12,
        h: 4,
        i: '垂直チャート:エンゲージメント率',
        name: 'TwitterAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'engagements_rate',
          interval: '1_day',
          unit: 'average',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 10,
        w: 12,
        h: 4,
        i: '垂直チャート:反応数',
        name: 'TwitterAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'reactions',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 14,
        w: 12,
        h: 4,
        i: '垂直チャート:反応率',
        name: 'TwitterAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'reactions_rate',
          interval: '1_day',
          unit: 'average',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 18,
        w: 6,
        h: 4,
        i: '半円チャート:エンゲージメント数の内訳',
        name: 'TwitterAnalyticsChartDonutHalf',
        options: {
          data_type: 'post_data',
          metric: 'engagements'
        }
      },
      {
        x: 6,
        y: 18,
        w: 6,
        h: 4,
        i: '半円チャート:反応数の内訳',
        name: 'TwitterAnalyticsChartDonutHalf',
        options: {
          data_type: 'post_data',
          metric: 'reactions'
        }
      },
      {
        x: 0,
        y: 22,
        w: 6,
        h: 4,
        i: '半円チャート:その他',
        name: 'TwitterAnalyticsChartDonutHalf',
        options: {
          data_type: 'post_data',
          metric: 'other_actions'
        }
      }
    ]
  }
}
