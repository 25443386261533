import API from '@/client/utils/api'
import { TikTokType } from '@/common/tiktok'

interface AudienceGender {
  gender: string
  percentage: number
  count: number
}

interface ImpressionSource {
  impression_source: string
  percentage: number
  count: number
}

interface AccountInsight {
  account_id: string
  created_time: number
  followers_count: number
  followers_count_up_down: number
  video_views: number
  comments: number
  shares: number
  likes: number
  reactions: number
  engagements: number
  profile_views: number
  audience_countries: {
    country: string
    percentage: number
    count: number
  }[]
  audience_genders: AudienceGender[]
  audience_activity: TikTokType.AudienceActivity[]
}

interface PostData {
  account_id: string
  post_id: string
  created_time: number
  message: string
  thumbnail_url: string
  share_url: string
  embed_url: string
  type: string
  likes: number
  comments: number
  shares: number
  reactions: number
  reactions_rate: number
  engagements: number
  engagements_rate: number
  video_views: number
  impressions: number
  impressions_unique: number
  video_duration: number
  full_video_watched_rate: number
  total_time_watched: number
  average_time_watched: number
  audience_countries: {
    country: string
    percentage: number
    count: number
  }[]
  impression_sources: ImpressionSource[]
  followers_at_that_time: number
  tags: string[]
}

interface Body {
  target_month: string // ? YYYY-MM 形式,
  account_name: string
  daily_data: AccountInsight[]
  post_data: PostData[]
  categories: number[]
}

export async function postReportTikTok(body: Body) {
  return await API.post<Blob>('reports/tiktok', body, { responseType: 'blob' })
}
