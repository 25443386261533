import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Textarea from '@/client/components-old/atoms/Textarea'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

@Component({
  name: 'PostKeywordCreateDialog',
  components: {
    Button,
    Dialog,
    Icon,
    Textarea
  }
})
export default class PostKeywordCreateDialog extends Vue {
  @group_setting.Action('createPostKeywords') createPostKeywords
  @notification.Action('showNotification') showNotification

  visible = false
  loading = false
  keyword = ''

  get disabled() {
    return !this.keyword.trim() || this.loading
  }

  /**
   * ダイアログの表示
   */
  open(): void {
    this.visible = true
    this.loading = false
    this.keyword = ''
  }

  /**
   * ダイアログの非表示
   */
  close(): void {
    this.visible = false
  }

  /**
   * キーワードの追加
   */
  async saveKeyword(): Promise<void> {
    const keywords = this.keyword
      .trim()
      .split('\n')
      .filter((v, i, array) => v && array.findIndex(key => key === v) === i)

    if (!keywords.length) return

    this.loading = true

    const result = await this.createPostKeywords({ keywords })

    this.loading = false

    if (result.data) {
      this.visible = false

      return this.showNotification({ title: '設定を保存しました。' })
    }

    this.showNotification({ type: 'error', title: '設定の保存に失敗しました。' })
  }
}
