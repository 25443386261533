import { getCurrentInstance } from 'vue'
import { Store } from 'vuex'

import { IRootState } from '@/client/store/global'

export const useStore = () => {
  const instance = getCurrentInstance()

  if (!instance) {
    console.error('You must use this function within the "setup()" method')
  }

  return instance?.proxy.$store as Store<IRootState>
}
