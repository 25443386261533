import moment from 'moment-timezone'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { MIN_DATE } from '@/client/components-old/_constants/global'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import { SnsType } from '@/common/types'

import {
  AccountInterval,
  AccountMetricNames,
  AccountNotation,
  BasicInsightOrder,
  BasicInsights,
  BasicInsightUnit,
  IGetSnsAnalyticsAccountTableSettingsParams,
  IGetSnsAnalyticsAccountTableSettingsResponse,
  IGetSnsAnalyticsBasicInsightsParams,
  IGetSnsAnalyticsBasicInsightsResponse,
  IGetSnsAnalyticsPostInsightsParams,
  IGetSnsAnalyticsPostInsightsResponse,
  IGetSnsAnalyticsPostTableSettingsParams,
  IGetSnsAnalyticsPostTableSettingsResponse,
  IGetSnsAnalyticsTransitionInsightsParams,
  IGetSnsAnalyticsTransitionInsightsResponse,
  IPostSnsAnalyticsAccountTableSettingsParams,
  IPostSnsAnalyticsAccountTableSettingsResponse,
  IPostSnsAnalyticsPostTableSettingsParams,
  IPostSnsAnalyticsPostTableSettingsResponse,
  PostDisplayNames,
  PostInsight,
  PostMetricNames,
  PostTableMetricBy,
  TransitionInsightOrder,
  TransitionInsights
} from '../api'
import * as m from './sns_analytics.mutations'

type FilterSnsType = 'all' | SnsType
type FilterPostType = 'text' | 'image' | 'video' | 'carousel' | 'other'
export type FilterPostOptions = {
  post_tags: number[]
  post_types: FilterPostType[]
  post_keyword: string
}
type DisplayTab = 'basic' | 'transition' | 'post'
type DisplayIntervalNotation = `${AccountInterval}_${AccountNotation}`
type PostListOrderBy = 'ASC' | 'DESC'
type PostDisplayView = 'list' | 'table'

export interface IState {
  api_basic_insights: BasicInsights
  api_transition_insights: TransitionInsights
  api_post_insights: PostInsight[]
  api_account_table_settings: AccountMetricNames[]
  api_post_table_settings: PostMetricNames[]
  target_month: string
  filter_sns_type: FilterSnsType
  filter_category_ids: number[]
  filter_post_types: FilterPostType[]
  filter_post_tags: number[]
  filter_post_keyword: string
  is_display_all_project: boolean
  basic_display_order: BasicInsightOrder
  basic_display_unit: BasicInsightUnit
  transition_display_order: TransitionInsightOrder
  transition_display_metric: AccountMetricNames
  post_table_metric_by: PostTableMetricBy
  post_list_metric_by: PostDisplayNames
  post_list_order_by: PostListOrderBy
  post_display_view: PostDisplayView
  display_tab: DisplayTab
  display_interval_notation: DisplayIntervalNotation
  is_loading: boolean
  is_basic_loading: boolean
  is_transition_loading: boolean
  is_post_loading: boolean
}

export interface IGetter {
  post_insights: PostInsight[]
  post_table_insights: PostInsight[]
  post_list_insights: PostInsight[]
  month_picker_since: string
  month_picker_until: string
}

const target_month = moment().startOf('month')

if (moment().date() === 1) {
  target_month.subtract(1, 'month')
}

const state: IState = {
  api_basic_insights: { insights: [], totals: {} },
  api_transition_insights: { insights: [], totals: [] },
  api_post_insights: [],
  api_account_table_settings: [],
  api_post_table_settings: [],
  post_display_view: 'table',
  target_month: target_month.format('YYYY-MM'),
  filter_sns_type: 'all',
  filter_category_ids: [],
  filter_post_types: [],
  filter_post_tags: [],
  filter_post_keyword: '',
  is_display_all_project: false,
  basic_display_order: 'name',
  basic_display_unit: 'total',
  transition_display_order: 'name',
  transition_display_metric: 'followers',
  post_table_metric_by: '-release_at',
  post_list_metric_by: 'release_at',
  post_list_order_by: 'DESC',
  display_tab: 'basic',
  display_interval_notation: 'mom_rate',
  is_loading: false,
  is_basic_loading: false,
  is_transition_loading: false,
  is_post_loading: false
}

const getters: GetterTree<IState, IRootState> = {
  post_insights(state): IGetter['post_insights'] {
    const posts = state.api_post_insights.filter(post => {
      const account_tags = post.account.categories.map(v => v.id)
      const post_tags = post.categories.map(v => v.id)

      if (state.filter_sns_type !== 'all' && state.filter_sns_type !== post.sns_type) return false

      if (
        state.filter_category_ids.length &&
        !state.filter_category_ids.some(v => account_tags.includes(v))
      )
        return false

      if (
        state.filter_post_types.length &&
        !state.filter_post_types.some(v => v === post.post_type)
      )
        return false

      if (state.filter_post_tags.length && !state.filter_post_tags.some(v => post_tags.includes(v)))
        return false

      if (state.filter_post_keyword && !post.post_message.includes(state.filter_post_keyword))
        return false

      return true
    })

    return posts
  },
  post_table_insights(state, getters): IGetter['post_table_insights'] {
    const post_insights: IGetter['post_insights'] = [...getters.post_insights]

    post_insights.sort((a, b) => {
      const sort = state.post_table_metric_by.replace('-', '')
      const is_desc = /^-/.test(state.post_table_metric_by)

      if (sort === 'release_at') {
        return is_desc ? b[sort].localeCompare(a[sort]) : a[sort].localeCompare(b[sort])
      } else if (sort === 'name') {
        return is_desc
          ? b.account[sort].localeCompare(a.account[sort])
          : a.account[sort].localeCompare(b.account[sort])
      } else {
        return is_desc
          ? b.analytics[sort] - a.analytics[sort]
          : a.analytics[sort] - b.analytics[sort]
      }
    })

    return post_insights
  },
  post_list_insights(state, getters): IGetter['post_list_insights'] {
    const post_insights: IGetter['post_insights'] = [...getters.post_insights]

    post_insights.sort((a, b) => {
      const sort = state.post_list_metric_by
      const is_desc = state.post_list_order_by === 'DESC'

      if (sort === 'release_at') {
        return is_desc ? b[sort].localeCompare(a[sort]) : a[sort].localeCompare(b[sort])
      } else if (sort === 'name') {
        return is_desc
          ? b.account[sort].localeCompare(a.account[sort])
          : a.account[sort].localeCompare(b.account[sort])
      } else {
        return is_desc
          ? b.analytics[sort] - a.analytics[sort]
          : a.analytics[sort] - b.analytics[sort]
      }
    })

    return post_insights
  },
  month_picker_since(): IGetter['month_picker_since'] {
    const since = moment(MIN_DATE).startOf('month')

    return since.format('YYYY-MM')
  },
  month_picker_until(): IGetter['month_picker_until'] {
    const until = moment().startOf('month')

    if (moment().date() === 1) {
      until.subtract(1, 'month')
    }

    return until.format('YYYY-MM')
  }
}

const mutations: MutationTree<IState> = {
  [m.SET_API_BASIC_INSIGHTS](state, payload: BasicInsights) {
    state.api_basic_insights = payload
  },
  [m.SET_API_TRANSITION_INSIGHTS](state, payload: TransitionInsights) {
    state.api_transition_insights = payload
  },
  [m.SET_API_POST_INSIGHTS](state, payload: PostInsight[]) {
    state.api_post_insights = payload
  },
  [m.SET_API_ACCOUNT_TABLE_SETTINGS](state, payload: AccountMetricNames[]) {
    state.api_account_table_settings = payload
  },
  [m.SET_API_POST_TABLE_SETTINGS](state, payload: PostMetricNames[]) {
    state.api_post_table_settings = payload
  },
  [m.SET_TARGET_MONTH](state, payload: string) {
    state.target_month = payload
  },
  [m.SET_FILTER_SNS_TYPE](state, payload: FilterSnsType) {
    state.filter_sns_type = payload
  },
  [m.SET_FILTER_CATEGORY_IDS](state, payload: number[]) {
    state.filter_category_ids = payload
  },
  [m.SET_FILTER_POST_TYPES](state, payload: FilterPostType[]) {
    state.filter_post_types = payload
  },
  [m.SET_FILTER_POST_TAGS](state, payload: number[]) {
    state.filter_post_tags = payload
  },
  [m.SET_FILTER_POST_KEYWORD](state, payload: string) {
    state.filter_post_keyword = payload
  },
  [m.SET_IS_DISPLAY_ALL_PROJECT](state, payload: boolean) {
    state.is_display_all_project = payload
  },
  [m.SET_BASIC_DISPLAY_ORDER](state, payload: BasicInsightOrder) {
    state.basic_display_order = payload
  },
  [m.SET_BASIC_DISPLAY_UNIT](state, payload: BasicInsightUnit) {
    state.basic_display_unit = payload
  },
  [m.SET_TRANSITION_DISPLAY_ORDER](state, payload: TransitionInsightOrder) {
    state.transition_display_order = payload
  },
  [m.SET_TRANSITION_DISPLAY_METRIC](state, payload: AccountMetricNames) {
    state.transition_display_metric = payload
  },
  [m.SET_POST_TABLE_METRIC_BY](state, payload: PostTableMetricBy) {
    state.post_table_metric_by = payload
  },
  [m.SET_POST_LIST_METRIC_BY](state, payload: PostDisplayNames) {
    state.post_list_metric_by = payload
  },
  [m.SET_DISPLAY_TAB](state, payload: DisplayTab) {
    state.display_tab = payload
  },
  [m.SET_POST_DISPLAY_VIEW](state, payload: PostDisplayView) {
    state.post_display_view = payload
  },
  [m.SET_POST_LIST_ORDER_BY](state, payload: PostListOrderBy) {
    state.post_list_order_by = payload
  },
  [m.SET_DISPLAY_INTERVAL_NOTATION](state, payload: DisplayIntervalNotation) {
    state.display_interval_notation = payload
  },
  [m.SET_IS_LOADING](state, payload: boolean) {
    state.is_loading = payload
  },
  [m.SET_IS_BASIC_LOADING](state, payload: boolean) {
    state.is_basic_loading = payload
  },
  [m.SET_IS_TRANSITION_LOADING](state, payload: boolean) {
    state.is_transition_loading = payload
  },
  [m.SET_IS_POST_LOADING](state, payload: boolean) {
    state.is_post_loading = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * ページ表示時の処理
   */
  async createdSnsAnalyticsPage(context) {
    context.commit(m.SET_IS_LOADING, true)

    await Promise.all([
      context.dispatch('fetchAccountTableSettings'),
      context.dispatch('fetchPostTableSettings')
    ])

    await context.dispatch('reloadSnsAnalytics')

    context.commit(m.SET_IS_LOADING, false)
  },

  /**
   * ページ離脱時の処理
   */
  destroyedSnsAnalyticsPage(context) {
    context.commit(m.SET_API_BASIC_INSIGHTS, { insights: [], totals: {} })
    context.commit(m.SET_API_TRANSITION_INSIGHTS, { insights: [], totals: [] })
    context.commit(m.SET_API_POST_INSIGHTS, [])
    context.commit(m.SET_API_ACCOUNT_TABLE_SETTINGS, [])
    context.commit(m.SET_API_POST_TABLE_SETTINGS, [])
  },

  /**
   * グループ変更時の処理
   */
  async changeGroupSnsAnalyticsPage(context) {
    await context.dispatch('destroyedSnsAnalyticsPage')

    context.commit(m.SET_FILTER_SNS_TYPE, 'all')
    context.commit(m.SET_FILTER_CATEGORY_IDS, [])
    context.commit(m.SET_FILTER_POST_TYPES, [])
    context.commit(m.SET_FILTER_POST_TAGS, [])
    context.commit(m.SET_FILTER_POST_KEYWORD, '')

    await context.dispatch('createdSnsAnalyticsPage')
  },

  /**
   * 再読み込み時の処理
   */
  reloadSnsAnalytics(context, payload = false) {
    switch (context.state.display_tab) {
      case 'basic':
        return context.dispatch('fetchBasicInsights')
      case 'transition':
        return context.dispatch('fetchTransitionInsights')
      case 'post':
        if (payload) return // 全データ取得な為、ケースによって再読み込みしない
        return context.dispatch('fetchPostInsights')
    }
  },

  /**
   * 基本データの取得
   */
  async fetchBasicInsights(context) {
    const params: IGetSnsAnalyticsBasicInsightsParams = {
      target_month: context.state.target_month,
      order: context.state.basic_display_order,
      unit: context.state.basic_display_unit,
      interval: context.state.display_interval_notation.split('_').shift() as AccountInterval,
      notation: context.state.display_interval_notation.split('_').pop() as AccountNotation
    }

    if (context.state.filter_category_ids.length) {
      params.category_ids = context.state.filter_category_ids.join(',')
    }

    if (!context.state.is_display_all_project) {
      params.project_id = context.rootState.project.id
    }

    if (context.state.filter_sns_type !== 'all') {
      params.sns_type = context.state.filter_sns_type
    }

    context.commit(m.SET_IS_BASIC_LOADING, true)

    const response = await API.get<IGetSnsAnalyticsBasicInsightsResponse>(
      'sns_analytics/basic_insights',
      { params }
    )

    context.commit(m.SET_IS_BASIC_LOADING, false)

    if (response.data) {
      context.commit(m.SET_API_BASIC_INSIGHTS, response.data)
    }

    return response
  },

  /**
   * 毎月の推移データの取得
   */
  async fetchTransitionInsights(context) {
    const params: IGetSnsAnalyticsTransitionInsightsParams = {
      target_month: context.state.target_month,
      metric: context.state.transition_display_metric,
      order: context.state.transition_display_order,
      interval: context.state.display_interval_notation.split('_').shift() as AccountInterval,
      notation: context.state.display_interval_notation.split('_').pop() as AccountNotation,
      category_ids: context.state.filter_category_ids.join(',')
    }

    if (!context.state.is_display_all_project) {
      params.project_id = context.rootState.project.id
    }

    if (context.state.filter_sns_type !== 'all') {
      params.sns_type = context.state.filter_sns_type
    }

    context.commit(m.SET_IS_TRANSITION_LOADING, true)

    const response = await API.get<IGetSnsAnalyticsTransitionInsightsResponse>(
      'sns_analytics/transition_insights',
      { params }
    )

    context.commit(m.SET_IS_TRANSITION_LOADING, false)

    if (response.data) {
      context.commit(m.SET_API_TRANSITION_INSIGHTS, response.data)
    }

    return response
  },

  /**
   * 投稿データの取得
   */
  async fetchPostInsights(context) {
    const params: IGetSnsAnalyticsPostInsightsParams = {
      target_month: context.state.target_month
    }

    if (!context.state.is_display_all_project) {
      params.project_id = context.rootState.project.id
    }

    context.commit(m.SET_IS_POST_LOADING, true)

    const response = await API.get<IGetSnsAnalyticsPostInsightsResponse>(
      'sns_analytics/post_insights',
      { params }
    )

    context.commit(m.SET_IS_POST_LOADING, false)

    if (response.data?.data) {
      context.commit(m.SET_API_POST_INSIGHTS, response.data.data)
    }

    return response
  },

  /**
   * 日次テーブル設定の取得
   */
  async fetchAccountTableSettings(context) {
    const params: IGetSnsAnalyticsAccountTableSettingsParams = {
      project_id: context.rootState.project.id
    }

    context.commit(m.SET_IS_LOADING, true)

    const response = await API.get<IGetSnsAnalyticsAccountTableSettingsResponse>(
      'sns_analytics/account_table_settings',
      { params }
    )

    context.commit(m.SET_IS_LOADING, false)

    if (response.data?.data) {
      context.commit(m.SET_API_ACCOUNT_TABLE_SETTINGS, response.data.data)
    }

    return response
  },

  /**
   * 日次テーブル設定の更新
   */
  async updateAccountTableSettings(context, payload: AccountMetricNames[]) {
    const params: IPostSnsAnalyticsAccountTableSettingsParams = {
      project_id: context.rootState.project.id,
      metric_names: payload
    }

    const response = await API.post<IPostSnsAnalyticsAccountTableSettingsResponse>(
      'sns_analytics/account_table_settings',
      params
    )

    if (response.data?.data) {
      await context.dispatch('fetchAccountTableSettings')
    }

    return response
  },

  /**
   * 投稿テーブル設定の取得
   */
  async fetchPostTableSettings(context) {
    const params: IGetSnsAnalyticsPostTableSettingsParams = {
      project_id: context.rootState.project.id
    }

    context.commit(m.SET_IS_LOADING, true)

    const response = await API.get<IGetSnsAnalyticsPostTableSettingsResponse>(
      'sns_analytics/post_table_settings',
      { params }
    )

    context.commit(m.SET_IS_LOADING, false)

    if (response.data?.data) {
      context.commit(m.SET_API_POST_TABLE_SETTINGS, response.data.data)
    }

    return response
  },

  /**
   * 投稿テーブル設定の更新
   */
  async updatePostTableSettings(context, payload: PostMetricNames[]) {
    const params: IPostSnsAnalyticsPostTableSettingsParams = {
      project_id: context.rootState.project.id,
      metric_names: payload
    }

    const response = await API.post<IPostSnsAnalyticsPostTableSettingsResponse>(
      'sns_analytics/post_table_settings',
      params
    )

    if (response.data?.data) {
      await context.dispatch('fetchPostTableSettings')
    }

    return response
  },

  /**
   * 対象月の変更
   */
  async changeTargetMonth(context, payload: string) {
    context.commit(m.SET_TARGET_MONTH, payload)

    await context.dispatch('reloadSnsAnalytics')

    TrackingService.sendEvent('click:一括分析|画面内ヘッダー|期間指定')
  },

  /**
   * 投稿のフィルタ条件を変更
   */
  async changeFilterPostOptions(context, payload: FilterPostOptions) {
    context.commit(m.SET_FILTER_POST_TYPES, payload.post_types)
    context.commit(m.SET_FILTER_POST_TAGS, payload.post_tags)
    context.commit(m.SET_FILTER_POST_KEYWORD, payload.post_keyword)
  },

  /**
   * SNSタイプの検索条件を変更
   */
  async changeSnsType(context, payload: FilterSnsType) {
    context.commit(m.SET_FILTER_SNS_TYPE, payload)

    await context.dispatch('reloadSnsAnalytics', true)

    TrackingService.sendEvent(`click:一括分析|画面内ヘッダー|SNS選択:${payload}`)
  },

  /**
   * アカウントタグの検索条件を変更
   */
  async changeCategoryIds(context, payload: number[]) {
    context.commit(m.SET_FILTER_CATEGORY_IDS, payload)

    await context.dispatch('reloadSnsAnalytics', true)

    TrackingService.sendEvent('click:一括分析|画面内ヘッダー|アカウントタグ')
  },

  /**
   * 全グループ表示フラグを変更
   */
  async changeIsDisplayAllProject(context, payload: boolean) {
    context.commit(m.SET_IS_DISPLAY_ALL_PROJECT, payload)

    await context.dispatch('reloadSnsAnalytics')

    TrackingService.sendEvent('switch:一括分析|画面内ヘッダー|全グループ表示')
  },

  /**
   * 基本データの並び替え条件を変更
   */
  async changeBasicDisplayOrder(context, payload: BasicInsightOrder) {
    context.commit(m.SET_BASIC_DISPLAY_ORDER, payload)

    await context.dispatch('fetchBasicInsights')
  },

  /**
   * 基本データの表示する単位を変更
   */
  async changeBasicDisplayUnit(context, payload: BasicInsightUnit) {
    context.commit(m.SET_BASIC_DISPLAY_UNIT, payload)

    await context.dispatch('fetchBasicInsights')

    if (payload === 'total') {
      TrackingService.sendEvent('click:一括分析|基本データ|表示値:合計')
    }

    if (payload === 'average') {
      TrackingService.sendEvent('click:一括分析|基本データ|表示値:平均')
    }
  },

  /**
   * 推移データの並び替え条件を変更
   */
  async changeTransitionDisplayOrder(context, payload: TransitionInsightOrder) {
    context.commit(m.SET_TRANSITION_DISPLAY_ORDER, payload)

    await context.dispatch('fetchTransitionInsights')
  },

  /**
   * 推移データの表示する指標を変更
   */
  async changeTransitionDisplayMetric(context, payload: AccountMetricNames) {
    context.commit(m.SET_TRANSITION_DISPLAY_METRIC, payload)

    await context.dispatch('fetchTransitionInsights')

    TrackingService.sendEvent(`select:一括分析|月ごとの推移|${payload}`)
  },

  /**
   * 投稿の並び替え条件を変更
   */
  async changePostTableMetricBy(context, payload: AccountMetricNames) {
    context.commit(m.SET_POST_TABLE_METRIC_BY, payload)
  },

  /**
   * 投稿の並び替え条件を変更
   */
  async changePostListMetricBy(context, payload: AccountMetricNames) {
    context.commit(m.SET_POST_LIST_METRIC_BY, payload)
  },

  /**
   * テーブル表示タブの変更
   */
  async changeDisplayTab(context, payload: DisplayTab) {
    context.commit(m.SET_DISPLAY_TAB, payload)

    await context.dispatch('reloadSnsAnalytics')

    if (payload === 'basic') {
      TrackingService.sendEvent('click:一括分析|タブ:基本データ')
    }

    if (payload === 'transition') {
      TrackingService.sendEvent('click:一括分析|タブ:月ごとの推移')
    }

    if (payload === 'post') {
      TrackingService.sendEvent('click:一括分析|タブ:投稿')
    }
  },

  /**
   * テーブル表示タブの変更
   */
  async changeDisplayView(context, payload: PostDisplayView) {
    context.commit(m.SET_DISPLAY_TAB, payload)

    if (payload === 'table') {
      TrackingService.sendEvent('click:一括分析|タブ:テーブル')
    }

    if (payload === 'list') {
      TrackingService.sendEvent('click:一括分析|タブ:リスト')
    }
  },

  /**
   * 表示する集計間隔を変更
   */
  async changeDisplayIntervalNotation(context, payload: DisplayIntervalNotation) {
    context.commit(m.SET_DISPLAY_INTERVAL_NOTATION, payload)

    await context.dispatch('reloadSnsAnalytics', true)

    if (context.state.display_tab === 'basic' && payload === 'mom_rate') {
      TrackingService.sendEvent('select:一括分析|基本データ|比較内容選択:前月比(率)')
    }
    if (context.state.display_tab === 'basic' && payload === 'mom_value') {
      TrackingService.sendEvent('select:一括分析|基本データ|比較内容選択:前月比(値)')
    }
    if (context.state.display_tab === 'basic' && payload === 'yoy_rate') {
      TrackingService.sendEvent('select:一括分析|基本データ|比較内容選択:昨年同月比(率)')
    }
    if (context.state.display_tab === 'basic' && payload === 'yoy_value') {
      TrackingService.sendEvent('select:一括分析|基本データ|比較内容選択:昨年同月比(値)')
    }
    if (context.state.display_tab === 'transition' && payload === 'mom_rate') {
      TrackingService.sendEvent('select:一括分析|月ごとの推移|比較内容選択:前月比(率)')
    }
    if (context.state.display_tab === 'transition' && payload === 'mom_value') {
      TrackingService.sendEvent('select:一括分析|月ごとの推移|比較内容選択:前月比(値)')
    }
    if (context.state.display_tab === 'transition' && payload === 'yoy_rate') {
      TrackingService.sendEvent('select:一括分析|月ごとの推移|比較内容選択:昨年同月比(率)')
    }
    if (context.state.display_tab === 'transition' && payload === 'yoy_value') {
      TrackingService.sendEvent('select:一括分析|月ごとの推移|比較内容選択:昨年同月比(値)')
    }
  },

  /**
   * 投稿の表示形式を変更
   */
  async changePostDisplayView(context, payload: PostDisplayView) {
    context.commit(m.SET_POST_DISPLAY_VIEW, payload)
  },

  /**
   * 投稿のリスト表示順序を変更
   */
  async changePostListOrderBy(context, payload: PostListOrderBy) {
    context.commit(m.SET_POST_LIST_ORDER_BY, payload)
  }
}

export const sns_analytics = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
