import i18n from '@/client/utils/i18n'

/**
 * センチメントテキストを取得
 */
export function getSentimentText(sentiment: string): string {
  switch (sentiment) {
    case 'positive':
      return i18n.t('ポジティブ')
    case 'negative':
      return i18n.t('ネガティブ')
    case 'neutral':
      return i18n.t('ニュートラル')
    default:
      return ''
  }
}
