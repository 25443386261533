
import { defineComponent, ref } from 'vue'
import { mapActions, mapState } from 'vuex'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import {
  IPostSnsAnalyticsSnsAccountCategoriesSettingsParams,
  IPostSnsAnalyticsSnsAccountCategoriesSettingsResponse
} from '@/client/features/sns_analytics/api'
import { TrackingService } from '@/client/services'
import API from '@/client/utils/api'
import { getTranslateText as t } from '@/client/utils/filters'

type SnsType = 'facebook' | 'twitter' | 'instagram'

type Options = {
  account_id: number
  project_id: number
  sns_type: SnsType | ''
  category_ids: number[]
}

export default defineComponent({
  name: 'AccountTagsSettingDialog',
  components: {
    Button,
    Dialog,
    Icon,
    SelectMulti
  },
  setup() {
    const account_id = ref(0)
    const project_id = ref(0)
    const sns_type = ref<SnsType | ''>('')
    const visible = ref(false)
    const is_submit = ref(false)
    const category_ids = ref([])

    return {
      account_id,
      project_id,
      sns_type,
      visible,
      is_submit,
      category_ids,
      t
    }
  },
  computed: {
    ...mapState('sns_account_categories', ['api_sns_account_categories']),

    category_options() {
      return this.api_sns_account_categories.map(v => ({ value: v.id, text: v.name }))
    }
  },
  methods: {
    ...mapActions('notification', ['showNotification']),

    /**
     * ダイアログの表示
     */
    open(options: Options): void {
      this.account_id = options.account_id
      this.project_id = options.project_id
      this.sns_type = options.sns_type
      this.category_ids = options.category_ids
      this.visible = true
    },

    /**
     * ダイアログを閉じる
     */
    close() {
      TrackingService.sendEvent('click:アカウントタグの設定|キャンセル')

      this.account_id = 0
      this.project_id = 0
      this.sns_type = ''
      this.category_ids = []
      this.visible = false
    },

    /**
     * タグを保存する
     */
    async saveCategories() {
      if (this.is_submit) {
        return
      }

      TrackingService.sendEvent('click:アカウントタグの設定|保存')

      const params: IPostSnsAnalyticsSnsAccountCategoriesSettingsParams = {
        account_id: this.account_id,
        project_id: this.project_id,
        sns_type: this.sns_type,
        category_ids: this.category_ids
      }

      this.is_submit = true

      const response = await API.post<IPostSnsAnalyticsSnsAccountCategoriesSettingsResponse>(
        'sns_account_categories/settings',
        params
      )

      this.is_submit = false

      if (response.data.data) {
        this.showNotification({ title: 'タグ設定を保存しました。' })
        this.$emit('update')
        this.close()

        return
      }

      if (response.data.error && response.data.error.type === 'MAX_CATEGORY_OVER') {
        this.showNotification({
          type: 'error',
          title: 'タグを10個以下に設定してください。'
        })

        return
      }

      this.showNotification({
        type: 'error',
        title: 'タグ設定の保存に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。'
      })
    }
  }
})
