import { Component, Prop, Vue } from 'vue-property-decorator'

import Account from '@/client/components-old/molecules/Account'

@Component({
  name: 'UserMultiSelect',
  components: {
    Account
  }
})
export default class UserMultiSelect extends Vue {
  @Prop({ type: Array, required: true })
  value: number[]

  @Prop({ type: Array, default: [] })
  options: {
    id: number
    name: string
    picture_url: string
  }[]

  @Prop({ type: String, default: '' })
  placeholder: string

  @Prop({ type: Boolean, default: false })
  disabled: boolean

  @Prop({ type: Number, default: 0 })
  multipleLimit: number

  get selected() {
    return this.value
  }

  set selected(val) {
    this.$emit('input', val)
  }

  get select_options() {
    const a = this.options.filter(item => this.value.indexOf(item.id) >= 0)
    const b = this.options.filter(item => this.value.indexOf(item.id) === -1)
    return [...a, ...b]
  }

  get clearable() {
    return this.placeholder && this.placeholder !== ''
  }

  get disabled_check() {
    return this.disabled || !this.options.length
  }

  async mounted() {
    await this.$nextTick()

    if (this.$device !== 'desktop') {
      const node_list = this.$el.querySelectorAll('.el-select__tags .el-select__input')

      for (let index = 0; index < node_list.length; index = index + 1) {
        node_list[index].setAttribute('readonly', 'readonly')
      }
    }
  }
}
