import { render, staticRenderFns } from "./SignupComplete.vue?vue&type=template&id=153ce390&scoped=true"
import script from "./SignupComplete.vue?vue&type=script&lang=ts"
export * from "./SignupComplete.vue?vue&type=script&lang=ts"
import style0 from "./SignupComplete.vue?vue&type=style&index=0&id=153ce390&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "153ce390",
  null
  
)

export default component.exports