export type ParallelQueue<T> = () => Promise<T>

/**
 * 同時実行数を制限して並列処理を行う
 */
export async function parallel<T = void>(queues: ParallelQueue<T>[], limit = 1) {
  const jobs = new Set<Promise<T>>()

  const response: T[] = []

  for (const createQueue of queues) {
    const queue = createQueue()

    queue.finally(() => jobs.delete(queue))

    jobs.add(queue)

    if (jobs.size >= limit) {
      const latest = await Promise.race(jobs)

      response.push(latest)
    }
  }

  const last = await Promise.all(jobs)

  response.push(...last)

  return response
}
