import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Scroll from '@/client/components-old/atoms/Scroll'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import DashboardPostItem from '@/client/components-old/molecules/DashboardPostItem'
import { IState as IDashboardState } from '@/client/store/modules/dashboard'

const dashboard = namespace('dashboard')

@Component({
  name: 'DashboardPostScheduled',
  components: {
    Icon,
    Message,
    Panel,
    Scroll,
    Tooltip,
    DashboardPostItem
  }
})
export default class DashboardPostScheduled extends Vue {
  @dashboard.State('api_post_scheduled') posts: IDashboardState['api_post_scheduled']
  @dashboard.State('is_scroll_post_scheduled')
  is_scroll: IDashboardState['is_scroll_post_scheduled']
  @dashboard.State('is_post_scheduled_loading')
  is_loading: IDashboardState['is_post_scheduled_loading']
  @dashboard.State('filter_project_all') filter_project_all: IDashboardState['filter_project_all']
  @dashboard.Action('scrollPostScheduled') scrollPostScheduled

  $refs: {
    scroll: HTMLDivElement
  }

  get is_empty() {
    return !this.posts.length
  }

  @Emit('open-post')
  openPost(payload: any) {
    return { ...payload, mode: 'approval', tab: 'preview' }
  }

  @Watch('is_scroll')
  initialize() {
    if (this.is_scroll && this.$refs.scroll) {
      this.$refs.scroll.scrollTop = 0
    }
  }

  /**
   * もっと読み込む
   */
  async loadMore(event: any) {
    const SCALING_MEASURES = 5

    const is_bottom =
      event.target.scrollHeight <=
      event.target.scrollTop + event.target.clientHeight + SCALING_MEASURES

    if (this.is_loading || !this.is_scroll || !is_bottom) return

    await this.scrollPostScheduled()
  }
}
