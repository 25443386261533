import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import ExternalLink from '@/client/components-old/atoms/ExternalLink'
import Icon from '@/client/components-old/atoms/Icon'
import Label from '@/client/components-old/atoms/Label'
import Message from '@/client/components-old/atoms/Message'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import { IInstagramHashtag } from '@/client/utils/api/instagram_hashtags'

@Component({
  name: 'AnalyticsHashtagItem',
  components: {
    ButtonLink,
    ExternalLink,
    Icon,
    Label,
    Message,
    SwitchButton
  }
})
export default class AnalyticsHashtagItem extends Vue {
  @Prop({ type: Object, required: true })
  option: IInstagramHashtag

  @Prop({ type: Boolean, required: true })
  disabled!: boolean

  get last_datetime() {
    return moment(this.option.last_processed_datetime).format('lll')
  }

  get start_date() {
    return moment(this.option.start_date).format('ll')
  }

  get end_date() {
    return moment(this.option.end_date).format('ll')
  }

  get is_range_active() {
    if (!this.option.start_date && !this.option.end_date) {
      return false
    }

    if (this.option.start_date && !this.option.end_date) {
      return moment().isSameOrAfter(this.option.start_date)
    }

    if (!this.option.start_date && this.option.end_date) {
      return moment().isSameOrBefore(this.option.end_date)
    }

    return moment().isBetween(this.option.start_date, this.option.end_date, 'day', '[]')
  }

  @Emit('edit-hashtag')
  editHashtag() {
    return {
      hashtag_id: this.option.id
    }
  }

  @Emit('change-status')
  changeStatus(payload: boolean) {
    return {
      hashtag_id: this.option.id,
      is_active: payload
    }
  }
}
