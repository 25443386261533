
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import API from '@/client/utils/api'
import {
  IPostPasswordsResetByIdParams,
  IPostPasswordsResetByIdResponse
} from '@/client/utils/api/passwords'
import { PASSWORD_CHARACTER, PASSWORD_REQUIRED } from '@/client/utils/regex'

const notification = namespace('notification')

@Component({
  name: 'PasswordResetForm',
  components: {
    Button,
    Icon,
    Input,
    Message,
    Panel
  }
})
export default class PasswordResetForm extends Vue {
  @notification.Action('showNotification') showNotification: any

  @Prop({ type: String, required: true })
  resetId!: string

  form: {
    password: string
  } = {
    password: ''
  }

  is_password_error = false
  is_password_format_error = false
  is_password_character_error = false
  is_password_required_error = false

  loading = false

  get error_password() {
    return (
      this.is_password_error ||
      this.is_password_format_error ||
      this.is_password_character_error ||
      this.is_password_required_error
    )
  }

  get disabled() {
    const is_password = !this.form.password || this.error_password

    return this.loading || is_password
  }

  /**
   * パスワードを入力
   */
  changePassword(value: string) {
    this.is_password_error = !value
    this.is_password_format_error = value.length < 8 || value.length > 60
    this.is_password_character_error = !PASSWORD_CHARACTER.test(value)
    this.is_password_required_error = !PASSWORD_REQUIRED.test(value)
  }

  /**
   * ログイン画面に移動
   */
  moveLoginPage() {
    this.changeRoute({ name: 'login' })
  }

  /**
   * パスワードを更新
   */
  async resetPassword() {
    if (this.disabled) return

    const params: IPostPasswordsResetByIdParams = {
      ...this.form
    }

    this.loading = true

    const response = await API.post<IPostPasswordsResetByIdResponse>(
      `passwords/resets/${this.resetId}`,
      params
    )

    if (response.data.data) {
      this.showNotification({
        title: 'パスワードを再設定しました。'
      })

      this.moveLoginPage()

      return
    }

    this.loading = false

    if (response.data.error && response.data.error.type === 'PASSWORD_RESET_EXPIRED') {
      return this.showNotification({
        title: 'パスワードリセットの有効期限が切れています。',
        message: '有効期限は申請後72時間以内となります。',
        type: 'error'
      })
    }

    this.showNotification({
      title: 'パスワードの再設定に失敗しました。',
      message: '時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
