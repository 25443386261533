import moment from 'moment-timezone'
import DatePick from 'vue-date-pick'
import { Component, Prop, Vue } from 'vue-property-decorator'

import Autocomplete from '@/client/components-old/atoms/Autocomplete'
import Input from '@/client/components-old/atoms/Input'
import Select from '@/client/components-old/atoms/Select'

import { getScheduleDefaultDatetime } from './utils'

@Component({
  name: 'DateTimePicker',
  components: {
    DatePick,
    Autocomplete,
    Input,
    Select
  }
})
export default class DateTimePicker extends Vue {
  @Prop({ type: String, default: null })
  value: string

  @Prop({ type: Boolean, default: false })
  isError: boolean

  $refs: {
    input: any
  }

  is_show = false

  get input_value() {
    return this.value
  }

  set input_value(val) {
    this.$emit('input', val)
  }

  get date_value() {
    if (!this.value || !moment(this.value).isValid()) {
      return moment().format('YYYY-MM-DD')
    }

    return moment(this.value).format('YYYY-MM-DD')
  }

  set date_value(date) {
    const datetime = `${date} ${moment(this.value).format('HH:mm')}`

    this.$emit('input', datetime)
  }

  get hour_value() {
    if (!this.value || !moment(this.value).isValid()) {
      return '00'
    }

    return moment(this.value).format('HH')
  }

  set hour_value(val) {
    const datetime = moment(this.value)

    if (Number(val) < 24) {
      datetime.set('hours', Number(val))
    }

    this.$emit('input', datetime.format('YYYY-MM-DD HH:mm'))
  }

  get minute_value() {
    if (!this.value || !moment(this.value).isValid()) {
      return '00'
    }

    return moment(this.value).format('mm')
  }

  set minute_value(val) {
    const datetime = moment(this.value)

    if (Number(val) < 60) {
      datetime.set('minutes', Number(val))
    }

    this.$emit('input', datetime.format('YYYY-MM-DD HH:mm'))
  }

  get hours() {
    if (this.$mq === 'sm') {
      return Array.from(Array(24).keys()).map(number => ({
        value: this.getTimeString(number),
        text: this.getTimeString(number)
      }))
    }

    return Array.from(Array(24).keys()).map(number => this.getTimeString(number))
  }

  get minutes() {
    if (this.$mq === 'sm') {
      return Array.from(Array(12).keys()).map(number => ({
        value: this.getTimeString(number * 5),
        text: this.getTimeString(number * 5)
      }))
    }

    return Array.from(Array(12).keys()).map(number => this.getTimeString(number * 5))
  }

  get months() {
    if (moment.locale() === 'ja') {
      return ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    }

    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
  }

  get weekdays() {
    if (moment.locale() === 'ja') {
      return ['月', '火', '水', '木', '金', '土', '日']
    }

    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  }

  /**
   * 日付が無効かチェック
   */
  checkDateTime(): void {
    if (moment(this.value).isValid()) {
      this.$emit('input', moment(this.value).format('YYYY-MM-DD HH:mm'))
    } else if (this.value) {
      this.$emit('input', `${this.date_value} ${this.hour_value}:${this.minute_value}`)
    }
  }

  /**
   * 日付が無効かチェック
   */
  isDateDisabled(date: Date): boolean {
    const start = moment().tz('Asia/Tokyo').startOf('day')

    const end = moment().tz('Asia/Tokyo').startOf('day').add(6, 'month')

    return moment(date).isBefore(start) || moment(date).isAfter(end)
  }

  /**
   * 表示する
   */
  open(): void {
    if (this.$device !== 'desktop' && !this.is_show) {
      this.$refs.input.blur()
    }

    this.is_show = true

    document.addEventListener('click', this.handleClick, false)
    document.addEventListener('touchstart', this.handleClick, false)
    document.addEventListener('keyup', this.handleKeyUp, false)

    if (!this.value || !moment(this.value, 'YYYY-MM-DD HH:mm', true).isValid()) {
      const datetime = getScheduleDefaultDatetime(new Date())

      this.$emit('input', datetime)
    }
  }

  /**
   * 閉じる
   */
  close(): void {
    this.is_show = false
    this.$refs.input.blur()

    document.removeEventListener('click', this.handleClick)
    document.removeEventListener('touchstart', this.handleClick)
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  /**
   * 時間文字列を取得
   */
  getTimeString(value: number): string {
    if (Number(value) > 9) {
      return value.toString()
    }

    return `0${value}`
  }

  /**
   * クリックイベント
   */
  handleClick(event: any): void {
    if (!this.$el.contains(event.target)) {
      this.close()
    }
  }

  /**
   * キーボードイベント
   */
  handleKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close()
    }
  }
}
