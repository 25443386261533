import { Component, Emit, Vue } from 'vue-property-decorator'

import ButtonCta from '@/client/components-old/atoms/ButtonCta'
import Panel from '@/client/components-old/atoms/Panel'
import i18n from '@/client/utils/i18n'

@Component({
  name: 'DashboardGroupRegister',
  components: {
    ButtonCta,
    Panel
  }
})
export default class DashboardGroupRegister extends Vue {
  /**
   * トライアル終了グループのデータを引き継いで契約する
   */
  applyContractGroup(): void {
    let message = ''

    message += i18n.t('データ引き継ぎでの契約を申し込んでよろしいですか？') + '\n'
    message += i18n.t('状況を確認後、担当から折り返しご連絡いたします。') + '\n'
    message += i18n.t('この操作は取り消しできません。')

    const confirm = window.confirm(message)

    if (!confirm) return

    this.applyContract()
  }

  /**
   * グループを新規登録して契約する
   */
  registerNewGroup(): void {
    this.changeRoute({ name: 'setting_billing_info' })
  }

  /**
   * 再トライアルを申し込む
   */
  applyRetrialGroup(): void {
    let message = ''

    message += i18n.t('再トライアルを申し込んでよろしいですか？') + '\n'
    message += i18n.t('状況を確認後、担当から折り返しご連絡いたします。') + '\n'
    message += i18n.t('この操作は取り消しできません。')

    const confirm = window.confirm(message)

    if (!confirm) return

    this.applyRetrial()
  }

  @Emit('apply-contract')
  applyContract(payload?: any): any {
    return payload
  }

  @Emit('apply-retrial')
  applyRetrial(payload?: any): any {
    return payload
  }

  @Emit('change-route')
  changeRoute(payload: any): any {
    return payload
  }
}
