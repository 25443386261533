import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import { TFacebookAccount } from '@/client/utils/api/facebook_accounts'
import { TInstagramAccount } from '@/client/utils/api/instagram_accounts'
import { TTwitterAccount } from '@/client/utils/api/twitter_accounts'

@Component({
  name: 'GroupSettingSnsItem',
  components: {
    ButtonLink,
    Dropdown,
    Icon,
    Message,
    Tooltip,
    Account
  }
})
export default class GroupSettingSnsItem extends Vue {
  @Prop({ type: Object, required: true })
  account: TFacebookAccount & TTwitterAccount & TInstagramAccount

  @Prop({ type: String, required: true })
  snsType: 'facebook' | 'twitter' | 'instagram'

  get is_not_twitter_enterprise_verified() {
    return (
      this.snsType === 'twitter' &&
      this.account.is_use_monitoring &&
      !this.account.is_enterprise_verified
    )
  }

  get token_modified_description() {
    return this.$options.filters.translate('アクセストークン更新日時: [[modified]]', {
      modified: this.$options.filters.date(this.account.token_modified, 'lll')
    })
  }

  get issuer_description() {
    return this.$options.filters.translate('発行者: [[name]]', {
      name: this.account.token_name
    })
  }

  get dropdown_options() {
    const options = [
      {
        value: 'role',
        text: this.$options.filters.translate('権限設定'),
        icon: 'shield'
      },
      {
        value: 'token',
        text: this.$options.filters.translate('アクセストークン更新'),
        icon: 'autorenew'
      }
    ]

    if (this.account.is_canceled) {
      options.push({
        value: 'cancel_unregister',
        text: this.$options.filters.translate('解除取消'),
        icon: 'clear'
      })
    } else {
      options.push({
        value: 'cancel',
        text: this.$options.filters.translate('登録解除'),
        icon: 'clear'
      })
    }

    return options
  }

  get no_account_authority() {
    return this.snsType === 'instagram' && !this.account.is_publish_permission
  }

  /**
   * コマンドを実行
   */
  execCommand(command: string) {
    switch (command) {
      case 'role':
        return this.openRoleDialog()
      case 'token':
        return this.openTokenDialog()
      case 'cancel':
        return this.openUnregisterDialog()
      case 'cancel_unregister':
        return this.openUnregisterCancelDialog()
    }
  }

  @Emit('open-role-dialog')
  openRoleDialog() {
    return { account_id: this.account.id, sns_type: this.snsType }
  }

  @Emit('open-token-dialog')
  openTokenDialog() {
    return { account_id: this.account.id, sns_type: this.snsType }
  }

  @Emit('open-unregister-dialog')
  openUnregisterDialog() {
    return { account_id: this.account.id, sns_type: this.snsType }
  }

  @Emit('open-unregister-cancel-dialog')
  openUnregisterCancelDialog() {
    return { account_id: this.account.id, sns_type: this.snsType }
  }
}
