import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/instagram_hashtags'
import Button from '@/client/components-old/atoms/Button'
import Label from '@/client/components-old/atoms/Label'
import Message from '@/client/components-old/atoms/Message'
import Select from '@/client/components-old/atoms/Select'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import AnalyticsRanking from '@/client/components-old/molecules/AnalyticsRanking'
import { TrackingService } from '@/client/services'
import {
  IGetter as IInstagramHashtagGetter,
  IState as IInstagramHashtagState
} from '@/client/store/modules/instagram_hashtag'
import { IInstagramHashtagsRankingPost } from '@/client/utils/api/instagram_hashtags'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'

const instagram_hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagPostRanking',
  components: {
    Button,
    Label,
    Message,
    Select,
    AnalyticsPanel,
    AnalyticsRanking
  }
})
export default class InstagramHashtagPostRanking extends Vue {
  @instagram_hashtag.State('api_settings') api_settings: IInstagramHashtagState['api_settings']
  @instagram_hashtag.State('api_ranking') api_ranking: IInstagramHashtagState['api_ranking']
  @instagram_hashtag.State('start_date') start_date: IInstagramHashtagState['start_date']
  @instagram_hashtag.State('end_date') end_date: IInstagramHashtagState['end_date']
  @instagram_hashtag.State('ranking_sort') ranking_sort: IInstagramHashtagState['ranking_sort']
  @instagram_hashtag.State('ranking_size') ranking_size: IInstagramHashtagState['ranking_size']
  @instagram_hashtag.State('is_ranking_loading')
  is_ranking_loading: IInstagramHashtagState['is_ranking_loading']
  @instagram_hashtag.Getter('is_data') is_data: IInstagramHashtagGetter['is_data']
  @instagram_hashtag.Action('changeRankingSort') changeRankingSort
  @instagram_hashtag.Action('changeRankingSize') changeRankingSize

  @Prop({ type: String, default: 'descending' })
  sort: 'ascending' | 'descending'

  get display_data() {
    return this.api_ranking.map(v => ({
      hashtag_name: this.convertHashtagName(v.hashtag_id),
      posts: this.convertRankingPosts(v.posts)
    }))
  }

  get display_limit() {
    return this.ranking_size
  }

  get ranking_sort_options() {
    return constants.RANKING_SORT_OPTIONS.map(v => ({
      ...v,
      text: i18n.t(v.text)
    }))
  }

  get ranking_size_options() {
    return constants.RANKING_SIZE_OPTIONS.map(v => ({
      value: v,
      text: i18n.t('上位[[count]]件', { count: v })
    }))
  }

  @Emit('open-embed')
  openEmbed(post_url: string) {
    TrackingService.sendEvent(`click:クチコミ(IG)>サマリー|ランキング:プレビュー`)

    return { sns_link: post_url }
  }

  /**
   * キーワード分類名を変換
   */
  convertHashtagName(id: number) {
    const hashtags = this.api_settings

    const result = hashtags.find(hashtag => hashtag.id === id)

    return result.name ? result.name : ''
  }

  /**
   * 投稿一覧を変換
   */
  convertRankingPosts(posts: IInstagramHashtagsRankingPost[]) {
    const sort_posts = [...posts]

    if (this.sort === 'ascending') {
      sort_posts.sort((a, b) => (a[this.ranking_sort] >= b[this.ranking_sort] ? 1 : -1))
    } else if (this.sort === 'descending') {
      sort_posts.sort((a, b) => (a[this.ranking_sort] <= b[this.ranking_sort] ? 1 : -1))
    }

    sort_posts.splice(this.display_limit)

    return sort_posts.map((v, index) => {
      const hashtag = this.api_settings.find(hashtag => hashtag.id === v.in_hashtag_id)

      return {
        account_id: hashtag ? hashtag.in_account.account_id : '',
        post_id: v.post_id,
        post_url: v.post_url,
        media_src: v.thumbnail_url,
        media_count: v.media_count,
        rank_no: index + 1,
        type: v.post_type,
        datetime: moment(v.timestamp).valueOf(),
        message: v.message,
        is_top_media: Boolean(v.is_top_media),
        results: this.rankingSortAction(v)
      }
    })
  }

  /**
   * ランキングソートアクション
   */
  rankingSortAction(post: IInstagramHashtagsRankingPost) {
    const actions = ['reaction_count', 'like_count', 'comment_count'].sort(a =>
      a === this.ranking_sort ? -1 : 0
    )

    return actions.map(v => ({
      key: constants[v.toUpperCase()].text,
      value: post[v] !== null ? post[v].toLocaleString() : null
    }))
  }

  /**
   * CSVをダウンロード
   */
  downloadCSV() {
    const fields = [
      i18n.t(constants.HASHTAG_NAME.text),
      i18n.t(constants.TIMESTAMP.text),
      i18n.t(constants.POST_TYPE.text),
      i18n.t(constants.MESSAGE.text),
      i18n.t(constants.REACTION_COUNT.text),
      i18n.t(constants.LIKE_COUNT.text),
      i18n.t(constants.COMMENT_COUNT.text),
      i18n.t(constants.IS_TOP_MEDIA.text),
      i18n.t(constants.THUMBNAIL_URL.text),
      i18n.t(constants.POST_URL.text),
      i18n.t(constants.POST_ID.text)
    ]

    const data = []

    for (let i = 0; i < this.api_ranking.length; i++) {
      const ranking_posts = this.api_ranking[i].posts.map(post => [
        this.convertHashtagName(post.in_hashtag_id),
        csv.format(post.timestamp),
        post.post_type,
        post.message,
        post.reaction_count,
        post.like_count,
        post.comment_count,
        post.is_top_media ? i18n.t('人気投稿') : '',
        post.thumbnail_url,
        post.post_url,
        post.post_id
      ])

      data.push(...ranking_posts)

      TrackingService.sendEvent(`click:クチコミ(IG)>サマリー|ランキング:CSVダウンロード`)
    }

    const csv_data = { fields, data }

    const page_name = i18n.t('Instagramクチコミ分析')
    const component_name = i18n.t('投稿ランキング')

    const sort = constants.RANKING_SORT_OPTIONS.find(v => v.value === this.ranking_sort)
    const sort_text = sort ? i18n.t(sort.text) : ''

    const csv_filename = [
      page_name,
      component_name,
      sort_text,
      this.ranking_size,
      this.start_date,
      this.end_date
    ]
      .filter(Boolean)
      .join('_')

    csv.download(csv_data, csv_filename)
  }

  /**
   * ランキングのソート変更
   */
  async onChangeRankingSort(payload: string) {
    await this.changeRankingSort(payload)

    const sort = constants.RANKING_SORT_OPTIONS.find(v => v.value === this.ranking_sort)
    const sort_text = sort ? i18n.t(sort.text) : ''

    TrackingService.sendEvent(`select:クチコミ(IG)>サマリー|ランキング:KW分類:${sort_text}`)
  }

  /**
   * ランキングの件数変更
   */
  async onChangeRankingSize(payload: string) {
    await this.changeRankingSize(payload)

    const ranking_size_option = this.ranking_size_options.find(v => v.value === this.ranking_size)
    const sort_label = ranking_size_option?.text || ''

    TrackingService.sendEvent(`select:クチコミ(IG)>サマリー|ランキング:${sort_label}`)
  }
}
