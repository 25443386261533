
import { Component, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import FacebookExcelReport from '@/client/components-old/organisms/FacebookExcelReport'

@Component({
  name: 'FacebookReport',
  components: {
    Scroll,
    FacebookExcelReport
  }
})
export default class FacebookReport extends Vue {}
