import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Account from '@/client/components-old/molecules/Account'
import { RoleAnalyticsName, RoleMonitoringName, RolePostName, SnsType } from '@/common/types'

@Component({
  name: 'OperatorRoleSns',
  components: {
    ButtonGroup,
    Icon,
    Account,
    Flex
  }
})
export default class OperatorRoleSns extends Vue {
  @Prop({ type: Object, default: null })
  account!: {
    account_id: number
    name: string
    username: string
    image_url: string
    role_analytics: RoleAnalyticsName
    role_monitoring: RoleMonitoringName
    role_post: RolePostName
    is_use_monitoring: boolean
    is_use_monitoring_canceled: boolean
  }

  @Prop({ type: String, required: true })
  snsType!: SnsType

  @Prop({ type: Boolean, default: true })
  editable!: boolean

  selected_role_analytics: RoleAnalyticsName = 'no_authority'
  selected_role_monitoring: RoleMonitoringName = 'no_authority'
  selected_role_post: RolePostName = 'no_authority'

  @Watch('account', { immediate: true, deep: true })
  changeRoles() {
    this.selected_role_post = this.account.role_post
    this.selected_role_analytics = this.account.role_analytics
    this.selected_role_monitoring = this.account.role_monitoring
  }

  get button_group_size() {
    return this.$mq === 'sm' ? 'minimum' : 'normal'
  }

  get analytics() {
    return [
      { value: 'no_authority', text: '権限なし' },
      { value: 'admin', text: '管理者' }
    ]
  }

  get monitoring() {
    return [
      { value: 'no_authority', text: '権限なし' },
      { value: 'observer', text: '監視者' },
      { value: 'admin', text: '管理者' }
    ]
  }

  get post() {
    return [
      { value: 'no_authority', text: '権限なし' },
      { value: 'editor', text: '編集者' },
      { value: 'admin', text: '管理者' }
    ]
  }

  @Emit('change-role')
  changeRole() {
    return {
      account_id: this.account.account_id,
      role_post: this.selected_role_post,
      role_analytics: this.selected_role_analytics,
      role_monitoring: this.selected_role_monitoring
    }
  }

  // ? TikTokのみモニタリング未実装のため、暫定的に対応済みのSNSのみを表示対象とする
  get is_monitoring_sns() {
    const monitoring_sns: SnsType[] = ['facebook', 'instagram', 'twitter']
    return monitoring_sns.includes(this.snsType)
  }

  /**
   * ロール名を変換
   */
  convertRole(role: string) {
    switch (role) {
      case 'admin':
        return '管理者'
      case 'editor':
        return '編集者'
      case 'observer':
        return '監視者'
      default:
        return '権限なし'
    }
  }

  /**
   * ロールカラーを変換
   */
  colorRole(role: string) {
    if (role === 'no_authority') {
      return 'color-disabled'
    }

    return ''
  }
}
