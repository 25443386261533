import API from '@/client/utils/api'

import {
  GetTikTokInsightsPostsParams,
  GetTikTokInsightsPostsResponse
} from '../../tiktok_analytics/api'

export async function getTikTokInsightPosts(query: GetTikTokInsightsPostsParams) {
  const response = await API.get<GetTikTokInsightsPostsResponse>('tiktok_insights/posts', {
    params: query
  })

  return response.data
}
