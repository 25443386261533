import { saveAs } from 'file-saver'
import moment from 'moment-timezone'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { MIN_DATE } from '@/client/components-old/_constants/global'
import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Radio from '@/client/components-old/atoms/Radio'
import Account from '@/client/components-old/molecules/Account'
import MonthPicker from '@/client/components-old/molecules/MonthPicker'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import API from '@/client/utils/api'
import {
  IGetPostCategoriesParams,
  IGetPostCategoriesResponse
} from '@/client/utils/api/post_categories'
import {
  IGetSnsCategoryPostsParams,
  IGetSnsCategoryPostsResponse
} from '@/client/utils/api/sns_category_posts'
import {
  IGetTwitterInsightsAccountsParams,
  IGetTwitterInsightsAccountsResponse,
  IGetTwitterInsightsPostsParams,
  IGetTwitterInsightsPostsResponse,
  ITwitterInsightsAccounts,
  ITwitterInsightsPosts
} from '@/client/utils/api/twitter_insights'

type ITwitterInsightsPostsExcelReprot = ITwitterInsightsPosts & { category?: string }

const accounts = namespace('accounts')
const notification = namespace('notification')

@Component({
  name: 'TwitterExcelReport',
  components: {
    Button,
    Message,
    Icon,
    Panel,
    Radio,
    Account,
    MonthPicker
  }
})
export default class TwitterExcelReport extends Vue {
  @State('user') user: IRootState['user']
  @State('project') project: IRootState['project']
  @accounts.Getter('twitter_analytics') accounts: IAccountsGetter['twitter_analytics']
  @notification.Action('showNotification') showNotification

  account_id = ''
  target_month = ''
  first_action = false
  is_loading = false

  daily_data: ITwitterInsightsAccounts[] = []
  post_data: ITwitterInsightsPostsExcelReprot[] = []
  categories: string[] = []

  get disabled_picker() {
    const account = this.accounts.find(v => v.id === this.account_id)

    return !account || account.is_empty
  }

  get disabled_button() {
    return this.disabled_picker || this.target_month === '' || this.error_message || this.is_loading
  }

  get error_message() {
    return this.first_action && this.daily_data.length === 0 && this.post_data.length === 0
  }

  get start_date() {
    return moment(MIN_DATE).format('YYYY-MM')
  }

  get end_date() {
    const end_date = moment().startOf('month')

    if (moment().date() === 1) {
      end_date.subtract(1, 'month')
    }

    return end_date.format('YYYY-MM')
  }

  @Watch('account_id')
  async watchAccountId() {
    if (this.target_month !== '') {
      TrackingService.sendEvent(`radio:Exレポート(TW)|アカウントを選択`)

      await this.getInsights()
    }
  }

  @Watch('target_month')
  async watchTargetMonth() {
    if (this.target_month !== '') {
      TrackingService.sendEvent(`select:Exレポート(TW)|レポート対象月:${this.target_month}`)
    }

    await this.getInsights()
  }

  /**
   * 分析データの取得
   * @returns {void}
   */
  async getInsights(): Promise<void> {
    const start_date = moment(this.target_month)
      .subtract(5, 'month')
      .startOf('month')
      .format('YYYY-MM-DD')

    let end_date = moment(this.target_month).endOf('month').format('YYYY-MM-DD')

    if (moment().isSame(moment(this.target_month), 'month')) {
      end_date = moment().subtract(1, 'day').format('YYYY-MM-DD')
    }

    const account_insight: IGetTwitterInsightsAccountsParams = {
      project_id: this.project.id,
      account_id: this.account_id,
      start_date,
      end_date
    }

    const post_insight: IGetTwitterInsightsPostsParams = {
      project_id: this.project.id,
      account_id: this.account_id,
      start_date,
      end_date
    }

    const category: IGetPostCategoriesParams = {
      project_id: this.project.id
    }

    const sns_category_post: IGetSnsCategoryPostsParams = {
      project_id: this.project.id,
      target: 'twitter',
      account_ids: [this.account_id],
      start_date,
      end_date
    }

    this.is_loading = true

    const [accounts, posts, categories, sns_category_posts] = await Promise.all([
      API.get<IGetTwitterInsightsAccountsResponse>('twitter_insights/accounts', {
        params: account_insight
      }),
      API.get<IGetTwitterInsightsPostsResponse>('twitter_insights/posts', {
        params: post_insight
      }),
      API.get<IGetPostCategoriesResponse>('post_categories', {
        params: category
      }),
      API.get<IGetSnsCategoryPostsResponse>('sns_category_posts', {
        params: sns_category_post
      })
    ])

    setTimeout(() => {
      this.is_loading = false
    }, 1000)

    const post_categories = categories.data.data
    const target_month_start_date = moment(this.target_month).startOf('month')

    this.daily_data = accounts.data.data
    this.post_data = posts.data.data
      ? posts.data.data.map(post => {
          if (!sns_category_posts.data.data) {
            return post
          }

          const category = sns_category_posts.data.data.find(
            sns_post_category => sns_post_category.post_id === post.post_id
          )

          if (!category) {
            return post
          }

          const category_ids = category.category_ids
          const category_data = []
          const is_target_month_post = moment(post.created_time).isSameOrAfter(
            target_month_start_date
          )

          for (let index = 0; index < category_ids.length; index++) {
            const category_info = post_categories.find(
              post_category => post_category.id === category_ids[index]
            )

            if (!category_info) {
              continue
            }

            category_data.push(category_info.name)

            if (this.categories.indexOf(category_info.name) === -1 && is_target_month_post) {
              this.categories.push(category_info.name)
            }
          }

          return { ...post, category: category_data.join('\n') }
        })
      : []

    if (!this.first_action) {
      this.first_action = true
    }
  }

  /**
   * Excelレポートのダウンロード
   * @returns {void}
   */
  async downloadExcelReport(): Promise<void> {
    TrackingService.sendEvent(`select:Exレポート(TW)|ダウンロード`)

    const target_month = moment(this.target_month).format('YYYY-MM')
    const account = this.accounts.find(v => v.id === this.account_id)

    const params = {
      language: this.user.language,
      target_month,
      account_id: this.account_id,
      account_name: account ? account.name : '',
      daily_data: this.daily_data,
      post_data: this.post_data,
      categories: this.categories
    }

    this.is_loading = true

    const response = await API.post<Blob>('reports/twitter', params, { responseType: 'blob' })

    this.is_loading = false

    if (response.status !== 200 || !response.headers['content-disposition']) {
      const title = this.$options.filters.translate('レポートの作成に失敗しました。')
      return this.showNotification({ title, type: 'error' })
    }

    saveAs(response.data, `report_x_${this.account_id}_${target_month}.xlsm`)
  }
}
