import { defineStore } from 'pinia'

import { useStore } from './useStore'

interface Options {
  title: string
  message?: string
  duration?: number
}

export const useNotificationStore = defineStore('notification', () => {
  const store = useStore()

  async function success(options: Options) {
    await store.dispatch('notification/showNotification', { type: 'success', ...options })
  }

  async function error(options: Options) {
    await store.dispatch('notification/showNotification', { type: 'error', ...options })
  }

  async function close() {
    await store.dispatch('notification/hideNotification')
  }

  return {
    success,
    error,
    close
  }
})
