import { RouteConfig } from 'vue-router'

export const twitter_enterprise_callback: RouteConfig = {
  path: '/twitter_enterprise_callback',
  name: 'twitter_enterprise_callback',
  meta: {
    auth: true
  },
  beforeEnter(to, from, next) {
    window.opener.onTwitterEnterpriseAuth(to.query)
    next()
  }
}
