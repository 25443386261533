
import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import PostListItem from '@/client/components-old/organisms/PostListItem'
import { IGetter, IState } from '@/client/store/modules/post_management'

const post_management = namespace('post_management')

@Component({
  name: 'PostManagementListView',
  components: {
    Message,
    Scroll,
    PostListItem
  }
})
export default class PostManagementListView extends Vue {
  @post_management.State('api_posts') api_posts: IState['api_posts']
  @post_management.State('is_loading') is_loading: IState['is_loading']
  @post_management.State('is_get_post_failed') is_get_post_failed: IState['is_get_post_failed']
  @post_management.Getter('is_empty_posts') is_empty_posts: IGetter['is_empty_posts']
  @post_management.Getter('is_read_more') is_read_more: IGetter['is_read_more']
  @post_management.Action('scrollFetchMorePosts') scrollFetchMorePosts
  @post_management.Action('openPostDetailDialog') openPostDetailDialog

  $refs: {
    scroll: Scroll
  }

  /**
   * もっと読み込む
   */
  async loadMore(event: any) {
    if (event.target.scrollTop + event.target.clientHeight + 50 >= event.target.scrollHeight) {
      await this.scrollFetchMorePosts()
    }
  }

  @Watch('is_loading', { immediate: true })
  watchIsLoading() {
    // オフセットがリセットされた場合は、上にスクロールする
    if (this.is_loading && this.$refs?.scroll) {
      this.$refs.scroll.scrollTop()
    }
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
