import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { HASHTAG_SIZE_OPTIONS } from '@/client/components-old/_constants/instagram_hashtags'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Select from '@/client/components-old/atoms/Select'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartSingleBar from '@/client/components-old/molecules/ChartSingleBar'
import { TrackingService } from '@/client/services'
import {
  IGetter as IInstagramHashtagGetter,
  IState as IInstagramHashtagState
} from '@/client/store/modules/instagram_hashtag'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'

const instagram_hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagHashtagList',
  components: {
    ButtonLink,
    Icon,
    Select,
    AnalyticsPanel,
    ChartSingleBar
  }
})
export default class InstagramHashtagHashtagList extends Vue {
  @instagram_hashtag.State('api_hashtags') api_hashtags: IInstagramHashtagState['api_hashtags']
  @instagram_hashtag.State('api_settings') api_settings: IInstagramHashtagState['api_settings']
  @instagram_hashtag.State('is_hashtags_loading')
  is_hashtags_loading: IInstagramHashtagState['is_hashtags_loading']
  @instagram_hashtag.State('hashtags_hashtag_id')
  hashtags_hashtag_id: IInstagramHashtagState['hashtags_hashtag_id']
  @instagram_hashtag.State('hashtag_ids') hashtag_ids: IInstagramHashtagState['hashtag_ids']
  @instagram_hashtag.State('hashtags_size') hashtags_size: IInstagramHashtagState['hashtags_size']
  @instagram_hashtag.State('start_date') start_date: IInstagramHashtagState['start_date']
  @instagram_hashtag.State('end_date') end_date: IInstagramHashtagState['end_date']
  @instagram_hashtag.Getter('is_data') is_data: IInstagramHashtagGetter['is_data']
  @instagram_hashtag.Action('changeHashtagsHashtagId') changeHashtagsHashtagId
  @instagram_hashtag.Action('changeHashtagsSize') changeHashtagsSize
  @instagram_hashtag.Action('checkHashtagPosts') checkHashtagPosts

  get is_hashtags_data() {
    return this.api_hashtags.length > 0
  }

  get hashtags_data() {
    const max = Math.max(...this.api_hashtags.map(hashtag => hashtag.post_count))

    return this.api_hashtags.map(hashtag => {
      const chart_options = {
        colors: ['#1c84c6'],
        display_total: true,
        max,
        series: [hashtag.post_count],
        total: hashtag.post_count
      }

      return { hashtag: hashtag.hashtag, chart_options }
    })
  }

  get hashtags_hashtag_options() {
    const hashtag_options = this.hashtag_ids.map(hashtag_id => {
      const hashtag = this.api_settings.find(v => v.id === hashtag_id)

      return { value: hashtag_id, text: hashtag ? hashtag.name : '' }
    })

    return [
      {
        value: '',
        text: i18n.t('全分類')
      },
      ...hashtag_options
    ]
  }

  get hashtags_size_options() {
    return HASHTAG_SIZE_OPTIONS.map(v => ({
      value: v,
      text: i18n.t('上位[[count]]件', { count: v })
    }))
  }

  /**
   * ダウンロードボタンを押した時
   */
  onDownload() {
    const setting = this.api_settings.find(v => v.id === this.hashtags_hashtag_id)
    const hashtag_name = setting ? setting.name : i18n.t('全分類')

    const csv_filename = [
      i18n.t('Instagramクチコミ分析'),
      i18n.t('投稿ハッシュタグ'),
      hashtag_name,
      this.hashtags_size,
      this.start_date,
      this.end_date
    ].join('_')

    const fields = [i18n.t('ハッシュタグ名'), i18n.t('投稿数')]

    const data = this.api_hashtags.map(hashtag => [hashtag.hashtag, hashtag.post_count])

    const csv_data = { fields, data }

    csv.download(csv_data, csv_filename)

    TrackingService.sendEvent(`click:クチコミ(IG)>サマリー|HT一覧:CSVダウンロード`)
  }

  /**
   * ハッシュタグ一覧で指定しているハッシュタグの変更
   */
  async onChangeHashtagsHashtagId(payload: number) {
    await this.changeHashtagsHashtagId(payload)

    const hashtags_hashtag_option = this.hashtags_hashtag_options.find(
      v => v.value === this.hashtags_hashtag_id
    )
    const hashtag_size = hashtags_hashtag_option?.value || ''

    TrackingService.sendEvent(`select:クチコミ(IG)>サマリー|HT一覧:KW分類:${hashtag_size}`)
  }

  /**
   * ハッシュタグ一覧の件数変更
   */
  async onChangeHashtagsSize(payload: number) {
    await this.changeHashtagsSize(payload)

    const hashtags_size_option = this.hashtags_size_options.find(
      v => v.value === this.hashtags_size
    )
    const hashtags_size_label = hashtags_size_option?.text

    TrackingService.sendEvent(`select:クチコミ(IG)>サマリー|HT一覧:${hashtags_size_label}`)
  }

  /**
   * 指定ハッシュタグの投稿の確認
   */
  async onCheckHashtagPosts(payload: string) {
    await this.checkHashtagPosts(payload)

    TrackingService.sendEvent(`click:クチコミ(IG)>サマリー|HT一覧:投稿を確認`)
  }
}
