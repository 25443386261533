// データ種別
export const DATA_TYPES = [
  {
    value: 'daily_data',
    text: '日次データ',
    color: 'info'
  },
  {
    value: 'post_data',
    text: '投稿データ(公開日で集計)',
    color: 'success'
  }
]

// 集計種別
export const UNITS = [
  { text: '期間合計', value: 'total' },
  { text: '1投稿あたり', value: 'average' }
]

// 順序種別
export const POST_RANKING_SORTS = [
  { value: 'ascending', text: '昇順' },
  { value: 'descending', text: '降順' }
]

// 件数種別
export const POST_RANKING_LIMITS = [
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 }
]

// 日次データ指標オプション
export const DAILY_DATA_METRICS = [
  { text: 'フォロワー数(累計)', value: 'followers_count', tooltip: '' },
  { text: 'フォロワー数(増減)', value: 'followers_count_up_down', tooltip: '' },
  { text: 'フォロー数(累計)', value: 'friends_count', tooltip: '' },
  { text: 'フォロー数(増減)', value: 'friends_count_up_down', tooltip: '' },
  { text: 'リスト数(累計)', value: 'listed_count', tooltip: '' },
  { text: 'リスト数(増減)', value: 'listed_count_up_down', tooltip: '' }
]

// 投稿データ指標オプション
export const POST_DATA_METRICS = [
  { text: 'インプレッション', value: 'impressions', tooltip: '' },
  { text: 'リポスト', value: 'retweets', tooltip: '' },
  { text: '引用', value: 'quotes', tooltip: '' },
  { text: 'いいね', value: 'favorites', tooltip: '' },
  { text: '返信', value: 'replies', tooltip: '' },
  { text: '反応数', value: 'reactions', tooltip: '' },
  { text: '反応率', value: 'reactions_rate', tooltip: '' }
]

// 投稿ランキング指標オプション
export const POST_RANKING_MAIN_METRICS = [
  ...POST_DATA_METRICS,
  { text: '公開日時', value: 'created_time', tooltip: '' },
  { text: '公開日のフォロワー数', value: 'followers_at_that_time', tooltip: '' }
]

export const POST_RANKING_SUB_METRICS = [
  { text: '指定なし', value: '' },
  ...POST_RANKING_MAIN_METRICS
]

// 垂直チャートの投稿データ指標オプション
export const CHANGE_CHART_POST_DATA_METRICS = POST_DATA_METRICS

// 垂直チャートの日次データ指標オプション
export const CHANGE_CHART_DAILY_DATA_METRICS = [...DAILY_DATA_METRICS]

export const POST_TYPE = [
  { text: 'テキスト', value: 'text' },
  { text: '画像', value: 'photo' },
  { text: 'リンク', value: 'link' },
  { text: '動画', value: 'video' },
  { text: 'アニメーションGIF', value: 'animation-gif' }
]

export const TABLE_COLUMN_METRICS = [
  { text: 'インプレッション', value: 'impressions', tooltip: '' },
  { text: 'リポスト', value: 'retweets', tooltip: '' },
  { text: '引用', value: 'quotes', tooltip: '' },
  { text: 'いいね', value: 'favorites', tooltip: '' },
  { text: '返信', value: 'replies', tooltip: '' },
  { text: '反応数', value: 'reactions', tooltip: '' },
  { text: '反応率', value: 'reactions_rate', tooltip: '' },
  { text: '公開日のフォロワー数', value: 'followers_at_that_time', tooltip: '' }
]

export const POST_TABLE_TOTAL = [
  { text: '公開された投稿', value: 'none' },
  { text: '投稿タイプ', value: 'type' },
  { text: 'ハッシュタグ', value: 'hashtag' },
  { text: 'タグ', value: 'category' },
  { text: '文字数', value: 'character_count' }
]

export const TABLE_TABS = [
  { text: '平均', value: 'average' },
  { text: '合計', value: 'total' }
]

export const POST_TABLE_ACCOUNT_METRIC = [
  { text: '対象', value: 'account' },
  { text: 'アカウント名', value: 'account_name' },
  { text: 'プロフィール画像', value: 'account_img' }
]

export const POST_TABLE_TYPE_METRICS = [
  { text: '投稿タイプ', value: 'type' },
  { text: '投稿数', value: 'post_count' },
  ...POST_TABLE_ACCOUNT_METRIC,
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_HASHTAG_METRICS = [
  { text: 'ハッシュタグ', value: 'hashtag' },
  { text: '投稿数', value: 'post_count' },
  ...POST_TABLE_ACCOUNT_METRIC,
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_CATEGORY_METRICS = [
  { text: 'タグ', value: 'category' },
  { text: '投稿数', value: 'post_count' },
  ...POST_TABLE_ACCOUNT_METRIC,
  ...TABLE_COLUMN_METRICS
]

export const POST_COMPARISON_TABLE_METRICS = [
  { text: '公開日時', value: 'created_time' },
  { text: '投稿内容', value: 'message' },
  { text: '投稿タイプ', value: 'type' },
  { text: '投稿ID', value: 'post_id' },
  { text: '投稿URL', value: 'post_url' },
  { text: 'サムネイルURL', value: 'picture_url' },
  { text: '動画URL', value: 'video_url' },
  { text: 'リンクタイトル', value: 'link_name' },
  { text: 'リンク説明文', value: 'link_description' },
  { text: 'リンクドメイン', value: 'link_caption' },
  { text: 'リンクURL', value: 'link_link' },
  { text: 'タグ', value: 'category_name' },
  { text: 'インプレッション', value: 'impressions', tooltip: '' },
  { text: 'リポスト', value: 'retweets', tooltip: '' },
  { text: '引用', value: 'quotes', tooltip: '' },
  { text: 'いいね', value: 'favorites', tooltip: '' },
  { text: '返信', value: 'replies', tooltip: '' },
  { text: '反応数', value: 'reactions', tooltip: '' },
  { text: '反応率', value: 'reactions_rate', tooltip: '' },
  { text: '公開日のフォロワー数', value: 'followers_at_that_time', tooltip: '' },
  { text: '対象', value: 'account' },
  { text: 'アカウント名', value: 'account_name' },
  { text: 'プロフィール画像', value: 'account_img' }
]

export const POST_TABLE_SORT_BLACKLIST = [
  'account_id',
  'post_id',
  'created_time',
  'picture_url',
  'video_url',
  'message',
  'type',
  'tags'
]

export const CHART_POSITIONING_MAP_POST_DATA_METRICS = [
  { text: '投稿数', value: 'post_count', tooltip: '' },
  { text: 'インプレッション', value: 'impressions', tooltip: '' },
  { text: '反応率', value: 'reactions_rate', tooltip: '' },
  { text: '反応数', value: 'reactions', tooltip: '' },
  { text: 'リポスト', value: 'retweets', tooltip: '' },
  { text: '引用', value: 'quotes', tooltip: '' },
  { text: 'いいね', value: 'favorites', tooltip: '' },
  { text: '返信', value: 'replies', tooltip: '' }
]

export const CHART_POSITIONING_MAP_DAILY_DATA_METRICS = [
  { text: 'フォロワー数(累計)', value: 'followers_count', tooltip: '' },
  { text: 'フォロワー数(増減)', value: 'followers_count_up_down', tooltip: '' }
]

export const POST_TABLE_CHARACTER_COUNT_METRICS = [
  { text: '文字数レンジ', value: 'character_range' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS,
  ...POST_TABLE_ACCOUNT_METRIC
]

export const CHARACTER_RANGE = [
  {
    title: '10文字未満',
    greater_than: 0,
    less_than: 10
  },
  {
    title: '10文字以上 - 50文字未満',
    greater_than: 10,
    less_than: 50
  },
  {
    title: '50文字以上 - 100文字未満',
    greater_than: 50,
    less_than: 100
  },
  {
    title: '100文字以上 - 200文字未満',
    greater_than: 100,
    less_than: 200
  },
  {
    title: '200文字以上',
    greater_than: 200,
    less_than: null
  }
]
