import moment from 'moment-timezone'
import qs from 'qs'
import { Component, Prop, Vue } from 'vue-property-decorator'

import * as calculation from '@/client/components-old/_utils/calculation'
import event from '@/client/utils/event'
import i18n from '@/client/utils/i18n'

type TChartVerticalOptions = {
  y_label: string
  display_value: boolean
  display_legend: boolean
  display_line: boolean
  display_average: boolean
  chart_type: 'column' | 'line' | 'spline' | 'area'
  categories: string[]
  colors: string[]
  series: any[]
}

type TChartVerticalInterval =
  | '1_day'
  | '7_day'
  | '14_day'
  | '28_day'
  | '1_month'
  | '3_month'
  | '6_month'
  | 'weekday'
  | 'hour'
  | '1_hour'

@Component({
  name: 'ChartVertical'
})
export default class ChartVertical extends Vue {
  @Prop({ type: Object, required: true })
  options!: TChartVerticalOptions

  @Prop({ type: String, required: true })
  startDate!: string

  @Prop({ type: String, required: true })
  endDate!: string

  @Prop({ type: String, default: '1_month' })
  interval!: TChartVerticalInterval

  @Prop({ type: Array, default: () => null })
  postsDate!: string[]

  is_show = false

  async mounted() {
    this.is_show = false
    await this.$nextTick()
    await event.delay(0)
    this.is_show = true
  }

  get line_marker() {
    return this.options.series.length <= 1 || this.options.categories.length <= 1
  }

  get margin_bottom() {
    switch (this.interval) {
      case '7_day':
      case '14_day':
      case '28_day':
        return 70
      default:
        return 45
    }
  }

  get plot_bands() {
    const pointStart = moment.utc(this.startDate).unix() * 1000
    const pointEnd = moment.utc(this.endDate).unix() * 1000

    // 1日のミリ秒
    const msPerOneDay = 24 * 3600 * 1000
    const plot_bands = []

    // 選択期間分繰り返す
    for (let i = pointStart; i <= pointEnd; i = i + msPerOneDay) {
      const day = new Date(i).getDay()

      // 週末の場合、背景色をセット
      if (day === 6 || day === 0) {
        const weekend = {
          from: i - msPerOneDay / 2,
          to: i + msPerOneDay / 2,
          color: day === 6 ? '#f0f8ff' : '#fff0f8'
        }

        plot_bands.push(weekend)
      }
    }
    return plot_bands
  }

  get x_axis() {
    switch (this.interval) {
      case '1_day': {
        const self = this
        return {
          gridLineColor: '#DDDDDD',
          labels: {
            useHTML: true,
            formatter: function () {
              const tickPositions: number[] = this.axis.tickPositions

              // ラベル数
              const pointCount = tickPositions.length
              // 対象ラベルの位置
              const pointIndex = tickPositions.indexOf(this.value)
              // グラプの横軸に表示するラベルの値
              const label = moment.unix(this.value / 1000).format('M/D')

              let html = '<div style="position: relative"><div>'

              const post_pointer_style =
                'position: absolute; left: 5px; width: 8px; height: 8px; left: -5px; border-radius: 4px; background-color: #4ba5b0;'

              const date_style = 'position: absolute; top: 13px; left: -8px; text-align: center;'

              if (self.show_post_pointer) {
                // 対象日が投稿があるか確認
                const has_post = self.postsDate.find(post_date => {
                  return moment.utc(post_date).unix() === this.value / 1000
                })

                if (has_post) {
                  html += `<div style="${post_pointer_style}"></div>`
                }
              }

              // ラベル数よってラベルを表示する場所を変える
              // ラベル数が15以下の場合、全て表示
              if (pointCount < 15) {
                html += `<div style="${date_style}">` + label + '</div></div></div>'
                return html
              }

              // ラベル数が15-40以内の場合、2つおきにラベルを表示
              if (15 <= pointCount && pointCount < 40 && pointIndex % 2 === 0) {
                html += `<div style="${date_style}">` + label + '</div></div></div>'
                return html
              }

              // ラベル数が40-150以内の場合、７つおきにラベルを表示
              if (40 <= pointCount && pointCount < 150 && pointIndex % 7 === 0) {
                html += `<div style="${date_style}">` + label + '</div></div></div>'
                return html
              }

              // ラベル数が150以上の場合、14つおきにラベルを表示
              if (150 <= pointCount && pointIndex % 14 === 7) {
                html += `<div style="${date_style}">` + label + '</div></div></div>'
                return html
              }

              html += '</div></div>'
              return html
            }
          },
          type: 'datetime',
          tickmarkPlacement: 'between',
          tickInterval: 24 * 3600 * 1000,
          plotBands: this.plot_bands
        }
      }
      case '1_hour': {
        const tick_interval = Math.ceil(this.options.categories.length / 10)

        return {
          gridLineColor: '#DDDDDD',
          categories: this.options.categories,
          tickInterval: tick_interval,
          labels: {
            formatter: function () {
              return moment(this.value, 'YYYY/MM/DD HH:mm').format('M/D HH:mm')
            }
          }
        }
      }
      case 'hour':
        return {
          gridLineColor: '#DDDDDD',
          categories: this.options.categories,
          tickInterval: 3
        }
      default:
        return {
          gridLineColor: '#DDDDDD',
          categories: this.options.categories
        }
    }
  }

  get line_width() {
    if (this.options.display_line) {
      return 1
    }

    return 0
  }

  get show_post_pointer() {
    // 日数が日付の差を1日プラス
    const dayCount = moment(this.endDate).diff(moment(this.startDate), 'days') + 1

    if (this.postsDate && this.interval === '1_day' && dayCount <= 31) {
      return true
    }

    return false
  }

  get plot_lines() {
    if (!this.options.display_average) return []

    let numerator = 0
    let denominator = 0

    this.options.series.forEach(series => {
      numerator += calculation.addition(series.data)
      denominator = series.data.length
    })

    const average = calculation.division(numerator, denominator, 1)

    return [
      {
        value: average,
        color: '#939393',
        dashStyle: 'ShortDash',
        width: 2,
        zIndex: 1
      }
    ]
  }

  get series() {
    const series = this.options.series.map(target => {
      // 集計間隔が1日の場合
      if (this.interval === '1_day') {
        target.pointInterval = 24 * 3600 * 1000
        target.pointStart = moment.utc(this.startDate).unix() * 1000
      }

      return target
    })

    if (this.options.display_average) {
      series.push({
        type: 'spline',
        dashStyle: 'LongDash',
        name: '平均',
        marker: {
          symbol: `url(${require('@/client/assets/images/icon_shortdash.png')})`
        },
        index: -1
      })
    }

    if (this.show_post_pointer) {
      series.push({
        type: 'scatter',
        dashStyle: 'LongDash',
        name: i18n.t('投稿'),
        color: '#4ba5b0',
        marker: {
          symbol: 'circle'
        },
        index: -2
      })
    }

    return series
  }

  get chart_options() {
    return {
      chart: {
        animation: false,
        type: this.options.chart_type,
        marginRight: 15,
        marginTop: 50,
        marginBottom: this.margin_bottom,
        height: 280
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: this.x_axis,
      yAxis: {
        title: {
          align: 'high',
          offset: -16,
          text: this.options.y_label,
          rotation: 0,
          x: 10,
          y: -30
        },
        lineWidth: this.line_width,
        minTickInterval: 1,
        stackLabels: {
          enabled: this.options.display_value,
          formatter: function () {
            return this.total.toLocaleString()
          },
          style: {
            fontWeight: 'bold',
            color: 'gray'
          }
        },
        labels: {
          align: 'left',
          x: 5,
          y: -5
        },
        plotLines: this.plot_lines
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        x: 5,
        y: -8,
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderRadius: 0,
        borderWidth: 1,
        shadow: false,
        symbolRadius: 0,
        reversed: true,
        enabled: this.options.display_legend,
        maxWidth: 800
      },
      tooltip: {
        shared: true,
        useHTML: true,
        borderColor: '#b5b5b5',
        hideDelay: 1500,
        style: {
          fontSize: '13px',
          pointerEvents: 'auto'
        },
        formatter: function () {
          let title = this.x
          if (typeof title === 'number') {
            title = moment.unix(title / 1000).format('YYYY/MM/DD dddd')
          }

          let total = 0
          let is_show_time = false
          let link_url = ''
          let link_title = ''

          this.points.forEach(point => {
            total += point.y

            if (point.series.options.metric === 'view_second_average') {
              is_show_time = true
            }

            if (point.series.options.link_url) {
              link_url = point.series.options.link_url
            }

            if (point.series.options.link_title) {
              link_title = point.series.options.link_title
            }

            if (point.series.options.twitter_search) {
              let since = ''
              let until = ''

              switch (point.series.options.interval) {
                case 'hour':
                  since = moment(this.x, 'YYYY/MM/DD HH:mm').format('YYYY-MM-DD_HH:mm:ss')
                  until = moment(this.x, 'YYYY/MM/DD HH:mm')
                    .endOf('hour')
                    .format('YYYY-MM-DD_HH:mm:ss')
                  break
                case 'day':
                  since = moment
                    .unix(this.x / 1000)
                    .startOf('day')
                    .format('YYYY-MM-DD_HH:mm:ss')
                  until = moment
                    .unix(this.x / 1000)
                    .endOf('day')
                    .format('YYYY-MM-DD_HH:mm:ss')
                  break
                case 'week': {
                  const range = this.x.split('-')
                  const current_year = moment().year()
                  since = moment(`${current_year}/${range[0]}`, 'YYYY/MM/DD')
                    .startOf('day')
                    .format('YYYY-MM-DD_HH:mm:ss')
                  until = moment(`${current_year}/${range[1]}`, 'YYYY/MM/DD')
                    .endOf('day')
                    .format('YYYY-MM-DD_HH:mm:ss')
                  break
                }
                case 'month': {
                  const date = moment(this.x, 'MMM')
                  since = moment(date).startOf('month').format('YYYY-MM-DD_HH:mm:ss')
                  until = moment(date).endOf('month').format('YYYY-MM-DD_HH:mm:ss')
                }
              }

              const query = qs.stringify({
                q: `${link_url} since:${since}_JST until:${until}_JST`,
                f: 'live'
              })

              link_url = `https://x.com/search?${query}`
            }
          })

          const total_show = is_show_time ? calculation.secondToTime(total) : total.toLocaleString()

          let tooltip = `
            <div style="font-size: 10px; margin-bottom: 5px;">${title}</div>
            <div style="font-size: 14px; display: flex; flex-direction: column; gap: 4px;">
              <span style="font-weight: bold;">${total_show}</span>
            </div>
          `

          if (link_url && link_title) {
            tooltip = `
              <div style="font-size: 10px; margin-bottom: 5px;">${title}</div>
              <div style="font-size: 14px; display: flex; flex-direction: column; gap: 4px;">
                <span style="font-weight: bold;">${total_show}</span>
                <a href="${link_url}" target="_blank" rel="noopener noreferrer" style="text-decoration: none; display: flex; align-items: center;"><i class="ci-open-in-new"></i><span style="margin-left: 4px;">${link_title}</span></a>
              </div>
            `
          }

          if (this.points.length === 1) {
            return tooltip
          }

          this.points.forEach(point => {
            let value = point.y.toLocaleString()

            if (point.series.options.metric === 'view_second_average') {
              value = calculation.secondToTime(point.y)
            }

            tooltip += `<span style="color:${point.color}">●</span> ${point.series.name}: <b>${value}</b><br/>`
          })

          return tooltip
        }
      },
      colors: this.options.colors,
      plotOptions: {
        series: {
          connectNulls: true,
          animation: false
        },
        area: {
          marker: {
            enabled: false
          }
        },
        column: {
          negativeColor: '#ed5565',
          stacking: 'normal'
        },
        line: {
          marker: {
            enabled: this.line_marker
          }
        }
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      series: this.series
    }
  }
}
