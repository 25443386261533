/** User access is restricted */
export const ERROR_CODE_25 = 25

/** Missing or invalid image file */
export const ERROR_CODE_324 = 324

/** The video format is not supported */
export const ERROR_CODE_352 = 352

/** The image is too large */
export const ERROR_CODE_36000 = 36000

/** Aspect ratio is not supported */
export const ERROR_CODE_36003 = 36003
