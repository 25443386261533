import { GridItem, GridLayout } from 'vue-grid-layout'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { MediaPreviewDialog } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/instagram_analytics'
import Box from '@/client/components-old/atoms/Box'
import Button from '@/client/components-old/atoms/Button'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Legend from '@/client/components-old/atoms/Legend'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import InstagramAnalyticsChartVertical from '@/client/components-old/organisms/InstagramAnalyticsChartVertical'
import InstagramAnalyticsGoal from '@/client/components-old/organisms/InstagramAnalyticsGoal'
import InstagramAnalyticsRanking from '@/client/components-old/organisms/InstagramAnalyticsRanking'
import InstagramAnalyticsStoriesRanking from '@/client/components-old/organisms/InstagramAnalyticsStoriesRanking'
import InstagramAnalyticsTotal from '@/client/components-old/organisms/InstagramAnalyticsTotal'
import InstagramAnalyticsTotalTable from '@/client/components-old/organisms/InstagramAnalyticsTotalTable'
import InstagramAnalyticsWidgetDialog from '@/client/components-old/organisms/InstagramAnalyticsWidgetDialog'
import SnsEmbedDialog from '@/client/components-old/organisms/SnsEmbedDialog'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as IInstagramState } from '@/client/store/modules/instagram_analytics'

const instagram = namespace('instagram_analytics')
const notification = namespace('notification')

@Component({
  name: 'InstagramAnalyticsSummary',
  components: {
    GridLayout,
    GridItem,
    InstagramAnalyticsChartVertical,
    InstagramAnalyticsRanking,
    InstagramAnalyticsStoriesRanking,
    InstagramAnalyticsTotal,
    InstagramAnalyticsGoal,
    InstagramAnalyticsTotalTable,
    InstagramAnalyticsWidgetDialog,
    SnsEmbedDialog,
    MediaPreviewDialog,
    Legend,
    Box,
    Button,
    Flex,
    Icon,
    Message,
    Scroll
  }
})
export default class InstagramAnalyticsSummary extends Vue {
  @State('project_role') project_role!: IRootState['project_role']
  @instagram.State('api_widget_settings') widgetSettings!: IInstagramState['api_widget_settings']
  @instagram.Action('deleteWidgetSettings') deleteWidgetSettings!: any
  @instagram.Action('postWidgetSettingsLayouts') postWidgetSettingsLayouts!: any
  @notification.Action('showNotification') showNotification!: any

  $refs!: {
    InstagramAnalyticsWidgetDialog: any
    SnsEmbedDialog: any
    MediaPreviewDialog: any
  }

  is_edit = false
  grid_layout: any[] = []

  get is_role() {
    return this.project_role.role_analytics_setting
  }

  get legend_data() {
    return constants.IN_DATA_TYPES.map(type => ({ text: type.text, color: type.color }))
  }

  @Watch('widgetSettings', { immediate: true, deep: true })
  changeApiWidgetSettings() {
    // レイアウトの同期
    this.grid_layout = this.widgetSettings.map(widget => ({
      id: widget.id,
      options: widget.options,
      name: this.getWidgetName(widget.type),
      minW: 3,
      maxW: 12,
      minH: this.getGridItemMinH(widget.type),
      maxH: this.getGridItemMaxH(widget.type),
      i: widget.layout_index,
      x: widget.layout_x,
      y: widget.layout_y,
      w: widget.layout_w,
      h: widget.layout_h
    }))
  }

  /**
   * ウィジェットの名前取得
   * @param {string} type
   * @returns {string} Vueコンポーネントの名前
   */
  getWidgetName(type: string): string {
    switch (type) {
      case 'total':
        return 'InstagramAnalyticsTotal'
      case 'chart':
        return 'InstagramAnalyticsChartVertical'
      case 'post_ranking':
        return 'InstagramAnalyticsRanking'
      case 'stories_ranking':
        return 'InstagramAnalyticsStoriesRanking'
      case 'goal':
        return 'InstagramAnalyticsGoal'
      case 'table':
        return 'InstagramAnalyticsTotalTable'
      default:
        return ''
    }
  }

  /**
   * グリッドアイテムの最小の高さを取得
   * @param {string} type
   * @returns {number} グリッドの最小の高さ
   */
  getGridItemMinH(type: string): number {
    switch (type) {
      case 'total':
        return 2
      case 'chart':
        return 4
      case 'post_ranking':
      case 'stories_ranking':
        return 6
      case 'goal':
        return 2
      case 'table':
        return 4
      default:
        return 2
    }
  }

  /**
   * グリッドアイテムの最大の高さを取得
   * @param {string} type
   * @returns {number} グリッドの最大の高さ
   */
  getGridItemMaxH(type: string): number {
    switch (type) {
      case 'total':
        return 2
      case 'chart':
        return 4
      case 'post_ranking':
      case 'stories_ranking':
        return 8
      case 'goal':
        return 2
      case 'table':
        return 4
      default:
        return 12
    }
  }

  /**
   * レイアウト編集モードの切り替え
   * @returns {void}
   */
  toggleIsEdit(): void {
    this.is_edit = !this.is_edit

    if (this.is_edit) {
      TrackingService.sendEvent('click:自社分析(IG)>サマリー|タブ内ヘッダー|レイアウトの編集')
    }
  }

  /**
   * ウィジェットダイアログを開く
   */
  openInstagramAnalyticsWidgetDialog() {
    this.$refs.InstagramAnalyticsWidgetDialog.open()

    TrackingService.sendEvent('click:自社分析(IG)>サマリー|タブ内ヘッダー|コンポーネントの追加')
  }

  /**
   * ウィジェットの削除
   * @param {number} widget_id
   * @returns {void}
   */
  async deleteWidget(widget_id: number): Promise<void> {
    const confirm = window.confirm(
      this.$options.filters?.translate('コンポーネントを削除してよろしいですか？')
    )

    if (!confirm) return

    const response = await this.deleteWidgetSettings({ id: widget_id })

    let payload

    if (response.error && response.error.type === 'NOT_EXISTS') {
      payload = { title: '対象のコンポーネントはすでに削除されています。', type: 'error' }
    } else if (!response.data) {
      const text_01 = this.$options.filters?.translate('コンポーネントの削除に失敗しました。')
      const text_02 = this.$options.filters?.translate(
        '恐れ入りますが、時間をおいて再度お試しください。'
      )
      payload = { title: `${text_01}<br>${text_02}`, type: 'error' }
    } else {
      payload = { title: 'コンポーネントを削除しました。' }
    }

    this.showNotification(payload)
  }

  /**
   * ウィジェットレイアウトの更新
   * @param {any} new_layout
   * @returns {void}
   */
  async updateWidgets(new_layout: any): Promise<void> {
    const response = await this.postWidgetSettingsLayouts({ layouts: new_layout })

    let payload

    if (!response.data) {
      payload = { title: 'レイアウトの保存に失敗しました。', type: 'error' }
    } else {
      payload = { title: 'レイアウトを保存しました。' }
    }

    this.showNotification(payload)
  }
}
