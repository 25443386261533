import { Component, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import PersonalSettingFacebookAuth from '@/client/components-old/organisms/PersonalSettingFacebookAuth'
import PersonalSettingProfile from '@/client/components-old/organisms/PersonalSettingProfile'
import PersonalSettingTwoFactor from '@/client/components-old/organisms/PersonalSettingTwoFactor'

@Component({
  name: 'PersonalSettingUser',
  components: {
    Scroll,
    PersonalSettingProfile,
    PersonalSettingFacebookAuth,
    PersonalSettingTwoFactor
  }
})
export default class PersonalSettingUser extends Vue {}
