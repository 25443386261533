import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import * as constants from '@/client/components-old/_constants/twitter_keywords'
import Icon from '@/client/components-old/atoms/Icon'
import Label from '@/client/components-old/atoms/Label'
import { NO_IMAGE_URL } from '@/client/constants/media'

type PostType =
  | 'status'
  | 'photo'
  | 'link'
  | 'video'
  | 'reels'
  | 'shared'
  | 'text'
  | 'animation-gif'
  | 'image'
  | 'carousel'
  | 'none'

type TweetType = 'tweet' | 'quote_tweet' | 'reply' | 'mention'

type PostMediaGridOption = {
  keyword_name: string
  screen_name: string
  account_id: string
  post_id: string
  post_type: PostType
  tweet_type: TweetType
  post_url: string
  thumbnail_url: string | null
  thumbnail_count: number
  timestamp: string
  is_top_media: boolean
  main_metric: {
    key: string
    value: string | number
  }
  details: {
    key: string
    value: number
  }[]
}

@Component({
  name: 'PostMediaGrid',
  components: {
    Icon,
    Label
  }
})
export default class PostMediaGrid extends Vue {
  @Prop({ type: Object, required: true })
  option: PostMediaGridOption

  get background() {
    const url = this.option.thumbnail_url ? this.option.thumbnail_url : NO_IMAGE_URL

    return { backgroundImage: `url(${url})` }
  }

  get timestamp() {
    return moment(this.option.timestamp).format('lll')
  }

  get icon_name() {
    switch (this.option.post_type) {
      case 'status':
      case 'text':
        return 'format-text'
      case 'photo':
      case 'image':
        return 'photo-camera'
      case 'animation-gif':
        return 'gif'
      case 'video':
        return 'videocam'
      case 'link':
        return 'link'
      case 'shared':
        return 'forward'
      case 'carousel':
        return 'perm-media'
      case 'reels':
        return 'ig-reel'
      default:
        return ''
    }
  }

  get is_thumbnail_count() {
    return this.option.thumbnail_count > 1
  }

  get tweet_type_icon() {
    switch (this.option.tweet_type) {
      case 'quote_tweet':
        return 'quote-tweet'
      case 'reply':
        return 'reply'
      case 'mention':
        return 'at'
      default:
        return ''
    }
  }

  get display_metric() {
    if (!this.option.main_metric || !this.option.main_metric.value) {
      return 0
    }

    if (this.option.main_metric.key === constants.TIMESTAMP.value) {
      return this.$options.filters.date(this.option.main_metric.value, 'lll')
    }

    return this.option.main_metric.value.toLocaleString()
  }

  get is_timestamp_order() {
    if (!this.option.main_metric) {
      return false
    }

    return this.option.main_metric.key === constants.TIMESTAMP.value
  }

  @Emit('click')
  clickPostMedia() {
    return {
      account_id: this.option.account_id,
      post_id: this.option.post_id,
      sns_link: this.option.post_url
    }
  }
}
