import { RouteConfig } from 'vue-router'

import PasswordReset from '../pages/PasswordReset.vue'

export const password_reset: RouteConfig = {
  component: PasswordReset,
  path: '/password_reset/:reset_id',
  name: 'password_reset',
  meta: {
    auth: false,
    title: 'パスワードリセット'
  }
}
