import { RouteConfig } from 'vue-router'

import GroupSetting from '../pages/GroupSetting.vue'

export const setting_group: RouteConfig = {
  component: GroupSetting,
  path: '/setting_group',
  name: 'setting_group',
  meta: {
    auth: true,
    title: 'グループ設定',
    description: 'グループ（契約単位）の各種設定を変更できます。'
  }
}
