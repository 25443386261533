import $ from 'jquery'
import ColorPicker from 'tui-color-picker'
import ImageEditor from 'tui-image-editor'
import { Component, Vue } from 'vue-property-decorator'

import Button from '@/client/components-old/atoms/Button'
import Checkbox from '@/client/components-old/atoms/Checkbox'
import Dialog from '@/client/components-old/atoms/Dialog'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Select from '@/client/components-old/atoms/Select'
import Slider from '@/client/components-old/atoms/Slider'

@Component({
  name: 'ImageEditorDialog',
  components: {
    Button,
    Checkbox,
    Dialog,
    Flex,
    Icon,
    Input,
    Slider,
    Select
  }
})
export default class ImageEditorDialog extends Vue {
  image_url: string

  visible = false
  sub_menu: string | null = null
  image_editor: any

  undo_disable_flg = true
  redo_disable_flg = true

  brush_color_picker: any
  text_color_picker: any
  shape_color_picker: any
  tint_color_picker: any
  multiply_color_picker: any
  blend_color_picker: any

  text_size = 21
  text_color: string

  rotate_range = 0
  active_object_id: number

  line_type = 'FREE_DRAWING'

  brush_range = 12

  shape_type = 'rect'
  shape_color_type = 'fill'
  stroke_range = 12

  gradient_transparency = false
  gradient_transparency_value = 4

  sepia = false
  sepia2: boolean
  sharpen = false
  grayscale = false
  invert = false
  blur = false
  emboss = false

  pixelate = false
  pixelate_value = 4

  brightness = false
  brightness_value = 0

  noise = false
  noise_value = 100

  blend = false
  blend_type = 'add'

  tint = false
  tint_opacity = 0.5

  transparent: number

  multiply = false

  get shape_option() {
    return {
      stroke: '#000000',
      fill: '#ffffff',
      strokeWidth: Number(this.stroke_range)
    }
  }

  get blend_options() {
    return [
      {
        text: this.$options.filters.translate('追加'),
        value: 'add'
      },
      {
        text: this.$options.filters.translate('差の絶対値'),
        value: 'diff'
      },
      {
        text: this.$options.filters.translate('乗算'),
        value: 'multiply'
      },
      {
        text: this.$options.filters.translate('スクリーン'),
        value: 'screen'
      },
      {
        text: this.$options.filters.translate('比較(明)'),
        value: 'lighten'
      },
      {
        text: this.$options.filters.translate('比較(暗)'),
        value: 'darken'
      }
    ]
  }

  get is_crop() {
    return this.sub_menu === 'crop'
  }

  get is_rotate() {
    return this.sub_menu === 'rotate'
  }

  get is_flip() {
    return this.sub_menu === 'flip'
  }

  get is_text() {
    return this.sub_menu === 'text'
  }

  get is_draw() {
    return this.sub_menu === 'draw'
  }

  get is_shape() {
    return this.sub_menu === 'shape'
  }

  get is_filter() {
    return this.sub_menu === 'filter'
  }

  /**
   * ダイアログの表示
   * @param {string} image_url
   * @returns {void}
   */
  open(image_url: string): void {
    this.visible = true
    this.image_url = image_url
  }

  /*
   * Opened dialog
   */
  opened() {
    this.init()
  }

  /**
   * 初期化
   * @returns {void}
   */
  init(): void {
    this.undo_disable_flg = true
    this.redo_disable_flg = true

    this.shape_type = 'rect'
    this.line_type = 'FREE_DRAWING'
    this.shape_color_type = 'fill'

    this.rotate_range = 0
    this.brush_range = 12
    this.stroke_range = 12

    this.text_size = 21
    this.text_color = '#000000'

    this.pixelate_value = 4
    this.brightness_value = 0
    this.noise_value = 100
    this.gradient_transparency_value = 4

    this.blend = false
    this.blend_type = 'add'
    this.tint_opacity = 0.5

    // 画像エディタ
    this.image_editor = new ImageEditor('.image-editor-main', {
      cssMaxWidth: 1000,
      selectionStyle: {
        cornerSize: 20,
        rotatingPointOffset: 70
      }
    })

    // ドローの色を設定
    this.brush_color_picker = ColorPicker.create({
      container: $('#image-brush-color-picker')[0],
      color: '#000000'
    })

    // テキストの色を設定
    this.text_color_picker = ColorPicker.create({
      container: $('#image-text-color-picker')[0],
      color: this.text_color
    })

    // シェイプの色を設定
    this.shape_color_picker = ColorPicker.create({
      container: $('#image-shape-color-picker')[0],
      color: '#000000'
    })

    this.tint_color_picker = ColorPicker.create({
      container: $('#tui-tint-color-picker')[0],
      color: '#000000'
    })

    this.multiply_color_picker = ColorPicker.create({
      container: $('#tui-multiply-color-picker')[0],
      color: '#000000'
    })

    this.blend_color_picker = ColorPicker.create({
      container: $('#tui-blend-color-picker')[0],
      color: '#00FF00'
    })

    // 画像エディタのイベント
    this.image_editor.on({
      undoStackChanged: length => {
        if (length) {
          this.undo_disable_flg = false
        } else {
          this.undo_disable_flg = true
        }
        this.resizeEditor()
      },
      redoStackChanged: length => {
        if (length) {
          this.redo_disable_flg = false
        } else {
          this.redo_disable_flg = true
        }
        this.resizeEditor()
      },
      objectScaled: obj => {
        if (obj.type === 'text') {
          this.text_size = Number(obj.fontSize)
        }
      },
      addText: pos => {
        this.image_editor.addText(this.$options.filters.translate('テキストを入力する'), {
          position: pos.originPosition,
          styles: {
            fill: this.text_color,
            fontSize: this.text_size
          }
        })
      },
      objectActivated: obj => {
        this.active_object_id = obj.id

        if (obj.type === 'rect' || obj.type === 'circle' || obj.type === 'triangle') {
          this.sub_menu = 'shape'

          this.transparent = obj[this.shape_color_type] === 'transparent' ? 1 : 0

          if (!this.transparent) {
            this.shape_color_picker.setColor(obj[this.shape_color_type])
          }
          this.stroke_range = obj.strokeWidth

          this.activateShapeMode()
        } else if (obj.type === 'text') {
          this.sub_menu = 'text'
          this.text_size = Number(obj.fontSize)
          this.text_color_picker.setColor(obj.fill)
          this.activateTextMode()
        }
      }
    })

    this.brush_color_picker.on('selectColor', event => {
      this.image_editor.setBrush({
        color: this.hexToRGBA(event.color, 0.5)
      })
    })

    this.shape_color_picker.on('selectColor', event => {
      if (this.transparent) {
        return
      }

      if (this.shape_color_type === 'stroke') {
        this.image_editor.changeShape(this.active_object_id, {
          stroke: event.color
        })
      } else if (this.shape_color_type === 'fill') {
        this.image_editor.changeShape(this.active_object_id, {
          fill: event.color
        })
      }

      this.image_editor.setDrawingShape(this.shape_type, this.shape_option)
    })

    this.text_color_picker.on('selectColor', event => {
      this.image_editor.changeTextStyle(this.active_object_id, {
        fill: event.color
      })
    })

    this.tint_color_picker.on('selectColor', e => {
      this.applyOrRemoveFilter(this.tint, 'tint', {
        color: e.color
      })
    })

    this.multiply_color_picker.on('selectColor', e => {
      this.applyOrRemoveFilter(this.multiply, 'multiply', {
        color: e.color
      })
    })

    this.blend_color_picker.on('selectColor', e => {
      this.applyOrRemoveFilter(this.blend, 'blend', {
        color: e.color
      })
    })

    // 画像をロード
    this.image_editor.loadImageFromURL(this.image_url + '?' + new Date().getTime(), 'image_editor')

    this.resizeEditor()
  }

  /**
   * 画像リスト追加
   * @returns {void}
   */
  async save(): Promise<void> {
    const base64 = this.image_editor.toDataURL({ format: 'png' })
    const blob = this.base64ToBlob(base64)
    this.$emit('save', blob)
    this.visible = false
    this.sub_menu = null
  }

  /**
   * Base64をBlobに変換
   * @param {string} base64 Base64
   * @returns {Blob} Blob
   */
  base64ToBlob(base64: string): Blob {
    let mime_type = ''

    let raw = base64.replace(/data:(image\/.+);base64,/, (header, imageType) => {
      mime_type = imageType
      return ''
    })

    raw = atob(raw)

    const length = raw.length

    const array = new Uint8Array(length)

    for (let i = 0; i < length; i++) {
      array[i] = raw.charCodeAt(i)
    }

    return new Blob([array], { type: mime_type })
  }

  /**
   * 元に戻す
   * @returns {void}
   */
  undo(): void {
    if (this.undo_disable_flg) {
      return
    }

    this.sub_menu = null
    this.image_editor.undo()
  }

  /**
   * やり直し
   * @returns {void}
   */
  redo(): void {
    if (this.redo_disable_flg) {
      return
    }

    this.sub_menu = null
    this.image_editor.redo()
  }

  /**
   * 変更をリセット
   * @returns {void}
   */
  clearObject(): void {
    this.sub_menu = null
    this.image_editor.clearObjects()
  }

  /**
   * ターゲットを削除
   * @returns {void}
   */
  removeObject(): void {
    this.sub_menu = null
    this.image_editor.stopDrawingMode()

    if (this.active_object_id) {
      this.image_editor.removeObject(this.active_object_id)
    }
  }

  /**
   * 切り抜き設定を行う
   * @returns {void}
   */
  crop(): void {
    this.image_editor.startDrawingMode('CROPPER')
    this.sub_menu = 'crop'
  }

  /**
   * 切り抜きを保存
   * @returns {void}
   */
  cropApply(): void {
    this.image_editor.crop(this.image_editor.getCropzoneRect()).then(() => {
      this.image_editor.stopDrawingMode()
      this.resizeEditor()
    })

    this.sub_menu = null
  }

  /**
   * 反転設定を行う
   * @returns {void}
   */
  flip(): void {
    this.image_editor.stopDrawingMode()
    this.sub_menu = 'flip'
  }

  setFlip(direction: string): void {
    switch (direction) {
      case 'X':
        this.image_editor.flipX()
        break
      case 'Y':
        this.image_editor.flipY()
        break
      default:
        this.image_editor.resetFlip()
    }
  }

  /**
   * 回転設定を行う
   * @returns {void}
   */
  rotate(): void {
    this.image_editor.stopDrawingMode()
    this.sub_menu = 'rotate'
  }

  /**
   * Set rotate
   * @param {number} deg
   * @returns {void}
   */
  setRotate(deg: number): void {
    this.image_editor.rotate(deg)
  }

  /**
   * 回転の範囲を変更
   * @returns {void}
   */
  changeRotateRange(): void {
    if (!this.image_editor) {
      return
    }

    this.image_editor.setAngle(Number(this.rotate_range))
  }

  /**
   * テキスト設定を行う
   * @returns {void}
   */
  text(): void {
    this.sub_menu = 'text'
    this.activateTextMode()
  }

  /**
   * テキストのサイズを変更
   * @returns {void}
   */
  changeTextSize(): void {
    if (!this.image_editor) {
      return
    }

    this.image_editor.changeTextStyle(this.active_object_id, {
      fontSize: this.text_size
    })
  }

  /**
   * テキストのスタイルを変更
   * @param {string} style スタイル
   * @returns {void}
   */
  changeTextStyle(style: string): void {
    if (!this.image_editor) {
      return
    }

    let text_style

    switch (style) {
      case 'bold':
        text_style = { fontWeight: 'bold' }
        break
      case 'italic':
        text_style = { fontStyle: 'italic' }
        break
      case 'underline':
        text_style = { textDecoration: 'underline' }
        break
      case 'left':
      case 'center':
      case 'right':
        text_style = { textAlign: style }
        break
      default:
        text_style = {}
    }

    this.image_editor.changeTextStyle(this.active_object_id, text_style)
  }

  /**
   * Change text mode
   * @returns {void}
   */
  activateTextMode(): void {
    if (this.image_editor.getDrawingMode() !== 'TEXT') {
      this.image_editor.stopDrawingMode()
      this.image_editor.startDrawingMode('TEXT')
    }
  }

  /**
   * ドロー設定を行う
   * @returns {void}
   */
  draw(): void {
    this.image_editor.stopDrawingMode()
    this.sub_menu = 'draw'
    this.changeLineType()
  }

  /**
   * ラインタイプを変更
   * @returns {void}
   */
  changeLineType(): void {
    this.image_editor.stopDrawingMode()

    this.image_editor.startDrawingMode(this.line_type, {
      width: this.brush_range,
      color: this.hexToRGBA(this.brush_color_picker.getColor(), 0.5)
    })
  }

  /**
   * ブラシの幅を変更
   * @returns {void}
   */
  changeBrushRange(): void {
    if (!this.image_editor) {
      return
    }

    this.image_editor.setBrush({ width: this.brush_range })
  }

  /**
   * シェイプ設定を行う
   * @returns {void}
   */
  shape(): void {
    this.sub_menu = 'shape'

    this.image_editor.setDrawingShape(this.shape_type, this.shape_option)
    this.activateShapeMode()
  }

  /**
   * シェイプタイプを行う
   * @returns {void}
   */
  changeShapeType(): void {
    this.image_editor.setDrawingShape(this.shape_type)
  }

  /**
   * 透明を変更
   * @returns {void}
   */
  changeTransparent(): void {
    const color = this.transparent ? 'transparent' : this.shape_color_picker.getColor()

    if (this.shape_color_type === 'stroke') {
      this.image_editor.changeShape(this.active_object_id, {
        stroke: color
      })
    } else if (this.shape_color_type === 'fill') {
      this.image_editor.changeShape(this.active_object_id, {
        fill: color
      })
    }

    this.image_editor.setDrawingShape(this.shape_type, this.shape_option)
  }

  /**
   * ストロークの幅を変更
   * @returns {void}
   */
  changeStrokeRange(): void {
    if (!this.image_editor) {
      return
    }

    this.image_editor.changeShape(this.active_object_id, {
      strokeWidth: this.stroke_range
    })

    this.image_editor.setDrawingShape(this.shape_type, this.shape_option)
  }

  /**
   * シェイプモードをアクティブ
   * @returns {void}
   */
  activateShapeMode(): void {
    if (this.image_editor.getDrawingMode() === 'SHAPE') {
      return
    }

    this.image_editor.stopDrawingMode()
    this.image_editor.startDrawingMode('SHAPE')
  }

  /**
   * フィルタ設定を行う
   * @returns {void}
   */
  filter(): void {
    this.sub_menu = 'filter'

    const filters = [
      'grayscale',
      'invert',
      'sepia',
      'blur',
      'sharpen',
      'emboss',
      'brightness',
      'noise',
      'gradient_transparency',
      'pixelate',
      'tint',
      'multiply',
      'blend'
    ]

    filters.map(filter => {
      switch (filter) {
        case 'gradient_transparency':
          this.gradient_transparency = this.image_editor.hasFilter('gradientTransparency')
          break
        case 'sepia':
          this.sepia = this.image_editor.hasFilter('sepia2')
          break
        default:
          this[filter] = this.image_editor.hasFilter(filter)
      }
    })
  }

  /**
   * Set blend type
   * @param {string} value
   * @returns {void}
   */
  setBlend(value: string): void {
    if (!this.image_editor) {
      return
    }

    this.blend_type = value
    this.changeFilter('blend_type')
  }

  /**
   * フィルタを行う
   * @param {string} type タイプ
   * @returns {void}
   */
  changeFilter(type: string): void {
    switch (type) {
      case 'grayscale':
        this.applyOrRemoveFilter(this.grayscale, 'Grayscale', null)
        break

      case 'invert':
        this.applyOrRemoveFilter(this.invert, 'Invert', null)
        break

      case 'sepia':
        this.applyOrRemoveFilter(this.sepia, 'Sepia2', null)
        break

      case 'blur':
        this.applyOrRemoveFilter(this.blur, 'Blur', null)
        break

      case 'sharpen':
        this.applyOrRemoveFilter(this.sharpen, 'Sharpen', null)
        break

      case 'emboss':
        this.applyOrRemoveFilter(this.emboss, 'Emboss', null)
        break

      case 'brightness':
        this.applyOrRemoveFilter(this.brightness, 'brightness', {
          brightness: Number(this.brightness_value)
        })
        break

      case 'brightness_value':
        this.applyOrRemoveFilter(this.brightness, 'brightness', {
          brightness: Number(this.brightness_value)
        })
        break

      case 'noise':
        this.applyOrRemoveFilter(this.noise, 'noise', {
          noise: Number(this.noise_value)
        })
        break

      case 'noise_value':
        this.applyOrRemoveFilter(this.noise, 'noise', {
          noise: Number(this.noise_value)
        })
        break

      case 'gradient_transparency':
        this.applyOrRemoveFilter(this.gradient_transparency, 'gradientTransparency', {
          threshold: Number(this.gradient_transparency_value)
        })
        break

      case 'gradient_transparency_value':
        this.applyOrRemoveFilter(this.gradient_transparency, 'gradientTransparency', {
          threshold: Number(this.gradient_transparency_value)
        })
        break

      case 'pixelate':
        this.applyOrRemoveFilter(this.pixelate, 'pixelate', {
          blocksize: Number(this.pixelate_value)
        })
        break

      case 'pixelate_value':
        this.applyOrRemoveFilter(this.pixelate, 'pixelate', {
          blocksize: Number(this.pixelate_value)
        })
        break

      case 'tint':
        this.applyOrRemoveFilter(this.tint, 'tint', {
          color: this.tint_color_picker.getColor(),
          opacity: Number(this.tint_opacity)
        })
        break

      case 'tint_opacity':
        this.applyOrRemoveFilter(this.tint, 'tint', {
          opacity: Number(this.tint_opacity)
        })
        break

      case 'multiply':
        this.applyOrRemoveFilter(this.multiply, 'multiply', {
          color: this.multiply_color_picker.getColor()
        })
        break

      case 'blend':
        this.applyOrRemoveFilter(this.blend, 'blend', {
          color: this.blend_color_picker.getColor(),
          mode: this.blend_type
        })
        break

      case 'blend_type':
        this.applyOrRemoveFilter(this.blend, 'blend', {
          mode: this.blend_type
        })
        break
    }
  }

  /**
   * 編集後のエディタリサイズ
   * @returns {void}
   */
  resizeEditor(): void {
    const editor = $('.image-editor-main')
    const container = $('.tui-image-editor-canvas-container')

    editor.height(parseFloat(container.css('max-height')) + 40)
  }

  /**
   * HEXをRGBAに変換
   * @param {string} hex
   * @param {number} alpha
   * @returns {string} RGBA
   */
  hexToRGBA(hex: string, alpha: number): string {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    const a = alpha || 1

    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')'
  }

  /**
   * フィルタ適用・取消
   * @param {boolean} applying
   * @param {string} type
   * @param {any} options
   * @returns {void}
   */
  applyOrRemoveFilter(applying: boolean, type: string, options: any): void {
    if (!this.image_editor) {
      return
    }

    if (applying) {
      this.image_editor.applyFilter(type, options)
    } else {
      this.image_editor.removeFilter(type)
    }
  }

  /**
   * サブメニューを閉じる
   * @returns {void}
   */
  closeSubMenu(): void {
    this.image_editor.stopDrawingMode()
    this.sub_menu = null
  }

  /**
   * ダイアログの非表示
   * @returns {void}
   */
  hide() {
    this.visible = false
    this.sub_menu = null

    this.image_editor.destroy()
    this.brush_color_picker.destroy()
    this.text_color_picker.destroy()
    this.shape_color_picker.destroy()
    this.tint_color_picker.destroy()
    this.multiply_color_picker.destroy()
    this.blend_color_picker.destroy()
  }
}
