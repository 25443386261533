import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import {
  PostListSubItemInstagram,
  PostListSubItemTwitter
} from '@/client/components/organisms/PostList'
import { PostTagsSnsPostSettingDialog } from '@/client/components/organisms/Tags'
import Button from '@/client/components-old/atoms/Button'
import DateTime from '@/client/components-old/atoms/DateTime'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import ApproveFlow from '@/client/components-old/molecules/ApproveFlow'
import CategoryList from '@/client/components-old/molecules/CategoryList'
import LabelFacebookTarget from '@/client/components-old/molecules/LabelFacebookTarget'
import MessagePostStatus from '@/client/components-old/molecules/MessagePostStatus'
import PostMedia from '@/client/components-old/molecules/PostMedia'
import RecentMemo from '@/client/components-old/molecules/RecentMemo'
import PostInstagramUpdateUrlDialog from '@/client/components-old/organisms/PostInstagramUpdateUrlDialog'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import { IState as IPostManagementState } from '@/client/store/modules/post_management'
import { IPost, IPostCategory } from '@/client/utils/api/posts'
import { getSnsName } from '@/client/utils/character'
import i18n from '@/client/utils/i18n'
import { convertHelpMessage } from '@/client/utils/notification/index'
import { convertMessage } from '@/client/utils/regex'
import { SnsType } from '@/common/types'

const accounts = namespace('accounts')
const post_management = namespace('post_management')
const notification = namespace('notification')

@Component({
  name: 'PostListItem',
  components: {
    PostTagsSnsPostSettingDialog,
    Button,
    DateTime,
    Dropdown,
    Flex,
    Icon,
    Panel,
    Tooltip,
    ApproveFlow,
    Account,
    CategoryList,
    LabelFacebookTarget,
    MessagePostStatus,
    PostListSubItemInstagram,
    PostListSubItemTwitter,
    PostMedia,
    RecentMemo,
    PostInstagramUpdateUrlDialog
  }
})
export default class PostListItem extends Vue {
  @State('user') user: IRootState['user']
  @accounts.Getter('post') role_post: IAccountsGetter['post']
  @post_management.State('api_persons') api_persons: IPostManagementState['api_persons']
  @post_management.Action('cancelApprove') cancelApprove
  @post_management.Action('cancelScheduledPost') cancelScheduledPost
  @post_management.Action('checkPostStatus') checkPostStatus
  @post_management.Action('deletePost') deletePost
  @post_management.Action('postRepublish') postRepublish
  @post_management.Action('updateCategories') updateCategories
  @notification.Action('showNotification') showNotification

  @Prop({ type: Object, required: true })
  post: IPost

  $refs: {
    PostTagsSnsPostSettingDialog: any
    PostInstagramUpdateUrlDialog: any
  }

  get panel_type() {
    switch (this.post.status) {
      case 'draft':
        return 'default'
      case 'scheduled':
        return 'info'
      case 'published':
        return 'success'
      case 'failure':
        return 'danger'
      case 'approval':
        return 'warning'
      case 'reject':
        return 'inactive'
    }
  }

  get icon_name() {
    switch (this.post.status) {
      case 'draft':
        return 'save'
      case 'scheduled':
        return 'query-builder'
      case 'published':
        return 'done'
      case 'failure':
        return 'clear'
      case 'approval':
        return 'assignment-late'
      case 'reject':
        return 'assignment-reject'
    }
  }

  get is_approver() {
    const current_step = this.post.post_approval_flow?.current_step ?? 0
    const steps = this.post?.project_post_approval_flow?.project_post_approval_steps ?? []

    return steps.some(v => v.step === current_step && v.user_id === this.user.id)
  }

  get is_disable_cancel_approve() {
    return this.post.post_approval_flow && this.post.post_approval_flow.current_step > 1
  }

  get is_disable_cancel_schedule() {
    if (!this.post) return false

    if (this.post.status !== 'scheduled') return false

    if (!this.post.is_auto_publish) return false

    return (
      this.post.role !== 'admin' ||
      !this.post.action_datetime ||
      moment().isAfter(this.post.action_datetime)
    )
  }

  get is_thumbnail() {
    return this.post.type !== 'text'
  }

  get post_accounts() {
    const fb_posts = this.post.fb_posts.map(post => ({ ...post.account, symbol: 'facebook' }))
    const tw_posts = this.post.tw_posts.map(post => ({ ...post.account, symbol: 'twitter' }))
    const in_posts = this.post.in_posts.map(post => ({ ...post.account, symbol: 'instagram' }))
    const in_stories = this.post.in_stories.map(post => ({ ...post.account, symbol: 'instagram' }))
    const tt_posts = this.post.tt_posts.map(post => ({ ...post.account, symbol: 'tiktok' }))

    return [...fb_posts, ...tw_posts, ...in_posts, ...in_stories, ...tt_posts]
  }

  get can_cancel_approve() {
    return (
      this.post.status === 'approval' &&
      ((this.post.role !== 'no_authority' && !this.is_approver) || this.post.role === 'editor')
    )
  }

  get can_show_approve() {
    return this.post.status === 'approval' && this.post.role === 'admin' && this.is_approver
  }

  get role_admin(): { account_id: string; sns: string }[] {
    const fb_posts = this.post.fb_posts
      ? this.post.fb_posts.map(post => ({ ...post.account, symbol: 'facebook' }))
      : []
    const tw_posts = this.post.tw_posts
      ? this.post.tw_posts.map(post => ({ ...post.account, symbol: 'twitter' }))
      : []
    const in_posts = this.post.in_posts
      ? this.post.in_posts.map(post => ({ ...post.account, symbol: 'instagram' }))
      : []
    const in_stories = this.post.in_stories
      ? this.post.in_stories.map(post => ({ ...post.account, symbol: 'instagram' }))
      : []
    const tt_posts = this.post.tt_posts
      ? this.post.tt_posts.map(post => ({ ...post.account, symbol: 'tiktok' }))
      : []

    const post_accounts = [...fb_posts, ...tw_posts, ...in_posts, ...in_stories, ...tt_posts]

    return post_accounts
      .filter(
        account =>
          !!this.role_post.find(
            role => role.id === account.id && role.sns === account.symbol && role.role === 'admin'
          )
      )
      .map(v => {
        return { account_id: v.id, sns: v.symbol }
      })
  }

  get can_show_edit() {
    return (
      this.post.role !== 'no_authority' &&
      !this.is_published &&
      !this.is_failure &&
      !this.is_scheduled &&
      (this.post.status !== 'approval' ||
        (this.post.status === 'approval' && this.post.role === 'admin'))
    )
  }

  get can_show_cancel() {
    return this.is_scheduled
  }

  get can_publish_instagram() {
    if (!this.is_scheduled) return false

    if (this.post.is_auto_publish) return false

    if (!this.is_instagram_post && !this.is_instagram_story) return false

    return (
      this.post.in_posts?.[0]?.result !== 'success' ||
      this.post.in_stories?.[0]?.result !== 'success'
    )
  }

  get is_instagram_post() {
    return this.post.in_posts.length
  }

  get is_instagram_story() {
    return this.post.in_stories.length
  }

  get is_tiktok_post() {
    return this.post.tt_posts.length
  }

  get is_enable_instagram_url_set() {
    if (this.post.is_auto_publish) return false

    if (this.post.status !== 'published') return false

    if (this.post.in_posts.length) {
      return moment().subtract(30, 'days').isBefore(this.post.in_posts[0].publish_datetime)
    }

    if (this.post.in_stories.length) {
      return moment().subtract(1, 'days').isBefore(this.post.in_stories[0].publish_datetime)
    }

    return false
  }

  get dropdown_options() {
    const options = []

    if (this.is_published) {
      options.push({
        value: 'category_setting',
        text: this.$options.filters?.translate('タグ設定'),
        icon: 'local-offer',
        disabled: true,
        tooltip: '分析機能から公開済み投稿のタグを設定できます。'
      })
    } else if (!this.is_failure) {
      options.push({
        value: 'category_setting',
        text: this.$options.filters?.translate('タグ設定'),
        icon: 'local-offer'
      })
    }

    options.push({
      value: 'copy',
      text: this.$options.filters?.translate('複製'),
      icon: 'my-library-books'
    })

    if (
      this.post.role === 'admin' ||
      (this.post.status === 'draft' && this.post.role === 'editor')
    ) {
      options.push({
        value: 'delete',
        text: this.$options.filters?.translate('投稿を削除'),
        icon: 'delete'
      })
    }

    if (this.is_enable_instagram_url_set) {
      options.push({
        value: 'url_setting',
        text: this.$options.filters?.translate('公開URL設定'),
        icon: 'link'
      })
    }

    return options
  }

  get has_dropdown() {
    return this.dropdown_options.length > 0
  }

  get mobile_dropdown_options() {
    const options = []

    if (this.can_publish_instagram) {
      options.push({
        value: 'release_procedure',
        text: this.$options.filters?.translate('公開手続'),
        icon: 'cloud-upload'
      })
    }

    if (this.can_show_approve) {
      options.unshift({
        value: 'approve',
        text: this.$options.filters?.translate('承認 / 差し戻し'),
        icon: 'assignment-late'
      })
    }

    options.push({
      value: 'preview',
      text: this.$options.filters?.translate('プレビュー'),
      icon: 'play-arrow'
    })

    if (this.can_cancel_approve && !this.is_disable_cancel_approve) {
      options.push({
        value: 'approve_cancel',
        text: this.$options.filters?.translate('申請取消'),
        icon: 'assignment-reject'
      })
    }

    if (this.can_show_edit) {
      options.push({
        value: 'edit',
        text: this.$options.filters?.translate('投稿を編集'),
        icon: 'new-message'
      })
    }

    if (this.is_scheduled && !this.is_disable_cancel_schedule) {
      options.push({
        value: 'post_scheduled_canceled',
        text: this.$options.filters?.translate('予約取消'),
        icon: 'alarm-off'
      })
    }

    if (!this.is_published && !this.is_failure) {
      options.push({
        value: 'category_setting',
        text: this.$options.filters?.translate('タグ設定'),
        icon: 'local-offer'
      })
    }

    options.push({
      value: 'copy',
      text: this.$options.filters?.translate('複製'),
      icon: 'my-library-books'
    })

    if (
      this.post.role === 'admin' ||
      (this.post.status === 'draft' && this.post.role === 'editor')
    ) {
      options.push({
        value: 'delete',
        text: this.$options.filters?.translate('投稿を削除'),
        icon: 'delete'
      })
    }

    if (this.is_enable_instagram_url_set) {
      options.push({
        value: 'url_setting',
        text: this.$options.filters?.translate('公開URL設定'),
        icon: 'link'
      })
    }

    return options
  }

  get message() {
    return convertMessage(this.post.message ?? '')
  }

  get category_names() {
    return this.post.post_categories ? this.post.post_categories.map(category => category.name) : []
  }

  get post_result() {
    const facebook_posts = this.post.fb_posts.map(post => {
      const insight = post.insight

      return {
        type: post.result === 'success' ? 'success' : 'error',
        sns: 'facebook',
        error: post.error,
        account_id: post.account.id,
        account_name: post.account.name,
        account_image_url: post.account.image_url,
        post_url: post.fb_posted_id ? `https://www.facebook.com/${post.fb_posted_id}` : '',
        insight_top: [
          {
            text: 'いいね！',
            value: insight ? insight.likes_insights : 0,
            icon: 'thumbs-up'
          },
          {
            text: 'コメント',
            value: insight ? insight.comments_insights : 0,
            icon: 'message'
          },
          {
            text: 'シェア',
            value: insight ? insight.shares_insights : 0,
            icon: 'export'
          },
          {
            text: '反応率',
            value: insight ? insight.reactions_rate : 0,
            icon: 'engagement'
          }
        ],
        insight_bottom: [
          {
            text: '投稿リーチ',
            value: insight ? insight.post_impressions_unique : 0,
            icon: 'megaphone'
          },
          {
            text: 'エンゲージメント数',
            value: insight ? insight.post_engaged_users : 0,
            icon: 'spellcheck'
          },
          {
            text: 'エンゲージメント率',
            value: insight ? insight.post_engaged_users_rate : 0,
            icon: 'action'
          },
          {
            text: '短縮URLクリック',
            value: insight ? insight.short_url_clicks : 0,
            icon: 'link'
          }
        ]
      }
    })

    const twitter_posts = this.post.tw_posts.map(post => {
      const insight = post.insight

      return {
        type: post.result === 'success' ? 'success' : 'error',
        sns: 'twitter',
        error: post.error,
        account_id: post.account.id,
        account_name: post.account.name,
        account_image_url: post.account.image_url,
        post_url: post.tw_posted_id
          ? `https://x.com/${post.account.id}/status/${post.tw_posted_id}`
          : '',
        insight_top: [
          {
            text: 'リポスト',
            value: insight ? insight.retweets : 0,
            icon: 'retweet'
          },
          {
            text: 'いいね！',
            value: insight ? insight.favorites : 0,
            icon: 'favorite'
          },
          {
            text: '返信',
            value: insight ? insight.replies : 0,
            icon: 'message'
          }
        ],
        insight_bottom: [
          {
            text: '反応率',
            value: insight ? insight.reactions_rate : 0,
            icon: 'engagement'
          },
          {
            text: '短縮URLクリック',
            value: insight ? insight.short_url_clicks : 0,
            icon: 'link'
          }
        ]
      }
    })

    const instagram_posts = this.post.in_posts.map(post => {
      const insight = post.insight

      return {
        type: post.result === 'success' ? 'success' : 'error',
        sns: 'instagram',
        error: post.error,
        account_id: post.account.id,
        account_name: post.account.name,
        account_image_url: post.account.image_url,
        post_url: post.in_posted_id
          ? `https://instagram.com/${post.account.id}/${post.in_posted_id}`
          : '',
        insight_top: [
          {
            text: 'いいね！',
            value: insight ? insight.likes : 0,
            icon: 'favorite'
          },
          {
            text: 'コメント',
            value: insight ? insight.comments : 0,
            icon: 'message'
          },
          {
            text: 'フォロー',
            value: insight ? insight.followers_at_that_time : 0,
            icon: 'message'
          },
          {
            text: '反応率',
            value: insight ? insight.reactions_rate : 0,
            icon: 'engagement'
          }
        ]
      }
    })

    return [...facebook_posts, ...twitter_posts, ...instagram_posts]
  }

  get is_approval() {
    return this.post.status === 'approval' || this.post.status === 'reject'
  }

  get is_published() {
    return this.post.status === 'published'
  }

  get is_failure() {
    return this.post.status === 'failure'
  }

  get is_draft() {
    return this.post.status === 'draft'
  }

  get is_scheduled() {
    return this.post.status === 'scheduled'
  }

  get is_instagram_scheduled() {
    return (
      (this.is_instagram_post || this.is_instagram_story) &&
      !this.post.is_auto_publish &&
      this.is_scheduled
    )
  }

  get is_schedule_post_publishing() {
    if (!this.is_scheduled) return false

    if (!this.post.is_auto_publish) return false

    return !this.post.action_datetime || moment().isAfter(this.post.action_datetime)
  }

  get public_text() {
    if (this.is_scheduled) {
      return '予約'
    }

    return '公開'
  }

  get is_show_publish_text() {
    return this.post.submit_user && (this.is_published || this.is_failure || this.is_scheduled)
  }

  get recent_memo() {
    if (!this.post.recent_memo) return null

    return {
      message: this.post.recent_memo.message,
      created: this.post.recent_memo.created,
      name: this.post.recent_memo.user.name,
      picture_url: this.post.recent_memo.user.picture_url
    }
  }

  get has_twitter_reply() {
    return (
      this.post.tw_posts.length &&
      (this.post.tw_posts[0].twitter_reply?.target_tw_post ||
        this.post.tw_posts[0].twitter_reply?.target_tweet)
    )
  }

  get has_twitter_quote() {
    return (
      this.post.tw_posts.length &&
      (this.post.tw_posts[0].twitter_quote?.target_tw_post ||
        this.post.tw_posts[0].twitter_quote?.target_tweet)
    )
  }

  get has_instagram_first_comment_message() {
    return this.post.in_posts.length && this.post.in_posts[0].first_comment_message
  }

  /**
   * ダイアログを開く
   */
  openApproveDialog(): void {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|承認/差し戻し')

    const options = {
      sns_post_id: this.post.id,
      mode: 'approval',
      tab: 'preview'
    }

    this.$emit('open-dialog', options)
  }

  /**
   * ダイアログを開く
   */
  openPreviewDialog(): void {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|プレビュー')

    const options = {
      sns_post_id: this.post.id,
      mode: 'preview',
      tab: 'preview'
    }

    this.$emit('open-dialog', options)
  }

  /**
   * ダイアログを開く
   */
  openMemoDialog(): void {
    const options = {
      sns_post_id: this.post.id,
      mode: 'preview',
      tab: 'memo'
    }

    this.$emit('open-dialog', options)
  }

  /**
   * ダイアログを開く
   */
  openHistoryDialog(): void {
    const options = {
      sns_post_id: this.post.id,
      mode: 'preview',
      tab: 'history'
    }

    this.$emit('open-dialog', options)
  }

  /**
   * 公開手続ダイアログを開く
   */
  openReleaseDialog() {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|公開手続')

    const options = {
      sns_post_id: this.post.id,
      mode: 'release_procedure',
      tab: 'preview'
    }

    this.$emit('open-dialog', options)
  }

  /**
   * コマンドを実行
   */
  async execCommand(command: string): Promise<void> {
    switch (command) {
      case 'release_procedure':
        this.openReleaseDialog()
        break
      case 'approve':
        this.openApproveDialog()
        break
      case 'preview':
        this.openPreviewDialog()
        break
      case 'copy':
        this.copy()
        break
      case 'edit':
        await this.edit()
        break
      case 'post_scheduled_canceled':
        await this.cancel()
        break
      case 'category_setting':
        this.openSettingCategoryDialog()
        break
      case 'delete':
        await this.delete()
        break
      case 'url_setting':
        this.openInstagramUpdateUrlDialog()
        break
      case 'approve_cancel':
        this.cancelApproval()
        break
    }
  }

  /**
   * 投稿を変更
   */
  async edit(): Promise<void> {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|編集')

    const result = await this.checkPostStatus({
      sns_post_id: this.post.id,
      status: this.post.status
    })

    if (result.data && result.data.status === 'published') {
      this.showNotification({ type: 'error', title: '該当の投稿はすでに公開されています。' })

      return
    }

    if (result.data && result.data.status === 'scheduled') {
      this.showNotification({
        type: 'error',
        title: '該当の投稿はすでに予約されています。',
        message: '編集するには先に予約取り消しをする必要があります。'
      })

      return
    }

    if (result.data && result.data.status === 'approval' && this.post.role === 'editor') {
      if (result.data.post_approval_flow.current_step === 1) {
        this.showNotification({
          type: 'error',
          title: '該当の投稿はすでに承認申請されています。',
          message: '編集する場合は、先に申請取消をしてください。'
        })

        return
      } else if (result.data.post_approval_flow.current_step > 1) {
        this.showNotification({
          type: 'error',
          title: '該当の投稿はすでに承認されているため、変更できません。'
        })

        return
      }
    }

    if (result.data && result.data.status === 'failure') {
      this.showNotification({ type: 'error', title: '該当の投稿はすでに投稿に失敗しています。' })

      return
    }

    if (result.error && result.error.type === 'NOT_EXISTS') {
      this.showNotification({ type: 'error', title: '投稿はすでに削除されました。' })

      return
    }

    this.changeRoute({
      name: 'posts/detail/edit',
      params: { sns_post_id: this.post.id }
    })
  }

  /**
   * 投稿をキャンセル
   */
  async cancel(): Promise<void> {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|予約取消')

    const message = [
      i18n.t('予約がキャンセルされ、投稿が下書き状態となります。'),
      i18n.t('この操作は取り消しできません。'),
      i18n.t('よろしければOKをクリックしてください。')
    ]

    if (!confirm(message.join('\n'))) return

    const result = await this.cancelScheduledPost(this.post.id)

    if (result.data) {
      this.showNotification({ title: '投稿の予約を取り消しました。' })

      return
    }

    if (result.error) {
      if (result.error.type === 'POST_PUBLISHED') {
        this.showNotification({ type: 'error', title: '該当の投稿はすでに公開されています。' })

        return
      }

      if (result.error.type === 'POST_IS_PUBLISHING') {
        this.showNotification({
          type: 'error',
          title: '該当の投稿は公開処理中のため取消できません。'
        })

        return
      }

      if (['POST_DRAFTED', 'POST_APPROVED', 'POST_REJECTED'].includes(result.error.type)) {
        this.showNotification({ type: 'error', title: '該当の投稿はすでに予約取消されています。' })

        return
      }

      if (result.error.type === 'POST_FAILED') {
        this.showNotification({ type: 'error', title: '該当の投稿はすでに投稿に失敗しています。' })

        return
      }

      if (result.error.type === 'NOT_EXISTS') {
        this.showNotification({ type: 'error', title: '投稿はすでに削除されました。' })

        return
      }

      this.showNotification({
        type: 'error',
        title: '投稿の予約取消に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。'
      })
    }
  }

  /**
   * 申請取消処理
   */
  async cancelApproval() {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|申請取消')

    const message = [
      i18n.t('承認申請を取り消すと、下書き状態に戻りますがよろしいですか？'),
      i18n.t('よろしければOKをクリックしてください。')
    ]

    if (!confirm(message.join('\n'))) return

    const result = await this.cancelApprove(this.post.id)

    if (result.data) {
      this.showNotification({ title: '投稿の承認申請を取り消しました。' })

      return
    }

    if (result.error) {
      if (result.error.type === 'POST_PUBLISHED') {
        this.showNotification({ type: 'error', title: '該当の投稿はすでに公開されています。' })

        return
      }

      if (result.error.type === 'POST_DRAFTED') {
        this.showNotification({ type: 'error', title: '該当の投稿はすでに申請取消されています。' })

        return
      }

      if (result.error.type === 'POST_FAILED') {
        this.showNotification({ type: 'error', title: ' 該当の投稿はすでに投稿に失敗しています。' })

        return
      }

      if (result.error.type === 'MAX_APPROVAL_STEP_OVER') {
        this.showNotification({
          type: 'error',
          title: '該当の投稿はすでに承認されているため、申請を取り消しできません。'
        })

        return
      }

      if (result.error.type === 'NOT_EXISTS') {
        this.showNotification({ type: 'error', title: '投稿はすでに削除されました。' })

        return
      }

      if (result.error.type === 'PERMISSION_DENIED') {
        this.showNotification({ type: 'error', title: '申請取消の権限がありません。' })

        return
      }

      this.showNotification({
        type: 'error',
        title: '投稿の申請取消に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。'
      })
    }
  }

  /**
   * 投稿を複製
   */
  copy(): void {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|その他:複製')

    this.changeRoute({
      name: 'posts/detail/copy',
      params: { sns_post_id: this.post.id }
    })
  }

  /**
   * ダイアログを開く
   */
  openSettingCategoryDialog(): void {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|その他:タグ設定')

    if (this.is_published || this.is_failure) return

    this.$refs.PostTagsSnsPostSettingDialog.open({
      sns_post_id: this.post.id,
      category_ids: this.post.post_categories.map(category => category.id)
    })
  }

  /**
   * Instagram投稿の公開のURLのダイアログを開く
   */
  openInstagramUpdateUrlDialog() {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|その他:公開URL設定')

    this.$refs.PostInstagramUpdateUrlDialog.open({
      sns_post_id: this.post.id,
      post_type: this.is_instagram_post ? 'post' : 'story'
    })
  }

  /**
   * タグを設定
   */
  async settingCategory(payload: { categories: IPostCategory[] }): Promise<void> {
    await this.updateCategories({ sns_post_id: this.post.id, categories: payload.categories })
  }

  /**
   * 投稿を削除
   */
  async delete(): Promise<void> {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|その他:投稿を削除')

    let messages: string[] = []

    if (this.is_published) {
      if (this.is_instagram_post || this.is_instagram_story) {
        messages = [
          i18n.t('本サービス上で投稿を削除しても、[[sns]]上の投稿は削除されません。', {
            sns: getSnsName('instagram')
          })
        ]
      } else if (this.is_tiktok_post) {
        messages = [
          i18n.t('本サービス上で投稿を削除しても、[[sns]]上の投稿は削除されません。', {
            sns: getSnsName('tiktok')
          })
        ]
      } else {
        messages = [i18n.t('SNS上に公開した投稿も削除されます。')]
      }
    }

    messages.push(i18n.t('投稿を削除してよろしいですか？'))
    messages.push(i18n.t('この操作は取り消しできません。'))

    const confirm = window.confirm(messages.join('\n'))

    if (!confirm) return

    const result = await this.deletePost({ sns_post_id: this.post.id, status: this.post.status })

    if (result.data) {
      return this.showNotification({ title: '投稿を削除しました。' })
    }

    if (result.error) {
      if (result.error.type === 'NOT_EXISTS') {
        this.showNotification({ type: 'error', title: '投稿はすでに削除されました。' })

        return
      }

      if (result.error.type === 'PERMISSION_DENIED') {
        this.showNotification({ type: 'error', title: '投稿の権限がありません。' })

        return
      }

      if (result.error.type === 'INVALID_TWITTER_QUOTE_TARGET') {
        this.showNotification({
          type: 'error',
          title: '投稿の削除に失敗しました。',
          message:
            '本投稿が、引用元の投稿に選択されています。まずは、他投稿からの引用元の指定を解除してください。'
        })

        return
      }

      if (result.error.type === 'INVALID_TWITTER_REPLY_TARGET') {
        this.showNotification({
          type: 'error',
          title: '投稿の削除に失敗しました。',
          message:
            '本投稿が、返信先の投稿に選択されています。まずは、他投稿からの返信先の指定を解除してください。'
        })

        return
      }
    }

    this.showNotification({ type: 'error', title: '投稿の削除に失敗しました。' })
  }

  /**
   * 再投稿処理
   */
  async republish(account: { account_id: string; sns: SnsType }): Promise<void> {
    const result = await this.postRepublish({
      account_id: account.account_id,
      sns: account.sns,
      post: this.post
    })

    if (result.error) {
      let title = result.error.title
      let message = result.error.message

      if (result.error.type === 'TOKEN_EXPIRED') {
        title = 'アクセストークンが失効しています。'
        message = convertHelpMessage({
          message: 'アクセストークンを更新してください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      }

      this.showNotification({ type: 'error', title, message })

      return
    }

    // ? TikTok投稿のみ外部APIの仕様で公開指示までしか出せないため、公開時のメッセージを変更する
    if (this.is_tiktok_post) {
      this.showNotification({
        title: '投稿を公開します。',
        message: 'TikTokの投稿は約1分ほど公開処理に時間がかかります。'
      })
    } else {
      this.showNotification({ title: '投稿の公開が完了しました。' })
    }
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
