import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import { IState as IPaymentContractState } from '@/client/store/modules/payment_contract'

const payment_contract = namespace('payment_contract')

@Component({
  name: 'PaymentContractAbout',
  components: {
    Icon,
    Panel
  }
})
export default class PaymentContractAbout extends Vue {
  @payment_contract.State('payment_group_id')
  payment_group_id: IPaymentContractState['payment_group_id']

  get is_add() {
    return this.mode === 'add'
  }

  get mode() {
    return this.payment_group_id ? 'contract' : 'add'
  }
}
