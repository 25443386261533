import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Textarea from '@/client/components-old/atoms/Textarea'
import i18n from '@/client/utils/i18n'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

@Component({
  name: 'MonitoringExcludeKeywordDialog',
  components: {
    Button,
    Dialog,
    Textarea
  }
})
export default class MonitoringExcludeKeywordDialog extends Vue {
  @group_setting.Action('createMonitoringExcludeKeyword') createMonitoringExcludeKeyword
  @notification.Action('showNotification') showNotification

  visible = false
  is_submit = false
  keyword_text = ''

  get is_disabled_submit() {
    return !this.keyword_text.trim()
  }

  /**
   * ダイアログを表示
   */
  open(): void {
    this.visible = true
    this.reset()
  }

  /**
   * ダイアログを非表示
   */
  close(): void {
    if (this.keyword_text !== '') {
      const message =
        i18n.t('編集内容が保存されていません。') +
        '\n' +
        i18n.t('ダイアログを閉じてよろしいですか？')

      const is_confirm = confirm(message)

      if (!is_confirm) {
        this.visible = true
        return
      }
    }

    this.visible = false
  }

  /**
   * 初期化
   */
  reset(): void {
    this.is_submit = false
    this.keyword_text = ''
  }

  /**
   * キーワードの追加
   */
  async addKeywords(): Promise<void> {
    if (this.is_submit) return

    const keywords = this.keyword_text
      .trim()
      .split('\n')
      .filter(keyword => keyword)

    this.is_submit = true

    const result = await this.createMonitoringExcludeKeyword({ keywords })

    this.is_submit = false

    if (result.data) {
      this.showNotification({ title: '設定を保存しました。' })

      this.visible = false
    } else {
      this.showNotification({ title: '設定の保存に失敗しました。', type: 'error' })
    }
  }
}
