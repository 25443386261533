
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'ButtonCta'
})
export default class ButtonCta extends Vue {
  @Prop({ type: String, default: 'default' })
  color!: 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'inactive'

  @Prop({ type: String, default: '' })
  type!: '' | 'help' | 'tutorial' | 'demo' | 'addsns' | 'invite'

  @Prop({ type: String, default: '' })
  href!: string

  @Emit('click')
  onClick(event: Event) {
    return event
  }

  get is_help() {
    return this.type === 'help'
  }

  get is_tutorial() {
    return this.type === 'tutorial'
  }

  get is_demo() {
    return this.type === 'demo'
  }

  get is_addsns() {
    return this.type === 'addsns'
  }

  get is_invite() {
    return this.type === 'invite'
  }

  get button_class() {
    return ['button-cta', `type-${this.color}`]
  }
}
