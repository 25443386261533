import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { MemoListItem } from '@/client/components/molecules'
import Button from '@/client/components-old/atoms/Button'
import Message from '@/client/components-old/atoms/Message'
import BadgeMonitoring from '@/client/components-old/molecules/BadgeMonitoring'
import TextareaEmoji from '@/client/components-old/molecules/TextareaEmoji'
import UserMultiSelect from '@/client/components-old/molecules/UserMultiSelect'
import { IState as IMonitoring } from '@/client/store/modules/monitoring'
import { TMonitoring } from '@/client/utils/api/monitorings'

const monitoring = namespace('monitoring')
const notification = namespace('notification')

@Component({
  name: 'MonitoringDetailMemo',
  components: {
    MemoListItem,
    Button,
    Message,
    BadgeMonitoring,
    TextareaEmoji,
    UserMultiSelect
  }
})
export default class MonitoringDetailMemo extends Vue {
  @monitoring.State('api_memos') api_memos: IMonitoring['api_memos']
  @monitoring.State('api_persons') api_persons: IMonitoring['api_persons']
  @monitoring.State('destination_ids') selected_user_ids: IMonitoring['destination_ids']
  @monitoring.State('memo_cache') memo_cache: IMonitoring['memo_cache']
  @monitoring.State('selected_monitoring') selected_monitoring: IMonitoring['selected_monitoring']
  @monitoring.Mutation('SET_DESTINATION_IDS') setDestinationIds
  @monitoring.Action('createMonitoringMemo') createMonitoringMemo
  @monitoring.Action('changeMemoCache') changeMemoCache
  @notification.Action('showNotification') showNotification

  @Prop({ type: Object, required: true })
  monitoring: TMonitoring

  message = ''

  is_submit = false

  get disabled() {
    return !this.message.trim() || this.is_submit
  }

  get destination_ids() {
    return this.selected_user_ids.filter(user_id =>
      this.destination_options.some(v => v.id === user_id)
    )
  }

  get destination_options() {
    return this.api_persons
      .filter(person => {
        switch (this.monitoring.sns) {
          case 'facebook':
            return person.fb_accounts.find(
              account =>
                account.account_id === this.monitoring.account_id &&
                account.role_monitoring !== 'none'
            )
          case 'twitter':
            return person.tw_accounts.find(
              account =>
                account.account_id === this.monitoring.account_id &&
                account.role_monitoring !== 'none'
            )
          case 'instagram':
            return person.in_accounts.find(
              account =>
                account.account_id === this.monitoring.account_id &&
                account.role_monitoring !== 'none'
            )
          default:
            return false
        }
      })
      .map(person => person.user)
  }

  created() {
    if (
      this.memo_cache.monitoring_id === this.monitoring.id &&
      this.memo_cache.sns_type === this.monitoring.sns
    ) {
      this.message = this.memo_cache.message
    }
  }

  /**
   * モニタリングのメモを送信
   */
  async submitMonitoringMemo() {
    let to_persons = ''

    if (this.message && this.destination_ids.length > 0) {
      const persons = this.destination_ids.map(person_id => {
        const person = this.api_persons.find(v => v.user.id === person_id)
        return person ? `@${person.user.name}` : ''
      })

      to_persons = persons.length > 0 ? `${persons.join(' ')}\n` : ''
    }

    const params = {
      monitoring_id: this.selected_monitoring.monitoring_id,
      sns_type: this.selected_monitoring.sns_type,
      message: `${to_persons}${this.message}`,
      destination_ids: this.destination_ids
    }

    this.is_submit = true

    const result = await this.createMonitoringMemo(params)

    this.is_submit = false

    if (result.data && result.data.id) {
      this.showNotification({ title: 'メモを追加しました。' })
      this.message = ''
      return this.updateMemo(result.data)
    }

    this.showNotification({
      title: 'メモの追加に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  async changeMessage(value: string) {
    await this.changeMemoCache({
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      message: value
    })
  }

  @Emit('update-memo')
  updateMemo(payload: any) {
    return payload
  }
}
