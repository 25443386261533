
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

import HelpLink from '@/client/components/molecules/HelpLink'
import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Textarea from '@/client/components-old/atoms/Textarea'
import { getTranslateText as t } from '@/client/utils/filters'

import { toLineFeedString, toStringArray } from './utils'

export default defineComponent({
  name: 'GroupSettingIPAddressRestriction',
  components: {
    Button,
    ButtonLink,
    HelpLink,
    Icon,
    Panel,
    Textarea
  },
  setup() {
    return {
      t
    }
  },
  data() {
    return {
      is_submit: false,
      is_allow_edit: false,
      text_ip_addresses: ''
    }
  },
  computed: {
    ...mapState(['user', 'project'])
  },
  methods: {
    ...mapActions('group_setting', ['updateGroupSettings']),
    ...mapActions('notification', ['showNotification']),

    /**
     * 編集に切り替える
     */
    changeEditMode() {
      this.is_allow_edit = true
      this.text_ip_addresses = toLineFeedString(this.project.allow_ip_addresses)
    },

    /**
     * 編集をキャンセルする
     */
    cancelEditMode() {
      this.is_allow_edit = false
      this.text_ip_addresses = ''
    },

    /**
     * IPアドレス許可一覧の送信
     */
    async submitAllowIPAddress() {
      this.is_submit = true

      const result = await this.updateGroupSettings({
        allow_ip_addresses: toStringArray(this.text_ip_addresses)
      })

      this.is_submit = false

      if (result.data) {
        this.cancelEditMode()

        await this.showNotification({
          title: '設定を保存しました。'
        })
      } else {
        await this.showNotification({
          title: '設定の保存に失敗しました。',
          type: 'error'
        })
      }
    }
  }
})
