export const TYPES = [
  {
    value: 'total',
    text: '集計'
  },
  {
    value: 'chart',
    text: 'グラフ'
  },
  {
    value: 'post_ranking',
    text: '投稿ランキング'
  },
  {
    value: 'goal',
    text: '目標'
  },
  {
    value: 'table',
    text: 'テーブル'
  }
]

export const DATA_TYPES = [
  {
    value: 'daily_data',
    text: '日次データ',
    type: 'info'
  },
  {
    value: 'post_data',
    text: '投稿データ(公開日で集計)',
    type: 'success'
  }
]

export const POST_TYPES = [
  { text: '動画', value: 'video' },
  { text: 'カルーセル', value: 'carousel' }
]

export const UNITS = [
  { text: '期間合計', value: 'total' },
  { text: '1投稿あたり', value: 'average' },
  { text: '主要', value: 'major' },
  { text: '過去1週間', value: 'past_week' }
]

export const INTERVALS = [
  { text: '1日', value: '1_day' },
  { text: '7日', value: '7_day' },
  { text: '14日', value: '14_day' },
  { text: '28日', value: '28_day' },
  { text: '1ヶ月', value: '1_month' },
  { text: '3ヶ月', value: '3_month' },
  { text: '6ヶ月', value: '6_month' }
]

export const POST_RANKING_SORTS = [
  { value: 'ascending', text: 'WORST' },
  { value: 'descending', text: 'BEST' }
]

export const POST_RANKING_LIMITS = [
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 }
]

export const DAILY_DATA_METRICS = [
  { text: 'フォロワー数(累計)', value: 'followers_count', tooltip: '' },
  { text: 'フォロワー数(増減)', value: 'followers_count_up_down', tooltip: '' },
  {
    text: '動画再生数',
    value: 'video_views',
    tooltip: '動画が0秒以上再生された回数です。カルーセル投稿の数値も含みます。'
  },
  { text: 'いいね', value: 'likes', tooltip: '' },
  { text: 'コメント', value: 'comments', tooltip: '' },
  { text: 'シェア', value: 'shares', tooltip: '' },
  { text: '反応数', value: 'reactions', tooltip: 'いいね・コメント・シェアの合計数です。' },
  {
    text: 'エンゲージメント数',
    value: 'engagements',
    tooltip: 'いいね・コメント・シェアの合計数です。'
  },
  { text: 'プロフィールビュー', value: 'profile_views', tooltip: '' },
  { text: '投稿数', value: 'post_count', tooltip: '' }
]

export const POST_DATA_METRICS = [
  { text: 'リーチ', value: 'impressions_unique', tooltip: '' },
  {
    text: '動画再生数',
    value: 'video_views',
    tooltip:
      '動画が0秒以上再生された回数です。内訳は該当するフィードや画面から発生したビューが表示されます。'
  },
  { text: '視聴完了率', value: 'full_video_watched_rate', tooltip: '' },
  { text: '平均視聴秒数', value: 'average_time_watched', tooltip: '' },
  { text: '合計視聴秒数', value: 'total_time_watched', tooltip: '' },
  { text: 'いいね', value: 'likes', tooltip: '' },
  { text: 'コメント', value: 'comments', tooltip: '' },
  { text: 'シェア', value: 'shares', tooltip: '' },
  { text: '反応数', value: 'reactions', tooltip: 'いいね・コメント・シェアの合計数です。' },
  { text: '反応率', value: 'reactions_rate', tooltip: '反応数 ÷ 公開日のフォロワー数です。' },
  {
    text: 'エンゲージメント数',
    value: 'engagements',
    tooltip: 'いいね・コメント・シェアの合計数です。'
  },
  {
    text: 'エンゲージメント率',
    value: 'engagements_rate',
    tooltip: 'エンゲージメント数 ÷ 動画再生数です。'
  },
  { text: '動画の長さ', value: 'video_duration', tooltip: '' }
]

export const FOLLOWER_CHART_DATA_METRICS = [
  {
    text: 'オンラインの時間帯',
    value: 'audience_activity',
    tooltip:
      '過去1週間の各時間帯にTikTokを閲覧していたフォロワーの平均値を1時間ごとに分類した値です。データが存在しない場合は、対象期間を広げてご確認ください。'
  }
]

export const IMPRESSION_SOURCE_CHART_POST_METRICS = [
  {
    text: '動画再生数',
    value: 'impression_sources',
    tooltip:
      '動画が0秒以上再生された回数の推定値です。内訳は該当するフィードや画面から発生したビューが表示されます。'
  }
]

export const CHANGE_CHART_DAILY_DATA_METRICS = [
  ...DAILY_DATA_METRICS,
  ...FOLLOWER_CHART_DATA_METRICS
]

export const CHANGE_CHART_POST_DATA_METRICS = [
  ...POST_DATA_METRICS.filter(v => v.value !== 'video_views'),
  ...IMPRESSION_SOURCE_CHART_POST_METRICS
]

export const POST_TABLE_TOTAL = [
  { text: '公開された投稿', value: 'none' },
  { text: '投稿タイプ', value: 'type' },
  { text: 'ハッシュタグ', value: 'hashtag' },
  { text: 'タグ', value: 'category' },
  { text: '文字数', value: 'character_count' }
]

export const POST_TABLE_TABS = [
  { text: '平均', value: 'average' },
  { text: '合計', value: 'total' }
]

export const POST_TABLE_SORT_BLACKLIST = [
  'account_id',
  'post_id',
  'message',
  'created_time',
  'type',
  'tags'
]

export const POST_TYPE = [
  { text: '動画', value: 'video' },
  { text: 'カルーセル', value: 'carousel' }
]

export const TABLE_COLUMN_METRICS = [
  { text: '動画の長さ', value: 'video_duration' },
  { text: 'リーチ', value: 'impressions_unique' },
  { text: '動画再生数', value: 'video_views' },
  { text: '視聴完了率', value: 'full_video_watched_rate' },
  { text: '平均視聴秒数', value: 'average_time_watched' },
  { text: '合計視聴秒数', value: 'total_time_watched' },
  { text: 'いいね', value: 'likes' },
  { text: 'コメント', value: 'comments' },
  { text: 'シェア', value: 'shares' },
  { text: '反応数', value: 'reactions' },
  { text: '反応率', value: 'reactions_rate' },
  { text: 'エンゲージメント数', value: 'engagements' },
  { text: 'エンゲージメント率', value: 'engagements_rate' },
  { text: '公開日のフォロワー数', value: 'followers_at_that_time' }
]

export const POST_TABLE_METRICS = [
  { text: '公開日時', value: 'created_time' },
  { text: '投稿内容', value: 'message' },
  { text: '投稿タイプ', value: 'type' },
  { text: '投稿ID', value: 'post_id' },
  { text: '投稿URL', value: 'share_url' },
  { text: 'サムネイルURL', value: 'thumbnail_url' },
  { text: 'タグ', value: 'category_name' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_TYPE_METRICS = [
  { text: '投稿タイプ', value: 'post_type' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_HASHTAG_METRICS = [
  { text: 'ハッシュタグ', value: 'hashtag' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_CATEGORY_METRICS = [
  { text: 'タグ', value: 'category' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_CHARACTER_COUNT_METRICS = [
  { text: '文字数レンジ', value: 'character_range' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const CHARACTER_RANGE = [
  {
    title: '10文字未満',
    greater_than: 0,
    less_than: 10
  },
  {
    title: '10文字以上 - 50文字未満',
    greater_than: 10,
    less_than: 50
  },
  {
    title: '50文字以上 - 100文字未満',
    greater_than: 50,
    less_than: 100
  },
  {
    title: '100文字以上 - 200文字未満',
    greater_than: 100,
    less_than: 200
  },
  {
    title: '200文字以上 - 500文字未満',
    greater_than: 200,
    less_than: 500
  },
  {
    title: '500文字以上 - 1,000文字未満',
    greater_than: 500,
    less_than: 1000
  },
  {
    title: '1,000文字以上',
    greater_than: 1000,
    less_than: null
  }
]

export const IMPRESSION_SOURCE_METRICS = [
  { text: 'おすすめ', value: 'For You', color: '#1c84c6' },
  { text: 'フォロー中', value: 'Follow', color: '#ed5565' },
  { text: 'ハッシュタグ', value: 'Hashtag', color: '#2ec881' },
  { text: '楽曲', value: 'Sound', color: '#f8ac59' },
  { text: 'プロフィール', value: 'Personal Profile', color: '#24cbe5' },
  { text: '検索', value: 'Search', color: '#9c27b0' },
  { text: '他ユーザーのプロフィール', value: 'other_profile_vv', color: '#5E5E5E' },
  { text: '不明', value: 'unknown', color: '#BDBDBD' }
]

export const HALF_DOUGHNUT_CHART_DAILY_DATA_METRICS = [
  {
    text: '性別の内訳',
    value: 'audience_genders',
    tooltip: '男性・女性・その他の合計数と内訳です。'
  },
  {
    text: 'エンゲージメント数の内訳',
    value: 'engagements',
    tooltip: 'いいね・コメント・シェアの合計数です。'
  }
]

export const HALF_DOUGHNUT_CHART_POST_DATA_METRICS = [
  {
    text: 'エンゲージメント数の内訳',
    value: 'engagements',
    tooltip: 'いいね・コメント・シェアの合計数です。'
  },
  {
    text: '動画再生数の内訳',
    value: 'impression_sources',
    tooltip:
      '動画が0秒以上再生された回数です。内訳は該当するフィードや画面から発生したビューが表示されます。'
  }
]

// 水平チャートの投稿データ指標オプション
export const HORIZONTAL_CHART_POST_DATA_METRICS = [
  {
    text: '視聴者の位置情報(国別ベスト10)',
    value: 'audience_countries',
    tooltip: '上位10か国の動画視聴者数の国別分布です。フォロワー以外も含まれています。'
  }
]

export const POST_RANKING_MAIN_METRICS = [
  ...POST_DATA_METRICS,
  { text: '公開日時', value: 'created_time', tooltip: '' },
  { text: '公開日のフォロワー数', value: 'followers_at_that_time', tooltip: '' }
]

export const POST_RANKING_SUB_METRICS = [...POST_RANKING_MAIN_METRICS]

export const MAP_CHART_DAILY_DATA_METRICS = [
  {
    text: 'フォロワー数(国)',
    value: 'audience_countries',
    tooltip: '対象期間の最終日時点のフォロワー数の国の内訳です。'
  }
]

// 集計用のフォロワーデータ指標オプション
const FOLLOWERS_TOTAL_DATA_METRICS = [
  { text: '主な国', value: 'audience_countries', tooltip: '' },
  { text: '主な性別', value: 'audience_genders', tooltip: '' },
  {
    text: '主なオンラインの時間帯',
    value: 'audience_activity',
    tooltip: ''
  }
]

const AUDIENCE_TOTAL_POST_METRICS = [
  {
    text: '視聴者の主な国',
    value: 'audience_countries',
    tooltip: '視聴者はフォロワー以外も含まれています。'
  }
]

// 累計の日次データ指標オプション
export const TOTAL_DAILY_DATA_METRICS = [...DAILY_DATA_METRICS, ...FOLLOWERS_TOTAL_DATA_METRICS]

// 累計の投稿データ指標オプション
export const TOTAL_POST_DATA_METRICS = [...POST_DATA_METRICS, ...AUDIENCE_TOTAL_POST_METRICS]

export const CHANGE_CHART_DAILY_HOUR_CATEGORIES = [
  '深夜',
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '正午',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm',
  '深夜'
]
