import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import {
  PostTagsManagementDialog,
  PostTagsSnsAnalyticsSettingDialog
} from '@/client/components/organisms/Tags'
import * as constants from '@/client/components-old/_constants/instagram_hashtags'
import { getSentimentText } from '@/client/components-old/_utils/sentiment_analysis'
import Box from '@/client/components-old/atoms/Box'
import Button from '@/client/components-old/atoms/Button'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Scroll from '@/client/components-old/atoms/Scroll'
import Select from '@/client/components-old/atoms/Select'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import PaginationTable from '@/client/components-old/molecules/PaginationTable'
import Search from '@/client/components-old/molecules/Search'
import InstagramHashtagPostCategoryDialog from '@/client/components-old/organisms/InstagramHashtagPostCategoryDialog'
import InstagramHashtagPostGrid from '@/client/components-old/organisms/InstagramHashtagPostGrid'
import InstagramHashtagPostTable from '@/client/components-old/organisms/InstagramHashtagPostTable'
import SnsEmbedDialog from '@/client/components-old/organisms/SnsEmbedDialog'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as IInstagramHashtagState } from '@/client/store/modules/instagram_hashtag'
import API from '@/client/utils/api'
import {
  IGetInstagramHashtagsPostsParams,
  IGetInstagramHashtagsPostsResponse
} from '@/client/utils/api/instagram_hashtags'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'

const categories = namespace('categories')
const instagram = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagPostLayout',
  components: {
    PostTagsManagementDialog,
    PostTagsSnsAnalyticsSettingDialog,
    Box,
    Button,
    ButtonGroup,
    Icon,
    Message,
    Panel,
    Scroll,
    Select,
    SelectMulti,
    SwitchButton,
    Tooltip,
    PaginationTable,
    Search,
    InstagramHashtagPostGrid,
    InstagramHashtagPostCategoryDialog,
    InstagramHashtagPostTable,
    SnsEmbedDialog
  }
})
export default class InstagramHashtagPostLayout extends Vue {
  @State('project') project: IRootState['project']
  @State('project_role') project_role: IRootState['project_role']

  @categories.State('api_post_categories') categories: ICategoriesState['api_post_categories']
  @categories.Action('fetchCategoryAnalysisPost') deleteCategory

  @instagram.State('hashtag_ids')
  hashtag_ids: IInstagramHashtagState['hashtag_ids']
  @instagram.State('api_settings')
  api_settings: IInstagramHashtagState['api_settings']
  @instagram.State('is_top_media') is_top_media: IInstagramHashtagState['is_top_media']
  @instagram.State('filter_post_types')
  filter_post_types: IInstagramHashtagState['filter_post_types']
  @instagram.State('filter_sentiment')
  filter_sentiment: IInstagramHashtagState['filter_sentiment']
  @instagram.State('filter_category_ids')
  filter_category_ids: IInstagramHashtagState['filter_category_ids']
  @instagram.State('filter_search_keyword')
  filter_search_keyword: IInstagramHashtagState['filter_search_keyword']
  @instagram.State('start_date')
  start_date: IInstagramHashtagState['start_date']
  @instagram.State('end_date')
  end_date: IInstagramHashtagState['end_date']
  @instagram.State('api_posts')
  api_posts: IInstagramHashtagState['api_posts']
  @instagram.State('post_display_count')
  post_display_count: IInstagramHashtagState['post_display_count']
  @instagram.State('post_display_order')
  post_display_order: IInstagramHashtagState['post_display_order']
  @instagram.State('post_display_tab') post_display_tab: IInstagramHashtagState['post_display_tab']
  @instagram.State('post_display_offset')
  post_display_offset: IInstagramHashtagState['post_display_offset']
  @instagram.State('is_posts_loading') is_posts_loading: IInstagramHashtagState['is_posts_loading']

  @instagram.Action('changePostDisplayTab') changePostDisplayTab
  @instagram.Action('changeIsTopMedia') changeIsTopMedia
  @instagram.Action('changePostTypes') changePostTypes
  @instagram.Action('changeSentiment') changeSentiment
  @instagram.Action('changeCategoryIds') changeCategoryIds
  @instagram.Action('changeSearchkeyword') changeSearchkeyword
  @instagram.Action('changePostDisplayCount') changePostDisplayCount
  @instagram.Action('changePostDisplayOrder') changePostDisplayOrder
  @instagram.Action('changePostDisplayOffset') changePostDisplayOffset

  keyword = ''

  table_height: number | null = null

  loading_text: string = i18n.t('データを取得中です。しばらくお待ちください。')

  is_csv_loading = false

  $refs: {
    PostTagsManagementDialog: any
    SnsEmbedDialog: any
    InstagramHashtagPostCategoryDialog: any
  }

  created() {
    this.keyword = this.filter_search_keyword
  }

  @Watch('filter_search_keyword')
  watchFilterSearchKeyword() {
    if (this.keyword !== this.filter_search_keyword) {
      this.keyword = this.filter_search_keyword
    }
  }

  @Emit('change-route')
  openSettingScreen() {
    return {
      name: 'setting_group',
      query: {
        screen_name: 'function',
        function_tab_name: 'analytics'
      }
    }
  }

  get is_max_hashtag() {
    return (
      this.project.keyword_hashtag_current_post_count >= this.project.keyword_hashtag_max_post_count
    )
  }

  get is_admin() {
    return this.project_role.role_admin
  }

  get has_account_expired() {
    const selected_hashtags = this.api_settings.filter(v => this.hashtag_ids.includes(v.id))

    return selected_hashtags.some(v => v.in_account && v.in_account.expired)
  }

  get has_account_expired_message() {
    const selected_hashtags = this.api_settings.filter(v => this.hashtag_ids.includes(v.id))

    const hashtag_names = selected_hashtags
      .filter(v => v.in_account && v.in_account.expired)
      .map(v => v.name)

    return i18n.t(
      `アカウントのアクセストークンが失効しているため、キーワード分類「[[hashtag_names]]」のデータを取得できません。`,
      {
        hashtag_names: hashtag_names.join(',')
      }
    )
  }

  get has_account_deleted() {
    const selected_hashtags = this.api_settings.filter(v => this.hashtag_ids.includes(v.id))

    return selected_hashtags.some(v => v.in_account && v.in_account.deleted)
  }

  get has_account_deleted_message() {
    const selected_hashtags = this.api_settings.filter(v => this.hashtag_ids.includes(v.id))

    const hashtag_names = selected_hashtags
      .filter(v => v.in_account && v.in_account.deleted)
      .map(v => v.name)

    return i18n.t(
      `アカウントが登録解除されているため、キーワード分類「[[hashtag_names]]」のデータを取得できません。`,
      {
        hashtag_names: hashtag_names.join(',')
      }
    )
  }

  get is_table() {
    return this.post_display_tab === 'table'
  }

  get is_grid() {
    return this.post_display_tab === 'grid'
  }

  get post_type_options() {
    return constants.POST_TYPE_OPTIONS.map(type => ({
      text: i18n.t(type.text),
      value: type.value
    }))
  }

  get sentiment_options() {
    return constants.SENTIMENT_OPTIONS.map(type => ({
      text: i18n.t(type.text),
      value: type.value
    }))
  }

  get search_category_options() {
    return this.categories.map(v => ({ text: v.name, value: v.id }))
  }

  get panel_tabs() {
    return constants.POST_LAYOUT_DISPLAY_TYPE.map(type => ({
      text: i18n.t(type.text),
      value: type.value
    }))
  }

  get table_content_height() {
    return this.table_height - 146
  }

  get post_limit_options() {
    return [
      { text: '10', value: 10 },
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ]
  }

  get post_sort_options() {
    return [
      {
        value: `-${constants.TIMESTAMP.value}`,
        text: `${i18n.t(constants.TIMESTAMP.text)} (${i18n.t('新しい順')})`
      },
      {
        value: `-${constants.REACTION_COUNT.value}`,
        text: `${i18n.t(constants.REACTION_COUNT.text)} (${i18n.t('多い順')})`
      },
      {
        value: `-${constants.LIKE_COUNT.value}`,
        text: `${i18n.t(constants.LIKE_COUNT.text)} (${i18n.t('多い順')})`
      },
      {
        value: `-${constants.COMMENT_COUNT.value}`,
        text: `${i18n.t(constants.COMMENT_COUNT.text)} (${i18n.t('多い順')})`
      },
      {
        value: constants.TIMESTAMP.value,
        text: `${i18n.t(constants.TIMESTAMP.text)} (${i18n.t('古い順')})`
      },
      {
        value: constants.REACTION_COUNT.value,
        text: `${i18n.t(constants.REACTION_COUNT.text)} (${i18n.t('少ない順')})`
      },
      {
        value: constants.LIKE_COUNT.value,
        text: `${i18n.t(constants.LIKE_COUNT.text)} (${i18n.t('少ない順')})`
      },
      {
        value: constants.COMMENT_COUNT.value,
        text: `${i18n.t(constants.COMMENT_COUNT.text)} (${i18n.t('少ない順')})`
      }
    ]
  }

  get post_display_page() {
    return Math.ceil(this.post_display_offset / this.post_display_count) + 1
  }

  get pagination_total() {
    // Elasticsearchの仕様上、ページングの表示は最大10000件にする
    return this.api_posts.total > 10000 ? 10000 : this.api_posts.total
  }

  /**
   * CSVダウンロードの確認
   */
  async confirmCsvDownload() {
    let message = ''

    message += i18n.t('投稿一覧の検索結果を全件ダウンロードします。') + '\n'
    message += i18n.t('投稿数が多い場合、ダウンロードに時間がかかることがあります。') + '\n'
    message += i18n.t('CSVダウンロードを開始しますか？') + '\n'

    const confirm = window.confirm(message)

    if (!confirm) return
    await this.downloadCSV()

    if (this.post_display_tab === 'table') {
      TrackingService.sendEvent(`click:クチコミ(IG)>投稿|テーブル:CSVダウンロード`)
    }

    if (this.post_display_tab === 'grid') {
      TrackingService.sendEvent(`click:クチコミ(IG)>投稿|グリッド:CSVダウンロード`)
    }
  }

  /**
   * CSVをダウンロードする
   */
  async downloadCSV() {
    const fields = [
      i18n.t(constants.TIMESTAMP.text),
      i18n.t(constants.POST_ID.text),
      i18n.t(constants.HASHTAG_NAME.text),
      i18n.t(constants.POST_TYPE.text),
      i18n.t(constants.POST_URL.text),
      i18n.t(constants.MESSAGE.text),
      i18n.t(constants.THUMBNAIL_URL.text),
      i18n.t(constants.MEDIA_COUNT.text),
      i18n.t(constants.LIKE_COUNT.text),
      i18n.t(constants.COMMENT_COUNT.text),
      i18n.t(constants.REACTION_COUNT.text),
      i18n.t(constants.IS_TOP_MEDIA.text),
      i18n.t('センチメント'),
      i18n.t(constants.CATEGORY.text)
    ]

    const data = await this.getPostsDataForCsv()

    const csv_data = { fields, data }

    const page_name = i18n.t('Instagramクチコミ分析')
    const component_name = i18n.t('投稿')

    const csv_filename = [page_name, component_name, this.start_date, this.end_date].join('_')

    csv.download(csv_data, csv_filename)
  }

  /**
   * CSV投稿一覧を取得
   */
  async getPostsDataForCsv() {
    this.loading_text = i18n.t(`CSVファイルの作成中です。しばらくお待ちください。`)
    this.is_csv_loading = true
    const limit_count = 1000
    let results = []
    let post_data = []
    let download_count = 0

    const params: IGetInstagramHashtagsPostsParams = {
      project_id: this.project.id,
      hashtag_ids: this.hashtag_ids.join(','),
      start_date: this.start_date,
      end_date: this.end_date,
      is_top_media: this.is_top_media,
      post_types: this.filter_post_types.join(','),
      sentiment: this.filter_sentiment,
      post_category_ids: this.filter_category_ids.join(','),
      keyword: this.filter_search_keyword,
      order: this.post_display_order,
      from: 0,
      size: limit_count,
      is_scroll: true,
      scroll_id: ''
    }

    do {
      const response = await API.get<IGetInstagramHashtagsPostsResponse>(
        'instagram_hashtags/posts',
        {
          params
        }
      )

      post_data = response.data.data.posts.map(result => [
        csv.format(result.timestamp),
        result.post_id,
        this.convertHashtagName(result.hashtag_id),
        result.post_type,
        result.post_url,
        result.message,
        result.thumbnail_url,
        result.media_count,
        result.is_like_hidden ? '-' : result.like_count,
        result.comment_count,
        result.reaction_count,
        result.is_top_media ? i18n.t('人気投稿') : '',
        getSentimentText(result.sentiment),
        result.post_categories.map(category => category.name).join(',')
      ])

      params.scroll_id = response.data.data.scroll_id

      results = results.concat(post_data)

      download_count += post_data.length

      if (this.api_posts.total < 1000) {
        this.getTextLoading(this.api_posts.total, this.api_posts.total)
      } else {
        this.getTextLoading(download_count, this.api_posts.total)
      }
    } while (post_data.length)

    this.is_csv_loading = false
    this.loading_text = i18n.t('データを取得中です。しばらくお待ちください。')
    return results
  }

  /**
   * CSVダウンロード中のテキストを取得する
   */
  getTextLoading(current: number, total: number) {
    this.loading_text = i18n.t(
      `CSVファイルの作成中です。しばらくお待ちください。 [[current]] / [[total]]`,
      {
        current: current.toLocaleString(),
        total: total.toLocaleString()
      }
    )
  }

  /**
   * キーワード分類名を変換
   */
  convertHashtagName(id: number) {
    const hashtags = this.api_settings

    const result = hashtags.find(hashtag => hashtag.id === id)

    return result.name ? result.name : ''
  }

  /**
   * 投稿の表示オフセットの変更
   */
  async changePagination(value: number): Promise<void> {
    await this.changePostDisplayOffset((value - 1) * this.post_display_count)
  }

  /**
   * スクロールの変更
   */
  changeHeight(height: number): void {
    this.table_height = height
  }

  /**
   * タグ変更時にタグフィルタを再設定する
   */
  async editCategory() {
    await this.changeCategoryIds([])
  }

  /**
   * 人気投稿フラグの変更
   */
  async onChangeIsTopMedia(payload: boolean) {
    await this.changeIsTopMedia(payload)

    TrackingService.sendEvent(`switch:クチコミ(IG)>投稿|タブ内ヘッダー|人気投稿`)
  }

  /**
   * 投稿タイプの変更
   */
  async onChangePostTypes(payload: string[]) {
    await this.changePostTypes(payload)

    TrackingService.sendEvent(
      `select:クチコミ(IG)>投稿|タブ内ヘッダー|投稿タイプ:${this.filter_post_types.toString()}`
    )
  }

  /**
   * センチメントの変更
   */
  async onChangeSentiment(payload: string) {
    await this.changeSentiment(payload)

    TrackingService.sendEvent(
      `select:クチコミ(IG)>投稿|タブ内ヘッダー|センチメント:${this.filter_sentiment}`
    )
  }

  /**
   * タグの変更
   */
  async onChangeCategoryIds(payload: number[]) {
    await this.changeCategoryIds(payload)

    TrackingService.sendEvent(`select:クチコミ(IG)>投稿|タブ内ヘッダー|タグ`)
  }

  /**
   * 検索キーワードの変更
   */
  async onChangeSearchKeyword(payload: string) {
    await this.changeSearchkeyword(payload)

    TrackingService.sendEvent(`click:クチコミ(IG)>投稿|タブ内ヘッダー|キーワード検索`)
  }

  /**
   * タグの管理を開く
   */
  onOpenPostTagsManagementDialog() {
    this.$refs.PostTagsManagementDialog.open()

    TrackingService.sendEvent(`click:クチコミ(IG)>投稿|タブ内ヘッダー|タグの管理`)
  }

  /**
   * 投稿の表示タブの変更
   */
  async onChangePostDisplayTab(payload: string) {
    await this.changePostDisplayTab(payload)

    TrackingService.sendEvent(`click:クチコミ(IG)>投稿|表示切替:${payload}`)
  }

  /**
   * SNSのプレビューを開く
   */
  onSnsEmbedDialog(event: any) {
    TrackingService.sendEvent(`click:クチコミ(IG)>投稿|テーブル:プレビュー`)

    this.$refs.SnsEmbedDialog.open(event)
  }

  /**
   * タグ設定を開く
   */
  onInstagramHashtagPostCategoryDialog(event: any) {
    TrackingService.sendEvent(`click:クチコミ(IG)>投稿|テーブル:タグ設定`)

    this.$refs.InstagramHashtagPostCategoryDialog.open(event)
  }

  /**
   * 投稿のソート順序の変更
   */
  async onChangePostDisplayOrder(payload: string) {
    TrackingService.sendEvent(`select:クチコミ(IG)>投稿|グリッド:並び順:${payload}`)

    await this.changePostDisplayOrder(payload)
  }
}
