
import moment from 'moment-timezone'
import { computed, defineComponent, PropType, reactive } from 'vue'

import { AvatarUser } from '@/client/components/atoms'
import Button from '@/client/components-old/atoms/Button'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import Icon from '@/client/components-old/atoms/Icon'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import TextareaEmoji from '@/client/components-old/molecules/TextareaEmoji'
import { useTimeAgo } from '@/client/hooks'
import { getMemoMessage, getTimeFormat as f, getTranslateText as t } from '@/client/utils/filters'

type User = {
  id: number
  name: string
  picture_url: string | null
}

type TypeProps = 'post' | 'monitoring'

type MemoProps = {
  id: number
  message: string
  is_editable: boolean
  is_deleted: boolean
  created: string
  modified: string
  user: User
  to_users: User[]
}

type ToUsersProps = User[]

type State = {
  message: string
  to_user_ids: number[]
  is_edit_mode: boolean
}

export default defineComponent({
  name: 'MemoListItem',
  components: {
    AvatarUser,
    Button,
    Dropdown,
    Icon,
    SelectMulti,
    TextareaEmoji
  },
  props: {
    type: {
      type: String as PropType<TypeProps>,
      default: 'post'
    },
    memo: {
      type: Object as PropType<MemoProps>,
      required: true
    },
    toUsers: {
      type: Array as PropType<ToUsersProps>,
      default: () => []
    }
  },
  setup(props, context) {
    const state = reactive({
      message: '',
      to_user_ids: [],
      is_edit_mode: false
    }) as State

    const { timeago } = useTimeAgo(() => props.memo.created)

    const message = computed(() =>
      props.type === 'post' && props.memo.to_users.length
        ? props.memo.to_users.map(v => '@' + v.name).join(' ') + '\n' + props.memo.message
        : props.memo.message
    )

    const to_users = computed(() =>
      props.toUsers.map(v => ({
        text: v.name,
        value: v.id
      }))
    )

    const dropdown_options = [
      { value: 'update', text: t('編集'), icon: 'new-message' },
      { value: 'remove', text: t('削除'), icon: 'delete' }
    ]

    const is_memo_edited = computed(() => !moment(props.memo.created).isSame(props.memo.modified))

    const is_memo_action = computed(
      () => props.memo.is_editable && !props.memo.is_deleted && !state.is_edit_mode
    )

    const is_memo_error = computed(() => !state.message.trim())

    function changeIsEditMode(value: boolean): void {
      state.is_edit_mode = value

      if (value) {
        state.message = props.memo.message
        state.to_user_ids = props.memo.to_users.map(v => v.id)
      }
    }

    function updateMemo(): void {
      if (!state.message.trim()) return

      context.emit('update', {
        id: props.memo.id,
        message: state.message.trim(),
        to_user_ids: state.to_user_ids
      })

      changeIsEditMode(false)
    }

    function removeMemo(): void {
      const message =
        t('メモを削除してよろしいですか？') + '\n' + t('この操作は取り消しできません。')

      if (confirm(message)) {
        context.emit('remove', props.memo.id)
      }
    }

    function changeDropdown(command: 'update' | 'remove'): void {
      if (command === 'update') {
        changeIsEditMode(true)
      }

      if (command === 'remove') {
        removeMemo()
      }
    }

    return {
      state,
      timeago,
      message,
      to_users,
      dropdown_options,
      is_memo_edited,
      is_memo_action,
      is_memo_error,
      changeIsEditMode,
      updateMemo,
      changeDropdown,
      getMemoMessage,
      f,
      t
    }
  }
})
