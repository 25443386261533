import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'

type TValue = {
  name: string
  label: string
  value: string
}

@Component({
  name: 'MultiSwitchesRow',
  components: {
    Icon,
    SwitchButton,
    Tooltip
  }
})
export default class MultiSwitchesRow extends Vue {
  @Prop({ type: String, default: '' })
  text: string

  @Prop({ type: String, default: '' })
  description: string

  @Prop({ type: Array, required: true })
  value: TValue[]

  get is_tooltip() {
    return this.description.length > 0
  }

  @Emit('input')
  changeValue(field: string) {
    return field
  }
}
