import moment from 'moment-timezone'
import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartVertical from '@/client/components-old/molecules/ChartVertical'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import {
  IGetter as IInstagramHashtagGetter,
  IState as IInstagramHashtagState
} from '@/client/store/modules/instagram_hashtag'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'

const instagram_hashtag = namespace('instagram_hashtag')
const notification = namespace('notification')

@Component({
  name: 'InstagramHashtagPostCount',
  components: {
    Button,
    ButtonGroup,
    Icon,
    Message,
    AnalyticsPanel,
    ChartVertical
  }
})
export default class InstagramHashtagPostCount extends Vue {
  @State('user') user: IRootState['user']
  @instagram_hashtag.State('api_settings') api_settings: IInstagramHashtagState['api_settings']
  @instagram_hashtag.State('api_post_count')
  api_post_count: IInstagramHashtagState['api_post_count']
  @instagram_hashtag.State('start_date') start_date: IInstagramHashtagState['start_date']
  @instagram_hashtag.State('end_date') end_date: IInstagramHashtagState['end_date']
  @instagram_hashtag.State('post_count_index')
  post_count_index: IInstagramHashtagState['post_count_index']
  @instagram_hashtag.State('post_count_interval')
  post_count_interval: IInstagramHashtagState['post_count_interval']
  @instagram_hashtag.State('is_post_count_loading')
  is_post_count_loading: IInstagramHashtagState['is_post_count_loading']
  @instagram_hashtag.Getter('is_data') is_data: IInstagramHashtagGetter['is_data']
  @instagram_hashtag.Getter('post_count_date_range')
  post_count_date_range: IInstagramHashtagGetter['post_count_date_range']
  @instagram_hashtag.Action('changePostCountIndex') changePostCountIndex
  @instagram_hashtag.Action('changePostCountInterval') changePostCountInterval
  @instagram_hashtag.Action('fetchHashtagPostCountCsv') fetchHashtagPostCountCsv
  @notification.Action('showNotification') showNotification

  get chart_interval() {
    switch (this.post_count_interval) {
      case 'hour':
        return '1_hour'
      case 'day':
        return '1_day'
      case 'week':
        return '7_day'
      case 'month':
        return '1_month'
      default:
        return '1_day'
    }
  }

  get is_show_paging() {
    const selected_date_count = moment(this.end_date, 'YYYY-MM-DD').diff(
      moment(this.start_date, 'YYYY-MM-DD'),
      'days'
    )

    const current_date_count = moment(this.post_count_date_range.end_date, 'YYYY-MM-DD').diff(
      moment(this.post_count_date_range.start_date),
      'days'
    )

    return selected_date_count > current_date_count
  }

  get display_options() {
    if (this.user.language === 'en') {
      return [
        { text: 'Hour', value: 'hour' },
        { text: 'Day', value: 'day' },
        { text: 'Week', value: 'week' },
        { text: 'Month', value: 'month' }
      ]
    }

    return [
      { text: '時間', value: 'hour' },
      { text: '日', value: 'day' },
      { text: '週', value: 'week' },
      { text: '月', value: 'month' }
    ]
  }

  get display_data() {
    return {
      chart_type: 'line',
      y_label: i18n.t('回数'),
      display_legend: true,
      display_value: true,
      display_line: true,
      colors: [
        '#1c84c6',
        '#ed5565',
        '#2ec881',
        '#24cbe5',
        '#f8ac59',
        '#5e5e5e',
        '#9b9b9b',
        '#b2dfdb',
        '#9c27b0',
        '#2d552d'
      ],
      categories: this.labels,
      series: this.series.map(v => ({ ...v, name: this.convertName(v.name) }))
    }
  }

  get series() {
    if (this.is_post_count_loading) {
      return []
    }

    return this.api_post_count
      .filter(v => this.api_settings.some(item => item.id === v.hashtag_id))
      .map(v => {
        const setting = this.api_settings.find(item => item.id === v.hashtag_id)

        return {
          name: setting ? setting.name : '',
          data: v.columns.map(item => item.value)
        }
      })
  }

  get display_date_range() {
    const start_date = moment(this.post_count_date_range.start_date, 'YYYY-MM-DD').format('ll')
    const end_date = moment(this.post_count_date_range.end_date, 'YYYY-MM-DD').format('ll')

    return `${start_date} ~ ${end_date}`
  }

  get is_prev_disabled() {
    return moment(this.post_count_date_range.start_date, 'YYYY-MM-DD').isSameOrBefore(
      this.start_date
    )
  }

  get is_next_disabled() {
    return moment(this.post_count_date_range.end_date, 'YYYY-MM-DD').isSameOrAfter(this.end_date)
  }

  get labels() {
    if (!this.api_post_count.length || this.is_post_count_loading) {
      return []
    }

    return this.api_post_count[0].columns.map(v => {
      switch (this.post_count_interval) {
        case 'hour':
          return moment(v.start_date).format('YYYY/MM/DD HH:00')
        case 'day':
          return moment(v.start_date).format('YYYY/MM/DD')
        case 'week':
          return `${moment(v.start_date).format('M/D')} - ${moment(v.end_date).format('M/D')}`
        case 'month':
          return moment(v.start_date).format('MMM')
        default:
          return ''
      }
    })
  }

  /**
   * 分類名を省略表示に対応する
   */
  convertName(name: string): string {
    let count = 0
    let temp = ''

    for (let i = 0; i < name.length; i++) {
      // 全角は1文字、半角は0.5文字としてカウントする
      count += name[i].match(/[ -~]/) ? 0.5 : 1

      if (count > 8) {
        temp += '...'
        break
      }

      temp += name[i]
    }

    return temp
  }

  /**
   * 前のページングのボタンをクリック
   */
  getPrev() {
    this.changePostCountIndex(this.post_count_index + 1)
  }

  /**
   * 次のページングのボタンをクリック
   */
  getNext() {
    this.changePostCountIndex(this.post_count_index - 1)
  }

  /**
   * CSVをダウンロード
   */
  async downloadCSV() {
    const result = await this.fetchHashtagPostCountCsv()

    if (result.error) {
      return this.showNotification({ title: 'CSVダウンロードに失敗しました。', type: 'error' })
    }

    const api_data = result.data || []

    const first_data = api_data[0]

    const labels = first_data
      ? first_data.columns.map(v =>
          csv.getGraphLabel({
            start_date: v.start_date,
            end_date: v.end_date,
            interval: this.post_count_interval
          })
        )
      : []

    const graph_data = api_data
      .filter(v => this.api_settings.some(item => item.id === v.hashtag_id))
      .map(v => {
        const setting = this.api_settings.find(item => item.id === v.hashtag_id)

        return {
          name: setting ? setting.name : '',
          data: v.columns.map(item => item.value)
        }
      })

    const display_option = this.display_options.find(v => v.value === this.post_count_interval)

    const filename = [
      i18n.t('Instagramクチコミ分析'),
      i18n.t('投稿数'),
      display_option ? display_option.text : '',
      this.start_date,
      this.end_date
    ].join('_')

    const fields = [i18n.t('日付'), ...graph_data.map(v => i18n.t(v.name))]

    const data = labels.map((label, index) => [label, ...graph_data.map(v => v.data[index])])

    const csv_data = { fields, data }

    csv.download(csv_data, filename)

    TrackingService.sendEvent(`click:クチコミ(IG)>サマリー|投稿数:CSVダウンロード`)
  }

  /**
   * サマリーの投稿数の集計期間の変更
   */
  async onChangePostCountInterval(payload: string) {
    await this.changePostCountInterval(payload)

    const display_option = this.display_options.find(v => v.value === this.post_count_interval)
    const text = display_option?.text || ''

    TrackingService.sendEvent(`click:クチコミ(IG)>サマリー|投稿数:${text}`)
  }
}
