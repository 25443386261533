import { extractHashtags } from 'instagram-pattern'
import moment from 'moment-timezone'
import twttr from 'twitter-text'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import * as post_util from '@/client/components-old/_utils/post'
import { IRootState } from '@/client/store/global'
import { IGetter as IAccountGetter } from '@/client/store/modules/accounts'
import API from '@/client/utils/api'
import {
  IGetFacebookLinksParams,
  IGetFacebookLinksResponse
} from '@/client/utils/api/facebook_links'
import { IPostImagesParams, IPostImagesResponse, TImagesType } from '@/client/utils/api/images'
import {
  TGetIncidentEventsParams,
  TGetIncidentEventsResponse,
  TIncidentEvent
} from '@/client/utils/api/incident_events'
import {
  IGetMediasTwitterProgressResponse,
  IPostMediasFacebookUploadParams,
  IPostMediasFacebookUploadResponse,
  IPostMediasTwitterUploadParams,
  IPostMediasTwitterUploadResponse
} from '@/client/utils/api/medias'
import {
  TGetPostKeywordsParams,
  TGetPostKeywordsResponse,
  TPostKeyword
} from '@/client/utils/api/post_keywords'
import { TGetPostSettingsResponse, TPostSetting } from '@/client/utils/api/post_settings'
import {
  IFBTarget,
  IGetPostResponse,
  IGetTwitterMonthsParams,
  IGetTwitterMonthsResponse,
  IGetTwitterPostsParams,
  IGetTwitterPostsResponse,
  IGetTwitterTweetsParams,
  IGetTwitterTweetsResponse,
  IPost,
  IPostImage,
  IPostVideo,
  IPublishPostMedia,
  IPublishPostParams,
  IPublishPostResponse,
  ISavePostFacebookPost,
  ISavePostInstagramPost,
  ISavePostInstagramStory,
  ISavePostParams,
  ISavePostResponse,
  ISavePostTwitterPost,
  ITwitterMonth,
  ITwitterPost,
  ITwitterTweet,
  SavePostTikTokPost,
  SubmitType
} from '@/client/utils/api/posts'
import {
  GetProjectPostApprovalFlowsParams,
  GetProjectPostApprovalFlowsResponse,
  ProjectPostApprovalFlow
} from '@/client/utils/api/project_post_approval_flows'
import {
  GetUsersRolesParams,
  GetUsersRolesResponse,
  User,
  UsersRoles
} from '@/client/utils/api/users'
import {
  IGetVideoProgressesResponse,
  IPostVideoActionUploadParams,
  IPostVideoActionUploadResponse
} from '@/client/utils/api/videos'
import { delay } from '@/client/utils/event'
import { getImageByBlob } from '@/client/utils/media'
import { convertHelpMessage } from '@/client/utils/notification'
import { FACEBOOK_INTERNAL_URL_FORMAT, INSTAGRAM_INTERNAL_URL_FORMAT } from '@/client/utils/regex'
import storage from '@/client/utils/storage'
import * as validate from '@/client/utils/validate'
import { SnsMessageType, SnsType } from '@/common/types'

const LOOP = true

interface Message {
  master: string | null
  facebook: string | null
  twitter: string | null
  instagram: string | null
  tiktok: string | null
}

interface FacebookLink {
  url: string
  image_url: string | null
  title: string | null
  description: string | null
  domain: string
  call_to_action: string
}

export interface TikTokOption {
  is_comment: boolean
  is_duet: boolean
  is_stitch: boolean
  is_brand_organic: boolean
  is_branded_content: boolean
}

const TIKTOK_OPTION_DEFAULT: TikTokOption = {
  is_comment: true,
  is_duet: true,
  is_stitch: true,
  is_brand_organic: false,
  is_branded_content: false
}

export interface IState {
  api_post: IPost | null
  api_persons: UsersRoles[]
  api_post_approval_flows: ProjectPostApprovalFlow[]
  api_post_setting: TPostSetting | null
  api_post_keywords: TPostKeyword[]
  api_project_short_url_domain: {
    domain: string
    type: string
  } | null
  api_twitter_quote_months: ITwitterMonth[]
  api_twitter_quote_posts: ITwitterPost[]
  api_twitter_reply_months: ITwitterMonth[]
  api_twitter_reply_posts: ITwitterPost[]
  api_incident_events: TIncidentEvent[]
  scheduled_datetime: string | null
  accounts: {
    id: string
    sns: SnsType
  }[]
  message: Message
  instagram_comment_enabled: boolean
  instagram_first_comment_message: string | null
  instagram_share_to_feed: boolean
  instagram_post_type: 'post' | 'story'
  master_sync: {
    facebook: boolean
    twitter: boolean
    instagram: boolean
    tiktok: boolean
  }
  categories: number[]
  project_post_approval_flow_id: number | ''
  persons: number[] // 担当者
  type: 'text' | 'image' | 'video' | 'link' | 'carousel' | 'story'
  twitter_quote_account_id: string
  twitter_quote_target_month: string
  twitter_quote_target_tw_post_id: number | ''
  twitter_quote_target_tweet: ITwitterTweet | null
  twitter_reply_account_id: string
  twitter_reply_target_month: string
  twitter_reply_target_tw_post_id: number | ''
  twitter_reply_target_tweet: ITwitterTweet | null
  is_show_url_input_in_reply: boolean
  is_show_url_input_in_quote: boolean
  is_auto_publish: boolean
  tiktok_option: TikTokOption
  images: IPostImage[]
  videos: IPostVideo[]
  facebook_links: FacebookLink[]
  carousels_end_card: boolean
  facebook_target: IFBTarget | null
  page_type: 'create' | 'edit' | 'copy'
  selected_message: SnsMessageType
  loading_message: string
  is_loading: boolean
  is_media_uploading: boolean
  reset_approve_step: boolean
}

const state: IState = {
  api_post: null,
  api_persons: [],
  api_post_approval_flows: [],
  api_post_setting: null,
  api_post_keywords: [],
  api_project_short_url_domain: null,
  api_twitter_quote_months: [],
  api_twitter_quote_posts: [],
  api_twitter_reply_months: [],
  api_twitter_reply_posts: [],
  api_incident_events: [],
  scheduled_datetime: null,
  accounts: [],
  message: {
    master: '',
    facebook: null,
    twitter: null,
    instagram: null,
    tiktok: null
  },
  instagram_comment_enabled: true,
  instagram_first_comment_message: null,
  instagram_share_to_feed: true,
  instagram_post_type: 'post',
  master_sync: {
    facebook: true,
    twitter: true,
    instagram: true,
    tiktok: true
  },
  categories: [],
  project_post_approval_flow_id: '',
  persons: [],
  type: 'text',
  twitter_quote_account_id: '',
  twitter_quote_target_month: '',
  twitter_quote_target_tw_post_id: '',
  twitter_quote_target_tweet: null,
  twitter_reply_account_id: '',
  twitter_reply_target_month: '',
  twitter_reply_target_tw_post_id: '',
  twitter_reply_target_tweet: null,
  is_show_url_input_in_reply: false,
  is_show_url_input_in_quote: false,
  is_auto_publish: true,
  tiktok_option: TIKTOK_OPTION_DEFAULT,
  images: [],
  videos: [],
  facebook_links: [],
  carousels_end_card: false,
  facebook_target: null,
  page_type: 'create',
  selected_message: 'master',
  loading_message: 'データを取得中です。しばらくお待ちください。',
  is_loading: false,
  is_media_uploading: false,
  reset_approve_step: false
}

export interface IGetter {
  disabled: boolean
  disabled_draft: boolean
  disabled_approve_request: boolean
  disabled_schedule: boolean
  disabled_publish: boolean
  use_facebook: boolean
  use_twitter: boolean
  use_instagram: boolean
  use_tiktok: boolean
  is_twitter_count_over: boolean
  is_instagram_count_over: boolean
  is_instagram_first_comment_message_count_over: boolean
  is_instagram_hashtag_type_count_over: boolean
  is_tiktok_count_over: boolean
  is_media_not_exist: boolean
  message: string
  short_urls: {
    hash: string
    type: string
  }[]
  persons_options: User[]
  is_post_change: boolean
  is_single_sns: boolean
  is_twitter_quote_invalid_post_publish_time: boolean
  is_twitter_quote_post_unpublish: boolean
  is_twitter_reply_invalid_post_publish_time: boolean
  is_twitter_reply_post_unpublish: boolean
  is_twitter_quote: boolean
  is_twitter_reply: boolean
}

const getters: GetterTree<IState, IRootState> = {
  disabled(state, getters): IGetter['disabled'] {
    if (!state.accounts.length) {
      return true
    }

    const exist_all_facebook_link_title = state.facebook_links.every(link => Boolean(link.title))
    const valid_all_facebook_link_url = state.facebook_links.every(link => {
      const exist_link = Boolean(link.url)
      const valid_link = twttr.extractUrls(link.url).length > 0
      const is_blacklist_url =
        FACEBOOK_INTERNAL_URL_FORMAT.test(link.url) || INSTAGRAM_INTERNAL_URL_FORMAT.test(link.url)

      return exist_link && valid_link && !is_blacklist_url
    })

    if (state.type === 'video' && !state.videos.length) {
      return true
    }

    if (state.type === 'image' && !state.images.length) {
      return true
    }

    const valid_type_link =
      exist_all_facebook_link_title && valid_all_facebook_link_url && state.facebook_links.length

    const exist_account_facebook = state.accounts.some(account => account.sns === 'facebook')
    const exist_account_twitter = state.accounts.some(account => account.sns === 'twitter')

    if (state.type === 'link' && !valid_type_link) {
      return true
    }

    if (state.type === 'carousel') {
      if (exist_account_facebook && !valid_type_link) {
        return true
      }

      if (
        getters.use_instagram &&
        state.instagram_post_type === 'post' &&
        getters.is_media_not_exist
      ) {
        return true
      }
    }

    if (getters.use_tiktok) {
      const is_video_only = state.images.length === 0 && state.videos.length === 1

      if (!is_video_only) return true
    }

    if (state.instagram_post_type === 'story' && getters.is_media_not_exist) {
      return true
    }

    const invalid_message_facebook =
      exist_account_facebook &&
      state.type === 'text' &&
      (!state.message.facebook || !state.message.facebook.trim())

    const invalid_message_twitter =
      exist_account_twitter &&
      ['text', 'link', 'carousel'].includes(state.type) &&
      (!state.message.twitter || !state.message.twitter.trim())

    if (invalid_message_facebook || invalid_message_twitter) {
      return true
    }

    if (
      state.is_loading ||
      state.is_media_uploading ||
      getters.is_twitter_count_over ||
      getters.is_instagram_count_over ||
      getters.is_tiktok_count_over ||
      getters.is_instagram_first_comment_message_count_over ||
      getters.is_instagram_hashtag_type_count_over ||
      getters.is_twitter_quote_invalid_post_publish_time ||
      getters.is_twitter_reply_invalid_post_publish_time
    ) {
      return true
    }

    const is_3_minutes = moment().add(3, 'minutes')
    const is_6_months = moment().add(6, 'months')

    if (
      state.scheduled_datetime &&
      (!moment(state.scheduled_datetime).isValid() ||
        !moment(state.scheduled_datetime).isBetween(is_3_minutes, is_6_months, 'minute', '()'))
    ) {
      return true
    }

    return false
  },

  disabled_draft(state, getters): IGetter['disabled_draft'] {
    const is_empty_instagram_message = !state.message.instagram || !state.message.instagram.trim()

    if (getters.use_instagram && state.instagram_post_type === 'post') {
      return (getters.is_media_not_exist && is_empty_instagram_message) || state.is_media_uploading
    }

    if (getters.use_instagram && state.instagram_post_type === 'story') {
      return getters.is_media_not_exist || state.is_media_uploading
    }

    const is_empty_tiktok_message = !state.message.tiktok || !state.message.tiktok.trim()

    if (getters.use_tiktok) {
      return (is_empty_tiktok_message && getters.is_media_not_exist) || state.is_media_uploading
    }

    return getters.disabled
  },

  disabled_approve_request(state, getters): IGetter['disabled_approve_request'] {
    return (
      getters.disabled ||
      (state.api_post_setting && !state.project_post_approval_flow_id) ||
      (getters.use_instagram && getters.is_media_not_exist)
    )
  },

  disabled_schedule(state, getters, rootState, rootGetters): IGetter['disabled_schedule'] {
    const posts: IAccountGetter['post'] = rootGetters['accounts/post']
    const post_role = state.accounts.every(
      account =>
        posts.findIndex(
          post => post.role === 'admin' && post.id === account.id && post.sns === account.sns
        ) !== -1
    )

    const is_expired_account = state.accounts.some(
      account =>
        posts.findIndex(
          post => post.id === account.id && post.sns === account.sns && post.expired
        ) !== -1
    )

    return (
      getters.disabled ||
      !post_role ||
      (getters.use_instagram && !state.scheduled_datetime) ||
      is_expired_account ||
      (getters.use_instagram && getters.is_media_not_exist)
    )
  },

  disabled_publish(state, getters, rootState, rootGetters): IGetter['disabled_publish'] {
    const posts: IAccountGetter['post'] = rootGetters['accounts/post']
    const post_role = state.accounts.every(
      account =>
        posts.findIndex(
          post => post.role === 'admin' && post.id === account.id && post.sns === account.sns
        ) !== -1
    )

    const is_expired_account = state.accounts.some(
      account =>
        posts.findIndex(
          post => post.id === account.id && post.sns === account.sns && post.expired
        ) !== -1
    )

    return (
      getters.disabled ||
      !post_role ||
      (getters.use_instagram && getters.is_media_not_exist) ||
      is_expired_account ||
      getters.is_twitter_quote_post_unpublish ||
      getters.is_twitter_reply_post_unpublish
    )
  },
  use_facebook(state): IGetter['use_facebook'] {
    return state.accounts.some(v => v.sns === 'facebook')
  },
  use_twitter(state): IGetter['use_twitter'] {
    return state.accounts.some(v => v.sns === 'twitter')
  },
  use_instagram(state): IGetter['use_instagram'] {
    return state.accounts.some(v => v.sns === 'instagram')
  },
  use_tiktok(state): IGetter['use_tiktok'] {
    return state.accounts.some(v => v.sns === 'tiktok')
  },
  is_twitter_count_over(state): IGetter['is_twitter_count_over'] {
    if (!state.message.twitter) return false

    const message = state.message.twitter.trim()

    return twttr.parseTweet(message).weightedLength > 280
  },
  is_instagram_count_over(state): IGetter['is_instagram_count_over'] {
    if (!state.message.instagram) return false

    const message = state.message.instagram.trim()

    return message.length > 2200
  },
  // TODO util関数に移動する
  is_tiktok_count_over(state): IGetter['is_tiktok_count_over'] {
    if (!state.message.tiktok) return false

    const message = state.message.tiktok.trim()

    return message.length > 2200
  },
  is_instagram_first_comment_message_count_over(
    state
  ): IGetter['is_instagram_first_comment_message_count_over'] {
    if (!state.instagram_first_comment_message) return false

    return state.instagram_first_comment_message.length > 2200
  },
  is_instagram_hashtag_type_count_over(state): IGetter['is_instagram_hashtag_type_count_over'] {
    if (!state.message.instagram && !state.instagram_first_comment_message) return false

    const in_message = state.message.instagram ? state.message.instagram.trim() : ''
    const message = `${in_message} ${state.instagram_first_comment_message || ''}`
    const hashtags = extractHashtags(message)
    const hashtag_type_count = [...new Set(hashtags)].length
    return hashtag_type_count > 30
  },
  is_media_not_exist(state): IGetter['is_media_not_exist'] {
    return state.images.length + state.videos.length === 0
  },
  short_urls(state): IGetter['short_urls'] {
    const short_url_domains = [{ domain: 'lnky.jp', type: 'lnky' }]
    const short_urls: { type: string; hash: string }[] = []

    if (
      state.api_project_short_url_domain &&
      short_url_domains.find(
        short_url => short_url.domain !== state.api_project_short_url_domain?.domain
      )
    ) {
      short_url_domains.push(state.api_project_short_url_domain)
    }

    const source: string[] = []

    for (const [key, value] of Object.entries(state.message)) {
      if (!value || key === 'master') continue
      if (!source.includes(value)) source.push(value)
    }

    if (state.type === 'link' || state.type === 'carousel') {
      state.facebook_links.forEach(link => {
        if (source.indexOf(link.url) === -1) {
          source.push(link.url)
        }
      })
    }

    for (const short_url_domain of short_url_domains) {
      const domain = short_url_domain.domain
      const type = short_url_domain.type
      const pattern = `https?://${domain}/([0-9A-Za-z_]+)`
      const regex = new RegExp(pattern, 'gi')

      source.forEach(text => {
        let matches

        while (LOOP) {
          if (text) {
            matches = regex.exec(text)
          }
          if (!matches) {
            break
          }

          const hash = matches[1]
          if (hash && hash.length <= 8) {
            short_urls.push({
              type,
              hash
            })
          }
        }
      })
    }

    return short_urls.filter((short_url, index, short_urls) => {
      return (
        short_urls.findIndex(url => short_url.hash === url.hash && short_url.type === url.type) ===
        index
      )
    })
  },
  persons_options(state): IGetter['persons_options'] {
    if (!state.accounts.length) return []

    return state.api_persons
      .filter(person =>
        state.accounts.every(account => {
          switch (account.sns) {
            case 'facebook':
              return person.fb_accounts.map(v => v.account_id).includes(account.id)
            case 'twitter':
              return person.tw_accounts.map(v => v.account_id).includes(account.id)
            case 'instagram':
              return person.in_accounts.map(v => v.account_id).includes(account.id)
            case 'tiktok':
              return person.tt_accounts.map(v => v.account_id).includes(account.id)
            default:
              return false
          }
        })
      )
      .map(person => person.user)
  },

  is_post_change(state): IGetter['is_post_change'] {
    if (!state.api_post) {
      if (
        state.message.master ||
        state.message.facebook ||
        state.message.twitter ||
        state.message.instagram ||
        state.images.length ||
        state.videos.length ||
        state.facebook_links.length
      ) {
        return true
      }

      return false
    }

    const fb_post = state.api_post.fb_posts[0]
    const tw_post = state.api_post.tw_posts[0]
    const in_post = state.api_post.in_posts[0]

    // 投稿内容
    if (
      state.message.master !== state.api_post.message ||
      (fb_post && state.message.facebook !== fb_post.message) ||
      (tw_post && state.message.twitter !== tw_post.message) ||
      (in_post && state.message.instagram !== in_post.message)
    ) {
      return true
    }

    // 投稿タイプ
    if (state.type !== state.api_post.type) {
      return true
    }

    // 画像
    if (state.api_post.post_images.length) {
      const image_urls = state.api_post.post_images.map(v => v.image_url)
      const images = state.images.map(v => v.image_url)

      if (images.toString() !== image_urls.toString()) {
        return true
      }
    }

    // 動画
    if (state.api_post.post_videos.length) {
      const video_urls = state.api_post.post_videos.map(v => v.video_url)
      const videos = state.videos.map(v => v.video_url)

      if (videos.toString() !== video_urls.toString()) {
        return true
      }
    }

    // Facebookリンク
    if (state.api_post.post_links.length) {
      const link_image_urls = state.api_post.post_links.map(v => v.image_url)
      const facebook_links = state.facebook_links.map(v => v.image_url)

      if (facebook_links.toString() !== link_image_urls.toString()) {
        return true
      }
    }

    return false
  },
  is_single_sns(state): IGetter['is_post_change'] {
    if (!state.accounts.length) {
      return true
    }

    return state.accounts.every(v => v.sns === state.accounts[0].sns)
  },

  is_twitter_quote_invalid_post_publish_time(
    state
  ): IGetter['is_twitter_quote_invalid_post_publish_time'] {
    // 引用の投稿でない場合
    if (!state.twitter_quote_target_tw_post_id) {
      return false
    }

    if (!state.scheduled_datetime) {
      return false
    }

    // 新たに引用を指定した場合
    if (state.api_twitter_quote_posts.length) {
      const twitter_quote_post = state.api_twitter_quote_posts.find(
        v => v.tw_post_id === state.twitter_quote_target_tw_post_id
      )

      return (
        !!twitter_quote_post &&
        moment(twitter_quote_post.action_datetime).isAfter(state.scheduled_datetime)
      )
    }

    // 表示時に引用が指定されている場合
    if (state.api_post?.tw_posts?.[0]?.twitter_quote?.target_tw_post) {
      return moment(
        state.api_post?.tw_posts[0].twitter_quote.target_tw_post.action_datetime
      ).isAfter(state.scheduled_datetime)
    }

    return false
  },

  is_twitter_quote_post_unpublish(state): IGetter['is_twitter_quote_post_unpublish'] {
    // 引用の投稿でない場合
    if (
      !state.twitter_quote_target_tw_post_id ||
      (state.api_post?.tw_posts?.[0]?.twitter_quote &&
        !state.api_post?.tw_posts[0].twitter_quote.target_tw_post) ||
      state.scheduled_datetime
    ) {
      return false
    }

    // 新たに引用を指定した場合
    if (state.api_twitter_quote_posts.length) {
      const twitter_quote_post = state.api_twitter_quote_posts.find(
        v => v.tw_post_id === state.twitter_quote_target_tw_post_id
      )

      return !!twitter_quote_post && twitter_quote_post.status !== 'published'
    }

    // 表示時に引用が指定されている場合
    if (state.api_post?.tw_posts?.[0]?.twitter_quote?.target_tw_post) {
      return state.api_post?.tw_posts[0].twitter_quote.target_tw_post.status !== 'published'
    }

    return false
  },

  is_twitter_reply_invalid_post_publish_time(
    state
  ): IGetter['is_twitter_reply_invalid_post_publish_time'] {
    // リプライでない場合
    if (!state.twitter_reply_target_tw_post_id) {
      return false
    }

    if (!state.scheduled_datetime) {
      return false
    }

    // 新たにリプライを指定した場合
    if (state.api_twitter_reply_posts.length) {
      const twitter_reply_post = state.api_twitter_reply_posts.find(
        v => v.tw_post_id === state.twitter_reply_target_tw_post_id
      )

      return (
        !!twitter_reply_post &&
        moment(twitter_reply_post.action_datetime).isAfter(state.scheduled_datetime)
      )
    }

    // 表示時にリプライが指定されている場合
    if (state.api_post?.tw_posts?.[0]?.twitter_reply?.target_tw_post) {
      return moment(
        state.api_post?.tw_posts[0].twitter_reply.target_tw_post.action_datetime
      ).isAfter(state.scheduled_datetime)
    }

    return false
  },

  is_twitter_reply_post_unpublish(state): IGetter['is_twitter_reply_post_unpublish'] {
    // リプライでない場合
    if (
      !state.twitter_reply_target_tw_post_id ||
      (state.api_post?.tw_posts?.[0]?.twitter_reply &&
        !state.api_post?.tw_posts[0].twitter_reply.target_tw_post) ||
      state.scheduled_datetime
    ) {
      return false
    }

    // 新たにリプライを指定した場合
    if (state.api_twitter_reply_posts.length) {
      const twitter_reply_post = state.api_twitter_reply_posts.find(
        v => v.tw_post_id === state.twitter_reply_target_tw_post_id
      )

      return !!twitter_reply_post && twitter_reply_post.status !== 'published'
    }

    // 表示時にリプライが指定されている場合
    if (state.api_post?.tw_posts?.[0]?.twitter_reply?.target_tw_post) {
      return state.api_post?.tw_posts[0].twitter_reply.target_tw_post.status !== 'published'
    }

    return false
  },

  is_twitter_quote(state): IGetter['is_twitter_quote'] {
    return Boolean(state.twitter_quote_target_tw_post_id || state.twitter_quote_target_tweet)
  },

  is_twitter_reply(state): IGetter['is_twitter_reply'] {
    return Boolean(state.twitter_reply_target_tw_post_id || state.twitter_reply_target_tweet)
  }
}

const mutations: MutationTree<IState> = {
  SET_API_POST(state, payload) {
    state.api_post = payload
  },
  SET_API_PERSONS(state, payload: IState['api_persons']) {
    state.api_persons = payload
  },
  SET_API_POST_APPROVAL_FLOWS(state, payload) {
    state.api_post_approval_flows = payload
  },
  SET_API_POST_SETTING(state, payload) {
    state.api_post_setting = payload
  },
  SET_API_POST_KEYWORDS(state, payload) {
    state.api_post_keywords = payload
  },
  SET_API_PROJECT_SHORT_URL_DOMAIN(state, payload) {
    state.api_project_short_url_domain = payload
  },
  SET_API_TWITTER_QUOTE_MONTHS(state, payload) {
    state.api_twitter_quote_months = payload
  },
  SET_API_TWITTER_QUOTE_POSTS(state, payload) {
    state.api_twitter_quote_posts = payload
  },
  SET_API_TWITTER_REPLY_MONTHS(state, payload) {
    state.api_twitter_reply_months = payload
  },
  SET_API_TWITTER_REPLY_POSTS(state, payload) {
    state.api_twitter_reply_posts = payload
  },
  SET_API_INCIDENT_EVENTS(state, payload) {
    state.api_incident_events = payload
  },
  SET_SCHEDULED_DATETIME(state, payload) {
    state.scheduled_datetime = payload
  },
  SET_ACCOUNTS(state, payload) {
    state.accounts = payload
  },
  SET_MESSAGE(state, payload) {
    state.message = payload
  },
  SET_MASTER_SYNC(state, payload) {
    state.master_sync = payload
  },
  SET_INSTAGRAM_COMMENT_ENABLED(state, payload) {
    state.instagram_comment_enabled = payload
  },
  SET_INSTAGRAM_FIRST_COMMENT_MESSAGE(state, payload) {
    state.instagram_first_comment_message = payload
  },
  SET_INSTAGRAM_SHARE_TO_FEED(state, payload) {
    state.instagram_share_to_feed = payload
  },
  SET_INSTAGRAM_POST_TYPE(state, payload) {
    state.instagram_post_type = payload
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload
  },
  SET_PROJECT_POST_APPROVAL_FLOW_ID(state, payload) {
    state.project_post_approval_flow_id = payload
  },
  SET_PERSONS(state, payload) {
    state.persons = payload
  },
  SET_TYPE(state, payload) {
    state.type = payload
  },
  SET_TWITTER_QUOTE_ACCOUNT_ID(state, payload) {
    state.twitter_quote_account_id = payload
  },
  SET_TWITTER_QUOTE_TARGET_MONTH(state, payload) {
    state.twitter_quote_target_month = payload
  },
  SET_TWITTER_QUOTE_TW_POST_ID(state, payload) {
    state.twitter_quote_target_tw_post_id = payload
  },
  SET_TWITTER_QUOTE_TARGET_TWEET(state, payload) {
    state.twitter_quote_target_tweet = payload
  },
  SET_IS_SHOW_URL_INPUT_IN_QUOTE(state, payload) {
    state.is_show_url_input_in_quote = payload
  },
  SET_TWITTER_REPLY_ACCOUNT_ID(state, payload) {
    state.twitter_reply_account_id = payload
  },
  SET_TWITTER_REPLY_TARGET_MONTH(state, payload) {
    state.twitter_reply_target_month = payload
  },
  SET_TWITTER_REPLY_TW_POST_ID(state, payload) {
    state.twitter_reply_target_tw_post_id = payload
  },
  SET_TWITTER_REPLY_TARGET_TWEET(state, payload) {
    state.twitter_reply_target_tweet = payload
  },
  SET_IS_SHOW_URL_INPUT_IN_REPLY(state, payload) {
    state.is_show_url_input_in_reply = payload
  },
  SET_IS_AUTO_PUBLISH(state, payload) {
    state.is_auto_publish = payload
  },
  SET_TIKTOK_OPTION(state, payload) {
    state.tiktok_option = payload
  },
  SET_IMAGES(state, payload) {
    state.images = payload
  },
  SET_VIDEOS(state, payload) {
    state.videos = payload
  },
  SET_FACEBOOK_LINKS(state, payload) {
    state.facebook_links = payload
  },
  SET_CAROUSELS_END_CARD(state, payload) {
    state.carousels_end_card = payload
  },
  SET_FACEBOOK_TARGET(state, payload) {
    state.facebook_target = payload
  },
  SET_PAGE_TYPE(state, payload) {
    state.page_type = payload
  },
  SET_SELECTED_MESSAGE(state, payload) {
    state.selected_message = payload
  },
  SET_LOADING_MESSAGE(state, payload) {
    state.loading_message = payload
  },
  SET_IS_LOADING(state, payload) {
    state.is_loading = payload
  },
  SET_IS_MEDIA_UPLOADING(state, payload) {
    state.is_media_uploading = payload
  },
  SET_RESET_APPROVE_STEP(state, payload) {
    state.reset_approve_step = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * 状態の初期化
   */
  async reset(context) {
    context.commit('SET_API_POST', null)
    context.commit('SET_API_PERSONS', [])
    context.commit('SET_API_POST_APPROVAL_FLOWS', [])
    context.commit('SET_API_POST_SETTING', null)
    context.commit('SET_API_POST_KEYWORDS', [])
    context.commit('SET_API_PROJECT_SHORT_URL_DOMAIN', null)
    context.commit('SET_API_TWITTER_QUOTE_MONTHS', [])
    context.commit('SET_API_TWITTER_QUOTE_POSTS', [])
    context.commit('SET_API_TWITTER_REPLY_MONTHS', [])
    context.commit('SET_API_TWITTER_REPLY_POSTS', [])
    context.commit('SET_API_INCIDENT_EVENTS', [])
    context.commit('SET_SCHEDULED_DATETIME', null)
    context.commit('SET_ACCOUNTS', [])
    context.commit('SET_MESSAGE', {
      master: '',
      facebook: null,
      twitter: null,
      instagram: null,
      tiktok: null
    })
    context.commit('SET_INSTAGRAM_COMMENT_ENABLED', true)
    context.commit('SET_INSTAGRAM_FIRST_COMMENT_MESSAGE', null)
    context.commit('SET_INSTAGRAM_SHARE_TO_FEED', true)
    context.commit('SET_INSTAGRAM_POST_TYPE', 'post')
    context.commit('SET_MASTER_SYNC', {
      facebook: true,
      twitter: true,
      instagram: true,
      tiktok: true
    })
    context.commit('SET_CATEGORIES', [])
    context.commit('SET_PROJECT_POST_APPROVAL_FLOW_ID', '')
    context.commit('SET_PERSONS', [])
    context.commit('SET_TYPE', 'text')
    context.commit('SET_TWITTER_QUOTE_ACCOUNT_ID', '')
    context.commit('SET_TWITTER_QUOTE_TARGET_MONTH', '')
    context.commit('SET_TWITTER_QUOTE_TW_POST_ID', '')
    context.commit('SET_TWITTER_QUOTE_TARGET_TWEET', null)
    context.commit('SET_IS_SHOW_URL_INPUT_IN_QUOTE', false)
    context.commit('SET_TWITTER_REPLY_ACCOUNT_ID', '')
    context.commit('SET_TWITTER_REPLY_TARGET_MONTH', '')
    context.commit('SET_TWITTER_REPLY_TW_POST_ID', '')
    context.commit('SET_TWITTER_REPLY_TARGET_TWEET', null)
    context.commit('SET_IS_SHOW_URL_INPUT_IN_REPLY', false)
    context.commit('SET_IS_AUTO_PUBLISH', true)
    context.commit('SET_TIKTOK_OPTION', TIKTOK_OPTION_DEFAULT)
    context.commit('SET_IMAGES', [])
    context.commit('SET_VIDEOS', [])
    context.commit('SET_FACEBOOK_LINKS', [])
    context.commit('SET_CAROUSELS_END_CARD', false)
    context.commit('SET_FACEBOOK_TARGET', null)
    context.commit('SET_PAGE_TYPE', 'create')
    context.commit('SET_SELECTED_MESSAGE', 'master')
    context.commit('SET_LOADING_MESSAGE', 'データを取得中です。しばらくお待ちください。')
    context.commit('SET_IS_LOADING', false)
    context.commit('SET_IS_MEDIA_UPLOADING', false)
    context.commit(
      'post_management/SET_MEMO_CACHE',
      {
        sns_post_id: 0,
        memo_type: 'memo',
        message: ''
      },
      { root: true }
    )
  },

  /**
   * 表示タイプの取得
   */
  async getPageType(context, payload: 'create' | 'edit' | 'copy') {
    context.commit('SET_PAGE_TYPE', payload)
  },

  /**
   * プロジェクトの共通設定項目を取得する
   */
  async getPostSetting(context) {
    const response = await API.get<TGetPostSettingsResponse>(
      `post_settings/${context.rootState.project.id}`
    )

    if (response.data.data) {
      context.commit('SET_API_POST_SETTING', response.data.data)
      context.commit('SET_INSTAGRAM_COMMENT_ENABLED', response.data.data.instagram_comment_enabled)
      context.commit('SET_TIKTOK_OPTION', {
        is_comment: response.data.data.tiktok_is_comment,
        is_duet: response.data.data.tiktok_is_duet,
        is_stitch: response.data.data.tiktok_is_stitch,
        is_brand_organic: response.data.data.tiktok_is_brand_organic,
        is_branded_content: response.data.data.tiktok_is_branded_content
      })
    }
  },

  /**
   * 投稿設定の特定キーワードの取得
   */
  async getPostKeywords(context) {
    const params: TGetPostKeywordsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<TGetPostKeywordsResponse>('post_keywords', { params })

    if (response.data.data) {
      context.commit('SET_API_POST_KEYWORDS', response.data.data)
    }
  },

  /**
   * 投稿情報の取得
   */
  async getPost(context, payload: number) {
    context.commit('SET_IS_LOADING', true)

    const response = await API.get<IGetPostResponse>(`posts/${payload}`)

    if (response.data.data) {
      const post_data = response.data.data

      let scheduled_datetime = post_data.action_datetime
        ? moment(post_data.action_datetime).format('YYYY-MM-DD HH:mm')
        : null

      // ? 公開済みの投稿の場合、複製パターンなのでリセットする
      if (post_data.status === 'published' || post_data.status === 'failure') {
        scheduled_datetime = null
      }

      let accounts = [
        ...post_data.fb_posts.map(v => ({ id: v.account.id, sns: 'facebook' })),
        ...post_data.tw_posts.map(v => ({ id: v.account.id, sns: 'twitter' })),
        ...post_data.in_posts.map(v => ({ id: v.account.id, sns: 'instagram' })),
        ...post_data.in_stories.map(v => ({ id: v.account.id, sns: 'instagram' })),
        ...post_data.tt_posts.map(v => ({ id: v.account.id, sns: 'tiktok' }))
      ]

      const message = {
        master: post_data.message,
        facebook: post_data.fb_posts.length ? post_data.fb_posts[0].message : null,
        twitter: post_data.tw_posts.length ? post_data.tw_posts[0].message : null,
        instagram: post_data.in_posts.length ? post_data.in_posts[0].message : null,
        tiktok: post_data.tt_posts.length ? post_data.tt_posts[0].message : null
      }

      const instagram_first_comment_message = post_data.in_posts.length
        ? post_data.in_posts[0].first_comment_message
        : null

      const is_single_sns = accounts.every(v => v.sns === accounts[0].sns)

      if (is_single_sns) {
        if (message.facebook) {
          message.master = message.facebook
        }

        if (message.twitter) {
          message.master = message.twitter
        }

        if (message.instagram) {
          message.master = message.instagram
        }

        if (message.tiktok) {
          message.master = message.tiktok
        }
      }

      const master_sync = {
        facebook: message.master === message.facebook || message.facebook === null,
        twitter: message.master === message.twitter || message.twitter === null,
        instagram: message.master === message.instagram || message.instagram === null,
        tiktok: message.master === message.tiktok || message.tiktok === null
      }

      const carousel_end_card = post_data.post_links.length > 0 && post_data.post_links[0].end_card
      const facebook_links = post_data.post_links.map(link => {
        delete link.end_card
        return link
      })

      const accounts_post: IAccountGetter['post'] = context.rootGetters['accounts/post']

      const is_publish_permission = accounts_post
        .filter(a => accounts.some(b => a.id === b.id && a.sns === b.sns))
        .some(v => v.is_publish_permission)
      const is_auto_publish = !is_publish_permission ? false : post_data.is_auto_publish

      if (context.state.page_type === 'copy') {
        accounts = accounts.filter(account =>
          accounts_post.some(v => v.id === account.id && v.sns === account.sns)
        )
      }

      context.commit('SET_API_POST', post_data)
      context.commit('SET_SCHEDULED_DATETIME', scheduled_datetime)
      context.commit('SET_ACCOUNTS', accounts)
      context.commit('SET_MESSAGE', message)
      context.commit('SET_INSTAGRAM_FIRST_COMMENT_MESSAGE', instagram_first_comment_message)
      context.commit('SET_MASTER_SYNC', master_sync)
      context.commit(
        'SET_CATEGORIES',
        post_data.post_categories.map(v => v.id)
      )
      context.commit(
        'SET_PROJECT_POST_APPROVAL_FLOW_ID',
        post_data.project_post_approval_flow?.id ?? ''
      )
      context.commit(
        'SET_PERSONS',
        post_data.post_persons.map(v => v.id)
      )
      context.commit('SET_TYPE', post_data.type)

      context.commit('SET_INSTAGRAM_POST_TYPE', post_data.type === 'story' ? 'story' : 'post')

      context.commit('SET_IS_AUTO_PUBLISH', is_auto_publish)

      let instagram_comment_enabled = true

      if (typeof post_data.in_posts[0]?.comment_enabled === 'boolean') {
        instagram_comment_enabled = post_data.in_posts[0].comment_enabled
      } else if (typeof state.api_post_setting?.instagram_comment_enabled === 'boolean') {
        instagram_comment_enabled = state.api_post_setting.instagram_comment_enabled
      }

      context.commit('SET_INSTAGRAM_COMMENT_ENABLED', instagram_comment_enabled)

      let instagram_share_to_feed = true

      if (typeof post_data.in_posts[0]?.share_to_feed === 'boolean') {
        instagram_share_to_feed = post_data.in_posts[0].share_to_feed
      }

      context.commit('SET_INSTAGRAM_SHARE_TO_FEED', instagram_share_to_feed)

      context.commit('SET_IMAGES', post_data.post_images)
      context.commit('SET_VIDEOS', post_data.post_videos)

      context.commit('SET_FACEBOOK_LINKS', facebook_links)
      context.commit('SET_CAROUSELS_END_CARD', carousel_end_card)
      context.commit('SET_FACEBOOK_TARGET', post_data.fb_target)

      if (post_data.tw_posts?.[0]?.twitter_quote) {
        const twitter_quote = post_data.tw_posts[0].twitter_quote

        if (twitter_quote.target_tw_post) {
          // 一覧を取得する必要があるため、change経由で設定する
          await context.dispatch(
            'changeTwitterQuoteAccountId',
            twitter_quote.target_tw_post.account.id
          )

          // 一覧を取得する必要があるため、change経由で設定する
          await context.dispatch(
            'changeTwitterQuoteTargetMonth',
            moment(twitter_quote.target_tw_post.action_datetime).format('YYYY-MM')
          )

          context.commit('SET_TWITTER_QUOTE_TW_POST_ID', twitter_quote.target_tw_post.tw_post_id)
        }

        context.commit('SET_TWITTER_QUOTE_TARGET_TWEET', twitter_quote.target_tweet)
      }

      if (post_data.tw_posts?.[0]?.twitter_reply) {
        const twitter_reply = post_data.tw_posts[0].twitter_reply

        if (twitter_reply.target_tw_post) {
          // 一覧を取得する必要があるため、change経由で設定する
          await context.dispatch(
            'changeTwitterReplyAccountId',
            twitter_reply.target_tw_post.account.id
          )

          // 一覧を取得する必要があるため、change経由で設定する
          await context.dispatch(
            'changeTwitterReplyTargetMonth',
            moment(twitter_reply.target_tw_post.action_datetime).format('YYYY-MM')
          )

          context.commit('SET_TWITTER_REPLY_TW_POST_ID', twitter_reply.target_tw_post.tw_post_id)
        }

        context.commit('SET_TWITTER_REPLY_TARGET_TWEET', twitter_reply.target_tweet)
      }

      // 引用のストア調整が終わってもアカウントIDが存在しなかった場合は初期値にする
      if (!context.state.twitter_quote_account_id) {
        await context.dispatch('changeTwitterQuoteAccountId', accounts[0].id)
      }

      // 返信のストア調整が終わってもアカウントIDが存在しなかった場合は初期値にする
      if (!context.state.twitter_reply_account_id) {
        await context.dispatch('changeTwitterReplyAccountId', accounts[0].id)
      }

      if (post_data.tt_posts.length) {
        const [tt_post] = post_data.tt_posts

        const tiktok_option: TikTokOption = {
          is_comment: tt_post.is_comment,
          is_duet: tt_post.is_duet,
          is_stitch: tt_post.is_stitch,
          is_brand_organic: tt_post.is_brand_organic,
          is_branded_content: tt_post.is_branded_content
        }

        context.commit('SET_TIKTOK_OPTION', tiktok_option)
      }
    }
  },

  /**
   * 投稿権限がある担当者一覧を取得
   */
  async getPersons(context) {
    const params: GetUsersRolesParams = {
      project_id: context.rootState.project.id,
      role_type: 'post',
      role_status: true
    }

    const response = await API.get<GetUsersRolesResponse>('users/roles', { params })

    if (response.data.data) {
      context.commit('SET_API_PERSONS', response.data.data)
    }
  },

  /**
   * 承認フロー一覧を取得
   */
  async fetchPostApprovalFlows(context) {
    const params: GetProjectPostApprovalFlowsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<GetProjectPostApprovalFlowsResponse>(
      'project_post_approval_flows',
      {
        params
      }
    )

    if (response.data.data) {
      context.commit('SET_API_POST_APPROVAL_FLOWS', response.data.data)
    }
  },

  /**
   * 画像をアップロード
   */
  async postImageUpload(
    context,
    payload: { file: File; type: TImagesType; is_change?: boolean; is_thumbnail?: boolean }
  ) {
    const is_instagram = context.state.accounts.some(v => v.sns === 'instagram')

    // ? メディア変更後のリールフラグ
    const is_instagram_reels = payload.is_change
      ? false
      : await validate.checkVideoInstagramReels(
          context.state.videos.length ? context.state.videos[0].video_url : ''
        )

    const before = await validate.checkImageUploadBefore(
      payload.file,
      payload.type,
      is_instagram,
      is_instagram_reels
    )

    if (before.error) return before

    const image = await getImageByBlob(payload.file)

    const params: IPostImagesParams = {
      file: image,
      type: payload.type
    }

    const data = new FormData()

    Object.entries(params).forEach(([key, value]) => {
      data.append(key, value)
    })

    const response = await API.post<IPostImagesResponse>('images', data)

    if (!response.data.data) return response.data

    const after = await validate.checkImageUploadAfter(
      response.data.data,
      context.state.images.map(image => image.image_url),
      context.state.images.some(v => v.is_animation_gif),
      context.state.accounts.some(v => v.sns === 'twitter'),
      is_instagram,
      context.state.instagram_post_type,
      payload.is_change,
      payload.is_thumbnail
    )

    if (after.error) return after

    return response.data
  },

  /**
   * 動画をアップロード
   */
  async postVideoUpload(context, payload: { file: File; is_change: boolean }) {
    // ? メディア変更後のリールフラグ
    const is_instagram_reels = payload.is_change
      ? false
      : await validate.checkVideoInstagramReels(
          context.state.videos.length ? context.state.videos[0].video_url : ''
        )

    // ? メディア変更後の複合カルーセルフラグ
    const is_instagram_carousel = payload.is_change
      ? context.state.images.length + context.state.videos.length > 1
      : context.state.images.length + context.state.videos.length > 0

    const before = await validate.checkVideoUploadBefore(
      payload.file,
      context.getters.use_facebook,
      context.getters.use_twitter,
      context.getters.use_instagram,
      context.getters.use_tiktok,
      context.state.instagram_post_type,
      is_instagram_reels,
      is_instagram_carousel
    )

    if (before.error) return before

    const params: IPostVideoActionUploadParams = {
      file: payload.file
    }

    const data = new FormData()

    Object.entries(params).forEach(([key, value]) => {
      data.append(key, value)
    })

    const response = await API.post<IPostVideoActionUploadResponse>('videos', data)

    if (response.data.data) {
      while (LOOP) {
        const progress = await API.get<IGetVideoProgressesResponse>(
          `videos/${response.data.data.id}/progress`
        )

        if (progress.status === 502) {
          await delay(3000)
        } else if (progress.data.data && progress.data.data === 'pending') {
          await delay(1000)
        } else if (progress.data.data && progress.data.data === 'success') {
          break
        } else {
          return {
            error: {
              title: 'アップロードに失敗しました。'
            }
          }
        }
      }

      const after = await validate.checkVideoUploadAfter(
        response.data.data,
        context.getters.use_facebook,
        context.getters.use_twitter,
        context.getters.use_instagram,
        context.getters.use_tiktok,
        context.state.instagram_post_type,
        is_instagram_reels,
        is_instagram_carousel
      )

      if (after.error) return after
    }

    return response.data
  },

  /**
   * Facebookリンク情報を取得
   */
  async getFacebookLink(context) {
    if (!context.state.message.facebook) return

    const urls = twttr.extractUrls(context.state.message.facebook, {
      extractUrlsWithoutProtocol: true
    })

    // ? Facebook公式側のリンク表示の検知仕様と合わせる
    const facebook_urls = urls.filter(
      url => url.startsWith('http') || url.startsWith('www.') || url.includes('/')
    )

    if (!facebook_urls.length) return
    if (context.state.type !== 'text') return
    if (!context.state.accounts.some(v => v.sns === 'facebook')) return
    if (context.state.accounts.some(v => v.sns === 'instagram')) return

    context.commit('SET_IS_MEDIA_UPLOADING', true)

    const [url] = facebook_urls

    const params: IGetFacebookLinksParams = { url }

    const response = await API.get<IGetFacebookLinksResponse>('facebook_links', { params })

    if (response.data.data) {
      const result = response.data.data

      const facebook_link: FacebookLink = {
        url: result.url,
        image_url: null,
        title: result.title,
        description: result.description,
        domain: result.domain,
        call_to_action: 'NO_BUTTON'
      }

      if (result.image) {
        const params: IPostImagesParams = {
          url: result.image,
          type: 'link'
        }

        const image = await API.post<IPostImagesResponse>('images', params)

        facebook_link.image_url = image.data.data ? image.data.data.image_url : null
      }

      context.commit('SET_FACEBOOK_LINKS', [facebook_link])
      context.commit('SET_TYPE', 'link')
    } else {
      context.commit('SET_TYPE', 'text')
    }

    context.commit('SET_IS_MEDIA_UPLOADING', false)
  },

  /**
   * 引用元投稿の公開月一覧を取得
   */
  async getTwitterQuoteMonths(context) {
    if (
      context.state.accounts.filter(v => v.sns === 'twitter').length !== 1 ||
      context.state.accounts.length > 1
    ) {
      return
    }

    const params: IGetTwitterMonthsParams = {
      project_id: context.rootState.project.id,
      account_id: context.state.twitter_quote_account_id
    }

    if (context.state.page_type === 'edit' && context.state.api_post?.tw_posts[0]) {
      params.exclude_tw_post_id = context.state.api_post.tw_posts[0].id
    }

    const response = await API.get<IGetTwitterMonthsResponse>('posts/twitter_months', { params })

    if (response.data.data) {
      context.commit('SET_API_TWITTER_QUOTE_MONTHS', response.data.data)
    }
  },

  /**
   * 引用元投稿の投稿一覧を取得
   */
  async getTwitterQuotePosts(context) {
    if (
      context.state.accounts.filter(v => v.sns === 'twitter').length !== 1 ||
      !context.state.twitter_quote_target_month
    ) {
      return
    }

    const params: IGetTwitterPostsParams = {
      project_id: context.rootState.project.id,
      target_month: context.state.twitter_quote_target_month,
      account_id: context.state.twitter_quote_account_id
    }

    if (context.state.page_type === 'edit' && context.state.api_post?.tw_posts[0]) {
      params.exclude_tw_post_id = context.state.api_post.tw_posts[0].id
    }

    const response = await API.get<IGetTwitterPostsResponse>('posts/twitter_posts', { params })

    if (response.data.data) {
      context.commit('SET_API_TWITTER_QUOTE_POSTS', response.data.data)
    }
  },

  /**
   * 返信先投稿の公開月一覧を取得
   */
  async getTwitterReplyMonths(context) {
    if (
      context.state.accounts.filter(v => v.sns === 'twitter').length !== 1 ||
      context.state.accounts.length > 1
    ) {
      return
    }

    const params: IGetTwitterMonthsParams = {
      project_id: context.rootState.project.id,
      account_id: context.state.twitter_reply_account_id
    }

    if (context.state.page_type === 'edit' && context.state.api_post?.tw_posts[0]) {
      params.exclude_tw_post_id = context.state.api_post.tw_posts[0].id
    }

    const response = await API.get<IGetTwitterMonthsResponse>('posts/twitter_months', { params })

    if (response.data.data) {
      context.commit('SET_API_TWITTER_REPLY_MONTHS', response.data.data)
    }
  },

  /**
   * 返信先投稿の投稿一覧を取得
   */
  async getTwitterReplyPosts(context) {
    if (
      context.state.accounts.filter(v => v.sns === 'twitter').length !== 1 ||
      !context.state.twitter_reply_target_month
    ) {
      return
    }

    const params: IGetTwitterPostsParams = {
      project_id: context.rootState.project.id,
      target_month: context.state.twitter_reply_target_month,
      account_id: context.state.twitter_reply_account_id
    }

    if (context.state.page_type === 'edit' && context.state.api_post?.tw_posts[0]) {
      params.exclude_tw_post_id = context.state.api_post.tw_posts[0].id
    }

    const response = await API.get<IGetTwitterPostsResponse>('posts/twitter_posts', { params })

    if (response.data.data) {
      context.commit('SET_API_TWITTER_REPLY_POSTS', response.data.data)
    }
  },

  /**
   * SNS投稿を保存する
   */
  async saveSnsPost(
    context,
    payload: {
      submit_type: SubmitType
      memo: {
        message: string
        users: number[]
      }
      history: {
        type: string
        is_edit_media: boolean
      }
    }
  ) {
    const is_3_minutes = moment().add(3, 'minute')
    const is_6_months = moment().add(6, 'month')

    if (
      context.state.scheduled_datetime &&
      !moment(context.state.scheduled_datetime).isBetween(is_3_minutes, is_6_months, 'minute', '()')
    ) {
      return {
        error: {
          title: '公開日時は4分後〜6ヶ月の間で指定できます。'
        }
      }
    }

    context.commit('SET_LOADING_MESSAGE', '投稿を保存しています。')

    const fb_page_ids = context.state.accounts.filter(v => v.sns === 'facebook').map(v => v.id)
    const fb_message = context.state.message.facebook?.trim() || null
    const fb_posts = fb_page_ids.map<ISavePostFacebookPost>(fb_page_id => ({
      account_id: fb_page_id,
      message: fb_message
    }))

    const tw_account_ids = context.state.accounts.filter(v => v.sns === 'twitter').map(v => v.id)
    const tw_message = context.state.message.twitter?.trim() || null
    const tw_posts = tw_account_ids.map<ISavePostTwitterPost>(tw_account_id => {
      const post: ISavePostTwitterPost = {
        account_id: tw_account_id,
        message: tw_message
      }

      if (context.state.twitter_reply_target_tw_post_id) {
        post.tw_reply_tweet = {
          target_tw_post_id: context.state.twitter_reply_target_tw_post_id,
          target_tweet_id: null
        }
      }

      if (context.state.twitter_reply_target_tweet) {
        post.tw_reply_tweet = {
          target_tw_post_id: null,
          target_tweet_id: context.state.twitter_reply_target_tweet.id
        }
      }

      if (context.state.twitter_quote_target_tw_post_id) {
        post.tw_quote_tweet = {
          target_tw_post_id: context.state.twitter_quote_target_tw_post_id,
          target_tweet_id: null
        }
      }

      if (context.state.twitter_quote_target_tweet) {
        post.tw_quote_tweet = {
          target_tw_post_id: null,
          target_tweet_id: context.state.twitter_quote_target_tweet.id
        }
      }

      return post
    })

    const in_account_ids = context.state.accounts.filter(v => v.sns === 'instagram').map(v => v.id)
    const in_message = context.state.message.instagram?.trim() || null
    const in_posts: ISavePostInstagramPost[] = []

    if (context.state.instagram_post_type === 'post') {
      in_posts.push(
        ...in_account_ids.map<ISavePostInstagramPost>(in_account_id => ({
          account_id: in_account_id,
          message: in_message,
          first_comment_message: context.state.instagram_first_comment_message,
          comment_enabled: context.state.instagram_comment_enabled,
          share_to_feed: context.state.instagram_share_to_feed
        }))
      )
    }

    const in_stories: ISavePostInstagramStory[] = []

    if (context.state.instagram_post_type === 'story') {
      in_stories.push(
        ...in_account_ids.map<ISavePostInstagramStory>(in_account_id => ({
          account_id: in_account_id
        }))
      )
    }

    const tt_account_ids = context.state.accounts.filter(v => v.sns === 'tiktok').map(v => v.id)
    const tt_message = context.state.message.tiktok?.trim() || null
    const tt_posts = tt_account_ids.map<SavePostTikTokPost>(tt_account_id => ({
      account_id: tt_account_id,
      message: tt_message,
      is_comment: context.state.tiktok_option.is_comment,
      is_duet: context.state.tiktok_option.is_duet,
      is_stitch: context.state.tiktok_option.is_stitch,
      is_brand_organic: context.state.tiktok_option.is_brand_organic,
      is_branded_content: context.state.tiktok_option.is_branded_content
    }))

    const post_links = context.state.facebook_links.map(v => ({
      url: v.url,
      image_url: v.image_url,
      title: v.title,
      description: v.description,
      domain: v.domain,
      call_to_action: v.call_to_action,
      end_card: context.state.carousels_end_card
    }))

    const short_urls: IGetter['short_urls'] = context.getters.short_urls

    const params: ISavePostParams = {
      id: null,
      project_id: context.rootState.project.id,
      submit_type: payload.submit_type,
      scheduled_datetime: context.state.scheduled_datetime,
      project_post_approval_flow_id: context.state.project_post_approval_flow_id || null,
      message: context.state.message.master?.trim() || null,
      facebook_target: context.state.facebook_target
        ? JSON.stringify(context.state.facebook_target)
        : null,
      is_auto_publish: context.state.is_auto_publish,
      fb_posts,
      tw_posts,
      in_posts,
      in_stories,
      tt_posts,
      post_images: context.state.images,
      post_videos: context.state.videos,
      post_links,
      post_persons: context.state.persons,
      post_categories: context.state.categories,
      short_urls,
      reset_approve_step: context.state.reset_approve_step,
      memo: payload.memo,
      history: payload.history
    }

    if (context.state.page_type === 'edit' && context.state.api_post) {
      params.id = context.state.api_post.id
    }

    const response = await API.post<ISavePostResponse>('posts', params)

    // 選択中アカウントをローカルストレージに保存
    storage.set('post_create', {
      accounts: context.state.accounts,
      persons: context.state.persons,
      project_post_approval_flow_id: context.state.project_post_approval_flow_id
    })

    if (response.data && response.data.data) {
      if (payload.submit_type === 'approve_request') {
        const memo_cache = {
          sns_post_id: 0,
          type: 'memo',
          message: ''
        }

        context.commit('post_management/SET_MEMO_CACHE', memo_cache, { root: true })

        if (payload.memo) {
          storage.set('post_management', { to_user_ids: payload.memo.users })
        }
      }

      await context.dispatch('getPost', response.data.data.id)
    }

    return response.data
  },

  /**
   * SNS投稿を公開する
   */
  async publishSnsPost(
    context,
    payload: {
      id: number
      is_edit_media: boolean
    }
  ) {
    let medias: IPublishPostMedia[] = []

    context.commit('SET_LOADING_MESSAGE', '投稿を公開しています。')

    if (context.state.api_post) {
      await post_util.preUploadMedia(context.state.api_post)
    }

    try {
      medias = await context.dispatch('postMediaUpload')
    } catch (error: any) {
      context.commit('SET_IS_LOADING', false)

      if (error.error.type === 'MEDIA_UPLOAD_FAILED') {
        return error
      }

      return {
        error: {
          title: 'アップロードに失敗しました。'
        }
      }
    }

    const params: IPublishPostParams = {
      type: 'publish',
      medias,
      is_edit_media: payload.is_edit_media
    }

    const response = await API.post<IPublishPostResponse>(`posts/${payload.id}/publish`, params)

    if (
      response.data &&
      response.data.data &&
      response.data.data.status !== 'ALL_ACCOUNT_SUCCESS'
    ) {
      const result = response.data.data

      if (result.error && result.error.length && result.error[0].error === 'TOKEN_EXPIRED') {
        return {
          status: result.status,
          error: {
            title: 'アクセストークンが失効しています。',
            message: convertHelpMessage({
              message: 'アクセストークンを更新してください。',
              help_color: 'warning',
              help_type: 'ABOUT_ACCESS_TOKEN'
            })
          }
        }
      }

      if (result.error && result.error.length && result.error[0].error === 'PUBLISH_RATE_LIMIT') {
        return {
          status: result.status,
          error: {
            title: '1日の最大投稿数(25件)に到達しています。',
            message: '明日以降に再投稿するか、公式Instagramから公開してください。'
          }
        }
      }

      if (
        result.error &&
        result.error.length &&
        result.error[0].error === 'POST_STATUS_DUPLICATED'
      ) {
        return {
          status: result.status,
          error: {
            title: '投稿の公開に失敗しました。',
            message: '同じ内容の投稿がすでに存在しています。投稿内容を変更してください。'
          }
        }
      }

      if (
        result.error &&
        result.error.length &&
        result.error[0].error === 'TWITTER_QUOTE_INVALID'
      ) {
        return {
          status: result.status,
          error: {
            title: '引用元の投稿が存在しません。'
          }
        }
      }

      if (result.error && result.error.length && result.error[0].error === 'TWITTER_QUOTE_EDITED') {
        return {
          status: result.status,
          error: {
            title: '引用元の投稿が編集されているため投稿を公開できません。'
          }
        }
      }

      if (
        result.error &&
        result.error.length &&
        result.error[0].error === 'TWITTER_REPLY_INVALID'
      ) {
        return {
          status: result.status,
          error: {
            title: '返信先の投稿が存在しません。'
          }
        }
      }

      if (result.error && result.error.length && result.error[0].error === 'TWITTER_REPLY_EDITED') {
        return {
          status: result.status,
          error: {
            title: '返信先の投稿が編集されているため投稿を公開できません。'
          }
        }
      }

      if (
        result.error &&
        result.error.length &&
        result.error[0].error === 'FACEBOOK_INTERNAL_SERVER_ERROR'
      ) {
        return {
          status: result.status,
          error: {
            title: '投稿の公開に失敗しました。',
            message:
              'Facebookの一時的なサーバー不調により、投稿に失敗しました。時間をあけて、再投稿をお願いします。'
          }
        }
      }

      if (
        result.error &&
        result.error.length &&
        result.error[0].error === 'TWITTER_INTERNAL_SERVER_ERROR'
      ) {
        return {
          status: result.status,
          error: {
            title: '投稿の公開に失敗しました。',
            message:
              'Xの一時的なサーバー不調により、投稿に失敗しました。時間をおいて、再投稿をお願いします。'
          }
        }
      }

      if (
        result.error &&
        result.error.length &&
        result.error[0].error === 'TWITTER_SPAM_TWEET_ERROR'
      ) {
        return {
          status: result.status,
          error: {
            title: '投稿の公開に失敗しました。',
            message:
              'アカウントが凍結されているか、投稿内容に問題がある可能性があります。投稿内容をご確認ください。'
          }
        }
      }

      if (result.error && result.error.length) {
        const { sns, error_code } = result.error[0]

        if (sns === 'facebook') {
          return post_util.createErrorNotificationByFacebook(error_code)
        }

        if (sns === 'instagram') {
          return post_util.createErrorNotificationByInstagram(error_code)
        }

        if (sns === 'twitter') {
          return post_util.createErrorNotificationByTwitter(error_code)
        }

        if (sns === 'tiktok') {
          return post_util.createErrorNotificationByTikTok(error_code)
        }
      }

      if (result.status === 'HAS_ACCOUNT_FAILED') {
        return {
          status: result.status,
          error: {
            title: '投稿の公開に失敗したアカウントがあります。'
          }
        }
      }

      return {
        status: result.status,
        error: {
          title: '投稿の公開に失敗しました。'
        }
      }
    }

    return response.data
  },

  /**
   * メディアアップロード
   * TODO: 将来的に削除する
   */
  async postMediaUpload(context) {
    let medias: IPublishPostMedia[] = []

    const is_facebook_media_upload =
      context.getters.use_facebook &&
      context.state.images.length &&
      !context.state.images.some(v => v.is_animation_gif)

    const is_twitter_media_upload =
      context.getters.use_twitter && context.state.images.length + context.state.videos.length > 0

    if (!is_facebook_media_upload && !is_twitter_media_upload) {
      return medias
    }

    context.commit('SET_LOADING_MESSAGE', '投稿を公開しています。しばらくお待ちください。')

    // TODO: 将来的に POST /api/v1/media/fb_posts/:fb_post_id が処理を行うようにする
    if (is_facebook_media_upload) {
      const tasks = context.state.accounts
        .filter(v => v.sns === 'facebook')
        .map(v => context.dispatch('postMediaFacebookUpload', { account_id: v.id }))

      const medias_facebook = await Promise.all(tasks)
      medias = medias.concat(medias_facebook)
    }

    // TODO: 将来的に POST /api/v1/media/tw_posts/:tw_post_id が処理を行うようにする
    if (is_twitter_media_upload) {
      const medias_twitter = await context.dispatch('postMediaTwitterUpload')

      medias = medias.concat(medias_twitter)
    }

    return medias
  },

  /**
   * Facebookメディアアップロード
   */
  async postMediaFacebookUpload(context, payload: { account_id: string }) {
    const tasks = context.state.images.map(image => {
      const params: IPostMediasFacebookUploadParams = {
        account_id: payload.account_id,
        image_url: image.image_url
      }

      return API.post<IPostMediasFacebookUploadResponse>('medias?sns=facebook', params)
    })

    const responses = await Promise.all(tasks)

    const media_ids = responses
      .filter(response => response.data.data)
      .map(response => response.data.data?.media_id)

    return {
      account_id: payload.account_id,
      media_ids,
      sns: 'facebook'
    }
  },

  /**
   * Twitterメディアアップロード
   */
  async postMediaTwitterUpload(context) {
    const media_urls = context.state.videos.length
      ? context.state.videos.filter(v => v.sequence_no === 1).map(v => v.video_url)
      : context.state.images.filter(v => v.sequence_no <= 4).map(image => image.image_url)

    const account_ids = context.state.accounts.filter(v => v.sns === 'twitter').map(v => v.id)

    let media_type = 'image'
    if (context.state.videos.length) media_type = 'video'
    if (context.state.images.some(v => v.is_animation_gif)) media_type = 'animation_gif'

    const upload = async (media_url: string) => {
      const params: IPostMediasTwitterUploadParams = {
        media_url,
        account_ids,
        media_type
      }
      const response = await API.post<IPostMediasTwitterUploadResponse>(
        'medias?sns=twitter',
        params
      )

      if (response.data.data) {
        while (LOOP) {
          // TODO: APIの型がエラーがするのでanyにしてる
          const progress = await API.get<IGetMediasTwitterProgressResponse>(
            `medias/${response.data.data.id}/progress?sns=twitter`
          )

          if (progress.status === 502) {
            await delay(3000)
          } else if (progress.data.data && progress.data.data === 'pending') {
            await delay(1000)
          } else if (progress.data.data) {
            const result = progress.data.data || []

            const has_error_media = result.find((v: any) => !v.media_id)
            if (has_error_media) {
              throw {
                error: {
                  type: 'MEDIA_UPLOAD_FAILED'
                }
              }
            }

            return result.map((v: any) => ({
              account_id: v.account_id,
              media_ids: [v.media_id],
              sns: 'twitter',
              medias: [
                {
                  id: v.media_id,
                  type: media_type,
                  url: media_url,
                  expired_at: v.expired_at
                }
              ]
            }))
          } else {
            throw {
              error: {
                title: 'アップロードに失敗しました。'
              }
            }
          }
        }
      }
    }

    const requests = media_urls.map(media_url => upload(media_url))

    const result = await Promise.all(requests)

    return result.reduce((medias, value) => {
      if (!medias || !medias.length) {
        return value
      }

      for (const media of medias) {
        const t = value?.find((v: any) => media.account_id === v.account_id)

        if (t) {
          media.media_ids = [...media.media_ids, ...t.media_ids]
          media.medias = [...media.medias, ...t.medias]
        }
      }

      return medias
    }, [])
  },

  /**
   * 災害事件イベント情報を取得する
   */
  async getIncidentEvents(context) {
    if (!context.state.scheduled_datetime) {
      return
    }

    const scheduled_datetime = moment(context.state.scheduled_datetime)

    if (!scheduled_datetime.isValid()) {
      return
    }

    const params: TGetIncidentEventsParams = {
      start_date: scheduled_datetime.format('YYYY-MM-DD'),
      end_date: scheduled_datetime.format('YYYY-MM-DD')
    }

    const response = await API.get<TGetIncidentEventsResponse>('incident_events', {
      params
    })

    if (response.data.data) {
      context.commit('SET_API_INCIDENT_EVENTS', response.data.data)
    } else {
      context.commit('SET_API_INCIDENT_EVENTS', [])
    }
  },

  /**
   * 投稿内容の末尾に短縮URLを挿入
   */
  async insertShortUrlToMessage(context, payload: string) {
    const message = { ...context.state.message }

    // ? 現在編集中の投稿内容に対して先に反映する
    switch (context.state.selected_message) {
      case 'master':
        message.master = message.master ? `${message.master} ${payload}` : payload
        break
      case 'facebook':
        message.facebook = message.facebook ? `${message.facebook} ${payload}` : payload
        if (context.state.master_sync.facebook) message.master = message.facebook
        break
      case 'twitter':
        message.twitter = message.twitter ? `${message.twitter} ${payload}` : payload
        if (context.state.master_sync.twitter) message.master = message.twitter
        break
      case 'instagram':
        message.instagram = message.instagram ? `${message.instagram} ${payload}` : payload
        if (context.state.master_sync.instagram) message.master = message.instagram
        break
      case 'tiktok':
        message.tiktok = message.tiktok ? `${message.tiktok} ${payload}` : payload
        if (context.state.master_sync.tiktok) message.master = message.tiktok
        break
    }

    // ? SNSが複数あって編集中以外のSNSにも同期設定がある場合に同期する
    if (context.getters.use_facebook && context.state.master_sync.facebook) {
      message.facebook = message.master
    }
    if (context.getters.use_twitter && context.state.master_sync.twitter) {
      message.twitter = message.master
    }
    if (context.getters.use_instagram && context.state.master_sync.instagram) {
      message.instagram = message.master
    }
    if (context.getters.use_tiktok && context.state.master_sync.tiktok) {
      message.tiktok = message.master
    }

    context.commit('SET_MESSAGE', message)

    await context.dispatch('getFacebookLink')
  },

  /**
   * 短縮URL情報を取得
   */
  async getProjectShortUrlDomain(context) {
    const project_id = context.rootState.project.id

    const response = await API.get(`project_short_url_domains/${project_id}`)

    if (response.data.data) {
      const project_short_url_domain = response.data.data

      context.commit('SET_API_PROJECT_SHORT_URL_DOMAIN', project_short_url_domain)
    }
  },

  /**
   * アカウントの変更
   */
  async changeAccounts(
    context,
    payload: {
      id: string
      name: string
      img: string
      sns: SnsType
    }[]
  ) {
    const message = { ...context.state.message }

    const is_facebook = payload.some(v => v.sns === 'facebook')
    const is_twitter = payload.some(v => v.sns === 'twitter')
    const is_instagram = payload.some(v => v.sns === 'instagram')
    const is_tiktok = payload.some(v => v.sns === 'tiktok')

    if (is_facebook) {
      if (context.state.master_sync.facebook) {
        message.facebook = context.state.message.master
      }
    } else {
      message.facebook = null

      if (context.state.type === 'link' || context.state.type === 'carousel') {
        context.commit('SET_TYPE', 'text')
      }

      context.commit('SET_FACEBOOK_LINKS', [])
      context.commit('SET_CAROUSELS_END_CARD', false)
      context.commit('SET_FACEBOOK_TARGET', null)

      if (context.state.selected_message === 'facebook') {
        context.commit('SET_SELECTED_MESSAGE', 'master')
      }
    }

    if (is_twitter) {
      if (context.state.master_sync.twitter) {
        message.twitter = context.state.message.master
      }

      const twitter_accounts = payload.filter(v => v.sns === 'twitter')

      if (twitter_accounts.length > 1) {
        const account_id = twitter_accounts.length === 1 ? twitter_accounts[0].id : ''

        await Promise.all([
          context.dispatch('changeTwitterQuoteAccountId', account_id),
          context.dispatch('changeTwitterReplyAccountId', account_id),
          context.dispatch('fetchQuoteTweet', null),
          context.dispatch('fetchReplyTweet', null)
        ])
      }
    } else {
      message.twitter = null

      if (context.state.selected_message === 'twitter') {
        context.commit('SET_SELECTED_MESSAGE', 'master')
      }

      await Promise.all([
        context.dispatch('changeTwitterQuoteAccountId', ''),
        context.dispatch('changeTwitterReplyAccountId', ''),
        context.dispatch('fetchQuoteTweet', null),
        context.dispatch('fetchReplyTweet', null)
      ])
    }

    if (is_instagram) {
      if (context.state.master_sync.instagram) {
        message.instagram = context.state.message.master
      }

      if (context.state.type !== 'text' && context.state.type !== 'image') {
        context.commit('SET_TYPE', 'text')
        context.commit('SET_FACEBOOK_LINKS', [])
        context.commit('SET_CAROUSELS_END_CARD', false)
        context.commit('SET_VIDEOS', [])
      }
    } else {
      message.instagram = null

      if (context.state.selected_message === 'instagram') {
        context.commit('SET_SELECTED_MESSAGE', 'master')
      }
    }

    if (is_tiktok) {
      if (context.state.master_sync.tiktok) {
        message.tiktok = context.state.message.master
      }

      if (context.state.type !== 'text' && context.state.type !== 'image') {
        context.commit('SET_TYPE', 'text')
        context.commit('SET_VIDEOS', [])
        // ? 専用テーブルを持っているため念の為初期化しておく
        context.commit('SET_FACEBOOK_LINKS', [])
        context.commit('SET_TIKTOK_OPTION', TIKTOK_OPTION_DEFAULT)
      }
    } else {
      message.tiktok = null

      if (context.state.selected_message === 'tiktok') {
        context.commit('SET_SELECTED_MESSAGE', 'master')
      }
    }

    if (!payload.length) {
      context.commit('SET_TYPE', 'text')
      context.commit('SET_IMAGES', [])
      context.commit('SET_VIDEOS', [])
      context.commit('SET_INSTAGRAM_POST_TYPE', 'post')
    }

    // ? アカウント追加による画像枚数制限を調整
    if (context.state.images.length) {
      let post_images: IPostImage[] = []

      if (is_facebook || is_instagram) {
        post_images = context.state.images.filter(v => v.sequence_no <= 10)
      } else if (is_twitter) {
        post_images = context.state.images.filter(v => v.sequence_no <= 4)
      }

      context.commit('SET_IMAGES', post_images)
    }

    context.commit('SET_MESSAGE', message)

    const facebook = payload.filter(v => v.sns === 'facebook')
    const twitter = payload.filter(v => v.sns === 'twitter')
    const instagram = payload.filter(v => v.sns === 'instagram')
    const tiktok = payload.filter(v => v.sns === 'tiktok')
    const accounts = [...facebook, ...twitter, ...instagram, ...tiktok].sort((a, b) =>
      a.sns === b.sns && `${a.name}`.localeCompare(b.name) ? -1 : 1
    )

    context.commit(
      'SET_ACCOUNTS',
      accounts.map(v => ({ id: v.id, sns: v.sns }))
    )

    // 担当者を設定
    const persons_options: IGetter['persons_options'] = context.getters.persons_options

    const persons = context.state.persons.filter(
      person => persons_options.map(v => v.id).indexOf(person) >= 0
    )

    context.commit('SET_PERSONS', persons)

    await context.dispatch('getFacebookLink')

    if (accounts.length === 1 && accounts[0].sns === 'twitter') {
      await context.dispatch('changeTwitterReplyAccountId', accounts[0].id)
      await context.dispatch('changeTwitterQuoteAccountId', accounts[0].id)
    }
  },

  /**
   * 投稿内容の変更
   */
  async changeMessage(context, payload: string | null) {
    const message: Message = {
      master: payload,
      facebook: null,
      twitter: null,
      instagram: null,
      tiktok: null
    }

    if (context.state.master_sync.facebook) {
      message.facebook = payload
    }

    if (context.state.master_sync.twitter) {
      message.twitter = payload
    }

    if (context.state.master_sync.instagram) {
      message.instagram = payload
    }

    if (context.state.master_sync.tiktok) {
      message.tiktok = payload
    }

    context.commit('SET_MESSAGE', message)
  },

  /**
   * Instagramコメント制限を変更する
   */
  async changeInstagramCommentEnabled(context, payload: boolean) {
    context.commit('SET_INSTAGRAM_COMMENT_ENABLED', payload)
  },

  /**
   * Instagram投稿の最初のコメントの内容の変更
   */
  async changeInstagramFirstCommentMessage(context, payload: boolean) {
    context.commit('SET_INSTAGRAM_FIRST_COMMENT_MESSAGE', payload)
  },

  /**
   * Instagram投稿のフィードでもシェアフラグを変更する
   */
  async changeInstagramShareToFeed(context, payload: boolean) {
    context.commit('SET_INSTAGRAM_SHARE_TO_FEED', payload)
  },

  /**
   * Instagram投稿のタイプを変更する
   */
  async changeInstagramPostType(context, payload: 'post' | 'story') {
    context.commit('SET_INSTAGRAM_POST_TYPE', payload)

    context.commit('SET_TYPE', 'text')
    context.commit('SET_IMAGES', [])
    context.commit('SET_VIDEOS', [])

    if (payload === 'story') {
      context.commit('SET_MESSAGE', {
        master: '',
        facebook: null,
        twitter: null,
        instagram: null,
        tiktok: null
      })
    }
  },

  /**
   * Instagramに自動公開フラグの変更
   */
  async changeInstagramAutoPublishStatus(context, payload: boolean) {
    context.commit('SET_IS_AUTO_PUBLISH', payload)
  },

  /**
   * 引用元投稿のアカウントを変更する
   */
  async changeTwitterQuoteAccountId(context, payload: string) {
    context.commit('SET_TWITTER_QUOTE_TW_POST_ID', '')
    context.commit('SET_TWITTER_QUOTE_TARGET_MONTH', '')
    context.commit('SET_TWITTER_QUOTE_ACCOUNT_ID', payload)
    if (payload) await context.dispatch('getTwitterQuoteMonths')
  },

  /**
   * 引用元投稿の公開月を変更する
   */
  async changeTwitterQuoteTargetMonth(context, payload: string) {
    context.commit('SET_TWITTER_QUOTE_TW_POST_ID', '')
    context.commit('SET_TWITTER_QUOTE_TARGET_MONTH', payload)
    await context.dispatch('getTwitterQuotePosts')
  },

  /**
   * 引用の設定方法を切り替える
   */
  async changeIsShowUrlInputInQuote(context, payload: boolean) {
    context.commit('SET_IS_SHOW_URL_INPUT_IN_QUOTE', payload)
  },

  /**
   * 引用元投稿の投稿を変更する
   */
  async changeTwitterQuoteTwPostId(context, payload: number | '') {
    context.commit('SET_TWITTER_QUOTE_TW_POST_ID', payload)
  },

  /**
   * 引用元投稿を取得し変更する
   */
  async fetchQuoteTweet(context, payload: string | null) {
    if (!payload || !context.state.accounts[0]) {
      context.commit('SET_TWITTER_QUOTE_TARGET_TWEET', payload)

      return { data: true }
    }

    const params: IGetTwitterTweetsParams = {
      project_id: context.rootState.project.id,
      account_id: context.state.accounts[0].id,
      tweet_url: payload
    }

    const response = await API.get<IGetTwitterTweetsResponse>(`posts/twitter_tweets`, { params })

    if (response.data?.data) {
      const tweet = response.data.data

      context.commit('SET_TWITTER_QUOTE_TARGET_TWEET', tweet)
    } else {
      context.commit('SET_TWITTER_QUOTE_TARGET_TWEET', null)
    }

    return response.data
  },

  /**
   * 返信先投稿のアカウントを変更する
   */
  async changeTwitterReplyAccountId(context, payload: string) {
    context.commit('SET_TWITTER_REPLY_TW_POST_ID', '')
    context.commit('SET_TWITTER_REPLY_TARGET_MONTH', '')
    context.commit('SET_TWITTER_REPLY_ACCOUNT_ID', payload)
    if (payload) await context.dispatch('getTwitterReplyMonths')
  },

  /**
   * 返信先投稿の公開月を変更する
   */
  async changeTwitterReplyTargetMonth(context, payload: string) {
    context.commit('SET_TWITTER_REPLY_TW_POST_ID', '')
    context.commit('SET_TWITTER_REPLY_TARGET_MONTH', payload)
    await context.dispatch('getTwitterReplyPosts')
  },

  /**
   * 返信先投稿の投稿を変更する
   */
  async changeTwitterReplyTwPostId(context, payload: number | '') {
    context.commit('SET_TWITTER_REPLY_TW_POST_ID', payload)
  },

  /**
   * 返信先の入力方法を変更する
   */
  async changeIsShowUrlInputInReply(context, payload: boolean) {
    context.commit('SET_IS_SHOW_URL_INPUT_IN_REPLY', payload)
  },

  /**
   * 返信先投稿を取得し変更する
   */
  async fetchReplyTweet(context, payload: string | null) {
    if (!payload || !context.state.accounts[0]) {
      context.commit('SET_TWITTER_REPLY_TARGET_TWEET', payload)

      return { data: true }
    }

    const params: IGetTwitterTweetsParams = {
      project_id: context.rootState.project.id,
      account_id: context.state.accounts[0].id,
      tweet_url: payload
    }

    const response = await API.get<IGetTwitterTweetsResponse>(`posts/twitter_tweets`, { params })

    if (response.data && response.data.data) {
      const tweet = response.data.data

      context.commit('SET_TWITTER_REPLY_TARGET_TWEET', tweet)
    } else {
      context.commit('SET_TWITTER_REPLY_TARGET_TWEET', null)
    }

    return response.data
  },

  /**
   * 返信先の指定を解除する
   */
  async resetTwitterReply(context, payload: string) {
    await context.dispatch('changeTwitterReplyAccountId', payload)
    await context.dispatch('changeTwitterReplyTargetMonth', '')
    await context.dispatch('changeTwitterReplyTwPostId', '')
    await context.dispatch('fetchReplyTweet', null)
  },

  /**
   * 引用元の指定を解除する
   */
  async resetTwitterQuote(context, payload) {
    await context.dispatch('changeTwitterQuoteAccountId', payload)
    await context.dispatch('changeTwitterQuoteTargetMonth', '')
    await context.dispatch('changeTwitterQuoteTwPostId', '')
    await context.dispatch('fetchQuoteTweet', null)
  },

  /**
   * 承認フローのための投稿のidを変更する
   */
  async changeProjectPostApprovalFlowId(context, payload: number | '') {
    context.commit('SET_PROJECT_POST_APPROVAL_FLOW_ID', payload)
  },

  /**
   * TikTokオプションを変更する
   */
  async changeTikTokOption(context, payload: TikTokOption) {
    context.commit('SET_TIKTOK_OPTION', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
