import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/twitter_analytics'
import * as twitter_insight from '@/client/components-old/_utils/twitter_insight'
import Button from '@/client/components-old/atoms/Button'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsList from '@/client/components-old/molecules/AnalyticsList'
import CategoryList from '@/client/components-old/molecules/CategoryList'
import ChartSingleBar from '@/client/components-old/molecules/ChartSingleBar'
import PaginationTable from '@/client/components-old/molecules/PaginationTable'
import PostMedia from '@/client/components-old/molecules/PostMedia'
import HourlyChartDialog from '@/client/components-old/organisms/HourlyChartDialog'
import { TrackingService } from '@/client/services'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as ITwitterState } from '@/client/store/modules/twitter_analytics'
import { IHourlyInsightsParams } from '@/client/utils/api/twitter_insights'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'

type TTwitterAnalyticsTableCustomChart = {
  chart: {
    series: number[]
    colors: string[]
    display_total: boolean
    total: number
    max: number
  }
  list: {
    title: string
    options: {
      key: string
      value: number
      point?: {
        type: string
        value: string
      }
    }[]
  }[]
}

const categories = namespace('categories')
const analytics = namespace('analytics')
const twitter = namespace('twitter_analytics')

@Component({
  name: 'TwitterAnalyticsTable',
  components: {
    HourlyChartDialog,
    AnalyticsList,
    CategoryList,
    ChartSingleBar,
    PaginationTable,
    PostMedia,
    Button,
    ButtonGroup,
    ButtonLink,
    Icon,
    Message,
    Panel,
    Select,
    Tooltip
  }
})
export default class TwitterAnalyticsTable extends Vue {
  @categories.State('api_post_categories') categories: ICategoriesState['api_post_categories']
  @categories.State('api_category_posts') category_posts: ICategoriesState['api_category_posts']
  @twitter.State('api_posts') post_data: ITwitterState['api_posts']
  @twitter.State('api_table_setting') table_setting: ITwitterState['api_table_setting']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']
  @twitter.State('search_type') search_type: ITwitterState['search_type']
  @twitter.State('search_category') search_category: ITwitterState['search_category']
  @twitter.State('search_message') search_message: ITwitterState['search_message']
  @twitter.State('post_display_count') post_display_count: ITwitterState['post_display_count']
  @twitter.Action('changePostDisplayCount') changePostDisplayCount

  @Prop({ type: Number, default: null })
  height: number

  @Watch('posts.length')
  watchPostsLength() {
    this.pagination = 1
  }

  $refs: {
    hourly_chart_dialog: any
  }

  pagination = 1

  sort: {
    metric: string
    order: 'ascending' | 'descending'
  } = {
    metric: 'created_time',
    order: 'descending'
  }

  panel_tab: 'none' | 'type' | 'hashtag' | 'category' | 'character_count' = 'none'
  total_tab: 'average' | 'total' = 'average'

  created() {
    switch (this.panel_tab) {
      case 'type':
      case 'hashtag':
      case 'category':
        this.sort.metric = 'post_count'
        break
      default:
        this.sort.metric = 'created_time'
    }
  }

  get table_key() {
    return Date.now() + this.table_setting.columns.length
  }

  get table_columns() {
    return this.table_setting.columns
  }

  get posts() {
    let posts = [...this.post_data]

    if (this.search_type.length > 0) {
      posts = posts.filter(post => this.search_type.indexOf(post.type) >= 0)
    }

    if (this.search_category.length) {
      posts = posts.filter(post => {
        const category_ids = this.getSnsPostCategory(post.post_id)

        return category_ids.some(category_id => this.search_category.indexOf(category_id) >= 0)
      })
    }

    if (this.search_message !== '') {
      posts = posts.filter(
        post => post.message.toLowerCase().match(this.search_message.toLowerCase()) !== null
      )
    }

    switch (this.panel_tab) {
      case 'type':
        posts = this.createPostsByType(posts)
        break
      case 'hashtag':
        posts = this.createPostsByHashtag(posts)
        break
      case 'category':
        posts = this.createPostsByCategory(posts)
        break
      case 'character_count':
        posts = this.createPostsByCountMessage(posts)
        break
    }

    return posts
  }

  get panel_tabs() {
    return constants.POST_TABLE_TOTAL
  }

  get total_tabs() {
    return constants.POST_TABLE_TABS
  }

  get pagination_total() {
    return this.posts.length
  }

  get is_none() {
    return this.panel_tab === 'none'
  }

  get is_type() {
    return this.panel_tab === 'type'
  }

  get is_hashtag() {
    return this.panel_tab === 'hashtag'
  }

  get is_category() {
    return this.panel_tab === 'category'
  }

  get is_data() {
    return this.pagination_total > 0
  }

  get is_character_count() {
    return this.panel_tab === 'character_count'
  }

  get post_limit_options() {
    return [
      { text: '10', value: 10 },
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ]
  }

  get table_posts() {
    const chart_colors_01 = ['#1c84c6', '#ed5565', '#2ec881', '#eeae67', '#24cbe5']
    const chart_colors_02 = ['#fcdab7', '#ff8541']

    const page_begin = (this.pagination - 1) * this.post_display_count
    const page_end = page_begin + this.post_display_count

    let posts = this.posts

    const max = key =>
      Math.max.apply(
        null,
        posts.map(post => post[key])
      )

    const max_impressions = max('impressions')
    const max_reactions = max('reactions')
    const max_engagements = max('engagements')

    posts = posts.sort((a, b) => {
      switch (this.sort.order) {
        case 'ascending':
          return a[this.sort.metric] - b[this.sort.metric]
        case 'descending':
          return b[this.sort.metric] - a[this.sort.metric]
        default:
          return 0
      }
    })

    posts = posts.slice(page_begin, page_end)

    return posts.map(post => {
      // インプレッション
      const impressions: TTwitterAnalyticsTableCustomChart = {
        chart: {
          series: [post.impressions_organic, post.impressions_paid],
          colors: chart_colors_02,
          display_total: true,
          total: post.impressions,
          max: max_impressions
        },
        list: [
          {
            title: this.getLabel('impressions'),
            options: [
              {
                key: 'オーガニック',
                value: post.impressions_organic,
                point: { type: 'color', value: chart_colors_02[0] }
              },
              {
                key: '広告',
                value: post.impressions_paid,
                point: { type: 'color', value: chart_colors_02[1] }
              }
            ]
          }
        ]
      }

      // エンゲージメント数
      const reactions: TTwitterAnalyticsTableCustomChart = {
        chart: {
          series: [post.retweets, post.quotes, post.favorites, post.replies],
          colors: chart_colors_01,
          display_total: true,
          total: post.reactions,
          max: max_reactions
        },
        list: [
          {
            title: this.getLabel('reactions'),
            options: [
              {
                key: 'リポスト',
                value: post.retweets,
                point: { type: 'color', value: chart_colors_01[0] }
              },
              {
                key: '引用',
                value: post.quotes,
                point: { type: 'color', value: chart_colors_01[1] }
              },
              {
                key: 'いいね',
                value: post.favorites,
                point: { type: 'color', value: chart_colors_01[2] }
              },
              {
                key: '返信',
                value: post.replies,
                point: { type: 'color', value: chart_colors_01[3] }
              }
            ]
          }
        ]
      }

      // エンゲージメント数
      const engagements: TTwitterAnalyticsTableCustomChart = {
        chart: {
          series: [post.retweets, post.quotes, post.favorites, post.replies, post.other_actions],
          colors: chart_colors_01,
          display_total: true,
          total: post.engagements,
          max: max_engagements
        },
        list: [
          {
            title: this.getLabel('engagements'),
            options: [
              {
                key: 'リポスト',
                value: post.retweets,
                point: { type: 'color', value: chart_colors_01[0] }
              },
              {
                key: '引用',
                value: post.quotes,
                point: { type: 'color', value: chart_colors_01[1] }
              },
              {
                key: 'いいね',
                value: post.favorites,
                point: { type: 'color', value: chart_colors_01[2] }
              },
              {
                key: '返信',
                value: post.replies,
                point: { type: 'color', value: chart_colors_01[3] }
              },
              {
                key: 'その他アクション',
                value: post.other_actions,
                point: { type: 'color', value: chart_colors_01[4] }
              }
            ]
          }
        ]
      }

      return { ...post, impressions, reactions, engagements }
    })
  }

  /**
   * 投稿タイプ名の取得
   * @param {string} val 投稿タイプ
   * @returns {string} 投稿タイプ名
   */
  getPostType(val: string): string {
    const type = constants.POST_TYPE.find(b => b.value === val)
    return type ? type.text : ''
  }

  /**
   * タグの取得
   * @param {string} post_id 投稿ID
   * @returns {number[]} タグIDs
   */
  getSnsPostCategory(post_id: string): number[] {
    const category = this.category_posts.find(category_post => category_post.post_id === post_id)

    return category ? category.category_ids : []
  }

  /**
   * 投稿タイプの投稿データを作成
   * @param {any[]} filter_posts フィルタリング後の投稿データ
   * @returns {any[]} 集計した投稿データ
   */
  createPostsByType(filter_posts: any[]): any[] {
    const metrics = filter_posts.length > 0 ? Object.keys(filter_posts[0]) : []
    const types = ['text', 'photo', 'link', 'video', 'animation-gif']

    return types
      .map(type => {
        const posts = filter_posts.filter(p => p.type === type)

        const temp = { type: this.getPostType(type), post_count: posts.length }

        metrics.forEach(metric => {
          if (constants.POST_TABLE_SORT_BLACKLIST.indexOf(metric) === -1) {
            temp[metric] = this.getMetricValue(posts, metric)
          }
        })

        return temp
      })
      .filter(post => post.post_count > 0)
  }

  /**
   * ハッシュタグの投稿データを作成
   * @param {any[]} filter_posts フィルタリング後の投稿データ
   * @returns {any[]} 集計した投稿データ
   */
  createPostsByHashtag(filter_posts: any[]): any[] {
    const metrics = filter_posts.length > 0 ? Object.keys(filter_posts[0]) : []
    const hashtags =
      filter_posts.length > 0
        ? filter_posts
            .map(p => p.tags)
            .reduce((a, b) => a.concat(b), [])
            .filter((x, i, self) => self.indexOf(x) === i)
        : []

    return hashtags.map(hashtag => {
      const posts = filter_posts.filter(p => p.tags.indexOf(hashtag) >= 0)

      const temp = { hashtag, post_count: posts.length }

      metrics.forEach(metric => {
        if (constants.POST_TABLE_SORT_BLACKLIST.indexOf(metric) === -1) {
          temp[metric] = this.getMetricValue(posts, metric)
        }
      })

      return temp
    })
  }

  /**
   * タグの投稿データを作成
   * @param {any[]} filter_posts フィルタリング後の投稿データ
   * @returns {any[]} 集計した投稿データ
   */
  createPostsByCategory(filter_posts: any[]): any[] {
    const metrics = filter_posts.length > 0 ? Object.keys(filter_posts[0]) : []

    return this.categories
      .map(category => {
        const posts = filter_posts.filter(p => {
          const category_ids = this.getSnsPostCategory(p.post_id)

          return category_ids.indexOf(category.id) >= 0
        })

        const temp = { category: category.name, post_count: posts.length }

        metrics.forEach(metric => {
          if (constants.POST_TABLE_SORT_BLACKLIST.indexOf(metric) === -1) {
            temp[metric] = this.getMetricValue(posts, metric)
          }
        })

        return temp
      })
      .filter(post => post.post_count > 0)
  }

  /**
   * 文字数の投稿データを作成
   * @param {any[]} filter_posts フィルタリング後の投稿データ
   * @returns {any[]} 集計した投稿データ
   */
  createPostsByCountMessage(filter_posts: any[]): any[] {
    const metrics = filter_posts.length > 0 ? Object.keys(filter_posts[0]) : []

    return constants.CHARACTER_RANGE.map(range => {
      const posts = filter_posts.filter(p => {
        if (!range.less_than) {
          return p.message.length >= range.greater_than
        }

        return p.message.length >= range.greater_than && p.message.length < range.less_than
      })
      const temp = {
        character_range: i18n.t(range.title),
        post_count: posts.length
      }

      metrics.forEach(metric => {
        if (constants.POST_TABLE_SORT_BLACKLIST.indexOf(metric) === -1) {
          temp[metric] = this.getMetricValue(posts, metric)
        }
      })

      return temp
    }).filter(post => post.post_count > 0)
  }

  /**
   * タグ名の取得
   * @param {string} post_id 投稿ID
   * @returns {string[]} タグ名
   */
  getCategoryName(post_id: string): string[] {
    const category_ids = this.getSnsPostCategory(post_id)

    if (!category_ids.length) return []

    return category_ids
      .map(category_id => {
        const value = this.categories.find(category => category.id === category_id)

        return value ? value.name : null
      })
      .filter(category_name => category_name)
  }

  /**
   * タグの判別
   * @param {string} post_id 投稿ID
   * @returns {boolean} 判定
   */
  isCategory(post_id: string): boolean {
    const category_ids = this.getSnsPostCategory(post_id)

    return category_ids.length > 0
  }

  /**
   * チャートの判別（詳細表示あり）
   * @param {string} val 指標
   * * @param {string}
   * @returns {boolean} 判定
   */
  isChartOnAnalyticsList(val: string): boolean {
    switch (val) {
      case 'reactions':
      case 'engagements':
      case 'impressions':
        return true
      default:
        return false
    }
  }

  /**
   * 時間帯別表示フラグをセット
   * @param {string} metric 指標
   * @param {IHourlyInsightsParams} hourly_insights
   * @returns {boolean} 判定
   */
  isHourlyChart(metric: string, hourly_insights: IHourlyInsightsParams): boolean {
    switch (metric) {
      case 'reactions':
      case 'retweets':
      case 'quotes':
      case 'favorites':
      case 'replies':
        return hourly_insights && Object.keys(hourly_insights).length > 0
      case 'impressions':
      case 'impressions_organic':
      case 'impressions_paid':
      case 'engagements':
        return true
      default:
        return false
    }
  }

  /**
   * パーセントの判別
   * @param {string} val 指標
   * @returns {boolean} 判定
   */
  isPercent(val: string): boolean {
    switch (val) {
      case 'reactions_rate':
      case 'engagements_rate':
        return true
      default:
        return false
    }
  }

  /**
   * 指標のデータがないか判断
   */
  isNotExistData(post: any, metric: string): boolean {
    if (post[metric] === null) {
      return true
    }

    if (constants.POST_PRIVATE_METRICS.includes(metric) && post.is_not_get_non_public_metrics) {
      return true
    }

    return constants.POST_CSV_IMPORT_METRICS.includes(metric) && !post.is_csv_import
  }

  /**
   * テーブルのスタイル追加
   * @param {any} val テーブルデータ
   * @returns {string} クラス文字列
   */
  styleSort(val: any): string {
    const columns = ['message', 'type', 'hashtag', 'category', 'character_range']
    if (columns.indexOf(val.column.property) >= 0) {
      return 'not-sort'
    }

    if (this.sort.metric === val.column.property) {
      return `sort-metric sort-${this.sort.order}`
    }

    return ''
  }

  /**
   * ソート変更時
   * @param {TTwitterAnalyticsTableSort} val
   * @returns {void}
   */
  onSort(val: any): void {
    if (['message', 'type', 'hashtag', 'category', 'character_range'].indexOf(val.property) >= 0) {
      return
    }

    TrackingService.sendEvent(
      `sort:自社分析(TW)>投稿|${this.panel_tab}:${this.getLabel(val.property)}`
    )

    if (val.property === this.sort.metric) {
      this.sort.order = this.sort.order === 'ascending' ? 'descending' : 'ascending'
    } else {
      this.sort = { metric: val.property, order: 'descending' }
      this.pagination = 1
    }
  }

  /**
   * 指標から合計・平均した値を取得
   * @param {any[]} posts 投稿データ
   * @param {string} metric 指標
   * @returns {number} 値
   */
  getMetricValue(posts: any[], metric: string): number {
    if (metric === 'is_csv_import') {
      return Number(posts.some(post => post.is_csv_import))
    }

    if (metric === 'is_not_get_non_public_metrics') {
      return Number(posts.every(post => post.is_not_get_non_public_metrics))
    }

    if (this.total_tab === 'average') {
      return twitter_insight.getMetricAverage(posts, metric)
    }

    switch (metric) {
      case 'reactions_rate':
      case 'engagements_rate':
        return null

      default:
        return twitter_insight.getMetricTotal(posts, metric)
    }
  }

  /**
   * ラベルの取得
   * @param {string} val 指標
   * @returns {string} ラベル
   */
  getLabel(val: string): string {
    let metrics = []

    switch (this.panel_tab) {
      case 'type':
        metrics = constants.POST_TABLE_TYPE_METRICS
        break
      case 'hashtag':
        metrics = constants.POST_TABLE_HASHTAG_METRICS
        break
      case 'category':
        metrics = constants.POST_TABLE_CATEGORY_METRICS
        break
      case 'character_count':
        metrics = constants.POST_TABLE_CHARACTER_COUNT_METRICS
        break
      default:
        metrics = constants.POST_TABLE_METRICS
        break
    }

    const metric = metrics.find(b => b.value === val)
    return metric ? metric.text : ''
  }

  @Emit('open-embed')
  openEmbed(post_id: string) {
    TrackingService.sendEvent('click:自社分析(TW)>投稿|公開された投稿:プレビュー')

    return { post_id }
  }

  @Emit('click-category-setting')
  onClickCategorySetting(account_id: string, post_id: string) {
    TrackingService.sendEvent('click:自社分析(TW)>投稿|公開された投稿:タグ設定')

    const category_ids = this.getSnsPostCategory(post_id)
    return {
      account_id,
      post_id,
      category_ids
    }
  }

  @Emit('click-data-import')
  onClickDataImport(e: Event) {
    TrackingService.sendEvent('click:自社分析(TW)>投稿|データインポート')

    return e
  }

  /**
   * SNSで確認をクリック
   * @param {string} account_id ページID
   * @param {string} post_id 投稿ID
   * @returns {void}
   */
  onClickDetail(account_id: string, post_id: string): void {
    TrackingService.sendEvent('click:自社分析(TW)>投稿|公開された投稿:SNSで確認')

    window.open(`https://x.com/${account_id}/status/${post_id}`)
  }

  /**
   * ダウンロードボタンを押した時
   * @param {string} type ダウンロード形式
   * @returns {void}
   */
  onDownload(type?: 'all'): void {
    const download_type = type === 'all' ? 'CSVダウンロード(全ての列)' : 'CSVダウンロード(表示列)'

    TrackingService.sendEvent(`click:自社分析(TW)>投稿|${this.panel_tab}:${download_type}`)

    let columns = [
      'created_time',
      'message',
      'type',
      'post_id',
      'post_url',
      'picture_url',
      'video_url',
      'link_title',
      'link_description',
      'link_url',
      'category_name'
    ]

    switch (this.panel_tab) {
      case 'type':
        columns = ['type', 'post_count']
        break
      case 'hashtag':
        columns = ['hashtag', 'post_count']
        break
      case 'category':
        columns = ['category', 'post_count']
        break
      case 'character_count':
        columns = ['character_range', 'post_count']
        break
    }

    switch (type) {
      case 'all':
        columns = columns.concat(constants.TABLE_COLUMN_METRICS.map(v => v.value))
        break
      default:
        columns = columns.concat(this.table_columns)
        break
    }

    const fields = columns.map(v => i18n.t(this.getLabel(v)))

    const data = this.posts.map(post =>
      columns.map(column => {
        if (column === 'created_time') {
          return csv.format(post[column])
        }

        if (column === 'type') {
          return i18n.t(post[column])
        }

        if (column === 'post_url') {
          return `https://x.com/${post.account_id}/status/${post.post_id}`
        }

        if (column === 'picture_url') {
          if (post.type === 'photo') {
            return post.picture_url
          }
        }

        if (column === 'link_title') {
          if (post.type === 'link' && post.link_data.title) {
            return post.link_data.title
          } else {
            return ''
          }
        }

        if (column === 'link_description') {
          if (post.type === 'link' && post.link_data.description) {
            return post.link_data.description
          } else {
            return ''
          }
        }

        if (column === 'link_url') {
          if (post.type === 'link' && post.link_data.url) {
            return post.link_data.url
          } else {
            return ''
          }
        }

        if (column === 'category_name') {
          return this.getCategoryName(post.post_id)
        }

        if (this.isNotExistData(post, column)) {
          return '-'
        }

        return post[column]
      })
    )

    const csv_data = { fields, data }

    const total = constants.POST_TABLE_TOTAL.find(b => b.value === this.panel_tab)
    const component_name = i18n.t(total ? total.text : '')

    let csv_filename = [component_name, this.start_date, this.end_date]

    switch (this.panel_tab) {
      case 'type':
      case 'hashtag':
      case 'category':
      case 'character_count': {
        const total_tab = constants.POST_TABLE_TABS.find(v => v.value === this.total_tab)
        const total_name = i18n.t(total_tab ? total_tab.text : '')

        csv_filename = [component_name, total_name, this.start_date, this.end_date]
        break
      }
    }

    if (type) {
      csv_filename = csv_filename.concat([type])
    }

    csv.download(csv_data, csv_filename.join('_'))
  }

  /**
   * 投稿の表示件数の変更
   */
  async changeDisplayCount(display_count: number) {
    this.pagination = 1
    await this.changePostDisplayCount(display_count)
  }

  /**
   * スクロール位置を最上部に変更する
   * @returns {void}
   */
  scrollTop(): void {
    // el-table のスクロール位置を変更するため、DOM要素を取得する
    const element = document.getElementsByClassName('el-table__body-wrapper')
    if (element && element.length) {
      element[0].scrollTop = 0
    }
  }

  /**
   * 時間帯別ダイアログを開く
   * @param {string} metric
   * @param {IHourlyInsightsParams} hourly_insights
   * @returns {void}
   */
  openHourlyChart(metric: string, hourly_insights: IHourlyInsightsParams) {
    TrackingService.sendEvent(`click:自社分析(TW)>投稿|公開された投稿:${metric}:時間帯別`)

    const hours = Object.keys(hourly_insights)
    const data = this.makeData(metric, hours, hourly_insights)
    const categories = data.categories
    let series = []
    let colors = []
    let display_legend = false
    switch (metric) {
      case 'reactions':
        series = [
          {
            name: i18n.t('リポスト'),
            data: this.makeData('retweets', hours, hourly_insights).data,
            index: 0
          },
          {
            name: i18n.t('引用'),
            data: this.makeData('quotes', hours, hourly_insights).data,
            index: 1
          },
          {
            name: i18n.t('いいね'),
            data: this.makeData('favorites', hours, hourly_insights).data,
            index: 2
          },
          {
            name: i18n.t('返信'),
            data: this.makeData('replies', hours, hourly_insights).data,
            index: 3
          }
        ]
        colors = ['#1c84c6', '#ed5565', '#2ec881', '#eeae67']
        display_legend = true
        break
      case 'impressions':
        series = [
          {
            name: i18n.t('オーガニック'),
            data: this.makeData('impressions_organic', hours, hourly_insights).data,
            index: 0
          },
          {
            name: i18n.t('広告'),
            data: this.makeData('impressions_paid', hours, hourly_insights).data,
            index: 1
          }
        ]
        colors = ['#fcdab7', '#ff8541']
        display_legend = true
        break
      case 'engagements':
        series = [
          {
            name: i18n.t('リポスト'),
            data: this.makeData('retweets', hours, hourly_insights).data,
            index: 0
          },
          {
            name: i18n.t('引用'),
            data: this.makeData('quotes', hours, hourly_insights).data,
            index: 1
          },
          {
            name: i18n.t('いいね'),
            data: this.makeData('favorites', hours, hourly_insights).data,
            index: 2
          },
          {
            name: i18n.t('返信'),
            data: this.makeData('replies', hours, hourly_insights).data,
            index: 3
          },
          {
            name: i18n.t('その他アクション'),
            data: this.makeData('other_actions', hours, hourly_insights).data,
            index: 4
          }
        ]
        colors = ['#1c84c6', '#ed5565', '#2ec881', '#eeae67', '#24cbe5']
        display_legend = true
        break
      default:
        series = [
          {
            name: i18n.t(this.getLabel(metric)),
            data: data.data,
            index: 0
          }
        ]
        colors = ['#1c84c6']
        break
    }

    const description =
      metric === 'engagements'
        ? i18n.t(
            'その他アクションは、URLクリック数とユーザープロフィールクリック数のみ反映されます。'
          )
        : ''

    const chart_options = {
      title: this.getLabel(metric),
      description,
      time_range: {
        start: hours.length > 0 ? hours[0] : '',
        end: hours.length > 0 ? hours[hours.length - 1] : ''
      },
      data,
      series,
      colors,
      display_legend,
      categories
    }

    this.$refs.hourly_chart_dialog.open(chart_options)
  }

  /**
   * データを配列にする
   * @param {string} metric
   * @param {string[]} hours
   * @param {IHourlyInsightsParams} hourly_insights
   * @returns {any} データ
   */
  makeData(metric: string, hours: string[], hourly_insights: IHourlyInsightsParams) {
    const hourly_data = []
    for (const key of hours) {
      const hour = moment(key, 'YYYY-MM-DD HH:mm').hour()
      hourly_data[hour] = hourly_insights[key][metric]
    }

    const first_hour = hours.length > 0 ? moment(hours[0], 'YYYY-MM-DD HH:mm').hour() : 0
    const data = []
    const categories = []
    let count = 0

    while (count < 24) {
      let hour = first_hour + count
      if (hour >= 24) {
        hour = hour - 24
      }

      const value = hourly_data[hour] || hourly_data[hour] === 0 ? hourly_data[hour] : null
      data.push(value)
      categories.push(this.toHourString(hour))

      count++
    }

    return { data, categories }
  }

  /**
   * タブの変更
   */
  changePanelTab(tab: string) {
    TrackingService.sendEvent(`click:自社分析(TW)>投稿|表示切替:${tab}`)
  }

  /**
   * 平均と合計の変更
   */
  changeTotalTab(value: string) {
    TrackingService.sendEvent(`click:自社分析(TW)>投稿|${this.panel_tab}:${value}`)
  }

  /**
   * 時間を文字列に変換
   * @param {number} hour
   * @returns {string} 時間
   */
  toHourString(hour: number) {
    if (hour < 10) {
      return `0${hour}:00`
    }

    return `${hour}:00`
  }
}
