
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter, namespace, State } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import { TrackingService } from '@/client/services'
import { IRootGetter, IRootState } from '@/client/store/global'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import { getTranslateText as t } from '@/client/utils/filters'

type Category = 'post' | 'analytics' | 'library' | 'setting' | false

const POSTS = ['posts', 'posts/create']

const ANALYTICS = [
  'sns_analytics',
  'analytics_fb',
  'report_fb',
  'comparison_fb',
  'analytics_tw',
  'report_tw',
  'comparison_tw',
  'twitter_viral',
  'analytics_in',
  'report_in',
  'comparison_in',
  'instagram_hashtag',
  'analytics_tt',
  'report_tt'
]

const LIBRARY = ['library_twitter_trend', 'library_engagement_ranking']

const SETTING = ['setting_personal', 'setting_group', 'setting_billing_info']

const accounts = namespace('accounts')

@Component({
  name: 'GlobalSidebar',
  components: {
    Icon
  }
})
export default class GlobalSidebar extends Vue {
  @State('user') user!: IRootState['user']
  @State('projects') projects!: IRootState['projects']
  @State('project') project!: IRootState['project']
  @State('project_role') project_role!: IRootState['project_role']
  @Getter('is_restricted') is_restricted!: IRootGetter['is_restricted']
  @accounts.Getter('post') post!: IAccountsGetter['post']
  @accounts.Getter('facebook_analytics') facebook_analytics!: IAccountsGetter['facebook_analytics']
  @accounts.Getter('twitter_analytics') twitter_analytics!: IAccountsGetter['twitter_analytics']
  @accounts.Getter('instagram_analytics')
  instagram_analytics!: IAccountsGetter['instagram_analytics']
  @accounts.Getter('tiktok_analytics') tiktok_analytics!: IAccountsGetter['tiktok_analytics']
  @accounts.Getter('account_count') account_count!: IAccountsGetter['account_count']
  @accounts.Getter('monitoring') monitoring!: IAccountsGetter['monitoring']

  @Prop({ type: String, required: true })
  name!: string

  @Prop({ type: Boolean, required: true })
  visibleSp!: boolean

  is_hover = false
  category: Category = false

  $refs!: {
    sidebar: HTMLDivElement
  }

  get is_text() {
    return this.$mq === 'md' ? this.is_hover : true
  }

  get is_post() {
    return this.project.is_use_post && this.post.length > 0 && !this.is_restricted
  }

  get is_analytics() {
    return (
      this.project.is_use_analytics &&
      (this.is_facebook_analytics ||
        this.is_twitter_analytics ||
        this.is_instagram_analytics ||
        this.is_tiktok_analytics) &&
      !this.is_restricted &&
      this.$mq !== 'sm'
    )
  }

  get is_facebook_analytics() {
    return this.facebook_analytics.length > 0
  }

  get is_twitter_analytics() {
    return this.twitter_analytics.length > 0
  }

  get is_instagram_analytics() {
    return this.instagram_analytics.length > 0
  }

  get is_tiktok_analytics() {
    return this.tiktok_analytics.length > 0
  }

  get is_use_in_hashtag() {
    return this.project.is_use_in_hashtag && this.project_role.role_keyword
  }

  get is_use_tw_viral() {
    return this.project.is_use_tw_viral && this.project_role.role_viral && !this.is_restricted
  }

  get is_monitoring() {
    return this.project.is_use_monitoring && this.monitoring.length > 0 && !this.is_restricted
  }

  get is_library() {
    return (
      this.projects.length > 0 && this.account_count > 0 && !this.is_restricted && this.$mq !== 'sm'
    )
  }

  get is_setting() {
    return !this.is_restricted && !this.user.is_demo_user
  }

  get is_group_setting() {
    return this.project_role.role_admin
  }

  get is_category_post() {
    return this.is_text && this.category === 'post'
  }

  get is_category_analytics() {
    return this.is_text && this.category === 'analytics'
  }

  get is_category_library() {
    return this.is_text && this.category === 'library'
  }

  get is_category_setting() {
    return this.is_text && this.category === 'setting'
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  @Emit('close-sidebar')
  closeSidebar(payload: any) {
    return payload
  }

  @Emit('open-support-chat')
  openSupportChat(payload: any) {
    return payload
  }

  @Watch('name', { immediate: true })
  watchMetaName() {
    if (POSTS.includes(this.name)) {
      this.category = 'post'
    } else if (ANALYTICS.includes(this.name)) {
      this.category = 'analytics'
    } else if (LIBRARY.includes(this.name)) {
      this.category = 'library'
    } else if (SETTING.includes(this.name)) {
      this.category = 'setting'
    } else {
      this.category = false
    }
  }

  async mounted() {
    await this.$nextTick()

    this.handleResize()

    window.addEventListener('resize', this.handleResize, false)
  }

  async beforeDestroy() {
    await this.$nextTick()

    window.removeEventListener('resize', this.handleResize)
  }

  /**
   * リサイズのイベントハンドリング
   */
  handleResize() {
    if (this.$refs.sidebar) {
      const top = this.$refs.sidebar.getBoundingClientRect().top
      const height = window.innerHeight - top
      const css = `${height}px`

      if (this.$refs.sidebar.style.height !== css) {
        this.$refs.sidebar.style.height = css
      }
    }
  }

  /**
   * マウスホバーになった場合
   */
  mouseover() {
    if (this.$mq === 'md') {
      this.is_hover = true
    }
  }

  /**
   * マウスホバーが外れた場合
   */
  mouseleave() {
    if (this.$mq === 'md') {
      this.is_hover = false
    }
  }

  /**
   * タグが開いてるかどうか
   */
  isCategory(category: Category) {
    let active = false

    if (category === 'post') {
      active = POSTS.includes(this.name)
    } else if (category === 'analytics') {
      active = ANALYTICS.includes(this.name)
    } else if (category === 'library') {
      active = LIBRARY.includes(this.name)
    } else if (category === 'setting') {
      active = SETTING.includes(this.name)
    }

    return {
      open: this.category === category,
      active
    }
  }

  /**
   * タグの切り替え
   */
  changeCategory(category: Category) {
    if (this.category === category) {
      this.category = false
    } else {
      this.category = category
    }
  }

  /**
   * 現在のページかどうか
   */
  isPage(name: string) {
    return {
      active: this.name === name
    }
  }

  /**
   * ページの切り替え
   */
  changePage(name: string, event: string) {
    if (this.name !== name) {
      TrackingService.sendEvent(event)
      this.changeRoute({ name })
    }

    this.closeSidebar({ name })
  }

  /**
   * ヘルプを表示
   */
  openHelpCenter() {
    TrackingService.sendEvent('View Help Center')

    window.open(t('TOP>url'), '_blank')
  }

  openLibrary() {
    TrackingService.sendEvent('View Library')

    window.open('https://www.comnico.jp/library/cms/', '_blank')
  }
}
