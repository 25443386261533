import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import {
  PostTagsManagementDialog,
  PostTagsSnsAnalyticsSettingDialog
} from '@/client/components/organisms/Tags'
import * as constants from '@/client/components-old/_constants/instagram_comparison_analytics'
import Box from '@/client/components-old/atoms/Box'
import Button from '@/client/components-old/atoms/Button'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Scroll from '@/client/components-old/atoms/Scroll'
import Select from '@/client/components-old/atoms/Select'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import Search from '@/client/components-old/molecules/Search'
import InstagramComparisonAnalyticsPostTable from '@/client/components-old/organisms/InstagramComparisonAnalyticsPostTable'
import SnsEmbedDialog from '@/client/components-old/organisms/SnsEmbedDialog'
import { TrackingService } from '@/client/services'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as IInstagramState } from '@/client/store/modules/instagram_comparison_analytics'

const categories = namespace('categories')
const instagram = namespace('instagram_comparison_analytics')

@Component({
  name: 'InstagramComparisonAnalyticsPost',
  components: {
    PostTagsManagementDialog,
    PostTagsSnsAnalyticsSettingDialog,
    InstagramComparisonAnalyticsPostTable,
    SnsEmbedDialog,
    Search,
    Box,
    Button,
    Flex,
    Icon,
    Scroll,
    Select,
    SelectMulti
  }
})
export default class InstagramComparisonAnalyticsPost extends Vue {
  @categories.State('api_post_categories') categories: ICategoriesState['api_post_categories']
  @instagram.State('screen_name') screen_name: IInstagramState['screen_name']
  @instagram.State('search_type') search_type: IInstagramState['search_type']
  @instagram.State('search_category') search_category: IInstagramState['search_category']
  @instagram.State('search_message') search_message: IInstagramState['search_message']
  @instagram.Mutation('SET_SEARCH_TYPE') setSearchType
  @instagram.Mutation('SET_SEARCH_CATEGORY') setSearchCategory
  @instagram.Mutation('SET_SEARCH_MESSAGE') setSearchMessage

  $refs: {
    SnsEmbedDialog: any
  }

  table_height: number | null = null
  message = ''

  @Watch('screen_name', { immediate: true })
  changeScreenName() {
    this.message = this.search_message
  }

  get search_type_options() {
    return constants.POST_TYPE.map(type => ({
      ...type,
      text: this.$options.filters.translate(type.text)
    }))
  }

  get search_category_options() {
    return this.categories.map(v => ({ text: v.name, value: v.id }))
  }

  get table_content_height() {
    return this.table_height - 128
  }

  /**
   * 投稿内容の検索
   * @returns {void}
   */
  submitSearchMessage(): void {
    this.setSearchMessage(this.message)

    TrackingService.sendEvent(`click:競合比較(IG)>投稿|タブ内ヘッダー|キーワード検索`)
  }

  /**
   * スクロールの変更
   * @param {number} height 内部の高さ
   * @returns {void}
   */
  changeHeight(height: number): void {
    this.table_height = height
  }

  /**
   * 投稿タイプを設定
   */
  onSetSearchType(payload: string) {
    this.setSearchType(payload)

    TrackingService.sendEvent(`select:競合比較(IG)>投稿|タブ内ヘッダー|投稿タイプ`)
  }

  /**
   * タグを設定
   */
  onSetSearchCategory(payload: string) {
    this.setSearchCategory(payload)

    TrackingService.sendEvent(`select:競合比較(IG)>投稿|タブ内ヘッダー|タグ`)
  }

  /**
   * タグの管理を開く
   */
  onPostTagsSnsAnalyticsSettingDialog(event: any) {
    this.$refs.SnsEmbedDialog.open(event)

    TrackingService.sendEvent(`click:競合比較(IG)>投稿|タブ内ヘッダー|タグの管理`)
  }
}
