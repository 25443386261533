import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/facebook_target'
import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { TrackingService } from '@/client/services'
import { IState as IPostCreateState } from '@/client/store/modules/post_create'

const post_create = namespace('post_create')

@Component({
  name: 'PostCreateFacebookTarget',
  components: {
    Button,
    Dialog,
    Flex,
    Icon,
    Input,
    Panel,
    Tooltip,
    Select,
    SelectMulti,
    SwitchButton
  }
})
export default class PostCreateFacebookTarget extends Vue {
  @post_create.State('facebook_target') facebook_target: IPostCreateState['facebook_target']
  @post_create.Mutation('SET_FACEBOOK_TARGET') setFacebookTarget

  get is_facebook_target() {
    return Boolean(this.facebook_target)
  }

  set is_facebook_target(value) {
    TrackingService.sendEvent('switch:投稿作成|Facebookターゲット')

    if (value) {
      this.setFacebookTarget({})
    } else {
      this.setFacebookTarget(null)
    }
  }

  get age_min() {
    return this.facebook_target && this.facebook_target.age_min ? this.facebook_target.age_min : 13
  }

  get age_min_options() {
    return constants.AGES
  }

  get countries() {
    return this.facebook_target && this.facebook_target.countries
      ? this.facebook_target.countries
      : []
  }

  get country_options() {
    return constants.COUNTRIES.map(item => ({
      value: item.value,
      text: this.$options.filters.translate(item.text)
    }))
  }

  get regions() {
    return this.facebook_target && this.facebook_target.regions ? this.facebook_target.regions : []
  }

  get region_options() {
    return constants.REGIONS.map(item => ({
      value: item.value,
      text: this.$options.filters.translate(item.text)
    }))
  }

  get is_regions_disabled() {
    return this.countries.length !== 1 || this.countries[0] !== 'JP'
  }

  /**
   * 国を設定
   * @param {string[]} value
   * @returns {void}
   */
  setCountry(value: string[]): void {
    TrackingService.sendEvent('select:投稿作成|Facebookターゲット|国')

    const facebook_target = this.facebook_target ? Object.assign({}, this.facebook_target) : {}

    facebook_target['countries'] = value

    if (value.length !== 1 || value[0] !== 'JP') {
      delete facebook_target['regions']
    }

    this.setFacebookTarget(facebook_target)
  }

  /**
   * 年齢以上を設定
   * @param {number} value
   * @returns {void}
   */
  setAgeMin(value: number): void {
    TrackingService.sendEvent('select:投稿作成|Facebookターゲット|年齢(下限)')

    const facebook_target = this.facebook_target ? Object.assign({}, this.facebook_target) : {}

    facebook_target['age_min'] = value

    this.setFacebookTarget(facebook_target)
  }

  /**
   * 地域を設定
   * @param {number[]} value
   * @returns {void}
   */
  setRegions(value: number[]): void {
    TrackingService.sendEvent('select:投稿作成|Facebookターゲット|地域')

    const facebook_target = this.facebook_target ? Object.assign({}, this.facebook_target) : {}

    if (!value || value.length === 0) {
      delete facebook_target['regions']
    } else {
      facebook_target['regions'] = value
    }

    this.setFacebookTarget(facebook_target)
  }
}
