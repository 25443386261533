
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { MemoListItem } from '@/client/components/molecules'
import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import TextareaEmoji from '@/client/components-old/molecules/TextareaEmoji'
import UserMultiSelect from '@/client/components-old/molecules/UserMultiSelect'
import { IGetter, IState } from '@/client/store/modules/post_management'
import storage from '@/client/utils/storage'

const post_management = namespace('post_management')
const notification = namespace('notification')

@Component({
  name: 'PostDetailMemo',
  components: {
    MemoListItem,
    Message,
    Button,
    Icon,
    TextareaEmoji,
    UserMultiSelect
  }
})
export default class PostDetailMemo extends Vue {
  @post_management.State('api_post') api_post: IState['api_post']
  @post_management.State('api_post_memos') api_post_memos: IState['api_post_memos']
  @post_management.State('api_persons') api_persons: IState['api_persons']
  @post_management.State('dialog_post_mode') dialog_post_mode: IState['dialog_post_mode']
  @post_management.State('memo_cache') memo_cache: IState['memo_cache']
  @post_management.Getter('dialog_memo_users') dialog_memo_users: IGetter['dialog_memo_users']
  @post_management.Action('createPostMemo') createPostMemo
  @post_management.Action('updatePostMemo') updatePostMemo
  @post_management.Action('removePostMemo') removePostMemo
  @post_management.Action('changePostMemoCache') changePostMemoCache
  @notification.Action('showNotification') showNotification

  @Prop({ type: String, default: 'create' })
  pageType: 'create' | 'edit' | 'copy'

  message = ''
  to_user_ids: number[] = []
  is_submit = false

  get disabled() {
    return !this.message.trim() || this.is_submit
  }

  get is_add_memo_mode() {
    return this.dialog_post_mode !== 'create' || this.pageType === 'edit'
  }

  @Watch('api_post')
  watchAPIPost() {
    this.restoreMemo()
  }

  mounted() {
    this.restoreMemo()
  }

  /**
   * メモの復元
   */
  restoreMemo() {
    const sns_post_id = this.api_post ? this.api_post.id : 0

    if (this.memo_cache.memo_type === 'memo' && this.memo_cache.sns_post_id === sns_post_id) {
      this.message = this.memo_cache.message
    }

    const local: { to_user_ids?: number[] } = storage.get('post_management')

    // ストレージに保存されていたら使用する
    if (local && local.to_user_ids && Array.isArray(local.to_user_ids)) {
      this.to_user_ids = local.to_user_ids.filter(v =>
        this.dialog_memo_users.map(v => v.id).includes(v)
      )
    }
  }

  /**
   * 投稿メモを送信
   */
  async submitPostMemo() {
    if (this.disabled) return

    this.is_submit = true

    const result = await this.createPostMemo({
      message: this.message.trim(),
      to_user_ids: this.to_user_ids
    })

    this.is_submit = false

    if (result.data && result.data.id) {
      this.message = ''
      this.showNotification({ title: 'メモを追加しました。' })
      return this.updateMemo(result.data)
    }

    this.showNotification({
      title: 'メモの追加に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * 投稿メモを更新
   */
  async submitUpdateMemo(event: { id: number; message: string; to_user_ids: number[] }) {
    if (this.is_submit) return

    this.is_submit = true

    const result = await this.updatePostMemo(event)

    this.is_submit = false

    if (result.data) {
      this.showNotification({ title: 'メモを更新しました。' })
      this.updateMemo(result.data)
      return
    }

    this.showNotification({
      title: 'メモの更新に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * 投稿メモを削除
   */
  async submitDeleteMemo(sns_post_memo_id: number) {
    if (this.is_submit) return

    this.is_submit = true

    const result = await this.removePostMemo(sns_post_memo_id)

    this.is_submit = false

    if (result.data) {
      this.showNotification({ title: 'メモを削除しました。' })
      this.updateMemo(result.data)
      return
    }

    this.showNotification({
      title: 'メモの削除に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * 投稿メモの保存
   */
  async savePostMemoCache(message: string): Promise<void> {
    await this.changePostMemoCache({
      sns_post_id: this.api_post ? this.api_post.id : 0,
      memo_type: 'memo',
      message
    })
  }

  @Emit('update-memo')
  updateMemo(payload: any) {
    return payload
  }
}
