
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, namespace, State } from 'vuex-class'

import GroupSettingHeader from '@/client/components-old/organisms/GroupSettingHeader'
import GroupSettingAnalyticsLayout from '@/client/components-old/templates/GroupSettingAnalyticsLayout'
import GroupSettingContractLayout from '@/client/components-old/templates/GroupSettingContractLayout'
import GroupSettingGroupLayout from '@/client/components-old/templates/GroupSettingGroupLayout'
import GroupSettingMonitoringLayout from '@/client/components-old/templates/GroupSettingMonitoringLayout'
import GroupSettingOperatorLayout from '@/client/components-old/templates/GroupSettingOperatorLayout'
import GroupSettingPostLayout from '@/client/components-old/templates/GroupSettingPostLayout'
import GroupSettingSnsLayout from '@/client/components-old/templates/GroupSettingSnsLayout'
import { IRootState } from '@/client/store/global'
import { IState as TGroupSettingState } from '@/client/store/modules/group_setting'
import storage from '@/client/utils/storage'

const accounts = namespace('accounts')
const post_templates = namespace('post_templates')
const monitoring_templates = namespace('monitoring_templates')
const group_setting = namespace('group_setting')

@Component({
  name: 'GroupSetting',
  components: {
    GroupSettingHeader,
    GroupSettingAnalyticsLayout,
    GroupSettingContractLayout,
    GroupSettingGroupLayout,
    GroupSettingMonitoringLayout,
    GroupSettingOperatorLayout,
    GroupSettingPostLayout,
    GroupSettingSnsLayout
  }
})
export default class GroupSetting extends Vue {
  @State('project') project!: IRootState['project']
  @State('project_role') project_role!: IRootState['project_role']
  @Action('fetchProjects') fetchProjects!: any
  @Action('fetchProjectData') fetchProjectData!: any
  @Action('fetchProjectRole') fetchProjectRole!: any
  @accounts.Action('fetchSnsAccounts') fetchSnsAccounts!: any
  @post_templates.Action('fetchTemplate') fetchPostTemplate!: any
  @monitoring_templates.Action('fetchTemplate') fetchMonitoringTemplate!: any
  @group_setting.State('screen_name') screen_name!: TGroupSettingState['screen_name']
  @group_setting.State('function_tab_name')
  function_tab_name!: TGroupSettingState['function_tab_name']
  @group_setting.State('is_loading') is_loading!: TGroupSettingState['is_loading']
  @group_setting.Action('createdGroupSetting') createdGroupSetting!: any
  @group_setting.Action('destroyedGroupSetting') destroyedGroupSetting!: any
  @group_setting.Action('changeScreenName') changeScreenName!: any
  @group_setting.Action('changeFunctionTabName') changeFunctionTabName!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  get is_account() {
    return this.screen_name === 'account'
  }

  get is_operator() {
    return this.screen_name === 'operator'
  }

  get is_function_post() {
    return this.screen_name === 'function' && this.function_tab_name === 'post'
  }

  get is_function_analytics() {
    return this.screen_name === 'function' && this.function_tab_name === 'analytics'
  }

  get is_function_monitoring() {
    return this.screen_name === 'function' && this.function_tab_name === 'monitoring'
  }

  get is_function_group() {
    return this.screen_name === 'function' && this.function_tab_name === 'group'
  }

  get is_contract() {
    return this.screen_name === 'contract'
  }

  @Emit('change-project')
  changeProject(payload: any) {
    return payload
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  @Watch('group')
  async watchGroup() {
    await this.initialize()
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.initialize()
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedGroupSetting()
  }

  /**
   * 初期化
   */
  async initialize() {
    if (this.$route.query.screen_name) {
      await this.changeScreenName(this.$route.query.screen_name)
    }

    if (this.$route.query.function_tab_name) {
      await this.changeFunctionTabName(this.$route.query.function_tab_name)
    }

    await Promise.all([
      this.fetchSnsAccounts(),
      this.fetchPostTemplate(),
      this.fetchMonitoringTemplate(),
      this.createdGroupSetting()
    ])
  }

  /**
   * グループ設定でチェックが必要な変更がされた場合
   */
  async changeVue() {
    const temp = this.project.id

    await this.fetchProjects()

    const project_id = storage.getGroupId()

    await this.fetchProjectData(project_id)
    await this.fetchProjectRole(project_id)

    // 自分のグループ権限が無くなった場合
    if (!this.project_role.role_admin) {
      this.changeRoute({ name: 'dashboard' })
    }

    // 自分がグループから外れた場合
    if (this.project.id !== temp) {
      this.changeRoute({ name: 'dashboard' })
    }
  }
}
