import { render, staticRenderFns } from "./PostCreateCollageDialog.vue?vue&type=template&id=1f971b46&scoped=true"
import script from "./PostCreateCollageDialog.ts?vue&type=script&lang=ts&external"
export * from "./PostCreateCollageDialog.ts?vue&type=script&lang=ts&external"
import style0 from "./PostCreateCollageDialog.vue?vue&type=style&index=0&id=1f971b46&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f971b46",
  null
  
)

export default component.exports