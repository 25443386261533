import { ref } from 'vue'

import API from '@/client/utils/api'
import {
  IGetPostCategoriesParams,
  IGetPostCategoriesResponse,
  IPostCategories,
  IPostPostCategoriesParams,
  IPostPostCategoriesResponse,
  IPutPostCategoryChangeOrderNoParams,
  IPutPostCategoryChangeOrderNoResponse,
  IPutPostCategoryParams,
  IPutPostCategoryResponse
} from '@/client/utils/api/post_categories'

export const usePostsTag = (getProjectId: () => number) => {
  const project_id = getProjectId()
  const post_categories = ref<IPostCategories[]>([])
  const is_posts_loading = ref(false)

  const fetchPostCategories = async () => {
    const params: IGetPostCategoriesParams = {
      project_id
    }

    is_posts_loading.value = true

    const response = await API.get<IGetPostCategoriesResponse>('post_categories', { params })

    is_posts_loading.value = false

    if (response.data.data) {
      post_categories.value = response.data.data
    }
    return response
  }

  const createPostCategory = async (name: string) => {
    const params: IPostPostCategoriesParams = {
      project_id,
      category_name: name
    }

    const response = await API.post<IPostPostCategoriesResponse>('post_categories', params)

    if (response.data.data) {
      await fetchPostCategories()
    }

    return response.data
  }

  const updatePostCategory = async (category_id: number, name: string) => {
    const params: IPutPostCategoryParams = {
      category_name: name
    }

    const response = await API.put<IPutPostCategoryResponse>(
      `post_categories/${category_id}`,
      params
    )

    await fetchPostCategories()

    return response.data
  }

  const removePostCategory = async (category_id: number) => {
    const response = await API.delete(`post_categories/${category_id}`)

    await fetchPostCategories()

    return response.data
  }

  const updatePostsCategoryOrder = async (payload: IPostCategories[]) => {
    post_categories.value = payload

    const params: IPutPostCategoryChangeOrderNoParams = {
      project_id,
      category_ids: payload.map(v => v.id)
    }

    const response = await API.put<IPutPostCategoryChangeOrderNoResponse>(
      'post_categories/change_order_no',
      params
    )

    await fetchPostCategories()

    return response.data
  }

  return {
    is_posts_loading,
    post_categories,
    fetchPostCategories,
    createPostCategory,
    updatePostCategory,
    updatePostsCategoryOrder,
    removePostCategory
  }
}
