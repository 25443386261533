import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/facebook_analytics'
import * as calculation from '@/client/components-old/_utils/calculation'
import * as facebook_insight from '@/client/components-old/_utils/facebook_insight'
import Gender from '@/client/components-old/atoms/Gender'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartHorizontal from '@/client/components-old/molecules/ChartHorizontal'
import { TrackingService } from '@/client/services'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as IFacebookState } from '@/client/store/modules/facebook_analytics'
import csv from '@/client/utils/csv'
import { getTranslateText as t } from '@/client/utils/filters'

type TDailyData = {
  data_type: 'daily_data'
  metric:
    | 'page_fans_by_like_source_unique'
    | 'page_fans_gender_age'
    | 'page_fans_city'
    | 'page_reach_type'
    | 'page_impression_type'
    | 'page_impressions_by_age_gender_unique'
    | 'page_impressions_by_city_unique'
    | 'page_impressions_by_story_type_unique'
    | 'page_tab_views_login_top_unique'
    | 'page_views_external_referrals'
    | 'page_video_views_organic_vs_paid'
    | 'page_video_views_autoplayed_vs_click_to_play'
    | 'page_video_views_by_time'
    | 'page_video_views_unique_by_time'
}

type TPostData = {
  data_type: 'post_data'
  metric:
    | 'post_reaction_place'
    | 'post_video_views_organic_vs_paid'
    | 'post_video_views_autoplayed_vs_click_to_play'
    | 'post_video_view_time_by_age_bucket_and_gender'
    | 'post_impressions_fan_unique_type'
}

type TFacebookAnalyticsChartHorizontalOptions = TDailyData | TPostData

const analytics = namespace('analytics')
const facebook = namespace('facebook_analytics')

@Component({
  name: 'FacebookAnalyticsChartHorizontal',
  components: {
    AnalyticsPanel,
    AnalyticsPanelHeader,
    ChartHorizontal,
    Icon,
    Gender,
    Tooltip
  }
})
export default class FacebookAnalyticsChartHorizontal extends Vue {
  @facebook.State('api_accounts') daily_data: IFacebookState['api_accounts']
  @facebook.State('screen_name') screen_name: IFacebookState['screen_name']
  @facebook.State('api_posts') post_data: IFacebookState['api_posts']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TFacebookAnalyticsChartHorizontalOptions

  get is_data() {
    switch (this.options.data_type) {
      case 'daily_data': {
        // 全体データ
        const daily_data_metrics = facebook_insight.getMetricArray(
          this.daily_data,
          this.options.metric
        )

        const metric = {}

        switch (this.options.metric) {
          case 'page_fans_gender_age':
          case 'page_fans_city':
          case 'page_impressions_by_age_gender_unique':
            return this.daily_data.some(v => Object.keys(v[this.options.metric]).length > 0)
          case 'page_impressions_by_city_unique':
          case 'page_impressions_by_story_type_unique':
          case 'page_tab_views_login_top_unique':
          case 'page_views_external_referrals':
            daily_data_metrics.forEach(data => {
              Object.keys(data).forEach(key => {
                metric[key] = metric[key] ? metric[key] + data[key] : data[key]
              })
            })

            return Object.keys(metric).length > 0
          default:
            return this.daily_data.length > 0
        }
      }

      case 'post_data': {
        // 全体データ
        const post_data = facebook_insight.getMetricArray(this.post_data, this.options.metric)

        switch (this.options.metric) {
          case 'post_video_view_time_by_age_bucket_and_gender': {
            const metric = {}

            post_data.forEach(data => {
              Object.keys(data).forEach(key => {
                metric[key] = metric[key] ? metric[key] + data[key] : data[key]
              })
            })

            return Object.keys(metric).length > 0
          }
          default:
            return this.post_data.length > 0
        }
      }

      default:
        return false
    }
  }

  get panel_type() {
    const data_type = constants.DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get gender() {
    switch (this.options.metric) {
      case 'page_fans_gender_age': {
        const fans_api = facebook_insight.getMetricArray<any>(this.daily_data, this.options.metric)

        const fans_last = fans_api.find(v => Object.keys(v).length > 0)

        const fans_gender = this.getGenderPercentage(fans_last)

        return { male: `${fans_gender.male}%`, female: `${fans_gender.female}%` }
      }

      case 'page_impressions_by_age_gender_unique': {
        const age_gender_impressions_api = facebook_insight.getMetricArray(
          this.daily_data,
          this.options.metric
        )
        const age_gender_impressions = {}

        age_gender_impressions_api.forEach(data => {
          Object.keys(data).forEach(key => {
            age_gender_impressions[key] = age_gender_impressions[key]
              ? age_gender_impressions[key] + data[key]
              : data[key]
          })
        })

        const impression_gender = this.getGenderPercentage(age_gender_impressions)

        return { male: `${impression_gender.male}%`, female: `${impression_gender.female}%` }
      }

      case 'post_video_view_time_by_age_bucket_and_gender': {
        const post_video_api = facebook_insight.getMetricArray(this.post_data, this.options.metric)

        const post_video_data = {}

        post_video_api.forEach(data => {
          Object.keys(data).forEach(key => {
            post_video_data[key] = post_video_data[key]
              ? post_video_data[key] + data[key]
              : data[key]
          })
        })

        const post_video_gender = this.getGenderPercentage(post_video_data)

        return { male: `${post_video_gender.male}%`, female: `${post_video_gender.female}%` }
      }

      default:
        return { male: null, female: null }
    }
  }

  get is_gender() {
    return (
      this.options.metric === 'page_fans_gender_age' ||
      this.options.metric === 'page_impressions_by_age_gender_unique' ||
      this.options.metric === 'post_video_view_time_by_age_bucket_and_gender'
    )
  }

  get metric() {
    let metrics

    switch (this.options.data_type) {
      case 'daily_data':
        metrics = constants.HORIZONTAL_CHART_DAILY_DATA_METRICS
        break
      case 'post_data':
        metrics = constants.HORIZONTAL_CHART_POST_DATA_METRICS
        break
      default:
        metrics = []
        break
    }

    return metrics.find(metric => metric.value === this.options.metric)
  }

  get metric_name() {
    return this.metric ? t(this.metric.text) : ''
  }

  get metric_tooltip() {
    return this.metric ? t(this.metric.tooltip) : ''
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }

  get chart_options() {
    const blue = '#1c84c6'
    const red = '#ed5565'
    const green = '#2ec881'

    const options = {
      display_value: true,
      display_legend: false,
      legend_position: 'top',
      categories: [],
      colors: [],
      series: [],
      label_type: this.is_gender ? '%' : ''
    }

    switch (this.options.metric) {
      case 'page_fans_by_like_source_unique': {
        const like_api = facebook_insight.getMetricArray(this.daily_data, this.options.metric)

        const like_my_page = like_api.map(metrics => {
          const data1 = metrics['Page Profile'] || metrics['page_profile']
          const data2 = metrics['Your Page']

          return calculation.addition([data1 ? data1 : 0, data2 ? data2 : 0])
        })

        const like_recommended_page = like_api.map(metrics => {
          const data1 = metrics['Recommended Pages'] || metrics['recommended_pages']
          const data2 = metrics['Page Suggestions'] || metrics['page_suggestion']
          const data3 =
            metrics['Mobile Page Suggestions On Liking'] ||
            metrics['mobile_page_suggestions_on_liking']
          const data4 = metrics['Wizard Suggestion'] || metrics['wizard-suggestion']
          const data5 = metrics['Feed Pyml'] || metrics['feed_pyml']
          const data6 =
            metrics['Launch Point Discover Pyml'] || metrics['launch_point_discover_pyml']

          return calculation.addition([
            data1 ? data1 : 0,
            data2 ? data2 : 0,
            data3 ? data3 : 0,
            data4 ? data4 : 0,
            data5 ? data5 : 0,
            data6 ? data6 : 0
          ])
        })

        const like_news_feed = like_api.map(metrics => {
          const data = metrics['News Feed']
          return data ? data : 0
        })

        const like_ad_and_sponsor = like_api.map(metrics => {
          const data1 = metrics['Mobile Ads'] || metrics['mobile_ads']
          const data2 =
            metrics['Mobile Sponsored Page You May Like'] ||
            metrics['mobile_sponsored_page_you_may_like']
          const data3 = metrics['Sponsored Story'] || metrics['sponsored_story']
          const data4 =
            metrics['Pagelike Adder For Reactivated Users'] ||
            metrics['pagelike_adder_for_reactivated_users']
          const data5 = metrics['Ads'] || metrics['ads']

          return calculation.addition([
            data1 ? data1 : 0,
            data2 ? data2 : 0,
            data3 ? data3 : 0,
            data4 ? data4 : 0,
            data5 ? data5 : 0
          ])
        })

        const like_restored_from_reactivate_account = like_api.map(metrics => {
          const data = metrics['Restored Likes from Reactivated Accounts']
          return data ? data : 0
        })

        const like_search = like_api.map(metrics => {
          const data = metrics['Search'] || metrics['search']
          return data ? data : 0
        })

        const like_other = like_api.map(metrics => {
          const blacklist = [
            'Page Profile',
            'page_profile',
            'Your Page',
            'Recommended Pages',
            'recommended_pages',
            'Page Suggestions',
            'page_suggestion',
            'Mobile Page Suggestions On Liking',
            'mobile_page_suggestions_on_liking',
            'Wizard Suggestion',
            'wizard-suggestion',
            'Feed Pyml',
            'feed_pyml',
            'Launch Point Discover Pyml',
            'launch_point_discover_pyml',
            'News Feed',
            'Mobile Ads',
            'mobile_ads',
            'Mobile Sponsored Page You May Like',
            'mobile_sponsored_page_you_may_like',
            'Sponsored Story',
            'sponsored_story',
            'Pagelike Adder For Reactivated Users',
            'pagelike_adder_for_reactivated_users',
            'Ads',
            'ads',
            'Restored Likes from Reactivated Accounts',
            'Search',
            'search'
          ]

          const data = []

          Object.keys(metrics).forEach(key => {
            if (blacklist.indexOf(key) === -1) {
              data.push(metrics[key])
            }
          })

          return calculation.addition(data)
        })

        return {
          ...options,
          colors: [blue],
          categories: [
            t('自分のページ'),
            t('オススメのページ'),
            t('ニュースフィード'),
            t('広告とスポンサー記事'),
            t('再開されたアカウントで復元された「いいね！」'),
            t('検索'),
            t('その他')
          ],
          series: [
            {
              name: t(this.metric_name),
              data: [
                calculation.addition(like_my_page),
                calculation.addition(like_recommended_page),
                calculation.addition(like_news_feed),
                calculation.addition(like_ad_and_sponsor),
                calculation.addition(like_restored_from_reactivate_account),
                calculation.addition(like_search),
                calculation.addition(like_other)
              ]
            }
          ]
        }
      }

      case 'page_fans_gender_age': {
        const gender_fans_api = facebook_insight.getMetricArray<any>(
          this.daily_data,
          this.options.metric
        )

        const gender_fans_last = gender_fans_api.find(v => Object.keys(v).length > 0)

        const gender_fans = this.getGenderAge(gender_fans_last)

        return {
          ...options,
          colors: [red, blue],
          categories: gender_fans.category,
          series: [
            { name: t('女性'), data: gender_fans.female },
            { name: t('男性'), data: gender_fans.male }
          ]
        }
      }

      case 'page_fans_city': {
        const city_fans_api = facebook_insight.getMetricArray(this.daily_data, this.options.metric)

        let city_fans_last = city_fans_api.find(v => Object.keys(v).length > 0)
        if (!city_fans_last) city_fans_last = {}

        const city_fans_data = {}

        Object.keys(city_fans_last).forEach(key => {
          city_fans_data[key] = city_fans_data[key]
            ? city_fans_data[key] + city_fans_last[key]
            : city_fans_last[key]
        })

        const city_fans_data_desc = this.getArraySortDesc(city_fans_data)
        const city_fans_top_10 = this.getArrayTop10(city_fans_data_desc, false)

        return {
          ...options,
          colors: [blue],
          categories: city_fans_top_10.map(item => item.name),
          series: [
            {
              name: t(this.metric_name),
              data: city_fans_top_10.map(item => item.value)
            }
          ]
        }
      }

      case 'page_reach_type': {
        return {
          ...options,
          display_legend: true,
          colors: [green, red, blue],
          categories: [t('全投稿リーチ'), t('全体リーチ')],
          series: [
            {
              name: t('広告'),
              data: [
                facebook_insight.getMetricTotal(
                  this.daily_data,
                  'page_posts_impressions_paid_unique'
                ),
                facebook_insight.getMetricTotal(this.daily_data, 'page_impressions_paid_unique')
              ]
            },
            {
              name: t('クチコミ'),
              data: [
                facebook_insight.getMetricTotal(
                  this.daily_data,
                  'page_posts_impressions_viral_unique'
                ),
                facebook_insight.getMetricTotal(this.daily_data, 'page_impressions_viral_unique')
              ]
            },
            {
              name: t('オーガニック'),
              data: [
                facebook_insight.getMetricTotal(
                  this.daily_data,
                  'page_posts_impressions_organic_unique'
                ),
                facebook_insight.getMetricTotal(this.daily_data, 'page_impressions_organic_unique')
              ]
            }
          ]
        }
      }

      case 'page_impression_type': {
        return {
          ...options,
          display_legend: true,
          colors: [green, red, blue],
          categories: [t('全投稿インプレッション'), t('全体インプレッション')],
          series: [
            {
              name: t('広告'),
              data: [
                facebook_insight.getMetricTotal(this.daily_data, 'page_posts_impressions_paid'),
                facebook_insight.getMetricTotal(this.daily_data, 'page_impressions_paid')
              ]
            },
            {
              name: t('クチコミ'),
              data: [
                facebook_insight.getMetricTotal(this.daily_data, 'page_posts_impressions_viral'),
                facebook_insight.getMetricTotal(this.daily_data, 'page_impressions_viral')
              ]
            },
            {
              name: t('オーガニック'),
              data: [
                facebook_insight.getMetricTotal(this.daily_data, 'page_posts_impressions_organic'),
                facebook_insight.getMetricTotal(this.daily_data, 'page_impressions_organic')
              ]
            }
          ]
        }
      }

      case 'page_impressions_by_age_gender_unique': {
        const age_gender_impressions_api = facebook_insight.getMetricArray(
          this.daily_data,
          this.options.metric
        )
        const age_gender_impressions = {}

        age_gender_impressions_api.forEach(data => {
          Object.keys(data).forEach(key => {
            age_gender_impressions[key] = age_gender_impressions[key]
              ? age_gender_impressions[key] + data[key]
              : data[key]
          })
        })

        const gender_impressions = this.getGenderAge(age_gender_impressions)

        return {
          ...options,
          colors: [red, blue],
          categories: gender_impressions.category,
          series: [
            { name: t('女性'), data: gender_impressions.female },
            { name: t('男性'), data: gender_impressions.male }
          ]
        }
      }

      case 'page_impressions_by_city_unique': {
        const city_impressions_api = facebook_insight.getMetricArray(
          this.daily_data,
          this.options.metric
        )

        const city_impressions_data = {}

        city_impressions_api.forEach(data => {
          Object.keys(data).forEach(key => {
            city_impressions_data[key] = city_impressions_data[key]
              ? city_impressions_data[key] + data[key]
              : data[key]
          })
        })

        const city_impressions_data_desc = this.getArraySortDesc(city_impressions_data)
        const city_impressions_top_10 = this.getArrayTop10(city_impressions_data_desc, false)

        return {
          ...options,
          colors: [blue],
          categories: city_impressions_top_10.map(item => item.name),
          series: [
            {
              name: t(this.metric_name),
              data: city_impressions_top_10.map(item => item.value)
            }
          ]
        }
      }

      case 'page_impressions_by_story_type_unique': {
        const story_type_api = facebook_insight.getMetricArray(this.daily_data, this.options.metric)

        const page_post = story_type_api.map(metrics => {
          const data = metrics['Page Post'] || metrics['page post']
          return data ? data : 0
        })

        const user_post = story_type_api.map(metrics => {
          const data = metrics['User Post'] || metrics['user post']
          return data ? data : 0
        })

        const checkin = story_type_api.map(metrics => {
          const data = metrics['Checkin'] || metrics['checkin']
          return data ? data : 0
        })

        const coupon = story_type_api.map(metrics => {
          const data = metrics['Coupon'] || metrics['coupon']
          return data ? data : 0
        })

        const event = story_type_api.map(metrics => {
          const data = metrics['Event'] || metrics['event']
          return data ? data : 0
        })

        const fan = story_type_api.map(metrics => {
          const data = metrics['Fan'] || metrics['fan']
          return data ? data : 0
        })

        const mention = story_type_api.map(metrics => {
          const data = metrics['Mention'] || metrics['mention']
          return data ? data : 0
        })

        const question = story_type_api.map(metrics => {
          const data = metrics['Question'] || metrics['question']
          return data ? data : 0
        })

        const other = story_type_api.map(metrics => {
          const data = metrics['Other'] || metrics['other']
          return data ? data : 0
        })

        return {
          ...options,
          colors: [blue],
          categories: [
            t('ページ投稿'),
            t('ページに対するユーザー投稿'),
            t('ページにチェックイン'),
            t('クーポン'),
            t('イベント'),
            t('ページにいいね！'),
            t('ページにメンション'),
            t('質問に回答'),
            t('その他')
          ],
          series: [
            {
              name: t(this.metric_name),
              data: [
                calculation.addition(page_post),
                calculation.addition(user_post),
                calculation.addition(checkin),
                calculation.addition(coupon),
                calculation.addition(event),
                calculation.addition(fan),
                calculation.addition(mention),
                calculation.addition(question),
                calculation.addition(other)
              ]
            }
          ]
        }
      }

      case 'page_tab_views_login_top_unique': {
        const access_api = facebook_insight.getMetricArray(this.daily_data, this.options.metric)

        const access_data = {}

        access_api.forEach(data => {
          Object.keys(data).forEach(key => {
            const name = key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')
            access_data[name] = access_data[name] ? access_data[name] + data[key] : data[key]
          })
        })

        const access_data_desc = this.getArraySortDesc(access_data)
        const access_top_10 = this.getArrayTop10(access_data_desc)

        return {
          ...options,
          colors: [blue],
          categories: access_top_10.map(v => v.name),
          series: [
            {
              name: t(this.metric_name),
              data: access_top_10.map(v => v.value)
            }
          ]
        }
      }

      case 'page_views_external_referrals': {
        const external_api = facebook_insight.getMetricArray(this.daily_data, this.options.metric)

        const external_data = {}

        external_api.forEach(data => {
          Object.keys(data).forEach(key => {
            external_data[key] = external_data[key] ? external_data[key] + data[key] : data[key]
          })
        })

        const external_data_desc = this.getArraySortDesc(external_data)
        const external_top_10 = this.getArrayTop10(external_data_desc)

        return {
          ...options,
          colors: [blue],
          categories: external_top_10.map(v => v.name.toString().substring(0, 50)),
          series: [
            {
              name: t(this.metric_name),
              data: external_top_10.map(v => v.value)
            }
          ]
        }
      }

      case 'page_video_views_organic_vs_paid': {
        return {
          ...options,
          colors: [blue],
          categories: [t('オーガニック'), t('広告')],
          series: [
            {
              name: t('再生数'),
              data: [
                facebook_insight.getMetricTotal(this.daily_data, 'page_video_views_organic'),
                facebook_insight.getMetricTotal(this.daily_data, 'page_video_views_paid')
              ]
            }
          ]
        }
      }

      case 'page_video_views_autoplayed_vs_click_to_play': {
        return {
          ...options,
          colors: [blue],
          categories: [t('自動再生数'), t('クリック再生数')],
          series: [
            {
              name: t('再生数'),
              data: [
                facebook_insight.getMetricTotal(this.daily_data, 'page_video_views_autoplayed'),
                facebook_insight.getMetricTotal(this.daily_data, 'page_video_views_click_to_play')
              ]
            }
          ]
        }
      }

      case 'page_video_views_by_time': {
        return {
          ...options,
          colors: [blue],
          categories: [t('3秒'), t('10秒'), t('30秒')],
          series: [
            {
              name: t('再生数'),
              data: [
                facebook_insight.getMetricTotal(this.daily_data, 'page_video_views'),
                facebook_insight.getMetricTotal(this.daily_data, 'page_video_views_10s'),
                facebook_insight.getMetricTotal(this.daily_data, 'page_video_complete_views_30s')
              ]
            }
          ]
        }
      }

      case 'page_video_views_unique_by_time': {
        return {
          ...options,
          colors: [blue],
          categories: [t('3秒'), t('10秒'), t('30秒')],
          series: [
            {
              name: t('人数'),
              data: [
                facebook_insight.getMetricTotal(this.daily_data, 'page_video_views_unique'),
                facebook_insight.getMetricTotal(this.daily_data, 'page_video_views_10s_unique'),
                facebook_insight.getMetricTotal(
                  this.daily_data,
                  'page_video_complete_views_30s_unique'
                )
              ]
            }
          ]
        }
      }

      case 'post_reaction_place': {
        return {
          ...options,
          display_legend: true,
          legend_position: 'bottom',
          colors: [green, red, blue],
          categories: [t('自社投稿'), t('クチコミ先')],
          series: [
            {
              name: t('シェア'),
              data: [
                facebook_insight.getMetricTotal(this.post_data, 'shares_feed'),
                facebook_insight.getMetricTotal(this.post_data, 'shares_viral')
              ]
            },
            {
              name: t('コメント'),
              data: [
                facebook_insight.getMetricTotal(this.post_data, 'comments_feed'),
                facebook_insight.getMetricTotal(this.post_data, 'comments_viral')
              ]
            },
            {
              name: t('いいね！'),
              data: [
                facebook_insight.getMetricTotal(this.post_data, 'likes_feed'),
                facebook_insight.getMetricTotal(this.post_data, 'likes_viral')
              ]
            }
          ]
        }
      }

      case 'post_video_views_organic_vs_paid': {
        return {
          ...options,
          colors: [blue],
          categories: [t('オーガニック'), t('広告')],
          series: [
            {
              name: t('再生数'),
              data: [
                facebook_insight.getMetricTotal(this.post_data, 'post_video_views_organic'),
                facebook_insight.getMetricTotal(this.post_data, 'post_video_views_paid')
              ]
            }
          ]
        }
      }

      case 'post_video_views_autoplayed_vs_click_to_play': {
        return {
          ...options,
          colors: [blue],
          categories: [t('自動再生数'), t('クリック再生数')],
          series: [
            {
              name: t('再生数'),
              data: [
                facebook_insight.getMetricTotal(this.post_data, 'post_video_views_autoplayed'),
                facebook_insight.getMetricTotal(this.post_data, 'post_video_views_clicked_to_play')
              ]
            }
          ]
        }
      }

      case 'post_video_view_time_by_age_bucket_and_gender': {
        const gender_post_video_api = facebook_insight.getMetricArray(
          this.post_data,
          this.options.metric
        )

        const gender_post_video_data = {}

        gender_post_video_api.forEach(data => {
          Object.keys(data).forEach(key => {
            gender_post_video_data[key] = gender_post_video_data[key]
              ? gender_post_video_data[key] + data[key]
              : data[key]
          })
        })

        const gender_post_video = this.getGenderAge(gender_post_video_data)

        return {
          ...options,
          colors: [red, blue],
          categories: gender_post_video.category,
          series: [
            { name: t('女性'), data: gender_post_video.female },
            { name: t('男性'), data: gender_post_video.male }
          ]
        }
      }

      case 'post_impressions_fan_unique_type': {
        const post_impressions_fan = facebook_insight.getMetricTotal(
          this.post_data,
          'post_impressions_fan_unique'
        )
        const post_impressions_not_fan =
          facebook_insight.getMetricTotal(this.post_data, 'post_impressions_unique') -
          post_impressions_fan

        return {
          ...options,
          colors: [blue, blue],
          categories: [t('ファン'), t('ファン以外')],
          series: [
            {
              name: t('リーチ'),
              data: [post_impressions_fan, post_impressions_not_fan]
            }
          ]
        }
      }

      default:
        return options
    }
  }

  /**
   * オブジェクトから降順にした配列を取得
   * @param {object} data
   * @returns {array} 配列
   */
  getArraySortDesc(data: { [key: string]: number }): { name: string; value: number }[] {
    return Object.keys(data)
      .map(key => ({
        name: key,
        value: data[key]
      }))
      .sort((a, b) => {
        if (a.value > b.value) return -1
        if (a.value < b.value) return 1
        return 0
      })
  }

  /**
   * 配列からトップ10にした配列を取得
   * @param {array} data
   * @param {boolean} is_other
   * @returns {array} 配列
   */
  getArrayTop10(
    data: { name: string; value: number }[],
    is_other = true
  ): { name: string; value: number }[] {
    if (data.length <= 10) return data

    if (is_other) {
      const other = {
        name: t('その他'),
        value: calculation.addition(data.slice(10).map(v => v.value))
      }

      return [...data.slice(0, 10), other]
    }

    return data.slice(0, 10)
  }

  /**
   * オブジェクトから性別の比率を求める
   * @param {any} data
   * @returns {object} オブジェクト
   */
  getGenderPercentage(data: any): { male: number; female: number } {
    let male_temp = 0
    let female_temp = 0

    if (!data) {
      return { male: male_temp, female: female_temp }
    }

    Object.keys(data).forEach(key => {
      if (key.indexOf('M') === 0) male_temp = male_temp + data[key]
      if (key.indexOf('F') === 0) female_temp = female_temp + data[key]
    })

    const male = calculation.percentage(male_temp, male_temp + female_temp)
    const female = calculation.percentage(female_temp, male_temp + female_temp)

    return { male, female }
  }

  /**
   * オブジェクトから年代の指標を求める
   * @param {any} data
   * @param {boolean} is_percent ％表記
   * @returns {object} オブジェクト
   */
  getGenderAge(
    data: any,
    is_percent = true
  ): { category: string[]; male: number[]; female: number[] } {
    const category = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+']
    const male = [0, 0, 0, 0, 0, 0, 0]
    const female = [0, 0, 0, 0, 0, 0, 0]

    if (!data) {
      return { category, male, female }
    }

    let total = 0

    Object.keys(data).forEach(key => {
      if (key.indexOf('M') === 0 || key.indexOf('F') === 0) {
        total += data[key]
      }
    })

    Object.keys(data).forEach(key => {
      if (key.indexOf('M') === 0) {
        category.forEach((category, index) => {
          if (key.includes(category)) {
            male[index] = is_percent ? calculation.percentage(data[key], total) : data[key]
          }
        })
      }

      if (key.indexOf('F') === 0) {
        category.forEach((category, index) => {
          if (key.includes(category)) {
            female[index] = is_percent ? calculation.percentage(data[key], total) : data[key]
          }
        })
      }
    })

    return { category, male, female }
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    TrackingService.sendEvent(
      `click:自社分析(FB)> ${this.screen_name} | ${this.options.metric} : CSVダウンロード`
    )

    const { categories, series } = this.chart_options

    let fields = [t('タグ'), ...series.map(v => v.name)]
    let data = categories.map((value, index) => [value, ...series.map(v => v.data[index])])

    if (this.is_gender) {
      fields = [t('タグ'), t('女性'), t('女性(実数)'), t('男性'), t('男性(実数)')]

      let gender_data = {}

      if (this.options.data_type === 'daily_data') {
        const gender_array = facebook_insight.getMetricArray(this.daily_data, this.options.metric)

        switch (this.options.metric) {
          case 'page_fans_gender_age':
            gender_data = gender_array.find(v => Object.keys(v).length > 0)
            break
          default:
            // 性別・年代ごとの合計値を算出する
            gender_array.forEach(data => {
              Object.keys(data).forEach(key => {
                gender_data[key] = gender_data[key] ? gender_data[key] + data[key] : data[key]
              })
            })
            break
        }
      }

      if (this.options.data_type === 'post_data') {
        const gender_array = facebook_insight.getMetricArray(this.post_data, this.options.metric)

        // 性別・年代ごとの合計値を算出する
        gender_array.forEach(data => {
          Object.keys(data).forEach(key => {
            gender_data[key] = gender_data[key] ? gender_data[key] + data[key] : data[key]
          })
        })
      }

      const gender_age = this.getGenderAge(gender_data)
      const gender_age_values = this.getGenderAge(gender_data, false)

      data = gender_age.category.map((value, index) => [
        value,
        gender_age.female[index],
        gender_age_values.female[index],
        gender_age.male[index],
        gender_age_values.male[index]
      ])
    }

    const csv_data = { fields, data }

    const component_name = t('グラフ')
    const metric_name = t(this.metric_name)

    const csv_filename = [component_name, metric_name, this.start_date, this.end_date].join('_')

    csv.download(csv_data, csv_filename)
  }
}
