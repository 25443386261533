import { render, staticRenderFns } from "./PersonalSettingProfile.vue?vue&type=template&id=335d5885&scoped=true"
import script from "./PersonalSettingProfile.ts?vue&type=script&lang=ts&external"
export * from "./PersonalSettingProfile.ts?vue&type=script&lang=ts&external"
import style0 from "./PersonalSettingProfile.vue?vue&type=style&index=0&id=335d5885&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "335d5885",
  null
  
)

export default component.exports