import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Checkbox from '@/client/components-old/atoms/Checkbox'
import Dialog from '@/client/components-old/atoms/Dialog'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Account from '@/client/components-old/molecules/Account'
import { TrackingService } from '@/client/services'
import { IState as IFacebookState } from '@/client/store/modules/facebook_comparison_analytics'
import { IState as IInstagramState } from '@/client/store/modules/instagram_comparison_analytics'
import { IState as ITwitterState } from '@/client/store/modules/twitter_comparison_analytics'

const facebook = namespace('facebook_comparison_analytics')
const twitter = namespace('twitter_comparison_analytics')
const instagram = namespace('instagram_comparison_analytics')
const notification = namespace('notification')

@Component({
  name: 'PresetDialog',
  components: {
    Dialog,
    Message,
    Input,
    Button,
    Icon,
    Flex,
    Checkbox,
    Account
  }
})
export default class PresetDialog extends Vue {
  // store facebook
  @facebook.State('api_sns_comparison_accounts')
  sns_comparison_accounts_facebook: IFacebookState['api_sns_comparison_accounts']
  @facebook.State('api_presets') presets_facebook: IFacebookState['api_presets']
  @facebook.Action('getPresetById') getPresetByIdFacebook
  @facebook.Action('postPresets') postPresetsFacebook
  @facebook.Action('putPresets') putPresetsFacebook
  // stores instagram
  @instagram.State('api_sns_comparison_accounts')
  sns_comparison_accounts_instagram: IInstagramState['api_sns_comparison_accounts']
  @instagram.State('api_presets') presets_instagram: IInstagramState['api_presets']
  @instagram.Action('getPresetById') getPresetByIdInstagram
  @instagram.Action('postPresets') postPresetsInstagram
  @instagram.Action('putPresets') putPresetsInstagram
  @notification.Action('showNotification') showNotification
  // stores twitter
  @twitter.State('api_sns_comparison_accounts')
  sns_comparison_accounts_twitter: ITwitterState['api_sns_comparison_accounts']
  @twitter.State('api_presets') presets_twitter: ITwitterState['api_presets']
  @twitter.Action('getPresetById') getPresetByIdTwitter
  @twitter.Action('postPresets') postPresetsTwitter
  @twitter.Action('putPresets') putPresetsTwitter

  @Prop({ type: String, required: true })
  snsType: 'facebook' | 'twitter' | 'instagram'

  visible = false
  is_submit = false

  preset_id: number | null = null

  preset_name = ''
  preset_name_error = false
  preset_name_error_message = ''

  accounts: {
    id: string
    name: string
    img: string
    selected: boolean
  }[] = []
  accounts_not_selected = false
  accounts_max = false

  get sns_comparison_accounts() {
    switch (this.snsType) {
      case 'facebook':
        return this.sns_comparison_accounts_facebook
      case 'twitter':
        return this.sns_comparison_accounts_twitter
      case 'instagram':
        return this.sns_comparison_accounts_instagram
      default:
        return []
    }
  }

  get presets() {
    switch (this.snsType) {
      case 'facebook':
        return this.presets_facebook
      case 'twitter':
        return this.presets_twitter
      case 'instagram':
        return this.presets_instagram
      default:
        return []
    }
  }

  get is_edit_mode() {
    return this.preset_id !== null
  }

  get is_disabled_submit() {
    return !(this.preset_name.trim() && !this.accounts_not_selected)
  }

  /**
   * ダイアログの表示
   * @param {number} preset_id
   * @returns {void}
   */
  async open(preset_id?: number): Promise<void> {
    this.reset()

    if (preset_id) {
      let result

      switch (this.snsType) {
        case 'facebook':
          result = await this.getPresetByIdFacebook({ preset_id })
          break
        case 'twitter':
          result = await this.getPresetByIdTwitter({ preset_id })
          break
        case 'instagram':
          result = await this.getPresetByIdInstagram({ preset_id })
          break
      }

      if (result && result.data) {
        this.preset_id = preset_id
        this.preset_name = result.data.name
        this.accounts = this.sns_comparison_accounts.map(account => ({
          id: account.id,
          name: account.name,
          img: account.img,
          selected: result.data.data.indexOf(account.id) !== -1
        }))
      } else if (result && result.error && result.error.type === 'NOT_EXISTS') {
        const title = this.$options.filters.translate('対象のプリセットはすでに削除されています。')
        return this.showNotification({ title, type: 'error' })
      } else {
        const title = this.$options.filters.translate('プリセットデータの取得に失敗しました。')
        return this.showNotification({ title, type: 'error' })
      }
    }

    this.accountValidation()

    this.visible = true
  }

  /**
   * ダイアログの非表示
   * @returns {void}
   */
  hide(): void {
    this.visible = false
  }

  /**
   * プリセットの保存
   * @returns {void}
   */
  async submit(): Promise<void> {
    if (this.preset_id) {
      await this.update()

      TrackingService.sendEvent(
        `click:競合比較(${this.snsType})>アカウント管理|プリセットの編集|保存`
      )
    } else {
      await this.create()

      TrackingService.sendEvent(
        `click:競合比較(${this.snsType})>アカウント管理|プリセットの追加|保存`
      )
    }
  }

  /**
   * プリセットの新規作成
   * @returns {void}
   */
  async create(): Promise<void> {
    if (this.is_submit) return

    this.is_submit = true

    let result

    const params = {
      name: this.preset_name,
      data: this.accounts.filter(account => account['selected']).map(account => account.id)
    }

    switch (this.snsType) {
      case 'facebook':
        result = await this.postPresetsFacebook(params)
        break
      case 'twitter':
        result = await this.postPresetsTwitter(params)
        break
      case 'instagram':
        result = await this.postPresetsInstagram(params)
        break
    }

    setTimeout(() => {
      this.is_submit = false
    }, 2000)

    if (result && result.data) {
      const title = this.$options.filters.translate('プリセットを追加しました。')
      this.showNotification({ title })

      this.hide()
    } else {
      const title = this.$options.filters.translate('プリセットの追加に失敗しました。')
      this.showNotification({ title, type: 'error' })
    }
  }

  /**
   * プリセットの更新
   * @returns {void}
   */
  async update(): Promise<void> {
    if (this.is_submit) return

    this.is_submit = true

    let response

    const params = {
      id: this.preset_id,
      name: this.preset_name,
      data: this.accounts.filter(account => account.selected).map(account => account.id)
    }

    switch (this.snsType) {
      case 'facebook':
        response = await this.putPresetsFacebook(params)
        break
      case 'twitter':
        response = await this.putPresetsTwitter(params)
        break
      case 'instagram':
        response = await this.putPresetsInstagram(params)
        break
    }

    setTimeout(() => {
      this.is_submit = false
    }, 2000)

    if (response && response.data) {
      const title = this.$options.filters.translate('プリセットを更新しました。')
      this.showNotification({ title })

      this.hide()
    } else if (response && response.error && response.error.type === 'NOT_EXISTS') {
      const title = this.$options.filters.translate('対象のプリセットはすでに削除されています。')
      this.showNotification({ title, type: 'error' })

      this.hide()
    } else {
      const title = this.$options.filters.translate('プリセットの更新に失敗しました。')
      this.showNotification({ title, type: 'error' })
    }
  }

  /**
   * 初期化
   * @returns {void}
   */
  reset(): void {
    this.is_submit = false

    this.preset_id = null

    this.preset_name = ''
    this.preset_name_error = false
    this.preset_name_error_message = ''

    this.accounts = this.sns_comparison_accounts.map(account => ({
      id: account.id,
      name: account.name,
      img: account.img,
      selected: false
    }))
    this.accounts_max = false
    this.accounts_not_selected = false
  }

  /**
   * プリセット名を検証
   * @returns {void}
   */
  presetNameValidation(): void {
    if (!this.preset_name.trim()) {
      this.preset_name_error = true
      this.preset_name_error_message =
        this.$options.filters.translate('プリセット名を入力してください。')
    } else {
      this.preset_name_error = false
      this.preset_name_error_message = ''
    }
  }

  /**
   * アカウントを検証
   * @returns {void}
   */
  accountValidation(): void {
    const selected_accounts = this.accounts.filter(account => account.selected)
    this.accounts_not_selected = selected_accounts.length === 0
    this.accounts_max = selected_accounts.length >= 10
  }
}
