
import { defineComponent, ref } from 'vue'
import draggable from 'vuedraggable'
import { mapActions, mapState } from 'vuex'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { IState as IGroupSettingState } from '@/client/store/modules/group_setting'
import { getTranslateText as t } from '@/client/utils/filters'

export default defineComponent({
  name: 'GroupSettingPostApprovalFlowsDialog',
  components: {
    draggable,
    Button,
    Dialog,
    Icon,
    Tooltip,
    Message
  },
  setup() {
    const visible = ref(false)

    const open = () => {
      visible.value = true
    }

    const close = () => {
      visible.value = false
    }

    return {
      visible,
      open,
      close,
      t
    }
  },
  computed: {
    ...mapState(['project']),
    ...mapState('group_setting', ['api_post_approval_flows']),

    limit() {
      return this.project.post_approval_flow_max_count - this.api_post_approval_flows.length
    }
  },
  methods: {
    ...mapActions('group_setting', [
      'createPostApprovalFlow',
      'removePostApprovalFlow',
      'updatePostApprovalFlow',
      'changePostApprovalFlowOrder'
    ]),
    ...mapActions('notification', ['showNotification']),

    openDetailFlow(payload: any) {
      return payload
    },

    /**
     * 承認フローのドラッグ&ドロップ
     */
    async changeDraggable(flows: IGroupSettingState['api_post_approval_flows']) {
      const result = await this.changePostApprovalFlowOrder(flows)

      if (result.data) {
        return this.showNotification({ title: '承認フローの順番を保存しました。' })
      }

      this.showNotification({ type: 'error', title: '承認フローの順番を保存できませんでした。' })
    },

    /**
     * 承認フローの削除
     */
    async deleteApprovalFlow(id: number) {
      const flow = this.api_post_approval_flows.find(flow => flow.id === id)

      if (!flow) {
        return
      }

      const message = t('過去に完了した承認フローも同時に削除されますがよろしいですか？')

      const confirm = window.confirm(message)

      if (!confirm) {
        return
      }

      const result = await this.removePostApprovalFlow({ flow_id: id })

      if (result.data) {
        this.showNotification({ title: t('[[name]]を削除しました。', { name: flow.name }) })

        return
      }

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.showNotification({ type: 'error', title: '承認フローはすでに削除されています。' })

        return
      }

      this.showNotification({ type: 'error', title: '承認フローの削除に失敗しました。' })
    }
  }
})
