import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import Icon from '@/client/components-old/atoms/Icon'
import Select from '@/client/components-old/atoms/Select'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import TextareaEmoji from '@/client/components-old/molecules/TextareaEmoji'
import UserMultiSelect from '@/client/components-old/molecules/UserMultiSelect'
import MonitoringTemplateDialog from '@/client/components-old/organisms/MonitoringTemplateDialog'
import { IState as IMonitoring } from '@/client/store/modules/monitoring'
import { IState as IMonitoringTemplates } from '@/client/store/modules/monitoring_templates'
import { TMonitoring } from '@/client/utils/api/monitorings'

const monitoring = namespace('monitoring')
const monitoring_templates = namespace('monitoring_templates')
const notification = namespace('notification')

@Component({
  name: 'MonitoringPending',
  components: {
    Button,
    ButtonLink,
    Dropdown,
    Icon,
    Select,
    Tooltip,
    Account,
    UserMultiSelect,
    TextareaEmoji,
    MonitoringTemplateDialog
  }
})
export default class MonitoringPending extends Vue {
  @monitoring.State('api_persons') api_persons: IMonitoring['api_persons']
  @monitoring.State('destination_ids') destination_ids: IMonitoring['destination_ids']
  @monitoring.Action('updateMonitoringStatus') updateMonitoringStatus
  @monitoring.Mutation('SET_DESTINATION_IDS') setDestinationIds
  @monitoring_templates.State('templates') templates: IMonitoringTemplates['templates']
  @notification.Action('showNotification') showNotification

  @Prop({ type: Object, required: true })
  monitoring: TMonitoring

  @Prop({ type: Boolean, default: false })
  isLoading: boolean

  template: number | '' = ''

  message = ''

  persons: number[] = []

  get person_ids() {
    return this.persons
  }

  set person_ids(ids: number[]) {
    if (ids.length > 3) {
      return
    }

    this.persons = ids
  }

  get loading() {
    return this.isLoading
  }

  set loading(val) {
    this.$emit('update:is-loading', val)
  }

  @Watch('templates')
  watchTemplates() {
    const index = this.templates.findIndex(v => v.id === this.template)

    if (index === -1) {
      this.template = ''
    }
  }

  get templates_options() {
    return this.templates.map(v => ({ text: v.name, value: v.id }))
  }

  get destinations() {
    return this.destination_ids.filter(v => this.persons_options.some(p => p.id === v))
  }

  set destinations(destination_ids: number[]) {
    this.setDestinationIds(destination_ids)
  }

  get persons_options() {
    return this.api_persons
      .filter(person => {
        switch (this.monitoring.sns) {
          case 'facebook':
            return person.fb_accounts.find(
              account =>
                account.account_id === this.monitoring.account_id &&
                account.role_monitoring !== 'none'
            )
          case 'twitter':
            return person.tw_accounts.find(
              account =>
                account.account_id === this.monitoring.account_id &&
                account.role_monitoring !== 'none'
            )
          case 'instagram':
            return person.in_accounts.find(
              account =>
                account.account_id === this.monitoring.account_id &&
                account.role_monitoring !== 'none'
            )
          default:
            return false
        }
      })
      .map(person => person.user)
  }

  @Emit('update-monitoring')
  updateMonitoring(payload: any) {
    return payload
  }

  @Emit('close-pending')
  closePending(event: Event) {
    return event
  }

  @Emit('open-template')
  openTemplateDialog(event: Event) {
    return event
  }

  /**
   * テンプレートの変更
   */
  changeTemplate() {
    const template = this.templates.find(v => v.id === this.template)
    let message = template ? template.content : ''

    if (
      (this.monitoring.sns === 'twitter' &&
        ['mention', 'reply'].some(v => v === this.monitoring.type)) ||
      this.monitoring.sns === 'instagram'
    ) {
      message = message.replace('__username__', `@${this.monitoring.from_account.username}`)
    } else {
      message = message.replace('__username__', this.monitoring.from_account.name)
    }

    this.message = [this.message, message].filter(Boolean).join('\n')
  }

  /**
   * 保留にする
   */
  async changePending(event: Event) {
    let mention = ''

    if (!!this.message.trim() && this.destination_ids.length > 0) {
      const persons = this.destination_ids.map(person_id => {
        const person = this.api_persons.find(v => v.user.id === person_id)
        return person ? `@${person.user.name}` : ''
      })

      mention = persons.length > 0 ? `${persons.join(' ')}\n` : ''
    }

    const memo_message = `${mention}${this.message.trim()}`

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      status: 'pending',
      memo_message,
      memo_destination_ids: this.destination_ids,
      persons: this.persons
    }

    this.loading = true

    const result = await this.updateMonitoringStatus(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.message = ''

      this.updateMonitoring(params)

      this.closePending(event)

      this.showNotification({ title: '保留に変更しました。' })
    } else {
      this.showNotification({
        title: '保留の変更に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }
}
