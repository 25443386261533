import twttr from 'twitter-text'

import { htmlEscapeNotUrl, MENTION_FORMAT } from '@/client/utils/regex'

/**
 * メモの内容をHTMLで取得
 */
export function getMemoMessage(message: string): string {
  let text = htmlEscapeNotUrl(message)

  text = twttr.autoLinkUrlsCustom(text, { targetBlank: true })

  return text.replace(MENTION_FORMAT, '<div class="mention">@$1</div>')
}
