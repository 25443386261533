import '@/client/vendor'
import '@/client/utils'
import '@/client/assets/scss/style.scss'

import moment from 'moment-timezone'
import { createPinia, PiniaVuePlugin } from 'pinia'
import Vue from 'vue'

import App from '@/client/App.vue'
import { router } from '@/client/router'
import { CrispService, TrackingService } from '@/client/services'
import { store } from '@/client/store'

TrackingService.init()
CrispService.init()

/**
 * イベント処理の停止
 */
function stopEvent(event: DragEvent) {
  event.preventDefault()
}

window.addEventListener('dragover', stopEvent, false)
window.addEventListener('drop', stopEvent, false)

Vue.config.productionTip = false

Vue.config.errorHandler = err => {
  console.error(err)
}

Vue.use(PiniaVuePlugin)

const pinia = createPinia()

/**
 * 初期化
 */
async function init() {
  const is_login = await store.dispatch('checkSession')

  if (is_login) {
    await store.dispatch('login')
  } else {
    const language = navigator.language.startsWith('ja') ? 'ja' : 'en'
    const timezone = moment.tz.guess()

    await store.dispatch('fetchLanguage', language)
    await store.dispatch('fetchTimezone', timezone)
  }

  // 各モジュールの初期化処理
  await store.dispatch('post_management/initializePostManagement')

  const app = new Vue({ store, pinia, router, render: h => h(App) })

  app.$mount('#app-root')
}

init().catch(console.error)
