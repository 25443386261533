import moment from 'moment-timezone'
import { Component, Vue } from 'vue-property-decorator'

import * as twitter_trends from '@/client/components-old/_utils/twitter_trends'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { TrackingService } from '@/client/services'
import API from '@/client/utils/api'
import { IGetTwitterTrendsNewResponse, ITwitterTrendsNew } from '@/client/utils/api/twitter_trends'

@Component({
  name: 'PostCreateTwitterTrend',
  components: {
    ButtonLink,
    Flex,
    Icon,
    Message,
    Panel,
    Tooltip
  }
})
export default class PostCreateTwitterTrend extends Vue {
  twitter_trends: ITwitterTrendsNew = { datetime: moment().toISOString(), trends: [] }

  get datetime() {
    return moment(this.twitter_trends.datetime).format('lll')
  }

  /**
   * ページ表示時
   */
  async created(): Promise<void> {
    await this.getTwitterTrends()
  }

  /**
   * 投稿数の表示
   */
  tweetCount(volume: number): string {
    return this.$options.filters?.number(volume)
  }

  /**
   * トレンドを一覧を取得
   */
  async getTwitterTrends(): Promise<void> {
    const response = await API.get<IGetTwitterTrendsNewResponse>('twitter_trends/new')

    if (response.data.data) {
      this.twitter_trends = response.data.data
    }
  }

  /**
   * トレンドをアクセス
   */
  openTwitterTrend(trend_word: string) {
    TrackingService.sendEvent('click:投稿作成|Xトレンド|トレンド')

    window.open(twitter_trends.createTrendUrl(trend_word), '_blank')
  }
}
