import moment from 'moment-timezone'

import { roundToDecimalPoint } from './roundToDecimalPoint'

type Hour = number
type DayOfWeek = number
// ? 平均/合計の切り替えによって値が変わる
type Value = number | null

export type Heatmap = [Hour, DayOfWeek, Value]

/**
 * ヒートマップ図の作成
 */
export function createHeatmapDiagram(
  posts: { datetime: string; count: number }[],
  aggregate: 'total' | 'average',
  timezone: string
): Heatmap[] {
  const HOUR = 24
  const WEEK = 7

  return [...Array(HOUR * WEEK)].map((_, i) => {
    const hour = Math.floor(i / WEEK)
    const weekday = i % WEEK

    const target_posts = posts.filter(v => {
      const date = moment(v.datetime).tz(timezone)
      return date.isoWeekday() - 1 === weekday && date.hours() === hour
    })

    const total = target_posts.reduce((p, c) => p + c.count, 0)
    const average = total > 0 ? total / target_posts.length : 0

    if (!target_posts.length) return [hour, weekday, null]
    if (aggregate === 'total') return [hour, weekday, total]
    if (aggregate === 'average') return [hour, weekday, roundToDecimalPoint(average, 2)]
    return [hour, weekday, null]
  })
}
