import moment from 'moment-timezone'
import twttr from 'twitter-text'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as utils from '@/client/components-old/_utils/monitoring'
import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import DateTime from '@/client/components-old/atoms/DateTime'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Select from '@/client/components-old/atoms/Select'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import TextareaEmoji from '@/client/components-old/molecules/TextareaEmoji'
import UserMultiSelect from '@/client/components-old/molecules/UserMultiSelect'
import { TrackingService } from '@/client/services'
import { IGetter as IAccountGetter } from '@/client/store/modules/accounts'
import { IState as IMonitoringState } from '@/client/store/modules/monitoring'
import { IState as IMonitoringTemplates } from '@/client/store/modules/monitoring_templates'
import { TMonitoring, TMonitoringReply } from '@/client/utils/api/monitorings'
import { getSnsName } from '@/client/utils/character'
import i18n from '@/client/utils/i18n'
import { convertHelpMessage } from '@/client/utils/notification'

const accounts = namespace('accounts')
const monitoring = namespace('monitoring')
const monitoring_templates = namespace('monitoring_templates')
const notification = namespace('notification')

@Component({
  name: 'MonitoringAction',
  components: {
    Button,
    ButtonLink,
    Dropdown,
    Select,
    DateTime,
    Icon,
    Message,
    Tooltip,
    Account,
    TextareaEmoji,
    UserMultiSelect
  }
})
export default class MonitoringAction extends Vue {
  @accounts.Getter('monitoring') monitoring_accounts: IAccountGetter['monitoring']
  @monitoring.State('api_setting') api_setting: IMonitoringState['api_setting']
  @monitoring.State('api_persons') api_persons: IMonitoringState['api_persons']
  @monitoring.State('destination_ids') destination_ids: IMonitoringState['destination_ids']
  @monitoring.State('status') status: IMonitoringState['status']
  @monitoring.Mutation('SET_DESTINATION_IDS') setPersons
  @monitoring.Action('updateMonitoringLike') updateMonitoringLike
  @monitoring.Action('updateMonitoringRetweet') updateMonitoringRetweet
  @monitoring.Action('updateMonitoringHidden') updateMonitoringHidden
  @monitoring.Action('updateMonitoringDelete') updateMonitoringDelete
  @monitoring.Action('createMonitoringReply') createMonitoringReply
  @monitoring.Action('updateMonitoringReply') updateMonitoringReply
  @monitoring.Action('removeMonitoringReply') removeMonitoringReply
  @monitoring.Action('createMonitoringQuoteRetweet') createMonitoringQuoteRetweet
  @monitoring.Action('removeMonitoringQuoteRetweet') removeMonitoringQuoteRetweet
  @monitoring_templates.State('templates') templates: IMonitoringTemplates['templates']
  @notification.Action('showNotification') showNotification

  @Prop({ type: Object, required: true })
  monitoring: TMonitoring

  @Prop({ type: Boolean, default: false })
  isLoading: boolean

  action: 'create_reply' | 'update_reply' | 'hidden' | 'remove' | 'create_quote_retweet' | '' = ''

  template: number | '' = ''

  message = ''

  reply_id: number | null = null

  max_words = 280

  current_date = new Date().toUTCString()

  mounted() {
    setInterval(() => {
      this.current_date = new Date().toUTCString()
    }, 60 * 1000)
  }

  get retweet_options() {
    return [
      { icon: 'retweet', text: i18n.t('リポスト'), value: 'retweet', disabled: this.is_expired },
      {
        icon: 'message',
        text: i18n.t('引用'),
        value: 'retweet_comment',
        disabled: this.is_expired
      }
    ]
  }

  get unretweet_options() {
    return [
      {
        icon: 'retweet',
        text: 'リポスト済みを取り消す',
        value: 'unretweet'
      },
      {
        icon: 'message',
        text: '引用',
        value: 'retweet_comment',
        disabled: this.is_expired
      }
    ]
  }

  get loading() {
    return this.isLoading
  }

  set loading(val) {
    this.$emit('update:is-loading', val)
  }

  @Watch('templates')
  watchTemplates() {
    const index = this.templates.findIndex(v => v.id === this.template)

    if (index === -1) {
      this.template = ''
    }
  }

  get templates_options() {
    return this.templates.map(v => ({ text: v.name, value: v.id }))
  }

  get is_same_account() {
    const { from_account, own_account } = this.monitoring

    const same_account_instagram =
      this.monitoring.sns === 'instagram' && from_account.username === own_account.username
    const same_account_other =
      this.monitoring.sns !== 'instagram' && from_account.id === own_account.id

    return same_account_instagram || same_account_other
  }

  get is_admin() {
    const role = this.monitoring_accounts.find(
      account =>
        account.id === this.monitoring.own_account.id && account.sns === this.monitoring.sns
    )

    return role && role.role === 'admin'
  }

  get is_expired() {
    const account = this.monitoring_accounts.find(
      account => account.id === this.monitoring.account_id && account.sns === this.monitoring.sns
    )

    if (!account) {
      return false
    }

    return account.expired
  }

  get is_facebook() {
    return this.monitoring.sns === 'facebook'
  }

  get is_twitter() {
    return this.monitoring.sns === 'twitter'
  }

  get is_like() {
    if (!this.is_admin && (!this.api_setting || !this.api_setting.observer_allow_like)) {
      return false
    }

    const types = ['facebook_comment', 'facebook_post', 'twitter_reply', 'twitter_mention']
    const type = `${this.monitoring.sns}_${this.monitoring.type}`

    if (!types.includes(type)) return false

    return !this.monitoring.is_deleted && !this.monitoring.is_hidden
  }

  get is_retweet() {
    if (!this.is_admin && (!this.api_setting || !this.api_setting.observer_allow_retweet)) {
      return false
    }

    const types = ['twitter_reply', 'twitter_mention']
    const type = `${this.monitoring.sns}_${this.monitoring.type}`

    return types.includes(type) && !this.monitoring.is_hidden
  }

  get is_reply() {
    if (!this.is_admin && (!this.api_setting || !this.api_setting.observer_allow_reply)) {
      return false
    }

    if (this.is_facebook_timeout) return false

    return !this.monitoring.is_deleted && !this.monitoring.is_hidden
  }

  get is_hidden() {
    const types = ['facebook_comment', 'facebook_post', 'instagram_comment', 'twitter_reply']
    const type = `${this.monitoring.sns}_${this.monitoring.type}`

    if (!types.includes(type)) return false

    if (!this.is_admin && !this.api_setting.observer_allow_hidden) return false

    if (type === 'facebook_comment' && !this.monitoring.is_can_hide) return false

    return !this.monitoring.is_deleted && !this.is_same_account
  }

  get is_remove() {
    const types = ['facebook_comment', 'facebook_post', 'instagram_comment']
    const type = `${this.monitoring.sns}_${this.monitoring.type}`

    if (!types.includes(type)) return false

    if (!this.is_admin && !this.api_setting.observer_allow_delete) return false

    return !this.monitoring.is_deleted && !this.monitoring.is_hidden
  }

  get is_parent_post() {
    return this.monitoring.sns === 'facebook' && this.monitoring.type === 'comment'
  }

  get is_show_detail() {
    const types = ['facebook_comment', 'twitter_message']
    const type = `${this.monitoring.sns}_${this.monitoring.type}`

    return !types.includes(type)
  }

  get detail_tooltip() {
    switch (this.monitoring.type) {
      case 'message':
        return 'メッセージを確認するには、ページ管理者でFacebookにログインする必要があります。'
      default:
        return 'SNS上の情報を表示します'
    }
  }

  get is_update_reply() {
    const types = ['facebook_comment', 'facebook_post']
    const type = `${this.monitoring.sns}_${this.monitoring.type}`

    return types.includes(type)
  }

  get is_facebook_timeout() {
    const LIMIT_HOUR = 24

    return (
      this.monitoring.sns === 'facebook' &&
      this.monitoring.type === 'message' &&
      moment(this.current_date).diff(this.monitoring.datetime, 'hour') >= LIMIT_HOUR
    )
  }

  get is_form() {
    return this.action !== ''
  }

  get is_form_delete() {
    return this.action === 'remove'
  }

  get is_form_hidden() {
    return this.action === 'hidden'
  }

  get is_form_reply() {
    return this.action === 'create_reply' || this.action === 'update_reply'
  }

  get is_form_quote_retweet() {
    return this.action === 'create_quote_retweet'
  }

  get is_display_count_words() {
    return (
      this.is_form_quote_retweet ||
      (this.is_form_reply && this.is_twitter && this.monitoring.type !== 'message')
    )
  }

  get word_count() {
    return (
      twttr.parseTweet(this.message).weightedLength +
      `@${this.monitoring.from_account.username} `.length
    )
  }

  get is_exceed_max_words() {
    return this.word_count > this.max_words && this.is_display_count_words
  }

  get is_empty_message() {
    return this.message.trim().length === 0
  }

  get is_monitoring_post() {
    return this.monitoring.type === 'post'
  }

  get placeholder() {
    switch (this.action) {
      case 'create_reply':
      case 'update_reply':
        return '返信内容を入力'
      case 'hidden':
        return '理由を入力'
      case 'remove':
        return '理由を入力(必須)'
      case 'create_quote_retweet':
        return '返信内容を入力'
      default:
        return ''
    }
  }

  get persons() {
    return this.destination_ids
  }

  set persons(persons) {
    this.setPersons(persons)
  }

  get persons_options() {
    return this.api_persons
      .filter(person => {
        switch (this.monitoring.sns) {
          case 'facebook':
            return person.fb_accounts.find(
              account =>
                account.account_id === this.monitoring.account_id &&
                account.role_monitoring !== 'none'
            )
          case 'twitter':
            return person.tw_accounts.find(
              account =>
                account.account_id === this.monitoring.account_id &&
                account.role_monitoring !== 'none'
            )
          case 'instagram':
            return person.in_accounts.find(
              account =>
                account.account_id === this.monitoring.account_id &&
                account.role_monitoring !== 'none'
            )
          default:
            return false
        }
      })
      .map(person => person.user)
  }

  get disabled() {
    return (this.action === 'remove' && !this.message.trim()) || this.is_expired
  }

  get reply_color() {
    return this.action === 'create_reply' ? 'primary' : 'link'
  }

  get hidden_color() {
    return this.action === 'hidden' ? 'primary' : 'link'
  }

  get remove_color() {
    return this.action === 'remove' ? 'primary' : 'link'
  }

  get memo_message() {
    if (!this.message.trim()) {
      return ''
    }

    let to_persons = ''

    if (this.destination_ids.length > 0) {
      const persons = this.destination_ids.map(person_id => {
        const person = this.api_persons.find(v => v.user.id === person_id)
        return person ? `@${person.user.name}` : ''
      })

      to_persons = persons.length > 0 ? `${persons.join(' ')}\n` : ''
    }

    return `${to_persons}${this.message.trim()}`
  }

  @Emit('update-monitoring')
  updateMonitoring(payload: any) {
    return payload
  }

  @Emit('open-post')
  openPostDialog() {
    return {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns
    }
  }

  @Emit('open-template')
  openTemplateDialog(event: Event) {
    TrackingService.sendEvent('click:モニタリング|レコード|テンプレートの管理')

    return event
  }

  /**
   * リポスト
   */
  addRetweet(value: string) {
    switch (value) {
      case 'retweet':
        this.retweetMonitoring()
        break
      case 'unretweet':
        this.unretweetMonitoring()
        break
      case 'retweet_comment':
        this.showCreateQuoteRetweetForm()
        break
    }
  }

  /**
   * 返信の削除チェック
   */
  isRemoveReply(sns_id: string | null): boolean {
    if (!sns_id) return false

    const types = [
      'facebook_comment',
      'facebook_post',
      'twitter_reply',
      'twitter_mention',
      'instagram_comment'
    ]
    const type = `${this.monitoring.sns}_${this.monitoring.type}`

    return types.includes(type)
  }

  /**
   * テンプレートの変更
   */
  changeTemplate() {
    TrackingService.sendEvent('click:モニタリング|レコード|テンプレートの挿入')

    const template = this.templates.find(v => v.id === this.template)

    let message = template ? template.content : ''

    if (
      (this.monitoring.sns === 'twitter' &&
        ['mention', 'reply'].some(v => v === this.monitoring.type)) ||
      this.monitoring.sns === 'instagram'
    ) {
      message = message.replace('__username__', `@${this.monitoring.from_account.username}`)
    } else {
      message = message.replace('__username__', this.monitoring.from_account.name)
    }

    this.message = [this.message, message].filter(Boolean).join('\n')
  }

  /**
   * 詳細を表示
   */
  showDetails() {
    TrackingService.sendEvent('click:モニタリング|レコード|詳細を表示')

    window.open(this.monitoring.sns_url, '_blank')
  }

  /**
   * フォームのリセット
   */
  resetForm() {
    this.setPersons([])
    this.action = ''
    this.message = ''
    this.template = ''
    this.reply_id = null
  }

  /**
   * 返信の追加フォームを表示
   */
  showCreateReplyForm() {
    this.resetForm()
    this.action = 'create_reply'
  }

  /**
   * リポストの追加フォームを表示
   */
  showCreateQuoteRetweetForm() {
    this.resetForm()
    this.action = 'create_quote_retweet'
  }

  /**
   * 返信の更新フォームを表示
   */
  showUpdateReplyForm(reply: TMonitoringReply) {
    this.resetForm()
    this.action = 'update_reply'
    this.reply_id = reply.id
    this.message = reply.message
  }

  /**
   * 非表示フォームを表示
   */
  showHiddenForm() {
    this.resetForm()
    this.action = 'hidden'
  }

  /**
   * 削除フォームを表示
   */
  showRemoveForm() {
    this.resetForm()
    this.action = 'remove'
  }

  /**
   * リプライ内容の変換
   */
  convertReply(text: string) {
    return utils.convertMonitoringReply(text, this.monitoring.sns)
  }

  /**
   * モニタリングをいいねする
   */
  async likeMonitoring() {
    TrackingService.sendEvent('click:モニタリング|レコード|いいね！')

    if (this.monitoring.is_like) return

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      is_like: true
    }

    this.loading = true

    const result = await this.updateMonitoringLike(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.resetForm()

      this.updateMonitoring(params)

      this.showNotification({ title: 'いいね！しました。' })
    } else if (result.error && result.error.type === 'TOKEN_EXPIRED') {
      this.showNotification({
        type: 'error',
        title: 'アクセストークンが失効しているため、いいね！に失敗しました。',
        message: convertHelpMessage({
          message: 'アクセストークン更新後、再度操作をしてください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    } else {
      this.showNotification({
        title: 'いいね！に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * モニタリングのいいねを解除する
   */
  async unlikeMonitoring() {
    TrackingService.sendEvent('click:モニタリング|レコード|いいね！済')

    if (!this.monitoring.is_like) return

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      is_like: false
    }

    this.loading = true

    const result = await this.updateMonitoringLike(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.resetForm()

      this.updateMonitoring(params)

      this.showNotification({ title: 'いいね！を取り消しました。' })
    } else if (result.error && result.error.type === 'TOKEN_EXPIRED') {
      this.showNotification({
        type: 'error',
        title: 'アクセストークンが失効しているため、いいね！に失敗しました。',
        message: convertHelpMessage({
          message: 'アクセストークン更新後、再度操作をしてください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    } else {
      this.showNotification({
        title: 'いいね！の取り消しに失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * モニタリングをリポストする
   */
  async retweetMonitoring() {
    if (this.monitoring.is_retweet) return

    const message = this.$options.filters.translate('[[name]]でリポストしてよろしいですか？', {
      name: this.monitoring.own_account.name
    })

    const confirm = window.confirm(message)

    if (!confirm) return

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      is_retweet: true
    }

    const result = await this.updateMonitoringRetweet(params)

    if (result.data) {
      this.resetForm()

      this.updateMonitoring(params)

      this.showNotification({ title: 'リポストしました。' })
    } else if (result.error && result.error.type === 'TOKEN_EXPIRED') {
      this.showNotification({
        type: 'error',
        title: 'アクセストークンが失効しているため、リポストに失敗しました。',
        message: convertHelpMessage({
          message: 'アクセストークン更新後、再度操作をしてください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    } else {
      this.showNotification({
        title: 'リポストに失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * モニタリングのリポストを解除する
   */
  async unretweetMonitoring() {
    if (!this.monitoring.is_retweet) return

    const message = this.$options.filters.translate(
      '[[name]]のリポストを解除してよろしいですか？',
      {
        name: this.monitoring.own_account.name
      }
    )

    const confirm = window.confirm(message)

    if (!confirm) return

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      is_retweet: false
    }

    this.loading = true

    const result = await this.updateMonitoringRetweet(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.resetForm()

      this.updateMonitoring(params)

      this.showNotification({ title: 'リポストを取り消しました。' })
    } else if (result.error && result.error.type === 'TOKEN_EXPIRED') {
      this.showNotification({
        type: 'error',
        title: 'アクセストークンが失効しているため、リポストの取り消しに失敗しました。',
        message: convertHelpMessage({
          message: 'アクセストークン更新後、再度操作をしてください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    } else {
      this.showNotification({
        title: 'リポストの取り消しに失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * モニタリングを非表示にする
   */
  async hiddenMonitoring() {
    TrackingService.sendEvent('click:モニタリング|レコード|非表示')

    if (this.monitoring.is_hidden) return

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      is_hidden: true,
      memo_message: this.memo_message,
      memo_destination_ids: this.destination_ids
    }

    this.loading = true

    const result = await this.updateMonitoringHidden(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.resetForm()

      this.updateMonitoring(params)

      this.showNotification({ title: '非表示にしました。' })
    } else {
      if (result.error.type === 'TOKEN_EXPIRED') {
        this.showNotification({
          title: 'アクセストークンが失効しているため、非表示に失敗しました。',
          message: convertHelpMessage({
            message: 'アクセストークン更新後、再度操作をしてください。',
            help_color: 'warning',
            help_type: 'ABOUT_ACCESS_TOKEN'
          }),
          type: 'error'
        })
      } else {
        this.showNotification({
          title: '非表示に失敗しました。',
          message: '恐れ入りますが、時間をおいて再度お試しください。',
          type: 'error'
        })
      }
    }
  }

  /**
   * モニタリングの非表示を解除する
   */
  async unhiddenMonitoring() {
    TrackingService.sendEvent('click:モニタリング|レコード|非表示を解除')

    if (!this.monitoring.is_hidden) return

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      is_hidden: false
    }

    this.loading = true

    const result = await this.updateMonitoringHidden(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.resetForm()

      this.updateMonitoring(params)

      this.showNotification({ title: '非表示を解除しました。' })
    } else {
      if (result.error.type === 'TOKEN_EXPIRED') {
        this.showNotification({
          title: 'アクセストークンが失効しているため、非表示の解除に失敗しました。',
          message: convertHelpMessage({
            message: 'アクセストークン更新後、再度操作をしてください。',
            help_color: 'warning',
            help_type: 'ABOUT_ACCESS_TOKEN'
          }),
          type: 'error'
        })
      } else {
        this.showNotification({
          title: '非表示の解除に失敗しました。',
          message: '恐れ入りますが、時間をおいて再度お試しください。',
          type: 'error'
        })
      }
    }
  }

  /**
   * モニタリングを削除する
   */
  async removeMonitoring() {
    TrackingService.sendEvent('click:モニタリング|レコード|削除')

    if (this.monitoring.is_deleted || !this.memo_message) return

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      memo_message: this.memo_message,
      memo_destination_ids: this.destination_ids
    }

    this.loading = true

    const result = await this.updateMonitoringDelete(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.resetForm()

      this.updateMonitoring(params)

      this.showNotification({ title: '削除しました。' })
    } else if (result.error && result.error.type === 'TOKEN_EXPIRED') {
      this.showNotification({
        type: 'error',
        title: 'アクセストークンが失効しているため、削除に失敗しました。',
        message: convertHelpMessage({
          message: 'アクセストークン更新後、再度操作をしてください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    } else {
      this.showNotification({
        title: '削除に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * モニタリングに返信する
   */
  async replyMonitoring() {
    TrackingService.sendEvent('click:モニタリング|レコード|返信')

    if (!this.message.trim()) return

    let message = this.message.trim()

    const types = ['twitter_reply', 'twitter_mention']
    const type = `${this.monitoring.sns}_${this.monitoring.type}`

    // Twitterのメンション・リプライに返信する場合、返信内容に@usernameを追加
    if (types.includes(type)) {
      message = `@${this.monitoring.from_account.username} ${message}`
    }

    if (this.reply_id) {
      await this.updateReply(message)
    } else {
      await this.createReply(message)
    }
  }

  /**
   * 返信を作成
   */
  async createReply(message: string) {
    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      message
    }

    this.loading = true

    const result = await this.createMonitoringReply(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.resetForm()

      this.updateMonitoring(params)

      this.showNotification({ title: '返信しました。' })
    } else if (result.error && result.error.type === 'FAILED_FACEBOOK_MESSAGE_REPLY') {
      this.showNotification({
        title: '返信に失敗しました。',
        message: '24時間以上経過しているメッセージのため返信ができません。',
        type: 'error'
      })
    } else if (result.error && result.error.type === 'TOKEN_EXPIRED') {
      this.showNotification({
        type: 'error',
        title: 'アクセストークンが失効しているため、返信に失敗しました。',
        message: convertHelpMessage({
          message: 'アクセストークン更新後、再度操作をしてください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    } else {
      this.showNotification({
        title: '返信に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * 返信を更新
   */
  async updateReply(message: string) {
    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      reply_id: this.reply_id,
      message
    }

    this.loading = true

    const result = await this.updateMonitoringReply(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.resetForm()

      this.updateMonitoring(params)

      this.showNotification({ title: '返信を更新しました。' })
    } else if (result.error && result.error.type === 'TOKEN_EXPIRED') {
      this.showNotification({
        type: 'error',
        title: 'アクセストークンが失効しているため、返信に失敗しました。',
        message: convertHelpMessage({
          message: 'アクセストークン更新後、再度操作をしてください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    } else {
      this.showNotification({
        title: '返信の更新に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * 返信を削除
   */
  async removeReply(reply_id: number) {
    const sns_name = getSnsName(this.monitoring.sns)

    const message = this.$options.filters.translate(
      '返信を削除してよろしいですか？[[sns]]上からも削除されます。',
      { sns: sns_name }
    )

    const confirm = window.confirm(message)

    if (!confirm) return

    TrackingService.sendEvent('click:モニタリング|レコード|削除(返信)')

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      reply_id
    }

    this.loading = true

    const result = await this.removeMonitoringReply(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.resetForm()

      this.updateMonitoring(params)

      this.showNotification({ title: '返信を削除しました。' })
    } else if (result.error && result.error.type === 'TOKEN_EXPIRED') {
      this.showNotification({
        type: 'error',
        title: 'アクセストークンが失効しているため、削除に失敗しました。',
        message: convertHelpMessage({
          message: 'アクセストークン更新後、再度操作をしてください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    } else {
      this.showNotification({
        title: '返信を削除に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * リポストを作成
   */
  async createQuoteRetweet() {
    const message = this.message.trim()

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      message
    }

    this.loading = true

    const result = await this.createMonitoringQuoteRetweet(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.resetForm()

      this.updateMonitoring(params)

      this.showNotification({ title: 'リポストしました。' })
    } else if (result.error && result.error.type === 'TOKEN_EXPIRED') {
      this.showNotification({
        type: 'error',
        title: 'アクセストークンが失効しているため、リポストに失敗しました。',
        message: convertHelpMessage({
          message: 'アクセストークン更新後、再度操作をしてください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    } else {
      this.showNotification({
        title: 'リポストに失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * リポストを削除
   */
  async removeQuoteRetweet(quote_retweet_id: number) {
    const message = this.$options.filters.translate(
      '引用を削除してよろしいですか？ X上からも削除されます。'
    )

    const confirm = window.confirm(message)

    if (!confirm) return

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      quote_retweet_id
    }

    this.loading = true

    const result = await this.removeMonitoringQuoteRetweet(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.resetForm()

      this.updateMonitoring(params)

      this.showNotification({ title: 'リポストを削除しました。' })
    } else if (result.error && result.error.type === 'TOKEN_EXPIRED') {
      this.showNotification({
        type: 'error',
        title: 'アクセストークンが失効しているため、リポストの削除に失敗しました。',
        message: convertHelpMessage({
          message: 'アクセストークン更新後、再度操作をしてください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    } else {
      this.showNotification({
        title: 'リポストの削除に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }
}
