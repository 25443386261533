import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Label from '@/client/components-old/atoms/Label'

@Component({
  name: 'InputKeyword',
  components: {
    Button,
    Icon,
    Label,
    Input
  }
})
export default class InputKeyword extends Vue {
  @Prop({ type: Array, default: () => [] })
  value!: string[]

  @Prop({ type: Number, default: 3 })
  maxCount!: number

  @Prop({ type: Number, default: 50 })
  maxLength!: number

  @Prop({ type: Number, default: 50 })
  maxKeywordLength!: number

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: Boolean, default: false })
  hideCreateButton!: boolean

  @Prop({ type: String, default: 'keyword' })
  type!: 'keyword' | 'hashtag'

  @Prop({ type: Array, default: () => [] })
  forbiddenWords!: RegExp[]

  $refs!: {
    input: HTMLInputElement
  }

  visible = false
  keyword = ''

  get maxlength() {
    const maxlength = this.maxLength - this.value.reduce((p, v) => p + v.length, 0)

    return maxlength >= 0 ? maxlength : 0
  }

  get is_create() {
    return (
      !this.visible && !this.hideCreateButton && this.value.length < this.maxCount && this.maxlength
    )
  }

  get is_duplicated() {
    return this.value.includes(this.keyword.trim())
  }

  get is_contains_forbidden_word() {
    const keyword = this.keyword.trim()

    return this.forbiddenWords.some(v => v.test(keyword))
  }

  get is_disabled() {
    const keyword = this.keyword.trim()

    return !keyword || this.is_duplicated || this.is_contains_forbidden_word
  }

  @Watch('value', { deep: true })
  async watchValue() {
    this.hideInput()
  }

  @Emit('input')
  changeKeywords(val: string[]) {
    return val
  }

  /**
   * インプットを表示
   */
  async showInput() {
    this.visible = true
    await this.$nextTick()
    this.$refs.input.focus()

    this.$emit('show')
  }

  /**
   * インプットを非表示
   */
  hideInput() {
    this.visible = false
    this.keyword = ''

    this.$emit('hide')
  }

  /**
   * キーワードの追加
   */
  createKeyword() {
    let keyword = this.keyword.trim()

    if (this.type === 'hashtag') {
      if (keyword.startsWith('＃')) {
        keyword = keyword.replace(/^＃/, '#')
      }

      if (!keyword.startsWith('#')) {
        keyword = '#' + keyword
      }
    }

    const keywords = [...this.value, keyword]

    this.changeKeywords(keywords)

    this.hideInput()
  }

  /**
   * キーワードの削除
   */
  removeKeyword(keyword: string) {
    const keywords = this.value.filter(v => v !== keyword)

    this.changeKeywords(keywords)
  }
}
