import moment from 'moment-timezone'

import {
  IFacebookInsightsAccounts,
  IFacebookInsightsPosts
} from '@/client/utils/api/facebook_insights'

import * as calculation from './calculation'

/**
 * 分析データから値を取得
 * @param {any} insight 分析データ（単体）
 * @param {string} metric 指標名
 * @returns {any} 値
 */
export function getMetricValue(insight: any, metric: string): any {
  const names = metric.split('.')

  let temp = insight

  names.forEach(name => {
    temp = temp[name]
  })

  return temp
}

/**
 * 分析データから値の配列を取得
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {R[]} 指標データの配列
 */
export function getMetricArray<R>(insights: any[], metric: string): R[] {
  return insights.map(insight => getMetricValue(insight, metric))
}

/**
 * 分析データから値の配列を取得（数値化）
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {number} 指標データの配列（数値化）
 */
export function getMetricArrayNumber(insights: any[], metric: string): number[] {
  return insights.map(v => {
    const value = getMetricValue(v, metric)

    // オブジェクトの場合は全ての値を合計する
    if (Object.prototype.toString.call(value) === '[object Object]') {
      return calculation.addition(Object.keys(value).map(k => value[k]))
    }

    return value
  })
}

/**
 * 分析データから値の合計を取得
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {number} 指標データの合計
 */
export function getMetricTotal(insights: any[], metric: string): number {
  switch (metric) {
    // 累計数の場合、最終日の値を返す
    case 'page_fans': {
      const metric_array = getMetricArrayNumber(insights, metric)
      return metric_array.length > 0 ? metric_array[0] : 0
    }

    default:
      return calculation.addition(getMetricArrayNumber(insights, metric))
  }
}

/**
 * 分析データから値の平均を取得
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {number} 指標データの平均
 */
export function getMetricAverage(insights: any[], metric: string): number {
  let numerator = 0
  let denominator = 0

  switch (metric) {
    // 累計数
    case 'page_fans':
      numerator = calculation.addition(getMetricArrayNumber(insights, metric))
      denominator = insights.length
      return calculation.division(numerator, denominator, 1)

    // 反応率
    case 'reactions_rate':
      numerator = getMetricTotal(insights, 'reactions') // 反応数
      denominator = getMetricTotal(insights, 'page_fans_at_that_time') // 公開日のファン数
      return calculation.percentage(numerator, denominator)

    // エンゲージメント率
    case 'post_engaged_users_rate':
      numerator = getMetricTotal(insights, 'post_engaged_users') // エンゲージメント数
      denominator = getMetricTotal(insights, 'post_impressions_unique') // リーチ
      return calculation.percentage(numerator, denominator)

    // クリック率
    case 'post_consumptions_unique_rate':
      numerator = getMetricTotal(insights, 'post_consumptions_unique') // クリック人数
      denominator = getMetricTotal(insights, 'post_impressions_unique') // リーチ
      return calculation.percentage(numerator, denominator)

    // ファンのリーチ率
    case 'post_impressions_fan_unique_rate':
      numerator = getMetricTotal(insights, 'post_impressions_fan_unique') // ファンのリーチ数
      denominator = getMetricTotal(insights, 'page_fans_at_that_time') // 公開日のファン数
      return calculation.percentage(numerator, denominator)

    // ファンのエンゲージメント率
    case 'post_engaged_fan_rate':
      numerator = getMetricTotal(insights, 'post_engaged_fan') // ファンのエンゲージメント数
      denominator = getMetricTotal(insights, 'page_fans_at_that_time') // 公開日のファン数
      return calculation.percentage(numerator, denominator)

    // 動画系 (投稿テーブルの場合を考慮)
    case 'post_video_views':
    case 'post_video_views_organic':
    case 'post_video_views_paid':
    case 'post_video_views_unique':
    case 'post_video_views_organic_unique':
    case 'post_video_views_paid_unique':
    case 'post_video_complete_views':
    case 'post_video_complete_views_unique':
    case 'post_video_complete_views_organic_unique':
    case 'post_video_complete_views_paid_unique':
    case 'post_video_views_autoplayed':
    case 'post_video_views_clicked_to_play':
    case 'post_video_avg_time_watched':
    case 'post_video_view_time_by_age_bucket_and_gender':
      numerator = getMetricTotal(insights, metric)
      denominator = insights.filter((v: any) => v.type === 'video').length
      return calculation.division(numerator, denominator, 1)

    // 短縮URLクリック数 (投稿テーブルの場合を考慮)
    case 'short_url_clicks':
      numerator = getMetricTotal(insights, metric)
      denominator = insights.filter((v: any) => v.use_short_url).length
      return calculation.division(numerator, denominator, 1)

    // その他の指標
    default:
      numerator = getMetricTotal(insights, metric)
      denominator = insights.length
      return calculation.division(numerator, denominator, 1)
  }
}

/**
 * 指標と期間で関連データを取得
 * @param {any} analytics
 * @param {string} metric
 * @param {string} timezone
 * @param {any} time
 * @returns {any} 関連データ
 */
export function getDataByMetric(analytics: any, metric: string, timezone: string, time?: any): any {
  let data = {}
  let analytic

  switch (metric) {
    case 'page_fans_gender_age':
      analytic = analytics.find(analytic => Object.keys(analytic[metric]).length > 0)

      if (!analytic) return null

      Object.keys(analytic[metric]).forEach(key => {
        // undefined性別を解除
        if (key.match('U')) {
          delete analytic[metric][key]
        }
      })

      return analytic[metric]

    case 'post_video_view_time_by_age_bucket_and_gender':
      analytic = analytics.filter(v => Object.keys(v[metric]).length > 0).map(v => v[metric])

      Object.keys(analytic).forEach(key => {
        // undefined性別を解除
        if (key.match('U')) {
          delete analytic[key]
        }
      })

      return analytic

    case 'page_fans_country':
    case 'page_fans_city':
      analytic = analytics.find(analytic => Object.keys(analytic[metric]).length > 0)
      return analytic ? analytic[metric] : null

    case 'page_fans_online': {
      analytic = analytics
        .filter(account => Object.keys(account[metric]).length > 0)
        .map(account => account[metric])
        .slice(0, 7)

      if (!analytic || !analytic.length) return null

      analytic.forEach(fan => {
        fan.forEach((value, index) => {
          data[index] = data[index] ? data[index] + Number(value) : Number(value)
        })
      })

      data = Object.keys(data).map(key => data[key])

      const fans_online_data = Array.from({ length: 24 }, () => 0)

      // ユーザーが設定したタイムゾーン
      const user_timezone = moment.tz(timezone).format('YYYY-MM-DD HH:mm')
      // Facebook Graph APIのタイムゾーン
      const api_timezone = moment.tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm')

      // ユーザーが設定したタイムゾーンとPSTの時差
      const fans_online_offset = moment(api_timezone).diff(moment(user_timezone), 'hours')

      let key_time = 0

      // タイムゾーンに合わせて24時間のデータの位置を調整する
      Object.keys(data).forEach(key => {
        key_time = Number(key) + fans_online_offset

        if (key_time < 0) {
          key_time = key_time + 24
        } else if (23 < key_time) {
          key_time = key_time - 24
        }

        if (data[key_time]) {
          fans_online_data[key] = data[key_time]
        }
      })

      return fans_online_data
    }

    case 'page_fans_online_per_day':
      analytic = analytics.filter(account => account.page_fans_online_per_day !== undefined)

      if (!analytic || !analytic.length) return null

      // 直近でデータが存在する日付の数値をセットする
      for (let index = 0; index < 7; index++) {
        const fan = analytics
          .filter(account => index === moment(account.created_time).day())
          .find(fan => fan.page_fans_online_per_day > 0)

        if (fan) data[index] = fan.page_fans_online_per_day
      }

      return data

    default:
      if (time) {
        return analytics.filter(
          post =>
            moment(post.created_time).unix() >= time.start &&
            moment(post.created_time).unix() <= time.end
        )
      }

      return analytics
  }
}

/**
 * ページ分析をキーによってフィルタリング
 * @param {IFacebookInsightsAccounts[]} daily_data ページ分析データ
 * @param {IFacebookInsightsPosts[]} post_data 投稿分析データ
 * @param {string} key 取得するキー
 * @returns {IFacebookInsightsAccounts[]} 合計値
 */
export function filterDailyData(
  daily_data: IFacebookInsightsAccounts[],
  post_data: IFacebookInsightsPosts[],
  key: string
): IFacebookInsightsAccounts[] {
  switch (key) {
    case 'post_count':
      return daily_data.map(account => {
        const post_count = post_data.filter(post =>
          moment(post.created_time).isSame(moment(account.created_time), 'day')
        ).length

        return { ...account, post_count }
      })

    default:
      return [...daily_data]
  }
}

/**
 * 投稿分析をキーによってフィルタリング
 * @param {IFacebookInsightsPosts[]} post_data 投稿分析データ
 * @param {string} key 取得するキー
 * @returns {IFacebookInsightsPosts[]} 合計値
 */
export function filterPostData(
  post_data: IFacebookInsightsPosts[],
  key: string
): IFacebookInsightsPosts[] {
  switch (key) {
    case 'post_video_views':
    case 'post_video_views_organic':
    case 'post_video_views_paid':
    case 'post_video_views_unique':
    case 'post_video_views_organic_unique':
    case 'post_video_views_paid_unique':
    case 'post_video_complete_views':
    case 'post_video_complete_views_unique':
    case 'post_video_complete_views_organic_unique':
    case 'post_video_complete_views_paid_unique':
    case 'post_video_views_autoplayed':
    case 'post_video_views_clicked_to_play':
    case 'post_video_avg_time_watched':
    case 'post_video_view_time_by_age_bucket_and_gender':
      return post_data.filter(post => post.type === 'video')
    case 'short_url_clicks':
      return post_data.filter(post => post.use_short_url)
    default:
      return [...post_data]
  }
}

/**
 * 指標によって値の表示方法を変換する
 * @param {number} value 値
 * @param {string} metric 指標
 * @returns {string} 加工した値文字列
 */
export function convertValueWithMetric(value: number, metric: string): string {
  switch (metric) {
    case 'reactions_rate':
    case 'post_engaged_users_rate':
    case 'post_consumptions_unique_rate':
    case 'post_impressions_fan_unique_rate':
    case 'post_engaged_fan_rate':
      if (!value) return '0%'
      return `${value.toLocaleString()}%`
    case 'page_video_view_time':
    case 'post_video_avg_time_watched':
    case 'post_video_view_time_by_age_bucket_and_gender':
      if (!value) return '0s'
      return `${calculation.millisecondToSecond(value).toLocaleString()}s`
    case 'page_fan_up_down':
      if (!value) return '0'
      if (Math.sign(value) === 1) return `+${value.toLocaleString()}`
      return value.toLocaleString()
    default:
      if (!value) return '0'
      return value.toLocaleString()
  }
}
