import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { NO_IMAGE_URL } from '@/client/constants/media'

type TIconSymbol = 'facebook' | 'twitter' | 'instagram' | 'tiktok' | 'star' | 'approve' | 'reject'
type TIconSize = 'normal' | 'small' | 'large' | 'minimum'

@Component({
  name: 'Account',
  components: {
    Tooltip,
    Icon
  }
})
export default class Account extends Vue {
  @Prop({ type: String, required: false })
  name: string

  @Prop({ type: String, default: null })
  userName: string | null

  @Prop({ type: String, default: 'normal' })
  iconSize: TIconSize

  @Prop({ type: String, default: '' })
  description: string

  @Prop({ type: Boolean, default: true })
  isDisplayName: boolean

  @Prop({ type: String, default: null })
  iconImage: string

  @Prop({ type: String, default: null })
  iconSymbol: TIconSymbol

  @Prop({ type: Boolean, default: false })
  iconCircle: boolean

  @Prop({ type: Boolean, default: false })
  isActive: boolean

  @Prop({ type: Boolean, default: false })
  isUseMonitoring: boolean

  @Prop({ type: Boolean, default: true })
  isDisplayTooltip: boolean

  @Prop({ type: Boolean, default: false })
  isColumn: boolean

  get name_classes() {
    return { 'is-column': this.isColumn }
  }

  get icon_classes() {
    return {
      'account-icon-size-normal': this.iconSize === 'normal',
      'account-icon-size-small': this.iconSize === 'small',
      'account-icon-size-large': this.iconSize === 'large',
      'account-icon-size-minimum': this.iconSize === 'minimum',
      'account-icon-circle': this.iconCircle,
      active: this.isActive
    }
  }

  get symbol_class() {
    switch (this.iconSymbol) {
      case 'facebook':
      case 'twitter':
      case 'instagram':
      case 'tiktok':
      case 'star':
      case 'approve':
      case 'reject':
        return `symbol-${this.iconSymbol}`
      default:
        return ''
    }
  }

  get icon_style() {
    return { backgroundImage: `url(${this.iconImage === null ? NO_IMAGE_URL : this.iconImage})` }
  }

  get icon_symbol() {
    switch (this.iconSymbol) {
      case 'facebook':
        return 'facebook-with-circle'
      case 'twitter':
        return 'twitter-with-circle'
      case 'instagram':
        return 'instagram-with-circle'
      case 'tiktok':
        return 'tiktok-with-circle'
      case 'star':
        return 'star'
      case 'approve':
        return 'done'
      case 'reject':
        return 'not-interested'
      default:
        return ''
    }
  }

  get name_initial() {
    if (!this.name) {
      return ''
    }

    return this.name.substr(0, 1)
  }

  get is_display_image() {
    return this.iconImage !== null
  }

  get is_display_symbol() {
    return this.iconSymbol !== null
  }

  get is_display_description() {
    return this.description.length > 0
  }
}
