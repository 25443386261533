import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import DashboardHistoryItem from '@/client/components-old/molecules/DashboardHistoryItem'
import { IState as IDashboardState } from '@/client/store/modules/dashboard'

const dashboard = namespace('dashboard')

@Component({
  name: 'DashboardPostHistory',
  components: {
    Icon,
    Message,
    Panel,
    Tooltip,
    Account,
    DashboardHistoryItem
  }
})
export default class DashboardPostHistory extends Vue {
  @dashboard.State('api_post_history') histories: IDashboardState['api_post_history']
  @dashboard.State('is_scroll_post_history') is_scroll: IDashboardState['is_scroll_post_history']
  @dashboard.State('is_post_history_loading') is_loading: IDashboardState['is_post_history_loading']
  @dashboard.State('filter_project_all') filter_project_all: IDashboardState['filter_project_all']
  @dashboard.Action('scrollPostHistory') scrollPostHistory

  $refs: {
    scroll: HTMLDivElement
  }

  get is_empty() {
    return !this.histories.length
  }

  @Emit('open-post')
  openPost(payload: any) {
    return { ...payload, mode: 'preview', tab: 'history' }
  }

  @Watch('is_scroll')
  initialize() {
    if (this.is_scroll && this.$refs.scroll) {
      this.$refs.scroll.scrollTop = 0
    }
  }

  /**
   * もっと読み込む
   */
  async loadMore(event: any) {
    const SCALING_MEASURES = 5

    const is_bottom =
      event.target.scrollHeight <=
      event.target.scrollTop + event.target.clientHeight + SCALING_MEASURES

    if (this.is_loading || !this.is_scroll || !is_bottom) return

    await this.scrollPostHistory()
  }
}
