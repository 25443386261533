import { onMounted, onUnmounted, ref } from 'vue'

import { getTimeAgo } from '@/client/utils/filters'

/**
 * 1秒おきに更新される現在時刻との差を取得
 */
export function useTimeAgo(getDatetime: () => Date | string) {
  const datetime = getDatetime()

  const timer = ref(0)
  const timeago = ref(getTimeAgo(datetime))

  onMounted(() => {
    timer.value = window.setInterval(() => {
      timeago.value = getTimeAgo(datetime)
    }, 1000)
  })

  onUnmounted(() => {
    window.clearInterval(timer.value)
  })

  return { timer, timeago }
}
