import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Account from '@/client/components-old/molecules/Account'
import { SnsType } from '@/common/types'

@Component({
  name: 'SnsRoleUser',
  components: {
    ButtonGroup,
    Icon,
    Account,
    Flex
  }
})
export default class SnsRoleUser extends Vue {
  @Prop({ type: Object, default: null })
  role!: {
    user_id: number
    user_name: string
    picture_url: string
    role_analytics: 'no_authority' | 'admin'
    role_monitoring: 'no_authority' | 'observer' | 'admin'
    role_post: 'no_authority' | 'editor' | 'admin'
  }

  @Prop({ type: String, default: null })
  sns!: SnsType

  selected_role_analytics: 'no_authority' | 'admin' = 'no_authority'
  selected_role_monitoring: 'no_authority' | 'observer' | 'admin' = 'no_authority'
  selected_role_post: 'no_authority' | 'editor' | 'admin' = 'no_authority'

  @Watch('role', { immediate: true, deep: true })
  changeRoles() {
    this.selected_role_post = this.role.role_post
    this.selected_role_analytics = this.role.role_analytics
    this.selected_role_monitoring = this.role.role_monitoring
  }

  get button_group_size() {
    return this.$mq === 'sm' ? 'minimum' : 'normal'
  }

  get analytics() {
    return [
      { value: 'no_authority', text: '権限なし' },
      { value: 'admin', text: '管理者' }
    ]
  }

  get monitoring() {
    return [
      { value: 'no_authority', text: '権限なし' },
      { value: 'observer', text: '監視者' },
      { value: 'admin', text: '管理者' }
    ]
  }

  get post() {
    return [
      { value: 'no_authority', text: '権限なし' },
      { value: 'editor', text: '編集者' },
      { value: 'admin', text: '管理者' }
    ]
  }

  // ? TikTokのみモニタリング未実装のため、暫定的に対応済みのSNSのみを表示対象とする
  get is_monitoring_sns() {
    const monitoring_sns: SnsType[] = ['facebook', 'instagram', 'twitter']
    return monitoring_sns.includes(this.sns)
  }

  @Emit('change-role')
  changeRole() {
    return {
      user_id: this.role.user_id,
      role_post: this.selected_role_post,
      role_analytics: this.selected_role_analytics,
      role_monitoring: this.selected_role_monitoring
    }
  }
}
