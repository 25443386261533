import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/instagram_analytics'
import * as calculation from '@/client/components-old/_utils/calculation'
import * as instagram_insight from '@/client/components-old/_utils/instagram_insight'
import Gender from '@/client/components-old/atoms/Gender'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartHorizontal from '@/client/components-old/molecules/ChartHorizontal'
import { TrackingService } from '@/client/services'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as IInstagramState } from '@/client/store/modules/instagram_analytics'
import csv from '@/client/utils/csv'

type TDailyData = {
  data_type: 'daily_data'
  metric: 'audience_gender_age' | 'audience_location'
}

type TInstagramAnalyticsChartHorizontalOptions = TDailyData

const analytics = namespace('analytics')
const instagram = namespace('instagram_analytics')

@Component({
  name: 'InstagramAnalyticsChartHorizontal',
  components: {
    AnalyticsPanel,
    AnalyticsPanelHeader,
    ChartHorizontal,
    Icon,
    Gender,
    Tooltip
  }
})
export default class InstagramAnalyticsChartHorizontal extends Vue {
  @instagram.State('api_accounts') daily_data: IInstagramState['api_accounts']
  @instagram.State('screen_name') screen_name: IInstagramState['screen_name']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TInstagramAnalyticsChartHorizontalOptions

  get is_data() {
    switch (this.options.data_type) {
      case 'daily_data':
        switch (this.options.metric) {
          case 'audience_gender_age':
          case 'audience_location':
            return this.daily_data.some(v => Object.keys(v[this.options.metric]).length > 0)
          default:
            return this.daily_data.length > 0
        }
      default:
        return false
    }
  }

  get is_gender() {
    return this.options.metric === 'audience_gender_age'
  }

  get panel_type() {
    const data_type = constants.DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get gender() {
    // 全体データ
    const metric_array = instagram_insight.getMetricArray<any>(this.daily_data, this.options.metric)

    // 最新データ
    const metric_array_latest = metric_array.find(v => Object.keys(v).length > 0)

    if (this.is_gender) {
      const gender_age = this.getGenderPercentage(metric_array_latest)
      return { male: `${gender_age.male}%`, female: `${gender_age.female}%` }
    }

    return { male: null, female: null }
  }

  get metric() {
    let metrics = []

    switch (this.options.data_type) {
      case 'daily_data':
        metrics = constants.HORIZONTAL_CHART_DAILY_DATA_METRICS
        break
    }

    return metrics.find(metric => metric.value === this.options.metric)
  }

  get metric_name() {
    return this.metric ? this.metric.text : ''
  }

  get metric_tooltip() {
    return this.metric ? this.metric.tooltip : ''
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }

  get chart_options() {
    const blue = '#1c84c6'
    const red = '#ed5565'

    const options = {
      display_value: true,
      display_legend: false,
      legend_position: 'top',
      categories: [],
      colors: [],
      series: [],
      label_type: this.is_gender ? '%' : ''
    }

    // 全体データ
    const metric_array = instagram_insight.getMetricArray<any>(this.daily_data, this.options.metric)

    const metric_array_latest = metric_array.find(v => Object.keys(v).length > 0)

    switch (this.options.metric) {
      case 'audience_gender_age': {
        const audience_gender_age = this.getGenderAge(metric_array_latest)

        return {
          ...options,
          colors: [red, blue],
          categories: audience_gender_age.category,
          series: [
            { name: this.$options.filters.translate('女性'), data: audience_gender_age.female },
            { name: this.$options.filters.translate('男性'), data: audience_gender_age.male }
          ]
        }
      }

      case 'audience_location': {
        const audience_location_desc = this.getArraySortDesc(metric_array_latest)
        const audience_location_top_10 = this.getArrayTop10(audience_location_desc, false)

        return {
          ...options,
          colors: [blue],
          categories: audience_location_top_10.map(item => item.name),
          series: [
            {
              name: this.$options.filters.translate(this.metric_name),
              data: audience_location_top_10.map(item => item.value)
            }
          ]
        }
      }

      default:
        return options
    }
  }

  /**
   * オブジェクトから降順にした配列を取得
   * @param {object} data
   * @returns {array} 配列
   */
  getArraySortDesc(data: { [key: string]: number }): { name: string; value: number }[] {
    if (!data) return []

    return Object.keys(data)
      .map(key => ({
        name: key,
        value: data[key]
      }))
      .sort((a, b) => {
        if (a.value > b.value) return -1
        if (a.value < b.value) return 1
        return 0
      })
  }

  /**
   * 配列からトップ10にした配列を取得
   * @param {array} data
   * @param {boolean} is_other
   * @returns {array} 配列
   */
  getArrayTop10(
    data: { name: string; value: number }[],
    is_other = true
  ): { name: string; value: number }[] {
    if (data.length <= 10) return data

    if (is_other) {
      const other = {
        name: this.$options.filters.translate('その他'),
        value: calculation.addition(data.slice(10).map(v => v.value))
      }

      return [...data.slice(0, 10), other]
    }

    return data.slice(0, 10)
  }

  /**
   * オブジェクトから性別の比率を求める
   * @param {any} data
   * @returns {object} オブジェクト
   */
  getGenderPercentage(data: any): { male: number; female: number } {
    let male_temp = 0
    let female_temp = 0

    if (!data) {
      return { male: male_temp, female: female_temp }
    }

    Object.keys(data).forEach(key => {
      if (key.indexOf('M') === 0) male_temp = male_temp + data[key]
      if (key.indexOf('F') === 0) female_temp = female_temp + data[key]
    })

    const male = calculation.percentage(male_temp, male_temp + female_temp)
    const female = calculation.percentage(female_temp, male_temp + female_temp)

    return { male, female }
  }

  /**
   * オブジェクトから年代の指標を求める
   * @param {any} data
   * @param {boolean} is_percent ％表記
   * @returns {object} オブジェクト
   */
  getGenderAge(
    data: any,
    is_percent = true
  ): { category: string[]; male: number[]; female: number[] } {
    const category = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+']
    const male = [0, 0, 0, 0, 0, 0, 0]
    const female = [0, 0, 0, 0, 0, 0, 0]

    if (!data) {
      return { category, male, female }
    }

    let total = 0

    Object.keys(data).forEach(key => {
      if (key.indexOf('M') === 0 || key.indexOf('F') === 0) {
        total += data[key]
      }
    })

    Object.keys(data).forEach(key => {
      if (key.indexOf('M') === 0) {
        category.forEach((category, index) => {
          if (key.includes(category)) {
            male[index] = is_percent ? calculation.percentage(data[key], total) : data[key]
          }
        })
      }

      if (key.indexOf('F') === 0) {
        category.forEach((category, index) => {
          if (key.includes(category)) {
            female[index] = is_percent ? calculation.percentage(data[key], total) : data[key]
          }
        })
      }
    })

    return { category, male, female }
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    TrackingService.sendEvent(
      `click:自社分析(IG)>${this.screen_name}|${this.options.metric}:CSVダウンロード`
    )

    const { categories, series } = this.chart_options

    let fields = [this.$options.filters.translate('タグ'), ...series.map(v => v.name)]
    let data = categories.map((value, index) => [value, ...series.map(v => v.data[index])])

    if (this.is_gender) {
      fields = [
        this.$options.filters.translate('タグ'),
        this.$options.filters.translate('女性'),
        this.$options.filters.translate('女性(実数)'),
        this.$options.filters.translate('男性'),
        this.$options.filters.translate('男性(実数)')
      ]

      // 全体データ
      const metric_array = instagram_insight.getMetricArray<any>(
        this.daily_data,
        this.options.metric
      )

      // 最新データ
      const metric_array_latest = metric_array.find(v => Object.keys(v).length > 0)

      const gender_age = this.getGenderAge(metric_array_latest)
      const gender_age_values = this.getGenderAge(metric_array_latest, false)

      data = gender_age.category.map((value, index) => [
        value,
        gender_age.female[index],
        gender_age_values.female[index],
        gender_age.male[index],
        gender_age_values.male[index]
      ])
    }

    const csv_data = { fields, data }

    const component_name = this.$options.filters.translate('グラフ')
    const metric_name = this.$options.filters.translate(this.metric_name)

    const csv_filename = [component_name, metric_name, this.start_date, this.end_date].join('_')

    csv.download(csv_data, csv_filename)
  }
}
