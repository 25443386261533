
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Autocomplete'
})
export default class Autocomplete extends Vue {
  @Prop({ type: String, default: '' })
  value!: string

  @Prop({ type: String, default: 'normal' })
  size!: 'normal' | 'full'

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: String, default: null })
  maxlength!: string

  @Prop({ type: String, default: '' })
  placeholder!: string

  @Prop({ type: Array, default: () => [] })
  suggest!: string[]

  @Prop({ type: Boolean, default: false })
  isFilter!: boolean

  $refs!: {
    el_input: any
    dummy_input: HTMLDivElement
  }

  get input_value() {
    return this.value
  }

  set input_value(val) {
    this.$emit('input', val)

    // PC以外の場合、Inputで入力した値がうまく反映されないため、反映できるようにダミーテキストを変更
    if (this.$device !== 'desktop') {
      this.$refs.dummy_input.innerText = this.$refs.dummy_input.innerText === '0' ? '1' : '0'
    }
  }

  get classes() {
    return [`size-${this.size}`]
  }

  /**
   * サジェストの取得
   */
  fetchSuggestions(suggestion: string, callback: any) {
    const lists = this.suggest.map(v => ({ value: v }))

    const results =
      this.isFilter && suggestion ? lists.filter(v => v.value.startsWith(suggestion)) : lists

    callback(results)
  }
}
