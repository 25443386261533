
import moment from 'moment-timezone'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { PostHistoryItem } from '@/client/components/organisms/PostHistory'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Select from '@/client/components-old/atoms/Select'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import SwitchSns from '@/client/components-old/molecules/SwitchSns'
import TextareaDifference from '@/client/components-old/molecules/TextareaDifference'
import { IPostHistory } from '@/client/utils/api/post_histories'
import i18n from '@/client/utils/i18n'
import { convertMemoMessage } from '@/client/utils/regex'
import { SnsMessageType } from '@/common/types'

@Component({
  name: 'PostDetailHistory',
  components: {
    PostHistoryItem,
    Icon,
    Message,
    Tooltip,
    Select,
    Account,
    TextareaDifference,
    SwitchSns
  }
})
export default class PostDetailHistory extends Vue {
  @Prop({ type: Array, default: () => [] })
  histories!: IPostHistory[]

  current_history_id: number | null = null
  sns_type: SnsMessageType = 'master'

  @Watch('histories', { immediate: true })
  watchHistories() {
    if (this.histories[0]) {
      this.changePostHistory(this.histories[0].id)
    }
  }

  get current_history() {
    return this.histories.find(history => history.id === this.current_history_id)
  }

  get options_histories() {
    return this.histories.map(history => ({
      text:
        this.getBadgeTextByHistoryType(history.type) +
        ' - ' +
        moment(history.created).format('lll'),
      value: history.id
    }))
  }

  get is_facebook() {
    return this.sns_type === 'facebook'
  }

  get is_twitter() {
    return this.sns_type === 'twitter'
  }

  get is_instagram() {
    return this.sns_type === 'instagram'
  }

  get is_tiktok() {
    return this.sns_type === 'tiktok'
  }

  get is_facebook_message() {
    return Boolean(this.current_history && this.current_history.fb_message)
  }

  get is_twitter_message() {
    return Boolean(this.current_history && this.current_history.tw_message)
  }

  get is_instagram_message() {
    return Boolean(this.current_history && this.current_history.in_message)
  }

  get is_tiktok_message() {
    return Boolean(this.current_history && this.current_history.tt_message)
  }

  get is_edit_media() {
    return this.current_history && this.current_history.is_edit_media
  }

  get is_empty_message() {
    if (!this.current_history) return true

    return (
      !this.current_history.master_message &&
      !this.current_history.fb_message &&
      !this.current_history.tw_message &&
      !this.current_history.in_message &&
      !this.current_history.tt_message
    )
  }

  get is_exist_memo() {
    if (!this.current_history) return false

    return Boolean(this.current_history.memo)
  }

  get scheduled_time() {
    return this.current_history && this.current_history.scheduled_time
      ? this.current_history.scheduled_time
      : null
  }

  get memo_content() {
    const message = this.current_history?.memo?.message || ''

    return convertMemoMessage(message)
  }

  get new_text() {
    if (!this.current_history) return ''

    switch (this.sns_type) {
      case 'facebook':
        return this.current_history.fb_message || this.current_history.master_message || ''
      case 'twitter':
        return this.current_history.tw_message || this.current_history.master_message || ''
      case 'instagram':
        return this.current_history.in_message || this.current_history.master_message || ''
      case 'tiktok':
        return this.current_history.tt_message || this.current_history.master_message || ''
      default:
        return this.current_history.master_message || ''
    }
  }

  get old_text() {
    if (!this.current_history) return ''

    const index = this.histories.findIndex(history => history.id === this.current_history_id)

    const next_history = this.histories[index + 1]

    if (!next_history) return this.new_text

    switch (this.sns_type) {
      case 'facebook':
        return next_history.fb_message || next_history.master_message || ''
      case 'twitter':
        return next_history.tw_message || next_history.master_message || ''
      case 'instagram':
        return next_history.in_message || next_history.master_message || ''
      case 'tiktok':
        return next_history.tt_message || next_history.master_message || ''
      default:
        return next_history.master_message || ''
    }
  }

  get post_messages() {
    if (!this.current_history) return []

    return this.current_history.publish_results
      .filter(result => {
        if (this.is_facebook) return result.sns === 'facebook'
        if (this.is_twitter) return result.sns === 'twitter'
        if (this.is_instagram) return result.sns === 'instagram'
        if (this.is_tiktok) return result.sns === 'tiktok'
        return true
      })
      .map(result => ({
        name: result.name,
        image_url: result.image_url,
        sns: result.sns,
        type: result.result === 'published' ? 'success' : 'danger',
        content:
          result.result === 'published' ? '投稿を公開しました。' : '投稿の公開に失敗しました。'
      }))
  }

  get is_single_sns() {
    const sns_count = [
      this.is_facebook_message,
      this.is_twitter_message,
      this.is_instagram_message,
      this.is_tiktok_message
    ].filter(v => v).length

    return sns_count <= 1
  }

  /**
   * 投稿履歴の変更
   */
  changePostHistory(history_id): void {
    this.sns_type = 'master'
    this.current_history_id = history_id
  }

  /**
   * システムの履歴か判別
   */
  isSystemHistory(history: IPostHistory): boolean {
    return (history.type === 'post_published' || history.type === 'post_failed') && !history.user
  }

  /**
   * 履歴タイプによってバッチテキストを取得
   */
  getBadgeTextByHistoryType(history_type: string): string {
    const approved_count = history_type.replace('post_approved_', '')

    switch (history_type) {
      case 'post_create':
        return i18n.t('作成')
      case 'post_update':
        return i18n.t('更新')
      case 'post_scheduled':
        return i18n.t('予約')
      case 'post_scheduled_canceled':
        return i18n.t('予約取消')
      case 'post_published':
        return i18n.t('公開')
      case 'post_failed':
        return i18n.t('失敗')
      case 'post_deleted':
        return i18n.t('削除')
      case 'post_approve':
        return i18n.t('承認申請')
      case 'post_approved':
        return i18n.t('承認')
      case 'post_approved_1':
      case 'post_approved_2':
      case 'post_approved_3':
        return i18n.t('承認 ([[count]]回目)', { count: approved_count })
      case 'post_approve_canceled':
        return i18n.t('申請取消')
      case 'post_reject':
        return i18n.t('差し戻し')
      default:
        return ''
    }
  }
}
