import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/facebook_comparison_analytics'
import * as facebook_comparison_insight from '@/client/components-old/_utils/facebook_comparison_insight'
import Button from '@/client/components-old/atoms/Button'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import AnalyticsList from '@/client/components-old/molecules/AnalyticsList'
import ChartSingleBar from '@/client/components-old/molecules/ChartSingleBar'
import { TrackingService } from '@/client/services'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as IFacebookState } from '@/client/store/modules/facebook_comparison_analytics'
import csv from '@/client/utils/csv'

const analytics = namespace('analytics')
const facebook = namespace('facebook_comparison_analytics')

@Component({
  name: 'FacebookComparisonAnalyticsAccountTable',
  components: {
    AnalyticsList,
    ChartSingleBar,
    Account,
    Panel,
    Button,
    ButtonGroup,
    ButtonLink,
    Message,
    Tooltip,
    Flex,
    Icon
  }
})
export default class FacebookComparisonAnalyticsAccountTable extends Vue {
  @facebook.State('api_sns_comparison_accounts')
  sns_comparison_accounts: IFacebookState['api_sns_comparison_accounts']
  @facebook.State('api_accounts') account_data: IFacebookState['api_accounts']
  @facebook.State('api_posts') post_data: IFacebookState['api_posts']
  @facebook.State('basic_display_format')
  basic_display_format: IFacebookState['basic_display_format']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']
  @facebook.Action('changeBasicDisplayFormat') changeBasicDisplayFormat

  @Prop({ type: Number, default: null })
  height: number

  sort: {
    metric: string
    order: 'ascending' | 'descending'
  } = { metric: 'order_no', order: 'ascending' }

  get total_tabs() {
    return constants.TABLE_TABS
  }

  get is_data() {
    return (
      (this.account_data.length > 0 &&
        this.account_data.some(account => account.data.length > 0)) ||
      (this.post_data.length > 0 && this.post_data.some(post => post.data.length > 0))
    )
  }

  get table_datas() {
    const data = []

    const chart_colors = ['#1c84c6', '#ed5565', '#2ec881']

    const account_ids = this.account_data.map(account => account.account_id)

    const sns_accounts = this.sns_comparison_accounts.filter(
      account => account_ids.indexOf(account.id) !== -1
    )

    for (const account of sns_accounts) {
      const account_data = this.account_data.find(v => v.account_id === account.id)
      const post_data = this.post_data.find(v => v.account_id === account.id)

      let page_fans = null
      let page_fan_up_down = null
      let talking_about_count = null
      let post_count = null
      let like_count = null
      let comment_count = null
      let share_count = null
      let reaction_count = null
      let reactions_rate = null

      if (account_data && account_data.data.length > 0) {
        page_fans = facebook_comparison_insight.getMetricTotal(account_data.data, 'page_fans')
        page_fan_up_down = facebook_comparison_insight.getMetricTotal(
          account_data.data,
          'page_fan_up_down'
        )
        talking_about_count = facebook_comparison_insight.getMetricTotal(
          account_data.data,
          'talking_about_count'
        )
      }

      if (post_data && post_data.data.length > 0) {
        post_count = post_data.data.length
        like_count = this.getMetricValue(post_data.data, 'likes')
        comment_count = this.getMetricValue(post_data.data, 'comments')
        share_count = this.getMetricValue(post_data.data, 'shares')
        reaction_count = this.getMetricValue(post_data.data, 'reactions')
        reactions_rate = this.getMetricValue(post_data.data, 'reactions_rate')
      }

      const series = [like_count, comment_count, share_count]
      const total = series.reduce((v, c) => v + c, 0)

      const reaction_chart = {
        chart: {
          series: series,
          colors: chart_colors,
          display_total: true,
          total: total,
          max: total
        },
        list: [
          {
            title: '反応数',
            options: [
              {
                key: 'いいね！',
                value: like_count,
                point: { type: 'color', value: chart_colors[0] }
              },
              {
                key: 'コメント',
                value: comment_count,
                point: { type: 'color', value: chart_colors[1] }
              },
              {
                key: 'シェア',
                value: share_count,
                point: { type: 'color', value: chart_colors[2] }
              }
            ]
          }
        ]
      }

      data.push({
        account_name: account.name,
        account_img: account.img,
        account_id: account.id,
        is_empty: account.is_empty,
        order_no: account.order_no,
        page_fans,
        page_fan_up_down: page_fan_up_down,
        page_fan_up_down_converted: facebook_comparison_insight.convertValueWithMetric(
          page_fan_up_down,
          'page_fan_up_down'
        ),
        talking_about_count,
        post_count,
        reaction_chart,
        reaction_count,
        like_count,
        comment_count,
        share_count,
        reactions_rate
      })
    }

    const max_value = Math.max(...data.map(v => v.reaction_chart.chart.max))

    return data
      .map(v => {
        v.reaction_chart.chart.max = max_value

        return v
      })
      .sort((a, b) => {
        switch (this.sort.order) {
          case 'ascending':
            return a[this.sort.metric] - b[this.sort.metric]
          case 'descending':
            return b[this.sort.metric] - a[this.sort.metric]
          default:
            return 0
        }
      })
  }

  /**
   * 指標から合計・平均した値を取得
   * @param {any[]} posts 投稿データ
   * @param {string} metric 指標
   * @returns {number} 値
   */
  getMetricValue(posts: any[], metric: string): number {
    if (this.basic_display_format === 'average') {
      return facebook_comparison_insight.getMetricAverage(posts, metric)
    }

    switch (metric) {
      case 'reactions_rate':
        return null

      default:
        return facebook_comparison_insight.getMetricTotal(posts, metric)
    }
  }

  /**
   * テーブルのスタイル追加
   * @param {any} val テーブルデータ
   * @returns {string} クラス文字列
   */
  styleSort(val: any): string {
    if (this.sort.metric === val.column.property) {
      return `sort-metric sort-${this.sort.order}`
    }

    return ''
  }

  /**
   * ソート変更時
   * @param {TFacebookAnalyticsTableSort} val
   * @returns {void}
   */
  onSort(val: any): void {
    if (val.property === this.sort.metric) {
      this.sort.order = this.sort.order === 'ascending' ? 'descending' : 'ascending'
    } else {
      this.sort = { metric: val.property, order: 'descending' }
    }
  }

  /**
   * CSVダウンロード
   * @returns {void}
   */
  onDownload(): void {
    const fields = [
      this.$options.filters.translate('ページ名'),
      this.$options.filters.translate('ページ画像'),
      this.$options.filters.translate('ファン数(累計)'),
      this.$options.filters.translate('ファン数(増減)'),
      this.$options.filters.translate('話題にしている人'),
      this.$options.filters.translate('投稿数'),
      this.$options.filters.translate('いいね！'),
      this.$options.filters.translate('コメント'),
      this.$options.filters.translate('シェア'),
      this.$options.filters.translate('反応数'),
      this.$options.filters.translate('反応率')
    ]

    const data = this.table_datas.map(table => [
      table.account_name,
      table.account_img,
      table.page_fans,
      table.page_fan_up_down_converted,
      table.talking_about_count,
      table.post_count,
      table.like_count,
      table.comment_count,
      table.share_count,
      table.reaction_count,
      table.reactions_rate
    ])

    const csv_data = { fields, data }

    const unit = this.total_tabs.find(v => v.value === this.basic_display_format)

    const component_name = this.$options.filters.translate('基本データ')
    const unit_name = this.$options.filters.translate(unit ? unit.text : '')

    const csv_filename = [component_name, unit_name, this.start_date, this.end_date].join('_')

    csv.download(csv_data, csv_filename)

    TrackingService.sendEvent(`click:競合比較(FB)>BM|基本データ:CSVダウンロード`)
  }

  /**
   * アカウントデータの表示形式を変更
   */
  async onChangeBasicDisplayFormat(payload: string) {
    TrackingService.sendEvent(`click:競合比較(FB)>BM|基本データ:${payload}`)

    await this.changeBasicDisplayFormat(payload)
  }
}
