import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Label from '@/client/components-old/atoms/Label'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import PaginationTable from '@/client/components-old/molecules/PaginationTable'
import Search from '@/client/components-old/molecules/Search'
import SwitchRow from '@/client/components-old/molecules/SwitchRow'
import { IState as IGroupSettingState } from '@/client/store/modules/group_setting'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

@Component({
  name: 'GroupSettingPostContent',
  components: {
    ButtonLink,
    Icon,
    Label,
    Message,
    Select,
    Panel,
    SwitchButton,
    Tooltip,
    PaginationTable,
    SwitchRow,
    Search
  }
})
export default class GroupSettingPostContent extends Vue {
  @group_setting.State('api_post_settings')
  api_post_settings: IGroupSettingState['api_post_settings']
  @group_setting.State('api_post_keywords')
  api_post_keywords: IGroupSettingState['api_post_keywords']
  @group_setting.Action('updatePostKeywords') updatePostKeywords
  @group_setting.Action('removePostKeywords') removePostKeywords
  @group_setting.Action('updatePostSettings') updatePostSettings
  @notification.Action('showNotification') showNotification

  keyword_page = 1
  keyword_search = ''

  get keyword_page_size() {
    return 5
  }

  get keywords() {
    return this.api_post_keywords
  }

  get filter_keywords() {
    return this.keywords.filter(v => v.keyword.match(this.keyword_search))
  }

  get display_keywords() {
    const start = (this.keyword_page - 1) * this.keyword_page_size
    const end = start + this.keyword_page_size

    return this.filter_keywords.slice(start, end)
  }

  get post_settings() {
    const setting = this.api_post_settings

    return [
      { text: '全角英数字', value: setting.check_2byte1, field: 'check_2byte1' },
      { text: '全角記号', value: setting.check_2byte2, field: 'check_2byte2' },
      { text: '全角・半角スペース', value: setting.check_space, field: 'check_space' },
      { text: '半角カタカナ', value: setting.check_kana, field: 'check_kana' },
      { text: '半角句読点', value: setting.check_punctuation, field: 'check_punctuation' }
    ]
  }

  @Emit('open-post-keyword-dialog')
  openPostKeywordDialog(payload: any) {
    return payload
  }

  /**
   * 特定のキーワードの有効フラグ変更
   */
  async updatePostKeyword(keyword_id: number, is_valid: boolean) {
    const params = {
      keyword_id,
      is_valid
    }

    const result = await this.updatePostKeywords(params)

    if (result.data) {
      return this.showNotification({ title: '設定を保存しました。' })
    }

    this.showNotification({ title: '設定の保存に失敗しました。', type: 'error' })
  }

  /**
   * 特定のキーワードの削除
   */
  async deleteKeyword(keyword_id: number) {
    const result = await this.removePostKeywords({ keyword_id })

    if (result.data) {
      return this.showNotification({ title: '設定を削除しました。' })
    }

    this.showNotification({ title: '設定の削除に失敗しました。', type: 'error' })
  }

  /**
   * 投稿内容チェック変更
   */
  async updatePostSetting(field: string, value: boolean) {
    const params = {
      [field]: value
    }

    const result = await this.updatePostSettings(params)

    if (result.data) {
      return this.showNotification({ title: '設定を保存しました。' })
    }

    this.showNotification({ title: '設定の保存に失敗しました。', type: 'error' })
  }
}
