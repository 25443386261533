import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Badge from '@/client/components-old/atoms/Badge'
import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import { IRootState } from '@/client/store/global'
import { IState as IPaymentContract } from '@/client/store/modules/payment_contract'
import API from '@/client/utils/api'
import { TGetZipAddressResponse } from '@/client/utils/api/zipaddress'
import * as regex from '@/client/utils/regex'

const payment_contract = namespace('payment_contract')

@Component({
  name: 'PaymentContractForm',
  components: {
    Panel,
    Badge,
    Button,
    Icon,
    Input,
    Select,
    Message
  }
})
export default class PaymentContractForm extends Vue {
  @State('user') user: IRootState['user']
  @State('projects') projects: IRootState['projects']
  @payment_contract.State('payment_group_id') payment_group_id: IPaymentContract['payment_group_id']
  @payment_contract.State('payment_form') payment_form: IPaymentContract['payment_form']
  @payment_contract.State('api_countries') api_countries: IPaymentContract['api_countries']
  @payment_contract.Action('changePaymentForm') changePaymentForm
  @payment_contract.Action('prevPaymentContract') prevPaymentContract
  @payment_contract.Action('nextPaymentContract') nextPaymentContract

  project_name = ''
  company_name = ''
  depart_name = ''
  person_name = ''
  email = ''
  telephone = ''
  country_code = 'JP'
  zip_code = ''
  zip_code_temp = ''
  address = ''

  is_error_project_name = false
  is_error_company_name = false
  is_error_person_name = false
  is_error_email = false
  is_error_email_format = false
  is_error_telephone = false
  is_error_telephone_format = false
  is_error_country = false
  is_error_zip_code = false
  is_error_zip_code_format = false
  is_error_address = false

  get countries() {
    return this.api_countries.map(country => ({
      text: this.user.language === 'ja' ? country.name : country.name_en,
      value: country.code
    }))
  }

  get disabled() {
    return (
      this.project_name.trim() === '' ||
      this.company_name.trim() === '' ||
      this.person_name.trim() === '' ||
      this.email.trim() === '' ||
      !regex.MAIL_FORMAT.test(this.email) ||
      this.telephone.trim() === '' ||
      this.is_error_telephone_format ||
      this.country_code.trim() === '' ||
      this.zip_code.trim() === '' ||
      this.is_error_zip_code_format ||
      this.address.trim() === ''
    )
  }

  /**
   * ストアのデータをバインド
   */
  created() {
    if (this.payment_group_id) {
      const group = this.projects.find(v => v.id === this.payment_group_id)

      this.project_name = group ? group.project_name : ''
    } else {
      this.project_name = this.payment_form.project_name
    }

    this.company_name = this.payment_form.company_name
    this.depart_name = this.payment_form.depart_name
    this.person_name = this.payment_form.person_name
    this.email = this.payment_form.email
    this.telephone = this.payment_form.telephone
    this.country_code = this.payment_form.country_code
    this.zip_code = this.payment_form.zip_code
    this.address = this.payment_form.address
  }

  /**
   * グループ名のチェック
   */
  changeProjectName(): void {
    this.is_error_project_name = this.project_name.trim() === '' ? true : false
  }

  /**
   * 会社名のチェック
   */
  changeCompanyName(): void {
    this.is_error_company_name = this.company_name.trim() === '' ? true : false
  }

  /**
   * 担当者名のチェック
   */
  changePersonName(): void {
    this.is_error_person_name = this.person_name.trim() === '' ? true : false
  }

  /**
   * メールアドレスのチェック
   */
  changeEmail(): void {
    this.is_error_email = this.email.trim() === '' ? true : false
    this.is_error_email_format = !regex.MAIL_FORMAT.test(this.email)
  }

  /**
   * 電話番号のチェック
   */
  changeTelephone(): void {
    this.is_error_telephone = this.telephone.trim() === '' ? true : false
    this.is_error_telephone_format = !regex.TELEPHONE_FORMAT.test(this.telephone)
  }

  /**
   * 国のチェック
   */
  changeCountryCode(): void {
    this.is_error_country = this.country_code.trim() === '' ? true : false
  }

  /**
   * 郵便番号のチェック
   */
  changeZipCode(): void {
    this.is_error_zip_code = this.zip_code.trim() === '' ? true : false
    this.is_error_zip_code_format = !regex.ZIP_CODE_FORMAT.test(this.zip_code)
  }

  /**
   * 住所のチェック
   */
  changeAddress(): void {
    this.is_error_address = this.address.trim() === '' ? true : false
  }

  /**
   * 郵便番号から住所の取得
   */
  async fetchZipAddress(): Promise<string> {
    if (
      this.zip_code === this.zip_code_temp ||
      this.is_error_zip_code ||
      this.is_error_zip_code_format
    )
      return

    const response = await API.get<TGetZipAddressResponse>(`zipaddress/${this.zip_code}`)

    const address = response.data.data.address

    this.zip_code_temp = this.zip_code

    if (!this.address.startsWith(address)) {
      this.address = address
      this.changeAddress()
    }
  }

  /**
   * 支払いフォームを同期する
   */
  async syncPaymentForm(): Promise<void> {
    await this.changePaymentForm({
      project_name: this.project_name.trim(),
      company_name: this.company_name.trim(),
      depart_name: this.depart_name.trim(),
      person_name: this.person_name.trim(),
      email: this.email.trim(),
      telephone: this.telephone.trim(),
      country_code: this.country_code.trim(),
      zip_code: this.zip_code.trim(),
      address: this.address.trim()
    })
  }

  /**
   * 前の画面へ戻る
   */
  async prevAction(): Promise<void> {
    await this.syncPaymentForm()
    await this.prevPaymentContract()
  }

  /**
   * 確認画面へ遷移する
   */
  async nextAction(): Promise<void> {
    await this.syncPaymentForm()
    await this.nextPaymentContract()
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.syncPaymentForm()
  }
}
