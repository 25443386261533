import { Component, Emit, Vue } from 'vue-property-decorator'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'

@Component({
  name: 'GroupSettingPostCategory',
  components: {
    Icon,
    Panel,
    Tooltip,
    ButtonLink
  }
})
export default class GroupSettingPostCategory extends Vue {
  @Emit('open-category-dialog')
  open(payload: any) {
    return payload
  }
}
