
import { Component, Emit, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import PostManagementMonth from '@/client/components-old/organisms/PostManagementMonth'

@Component({
  name: 'PostManagementMonthView',
  components: {
    Scroll,
    PostManagementMonth
  }
})
export default class PostManagementMonthView extends Vue {
  table_height: number | null = null

  get table_content_height() {
    return this.table_height - 16
  }

  /**
   * スクロールの変更
   */
  changeHeight(height: number): void {
    this.table_height = height
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
