import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/instagram_analytics'
import * as instagram_insight from '@/client/components-old/_utils/instagram_insight'
import Button from '@/client/components-old/atoms/Button'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsList from '@/client/components-old/molecules/AnalyticsList'
import CategoryList from '@/client/components-old/molecules/CategoryList'
import ChartSingleBar from '@/client/components-old/molecules/ChartSingleBar'
import PaginationTable from '@/client/components-old/molecules/PaginationTable'
import PostMedia from '@/client/components-old/molecules/PostMedia'
import { TrackingService } from '@/client/services'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as IInstagramState } from '@/client/store/modules/instagram_analytics'
import { IInstagramInsightsStories } from '@/client/utils/api/instagram_insights'
import csv from '@/client/utils/csv'

type TInstagramAnalyticsTableCustom = {
  chart: {
    series: number[]
    colors: string[]
    display_total: boolean
    total: number
    max: number
  }
  list: {
    title: string
    options: {
      key: string
      value: number
      point?: {
        type: string
        value: string
      }
    }[]
  }[]
}

const categories = namespace('categories')
const analytics = namespace('analytics')
const instagram = namespace('instagram_analytics')

@Component({
  name: 'InstagramAnalyticsStoriesTable',
  components: {
    AnalyticsList,
    CategoryList,
    ChartSingleBar,
    PaginationTable,
    PostMedia,
    Button,
    ButtonGroup,
    ButtonLink,
    Icon,
    Message,
    Panel,
    Select,
    Tooltip
  }
})
export default class InstagramAnalyticsStoriesTable extends Vue {
  @categories.State('api_post_categories') categories: ICategoriesState['api_post_categories']
  @categories.State('api_category_posts') category_posts: ICategoriesState['api_category_posts']
  @instagram.State('api_stories') stories_data: IInstagramState['api_stories']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']
  @instagram.State('stories_search_type')
  stories_search_type: IInstagramState['stories_search_type']
  @instagram.State('search_category') search_category: IInstagramState['search_category']
  @instagram.State('search_message') search_message: IInstagramState['search_message']
  @instagram.State('post_display_count') post_display_count: IInstagramState['post_display_count']
  @instagram.Action('changePostDisplayCount') changePostDisplayCount

  @Prop({ type: Number, default: null })
  height: number

  pagination = 1

  sort: {
    metric: string
    order: 'ascending' | 'descending'
  } = {
    metric: 'created_time',
    order: 'descending'
  }

  panel_tab: 'none' | 'type' | 'category' = 'none'

  total_tab: 'average' | 'total' = 'average'

  created() {
    switch (this.panel_tab) {
      case 'type':
      case 'category':
        this.sort.metric = 'post_count'
        break
      default:
        this.sort.metric = 'created_time'
    }
  }

  get table_columns() {
    return ['reach', 'impressions', 'taps_back', 'taps_forward', 'exits']
  }

  get stories() {
    let stories = [...this.stories_data]

    if (this.stories_search_type.length > 0) {
      stories = stories.filter(storie => this.stories_search_type.indexOf(storie.type) >= 0)
    }

    if (this.search_category.length) {
      stories = stories.filter(story => {
        const category_ids = this.getCategory(story.stories_id)

        return category_ids.some(category_id => this.search_category.indexOf(category_id) >= 0)
      })
    }

    if (this.search_message !== '') {
      stories = stories.filter(
        story => story.message.toLowerCase().match(this.search_message.toLowerCase()) !== null
      )
    }

    switch (this.panel_tab) {
      case 'type':
        stories = this.createStoriesByType(stories)
        break
      case 'category':
        stories = this.createStoriesByCategory(stories)
        break
    }

    return stories
  }

  get panel_tabs() {
    return constants.STORIES_TABLE_TOTAL
  }

  get total_tabs() {
    return constants.POST_TABLE_TABS
  }

  get pagination_total() {
    return this.stories.length
  }

  get is_none() {
    return this.panel_tab === 'none'
  }

  get is_type() {
    return this.panel_tab === 'type'
  }

  get is_category() {
    return this.panel_tab === 'category'
  }

  get is_data() {
    return this.pagination_total > 0
  }

  get post_limit_options() {
    return [
      { text: '10', value: 10 },
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ]
  }

  get table_stories() {
    const chart_colors = ['#1c84c6']

    const page_begin = (this.pagination - 1) * this.post_display_count
    const page_end = page_begin + this.post_display_count

    let stories = this.stories

    const max = key =>
      Math.max.apply(
        null,
        stories.map(story => story[key])
      )

    const max_reach = max('reach')
    const max_impressions = max('impressions')

    stories = stories.sort((a, b) => {
      switch (this.sort.order) {
        case 'ascending':
          return a[this.sort.metric] - b[this.sort.metric]
        case 'descending':
          return b[this.sort.metric] - a[this.sort.metric]
        default:
          return 0
      }
    })

    stories = stories.slice(page_begin, page_end)

    return stories.map(story => {
      // リーチ
      const reach: TInstagramAnalyticsTableCustom = {
        chart: {
          series: [story.reach],
          colors: chart_colors,
          display_total: true,
          total: story.reach,
          max: max_reach
        },
        list: []
      }

      // インプレッション
      const impressions: TInstagramAnalyticsTableCustom = {
        chart: {
          series: [story.impressions],
          colors: chart_colors,
          display_total: true,
          total: story.impressions,
          max: max_impressions
        },
        list: []
      }

      return {
        ...story,
        reach,
        impressions
      }
    })
  }

  /**
   * メディアタイプのストーリーズデータを作成
   * @param {any[]} filter_stories フィルタリング後の投稿データ
   * @returns {any[]} 集計した投稿データ
   */
  createStoriesByType(filter_stories: any[]): any[] {
    const metrics = filter_stories.length > 0 ? Object.keys(filter_stories[0]) : []
    const types = ['image', 'video']

    return types
      .map(type => {
        const stories = filter_stories.filter(p => p.type === type)

        const temp = { type: this.getMediaType(type), post_count: stories.length }

        metrics.forEach(metric => {
          if (constants.STORIES_TABLE_SORT_BLACKLIST.indexOf(metric) === -1) {
            temp[metric] = this.getMetricValue(stories, metric)
          }
        })

        return temp
      })
      .filter(post => post.post_count > 0)
  }

  /**
   * タグのストーリーズデータを作成
   * @param {any[]} filter_stories フィルタリング後の投稿データ
   * @returns {any[]} 集計した投稿データ
   */
  createStoriesByCategory(filter_stories: any[]): any[] {
    const metrics = filter_stories.length > 0 ? Object.keys(filter_stories[0]) : []

    return this.categories
      .map(category => {
        const stories = filter_stories.filter(s => {
          const category_ids = this.getCategory(s.stories_id)

          return category_ids.indexOf(category.id) >= 0
        })

        const temp = { category: category.name, post_count: stories.length }

        metrics.forEach(metric => {
          if (constants.STORIES_TABLE_SORT_BLACKLIST.indexOf(metric) === -1) {
            temp[metric] = this.getMetricValue(stories, metric)
          }
        })

        return temp
      })
      .filter(post => post.post_count > 0)
  }

  @Emit('open-hourly-chart')
  openHourlyChart(metric: string, hourly_insights: IInstagramInsightsStories['hourly_insights']) {
    TrackingService.sendEvent(
      `click:自社分析(IG)>ストーリーズ|公開されたストーリーズ:時間帯別:${metric}`
    )

    const hours = Object.keys(hourly_insights)
    const hourly_data = []

    for (const key of hours) {
      const hour = moment(key, 'YYYY-MM-DD HH:mm').hour()
      hourly_data[hour] = hourly_insights[key][metric]
    }

    const first_hour = hours.length > 0 ? moment(hours[0], 'YYYY-MM-DD HH:mm').hour() : 0
    const data = []
    const categories = []
    let count = 0

    while (count < 24) {
      let hour = first_hour + count
      if (hour >= 24) {
        hour = hour - 24
      }

      const value = hourly_data[hour] || 0
      data.push(value)
      categories.push(this.toHourString(hour))

      count++
    }

    const series = [
      {
        name: this.$options.filters.translate(this.getLabel(metric)),
        data: data,
        index: 0
      }
    ]

    const colors = ['#1c84c6']
    const display_legend = false
    const chart_options = {
      title: this.getLabel(metric),
      time_range: {
        start: hours.length > 0 ? hours[0] : '',
        end: hours.length > 0 ? hours[hours.length - 1] : ''
      },
      series,
      colors,
      display_legend,
      categories
    }

    return chart_options
  }

  @Emit('open-preview')
  openStoriesPreview(image: string) {
    TrackingService.sendEvent('click:自社分析(IG)>ストーリーズ|公開されたストーリーズ:プレビュー')
    const preview_options = {
      media: [{ url: image, type: 'image' }],
      index: 0
    }

    return preview_options
  }

  /**
   * 時間を文字列に変換
   * @param {number} hour
   * @returns {string} 時間
   */
  toHourString(hour: number) {
    if (hour < 10) {
      return `0${hour}:00`
    }

    return `${hour}:00`
  }

  /**
   * 指標から合計・平均した値を取得
   * @param {any[]} stories 投稿データ
   * @param {string} metric 指標
   * @returns {number} 値
   */
  getMetricValue(stories: any[], metric: string): number {
    if (this.total_tab === 'average') {
      return instagram_insight.getMetricAverage(stories, metric)
    }

    return instagram_insight.getMetricTotal(stories, metric)
  }

  /**
   * ラベルの取得
   * @param {string} val 指標
   * @returns {string} ラベル
   */
  getLabel(val: string): string {
    const metrics = constants.STORIES_TABLE_METRICS

    const metric = metrics.find(b => b.value === val)
    return metric ? metric.text : ''
  }

  /**
   * メディアタイプ名の取得
   * @param {string} val メディアタイプ
   * @returns {string} メディアタイプ名
   */
  getMediaType(val: string): string {
    const type = constants.STORIES_TYPE.find(b => b.value === val)
    return type ? type.text : ''
  }

  /**
   * タグの取得
   * @param {string} stories_id 投稿ID
   * @returns {object} タグ
   */
  getCategory(stories_id: string): number[] {
    const category = this.category_posts.find(category_post => category_post.post_id === stories_id)

    return category ? category.category_ids : []
  }

  /**
   * 名の取得
   * @param {string} stories_id 投稿ID
   * @returns {string[]} タグ名
   */
  getCategoryName(stories_id: string): string[] {
    const category_ids = this.getCategory(stories_id)

    if (!category_ids.length) return []

    return category_ids
      .map(category_id => {
        const value = this.categories.find(category => category.id === category_id)

        return value ? value.name : null
      })
      .filter(category_name => category_name)
  }

  /**
   * タグの判別
   * @param {string} stories_id ストーリーズID
   * @returns {boolean} 判定
   */
  isCategory(stories_id: string): boolean {
    const category_ids = this.getCategory(stories_id)

    return category_ids.length > 0
  }

  /**
   * チャートの判別（詳細表示なし）
   * @param {string} val 指標
   * @returns {boolean} 判定
   */
  isChart(val: string): boolean {
    switch (val) {
      case 'reach':
      case 'impressions':
        return true
      default:
        return false
    }
  }

  /**
   * タブの変更
   */
  changeTab(tab: string) {
    switch (tab) {
      case 'type':
        TrackingService.sendEvent('click:自社分析(IG)>ストーリーズ|表示切替:タグ')
        break
      case 'tag':
        TrackingService.sendEvent('click:自社分析(IG)>ストーリーズ|表示切替:メディアタイプ')
        break
      default:
        TrackingService.sendEvent('click:自社分析(IG)>ストーリーズ|表示切替:公開されたストーリーズ')
        break
    }
  }

  /**
   * 平均|合計の変更
   */
  changeTotalTab(value: string) {
    const tab = this.panel_tab === 'type' ? 'メディアタイプ' : 'タグ'
    const total_type = value === 'average' ? '平均' : '合計'

    TrackingService.sendEvent(`click:自社分析(IG)>ストーリーズ|${tab}:${total_type}`)
  }

  /**
   * テーブルのスタイル追加
   * @param {any} val テーブルデータ
   * @returns {string} クラス文字列
   */
  styleSort(val: any): string {
    if (['message', 'type', 'category'].indexOf(val.column.property) >= 0) {
      return 'not-sort'
    }

    if (this.sort.metric === val.column.property) {
      return `sort-metric sort-${this.sort.order}`
    }

    return ''
  }

  /**
   * ソート変更時
   * @param {TInstagramAnalyticsTableSort} val
   * @returns {void}
   */
  onSort(val: any): void {
    if (['message', 'type', 'category'].indexOf(val.property) >= 0) return

    switch (this.panel_tab) {
      case 'type':
        TrackingService.sendEvent(`sort:自社分析(IG)>ストーリーズ|メディアタイプ:${val.property}`)
        break
      case 'category':
        TrackingService.sendEvent(`sort:自社分析(IG)>ストーリーズ|タグ:${val.property}`)
        break
      default:
        TrackingService.sendEvent(
          `sort:自社分析(IG)>ストーリーズ|公開されたストーリーズ:${val.property}`
        )
        break
    }

    if (val.property === this.sort.metric) {
      this.sort.order = this.sort.order === 'ascending' ? 'descending' : 'ascending'
    } else {
      this.sort = { metric: val.property, order: 'descending' }
      this.pagination = 1
    }
  }

  @Emit('click-category-setting')
  onClickCategorySetting(account_id: string, stories_id: string) {
    TrackingService.sendEvent('click:自社分析(IG)>ストーリーズ|公開されたストーリーズ:タグ設定')

    const category_ids = this.getCategory(stories_id)
    return {
      account_id,
      post_id: stories_id,
      category_ids
    }
  }

  /**
   * ダウンロードボタンを押した時
   * @param {string} type ダウンロード形式
   * @returns {void}
   */
  onDownload(): void {
    switch (this.panel_tab) {
      case 'type':
        TrackingService.sendEvent(
          'click:自社分析(IG)>ストーリーズ|メディアタイプ:CSVダウンロード(表示列)'
        )
        break
      case 'category':
        TrackingService.sendEvent('click:自社分析(IG)>ストーリーズ|タグ:CSVダウンロード(表示列)')
        break
      default:
        TrackingService.sendEvent(
          'click:自社分析(IG)>ストーリーズ|公開されたストーリーズ:CSVダウンロード(表示列)'
        )
        break
    }

    let columns = [
      'created_time',
      'message',
      'type',
      'stories_id',
      'sns_link',
      'thumbnail_url',
      'media_url',
      'category_name'
    ]

    switch (this.panel_tab) {
      case 'type':
        columns = ['type', 'post_count']
        break
      case 'category':
        columns = ['category', 'post_count']
        break
    }

    columns = columns.concat(this.table_columns)

    const fields = columns.map(v => this.$options.filters.translate(this.getLabel(v)))

    const data = this.stories.map(story =>
      columns.map(column => {
        if (column === 'created_time') {
          return csv.format(story[column])
        }

        if (column === 'type') {
          if (this.panel_tab === 'none') {
            return this.$options.filters.translate(this.getMediaType(story[column]))
          }

          return this.$options.filters.translate(story[column])
        }

        if (column === 'thumbnail_url') {
          return story.type === 'image' ? story.media_url : story.thumbnail_url
        }

        if (column === 'sns_link') {
          return `https://www.instagram.com/p/${story.shortcode}`
        }

        if (column === 'category_name') {
          return this.getCategoryName(story.stories_id)
        }

        return story[column]
      })
    )

    const csv_data = { fields, data }

    const total = constants.STORIES_TABLE_TOTAL.find(b => b.value === this.panel_tab)
    const component_name = this.$options.filters.translate(total ? total.text : '')

    let csv_filename = [component_name, this.start_date, this.end_date]

    if (this.panel_tab !== 'none') {
      const total_tab = constants.POST_TABLE_TABS.find(v => v.value === this.total_tab)
      const total_name = this.$options.filters.translate(total_tab ? total_tab.text : '')
      csv_filename = [component_name, total_name, this.start_date, this.end_date]
    }

    csv.download(csv_data, csv_filename.join('_'))
  }

  /**
   * 投稿の表示件数の変更
   */
  async changeDisplayCount(display_count: number) {
    this.pagination = 1
    await this.changePostDisplayCount(display_count)
  }

  /**
   * スクロール位置を最上部に変更する
   * @returns {void}
   */
  scrollTop(): void {
    // el-table のスクロール位置を変更するため、DOM要素を取得する
    const element = document.getElementsByClassName('el-table__body-wrapper')
    if (element && element.length) {
      element[0].scrollTop = 0
    }
  }
}
