import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import UserMultiSelect from '@/client/components-old/molecules/UserMultiSelect'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import {
  IGetter as IPostCreateGetter,
  IState as IPostCreateState
} from '@/client/store/modules/post_create'

const post_create = namespace('post_create')

@Component({
  name: 'PostCreatePerson',
  components: {
    Flex,
    Panel,
    Icon,
    Tooltip,
    UserMultiSelect
  }
})
export default class PostCreatePerson extends Vue {
  @State('user') user: IRootState['user']
  @post_create.State('persons') persons: IPostCreateState['persons']
  @post_create.Getter('persons_options') persons_list: IPostCreateGetter['persons_options']
  @post_create.Mutation('SET_PERSONS') setPersons

  get persons_options() {
    if (!this.persons_list.length) return []

    const login_user = this.persons_list.find(v => v.id === this.user.id)
    const without_login_users = this.persons_list.filter(v => v.id !== this.user.id)

    // ログインユーザーは1番上にする
    return [login_user, ...without_login_users]
  }

  /**
   * 担当者を指定
   */
  changePersons(persons: number[]) {
    TrackingService.sendEvent('click:投稿作成|タグ|担当者を指定')

    this.setPersons(persons)
  }
}
