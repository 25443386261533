
import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { MIN_DATE } from '@/client/components-old/_constants/global'
import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import CalendarTrendItem from '@/client/components-old/molecules/CalendarTrendItem'
import IncidentEventItem from '@/client/components-old/molecules/IncidentEventItem'
import PostManagementMonthListPost from '@/client/components-old/organisms/PostManagementMonthListPost'
import { TrackingService } from '@/client/services'
import { IState } from '@/client/store/modules/post_management'
import event from '@/client/utils/event'
import i18n from '@/client/utils/i18n'

const post_management = namespace('post_management')
const notification = namespace('notification')

@Component({
  name: 'PostManagementMonth',
  components: {
    Button,
    Icon,
    Message,
    Tooltip,
    CalendarTrendItem,
    IncidentEventItem,
    PostManagementMonthListPost
  }
})
export default class PostManagementMonth extends Vue {
  @post_management.State('api_posts') api_posts: IState['api_posts']
  @post_management.State('api_incident_events') api_incident_events: IState['api_incident_events']
  @post_management.State('api_twitter_trends') api_twitter_trends: IState['api_twitter_trends']
  @post_management.State('start_date') start_date: IState['start_date']
  @post_management.State('is_loading') is_loading: IState['is_loading']
  @post_management.State('is_get_post_failed') is_get_post_failed: IState['is_get_post_failed']
  @post_management.State('is_twitter_trend') is_twitter_trend: IState['is_twitter_trend']
  @post_management.Action('openPostDetailDialog') openPostDetailDialog
  @notification.Action('showNotification') showNotification

  @Prop({ type: Number, default: 800 })
  height: number

  @Watch('is_loading', { immediate: true })
  watchLoading() {
    // オフセットがリセットされた場合は、上にスクロールする
    if (this.is_loading && this.$el && this.$el.querySelector('.el-table__body-wrapper')) {
      this.$el.querySelector('.el-table__body-wrapper').scrollTop = 0
    }
  }

  get header_data() {
    const weekdays = 7

    return [...Array(weekdays)].map((_, weekday) =>
      moment()
        .isoWeekday(weekday + 1)
        .format('ddd')
    )
  }

  get table_data() {
    // アクション日時または変更日時でソートする（秒のズレを直すために2回ソートする）
    const posts = [...this.api_posts]
      .sort((a, b) => moment(a.modified).unix() - moment(b.modified).unix())
      .sort(
        (a, b) =>
          moment(a.action_datetime || a.modified).unix() -
          moment(b.action_datetime || b.modified).unix()
      )

    const trends = [...this.api_twitter_trends]

    const incident_events = [...this.api_incident_events]

    const target_month = moment(this.start_date)

    const first_date = target_month.clone().startOf('month').startOf('isoWeek')

    const last_date = target_month.clone().endOf('month').endOf('isoWeek')

    const today = moment()

    const min_date = moment(MIN_DATE).startOf('day')

    const max_date = moment().startOf('day').add(6, 'months')

    const weeks = Math.round(last_date.diff(first_date, 'week', true))

    return [...Array(weeks)].map((_, week) =>
      this.header_data.map(weekday => {
        const target_date = first_date.clone().isoWeekday(weekday).add(week, 'week')

        const is_disabled =
          !target_month.isSame(target_date, 'month') ||
          max_date.isBefore(target_date) ||
          min_date.isAfter(target_date)

        const is_today = today.isSame(target_date, 'day')

        const is_past = today.isAfter(target_date, 'day')

        const target_posts = posts.filter(post =>
          moment(post.action_datetime || post.modified).isSame(target_date, 'day')
        )

        const target_trends = trends.filter(trend => target_date.isSame(trend.datetime, 'day'))

        const target_incident_events = incident_events.filter(event =>
          target_date.isSame(event.date, 'day')
        )

        const target_trends_old = trends.filter(trend =>
          target_date.clone().subtract(1, 'year').isSame(trend.datetime, 'day')
        )

        let number_of_display_post = 4

        if (!is_disabled && target_incident_events.length > 0) {
          const remain_items = 4 - target_incident_events.length
          number_of_display_post = remain_items > 0 ? remain_items : 0
        }

        return {
          day: target_date.format(),
          posts: is_disabled ? [] : target_posts,
          trends: is_disabled ? [] : is_past ? target_trends : target_trends_old,
          incident_events: is_disabled ? [] : target_incident_events,
          number_of_display_post,
          is_disabled,
          is_today,
          is_past
        }
      })
    )
  }

  get header_class() {
    return 'not-sort white'
  }

  get cell_class() {
    return 'no-padding'
  }

  /**
   * カレンダーのタイトル取得
   */
  getCalendarTitle(date: string): string {
    return moment(date).format('DD')
  }

  /**
   * ポップオーバーのタイトル取得
   */
  getPopoverTitle(date: string, count: number, event_count = 0): string {
    return `${moment(date).format('ll')} (${i18n.t('[[count]]件', { count: count + event_count })})`
  }

  /**
   * 投稿の新規作成
   */
  createPost(date: string): void {
    TrackingService.sendEvent('click:投稿管理|月|新規作成(日付)')

    let datetime = moment(date).startOf('day')

    // 当日の場合
    if (moment().isSame(datetime, 'day')) {
      datetime = moment().add(10, 'minute')

      const minute = Math.ceil(datetime.minute() / 5) * 5

      datetime.set('minute', minute)
    }

    this.changeRoute({
      name: 'posts/create',
      query: {
        scheduled_datetime: datetime.format()
      }
    })
  }

  /**
   * トレンド実績から投稿の新規作成
   */
  createPostTwitterTrend(message: string): void {
    this.changeRoute({
      name: 'posts/create',
      query: {
        message
      }
    })
  }

  /**
   * トレンド実績からクリップボードにコピー
   */
  copyMessageTwitterTrend(message: string): void {
    event.copy(message)

    this.showNotification({ title: 'クリップボードにコピーしました。' })
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
