import { render, staticRenderFns } from "./TwitterTweet.vue?vue&type=template&id=542831d2&scoped=true"
import script from "./TwitterTweet.ts?vue&type=script&lang=ts&external"
export * from "./TwitterTweet.ts?vue&type=script&lang=ts&external"
import style0 from "./TwitterTweet.vue?vue&type=style&index=0&id=542831d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "542831d2",
  null
  
)

export default component.exports