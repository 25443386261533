interface OptimizeSizeOptions {
  width: number
  height: number
}

export function optimizeSize(options: OptimizeSizeOptions): OptimizeSizeOptions {
  const LIMIT = 2048
  const SCALE = 0.5

  if (options.width > LIMIT || options.height > LIMIT) {
    return optimizeSize({
      width: Math.round(options.width * SCALE),
      height: Math.round(options.height * SCALE)
    })
  } else {
    return options
  }
}
