import i18n from '@/client/utils/i18n'

const MB = 2 ** 20

const createImageHelp = () =>
  '<a href="https://help-cms.comnico.jp/post-image" target="_blank" style="color:#f5f859">' +
  i18n.t('ヘルプ: 対応する画像形式') +
  '<i class="icon ci-open-in-new" style="margin-left:8px" />' +
  '</a>'

const createVideoHelp = () =>
  '<a href="https://help-cms.comnico.jp/post-video" target="_blank" style="color:#f5f859">' +
  i18n.t('ヘルプ: 対応する動画形式') +
  '<i class="icon ci-open-in-new" style="margin-left:8px" />' +
  '</a>'

export function imageResolutionMax(size: number) {
  return {
    error: {
      title: i18n.t('画像の横幅もしくは縦幅が大きすぎます。'),
      message: i18n.t('画像の横幅と縦幅を[[size]]px以下にリサイズしてください。', { size })
    }
  }
}

export function imageResolutionMin(size: number) {
  return {
    error: {
      title: i18n.t('画像の横幅もしくは縦幅が小さすぎます。'),
      message: i18n.t('画像の横幅と縦幅を[[size]]px以上にリサイズしてください。', { size })
    }
  }
}

export function imageNotSupport() {
  return {
    error: {
      title: i18n.t('画像の形式に問題があります。'),
      message: createImageHelp()
    }
  }
}

export function imageExtension() {
  return {
    error: {
      title: i18n.t('画像の拡張子が対応していません。'),
      message: createImageHelp()
    }
  }
}

export function imageSize(size: number) {
  return {
    error: {
      title: i18n.t('画像のファイルサイズ([[size]]MB)を調整してください。', {
        size: Math.floor((size / MB) * 100) / 100
      }),
      message: createImageHelp()
    }
  }
}

export function imageResolution() {
  return {
    error: {
      title: i18n.t('画像の解像度を調整してください。'),
      message: createImageHelp()
    }
  }
}

export function imageAspectRatio() {
  return {
    error: {
      title: i18n.t('画像のアスペクト比を調整してください。'),
      message: createImageHelp()
    }
  }
}

export function animationGifTwitterSize(size: number) {
  return {
    error: {
      title: i18n.t('アニメーションGIFのファイルサイズ([[size]]MB)を調整してください。', {
        size: Math.floor((size / MB) * 100) / 100
      }),
      message: createImageHelp()
    }
  }
}

export function animationGifTwitterResolution() {
  return {
    error: {
      title: i18n.t('アニメーションGIFの解像度は2048px以下にしてください。'),
      message: createImageHelp()
    }
  }
}

export function animationGifInstagram() {
  return {
    error: {
      title: i18n.t('アニメーションGIFはInstagramで利用できません。'),
      message: createImageHelp()
    }
  }
}

export function animationGifMulti() {
  return {
    error: {
      title: i18n.t('アニメーションGIFは複数枚の画像投稿で利用できません。'),
      message: createImageHelp()
    }
  }
}

export function videoNotSupport() {
  return {
    error: {
      title: i18n.t('動画の形式に問題があります。'),
      message: createVideoHelp()
    }
  }
}

export function videoExtension() {
  return {
    error: {
      title: i18n.t('動画の拡張子が対応していません。'),
      message: createVideoHelp()
    }
  }
}

export function videoSize(size: number) {
  return {
    error: {
      title: i18n.t('動画のファイルサイズ([[size]]MB)を調整してください。', {
        size: Math.floor((size / MB) * 100) / 100
      }),
      message: createVideoHelp()
    }
  }
}

export function videoDuration() {
  return {
    error: {
      title: i18n.t('動画の再生時間を調整してください。'),
      message: createVideoHelp()
    }
  }
}

export function videoSpecForInstagramReels() {
  return {
    error: {
      title: i18n.t(
        '動画が1分以上またはアスペクト比が9:16の場合、他のメディアと同時に投稿できません。'
      ),
      message: createVideoHelp()
    }
  }
}

export function videoResolution() {
  return {
    error: {
      title: i18n.t('動画の解像度を調整してください。'),
      message: createVideoHelp()
    }
  }
}

export function videoAspectRatio() {
  return {
    error: {
      title: i18n.t('動画のアスペクト比を調整してください。'),
      message: createVideoHelp()
    }
  }
}

export function videoFrameRate() {
  return {
    error: {
      title: i18n.t('動画のフレームレートを調整してください。'),
      message: createVideoHelp()
    }
  }
}

export function videoBitRate() {
  return {
    error: {
      title: i18n.t('動画のビットレートを調整してください。'),
      message: createVideoHelp()
    }
  }
}

export function videoAudioChannels() {
  return {
    error: {
      title: i18n.t('動画の音声をモノラルかステレオにしてください。'),
      message: createVideoHelp()
    }
  }
}

export function videoAudioFormat() {
  return {
    error: {
      title: i18n.t('動画の音声コーデックをAAC LCにしてください。'),
      message: createVideoHelp()
    }
  }
}
