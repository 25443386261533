import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/facebook_analytics'
import * as calculation from '@/client/components-old/_utils/calculation'
import * as facebook_insight from '@/client/components-old/_utils/facebook_insight'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsNumbers from '@/client/components-old/molecules/AnalyticsNumbers'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartPercent from '@/client/components-old/molecules/ChartPercent'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as IFacebookState } from '@/client/store/modules/facebook_analytics'
import i18n from '@/client/utils/i18n'

type TFacebookAnalyticsGoalOptions = {
  goal: number
  data_type: 'daily_data' | 'post_data'
  unit: 'total' | 'average'
  metric: string
  post_types?: string[]
  post_tags?: number[]
}

const facebook = namespace('facebook_analytics')
const categories = namespace('categories')

@Component({
  name: 'FacebookAnalyticsGoal',
  components: {
    Icon,
    Tooltip,
    AnalyticsPanel,
    AnalyticsPanelHeader,
    AnalyticsNumbers,
    ChartPercent
  }
})
export default class FacebookAnalyticsGoal extends Vue {
  @categories.State('api_post_categories')
  api_post_categories: ICategoriesState['api_post_categories']
  @categories.State('api_category_posts') api_category_posts: ICategoriesState['api_category_posts']
  @facebook.State('api_accounts') daily_data: IFacebookState['api_accounts']
  @facebook.State('api_posts') post_data: IFacebookState['api_posts']

  @Prop({ type: Object, required: true })
  options: TFacebookAnalyticsGoalOptions

  @Prop({ type: Boolean, default: false })
  isPreview: boolean

  @Prop({ type: Boolean, default: false })
  isEdit: boolean

  @Emit('edit')
  onEdit() {
    return this.options
  }

  @Emit('delete')
  onDelete(event) {
    return event
  }

  getPostTagNames(post_tag_ids?: number[]): string[] {
    if (post_tag_ids === undefined || !post_tag_ids.length) return []

    return (
      this.api_post_categories.filter(tag => post_tag_ids.includes(tag.id)).map(v => v.name) || []
    )
  }

  getSnsPostTags(post_id: string): number[] {
    const post_tag = this.api_category_posts.find(post_tag => post_tag.post_id === post_id)
    return post_tag ? post_tag.category_ids : []
  }

  get analytics(): any[] {
    const { data_type, post_types, post_tags } = this.options

    switch (data_type) {
      case 'daily_data':
        return facebook_insight.filterDailyData(
          this.daily_data,
          this.post_data,
          this.options.metric
        )
      case 'post_data': {
        let temp_insights = facebook_insight.filterPostData(this.post_data, this.options.metric)

        if (post_types?.length) {
          temp_insights = temp_insights.filter(post => post_types.includes(post.type))
        }
        if (post_tags?.length) {
          temp_insights = temp_insights.filter(post => {
            const post_tag_ids = this.getSnsPostTags(post.post_id)
            return post_tag_ids.some(post_tag_id => post_tags.includes(post_tag_id))
          })
        }
        return temp_insights
      }
      default:
        return []
    }
  }

  get is_data() {
    return this.analytics.length > 0
  }

  get is_edit() {
    return !this.isPreview && this.isEdit
  }

  get show_post_types() {
    if (this.options.data_type !== 'post_data') return []

    return ['status', 'photo', 'link', 'video', 'shared'].filter(v =>
      this.options.post_types.includes(v)
    )
  }

  get post_tags_names() {
    if (this.options.data_type !== 'post_data') return []

    return this.getPostTagNames(this.options.post_tags)
  }

  get panel_type() {
    const data_type = constants.DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get metrics() {
    switch (this.options.data_type) {
      case 'daily_data':
        return constants.GOAL_DAILY_DATA_METRICS
      case 'post_data':
        return constants.GOAL_POST_DATA_METRICS
      default:
        return []
    }
  }

  get metric_name() {
    const metric = this.metrics.find(options => options.value === this.options.metric)
    const metric_name = metric ? i18n.t(metric.text) : ''

    const unit = constants.UNITS.find(unit => unit.value === this.options.unit)
    const unit_name = unit ? i18n.t(unit.text) : ''
    const unit_value = unit ? unit.value : ''

    if (
      unit_name === '' ||
      unit_value === '' ||
      this.options.metric === 'page_fans' ||
      this.options.metric === 'post_video_avg_time_watched'
    ) {
      return metric_name
    }

    return `${metric_name} [${unit_name}]`
  }

  get result() {
    if (this.options.unit === 'total') {
      return facebook_insight.convertValueWithMetric(this.total, this.options.metric)
    }

    if (this.options.unit === 'average') {
      return facebook_insight.convertValueWithMetric(this.average, this.options.metric)
    }
  }

  get extras() {
    let viewText = ''
    switch (this.options.metric) {
      case 'reactions_rate':
      case 'post_engaged_users_rate':
      case 'post_consumptions_unique_rate':
      case 'post_impressions_fan_unique_rate':
      case 'post_engaged_fan_rate':
        viewText = this.$options.filters.percent(this.options.goal)
        break
      case 'page_video_view_time':
      case 'post_video_avg_time_watched':
      case 'post_video_view_time_by_age_bucket_and_gender':
        viewText = this.$options.filters.second(this.options.goal)
        break
      case 'page_fan_up_down':
        viewText = this.$options.filters.updown(this.options.goal)
        break
      default:
        viewText = this.$options.filters.number(this.options.goal)
    }
    return [{ title: '目標', value: viewText }]
  }

  get goal_percent() {
    let denominator = this.options.goal
    let numerator = 0

    if (this.options.unit === 'total') numerator = this.total

    if (this.options.unit === 'average') numerator = this.average

    if (
      this.options.metric === 'page_video_view_time' ||
      this.options.metric === 'post_video_avg_time_watched' ||
      this.options.metric === 'post_video_view_time_by_age_bucket_and_gender'
    ) {
      denominator = calculation.secontToMillisecond(this.options.goal)
    }

    return calculation.percentage(numerator, denominator, 1)
  }

  get total() {
    return facebook_insight.getMetricTotal(this.analytics, this.options.metric)
  }

  get average() {
    return facebook_insight.getMetricAverage(this.analytics, this.options.metric)
  }

  get metric_tooltip() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? i18n.t(metric.tooltip) : ''
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }
}
