export const htmlEscape = (message: string) => {
  const entities = {
    '>': '&gt;',
    '<': '&lt;',
    '"': '&quot;',
    "'": '&#39;'
  }

  return message.replace(/["'><]/g, character => entities[character])
}
