import { render, staticRenderFns } from "./TwitterComparisonAccountDialog.vue?vue&type=template&id=327f3c48&scoped=true"
import script from "./TwitterComparisonAccountDialog.ts?vue&type=script&lang=ts&external"
export * from "./TwitterComparisonAccountDialog.ts?vue&type=script&lang=ts&external"
import style0 from "./TwitterComparisonAccountDialog.vue?vue&type=style&index=0&id=327f3c48&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327f3c48",
  null
  
)

export default component.exports