import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import * as twitter_trends from '@/client/components-old/_utils/twitter_trends'
import Icon from '@/client/components-old/atoms/Icon'
import { TrackingService } from '@/client/services'
import { ITwitterTrendCalendar } from '@/client/utils/api/twitter_trends'

@Component({
  name: 'CalendarTrendItem',
  components: {
    Icon
  }
})
export default class CalendarTrendItem extends Vue {
  @Prop({ type: Object, required: true })
  trend: ITwitterTrendCalendar

  @Prop({ type: Boolean, default: false })
  isDatetime: boolean

  get title() {
    return this.isDatetime ? moment(this.trend.datetime).format('ll') : null
  }

  get styles() {
    return {
      'is-highlight': this.trend.count >= 10
    }
  }

  get href() {
    return twitter_trends.createTrendUrl(this.trend.word)
  }

  sendClickTrendEvent() {
    TrackingService.sendEvent('click:投稿管理|月|トレンド')
  }

  searchTrendOnTwitter() {
    TrackingService.sendEvent('click:投稿管理|月|トレンド>検索')

    window.open(this.href, '_blank')
  }

  @Emit('copy-message')
  copyMessage() {
    TrackingService.sendEvent('click:投稿管理|月|トレンド>コピー')

    return this.trend.word
  }

  @Emit('create-post')
  createPost() {
    TrackingService.sendEvent('click:投稿管理|月|トレンド>投稿作成')

    return this.trend.word
  }
}
