import { RouteConfig } from 'vue-router'

import Login from '../pages/Login.vue'

export const login: RouteConfig = {
  component: Login,
  path: '/login',
  name: 'login',
  meta: {
    auth: false,
    title: 'ログイン'
  }
}
