/**
 * 数値変換
 */
export function toNumber(value: any): number | null {
  if (value == null) return null

  return !isNaN(value) ? Number(value) : 0
}

/**
 * 足し算
 */
export function addition(numbers: number[] = []): number {
  return numbers.map(v => toNumber(v)).reduce((a, c) => a + c, 0)
}

/**
 * 割り算
 */
export function division(numerator = 0, denominator = 0, decimal_point = 2): number {
  const _numerator = toNumber(numerator)
  const _denominator = toNumber(denominator)
  if (_numerator === 0 || _denominator === 0) return 0
  const value = _numerator / _denominator

  return round(value, decimal_point)
}

/**
 * 引数で受け取った小数点の桁数に合わせて四捨五入する
 */
export function round(value: number, decimal_point = 2) {
  const scale = 10 ** decimal_point

  return Math.round(value * scale) / scale
}

/**
 * 百分率を計算する
 */
export function percentage(
  numerator: number | null = 0,
  denominator: number | null = 0,
  decimal_point = 2
): number | null {
  const _numerator = toNumber(numerator)
  const _denominator = toNumber(denominator)
  if (_numerator === null || _denominator === null) return null
  if (_numerator === 0 || _denominator === 0) return 0
  const value = (_numerator / _denominator) * 100
  const scale = 10 ** decimal_point
  return Math.round(value * scale) / scale
}

/**
 * ミリ秒を秒に変換する
 */
export function millisecondToSecond(millisecond: number): number {
  return Math.round((millisecond / 1000) * 10) / 10
}

/**
 * 秒をミリ秒に変換する
 */
export function secontToMillisecond(second: number): number {
  return Math.round(second * 1000 * 10) / 10
}

/**
 * 秒を HH:mm:ss 表記に変換する
 */
export function secondToTime(second: number): string {
  const time_hour = Math.floor(second / 3600)

  const temp_second = second - time_hour * 3600

  const time_minute = Math.floor(temp_second / 60)

  const time_second = Math.floor(temp_second - time_minute * 60)

  const format = (v: number) => (String(v).length === 1 ? '0' + String(v) : String(v))

  return format(time_hour) + ':' + format(time_minute) + ':' + format(time_second)
}
