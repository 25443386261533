import moment from 'moment-timezone'

import * as calculation from '@/client/components-old/_utils/calculation'
import {
  getNumberFluctuation,
  getNumberLocale,
  getNumberPercent,
  getNumberSecond
} from '@/client/utils/filters'

import { TikTokInsightsAccounts, TikTokInsightsPosts } from '../api'
import { getAggregateAudienceCountriesByPost } from './getAggregateAudienceCountriesByPost'

/**
 * 分析データから値を取得
 */
export function getMetricValue(
  insight: TikTokInsightsPosts | TikTokInsightsAccounts,
  metric: string
): any {
  return insight[metric] ?? null
}

/**
 * 分析データから値の配列を取得
 */
export function getMetricArray<R>(
  insights: TikTokInsightsPosts[] | TikTokInsightsAccounts[],
  metric: string
): R[] {
  return insights.map(insight => getMetricValue(insight, metric))
}

/**
 * 分析データから値の合計を取得
 */
export function getMetricTotal(
  insights: TikTokInsightsPosts[] | TikTokInsightsAccounts[],
  metric: keyof TikTokInsightsPosts | keyof TikTokInsightsAccounts
): number | null {
  switch (metric) {
    // ? 率の指標は合計が存在しない
    case 'reactions_rate':
    case 'engagements_rate':
    case 'full_video_watched_rate':
      return null

    // ? 累計数の場合、最終日の値を返す
    case 'followers_count': {
      const metric_array = getMetricArray<number>(insights, metric)
      return metric_array.length > 0 ? metric_array[0] : 0
    }

    // ? 小数点付きの数値を足し算しているため、小数点第一位で四捨五入する
    case 'video_duration':
    case 'total_time_watched':
    case 'average_time_watched':
      return calculation.round(calculation.addition(getMetricArray(insights, metric)), 1)

    default:
      return calculation.addition(getMetricArray(insights, metric))
  }
}

/**
 * 分析データからサブ指標のパーセントの配列を取得
 */
export function getSubMetricPercentageArray(
  insights: TikTokInsightsPosts[] | TikTokInsightsAccounts[],
  metric: keyof TikTokInsightsPosts | keyof TikTokInsightsAccounts,
  sub_metric: string
): (number | null)[] {
  let sub_metric_key = ''

  switch (metric) {
    case 'audience_genders': {
      sub_metric_key = 'gender'
      break
    }
    case 'impression_sources': {
      sub_metric_key = 'impression_source'
      break
    }
    case 'audience_countries': {
      sub_metric_key = 'country'
      break
    }
  }

  return insights.map(insight => {
    const metric_value: { [key: string]: string | number; percentage: number; count: number }[] =
      insight[metric] ?? null

    if (!metric_value || !metric_value.length) return null

    const data = metric_value.find(v => v[sub_metric_key] === sub_metric)

    return data?.percentage ?? 0
  })
}

/**
 * 分析データからサブ指標のパーセントを取得し、100分率で返す
 */
export function getSubMetricPercentage(
  insights: TikTokInsightsPosts[] | TikTokInsightsAccounts[],
  metric: keyof TikTokInsightsPosts | keyof TikTokInsightsAccounts,
  sub_metric: string
) {
  const sub_metric_array = getSubMetricPercentageArray(insights, metric, sub_metric)

  switch (metric) {
    case 'audience_genders': {
      // ? 取得できた時点での最新値を取得する
      const [gender] = sub_metric_array.filter(v => v !== null)

      const percentage = gender * 100

      return gender ? calculation.round(percentage) : null
    }

    case 'impression_sources':
    case 'audience_countries': {
      // ? 取得できた配列の1つあたりの平均値を取得する
      const percentage = calculation.addition(sub_metric_array) / insights.length

      return calculation.round(percentage * 100)
    }
    default:
      return null
  }
}

/**
 * 分析データから値の平均を取得
 */
export function getMetricAverage(
  insights: TikTokInsightsPosts[] | TikTokInsightsAccounts[],
  metric: keyof TikTokInsightsPosts | keyof TikTokInsightsAccounts
): number | null {
  switch (metric) {
    case 'reactions_rate': {
      const numerator = getMetricTotal(insights, 'reactions')
      const denominator = getMetricTotal(insights, 'followers_at_that_time')

      return calculation.percentage(numerator, denominator)
    }

    case 'engagements_rate': {
      const numerator = getMetricTotal(insights, 'engagements')
      const denominator = getMetricTotal(insights, 'video_views')

      return calculation.percentage(numerator, denominator)
    }

    // ? 親となるデータがないので率を足し上げて割る
    case 'full_video_watched_rate': {
      const numerator = calculation.addition(getMetricArray(insights, metric))
      const denominator = insights.length

      // ? 率系指標のため
      return calculation.division(numerator, denominator, 2)
    }

    case 'followers_count': {
      const numerator = calculation.addition(getMetricArray(insights, metric))
      const denominator = insights.length

      return calculation.division(numerator, denominator, 1)
    }

    default: {
      const numerator = getMetricTotal(insights, metric)
      const denominator = insights.length

      return calculation.division(numerator, denominator, 1)
    }
  }
}

/**
 * ページ分析をキーによってフィルタリング
 */
export function filterDailyData(
  daily_data: TikTokInsightsAccounts[],
  post_data: TikTokInsightsPosts[],
  key: string
): TikTokInsightsAccounts[] {
  switch (key) {
    case 'post_count':
      return daily_data.map(account => {
        const post_count = post_data.filter(post =>
          moment(post.created_time).isSame(moment(account.created_time), 'day')
        ).length

        return { ...account, post_count }
      })

    default:
      return [...daily_data]
  }
}

/**
 * 指標によって値の表示方法を変換する
 */
export function convertValueWithMetric(value: number | null, metric: string): string {
  if (metric.endsWith('_rate')) return getNumberPercent(value ?? 0)

  if (metric.endsWith('_up_down')) return getNumberFluctuation(value ?? 0)

  if (metric.endsWith('_duration') || metric.endsWith('_watched')) {
    // ? 率ではない指標で小数点があるため小数点1桁で丸める
    return getNumberSecond(calculation.round(value ?? 0, 1))
  }

  return getNumberLocale(value ?? 0)
}

/**
 * 指標と期間で関連データを取得
 */
export function getDataByMetric(
  analytics: (TikTokInsightsPosts | TikTokInsightsAccounts)[],
  metric: string,
  data_type: string,
  timezone: string,
  time?: { start: number; end: number }
): Record<number | string, number> | (TikTokInsightsPosts | TikTokInsightsAccounts)[] | null {
  const data: Record<number | string, number> = {}

  switch (metric) {
    case 'audience_genders': {
      const latest_analytic_data = analytics.find(analytic => analytic[metric].length > 0)

      if (!latest_analytic_data?.[metric]) return null

      for (const item of latest_analytic_data[metric]) {
        data[item.gender] = item.count
      }

      return data
    }

    case 'audience_countries': {
      if (data_type === 'daily_data') {
        const latest_analytic_data = analytics.find(analytic => analytic[metric].length > 0)

        if (!latest_analytic_data?.[metric]) return null

        for (const item of latest_analytic_data[metric]) {
          data[item.country] = item.count
        }

        return data
      }

      if (data_type === 'post_data') {
        const audience_countries = getAggregateAudienceCountriesByPost(
          analytics as TikTokInsightsPosts[]
        )

        return Object.fromEntries(audience_countries.map(v => [v.country, v.count]))
      }

      break
    }

    case 'audience_activity': {
      const total_hours: Record<number, number> = {}

      const activities = analytics
        .filter(account => account[metric].length > 0)
        .map(account => account[metric])
        .slice(0, 7)

      if (!activities?.length) return null

      for (const activity of activities) {
        for (const item of activity) {
          const hour = Number(item.hour)

          total_hours[hour] = total_hours[hour] ? total_hours[hour] + item.count : item.count
        }
      }

      // ユーザーが設定したタイムゾーン
      const user_timezone = moment.tz(timezone).format('YYYY-MM-DD HH:mm')
      // TikTok APIのタイムゾーン
      const api_timezone = moment.tz('UTC').format('YYYY-MM-DD HH:mm')

      // ユーザーが設定したタイムゾーンとUTCの時差
      const time_offset = moment(api_timezone).diff(moment(user_timezone), 'hours')

      let key_time = 0

      // タイムゾーンに合わせて24時間のデータの位置を調整する
      for (const key of Object.keys(total_hours)) {
        key_time = Number(key) + time_offset

        if (key_time < 0) {
          key_time += 24
        } else if (key_time >= 24) {
          key_time -= 24
        }

        if (total_hours[key_time]) {
          data[key] = total_hours[key_time]
        }
      }

      return data
    }

    default:
      if (time) {
        return analytics.filter(
          post =>
            moment(post.created_time).unix() >= time.start &&
            moment(post.created_time).unix() <= time.end
        )
      }

      return analytics
  }

  return null
}

export function getUnitField(metric: keyof TikTokInsightsPosts | keyof TikTokInsightsAccounts) {
  switch (metric) {
    case 'audience_genders':
    case 'impression_sources':
      return '割合'
    default:
      return '回数'
  }
}
