import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/facebook_analytics'
import * as facebook_insight from '@/client/components-old/_utils/facebook_insight'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartDonutHalf from '@/client/components-old/molecules/ChartDonutHalf'
import { TrackingService } from '@/client/services'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as IFacebookState } from '@/client/store/modules/facebook_analytics'
import csv from '@/client/utils/csv'
import { getTranslateText as t } from '@/client/utils/filters'

type TDailyData = {
  data_type: 'daily_data'
  metric: 'page_positive_feedback_by_type_unique' | 'page_negative_feedback_by_type_unique'
}

type TPostData = {
  data_type: 'post_data'
  metric: 'post_consumptions_by_type_unique' | 'likes_insights'
}

type TFacebookAnalyticsChartDonutHalfOptions = TDailyData | TPostData

const analytics = namespace('analytics')
const facebook = namespace('facebook_analytics')

@Component({
  name: 'FacebookAnalyticsChartDonutHalf',
  components: {
    ChartDonutHalf,
    AnalyticsPanel,
    AnalyticsPanelHeader,
    Icon,
    Tooltip
  }
})
export default class FacebookAnalyticsChartDonutHalf extends Vue {
  @facebook.State('api_accounts') daily_data: IFacebookState['api_accounts']
  @facebook.State('api_posts') post_data: IFacebookState['api_posts']
  @facebook.State('screen_name') screen_name: IFacebookState['screen_name']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TFacebookAnalyticsChartDonutHalfOptions

  get is_data() {
    switch (this.options.data_type) {
      case 'daily_data':
        return this.daily_data.length > 0
      case 'post_data':
        return this.post_data.length > 0
      default:
        return false
    }
  }

  get metrics() {
    switch (this.options.data_type) {
      case 'daily_data':
        return constants.HALF_DOUGHNUT_CHART_DAILY_DATA_METRICS
      case 'post_data':
        return constants.HALF_DOUGHNUT_CHART_POST_DATA_METRICS
      default:
        return []
    }
  }

  get metric_name() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? t(metric.text) : ''
  }

  get metric_tooltip() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? metric.tooltip : ''
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }

  get panel_type() {
    const data_type = constants.DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get chart_options() {
    const chart_color1 = '#1c84c6'
    const chart_color2 = '#ed5565'
    const chart_color3 = '#2ec881'
    const chart_color4 = '#24cbe5'
    const chart_color5 = '#f8ac59'
    const chart_color6 = '#5e5e5e'

    switch (this.options.metric) {
      case 'page_positive_feedback_by_type_unique':
        return {
          title: t('反応数'),
          colors: [chart_color1, chart_color2, chart_color3],
          data: [
            [
              t('いいね！'),
              facebook_insight.getMetricTotal(this.daily_data, `${this.options.metric}_like`)
            ],
            [
              t('コメント'),
              facebook_insight.getMetricTotal(this.daily_data, `${this.options.metric}_comment`)
            ],
            [
              t('シェア'),
              facebook_insight.getMetricTotal(this.daily_data, `${this.options.metric}_share`)
            ]
          ]
        }

      case 'page_negative_feedback_by_type_unique':
        return {
          title: t('否定的な反応'),
          colors: [chart_color1, chart_color2, chart_color3, chart_color4],
          data: [
            [
              t('投稿を非表示'),
              facebook_insight.getMetricTotal(this.daily_data, `${this.options.metric}.hide_clicks`)
            ],
            [
              t('全投稿を非表示'),
              facebook_insight.getMetricTotal(
                this.daily_data,
                `${this.options.metric}.hide_all_clicks`
              )
            ],
            [
              t('いいね！取消'),
              facebook_insight.getMetricTotal(
                this.daily_data,
                `${this.options.metric}.unlike_page_clicks`
              )
            ],
            [
              t('スパムとして報告'),
              facebook_insight.getMetricTotal(
                this.daily_data,
                `${this.options.metric}.report_spam_clicks`
              )
            ]
          ].sort((a: any, b: any) => b[1] - a[1])
        }

      case 'post_consumptions_by_type_unique':
        return {
          title: t('クリック人数'),
          colors: [chart_color1, chart_color2, chart_color3, chart_color4],
          data: [
            [
              t('写真'),
              facebook_insight.getMetricTotal(this.post_data, 'post_consumptions_unique_photo_view')
            ],
            [
              t('リンク'),
              facebook_insight.getMetricTotal(
                this.post_data,
                'post_consumptions_unique_link_clicks'
              )
            ],
            [
              t('動画'),
              facebook_insight.getMetricTotal(this.post_data, 'post_consumptions_unique_video_play')
            ],
            [
              t('その他'),
              facebook_insight.getMetricTotal(
                this.post_data,
                'post_consumptions_unique_other_clicks'
              )
            ]
          ].sort((a: any, b: any) => b[1] - a[1])
        }

      case 'likes_insights':
        return {
          title: t('いいね！数'),
          colors: [
            chart_color1,
            chart_color2,
            chart_color3,
            chart_color4,
            chart_color5,
            chart_color6
          ],
          data: [
            [
              t('いいね！'),
              facebook_insight.getMetricTotal(this.post_data, `${this.options.metric}_like`)
            ],
            [
              t('超いいね！'),
              facebook_insight.getMetricTotal(this.post_data, `${this.options.metric}_love`)
            ],
            [
              t('うけるね'),
              facebook_insight.getMetricTotal(this.post_data, `${this.options.metric}_haha`)
            ],
            [
              t('すごいね'),
              facebook_insight.getMetricTotal(this.post_data, `${this.options.metric}_wow`)
            ],
            [
              t('悲しいね'),
              facebook_insight.getMetricTotal(this.post_data, `${this.options.metric}_sorry`)
            ],
            [
              t('ひどいね'),
              facebook_insight.getMetricTotal(this.post_data, `${this.options.metric}_anger`)
            ]
          ].sort((a: any, b: any) => b[1] - a[1])
        }

      default:
        return {
          title: '',
          colors: [],
          data: []
        }
    }
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    TrackingService.sendEvent(
      `click:自社分析(FB)> ${this.screen_name} | ${this.options.metric} : CSVダウンロード`
    )

    const fields = [t('種別'), t('回数')]

    const data = this.chart_options.data

    const csv_data = { fields, data }

    const component_name = t('グラフ')
    const metric_name = t(this.metric_name)

    const csv_filename = [component_name, metric_name, this.start_date, this.end_date].join('_')

    csv.download(csv_data, csv_filename)
  }
}
