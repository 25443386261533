
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Select'
})
export default class Select extends Vue {
  @Prop({ type: [String, Number], required: true })
  value!: string | number

  @Prop({ type: Array, required: true })
  options!: {
    text: string
    value: string | number
  }[]

  @Prop({ type: String, default: null })
  placeholder!: string

  @Prop({ type: Boolean, default: false })
  filterable!: boolean

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: String, default: 'normal' })
  size!: 'normal' | 'full'

  @Prop({ type: Boolean, default: true })
  defaultFirstOption!: boolean

  @Prop({ type: Boolean, default: true })
  popperAppendToBody!: boolean

  @Prop({ type: String, default: '' })
  emptyText!: ''

  @Prop({ type: String, default: 'default' })
  color!: 'default' | 'skeleton'

  get selected() {
    return this.value
  }

  set selected(val) {
    this.$emit('input', val)
  }

  get clearable() {
    return this.placeholder && this.placeholder !== ''
  }

  get class_names() {
    return [`size-${this.size}`]
  }
}
