import delay from 'delay'
import moment from 'moment-timezone'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import * as post_util from '@/client/components-old/_utils/post'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  TGetIncidentEventsParams,
  TGetIncidentEventsResponse,
  TIncidentEvent
} from '@/client/utils/api/incident_events'
import { IPostCategories } from '@/client/utils/api/post_categories'
import {
  IGetPostHistoriesParams,
  IGetPostHistoriesResponse,
  IPostHistory
} from '@/client/utils/api/post_histories'
import {
  TGetPostKeywordsParams,
  TGetPostKeywordsResponse,
  TPostKeyword
} from '@/client/utils/api/post_keywords'
import {
  IDeletePostMemoResponses,
  IGetPostMemosParams,
  IGetPostMemosResponses,
  IPostMemo,
  IPostMemosParams,
  IPostMemosResponses,
  IPutMemoParams,
  IPutMemoResponses
} from '@/client/utils/api/post_memos'
import { TGetPostSettingsResponse, TPostSetting } from '@/client/utils/api/post_settings'
import {
  IGetPostResponse,
  IGetPostsParams,
  IGetPostsResponse,
  IPost,
  IPostApproveCancelResponse,
  IPostInstagramPublishParams,
  IPostInstagramPublishResponse,
  IPostPostApprovalParams,
  IPostPostApprovalResponse,
  IPostScheduledCancelResponse,
  IPutInstagramUrlParams,
  IPutInstagramUrlResponse
} from '@/client/utils/api/posts'
import {
  IGetTwitterTrendCalendarParams,
  IGetTwitterTrendCalendarResponse,
  ITwitterTrendCalendar
} from '@/client/utils/api/twitter_trends'
import {
  GetUsersRolesParams,
  GetUsersRolesResponse,
  User,
  UsersRoles
} from '@/client/utils/api/users'
import storage from '@/client/utils/storage'
import { SnsType } from '@/common/types'

type TCount = {
  all: number
  draft: number
  scheduled: number
  published: number
  approval: number
  reject: number
  failure: number
}
type TOrder = 'publish_asc' | 'publish_desc' | 'modified_asc' | 'modified_desc'
type TDisplayMode = 'list' | 'week' | 'month'
type TFilterStatus = 'all' | 'draft' | 'scheduled' | 'published' | 'approval' | 'reject' | 'failure'
type TFilterAccount = {
  id: string
  sns: SnsType
}
type TDialogPostMode = 'create' | 'preview' | 'approval' | 'release_procedure'
type TDialogPostTab = 'preview' | 'memo' | 'history'
type TDialogPostError = '' | 'NOT_EXISTS' | 'PERMISSION_DENIED'
type TMemoCache = {
  sns_post_id: number
  memo_type: 'memo' | 'approval' | 'approval_request'
  message: string
}
type TPostIdStatus = {
  sns_post_id: number
  status: string
}

export interface IState {
  api_count: TCount
  api_posts: IPost[]
  api_post: IPost | null
  api_post_memos: IPostMemo[]
  api_post_histories: IPostHistory[]
  api_post_keywords: TPostKeyword[]
  api_post_setting: TPostSetting
  api_persons: UsersRoles[]
  api_twitter_trends: ITwitterTrendCalendar[]
  api_incident_events: TIncidentEvent[]
  start_date: string
  end_date: string
  offset: number
  order: TOrder
  display_mode: TDisplayMode
  filter_status: TFilterStatus
  filter_account: '' | TFilterAccount
  filter_categories: number[]
  filter_persons: number[]
  filter_keyword: string
  is_loading: boolean
  is_loading_more: boolean
  is_csv_loading: boolean
  is_publishing: boolean
  is_get_post_failed: boolean
  is_twitter_trend: boolean
  dialog_post_id: number | null
  dialog_post_mode: TDialogPostMode
  dialog_post_tab: TDialogPostTab
  dialog_post_error: TDialogPostError
  is_dialog_visible: boolean
  is_dialog_loading: boolean
  memo_cache: TMemoCache
}

const init_api_count: TCount = {
  all: 0,
  draft: 0,
  scheduled: 0,
  published: 0,
  approval: 0,
  reject: 0,
  failure: 0
}

const init_api_post_setting: TPostSetting = {
  check_2byte1: false,
  check_2byte2: false,
  check_kana: false,
  check_punctuation: false,
  check_space: false,
  instagram_comment_enabled: true,
  tiktok_is_duet: true,
  tiktok_is_comment: true,
  tiktok_is_stitch: true,
  tiktok_is_brand_organic: false,
  tiktok_is_branded_content: false
}

const init_memo_cache: TMemoCache = {
  sns_post_id: 0,
  memo_type: 'memo',
  message: ''
}

function getInitDateRange(display_mode: TDisplayMode): [string, string] {
  switch (display_mode) {
    case 'week':
      return [
        moment().startOf('isoWeek').format('YYYY-MM-DD'),
        moment().endOf('isoWeek').format('YYYY-MM-DD')
      ]

    case 'month':
      return [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD')
      ]

    default:
      return [
        moment().subtract(30, 'day').format('YYYY-MM-DD'),
        moment().add(60, 'day').format('YYYY-MM-DD')
      ]
  }
}

const [init_start_date, init_end_date] = getInitDateRange('list')

const state: IState = {
  api_count: init_api_count,
  api_posts: [],
  api_post: null,
  api_post_memos: [],
  api_post_histories: [],
  api_post_keywords: [],
  api_post_setting: init_api_post_setting,
  api_persons: [],
  api_twitter_trends: [],
  api_incident_events: [],
  start_date: init_start_date,
  end_date: init_end_date,
  offset: 0,
  order: 'publish_desc',
  display_mode: 'list',
  filter_status: 'all',
  filter_account: '',
  filter_categories: [],
  filter_persons: [],
  filter_keyword: '',
  is_loading: false,
  is_loading_more: false,
  is_csv_loading: false,
  is_publishing: false,
  is_get_post_failed: false,
  is_twitter_trend: false,
  dialog_post_id: null,
  dialog_post_mode: 'preview',
  dialog_post_tab: 'preview',
  dialog_post_error: '',
  is_dialog_visible: false,
  is_dialog_loading: false,
  memo_cache: init_memo_cache
}

export interface IGetter {
  is_empty_posts: boolean
  is_read_more: boolean
  post: IPost | null
  dialog_memo_users: User[]
  is_instagram_manual_publish: boolean
  is_dialog_approval: boolean
  is_dialog_release_procedure: boolean
}

const getters: GetterTree<IState, IRootState> = {
  is_empty_posts(state): IGetter['is_empty_posts'] {
    return !state.is_loading && !state.api_posts.length
  },
  is_read_more(state): IGetter['is_read_more'] {
    return !state.is_loading && state.api_count[state.filter_status] !== state.api_posts.length
  },
  post(state, rootGetter, rootState): IGetter['post'] {
    if (state.dialog_post_mode !== 'create') return state.api_post

    const post_create = rootState.post_create

    const fb_accounts = post_create?.accounts.filter(account => account.sns === 'facebook') ?? []
    const tw_accounts = post_create?.accounts.filter(account => account.sns === 'twitter') ?? []
    const in_accounts = post_create?.accounts.filter(account => account.sns === 'instagram') ?? []
    const tt_accounts = post_create?.accounts.filter(account => account.sns === 'tiktok') ?? []

    const twitter_reply = post_create?.api_post?.tw_posts?.[0]?.twitter_reply

    const twitter_reply_error_type = post_create?.api_post?.tw_posts?.[0]?.twitter_reply_error_type

    const twitter_quote = post_create?.api_post?.tw_posts?.[0]?.twitter_quote

    const twitter_quote_error_type = post_create?.api_post?.tw_posts?.[0]?.twitter_quote_error_type

    return {
      id: 0,
      action_datetime: post_create?.scheduled_datetime,
      status: post_create?.api_post ? post_create?.api_post.status : 'draft',
      type: post_create?.type,
      message: post_create?.message.master,
      fb_target: post_create?.facebook_target,
      instagram_comment_enabled: post_create?.instagram_comment_enabled,
      is_auto_publish: post_create?.is_auto_publish,
      fb_posts: fb_accounts.map(account => ({
        id: 0,
        fb_posted_id: null,
        publish_datetime: null,
        message: post_create?.message.facebook || '',
        result: 'unposted',
        account: { id: account.id, name: null, image_url: null }
      })),
      tw_posts: tw_accounts.map(account => ({
        id: 0,
        tw_posted_id: null,
        publish_datetime: null,
        message: post_create?.message.twitter || '',
        result: 'unposted',
        account: { id: account.id, name: null, username: null, image_url: null },
        twitter_reply,
        twitter_quote,
        twitter_reply_error_type,
        twitter_quote_error_type
      })),
      in_posts: in_accounts.map(account => ({
        id: 0,
        in_posted_id: null,
        publish_datetime: null,
        message: post_create?.message.instagram || '',
        first_comment_message: post_create?.instagram_first_comment_message,
        result: 'unposted',
        account: { id: account.id, name: null, username: null, image_url: null }
      })),
      in_stories: in_accounts.map(account => ({
        id: 0,
        in_posted_id: null,
        publish_datetime: null,
        permalink: null,
        result: 'unposted',
        account: { id: account.id, name: null, username: null, image_url: null }
      })),
      tt_posts: tt_accounts.map(account => ({
        id: 0,
        tt_posted_id: null,
        publish_datetime: null,
        message: post_create?.message.tiktok || '',
        result: 'unposted',
        account: { id: account.id, name: null, username: null, image_url: null },
        is_brand_organic: post_create?.tiktok_option?.is_brand_organic,
        is_branded_content: post_create?.tiktok_option?.is_branded_content
      })),
      post_images: post_create?.images ?? [],
      post_videos: post_create?.videos ?? [],
      post_links: post_create?.facebook_links.map((link, index) => ({
        ...link,
        end_card: index === 0 ? post_create?.carousels_end_card : false
      })),
      post_approval_flow:
        post_create?.api_post && post_create?.page_type === 'edit'
          ? post_create?.api_post.post_approval_flow
          : null,
      post_persons: post_create?.api_post ? post_create?.api_post.post_persons : [],
      post_categories: post_create?.api_post ? post_create?.api_post.post_categories : [],
      create_user: post_create?.api_post ? post_create?.api_post.create_user : null,
      submit_user: post_create?.api_post ? post_create?.api_post.submit_user : null,
      created: post_create?.api_post ? post_create?.api_post.created : null,
      modified: post_create?.api_post ? post_create?.api_post.modified : null,
      recent_memo: post_create?.api_post ? post_create?.api_post.recent_memo : null,
      memo_count: post_create?.api_post ? post_create?.api_post.memo_count : 0,
      history_count: post_create?.api_post ? post_create?.api_post.history_count : 0,
      role: null
    } as any
  },
  dialog_memo_users(state, rootGetter, rootState): IGetter['dialog_memo_users'] {
    const api_post: IPost | null | undefined = rootState.post_create?.api_post

    const post = state.dialog_post_mode === 'create' ? api_post : state.api_post

    if (!post) return []

    const fb_accounts = post.fb_posts.map(v => ({ id: v.account.id, sns: 'facebook' }))
    const tw_accounts = post.tw_posts.map(v => ({ id: v.account.id, sns: 'twitter' }))
    const in_accounts = post.in_posts.map(v => ({ id: v.account.id, sns: 'instagram' }))
    const tt_accounts = post.tt_posts.map(v => ({ id: v.account.id, sns: 'tiktok' }))

    const accounts = [...fb_accounts, ...tw_accounts, ...in_accounts, ...tt_accounts]

    const persons = state.api_persons.filter(person =>
      accounts.every(account => {
        const fb_account_ids = person.fb_accounts.map(v => v.account_id)
        const tw_account_ids = person.tw_accounts.map(v => v.account_id)
        const in_account_ids = person.in_accounts.map(v => v.account_id)
        const tt_account_ids = person.tt_accounts.map(v => v.account_id)

        switch (account.sns) {
          case 'facebook':
            return fb_account_ids.includes(account.id)
          case 'twitter':
            return tw_account_ids.includes(account.id)
          case 'instagram':
            return in_account_ids.includes(account.id)
          case 'tiktok':
            return tt_account_ids.includes(account.id)
          default:
            return false
        }
      })
    )

    return persons.map(person => person.user)
  },
  is_instagram_manual_publish(state): IGetter['is_instagram_manual_publish'] {
    return (
      !!state.api_post &&
      !state.api_post.is_auto_publish &&
      (state.api_post.in_posts.length > 0 || state.api_post.in_stories.length > 0)
    )
  },
  is_dialog_approval(state, rootGetter, rootState): IGetter['is_dialog_approval'] {
    const user = rootState.user

    const current_step = state.api_post?.post_approval_flow?.current_step ?? 0
    const steps = state.api_post?.project_post_approval_flow?.project_post_approval_steps ?? []

    return (
      !!state.api_post &&
      state.api_post.status === 'approval' &&
      state.api_post.role === 'admin' &&
      steps.some(v => v.step === current_step && v.user_id === user.id)
    )
  },
  is_dialog_release_procedure(state): IGetter['is_dialog_release_procedure'] {
    return (
      !!state.api_post &&
      (state.api_post.role === 'admin' || state.api_post.role === 'editor') &&
      state.api_post.status === 'scheduled' &&
      (state.api_post.in_posts.length > 0 || state.api_post.in_stories.length > 0) &&
      !state.api_post.is_auto_publish
    )
  }
}

const mutations: MutationTree<IState> = {
  SET_API_COUNT(state, payload: IState['api_count']) {
    state.api_count = payload
  },
  SET_API_POSTS(state, payload: IState['api_posts']) {
    state.api_posts = payload
  },
  SET_API_POST_BY_ID(state, payload: IState['api_post']) {
    state.api_post = payload
  },
  SET_API_POST_MEMOS(state, payload: IState['api_post_memos']) {
    state.api_post_memos = payload
  },
  SET_API_POST_HISTORIES(state, payload: IState['api_post_histories']) {
    state.api_post_histories = payload
  },
  SET_API_POST_KEYWORDS(state, payload: IState['api_post_keywords']) {
    state.api_post_keywords = payload
  },
  SET_API_POST_SETTING(state, payload: IState['api_post_setting']) {
    state.api_post_setting = payload
  },
  SET_API_PERSONS(state, payload: IState['api_persons']) {
    state.api_persons = payload
  },
  SET_API_TWITTER_TRENDS(state, payload: IState['api_twitter_trends']) {
    state.api_twitter_trends = payload
  },
  SET_API_INCIDENT_EVENTS(state, payload: IState['api_incident_events']) {
    state.api_incident_events = payload
  },
  SET_START_DATE(state, payload: IState['start_date']) {
    state.start_date = payload
  },
  SET_END_DATE(state, payload: IState['end_date']) {
    state.end_date = payload
  },
  SET_OFFSET(state, payload: IState['offset']) {
    state.offset = payload
  },
  SET_ORDER(state, payload: IState['order']) {
    state.order = payload
  },
  SET_DISPLAY_MODE(state, payload: IState['display_mode']) {
    state.display_mode = payload
  },
  SET_FILTER_STATUS(state, payload: IState['filter_status']) {
    state.filter_status = payload
  },
  SET_FILTER_ACCOUNT(state, payload: IState['filter_account']) {
    state.filter_account = payload
  },
  SET_FILTER_CATEGORIES(state, payload: IState['filter_categories']) {
    state.filter_categories = payload
  },
  SET_FILTER_PERSONS(state, payload: IState['filter_persons']) {
    state.filter_persons = payload
  },
  SET_FILTER_KEYWORD(state, payload: IState['filter_keyword']) {
    state.filter_keyword = payload
  },
  SET_IS_LOADING(state, payload: IState['is_loading']) {
    state.is_loading = payload
  },
  SET_IS_LOADING_MORE(state, payload: IState['is_loading_more']) {
    state.is_loading_more = payload
  },
  SET_IS_CSV_LOADING(state, payload: IState['is_csv_loading']) {
    state.is_csv_loading = payload
  },
  SET_IS_PUBLISHING(state, payload: IState['is_publishing']) {
    state.is_publishing = payload
  },
  SET_IS_GET_POST_FAILED(state, payload: IState['is_get_post_failed']) {
    state.is_get_post_failed = payload
  },
  SET_IS_TWITTER_TREND(state, payload: IState['is_twitter_trend']) {
    state.is_twitter_trend = payload
  },
  SET_DIALOG_POST_ID(state, payload: IState['dialog_post_id']) {
    state.dialog_post_id = payload
  },
  SET_DIALOG_POST_MODE(state, payload: IState['dialog_post_mode']) {
    state.dialog_post_mode = payload
  },
  SET_DIALOG_POST_TAB(state, payload: IState['dialog_post_tab']) {
    state.dialog_post_tab = payload
  },
  SET_DIALOG_POST_ERROR(state, payload: IState['dialog_post_error']) {
    state.dialog_post_error = payload
  },
  SET_IS_DIALOG_VISIBLE(state, payload: IState['is_dialog_visible']) {
    state.is_dialog_visible = payload
  },
  SET_IS_DIALOG_LOADING(state, payload: IState['is_dialog_loading']) {
    state.is_dialog_loading = payload
  },
  SET_MEMO_CACHE(state, payload: IState['memo_cache']) {
    state.memo_cache = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * 投稿一覧の取得
   */
  async getAPIPosts(context, payload: number) {
    const LIMIT = context.state.display_mode === 'list' ? 10 : -1

    const params: IGetPostsParams = {
      project_id: context.rootState.project.id,
      start_date: moment(context.state.start_date).format('YYYY-MM-DD'),
      end_date: moment(context.state.end_date).format('YYYY-MM-DD'),
      order: context.state.order,
      offset: payload,
      limit: LIMIT,
      mode: context.state.display_mode
    }

    if (context.state.filter_status !== 'all') {
      params.status = context.state.filter_status
    }

    if (context.state.filter_account) {
      if (context.state.filter_account.sns === 'facebook') {
        params.fb_account_id = context.state.filter_account.id
      }
      if (context.state.filter_account.sns === 'twitter') {
        params.tw_account_id = context.state.filter_account.id
      }
      if (context.state.filter_account.sns === 'instagram') {
        params.in_account_id = context.state.filter_account.id
      }
      if (context.state.filter_account.sns === 'tiktok') {
        params.tt_account_id = context.state.filter_account.id
      }
    }

    if (context.state.filter_categories.length) {
      params.categories = context.state.filter_categories.join(',')
    }

    if (context.state.filter_persons.length) {
      params.persons = context.state.filter_persons.join(',')
    }

    if (context.state.filter_keyword) {
      params.keyword = context.state.filter_keyword
    }

    const response = await API.get<IGetPostsResponse>('posts', { params })

    if (response.data.error) {
      context.commit('SET_IS_GET_POST_FAILED', true)

      return response.data
    }

    if (context.state.is_get_post_failed) {
      context.commit('SET_IS_GET_POST_FAILED', false)
    }

    if (response.data.data.count) {
      context.commit('SET_API_COUNT', response.data.data.count)
    }

    if (response.data.data.posts) {
      if (payload && context.state.display_mode === 'list') {
        const posts = Array.from(
          context.state.api_posts
            .concat(response.data.data.posts)
            .reduce((p, v) => p.set(v.id, v), new Map())
            .values()
        )

        context.commit('SET_API_POSTS', posts)
        context.commit('SET_OFFSET', posts.length)
      } else {
        context.commit('SET_API_POSTS', response.data.data.posts)
        context.commit('SET_OFFSET', response.data.data.posts.length)
      }
    }

    return response.data
  },

  /**
   * CSVの投稿一覧の取得
   */
  async getAPIPostsByCsv(context) {
    const params: IGetPostsParams = {
      project_id: context.rootState.project.id,
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      order: context.state.order,
      offset: 0,
      limit: 100,
      mode: context.state.display_mode,
      for_csv: true
    }

    if (context.state.filter_status !== 'all') {
      params.status = context.state.filter_status
    }

    if (context.state.filter_account) {
      if (context.state.filter_account.sns === 'facebook') {
        params.fb_account_id = context.state.filter_account.id
      }
      if (context.state.filter_account.sns === 'twitter') {
        params.tw_account_id = context.state.filter_account.id
      }
      if (context.state.filter_account.sns === 'instagram') {
        params.in_account_id = context.state.filter_account.id
      }
    }

    if (context.state.filter_categories.length) {
      params.categories = context.state.filter_categories.join(',')
    }

    if (context.state.filter_persons.length) {
      params.persons = context.state.filter_persons.join(',')
    }

    if (context.state.filter_keyword) {
      params.keyword = context.state.filter_keyword
    }

    let posts: IPost[] = []

    context.commit('SET_IS_CSV_LOADING', true)

    const count = Math.ceil(context.state.api_count[context.state.filter_status] / params.limit)

    for (let i = 0; i < count; i++) {
      params.offset = posts.length

      const response = await API.get<IGetPostsResponse>('posts', { params })

      if (response.data.data) {
        posts = Array.from(
          posts
            .concat(response.data.data.posts)
            .reduce((p, v) => p.set(v.id, v), new Map())
            .values()
        )

        // ? DDoS対策
        await delay(1000)
      } else {
        context.commit('SET_IS_CSV_LOADING', false)

        throw new Error('GET_POST_FAILED')
      }
    }

    context.commit('SET_IS_CSV_LOADING', false)

    return posts
  },

  /**
   * 投稿単体の取得（ダイアログ用）
   */
  async getAPIPostById(context) {
    context.commit('SET_API_POST_BY_ID', null)
    context.commit('SET_DIALOG_POST_ERROR', '')

    const response = await API.get<IGetPostsResponse>('posts/' + context.state.dialog_post_id)

    if (response.data.data) {
      context.commit('SET_API_POST_BY_ID', response.data.data)
    }

    if (response.data.error) {
      if (response.data.error.type === 'NOT_EXISTS') {
        context.commit('SET_DIALOG_POST_ERROR', response.data.error.type)
      }
    }
  },

  /**
   * 投稿メモ一覧の取得（ダイアログ用）
   */
  async getAPIPostMemos(context) {
    const params: IGetPostMemosParams = {
      sns_post_id: context.state.dialog_post_id
    }

    const response = await API.get<IGetPostMemosResponses>('post_memos', { params })

    if (response.data.data) {
      context.commit('SET_API_POST_MEMOS', response.data.data)
    }
  },

  /**
   * 投稿履歴一覧の取得（ダイアログ用）
   */
  async getAPIPostHistories(context) {
    const params: IGetPostHistoriesParams = {
      sns_post_id: context.state.dialog_post_id
    }

    const response = await API.get<IGetPostHistoriesResponse>(`post_histories`, { params })

    if (response.data.data) {
      context.commit('SET_API_POST_HISTORIES', response.data.data)
    }
  },

  /**
   * 投稿設定の特定キーワードの取得（ダイアログ用）
   */
  async getAPIPostKeywords(context) {
    const params: TGetPostKeywordsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<TGetPostKeywordsResponse>('post_keywords', { params })

    if (response.data.data) {
      context.commit('SET_API_POST_KEYWORDS', response.data.data)
    }
  },

  /**
   * 投稿設定の取得（ダイアログ用）
   */
  async getAPIPostSettings(context) {
    const response = await API.get<TGetPostSettingsResponse>(
      `post_settings/${context.rootState.project.id}`
    )

    if (response.data.data) {
      context.commit('SET_API_POST_SETTING', response.data.data)
    }
  },

  /**
   * 担当者一覧の取得
   */
  async getAPIPersons(context) {
    const params: GetUsersRolesParams = {
      project_id: context.rootState.project.id,
      role_type: 'post',
      role_status: true
    }

    const responses = await API.get<GetUsersRolesResponse>('users/roles', { params })

    if (responses.data.data) {
      context.commit('SET_API_PERSONS', responses.data.data)
    }

    if (
      context.state.filter_persons.length &&
      !context.state.filter_persons.every(x =>
        context.state.api_persons.map(y => y.user.id).includes(x)
      )
    ) {
      context.commit('SET_PERSONS', [])
    }
  },

  /**
   * トレンド実績の取得
   */
  async getAPITwitterTrends(context) {
    const params: IGetTwitterTrendCalendarParams = {
      start_date: context.state.start_date,
      end_date: context.state.end_date
    }

    const responses = await API.get<IGetTwitterTrendCalendarResponse>('twitter_trends/calendar', {
      params
    })

    if (responses.data.data) {
      context.commit('SET_API_TWITTER_TRENDS', responses.data.data)
    }
  },

  /**
   * 災害事件イベント情報を取得する
   */
  async getAPIIncidentEvents(context) {
    const params: TGetIncidentEventsParams = {
      start_date: context.state.start_date,
      end_date: context.state.end_date
    }

    const response = await API.get<TGetIncidentEventsResponse>('incident_events', {
      params
    })

    if (response.data.data) {
      context.commit('SET_API_INCIDENT_EVENTS', response.data.data)
    }
  },

  /**
   * 投稿タグの更新
   */
  async updateCategories(
    context,
    payload: {
      sns_post_id: number
      categories: IPostCategories[]
    }
  ) {
    let copy = context.state.api_posts.map(v => ({ ...v }))

    const index = copy.findIndex(post => post.id === payload.sns_post_id)

    if (index < 0) return

    // 投稿のタグがフィルタリング中タグに含まない場合、投稿一覧から除外
    if (
      context.state.filter_categories.length &&
      context.state.filter_categories.every(x => !payload.categories.map(y => y.id).includes(x))
    ) {
      const status = copy[index].status
      const count = { ...context.state.api_count }

      count[status] = count[status] - 1
      count.all = count.all - 1
      copy = copy.filter(v => v.id !== payload.sns_post_id)

      context.commit('SET_API_POSTS', copy)
      context.commit('SET_OFFSET', copy.length)
      context.commit('SET_API_COUNT', count)
      return
    }

    copy[index].post_categories = payload.categories

    context.commit('SET_API_POSTS', copy)
  },

  /**
   * 投稿の削除
   */
  async deletePost(context, payload: TPostIdStatus) {
    const response = await API.delete(`posts/${payload.sns_post_id}`)

    if (response.data && response.data.data) {
      const posts = context.state.api_posts.filter(post => post.id !== payload.sns_post_id)

      context.commit('SET_API_POSTS', posts)

      const count = { ...context.state.api_count }

      count[payload.status] = count[payload.status] - 1
      count.all = count.all - 1

      context.commit('SET_API_COUNT', count)

      context.commit('SET_OFFSET', posts.length)
    }
    if (response.data.error && response.data.error.type === 'NOT_EXISTS') {
      await context.dispatch('removeDeletedPost', payload)
    }

    return response.data
  },

  /**
   * 削除済み投稿の除去
   */
  async removeDeletedPost(context, payload: TPostIdStatus) {
    const posts = context.state.api_posts.filter(post => post.id !== payload.sns_post_id)

    context.commit('SET_API_POSTS', posts)

    const count = { ...context.state.api_count }

    count[payload.status] = count[payload.status] - 1
    count.all = count.all - 1

    context.commit('SET_API_COUNT', count)

    context.commit('SET_OFFSET', posts.length)
  },

  /**
   * 再投稿を行う
   */
  async postRepublish(
    context,
    payload: {
      account_id: string
      sns: SnsType
      post: IPost
    }
  ) {
    context.commit('SET_IS_PUBLISHING', true)

    const result = await post_util.republish(payload.sns, payload.account_id, payload.post)

    context.commit('SET_IS_PUBLISHING', false)

    if (result.data) {
      if (context.state.api_posts.length) {
        await context.dispatch('reloadAllPosts')
      }
    }

    return result
  },

  /**
   * 承認待ち投稿を承認する
   */
  async postApproval(
    context,
    payload: {
      message: string
      to_user_ids: number[]
    }
  ) {
    if (!context.state.api_post) return

    const params: IPostPostApprovalParams = {
      steps: context.state.api_post.post_approval_flow?.current_step ?? 0,
      action_type: 'approved',
      memo: {
        message: payload.message,
        users: payload.to_user_ids
      }
    }

    const response = await API.post<IPostPostApprovalResponse>(
      `posts/${context.state.dialog_post_id}/approve`,
      params
    )

    if (response.data && response.data.data) {
      context.commit('SET_MEMO_CACHE', init_memo_cache)

      storage.set('post_management', { to_user_ids: payload.to_user_ids })
    }

    let result: any = {}

    // ? 承認後に手動公開する場合（Instagramのみ）
    if (response.data.data === 'publish_remind') {
      result = await API.post(
        `posts/${context.state.dialog_post_id}/instagram_publish_reminder_mail`
      )
    }

    // ? 承認後に自動公開する場合
    if (response.data.data === 'publish') {
      context.commit('SET_IS_PUBLISHING', true)

      await post_util.preUploadMedia(context.state.api_post)

      result = await post_util.approvePublish(context.state.api_post)

      context.commit('SET_IS_PUBLISHING', false)
    }

    if (result.error) {
      response.data = result
    }

    if (response.data && response.data.error && response.data.error.type === 'NOT_EXISTS') {
      await context.dispatch('removeDeletedPost', {
        sns_post_id: context.state.dialog_post_id,
        status: 'approval'
      })
    }

    if (context.state.api_posts.length) {
      await context.dispatch('changePostStatus', context.state.dialog_post_id)
    }

    return response.data
  },

  /**
   * 投稿の差し戻しを行う
   */
  async postReject(
    context,
    payload: {
      message: string
      to_user_ids: number[]
    }
  ) {
    if (!context.state.api_post) return

    const params: IPostPostApprovalParams = {
      steps: context.state.api_post.post_approval_flow?.current_step ?? 0,
      action_type: 'rejected',
      memo: {
        message: payload.message,
        users: payload.to_user_ids
      }
    }

    const response = await API.post<IPostPostApprovalResponse>(
      `posts/${context.state.dialog_post_id}/approve`,
      params
    )

    if (response.data && response.data.data) {
      context.commit('SET_MEMO_CACHE', init_memo_cache)

      storage.set('post_management', { to_user_ids: payload.to_user_ids })
    }

    if (response.data && response.data.error) {
      if (response.data.error.type === 'NOT_EXISTS') {
        await context.dispatch('removeDeletedPost', {
          sns_post_id: context.state.dialog_post_id,
          status: 'approval'
        })
      }
    }

    if (context.state.api_posts.length) {
      await context.dispatch('changePostStatus', context.state.dialog_post_id)
    }

    return response.data
  },

  /**
   * Instagram投稿の公開を行う
   */
  async publishInstagramPost(
    context,
    payload: {
      sns_post_id: number
      post_url: string
    }
  ) {
    const params: IPostInstagramPublishParams = {
      post_url: payload.post_url
    }

    const response = await API.post<IPostInstagramPublishResponse>(
      `posts/${payload.sns_post_id}/instagram_publish`,
      params
    )

    if (context.state.api_posts.length) {
      await context.dispatch('changePostStatus', payload.sns_post_id)
    }

    return response.data
  },

  /**
   * Instagram投稿の公開URLを更新
   */
  async updateInstagramUrl(
    context,
    payload: {
      sns_post_id: number
      post_url: string
    }
  ) {
    const params: IPutInstagramUrlParams = {
      post_url: payload.post_url
    }

    const response = await API.put<IPutInstagramUrlResponse>(
      `posts/${payload.sns_post_id}/instagram_url`,
      params
    )

    if (context.state.api_posts.length) {
      await context.dispatch('changePostStatus', payload.sns_post_id)
    }

    return response.data
  },

  /**
   * 投稿の予約取り消しを行う
   */
  async cancelScheduledPost(context, payload: number) {
    const { data } = await API.post<IPostScheduledCancelResponse>(
      `posts/${payload}/scheduled_cancel`
    )

    if (context.state.api_posts.length) {
      await context.dispatch('changePostStatus', payload)
    }

    if (data.error && data.error.type === 'NOT_EXISTS') {
      await context.dispatch('removeDeletedPost', {
        sns_post_id: payload,
        status: 'scheduled'
      })
    }

    return data
  },

  /**
   * 投稿の承認申請取り消しを行う
   */
  async cancelApprove(context, payload: number) {
    const { data } = await API.post<IPostApproveCancelResponse>(`posts/${payload}/approve_cancel`)

    if (context.state.api_posts.length) {
      await context.dispatch('changePostStatus', payload)
    }

    if (data.error && data.error.type === 'NOT_EXISTS') {
      await context.dispatch('removeDeletedPost', {
        sns_post_id: payload,
        status: 'approval'
      })
    }

    return data
  },

  /**
   * 投稿データを更新する
   */
  async changePost(context, payload: IPost) {
    let copy = context.state.api_posts.map(v => ({ ...v }))

    const current_post = copy.find(v => v.id === payload.id)

    if (!current_post) return

    const current_status = current_post.status

    if (context.state.filter_status === 'all' || payload.status === context.state.filter_status) {
      const post_item = copy.find(v => v.id === payload.id)

      post_item.action_datetime = payload.action_datetime
      post_item.recent_memo = payload.recent_memo
      post_item.post_approval_flow = payload.post_approval_flow
      post_item.status = payload.status

      post_item.memo_count = payload.memo_count
      post_item.history_count = payload.history_count

      post_item.create_user = payload.create_user
      post_item.submit_user = payload.submit_user

      post_item.fb_posts = payload.fb_posts
      post_item.tw_posts = payload.tw_posts
      post_item.in_posts = payload.in_posts
      post_item.in_stories = payload.in_stories
    } else if (context.state.filter_status !== payload.status) {
      copy = copy.filter(v => v.id !== payload.id)
    }

    context.commit('SET_API_POSTS', copy)
    context.commit('SET_OFFSET', copy.length)

    if (payload.status !== current_status) {
      const count = { ...context.state.api_count }

      count[current_status] = count[current_status] - 1

      count[payload.status] = count[payload.status] + 1
      context.commit('SET_API_COUNT', count)
    }
  },

  /**
   * 投稿ステータスを変更する
   */
  async changePostStatus(context, payload: number) {
    const { data } = await API.get<IGetPostResponse>(`posts/${payload}`)

    if (!data || !data.data) {
      return
    }

    const post = data.data

    await context.dispatch('changePost', post)

    return post
  },

  /**
   * 投稿ステータスを判定する
   */
  async checkPostStatus(context, payload: TPostIdStatus) {
    const { data } = await API.get<IGetPostResponse>(`posts/${payload.sns_post_id}`)

    const post = data.data

    if (post && ['published', 'scheduled', 'failure', 'approval'].includes(post.status)) {
      await context.dispatch('changePost', post)
    }

    if (data.error && data.error.type === 'NOT_EXISTS') {
      await context.dispatch('removeDeletedPost', payload)
    }

    return data
  },

  /**
   * 初回アクセス時の初期化
   */
  async initializePostManagement(context) {
    const [start_date, end_date] = getInitDateRange(context.state.display_mode)

    context.commit('SET_START_DATE', start_date)
    context.commit('SET_END_DATE', end_date)
  },

  /**
   * ページ表示時の処理
   */
  async createdPostManagementPage(
    context,
    payload: {
      is_smartphone: boolean
      scheduled_datetime?: string
    }
  ) {
    // ? 意図せずスマホで表示できないUIが表示されないように強制変更する
    if (payload.is_smartphone && context.state.display_mode !== 'list') {
      context.commit('SET_DISPLAY_MODE', 'list')
    }

    // ? 日付指定がある場合は日付間隔を調整する
    if (payload.scheduled_datetime) {
      const base = payload.scheduled_datetime

      if (context.state.display_mode === 'week') {
        const start_date = moment(base).startOf('isoWeek').format('YYYY-MM-DD')
        const end_date = moment(base).endOf('isoWeek').format('YYYY-MM-DD')

        context.commit('SET_START_DATE', start_date)
        context.commit('SET_END_DATE', end_date)
      }

      if (context.state.display_mode === 'month') {
        const start_date = moment(base).startOf('month').format('YYYY-MM-DD')
        const end_date = moment(base).endOf('month').format('YYYY-MM-DD')

        context.commit('SET_START_DATE', start_date)
        context.commit('SET_END_DATE', end_date)
      }
    }

    await context.dispatch('reloadAllPosts')
  },

  /**
   * ページ離脱時の処理
   */
  async destroyedPostManagementPage(context) {
    context.commit('SET_API_POSTS', [])
    context.commit('SET_API_COUNT', init_api_count)
    context.commit('SET_API_PERSONS', [])
    context.commit('SET_API_TWITTER_TRENDS', [])
    context.commit('SET_API_INCIDENT_EVENTS', [])
    context.commit('SET_OFFSET', 0)
    context.commit('SET_IS_LOADING', false)
    context.commit('SET_IS_LOADING_MORE', false)
    context.commit('SET_IS_CSV_LOADING', false)
    context.commit('SET_IS_PUBLISHING', false)
    context.commit('SET_IS_TWITTER_TREND', false)
    context.commit('SET_MEMO_CACHE', init_memo_cache)
  },

  /**
   * グループの変更時の処理
   */
  async changeGroupPostManagementPage(context) {
    await context.dispatch('destroyedPostManagementPage')

    context.commit('SET_FILTER_STATUS', 'all')
    context.commit('SET_FILTER_ACCOUNT', '')
    context.commit('SET_FILTER_CATEGORIES', [])
    context.commit('SET_FILTER_PERSONS', [])
    context.commit('SET_FILTER_KEYWORD', '')

    await context.dispatch('createdPostManagementPage', {
      is_smartphone: false
    })
  },

  /**
   * スクロールして投稿を更に取得
   */
  async scrollFetchMorePosts(context) {
    const getters: IGetter = context.getters

    if (!getters.is_read_more || context.state.is_loading_more) return

    context.commit('SET_IS_LOADING_MORE', true)

    await context.dispatch('getAPIPosts', context.state.offset)

    context.commit('SET_IS_LOADING_MORE', false)
  },

  /**
   * 投稿一覧の再読み込み
   */
  async reloadAllPosts(context): Promise<void> {
    context.commit('SET_IS_LOADING', true)

    await Promise.all([
      context.dispatch('getAPIPosts', 0),
      context.dispatch('getAPIPersons'),
      context.dispatch('getAPIIncidentEvents')
    ])

    context.commit('SET_IS_LOADING', false)
  },

  /**
   * 投稿の日付範囲を変更
   */
  async changePostDateRange(context, payload: [IState['start_date'], IState['end_date']]) {
    context.commit('SET_START_DATE', payload[0])
    context.commit('SET_END_DATE', payload[1])

    if (context.state.is_twitter_trend) {
      await context.dispatch('getAPITwitterTrends')
    }

    await context.dispatch('reloadAllPosts')
  },

  /**
   * 投稿の並び順を変更
   */
  async changePostOrder(context, payload: IState['order']) {
    context.commit('SET_ORDER', payload)

    await context.dispatch('reloadAllPosts')
  },

  /**
   * 投稿の表示モードの変更
   */
  async changePostDisplayMode(context, payload: IState['display_mode']) {
    context.commit('SET_DISPLAY_MODE', payload)

    const date_range = getInitDateRange(payload)

    await context.dispatch('changePostDateRange', date_range)
  },

  /**
   * 投稿ステータスの絞り込み
   */
  async filterPostStatus(context, payload: IState['filter_status']) {
    context.commit('SET_FILTER_STATUS', payload)

    await context.dispatch('reloadAllPosts')
  },

  /**
   * 投稿アカウントの絞り込み
   */
  async filterPostAccount(context, payload: IState['filter_account']) {
    context.commit('SET_FILTER_ACCOUNT', payload)

    await context.dispatch('reloadAllPosts')
  },

  /**
   * 投稿タグの絞り込み
   */
  async filterPostCategories(context, payload: IState['filter_categories']) {
    context.commit('SET_FILTER_CATEGORIES', payload)

    await context.dispatch('reloadAllPosts')
  },

  /**
   * 担当者の絞り込み
   */
  async filterPostPersons(context, payload: IState['filter_persons']) {
    context.commit('SET_FILTER_PERSONS', payload)

    await context.dispatch('reloadAllPosts')
  },

  /**
   * キーワードの絞り込み
   */
  async filterPostKeyword(context, payload: IState['filter_keyword']) {
    context.commit('SET_FILTER_KEYWORD', payload)
  },

  /**
   * トレンド実績の表示フラグ変更
   */
  async changeIsTwitterTrend(context, payload: IState['is_twitter_trend']) {
    context.commit('SET_IS_TWITTER_TREND', payload)

    if (context.state.is_twitter_trend) {
      await context.dispatch('getAPITwitterTrends')
    }
  },

  /**
   * 投稿詳細ダイアログを開く
   */
  async openPostDetailDialog(
    context,
    payload: {
      sns_post_id: IState['dialog_post_id']
      mode: IState['dialog_post_mode']
      tab: IState['dialog_post_tab']
    }
  ) {
    context.commit('SET_IS_DIALOG_VISIBLE', true)

    context.commit('SET_DIALOG_POST_ID', payload.sns_post_id)
    context.commit('SET_DIALOG_POST_MODE', payload.mode)
    context.commit('SET_DIALOG_POST_TAB', payload.tab)

    context.commit('SET_IS_DIALOG_LOADING', true)

    if (context.state.dialog_post_id) {
      await Promise.all([
        context.dispatch('getAPIPostById'),
        context.dispatch('getAPIPostMemos'),
        context.dispatch('getAPIPostHistories')
      ])
    }

    await Promise.all([
      context.dispatch('getAPIPostKeywords'),
      context.dispatch('getAPIPostSettings')
    ])

    context.commit('SET_IS_DIALOG_LOADING', false)

    if (
      context.state.dialog_post_mode === 'create' ||
      context.state.dialog_post_mode === 'preview'
    ) {
      return
    }

    const getters: IGetter = context.getters

    // 承認で開こうとしてもまだ承認状態じゃない可能性があるため
    if (context.state.dialog_post_mode === 'approval' && getters.is_dialog_approval) {
      return context.commit('SET_DIALOG_POST_MODE', 'approval')
    }

    // メール経由では承認指定になっているため、承認ではない場合にどっちか満たしていれば切り替える
    if (
      context.state.dialog_post_mode === 'release_procedure' ||
      getters.is_dialog_release_procedure
    ) {
      return context.commit('SET_DIALOG_POST_MODE', 'release_procedure')
    }

    // 承認指定や手動投稿指定だが、条件にマッチしていない場合はプレビューとして扱う
    return context.commit('SET_DIALOG_POST_MODE', 'preview')
  },

  /**
   * 投稿詳細ダイアログを閉じる
   */
  async closePostDetailDialog(context) {
    context.commit('SET_IS_DIALOG_VISIBLE', false)

    context.commit('SET_DIALOG_POST_ID', null)
    context.commit('SET_DIALOG_POST_MODE', 'preview')
    context.commit('SET_DIALOG_POST_TAB', 'preview')
    context.commit('SET_DIALOG_POST_ERROR', '')

    context.commit('SET_API_POST_BY_ID', null)
    context.commit('SET_API_POST_MEMOS', [])
    context.commit('SET_API_POST_HISTORIES', [])
  },

  /**
   * 投稿ダイアログのタブを変更
   */
  async changePostDialogTab(context, payload: IState['dialog_post_tab']) {
    context.commit('SET_DIALOG_POST_TAB', payload)

    if (payload === 'preview') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー')
    }
    if (payload === 'memo') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|メモ')
    }
    if (payload === 'history') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|履歴')
    }
  },

  /**
   * 投稿ダイアログの更新
   */
  async updatePostDialog(context): Promise<void> {
    await Promise.all([
      context.dispatch('getAPIPostById'),
      context.dispatch('getAPIPostMemos'),
      context.dispatch('getAPIPostHistories')
    ])
  },

  /**
   * 投稿メモの作成
   */
  async createPostMemo(
    context,
    payload: {
      message: string
      to_user_ids: number[]
    }
  ) {
    TrackingService.sendEvent('click:投稿(ダイアログ)|メモ|メモを追加')

    const params: IPostMemosParams = {
      project_id: context.rootState.project.id,
      sns_post_id: context.state.dialog_post_id,
      to_user_ids: payload.to_user_ids,
      message: payload.message
    }

    const response = await API.post<IPostMemosResponses>('post_memos', params)

    if (response.data && response.data.data) {
      context.commit('SET_MEMO_CACHE', init_memo_cache)

      storage.set('post_management', { to_user_ids: payload.to_user_ids })

      await context.dispatch('getAPIPostMemos')
    }

    const copy = context.state.api_posts.map(v => ({ ...v }))

    const memos = context.state.api_post_memos.filter(v => !v.is_deleted)

    const post = copy.find(v => v.id === context.state.dialog_post_id)

    if (post) {
      post.memo_count = memos.length
      post.recent_memo = memos[0] || null

      context.commit('SET_API_POSTS', copy)
    }

    return response.data
  },

  /**
   * 投稿メモの更新
   */
  async updatePostMemo(
    context,
    payload: {
      id: number
      message: string
      to_user_ids: number[]
    }
  ) {
    TrackingService.sendEvent('click:投稿(ダイアログ)|メモ|編集を保存')

    const params: IPutMemoParams = {
      message: payload.message,
      to_user_ids: payload.to_user_ids
    }

    const response = await API.put<IPutMemoResponses>('post_memos/' + payload.id, params)

    if (response.data.data) {
      storage.set('post_management', { to_user_ids: payload.to_user_ids })

      await context.dispatch('getAPIPostMemos')
    }

    const copy = context.state.api_posts.map(v => ({ ...v }))

    const memos = context.state.api_post_memos.filter(v => !v.is_deleted)

    const post = copy.find(v => v.id === context.state.dialog_post_id)

    if (post) {
      post.memo_count = memos.length
      post.recent_memo = memos[0] || null

      context.commit('SET_API_POSTS', copy)
    }

    return response.data
  },

  /**
   * 投稿メモの削除
   */
  async removePostMemo(context, payload: number) {
    TrackingService.sendEvent('click:投稿(ダイアログ)|メモ|削除')

    const response = await API.delete<IDeletePostMemoResponses>('post_memos/' + payload)

    if (response.data.data) {
      await context.dispatch('getAPIPostMemos')
    }

    const copy = context.state.api_posts.map(v => ({ ...v }))

    const memos = context.state.api_post_memos.filter(v => !v.is_deleted)

    const post = copy.find(v => v.id === context.state.dialog_post_id)

    if (post) {
      post.memo_count = memos.length
      post.recent_memo = memos[0] || null

      context.commit('SET_API_POSTS', copy)
    }

    return response.data
  },

  /**
   * 投稿のメモキャッシュを変更
   */
  async changePostMemoCache(context, payload: IState['memo_cache']) {
    context.commit('SET_MEMO_CACHE', payload)
  },

  /**
   * 投稿作成のプレビュー初期化
   */
  async initPostCreateDialog(context, payload: IState['dialog_post_id']) {
    context.commit('SET_DIALOG_POST_ID', payload)
    context.commit('SET_DIALOG_POST_MODE', 'create')
    context.commit('SET_DIALOG_POST_TAB', 'preview')

    context.commit('SET_IS_DIALOG_LOADING', true)

    if (context.state.dialog_post_id) {
      await Promise.all([
        context.dispatch('getAPIPostById'),
        context.dispatch('getAPIPostMemos'),
        context.dispatch('getAPIPostHistories')
      ])
    }

    await Promise.all([
      context.dispatch('getAPIPostKeywords'),
      context.dispatch('getAPIPostSettings')
    ])

    context.commit('SET_IS_DIALOG_LOADING', false)
  },

  /**
   * 投稿作成のプレビュー離脱時
   */
  async resetPostCreateDialog(context) {
    context.commit('SET_DIALOG_POST_ID', null)
    context.commit('SET_DIALOG_POST_MODE', 'preview')
    context.commit('SET_DIALOG_POST_TAB', 'preview')
    context.commit('SET_DIALOG_POST_ERROR', '')

    context.commit('SET_API_POST_BY_ID', null)
    context.commit('SET_API_POST_MEMOS', [])
    context.commit('SET_API_POST_HISTORIES', [])
  },

  /**
   * 投稿作成で送信失敗した時
   */
  async submitErrorForPostCreate(context, payload: number) {
    context.commit('SET_DIALOG_POST_ID', payload)

    await Promise.all([
      context.dispatch('getAPIPostById'),
      context.dispatch('getAPIPostMemos'),
      context.dispatch('getAPIPostHistories')
    ])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
