
import { Component, Prop, Vue } from 'vue-property-decorator'

type TFlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse'
type TFlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse'
type TFlexJustify = 'start' | 'end' | 'center' | 'between' | 'around'
type TFlexAlign = 'start' | 'end' | 'center' | 'baseline' | 'stretch'

@Component({
  name: 'Flex'
})
export default class Flex extends Vue {
  @Prop({ type: String, default: 'nowrap' })
  wrap!: TFlexWrap

  @Prop({ type: String, default: 'row' })
  direction!: TFlexDirection

  @Prop({ type: String, default: 'start' })
  justify!: TFlexJustify

  @Prop({ type: String, default: 'start' })
  align!: TFlexAlign
}
