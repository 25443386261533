import { Component, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import InstagramComparisonAnalyticsAccountTable from '@/client/components-old/organisms/InstagramComparisonAnalyticsAccountTable'
import InstagramComparisonAnalyticsChartPositioningMap from '@/client/components-old/organisms/InstagramComparisonAnalyticsChartPositioningMap'
import InstagramComparisonAnalyticsChartVertical from '@/client/components-old/organisms/InstagramComparisonAnalyticsChartVertical'
import InstagramComparisonAnalyticsRanking from '@/client/components-old/organisms/InstagramComparisonAnalyticsRanking'
import SnsEmbedDialog from '@/client/components-old/organisms/SnsEmbedDialog'

@Component({
  name: 'InstagramComparisonAnalyticsBenchmark',
  components: {
    InstagramComparisonAnalyticsAccountTable,
    InstagramComparisonAnalyticsChartVertical,
    InstagramComparisonAnalyticsRanking,
    InstagramComparisonAnalyticsChartPositioningMap,
    SnsEmbedDialog,
    Scroll
  }
})
export default class InstagramComparisonAnalyticsBenchmark extends Vue {
  get followers_count() {
    return {
      metric: 'followers_count',
      data_type: 'daily_data',
      interval: '1_day',
      unit: 'total',
      is_change_interval: true
    }
  }

  get followers_count_up_down() {
    return {
      metric: 'followers_count_up_down',
      data_type: 'daily_data',
      interval: '1_day',
      unit: 'total',
      is_change_interval: true
    }
  }

  get rankings() {
    return [
      {
        main_metric: 'reactions',
        sub_metrics: ['likes', 'comments', 'reactions_rate'],
        sort: 'descending',
        limit: 5
      },
      {
        main_metric: 'reactions',
        sub_metrics: ['likes', 'comments', 'reactions_rate'],
        sort: 'ascending',
        limit: 5
      }
    ]
  }
}
