import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { IState as ISettingState } from '@/client/store/modules/personal_setting'
import { getLoginStatus, login } from '@/client/utils/facebook-sdk'

const setting = namespace('personal_setting')
const notification = namespace('notification')

@Component({
  name: 'PersonalSettingFacebookAuth',
  components: {
    Button,
    Flex,
    Icon,
    Panel,
    Tooltip
  }
})
export default class PersonalSettingFacebookAuth extends Vue {
  @setting.State('api_user') api_user: ISettingState['api_user']
  @setting.Action('postFacebookConnection') postFacebookConnection
  @setting.Action('deleteFacebookConnection') deleteFacebookConnection
  @notification.Action('showNotification') showNotification

  get is_facebook_login() {
    return this.api_user && this.api_user.is_facebook_login
  }

  /**
   * Facebookログイン連携を有効化する
   */
  async addFacebookConnection(): Promise<void> {
    let res = await getLoginStatus()

    if (res.status !== 'connected') {
      res = await login()
    }

    if (res.status === 'connected') {
      const response = await this.postFacebookConnection(res.authResponse.accessToken)

      if (response && response.data) {
        return this.showNotification({ title: 'Facebookアカウントと連携しました。' })
      }

      if (response.error && response.error.type === 'DUPLICATE') {
        return this.showNotification({ title: 'すでに連携済みのFacebookアカウントです。' })
      }
    }

    this.showNotification({ title: 'Facebookログイン連携に失敗しました。', type: 'error' })
  }

  /**
   * Facebookログイン連携を解除する
   */
  async cancelFacebookConnection(): Promise<void> {
    const confirm = window.confirm(
      this.$options.filters.translate('Facebookとの連携を解除します。よろしいですか？')
    )

    if (!confirm) return

    const response = await this.deleteFacebookConnection()

    if (response && response.data) {
      return this.showNotification({ title: 'Facebookログイン連携を解除しました。' })
    }

    this.showNotification({
      title: 'Facebookログイン連携の解除に失敗しました。',
      type: 'error'
    })
  }
}
