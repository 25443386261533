
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Textarea'
})
export default class Textarea extends Vue {
  @Prop({ type: String, required: true })
  value!: string

  @Prop({ type: String, default: '' })
  placeholder!: string

  @Prop({ type: Number, default: 5 })
  rows!: number

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: Boolean, default: false })
  isError!: boolean

  get classes() {
    if (this.isError && !this.disabled) {
      return ['error']
    }

    return []
  }

  get input_value() {
    return this.value
  }

  set input_value(val) {
    this.$emit('input', val)
  }
}
