
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import InstagramAnalyticsHeader from '@/client/components-old/organisms/InstagramAnalyticsHeader'
import InstagramAnalyticsAction from '@/client/components-old/templates/InstagramAnalyticsAction'
import InstagramAnalyticsFollower from '@/client/components-old/templates/InstagramAnalyticsFollower'
import InstagramAnalyticsNodata from '@/client/components-old/templates/InstagramAnalyticsNodata'
import InstagramAnalyticsPost from '@/client/components-old/templates/InstagramAnalyticsPost'
import InstagramAnalyticsReach from '@/client/components-old/templates/InstagramAnalyticsReach'
import InstagramAnalyticsStories from '@/client/components-old/templates/InstagramAnalyticsStories'
import InstagramAnalyticsSummary from '@/client/components-old/templates/InstagramAnalyticsSummary'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import { IState as IInstagramState } from '@/client/store/modules/instagram_analytics'
import event from '@/client/utils/event'

const accounts = namespace('accounts')
const instagram = namespace('instagram_analytics')

@Component({
  name: 'InstagramAnalytics',
  components: {
    InstagramAnalyticsHeader,
    InstagramAnalyticsNodata,
    InstagramAnalyticsSummary,
    InstagramAnalyticsPost,
    InstagramAnalyticsStories,
    InstagramAnalyticsFollower,
    InstagramAnalyticsAction,
    InstagramAnalyticsReach
  }
})
export default class InstagramAnalytics extends Vue {
  @accounts.Getter('instagram_analytics') accounts!: IAccountsGetter['instagram_analytics']
  @instagram.State('is_loading') is_loading!: IInstagramState['is_loading']
  @instagram.State('account_id') account_id!: IInstagramState['account_id']
  @instagram.State('screen_name') screen_name!: IInstagramState['screen_name']
  @instagram.Action('createdInstagramAnalyticsPage') createdInstagramAnalyticsPage!: any
  @instagram.Action('destroyedInstagramAnalyticsPage') destroyedInstagramAnalyticsPage!: any
  @instagram.Action('changeGroupInstagramAnalyticsPage') changeGroupInstagramAnalyticsPage!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  get is_empty() {
    const account = this.accounts.find(account => account.id === this.account_id)

    return account ? account.is_empty : false
  }

  get is_summary() {
    return this.screen_name === 'summary'
  }

  get is_post() {
    return this.screen_name === 'post'
  }

  get is_stories() {
    return this.screen_name === 'stories'
  }

  get is_follower() {
    return this.screen_name === 'follower'
  }

  get is_action() {
    return this.screen_name === 'action'
  }

  get is_reach() {
    return this.screen_name === 'reach'
  }

  @Watch('is_loading')
  async changeIsLoading() {
    await this.$nextTick()
    await event.delay(500)
    event.resize()
  }

  @Watch('screen_name')
  async changeScreenName() {
    await this.$nextTick()
    await event.delay(500)
    event.resize()
  }

  @Watch('group')
  async watchGroup() {
    await this.changeGroupInstagramAnalyticsPage()
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  @Emit('change-project')
  changeProject(payload: any) {
    return payload
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.createdInstagramAnalyticsPage(this.$route.query)

    if (this.$route.query?.account_id) {
      await this.$router.replace({ name: 'analytics_in' })
    }
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedInstagramAnalyticsPage()
  }
}
