import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { sns_analytics } from '@/client/features/sns_analytics/stores'
import { tiktok_analytics } from '@/client/features/tiktok_analytics/stores'
import global from '@/client/store/global'
import accounts from '@/client/store/modules/accounts'
import analytics from '@/client/store/modules/analytics'
import categories from '@/client/store/modules/categories'
import dashboard from '@/client/store/modules/dashboard'
import facebook_analytics from '@/client/store/modules/facebook_analytics'
import facebook_comparison_analytics from '@/client/store/modules/facebook_comparison_analytics'
import group_setting from '@/client/store/modules/group_setting'
import instagram_analytics from '@/client/store/modules/instagram_analytics'
import instagram_comparison_analytics from '@/client/store/modules/instagram_comparison_analytics'
import instagram_hashtag from '@/client/store/modules/instagram_hashtag'
import monitoring from '@/client/store/modules/monitoring'
import monitoring_templates from '@/client/store/modules/monitoring_templates'
import notification from '@/client/store/modules/notification'
import payment_contract from '@/client/store/modules/payment_contract'
import personal_setting from '@/client/store/modules/personal_setting'
import post_create from '@/client/store/modules/post_create'
import post_management from '@/client/store/modules/post_management'
import post_templates from '@/client/store/modules/post_templates'
import signup from '@/client/store/modules/signup'
import sns_account_categories from '@/client/store/modules/sns_account_categories'
import twitter_analytics from '@/client/store/modules/twitter_analytics'
import twitter_comparison_analytics from '@/client/store/modules/twitter_comparison_analytics'
import twitter_keyword from '@/client/store/modules/twitter_keyword'

Vue.use(Vuex)

export const store = new Vuex.Store({
  ...global,
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    accounts,
    analytics,
    categories,
    dashboard,
    facebook_analytics,
    facebook_comparison_analytics,
    group_setting,
    instagram_analytics,
    instagram_comparison_analytics,
    instagram_hashtag,
    monitoring_templates,
    monitoring,
    notification,
    payment_contract,
    personal_setting,
    post_create,
    post_management,
    post_templates,
    signup,
    sns_account_categories,
    sns_analytics,
    tiktok_analytics,
    twitter_analytics,
    twitter_comparison_analytics,
    twitter_keyword
  },
  plugins: [
    createPersistedState({
      key: 'cms_store',
      paths: [
        'post_management.display_mode',
        'sns_analytics.basic_display_unit',
        'sns_analytics.transition_display_metric',
        'sns_analytics.display_interval'
      ]
    })
  ]
})
