import { render, staticRenderFns } from "./DashboardPostApprove.vue?vue&type=template&id=aabaca34&scoped=true"
import script from "./DashboardPostApprove.ts?vue&type=script&lang=ts&external"
export * from "./DashboardPostApprove.ts?vue&type=script&lang=ts&external"
import style0 from "./DashboardPostApprove.vue?vue&type=style&index=0&id=aabaca34&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aabaca34",
  null
  
)

export default component.exports