import { parse } from 'papaparse'
import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import HelpLink from '@/client/components/molecules/HelpLink'
import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import ExternalLink from '@/client/components-old/atoms/ExternalLink'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { IRootState } from '@/client/store/global'
import { IGetter as IGetterAccounts } from '@/client/store/modules/accounts'
import API from '@/client/utils/api'
import {
  IGetTwitterInsightsPostImportsParams,
  IGetTwitterInsightsPostImportsResponse
} from '@/client/utils/api/twitter_insights'

const accounts = namespace('accounts')
const twitter = namespace('twitter_analytics')
const notification = namespace('notification')

@Component({
  name: 'TwitterAnalyticsDataImportDialog',
  components: {
    Dialog,
    Button,
    ExternalLink,
    HelpLink,
    Icon,
    Message,
    Tooltip
  }
})
export default class TwitterAnalyticsDataImportDialog extends Vue {
  @State('project') project: IRootState['project']
  @accounts.Getter('twitter_analytics') sns_accounts: IGetterAccounts['twitter_analytics']
  @twitter.State('account_id') account_id: string
  @twitter.Action('getInsights') getInsights
  @notification.Action('showNotification') showNotification

  $refs: {
    file: HTMLInputElement
  }

  visible = false
  disabled = false
  loading = false

  get username() {
    const account = this.sns_accounts.find(v => v.id === this.account_id)
    return account ? account.username : ''
  }

  /**
   * ダイアログの非表示
   * @returns {void}
   */
  hide(): void {
    this.visible = false
    this.disabled = false
  }

  /**
   * ダイアログの表示
   * @returns {void}
   */
  open(): void {
    this.visible = true
    this.disabled = false
  }

  /**
   * ファイル選択を実行
   * @returns {void}
   */
  openInputFile(): void {
    this.$refs.file.click()
  }

  /**
   * ファイル選択を実行
   * @param {any} e イベント
   * @returns {void}
   */
  changeInputFile(e: any): void {
    const files: FileList = e.target.files

    // 存在チェック
    if (!files.length || !files[0]) {
      this.showNotificationError()
      return
    }

    const file = files[0]

    this.$refs.file.value = null

    this.disabled = true

    const reader = new FileReader()

    reader.onerror = () => {
      this.showNotificationError()
    }

    reader.onload = () => {
      const input: any = reader.result

      // CSVの読み込み
      const csv = parse<any>(input, { header: true, skipEmptyLines: true })

      if (csv.errors.length) {
        this.showNotificationError()
      } else {
        this.uploadInputFile(csv.data).catch(() => {
          this.showNotificationError()
        })
      }
    }

    reader.readAsText(file)
  }

  /**
   * ファイルアップロード
   * @param {any[]} csv イベント
   * @returns {void}
   */
  async uploadInputFile(csv: { [key: string]: string }[]): Promise<void> {
    if (!this.account_id) {
      return
    }

    this.loading = true

    // 投稿データを作成
    const post_data = csv.map(data => {
      const convert = (ja: string, en: string, zh_cn: string): number => {
        const value = Number(data[ja] || data[en] || data[zh_cn])
        return isNaN(value) ? 0 : value
      }

      const tweet_id = data['ツイートID'] || data['Tweet id'] || data['發推id']

      const permalink_clicks_organic = convert(
        '固定リンクのクリック数',
        'permalink clicks',
        '永久链接的点击次数'
      )
      const permalink_clicks_paid = convert(
        'プロモの固定リンクのクリック数',
        'promoted permalink clicks',
        '推广的 永久链接的点击次数'
      )
      const permalink_clicks = permalink_clicks_organic + permalink_clicks_paid

      const hashtag_clicks_organic = convert(
        'ハッシュタグクリック',
        'hashtag clicks',
        '话题标签点击数'
      )
      const hashtag_clicks_paid = convert(
        'プロモのハッシュタグクリック',
        'promoted hashtag clicks',
        '推广的 话题标签点击数'
      )
      const hashtag_clicks = hashtag_clicks_organic + hashtag_clicks_paid

      const detail_expands_organic = convert('詳細クリック', 'detail expands', '展开详情次数')
      const detail_expands_paid = convert(
        'プロモの詳細クリック',
        'promoted detail expands',
        '推广的 展开详情次数'
      )
      const detail_expands = detail_expands_organic + detail_expands_paid

      const app_opens_organic = convert('アプリ表示', 'app opens', '打开应用')
      const app_opens_paid = convert('プロモのアプリ表示', 'promoted app opens', '推广的 打开应用')
      const app_opens = app_opens_organic + app_opens_paid

      const app_installs_organic = convert('アプリインストール', 'app installs', '应用安装次数')
      const app_installs_paid = convert(
        'プロモのアプリインストール',
        'promoted app installs',
        '推广的 应用安装次数'
      )
      const app_installs = app_installs_organic + app_installs_paid

      const follows_organic = convert('フォローしている', 'follows', '关注')
      const follows_paid = convert('プロモのフォローしている', 'promoted follows', '推广的 关注')
      const follows = follows_organic + follows_paid

      const media_engagements_organic = convert(
        'メディアのエンゲージメント数',
        'media engagements',
        '媒体参与'
      )
      const media_engagements_paid = convert(
        'プロモのメディアのエンゲージメント数',
        'promoted media engagements',
        '推广的 媒体参与'
      )
      const media_engagements = media_engagements_organic + media_engagements_paid

      return {
        tweet_id,
        permalink_clicks_organic,
        permalink_clicks_paid,
        permalink_clicks,
        hashtag_clicks_organic,
        hashtag_clicks_paid,
        hashtag_clicks,
        detail_expands_organic,
        detail_expands_paid,
        detail_expands,
        app_opens_organic,
        app_opens_paid,
        app_opens,
        app_installs_organic,
        app_installs_paid,
        app_installs,
        follows_organic,
        follows_paid,
        follows,
        media_engagements_organic,
        media_engagements_paid,
        media_engagements
      }
    })

    // 10件ごとに実行間隔を分ける
    const tasks = post_data
      .filter(data => data.tweet_id)
      .reduce(
        (v, c) => {
          const last = v[v.length - 1]

          if (last.length === 10) {
            v.push([c])
            return v
          }

          last.push(c)
          return v
        },
        [[]]
      )

    let update_count = 0

    for (let i = 0; i < tasks.length; i++) {
      try {
        const params: IGetTwitterInsightsPostImportsParams = {
          account_id: this.account_id,
          post_data: tasks[i],
          project_id: this.project.id
        }

        const response = await API.post<IGetTwitterInsightsPostImportsResponse>(
          'twitter_insights/post_imports',
          params
        )

        if (response.data && response.data.data) {
          update_count += response.data.data.update_count
        }
      } catch (e) {
        console.error(e)
      }
    }

    const title = this.$options.filters.translate(
      '[[all]] 件中 [[update]] 件のレコードをインポートしました。',
      {
        all: post_data.length,
        update: update_count
      }
    )

    this.showNotification({ title })

    this.loading = false

    this.getInsights({ account_id: this.account_id })

    this.hide()
  }

  /**
   * ファイル選択を実行
   * @param {any} e イベント
   * @returns {void}
   */
  showNotificationError(): void {
    this.disabled = false
    this.showNotification({
      title: this.$options.filters.translate(
        'インポートに失敗しました。恐れ入りますが、時間をおいて再度お試しください。'
      ),
      type: 'error'
    })
  }
}
