
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import GlobalHeader from '@/client/components-old/organisms/GlobalHeader'
import NotificationHandler from '@/client/components-old/organisms/NotificationHandler'
import PostDetailDialog from '@/client/components-old/organisms/PostDetailDialog'
import PostManagementHeader from '@/client/components-old/organisms/PostManagementHeader'
import PostManagementListView from '@/client/components-old/templates/PostManagementListView'
import PostManagementMonthView from '@/client/components-old/templates/PostManagementMonthView'
import PostManagementWeekView from '@/client/components-old/templates/PostManagementWeekView'
import { IState } from '@/client/store/modules/post_management'

const post_management = namespace('post_management')

@Component({
  name: 'PostManagement',
  components: {
    GlobalHeader,
    NotificationHandler,
    PostDetailDialog,
    PostManagementHeader,
    PostManagementListView,
    PostManagementMonthView,
    PostManagementWeekView
  }
})
export default class PostManagement extends Vue {
  @post_management.State('display_mode') display_mode!: IState['display_mode']
  @post_management.State('is_loading') is_loading!: IState['is_loading']
  @post_management.State('is_csv_loading') is_csv_loading!: IState['is_csv_loading']
  @post_management.State('is_publishing') is_publishing!: IState['is_publishing']
  @post_management.Action('createdPostManagementPage') createdPostManagementPage!: any
  @post_management.Action('destroyedPostManagementPage') destroyedPostManagementPage!: any
  @post_management.Action('changeGroupPostManagementPage') changeGroupPostManagementPage!: any
  @post_management.Action('openPostDetailDialog') openPostDetailDialog!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  get component_name() {
    if (this.display_mode === 'list') return PostManagementListView
    if (this.display_mode === 'week') return PostManagementWeekView
    if (this.display_mode === 'month') return PostManagementMonthView
    return ''
  }

  get is_full_loading() {
    return this.is_loading || this.is_csv_loading || this.is_publishing
  }

  get text_full_loading() {
    if (this.is_publishing) {
      return '投稿を公開しています。'
    }

    if (this.is_csv_loading) {
      return 'CSVファイルの作成中です。しばらくお待ちください。'
    }

    return 'データを取得中です。しばらくお待ちください。'
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  @Watch('group')
  async watchGroup() {
    await this.changeGroupPostManagementPage()
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.createdPostManagementPage({
      is_smartphone: this.$mq === 'sm',
      scheduled_datetime: this.$route.query.scheduled_datetime
    })

    if (this.$route.query.sns_post_id && this.$route.query.mode) {
      await this.openPostDetailDialog({
        sns_post_id: Number(this.$route.query.sns_post_id),
        mode: this.$route.query.mode,
        tab: 'preview'
      })
    }
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedPostManagementPage()
  }
}
