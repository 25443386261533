
import { Component, Prop, Vue } from 'vue-property-decorator'

import Message from '@/client/components-old/atoms/Message'

@Component({
  name: 'EmbedTwitter',
  components: {
    Message
  }
})
export default class EmbedTwitter extends Vue {
  @Prop({ type: String, required: true })
  tweetId!: string

  @Prop({ type: String, required: true })
  lang!: string

  $refs!: {
    embed: HTMLDivElement
  }

  is_exists = true

  async mounted() {
    await this.displayTweet()
  }

  /**
   * 投稿の表示
   */
  async displayTweet() {
    if (!Object.prototype.hasOwnProperty.call(window, 'twttr')) return

    await window.twttr.ready()

    const element = await window.twttr.widgets.createTweetEmbed(this.tweetId, this.$refs.embed, {
      lang: this.lang,
      width: '500'
    })

    this.is_exists = Boolean(element)
  }
}
