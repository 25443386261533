import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import { IRootState } from '@/client/store/global'
import { IState as IGroupSettingState } from '@/client/store/modules/group_setting'

const group_setting = namespace('group_setting')

@Component({
  name: 'GroupSettingBilling',
  components: {
    Message,
    Panel
  }
})
export default class GroupSettingBilling extends Vue {
  @State('user') user: IRootState['user']
  @group_setting.State('api_group_billings')
  api_group_billings: IGroupSettingState['api_group_billings']
  @group_setting.State('api_countries')
  api_countries: IGroupSettingState['api_countries']

  get country_name(): string {
    const country = this.api_countries.find(
      country => country.code === this.api_group_billings.country_code
    )

    if (!country) return ''

    return this.user.language === 'ja' ? country.name : country.name_en
  }
}
