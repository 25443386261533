
import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'

@Component({
  name: 'Gender',
  components: {
    Icon
  }
})
export default class Gender extends Vue {
  @Prop({ type: String, default: '' })
  male!: string

  @Prop({ type: String, default: '' })
  female!: string
}
