import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  TDeleteSnsAccountCategoryResponses,
  TGetSnsAccountCategoryParams,
  TGetSnsAccountCategoryResponses,
  TPostSnsAccountCategoryParams,
  TPostSnsAccountCategoryResponses,
  TPutSnsAccountCategoryChangeOrderNoParams,
  TPutSnsAccountCategoryChangeOrderNoResponses,
  TPutSnsAccountCategoryParams,
  TPutSnsAccountCategoryResponses,
  TSnsAccountCategory
} from '@/client/utils/api/sns_account_categories'

export interface IState {
  api_sns_account_categories: TSnsAccountCategory[]
}

const state: IState = {
  api_sns_account_categories: []
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  SET_API_SNS_ACCOUNT_CATEGORIES(state, payload: TSnsAccountCategory[]) {
    state.api_sns_account_categories = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * SNSアカウントタグ一覧の取得
   */
  async fetchSnsAccountCategories(context) {
    const params: TGetSnsAccountCategoryParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<TGetSnsAccountCategoryResponses>('sns_account_categories', {
      params
    })

    if (response.data) {
      context.commit('SET_API_SNS_ACCOUNT_CATEGORIES', response.data.data)
    }

    return response
  },

  /**
   * タグの作成
   */
  async createSnsAccountCategory(context, payload: { name: string }) {
    const params: TPostSnsAccountCategoryParams = {
      project_id: context.rootState.project.id,
      name: payload.name
    }

    const response = await API.post<TPostSnsAccountCategoryResponses>(
      'sns_account_categories',
      params
    )

    if (response.data) {
      const new_category = {
        id: response.data.data.id,
        name: payload.name,
        order_no: context.state.api_sns_account_categories.length
      }

      const api_sns_account_categories = [...context.state.api_sns_account_categories, new_category]

      context.commit('SET_API_SNS_ACCOUNT_CATEGORIES', api_sns_account_categories)
    }

    return response
  },

  /**
   * タグの更新
   */
  async updateSnsAccountCategory(context, payload: { category_id: number; name: string }) {
    const { category_id, name } = payload

    const params: TPutSnsAccountCategoryParams = { name }

    const response = await API.put<TPutSnsAccountCategoryResponses>(
      `sns_account_categories/${category_id}`,
      params
    )

    if (response.data) {
      const api_sns_account_categories = [...context.state.api_sns_account_categories].map(
        category => {
          if (category.id === category_id) {
            return { ...category, name }
          }

          return category
        }
      )

      context.commit('SET_API_SNS_ACCOUNT_CATEGORIES', api_sns_account_categories)
    } else if (response.data.error && response.data.error.type === 'NOT_EXISTS') {
      await context.dispatch('fetchSnsAccountCategories')
    }

    return response
  },

  /**
   * タグの順番を変更
   */
  async updateSnsAccountCategoryOrder(context, payload: TSnsAccountCategory[]) {
    context.commit('SET_API_SNS_ACCOUNT_CATEGORIES', payload)

    const category_ids = payload.map(category => category.id)

    const params: TPutSnsAccountCategoryChangeOrderNoParams = {
      project_id: context.rootState.project.id,
      category_ids: category_ids
    }

    const response = await API.put<TPutSnsAccountCategoryChangeOrderNoResponses>(
      'sns_account_categories/change_order_no',
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchSnsAccountCategories')
    }

    return response
  },

  /**
   * タグを削除
   */
  async removeSnsAccountCategory(context, payload: { category_id: number }) {
    const response = await API.delete<TDeleteSnsAccountCategoryResponses>(
      `sns_account_categories/${payload.category_id}`
    )

    if (response.data) {
      const api_sns_account_categories = [...context.state.api_sns_account_categories]
        .filter(category => category.id !== payload.category_id)
        .map((category, index) => ({ ...category, order_no: index + 1 }))

      context.commit('SET_API_SNS_ACCOUNT_CATEGORIES', api_sns_account_categories)
    } else if (response.data.error && response.data.error.type === 'NOT_EXISTS') {
      await context.dispatch('fetchSnsAccountCategories')
    }

    return response
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
