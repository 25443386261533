
import { Component, Prop, Vue } from 'vue-property-decorator'

import event from '@/client/utils/event'
import i18n from '@/client/utils/i18n'

import { parseHeatmapValue } from './utils'

type TChartHeatmapOptions = {
  metric_name: string
  x_categories: string[]
  y_categories: string[]
  series: [number, number, number | null][]
}

@Component({
  name: 'ChartHeatmap'
})
export default class ChartHeatmap extends Vue {
  @Prop({ type: Object, required: true })
  options: TChartHeatmapOptions

  is_show = false

  async mounted() {
    this.is_show = false
    await this.$nextTick()
    await event.delay(0)
    this.is_show = true
  }

  get chart_options() {
    return {
      chart: {
        type: 'heatmap',
        marginTop: 18,
        marginBottom: 38,
        plotBorderWidth: 1
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: this.options.x_categories
      },
      yAxis: {
        categories: this.options.y_categories,
        title: null,
        reversed: true
      },
      colorAxis: {
        min: 0,
        minColor: '#FFFFFF',
        maxColor: '#ED5565'
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 1,
        symbolHeight: 344
      },
      tooltip: {
        nullFormat: i18n.t('データが存在しません。'),
        pointFormatter() {
          return (
            this.series.yAxis.categories[this.y] +
            ' ' +
            this.series.xAxis.categories[this.x] +
            `<br>  ${this.series.name} ` +
            this.value.toLocaleString()
          )
        }
      },
      loading: false,
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      series: [
        {
          name: this.options.metric_name,
          borderWidth: 1,
          borderColor: '#DCDFE5',
          data: this.options.series,
          dataLabels: {
            enabled: true,
            color: '#676A6C',
            nullFormat: '-',
            style: {
              fontWeight: 'normal',
              textOutline: false
            },
            formatter() {
              return parseHeatmapValue(this.point.value)
            }
          }
        }
      ]
    }
  }
}
