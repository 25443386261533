
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'

@Component({
  name: 'SwitchSns',
  components: {
    Icon,
    Tooltip
  }
})
export default class SwitchSns extends Vue {
  @Prop({ type: String, required: true })
  value!: 'master' | 'facebook' | 'twitter' | 'instagram'

  @Prop({ type: Boolean, default: true })
  isMaster!: boolean

  @Prop({ type: Boolean, default: true })
  isFacebook!: boolean

  @Prop({ type: Boolean, default: true })
  isTwitter!: boolean

  @Prop({ type: Boolean, default: true })
  isInstagram!: boolean

  @Prop({ type: Boolean, default: true })
  isTiktok!: boolean

  @Prop({ type: Boolean, default: true })
  isTriangle!: boolean

  @Emit('input')
  onChange(val: string) {
    return val
  }

  /**
   * アクティブかどうか
   */
  isActive(value: string): boolean {
    return this.value === value
  }
}
