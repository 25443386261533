
import { computed, defineComponent, ref } from 'vue'
import draggable from 'vuedraggable'
import { mapActions, mapState } from 'vuex'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { IPostCategories } from '@/client/utils/api/post_categories'
import { getTranslateText as t } from '@/client/utils/filters'

export default defineComponent({
  name: 'PostTagsManagementDialog',
  components: {
    draggable,
    Button,
    Icon,
    Input,
    Dialog,
    Tooltip
  },
  setup() {
    const visible = ref(false)
    const is_create_mode = ref(false)
    const create_category_name = ref('')
    const edit_category_id = ref(0)
    const edit_category_name = ref('')

    const is_edit_mode = computed(() => edit_category_id.value !== 0)
    const is_editable = computed(() => !is_create_mode.value && !is_edit_mode.value)

    const hide = () => {
      visible.value = false
    }

    const startCreateMode = () => {
      is_create_mode.value = true
    }

    const cancelCreateMode = () => {
      is_create_mode.value = false
    }

    const checkEditMode = (category_id: number) => {
      return edit_category_id.value === category_id
    }

    const cancelEditMode = () => {
      edit_category_id.value = 0
      edit_category_name.value = ''
    }

    return {
      visible,
      create_category_name,
      edit_category_id,
      edit_category_name,
      is_create_mode,
      is_edit_mode,
      is_editable,
      hide,
      startCreateMode,
      cancelCreateMode,
      checkEditMode,
      cancelEditMode,
      t
    }
  },
  computed: {
    ...mapState('categories', ['api_post_categories'])
  },
  methods: {
    ...mapActions('categories', [
      'createCategory',
      'updateCategory',
      'removeCategory',
      'syncCategoryOrder'
    ]),
    ...mapActions('notification', ['showNotification']),

    /**
     * ダイアログの表示
     */
    open(): void {
      this.visible = true
      this.create_mode = false
      this.create_category_name = ''
      this.edit_category_id = 0
      this.edit_category_name = ''
    },

    /**
     * 作成フォームの送信
     */
    async submitCreateForm(): Promise<void> {
      this.create_category_name = this.create_category_name.trim()

      if (!this.create_category_name) return

      const result = await this.createCategory({ category_name: this.create_category_name })

      if (result.data) {
        this.create_category_name = ''

        this.cancelCreateMode()

        this.$emit('add-category')
        this.showNotification({ title: 'タグを追加しました。' })
      } else if (result.error && result.error.type === 'DUPLICATE') {
        this.showNotification({
          title: 'タグがすでに存在するため、他のタグ名を入力してください。',
          type: 'error'
        })
      } else {
        this.showNotification({ title: 'タグの追加に失敗しました。', type: 'error' })
      }
    },

    /**
     * 編集モードの開始
     */
    startEditMode(category_id: number): void {
      const category = this.api_post_categories.find(v => v.id === category_id)

      if (!category) return

      this.edit_category_id = category.id
      this.edit_category_name = category.name
    },

    /**
     * 編集フォームの送信
     */
    async submitEditForm(): Promise<void> {
      if (!this.edit_category_id) return

      this.edit_category_name = this.edit_category_name.trim()

      if (!this.edit_category_name) return

      const result = await this.updateCategory({
        category_id: this.edit_category_id,
        category_name: this.edit_category_name
      })

      if (result.data) {
        this.cancelEditMode()

        this.$emit('edit-category')
        return this.showNotification({ title: 'タグを変更しました。' })
      }

      if (result.error && result.error.type === 'DUPLICATE') {
        return this.showNotification({
          title: 'タグがすでに存在するため、他のタグ名を入力してください。',
          type: 'error'
        })
      }

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.cancelEditMode()

        return this.showNotification({ title: 'タグがすでに削除されています。', type: 'error' })
      }

      this.showNotification({ title: 'タグの変更に失敗しました。', type: 'error' })
    },

    /**
     * タグ削除のチェック
     */
    async checkRemoveCategory(category_id: number): Promise<void> {
      const category = this.api_post_categories.find(v => v.id === category_id)

      if (!category) return

      const line1 = this.$options.filters.translate(
        'この操作をすると投稿・分析機能で設定したタグも削除されます。'
      )
      const line2 = this.$options.filters.translate('本当に以下のタグを削除してよろしいですか？')
      const line3 = this.$options.filters.translate('この操作は取り消しできません。')
      const lines = `${line1}\n${line2}\n\n${category.name}\n\n${line3}`

      const confirm = window.confirm(lines)

      if (!confirm) return

      const result = await this.removeCategory({ category_id })

      if (result.data) {
        this.cancelEditMode()

        this.$emit('delete-category')
        return this.showNotification({
          title: this.$options.filters.translate('[[name]]を削除しました。', {
            name: category.name
          })
        })
      }

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.cancelEditMode()

        return this.showNotification({ title: 'タグがすでに削除されています。', type: 'error' })
      }

      this.showNotification({ title: 'タグの削除に失敗しました。', type: 'error' })
    },

    /**
     * ドラッグ＆ドロップで変更した場合
     */
    async changeDraggable(payload: IPostCategories[]): Promise<void> {
      const result = await this.syncCategoryOrder(payload)

      if (result.data) {
        return this.showNotification({ title: 'タグの順番を保存しました。' })
      }

      this.showNotification({ title: 'タグの順番の保存に失敗しました。', type: 'error' })
    }
  }
})
