import { render, staticRenderFns } from "./ApproveFlow.vue?vue&type=template&id=549c95ea&scoped=true"
import script from "./ApproveFlow.ts?vue&type=script&lang=ts&external"
export * from "./ApproveFlow.ts?vue&type=script&lang=ts&external"
import style0 from "./ApproveFlow.vue?vue&type=style&index=0&id=549c95ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "549c95ea",
  null
  
)

export default component.exports