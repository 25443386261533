
import moment from 'moment-timezone'
import twttr from 'twitter-text'
import { computed, defineComponent, PropType } from 'vue'

import { PostListSubItemBase } from '@/client/components/organisms/PostList'
import Badge from '@/client/components-old/atoms/Badge'
import PostMedia from '@/client/components-old/molecules/PostMedia'
import { ITwitterPost, ITwitterTweet } from '@/client/utils/api/posts'
import { getTranslateText as t } from '@/client/utils/filters'

import { htmlEscape } from './utils'

type TwitterSubItemType = 'tweet' | 'quote'

export default defineComponent({
  name: 'PostListSubItemTwitter',
  components: {
    PostListSubItemBase,
    Badge,
    PostMedia
  },
  props: {
    twPost: {
      type: Object as PropType<ITwitterPost | null>,
      default: null
    },
    tweet: {
      type: Object as PropType<ITwitterTweet | null>,
      default: null
    },
    type: {
      type: String as PropType<TwitterSubItemType>,
      required: true
    }
  },
  setup(props) {
    const datetime = computed(() => {
      if (!props.twPost && !props.tweet) return ''

      if (props.twPost && props.twPost.action_datetime) {
        return moment(props.twPost.action_datetime).format('lll')
      }

      if (props.tweet && props.tweet.created_at) {
        return moment(props.tweet.created_at).format('lll')
      }

      return ''
    })

    const screen_name = computed(() => {
      if (props.twPost) return props.twPost.account.screen_name

      return props.tweet ? props.tweet.name : ''
    })

    const username = computed(() => {
      if (props.twPost) return `@${props.twPost.account.username}`

      return props.tweet ? `@${props.tweet.screen_name}` : ''
    })

    const thumbnail_url = computed(() => {
      if (props.twPost) return props.twPost.thumbnail_url

      return props.tweet ? props.tweet.thumbnail_url : ''
    })

    const post_type = computed(() => {
      if (props.twPost) return props.twPost.type || 'none'

      return 'none'
    })

    const message = computed(() => {
      let message = ''

      if (props.twPost) message = props.twPost.message

      if (props.tweet) message = props.tweet.full_text

      if (!message) return ''

      message = message.replace(/\n\n\s*\n/g, '\n\n')

      message = htmlEscape(message)

      const entities = twttr.extractEntitiesWithIndices(message, {
        extractUrlsWithoutProtocol: true
      })

      message = twttr.autoLinkEntities(message, entities, {
        hashtagUrlBase: 'https://x.com/hashtag/',
        usernameUrlBase: 'https://x.com/',
        usernameIncludeSymbol: true,
        targetBlank: true
      })

      return message
    })

    const status_type = computed(() => {
      if (!props.twPost) return ''

      switch (props.twPost.status) {
        case 'approval':
          return 'warning'
        case 'draft':
          return 'default'
        case 'published':
          return 'success'
        case 'scheduled':
          return 'info'
        case 'reject':
          return 'inactive'
        case 'failure':
          return 'danger'
        default:
          return ''
      }
    })

    const status_text = computed(() => {
      if (!props.twPost) return ''

      switch (props.twPost.status) {
        case 'approval':
          return t('承認待ち')
        case 'draft':
          return t('下書き')
        case 'published':
          return t('公開')
        case 'scheduled':
          return t('予約')
        case 'reject':
          return t('差し戻し')
        case 'failure':
          return t('失敗')
        default:
          return ''
      }
    })

    return {
      datetime,
      username,
      screen_name,
      thumbnail_url,
      post_type,
      message,
      status_type,
      status_text
    }
  }
})
