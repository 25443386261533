import { getCurrentInstance } from 'vue'
import { Route } from 'vue-router'

export function useRoute() {
  const instance = getCurrentInstance()

  if (!instance) {
    console.error('You must use this function within the "setup()" method')
  }

  return instance?.proxy.$route as Route
}
