import { render, staticRenderFns } from "./PreviewInstagram.vue?vue&type=template&id=4fc72f91&scoped=true"
import script from "./PreviewInstagram.ts?vue&type=script&lang=ts&external"
export * from "./PreviewInstagram.ts?vue&type=script&lang=ts&external"
import style0 from "./PreviewInstagram.vue?vue&type=style&index=0&id=4fc72f91&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fc72f91",
  null
  
)

export default component.exports