
import { computed, defineComponent, PropType } from 'vue'

import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import { getTranslateText as t } from '@/client/utils/filters'

type User = {
  id: number
  name: string
  picture_url: string | null
}

export default defineComponent({
  name: 'UserList',
  components: {
    Account,
    Tooltip
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: Infinity
    },
    users: {
      type: Array as PropType<User[]>,
      default: () => []
    }
  },
  setup(props) {
    const show_users = computed(() => {
      if (props.users.length > props.max) {
        return props.users.slice(0, props.max)
      } else {
        return props.users
      }
    })

    const hidden_users = computed(() => {
      if (props.users.length > props.max) {
        return props.users.slice(props.max, props.users.length)
      } else {
        return []
      }
    })

    return {
      show_users,
      hidden_users,
      t
    }
  }
})
