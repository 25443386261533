import { ref } from 'vue'

import { useStore } from '@/client/hooks'
import API from '@/client/utils/api'

import { GetTwitterViralGroupByIdResponse, TwitterViralGroup } from '../api'

export function useTwitterViralGroup() {
  const store = useStore()

  const is_group_loading = ref(false)

  const api_group = ref<TwitterViralGroup>({
    max_collection_count: 0,
    free_collection_count: 0
  })

  /**
   * クチコミ推移のグループ情報を取得する
   */
  async function fetchTwitterViralGroupById() {
    is_group_loading.value = true

    const response = await API.get<GetTwitterViralGroupByIdResponse>(
      `twitter_viral/groups/${store.state.project.id}`
    )

    is_group_loading.value = false

    if (response.data.data) {
      api_group.value = response.data.data
    }
  }

  return {
    api_group,
    is_group_loading,
    fetchTwitterViralGroupById
  }
}
