import { render, staticRenderFns } from "./GroupSettingPostTemplate.vue?vue&type=template&id=0eda8303&scoped=true"
import script from "./GroupSettingPostTemplate.ts?vue&type=script&lang=ts&external"
export * from "./GroupSettingPostTemplate.ts?vue&type=script&lang=ts&external"
import style0 from "./GroupSettingPostTemplate.vue?vue&type=style&index=0&id=0eda8303&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eda8303",
  null
  
)

export default component.exports