import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import AnalyticsNodata from '@/client/components-old/organisms/AnalyticsNodata'
import { IState as IFacebookState } from '@/client/store/modules/facebook_comparison_analytics'

const facebook = namespace('facebook_comparison_analytics')

@Component({
  name: 'FacebookComparisonAnalyticsNodata',
  components: {
    Button,
    Message,
    Scroll,
    Icon,
    AnalyticsNodata
  }
})
export default class FacebookComparisonAnalyticsNodata extends Vue {
  @facebook.State('api_sns_comparison_accounts')
  api_sns_comparison_accounts: IFacebookState['api_sns_comparison_accounts']
  @facebook.State('account_ids') account_ids: IFacebookState['account_ids']

  get is_empty() {
    if (!this.account_ids.length) return false

    return this.account_ids.every(account_id => {
      const account = this.api_sns_comparison_accounts.find(v => v.id === account_id)

      return account ? Boolean(account.is_empty) : false
    })
  }

  @Emit('change-route')
  changeRoute(payload: any): any {
    return payload
  }
}
