
import { Component, Emit, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import ButtonCta from '@/client/components-old/atoms/ButtonCta'
import Panel from '@/client/components-old/atoms/Panel'
import { IRootState } from '@/client/store/global'

@Component({
  name: 'AnalyticsNodata',
  components: {
    ButtonCta,
    Panel
  }
})
export default class AnalyticsNodata extends Vue {
  @State('project_role') project_role!: IRootState['project_role']

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  /**
   * オペレーター招待画面に移動
   */
  moveGroupSettingOperator() {
    this.changeRoute({
      name: 'setting_group',
      query: { screen_name: 'operator' }
    })
  }
}
