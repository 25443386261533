import axios from 'axios'

export default axios.create({
  baseURL: '/api/v1',
  timeout: 60 * 1000,
  validateStatus: status => status >= 200 && status <= 504
})

export interface IAPIError {
  status: number
  type: string
  message: string
}

export function isRequestTimeout(error?: IAPIError): boolean {
  return error?.type === 'REQUEST_TIMEOUT'
}
