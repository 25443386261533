import { RouteConfig } from 'vue-router'

import TwitterComparisonAnalytics from '../pages/TwitterComparisonAnalytics.vue'

export const comparison_tw: RouteConfig = {
  component: TwitterComparisonAnalytics,
  path: '/comparison_tw',
  name: 'comparison_tw',
  meta: {
    auth: true,
    title: '競合比較 (X)',
    description: '複数アカウントの分析結果を同時に10アカウントまで比較できます。',
    color: 'warning',
    type: 'CHECK_COMPETITOR_ANALYSIS'
  }
}
