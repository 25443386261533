
import { saveAs } from 'file-saver'
import moment from 'moment-timezone'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import HelpLink from '@/client/components/molecules/HelpLink'
import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import { TrackingService } from '@/client/services'
import { IState } from '@/client/store/modules/post_management'
import API from '@/client/utils/api'
import event from '@/client/utils/event'
import { INSTAGRAM_STORY_URL_FORMAT, INSTAGRAM_URL_FORMAT } from '@/client/utils/regex'

const post_management = namespace('post_management')
const notification = namespace('notification')

type TMedia = {
  id: number
  image_url: string
  media_url: string
  is_loading: boolean
  is_download: boolean
  is_video: boolean
}

@Component({
  name: 'PostDetailInstagramPublish',
  components: {
    Button,
    HelpLink,
    Icon,
    Input,
    Message,
    Tooltip,
    Account
  }
})
export default class PostDetailInstagramPublish extends Vue {
  @post_management.State('api_post') api_post: IState['api_post']
  @post_management.Action('publishInstagramPost') publishInstagramPost
  @notification.Action('showNotification') showNotification

  medias: TMedia[] = []
  post_url = ''

  get account() {
    return this.api_post?.in_posts.length ? this.api_post.in_posts[0].account : null
  }

  get is_instagram_story() {
    return this.api_post?.type === 'story'
  }

  get is_url_error() {
    if (this.is_instagram_story) {
      return !!this.post_url && !INSTAGRAM_STORY_URL_FORMAT.test(this.post_url)
    }

    return !!this.post_url && !INSTAGRAM_URL_FORMAT.test(this.post_url)
  }

  get is_submit_disabled() {
    return this.is_url_error
  }

  get button_size() {
    if (this.$mq === 'sm') {
      return 'full'
    }

    return 'normal'
  }

  get step4_text() {
    const svg = `
      <svg fill="#262626" height="16" viewBox="0 0 48 48" width="16">
        <circle clip-rule="evenodd" cx="8" cy="36" fill-rule="evenodd" r="4.5" />
        <circle clip-rule="evenodd" cx="24" cy="36" fill-rule="evenodd" r="4.5" />
        <circle clip-rule="evenodd" cx="40" cy="36" fill-rule="evenodd" r="4.5" />
      </svg>
    `

    return this.$options.filters?.translate(
      '公開した投稿の右上 [[svg]] から [リンクをコピー] を選択し、URLをコピー',
      { svg }
    )
  }

  get instagram_comment_enabled_text() {
    const svg = `
      <svg fill="#262626" height="16" viewBox="0 0 48 48" width="16">
        <circle clip-rule="evenodd" cx="8" cy="36" fill-rule="evenodd" r="4.5" />
        <circle clip-rule="evenodd" cx="24" cy="36" fill-rule="evenodd" r="4.5" />
        <circle clip-rule="evenodd" cx="40" cy="36" fill-rule="evenodd" r="4.5" />
      </svg>
    `

    return this.$options.filters?.translate(
      '公開した投稿の右上 [[svg]] から [コメントをオフにする] を選択し、コメントを制限',
      { svg }
    )
  }

  get has_first_comment_message() {
    return this.api_post?.in_posts.length && this.api_post.in_posts[0].first_comment_message
  }

  get is_instagram_comment_disabled() {
    if (this.api_post?.in_posts.length === 0) return false

    return !this.api_post?.in_posts[0].comment_enabled
  }

  /**
   * ダイアログの表示
   */
  created() {
    this.getMedias()
  }

  /**
   * メディアを取得
   */
  getMedias() {
    this.medias = []

    if (!this.api_post) {
      return
    }

    if (this.api_post.post_images.length) {
      this.medias.push(
        ...this.api_post.post_images.map<TMedia>(v => ({
          id: v.sequence_no,
          image_url: v.image_url,
          media_url: v.image_url,
          is_loading: false,
          is_download: false,
          is_video: false
        }))
      )
    }

    if (this.api_post.post_videos.length) {
      this.medias.push(
        ...this.api_post.post_videos.map<TMedia>(v => ({
          id: v.sequence_no,
          image_url: v.video_thumbnail ?? '',
          media_url: v.video_url,
          is_loading: false,
          is_download: false,
          is_video: true
        }))
      )
    }

    this.medias.sort((a, b) => a.id - b.id)
  }

  /**
   * スタイルを取得
   */
  getStyle(url: string) {
    return {
      backgroundImage: `url(${url})`
    }
  }

  /**
   * 投稿内容をクリップボードにコピー
   */
  copyMessage() {
    event.copy(this.api_post?.message ?? '')

    this.showNotification({ title: 'クリップボードにコピーしました。' })

    TrackingService.sendEvent('click:Instagram公開手続(ダイアログ)|投稿内容コピー')
  }

  /**
   * 最初のコメント内容をクリップボードにコピー
   */
  copyFirstCommentMessage() {
    event.copy(this.api_post?.in_posts?.[0]?.first_comment_message ?? '')

    this.showNotification({ title: 'クリップボードにコピーしました。' })
  }

  /**
   * メディアをダウンロード
   */
  async downloadMedia(id: number) {
    const media = this.medias.find(v => v.id === id)

    if (!media) return

    const url = new URL(media.media_url)

    const datetime = moment(this.api_post?.action_datetime).format('YYYY-MM-DD-hh-ss')
    const sequence = String(media.id).padStart(2, '0')
    const username = this.account ? this.account.username : ''
    const extension = url.pathname.split('.').pop()

    const filename = `${datetime}_${sequence}_${username}.${extension}`

    try {
      const paths = url.pathname.split('/')

      const params = {
        bucket: paths.filter((v, i) => i === 1).join('/'),
        key: paths.filter((v, i) => i > 1).join('/')
      }

      media.is_loading = true

      const response = await API.get<Blob>('downloads', { params, responseType: 'blob' })

      media.is_loading = false

      saveAs(response.data, filename)

      media.is_download = true
    } catch (err) {
      this.showNotification({
        title: this.$options.filters?.translate('[[filename]] のダウンロードに失敗しました。', {
          filename
        }),
        type: 'error'
      })

      throw err
    }
  }

  /**
   * Instagram投稿の公開
   */
  async publishInstagram() {
    if (!this.api_post) return

    TrackingService.sendEvent('click:Instagram公開手続(ダイアログ)|全て完了')

    const result = await this.publishInstagramPost({
      sns_post_id: this.api_post.id,
      post_url: this.post_url
    })

    if (result && result.data) {
      this.showNotification({ title: '公開手続が完了しました。' })
      this.$emit('publish-instagram-post')
      return
    }

    if (result && result.error) {
      if (result.error.type === 'INSTAGRAM_POST_NOT_EXISTS') {
        return this.showNotification({
          title: '公開手続に失敗しました。URLに一致する投稿がありません。',
          type: 'error'
        })
      }

      if (result.error.type === 'TOKEN_EXPIRED') {
        return this.showNotification({
          title: '公開手続に失敗しました。アクセストークンが失効しています。',
          type: 'error'
        })
      }
    }

    return this.showNotification({
      title: '公開手続に失敗しました。時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  @Emit('close-dialog')
  closeDialog(payload: any) {
    TrackingService.sendEvent('click:Instagram公開手続(ダイアログ)|キャンセル')

    return payload
  }
}
