
import { Component, Emit, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import LoginForm from '@/client/components-old/organisms/LoginForm'
import LoginTwoStepForm from '@/client/components-old/organisms/LoginTwoStepForm'
import { IRootState } from '@/client/store/global'

@Component({
  name: 'Login',
  components: {
    ButtonLink,
    LoginForm,
    LoginTwoStepForm
  }
})
export default class Login extends Vue {
  @State('user') user!: IRootState['user']
  @Action('fetchLanguage') fetchLanguage: any
  @Action('forceReRender') forceReRender: any

  email = ''

  get disabled_ja() {
    return this.user.language === 'ja'
  }

  get disabled_en() {
    return this.user.language === 'en'
  }

  get color_ja() {
    return this.disabled_ja ? 'default' : 'info'
  }

  get color_en() {
    return this.disabled_en ? 'default' : 'info'
  }

  get redirect() {
    return Array.isArray(this.$route.query.redirect)
      ? this.$route.query.redirect[0]
      : this.$route.query.redirect
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  /**
   * ログインに変更
   */
  changeLogin(): void {
    this.email = ''
  }

  /**
   * 2段階認証に変更
   */
  changeTwoFactor(email: string): void {
    this.email = email
  }

  /**
   * トライアル登録に移動
   */
  moveSignup(): void {
    this.changeRoute({ name: 'signup' })
  }

  /**
   * 言語の変更
   */
  async changeLanguage(language: 'ja' | 'en') {
    await this.fetchLanguage(language)
    await this.forceReRender()
  }
}
