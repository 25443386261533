import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/facebook_analytics'
import * as calculation from '@/client/components-old/_utils/calculation'
import * as facebook_insight from '@/client/components-old/_utils/facebook_insight'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartVertical from '@/client/components-old/molecules/ChartVertical'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as IFacebookState } from '@/client/store/modules/facebook_analytics'
import csv from '@/client/utils/csv'
import { getTranslateText as t } from '@/client/utils/filters'

type TFacebookAnalyticsChartVerticalOptions = {
  data_type: 'daily_data' | 'post_data'
  metric: string
  interval:
    | '1_day'
    | '7_day'
    | '14_day'
    | '28_day'
    | '1_month'
    | '3_month'
    | '6_month'
    | 'weekday'
    | 'hour'
  unit: 'total' | 'average' | 'major' | 'past_week'
  post_types?: string[]
  post_tags?: number[]
  is_change_interval?: boolean
}

type TFacebookAnalyticsChartVerticalSeries = {
  name: string
  metric: string
  data?: number[]
  index?: number
}

const analytics = namespace('analytics')
const facebook = namespace('facebook_analytics')
const categories = namespace('categories')

@Component({
  name: 'FacebookAnalyticsChartVertical',
  components: {
    AnalyticsPanel,
    AnalyticsPanelHeader,
    ChartVertical,
    ButtonGroup,
    Icon,
    Tooltip
  }
})
export default class FacebookAnalyticsChartVertical extends Vue {
  @State('user') user: IRootState['user']
  @categories.State('api_post_categories')
  api_post_categories: ICategoriesState['api_post_categories']
  @categories.State('api_category_posts') api_category_posts: ICategoriesState['api_category_posts']
  @facebook.State('api_accounts') daily_data: IFacebookState['api_accounts']
  @facebook.State('api_posts') post_data: IFacebookState['api_posts']
  @facebook.State('screen_name') screen_name: IFacebookState['screen_name']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TFacebookAnalyticsChartVerticalOptions

  @Prop({ type: Boolean, default: false })
  isPreview: boolean

  @Prop({ type: Boolean, default: false })
  isEdit: boolean

  interval = '1_day'

  @Watch('options', { immediate: true, deep: true })
  changeOptions() {
    this.interval = this.options.interval
  }

  get analytics(): any[] {
    const { data_type, post_types, post_tags } = this.options

    switch (data_type) {
      case 'daily_data':
        return facebook_insight.filterDailyData(
          this.daily_data,
          this.post_data,
          this.options.metric
        )
      case 'post_data': {
        let temp_insights = facebook_insight.filterPostData(this.post_data, this.options.metric)

        if (post_types?.length) {
          temp_insights = temp_insights.filter(post => post_types.includes(post.type))
        }
        if (post_tags?.length) {
          temp_insights = temp_insights.filter(post => {
            const post_tag_ids = this.getSnsPostTags(post.post_id)
            return post_tag_ids.some(post_tag_id => post_tags.includes(post_tag_id))
          })
        }
        return temp_insights
      }
      default:
        return []
    }
  }

  get posts_date() {
    const posts = facebook_insight.filterPostData(this.post_data, this.options.metric)
    return posts.map(post => moment(post.created_time).format('YYYY-MM-DD'))
  }

  get is_data() {
    return this.analytics.length > 0
  }

  get is_download() {
    return this.is_data && !this.isPreview
  }

  get is_edit() {
    return !this.isPreview && this.isEdit
  }

  get intervals() {
    if (this.user.language === 'en') {
      return [
        { text: '1D', value: '1_day' },
        { text: '7D', value: '7_day' },
        { text: '14D', value: '14_day' },
        { text: '28D', value: '28_day' },
        { text: '1M', value: '1_month' },
        { text: '3M', value: '3_month' },
        { text: '6M', value: '6_month' }
      ]
    }

    return [
      { text: '1日', value: '1_day' },
      { text: '7日', value: '7_day' },
      { text: '14日', value: '14_day' },
      { text: '28日', value: '28_day' },
      { text: '1ヶ月', value: '1_month' },
      { text: '3ヶ月', value: '3_month' },
      { text: '6ヶ月', value: '6_month' }
    ]
  }

  get metrics() {
    switch (this.options.data_type) {
      case 'daily_data':
        return constants.CHANGE_CHART_DAILY_DATA_METRICS
      case 'post_data':
        return constants.CHANGE_CHART_POST_DATA_METRICS
      default:
        return []
    }
  }

  get metric_name() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    const metric_name = metric ? t(metric.text) : ''

    const unit = constants.UNITS.find(unit => unit.value === this.options.unit)
    const unit_name = unit ? t(unit.text) : ''
    const unit_value = unit ? unit.value : ''

    if (
      unit_name === '' ||
      unit_value === '' ||
      unit_value === 'major' ||
      unit_value === 'past_week' ||
      this.options.metric === 'page_fans' ||
      this.options.metric === 'post_video_avg_time_watched'
    ) {
      return metric_name
    }

    return `${metric_name} [${unit_name}]`
  }

  get metric_tooltip() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? t(metric.tooltip) : ''
  }

  get show_post_types() {
    if (this.options.data_type !== 'post_data') return []

    return ['status', 'photo', 'link', 'video', 'shared'].filter(v =>
      this.options.post_types.includes(v)
    )
  }

  get post_tags_names() {
    if (this.options.data_type !== 'post_data') return []

    return this.getPostTagNames(this.options.post_tags)
  }

  get panel_type() {
    const data_type = constants.DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }

  get is_button_group() {
    return this.options.is_change_interval && this.is_data
  }

  get time_range() {
    if (this.interval === 'weekday') {
      return Array.from(Array(7).keys())
    }

    if (this.interval === 'hour') {
      return Array.from(Array(25).keys())
    }

    const time_range = []
    const add_number = Number(this.interval.match(/\d+/g))

    let time_end
    let time_start = moment(moment(this.start_date).format('YYYY-MM-DD 00:00:00')).unix()

    const end_date_unix = moment(moment(this.end_date).format('YYYY-MM-DD 23:59:59')).unix()

    while (time_start < end_date_unix) {
      if (
        this.interval === '1_day' ||
        this.interval === '7_day' ||
        this.interval === '14_day' ||
        this.interval === '28_day'
      ) {
        // 日数のunix値: 日数 x 一日のunix値 (一日のunix値: 86400)
        time_end = time_start + add_number * 86400 - 1
      } else if (
        this.interval === '1_month' ||
        this.interval === '3_month' ||
        this.interval === '6_month'
      ) {
        time_end = moment.unix(time_start).add(add_number, 'months').startOf('month').unix() - 1
      }

      if (time_end > end_date_unix) {
        time_end = end_date_unix
      }

      time_range.push({
        start: time_start,
        end: time_end
      })

      time_start = time_end + 1
    }

    return time_range
  }

  get categories() {
    switch (this.interval) {
      case 'weekday':
        return [
          t('日曜日'),
          t('月曜日'),
          t('火曜日'),
          t('水曜日'),
          t('木曜日'),
          t('金曜日'),
          t('土曜日')
        ]
      case 'hour':
        return [
          t('深夜'),
          '1am',
          '2am',
          '3am',
          '4am',
          '5am',
          '6am',
          '7am',
          '8am',
          '9am',
          '10am',
          '11am',
          t('正午'),
          '1pm',
          '2pm',
          '3pm',
          '4pm',
          '5pm',
          '6pm',
          '7pm',
          '8pm',
          '9pm',
          '10pm',
          '11pm',
          t('深夜')
        ]
      case '1_day':
        return this.time_range.map(time => {
          return moment.unix(time.start).format('YYYY/MM/DD')
        })
      case '7_day':
      case '14_day':
      case '28_day':
        return this.time_range.map(time => {
          return `${moment.unix(time.start).format('M/D')}-${moment.unix(time.end).format('M/D')}`
        })
      case '1_month':
      case '3_month':
      case '6_month':
        return this.time_range.map(time => {
          const start = moment.unix(time.start).month() + 1 + t('月')
          const end = moment.unix(time.end).month() + 1 + t('月')

          return start !== end ? `${start}-${end}` : start
        })
      default:
        return this.time_range
    }
  }

  get chart_options() {
    const blue_color = '#1c84c6'
    const red_color = '#ed5565'
    const green_color = '#2ec881'

    switch (this.options.metric) {
      case 'page_fans_online':
        return {
          chart_type: 'area',
          y_label: '',
          display_legend: false,
          display_value: false,
          display_line: false,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }

      case 'page_fans_online_per_day':
        return {
          chart_type: 'column',
          y_label: '',
          display_legend: false,
          display_value: true,
          display_line: false,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }

      case 'page_fan_up_down':
        return {
          chart_type: 'column',
          y_label: t('人数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [green_color, blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'いいね！(広告)',
              metric: 'page_fan_adds_by_paid_unique',
              index: 2
            },
            {
              name: 'いいね！(オーガニック)',
              metric: 'page_fan_adds_by_non_paid_unique',
              index: 1
            },
            {
              name: 'いいね！取消',
              metric: 'page_fan_removes_unique',
              index: 0
            }
          ])
        }

      case 'reactions_feed':
        return {
          chart_type: 'column',
          y_label: t('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'いいね!',
              metric: 'likes_feed',
              index: 2
            },
            {
              name: 'コメント',
              metric: 'comments_feed',
              index: 1
            },
            {
              name: 'シェア',
              metric: 'shares_feed',
              index: 0
            }
          ])
        }

      case 'reactions_viral':
        return {
          chart_type: 'column',
          y_label: t('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'いいね!',
              metric: 'likes_viral',
              index: 2
            },
            {
              name: 'コメント',
              metric: 'comments_viral',
              index: 1
            },
            {
              name: 'シェア',
              metric: 'shares_viral',
              index: 0
            }
          ])
        }

      case 'page_posts_impressions_unique':
        return {
          chart_type: 'column',
          y_label: t('人数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'オーガニック',
              metric: 'page_posts_impressions_organic_unique',
              index: 2
            },
            {
              name: 'クチコミ',
              metric: 'page_posts_impressions_viral_unique',
              index: 1
            },
            {
              name: '広告',
              metric: 'page_posts_impressions_paid_unique',
              index: 0
            }
          ])
        }

      case 'page_posts_impressions':
        return {
          chart_type: 'column',
          y_label: t('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'オーガニック',
              metric: 'page_posts_impressions_organic',
              index: 2
            },
            {
              name: 'クチコミ',
              metric: 'page_posts_impressions_viral',
              index: 1
            },
            {
              name: '広告',
              metric: 'page_posts_impressions_paid',
              index: 0
            }
          ])
        }

      case 'page_impressions_unique':
        return {
          chart_type: 'column',
          y_label: t('人数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'オーガニック',
              metric: 'page_impressions_organic_unique',
              index: 2
            },
            {
              name: 'クチコミ',
              metric: 'page_impressions_viral_unique',
              index: 1
            },
            {
              name: '広告',
              metric: 'page_impressions_paid_unique',
              index: 0
            }
          ])
        }

      case 'post_impressions_unique':
        return {
          chart_type: 'column',
          y_label: t('人数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'オーガニック',
              metric: 'post_impressions_organic_unique',
              index: 2
            },
            {
              name: 'クチコミ',
              metric: 'post_impressions_viral_unique',
              index: 1
            },
            {
              name: '広告',
              metric: 'post_impressions_paid_unique',
              index: 0
            }
          ])
        }

      case 'page_impressions':
        return {
          chart_type: 'column',
          y_label: t('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'オーガニック',
              metric: 'page_impressions_organic',
              index: 2
            },
            {
              name: 'クチコミ',
              metric: 'page_impressions_viral',
              index: 1
            },
            {
              name: '広告',
              metric: 'page_impressions_paid',
              index: 0
            }
          ])
        }

      case 'post_impressions':
        return {
          chart_type: 'column',
          y_label: t('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'オーガニック',
              metric: 'post_impressions_organic',
              index: 2
            },
            {
              name: 'クチコミ',
              metric: 'post_impressions_viral',
              index: 1
            },
            {
              name: '広告',
              metric: 'post_impressions_paid',
              index: 0
            }
          ])
        }

      case 'page_positive_feedback_by_type_unique_reaction':
        return {
          chart_type: 'column',
          y_label: t('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'いいね！',
              metric: 'page_positive_feedback_by_type_unique_like',
              index: 2
            },
            {
              name: 'コメント',
              metric: 'page_positive_feedback_by_type_unique_comment',
              index: 1
            },
            {
              name: 'シェア',
              metric: 'page_positive_feedback_by_type_unique_share',
              index: 0
            }
          ])
        }

      case 'reactions':
        return {
          chart_type: 'column',
          y_label: t('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'いいね！',
              metric: 'likes_insights',
              index: 2
            },
            {
              name: 'コメント',
              metric: 'comments_insights',
              index: 1
            },
            {
              name: 'シェア',
              metric: 'shares_insights',
              index: 0
            }
          ])
        }

      case 'post_video_views':
        return {
          chart_type: 'column',
          y_label: t('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'オーガニック',
              metric: 'post_video_views_organic',
              index: 1
            },
            {
              name: '広告',
              metric: 'post_video_views_paid',
              index: 0
            }
          ])
        }

      case 'post_video_views_unique':
        return {
          chart_type: 'column',
          y_label: t('人数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'オーガニック',
              metric: 'post_video_views_organic_unique',
              index: 1
            },
            {
              name: '広告',
              metric: 'post_video_views_paid_unique',
              index: 0
            }
          ])
        }

      case 'post_video_complete_views':
        return {
          chart_type: 'column',
          y_label: t('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'オーガニック',
              metric: 'post_video_complete_views_organic',
              index: 1
            },
            {
              name: '広告',
              metric: 'post_video_complete_views_paid',
              index: 0
            }
          ])
        }

      case 'post_video_complete_views_unique':
        return {
          chart_type: 'column',
          y_label: t('人数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'オーガニック',
              metric: 'post_video_complete_views_organic_unique',
              index: 1
            },
            {
              name: '広告',
              metric: 'post_video_complete_views_paid_unique',
              index: 0
            }
          ])
        }

      case 'page_video_views':
        return {
          chart_type: 'column',
          y_label: t('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'オーガニック',
              metric: 'page_video_views_organic',
              index: 1
            },
            {
              name: '広告',
              metric: 'page_video_views_paid',
              index: 0
            }
          ])
        }

      case 'page_video_view_time':
      case 'post_video_avg_time_watched':
        return {
          chart_type: 'column',
          y_label: t('秒数'),
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }

      case 'page_fans':
        return {
          chart_type: 'line',
          y_label: t('人数'),
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }

      case 'page_impressions_organic_unique':
      case 'page_impressions_viral_unique':
      case 'page_impressions_paid_unique':
      case 'page_posts_impressions_organic_unique':
      case 'page_posts_impressions_viral_unique':
      case 'page_posts_impressions_paid_unique':
      case 'post_video_views_organic_unique':
      case 'post_video_views_paid_unique':
      case 'post_video_complete_views_organic_unique':
      case 'post_video_complete_views_paid_unique':
      case 'page_video_views_unique':
      case 'page_video_views_10s_unique':
      case 'page_video_complete_views_30s_unique':
      case 'post_consumptions_unique':
      case 'post_impressions_fan_unique':
      case 'post_impressions_organic_unique':
      case 'post_impressions_viral_unique':
      case 'post_impressions_paid_unique':
        return {
          chart_type: 'column',
          y_label: t('人数'),
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }

      case 'reactions_rate':
      case 'post_engaged_users_rate':
      case 'post_consumptions_unique_rate':
      case 'post_impressions_fan_unique_rate':
      case 'post_engaged_fan_rate':
        return {
          chart_type: 'line',
          y_label: '%',
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }

      default:
        return {
          chart_type: 'column',
          y_label: t('回数'),
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }
    }
  }

  get display_type() {
    return `chart_vertical_${this.chart_options.chart_type}`
  }

  @Emit('edit')
  onEdit() {
    return this.options
  }

  @Emit('delete')
  onDelete(event) {
    return event
  }

  getPostTagNames(post_tag_ids?: number[]): string[] {
    if (post_tag_ids === undefined || !post_tag_ids.length) return []

    return (
      this.api_post_categories.filter(tag => post_tag_ids.includes(tag.id)).map(v => v.name) || []
    )
  }

  getSnsPostTags(post_id: string): number[] {
    const post_tag = this.api_category_posts.find(post_tag => post_tag.post_id === post_id)
    return post_tag ? post_tag.category_ids : []
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    TrackingService.sendEvent(
      `click:自社分析(FB)> ${this.screen_name} | ${this.options.metric} : CSVダウンロード`
    )

    const fields = [t('期間'), t(this.metric_name)]

    if (this.chart_options.series.length > 1) {
      fields.push(...this.chart_options.series.map(element => element.name))
    }

    const data = this.categories.map((time, key) => {
      if (this.chart_options.series.length === 1) {
        return [time, ...this.chart_options.series.map(element => element.data[key])]
      }

      // 複数ターゲットの場合、合計を計算
      const total = calculation.addition(
        this.chart_options.series.map(element => element.data[key])
      )

      return [
        time,
        total ? total : null,
        ...this.chart_options.series.map(element => element.data[key])
      ]
    })

    const csv_data = { fields, data }

    const component_name = t('グラフ')

    const csv_filename = [component_name, this.metric_name, this.start_date, this.end_date].join(
      '_'
    )

    csv.download(csv_data, csv_filename)
  }

  /**
   * グラフのシリーズデータを取得
   * @param {TFacebookAnalyticsChartVerticalSeries[]} series
   * @returns {TFacebookAnalyticsChartVerticalSeries[]} シリーズデータ
   */
  getSeries(
    series: TFacebookAnalyticsChartVerticalSeries[]
  ): TFacebookAnalyticsChartVerticalSeries[] {
    series = series.map(element => {
      return {
        name: t(element.name),
        metric: element.metric,
        data: [],
        index: element.index
      }
    })

    this.time_range.forEach(time => {
      const analytics = facebook_insight.getDataByMetric(
        this.analytics,
        this.options.metric,
        this.user.timezone,
        time
      )

      // 該当する期間のデータがない場合、nullにセット
      if (analytics === null || analytics.length === 0) {
        series = series.map(element => {
          element.data.push(null)
          return element
        })
        return
      }

      series.forEach(element => {
        // 期間と指標に該当する数字を計算
        switch (element.metric) {
          case 'page_fans_online': {
            // 7日分の合計値なので1日の平均値にする
            const fans_online = analytics.map(value => Math.round(value / 7))

            // 0時の値を後ろにも入れる
            fans_online.push(fans_online[0])

            element.data = fans_online
            break
          }

          case 'page_fans_online_per_day':
            element.data = Object.keys(analytics).map(key => analytics[key])
            break

          case 'page_video_view_time':
          case 'post_video_avg_time_watched': {
            const millisecond = facebook_insight.getMetricTotal(analytics, element.metric)

            element.data.push(calculation.millisecondToSecond(millisecond))
            break
          }

          // 共通計算処理
          default: {
            const total = facebook_insight.getMetricTotal(analytics, element.metric)
            // 期間合計の場合、戻す
            if (this.options.unit === 'total') {
              element.data.push(total)
              break
            }

            element.data.push(facebook_insight.getMetricAverage(analytics, element.metric))
            break
          }
        }
      })
    })

    return series
  }

  /**
   * 期間選択の変更
   */
  changeInteval(value: string) {
    TrackingService.sendEvent(
      `select:自社分析(FB)> ${this.screen_name} | ${this.options.metric} : ${value}`
    )
  }
}
