import { Component, Prop, Vue } from 'vue-property-decorator'

import event from '@/client/utils/event'

type TChartDonutHalfOptions = {
  title: string
  colors: string[]
  data: [string, number]
  total?: number
  pointFormat?: string // ? ライブラリの名称と命名規則を揃えている
}

@Component({
  name: 'ChartDonutHalf'
})
export default class ChartDonutHalf extends Vue {
  @Prop({ type: Object, required: true })
  options: TChartDonutHalfOptions

  is_show = false

  async mounted() {
    this.is_show = false
    await this.$nextTick()
    await event.delay(0)
    this.is_show = true
  }

  get title() {
    let total = 0

    if (this.options.total) {
      total = this.options.total
    } else {
      this.options.data.forEach(value => {
        total += value[1]
      })
    }

    return this.options.title + '<br>' + total.toLocaleString()
  }

  get chart_options() {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: 285,
        marginTop: -230,
        marginBottom: -100
      },
      title: {
        text: this.title,
        align: 'center',
        verticalAlign: 'middle',
        y: 65
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        shared: true,
        borderColor: '#b5b5b5',
        style: {
          fontSize: '13px'
        },
        pointFormat: this.options.pointFormat ?? '<b>{point.y}</b>({point.percentage:.2f}%)<br>'
      },
      plotOptions: {
        series: {
          animation: false
        },
        pie: {
          dataLabels: {
            enabled: true
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '60%'
        }
      },
      colors: this.options.colors,
      series: [
        {
          type: 'pie',
          innerSize: '60%',
          animation: false,
          data: this.options.data
        }
      ]
    }
  }
}
