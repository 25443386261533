
import { Component, Emit, Vue } from 'vue-property-decorator'

import Button from '@/client/components-old/atoms/Button'

@Component({
  name: 'SignupComplete',
  components: {
    Button
  }
})
export default class SignupComplete extends Vue {
  @Emit('change-route')
  changeRoute(payload: any): any {
    return payload
  }

  /**
   * 利用を開始
   */
  startUsing() {
    this.changeRoute({ name: 'dashboard' })
  }
}
