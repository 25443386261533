import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import * as validate from '@/client/utils/validate'

type TPosition = {
  x: number
  y: number
}

@Component({
  name: 'Collage',
  components: {
    Icon,
    Tooltip
  }
})
export default class Collage extends Vue {
  @Prop({ type: Number, required: true })
  width: number

  @Prop({ type: Number, required: true })
  height: number

  $refs: {
    add_image: HTMLInputElement
    canvas: HTMLCanvasElement
  }

  image: HTMLImageElement = null

  image_scale: number = null

  image_position: TPosition = {
    x: 0,
    y: 0
  }

  mouse_position: TPosition = {
    x: 0,
    y: 0
  }

  is_dragging = false

  // ipadを使う場合
  distanceBetweenTouches = 0

  get accept() {
    return validate.IMAGE_TYPES
  }

  get is_edit_mode() {
    return this.image !== null
  }

  get canvas_width() {
    return this.width
  }

  get canvas_height() {
    return this.height
  }

  get image_width() {
    return this.image.width * this.image_scale
  }

  get image_height() {
    return this.image.height * this.image_scale
  }

  /**
   * 画像を追加
   * @returns {void}
   */
  openFile(): void {
    if (this.is_edit_mode) {
      return
    }

    this.$refs.add_image.click()
  }

  /**
   * 画像を追加
   * @returns {void}
   */
  async addImage(): Promise<void> {
    const input = this.$refs.add_image

    if (!input.files.length) return

    if (this.accept.indexOf(input.files[0].type) === -1) return

    try {
      const file = await this.loadFile(input.files[0])
      this.image = await this.loadImage(file)
    } catch (e) {
      return
    }

    this.image_scale = Math.max(
      this.canvas_height / this.image.height,
      this.canvas_width / this.image.width
    )

    this.image_position = {
      x: (this.canvas_width - this.image_width) / 2,
      y: (this.canvas_height - this.image_height) / 2
    }

    this.drawImage()
  }

  /**
   * ファイル読み込み
   * @param {any} file 画像
   * @returns {Promise<FileReader>} ファイル
   */
  loadFile(file: any): Promise<FileReader> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()

      fileReader.onload = (e: any) => {
        resolve(e.target.result)
      }

      fileReader.onerror = e => {
        reject(e)
      }

      fileReader.readAsDataURL(file)
    })
  }

  /**
   * 画像読み込み
   * @param {any} file 画像
   * @returns {Promise<HTMLImageElement>} DOM
   */
  loadImage(file: any): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image()

      image.onload = () => {
        resolve(image)
      }

      image.onerror = e => {
        reject(e)
      }

      image.src = file
    })
  }

  /**
   * 画像をズームイン
   * @returns {void}
   */
  zoomIn(): void {
    if (!this.is_edit_mode) {
      return
    }

    this.resizeImage(this.image_scale * 0.1)
  }

  /**
   * 画像をズームアウト
   * @returns {void}
   */
  zoomOut(): void {
    if (!this.is_edit_mode) {
      return
    }

    this.resizeImage(-this.image_scale / 11)
  }

  /**
   * mousedownイベント
   * @param {any} event
   * @returns {void}
   */
  mouseDown(event): void {
    if (!this.is_edit_mode) {
      return
    }

    this.mouse_position = this.getMousePosition(event)
    this.is_dragging = true
  }

  /**
   * mouseupイベント
   * @param {any} event
   * @returns {void}
   */
  mouseUp(event): void {
    if (!this.is_edit_mode) {
      return
    }

    if (!event.changedTouches || event.changedTouches.length < 2) {
      this.is_dragging = false
    }

    this.distanceBetweenTouches = 0
  }

  /**
   * 画像を移動
   * @param {any} event
   * @returns {void}
   */
  mouseWheel(event) {
    if (!this.is_edit_mode) {
      return
    }

    event.preventDefault()

    let delta = 0

    // IE Chrome safari
    if (event.wheelDelta) {
      delta = event.wheelDelta / 10000
    } else if (event.detail) {
      // firefox
      delta = -event.detail / 3
    }

    this.resizeImage(delta)
  }

  /**
   * 画像を移動
   * @param {any} event
   * @returns {void}
   */
  moveImage(event): void {
    if (!this.is_edit_mode) {
      return
    }

    event.preventDefault()

    // カーソルの位置を取得
    const mouse_position = this.getMousePosition(event)

    // ドラッグ場合、画像の位置を調整する
    if (
      this.is_dragging === true &&
      (!event.changedTouches || (event.changedTouches && event.changedTouches.length < 2))
    ) {
      this.image_position.x += mouse_position.x - this.mouse_position.x
      this.image_position.y += mouse_position.y - this.mouse_position.y

      this.drawImage()
    } else if (event.changedTouches && event.changedTouches.length >= 2) {
      // ピンチズーム
      const touchPosition = [
        this.getMousePosition(event.changedTouches[0]),
        this.getMousePosition(event.changedTouches[1])
      ]

      const deltaX = touchPosition[1].x - touchPosition[0].x
      const deltaY = touchPosition[1].y - touchPosition[0].y
      const new_distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY)

      if (this.distanceBetweenTouches !== 0) {
        this.resizeImage((new_distance - this.distanceBetweenTouches) * 0.01)
      }

      this.distanceBetweenTouches = new_distance
    }

    this.mouse_position = mouse_position
  }

  /**
   * カーソルの位置を取得
   * @param {any} event
   * @returns {TPosition} カーソルの位置
   */
  getMousePosition(event): TPosition {
    if (event.changedTouches && event.changedTouches[0]) {
      event = event.changedTouches[0]
    }

    const rect = event.target.getBoundingClientRect()

    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top
    }
  }

  /**
   * 画像をリサイズ
   * @param {number} scale_plus
   * @returns {void}
   */
  resizeImage(scale_plus: number): void {
    const old_width = this.image_width
    const old_height = this.image_height

    this.image_scale += scale_plus

    if (this.image_scale < 0.1) {
      this.image_scale = 0.1
    }

    const new_width = this.image_width
    const new_height = this.image_height

    this.image_position.x -= (new_width - old_width) / 2
    this.image_position.y -= (new_height - old_height) / 2

    this.drawImage()
  }

  /**
   * キャンバスに画像を反映
   * @returns {void}
   */
  drawImage(): void {
    const canvas = this.$refs.canvas.getContext('2d')

    canvas.fillStyle = '#e6e6e6'
    canvas.fillRect(0, 0, this.canvas_width, this.canvas_height)

    canvas.drawImage(
      this.image,
      this.image_position.x,
      this.image_position.y,
      this.image_width,
      this.image_height
    )
    canvas.restore()
  }
}
