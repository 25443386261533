import { Component, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import SnsEmbedDialog from '@/client/components-old/organisms/SnsEmbedDialog'
import TwitterComparisonAnalyticsAccountTable from '@/client/components-old/organisms/TwitterComparisonAnalyticsAccountTable'
import TwitterComparisonAnalyticsChartPositioningMap from '@/client/components-old/organisms/TwitterComparisonAnalyticsChartPositioningMap'
import TwitterComparisonAnalyticsChartVertical from '@/client/components-old/organisms/TwitterComparisonAnalyticsChartVertical'
import TwitterComparisonAnalyticsRanking from '@/client/components-old/organisms/TwitterComparisonAnalyticsRanking'

@Component({
  name: 'TwitterComparisonAnalyticsBenchmark',
  components: {
    TwitterComparisonAnalyticsAccountTable,
    TwitterComparisonAnalyticsChartVertical,
    TwitterComparisonAnalyticsRanking,
    TwitterComparisonAnalyticsChartPositioningMap,
    SnsEmbedDialog,
    Scroll
  }
})
export default class TwitterComparisonAnalyticsBenchmark extends Vue {
  get followers_count() {
    return {
      metric: 'followers_count',
      data_type: 'daily_data',
      interval: '1_day',
      unit: 'total',
      is_change_interval: true
    }
  }

  get followers_count_up_down() {
    return {
      metric: 'followers_count_up_down',
      data_type: 'daily_data',
      interval: '1_day',
      unit: 'total',
      is_change_interval: true
    }
  }

  get listed_count() {
    return {
      metric: 'listed_count',
      data_type: 'daily_data',
      interval: '1_day',
      unit: 'total',
      is_change_interval: true
    }
  }

  get listed_count_up_down() {
    return {
      metric: 'listed_count_up_down',
      data_type: 'daily_data',
      interval: '1_day',
      unit: 'total',
      is_change_interval: true
    }
  }

  get rankings() {
    return [
      {
        main_metric: 'reactions',
        sub_metrics: [
          'retweets',
          'quotes',
          'favorites',
          'replies',
          'reactions_rate',
          'impressions'
        ],
        sort: 'descending',
        limit: 5
      },
      {
        main_metric: 'reactions',
        sub_metrics: [
          'retweets',
          'quotes',
          'favorites',
          'replies',
          'reactions_rate',
          'impressions'
        ],
        sort: 'ascending',
        limit: 5
      }
    ]
  }
}
