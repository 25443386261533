import { Component, Prop, Vue } from 'vue-property-decorator'

import Badge from '@/client/components-old/atoms/Badge'

@Component({
  name: 'DashboardLabel',
  components: {
    Badge
  }
})
export default class DashboardLabel extends Vue {
  @Prop({ type: String, default: '' })
  type: string

  get badge_type() {
    switch (this.type) {
      case 'post_scheduled':
        return 'info'
      case 'post_published':
        return 'success'
      case 'post_failed':
      case 'post_deleted':
        return 'danger'
      case 'post_approve':
      case 'post_approved':
      case 'post_approved_1':
      case 'post_approved_2':
      case 'post_approved_3':
        return 'warning'
      case 'post_reject':
        return 'inactive'
      case 'monitoring_read':
        return 'success'
      case 'monitoring_pending':
        return 'warning'
      case 'monitoring_delete':
        return 'danger'
      default:
        return 'default'
    }
  }

  get badge_text() {
    switch (this.type) {
      case 'post_create':
        return '作成'
      case 'post_update':
        return '更新'
      case 'post_scheduled':
        return '予約'
      case 'post_scheduled_canceled':
        return '予約取消'
      case 'post_published':
        return '公開'
      case 'post_failed':
        return '失敗'
      case 'post_deleted':
        return '削除'
      case 'post_approve':
        return '承認申請'
      case 'post_approved':
        return '承認'
      case 'post_approved_1':
        return '承認 (1回目)'
      case 'post_approved_2':
        return '承認 (2回目)'
      case 'post_approved_3':
        return '承認 (3回目)'
      case 'post_approve_canceled':
        return '申請取消'
      case 'post_reject':
        return '差し戻し'
      case 'monitoring_read':
        return '既読'
      case 'monitoring_unread':
        return '未読'
      case 'monitoring_pending':
        return '保留'
      case 'monitoring_hide':
        return '非表示'
      case 'monitoring_unhide':
        return '非表示を解除'
      case 'monitoring_delete':
        return '削除'
      case 'monitoring_reply':
        return '返信'
      case 'monitoring_reply_edit':
        return '返信を更新'
      case 'monitoring_reply_delete':
        return '返信を削除'
      case 'monitoring_like':
        return 'いいね！'
      case 'monitoring_like_cancel':
        return 'いいね！取消'
      case 'monitoring_retweet':
        return 'リポスト'
      case 'monitoring_retweet_cancel':
        return 'リポスト取消'
      case 'monitoring_block':
        return 'ブロック'
      case 'monitoring_block_delete':
        return 'ブロックを解除'
      case 'monitoring_comment_edit':
        return 'コメント変更'
      case 'monitoring_quote_retweet':
        return '引用'
      case 'monitoring_quote_retweet_delete':
        return '引用を削除'
      default:
        return ''
    }
  }
}
