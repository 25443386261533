import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { SnsButton } from '@/client/components/atoms'
import { HelpLink } from '@/client/components/molecules'
import * as facebook_util from '@/client/components-old/_utils/facebook_authentication'
import * as instagram_util from '@/client/components-old/_utils/instagram_authentication'
import { TikTokAuthService } from '@/client/components-old/_utils/tiktok_authentication'
import * as twitter_util from '@/client/components-old/_utils/twitter_authentication'
import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import SnsAccountAbout from '@/client/components-old/molecules/SnsAccountAbout'
import SnsAccountError from '@/client/components-old/molecules/SnsAccountError'
import SnsRegisterItem from '@/client/components-old/molecules/SnsRegisterItem'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import { TPostFacebookAccountsResponses } from '@/client/utils/api/facebook_accounts'
import { TPostInstagramAccountsResponses } from '@/client/utils/api/instagram_accounts'
import { PostTikTokAccountsResponse } from '@/client/utils/api/tiktok_accounts'
import { TPostTwitterAccountsResponses } from '@/client/utils/api/twitter_accounts'

type TSnsType = 'facebook' | 'twitter' | 'instagram' | 'tiktok' | null

type TErrorType =
  | 'authentication_canceled'
  | 'permission_denied'
  | 'account_protected'
  | 'facebook_page_not_exist'
  | 'instagram_account_not_exist'
  | 'add_account_failed'
  | 'oauth_failed'
  | null

const accounts = namespace('accounts')
const notification = namespace('notification')

@Component({
  name: 'SnsAccountRegisterDialog',
  components: {
    Button,
    Dialog,
    HelpLink,
    Icon,
    Message,
    SnsAccountAbout,
    SnsAccountError,
    SnsButton,
    SnsRegisterItem
  }
})
export default class SnsAccountRegisterDialog extends Vue {
  @State('project') project!: IRootState['project']
  @accounts.Getter('account_count') account_count!: IAccountsGetter['account_count']
  @accounts.Action('fetchSnsAccounts') fetchSnsAccounts
  @accounts.Action('createFacebookAccount') createFacebookAccount
  @accounts.Action('createTwitterAccount') createTwitterAccount
  @accounts.Action('createInstagramAccount') createInstagramAccount
  @accounts.Action('createTikTokAccount') createTikTokAccount
  @notification.Action('showNotification') showNotification

  visible = false
  loading = false
  sns_type: TSnsType = null
  error_type: TErrorType = null
  current_step = 0
  add_account_success = false
  accounts: any[] = []
  account_id = ''
  user_access_token = ''

  get is_screen_select() {
    return this.sns_type === null
  }

  get is_screen_authentication() {
    return this.sns_type !== null && !this.accounts.length && this.error_type === null
  }

  get is_screen_register() {
    return this.accounts.length && !this.add_account_success && this.error_type === null
  }

  get is_register_success() {
    return this.add_account_success
  }

  get is_disabled() {
    return this.loading || !this.account_id
  }

  get is_warning() {
    return this.account_count >= 3
  }

  /**
   * ダイアログを表示
   */
  open() {
    this.visible = true
    this.loading = false
    this.add_account_success = false
    this.sns_type = null
    this.error_type = null
    this.current_step = 0
    this.accounts = []
    this.account_id = ''
  }

  /**
   * ダイアログを非表示
   */
  close() {
    this.visible = false
  }

  /**
   * キャンセルを選択
   */
  selectCancel(type: 'initial' | 'retry' = 'initial') {
    this.error_type = null

    this.current_step = 0

    if (this.accounts.length) {
      this.accounts = []
    } else if (this.sns_type) {
      this.sns_type = null
    }

    if (type === 'initial') {
      TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|キャンセル')
    }

    if (type === 'retry') {
      TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|失敗キャンセル')
    }
  }

  /**
   * SNSアカウントを選択
   */
  selectSnsAccount(sns_type: 'facebook' | 'twitter' | 'instagram' | 'tiktok') {
    this.sns_type = sns_type

    if (this.sns_type === 'facebook') {
      TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|FB')
    }

    if (this.sns_type === 'twitter') {
      TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|X')
    }

    if (this.sns_type === 'instagram') {
      TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|IG')
    }

    if (this.sns_type === 'tiktok') {
      TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|TT')
    }
  }

  /**
   * SNSの認証処理を開始
   */
  async startSnsAuthentication(type: 'initial' | 'retry' = 'initial') {
    switch (this.sns_type) {
      case 'facebook': {
        this.accounts = await this.authFacebookAccount()

        const [account] = this.accounts.filter(v => !v.is_exist)

        // ? 追加できるアカウントの先頭を選択状態にする
        if (account) {
          this.account_id = account.account_id
        }

        if (type === 'initial') {
          TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|FB|認証開始')
        }

        if (type === 'retry') {
          TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|FB|失敗再認証')
        }

        break
      }

      case 'twitter': {
        this.accounts = await this.authTwitterAccount()

        const [account] = this.accounts.filter(v => !v.is_exist)

        // ? 追加できるアカウントの先頭を選択状態にする
        if (account) {
          this.account_id = account.account_id
        }

        if (type === 'initial') {
          TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|X|認証開始')
        }

        if (type === 'retry') {
          TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|X|失敗再認証')
        }

        break
      }

      case 'instagram': {
        this.accounts = await this.authInstagramAccount()

        const [account] = this.accounts.filter(v => !v.is_exist)

        // ? 追加できるアカウントの先頭を選択状態にする
        if (account) {
          this.account_id = account.account_id
        }

        if (type === 'initial') {
          TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|IG|認証開始')
        }

        if (type === 'retry') {
          TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|IG|失敗再認証')
        }

        break
      }

      case 'tiktok': {
        this.accounts = await this.authTikTokAccount()

        const [account] = this.accounts.filter(v => !v.is_exist)

        // ? 追加できるアカウントの先頭を選択状態にする
        if (account) {
          this.account_id = account.account_id
        }

        if (type === 'initial') {
          TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|TT|認証開始')
        }

        if (type === 'retry') {
          TrackingService.sendEvent('click:グループ設定|SNSアカウント追加|TT|失敗再認証')
        }

        break
      }
    }
  }

  /**
   * SNSの登録処理を開始
   */
  async startSnsRegistration() {
    let result:
      | TPostFacebookAccountsResponses
      | TPostTwitterAccountsResponses
      | TPostInstagramAccountsResponses
      | PostTikTokAccountsResponse = {}

    this.loading = true

    const account = this.accounts.find(v => v.account_id === this.account_id)

    switch (this.sns_type) {
      case 'facebook': {
        result = await this.createFacebookAccount({
          account_id: account.account_id,
          name: account.name,
          username: account.username,
          image_url: account.image_url,
          user_access_token: this.user_access_token,
          fb_user_id: account.user.id,
          fb_user_name: account.user.name
        })

        if (result.data) TrackingService.sendEvent('addFacebookAccount')

        break
      }

      case 'twitter': {
        result = await this.createTwitterAccount({
          account_id: account.account_id,
          name: account.name,
          username: account.username,
          image_url: account.image_url,
          access_token: account.access_token,
          access_token_secret: account.access_token_secret
        })

        if (result.data) TrackingService.sendEvent('addTwitterAccount')

        break
      }

      case 'instagram': {
        result = await this.createInstagramAccount({
          account_id: account.account_id,
          business_account_id: account.business_account_id,
          name: account.name,
          username: account.username,
          image_url: account.image_url,
          access_token: account.access_token,
          fb_user_id: account.user.id,
          fb_user_name: account.user.name
        })

        if (result.data) TrackingService.sendEvent('addInstagramAccount')

        break
      }

      case 'tiktok': {
        result = await this.createTikTokAccount({
          access_token: account.access_token,
          refresh_token: account.refresh_token
        })

        if (result.data) TrackingService.sendEvent('addTikTokAccount')

        break
      }
    }

    this.loading = false

    if (result.data) {
      await this.fetchSnsAccounts()

      this.add_account_success = true
      this.current_step++

      return
    }

    this.error_type = 'add_account_failed'
  }

  /**
   * Facebookアカウントを認証
   */
  async authFacebookAccount() {
    const result = await facebook_util.execAuth(this.project.id)

    if (result.error) {
      switch (result.error.type) {
        case 'AUTHENTICATION_CANCELED':
          this.error_type = 'authentication_canceled'
          break
        case 'PERMISSION_DENIED':
          this.error_type = 'permission_denied'
          break
        case 'OAUTH_FACEBOOK_PAGE_NOT_EXIST':
          this.error_type = 'facebook_page_not_exist'
          break
        case 'OAUTH_FAILED':
          this.error_type = 'oauth_failed'
          break
      }
      return []
    }

    if (!result.data) return []

    this.user_access_token = result.data.user_access_token
    this.error_type = null

    return result.data.accounts
  }

  /**
   * Twitterアカウントを認証
   */
  async authTwitterAccount() {
    const result = await twitter_util.execAuth(this.project.id)

    if (result.error) {
      switch (result.error.type) {
        case 'AUTHENTICATION_CANCELED':
          this.error_type = 'authentication_canceled'
          break
        case 'OAUTH_FAILED':
          this.error_type = 'oauth_failed'
          break
      }
      return []
    }

    const is_protected = result.data.some(v => v.is_protected)

    if (is_protected) {
      this.error_type = 'account_protected'
      return []
    }

    if (!result.data) return []

    this.error_type = null

    return result.data
  }

  /**
   * Instagramアカウントを認証
   */
  async authInstagramAccount() {
    const result = await instagram_util.execAuth(this.project.id)

    if (result.error) {
      switch (result.error.type) {
        case 'AUTHENTICATION_CANCELED':
          this.error_type = 'authentication_canceled'
          break
        case 'PERMISSION_DENIED':
          this.error_type = 'permission_denied'
          break
        case 'OAUTH_FACEBOOK_PAGE_NOT_EXIST':
          this.error_type = 'facebook_page_not_exist'
          break
        case 'OAUTH_INSTAGRAM_ACCOUNT_NOT_EXIST':
          this.error_type = 'instagram_account_not_exist'
          break
        case 'OAUTH_FAILED':
          this.error_type = 'oauth_failed'
          break
      }

      return []
    }

    if (!result.data) return []

    this.error_type = null

    return result.data
  }

  /**
   * TikTokアカウントを認証
   */
  async authTikTokAccount() {
    const callback = await TikTokAuthService.openAuthWindow()

    // ? 認証をキャンセルした場合
    if (callback.type === 'AUTHENTICATION_CANCELED') {
      this.error_type = 'authentication_canceled'
    }

    // ? 認証したがスコープが足りてない場合
    if (callback.type === 'PERMISSION_DENIED') {
      this.error_type = 'permission_denied'
    }

    // ? 認証が失敗した場合
    if (callback.type === 'OAUTH_FAILED') {
      this.error_type = 'oauth_failed'
    }

    if (callback.type === 'SUCCESS') {
      this.error_type = null

      const accounts = await TikTokAuthService.getAccountInfo(callback, this.project.id)

      if (accounts.data) return accounts.data
    }

    this.error_type = 'oauth_failed'
    return []
  }
}
