import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Scroll from '@/client/components-old/atoms/Scroll'
import InstagramComparisonAccountManagement from '@/client/components-old/organisms/InstagramComparisonAccountManagement'
import PresetManagement from '@/client/components-old/organisms/PresetManagement'
import { TrackingService } from '@/client/services'
import {
  IGetter as IInstagramGetter,
  IState as IInstagramState
} from '@/client/store/modules/instagram_comparison_analytics'

const instagram = namespace('instagram_comparison_analytics')
const notification = namespace('notification')

@Component({
  name: 'InstagramComparisonAnalyticsManagement',
  components: {
    Button,
    ButtonGroup,
    Icon,
    Message,
    Panel,
    Scroll,
    InstagramComparisonAccountManagement,
    PresetManagement
  }
})
export default class InstagramComparisonAnalyticsManagement extends Vue {
  @instagram.State('management_tab_name')
  management_tab_name: IInstagramState['management_tab_name']
  @instagram.Getter('is_unregister_comparison')
  is_unregister_comparison: IInstagramGetter['is_unregister_comparison']
  @instagram.Action('getManagementTab') getManagementTab
  @instagram.Action('postSamplePreset') postSamplePreset
  @notification.Action('showNotification') showNotification

  @Watch('management_tab_name')
  watchManagementTabName() {
    TrackingService.sendEvent(
      `click:競合比較(IG)>アカウント管理|表示切替:${this.management_tab_name}`
    )
  }

  get tabs() {
    return [
      { value: 'account', text: 'アカウント' },
      { value: 'preset', text: 'プリセット' }
    ]
  }

  get is_account() {
    return this.management_tab_name === 'account'
  }

  get is_preset() {
    return this.management_tab_name === 'preset'
  }

  /**
   * サンプルプリセットを追加
   */
  async addSamplePresets(): Promise<void> {
    const result = await this.postSamplePreset()

    if (result && result.data) {
      this.showNotification({ title: 'サンプルプリセットを追加しました。' })
    } else {
      this.showNotification({ title: 'サンプルプリセットの追加に失敗しました。', type: 'error' })
    }
  }
}
