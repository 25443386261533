
import { computed, defineComponent, PropType, watchEffect } from 'vue'

import { AppTooltip, AvatarService } from '@/client/components/atoms'
import { getTranslateText as t } from '@/client/utils/filters'

type ValueProps = 'master' | 'facebook' | 'instagram' | 'twitter'

export default defineComponent({
  name: 'SelectAvatarService',
  components: {
    AppTooltip,
    AvatarService
  },
  props: {
    value: {
      type: String as PropType<ValueProps>,
      default: 'master'
    },
    options: {
      type: Array as PropType<ValueProps[]>,
      default: () => ['master', 'facebook', 'instagram', 'twitter']
    }
  },
  setup(props, context) {
    const default_options = [
      { name: 'マスター', value: 'master', icon: 'comnico' },
      { name: 'Facebook', value: 'facebook', icon: 'facebook' },
      { name: 'Twitter', value: 'twitter', icon: 'twitter' },
      { name: 'Instagram', value: 'instagram', icon: 'instagram' }
    ]

    const filter_options = computed(() =>
      default_options.filter(v => props.options.includes(v.value))
    )

    function isActive(value: ValueProps) {
      return props.value === value
    }

    function onChange(value: ValueProps) {
      context.emit('input', value)
    }

    watchEffect(() => {
      if (!props.options.includes(props.value)) {
        onChange(props.options[0])
      }
    })

    return {
      filter_options,
      isActive,
      onChange,
      t
    }
  }
})
