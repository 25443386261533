
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import TwitterAnalyticsHeader from '@/client/components-old/organisms/TwitterAnalyticsHeader'
import TwitterAnalyticsAction from '@/client/components-old/templates/TwitterAnalyticsAction'
import TwitterAnalyticsFollower from '@/client/components-old/templates/TwitterAnalyticsFollower'
import TwitterAnalyticsNodata from '@/client/components-old/templates/TwitterAnalyticsNodata'
import TwitterAnalyticsPost from '@/client/components-old/templates/TwitterAnalyticsPost'
import TwitterAnalyticsReach from '@/client/components-old/templates/TwitterAnalyticsReach'
import TwitterAnalyticsSummary from '@/client/components-old/templates/TwitterAnalyticsSummary'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import { IState as ITwitterState } from '@/client/store/modules/twitter_analytics'
import event from '@/client/utils/event'

const accounts = namespace('accounts')
const twitter = namespace('twitter_analytics')

@Component({
  name: 'TwitterAnalytics',
  components: {
    TwitterAnalyticsHeader,
    TwitterAnalyticsNodata,
    TwitterAnalyticsSummary,
    TwitterAnalyticsPost,
    TwitterAnalyticsFollower,
    TwitterAnalyticsAction,
    TwitterAnalyticsReach
  }
})
export default class TwitterAnalytics extends Vue {
  @accounts.Getter('twitter_analytics') accounts!: IAccountsGetter['twitter_analytics']
  @twitter.State('is_loading') is_loading!: ITwitterState['is_loading']
  @twitter.State('account_id') account_id!: ITwitterState['account_id']
  @twitter.State('screen_name') screen_name!: ITwitterState['screen_name']
  @twitter.Action('createdTwitterAnalyticsPage') createdTwitterAnalyticsPage!: any
  @twitter.Action('destroyedTwitterAnalyticsPage') destroyedTwitterAnalyticsPage!: any
  @twitter.Action('changeGroupTwitterAnalyticsPage') changeGroupTwitterAnalyticsPage!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  get is_empty() {
    const account = this.accounts.find(account => account.id === this.account_id)

    return account ? account.is_empty : false
  }

  get is_summary() {
    return this.screen_name === 'summary'
  }

  get is_post() {
    return this.screen_name === 'post'
  }

  get is_follower() {
    return this.screen_name === 'follower'
  }

  get is_action() {
    return this.screen_name === 'action'
  }

  get is_reach() {
    return this.screen_name === 'reach'
  }

  @Watch('is_loading')
  async changeIsLoading() {
    await this.$nextTick()
    await event.delay(500)
    event.resize()
  }

  @Watch('screen_name')
  async changeScreenName() {
    await this.$nextTick()
    await event.delay(500)
    event.resize()
  }

  @Watch('group')
  async watchGroup() {
    await this.changeGroupTwitterAnalyticsPage()
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  @Emit('change-project')
  changeProject(payload: any) {
    return payload
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.createdTwitterAnalyticsPage(this.$route.query)

    if (this.$route.query?.account_id) {
      await this.$router.replace({ name: 'analytics_tw' })
    }
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedTwitterAnalyticsPage()
  }
}
