import { Component, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import FacebookComparisonAnalyticsAccountTable from '@/client/components-old/organisms/FacebookComparisonAnalyticsAccountTable'
import FacebookComparisonAnalyticsChartPositioningMap from '@/client/components-old/organisms/FacebookComparisonAnalyticsChartPositioningMap'
import FacebookComparisonAnalyticsChartVertical from '@/client/components-old/organisms/FacebookComparisonAnalyticsChartVertical'
import FacebookComparisonAnalyticsRanking from '@/client/components-old/organisms/FacebookComparisonAnalyticsRanking'
import SnsEmbedDialog from '@/client/components-old/organisms/SnsEmbedDialog'

@Component({
  name: 'FacebookComparisonAnalyticsBenchmark',
  components: {
    FacebookComparisonAnalyticsAccountTable,
    FacebookComparisonAnalyticsChartVertical,
    FacebookComparisonAnalyticsRanking,
    FacebookComparisonAnalyticsChartPositioningMap,
    SnsEmbedDialog,
    Scroll
  }
})
export default class FacebookComparisonAnalyticsBenchmark extends Vue {
  get page_fans() {
    return {
      metric: 'page_fans',
      data_type: 'daily_data',
      interval: '1_day',
      unit: 'total',
      is_change_interval: true
    }
  }

  get page_fan_up_down() {
    return {
      metric: 'page_fan_up_down',
      data_type: 'daily_data',
      interval: '1_day',
      unit: 'total',
      is_change_interval: true
    }
  }

  get talking_about_count() {
    return {
      metric: 'talking_about_count',
      data_type: 'daily_data',
      interval: '1_day',
      unit: 'total',
      is_change_interval: true
    }
  }

  get rankings() {
    return [
      {
        main_metric: 'reactions',
        sub_metrics: ['likes', 'comments', 'shares', 'reactions_rate'],
        sort: 'descending',
        limit: 5
      },
      {
        main_metric: 'reactions',
        sub_metrics: ['likes', 'comments', 'shares', 'reactions_rate'],
        sort: 'ascending',
        limit: 5
      }
    ]
  }
}
