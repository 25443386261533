import { render, staticRenderFns } from "./FacebookAnalyticsWidgetDialog.vue?vue&type=template&id=afcba88a&scoped=true"
import script from "./FacebookAnalyticsWidgetDialog.ts?vue&type=script&lang=ts&external"
export * from "./FacebookAnalyticsWidgetDialog.ts?vue&type=script&lang=ts&external"
import style0 from "./FacebookAnalyticsWidgetDialog.vue?vue&type=style&index=0&id=afcba88a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afcba88a",
  null
  
)

export default component.exports