
import { computed, defineComponent, PropType } from 'vue'

type AccountProps = {
  id: string
  name: string
  username: string
  image_url: string
}

type SnsProps = 'facebook' | 'instagram' | 'twitter'

type SizeProps = 'small' | 'normal' | 'large'

export default defineComponent({
  name: 'AvatarAccount',
  props: {
    account: {
      type: Object as PropType<AccountProps>,
      required: true
    },
    sns: {
      type: String as PropType<SnsProps>,
      required: true
    },
    size: {
      type: String as PropType<SizeProps>,
      default: 'normal'
    }
  },
  setup(props) {
    const icon = computed(() => `ci-${props.sns}-with-circle`)

    return {
      icon
    }
  }
})
