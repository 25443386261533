import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/instagram_hashtags'
import Icon from '@/client/components-old/atoms/Icon'
import Select from '@/client/components-old/atoms/Select'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartWordCloud from '@/client/components-old/molecules/ChartWordCloud'
import { TrackingService } from '@/client/services'
import {
  IGetter as IInstagramHashtagGetter,
  IState as IInstagramHashtagState
} from '@/client/store/modules/instagram_hashtag'
import i18n from '@/client/utils/i18n'

const instagram_hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagWordCloud',
  components: {
    Icon,
    Select,
    Tooltip,
    AnalyticsPanel,
    ChartWordCloud
  }
})
export default class InstagramHashtagWordCloud extends Vue {
  @instagram_hashtag.State('api_settings') api_settings: IInstagramHashtagState['api_settings']
  @instagram_hashtag.State('api_word_cloud')
  api_word_cloud: IInstagramHashtagState['api_word_cloud']
  @instagram_hashtag.State('hashtag_ids')
  hashtag_ids: IInstagramHashtagState['hashtag_ids']
  @instagram_hashtag.State('is_word_cloud_loading')
  is_word_cloud_loading: IInstagramHashtagState['is_word_cloud_loading']
  @instagram_hashtag.State('word_cloud_hashtag_id')
  word_cloud_hashtag_id: IInstagramHashtagState['word_cloud_hashtag_id']
  @instagram_hashtag.State('word_cloud_size')
  word_cloud_size: IInstagramHashtagState['word_cloud_size']
  @instagram_hashtag.Getter('is_data') is_data: IInstagramHashtagGetter['is_data']
  @instagram_hashtag.Action('changeWordCloudHashtagId') changeWordCloudHashtagId
  @instagram_hashtag.Action('changeWordCloudSize') changeWordCloudSize
  @instagram_hashtag.Action('checkHashtagPosts') checkHashtagPosts

  get word_cloud_options() {
    const options = this.hashtag_ids.map(hashtag_id => {
      const hashtag = this.api_settings.find(v => v.id === hashtag_id)

      return { value: hashtag_id, text: hashtag ? hashtag.name : '' }
    })

    return [{ value: '', text: i18n.t('全分類') }, ...options]
  }

  get word_cloud_size_options() {
    return constants.WORD_CLOUD_SIZE_OPTIONS.map(v => ({
      value: v,
      text: i18n.t('上位[[count]]件', { count: v })
    }))
  }

  get is_select_data() {
    return this.api_word_cloud.length > 0
  }

  get chart_options() {
    return {
      series: this.api_word_cloud.map(v => ({
        name: v.word,
        weight: v.post_count
      }))
    }
  }

  /**
   * ワードクラウドで指定しているハッシュタグの変更
   */
  async onChangeWordCloudHashtagId(payload: number) {
    await this.changeWordCloudHashtagId(payload)

    const word_cloud_option = this.word_cloud_options.find(
      option => option.value === this.word_cloud_hashtag_id
    )
    const word_cloud_keyword_label = word_cloud_option?.text || ''

    TrackingService.sendEvent(`select:クチコミ(IG)>サマリー|WC:KW分類:${word_cloud_keyword_label}`)
  }
  /**
   * ワードクラウドの件数変更
   */
  async onChangeWordCloudSize(payload: number) {
    await this.changeWordCloudSize(payload)

    const word_cloud_size_option = this.word_cloud_size_options.find(
      option => option.value === this.word_cloud_size
    )
    const word_cloud_size_label = word_cloud_size_option?.text || ''

    TrackingService.sendEvent(`select:クチコミ(IG)>サマリー|WC:${word_cloud_size_label}`)
  }
}
