import { render, staticRenderFns } from "./PersonalSettingTwoFactorDialog.vue?vue&type=template&id=f2a642fc&scoped=true"
import script from "./PersonalSettingTwoFactorDialog.ts?vue&type=script&lang=ts&external"
export * from "./PersonalSettingTwoFactorDialog.ts?vue&type=script&lang=ts&external"
import style0 from "./PersonalSettingTwoFactorDialog.vue?vue&type=style&index=0&id=f2a642fc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2a642fc",
  null
  
)

export default component.exports