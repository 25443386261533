
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'EmbedInstagram'
})
export default class EmbedInstagram extends Vue {
  @Prop({ type: String, required: true })
  url!: string

  @Prop({ type: String, default: '500' })
  width!: string

  get style() {
    return { width: `${this.width}px` }
  }

  mounted() {
    this.displayInstagramPost()
  }

  /**
   * Instagram投稿を表示
   */
  displayInstagramPost() {
    if (!Object.prototype.hasOwnProperty.call(window, 'instgrm')) return

    window.instgrm.Embeds.process()
  }
}
