import { Component, Prop, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import { namespace } from 'vuex-class'

import HelpLink from '@/client/components/molecules/HelpLink'
import * as constants_facebook from '@/client/components-old/_constants/facebook_analytics'
import * as constants_instagram from '@/client/components-old/_constants/instagram_analytics'
import * as constants_twitter from '@/client/components-old/_constants/twitter_analytics'
import Badge from '@/client/components-old/atoms/Badge'
import Button from '@/client/components-old/atoms/Button'
import Checkbox from '@/client/components-old/atoms/Checkbox'
import Dialog from '@/client/components-old/atoms/Dialog'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import * as constants_tiktok from '@/client/features/tiktok_analytics/constants'

const facebook = namespace('facebook_analytics')
const twitter = namespace('twitter_analytics')
const instagram = namespace('instagram_analytics')
const tiktok = namespace('tiktok_analytics')
const notification = namespace('notification')

interface ISelectedList {
  text: string
  value: string
  selected: boolean
}

@Component({
  name: 'TableSettingDialog',
  components: {
    draggable,
    Dialog,
    HelpLink,
    Flex,
    Badge,
    Button,
    Icon,
    Checkbox,
    Tooltip
  }
})
export default class TableSettingDialog extends Vue {
  @facebook.Action('putTableSetting') submitFacebook
  @twitter.Action('putTableSetting') submitTwitter
  @instagram.Action('putTableSetting') submitInstagram
  @tiktok.Action('postTableSetting') submitTikTok
  @notification.Action('showNotification') showNotification

  @Prop({ type: String, required: true })
  snsType: 'facebook' | 'twitter' | 'instagram' | 'tiktok'

  is_visible = false
  is_submit = false
  options: string[] = []

  get length() {
    return this.options.length
  }

  get metrics() {
    switch (this.snsType) {
      case 'facebook':
        return constants_facebook.TABLE_COLUMN_METRICS
      case 'twitter':
        return constants_twitter.TABLE_COLUMN_METRICS
      case 'instagram':
        return constants_instagram.TABLE_COLUMN_METRICS
      case 'tiktok':
        return constants_tiktok.TABLE_COLUMN_METRICS
      default:
        return []
    }
  }

  get selected_list() {
    return this.metrics.map(metric => ({
      selected: this.options.indexOf(metric.value) >= 0,
      text: metric.text,
      value: metric.value
    }))
  }

  get sort_list() {
    return this.options.map(v => this.metrics.find(m => m.value === v))
  }

  set sort_list(val) {
    this.options = val.map(v => v.value)
  }

  /**
   * ダイアログの表示
   * @param {string[]} options テーブル設定
   * @returns {void}
   */
  open(options: string[]): void {
    this.is_visible = true
    this.options = [...options]
  }

  /**
   * ダイアログの非表示
   * @returns {void}
   */
  hide(): void {
    this.is_visible = false
    this.options = []
  }

  /**
   * 選択一覧の変更
   * @param {ISelectedList} item
   * @returns {void}
   */
  changeSelectedList(item: ISelectedList): void {
    if (item.selected) {
      this.options = this.options.concat([item.value])
    } else {
      this.options = this.options.filter(v => v !== item.value)
    }
  }

  /**
   * ソート一覧の削除
   * @param {number} index
   * @returns {void}
   */
  removeSortList(index: number): void {
    this.sort_list = this.sort_list.filter((item, i) => i !== index)
  }

  /**
   * 設定の送信
   * @returns {void}
   */
  async submit(): Promise<void> {
    if (this.is_submit) return

    this.is_submit = true

    let result = null

    switch (this.snsType) {
      case 'facebook':
        result = await this.submitFacebook({ columns: JSON.stringify(this.options) })
        break
      case 'twitter':
        result = await this.submitTwitter({ columns: JSON.stringify(this.options) })
        break
      case 'instagram':
        result = await this.submitInstagram({ columns: JSON.stringify(this.options) })
        break
      case 'tiktok':
        result = await this.submitTikTok({ columns: this.options })
        break
    }

    setTimeout(() => {
      this.is_submit = false
    }, 2000)

    if (result?.data) {
      this.hide()
      return this.showNotification({ title: '表示列設定を保存しました。' })
    }

    this.showNotification({ title: '表示列設定の保存に失敗しました。', type: 'error' })
  }

  /**
   * X CSVインポートの指標を判断
   */
  isCsvImportMetric(metric: string): boolean {
    if (this.snsType !== 'twitter') return false

    return constants_twitter.POST_CSV_IMPORT_METRICS.includes(metric)
  }
}
