/** The request post info is empty or incorrect */
export const ERROR_CODE_40002 = 40002

/** The rate limit for the used endpoint at the developer application level has been reached, thus your request is throttled. */
export const ERROR_CODE_40016 = 40016

/** The rate limit for the endpoint at the developer application level has been reached, thus your request is throttled. */
export const ERROR_CODE_40100 = 40100

/** The rate limit for the used endpoint at the advertiser level has been reached, thus your request is throttled. */
export const ERROR_CODE_40133 = 40133
