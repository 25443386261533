
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Panel from '@/client/components-old/atoms/Panel'
import { IRootState } from '@/client/store/global'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

@Component({
  name: 'GroupSettingGroupDetail',
  components: {
    Button,
    Icon,
    Input,
    Panel
  }
})
export default class GroupSettingGroupDetail extends Vue {
  @State('project') project: IRootState['project']
  @group_setting.Action('updateGroupSettings') updateGroupSettings
  @notification.Action('showNotification') showNotification

  project_name = ''

  @Watch('project', { immediate: true, deep: true })
  watchGroupName() {
    this.project_name = this.project.project_name
  }

  get disabled() {
    return this.project_name.trim() === '' || this.project_name.trim() === this.project.project_name
  }

  /**
   * グループ設定の保存
   */
  async saveGroupSetting(): Promise<void> {
    const params = {
      project_name: this.project_name.trim()
    }

    const result = await this.updateGroupSettings(params)

    if (result.data) {
      return this.showNotification({ title: '設定を保存しました。' })
    }

    this.showNotification({ title: '設定の保存に失敗しました。', type: 'error' })
  }
}
