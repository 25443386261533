import { Component, Prop, Vue } from 'vue-property-decorator'

import Badge from '@/client/components-old/atoms/Badge'

@Component({
  name: 'BadgeMonitoring',
  components: {
    Badge
  }
})
export default class BadgeMonitoring extends Vue {
  @Prop({ type: String, default: '' })
  type: string

  get badge_type() {
    switch (this.type) {
      case 'monitoring_read':
        return 'success'
      case 'monitoring_pending':
        return 'warning'
      case 'monitoring_delete':
        return 'danger'
      default:
        return 'default'
    }
  }

  get badge_text() {
    switch (this.type) {
      case 'monitoring_read':
        return '既読'
      case 'monitoring_unread':
        return '未読'
      case 'monitoring_pending':
        return '保留'
      case 'monitoring_hide':
        return '非表示'
      case 'monitoring_unhide':
        return '非表示を解除'
      case 'monitoring_delete':
        return '削除'
      case 'monitoring_reply':
        return '返信'
      case 'monitoring_reply_edit':
        return '返信を更新'
      case 'monitoring_reply_delete':
        return '返信を削除'
      case 'monitoring_like':
        return 'いいね！'
      case 'monitoring_like_cancel':
        return 'いいね！取消'
      case 'monitoring_retweet':
        return 'リポスト'
      case 'monitoring_retweet_cancel':
        return 'リポスト取消'
      case 'monitoring_block':
        return 'ブロック'
      case 'monitoring_block_delete':
        return 'ブロックを解除'
      case 'monitoring_comment_edit':
        return 'コメント変更'
      case 'monitoring_quote_retweet':
        return '引用'
      case 'monitoring_quote_retweet_delete':
        return '引用を削除'
      default:
        return ''
    }
  }
}
