
import { defineComponent } from 'vue'

import { GroupSettingIPAddressRestriction } from '@/client/components/organisms/GroupSetting'
import Scroll from '@/client/components-old/atoms/Scroll'
import GroupSettingGroupDetail from '@/client/components-old/organisms/GroupSettingGroupDetail'

export default defineComponent({
  name: 'GroupSettingGroupLayout',
  components: {
    Scroll,
    GroupSettingGroupDetail,
    GroupSettingIPAddressRestriction
  }
})
