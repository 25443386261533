import { GridItem, GridLayout } from 'vue-grid-layout'
import { Component, Vue } from 'vue-property-decorator'

import * as constants from '@/client/components-old/_constants/instagram_analytics'
import Box from '@/client/components-old/atoms/Box'
import Legend from '@/client/components-old/atoms/Legend'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import InstagramAnalyticsChartDonutHalf from '@/client/components-old/organisms/InstagramAnalyticsChartDonutHalf'
import InstagramAnalyticsChartVertical from '@/client/components-old/organisms/InstagramAnalyticsChartVertical'
import InstagramAnalyticsTotal from '@/client/components-old/organisms/InstagramAnalyticsTotal'

@Component({
  name: 'InstagramAnalyticsAction',
  components: {
    GridLayout,
    GridItem,
    InstagramAnalyticsChartDonutHalf,
    InstagramAnalyticsChartVertical,
    InstagramAnalyticsTotal,
    Legend,
    Box,
    Message,
    Scroll
  }
})
export default class InstagramAnalyticsAction extends Vue {
  get legend_data() {
    return constants.IN_DATA_TYPES.map(type => ({ text: type.text, color: type.color }))
  }

  get grid_layout() {
    return [
      {
        x: 0,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:エンゲージメント数',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'engagements',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 3,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:反応数',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'reactions',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 6,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:エンゲージメント率',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'engagements_rate',
          unit: 'average',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 9,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:反応率',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'reactions_rate',
          unit: 'average',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:プロフィールへのアクション',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'profile_clicks',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 3,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:前へ',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'stories_data',
          metric: 'taps_back',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 6,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:次へ',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'stories_data',
          metric: 'taps_forward',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 9,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:ストーリーズから移動',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'stories_data',
          metric: 'exits',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 4,
        w: 12,
        h: 4,
        i: '垂直チャート:エンゲージメント数',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'engagements',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 8,
        w: 12,
        h: 4,
        i: '垂直チャート:反応数',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'engagements_rate',
          interval: '1_day',
          unit: 'average',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 12,
        w: 12,
        h: 4,
        i: '垂直チャート:エンゲージメント率',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'reactions',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 16,
        w: 12,
        h: 4,
        i: '垂直チャート:反応率',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'reactions_rate',
          interval: '1_day',
          unit: 'average',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 20,
        w: 12,
        h: 4,
        i: '垂直チャート:プロフィールへのアクション',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'profile_clicks',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 24,
        w: 12,
        h: 4,
        i: '垂直チャート:前へ',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'stories_data',
          metric: 'taps_back',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 28,
        w: 12,
        h: 4,
        i: '垂直チャート:次へ',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'stories_data',
          metric: 'taps_forward',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 32,
        w: 12,
        h: 4,
        i: '垂直チャート:ストーリーズから移動',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'stories_data',
          metric: 'exits',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 36,
        w: 6,
        h: 4,
        i: '半円チャート:エンゲージメント数の内訳',
        name: 'InstagramAnalyticsChartDonutHalf',
        options: {
          data_type: 'post_data',
          metric: 'engagements'
        }
      },
      {
        x: 6,
        y: 36,
        w: 6,
        h: 4,
        i: '半円チャート:反応数の内訳',
        name: 'InstagramAnalyticsChartDonutHalf',
        options: {
          data_type: 'post_data',
          metric: 'reactions'
        }
      }
    ]
  }
}
