var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Panel',{attrs:{"is-transparent-mobile":""}},[_c('template',{slot:"header"},[_c('div',{staticClass:"group-header"},[_vm._v("\n      "+_vm._s(_vm.t('モニタリングアカウント'))+"\n      "),_c('Tooltip',{attrs:{"content":"モニタリングで利用できるアカウントを設定できます。"}},[_c('Icon',{attrs:{"name":"help-with-circle","space":"left"}})],1)],1),_vm._v(" "),(_vm.$mq === 'sm')?_c('ButtonLink',{on:{"click":function($event){return _vm.$emit('open-monitoring-add-dialog')}}},[_c('Icon',{attrs:{"name":"add","space":"right"}}),_vm._v("\n      "+_vm._s(_vm.t('追加'))+"\n    ")],1):_c('Button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.$emit('open-monitoring-add-dialog')}}},[_c('Icon',{attrs:{"name":"add","space":"right"}}),_vm._v("\n      "+_vm._s(_vm.t('追加'))+"\n    ")],1)],1),_vm._v(" "),(_vm.monitoring_accounts.length)?_vm._l((_vm.monitoring_accounts),function(account){return _c('GroupSettingMonitoringItem',{key:account.id,attrs:{"account":account,"sns-type":account.sns_type},on:{"open-monitoring-unsubscribe-dialog":function($event){return _vm.$emit('open-monitoring-unsubscribe-dialog', {
          account_id: account.id,
          sns_type: account.sns_type
        })},"open-monitoring-unsubscribe-cancel-dialog":function($event){return _vm.$emit('open-monitoring-unsubscribe-cancel-dialog', {
          account_id: account.id,
          sns_type: account.sns_type
        })}}})}):_c('Message',{attrs:{"type":"info"}},[_vm._v("\n    "+_vm._s(_vm.t('モニタリングアカウントはまだ登録されていません。'))+"\n  ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }