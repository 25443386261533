import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Badge from '@/client/components-old/atoms/Badge'
import Button from '@/client/components-old/atoms/Button'
import Checkbox from '@/client/components-old/atoms/Checkbox'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Textarea from '@/client/components-old/atoms/Textarea'

type TGroupContractCancelDialogStep =
  | 'cancel_confirm'
  | 'cancel_reason'
  | 'cancel_reason_confirm'
  | 'cancel_finish'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

@Component({
  name: 'GroupContractCancelDialog',
  components: {
    Badge,
    Button,
    Checkbox,
    Dialog,
    Message,
    Icon,
    Input,
    Textarea
  }
})
export default class GroupContractCancelDialog extends Vue {
  @group_setting.Action('cancelGroupBillings') cancelGroupBillings
  @notification.Action('showNotification') showNotification

  visible = false
  loading = false
  step: TGroupContractCancelDialogStep = 'cancel_confirm'

  questions = [
    { text: '期待していたサービスや機能がなかったため', value: false },
    { text: '予算が見合わなかったため', value: false },
    { text: '他社サービスへ移行のため', value: false },
    { text: '案件が終了したため', value: false },
    { text: 'サポートに不満があったため', value: false }
  ]

  question_other = false
  reason_other = ''
  question_request = ''

  get is_step_cancel_confirm(): boolean {
    return this.step === 'cancel_confirm'
  }

  get is_step_cancel_reason(): boolean {
    return this.step === 'cancel_reason'
  }

  get is_step_cancel_reason_confirm(): boolean {
    return this.step === 'cancel_reason_confirm'
  }

  get is_step_cancel_finish(): boolean {
    return this.step === 'cancel_finish'
  }

  get is_disabled(): boolean {
    if (this.question_other && this.reason_other === '') {
      return true
    }

    return !this.questions.some(question => question.value === true) && !this.question_other
  }

  get confirm_questions() {
    const questions = this.questions
      .filter(question => question.value)
      .map(question => this.$options.filters.translate(question.text))

    if (this.question_other) {
      questions.push(
        this.$options.filters.translate('その他: [[text]]', {
          text: this.reason_other
        })
      )
    }

    return questions
  }

  @Emit('change-vue')
  changeVue(payload: any) {
    return payload
  }

  /**
   * ダイアログを開く
   */
  open(): void {
    this.visible = true
    this.loading = false
    this.step = 'cancel_confirm'
    this.questions.forEach(question => (question.value = false))
    this.question_other = false
    this.reason_other = ''
    this.question_request = ''
  }

  /**
   * ダイアログを非表示
   */
  close(): void {
    this.visible = false
  }

  /**
   * ダイアログを非表示する前
   */
  beforeClose(done: () => void): void {
    if (this.loading) return

    done()
  }

  /**
   * 次のステップに切り替える
   */
  async nextStep(): Promise<void> {
    switch (this.step) {
      case 'cancel_confirm':
        this.step = 'cancel_reason'
        break
      case 'cancel_reason':
        this.step = 'cancel_reason_confirm'
        break
    }
  }

  /**
   * 前のステップに切り返る
   */
  prevStep(): void {
    switch (this.step) {
      case 'cancel_reason':
        this.step = 'cancel_confirm'
        break
      case 'cancel_reason_confirm':
        this.step = 'cancel_reason'
        break
    }
  }

  /**
   * 解約手続を行う
   */
  async cancellationProcedure() {
    const question_reason = this.questions
      .filter(question => question.value)
      .map(question => question.text)

    if (this.question_other) {
      question_reason.push(this.reason_other.trim())
    }

    const params = {
      question_reason,
      question_request: this.question_request.trim()
    }

    this.loading = true

    const result = await this.cancelGroupBillings(params)

    this.loading = false

    if (result.data) {
      this.step = 'cancel_finish'
      return this.changeVue(result.data)
    }

    return this.showNotification({ title: '解約手続に失敗しました。', type: 'error' })
  }
}
