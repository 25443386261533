import i18n from '@/client/utils/i18n'

/**
 * ページタイトルの変更
 */
function changePageTitle(title?: string) {
  const app = 'comnico Marketing Suite'

  if (title) {
    document.title = `${i18n.t(title)} | ${app}`
  } else {
    document.title = app
  }
}

export default { changePageTitle }
