import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/instagram_analytics'
import * as instagram_insight from '@/client/components-old/_utils/instagram_insight'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartHorizontal from '@/client/components-old/molecules/ChartHorizontal'
import ChartMap from '@/client/components-old/molecules/ChartMap'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as IInstagramState } from '@/client/store/modules/instagram_analytics'
import csv from '@/client/utils/csv'

type TInstagramAnalyticsChartMapOptions = {
  metric: 'audience_country' | 'audience_city'
}

const analytics = namespace('analytics')
const instagram = namespace('instagram_analytics')

@Component({
  name: 'InstagramAnalyticsChartMap',
  components: {
    AnalyticsPanel,
    ChartHorizontal,
    AnalyticsPanelHeader,
    ChartMap,
    ButtonGroup,
    Icon,
    Tooltip
  }
})
export default class InstagramAnalyticsChartMap extends Vue {
  @State('user') user: IRootState['user']
  @instagram.State('api_accounts') daily_data: IInstagramState['api_accounts']
  @instagram.State('screen_name') screen_name: IInstagramState['screen_name']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TInstagramAnalyticsChartMapOptions

  type = 'map'

  get metric() {
    const metrics = constants.MAP_CHART_DAILY_DATA_METRICS

    return metrics.find(metric => metric.value === this.options.metric)
  }

  get metric_name() {
    return this.metric ? this.metric.text : ''
  }

  get metric_tooltip() {
    return this.metric ? this.metric.tooltip : ''
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }

  get is_data() {
    switch (this.options.metric) {
      case 'audience_country':
        return Object.keys(this.daily_data_last).length > 0
      case 'audience_city':
        return Object.keys(this.daily_data_last).length > 0
      default:
        return this.daily_data.length > 0
    }
  }

  get type_options() {
    return [
      {
        value: 'map',
        text: '地図'
      },
      {
        value: 'graph',
        text: 'グラフ'
      }
    ]
  }

  get is_type_map() {
    return this.type === 'map'
  }

  get is_type_graph() {
    return this.type == 'graph'
  }

  get map_chart_options() {
    return {
      name: this.metric_name,
      type: this.chart_type,
      lang: this.user.language,
      metric_type: 'positive',
      data: this.chart_data
    }
  }

  get graph_chart_options() {
    const blue = '#1c84c6'

    let categories = []

    if (this.options.metric === 'audience_country') {
      categories = this.chart_data.map(v => {
        const country = this.map_names.find(m => m['hc-a2'] === v[0])

        if (this.user.language === 'ja') {
          return country ? country['jp-name'] : v[0]
        }

        return country ? country['name'] : v[0]
      })
    } else {
      categories = this.chart_data.map(v => {
        const city = this.map_names.find(m => m['name'] === v[0])

        if (this.user.language === 'ja') {
          return city ? city['jp-name'] : v[0]
        }

        return city ? city['name'] : v[0]
      })
    }

    return {
      display_value: true,
      display_legend: false,
      legend_position: 'top',
      categories,
      colors: [blue],
      series: [
        {
          name: this.$options.filters.translate(this.metric_name),
          data: this.chart_data.map(v => v[1])
        }
      ],
      label_type: ''
    }
  }

  get chart_type() {
    switch (this.options.metric) {
      case 'audience_country':
        return 'custom/world'
      case 'audience_city':
        return 'countries/jp/jp-all'
      default:
        return ''
    }
  }

  get daily_data_last() {
    const daily_data = instagram_insight.getMetricArray<any>(this.daily_data, this.options.metric)

    const daily_data_last = daily_data.find(v => Object.keys(v).length > 0)

    return daily_data_last ? daily_data_last : {}
  }

  get chart_data() {
    switch (this.options.metric) {
      case 'audience_country':
        return this.getCountryMap(this.daily_data_last)
      case 'audience_city':
        return this.getJapanMap(this.daily_data_last)
      default:
        return []
    }
  }

  get map_names() {
    const { features } = Highcharts.maps[this.chart_type]
    const properties = features.map(f => f.properties)
    return properties
  }

  get csv_name() {
    switch (this.options.metric) {
      case 'audience_country':
        return this.$options.filters.translate('国')
      case 'audience_city':
        return this.$options.filters.translate('都道府県')
      default:
        return ''
    }
  }

  get csv_metric() {
    switch (this.options.metric) {
      case 'audience_country':
      case 'audience_city':
        return this.$options.filters.translate('フォロワー数')
      default:
        return ''
    }
  }

  /**
   * 国のマップ配列を取得
   * @param {any} api
   * @returns {any[]} マップ配列
   */
  getCountryMap(api: any): any[][] {
    if (Object.keys(api).length === 0) return []

    const country_keys = this.map_names.map(v => v['hc-a2'])
    const country = {}

    Object.keys(api).forEach(key => {
      if (country_keys.indexOf(key) >= 0) {
        country[key] = country[key] ? country[key] + api[key] : api[key]
      }
    })

    return Object.keys(country)
      .map(key => [key, country[key]])
      .sort((a, b) => (a[1] < b[1] ? 1 : -1))
  }

  /**
   * 日本のマップ配列を取得
   * @param {any} api
   * @returns {any[]} マップ配列
   */
  getJapanMap(api: any): any[][] {
    if (Object.keys(api).length === 0) return []

    const japan_keys = this.map_names.map(v => v['name'])
    const japan = {}

    Object.keys(api).forEach(key => {
      if (japan_keys.indexOf(key) >= 0) {
        japan[key] = japan[key] ? japan[key] + api[key] : api[key]
      }
    })

    return Object.keys(japan)
      .map(key => [key, japan[key]])
      .sort((a, b) => (a[1] < b[1] ? 1 : -1))
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    TrackingService.sendEvent(
      `click:自社分析(IG)>${this.screen_name}|${this.options.metric}:CSVダウンロード`
    )

    const fields = [
      this.$options.filters.translate(this.csv_name),
      this.$options.filters.translate(this.csv_metric)
    ]

    const data = this.chart_data.map(d => {
      switch (this.options.metric) {
        case 'audience_country': {
          const country = this.map_names.find(v => v['hc-a2'] === d[0])

          if (this.user.language === 'ja') {
            return country ? [country['jp-name'], d[1]] : d
          } else {
            return country ? [country['name'], d[1]] : d
          }
        }

        case 'audience_city': {
          const city = this.map_names.find(v => v['name'] === d[0])

          if (this.user.language === 'ja') {
            return city ? [city['jp-name'], d[1]] : d
          } else {
            return city ? [city['name'], d[1]] : d
          }
        }

        default:
          return d
      }
    })

    const csv_data = { fields, data }

    const component_name = this.$options.filters.translate('グラフ')
    const metric_name = this.$options.filters.translate(this.metric_name)

    const csv_filename = [component_name, metric_name, this.start_date, this.end_date].join('_')

    csv.download(csv_data, csv_filename)
  }
}
