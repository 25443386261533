import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  IDeleteInstagramInsightsWidgetSettingsResponse,
  IGetInstagramInsightsAccountsParams,
  IGetInstagramInsightsAccountsResponse,
  IGetInstagramInsightsPostsParams,
  IGetInstagramInsightsPostsResponse,
  IGetInstagramInsightsStoriesParams,
  IGetInstagramInsightsStoriesResponse,
  IGetInstagramInsightsTableSettingsParams,
  IGetInstagramInsightsTableSettingsResponse,
  IGetInstagramInsightsWidgetSettingsByIdResponse,
  IGetInstagramInsightsWidgetSettingsParams,
  IGetInstagramInsightsWidgetSettingsResponse,
  IInstagramInsightsAccounts,
  IInstagramInsightsPosts,
  IInstagramInsightsStories,
  IInstagramInsightsTableSettings,
  IInstagramInsightsWidgetSettings,
  IPostInstagramInsightsWidgetSettingLayoutsParams,
  IPostInstagramInsightsWidgetSettingLayoutsResponse,
  IPostInstagramInsightsWidgetSettingsParams,
  IPostInstagramInsightsWidgetSettingsResponse,
  IPutInstagramInsightsWidgetSettingsParams,
  IPutInstagramInsightsWidgetSettingsResponse
} from '@/client/utils/api/instagram_insights'
import storage from '@/client/utils/storage'

export interface IState {
  api_accounts: IInstagramInsightsAccounts[]
  api_posts: IInstagramInsightsPosts[]
  api_stories: IInstagramInsightsStories[]
  api_table_setting: IInstagramInsightsTableSettings
  api_widget_settings: IInstagramInsightsWidgetSettings[]
  account_id: string
  screen_name: 'summary' | 'post' | 'stories' | 'follower' | 'action' | 'reach'
  post_search_type: string[]
  stories_search_type: string[]
  search_category: number[]
  search_message: string
  is_loading: boolean
  post_display_count: 10 | 25 | 50 | 100
}

const state: IState = {
  api_accounts: [],
  api_posts: [],
  api_stories: [],
  api_table_setting: { id: 0, columns: [] },
  api_widget_settings: [],
  account_id: '',
  screen_name: 'summary',
  post_search_type: [],
  stories_search_type: [],
  search_category: [],
  search_message: '',
  is_loading: false,
  post_display_count: 25
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  SET_API_INSIGHTS(state, payload) {
    state.api_accounts = payload.accounts
    state.api_posts = payload.posts
    state.api_stories = payload.stories
  },
  SET_API_STORIES(state, payload: IGetInstagramInsightsStoriesResponse) {
    state.api_stories = payload.data
  },
  SET_API_TABLE_SETTING(state, payload: IGetInstagramInsightsTableSettingsResponse) {
    state.api_table_setting = payload.data
  },
  SET_API_WIDGET_SETTINGS(state, payload: IGetInstagramInsightsWidgetSettingsResponse) {
    state.api_widget_settings = payload.data
  },
  SET_PARAMS(state, payload) {
    state.account_id = payload
    storage.set('instagram_analytics', { account_id: payload })
  },
  SET_SCREEN(state, payload) {
    state.screen_name = payload
  },
  SET_POST_SEARCH_TYPE(state, payload) {
    state.post_search_type = payload
  },
  SET_STORIES_SEARCH_TYPE(state, payload) {
    state.stories_search_type = payload
  },
  SET_SEARCH_CATEGORY(state, payload) {
    state.search_category = payload
  },
  SET_SEARCH_MESSAGE(state, payload) {
    state.search_message = payload
  },
  SET_LOADING(state, payload) {
    state.is_loading = payload
  },
  SET_POST_DISPLAY_COUNT(state, payload: 10 | 25 | 50 | 100) {
    state.post_display_count = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * ページ表示時の処理
   */
  async createdInstagramAnalyticsPage(context, payload?: { account_id?: string }) {
    const api: string[] = context.rootGetters['accounts/instagram_analytics'].map(v => v.id)
    const local: { account_id?: string } = storage.get('instagram_analytics')

    let account_id = ''

    // ストレージに保存されていたら使用する
    if (local && local.account_id && api.includes(local.account_id)) {
      account_id = local.account_id
    }

    // ストレージに無い場合はアカウント一覧の最初のアカウントをデフォルトにする
    if (account_id === '' && api[0]) {
      account_id = api[0]
    }

    if (payload && payload.account_id && api.includes(payload.account_id)) {
      account_id = payload.account_id
    }

    await context.dispatch('getInsights', { account_id })
  },

  /**
   * ページ離脱時の処理
   */
  async destroyedInstagramAnalyticsPage(context) {
    context.commit('SET_API_INSIGHTS', { accounts: [], posts: [], stories: [] })
    context.commit('SET_API_TABLE_SETTING', { data: { id: 0, columns: [] } })
    context.commit('SET_API_WIDGET_SETTINGS', { data: [] })
  },

  /**
   * グループ変更時の処理
   */
  async changeGroupInstagramAnalyticsPage(context) {
    await context.dispatch('destroyedInstagramAnalyticsPage')

    context.commit('SET_POST_SEARCH_TYPE', [])
    context.commit('SET_STORIES_SEARCH_TYPE', [])
    context.commit('SET_SEARCH_CATEGORY', [])
    context.commit('SET_SEARCH_MESSAGE', '')

    await context.dispatch('createdInstagramAnalyticsPage')
  },

  /**
   * 投稿の表示件数の変更
   */
  changePostDisplayCount(context, payload: number) {
    context.commit('SET_POST_DISPLAY_COUNT', payload)
  },

  /**
   * 分析データの取得
   */
  async getInsights(context, payload) {
    context.commit('SET_PARAMS', payload.account_id)

    if (context.state.account_id === '') return

    context.commit('SET_LOADING', true)

    const category = { target: 'instagram', account_ids: [context.state.account_id] }

    await Promise.all([
      context.dispatch('categories/fetchCategoryAnalysisPost', category, { root: true }),
      context.dispatch('getWidgetSettings'),
      context.dispatch('getTableSetting')
    ])

    const params:
      | IGetInstagramInsightsAccountsParams
      | IGetInstagramInsightsPostsParams
      | IGetInstagramInsightsStoriesParams = {
      account_id: context.state.account_id,
      start_date: context.rootState.analytics.start_date,
      end_date: context.rootState.analytics.end_date,
      project_id: context.rootState.project.id
    }

    const [accounts, posts, stories] = await Promise.all([
      API.get<IGetInstagramInsightsAccountsResponse>('instagram_insights/accounts', {
        params
      }),
      API.get<IGetInstagramInsightsPostsResponse>('instagram_insights/posts', { params }),
      API.get<IGetInstagramInsightsStoriesResponse>('instagram_insights/stories', { params })
    ])

    context.commit('SET_API_INSIGHTS', {
      accounts: accounts.data.data,
      posts: posts.data.data,
      stories: stories.data.data
    })

    context.commit('SET_LOADING', false)
  },

  /**
   * スクリーン情報の取得
   */
  async getScreen(context, payload) {
    context.commit('SET_SCREEN', payload)
  },

  /**
   * テーブル設定の取得
   */
  async getTableSetting(context) {
    const params: IGetInstagramInsightsTableSettingsParams = {
      project_id: context.rootState.project.id
    }

    const { data } = await API.get<IGetInstagramInsightsTableSettingsResponse>(
      'instagram_insights/table_settings',
      { params }
    )

    context.commit('SET_API_TABLE_SETTING', data)
  },

  /**
   * テーブル設定の更新
   */
  async putTableSetting(context, payload) {
    const { id } = context.state.api_table_setting

    const params = { columns: payload.columns }

    const { data } = await API.put(`instagram_insights/table_settings/${id}`, params)

    // 更新に成功した場合
    if (data && data.data) {
      await context.dispatch('getTableSetting')
    }

    return data
  },

  /**
   * ウィジェットの取得
   */
  async getWidgetSettings(context) {
    const params: IGetInstagramInsightsWidgetSettingsParams = {
      project_id: context.rootState.project.id,
      account_id: context.state.account_id
    }

    const settings = await API.get<IGetInstagramInsightsWidgetSettingsResponse>(
      'instagram_insights/widget_settings',
      {
        params
      }
    )

    context.commit('SET_API_WIDGET_SETTINGS', settings.data)
  },

  /**
   * IDによるウィジェットの取得
   */
  async getWidgetSettingById(context, payload) {
    const { data } = await API.get<IGetInstagramInsightsWidgetSettingsByIdResponse>(
      `instagram_insights/widget_settings/${payload.id}`
    )

    // 取得に失敗した場合
    if (!data.data || !data.data.id) {
      await context.dispatch('getWidgetSettings')
    }

    return data
  },

  // ウィジェットの作成
  async postWidgetSettings(context, payload) {
    const params: IPostInstagramInsightsWidgetSettingsParams = {
      project_id: context.rootState.project.id,
      account_id: context.state.account_id,
      type: payload.type,
      options: payload.options
    }

    const { data } = await API.post<IPostInstagramInsightsWidgetSettingsResponse>(
      'instagram_insights/widget_settings',
      params
    )

    // 作成に成功した場合
    if (data && data.data) {
      await context.dispatch('getWidgetSettings')
    }

    return data
  },

  /**
   * ウィジェットの更新
   */
  async putWidgetSettings(context, payload) {
    const params: IPutInstagramInsightsWidgetSettingsParams = {
      type: payload.type,
      options: payload.options
    }

    const { data } = await API.put<IPutInstagramInsightsWidgetSettingsResponse>(
      `instagram_insights/widget_settings/${payload.id}`,
      params
    )

    // 更新に成功した場合、またはすでに削除されている場合
    if ((data && data.data) || (data.error && data.error.type === 'NOT_EXISTS')) {
      await context.dispatch('getWidgetSettings')
    }

    return data
  },

  /**
   * ウィジェットの削除
   */
  async deleteWidgetSettings(context, payload) {
    const { data } = await API.delete<IDeleteInstagramInsightsWidgetSettingsResponse>(
      `instagram_insights/widget_settings/${payload.id}`
    )

    // 削除に成功した場合、またはすでに削除されている場合
    if ((data && data.data) || (data.error && data.error.type === 'NOT_EXISTS')) {
      await context.dispatch('getWidgetSettings')
    }

    return data
  },

  /**
   * ウィジェットのレイアウト更新
   */
  async postWidgetSettingsLayouts(context, payload) {
    const params: IPostInstagramInsightsWidgetSettingLayoutsParams = {
      layouts: payload.layouts,
      project_id: context.rootState.project.id
    }

    const { data } = await API.post<IPostInstagramInsightsWidgetSettingLayoutsResponse>(
      'instagram_insights/widget_settings/layouts',
      params
    )

    // レイアウト更新に成功した場合
    if (data && data.data) {
      await context.dispatch('getWidgetSettings')
    }

    return data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
