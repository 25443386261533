import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Account from '@/client/components-old/molecules/Account'
import { TDashboardsMonitoringMemo, TDashboardsPostMemo } from '@/client/utils/api/dashboards'
import i18n from '@/client/utils/i18n'
import { convertMemoMessage } from '@/client/utils/regex'

@Component({
  name: 'DashboardMemoItem',
  components: {
    Account,
    Icon
  }
})
export default class DashboardMemoItem extends Vue {
  @Prop({ type: String, required: true })
  type!: 'post' | 'monitoring'

  @Prop({ type: Object, required: true })
  memo!: TDashboardsPostMemo & TDashboardsMonitoringMemo

  @Prop({ type: Boolean, default: false })
  isShowGroup!: boolean

  get datetime() {
    return moment(this.memo.datetime).format('lll')
  }

  get memo_message() {
    return convertMemoMessage(this.memo.message)
  }

  get message() {
    switch (this.type) {
      case 'post':
        return this.memo.post.message || i18n.t('この投稿は内容がありません')
      case 'monitoring':
        return this.memo.monitoring.message || i18n.t('このアクションはメッセージがありません')
      default:
        return ''
    }
  }

  get is_story_by_post() {
    return this.type === 'post' && this.memo.type === 'story'
  }

  @Emit('open')
  open() {
    switch (this.type) {
      case 'post':
        return {
          sns_post_id: this.memo.post.id,
          project_id: this.memo.project.id
        }

      case 'monitoring':
        return {
          monitoring_id: this.memo.monitoring.id,
          monitoring_sns: this.memo.monitoring.sns,
          project_id: this.memo.project.id
        }

      default:
        return {}
    }
  }
}
