
import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import { IState as IPostCreateState } from '@/client/store/modules/post_create'

const accounts = namespace('accounts')
const post_create = namespace('post_create')

@Component({
  name: 'PostCreateAutoPublishSetting',
  components: {
    Icon,
    Panel,
    SwitchButton,
    Tooltip
  }
})
export default class PostCreateAutoPublishSetting extends Vue {
  @State('user') user!: IRootState['user']
  @accounts.Getter('post') sns_accounts!: IAccountsGetter['post']
  @post_create.State('accounts') accounts!: IPostCreateState['accounts']
  @post_create.State('is_auto_publish')
  is_auto_publish!: IPostCreateState['is_auto_publish']
  @post_create.State('is_media_uploading')
  is_media_uploading!: IPostCreateState['is_media_uploading']
  @post_create.Action('changeInstagramAutoPublishStatus') changeInstagramAutoPublishStatus!: any

  get disabled() {
    const is_instagram_publish = this.sns_accounts
      .filter(a => this.accounts.some(b => a.id === b.id && a.sns === b.sns))
      .some(v => v.is_publish_permission)

    return !is_instagram_publish || this.is_media_uploading
  }

  get is_languege_ja() {
    return this.user.language === 'ja'
  }

  /**
   * Instagramに自動公開フラグの変更
   */
  changeInstagramAutoPublish() {
    TrackingService.sendEvent('switch:投稿作成|IG⾃動公開')

    this.changeInstagramAutoPublishStatus()
  }
}
