import { Component, Prop, Vue } from 'vue-property-decorator'

import { EmojiPicker } from '@/client/components/atoms'

@Component({
  name: 'TextareaEmoji',
  components: {
    EmojiPicker
  }
})
export default class TextareaEmoji extends Vue {
  @Prop({ type: String, required: true })
  value: string

  @Prop({ type: String, default: '' })
  placeholder: string

  @Prop({ type: Number, default: 5 })
  rows: number

  @Prop({ type: Boolean, default: false })
  disabled: boolean

  $refs: {
    textarea: HTMLTextAreaElement
  }

  get textarea() {
    return this.value
  }

  set textarea(val) {
    this.$emit('input', val)
  }

  get is_emoji() {
    return !this.disabled && this.$mq !== 'sm'
  }

  /**
   * 絵文字を追加
   */
  async addEmoji(emoji: string): Promise<void> {
    const startPos = this.$refs.textarea.selectionStart

    const value =
      this.value.substring(0, startPos) + emoji + this.value.substring(startPos, this.value.length)

    this.$emit('input', value)

    await this.$nextTick()

    this.$refs.textarea.selectionStart = startPos + emoji.length
  }

  onMetaEnter(e: any): void {
    if (e.metaKey) {
      this.$emit('meta-enter', e)
    }
  }
}
