
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import Panel from '@/client/components-old/atoms/Panel'
import Radio from '@/client/components-old/atoms/Radio'
import { TrackingService } from '@/client/services'
import { getTranslateText as t } from '@/client/utils/filters'

export default defineComponent({
  name: 'PostCreateInstagramPostType',
  components: {
    Panel,
    Radio
  },
  setup() {
    return {
      t
    }
  },
  computed: {
    ...mapState('post_create', ['instagram_post_type', 'message', 'is_media_uploading']),
    ...mapGetters('post_create', ['is_instagram_media_not_exist']),
    ...mapGetters('post_create', ['is_instagram_media_not_exist'])
  },
  methods: {
    ...mapActions('post_create', ['changeInstagramPostType']),
    changePostType(type: 'post' | 'story') {
      switch (type) {
        case 'post':
          TrackingService.sendEvent('radio:投稿作成|投稿タイプ:フィード/リール')
          break
        case 'story':
          TrackingService.sendEvent('radio:投稿作成|投稿タイプ:ストーリーズ')
          break
      }

      if (this.is_instagram_media_not_exist && !this.message.master && !this.message.instagram) {
        this.changeInstagramPostType(type)
        return
      }

      if (confirm(t('投稿タイプを変更すると、投稿内容やメディアがリセットされます。'))) {
        this.changeInstagramPostType(type)
        return
      }

      // ? storeの値は更新されないがdom表示だけ変更されてしまうため、元の値に戻す
      const checked_radio = document.querySelector<HTMLInputElement>(`input[value="${type}"]`)
      const ex_checked_radio = document.querySelector<HTMLInputElement>(
        `input[value="${this.instagram_post_type}"]`
      )

      if (!checked_radio || !ex_checked_radio) return

      checked_radio.checked = false
      ex_checked_radio.checked = true
    }
  }
})
