import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import SwitchRow from '@/client/components-old/molecules/SwitchRow'
import { IState as IGroupSettingState } from '@/client/store/modules/group_setting'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

@Component({
  name: 'GroupSettingMonitoringGlobalKeyword',
  components: {
    Icon,
    Panel,
    Tooltip,
    SwitchRow
  }
})
export default class GroupSettingMonitoringGlobalKeyword extends Vue {
  @group_setting.State('api_monitoring_settings')
  api_monitoring_settings: IGroupSettingState['api_monitoring_settings']
  @group_setting.Action('updateMonitoringSettings') updateMonitoringSettings
  @notification.Action('showNotification') showNotification

  /**
   * NGワード辞書の更新
   */
  async changeIsValidGlobalKeywords(value: boolean): Promise<void> {
    const payload = {
      is_valid_global_keywords: value
    }

    const result = await this.updateMonitoringSettings(payload)

    if (result.data) {
      return this.showNotification({ title: '設定を保存しました。' })
    }

    this.showNotification({
      title: '設定の保存に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }
}
