import { IImage } from '@/client/utils/api/images'
import { IVideo } from '@/client/utils/api/videos'
import i18n from '@/client/utils/i18n'

import * as errors from './errors'
import * as facebook from './facebook'
import * as instagram from './instagram'
import * as tiktok from './tiktok'
import * as twitter from './twitter'

interface IMediaError {
  error?: {
    title: string
    message?: string
  }
}

const MB = 2 ** 20

/**
 * 画像の非同期読み込み
 */
function imageLoad(url: string): Promise<HTMLImageElement | null> {
  return new Promise(resolve => {
    const img = document.createElement('img')

    img.onerror = () => {
      resolve(null)
    }

    img.onload = () => {
      resolve(img)
    }

    img.src = url
  })
}

/**
 * 動画の非同期読み込み
 */
function videoLoad(url: string): Promise<HTMLVideoElement | null> {
  return new Promise(resolve => {
    const video = document.createElement('video')

    video.onerror = () => {
      resolve(null)
    }

    video.onloadedmetadata = () => {
      resolve(video)
    }

    video.src = url
  })
}

/**
 * 共通で使える画像拡張子
 */
export const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif']

/**
 * 共通で使える動画拡張子
 */
export const VIDEO_TYPES = ['video/mp4']

/**
 * 画像アップロード前の検証
 */
export async function checkImageUploadBefore(
  file: File,
  type: 'normal' | 'link',
  is_instagram: boolean,
  is_instagram_reels: boolean
): Promise<IMediaError> {
  if (!IMAGE_TYPES.includes(file.type)) {
    return errors.imageExtension()
  }

  const el = await imageLoad(URL.createObjectURL(file))

  if (el === null) {
    return errors.imageNotSupport()
  }

  if (file.size > 15 * MB) {
    return errors.imageSize(file.size)
  }

  const max_size = type === 'normal' ? 10000 : 5000
  const min_size = type === 'normal' ? 5 : 112

  if (el.naturalWidth > max_size || el.naturalHeight > max_size) {
    return errors.imageResolutionMax(max_size)
  }

  if (el.naturalWidth < min_size || el.naturalHeight < min_size) {
    return errors.imageResolutionMin(min_size)
  }

  if (is_instagram && is_instagram_reels) {
    return errors.videoSpecForInstagramReels()
  }

  return {}
}

/**
 * 画像アップロード後の検証
 */
export async function checkImageUploadAfter(
  image: IImage,
  images: string[],
  is_animation_gif: boolean,
  is_twitter: boolean,
  is_instagram: boolean,
  instagram_post_type: 'post' | 'story',
  is_change?: boolean,
  is_thumbnail?: boolean
): Promise<IMediaError> {
  if (!IMAGE_TYPES.includes(image.image_type)) {
    return errors.imageExtension()
  }

  const el = await imageLoad(image.image_url)

  if (el === null) {
    return errors.imageNotSupport()
  }

  if (is_twitter && !is_thumbnail) {
    if (image.is_animation_gif) {
      if (!twitter.checkAnimationGifSize(image.image_size)) {
        return errors.animationGifTwitterSize(image.image_size)
      }

      if (!twitter.checkAnimationGifResolution(el.naturalWidth, el.naturalHeight)) {
        return errors.animationGifTwitterResolution()
      }
    } else {
      if (!twitter.checkImageSize(image.image_size)) {
        return errors.imageSize(image.image_size)
      }
    }
  }

  if (is_instagram && !is_thumbnail) {
    if (image.is_animation_gif) {
      return errors.animationGifInstagram()
    } else {
      if (!instagram.checkImageType(image.image_type)) {
        return errors.imageExtension()
      }

      if (!instagram.checkImageSize(image.image_size)) {
        return errors.imageSize(image.image_size)
      }

      if (instagram_post_type === 'post') {
        if (!instagram.checkImageAspectRatio(image.image_width, image.image_height)) {
          return errors.imageAspectRatio()
        }
      }
    }
  }

  if (image.is_animation_gif) {
    if (is_change && images.length > 1) {
      return errors.animationGifMulti()
    }

    if (!is_change && images.length) {
      return errors.animationGifMulti()
    }
  } else {
    if (image.image_size >= 5 * MB) {
      return errors.imageSize(image.image_size)
    }

    if (!is_change && is_animation_gif && images.length) {
      return errors.animationGifMulti()
    }
  }

  return {}
}

/**
 * アカウント変更後の画像検証
 */
export async function checkImageAccountChange(
  image_url: string,
  is_animation_gif: boolean,
  is_twitter: boolean
): Promise<string> {
  const confirmMessage = (result: IMediaError): string => {
    let message = result.error.title + '\n\n'

    message +=
      i18n.t('このままSNSアカウントを追加すると、メディアの設定は全てリセットされます。') + '\n'
    message += i18n.t('SNSアカウントを追加してよろしいですか？')

    return message
  }

  const el = await imageLoad(image_url)

  if (
    is_twitter &&
    is_animation_gif &&
    !twitter.checkAnimationGifResolution(el.naturalWidth, el.naturalHeight)
  ) {
    return confirmMessage(errors.animationGifTwitterResolution())
  }

  return ''
}

/**
 * 動画アップロード前の検証
 */
export async function checkVideoUploadBefore(
  file: File,
  is_facebook: boolean,
  is_twitter: boolean,
  is_instagram: boolean,
  is_tiktok: boolean,
  instagram_post_type: 'post' | 'story',
  is_instagram_reels: boolean,
  is_instagram_carousel: boolean
): Promise<IMediaError> {
  if (!VIDEO_TYPES.includes(file.type)) {
    return errors.videoExtension()
  }

  const el = await videoLoad(URL.createObjectURL(file))

  if (el === null || el.videoWidth <= 0 || el.videoHeight <= 0) {
    return errors.videoNotSupport()
  }

  if (is_facebook) {
    if (!facebook.checkVideoSize(file.size)) {
      return errors.videoSize(file.size)
    }

    if (!facebook.checkVideoDuration(el.duration)) {
      return errors.videoDuration()
    }
  }

  if (is_twitter) {
    if (!twitter.checkVideoSize(file.size)) {
      return errors.videoSize(file.size)
    }

    if (!twitter.checkVideoDuration(el.duration)) {
      return errors.videoDuration()
    }
  }

  if (is_instagram) {
    if (instagram_post_type === 'post') {
      if (!instagram.checkVideoSize(file.size)) {
        return errors.videoSize(file.size)
      }

      if (!instagram.checkVideoDuration(el.duration)) {
        return errors.videoDuration()
      }

      if (is_instagram_reels) {
        return errors.videoSpecForInstagramReels()
      }

      if (is_instagram_carousel) {
        if (!instagram.checkVideoDurationForCarousel(el.duration)) {
          return errors.videoSpecForInstagramReels()
        }
      }
    }

    if (instagram_post_type === 'story') {
      if (!instagram.checkVideoSizeByStory(file.size)) {
        return errors.videoSize(file.size)
      }

      if (!instagram.checkVideoDurationByStory(el.duration)) {
        return errors.videoDuration()
      }
    }

    if (is_tiktok) {
      if (!tiktok.validateVideoFileSize(file.size)) {
        return errors.videoSize(file.size)
      }

      if (!tiktok.validateVideoDuration(el.duration)) {
        return errors.videoDuration()
      }

      if (!tiktok.validateVideoResolution(el.videoWidth, el.videoHeight)) {
        return errors.videoResolution()
      }
    }
  }

  return {}
}

/**
 * 動画アップロード後の検証
 */
export async function checkVideoUploadAfter(
  video: IVideo,
  is_facebook: boolean,
  is_twitter: boolean,
  is_instagram: boolean,
  is_tiktok: boolean,
  instagram_post_type: 'post' | 'story',
  is_instagram_reels: boolean,
  is_instagram_carousel: boolean
): Promise<IMediaError> {
  if (!VIDEO_TYPES.includes(video.video_type)) {
    return errors.videoExtension()
  }

  const el = await videoLoad(video.video_url)

  if (el === null || el.videoWidth <= 0 || el.videoHeight <= 0) {
    return errors.videoNotSupport()
  }

  if (is_facebook) {
    if (!facebook.checkVideoSize(video.video_size)) {
      return errors.videoSize(video.video_size)
    }

    if (!facebook.checkVideoDuration(el.duration)) {
      return errors.videoDuration()
    }

    if (!facebook.checkVideoResolution(el.videoWidth, el.videoHeight)) {
      return errors.videoResolution()
    }
  }

  if (is_twitter) {
    if (!twitter.checkVideoSize(video.video_size)) {
      return errors.videoSize(video.video_size)
    }

    if (!twitter.checkVideoDuration(el.duration)) {
      return errors.videoDuration()
    }

    if (!twitter.checkVideoResolution(el.videoWidth, el.videoHeight)) {
      return errors.videoResolution()
    }

    if (!twitter.checkVideoAspectRatio(el.videoWidth, el.videoHeight)) {
      return errors.videoAspectRatio()
    }

    if (!twitter.checkVideoFrameRate(video.video_frame_rate)) {
      return errors.videoFrameRate()
    }

    if (!twitter.checkVideoAudioChannels(video.video_audio_channels)) {
      return errors.videoAudioChannels()
    }

    if (!twitter.checkVideoAudioFormat(video.video_audio_format)) {
      return errors.videoAudioFormat()
    }
  }

  if (is_instagram) {
    if (instagram_post_type === 'post') {
      if (!instagram.checkVideoSize(video.video_size)) {
        return errors.videoSize(video.video_size)
      }

      if (!instagram.checkVideoDuration(el.duration)) {
        return errors.videoDuration()
      }

      if (is_instagram_reels) {
        return errors.videoSpecForInstagramReels()
      }

      if (is_instagram_carousel) {
        if (!instagram.checkVideoDurationForCarousel(el.duration)) {
          return errors.videoSpecForInstagramReels()
        }

        if (!instagram.checkVideoAspectRatioForCarousel(el.videoWidth, el.videoHeight)) {
          return errors.videoAspectRatio()
        }
      } else {
        if (!instagram.checkVideoAspectRatioForReels(el.videoWidth, el.videoHeight)) {
          return errors.videoAspectRatio()
        }
      }
    }

    if (instagram_post_type === 'story') {
      if (!instagram.checkVideoSizeByStory(video.video_size)) {
        return errors.videoSize(video.video_size)
      }

      if (!instagram.checkVideoDurationByStory(el.duration)) {
        return errors.videoDuration()
      }

      if (!instagram.checkVideoAspectRatioForStories(el.videoWidth, el.videoHeight)) {
        return errors.videoAspectRatio()
      }
    }

    if (!instagram.checkVideoResolution(el.videoWidth, el.videoHeight)) {
      return errors.videoResolution()
    }

    if (!instagram.checkVideoFrameRate(video.video_frame_rate)) {
      return errors.videoFrameRate()
    }

    if (!instagram.checkVideoBitRate(video.video_bit_rate)) {
      return errors.videoBitRate()
    }

    if (!instagram.checkVideoAudioChannels(video.video_audio_channels)) {
      return errors.videoAudioChannels()
    }
  }

  if (is_tiktok) {
    if (!tiktok.validateVideoFileSize(video.video_size)) {
      return errors.videoSize(video.video_size)
    }

    if (!tiktok.validateVideoDuration(el.duration)) {
      return errors.videoDuration()
    }

    if (!tiktok.validateVideoResolution(el.videoWidth, el.videoHeight)) {
      return errors.videoResolution()
    }

    if (!tiktok.validateVideoFrameRate(video.video_frame_rate)) {
      return errors.videoFrameRate()
    }
  }

  return {}
}

/**
 * アカウント変更後の動画検証
 */
export async function checkVideoAccountChange(
  video: IVideo,
  is_facebook: boolean,
  is_twitter: boolean,
  is_instagram: boolean,
  is_tiktok: boolean,
  instagram_post_type: 'post' | 'story',
  is_instagram_reels: boolean,
  is_instagram_carousel: boolean
): Promise<string> {
  const result = await checkVideoUploadAfter(
    video,
    is_facebook,
    is_twitter,
    is_instagram,
    is_tiktok,
    instagram_post_type,
    is_instagram_reels,
    is_instagram_carousel
  )

  const confirmMessage = (result: IMediaError): string => {
    let message = result.error.title + '\n\n'

    message +=
      i18n.t('このままSNSアカウントを追加すると、メディアの設定は全てリセットされます。') + '\n'
    message += i18n.t('SNSアカウントを追加してよろしいですか？')

    return message
  }

  if (result.error) {
    return confirmMessage(result)
  }

  return ''
}

export async function checkVideoInstagramReels(url: string) {
  const video = await videoLoad(url)

  if (!video) return false

  return instagram.checkVideoReels(video)
}
