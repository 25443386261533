
import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Checkbox from '@/client/components-old/atoms/Checkbox'
import Input from '@/client/components-old/atoms/Input'
import Label from '@/client/components-old/atoms/Label'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import { IRootState } from '@/client/store/global'
import { IState as ISignupState } from '@/client/store/modules/signup'
import { IPostSignupExistsResponse } from '@/client/utils/api/signup'
import i18n from '@/client/utils/i18n'
import {
  MAIL_FORMAT,
  PASSWORD_CHARACTER,
  PASSWORD_REQUIRED,
  TELEPHONE_FORMAT
} from '@/client/utils/regex'

const signup = namespace('signup')
const notification = namespace('notification')

@Component({
  name: 'SignupForm',
  components: {
    Button,
    ButtonLink,
    Checkbox,
    Input,
    Label,
    Message,
    Panel,
    SelectMulti
  }
})
export default class SignupForm extends Vue {
  @State('user') user!: IRootState['user']
  @signup.State('form_data') form_data!: ISignupState['form_data']
  @signup.State('api_user_works') api_user_works!: ISignupState['api_user_works']
  @signup.Action('confirmFormData') confirmFormData: any
  @notification.Action('showNotification') showNotification: any

  form: ISignupState['form_data'] = {
    email: '',
    password: '',
    name: '',
    company_name: '',
    telephone: '',
    user_work_ids: [],
    terms_of_service: false
  }

  is_email_error = false
  is_email_invalid = false
  is_password_error = false
  is_password_format_error = false
  is_password_character_error = false
  is_password_required_error = false
  is_name_error = false
  is_company_name_error = false
  is_telephone_error = false
  is_telephone_format = false

  loading = false

  get is_lang_ja() {
    return this.user.language === 'ja'
  }

  get user_work_options() {
    return this.api_user_works.map(v => ({ value: v.id, text: i18n.t(v.name) }))
  }

  get error_email() {
    return this.is_email_error || this.is_email_invalid
  }

  get error_password() {
    return (
      this.is_password_error ||
      this.is_password_format_error ||
      this.is_password_character_error ||
      this.is_password_required_error
    )
  }

  get error_name() {
    return this.is_name_error
  }

  get error_company_name() {
    return this.is_company_name_error
  }

  get error_telephone() {
    return this.is_telephone_error || this.is_telephone_format
  }

  get disabled() {
    const is_email = !this.form.email || this.error_email
    const is_password = !this.form.password || this.error_password
    const is_name = !this.form.name || this.error_name
    const is_company_name = !this.form.company_name || this.error_company_name
    const is_telephone = !this.form.telephone || this.error_telephone

    return (
      this.loading ||
      is_email ||
      is_password ||
      is_name ||
      is_company_name ||
      is_telephone ||
      !this.form.terms_of_service
    )
  }

  /**
   * ページの表示時
   */
  created() {
    this.form = { ...this.form_data }
  }

  /**
   * メールの変更
   */
  changeEmail(value: string) {
    this.is_email_error = !value
    this.is_email_invalid = !MAIL_FORMAT.test(this.form.email)
  }

  /**
   * パスワードの変更
   */
  changePassword(value: string) {
    this.is_password_error = !value
    this.is_password_format_error = value.length < 8 || value.length > 60
    this.is_password_character_error = !PASSWORD_CHARACTER.test(value)
    this.is_password_required_error = !PASSWORD_REQUIRED.test(value)
  }

  /**
   * 氏名の変更
   */
  changeName(value: string) {
    this.is_name_error = !value
  }

  /**
   * 会社名の変更
   */
  changeCompanyName(value: string) {
    this.is_company_name_error = !value
  }

  /**
   * 電話番号の変更
   */
  changeTelephone(value: string) {
    this.is_telephone_error = !value
    this.is_telephone_format = !TELEPHONE_FORMAT.test(this.form.telephone)
  }

  /**
   * フォームの検証チェック
   */
  async confirmData() {
    if (this.disabled) return

    this.loading = true

    this.form.name = this.form.name.trim()
    this.form.company_name = this.form.company_name.trim()

    const result: IPostSignupExistsResponse = await this.confirmFormData(this.form)

    this.loading = false

    if (result.data) {
      return this.showNotification({
        title: '登録済のメールアドレスです。',
        message: 'ログイン画面からログインしてください。',
        type: 'error'
      })
    }
  }
}
