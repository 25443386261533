
import { defineComponent, PropType } from 'vue'

type PlacementProps =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
  | 'right'
  | 'right-start'
  | 'right-end'

export default defineComponent({
  name: 'AppTooltip',
  props: {
    placement: {
      type: String as PropType<PlacementProps>,
      default: 'top'
    },
    content: {
      type: String,
      default: ''
    }
  }
})
