
import { computed, defineComponent } from 'vue'
import draggable from 'vuedraggable'
import { mapActions } from 'vuex'

import Button from '@/client/components-old/atoms/Button'
import Checkbox from '@/client/components-old/atoms/Checkbox'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import API from '@/client/utils/api'
import { getTranslateText as t } from '@/client/utils/filters'

import {
  IGetSnsAnalyticsAccountTableSettingsParams,
  IGetSnsAnalyticsAccountTableSettingsResponse,
  IPostSnsAnalyticsAccountTableSettingsParams,
  IPostSnsAnalyticsAccountTableSettingsResponse
} from '../../api'
import * as constants from '../../constants'

type ISelectedItem = {
  selected: boolean
  text: string
  value: string
}

type ISortItem = {
  text: string
  value: string
}

type DataType = {
  is_visible: boolean
  is_submit: boolean
  sort_list: ISortItem[]
}

export default defineComponent({
  name: 'SnsAnalyticsAccountTableSettingDialog',
  components: {
    draggable,
    Dialog,
    Button,
    Icon,
    Checkbox,
    Tooltip
  },
  props: {
    projectId: {
      type: Number,
      required: true
    }
  },
  setup() {
    const metrics = computed(() => constants.BASIC_TABLE_METRIC)

    return {
      metrics,
      t
    }
  },
  data(): DataType {
    return {
      is_visible: false,
      is_submit: false,
      sort_list: []
    }
  },
  computed: {
    length() {
      return this.sort_list.length
    },

    selected_list() {
      return this.metrics.map(metric => ({
        selected: this.sort_list.map(v => v.value).includes(metric.value),
        text: metric.text,
        value: metric.value
      }))
    }
  },
  methods: {
    ...mapActions('notification', ['showNotification']),

    /**
     * ダイアログの表示
     */
    async open(): Promise<void> {
      const params: IGetSnsAnalyticsAccountTableSettingsParams = {
        project_id: this.projectId
      }

      const response = await API.get<IGetSnsAnalyticsAccountTableSettingsResponse>(
        'sns_analytics/account_table_settings',
        { params }
      )

      if (response.data?.data) {
        this.hide()
        this.sort_list = response.data.data.map(v => this.metrics.find(m => m.value === v))
        this.is_visible = true
        return
      }

      this.showNotification({
        title: '表示列データの取得に失敗しました。',
        message: '時間をおいて再度お試しください。',
        type: 'error'
      })
    },

    /**
     * ダイアログの非表示
     */
    hide(): void {
      this.is_visible = false
      this.sort_list = []
    },

    /**
     * 選択一覧の変更
     */
    changeSelectedList(item: ISelectedItem): void {
      if (item.selected) {
        this.sort_list = [...this.sort_list, { text: item.text, value: item.value }]
      } else {
        this.sort_list = this.sort_list.filter(v => v.value !== item.value)
      }
    },

    /**
     * ソート一覧の削除
     */
    removeSortList(value: string): void {
      this.sort_list = this.sort_list.filter(v => v.value !== value)
    },

    /**
     * 設定の送信
     */
    async submit(): Promise<void> {
      if (this.is_submit) return

      this.is_submit = true

      const params: IPostSnsAnalyticsAccountTableSettingsParams = {
        project_id: this.projectId,
        metric_names: this.sort_list.map(v => v.value)
      }

      const response = await API.post<IPostSnsAnalyticsAccountTableSettingsResponse>(
        'sns_analytics/account_table_settings',
        params
      )

      setTimeout(() => {
        this.is_submit = false
      }, 2000)

      if (response.data?.data) {
        this.hide()
        this.$emit('update-account-table')

        return this.showNotification({ title: '表示列設定を保存しました。' })
      }

      this.showNotification({ title: '表示列設定の保存に失敗しました。', type: 'error' })
    }
  }
})
