import moment from 'moment-timezone'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import * as twitter_trends from '@/client/components-old/_utils/twitter_trends'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import DateRangePicker from '@/client/components-old/molecules/DateRangePicker'
import PaginationTable from '@/client/components-old/molecules/PaginationTable'
import Search from '@/client/components-old/molecules/Search'
import { TrackingService } from '@/client/services'
import API from '@/client/utils/api'
import {
  IGetTwitterTrendsParams,
  IGetTwitterTrendsResponse,
  ITwitterTrends
} from '@/client/utils/api/twitter_trends'
import event from '@/client/utils/event'

@Component({
  name: 'LibrarySearchTwitterTrend',
  components: {
    Panel,
    Message,
    Flex,
    Icon,
    Search,
    DateRangePicker,
    PaginationTable
  }
})
export default class LibrarySearchTwitterTrend extends Vue {
  @Prop({ type: Number, default: null })
  height: number

  search = ''

  twitter_trends: ITwitterTrends = {
    trends: [],
    count: 0
  }

  params: IGetTwitterTrendsParams = {
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: moment().startOf('day').format('YYYY-MM-DD'),
    keyword: '',
    order: '-entry_count',
    page: 1,
    limit: 20
  }

  @Watch('params', { immediate: true, deep: true })
  async watchParams(): Promise<void> {
    await this.getTwitterTrends()
    await this.$nextTick()
    event.resize()
  }

  get total() {
    return this.twitter_trends.count
  }

  get start_date() {
    return moment(this.params.start_date).format('ll')
  }

  get end_date() {
    return moment(this.params.end_date).format('ll')
  }

  openTwitterTrendUrl(word: string) {
    TrackingService.sendEvent('click:Xトレンド|トレンドワード')

    const url = this.createTrendUrl(word)

    window.open(url, '_blank')
  }

  /**
   * トレンド一覧を取得する
   */
  async getTwitterTrends(): Promise<void> {
    const response = await API.get<IGetTwitterTrendsResponse>('twitter_trends', {
      params: this.params
    })

    if (response.data.data) {
      this.twitter_trends = response.data.data
    }
  }

  /**
   * キーワードの検索
   */
  submitSearch(value: string): void {
    this.params.keyword = value
    this.params.page = 1

    TrackingService.sendEvent('click:Xトレンド|キーワード検索')
  }

  /**
   * 選択期間の変更
   */
  changeDateRangePicker(value: [string, string]): void {
    this.params.start_date = value[0]
    this.params.end_date = value[1]
    this.params.page = 1

    TrackingService.sendEvent('select:Xトレンド|期間指定')
  }

  /**
   * トレンドのURLを生成
   */
  createTrendUrl(value: string): string {
    return twitter_trends.createTrendUrl(value)
  }

  /**
   * スクロール位置を最上部に変更する
   */
  scrollTop(): void {
    // el-table のスクロール位置を変更するため、DOM要素を取得する
    const element = document.getElementsByClassName('el-table__body-wrapper')
    if (element && element.length) {
      element[0].scrollTop = 0
    }
  }

  /**
   * テーブルのスタイル追加
   */
  styleSort(val: any): string {
    if (
      this.params.order.indexOf('-') === 0 &&
      this.params.order.substring(1) === val.column.property
    ) {
      return 'sort-metric sort-descending'
    }

    if (this.params.order === val.column.property) {
      return 'sort-metric sort-ascending'
    }

    return ''
  }

  /**
   * ソート変更時
   */
  onSort(val: any): void {
    switch (val.property) {
      case 'word':
        TrackingService.sendEvent('sort:Xトレンド|トレンドワード')
        break
      case 'ranking':
        TrackingService.sendEvent('sort:Xトレンド|最高順位')
        break
      case 'entry_count':
        TrackingService.sendEvent('sort:Xトレンド|トレンド入り回数')
        break
      case 'volume':
        TrackingService.sendEvent('sort:Xトレンド|累計投稿数')
        break
    }

    if (val.property === this.params.order.substring(1)) {
      this.params.order = val.property
    } else {
      this.params.order = '-' + val.property
    }
    this.params.page = 1
  }
}
