import locale from 'element-ui/lib/locale'
import en from 'element-ui/lib/locale/lang/en'
import ja from 'element-ui/lib/locale/lang/ja'
import IPCIDR from 'ip-cidr'
import moment from 'moment-timezone'
import { ActionTree, GetterTree, MutationTree, StoreOptions } from 'vuex'

import { IState as ISnsAnalyticsState } from '@/client/features/sns_analytics/stores'
import { IState as ITikTokAnalyticsState } from '@/client/features/tiktok_analytics/stores'
import API from '@/client/utils/api'
import { TPostEmergencyNotificationReadResponse } from '@/client/utils/api/emergency_notification_reads'
import {
  TEmergencyNotification,
  TGetEmergencyNotificationResponse
} from '@/client/utils/api/emergency_notifications'
import {
  TDeleteMeSessionResponse,
  TGetMeResponse,
  TGetMeSessionResponse,
  TMe
} from '@/client/utils/api/me'
import {
  TGetProjectResponse,
  TGetProjectRolesResponse,
  TGetProjectsResponse,
  TProject,
  TProjectRoles,
  TProjects
} from '@/client/utils/api/projects'
import event from '@/client/utils/event'
import i18n from '@/client/utils/i18n'
import storage from '@/client/utils/storage'

import { IState as IAnalyticsState } from './modules/analytics'
import { IState as ICategoriesState } from './modules/categories'
import { IState as IGroupSettingState } from './modules/group_setting'
import { IState as IPostCreateState } from './modules/post_create'
import { IState as ISnsAccountCategoriesState } from './modules/sns_account_categories'

export interface IRootModule {
  analytics?: IAnalyticsState
  categories?: ICategoriesState
  group_setting?: IGroupSettingState
  post_create?: IPostCreateState
  sns_account_categories?: ISnsAccountCategoriesState
  sns_analytics?: ISnsAnalyticsState
  tiktok_analytics?: ITikTokAnalyticsState
}

export interface IRootState extends IRootModule {
  user: TMe
  projects: TProjects[]
  project: TProject
  project_role: TProjectRoles
  emergency_notification: TEmergencyNotification
  render: string
}

const init_user: TMe = {
  id: 0,
  mail: '',
  name: '',
  picture_url: null,
  language: 'ja',
  timezone: 'Asia/Tokyo',
  created: '',
  modified: '',
  ip_address: '',
  is_super_login: false,
  is_demo_user: false,
  is_show_emergency_notification: false,
  works: []
}

const init_project: TProject = {
  id: 0,
  project_name: '',
  contract_status: 1,
  contract_start_date: null,
  contract_end_date: null,
  trial_start_date: null,
  trial_end_date: null,
  fb_comparison_count: 10,
  tw_comparison_count: 10,
  in_comparison_count: 10,
  charge_keyword_hashtag: 0,
  tw_keyword_max_count: 0,
  in_hashtag_max_count: 0,
  post_approval_flow_max_count: 0,
  keyword_hashtag_max_post_count: 0,
  keyword_hashtag_current_post_count: 0,
  keyword_hashtag_free_post_count: 0,
  fb_monitoring_free_count: 1,
  in_monitoring_free_count: 1,
  tw_monitoring_free_count: 1,
  charge_fb_monitoring: 0,
  charge_in_monitoring: 0,
  charge_tw_monitoring: 0,
  stockphoto_count: 10,
  operator_max_count: 5,
  is_use_analytics: false,
  is_use_tw_keyword: false,
  is_use_in_hashtag: false,
  is_use_tw_viral: false,
  is_use_monitoring: false,
  is_use_post: false,
  allow_ip_addresses: [],
  tw_keyword_active_max_count: 0,
  in_hashtag_active_max_count: 0
}

const init_project_role: TProjectRoles = {
  role_admin: false,
  role_comparison: false,
  role_analytics_setting: false,
  role_keyword: false,
  role_viral: false
}

const init_notification = {
  title_ja: '',
  title_en: '',
  description_ja: '',
  description_en: '',
  type: 0,
  detail_url: ''
}

const state: IRootState = {
  user: init_user,
  projects: [],
  project: init_project,
  project_role: init_project_role,
  emergency_notification: init_notification,
  render: Math.random().toString()
}

export interface IRootGetter {
  is_restricted: boolean
}

const getters: GetterTree<IRootState, IRootState> = {
  is_restricted(state): IRootGetter['is_restricted'] {
    if (state.user.is_super_login || !state.project.allow_ip_addresses.length) return false

    const is_ip_address = state.project.allow_ip_addresses.some(address => {
      if (IPCIDR.isValidCIDR(address)) {
        return new IPCIDR(address).contains(state.user.ip_address)
      }

      if (IPCIDR.isValidAddress(address)) {
        return address === state.user.ip_address
      }

      return false
    })

    return !is_ip_address
  }
}

const mutations: MutationTree<IRootState> = {
  SET_USER_DATA(state, payload: TMe) {
    state.user = payload
  },
  SET_PROJECTS(state, payload: TProjects[]) {
    state.projects = payload
  },
  SET_PROJECT_DATA(state, payload: TProject) {
    state.project = payload
  },
  SET_PROJECT_ROLE(state, payload: TProjectRoles) {
    state.project_role = payload
  },
  SET_EMERGENCY_NOTIFICATION(state, payload: TEmergencyNotification) {
    state.emergency_notification = payload
  },
  SET_LANGUAGE(state, payload) {
    state.user.language = payload
  },
  SET_TIMEZONE(state, payload) {
    state.user.timezone = payload
  },
  SET_RENDER(state, payload: string) {
    state.render = payload
  }
}

const actions: ActionTree<IRootState, IRootState> = {
  /**
   * ログイン
   */
  async login(context) {
    await Promise.all([context.dispatch('fetchUserData'), context.dispatch('fetchProjects')])

    const project_id = storage.getGroupId()

    await Promise.all([
      context.dispatch('fetchProjectData', project_id),
      context.dispatch('fetchProjectRole', project_id),
      context.dispatch('fetchEmergencyNotification')
    ])

    await Promise.all([
      context.dispatch('accounts/fetchSnsAccounts'),
      context.dispatch('categories/fetchCategory'),
      context.dispatch('sns_account_categories/fetchSnsAccountCategories')
    ])

    // 安定化対策
    await event.delay(100)
  },

  /**
   * ログアウト
   */
  async logout() {
    await API.delete<TDeleteMeSessionResponse>('me/session')

    // 安定化対策
    await event.delay(100)
  },

  /*
   * セッション情報のチェック
   */
  async checkSession() {
    const response = await API.get<TGetMeSessionResponse>('me/session')

    return response.data ? response.data.data : false
  },

  /**
   * グループの変更
   */
  async changeGroup(context, payload: number) {
    storage.setGroupId(payload)

    await context.dispatch('fetchProjects')

    const project_id = storage.getGroupId()

    await context.dispatch('fetchProjectData', project_id)
    await context.dispatch('fetchProjectRole', project_id)
    await context.dispatch('accounts/fetchSnsAccounts')
    await context.dispatch('categories/fetchCategory')
    await context.dispatch('sns_account_categories/fetchSnsAccountCategories')
  },

  /*
   * ユーザー情報を取得
   */
  async fetchUserData(context) {
    const response = await API.get<TGetMeResponse>('me')

    if (response.data.data) {
      context.commit('SET_USER_DATA', response.data.data)

      await context.dispatch('fetchLanguage', response.data.data.language)
      await context.dispatch('fetchTimezone', response.data.data.timezone)
    } else {
      context.commit('SET_USER_DATA', init_user)
    }
  },

  /*
   * グループ一覧を取得
   */
  async fetchProjects(context) {
    const response = await API.get<TGetProjectsResponse>('projects')

    if (response.data.data) {
      context.commit('SET_PROJECTS', response.data.data)
    } else {
      context.commit('SET_PROJECTS', [])
    }
  },

  /*
   * グループ情報を取得
   */
  async fetchProjectData(context, payload: number) {
    const response = await API.get<TGetProjectResponse>(`projects/${payload}`)

    if (response.data.data) {
      context.commit('SET_PROJECT_DATA', response.data.data)
    } else {
      context.commit('SET_PROJECT_DATA', init_project)
    }
  },

  /*
   * グループ権限設定を取得
   */
  async fetchProjectRole(context, payload: number) {
    const response = await API.get<TGetProjectRolesResponse>(`projects/${payload}/roles`)

    if (response.data.data) {
      context.commit('SET_PROJECT_ROLE', response.data.data)
    } else {
      context.commit('SET_PROJECT_ROLE', init_project_role)
    }
  },

  /*
   * 言語設定をライブラリに反映
   */
  async fetchLanguage(context, payload: 'ja' | 'en') {
    moment.locale(payload)
    locale.use(payload === 'ja' ? ja : en)
    i18n.changeLanguage(payload, () => {
      context.commit('SET_LANGUAGE', payload)
    })
  },

  /*
   * タイムゾーン設定をライブラリに反映
   */
  async fetchTimezone(context, payload: string) {
    moment.tz.setDefault(payload)
    context.commit('SET_TIMEZONE', payload)
  },

  /**
   * 強制的に再レンダリング
   */
  async forceReRender(context) {
    context.commit('SET_RENDER', Math.random().toString())
  },

  /**
   * 緊急時のお知らせを取得
   */
  async fetchEmergencyNotification(context) {
    const response = await API.get<TGetEmergencyNotificationResponse>('emergency_notifications/1')

    if (response.data.data) {
      context.commit('SET_EMERGENCY_NOTIFICATION', response.data.data)
    } else {
      context.commit('SET_EMERGENCY_NOTIFICATION', init_notification)
    }
  },

  /**
   * 緊急時のお知らせの既読を保存する
   */
  async addEmergencyNotificationRead(context) {
    const params = {
      emergency_notification_id: 1
    }

    const response = await API.post<TPostEmergencyNotificationReadResponse>(
      'emergency_notification_reads',
      params
    )

    if (response.data.data) {
      context.dispatch('fetchUserData')
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
} as StoreOptions<IRootState>
