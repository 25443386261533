
import { Component, Prop, Vue } from 'vue-property-decorator'

type TTooltipPlacement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
  | 'right'
  | 'right-start'
  | 'right-end'

@Component({
  name: 'Tooltip'
})
export default class Tooltip extends Vue {
  @Prop({ type: String, default: 'top' })
  placement!: TTooltipPlacement

  @Prop({ type: String, default: '' })
  content!: string

  @Prop({ type: Boolean, default: true })
  isDisplay!: boolean
}
