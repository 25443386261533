
import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import PostCreateLayout from '@/client/components-old/templates/PostCreateLayout'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import {
  IGetter as IPostCreateGetter,
  IState as IPostCreateState
} from '@/client/store/modules/post_create'
import i18n from '@/client/utils/i18n'
import storage from '@/client/utils/storage'

const accounts = namespace('accounts')
const post_create = namespace('post_create')
const post_management = namespace('post_management')
const post_templates = namespace('post_templates')
const notification = namespace('notification')

@Component({
  name: 'PostCreate',
  components: {
    PostCreateLayout
  }
})
export default class PostCreate extends Vue {
  @accounts.Getter('post') sns_accounts!: IAccountsGetter['post']
  @post_create.State('api_post') api_post!: IPostCreateState['api_post']
  @post_create.State('api_twitter_quote_posts')
  api_twitter_quote_posts!: IPostCreateState['api_twitter_quote_posts']
  @post_create.State('api_twitter_reply_posts')
  api_twitter_reply_posts!: IPostCreateState['api_twitter_reply_posts']
  @post_create.State('accounts') accounts!: IPostCreateState['accounts']
  @post_create.State('scheduled_datetime')
  scheduled_datetime!: IPostCreateState['scheduled_datetime']
  @post_create.State('api_post_approval_flows')
  api_post_approval_flows!: IPostCreateState['api_post_approval_flows']
  @post_create.Getter('persons_options') persons_options: IPostCreateGetter['persons_options']
  @post_create.Mutation('SET_ACCOUNTS') setAccounts!: any
  @post_create.Mutation('SET_PERSONS') setPersons!: any
  @post_create.Mutation('SET_SCHEDULED_DATETIME') setScheduledDatetime!: any
  @post_create.Mutation('SET_IS_LOADING') setIsLoading!: any
  @post_create.Action('reset') reset!: any
  @post_create.Action('getPostSetting') getPostSetting!: any
  @post_create.Action('getPostKeywords') getPostKeywords!: any
  @post_create.Action('getPersons') getPersons!: any
  @post_create.Action('getPageType') getPageType!: any
  @post_create.Action('getPost') getPost!: any
  @post_create.Action('getProjectShortUrlDomain') getProjectShortUrlDomain!: any
  @post_create.Action('changeMessage') changeMessage!: any
  @post_create.Action('changeInstagramAutoPublishStatus') changeInstagramAutoPublishStatus!: any
  @post_create.Action('getIncidentEvents') getIncidentEvents!: any
  @post_create.Action('changeTwitterQuoteTargetMonth') changeTwitterQuoteTargetMonth!: any
  @post_create.Action('changeTwitterReplyTargetMonth') changeTwitterReplyTargetMonth!: any
  @post_create.Action('fetchQuoteTweet') fetchQuoteTweet!: any
  @post_create.Action('changeIsShowUrlInputInQuote') changeIsShowUrlInputInQuote!: any
  @post_create.Action('changeIsShowUrlInputInReply') changeIsShowUrlInputInReply!: any
  @post_create.Action('changeTwitterQuoteAccountId') changeTwitterQuoteAccountId!: any
  @post_create.Action('changeTwitterReplyAccountId') changeTwitterReplyAccountId!: any
  @post_create.Action('fetchPostApprovalFlows') fetchPostApprovalFlows!: any
  @post_create.Action('changeProjectPostApprovalFlowId') changeProjectPostApprovalFlowId!: any
  @post_management.Action('initPostCreateDialog') initPostCreateDialog!: any
  @post_management.Action('resetPostCreateDialog') resetPostCreateDialog!: any
  @post_templates.Action('fetchTemplate') fetchTemplate!: any
  @notification.Action('showNotification') showNotification!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  @Watch('group')
  async watchGroup() {
    await this.initialize()
  }

  @Watch('$route.name')
  async watchRouteName() {
    await this.initialize() // 作成と編集と複製の移動時に初期化する
  }

  get is_invalid_date_twitter_quote() {
    if (!this.api_post?.tw_posts?.[0]?.twitter_quote?.target_tw_post) {
      return false
    }

    if (!this.api_post?.tw_posts?.[0]?.twitter_quote_error_type) {
      return false
    }

    const invalid_date_errors = ['DATE_DELETED']

    return invalid_date_errors.includes(this.api_post.tw_posts[0].twitter_quote_error_type)
  }

  get is_invalid_date_twitter_reply() {
    if (!this.api_post?.tw_posts?.[0]?.twitter_reply?.target_tw_post) {
      return false
    }

    if (!this.api_post?.tw_posts?.[0]?.twitter_reply_error_type) {
      return false
    }

    const invalid_date_errors = ['DATE_DELETED']

    return invalid_date_errors.includes(this.api_post.tw_posts[0].twitter_reply_error_type)
  }

  get is_failure_twitter_quote() {
    if (!this.api_post?.tw_posts?.[0]?.twitter_quote?.target_tw_post) {
      return false
    }

    const current_twitter_quote = this.api_twitter_quote_posts.find(
      quote =>
        quote.tw_post_id === this.api_post.tw_posts[0].twitter_quote.target_tw_post.tw_post_id
    )

    return !current_twitter_quote
  }

  get is_failure_twitter_reply() {
    if (!this.api_post?.tw_posts?.[0]?.twitter_reply?.target_tw_post) {
      return false
    }

    const current_twitter_reply = this.api_twitter_reply_posts.find(
      reply =>
        reply.tw_post_id === this.api_post.tw_posts[0].twitter_reply.target_tw_post.tw_post_id
    )

    return !current_twitter_reply
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.initialize()
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.resetPostCreateDialog()
    await this.reset()
  }

  /**
   * 初期化
   */
  async initialize() {
    this.setIsLoading(true)

    const sns_post_id = Number(this.$route.params.sns_post_id) || null
    const { account_id, message, scheduled_datetime, quote_tweet } = this.$route.query

    await Promise.all([
      this.fetchTemplate(),
      this.getPostSetting(),
      this.getPostKeywords(),
      this.getProjectShortUrlDomain(),
      this.fetchPostApprovalFlows()
    ])

    let page_type: 'create' | 'edit' | 'copy' = 'create'

    if (this.$route.name === 'posts/create') {
      page_type = 'create'
    }

    if (this.$route.name === 'posts/detail/edit') {
      page_type = 'edit'
    }

    if (this.$route.name === 'posts/detail/copy') {
      page_type = 'copy'
    }

    await this.getPageType(page_type)

    await this.initPostCreateDialog(sns_post_id)

    const local: {
      accounts?: { id: string; sns: string }[]
      persons?: number[]
      project_post_approval_flow_id?: number | ''
    } = storage.get('post_create')

    if (['edit', 'copy'].includes(page_type)) {
      await this.getPost(sns_post_id)

      if (!this.api_post) {
        this.showNotification({
          title: '対象の投稿が削除されています。',
          message: 'ブラウザを再読み込みしてください。',
          type: 'error'
        })

        this.changeRoute({ name: 'posts' })
      }

      // 編集で取得できないアカウントが含まれていた場合、権限がないのでリダイレクトする
      if (page_type === 'edit') {
        if (
          this.api_post &&
          (this.api_post.status === 'published' || this.api_post.status === 'failure')
        ) {
          this.showNotification({
            title: '該当の投稿はすでに公開されています。',
            type: 'error'
          })

          this.changeRoute({ name: 'posts' })
        }

        if (this.api_post && this.api_post.status === 'scheduled') {
          this.showNotification({
            title: '該当の投稿はすでに予約されています。',
            message: '編集する場合は、先に予約取消をしてください。',
            type: 'error'
          })

          this.changeRoute({ name: 'posts' })
        }

        if (
          this.api_post &&
          this.api_post.status === 'approval' &&
          this.api_post.role === 'editor'
        ) {
          if (
            this.api_post.post_approval_flow &&
            this.api_post.post_approval_flow.current_step === 1
          ) {
            this.showNotification({
              title: '該当の投稿はすでに承認申請されています。',
              message: '編集する場合は、先に申請取消をしてください。',
              type: 'error'
            })

            this.changeRoute({ name: 'posts' })
          } else if (
            this.api_post.post_approval_flow &&
            this.api_post.post_approval_flow.current_step > 1
          ) {
            this.showNotification({
              title: '該当の投稿はすでに承認されているため、変更できません。',
              type: 'error'
            })

            this.changeRoute({ name: 'posts' })
          }
        }

        const is_role = this.accounts.every(account =>
          this.sns_accounts.some(v => v.sns === account.sns && v.id === account.id)
        )

        if (!is_role) {
          this.showNotification({ title: '投稿する権限がないため編集できません。', type: 'error' })

          this.changeRoute({ name: 'posts' })
        }
      }
    } else {
      // ストレージに保存されていたら使用する
      if (local && local.accounts && Array.isArray(local.accounts)) {
        const accounts = local.accounts.filter(account =>
          this.sns_accounts.some(v => v.id === account.id && v.sns === account.sns)
        )

        await this.changeAccounts(accounts)
      } else {
        const accounts: any[] = []

        await this.changeAccounts(accounts)
      }

      // クエリにアカウントの指定がある場合、自動で選択させる
      if (account_id && !Array.isArray(account_id)) {
        const accounts = this.sns_accounts
          .map(v => ({ id: v.id, sns: v.sns }))
          .filter(v => v.id === account_id)

        await this.changeAccounts(accounts)
      }

      // 未選択でアカウントが1件ある場合、自動で選択させる
      if (!this.accounts.length && this.sns_accounts.length === 1) {
        const accounts = this.sns_accounts.map(v => ({ id: v.id, sns: v.sns }))

        await this.changeAccounts(accounts)
      }
    }

    // クエリに投稿内容の指定がある場合、自動で選択させる
    if (message && !Array.isArray(message)) {
      await this.changeMessage(message)
    }

    // クエリに予約時間の指定がある場合、自動で選択させる
    if (scheduled_datetime && !Array.isArray(scheduled_datetime)) {
      this.setScheduledDatetime(moment(scheduled_datetime).format('YYYY-MM-DD HH:mm'))
    }

    // クエリに引用がある場合（クチコミ分析から遷移した場合）、自動で選択させる（Xアカウントのみ）
    if (
      quote_tweet &&
      !Array.isArray(quote_tweet) &&
      this.accounts.some(v => v.sns === 'twitter')
    ) {
      await this.fetchQuoteTweet(quote_tweet)
      await this.changeIsShowUrlInputInQuote(true)
    }

    // 引用がURL経由で設定されている場合
    if (this.api_post?.tw_posts?.[0]?.twitter_quote?.target_tweet) {
      this.changeIsShowUrlInputInQuote(true)
    }

    // 返信がURL経由で設定されている場合
    if (this.api_post?.tw_posts?.[0]?.twitter_reply?.target_tweet) {
      this.changeIsShowUrlInputInReply(true)
    }

    await Promise.all([this.getPersons(), this.getIncidentEvents(), this.fetchPostApprovalFlows()])

    // 担当者がストレージに保存されていたら使用する
    if (page_type !== 'edit' && Array.isArray(local?.persons) && local.persons.length) {
      const persons = local.persons.filter(v => this.persons_options.some(x => x.id === v))

      this.setPersons(persons)
    }

    // 承認フロー設定がストレージに保存されていたら使用する
    if (page_type !== 'edit' && typeof local?.project_post_approval_flow_id !== 'undefined') {
      if (local.project_post_approval_flow_id === '') {
        await this.changeProjectPostApprovalFlowId(local.project_post_approval_flow_id)
      } else {
        const current_flow_ids = this.api_post_approval_flows.map(v => v.id)

        if (current_flow_ids.includes(local.project_post_approval_flow_id)) {
          await this.changeProjectPostApprovalFlowId(local.project_post_approval_flow_id)
        } else {
          await this.changeProjectPostApprovalFlowId('')
        }
      }
    }

    if (['edit', 'copy'].includes(page_type)) {
      await this.checkTwitterQuote()
      await this.checkTwitterReply()
    }

    this.setIsLoading(false)
  }

  async changeAccounts(accounts: any[]) {
    const is_auto_publish = this.sns_accounts
      .filter(a => accounts.some(b => a.id === b.id && a.sns === b.sns))
      .some(v => v.is_publish_permission)

    await this.changeInstagramAutoPublishStatus(is_auto_publish)

    this.setAccounts(accounts)

    if (accounts.length === 1 && accounts[0].sns === 'twitter') {
      await this.changeTwitterQuoteAccountId(accounts[0].id)
      await this.changeTwitterReplyAccountId(accounts[0].id)
    }
  }

  /**
   * 編集の場合で引用元投稿の設定のエラーをチェック
   */
  async checkTwitterQuote() {
    if (this.is_invalid_date_twitter_quote) {
      const message = i18n.t('引用元の投稿で公開日時が未設定のため、引用の設定をリセットします。')

      window.alert(message)

      this.showNotification({
        title: '引用の設定をリセットしました。',
        type: 'success'
      })

      await this.changeTwitterQuoteTargetMonth('')

      return
    }

    if (this.is_failure_twitter_quote) {
      const message = i18n.t('引用元の投稿が公開に失敗しているため、引用の設定をリセットします。')

      window.alert(message)

      this.showNotification({
        title: '引用の設定をリセットしました。',
        type: 'success'
      })

      await this.changeTwitterQuoteTargetMonth('')

      return
    }
  }

  /**
   * 編集の場合で返信先投稿の設定のエラーをチェック
   */
  async checkTwitterReply() {
    if (this.is_invalid_date_twitter_reply) {
      const message = i18n.t('返信先の投稿で公開日時が未設定のため、返信の設定をリセットします。')

      window.alert(message)

      this.showNotification({
        title: '返信の設定をリセットしました。',
        type: 'success'
      })

      await this.changeTwitterReplyTargetMonth('')

      return
    }

    if (this.is_failure_twitter_reply) {
      const message = i18n.t('返信先の投稿が公開に失敗しているため、返信の設定をリセットします。')

      window.alert(message)

      this.showNotification({
        title: '返信の設定をリセットしました。',
        type: 'success'
      })

      await this.changeTwitterReplyTargetMonth('')

      return
    }
  }
}
