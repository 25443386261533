const PUBLIC_DATETIME = { value: 'release_at', text: '公開日時' }
const ACCOUNT_NAME = { value: 'name', text: 'アカウント' }
const POST_MESSAGE = { value: 'message', text: '投稿内容' }
const GROUP_NAME = { value: 'group', text: 'グループ' }
const FOLLOWERAS = { value: 'followers', text: 'フォロワー数' }
const POSTS_COUNT = { value: 'posts_count', text: '投稿数' }
const VIDEO_POSTS_COUNT = { value: 'video_posts_count', text: '動画投稿数' }
const REACTIONS = { value: 'reactions', text: '反応数' }
const REACTIONS_RATE = { value: 'reactions_rate', text: '反応率' }
const IMPRESSIONS_UNIQUE = { value: 'impressions_unique', text: 'リーチ' }
const IMPRESSIONS = { value: 'impressions', text: 'インプレッション' }
const URL_CLICKS = { value: 'url_clicks', text: 'リンククリック' }
const VIDEO_VIEWS = { value: 'video_views', text: '動画再生数' }
const ENGAGEMENTS = { value: 'engagements', text: 'エンゲージメント数' }
const ENGAGEMENTS_RATE = { value: 'engagements_rate', text: 'エンゲージメント率' }
const SNS_TYPE = { value: 'sns_type', text: 'SNSタイプ' }
const CATEGORIES = { value: 'categories', text: 'タグ' }

export const BASIC_METRICS = [
  ACCOUNT_NAME,
  FOLLOWERAS,
  POSTS_COUNT,
  VIDEO_POSTS_COUNT,
  REACTIONS,
  REACTIONS_RATE,
  IMPRESSIONS_UNIQUE,
  IMPRESSIONS,
  URL_CLICKS,
  VIDEO_VIEWS,
  ENGAGEMENTS,
  ENGAGEMENTS_RATE
]

export const DISPLAY_INTERVAL_NOTATION = [
  { value: 'mom_rate', text: '前月比(率)' },
  { value: 'mom_value', text: '前月比(値)' },
  { value: 'yoy_rate', text: '昨年同月比(率)' },
  { value: 'yoy_value', text: '昨年同月比(値)' }
]

export const DISPLAY_UNIT = [
  { value: 'average', text: '平均' },
  { value: 'total', text: '合計' }
]

export const POST_DISPLAY_VIEW = [
  { value: 'table', text: 'テーブル' },
  { value: 'list', text: 'リスト' }
]

export const FILTER_POST_TYPES = [
  { value: 'image', text: '画像' },
  { value: 'video', text: '動画' },
  { value: 'carousel', text: 'カルーセル' },
  { value: 'text', text: 'テキスト' },
  { value: 'link', text: 'リンク' },
  { value: 'shared', text: 'シェア' }
]

export const POST_LIST_ORDER_BY = [
  { value: 'ASC', text: '昇順' },
  { value: 'DESC', text: '降順' }
]

export const BASIC_TABLE_METRIC = [
  FOLLOWERAS,
  POSTS_COUNT,
  VIDEO_POSTS_COUNT,
  REACTIONS,
  REACTIONS_RATE,
  IMPRESSIONS_UNIQUE,
  IMPRESSIONS,
  URL_CLICKS,
  VIDEO_VIEWS,
  ENGAGEMENTS,
  ENGAGEMENTS_RATE
]

export const POST_TABLE_METRIC = [
  PUBLIC_DATETIME,
  ACCOUNT_NAME,
  POST_MESSAGE,
  REACTIONS,
  REACTIONS_RATE,
  IMPRESSIONS_UNIQUE,
  IMPRESSIONS,
  URL_CLICKS,
  VIDEO_VIEWS,
  ENGAGEMENTS,
  ENGAGEMENTS_RATE
]

export const POST_LIST_METRIC = [
  PUBLIC_DATETIME,
  REACTIONS,
  REACTIONS_RATE,
  IMPRESSIONS_UNIQUE,
  IMPRESSIONS,
  URL_CLICKS,
  VIDEO_VIEWS,
  ENGAGEMENTS,
  ENGAGEMENTS_RATE
]

export const TRANSITION_DISPLAY_METRIC = [
  FOLLOWERAS,
  POSTS_COUNT,
  REACTIONS,
  REACTIONS_RATE,
  IMPRESSIONS_UNIQUE,
  IMPRESSIONS,
  URL_CLICKS,
  VIDEO_VIEWS,
  ENGAGEMENTS,
  ENGAGEMENTS_RATE
]

export const POST_DISPLAY_METRIC = [
  REACTIONS,
  REACTIONS_RATE,
  IMPRESSIONS_UNIQUE,
  IMPRESSIONS,
  URL_CLICKS,
  VIDEO_VIEWS,
  ENGAGEMENTS,
  ENGAGEMENTS_RATE
]

export const CSV_BASIC_METRIC = [
  ACCOUNT_NAME.text,
  GROUP_NAME.text,
  SNS_TYPE.text,
  CATEGORIES.text,
  ...BASIC_TABLE_METRIC.map(v => v.text)
]
