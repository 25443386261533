import { RouteConfig } from 'vue-router'

import PaymentContract from '../pages/PaymentContract.vue'

export const setting_billing_info: RouteConfig = {
  component: PaymentContract,
  path: '/setting_billing_info',
  name: 'setting_billing_info',
  meta: {
    auth: true,
    title: 'ご契約手続'
  }
}
