import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import InstagramHashtagHashtagList from '@/client/components-old/organisms/InstagramHashtagHashtagList'
import InstagramHashtagHeatmap from '@/client/components-old/organisms/InstagramHashtagHeatmap'
import InstagramHashtagPostCount from '@/client/components-old/organisms/InstagramHashtagPostCount'
import InstagramHashtagPostRanking from '@/client/components-old/organisms/InstagramHashtagPostRanking'
import InstagramHashtagSentimentCount from '@/client/components-old/organisms/InstagramHashtagSentimentCount'
import InstagramHashtagSummary from '@/client/components-old/organisms/InstagramHashtagSummary'
import InstagramHashtagWordCloud from '@/client/components-old/organisms/InstagramHashtagWordCloud'
import InstagramHashtagWordList from '@/client/components-old/organisms/InstagramHashtagWordList'
import SnsEmbedDialog from '@/client/components-old/organisms/SnsEmbedDialog'
import { IRootState } from '@/client/store/global'
import { IState as IInstagramHashtagState } from '@/client/store/modules/instagram_hashtag'
import i18n from '@/client/utils/i18n'

const instagram_hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagSummaryLayout',
  components: {
    Message,
    Scroll,
    InstagramHashtagHashtagList,
    InstagramHashtagHeatmap,
    InstagramHashtagPostCount,
    InstagramHashtagPostRanking,
    InstagramHashtagSentimentCount,
    InstagramHashtagSummary,
    InstagramHashtagWordCloud,
    InstagramHashtagWordList,
    SnsEmbedDialog
  }
})
export default class InstagramHashtagSummaryLayout extends Vue {
  @State('project') project: IRootState['project']
  @State('project_role') project_role: IRootState['project_role']
  @instagram_hashtag.State('hashtag_ids') hashtag_ids: IInstagramHashtagState['hashtag_ids']
  @instagram_hashtag.State('api_settings') api_settings: IInstagramHashtagState['api_settings']

  @Emit('change-route')
  openSettingScreen() {
    return {
      name: 'setting_group',
      query: {
        screen_name: 'function',
        function_tab_name: 'analytics'
      }
    }
  }

  get is_max_hashtag() {
    return (
      this.project.keyword_hashtag_current_post_count >= this.project.keyword_hashtag_max_post_count
    )
  }

  get is_admin() {
    return this.project_role.role_admin
  }

  get has_account_expired() {
    const selected_keywords = this.api_settings.filter(v => this.hashtag_ids.includes(v.id))

    return selected_keywords.some(v => v.in_account && v.in_account.expired)
  }

  get has_account_expired_message() {
    const selected_hashtags = this.api_settings.filter(v => this.hashtag_ids.includes(v.id))

    const hashtag_names = selected_hashtags
      .filter(v => v.in_account && v.in_account.expired)
      .map(v => v.name)

    return i18n.t(
      `アカウントのアクセストークンが失効しているため、キーワード分類「[[hashtag_names]]」のデータを取得できません。`,
      {
        hashtag_names: hashtag_names.join(',')
      }
    )
  }

  get has_account_deleted() {
    const selected_hashtags = this.api_settings.filter(v => this.hashtag_ids.includes(v.id))

    return selected_hashtags.some(v => v.in_account && v.in_account.deleted)
  }

  get has_account_deleted_message() {
    const selected_hashtags = this.api_settings.filter(v => this.hashtag_ids.includes(v.id))

    const hashtag_names = selected_hashtags
      .filter(v => v.in_account && v.in_account.deleted)
      .map(v => v.name)

    return i18n.t(
      `アカウントが登録解除されているため、キーワード分類「[[hashtag_names]]」のデータを取得できません。`,
      {
        hashtag_names: hashtag_names.join(',')
      }
    )
  }
}
