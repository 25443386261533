import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import {
  CONTRACT_STATUS_CANCEL,
  CONTRACT_STATUS_TRIAL
} from '@/client/components-old/_constants/project'
import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import ExternalLink from '@/client/components-old/atoms/ExternalLink'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import GroupSettingSnsItem from '@/client/components-old/molecules/GroupSettingSnsItem'
import { IRootState } from '@/client/store/global'
import { IState as IAccountState } from '@/client/store/modules/accounts'

const TRIAL_PERIOD_MAX_ACCOUNT_COUNT = 3

const accounts = namespace('accounts')

@Component({
  name: 'GroupSettingSns',
  components: {
    Button,
    ButtonLink,
    ExternalLink,
    Icon,
    Panel,
    Message,
    Tooltip,
    GroupSettingSnsItem
  }
})
export default class GroupSettingSns extends Vue {
  @State('project') project: IRootState['project']
  @accounts.State('facebook') facebook: IAccountState['facebook']
  @accounts.State('twitter') twitter: IAccountState['twitter']
  @accounts.State('instagram') instagram: IAccountState['instagram']
  @accounts.State('tiktok') tiktok: IAccountState['tiktok']

  get accounts() {
    const fb_accounts = this.facebook.map(account => ({ account, sns_type: 'facebook' }))
    const tw_accounts = this.twitter.map(account => ({ account, sns_type: 'twitter' }))
    const in_accounts = this.instagram.map(account => ({ account, sns_type: 'instagram' }))
    const tt_accounts = this.tiktok.map(account => ({ account, sns_type: 'tiktok' }))
    return [...fb_accounts, ...tw_accounts, ...in_accounts, ...tt_accounts]
  }

  get is_add_button() {
    if (this.project.contract_status === CONTRACT_STATUS_TRIAL) {
      return this.accounts.length < TRIAL_PERIOD_MAX_ACCOUNT_COUNT
    }

    return this.project.contract_status !== CONTRACT_STATUS_CANCEL
  }

  get is_warning_waitlist() {
    return this.project.contract_status === CONTRACT_STATUS_CANCEL
  }

  get is_warning_trial() {
    return (
      this.project.contract_status === CONTRACT_STATUS_TRIAL &&
      this.accounts.length >= TRIAL_PERIOD_MAX_ACCOUNT_COUNT
    )
  }

  @Emit('open-register-dialog')
  openRegisterDialog(payload: any) {
    return payload
  }

  @Emit('open-role-dialog')
  openRoleDialog(payload: any) {
    return payload
  }

  @Emit('open-token-dialog')
  openTokenDialog(payload: any) {
    return payload
  }

  @Emit('open-unregister-dialog')
  openUnregisterDialog(payload: any) {
    return payload
  }

  @Emit('open-unregister-cancel-dialog')
  openUnregisterCancelDialog(payload: any) {
    return payload
  }
}
