import { render, staticRenderFns } from "./DashboardGroupRegister.vue?vue&type=template&id=34a9202b&scoped=true"
import script from "./DashboardGroupRegister.ts?vue&type=script&lang=ts&external"
export * from "./DashboardGroupRegister.ts?vue&type=script&lang=ts&external"
import style0 from "./DashboardGroupRegister.vue?vue&type=style&index=0&id=34a9202b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34a9202b",
  null
  
)

export default component.exports