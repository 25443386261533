
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'EmbedTwitterTimeline'
})
export default class EmbedTwitterTimeline extends Vue {
  @Prop({ type: String, required: true })
  screenName!: string

  @Prop({ type: String, required: true })
  lang!: string

  $refs!: {
    embed: HTMLDivElement
  }

  async mounted() {
    await this.displayTimeline()
  }

  /**
   * タイムラインの表示
   */
  async displayTimeline() {
    if (!Object.prototype.hasOwnProperty.call(window, 'twttr')) return

    await window.twttr.ready()

    await window.twttr.widgets.createTimeline(
      {
        sourceType: 'profile',
        screenName: this.screenName
      },
      this.$refs.embed,
      {
        id: `profile:${this.screenName}`,
        chrome: 'noheader nofooter',
        lang: this.lang,
        tweetLimit: 10
      }
    )
  }
}
