import { RouteConfig } from 'vue-router'

import TwitterAnalytics from '../pages/TwitterAnalytics.vue'

export const analytics_tw: RouteConfig = {
  component: TwitterAnalytics,
  path: '/analytics_tw',
  name: 'analytics_tw',
  meta: {
    auth: true,
    title: '自社アカウント分析 (X)',
    description: '選択したアカウントの分析結果を確認できます。',
    color: 'warning',
    type: 'CHECK_OWN_ANALYSIS'
  }
}
