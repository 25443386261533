import { getTranslateText as t } from '@/client/utils/filters'

/**
 * 履歴タイプからタイトルを取得
 */
export function getHistoryTypeText(history_type: string): string {
  const approved_count = Number(history_type.replace('post_approved_', ''))

  switch (history_type) {
    case 'post_create':
      return t('作成')
    case 'post_update':
      return t('更新')
    case 'post_scheduled':
      return t('予約')
    case 'post_scheduled_canceled':
      return t('予約取消')
    case 'post_published':
      return t('公開')
    case 'post_failed':
      return t('失敗')
    case 'post_deleted':
      return t('削除')
    case 'post_approve':
      return t('承認申請')
    case 'post_approved':
      return t('承認')
    case 'post_approved_1':
    case 'post_approved_2':
    case 'post_approved_3':
      return t('承認 ([[count]]回目)', { count: approved_count })
    case 'post_approve_canceled':
      return t('申請取消')
    case 'post_reject':
      return t('差し戻し')
    default:
      return ''
  }
}

/**
 * 履歴タイプから色を取得
 */
export function getHistoryTypeColor(history_type: string): string {
  switch (history_type) {
    case 'post_scheduled':
      return 'info'
    case 'post_published':
      return 'success'
    case 'post_failed':
      return 'danger'
    case 'post_approve':
    case 'post_approved':
    case 'post_approved_1':
    case 'post_approved_2':
    case 'post_approved_3':
      return 'warning'
    case 'post_reject':
      return 'inactive'
    default:
      return 'default'
  }
}
