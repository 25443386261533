// 年齢
export const AGES = [
  { text: '13+', value: 13 },
  { text: '15+', value: 15 },
  { text: '18+', value: 18 },
  { text: '21+', value: 21 },
  { text: '25+', value: 25 }
]

// 国
export const COUNTRIES = [
  { value: 'JP', text: '日本' },
  { value: 'US', text: 'アメリカ合衆国' },
  { value: 'CA', text: 'カナダ' },
  { value: 'BR', text: 'ブラジル' },
  { value: 'GB', text: 'イギリス' },
  { value: 'FR', text: 'フランス' },
  { value: 'IT', text: 'イタリア' },
  { value: 'ES', text: 'スペイン' },
  { value: 'DE', text: 'ドイツ' },
  { value: 'TR', text: 'トルコ' },
  { value: 'AU', text: 'オーストラリア' },
  { value: 'MY', text: 'マレーシア' },
  { value: 'ID', text: 'インドネシア' },
  { value: 'TH', text: 'タイ王国' },
  { value: 'VN', text: 'ベトナム' },
  { value: 'SG', text: 'シンガポール' },
  { value: 'PH', text: 'フィリピン' },
  { value: 'IN', text: 'インド' },
  { value: 'KR', text: '大韓民国' },
  { value: 'CN', text: '中華人民共和国' },
  { value: 'TW', text: '台湾' },
  { value: 'HK', text: '香港' }
]

// 地域
export const REGIONS = [
  { value: 1894, text: '北海道' },
  { value: 1885, text: '青森県' },
  { value: 1898, text: '岩手県' },
  { value: 1906, text: '宮城県' },
  { value: 1884, text: '秋田県' },
  { value: 1926, text: '山形県' },
  { value: 1890, text: '福島県' },
  { value: 1896, text: '茨城県' },
  { value: 1920, text: '栃木県' },
  { value: 1892, text: '群馬県' },
  { value: 1916, text: '埼玉県' },
  { value: 1886, text: '千葉県' },
  { value: 1922, text: '東京都' },
  { value: 1901, text: '神奈川県' },
  { value: 1911, text: '新潟県' },
  { value: 1924, text: '富山県' },
  { value: 1897, text: '石川県' },
  { value: 1888, text: '福井県' },
  { value: 1928, text: '山梨県' },
  { value: 1908, text: '長野県' },
  { value: 1891, text: '岐阜県' },
  { value: 1919, text: '静岡県' },
  { value: 1883, text: '愛知県' },
  { value: 1905, text: '三重県' },
  { value: 1917, text: '滋賀県' },
  { value: 1904, text: '京都府' },
  { value: 1914, text: '大阪府' },
  { value: 1895, text: '兵庫県' },
  { value: 1910, text: '奈良県' },
  { value: 1925, text: '和歌山県' },
  { value: 1923, text: '鳥取県' },
  { value: 1918, text: '島根県' },
  { value: 1913, text: '岡山県' },
  { value: 1893, text: '広島県' },
  { value: 1927, text: '山口県' },
  { value: 1921, text: '徳島県' },
  { value: 1899, text: '香川県' },
  { value: 1887, text: '愛媛県' },
  { value: 1902, text: '高知県' },
  { value: 1889, text: '福岡県' },
  { value: 1915, text: '佐賀県' },
  { value: 1909, text: '長崎県' },
  { value: 1903, text: '熊本県' },
  { value: 1912, text: '大分県' },
  { value: 1907, text: '宮崎県' },
  { value: 1900, text: '鹿児島県' },
  { value: 1929, text: '沖縄県' }
]
