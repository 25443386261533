
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'SelectMulti'
})
export default class SelectMulti extends Vue {
  @Prop({ type: Array, required: true })
  value!: (string | number)[]

  @Prop({ type: Array, required: true })
  options!: {
    text: string
    value: string | number
  }[]

  @Prop({ type: Boolean, default: true })
  filterable!: boolean

  @Prop({ type: String, default: null })
  placeholder!: string

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: String, default: 'normal' })
  size!: 'normal' | 'full'

  @Prop({ type: Boolean, default: false })
  collapseTags!: boolean

  @Prop({ type: Number, default: 0 })
  multipleLimit!: number

  get selected() {
    return this.value
  }

  set selected(val) {
    // 複数選択制限で非活性時になっても発生するイベント用の対策
    if (this.value.length !== val.length) {
      this.$emit('input', val)
    }
  }

  get select_options() {
    const a = this.options.filter(item => this.value.includes(item.value))
    const b = this.options.filter(item => !this.value.includes(item.value))
    return [...a, ...b]
  }

  get clearable() {
    return this.placeholder && this.placeholder !== ''
  }

  get disabled_check() {
    return this.disabled || !this.options.length
  }

  get class_names() {
    const names = [`size-${this.size}`]

    if (this.collapseTags) {
      names.push('collapse-tags')
    }

    return names
  }

  async mounted() {
    await this.$nextTick()

    if (this.$device !== 'desktop') {
      const node_list = this.$el.querySelectorAll('.el-select__tags .el-select__input')

      for (let index = 0; index < node_list.length; index = index + 1) {
        node_list[index].setAttribute('readonly', 'readonly')
      }
    }
  }
}
