import { RouteConfig } from 'vue-router'

import PostCreate from '../pages/PostCreate.vue'

export const posts_detail_edit: RouteConfig = {
  component: PostCreate,
  path: '/posts/:sns_post_id/edit',
  name: 'posts/detail/edit',
  meta: {
    auth: true,
    title: '投稿の作成',
    description: '各SNSの投稿を作成できます。',
    color: 'warning',
    type: 'CREATE_POST'
  }
}
