import { format, subMonths } from 'date-fns'
import { ref, watch } from 'vue'

import API from '@/client/utils/api'

import {
  GetTwitterViralPostCountsQuery,
  GetTwitterViralPostCountsResponse,
  TwitterViralPostCount
} from '../api'

export function useTwitterViralPostCount() {
  const api_post_counts = ref<TwitterViralPostCount[]>([])

  const is_post_count_loading = ref(false)

  const setting_ids = ref<number[]>([])

  const date_range = ref<[string, string]>([
    format(subMonths(new Date(), 1), 'YYYY-MM-DD'),
    format(new Date(), 'YYYY-MM-DD')
  ])

  /**
   * クチコミ推移の投稿数を取得する
   */
  async function fetchTwitterViralPostCounts() {
    const query: GetTwitterViralPostCountsQuery = {
      setting_ids: setting_ids.value,
      start_date: date_range.value[0],
      end_date: date_range.value[1]
    }

    is_post_count_loading.value = true

    const response = await API.get<GetTwitterViralPostCountsResponse>('twitter_viral/post_counts', {
      params: query
    })

    is_post_count_loading.value = false

    if (response.data.data) {
      api_post_counts.value = response.data.data
    }
  }

  watch([setting_ids, date_range], fetchTwitterViralPostCounts)

  return {
    api_post_counts,
    is_post_count_loading,
    setting_ids,
    date_range,
    fetchTwitterViralPostCounts
  }
}
