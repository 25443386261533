var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{class:[
      'flex',
      `wrap-${_vm.wrap}`,
      `direction-${_vm.direction}`,
      `justify-${_vm.justify}`,
      `align-${_vm.align}`
    ]},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }