import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Account from '@/client/components-old/molecules/Account'
import ApproveFlow from '@/client/components-old/molecules/ApproveFlow'
import { TDashboardPost } from '@/client/utils/api/dashboards'
import i18n from '@/client/utils/i18n'

@Component({
  name: 'DashboardPostItem',
  components: {
    Icon,
    Account,
    ApproveFlow
  }
})
export default class DashboardPostItem extends Vue {
  @Prop({ type: Object, required: true })
  post!: TDashboardPost

  @Prop({ type: Boolean, default: false })
  isShowGroup!: boolean

  get datetime() {
    return moment(this.post.datetime).format('lll')
  }

  get accounts() {
    return [
      ...this.post.fb_accounts.map(v => ({ ...v, symbol: 'facebook' })),
      ...this.post.tw_accounts.map(v => ({ ...v, symbol: 'twitter' })),
      ...this.post.in_accounts.map(v => ({ ...v, symbol: 'instagram' })),
      ...this.post.tt_accounts.map(v => ({ ...v, symbol: 'tiktok' }))
    ]
  }

  get message() {
    return this.post.message || i18n.t('投稿内容がありません。')
  }

  @Emit('open')
  open() {
    return {
      sns_post_id: this.post.id,
      project_id: this.post.project.id
    }
  }
}
