import { Component, Prop, Vue } from 'vue-property-decorator'

import event from '@/client/utils/event'

type TChartPositioningMapOptions = {
  x_format?: string // X軸のツールチップフォーマット
  y_format?: string // Y軸のツールチップフォーマット
  z_format?: string // Z軸のツールチップフォーマット
  x_label: string // X軸のラベル
  y_label: string // Y軸のラベル
  z_label: string // Z軸のラベル
  colors: string[] // グラフの色
  series: {
    data: {
      name: string // フルネームの最初5文字
      full_name: string // フルネーム
      x: number // X軸
      y: number // Y軸
      z: number // Z軸
    }[]
  }[]
}

@Component({
  name: 'ChartPositioningMap'
})
export default class ChartPositioningMap extends Vue {
  @Prop({ type: Object, required: true })
  options: TChartPositioningMapOptions

  is_show = false

  async mounted() {
    this.is_show = false
    await this.$nextTick()
    await event.delay(0)
    this.is_show = true
  }

  get chart_options() {
    const self = this
    const x_label = this.options.x_label
    const y_label = this.options.y_label
    const z_label = this.options.z_label

    return {
      chart: {
        type: 'bubble',
        plotBorderWidth: 1,
        zoomType: 'xy'
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        gridLineWidth: 1,
        title: {
          text: x_label
        },
        labels: {
          formatter() {
            return this.value.toLocaleString()
          }
        }
      },
      colors: this.options.colors,
      yAxis: {
        startOnTick: false,
        endOnTick: false,
        title: {
          text: y_label
        },
        labels: {
          formatter() {
            return this.value.toLocaleString()
          }
        },
        maxPadding: 0
      },
      tooltip: {
        useHTML: true,
        pointFormatter() {
          return `
          <tr><th colspan="2"><h3>${this.full_name}</h3></th></tr>
          <tr><th>${x_label}</th>
          <td>${self.getTooltip('x', this.x)}</td>
          </tr>
          <tr><th>${y_label}</th>
          <td>${self.getTooltip('y', this.y)}</td>
          </tr>
          <tr><th>${z_label}</th>
          <td>${self.getTooltip('z', this.z)}</td>
          </tr>
          `
        },
        headerFormat: '<table style="min-width: 300px">',
        footerFormat: '</table>',
        followPointer: true
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          },
          animation: false
        },
        bubble: {
          minSize: 16,
          maxSize: 96
        }
      },
      series: this.options.series
    }
  }

  /**
   * ツールチップを取得する
   * @param {string} axis 軸
   * @param {number} value 値
   * @returns {string} ツールチップ
   */
  getTooltip(axis: 'x' | 'y' | 'z', value: number) {
    let format = ''
    switch (axis) {
      case 'x':
        format = this.options.x_format ? this.options.x_format : ''
        break
      case 'y':
        format = this.options.y_format ? this.options.y_format : ''
        break
      case 'z':
        format = this.options.z_format ? this.options.z_format : ''
        break
    }

    switch (format) {
      case 'percent':
        if (!value) return '0%'
        return `${value.toLocaleString()}%`
      case 'up_down':
        if (!value) return '0'
        if (Math.sign(value) === 1) return `+${value.toLocaleString()}`
        return value.toLocaleString()
      default:
        if (!value) return '0'
        return value.toLocaleString()
    }
  }
}
