import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import { IState as IInstagramHashtagState } from '@/client/store/modules/instagram_hashtag'

const instagram_hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagNodataLayout',
  components: {
    Message,
    Scroll
  }
})
export default class InstagramHashtagNodataLayout extends Vue {
  @instagram_hashtag.State('hashtag_ids') hashtag_ids: IInstagramHashtagState['hashtag_ids']
  @instagram_hashtag.State('api_settings') api_settings: IInstagramHashtagState['api_settings']

  get is_no_hashtag() {
    return !this.api_settings.length
  }

  get is_hashtag_unselected() {
    return !this.hashtag_ids.length
  }
}
