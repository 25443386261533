
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/instagram_hashtags'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Select from '@/client/components-old/atoms/Select'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartHeatmap from '@/client/components-old/molecules/ChartHeatmap'
import { TrackingService } from '@/client/services'
import { IState as IInstagramHashtagState } from '@/client/store/modules/instagram_hashtag'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'

const instagram_hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagHeatmap',
  components: {
    ButtonGroup,
    Select,
    AnalyticsPanel,
    ChartHeatmap
  }
})
export default class InstagramHashtagHeatmap extends Vue {
  @instagram_hashtag.State('api_settings') api_settings: IInstagramHashtagState['api_settings']
  @instagram_hashtag.State('hashtag_ids') hashtag_ids: IInstagramHashtagState['hashtag_ids']
  @instagram_hashtag.State('start_date') start_date: IInstagramHashtagState['start_date']
  @instagram_hashtag.State('end_date') end_date: IInstagramHashtagState['end_date']
  @instagram_hashtag.State('api_heatmaps') api_heatmaps: IInstagramHashtagState['api_heatmaps']
  @instagram_hashtag.State('is_heatmap_loading')
  is_heatmap_loading: IInstagramHashtagState['is_heatmap_loading']
  @instagram_hashtag.State('heatmap_hashtag_id')
  heatmap_hashtag_id: IInstagramHashtagState['heatmap_hashtag_id']
  @instagram_hashtag.State('heatmap_metric')
  heatmap_metric: IInstagramHashtagState['heatmap_metric']
  @instagram_hashtag.State('heatmap_display_format')
  heatmap_display_format: IInstagramHashtagState['heatmap_display_format']
  @instagram_hashtag.Action('changeHeatmapHashtagId') changeHeatmapHashtagId
  @instagram_hashtag.Action('changeHeatmapMetric') changeHeatmapMetric
  @instagram_hashtag.Action('changeHeatmapDisplayFormat') changeHeatmapDisplayFormat

  get is_heatmap_data() {
    return this.api_heatmaps.some(v => v[2] !== null)
  }

  get hashtag_options() {
    const options = this.hashtag_ids.map(hashtag_id => {
      const hashtag = this.api_settings.find(v => v.id === hashtag_id)

      return { value: hashtag_id, text: hashtag ? hashtag.name : '' }
    })

    return [{ value: '', text: i18n.t('全分類') }, ...options]
  }

  get metric_options() {
    return constants.HEATMAP_METRICS.map(v => ({
      value: v.value,
      text: i18n.t(v.text)
    }))
  }

  get display_options() {
    return constants.DISPLAY_OPTIONS
  }

  get chart_options() {
    const metric = this.metric_options.find(v => v.value === this.heatmap_metric)

    return {
      metric_name: metric ? metric.text : '',
      x_categories: constants.HEATMAP_HOUR_OPTIONS,
      y_categories: constants.HEATMAP_DAY_OF_WEEK_OPTIONS.map(v => i18n.t(v)),
      series: this.api_heatmaps
    }
  }

  /**
   * CSVをダウンロード
   */
  downloadCSV(): void {
    const page_name = i18n.t('Instagramクチコミ分析')

    const component_name = i18n.t('ヒートマップ')

    const hashtag = this.api_settings.find(v => v.id === this.heatmap_hashtag_id)
    const hashtag_name = hashtag ? i18n.t(hashtag.name) : i18n.t('全分類')

    const metric = this.metric_options.find(v => v.value === this.heatmap_metric)
    const metric_name = metric ? metric.text : ''

    const format = this.display_options.find(v => v.value === this.heatmap_display_format)
    const format_name = format ? i18n.t(format.text) : ''

    const csv_filename = [
      page_name,
      component_name,
      hashtag_name,
      metric_name,
      format_name,
      this.start_date,
      this.end_date
    ].join('_')

    const fields = [i18n.t('曜日'), i18n.t('時刻'), metric_name]

    const copy = this.api_heatmaps.slice()

    copy.sort((a, b) => a[1] - b[1])

    const data = copy.map(v => [
      i18n.t(constants.HEATMAP_DAY_OF_WEEK_OPTIONS[v[1]]),
      constants.HEATMAP_HOUR_OPTIONS[v[0]],
      v[2]
    ])

    const csv_data = { fields, data }

    csv.download(csv_data, csv_filename)

    TrackingService.sendEvent(`click:クチコミ(IG)>サマリー|HM:CSVダウンロード`)
  }

  /**
   * ヒートマップで指定しているハッシュタグの変更
   */
  async onChangeHeatmapHashtagId(payload: number) {
    await this.changeHeatmapHashtagId(payload)

    const hashtag_option = this.hashtag_options.find(v => v.value === this.heatmap_hashtag_id)
    const hashtag_label = hashtag_option?.text || ''

    TrackingService.sendEvent(`select:クチコミ(IG)>サマリー|HM:KW分類:${hashtag_label}`)
  }
  /**
   * ヒートマップで指定している指標の変更
   */
  async onChangeHeatmapMetric(payload: string) {
    await this.changeHeatmapMetric(payload)

    const metric_option = this.metric_options.find(v => v.value === this.heatmap_metric)
    const metric_label = metric_option?.text || ''

    TrackingService.sendEvent(`select:クチコミ(IG)>サマリー|HM:指標:${metric_label}`)
  }
  /**
   * ヒートマップの平均・合計の切り替え
   */
  async onChangeHeatmapDisplayFormat(payload: string) {
    await this.changeHeatmapDisplayFormat(payload)

    const display_option = this.display_options.find(v => v.value === this.heatmap_display_format)
    const format_label = display_option?.text || ''

    TrackingService.sendEvent(`click:クチコミ(IG)>サマリー|HM:${format_label}`)
  }
}
