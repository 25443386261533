
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'ButtonLink'
})
export default class ButtonLink extends Vue {
  @Prop({ type: String, default: 'link' })
  color!:
    | 'default'
    | 'primary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'inactive'
    | 'muted'
    | 'disabled'
    | 'text'
    | 'link'
    | 'white'

  @Prop({ type: String, default: 'normal' })
  size!: 'small' | 'normal' | 'large'

  @Prop({ type: String, default: '' })
  href!: string

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Emit('click')
  onClick(event: Event) {
    return event
  }
}
