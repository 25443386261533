export const SET_API_BASIC_INSIGHTS = 'SET_API_BASIC_INSIGHTS'
export const SET_API_TRANSITION_INSIGHTS = 'SET_API_TRANSITION_INSIGHTS'
export const SET_API_POST_INSIGHTS = 'SET_API_POST_INSIGHTS'
export const SET_API_ACCOUNT_TABLE_SETTINGS = 'SET_API_ACCOUNT_TABLE_SETTINGS'
export const SET_API_POST_TABLE_SETTINGS = 'SET_API_POST_TABLE_SETTINGS'
export const SET_TARGET_MONTH = 'SET_TARGET_MONTH'
export const SET_FILTER_SNS_TYPE = 'SET_FILTER_SNS_TYPE'
export const SET_FILTER_CATEGORY_IDS = 'SET_FILTER_CATEGORY_IDS'
export const SET_FILTER_POST_TYPES = 'SET_FILTER_POST_TYPES'
export const SET_FILTER_POST_TAGS = 'SET_FILTER_POST_TAGS'
export const SET_FILTER_POST_KEYWORD = 'SET_FILTER_POST_KEYWORD'
export const SET_IS_DISPLAY_ALL_PROJECT = 'SET_IS_DISPLAY_ALL_PROJECT'
export const SET_BASIC_DISPLAY_ORDER = 'SET_BASIC_DISPLAY_ORDER'
export const SET_BASIC_DISPLAY_UNIT = 'SET_BASIC_DISPLAY_UNIT'
export const SET_TRANSITION_DISPLAY_ORDER = 'SET_TRANSITION_DISPLAY_ORDER'
export const SET_TRANSITION_DISPLAY_METRIC = 'SET_TRANSITION_DISPLAY_METRIC'
export const SET_POST_TABLE_METRIC_BY = 'SET_POST_TABLE_METRIC_BY'
export const SET_POST_LIST_METRIC_BY = 'SET_POST_LIST_METRIC_BY'
export const SET_POST_LIST_ORDER_BY = 'SET_POST_LIST_ORDER_BY'
export const SET_POST_DISPLAY_VIEW = 'SET_POST_DISPLAY_VIEW'
export const SET_DISPLAY_TAB = 'SET_DISPLAY_TAB'
export const SET_DISPLAY_INTERVAL_NOTATION = 'SET_DISPLAY_INTERVAL_NOTATION'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_IS_TRANSITION_LOADING = 'SET_IS_TRANSITION_LOADING'
export const SET_IS_BASIC_LOADING = 'SET_IS_BASIC_LOADING'
export const SET_IS_POST_LOADING = 'SET_IS_POST_LOADING'
