
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import TwitterComparisonAnalyticsHeader from '@/client/components-old/organisms/TwitterComparisonAnalyticsHeader'
import TwitterComparisonAnalyticsBenchmark from '@/client/components-old/templates/TwitterComparisonAnalyticsBenchmark'
import TwitterComparisonAnalyticsManagement from '@/client/components-old/templates/TwitterComparisonAnalyticsManagement'
import TwitterComparisonAnalyticsNodata from '@/client/components-old/templates/TwitterComparisonAnalyticsNodata'
import TwitterComparisonAnalyticsPost from '@/client/components-old/templates/TwitterComparisonAnalyticsPost'
import { IState as ITwitterState } from '@/client/store/modules/twitter_comparison_analytics'
import event from '@/client/utils/event'

const twitter = namespace('twitter_comparison_analytics')

@Component({
  name: 'TwitterComparisonAnalytics',
  components: {
    TwitterComparisonAnalyticsHeader,
    TwitterComparisonAnalyticsNodata,
    TwitterComparisonAnalyticsBenchmark,
    TwitterComparisonAnalyticsPost,
    TwitterComparisonAnalyticsManagement
  }
})
export default class TwitterComparisonAnalytics extends Vue {
  @twitter.State('api_sns_comparison_accounts')
  accounts!: ITwitterState['api_sns_comparison_accounts']
  @twitter.State('account_ids') account_ids!: ITwitterState['account_ids']
  @twitter.State('screen_name') screen_name!: ITwitterState['screen_name']
  @twitter.State('is_loading') is_loading!: ITwitterState['is_loading']
  @twitter.Action('createdTwitterComparisonAnalyticsPage')
  createdTwitterComparisonAnalyticsPage!: any
  @twitter.Action('destroyedTwitterComparisonAnalyticsPage')
  destroyedTwitterComparisonAnalyticsPage!: any
  @twitter.Action('changeGroupTwitterComparisonAnalyticsPage')
  changeGroupTwitterComparisonAnalyticsPage!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  get is_empty() {
    if (this.is_management) return false

    if (!this.account_ids.length) return true

    return this.account_ids.every(account_id => {
      const account = this.accounts.find(account => account.id === account_id)

      return account ? Boolean(account.is_empty) : false
    })
  }

  get is_benchmark() {
    return this.screen_name === 'benchmark'
  }

  get is_post() {
    return this.screen_name === 'post'
  }

  get is_management() {
    return this.screen_name === 'management'
  }

  @Watch('is_loading', { immediate: true })
  async changeIsLoading() {
    await this.$nextTick()
    event.resize()
  }

  @Watch('screen_name', { immediate: true })
  async changeScreenName() {
    await this.$nextTick()
    event.resize()
  }

  @Watch('group')
  async watchGroup() {
    await this.changeGroupTwitterComparisonAnalyticsPage()
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.createdTwitterComparisonAnalyticsPage()
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedTwitterComparisonAnalyticsPage()
  }
}
