import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import DashboardMemoItem from '@/client/components-old/molecules/DashboardMemoItem'
import { IState as IDashboardState } from '@/client/store/modules/dashboard'

const dashboard = namespace('dashboard')

@Component({
  name: 'DashboardPostMemo',
  components: {
    Icon,
    Message,
    Panel,
    Tooltip,
    DashboardMemoItem
  }
})
export default class DashboardPostMemo extends Vue {
  @dashboard.State('api_post_memo') memos: IDashboardState['api_post_memo']
  @dashboard.State('is_scroll_post_memo') is_scroll: IDashboardState['is_scroll_post_memo']
  @dashboard.State('is_post_memo_loading') is_loading: IDashboardState['is_post_memo_loading']
  @dashboard.State('filter_project_all') filter_project_all: IDashboardState['filter_project_all']
  @dashboard.Action('scrollPostMemo') scrollPostMemo

  $refs: {
    scroll: HTMLDivElement
  }

  get is_empty() {
    return !this.memos.length
  }

  @Emit('open-post')
  openPost(payload: any) {
    return { ...payload, mode: 'preview', tab: 'memo' }
  }

  @Watch('is_scroll')
  initialize() {
    if (this.is_scroll && this.$refs.scroll) {
      this.$refs.scroll.scrollTop = 0
    }
  }

  /**
   * もっと読み込む
   */
  async loadMore(event: any) {
    const SCALING_MEASURES = 5

    const is_bottom =
      event.target.scrollHeight <=
      event.target.scrollTop + event.target.clientHeight + SCALING_MEASURES

    if (this.is_loading || !this.is_scroll || !is_bottom) return

    await this.scrollPostMemo()
  }
}
