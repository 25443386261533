
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Scroll'
})
export default class Scroll extends Vue {
  $refs!: {
    scroll: HTMLDivElement
  }

  async mounted() {
    await this.$nextTick()

    this.handleResize()

    window.addEventListener('resize', this.handleResize, false)
  }

  async beforeDestroy() {
    await this.$nextTick()

    window.removeEventListener('resize', this.handleResize)
  }

  /**
   * リサイズのイベントハンドリング
   */
  handleResize(): void {
    if (this.$refs.scroll) {
      const top = this.$refs.scroll.getBoundingClientRect().top
      const height = window.innerHeight - top
      const css = `${height}px`

      if (this.$refs.scroll.style.height !== css) {
        this.$refs.scroll.style.height = css
        this.$emit('change', height)
      }
    }
  }

  /**
   * スクロール位置の初期化
   */
  scrollTop(): void {
    this.$refs.scroll.scrollTop = 0
  }
}
