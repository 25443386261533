/**
 * canvasタグからBlobデータを生成する
 */
export function createBlob(canvas: HTMLCanvasElement, quality = 0.9) {
  const ctx = canvas.getContext('2d')

  // ? JPEGにするため透過PNGを黒背景から白背景にする
  if (ctx) {
    const original = ctx.globalCompositeOperation
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = '#FFFFFF'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.globalCompositeOperation = original
  }

  return new Promise<Blob>((resolve, reject) => {
    const callback: BlobCallback = blob => {
      if (blob) resolve(blob)
      else reject(new Error('ERROR_IMAGE_TO_BLOB'))
    }

    // ? 容量を抑えるためJPEGで出力する
    canvas.toBlob(callback, 'image/jpeg', quality)
  })
}
