
import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { TIncidentEvent } from '@/client/utils/api/incident_events'

@Component({
  name: 'IncidentEventItem',
  components: {
    Icon,
    Tooltip
  }
})
export default class IncidentEventItem extends Vue {
  @Prop({ type: Object, required: true })
  event!: TIncidentEvent

  get is_display_tooltip() {
    return !!this.event.description
  }

  get description() {
    if (!this.is_display_tooltip) {
      return ''
    }

    return this.event.description.replace(new RegExp('\\n', 'g'), '<br />')
  }
}
