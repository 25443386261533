
import { EmojiIndex, Picker } from 'emoji-mart-vue-fast'
import apple from 'emoji-mart-vue-fast/data/apple.json'
import { defineComponent } from 'vue'

import { getTranslateText as t } from '@/client/utils/filters'

type EmojiObject = {
  id: string
  name: string
  colons: string
  text: string
  emoticons: string[]
  skin: number | null
  native: string
}

export default defineComponent({
  name: 'EmojiPicker',
  components: {
    Picker
  },
  setup(props, context) {
    const data = new EmojiIndex(apple)

    const i18n = {
      search: t('検索'),
      notfound: t('絵文字が見つかりません。'),
      categories: {
        search: t('検索結果'),
        recent: t('よく使われる'),
        smileys: t('表情と絵文字'),
        people: t('人と体'),
        nature: t('動物と自然'),
        foods: t('食べ物と飲み物'),
        activity: t('アクティビティ'),
        places: t('旅行と場所'),
        objects: t('もの'),
        symbols: t('記号'),
        flags: t('旗'),
        custom: t('カスタム')
      }
    }

    function select(emoji: EmojiObject) {
      context.emit('select', emoji.native)
    }

    return {
      data,
      i18n,
      select
    }
  }
})
