import { render, staticRenderFns } from "./TikTokAnalyticsVideo.vue?vue&type=template&id=1c875563&scoped=true"
import script from "./TikTokAnalyticsVideo.vue?vue&type=script&lang=ts&setup=true"
export * from "./TikTokAnalyticsVideo.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./TikTokAnalyticsVideo.vue?vue&type=style&index=0&id=1c875563&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c875563",
  null
  
)

export default component.exports