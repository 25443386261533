import twttr from 'twitter-text'
import { Component, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Photo from '@/client/components-old/molecules/Photo'
import { TrackingService } from '@/client/services'
import { IState as IPostCreateState } from '@/client/store/modules/post_create'
import { FACEBOOK_INTERNAL_URL_FORMAT, INSTAGRAM_INTERNAL_URL_FORMAT } from '@/client/utils/regex'
import * as validate from '@/client/utils/validate'

interface HTMLElementDragEvent<T extends HTMLElement> extends DragEvent {
  target: T
}

type TDragEvent = {
  moved: {
    element: { img: string; symbol: string[]; is_active: boolean; is_delete: boolean }
    newIndex: number
    oldIndex: number
  }
}

const CALL_TO_ACTIONS = [
  { text: 'ボタンなし', value: 'NO_BUTTON' },
  { text: '詳しくはこちら', value: 'LEARN_MORE' },
  { text: 'リンクを開く', value: 'OPEN_LINK' },
  { text: 'ダウンロード', value: 'DOWNLOAD' },
  { text: '登録する', value: 'SIGN_UP' },
  { text: '予約する', value: 'BOOK_TRAVEL' },
  { text: 'アプリを利用', value: 'USE_APP' },
  { text: 'アプリをインストール', value: 'INSTALL_APP' },
  { text: '音楽を聴く', value: 'LISTEN_MUSIC' },
  { text: '動画を見る', value: 'WATCH_VIDEO' },
  { text: '他の動画を視聴', value: 'WATCH_MORE' }
]

const post_create = namespace('post_create')
const notification = namespace('notification')

@Component({
  name: 'PostCreateFacebookLink',
  components: {
    draggable,
    Panel,
    Button,
    Message,
    Flex,
    Icon,
    Input,
    Tooltip,
    SwitchButton,
    Select,
    Photo
  }
})
export default class PostCreateFacebookLink extends Vue {
  @post_create.State('facebook_links') facebook_links: IPostCreateState['facebook_links']
  @post_create.State('carousels_end_card')
  carousels_end_card: IPostCreateState['carousels_end_card']
  @post_create.State('is_media_uploading')
  is_media_uploading: IPostCreateState['is_media_uploading']
  @post_create.Mutation('SET_TYPE') setType
  @post_create.Mutation('SET_FACEBOOK_LINKS') setFacebookLinks
  @post_create.Mutation('SET_CAROUSELS_END_CARD') setCarouselsEndCard
  @post_create.Mutation('SET_IS_MEDIA_UPLOADING') setIsMediaUploading
  @post_create.Action('postImageUpload') postImageUpload
  @notification.Action('showNotification') showNotification

  $refs: {
    add_images: HTMLInputElement
    ChangeImageInput: HTMLInputElement
  }

  selected_link = 0

  get disabled() {
    return this.facebook_links.length === 0
  }

  get accept() {
    return validate.IMAGE_TYPES
  }

  get is_delete() {
    return this.facebook_links.length > 1
  }

  get is_end_card() {
    return this.facebook_links.length > 1
  }

  get is_add_button() {
    return this.facebook_links.length < 5
  }

  get call_to_actions() {
    return CALL_TO_ACTIONS.map(v => ({
      text: this.$options.filters.translate(v.text),
      value: v.value
    }))
  }

  get link_title() {
    return this.facebook_links[this.selected_link].title
  }

  set link_title(title: string) {
    const links = this.facebook_links.map((link, index) =>
      this.selected_link === index ? { ...link, title } : link
    )

    this.setFacebookLinks(links)
  }

  get link_description() {
    return this.facebook_links[this.selected_link].description
  }

  set link_description(description: string) {
    const links = this.facebook_links.map((link, index) =>
      this.selected_link === index ? { ...link, description } : link
    )

    this.setFacebookLinks(links)
  }

  get link_url() {
    return this.facebook_links[this.selected_link].url
  }

  set link_url(url: string) {
    const links = this.facebook_links.map((link, index) =>
      this.selected_link === index ? { ...link, url } : link
    )

    this.setFacebookLinks(links)
  }

  get link_call_to_action() {
    return this.facebook_links[this.selected_link].call_to_action || 'NO_BUTTON'
  }

  set link_call_to_action(call_to_action: string) {
    const links = this.facebook_links.map((link, index) =>
      this.selected_link === index ? { ...link, call_to_action } : link
    )

    this.setFacebookLinks(links)
  }

  /**
   * リンクを初期化する
   * @returns {void}
   */
  clearLinks(): void {
    this.setType('text')
    this.setFacebookLinks([])
    this.setCarouselsEndCard(false)
  }

  /**
   * 選択してるリンクの変更
   * @param {number} index
   * @returns {void}
   */
  selectedLink(index: number): void {
    this.selected_link = index
  }

  /**
   * 画像の順番を変更する
   * @param {TDragEvent} drag
   * @returns {Promise<void>} void
   */
  changeSortLink(drag: TDragEvent): void {
    if (this.selected_link === drag.moved.oldIndex) {
      this.selected_link = drag.moved.newIndex
    } else if (
      this.selected_link < drag.moved.oldIndex &&
      this.selected_link >= drag.moved.newIndex
    ) {
      this.selected_link++
    } else if (
      this.selected_link > drag.moved.oldIndex &&
      this.selected_link <= drag.moved.newIndex
    ) {
      this.selected_link--
    }
  }

  /**
   * 見出しのチェック
   * @param {string} value
   * @returns {boolean} 結果
   */
  errorTitle(value?: string): boolean {
    return value ? value.trim() === '' : true
  }

  /**
   * URLのチェック
   */
  errorUrl(url: string): boolean {
    return this.isInvalidUrl(url) || this.isBlacklistUrl(url)
  }

  /**
   * 無効なURLのチェック
   */
  isInvalidUrl(url: string): boolean {
    const empty_url = url ? url.trim() === '' : true
    const invalid_url = url ? !twttr.extractUrls(url).length : true

    return empty_url || invalid_url
  }

  /**
   * FacebookやInstagramの内部URLのチェック
   */
  isBlacklistUrl(url: string): boolean {
    return FACEBOOK_INTERNAL_URL_FORMAT.test(url) || INSTAGRAM_INTERNAL_URL_FORMAT.test(url)
  }

  /**
   * 画像の削除
   * @param {number} index
   * @returns {void}
   */
  removeImage(index: number): void {
    TrackingService.sendEvent('click:投稿作成|Facebookリンク情報|解除')

    if (this.selected_link === index) {
      this.selected_link = 0
    }

    if (this.selected_link > index) {
      this.selected_link--
    }

    const links = this.facebook_links.filter((v, i) => i !== index)

    this.setFacebookLinks(links)

    if (this.facebook_links.length <= 1) {
      this.setType('link')
      this.setCarouselsEndCard(false)
    }
  }

  /**
   * 画像を追加する（複数あり）
   * @returns {Promise<void>} void
   */
  async addImages(): Promise<void> {
    const input = this.$refs.add_images

    if (!input.files.length) return

    this.setIsMediaUploading(true)

    for (let i = 0; i < input.files.length; i++) {
      if (this.facebook_links.length >= 5) continue

      const response = await this.postImageUpload({
        file: input.files[i],
        type: 'link'
      })

      if (response.data && !response.data.is_animation_gif) {
        const link = this.facebook_links[this.facebook_links.length - 1]

        const facebook_links = this.facebook_links.concat([
          { ...link, image_url: response.data.image_url }
        ])

        this.setFacebookLinks(facebook_links)

        this.selected_link = this.facebook_links.length - 1
      } else if (response.error) {
        this.showNotification({ ...response.error, type: 'error' })
        break
      } else {
        this.showNotification({ title: 'アップロードに失敗しました。', type: 'error' })
        break
      }
    }

    if (this.facebook_links.length > 1) {
      this.setType('carousel')
    }

    setTimeout(() => {
      this.setIsMediaUploading(false)
    }, 1000)

    input.value = null
  }

  /**
   * 画像を変更する
   * @returns {Promise<void>} void
   */
  async changeImage(): Promise<void> {
    const input = this.$refs.ChangeImageInput

    if (!input.files.length) return

    this.setIsMediaUploading(true)

    const response = await this.postImageUpload({
      file: input.files[0],
      type: 'link'
    })

    if (response.data && !response.data.is_animation_gif) {
      const facebook_links = this.facebook_links.map((link, index) =>
        this.selected_link === index ? { ...link, image_url: response.data.image_url } : link
      )

      this.setFacebookLinks(facebook_links)
    } else if (response.error) {
      this.showNotification({ ...response.error, type: 'error' })
    } else {
      this.showNotification({ title: 'アップロードに失敗しました。', type: 'error' })
    }

    setTimeout(() => {
      this.setIsMediaUploading(false)
    }, 1000)

    input.value = null
  }

  /**
   * 画像ブラウザを開く
   */
  openChangeImageBrower() {
    TrackingService.sendEvent('click:投稿作成|Facebookリンク情報|画像を変更')

    this.$refs.ChangeImageInput.click()
  }

  /**
   * dragover
   * @param {HTMLElementDragEvent<HTMLElement>} event
   * @returns {void}
   */
  dragover(event: HTMLElementDragEvent<HTMLElement>): void {
    event.target.classList.add('drag')
  }

  /**
   * dragleave
   * @param {HTMLElementDragEvent<HTMLElement>} event
   * @returns {void}
   */
  dragleave(event: HTMLElementDragEvent<HTMLElement>): void {
    event.target.classList.remove('drag')
  }

  /**
   * drop
   * @param {HTMLElementDragEvent<HTMLElement>} event
   * @returns {Promise<void>} void
   */
  async drop(event: HTMLElementDragEvent<HTMLElement>): Promise<void> {
    event.target.classList.remove('drag')

    this.setIsMediaUploading(true)

    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      if (this.facebook_links.length >= 5) continue

      const response = await this.postImageUpload({
        file: event.dataTransfer.files[i],
        type: 'link'
      })

      if (response.data && !response.data.is_animation_gif) {
        const link = this.facebook_links[this.facebook_links.length - 1]

        const facebook_links = this.facebook_links.concat([
          { ...link, image_url: response.data.image_url }
        ])

        this.setFacebookLinks(facebook_links)

        this.selected_link = this.facebook_links.length - 1
      } else if (response.error) {
        this.showNotification({ ...response.error, type: 'error' })
        break
      } else {
        this.showNotification({ title: 'アップロードに失敗しました。', type: 'error' })
        break
      }
    }

    if (this.facebook_links.length > 1) {
      this.setType('carousel')
    }

    setTimeout(() => {
      this.setIsMediaUploading(false)
    }, 1000)
  }
}
