import { Component, Prop, Vue } from 'vue-property-decorator'

import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Account from '@/client/components-old/molecules/Account'
import { IPostApprovalFlow, TStatus } from '@/client/utils/api/posts'

type TIconSize = 'normal' | 'small' | 'large' | 'minimum'

@Component({
  name: 'ApproveFlow',
  components: {
    Flex,
    Icon,
    Account
  }
})
export default class ApproveFlow extends Vue {
  @Prop({ type: Object, required: true })
  postApprovalFlow!: IPostApprovalFlow

  @Prop({ type: String, required: true })
  status!: TStatus

  @Prop({ type: String, default: 'start' })
  justify!: string

  @Prop({ type: String, default: 'small' })
  size!: TIconSize

  get create_user() {
    if (!this.postApprovalFlow.create_user) {
      return null
    }

    return {
      name: this.postApprovalFlow.create_user.name,
      icon_image: this.postApprovalFlow.create_user.picture_url,
      icon_circle: true
    }
  }

  get is_approval() {
    return ['approval', 'reject'].includes(this.status)
  }

  get user_1() {
    const user_is_null = !this.postApprovalFlow.user1 || !this.postApprovalFlow.user1.id

    if (
      (this.is_approval && this.postApprovalFlow.total_step < 1) ||
      (!this.is_approval && user_is_null)
    ) {
      return null
    }

    return {
      user_is_null,
      name: !user_is_null ? this.postApprovalFlow.user1?.name : '1',
      icon_image: !user_is_null ? this.postApprovalFlow.user1?.picture_url : null,
      class: user_is_null ? 'not-account' : '',
      action_type: !user_is_null
        ? this.setActionType(this.postApprovalFlow.user1?.action_type || '')
        : null
    }
  }

  get user_2() {
    const user_is_null = !this.postApprovalFlow.user2 || !this.postApprovalFlow.user2.id

    if (
      (this.is_approval && this.postApprovalFlow.total_step < 2) ||
      (!this.is_approval && user_is_null)
    ) {
      return null
    }

    return {
      user_is_null,
      name: !user_is_null ? this.postApprovalFlow.user2?.name : '2',
      icon_image: !user_is_null ? this.postApprovalFlow.user2?.picture_url : null,
      class: user_is_null ? 'not-account' : '',
      action_type: !user_is_null
        ? this.setActionType(this.postApprovalFlow.user2?.action_type || '')
        : null
    }
  }

  get user_3() {
    const user_is_null = !this.postApprovalFlow.user3 || !this.postApprovalFlow.user3.id

    if (
      (this.is_approval && this.postApprovalFlow.total_step < 3) ||
      (!this.is_approval && user_is_null)
    ) {
      return null
    }

    return {
      user_is_null,
      name: !user_is_null ? this.postApprovalFlow.user3?.name : '3',
      icon_image: !user_is_null ? this.postApprovalFlow.user3?.picture_url : null,
      class: user_is_null ? 'not-account' : '',
      action_type: !user_is_null
        ? this.setActionType(this.postApprovalFlow.user3?.action_type || '')
        : null
    }
  }

  setActionType(action_type: string) {
    switch (action_type) {
      case 'approved':
        return 'approve'
      case 'rejected':
        return 'reject'
      default:
        return null
    }
  }
}
