import moment from 'moment-timezone'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/twitter_comparison_analytics'
import * as twitter_comparison_insight from '@/client/components-old/_utils/twitter_comparison_insight'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartVertical from '@/client/components-old/molecules/ChartVertical'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as ITwitterState } from '@/client/store/modules/twitter_comparison_analytics'
import csv from '@/client/utils/csv'

type TTwitterComparisonAnalyticsChartVerticalOptions = {
  data_type: 'daily_data' | 'post_data'
  metric: string
  interval: '1_day' | '7_day' | '14_day' | '28_day' | '1_month' | '3_month' | '6_month'
  unit: 'total' | 'average'
  is_change_interval?: boolean
}

const analytics = namespace('analytics')
const twitter = namespace('twitter_comparison_analytics')

@Component({
  name: 'TwitterComparisonAnalyticsChartVertical',
  components: {
    AnalyticsPanel,
    ChartVertical,
    ButtonGroup,
    Flex,
    Icon,
    Tooltip
  }
})
export default class TwitterComparisonAnalyticsChartVertical extends Vue {
  @State('user') user: IRootState['user']
  @twitter.State('api_sns_comparison_accounts')
  comparison_accounts: ITwitterState['api_sns_comparison_accounts']
  @twitter.State('api_accounts') daily_data: ITwitterState['api_accounts']
  @twitter.State('api_posts') posts_data: ITwitterState['api_posts']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TTwitterComparisonAnalyticsChartVerticalOptions

  interval = '1_day'

  @Watch('options', { immediate: true, deep: true })
  changeOptions() {
    this.interval = this.options.interval
  }

  @Watch('interval')
  changeInterval() {
    const interval_label =
      this.intervals.find(interval => interval.value === this.interval)?.text || ''

    switch (this.options.metric) {
      case 'followers_count':
        TrackingService.sendEvent(`click:競合比較(TW)>BM|フォロワー数(累計):${interval_label}`)
        break

      case 'followers_count_up_down':
        TrackingService.sendEvent(`click:競合比較(TW)>BM|フォロワー数(増減):${interval_label}`)
        break

      case 'listed_count':
        TrackingService.sendEvent(`click:競合比較(TW)>BM|リスト数(累計):${interval_label}`)
        break

      case 'listed_count_up_down':
        TrackingService.sendEvent(`click:競合比較(TW)>BM|リスト数(増減):${interval_label}`)
        break
    }
  }

  get analytics(): any[] {
    switch (this.options.data_type) {
      case 'daily_data':
        return twitter_comparison_insight.filterDailyData(
          this.daily_data,
          this.posts_data,
          this.options.metric
        )
      case 'post_data':
        return this.posts_data
      default:
        return []
    }
  }

  get is_data() {
    return this.analytics.length > 0 && this.analytics.some(analytic => analytic.data.length > 0)
  }

  get intervals() {
    if (this.user.language === 'en') {
      return [
        { text: '1D', value: '1_day' },
        { text: '7D', value: '7_day' },
        { text: '14D', value: '14_day' },
        { text: '28D', value: '28_day' },
        { text: '1M', value: '1_month' },
        { text: '3M', value: '3_month' },
        { text: '6M', value: '6_month' }
      ]
    }

    return [
      { text: '1日', value: '1_day' },
      { text: '7日', value: '7_day' },
      { text: '14日', value: '14_day' },
      { text: '28日', value: '28_day' },
      { text: '1ヶ月', value: '1_month' },
      { text: '3ヶ月', value: '3_month' },
      { text: '6ヶ月', value: '6_month' }
    ]
  }

  get metrics() {
    switch (this.options.data_type) {
      case 'daily_data':
        return constants.CHANGE_CHART_DAILY_DATA_METRICS
      case 'post_data':
        return constants.CHANGE_CHART_POST_DATA_METRICS
      default:
        return []
    }
  }

  get metric_name() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    const metric_name = metric ? this.$options.filters.translate(metric.text) : ''

    const unit = constants.UNITS.find(unit => unit.value === this.options.unit)
    const unit_name = unit ? this.$options.filters.translate(unit.text) : ''
    const unit_value = unit ? unit.value : ''

    if (unit_name === '' || unit_value === '') {
      return metric_name
    }

    switch (this.options.metric) {
      case 'followers_count':
      case 'friends_count':
      case 'listed_count':
        return metric_name
      default:
        return `${metric_name} [${unit_name}]`
    }
  }

  get metric_tooltip() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? metric.tooltip : ''
  }

  get panel_type() {
    const data_type = constants.DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }

  get is_button_group() {
    return this.options.is_change_interval && this.is_data
  }

  get time_range() {
    const time_range = []
    const add_number = Number(this.interval.match(/\d+/g))

    let time_end
    let time_start = moment(moment(this.start_date).format('YYYY-MM-DD 00:00:00')).unix()

    const end_date_unix = moment(moment(this.end_date).format('YYYY-MM-DD 23:59:59')).unix()

    while (time_start < end_date_unix) {
      if (
        this.interval === '1_day' ||
        this.interval === '7_day' ||
        this.interval === '14_day' ||
        this.interval === '28_day'
      ) {
        // 日数のunix値: 日数 x 一日のunix値 (一日のunix値: 86400)
        time_end = time_start + add_number * 86400 - 1
      } else if (
        this.interval === '1_month' ||
        this.interval === '3_month' ||
        this.interval === '6_month'
      ) {
        time_end = moment.unix(time_start).add(add_number, 'months').startOf('month').unix() - 1
      }

      if (time_end > end_date_unix) {
        time_end = end_date_unix
      }

      time_range.push({
        start: time_start,
        end: time_end
      })

      time_start = time_end + 1
    }

    return time_range
  }

  get categories() {
    switch (this.interval) {
      case '1_day':
        return this.time_range.map(time => {
          return moment.unix(time.start).format('YYYY/MM/DD')
        })
      case '7_day':
      case '14_day':
      case '28_day':
        return this.time_range.map(time => {
          return `${moment.unix(time.start).format('M/D')}-${moment.unix(time.end).format('M/D')}`
        })
      case '1_month':
      case '3_month':
      case '6_month':
        return this.time_range.map(time => {
          const start = moment.unix(time.start).month() + 1 + this.$options.filters.translate('月')
          const end = moment.unix(time.end).month() + 1 + this.$options.filters.translate('月')

          return start !== end ? `${start}-${end}` : start
        })
      default:
        return this.time_range
    }
  }

  get chart_options() {
    const chart_color = [
      '#1c84c6',
      '#ed5565',
      '#2ec881',
      '#24cbe5',
      '#f8ac59',
      '#5e5e5e',
      '#9b9b9b',
      '#b2dfdb',
      '#9c27b0',
      '#2d552d'
    ]

    let series = this.posts_data.map((account, index) => {
      const comparison_account = this.comparison_accounts.find(acc => acc.id === account.account_id)

      if (!comparison_account) {
        return null
      }

      return {
        name: comparison_account ? comparison_account.name : '',
        id: account.account_id,
        index: this.posts_data.length - index - 1
      }
    })

    series = series.filter(account => account)

    const colors = chart_color.slice(0, series.length)

    switch (this.options.metric) {
      case 'followers_count':
      case 'followers_count_up_down':
      case 'listed_count':
      case 'listed_count_up_down':
      case 'friends_count':
      case 'friends_count_up_down':
        return {
          chart_type: 'line',
          y_label: this.$options.filters.translate('人数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors,
          categories: this.categories,
          series: this.getSeries(this.options.metric, series)
        }

      default:
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: false,
          display_value: false,
          display_line: true,
          colors,
          categories: this.categories,
          series
        }
    }
  }

  get display_type() {
    return `chart_vertical_${this.chart_options.chart_type}`
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    const fields = [this.$options.filters.translate('期間')]

    fields.push(...this.chart_options.series.map(element => element.name))

    const data = this.categories.map((time, key) => {
      return [time, ...this.chart_options.series.map(element => element.data[key])]
    })

    const csv_data = { fields, data }

    const component_name = this.$options.filters.translate('グラフ')

    const csv_filename = [component_name, this.metric_name, this.start_date, this.end_date].join(
      '_'
    )

    csv.download(csv_data, csv_filename)

    switch (this.options.metric) {
      case 'followers_count':
        TrackingService.sendEvent(`click:競合比較(TW)>BM|フォロワー数(累計):CSVダウンロード`)
        break

      case 'followers_count_up_down':
        TrackingService.sendEvent(`click:競合比較(TW)>BM|フォロワー数(増減):CSVダウンロード`)
        break

      case 'listed_count':
        TrackingService.sendEvent(`click:競合比較(TW)>BM|リスト数(累計):CSVダウンロード`)
        break

      case 'listed_count_up_down':
        TrackingService.sendEvent(`click:競合比較(TW)>BM|リスト数(増減):CSVダウンロード`)
        break
    }
  }

  /**
   * グラフのシリーズデータを取得
   * @param {string} metric
   * @param {any[]} series
   * @returns {any[]} シリーズデータ
   */
  getSeries(metric: string, series: any[]): any[] {
    series = series.map(element => {
      return {
        name: element.name,
        id: element.id,
        data: [],
        index: element.index
      }
    })

    this.time_range.forEach(time => {
      const analytics = twitter_comparison_insight.getDataByMetric(this.analytics, time)

      series.forEach(element => {
        // 共通計算処理
        const account = analytics.find(item => item.account_id === element.id)
        if (!account || account.data === null || account.data.length === 0) {
          element.data.push(null)
          return
        }

        const total = twitter_comparison_insight.getMetricTotal(account.data, metric)

        // 期間合計の場合、戻す
        if (this.options.unit === 'total') {
          element.data.push(total)
          return
        }

        element.data.push(twitter_comparison_insight.getMetricAverage(account.data, metric))
      })
    })

    return series
  }
}
