
import { computed, defineComponent, ref } from 'vue'
import draggable from 'vuedraggable'
import { mapActions, mapState } from 'vuex'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { IState as ICategoryState } from '@/client/store/modules/sns_account_categories'
import { getTranslateText as t } from '@/client/utils/filters'

export default defineComponent({
  name: 'AccountTagsManagementDialog',
  components: {
    draggable,
    Button,
    Dialog,
    Icon,
    Input,
    Tooltip
  },
  setup() {
    const visible = ref(false)
    const loading = ref(false)
    const is_create_mode = ref(false)
    const create_category_name = ref('')
    const edit_category_id = ref(0)
    const edit_category_name = ref('')

    const is_edit_mode = computed(() => Boolean(edit_category_id.value))
    const is_creatable = computed(() => !loading.value && !is_edit_mode.value)
    const is_editable = computed(
      () => !loading.value && !is_create_mode.value && !is_edit_mode.value
    )

    const open = () => {
      visible.value = true
    }

    const close = () => {
      visible.value = false
    }

    const checkEditMode = (id: number) =>
      Boolean(is_edit_mode.value) && edit_category_id.value === id

    const startCreateCategory = () => {
      is_create_mode.value = true
    }

    const stopCreateCategory = () => {
      is_create_mode.value = false
      create_category_name.value = ''
    }

    const stopUpdateCategory = () => {
      edit_category_id.value = 0
      edit_category_name.value = ''
    }

    return {
      visible,
      loading,
      is_create_mode,
      create_category_name,
      edit_category_id,
      edit_category_name,
      is_edit_mode,
      is_creatable,
      is_editable,
      open,
      close,
      checkEditMode,
      startCreateCategory,
      stopCreateCategory,
      stopUpdateCategory,
      t
    }
  },
  computed: {
    ...mapState('sns_account_categories', ['api_sns_account_categories'])
  },
  methods: {
    ...mapActions('sns_account_categories', [
      'createSnsAccountCategory',
      'removeSnsAccountCategory',
      'updateSnsAccountCategory',
      'updateSnsAccountCategoryOrder'
    ]),
    ...mapActions('notification', ['showNotification']),

    /**
     * 編集モードの開始
     */
    startUpdateCategory(id: number) {
      const category = this.api_sns_account_categories.find(category => category.id === id)

      if (!category) {
        return
      }

      this.edit_category_id = category.id
      this.edit_category_name = category.name
    },

    /**
     * タグのドラッグ&ドロップ
     */
    async changeDraggable(categories: ICategoryState['api_sns_account_categories']) {
      const result = await this.updateSnsAccountCategoryOrder(categories)

      if (result.data) {
        this.showNotification({ title: 'タグの順番を保存しました。' })

        return
      }

      this.showNotification({ type: 'error', title: 'タグの順番の保存に失敗しました。' })
    },

    /**
     * タグの追加
     */
    async createCategory() {
      const name = this.create_category_name.trim()

      if (this.loading || !this.create_mode || !name) {
        return
      }

      this.loading = true

      const result = await this.createSnsAccountCategory({ name })

      this.loading = false

      if (result.data) {
        this.showNotification({ title: 'タグを追加しました。' })

        this.stopCreateCategory()

        return
      }

      if (result.error && result.error.type === 'DUPLICATE') {
        this.showNotification({
          type: 'error',
          title: 'タグがすでに存在するため、他のタグ名を入力してください。'
        })

        return
      }

      this.showNotification({ type: 'error', title: 'タグの追加に失敗しました。' })
    },

    /**
     * タグの編集
     */
    async updateCategory() {
      const name = this.edit_category_name.trim()

      if (this.loading || !this.edit_category_id || !name) {
        return
      }

      const params = {
        category_id: this.edit_category_id,
        name
      }

      this.loading = true

      const result = await this.updateSnsAccountCategory(params)

      this.loading = false

      if (result.data) {
        this.showNotification({ title: 'タグを変更しました。' })

        this.stopUpdateCategory()

        return
      }

      if (result.error && result.error.type === 'DUPLICATE') {
        this.showNotification({
          type: 'error',
          title: 'タグがすでに存在するため、他のタグ名を入力してください。'
        })

        return
      }

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.showNotification({
          type: 'error',
          title: 'タグがすでに削除されています。'
        })

        return
      }

      this.showNotification({
        type: 'error',
        title: 'タグの変更に失敗しました。'
      })
    },

    /**
     * *タグの削除*
     */
    async deleteCategory(id: number) {
      const category = this.api_sns_account_categories.find(category => category.id === id)

      if (!category) {
        return
      }

      let message = t('本当に以下のタグを削除してよろしいですか？') + '\n'
      message += category.name + '\n'
      message += t('この操作は取り消しできません。')

      const confirm = window.confirm(message)

      if (!confirm) {
        return
      }

      const result = await this.removeSnsAccountCategory({ category_id: id })

      if (result.data) {
        this.showNotification({ title: t('[[name]]を削除しました。', { name: category.name }) })

        return
      }

      if (result.error && result.error.type === 'NOT_EXISTS') {
        this.showNotification({ type: 'error', title: 'タグがすでに削除されています。' })

        return
      }

      this.showNotification({ type: 'error', title: 'タグの削除に失敗しました。' })
    }
  }
})
