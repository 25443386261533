import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/instagram_analytics'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import AnalyticsRanking from '@/client/components-old/molecules/AnalyticsRanking'
import PaginationTable from '@/client/components-old/molecules/PaginationTable'
import { IRootState } from '@/client/store/global'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as IInstagramState } from '@/client/store/modules/instagram_analytics'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'

type TInstagramAnalyticsStoriesRankingOptions = {
  main_metric: string
  sub_metrics: string[]
  sort: 'ascending' | 'descending'
  limit: number
  post_types: string[]
  post_tags: number[]
}

const analytics = namespace('analytics')
const instagram = namespace('instagram_analytics')
const categories = namespace('categories')

@Component({
  name: 'InstagramAnalyticsStoriesRanking',
  components: {
    AnalyticsPanel,
    AnalyticsPanelHeader,
    AnalyticsRanking,
    PaginationTable,
    Tooltip,
    Icon
  }
})
export default class InstagramAnalyticsStoriesRanking extends Vue {
  @State('user') user: IRootState['user']
  @categories.State('api_post_categories')
  api_post_categories: ICategoriesState['api_post_categories']
  @categories.State('api_category_posts') api_category_posts: ICategoriesState['api_category_posts']
  @instagram.State('api_stories') stories_data: IInstagramState['api_stories']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TInstagramAnalyticsStoriesRankingOptions

  @Prop({ type: Boolean, default: false })
  isPreview: boolean

  @Prop({ type: Boolean, default: false })
  isEdit: boolean

  current_page = 1

  get is_data() {
    return this.ranking_posts.length > 0
  }

  get post_tags_names() {
    return this.getPostTagNames(this.options.post_tags)
  }

  get is_download() {
    return this.is_data && !this.isPreview
  }

  get is_edit() {
    return !this.isPreview && this.isEdit
  }

  get metrics_name() {
    return this.getMetricsName(this.options.main_metric)
  }

  get sort_name() {
    const sort = constants.POST_RANKING_SORTS.find(item => item.value === this.options.sort)
    return sort ? sort.text : ''
  }

  get header_title() {
    return `${i18n.t(this.metrics_name)} ${i18n.t('ランキング')} [${i18n.t(this.sort_name)}]`
  }

  get total_ranking_posts() {
    const { main_metric, sub_metrics, sort, post_types, post_tags } = this.options

    let posts = [...this.stories_data]
    // ランキングに必要なものを追加
    if (post_types.length) {
      posts = posts.filter(post => post_types.includes(post.type))
    }

    if (post_tags.length) {
      posts = posts.filter(post => {
        const post_tag_ids = this.getSnsPostTags(post.stories_id)
        return post_tag_ids.some(post_tag_id => post_tags.includes(post_tag_id))
      })
    }

    posts = posts.map(post => {
      const main = {
        key: this.getMetricsName(main_metric),
        value: this.getResultValue(main_metric, post)
      }

      const sub = sub_metrics.map(metrics => {
        return {
          key: this.getMetricsName(metrics),
          value: this.getResultValue(metrics, post)
        }
      })

      return { ...post, results: [main].concat(sub) }
    })

    // ランキングのソート処理
    return [...posts]
      .sort((a, b) => {
        switch (sort) {
          case 'ascending':
            return a[main_metric] - b[main_metric]
          case 'descending':
            return b[main_metric] - a[main_metric]
          default:
            return 1
        }
      })
      .map((post, index) => ({ ...post, ranking_no: index + 1 }))
  }

  get ranking_posts() {
    const start = (this.current_page - 1) * this.options.limit
    const end = start + this.options.limit

    return this.total_ranking_posts.slice(start, end)
  }

  get total_post() {
    return this.total_ranking_posts.length
  }

  get show_post_types() {
    const POST_TYPES = ['image', 'video']

    return POST_TYPES.filter(v => this.options.post_types.includes(v))
  }

  get page_size() {
    return this.options.limit
  }

  @Emit('edit')
  onEdit() {
    return this.options
  }

  @Emit('delete')
  onDelete(event: Event) {
    return event
  }

  @Emit('open-preview')
  openPreview(payload: { url: string }) {
    return {
      // ? 24時間経つとストーリーの動画にアクセスできないため動画の場合もサムネイルを表示させる
      media: [{ url: payload.url, type: 'image' }],
      index: 0
    }
  }

  /**
   * ページの変更
   */
  changePage(page: number): void {
    if (this.current_page === page) {
      return
    }

    this.current_page = page
  }

  /**
   * 指標から指標名を取得
   * @param {string} value 指標
   * @returns {string} 指標名
   */
  getMetricsName(value: string): string {
    const metric = constants.STORIES_POST_RANKING_MAIN_METRICS.find(item => item.value === value)
    return metric ? metric.text : ''
  }

  /**
   * 指標から表示用の値を取得
   * @param {string} value 指標
   * @param {any} data 指標データ
   * @returns {string} 表示用の値
   */
  getResultValue(value: string, data: any): string {
    switch (value) {
      case 'created_time': {
        const format = this.user.language === 'ja' ? 'YYYY年M月D日H:mm' : 'M/D/YYYY<br>H:mm'
        return moment(data[value]).format(format)
      }
      default:
        return this.$options.filters.number(data[value])
    }
  }

  getSnsPostTags(post_id: string): number[] {
    const post_tag = this.api_category_posts.find(post_tag => post_tag.post_id === post_id)

    return post_tag ? post_tag.category_ids : []
  }

  getPostTagNames(post_tag_ids: number[]): string[] {
    if (!post_tag_ids.length) return []

    return this.api_post_categories.filter(tag => post_tag_ids.includes(tag.id)).map(v => v.name)
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    const { main_metric, sub_metrics } = this.options

    const conversion = (metric, post) => {
      switch (metric) {
        case 'created_time':
          return csv.format(post[metric])
        default:
          return post[metric]
      }
    }

    const fields = [
      i18n.t('公開日時'),
      i18n.t('投稿タイプ'),
      i18n.t('投稿内容'),
      i18n.t(this.metrics_name),
      ...sub_metrics.map(metric => i18n.t(this.getMetricsName(metric))),
      i18n.t('サムネイルURL'),
      i18n.t('投稿URL'),
      i18n.t('投稿ID')
    ]

    const data = this.total_ranking_posts.map(post => [
      csv.format(post.created_time),
      post.type,
      post.message,
      conversion(main_metric, post),
      ...sub_metrics.map(metric => conversion(metric, post)),
      post.type === 'image' ? post.media_url : post.thumbnail_url,
      'https://www.instagram.com/p/' + post.shortcode,
      post.stories_id
    ])

    const csv_data = { fields, data }

    const component_name = i18n.t('投稿ランキング')
    const metrics_name = i18n.t(this.metrics_name)

    const csv_filename = [
      component_name,
      metrics_name,
      this.sort_name,
      this.start_date,
      this.end_date
    ].join('_')

    csv.download(csv_data, csv_filename)
  }
}
