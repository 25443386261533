import { store } from '@/client/store'

/**
 * グループ設定のキー
 */
function groupKey(): string {
  return `cms_project_${store.state.project.id}`
}

/**
 * ユーザー設定のキー
 */
function userKey(): string {
  return 'cms_user'
}

/**
 * 対象のキーからストレージの取得
 */
function get(key: string): any {
  const storage = JSON.parse(localStorage.getItem(groupKey()))

  return storage && storage[key] ? storage[key] : null
}

/**
 * 対象のキーからストレージの設定
 */
function set(key: string, val: any): void {
  const group_key = groupKey()

  const temp = JSON.parse(localStorage.getItem(group_key))

  const storage = temp ? temp : {}

  if (storage) {
    storage[key] = storage[key]
      ? {
          ...storage[key],
          ...val
        }
      : {
          ...val
        }
  }

  localStorage.setItem(group_key, JSON.stringify(storage))
}

/**
 * 対象のキーからストレージの削除
 */
function remove(key: string): void {
  const group_key = groupKey()

  const temp = JSON.parse(localStorage.getItem(group_key))

  const storage = temp ? temp : {}

  if (storage) {
    storage[key] = null
  }

  localStorage.setItem(group_key, JSON.stringify(storage))
}

/**
 * グループIDを取得
 */
function getGroupId(): number {
  const user_key = userKey()

  const json = JSON.parse(localStorage.getItem(user_key))

  if (json) {
    const group_id = json[store.state.user.id]

    const group_ids = store.state.projects.map(v => v.id)

    if (group_ids.includes(group_id)) {
      return group_id
    }
  }

  if (store.state.projects[0]) {
    const group_id = store.state.projects[0].id

    const values = {
      ...json,
      [store.state.user.id]: store.state.projects[0].id
    }

    localStorage.setItem(user_key, JSON.stringify(values))

    return group_id
  }

  return 0
}

/**
 * グループIDを設定
 */
function setGroupId(group_id: number): void {
  const user_key = userKey()

  const json = JSON.parse(localStorage.getItem(user_key))

  const update = { [store.state.user.id]: group_id }

  const values = json ? { ...json, ...update } : { ...update }

  localStorage.setItem(user_key, JSON.stringify(values))
}

/**
 * ユーザーIDを設定
 */
function setUserId(user_id: number, group_id: number): void {
  const user_key = userKey()

  const json = JSON.parse(localStorage.getItem(user_key))

  const update = { [user_id]: group_id }

  const values = json ? { ...json, ...update } : { ...update }

  localStorage.setItem(user_key, JSON.stringify(values))
}

export default {
  get,
  set,
  remove,
  getGroupId,
  setGroupId,
  setUserId
}
