import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Checkbox from '@/client/components-old/atoms/Checkbox'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import PresetDialog from '@/client/components-old/organisms/PresetDialog'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as IFacebookState } from '@/client/store/modules/facebook_comparison_analytics'
import { IState as IInstagramState } from '@/client/store/modules/instagram_comparison_analytics'
import { IState as ITwitterState } from '@/client/store/modules/twitter_comparison_analytics'

const facebook = namespace('facebook_comparison_analytics')
const twitter = namespace('twitter_comparison_analytics')
const instagram = namespace('instagram_comparison_analytics')
const notification = namespace('notification')

@Component({
  name: 'PresetManagement',
  components: {
    draggable,
    PresetDialog,
    Account,
    Message,
    Input,
    Button,
    Icon,
    Checkbox,
    Tooltip
  }
})
export default class PresetManagement extends Vue {
  @State('project') project: IRootState['project']
  @facebook.State('api_sns_comparison_accounts')
  sns_comparison_accounts_facebook: IFacebookState['api_sns_comparison_accounts']
  @facebook.State('api_presets') presets_facebook: IFacebookState['api_presets']
  @facebook.Action('deletePresets') deletePresetsFacebook
  @facebook.Action('changeOrderNoPresets') changeOrderNoPresetsFacebook
  @twitter.State('api_sns_comparison_accounts')
  sns_comparison_accounts_twitter: ITwitterState['api_sns_comparison_accounts']
  @twitter.State('api_presets') presets_twitter: ITwitterState['api_presets']
  @twitter.Action('deletePresets') deletePresetsTwitter
  @twitter.Action('changeOrderNoPresets') changeOrderNoPresetsTwitter
  @instagram.State('api_sns_comparison_accounts')
  sns_comparison_accounts_instagram: IInstagramState['api_sns_comparison_accounts']
  @instagram.State('api_presets') presets_instagram: IInstagramState['api_presets']
  @instagram.Action('deletePresets') deletePresetsInstagram
  @instagram.Action('changeOrderNoPresets') changeOrderNoPresetsInstagram
  @notification.Action('showNotification') showNotification

  @Prop({ type: String, required: true })
  snsType: 'facebook' | 'twitter' | 'instagram'

  $refs: {
    PresetDialog: any
  }

  layout = []

  get sns_comparison_accounts() {
    switch (this.snsType) {
      case 'facebook':
        return this.sns_comparison_accounts_facebook
      case 'twitter':
        return this.sns_comparison_accounts_twitter
      case 'instagram':
        return this.sns_comparison_accounts_instagram
      default:
        return []
    }
  }

  get presets() {
    switch (this.snsType) {
      case 'facebook':
        return this.presets_facebook
      case 'twitter':
        return this.presets_twitter
      case 'instagram':
        return this.presets_instagram
      default:
        return []
    }
  }

  get preset_ids() {
    return [...this.presets].sort((a, b) => a.order_no - b.order_no).map(v => v.id)
  }

  @Watch('preset_ids', { immediate: true })
  changePresetIds() {
    const presets = [...this.presets].sort((a, b) => a.order_no - b.order_no)

    this.layout = presets.map(preset => ({
      id: preset.id,
      name: preset.name,
      data: preset.data
        .map(account_id => this.sns_comparison_accounts.find(account => account.id === account_id))
        .filter(account => account)
        .sort((a, b) => a.order_no - b.order_no)
    }))
  }

  /**
   * プリセットを削除
   */
  async deletePreset(preset_id: number): Promise<void> {
    const message =
      this.$options.filters.translate('プリセットを削除してよろしいですか？') +
      '\n' +
      this.$options.filters.translate('この操作は取り消しできません。')

    const confirm = window.confirm(message)

    if (!confirm) return

    let result: any

    switch (this.snsType) {
      case 'facebook':
        TrackingService.sendEvent(`click:競合比較(FB)>アカウント管理|プリセット|削除`)

        result = await this.deletePresetsFacebook({ id: preset_id })
        break
      case 'twitter':
        TrackingService.sendEvent(`click:競合比較(TW)>アカウント管理|プリセット|削除`)

        result = await this.deletePresetsTwitter({ id: preset_id })
        break
      case 'instagram':
        TrackingService.sendEvent(`click:競合比較(IG)>アカウント管理|プリセット|削除`)

        result = await this.deletePresetsInstagram({ id: preset_id })
        break
    }

    if (result.error && result.error.type === 'NOT_EXISTS') {
      return this.showNotification({
        title: '対象のプリセットはすでに削除されています。',
        type: 'error'
      })
    }

    if (result.data) {
      return this.showNotification({ title: 'プリセットを削除しました。' })
    }

    this.showNotification({ title: 'プリセットの削除に失敗しました。', type: 'error' })
  }

  /**
   * プリセット一覧のドラッグ&ドロップ
   */
  async changeLayout(): Promise<void> {
    const preset_ids = this.layout.map(v => v.id)

    let result: any

    switch (this.snsType) {
      case 'facebook':
        result = await this.changeOrderNoPresetsFacebook({ preset_ids })
        break
      case 'twitter':
        result = await this.changeOrderNoPresetsTwitter({ preset_ids })
        break
      case 'instagram':
        result = await this.changeOrderNoPresetsInstagram({ preset_ids })
        break
    }

    if (result.data) {
      return this.showNotification({ title: 'プリセット一覧を更新しました。' })
    }

    this.showNotification({ title: 'プリセット一覧の更新に失敗しました。', type: 'error' })
  }

  /**
   * プリセットダイアログを開く
   */
  async openPresetDialog(id?: any) {
    this.$refs.PresetDialog.open(id)

    if (id) {
      TrackingService.sendEvent(`click:競合比較(${this.snsType})>アカウント管理|プリセット|編集`)
    } else {
      TrackingService.sendEvent(`click:競合比較(${this.snsType})>アカウント管理|プリセットの追加`)
    }
  }
}
