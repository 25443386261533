import { Component, Prop, Vue } from 'vue-property-decorator'

import HelpLink from '@/client/components/molecules/HelpLink'
import * as constants from '@/client/components-old/_constants/twitter_analytics'
import Badge from '@/client/components-old/atoms/Badge'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'

type TAnalyticsTotalTableData = {
  title: string
  headers: string[]
  rows: {
    metric: string
    metric_value: string
    metric_tooltip?: string
    columns: {
      value: string
      updown: string
    }[]
  }[]
}

@Component({
  name: 'AnalyticsTotalTable',
  components: {
    Badge,
    Icon,
    Tooltip,
    HelpLink
  }
})
export default class AnalyticsTotalTable extends Vue {
  @Prop({ type: Object, required: true })
  data: TAnalyticsTotalTableData

  get table_title() {
    return this.data.title
  }

  get table_labels() {
    return this.data.headers
  }

  get table_key() {
    return Date.now() + this.data.rows.length
  }

  get table_data() {
    return this.data.rows.map(row => ({
      metric: row.metric,
      metric_value: row.metric_value,
      metric_tooltip: row.metric_tooltip,
      ...row.columns.map(column => ({ ...column }))
    }))
  }

  get table_style() {
    return 'not-sort'
  }

  /**
   * 増減の値によってクラス名を変更する
   */
  classUpdown(val: string): string[] {
    if (val === '-') {
      return []
    }

    if (val.indexOf('-') === 0) {
      return ['color-minus']
    }

    if (val.indexOf('+') === 0) {
      return ['color-plus']
    }

    return []
  }

  /**
   * CSVインポートの指標を判断
   */
  isCsvImportMetric(metric: string): boolean {
    return constants.POST_CSV_IMPORT_METRICS.includes(metric)
  }
}
