import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Dialog from '@/client/components-old/atoms/Dialog'
import Message from '@/client/components-old/atoms/Message'
import Tabs from '@/client/components-old/atoms/Tabs'
import MonitoringDetailHistory from '@/client/components-old/organisms/MonitoringDetailHistory'
import MonitoringDetailMemo from '@/client/components-old/organisms/MonitoringDetailMemo'
import MonitoringItem from '@/client/components-old/organisms/MonitoringItem'
import { IRootState } from '@/client/store/global'
import { IState as IMonitoringState } from '@/client/store/modules/monitoring'

const monitoring = namespace('monitoring')

@Component({
  name: 'MonitoringDetailDialog',
  components: {
    Dialog,
    Message,
    Tabs,
    MonitoringItem,
    MonitoringDetailMemo,
    MonitoringDetailHistory
  }
})
export default class MonitoringDetailDialog extends Vue {
  @State('project') project: IRootState['project']
  @monitoring.State('api_monitorings') monitorings: IMonitoringState['api_monitorings']
  @monitoring.State('api_memos') api_memos: IMonitoringState['api_memos']
  @monitoring.State('api_histories') api_histories: IMonitoringState['api_histories']
  @monitoring.State('dialog_detail_tab') dialog_detail_tab: IMonitoringState['dialog_detail_tab']
  @monitoring.State('dialog_detail_visible')
  dialog_detail_visible: IMonitoringState['dialog_detail_visible']
  @monitoring.State('selected_monitoring')
  selected_monitoring: IMonitoringState['selected_monitoring']
  @monitoring.State('is_dialog') is_dialog: IMonitoringState['is_dialog']
  @monitoring.State('is_dialog_exist') is_dialog_exist: IMonitoringState['is_dialog_exist']
  @monitoring.State('is_dialog_role') is_dialog_role: IMonitoringState['is_dialog_role']
  @monitoring.Mutation('SET_DIALOG_DETAIL_TAB') setDialogDetailTab
  @monitoring.Action('getMonitoringSettings') getMonitoringSettings
  @monitoring.Action('getMonitoringWords') getMonitoringWords
  @monitoring.Action('closeDetailDialog') closeDetailDialog

  get show_close() {
    return this.is_dialog_role && this.is_dialog_exist
  }

  get monitoring() {
    if (!this.selected_monitoring) {
      return null
    }

    return this.monitorings.find(
      m =>
        m.id === this.selected_monitoring.monitoring_id &&
        m.sns === this.selected_monitoring.sns_type
    )
  }

  get options() {
    const preview_text = this.$options.filters.translate('プレビュー')
    const memo_text = this.$options.filters.translate('メモ') + `(${this.api_memos.length})`
    const history_text = this.$options.filters.translate('履歴') + `(${this.api_histories.length})`

    return [
      { value: 'preview', text: preview_text },
      { value: 'memo', text: memo_text },
      { value: 'history', text: history_text }
    ]
  }

  get is_preview() {
    return this.dialog_detail_tab === 'preview' && this.monitoring
  }

  get is_memo() {
    return this.dialog_detail_tab === 'memo' && this.monitoring
  }

  get is_history() {
    return this.dialog_detail_tab === 'history' && this.monitoring
  }

  @Watch('project.id', { deep: true })
  async watchProjectId() {
    await Promise.all([this.getMonitoringSettings(), this.getMonitoringWords()])
  }

  @Watch('monitoring', { deep: true })
  watchMonitoring() {
    if (this.monitoring && this.project.id !== this.monitoring.project_id) {
      this.changeProject({ project_id: this.monitoring.project_id })
    }
  }

  @Emit('change-project')
  changeProject(payload: any) {
    return payload
  }

  @Emit('update-monitoring')
  updateMonitoring(payload: any) {
    return payload
  }

  @Emit('open-post')
  openPostDialog(payload: any) {
    return payload
  }

  @Emit('open-conversation')
  openConversationDialog(payload: any) {
    return payload
  }

  @Emit('open-template')
  openTemplateDialog(event: Event) {
    return event
  }
}
