import { GridItem, GridLayout } from 'vue-grid-layout'
import { Component, Vue } from 'vue-property-decorator'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Collage from '@/client/components-old/molecules/Collage'

@Component({
  name: 'PostCreateCollageDialog',
  components: {
    GridLayout,
    GridItem,
    Button,
    Dialog,
    Flex,
    Icon,
    Message,
    Panel,
    Collage
  }
})
export default class PostCreateCollageDialog extends Vue {
  $refs: {
    collage_image: HTMLDivElement
  }

  visible = false
  type: string | null = null
  is_submit = false

  get collage_options() {
    return [
      {
        type: 'vertical',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 6,
            h: 6,
            width: 348,
            height: 705,
            i: 'vertial:left'
          },
          {
            x: 6,
            y: 0,
            w: 6,
            h: 6,
            width: 348,
            height: 705,
            i: 'verical:right'
          }
        ]
      },
      {
        type: 'horizontal',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 12,
            h: 3,
            width: 705,
            height: 348,
            i: 'horizontal:top'
          },
          {
            x: 0,
            y: 3,
            w: 12,
            h: 3,
            width: 705,
            height: 348,
            i: 'horizontal:bottom'
          }
        ]
      },
      {
        type: 'vertical-right-horizontal',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 6,
            h: 6,
            width: 348,
            height: 705,
            i: 'vertical:left'
          },
          {
            x: 6,
            y: 0,
            w: 6,
            h: 3,
            width: 348,
            height: 348,
            i: 'vertical:right_top'
          },
          {
            x: 6,
            y: 3,
            w: 6,
            h: 3,
            width: 348,
            height: 348,
            i: 'vertical:right_bottom'
          }
        ]
      },
      {
        type: 'horizontal-bottom-vertical',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 12,
            h: 3,
            width: 705,
            height: 348,
            i: 'horizontal:top'
          },
          {
            x: 0,
            y: 3,
            w: 6,
            h: 3,
            width: 348,
            height: 348,
            i: 'horizontal:bottom_left'
          },
          {
            x: 6,
            y: 3,
            w: 6,
            h: 3,
            width: 348,
            height: 348,
            i: 'horizontal:bottom_right'
          }
        ]
      },
      {
        type: 'three-vertival',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 4,
            h: 6,
            width: 229,
            height: 705,
            i: 'vertical:left'
          },
          {
            x: 4,
            y: 0,
            w: 4,
            h: 6,
            width: 229,
            height: 705,
            i: 'vertical:center'
          },
          {
            x: 8,
            y: 0,
            w: 4,
            h: 6,
            width: 229,
            height: 705,
            i: 'vertical:right'
          }
        ]
      },
      {
        type: 'bottom-three-vertical',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 12,
            h: 4,
            width: 705,
            height: 470,
            i: 'horizontal:top'
          },
          {
            x: 0,
            y: 4,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'horizontal:bottom_left'
          },
          {
            x: 4,
            y: 4,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'horizontal:bottom_center'
          },
          {
            x: 8,
            y: 4,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'horizontal:bottom_right'
          }
        ]
      },
      {
        type: 'four-divition',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 6,
            h: 3,
            width: 348,
            height: 348,
            i: 'horizontal:top_left'
          },
          {
            x: 0,
            y: 3,
            w: 6,
            h: 3,
            width: 348,
            height: 348,
            i: 'horizontal:top_right'
          },
          {
            x: 6,
            y: 0,
            w: 6,
            h: 3,
            width: 348,
            height: 348,
            i: 'horizontal:bottom_left'
          },
          {
            x: 6,
            y: 3,
            w: 6,
            h: 3,
            width: 348,
            height: 348,
            i: 'horizontal:bottom_right'
          }
        ]
      },
      {
        type: 'right-three-horizontal',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 6,
            h: 6,
            width: 348,
            height: 705,
            i: 'vertical:left'
          },
          {
            x: 6,
            y: 0,
            w: 6,
            h: 2,
            width: 348,
            height: 229,
            i: 'vertical:right_top'
          },
          {
            x: 6,
            y: 2,
            w: 6,
            h: 2,
            width: 348,
            height: 229,
            i: 'vertical:right_center'
          },
          {
            x: 6,
            y: 4,
            w: 6,
            h: 2,
            width: 348,
            height: 229,
            i: 'vertical:right_bottom'
          }
        ]
      },
      {
        type: 'two-three-horizontal',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 6,
            h: 3,
            width: 348,
            height: 348,
            i: 'vertical:left_top'
          },
          {
            x: 0,
            y: 3,
            w: 6,
            h: 3,
            width: 348,
            height: 348,
            i: 'vertical:left_bottom'
          },
          {
            x: 6,
            y: 0,
            w: 6,
            h: 2,
            width: 348,
            height: 229,
            i: 'vertical:right_top'
          },
          {
            x: 6,
            y: 2,
            w: 6,
            h: 2,
            width: 348,
            height: 229,
            i: 'vertical:right_center'
          },
          {
            x: 6,
            y: 4,
            w: 6,
            h: 2,
            width: 348,
            height: 229,
            i: 'vertical:right_bottom'
          }
        ]
      },
      {
        type: 'three-vertival-right-three-horizontal',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 4,
            h: 6,
            width: 229,
            height: 705,
            i: 'vertical:left'
          },
          {
            x: 4,
            y: 0,
            w: 4,
            h: 6,
            width: 229,
            height: 705,
            i: 'vertical:center'
          },
          {
            x: 8,
            y: 0,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:right_top'
          },
          {
            x: 8,
            y: 2,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:right_center'
          },
          {
            x: 8,
            y: 4,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:right_bottom'
          }
        ]
      },
      {
        type: 'three-vertival-center-right-three-horizontal',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 4,
            h: 6,
            width: 229,
            height: 705,
            i: 'vertical:left'
          },
          {
            x: 4,
            y: 0,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:center_top'
          },
          {
            x: 4,
            y: 2,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:center'
          },
          {
            x: 4,
            y: 4,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:center_bottom'
          },
          {
            x: 8,
            y: 0,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:right_top'
          },
          {
            x: 8,
            y: 2,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:right_center'
          },
          {
            x: 8,
            y: 4,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:right_bottom'
          }
        ]
      },
      {
        type: 'nine-divition',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:left_top'
          },
          {
            x: 0,
            y: 2,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:left_center'
          },
          {
            x: 0,
            y: 4,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:left_bottom'
          },
          {
            x: 4,
            y: 0,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:center_top'
          },
          {
            x: 4,
            y: 2,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:center'
          },
          {
            x: 4,
            y: 4,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:center_bottom'
          },
          {
            x: 8,
            y: 0,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:right_top'
          },
          {
            x: 8,
            y: 2,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:right_center'
          },
          {
            x: 8,
            y: 4,
            w: 4,
            h: 2,
            width: 229,
            height: 229,
            i: 'vertical:right_bottom'
          }
        ]
      },
      {
        type: 'vertical-short',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 6,
            h: 3,
            width: 348,
            height: 348,
            i: 'short:left'
          },
          {
            x: 6,
            y: 0,
            w: 6,
            h: 3,
            width: 348,
            height: 348,
            i: 'short:right'
          }
        ]
      },
      {
        type: 'three-vertical-short',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 4,
            h: 3,
            width: 229,
            height: 348,
            i: 'short:left'
          },
          {
            x: 4,
            y: 0,
            w: 4,
            h: 3,
            width: 229,
            height: 348,
            i: 'short:center'
          },
          {
            x: 8,
            y: 0,
            w: 4,
            h: 3,
            width: 229,
            height: 348,
            i: 'short:right'
          }
        ]
      },
      {
        type: 'four-vertical-short',
        grid_layout: [
          {
            x: 0,
            y: 0,
            w: 3,
            h: 3,
            width: 170,
            height: 348,
            i: 'short:left'
          },
          {
            x: 3,
            y: 0,
            w: 3,
            h: 3,
            width: 170,
            height: 348,
            i: 'short:center_left'
          },
          {
            x: 6,
            y: 0,
            w: 3,
            h: 3,
            width: 170,
            height: 348,
            i: 'short:center_right'
          },
          {
            x: 9,
            y: 0,
            w: 3,
            h: 3,
            width: 170,
            height: 348,
            i: 'short:right'
          }
        ]
      }
    ]
  }

  get grid_layout() {
    if (!this.type) return []

    const option = this.collage_options.find(image_option => image_option.type === this.type)

    if (!option || !option.grid_layout) return []

    return option.grid_layout
  }

  /**
   * ダイアログの表示
   * @returns {void}
   */
  open(): void {
    this.visible = true
    this.type = null
  }

  /**
   * ダイアログの非表示
   * @returns {void}
   */
  hide(): void {
    this.visible = false
  }

  /**
   * Change template
   * @param {string} type
   * @returns {void}
   */
  setType(type: string): void {
    this.type = type
  }

  /**
   * コラージュ画像を投稿に挿入する
   * @returns {Promise<void>} result
   */
  async saveImage(): Promise<void> {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const width = 720
    let height = 720

    if (
      this.type === 'vertical-short' ||
      this.type === 'three-vertical-short' ||
      this.type === 'four-vertical-short'
    ) {
      height = 360
    }

    canvas.width = width
    canvas.height = height

    ctx.rect(0, 0, width, height)
    ctx.lineWidth = 1
    ctx.strokeStyle = '#dcdfe5'
    ctx.stroke()

    const rect = this.$refs.collage_image.getBoundingClientRect()

    const node_list = this.$refs.collage_image.querySelectorAll('canvas')

    for (let index = 0; index < node_list.length; index = index + 1) {
      const item_rect = node_list[index].getBoundingClientRect()
      ctx.drawImage(node_list[index], item_rect.left - rect.left, item_rect.top - rect.top)
    }

    const base64 = canvas.toDataURL('image/png')
    const blob = this.base64ToBlob(base64)

    this.$emit('input', blob)
    this.hide()
  }

  /**
   * Base64をBlobに変換
   * @param {string} base64 Base64
   * @returns {Blob} Blob
   */
  base64ToBlob(base64: string): Blob {
    let mime_type = ''

    let raw = base64.replace(/data:(image\/.+);base64,/, (header, imageType) => {
      mime_type = imageType
      return ''
    })

    raw = atob(raw)

    const length = raw.length

    const array = new Uint8Array(length)

    for (let i = 0; i < length; i++) {
      array[i] = raw.charCodeAt(i)
    }

    return new Blob([array], { type: mime_type })
  }
}
