import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import { NO_IMAGE_URL } from '@/client/constants/media'

type TSymbol = 'facebook' | 'twitter' | 'instagram'

@Component({
  name: 'Photo',
  components: {
    Flex,
    Icon
  }
})
export default class Photo extends Vue {
  @Prop({ type: String, default: null })
  img: string | null

  @Prop({ type: Array, default: null })
  symbol: TSymbol[] | null

  @Prop({ type: Boolean, default: false })
  isActive: boolean

  @Prop({ type: Boolean, default: false })
  isDelete: boolean

  get use_facebook() {
    return this.symbol && this.symbol.indexOf('facebook') >= 0
  }

  get use_twitter() {
    return this.symbol && this.symbol.indexOf('twitter') >= 0
  }

  get use_instagram() {
    return this.symbol && this.symbol.indexOf('instagram') >= 0
  }

  get style_photo() {
    const image_url = this.img ? this.img : NO_IMAGE_URL
    return { 'background-image': `url(${image_url})` }
  }

  @Emit('select')
  selectPhoto(event: Event) {
    return event
  }

  @Emit('remove')
  removePhoto(event: Event) {
    return event
  }
}
