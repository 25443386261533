// コンポーネント種別
export const TYPES = [
  {
    value: 'total',
    text: '集計'
  },
  {
    value: 'chart',
    text: 'グラフ'
  },
  {
    value: 'post_ranking',
    text: '投稿ランキング'
  },
  {
    value: 'stories_ranking',
    text: 'ストーリーズランキング'
  },
  {
    value: 'goal',
    text: '目標'
  },
  {
    value: 'table',
    text: 'テーブル'
  }
]

// データ種別
export const DATA_TYPES = [
  {
    value: 'daily_data',
    text: '日次データ',
    color: 'info'
  },
  {
    value: 'post_data',
    text: '投稿データ(公開日で集計)',
    color: 'success'
  }
]

// インスタグラム データ種別
export const IN_DATA_TYPES = [
  ...DATA_TYPES,
  {
    value: 'stories_data',
    text: 'ストーリーズデータ(公開日で集計)',
    color: 'danger'
  }
]

// 間隔種別
export const INTERVALS = [
  { text: '1日', value: '1_day' },
  { text: '7日', value: '7_day' },
  { text: '14日', value: '14_day' },
  { text: '28日', value: '28_day' },
  { text: '1ヶ月', value: '1_month' },
  { text: '3ヶ月', value: '3_month' },
  { text: '6ヶ月', value: '6_month' }
]

// 集計種別
export const UNITS = [
  { text: '期間合計', value: 'total' },
  { text: '1投稿あたり', value: 'average' },
  { text: '主要', value: 'major' }
]

// 順序種別
export const POST_RANKING_SORTS = [
  { value: 'ascending', text: 'WORST' },
  { value: 'descending', text: 'BEST' }
]

// 件数種別
export const POST_RANKING_LIMITS = [
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 }
]

// 日次データ指標オプション
export const DAILY_DATA_METRICS = [
  {
    text: 'フォロワー数(累計)',
    value: 'followers_count',
    tooltip: 'アカウント登録より前の値は取得できません。'
  },
  { text: 'フォロワー数(増減)', value: 'followers_count_up_down', tooltip: '' },
  {
    text: 'フォロー数(累計)',
    value: 'follows_count',
    tooltip: 'アカウント登録より前の値は取得できません。'
  },
  { text: 'フォロー数(増減)', value: 'follows_count_up_down', tooltip: '' },
  {
    text: 'リーチ',
    value: 'impressions_unique',
    tooltip: '投稿(フィード・リール・ストーリーズ)などのコンテンツが表示されたアカウント数です。'
  },
  {
    text: 'インプレッション',
    value: 'impressions',
    tooltip: '投稿(フィード・ストーリーズ)の表示回数の合計数です。'
  },
  { text: 'プロフィールビュー', value: 'profile_views', tooltip: '' },
  { text: 'ウェブサイトクリック', value: 'website_clicks', tooltip: '' },
  { text: 'メッセージをクリック', value: 'text_message_clicks', tooltip: '' },
  { text: '道順をクリック', value: 'get_directions_clicks', tooltip: '' },
  { text: '電話をクリック', value: 'phone_call_clicks', tooltip: '' },
  { text: 'メールをクリック', value: 'email_contacts_clicks', tooltip: '' },
  { text: '投稿数', value: 'post_count', tooltip: '' }
]

// 投稿データ指標オプション
export const POST_DATA_METRICS = [
  { text: 'リーチ', value: 'impressions_unique', tooltip: '投稿が表示されたアカウント数です。' },
  { text: 'インプレッション', value: 'impressions', tooltip: '投稿の表示回数です。' },
  { text: 'いいね！', value: 'likes', tooltip: '' },
  { text: 'コメント', value: 'comments', tooltip: '' },
  { text: '保存', value: 'saved_count', tooltip: '' },
  { text: 'シェア(リール)', value: 'shares', tooltip: '' },
  { text: '反応数', value: 'reactions', tooltip: 'いいね・コメントの合計数です。' },
  {
    text: '反応率',
    value: 'reactions_rate',
    tooltip: '反応数 ÷ 公開日のフォロワー数です。'
  },
  {
    text: 'エンゲージメント数',
    value: 'engagements',
    tooltip: 'いいね・コメント・保存の合計数です。リールの場合はシェアも含みます。'
  },
  {
    text: 'エンゲージメント率',
    value: 'engagements_rate',
    tooltip: 'エンゲージメント数 ÷ リーチです。'
  },
  { text: '動画再生数', value: 'video_views', tooltip: '' }
]

// ストーリーズデータ指標オプション
export const STORIES_DATA_METRICS = [
  { text: 'リーチ', value: 'reach', tooltip: 'ストーリーズが表示されたアカウント数です。' },
  { text: 'インプレッション', value: 'impressions', tooltip: 'ストーリーズの表示回数です。' },
  { text: '前へ', value: 'taps_back', tooltip: '' },
  { text: '次へ', value: 'taps_forward', tooltip: '' },
  { text: 'ストーリーズから移動', value: 'exits', tooltip: '' }
]

// 集計用のファンデータ指標オプション
const FAN_TOTAL_DATA_METRICS = [
  { text: '主な性別・年代', value: 'audience_gender_age', tooltip: '' },
  { text: '主な国', value: 'audience_country', tooltip: '' },
  {
    text: '主な場所',
    value: 'audience_location',
    tooltip: ''
  },
  {
    text: '主な都道府県',
    value: 'audience_city',
    tooltip: ''
  },
  {
    text: 'アクティブな時間帯',
    value: 'online_followers',
    tooltip: ''
  }
]

const ONLINE_FOLLOWER_DATA_METRICS = [
  {
    text: 'オンラインの時間帯',
    value: 'online_followers',
    tooltip:
      '対象日でInstagramにログインしたフォロワーの合計値を1時間ごとに分類した値です。データが存在しない場合は、対象期間を広げてご確認ください。'
  }
]

// 集計用日次データ指標オプション
export const TOTAL_DAILY_DATA_METRICS = [
  ...DAILY_DATA_METRICS,
  ...FAN_TOTAL_DATA_METRICS,
  { text: 'プロフィールへのアクション', value: 'profile_clicks', tooltip: '' }
]

// 集計用投稿データ指標オプション
export const TOTAL_POST_DATA_METRICS = POST_DATA_METRICS

// 集計用ストーリーズデータ指標オプション
export const TOTAL_STORIES_DATA_METRICS = STORIES_DATA_METRICS

// 目標用日次データ指標オプション
export const GOAL_DAILY_DATA_METRICS = DAILY_DATA_METRICS

// 目標用投稿データ指標オプション
export const GOAL_POST_DATA_METRICS = POST_DATA_METRICS

// 表の日次データ指標オプション
export const TABLE_DAILY_DATA_METRICS = [...DAILY_DATA_METRICS]

// 表の投稿データ指標オプション
export const TABLE_POST_DATA_METRICS = [...POST_DATA_METRICS]

// 表のストーリーズデータ指標オプション
export const TABLE_STORIES_DATA_METRICS = [...STORIES_DATA_METRICS]

// 垂直チャートの日次データ指標オプション
export const CHANGE_CHART_DAILY_DATA_METRICS = [
  ...DAILY_DATA_METRICS,
  ...ONLINE_FOLLOWER_DATA_METRICS,
  { text: 'プロフィールへのアクション', value: 'profile_clicks', tooltip: '' }
]

// 垂直チャートの投稿データ指標オプション
export const CHANGE_CHART_POST_DATA_METRICS = POST_DATA_METRICS

export const CHANGE_CHART_STORIES_DATA_METRICS = STORIES_DATA_METRICS

// 投稿ランキング指標オプション
export const POST_RANKING_MAIN_METRICS = [
  ...POST_DATA_METRICS,
  { text: '公開日時', value: 'created_time', tooltip: '' },
  { text: '公開日のフォロワー数', value: 'followers_at_that_time', tooltip: '' }
]

export const POST_RANKING_SUB_METRICS = [...POST_RANKING_MAIN_METRICS]

export const STORIES_POST_RANKING_MAIN_METRICS = [
  ...STORIES_DATA_METRICS,
  { text: '公開日時', value: 'created_time', tooltip: '' }
]

export const STORIES_POST_RANKING_SUB_METRICS = [...STORIES_POST_RANKING_MAIN_METRICS]

// 水平チャートの日次データ指標オプション
export const HORIZONTAL_CHART_DAILY_DATA_METRICS = [
  {
    text: '性別・年代',
    value: 'audience_gender_age',
    tooltip: '対象期間の最終日時点のフォロワーの性別、年代別の内訳'
  },
  {
    text: 'フォロワー数(場所別ベスト10)',
    value: 'audience_location',
    tooltip: '対象期間の最終日時点のフォロワーの場所別の内訳'
  }
]

// 水平チャートの投稿データ指標オプション
export const HORIZONTAL_CHART_POST_DATA_METRICS = []

export const MAP_CHART_DAILY_DATA_METRICS = [
  {
    text: 'フォロワー数(国)',
    value: 'audience_country',
    tooltip: '対象期間の最終日時点のフォロワー数の国の内訳'
  },
  {
    text: 'フォロワー数(都道府県)',
    value: 'audience_city',
    tooltip: '対象期間の最終日時点のフォロワー数の都道府県の内訳'
  }
]

export const TABLE_COLUMN_METRICS = [
  { text: 'リーチ', value: 'impressions_unique' },
  { text: 'インプレッション', value: 'impressions' },
  { text: 'いいね！', value: 'likes' },
  { text: 'コメント', value: 'comments' },
  { text: '保存', value: 'saved_count' },
  { text: 'シェア(リール)', value: 'shares' },
  { text: '反応数', value: 'reactions' },
  { text: '反応率', value: 'reactions_rate' },
  { text: 'エンゲージメント数', value: 'engagements' },
  { text: 'エンゲージメント率', value: 'engagements_rate' },
  { text: '動画再生数', value: 'video_views' },
  { text: '公開日のフォロワー数', value: 'followers_at_that_time' }
]

export const HALF_DOUGHNUT_CHART_POST_DATA_METRICS = [
  {
    text: '反応数の内訳',
    value: 'reactions',
    tooltip: ''
  },
  {
    text: 'エンゲージメント数の内訳',
    value: 'engagements',
    tooltip: ''
  }
]

export const POST_TABLE_METRICS = [
  { text: '公開日時', value: 'created_time' },
  { text: '投稿内容', value: 'message' },
  { text: '投稿タイプ', value: 'type' },
  { text: '投稿ID', value: 'post_id' },
  { text: '投稿URL', value: 'sns_link' },
  { text: 'サムネイルURL', value: 'picture_url' },
  { text: '動画URL', value: 'video_url' },
  { text: 'タグ', value: 'category_name' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_TYPE_METRICS = [
  { text: '投稿タイプ', value: 'type' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_HASHTAG_METRICS = [
  { text: 'ハッシュタグ', value: 'hashtag' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_CATEGORY_METRICS = [
  { text: 'タグ', value: 'category' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_CHARACTER_COUNT_METRICS = [
  { text: '文字数レンジ', value: 'character_range' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_TOTAL = [
  { text: '公開された投稿', value: 'none' },
  { text: '投稿タイプ', value: 'type' },
  { text: 'ハッシュタグ', value: 'hashtag' },
  { text: 'タグ', value: 'category' },
  { text: '文字数', value: 'character_count' }
]

export const POST_TABLE_TABS = [
  { text: '平均', value: 'average' },
  { text: '合計', value: 'total' }
]

export const POST_TABLE_SORT_BLACKLIST = [
  'account_id',
  'created_time',
  'message',
  'type',
  'post_id',
  'sns_link',
  'picture_url',
  'video_url'
]

export const POST_TYPE = [
  { text: '画像', value: 'image' },
  { text: '動画', value: 'video' },
  { text: 'カルーセル', value: 'carousel' },
  { text: 'リール', value: 'reels' }
]

export const STORIES_TYPE = [
  { text: '画像', value: 'image' },
  { text: '動画', value: 'video' }
]

export const STORIES_TABLE_TOTAL = [
  { text: '公開されたストーリーズ', value: 'none' },
  { text: 'メディアタイプ', value: 'type' },
  { text: 'タグ', value: 'category' }
]

export const STORIES_TABLE_METRICS = [
  { text: 'タグ', value: 'category' },
  { text: '投稿数', value: 'post_count' },
  { text: '公開日時', value: 'created_time' },
  { text: '投稿内容', value: 'message' },
  { text: 'メディアタイプ', value: 'type' },
  { text: '投稿ID', value: 'stories_id' },
  { text: '投稿URL', value: 'sns_link' },
  { text: 'サムネイルURL', value: 'thumbnail_url' },
  { text: 'メディアURL', value: 'media_url' },
  { text: 'リーチ', value: 'reach' },
  { text: 'インプレッション', value: 'impressions' },
  { text: '前へ', value: 'taps_back' },
  { text: '次へ', value: 'taps_forward' },
  { text: 'ストーリーズから移動', value: 'exits' },
  { text: 'タグ', value: 'category_name' }
]

export const STORIES_TABLE_SORT_BLACKLIST = [
  'account_id',
  'created_time',
  'message',
  'type',
  'stories_id',
  'sns_link',
  'thumbnail_url',
  'media_url'
]

export const CHARACTER_RANGE = [
  {
    title: '10文字未満',
    greater_than: 0,
    less_than: 10
  },
  {
    title: '10文字以上 - 50文字未満',
    greater_than: 10,
    less_than: 50
  },
  {
    title: '50文字以上 - 100文字未満',
    greater_than: 50,
    less_than: 100
  },
  {
    title: '100文字以上 - 200文字未満',
    greater_than: 100,
    less_than: 200
  },
  {
    title: '200文字以上 - 500文字未満',
    greater_than: 200,
    less_than: 500
  },
  {
    title: '500文字以上 - 1,000文字未満',
    greater_than: 500,
    less_than: 1000
  },
  {
    title: '1,000文字以上',
    greater_than: 1000,
    less_than: null
  }
]
