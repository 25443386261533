import moment from 'moment-timezone'
import qs from 'qs'

export function getTwitterSearchUrl(base_query: string, start_date: Date, end_date: Date) {
  const since = moment(start_date).format('YYYY-MM-DD_HH:mm:ss')
  const until = moment(end_date).format('YYYY-MM-DD_HH:mm:ss')

  const query = qs.stringify({
    q: `${base_query} since:${since}_JST until:${until}_JST`,
    f: 'live'
  })

  return `https://x.com/search?${query}`
}
