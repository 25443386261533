import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import EmbedTwitterTimeline from '@/client/components-old/atoms/EmbedTwitterTimeline'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { IRootState } from '@/client/store/global'

@Component({
  name: 'DashboardNotice',
  components: {
    EmbedTwitterTimeline,
    Icon,
    Panel,
    Tooltip
  }
})
export default class DashboardNotice extends Vue {
  @State('user') user: IRootState['user']
}
