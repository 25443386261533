interface TwitterEnterpriseCallbackSuccess {
  type: 'SUCCESS'
  account_id: string
}

interface TwitterEnterpriseCallbackError {
  type: 'AUTHENTICATION_FAILED' | 'INVALID_ACCOUNT'
}

type TwitterEnterpriseCallbackQuery =
  | TwitterEnterpriseCallbackSuccess
  | TwitterEnterpriseCallbackError

export class TwitterEnterpriseAuthService {
  static async openAuthWindow(account_id: string) {
    const width = 600
    const height = 800
    const top = (screen.height - height) / 4
    const left = (screen.width - width) / 2

    const auth = window.open(
      '/api/v1/oauth/twitter_enterprise?id=' + account_id,
      'twitter_enterprise_oauth',
      `width=${width}, height=${height}, top=${top}, left=${left}`
    )

    const response = await new Promise<TwitterEnterpriseCallbackQuery>(resolve => {
      window.onTwitterEnterpriseAuth = resolve
    })

    if (auth) auth.close()

    return response
  }
}
