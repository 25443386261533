import delay from 'delay'
import moment from 'moment-timezone'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  TGetMonitoringSettingsResponse,
  TMonitoringSetting
} from '@/client/utils/api/monitoring_settings'
import {
  TGetMonitoringWordsParams,
  TGetMonitoringWordsResponse,
  TMonitoringWord
} from '@/client/utils/api/monitoring_words'
import {
  TDeleteMonitoringBlockParams,
  TDeleteMonitoringBlockResponse,
  TDeleteMonitoringQuoteRetweetResponse,
  TDeleteMonitoringReplyParams,
  TDeleteMonitoringReplyResponse,
  TGetMonitoringParams,
  TGetMonitoringResponse,
  TGetMonitoringsHistoriesParams,
  TGetMonitoringsHistoriesResponse,
  TGetMonitoringsMemosParams,
  TGetMonitoringsMemosResponse,
  TGetMonitoringsParams,
  TGetMonitoringsResponse,
  TMonitoring,
  TMonitoringCounts,
  TMonitoringHistory,
  TMonitoringMemo,
  TPostMonitoringBlockParams,
  TPostMonitoringBlockResponse,
  TPostMonitoringMemosParams,
  TPostMonitoringMemosResponse,
  TPostMonitoringQuoteRetweetParams,
  TPostMonitoringQuoteRetweetResponse,
  TPostMonitoringRepliesParams,
  TPostMonitoringRepliesResponse,
  TPutBulkMonitoringStatusParams,
  TPutBulkMonitoringStatusResponse,
  TPutMonitoringParams,
  TPutMonitoringReplyParams,
  TPutMonitoringReplyResponse,
  TPutMonitoringResponse
} from '@/client/utils/api/monitorings'
import { GetUsersRolesParams, GetUsersRolesResponse, UsersRoles } from '@/client/utils/api/users'
import event from '@/client/utils/event'
import storage from '@/client/utils/storage'

type TMonitoringType =
  | 'facebook_comment'
  | 'facebook_message'
  | 'facebook_post'
  | 'twitter_reply'
  | 'twitter_mention'
  | 'twitter_message'
  | 'instagram_comment'

export interface IState {
  api_monitorings: TMonitoring[]
  api_counts: TMonitoringCounts
  api_memos: TMonitoringMemo[]
  api_histories: TMonitoringHistory[]
  api_words: TMonitoringWord[]
  api_setting: null | TMonitoringSetting
  api_persons: UsersRoles[]
  start_date: string
  end_date: string
  status: 'unread' | 'read' | 'pending' | 'all'
  filter_account_id: '' | string
  filter_account_sns: '' | 'facebook' | 'twitter' | 'instagram'
  filter_types: TMonitoringType[]
  filter_persons: number[]
  filter_keyword: '' | string
  selected_monitoring: { monitoring_id: number; sns_type: string } | null
  checked_monitorings: { monitoring_id: number; sns_type: string }[]
  dialog_detail_visible: boolean
  dialog_detail_tab: 'preview' | 'memo' | 'history'
  is_loading: boolean
  is_bulk_loading: boolean
  is_csv_loading: boolean
  is_scroll: boolean
  is_dialog: boolean
  is_dialog_exist: boolean
  is_dialog_role: boolean
  is_checked_all: boolean
  destination_ids: number[]
  memo_cache: {
    monitoring_id: number
    sns_type: string
    message: string
  }
}

const state: IState = {
  api_monitorings: [],
  api_counts: { unread: 0, read: 0, pending: 0, all: 0 },
  api_memos: [],
  api_histories: [],
  api_words: [],
  api_setting: null,
  api_persons: [],
  start_date: moment().subtract(30, 'day').format('YYYY-MM-DD'),
  end_date: moment().format('YYYY-MM-DD'),
  status: 'unread',
  filter_account_id: '',
  filter_account_sns: '',
  filter_types: [],
  filter_persons: [],
  filter_keyword: '',
  selected_monitoring: null,
  checked_monitorings: [],
  dialog_detail_visible: false,
  dialog_detail_tab: 'preview',
  is_loading: false,
  is_bulk_loading: false,
  is_csv_loading: false,
  is_scroll: false,
  is_dialog: false,
  is_dialog_exist: true,
  is_dialog_role: true,
  is_checked_all: false,
  destination_ids: [],
  memo_cache: {
    monitoring_id: 0,
    sns_type: '',
    message: ''
  }
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  SET_API_MONITORINGS(state, payload) {
    state.api_monitorings = payload
  },
  SET_API_COUNTS(state, payload) {
    state.api_counts = payload
  },
  SET_API_MEMOS(state, payload) {
    state.api_memos = payload
  },
  SET_API_HISTORIES(state, payload) {
    state.api_histories = payload
  },
  SET_API_WORDS(state, payload) {
    state.api_words = payload
  },
  SET_API_SETTING(state, payload) {
    state.api_setting = payload
  },
  SET_API_PERSONS(state, payload: IState['api_persons']) {
    state.api_persons = payload
  },
  SET_DATE(state, payload) {
    state.start_date = payload.start_date
    state.end_date = payload.end_date
  },
  SET_STATUS(state, payload) {
    state.status = payload
  },
  SET_FILTER_ACCOUNT(state, payload) {
    const { account_id, account_sns } = payload

    state.filter_account_id = account_id
    state.filter_account_sns = account_sns

    storage.set('monitoring', { account_id, account_sns })
  },
  SET_FILTER_TYPES(state, payload) {
    state.filter_types = payload
  },
  SET_FILTER_PERSONS(state, payload) {
    state.filter_persons = payload
  },
  SET_FILTER_KEYWORD(state, payload) {
    state.filter_keyword = payload
  },
  SET_SELECTED_MONITORING(state, payload) {
    state.selected_monitoring = payload
  },
  SET_CHECKED_MONITORINGS(state, payload) {
    state.checked_monitorings = payload
  },
  SET_DIALOG_DETAIL_VISIBLE(state, payload) {
    state.dialog_detail_visible = payload
  },
  SET_DIALOG_DETAIL_TAB(state, payload) {
    state.dialog_detail_tab = payload
  },
  SET_IS_LOADING(state, payload) {
    state.is_loading = payload
  },
  SET_IS_BULK_LOADING(state, payload) {
    state.is_bulk_loading = payload
  },
  SET_IS_CSV_LOADING(state, payload) {
    state.is_csv_loading = payload
  },
  SET_IS_SCROLL(state, payload) {
    state.is_scroll = payload
  },
  SET_IS_DIALOG(state, payload) {
    state.is_dialog = payload
  },
  SET_IS_DIALOG_EXIST(state, payload) {
    state.is_dialog_exist = payload
  },
  SET_IS_DIALOG_ROLE(state, payload) {
    state.is_dialog_role = payload
  },
  SET_IS_CHECKED_ALL(state, payload) {
    state.is_checked_all = payload
  },
  SET_DESTINATION_IDS(state, payload) {
    state.destination_ids = payload
    storage.set('monitoring', { destination_ids: payload })
  },
  SET_MEMO_CACHE(state, payload) {
    state.memo_cache = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * API: GET /monitorings
   */
  async getMonitorings(
    context,
    payload: { offset?: number; limit?: number; is_csv?: boolean } = {}
  ) {
    const params: TGetMonitoringsParams = {
      project_id: context.rootState.project.id,
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      status: context.state.status,
      offset: payload.offset || 0,
      limit: payload.limit || 20
    }

    if (context.state.filter_account_sns.length) {
      params.sns_type = context.state.filter_account_sns
    }

    if (context.state.filter_account_id.length) {
      params.account_id = context.state.filter_account_id
    }

    if (context.state.filter_types.length) {
      params.types = context.state.filter_types.join(',')
    }

    if (context.state.filter_persons) {
      params.persons = context.state.filter_persons.join(',')
    }

    if (context.state.filter_keyword.length) {
      params.keyword = context.state.filter_keyword
    }

    const response = await API.get<TGetMonitoringsResponse>('monitorings', { params })

    if (response.data.data && !payload.is_csv) {
      if (params.offset) {
        const monitorings = context.state.api_monitorings.concat(response.data.data.monitorings)

        context.commit('SET_API_MONITORINGS', monitorings)
      } else {
        const monitorings = response.data.data.monitorings
        const counts = response.data.data.counts

        context.commit('SET_API_MONITORINGS', monitorings)
        context.commit('SET_API_COUNTS', counts)
      }
    }

    return response.data
  },

  /**
   * API: GET /monitorings/:monitoring_id
   */
  async getMonitoring(context, payload: { monitoring_id: number; sns_type: string }) {
    const params: TGetMonitoringParams = {
      sns_type: payload.sns_type
    }

    const response = await API.get<TGetMonitoringResponse>(`monitorings/${payload.monitoring_id}`, {
      params
    })

    if (response.data.data) {
      if (
        context.state.api_monitorings.some(
          v => v.id === payload.monitoring_id && v.sns === payload.sns_type
        )
      ) {
        const monitorings = context.state.api_monitorings.map(v => {
          if (v.id === payload.monitoring_id && v.sns === payload.sns_type) {
            return response.data.data
          }

          return v
        })

        context.commit('SET_API_MONITORINGS', monitorings)
      } else {
        const monitorings = context.state.api_monitorings.concat(response.data.data)

        context.commit('SET_API_MONITORINGS', monitorings)
      }
    }

    return response.data
  },

  /**
   * API: GET /monitorings/:monitoring_id/memos
   */
  async getMonitoringsMemos(context, payload: { monitoring_id: number; sns_type: string }) {
    const params: TGetMonitoringsMemosParams = { sns_type: payload.sns_type }

    const response = await API.get<TGetMonitoringsMemosResponse>(
      `monitorings/${payload.monitoring_id}/memos`,
      { params }
    )

    if (response.data.data) {
      context.commit('SET_API_MEMOS', response.data.data)
    }

    return response.data
  },

  /**
   * API: GET /monitorings/:monitoring_id/histories
   */
  async getMonitoringsHistories(context, payload: { monitoring_id: number; sns_type: string }) {
    const params: TGetMonitoringsHistoriesParams = { sns_type: payload.sns_type }

    const response = await API.get<TGetMonitoringsHistoriesResponse>(
      `monitorings/${payload.monitoring_id}/histories`,
      { params }
    )

    if (response.data.data) {
      context.commit('SET_API_HISTORIES', response.data.data)
    }

    return response.data
  },

  /**
   * API: GET /monitoring_words
   */
  async getMonitoringWords(context) {
    const params: TGetMonitoringWordsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<TGetMonitoringWordsResponse>('monitoring_words', { params })

    if (response.data.data) {
      context.commit('SET_API_WORDS', response.data.data)
    }

    return response.data
  },

  /**
   * API: GET /monitoring_settings/:project_id
   */
  async getMonitoringSettings(context) {
    const response = await API.get<TGetMonitoringSettingsResponse>(
      `monitoring_settings/${context.rootState.project.id}`
    )

    if (response.data.data) {
      context.commit('SET_API_SETTING', response.data.data)
    }

    return response.data
  },

  /**
   * API: GET users?type=role
   */
  async getUsersTypeRole(context) {
    const params: GetUsersRolesParams = {
      project_id: context.rootState.project.id,
      role_type: 'monitoring',
      role_status: true
    }

    const response = await API.get<GetUsersRolesResponse>('users/roles', { params })

    if (response.data.data) {
      context.commit('SET_API_PERSONS', response.data.data)
    }

    return response.data
  },

  /**
   * モニタリングページのデータ取得
   */
  async createdMonitoringPage(context) {
    const local: { destination_ids?: number[] } = storage.get('monitoring')

    // ストレージに保存されていたら使用する
    if (local && local.destination_ids && Array.isArray(local.destination_ids)) {
      context.commit('SET_DESTINATION_IDS', local.destination_ids)
    }

    context.commit('SET_IS_LOADING', true)

    await Promise.all([
      context.dispatch('getMonitorings'),
      context.dispatch('getMonitoringSettings'),
      context.dispatch('getMonitoringWords'),
      context.dispatch('getUsersTypeRole')
    ])

    context.commit('SET_IS_LOADING', false)
  },

  /**
   * モニタリングページのデータ初期化
   */
  async destroyedMonitoringPage(context) {
    context.commit('SET_API_MONITORINGS', [])
    context.commit('SET_API_COUNTS', { unread: 0, read: 0, pending: 0, all: 0 })
    context.commit('SET_SELECTED_MONITORING', null)
    context.commit('SET_CHECKED_MONITORINGS', [])
    context.commit('SET_FILTER_KEYWORD', '')
    context.commit('SET_FILTER_TYPES', [])
    context.commit('SET_FILTER_PERSONS', [])
    context.commit('SET_STATUS', 'unread')
    context.commit('SET_IS_CHECKED_ALL', false)
    context.commit('SET_MEMO_CACHE', { monitoring_id: 0, sns_type: '', message: '' })
  },

  /**
   * モニタリングページの更新
   */
  async refreshMonitoringPage(context) {
    context.commit('SET_IS_LOADING', true)

    const data = await context.dispatch('getMonitorings')

    // 連打するとローディング表示にならないでレスポンスが終わるため、少し遅延させる
    await event.delay(500)

    context.commit('SET_IS_LOADING', false)

    return data
  },

  /**
   * モニタリングページのスクロール
   */
  async scrollMonitoringPage(context) {
    const offset =
      context.state.status === 'all'
        ? context.state.api_monitorings.length
        : context.state.api_monitorings.filter(v => v.status === context.state.status).length

    const offset_max = context.state.api_counts[context.state.status]

    if (context.state.is_scroll || offset >= offset_max) return

    context.commit('SET_IS_SCROLL', true)

    await context.dispatch('getMonitorings', { offset })

    context.commit('SET_IS_SCROLL', false)
  },

  /**
   * フィルタリングするアカウントの変更
   */
  async changeMonitoringAccount(context, payload: { account_id: string; account_sns: string }) {
    context.commit('SET_FILTER_ACCOUNT', payload)
    context.commit('SET_FILTER_TYPES', [])
    context.commit('SET_IS_CHECKED_ALL', false)
    context.commit('SET_CHECKED_MONITORINGS', [])

    context.commit('SET_IS_LOADING', true)

    const data = await context.dispatch('getMonitorings')

    context.commit('SET_IS_LOADING', false)

    return data
  },

  /**
   * フィルタリングするタイプの変更
   */
  async changeMonitoringTypes(context, payload: string) {
    context.commit('SET_FILTER_TYPES', payload)
    context.commit('SET_IS_CHECKED_ALL', false)
    context.commit('SET_CHECKED_MONITORINGS', [])

    context.commit('SET_IS_LOADING', true)

    const data = await context.dispatch('getMonitorings')

    context.commit('SET_IS_LOADING', false)

    return data
  },

  /**
   * フィルタリングする担当者の変更
   */
  async changeMonitoringPersons(context, payload: string) {
    context.commit('SET_FILTER_PERSONS', payload)
    context.commit('SET_IS_CHECKED_ALL', false)
    context.commit('SET_CHECKED_MONITORINGS', [])

    context.commit('SET_IS_LOADING', true)

    const data = await context.dispatch('getMonitorings')

    context.commit('SET_IS_LOADING', false)

    return data
  },

  /**
   * フィルタリングするキーワードの変更
   */
  async changeMonitoringKeyword(context, payload: string) {
    context.commit('SET_FILTER_KEYWORD', payload)
    context.commit('SET_IS_CHECKED_ALL', false)
    context.commit('SET_CHECKED_MONITORINGS', [])

    context.commit('SET_IS_LOADING', true)

    const data = await context.dispatch('getMonitorings')

    context.commit('SET_IS_LOADING', false)

    return data
  },

  /**
   * モニタリング日付の変更
   */
  async changeMonitoringDate(context, payload: { start_date: string; end_date: string }) {
    context.commit('SET_DATE', payload)
    context.commit('SET_IS_CHECKED_ALL', false)
    context.commit('SET_CHECKED_MONITORINGS', [])

    context.commit('SET_IS_LOADING', true)

    const data = await context.dispatch('getMonitorings')

    context.commit('SET_IS_LOADING', false)

    return data
  },

  /**
   * モニタリングタブの変更
   */
  async changeMonitoringStatus(context, payload: string) {
    context.commit('SET_STATUS', payload)
    context.commit('SET_IS_CHECKED_ALL', false)
    context.commit('SET_CHECKED_MONITORINGS', [])

    context.commit('SET_IS_LOADING', true)

    const data = await context.dispatch('getMonitorings')

    context.commit('SET_IS_LOADING', false)

    return data
  },

  /**
   * モニタリングのCSVを取得
   */
  async downloadMonitoringCSV(context) {
    let monitorings: any[] = []

    context.commit('SET_IS_CSV_LOADING', true)

    while (monitorings.length < context.state.api_counts[context.state.status]) {
      const result = await context.dispatch('getMonitorings', {
        offset: monitorings.length,
        limit: 100,
        is_csv: true
      })

      if (result.data && result.data.monitorings.length) {
        monitorings = monitorings.concat(result.data.monitorings)

        // ? DDoS対策
        await delay(1000)
      }
    }

    context.commit('SET_IS_CSV_LOADING', false)

    return monitorings
  },

  /**
   * モニタリングの選択
   */
  async selectMonitoring(
    context,
    payload: { monitoring_id: number; sns_type: string; is_checked: boolean }
  ) {
    if (payload.is_checked) {
      const params = {
        monitoring_id: payload.monitoring_id,
        sns_type: payload.sns_type
      }

      const checked_monitorings = context.state.checked_monitorings.concat([params])

      context.commit('SET_CHECKED_MONITORINGS', checked_monitorings)
    } else {
      const checked_monitorings = context.state.checked_monitorings.filter(
        v => !(v.monitoring_id === payload.monitoring_id && v.sns_type === payload.sns_type)
      )

      context.commit('SET_CHECKED_MONITORINGS', checked_monitorings)
    }
  },

  /**
   * すべてのモニタリングを選択
   */
  async selectAllMonitoring(context, payload: boolean) {
    context.commit('SET_IS_CHECKED_ALL', payload)

    if (payload) {
      const monitorings =
        context.state.status === 'all'
          ? context.state.api_monitorings
          : context.state.api_monitorings.filter(v => v.status === context.state.status)

      const checked_monitorings = monitorings.map(v => ({
        monitoring_id: v.id,
        sns_type: v.sns
      }))

      context.commit('SET_CHECKED_MONITORINGS', checked_monitorings)
    } else {
      context.commit('SET_CHECKED_MONITORINGS', [])
    }
  },

  /**
   * 一括でモニタリングのステータス変更
   */
  async bulkMonitoringStatus(context, payload: { status: 'unread' | 'read' | 'pending' }) {
    context.commit('SET_IS_BULK_LOADING', true)

    const params: TPutBulkMonitoringStatusParams = {
      status: payload.status,
      monitorings: context.state.checked_monitorings.map(monitoring => ({
        id: monitoring.monitoring_id,
        sns: monitoring.sns_type
      }))
    }

    const response = await API.put<TPutBulkMonitoringStatusResponse>(
      'monitorings/bulk_change_status',
      params
    )

    context.commit('SET_IS_BULK_LOADING', false)

    if (response.data && response.data.data) {
      const count = { ...context.state.api_counts }
      const api_monitorings = context.state.api_monitorings
      const checked_monitorings = context.state.checked_monitorings.map(
        monitoring => monitoring.monitoring_id
      )

      const monitorings = api_monitorings.map(monitoring => {
        if (checked_monitorings.includes(monitoring.id)) {
          return { ...monitoring, status: payload.status }
        }

        return monitoring
      })

      const status = api_monitorings
        .filter(monitoring => checked_monitorings.includes(monitoring.id))
        .map(monitoring => monitoring.status)

      count.unread -= status.filter(s => s === 'unread').length
      count.read -= status.filter(s => s === 'read').length
      count.pending -= status.filter(s => s === 'pending').length
      count[payload.status] += context.state.checked_monitorings.length

      context.commit('SET_API_MONITORINGS', monitorings)
      context.commit('SET_API_COUNTS', count)
      context.commit('SET_IS_CHECKED_ALL', false)
      context.commit('SET_CHECKED_MONITORINGS', [])
      await context.dispatch('refreshMonitoringPage')
    }

    return response.data
  },

  /**
   * モニタリングのステータス更新
   */
  async updateMonitoringStatus(
    context,
    payload: {
      monitoring_id: number
      sns_type: string
      status: 'unread' | 'read' | 'pending'
      memo_message?: string
      memo_destination_ids?: number[]
      persons?: number[]
    }
  ) {
    const params: TPutMonitoringParams = {
      sns_type: payload.sns_type,
      status: payload.status
    }

    if ('memo_message' in payload) {
      params.memo_message = payload.memo_message
    }

    if ('memo_destination_ids' in payload) {
      params.memo_destination_ids = payload.memo_destination_ids
    }

    if ('persons' in payload) {
      params.persons = payload.persons
    }

    const response = await API.put<TPutMonitoringResponse>(
      `monitorings/${payload.monitoring_id}`,
      params
    )

    if (response.data.data) {
      // モニタリング画面の場合
      if (context.state.api_counts.all) {
        const counts = { ...context.state.api_counts }

        const monitoring = context.state.api_monitorings.find(
          v => v.id === payload.monitoring_id && v.sns === payload.sns_type
        )

        if (monitoring) {
          counts[monitoring.status] = context.state.api_counts[monitoring.status] - 1
          counts[payload.status] = context.state.api_counts[payload.status] + 1
          context.commit('SET_API_COUNTS', counts)
        }
      }

      await context.dispatch('getMonitoring', payload)
    }

    if (context.state.dialog_detail_visible) {
      await context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * モニタリングのいいね！更新
   */
  async updateMonitoringLike(
    context,
    payload: { monitoring_id: number; sns_type: string; is_like: boolean }
  ) {
    const monitoring = context.state.api_monitorings.find(
      v => v.id === payload.monitoring_id && v.sns === payload.sns_type
    )

    // 該当のケース以外は処理をスキップ
    if (
      !monitoring ||
      !(
        (monitoring.sns === 'facebook' && monitoring.type === 'comment') ||
        (monitoring.sns === 'facebook' && monitoring.type === 'post') ||
        (monitoring.sns === 'twitter' && monitoring.type === 'reply') ||
        (monitoring.sns === 'twitter' && monitoring.type === 'mention')
      )
    ) {
      return
    }

    const params: TPutMonitoringParams = {
      sns_type: payload.sns_type,
      is_like: payload.is_like
    }

    const response = await API.put<TPutMonitoringResponse>(
      `monitorings/${payload.monitoring_id}`,
      params
    )

    if (response.data.data) {
      if (
        monitoring.status !== 'read' &&
        payload.is_like &&
        context.state.api_setting.like_to_read
      ) {
        await context.dispatch('updateMonitoringStatus', {
          monitoring_id: payload.monitoring_id,
          sns_type: payload.sns_type,
          status: 'read'
        })
      } else {
        await context.dispatch('getMonitoring', payload)
      }
    }

    if (context.state.dialog_detail_visible) {
      await context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * モニタリングのリポスト更新
   */
  async updateMonitoringRetweet(
    context,
    payload: { monitoring_id: number; sns_type: string; is_retweet: boolean }
  ) {
    const monitoring = context.state.api_monitorings.find(
      v => v.id === payload.monitoring_id && v.sns === payload.sns_type
    )

    // 該当のケース以外は処理をスキップ
    if (
      !monitoring ||
      !(
        (monitoring.sns === 'twitter' && monitoring.type === 'reply') ||
        (monitoring.sns === 'twitter' && monitoring.type === 'mention')
      )
    ) {
      return
    }

    const params: TPutMonitoringParams = {
      sns_type: payload.sns_type,
      is_retweet: payload.is_retweet
    }

    const response = await API.put<TPutMonitoringResponse>(
      `monitorings/${payload.monitoring_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('getMonitoring', payload)
    }

    if (context.state.dialog_detail_visible) {
      await context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * モニタリングの非表示を更新
   */
  async updateMonitoringHidden(
    context,
    payload: {
      monitoring_id: number
      sns_type: string
      is_hidden: boolean
      memo_message?: string
      memo_destination_ids?: number[]
    }
  ) {
    const monitoring = context.state.api_monitorings.find(
      v => v.id === payload.monitoring_id && v.sns === payload.sns_type
    )

    // 該当のケース以外は処理をスキップ
    if (
      !monitoring ||
      !(
        (monitoring.sns === 'facebook' && monitoring.type === 'comment') ||
        (monitoring.sns === 'facebook' && monitoring.type === 'post') ||
        (monitoring.sns === 'instagram' && monitoring.type === 'comment') ||
        (monitoring.sns === 'twitter' && monitoring.type === 'reply')
      )
    ) {
      return
    }

    const params: TPutMonitoringParams = {
      sns_type: payload.sns_type,
      is_hidden: payload.is_hidden
    }

    if ('memo_message' in payload) {
      params.memo_message = payload.memo_message
    }

    if ('memo_destination_ids' in payload) {
      params.memo_destination_ids = payload.memo_destination_ids
    }

    const response = await API.put<TPutMonitoringResponse>(
      `monitorings/${payload.monitoring_id}`,
      params
    )

    if (response.data.data) {
      if (
        monitoring.status !== 'read' &&
        payload.is_hidden &&
        context.state.api_setting.hide_to_read
      ) {
        await context.dispatch('updateMonitoringStatus', {
          monitoring_id: payload.monitoring_id,
          sns_type: payload.sns_type,
          status: 'read'
        })
      } else {
        await context.dispatch('getMonitoring', payload)
      }
    }

    if (context.state.dialog_detail_visible) {
      await context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * モニタリングの削除を更新
   */
  async updateMonitoringDelete(
    context,
    payload: {
      monitoring_id: number
      sns_type: string
      memo_message?: string
      memo_destination_ids?: number[]
    }
  ) {
    const monitoring = context.state.api_monitorings.find(
      v => v.id === payload.monitoring_id && v.sns === payload.sns_type
    )

    // 該当のケース以外は処理をスキップ
    if (
      !monitoring ||
      !(
        (monitoring.sns === 'facebook' && monitoring.type === 'comment') ||
        (monitoring.sns === 'facebook' && monitoring.type === 'post') ||
        (monitoring.sns === 'instagram' && monitoring.type === 'comment')
      )
    ) {
      return
    }

    const params: TPutMonitoringParams = {
      sns_type: payload.sns_type,
      is_deleted: true
    }

    if ('memo_message' in payload) {
      params.memo_message = payload.memo_message
    }

    if ('memo_destination_ids' in payload) {
      params.memo_destination_ids = payload.memo_destination_ids
    }

    const response = await API.put<TPutMonitoringResponse>(
      `monitorings/${payload.monitoring_id}`,
      params
    )

    if (response.data.data) {
      if (monitoring.status !== 'read' && context.state.api_setting.delete_to_read) {
        await context.dispatch('updateMonitoringStatus', {
          monitoring_id: payload.monitoring_id,
          sns_type: payload.sns_type,
          status: 'read'
        })
      } else {
        await context.dispatch('getMonitoring', payload)
      }
    }

    if (context.state.dialog_detail_visible) {
      await context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * モニタリングのメモを作成
   */
  async createMonitoringMemo(
    context,
    payload: {
      monitoring_id: number
      sns_type: string
      message: string
      destination_ids?: number[]
    }
  ) {
    const params: TPostMonitoringMemosParams = {
      sns_type: payload.sns_type,
      message: payload.message
    }

    if ('destination_ids' in payload) {
      params.destination_ids = payload.destination_ids
    }

    const response = await API.post<TPostMonitoringMemosResponse>(
      `monitorings/${payload.monitoring_id}/memos`,
      params
    )

    if (response.data.data) {
      const memos = [response.data.data].concat(context.state.api_memos)

      context.commit('SET_API_MEMOS', memos)

      await context.dispatch('getMonitoring', payload)

      context.commit('SET_MEMO_CACHE', { monitoring_id: 0, sns_type: '', message: '' })
    }

    if (context.state.dialog_detail_visible) {
      await context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * モニタリングに返信を送る
   */
  async createMonitoringReply(
    context,
    payload: {
      monitoring_id: number
      sns_type: string
      message: string
    }
  ) {
    const params: TPostMonitoringRepliesParams = {
      sns_type: payload.sns_type,
      message: payload.message
    }

    const response = await API.post<TPostMonitoringRepliesResponse>(
      `monitorings/${payload.monitoring_id}/replies`,
      params
    )

    if (response.data.data) {
      const monitoring = context.state.api_monitorings.find(
        v => v.id === payload.monitoring_id && v.sns === payload.sns_type
      )

      if (monitoring.status !== 'read' && context.state.api_setting.reply_to_read) {
        await context.dispatch('updateMonitoringStatus', {
          monitoring_id: payload.monitoring_id,
          sns_type: payload.sns_type,
          status: 'read'
        })
      } else {
        await context.dispatch('getMonitoring', payload)
      }
    }

    if (context.state.dialog_detail_visible) {
      await context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * モニタリングの返信を更新
   */
  async updateMonitoringReply(
    context,
    payload: {
      monitoring_id: number
      sns_type: string
      reply_id: number
      message: string
    }
  ) {
    const params: TPutMonitoringReplyParams = {
      sns_type: payload.sns_type,
      message: payload.message
    }

    const response = await API.put<TPutMonitoringReplyResponse>(
      `monitorings/${payload.monitoring_id}/replies/${payload.reply_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('getMonitoring', payload)
    }

    if (context.state.dialog_detail_visible) {
      await context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * モニタリングの返信を削除
   */
  async removeMonitoringReply(
    context,
    payload: { monitoring_id: number; sns_type: string; reply_id: number }
  ) {
    const params: TDeleteMonitoringReplyParams = {
      sns_type: payload.sns_type
    }

    const response = await API.delete<TDeleteMonitoringReplyResponse>(
      `monitorings/${payload.monitoring_id}/replies/${payload.reply_id}`,
      { data: params }
    )

    if (response.data.data) {
      await context.dispatch('getMonitoring', payload)
    }

    if (context.state.dialog_detail_visible) {
      await context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * アカウントをブロック設定する
   */
  async createAccountBlock(context, payload: { monitoring_id: number; sns_type: string }) {
    const params: TPostMonitoringBlockParams = {
      sns_type: payload.sns_type
    }

    const response = await API.post<TPostMonitoringBlockResponse>(
      `monitorings/${payload.monitoring_id}/block`,
      params
    )

    if (response.data.data) {
      const result = await context.dispatch('getMonitoring', payload)

      // 他のモニタリングで同じアカウントのブロック状態を更新する
      if (result.data) {
        const monitoring: TMonitoring = result.data

        const monitorings = context.state.api_monitorings.map(v => {
          if (
            v.own_account.id === monitoring.own_account.id &&
            v.from_account.id === monitoring.from_account.id
          ) {
            return {
              ...v,
              from_account: {
                ...v.from_account,
                is_block: monitoring.from_account.is_block
              }
            }
          } else {
            return v
          }
        })

        context.commit('SET_API_MONITORINGS', monitorings)
      }
    }

    if (context.state.dialog_detail_visible) {
      await context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * アカウントをブロック解除する
   */
  async removeAccountBlock(context, payload: { monitoring_id: number; sns_type: string }) {
    const params: TDeleteMonitoringBlockParams = {
      sns_type: payload.sns_type
    }

    const response = await API.delete<TDeleteMonitoringBlockResponse>(
      `monitorings/${payload.monitoring_id}/block`,
      { data: params }
    )

    if (response.data.data) {
      const result = await context.dispatch('getMonitoring', payload)

      // 他のモニタリングで同じアカウントのブロック状態を更新する
      if (result.data) {
        const monitoring: TMonitoring = result.data

        const monitorings = context.state.api_monitorings.map(v => {
          if (
            v.own_account.id === monitoring.own_account.id &&
            v.from_account.id === monitoring.from_account.id
          ) {
            return {
              ...v,
              from_account: {
                ...v.from_account,
                is_block: monitoring.from_account.is_block
              }
            }
          } else {
            return v
          }
        })

        context.commit('SET_API_MONITORINGS', monitorings)
      }
    }

    if (context.state.dialog_detail_visible) {
      await context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * Twitterメンション・リプライに引用を行う
   */
  async createMonitoringQuoteRetweet(context, payload: { monitoring_id: number; message: string }) {
    const params: TPostMonitoringQuoteRetweetParams = {
      message: payload.message
    }

    const response = await API.post<TPostMonitoringQuoteRetweetResponse>(
      `monitorings/${payload.monitoring_id}/quote_retweets`,
      params
    )

    if (response.data && response.data.data) {
      context.dispatch('getMonitoring', payload)
    }

    if (context.state.dialog_detail_visible) {
      context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * Twitterメンション・リプライに行った引用を削除
   */
  async removeMonitoringQuoteRetweet(
    context,
    payload: { monitoring_id: number; quote_retweet_id: number }
  ) {
    const response = await API.delete<TDeleteMonitoringQuoteRetweetResponse>(
      `monitorings/${payload.monitoring_id}/quote_retweets/${payload.quote_retweet_id}`
    )

    if (response.data && response.data.data) {
      context.dispatch('getMonitoring', payload)
    }

    if (context.state.dialog_detail_visible) {
      context.dispatch('refreshDetailDialog')
    }

    return response.data
  },

  /**
   * 詳細ダイアログの更新
   */
  async refreshDetailDialog(context) {
    const [memos, histories] = await Promise.all([
      context.dispatch('getMonitoringsMemos', context.state.selected_monitoring),
      context.dispatch('getMonitoringsHistories', context.state.selected_monitoring)
    ])

    if (
      (memos.error && memos.error.type === 'NOT_EXISTS') ||
      (histories.error && histories.error.type === 'NOT_EXISTS')
    ) {
      context.commit('SET_IS_DIALOG_EXIST', false)
    }

    if (
      (memos.error && memos.error.type === 'PERMISSION_DENIED') ||
      (histories.error && histories.error.type === 'PERMISSION_DENIED')
    ) {
      context.commit('SET_IS_DIALOG_ROLE', false)
    }
  },

  /**
   * 詳細ダイアログの表示
   */
  async openDetailDialog(
    context,
    payload: { monitoring_id: number; sns_type: string; tab: 'preview' | 'memo' | 'history' }
  ) {
    const local: { destination_ids?: number[] } = storage.get('monitoring')

    // ストレージに保存されていたら使用する
    if (local && local.destination_ids && Array.isArray(local.destination_ids)) {
      context.commit('SET_DESTINATION_IDS', local.destination_ids)
    }

    context.commit('SET_DIALOG_DETAIL_VISIBLE', true)
    context.commit('SET_DIALOG_DETAIL_TAB', payload.tab)

    context.commit('SET_IS_DIALOG', true)
    context.commit('SET_IS_DIALOG_EXIST', true)
    context.commit('SET_IS_DIALOG_ROLE', true)

    context.commit('SET_SELECTED_MONITORING', {
      monitoring_id: payload.monitoring_id,
      sns_type: payload.sns_type
    })

    // ダッシュボード画面の場合
    if (!context.state.api_counts.all) {
      await Promise.all([
        context.dispatch('getMonitoringSettings'),
        context.dispatch('getMonitoringWords'),
        context.dispatch('getUsersTypeRole')
      ])
    }

    await context.dispatch('getMonitoring', context.state.selected_monitoring)

    await context.dispatch('refreshDetailDialog')

    context.commit('SET_IS_DIALOG', false)
  },

  /**
   * 詳細ダイアログの非表示
   */
  async closeDetailDialog(context) {
    context.commit('SET_DIALOG_DETAIL_VISIBLE', false)
    context.commit('SET_SELECTED_MONITORING', null)
    context.commit('SET_IS_DIALOG_EXIST', true)
    context.commit('SET_IS_DIALOG_ROLE', true)
    context.commit('SET_API_MEMOS', [])
    context.commit('SET_API_HISTORIES', [])
  },

  /**
   * 保持するメモの変更
   */
  async changeMemoCache(context, payload: IState['memo_cache']) {
    context.commit('SET_MEMO_CACHE', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
