import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import PostDetailDialog from '@/client/components-old/organisms/PostDetailDialog'
import { IState as IPostCreateState } from '@/client/store/modules/post_create'

const post_create = namespace('post_create')
const post_management = namespace('post_management')

@Component({
  name: 'PostCreateNav',
  components: {
    Icon,
    Flex,
    PostDetailDialog
  }
})
export default class PostCreateNav extends Vue {
  @post_create.State('api_post') api_post: IPostCreateState['api_post']
  @post_create.State('page_type') page_type: IPostCreateState['page_type']
  @post_management.Action('openPostDetailDialog') openPostDetailDialog

  get memo_count() {
    return this.page_type !== 'copy' && this.api_post ? this.api_post.memo_count : 0
  }

  get history_count() {
    return this.page_type !== 'copy' && this.api_post ? this.api_post.history_count : 0
  }

  /**
   * ダイアログを表示
   */
  async openDialog(tab: string) {
    await this.openPostDetailDialog({
      sns_post_id: this.api_post ? this.api_post.id : null,
      mode: 'create',
      tab
    })
  }
}
