import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  TFacebookAccount,
  TGetFacebookAccountRolesResponse,
  TGetFacebookAccountsParams,
  TGetFacebookAccountsResponse,
  TPostFacebookAccountsParams,
  TPostFacebookAccountsResponses,
  TPutFacebookAccountAccessTokenParams,
  TPutFacebookAccountAccessTokenResponses,
  TPutFacebookAccountMonitoringParams,
  TPutFacebookAccountRolesParams,
  TPutFacebookAccountRolesResponse,
  TPutFacebookAccountsParams,
  TPutFacebookAccountsResponses
} from '@/client/utils/api/facebook_accounts'
import {
  TGetInstagramAccountRolesResponse,
  TGetInstagramAccountsParams,
  TGetInstagramAccountsResponse,
  TInstagramAccount,
  TPostInstagramAccountsParams,
  TPostInstagramAccountsResponses,
  TPutInstagramAccountAccessTokenParams,
  TPutInstagramAccountAccessTokenResponses,
  TPutInstagramAccountMonitoringParams,
  TPutInstagramAccountRolesParams,
  TPutInstagramAccountRolesResponse,
  TPutInstagramAccountsParams,
  TPutInstagramAccountsResponses
} from '@/client/utils/api/instagram_accounts'
import {
  GetTikTokAccountRolesResponse,
  GetTikTokAccountsParams,
  GetTikTokAccountsResponse,
  PostTikTokAccountsBody,
  PostTikTokAccountsResponse,
  PutTikTokAccountRolesBody,
  PutTikTokAccountRolesResponse,
  PutTikTokAccountsParams,
  PutTikTokAccountsResponses,
  PutTikTokAccountsTokenBody,
  PutTikTokAccountsTokenResponse,
  TikTokAccount
} from '@/client/utils/api/tiktok_accounts'
import {
  TGetTwitterAccountRolesResponse,
  TGetTwitterAccountsParams,
  TGetTwitterAccountsResponse,
  TPostTwitterAccountsParams,
  TPostTwitterAccountsResponses,
  TPutTwitterAccountAccessTokenParams,
  TPutTwitterAccountAccessTokenResponses,
  TPutTwitterAccountMonitoringParams,
  TPutTwitterAccountRolesParams,
  TPutTwitterAccountRolesResponse,
  TPutTwitterAccountsParams,
  TPutTwitterAccountsResponses,
  TTwitterAccount
} from '@/client/utils/api/twitter_accounts'
import { RoleMonitoringName, RolePostName } from '@/common/types'

type SnsType = 'facebook' | 'twitter' | 'instagram' | 'tiktok'

export interface IState {
  facebook: TFacebookAccount[]
  twitter: TTwitterAccount[]
  instagram: TInstagramAccount[]
  tiktok: TikTokAccount[]
}

const state: IState = {
  facebook: [],
  twitter: [],
  instagram: [],
  tiktok: []
}

export interface IGetter {
  facebook_analytics: {
    id: string
    name: string
    username: string
    img: string
    is_empty: boolean
    expired: boolean
  }[]
  twitter_analytics: {
    id: string
    name: string
    username: string
    img: string
    is_empty: boolean
    expired: boolean
  }[]
  instagram_analytics: {
    id: string
    name: string
    username: string
    img: string
    is_empty: boolean
    expired: boolean
  }[]
  tiktok_analytics: {
    id: string
    name: string
    username: string
    img: string
    is_empty: boolean
    expired: boolean
  }[]
  post: {
    id: string
    name: string
    username: string | null
    img: string
    expired: boolean
    is_publish_permission?: boolean
    sns: SnsType
    role: RolePostName
  }[]
  monitoring: {
    id: string
    name: string
    username: string | null
    is_use_monitoring: boolean
    is_enterprise_verified?: boolean
    img: string
    expired: boolean
    sns: SnsType
    role: RoleMonitoringName
  }[]
  is_sns_accounts: boolean
  account_count: number
}

const getters: GetterTree<IState, IRootState> = {
  facebook_analytics(state): IGetter['facebook_analytics'] {
    const accounts = state.facebook.filter(
      v => v.is_available && v.role_analytics !== 'no_authority'
    )

    return accounts.map(v => ({
      id: v.id,
      name: v.name,
      username: v.username,
      img: v.image_url,
      is_empty: v.is_empty,
      expired: v.token_expired
    }))
  },
  twitter_analytics(state): IGetter['twitter_analytics'] {
    const accounts = state.twitter.filter(
      v => v.is_available && v.role_analytics !== 'no_authority'
    )

    return accounts.map(v => ({
      id: v.id,
      name: v.name,
      username: v.username,
      img: v.image_url,
      is_empty: v.is_empty,
      expired: v.token_expired
    }))
  },
  instagram_analytics(state): IGetter['instagram_analytics'] {
    const accounts = state.instagram.filter(
      v => v.is_available && v.role_analytics !== 'no_authority'
    )

    return accounts.map(v => ({
      id: v.id,
      name: v.name,
      username: v.username,
      img: v.image_url,
      is_empty: v.is_empty,
      expired: v.token_expired
    }))
  },
  tiktok_analytics(state): IGetter['tiktok_analytics'] {
    const accounts = state.tiktok.filter(v => v.is_available && v.role_analytics !== 'no_authority')

    return accounts.map(v => ({
      id: v.id,
      name: v.name,
      username: v.username,
      img: v.image_url,
      is_empty: v.is_empty,
      expired: v.token_expired
    }))
  },
  post(state): IGetter['post'] {
    const getAccount = (
      account: TFacebookAccount | TTwitterAccount | TInstagramAccount | TikTokAccount,
      sns: SnsType
    ) => {
      return {
        id: account.id,
        name: account.name,
        username: account.username,
        img: account.image_url,
        expired: account.token_expired,
        is_publish_permission:
          'is_publish_permission' in account ? account.is_publish_permission : true,
        sns,
        role: account.role_post
      }
    }

    const facebook = state.facebook
      .filter(v => v.role_post !== 'no_authority')
      .map(account => getAccount(account, 'facebook'))

    const twitter = state.twitter
      .filter(v => v.role_post !== 'no_authority')
      .map(account => getAccount(account, 'twitter'))

    const instagram = state.instagram
      .filter(v => v.role_post !== 'no_authority')
      .map(account => getAccount(account, 'instagram'))

    const tiktok = state.tiktok
      .filter(v => v.role_post !== 'no_authority')
      .map(account => getAccount(account, 'tiktok'))

    return [...facebook, ...twitter, ...instagram, ...tiktok]
  },
  monitoring(): IGetter['monitoring'] {
    const getAccount = (
      account: TFacebookAccount | TTwitterAccount | TInstagramAccount,
      sns: SnsType
    ) => {
      return {
        id: account.id,
        name: account.name,
        username: account.username,
        is_use_monitoring: account.is_use_monitoring,
        is_enterprise_verified:
          'is_enterprise_verified' in account ? account?.is_enterprise_verified : undefined,
        img: account.image_url,
        expired: account.token_expired,
        sns,
        role: account.role_monitoring
      }
    }

    const facebook = state.facebook
      .filter(v => v.role_monitoring !== 'no_authority')
      .map(account => getAccount(account, 'facebook'))

    const twitter = state.twitter
      .filter(v => v.role_monitoring !== 'no_authority')
      .map(account => getAccount(account, 'twitter'))

    const instagram = state.instagram
      .filter(v => v.role_monitoring !== 'no_authority')
      .map(account => getAccount(account, 'instagram'))

    return [...facebook, ...twitter, ...instagram]
  },
  is_sns_accounts(state): IGetter['is_sns_accounts'] {
    return (
      state.facebook.length > 0 ||
      state.twitter.length > 0 ||
      state.instagram.length > 0 ||
      state.tiktok.length > 0
    )
  },
  account_count(state): IGetter['account_count'] {
    return (
      state.facebook.length + state.twitter.length + state.instagram.length + state.tiktok.length
    )
  }
}

const mutations: MutationTree<IState> = {
  SET_FACEBOOK_ACCOUNTS(state, payload: TFacebookAccount[]) {
    state.facebook = payload
  },
  SET_TWITTER_ACCOUNTS(state, payload: TTwitterAccount[]) {
    state.twitter = payload
  },
  SET_INSTAGRAM_ACCOUNTS(state, payload: TInstagramAccount[]) {
    state.instagram = payload
  },
  SET_TIKTOK_ACCOUNTS(state, payload: TikTokAccount[]) {
    state.tiktok = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * Facebookアカウントの一覧を取得
   */
  async fetchFacebookAccounts(context) {
    const params: TGetFacebookAccountsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<TGetFacebookAccountsResponse>('facebook_accounts', { params })

    if (response.data.data) {
      context.commit('SET_FACEBOOK_ACCOUNTS', response.data.data)
    }
  },

  /**
   * Facebookアカウントを作成
   */
  async createFacebookAccount(
    context,
    payload: {
      account_id: string
      name: string
      username: string
      image_url: string
      user_access_token: string
      fb_user_id: string
      fb_user_name: string
    }
  ) {
    const params: TPostFacebookAccountsParams = {
      project_id: context.rootState.project.id,
      ...payload
    }

    const response = await API.post<TPostFacebookAccountsResponses>('facebook_accounts', params)

    if (response.data.data) {
      await context.dispatch('fetchFacebookAccounts')
    }

    return response.data
  },

  /**
   * Facebookモニタリングアカウントを作成
   */
  async createFacebookAccountMonitoring(
    context,
    payload: {
      account_id: string
    }
  ) {
    const params = {
      project_id: context.rootState.project.id
    }

    const response = await API.post<TPostFacebookAccountsResponses>(
      `facebook_accounts/${payload.account_id}/subscribe`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchFacebookAccounts')
    }

    return response.data
  },

  /**
   * Facebookアカウントのアクセストーケンを更新
   */
  async updateFacebookAccountAccessToken(
    context,
    payload: {
      account_id: string
      user_access_token: string
      fb_user_id: string
      fb_user_name: string
    }
  ) {
    const params: TPutFacebookAccountAccessTokenParams = {
      user_access_token: payload.user_access_token,
      fb_user_id: payload.fb_user_id,
      fb_user_name: payload.fb_user_name
    }

    const response = await API.put<TPutFacebookAccountAccessTokenResponses>(
      `facebook_accounts/${payload.account_id}/access_token`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchFacebookAccounts')
    }

    return response.data
  },

  /**
   * Facebookアカウントを登録解除
   */
  async removeFacebookAccount(context, payload: { account_id: string }) {
    const params: TPutFacebookAccountsParams = {
      canceled: true
    }

    const response = await API.put<TPutFacebookAccountsResponses>(
      `facebook_accounts/${payload.account_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchFacebookAccounts')
    }

    return response.data
  },

  /**
   * Facebookアカウントの登録解除取り消し
   */
  async cancelFacebookUnregister(context, payload: { account_id: string }) {
    const params: TPutFacebookAccountsParams = {
      canceled: false
    }

    const response = await API.put<TPutFacebookAccountsResponses>(
      `facebook_accounts/${payload.account_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchFacebookAccounts')
    }

    return response.data
  },

  /**
   * Facebookアカウントを登録解除
   */
  async unsubscribeFacebookAccount(context, payload: { account_id: string }) {
    const params: TPutFacebookAccountMonitoringParams = {
      project_id: context.rootState.project.id,
      is_canceled: true
    }

    const response = await API.put<TPutFacebookAccountsResponses>(
      `facebook_accounts/${payload.account_id}/subscribe`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchFacebookAccounts')
    }

    return response.data
  },

  /**
   * Facebookアカウントの登録解除取り消し
   */
  async cancelUnsubscribeFacebookAccount(context, payload: { account_id: string }) {
    const params: TPutFacebookAccountMonitoringParams = {
      project_id: context.rootState.project.id,
      is_canceled: false
    }

    const response = await API.put<TPutFacebookAccountsResponses>(
      `facebook_accounts/${payload.account_id}/subscribe`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchFacebookAccounts')
    }

    return response.data
  },

  /**
   * Twitterアカウントの一覧を取得
   */
  async fetchTwitterAccounts(context) {
    const params: TGetTwitterAccountsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<TGetTwitterAccountsResponse>('twitter_accounts', { params })

    if (response.data.data) {
      context.commit('SET_TWITTER_ACCOUNTS', response.data.data)
    }
  },

  /**
   * Twitterアカウントを作成
   */
  async createTwitterAccount(
    context,
    payload: {
      account_id: string
      name: string
      username: string
      image_url: string
      access_token: string
      access_token_secret: string
    }
  ) {
    const params: TPostTwitterAccountsParams = {
      project_id: context.rootState.project.id,
      ...payload
    }

    const response = await API.post<TPostTwitterAccountsResponses>('twitter_accounts', params)

    if (response.data.data) {
      await context.dispatch('fetchTwitterAccounts')
    }

    return response.data
  },

  /**
   * Twitterモニタリングアカウントを作成
   */
  async createTwitterAccountMonitoring(
    context,
    payload: {
      account_id: string
    }
  ) {
    const params = {
      project_id: context.rootState.project.id
    }

    const response = await API.post<TPostTwitterAccountsResponses>(
      `twitter_accounts/${payload.account_id}/subscribe`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchTwitterAccounts')
    }

    return response.data
  },

  /**
   * Twitterアカウントのアクセストーケンを更新
   */
  async updateTwitterAccountAccessToken(
    context,
    payload: {
      account_id: string
      access_token: string
      access_token_secret: string
    }
  ) {
    const params: TPutTwitterAccountAccessTokenParams = {
      access_token: payload.access_token,
      access_token_secret: payload.access_token_secret
    }

    const response = await API.put<TPutTwitterAccountAccessTokenResponses>(
      `twitter_accounts/${payload.account_id}/access_token`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchTwitterAccounts')
    }

    return response.data
  },

  /**
   * Twitterアカウントを登録解除
   */
  async removeTwitterAccount(context, payload: { account_id: string }) {
    const params: TPutTwitterAccountsParams = {
      canceled: true
    }

    const response = await API.put<TPutTwitterAccountsResponses>(
      `twitter_accounts/${payload.account_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchTwitterAccounts')
    }

    return response.data
  },

  /**
   * Xアカウントの登録解除取り消し
   */
  async cancelTwitterUnregister(context, payload: { account_id: string }) {
    const params: TPutTwitterAccountsParams = {
      canceled: false
    }

    const response = await API.put<TPutTwitterAccountsResponses>(
      `twitter_accounts/${payload.account_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchTwitterAccounts')
    }

    return response.data
  },

  /**
   * Twitterアカウントを登録解除
   */
  async removeTikTokAccount(context, payload: { account_id: string }) {
    const params: PutTikTokAccountsParams = {
      is_canceled: true
    }

    const response = await API.put<PutTikTokAccountsResponses>(
      `tiktok_accounts/${payload.account_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchTikTokAccounts')
    }

    return response.data
  },

  /**
   * TikTokアカウントの登録解除取り消し
   */
  async cancelTikTokUnregister(context, payload: { account_id: string }) {
    const params: PutTikTokAccountsParams = {
      is_canceled: false
    }

    const response = await API.put<PutTikTokAccountsResponses>(
      `tiktok_accounts/${payload.account_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchTikTokAccounts')
    }

    return response.data
  },

  /**
   * Twitterアカウントを登録解除
   */
  async unsubscribeTwitterAccount(context, payload: { account_id: string }) {
    const params: TPutTwitterAccountMonitoringParams = {
      project_id: context.rootState.project.id,
      is_canceled: true
    }

    const response = await API.put<TPutTwitterAccountsResponses>(
      `twitter_accounts/${payload.account_id}/subscribe`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchTwitterAccounts')
    }

    return response.data
  },

  /**
   * Xアカウントの登録解除取り消し
   */
  async cancelUnsubscribeTwitterAccount(context, payload: { account_id: string }) {
    const params: TPutTwitterAccountMonitoringParams = {
      project_id: context.rootState.project.id,
      is_canceled: false
    }

    const response = await API.put<TPutTwitterAccountsResponses>(
      `twitter_accounts/${payload.account_id}/subscribe`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchTwitterAccounts')
    }

    return response.data
  },

  /**
   * NTT AAA認証済みフラグを更新
   */
  async updateTwitterEnterpriseVerified(context, payload: { account_id: string }) {
    const params: TPutTwitterAccountMonitoringParams = {
      project_id: context.rootState.project.id,
      is_enterprise_verified: true
    }

    const response = await API.put<TPutTwitterAccountsResponses>(
      `twitter_accounts/${payload.account_id}/subscribe`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchTwitterAccounts')
    }

    return response.data
  },

  /*
   * Instagramアカウントの一覧を取得
   */
  async fetchInstagramAccounts(context) {
    const params: TGetInstagramAccountsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<TGetInstagramAccountsResponse>('instagram_accounts', { params })

    if (response.data.data) {
      context.commit('SET_INSTAGRAM_ACCOUNTS', response.data.data)
    }
  },

  /**
   * Instagramアカウントを作成
   */
  async createInstagramAccount(
    context,
    payload: {
      account_id: string
      business_account_id: string
      name: string
      username: string
      image_url: string
      access_token: string
      fb_user_id: string
      fb_user_name: string
    }
  ) {
    const params: TPostInstagramAccountsParams = {
      project_id: context.rootState.project.id,
      ...payload
    }

    const response = await API.post<TPostInstagramAccountsResponses>('instagram_accounts', params)

    if (response.data.data) {
      await context.dispatch('fetchFacebookAccounts')
      await context.dispatch('fetchInstagramAccounts')
    }

    return response.data
  },

  /**
   * Instagramモニタリングアカウントを作成
   */
  async createInstagramAccountMonitoring(
    context,
    payload: {
      account_id: string
    }
  ) {
    const params = {
      project_id: context.rootState.project.id
    }

    const response = await API.post<TPostInstagramAccountsResponses>(
      `instagram_accounts/${payload.account_id}/subscribe`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchInstagramAccounts')
    }

    return response.data
  },

  /**
   * Instagramアカウントのアクセストーケンを更新
   */
  async updateInstagramAccountAccessToken(
    context,
    payload: {
      account_id: string
      business_account_id: string
      access_token: string
      fb_user_id: string
      fb_user_name: string
    }
  ) {
    const params: TPutInstagramAccountAccessTokenParams = {
      business_account_id: payload.business_account_id,
      access_token: payload.access_token,
      fb_user_id: payload.fb_user_id,
      fb_user_name: payload.fb_user_name
    }

    const response = await API.put<TPutInstagramAccountAccessTokenResponses>(
      `instagram_accounts/${payload.account_id}/access_token`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchFacebookAccounts')
      await context.dispatch('fetchInstagramAccounts')
    }

    return response.data
  },

  /**
   * Instagramアカウントを登録解除
   */
  async removeInstagramAccount(context, payload: { account_id: string }) {
    const params: TPutInstagramAccountsParams = {
      canceled: true
    }

    const response = await API.put<TPutInstagramAccountsResponses>(
      `instagram_accounts/${payload.account_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchInstagramAccounts')
    }

    return response.data
  },

  /**
   * Instagramアカウントの登録解除取り消し
   */
  async cancelInstagramUnregister(context, payload: { account_id: string }) {
    const params: TPutInstagramAccountsParams = {
      canceled: false
    }

    const response = await API.put<TPutInstagramAccountsResponses>(
      `instagram_accounts/${payload.account_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchInstagramAccounts')
    }

    return response.data
  },

  /**
   * Instagramアカウントを登録解除
   */
  async unsubscribeInstagramAccount(context, payload: { account_id: string }) {
    const params: TPutInstagramAccountMonitoringParams = {
      project_id: context.rootState.project.id,
      is_canceled: true
    }

    const response = await API.put<TPutInstagramAccountsResponses>(
      `instagram_accounts/${payload.account_id}/subscribe`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchInstagramAccounts')
    }

    return response.data
  },

  /**
   * Instagramアカウントの登録解除取り消し
   */
  async cancelUnsubscribeInstagramAccount(context, payload: { account_id: string }) {
    const params: TPutInstagramAccountMonitoringParams = {
      project_id: context.rootState.project.id,
      is_canceled: false
    }

    const response = await API.put<TPutInstagramAccountsResponses>(
      `instagram_accounts/${payload.account_id}/subscribe`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchInstagramAccounts')
    }

    return response.data
  },

  /**
   * TikTokアカウントを作成する
   */
  async createTikTokAccount(context, payload: { access_token: string; refresh_token: string }) {
    const body: PostTikTokAccountsBody = {
      project_id: context.rootState.project.id,
      access_token: payload.access_token,
      refresh_token: payload.refresh_token
    }

    const response = await API.post<PostTikTokAccountsResponse>('tiktok_accounts', body)

    if (response.data.data) {
      await context.dispatch('fetchTikTokAccounts')
    }

    return response.data
  },

  /**
   * TikTokアカウントの一覧を取得
   */
  async fetchTikTokAccounts(context) {
    const params: GetTikTokAccountsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<GetTikTokAccountsResponse>('tiktok_accounts', { params })

    if (response.data.data) {
      context.commit('SET_TIKTOK_ACCOUNTS', response.data.data)
    }
  },

  async updateTikTokAccountAccessToken(
    context,
    payload: {
      account_id: string
      access_token: string
      refresh_token: string
    }
  ) {
    const params: PutTikTokAccountsTokenBody = {
      access_token: payload.access_token,
      refresh_token: payload.refresh_token
    }

    const response = await API.put<PutTikTokAccountsTokenResponse>(
      `tiktok_accounts/${payload.account_id}/access_token`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchTikTokAccounts')
    }

    return response.data
  },

  /**
   * SNSアカウントの一覧を取得
   */
  async fetchSnsAccounts(context) {
    await Promise.all([
      context.dispatch('fetchFacebookAccounts'),
      context.dispatch('fetchTwitterAccounts'),
      context.dispatch('fetchInstagramAccounts'),
      context.dispatch('fetchTikTokAccounts')
    ])
  },

  /**
   * SNSアカウントの登録解除
   */
  async removeSnsAccount(
    context,
    payload: {
      account_id: string
      sns_type: SnsType
    }
  ) {
    const params = { account_id: payload.account_id }

    switch (payload.sns_type) {
      case 'facebook':
        return context.dispatch('removeFacebookAccount', params)
      case 'twitter':
        return context.dispatch('removeTwitterAccount', params)
      case 'instagram':
        return context.dispatch('removeInstagramAccount', params)
      case 'tiktok':
        return context.dispatch('removeTikTokAccount', params)
      default:
        return
    }
  },

  /**
   * SNSアカウントの登録解除取り消し
   */
  cancelAccountUnregister(
    context,
    payload: {
      account_id: string
      sns_type: SnsType
    }
  ) {
    const params = { account_id: payload.account_id }

    switch (payload.sns_type) {
      case 'facebook':
        return context.dispatch('cancelFacebookUnregister', params)
      case 'twitter':
        return context.dispatch('cancelTwitterUnregister', params)
      case 'instagram':
        return context.dispatch('cancelInstagramUnregister', params)
      case 'tiktok':
        return context.dispatch('cancelTikTokUnregister', params)
      default:
        return
    }
  },

  /**
   * SNSアカウントの登録解除
   */
  async unsubscribeSnsAccount(
    context,
    payload: {
      account_id: string
      sns_type: SnsType
    }
  ) {
    const params = { account_id: payload.account_id }

    switch (payload.sns_type) {
      case 'facebook':
        return context.dispatch('unsubscribeFacebookAccount', params)
      case 'instagram':
        return context.dispatch('unsubscribeInstagramAccount', params)
      case 'twitter':
        return context.dispatch('unsubscribeTwitterAccount', params)
      default:
        return
    }
  },

  /**
   * SNSアカウントの登録解除取り消し
   */
  cancelAccountUnsubscribe(
    context,
    payload: {
      account_id: string
      sns_type: SnsType
    }
  ) {
    const params = { account_id: payload.account_id }

    switch (payload.sns_type) {
      case 'facebook':
        return context.dispatch('cancelUnsubscribeFacebookAccount', params)
      case 'instagram':
        return context.dispatch('cancelUnsubscribeInstagramAccount', params)
      case 'twitter':
        return context.dispatch('cancelUnsubscribeTwitterAccount', params)
      default:
        return
    }
  },

  /**
   * SNSアカウント権限一覧を取得
   */
  async fetchSnsAccountsRoles(
    context,
    payload: {
      account_id: string
      sns_type: SnsType
    }
  ) {
    const response = await API.get<
      | TGetFacebookAccountRolesResponse
      | TGetTwitterAccountRolesResponse
      | TGetInstagramAccountRolesResponse
      | GetTikTokAccountRolesResponse
    >(`${payload.sns_type}_accounts/${payload.account_id}/roles`)

    return response.data
  },

  /**
   * SNSアカウント権限を更新
   */
  async updateSnsAccountRole(
    context,
    payload: {
      account_id: string
      sns_type: SnsType
      user_id: number
      role_post: string
      role_analytics: string
      role_monitoring: string
    }
  ) {
    const params:
      | TPutFacebookAccountRolesParams
      | TPutTwitterAccountRolesParams
      | TPutInstagramAccountRolesParams
      | PutTikTokAccountRolesBody = {
      user_id: payload.user_id,
      role_post: payload.role_post,
      role_analytics: payload.role_analytics,
      role_monitoring: payload.role_monitoring
    }

    const response = await API.put<
      | TPutFacebookAccountRolesResponse
      | TPutTwitterAccountRolesResponse
      | TPutInstagramAccountRolesResponse
      | PutTikTokAccountRolesResponse
    >(`${payload.sns_type}_accounts/${payload.account_id}/roles`, params)

    if (response.data.data) {
      switch (payload.sns_type) {
        case 'facebook':
          await context.dispatch('fetchFacebookAccounts')
          break
        case 'twitter':
          await context.dispatch('fetchTwitterAccounts')
          break
        case 'instagram':
          await context.dispatch('fetchInstagramAccounts')
          break
        case 'tiktok':
          await context.dispatch('fetchTikTokAccounts')
          break
      }
    }

    return response.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
