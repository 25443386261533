import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import DateTime from '@/client/components-old/atoms/DateTime'
import Dialog from '@/client/components-old/atoms/Dialog'
import Flex from '@/client/components-old/atoms/Flex'
import Message from '@/client/components-old/atoms/Message'
import Tabs from '@/client/components-old/atoms/Tabs'
import Account from '@/client/components-old/molecules/Account'
import MonitoringContent from '@/client/components-old/molecules/MonitoringContent'
import { IState as IMonitoringState } from '@/client/store/modules/monitoring'
import API from '@/client/utils/api'

type TSns = 'facebook' | 'twitter' | 'instagram'
type TTab = 'mention' | 'comment' | 'message' | 'reply'
type TOptions = {
  monitoring_id: number
  sns_type: TSns
  type: TTab
}

const monitoring = namespace('monitoring')

@Component({
  name: 'MonitoringConversationDialog',
  components: {
    DateTime,
    Dialog,
    Flex,
    Tabs,
    Account,
    Message,
    MonitoringContent
  }
})
export default class MonitoringConversationDialog extends Vue {
  @monitoring.State('api_words') words: IMonitoringState['api_words']

  $refs: {
    scroll: HTMLDivElement
  }

  visible = false

  monitoring_id: number

  sns_type: TSns = 'facebook'

  tab: TTab = 'comment'

  is_loading_more = false

  comments = []

  comment_count = 0

  mentions = []

  mention_count = 0

  replies = []

  reply_count = 0

  messages = []

  message_count = 0

  get is_tab_comment() {
    return this.tab === 'comment'
  }

  get is_tab_message() {
    return this.tab === 'message'
  }

  get is_tab_mention() {
    return this.tab === 'mention'
  }

  get display_data() {
    switch (this.tab) {
      case 'mention':
        return this.mentions
      case 'comment':
        return this.comments
      case 'reply':
        return this.replies
      case 'message':
        return this.messages
      default:
        return []
    }
  }

  get count() {
    switch (this.tab) {
      case 'mention':
        return this.mention_count
      case 'comment':
        return this.comment_count
      case 'reply':
        return this.reply_count
      case 'message':
        return this.message_count
      default:
        return 0
    }
  }

  get tab_options() {
    const comment = this.$options.filters.translate('コメント') + ` (${this.comment_count})`
    const mention = this.$options.filters.translate('メンション') + ` (${this.mention_count})`
    const message = this.$options.filters.translate('メッセージ') + ` (${this.message_count})`
    const reply = this.$options.filters.translate('リプライ') + ` (${this.reply_count})`

    switch (this.sns_type) {
      case 'facebook':
        return [
          { value: 'comment', text: comment },
          { value: 'message', text: message }
        ]

      case 'twitter':
        return [
          { value: 'mention', text: mention },
          { value: 'reply', text: reply },
          { value: 'message', text: message }
        ]

      case 'instagram':
        return [{ value: 'comment', text: comment }]

      default:
        return []
    }
  }

  /**
   * ダイアログの表示
   */
  async open(options: TOptions): Promise<void> {
    this.reset()
    this.visible = true
    this.sns_type = options.sns_type
    this.monitoring_id = options.monitoring_id
    this.tab = options.type || 'comment'

    await this.getConversationData(options)
  }

  /**
   * APIからデータを取得する
   */
  async getConversationData(options: TOptions): Promise<void> {
    const endpoint = `monitoring_conversations/${this.monitoring_id}`

    const params = {
      sns_type: options.sns_type,
      conversation_type: '',
      offset: 0
    }

    switch (options.sns_type) {
      case 'facebook': {
        const result = await Promise.all([
          API.get(endpoint, { params: { ...params, conversation_type: 'comment' } }),
          API.get(endpoint, { params: { ...params, conversation_type: 'message' } })
        ])

        const facebook_comment = result[0].data

        if (facebook_comment && facebook_comment.data) {
          this.comment_count = facebook_comment.data.count
          this.comments = facebook_comment.data.conversations
        }

        const facebook_message = result[1].data

        if (facebook_message && facebook_message.data) {
          this.message_count = facebook_message.data.count
          this.messages = facebook_message.data.conversations
        }

        break
      }

      case 'twitter': {
        const result = await Promise.all([
          API.get(endpoint, {
            params: { ...params, conversation_type: 'mention' }
          }),
          API.get(endpoint, {
            params: { ...params, conversation_type: 'reply' }
          }),
          API.get(endpoint, {
            params: { ...params, conversation_type: 'message' }
          })
        ])

        const twitter_mention = result[0].data

        if (twitter_mention && twitter_mention.data) {
          this.mention_count = twitter_mention.data.count
          this.mentions = twitter_mention.data.conversations
        }

        const twitter_reply = result[1].data

        if (twitter_reply && twitter_reply.data) {
          this.reply_count = twitter_reply.data.count
          this.replies = twitter_reply.data.conversations
        }

        const twitter_message = result[2].data

        if (twitter_message && twitter_message.data) {
          this.message_count = twitter_message.data.count
          this.messages = twitter_message.data.conversations
        }

        break
      }

      case 'instagram': {
        const result = await API.get(endpoint, {
          params: { ...params, conversation_type: 'comment' }
        })

        const instagram_comment = result.data

        if (instagram_comment && instagram_comment.data) {
          this.comment_count = instagram_comment.data.count
          this.comments = instagram_comment.data.conversations
        }

        break
      }

      default:
        break
    }
  }

  /**
   * もっと読み込む
   */
  async loadMore(event: any): Promise<void> {
    if (this.is_loading_more || this.display_data.length >= this.count) {
      return
    }

    if (event.target.scrollTop + event.target.clientHeight + 50 < event.target.scrollHeight) {
      return
    }

    const params = {
      sns_type: this.sns_type,
      conversation_type: this.tab,
      offset: this.display_data.length
    }

    this.is_loading_more = true

    const result = await API.get(`monitoring_conversations/${this.monitoring_id}`, { params })

    this.is_loading_more = false

    if (this.tab === 'comment' && result.data && result.data.data) {
      this.comments = [...this.comments, ...result.data.data.conversations]
      this.comment_count = result.data.data.count
    }

    if (this.tab === 'message' && result.data && result.data.data) {
      this.messages = [...this.messages, ...result.data.data.conversations]
      this.message_count = result.data.data.count
    }

    if (this.tab === 'reply' && result.data && result.data.data) {
      this.replies = [...this.replies, ...result.data.data.conversations]
      this.reply_count = result.data.data.count
    }

    if (this.tab === 'mention' && result.data && result.data.data) {
      this.mentions = [...this.mentions, ...result.data.data.conversations]
      this.mention_count = result.data.data.count
    }
  }

  /**
   * 初期化
   */
  reset(): void {
    this.is_loading_more = false
    this.comments = []
    this.comment_count = 0
    this.mentions = []
    this.mention_count = 0
    this.messages = []
    this.message_count = 0
    this.replies = []
    this.reply_count = 0
  }

  /**
   * ダイアログの非表示
   */
  hide(): void {
    this.visible = false
  }

  /**
   * スクロール位置の初期化
   */
  scrollTop(): void {
    if (this.$refs.scroll) {
      this.$refs.scroll.scrollTop = 0
    }
  }
}
