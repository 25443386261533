const UNITS = [
  { symbol: 'P', digit: 15 },
  { symbol: 'T', digit: 12 },
  { symbol: 'G', digit: 9 },
  { symbol: 'M', digit: 6 },
  { symbol: 'k', digit: 3 }
]

/**
 * 引数をヒートマップに表示する形式に変換する
 */
export function parseHeatmapValue(value: number): string {
  const digit = (Math.log(value) * Math.LOG10E + 1) | 0

  const unit = UNITS.find(v => digit > v.digit)

  if (!unit) return value.toString()

  return (value / 10 ** unit.digit).toFixed(1) + unit.symbol
}
