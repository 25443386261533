import moment from 'moment'

/**
 * 月の時間別配列を作成
 */
export function createHourlyByMonth() {
  const start = moment().startOf('month')
  const end = moment().endOf('month')

  const hourly: Date[] = []

  while (end.isAfter(start)) {
    hourly.push(start.toDate())
    start.add(1, 'hour')
  }

  return hourly
}
