import { Component, Prop, Vue } from 'vue-property-decorator'

import event from '@/client/utils/event'

type TChartMapOptions = {
  name: string
  type: 'custom/world' | 'countries/jp/jp-all'
  lang: 'ja' | 'en'
  metric_type: 'positive' | 'negative'
  data: [string, number][]
}

@Component({
  name: 'ChartMap'
})
export default class ChartMap extends Vue {
  @Prop({ type: Object, required: true })
  options: TChartMapOptions

  is_show = false

  async mounted() {
    this.is_show = false
    await this.$nextTick()
    await event.delay(0)
    this.is_show = true
  }

  get chart_data() {
    const type = this.options.type
    const { features } = Highcharts.maps[type]
    const properties = features.map(f => f.properties)

    switch (type) {
      case 'custom/world':
        return this.options.data.map(d => {
          const p = properties.find(p => p['hc-a2'] === d[0])
          return p ? [p['hc-key'], d[1]] : d
        })
      case 'countries/jp/jp-all':
        return this.options.data.map(d => {
          const p = properties.find(p => p['name'] === d[0])
          return p ? [p['hc-key'], d[1]] : d
        })
      default:
        return this.options.data
    }
  }

  get chart_options() {
    const name = this.options.name
    const type = this.options.type
    const lang = this.options.lang
    const color_axis = {
      min: 0,
      minColor: '#e8f2f9',
      maxColor: '#1c84c6'
    }

    if (this.options.metric_type === 'negative') {
      color_axis.minColor = '#FBEBEC'
      color_axis.maxColor = '#ed5565'
    }

    return {
      chart: { map: type, height: 380 },
      title: { text: null },
      subtitle: { text: null },
      credits: { enabled: false },
      exporting: { enabled: false },
      colorAxis: color_axis,
      mapNavigation: {
        enabled: true,
        buttonOptions: { verticalAlign: 'bottom' }
      },
      tooltip: {
        headerFormat:
          '<span style="color:{point.color}">●</span><span style="font-size: 10px">{series.name}</span><br/>',
        pointFormatter() {
          const point_name = this.properties['name']
          const point_name_jp = this.properties['jp-name']
          const value = this.value.toLocaleString()

          if (lang === 'ja') {
            return `${point_name_jp}: <b>${value}</b><br/>`
          } else {
            return `${point_name}: <b>${value}</b><br/>`
          }
        }
      },
      series: [
        {
          name: this.$options.filters.translate(name, { lng: lang }),
          data: this.chart_data,
          states: { hover: { color: '#2ec881' } },
          dataLabels: {
            enabled: true,
            formatter: function () {
              const point_name = this.point['name']
              const point_name_jp = this.point.properties['jp-name']

              if (lang === 'ja') {
                return point_name_jp
              } else {
                return point_name
              }
            }
          }
        }
      ]
    }
  }
}
