import { GridItem, GridLayout } from 'vue-grid-layout'
import { Component, Vue } from 'vue-property-decorator'

import * as constants from '@/client/components-old/_constants/facebook_analytics'
import Box from '@/client/components-old/atoms/Box'
import Legend from '@/client/components-old/atoms/Legend'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import FacebookAnalyticsChartHorizontal from '@/client/components-old/organisms/FacebookAnalyticsChartHorizontal'
import FacebookAnalyticsChartMap from '@/client/components-old/organisms/FacebookAnalyticsChartMap'
import FacebookAnalyticsChartVertical from '@/client/components-old/organisms/FacebookAnalyticsChartVertical'
import FacebookAnalyticsTotal from '@/client/components-old/organisms/FacebookAnalyticsTotal'

@Component({
  name: 'FacebookAnalyticsFan',
  components: {
    GridLayout,
    GridItem,
    FacebookAnalyticsChartHorizontal,
    FacebookAnalyticsChartMap,
    FacebookAnalyticsChartVertical,
    FacebookAnalyticsTotal,
    Legend,
    Box,
    Message,
    Scroll
  }
})
export default class FacebookAnalyticsFan extends Vue {
  get legend_data() {
    return constants.DATA_TYPES.map(type => ({ text: type.text, color: type.color }))
  }

  get grid_layout() {
    return [
      {
        x: 0,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:ファン数(累計)',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_fans',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 3,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:ファン数(増減)',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_fan_up_down',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 6,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:主な性別・年代',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_fans_gender_age',
          unit: 'major',
          is_view_extra: true
        }
      },
      {
        x: 9,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:主な国',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_fans_country',
          unit: 'major',
          is_view_extra: true
        }
      },
      {
        x: 0,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:主な都道府県',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_fans_city',
          unit: 'major',
          is_view_extra: true
        }
      },
      {
        x: 3,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:アクティブな時間帯',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_fans_online',
          unit: 'past_week',
          is_view_extra: true
        }
      },
      {
        x: 6,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:アクティブな曜日',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_fans_online_per_day',
          unit: 'past_week',
          is_view_extra: true
        }
      },
      {
        x: 0,
        y: 4,
        w: 12,
        h: 4,
        i: '垂直チャート:ファン数(累計)',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_fans',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 8,
        w: 12,
        h: 4,
        i: '垂直チャート:ファン数(増減)',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_fan_up_down',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 12,
        w: 6,
        h: 5,
        i: '水平チャート:ページへのいいね！の発生箇所',
        name: 'FacebookAnalyticsChartHorizontal',
        options: { data_type: 'daily_data', metric: 'page_fans_by_like_source_unique' }
      },
      {
        x: 0,
        y: 17,
        w: 6,
        h: 5,
        i: '水平チャート:性別・年代',
        name: 'FacebookAnalyticsChartHorizontal',
        options: { data_type: 'daily_data', metric: 'page_fans_gender_age' }
      },
      {
        x: 0,
        y: 22,
        w: 6,
        h: 6,
        i: '水平チャート:ファン数(場所別ベスト10)',
        name: 'FacebookAnalyticsChartHorizontal',
        options: { data_type: 'daily_data', metric: 'page_fans_city' }
      },
      {
        x: 6,
        y: 12,
        w: 6,
        h: 4,
        i: '垂直チャート:オンラインの時間帯',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_fans_online',
          interval: 'hour',
          unit: 'past_week'
        }
      },
      {
        x: 6,
        y: 16,
        w: 6,
        h: 4,
        i: '垂直チャート:オンラインの曜日',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_fans_online_per_day',
          interval: 'weekday',
          unit: 'past_week'
        }
      },
      {
        x: 6,
        y: 20,
        w: 6,
        h: 5,
        i: 'マップチャート:ファン数(国)',
        name: 'FacebookAnalyticsChartMap',
        options: { metric: 'page_fans_country' }
      },
      {
        x: 6,
        y: 25,
        w: 6,
        h: 5,
        i: 'マップチャート:ファン数(都道府県)',
        name: 'FacebookAnalyticsChartMap',
        options: { metric: 'page_fans_city' }
      }
    ]
  }
}
