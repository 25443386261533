import HyperDX from '@hyperdx/browser'

import { store } from '@/client/store'

export class TrackingService {
  static DEBUG = '[TrackingService]'

  /**
   * 初期化
   */
  static init(): void {
    if (process.env.NODE_ENV === 'test') return

    const HYPER_DX_API_KEY = 'bc1138eb-8bc9-456b-9034-82a16966685e'

    // ? 開発環境のみ
    if (process.env.NODE_ENV === 'development') {
      // ? テストしたい時のみコメントを外す
      // HyperDX.init({
      //   apiKey: HYPER_DX_API_KEY,
      //   service: 'cms-dev',
      //   tracePropagationTargets: [/dev-cms.comnico.jp\/api/i],
      //   consoleCapture: true,
      //   advancedNetworkCapture: true
      // })
    }

    // ? 本番環境のみ
    if (process.env.NODE_ENV === 'production') {
      // ? 別途料金が発生するため
      HyperDX.init({
        apiKey: HYPER_DX_API_KEY,
        service: 'cms',
        tracePropagationTargets: [/cms.comnico.jp\/api/i],
        consoleCapture: true,
        advancedNetworkCapture: true
      })
    }
  }

  /**
   * イベント送信
   */
  static sendEvent(name: string): void {
    if (process.env.NODE_ENV === 'test') return

    const is_logined = store.state.user.id && store.state.project.id

    if (!is_logined || store.state.user.is_super_login) return

    // ? 開発環境のみ
    if (process.env.NODE_ENV === 'development') {
      console.log(this.DEBUG, name)
    }

    gtag('event', name, {
      event_category: store.state.project.project_name,
      event_label: store.state.user.name
    })

    // ? 本番環境のみ
    if (process.env.NODE_ENV === 'production') {
      // ? 別途料金が発生するため
      HyperDX.addAction(name, {})
    }
  }

  /**
   * トライアル登録の送信
   */
  static sendTrialRegistration(project_name: string, user_name: string): void {
    if (process.env.NODE_ENV === 'test') return

    // ? 開発環境のみ
    if (process.env.NODE_ENV === 'development') {
      console.log(this.DEBUG, 'Start Trial')
    }

    gtag('event', 'Start Trial', {
      event_category: project_name,
      event_label: user_name
    })

    // ? 特殊なイベント送信のため、本番のみ送信する
    if (process.env.NODE_ENV === 'production') {
      gtag('event', 'conversion', {
        send_to: 'AW-852155166/3U7pCMun-XQQnrarlgM',
        value: 68000,
        currency: 'JPY',
        remarketing_only: false
      })

      fbq('track', 'CompleteRegistration')

      ytag({
        type: 'yss_conversion',
        config: {
          yahoo_conversion_id: '1001185229',
          yahoo_conversion_label: 'B95nCLf5hfsBEMaf8MIB',
          yahoo_conversion_value: '0'
        }
      })
    }
  }

  /**
   * ログイン情報の送信
   */
  static sendLoginData(page_path: string) {
    if (process.env.NODE_ENV === 'test') return

    const is_logined = store.state.user.id && store.state.project.id

    if (!is_logined || store.state.user.is_demo_user || store.state.user.is_super_login) return

    // ? テスト実行環境ではない結合環境の場合のみ
    gtag('set', 'user_properties', {
      userId: store.state.user.id,
      userName: store.state.user.name,
      groupId: store.state.project.id,
      groupName: store.state.project.project_name,
      groupContractStatus: store.state.project.contract_status
    })

    gtag('set', { page_path })

    // ? 本番環境のみ
    if (process.env.NODE_ENV === 'production') {
      // ? 規定値が文字列のため、数値を文字列に変換
      HyperDX.setGlobalAttributes({
        userId: store.state.user.id.toString(),
        userEmail: store.state.user.mail,
        userName: store.state.user.name,
        groupId: store.state.project.id.toString(),
        groupName: store.state.project.project_name,
        groupContractStatus: store.state.project.contract_status.toString()
      })
    }
  }
}
