
import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'

@Component({
  name: 'ExternalLink',
  components: {
    Icon
  }
})
export default class ExternalLink extends Vue {
  @Prop({ type: String, default: 'link' })
  color!: 'link' | 'warning'

  @Prop({ type: String, required: true })
  href!: string
}
