
import { Component, Emit, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Panel from '@/client/components-old/atoms/Panel'
import InvitationForm from '@/client/components-old/organisms/InvitationForm'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import { IGetInvitationByIdResponse, IInvitation } from '@/client/utils/api/invitations'

@Component({
  name: 'Invitation',
  components: {
    ButtonLink,
    Panel,
    InvitationForm
  }
})
export default class Invitation extends Vue {
  @State('user') user!: IRootState['user']
  @Action('fetchLanguage') fetchLanguage: any
  @Action('forceReRender') forceReRender: any

  is_expired = false

  invitation: IInvitation = {
    email: '',
    project: {
      id: 0,
      project_name: ''
    },
    user: null
  }

  get disabled_ja() {
    return this.user.language === 'ja'
  }

  get disabled_en() {
    return this.user.language === 'en'
  }

  get color_ja() {
    return this.disabled_ja ? 'default' : 'info'
  }

  get color_en() {
    return this.disabled_en ? 'default' : 'info'
  }

  @Emit('change-route')
  changeRoute(payload: any): any {
    return payload
  }

  /**
   * ページ表示時
   */
  async created() {
    const response = await API.get<IGetInvitationByIdResponse>(
      `invitations/${this.$route.params.invitation_id}`
    )

    if (response.data.data) {
      this.is_expired = false

      this.invitation = response.data.data

      return
    }

    if (response.data.error && response.data.error.type === 'INVITATION_EXPIRED') {
      this.is_expired = true

      return
    }

    this.changeRoute({ name: 'login' })
  }

  /**
   * 言語の変更
   */
  async changeLanguage(language: 'ja' | 'en') {
    await this.fetchLanguage(language)
    await this.forceReRender()
  }
}
