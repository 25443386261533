
import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import PaymentContractLayout from '@/client/components-old/templates/PaymentContractLayout'
import { IState as IPaymentContractState } from '@/client/store/modules/payment_contract'

const payment_contract = namespace('payment_contract')

@Component({
  name: 'PaymentContract',
  components: {
    PaymentContractLayout
  }
})
export default class PaymentContract extends Vue {
  @payment_contract.State('is_loading') is_loading!: IPaymentContractState['is_loading']
  @payment_contract.State('is_submit') is_submit!: IPaymentContractState['is_submit']
  @payment_contract.Action('createdPaymentContract') createdPaymentContract!: any
  @payment_contract.Action('destroyedPaymentContract') destroyedPaymentContract!: any

  get is_show_loading() {
    return this.is_loading || this.is_submit
  }

  get loading_text() {
    if (this.is_submit) {
      return 'グループを登録しています。'
    }

    return 'データを取得中です。しばらくお待ちください。'
  }

  @Emit('change-project')
  changeProject(payload: any) {
    return payload
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.createdPaymentContract()
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedPaymentContract()
  }
}
