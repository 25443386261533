const MB = 2 ** 20

/**
 * 画像のファイル形式をチェック
 */
export function checkImageType(type: string): boolean {
  return ['image/jpeg', 'image/png', 'image/gif'].includes(type)
}

/**
 * 動画のファイルサイズをチェック
 */
export function checkVideoSize(size: number): boolean {
  return size <= 300 * MB // API仕様だと1GBまで
}

/**
 * 動画の再生時間をチェック
 */
export function checkVideoDuration(duration: number): boolean {
  return duration <= 10 * 60 // API仕様だと20分まで
}

/**
 * 動画の解像度をチェック
 */
export function checkVideoResolution(width: number, height: number): boolean {
  return 200 <= width && 200 <= height
}
