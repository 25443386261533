import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import DateTime from '@/client/components-old/atoms/DateTime'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import BadgeMonitoring from '@/client/components-old/molecules/BadgeMonitoring'
import { IState as IMonitoring } from '@/client/store/modules/monitoring'
import { convertMemoMessage } from '@/client/utils/regex'

const monitoring = namespace('monitoring')

@Component({
  name: 'MonitoringDetailHistory',
  components: {
    DateTime,
    Flex,
    Icon,
    Message,
    Tooltip,
    Account,
    BadgeMonitoring
  }
})
export default class MonitoringDetailHistory extends Vue {
  @monitoring.State('api_histories') api_histories: IMonitoring['api_histories']

  /**
   * 編集コメントか確認
   */
  isCommentEdit(type: string): boolean {
    return type === 'monitoring_comment_edit'
  }

  /**
   * メモの内容を変換
   */
  convertMessage(message: string): string {
    return convertMemoMessage(message)
  }
}
