import { GridItem, GridLayout } from 'vue-grid-layout'
import { Component, Vue } from 'vue-property-decorator'

import * as constants from '@/client/components-old/_constants/facebook_analytics'
import Box from '@/client/components-old/atoms/Box'
import Legend from '@/client/components-old/atoms/Legend'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import FacebookAnalyticsChartHorizontal from '@/client/components-old/organisms/FacebookAnalyticsChartHorizontal'
import FacebookAnalyticsChartVertical from '@/client/components-old/organisms/FacebookAnalyticsChartVertical'
import FacebookAnalyticsTotal from '@/client/components-old/organisms/FacebookAnalyticsTotal'

@Component({
  name: 'FacebookAnalyticsVideo',
  components: {
    GridLayout,
    GridItem,
    FacebookAnalyticsChartHorizontal,
    FacebookAnalyticsChartVertical,
    FacebookAnalyticsTotal,
    Legend,
    Box,
    Message,
    Scroll
  }
})
export default class FacebookAnalyticsVideo extends Vue {
  get legend_data() {
    return constants.DATA_TYPES.map(type => ({ text: type.text, color: type.color }))
  }

  get grid_layout() {
    return [
      {
        x: 0,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:動画3秒再生数',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_video_views',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 3,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:動画3秒再生人数',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_video_views_unique',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 6,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:視聴秒数',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_video_view_time',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 9,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:動画3秒再生数',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'post_video_views',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:動画3秒再生人数',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'post_video_views_unique',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 3,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:平均視聴秒数',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'post_video_avg_time_watched',
          unit: 'average',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 6,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:主な性別・年代',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'post_video_view_time_by_age_bucket_and_gender',
          unit: 'major',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 4,
        w: 12,
        h: 4,
        i: '垂直チャート:動画3秒再生数',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_video_views',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 8,
        w: 12,
        h: 4,
        i: '垂直チャート:動画3秒再生人数',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_video_views_unique',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 12,
        w: 12,
        h: 4,
        i: '垂直チャート:視聴秒数',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_video_view_time',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 16,
        w: 12,
        h: 4,
        i: '垂直チャート:動画3秒再生数',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'post_video_views',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 20,
        w: 12,
        h: 4,
        i: '垂直チャート:動画3秒再生人数',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'post_video_views_unique',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 24,
        w: 12,
        h: 4,
        i: '垂直チャート:平均視聴秒数',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'post_video_avg_time_watched',
          interval: '1_day',
          unit: 'average',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 28,
        w: 6,
        h: 2,
        i: '水平チャート:オーガニック再生数と広告再生数',
        name: 'FacebookAnalyticsChartHorizontal',
        options: {
          data_type: 'daily_data',
          metric: 'page_video_views_organic_vs_paid'
        }
      },
      {
        x: 0,
        y: 30,
        w: 6,
        h: 2,
        i: '水平チャート:自動再生数とクリック再生数',
        name: 'FacebookAnalyticsChartHorizontal',
        options: {
          data_type: 'daily_data',
          metric: 'page_video_views_autoplayed_vs_click_to_play'
        }
      },
      {
        x: 0,
        y: 32,
        w: 6,
        h: 3,
        i: '水平チャート:視聴秒数ごとの再生数',
        name: 'FacebookAnalyticsChartHorizontal',
        options: {
          data_type: 'daily_data',
          metric: 'page_video_views_by_time'
        }
      },
      {
        x: 0,
        y: 35,
        w: 6,
        h: 3,
        i: '水平チャート:視聴秒数ごとの再生人数',
        name: 'FacebookAnalyticsChartHorizontal',
        options: {
          data_type: 'daily_data',
          metric: 'page_video_views_unique_by_time'
        }
      },
      {
        x: 6,
        y: 28,
        w: 6,
        h: 2,
        i: '水平チャート:オーガニック再生数と広告再生数',
        name: 'FacebookAnalyticsChartHorizontal',
        options: {
          data_type: 'post_data',
          metric: 'post_video_views_organic_vs_paid'
        }
      },
      {
        x: 6,
        y: 30,
        w: 6,
        h: 2,
        i: '水平チャート:自動再生数とクリック再生数',
        name: 'FacebookAnalyticsChartHorizontal',
        options: {
          data_type: 'post_data',
          metric: 'post_video_views_autoplayed_vs_click_to_play'
        }
      },
      {
        x: 6,
        y: 32,
        w: 6,
        h: 5,
        i: '水平チャート:視聴秒数(性別・年代別)',
        name: 'FacebookAnalyticsChartHorizontal',
        options: {
          data_type: 'post_data',
          metric: 'post_video_view_time_by_age_bucket_and_gender'
        }
      }
    ]
  }
}
