
import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import API from '@/client/utils/api'
import {
  IPostPasswordsResetsParams,
  IPostPasswordsResetsResponse
} from '@/client/utils/api/passwords'
import { MAIL_FORMAT } from '@/client/utils/regex'

const notification = namespace('notification')

@Component({
  name: 'PasswordRequestForm',
  components: {
    Button,
    Icon,
    Input,
    Message,
    Panel
  }
})
export default class PasswordRequestForm extends Vue {
  @notification.Action('showNotification') showNotification: any

  form: {
    email: string
  } = {
    email: ''
  }

  is_email_error = false
  is_email_format_error = false

  loading = false

  get error_email() {
    return this.is_email_error || this.is_email_format_error
  }

  get disabled() {
    const is_email = !this.form.email || this.error_email

    return this.loading || is_email
  }

  /**
   * メールアドレスの変更
   */
  changeEmail(value: string) {
    this.is_email_error = !value
    this.is_email_format_error = !MAIL_FORMAT.test(value)
  }

  /**
   * ログイン画面に移動
   */
  moveLoginPage() {
    this.changeRoute({ name: 'login' })
  }

  /**
   * パスワードリセットを申請
   */
  async requestPasswordReset() {
    if (this.disabled) return

    const params: IPostPasswordsResetsParams = {
      ...this.form
    }

    this.loading = true

    const response = await API.post<IPostPasswordsResetsResponse>('/passwords/resets', params)

    if (response.data.data) {
      this.showNotification({
        title: '確認メールを送信しました。',
        message: 'メールが届かない場合は、再度確認メールを送信してください。'
      })

      this.moveLoginPage()

      return
    }

    this.loading = false

    this.showNotification({
      title: '確認メールの送信に失敗しました。',
      message: '時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
