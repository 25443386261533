import API from '@/client/utils/api'
import {
  TGetOauthTwitterAccountsParams,
  TGetOauthTwitterAccountsResponses,
  TTwitterAccount
} from '@/client/utils/api/oauth'

type TAuth = {
  account_id: string
  access_token: string
  access_token_secret: string
}

type TAccount = TTwitterAccount & {
  access_token_secret: string
}

type TAuthResponse = {
  data?: TAccount[]
  error?: {
    type: string
    title: string
  }
}

/**
 * Twitter認証
 */
export async function execAuth(project_id: number): Promise<TAuthResponse> {
  const result = await auth()

  if (!result) {
    return {
      error: {
        type: 'AUTHENTICATION_CANCELED',
        title: 'X認証が取り消されました。'
      }
    }
  }

  const accounts = await getAccounts(
    project_id,
    result.account_id,
    result.access_token,
    result.access_token_secret
  )

  if (!accounts || !accounts.length) {
    return {
      error: {
        type: 'OAUTH_FAILED',
        title: 'アカウント取得に失敗しました。'
      }
    }
  }

  return {
    data: accounts
  }
}

/**
 * Twitterの許可認証を行う
 */
function auth(): Promise<TAuth> {
  const width = 600
  const height = 800
  const top = (screen.height - height) / 4
  const left = (screen.width - width) / 2

  const auth_window = window.open(
    '/api/v1/oauth/twitter',
    'twitter_oauth',
    `width=${width}, height=${height}, top=${top}, left=${left}`
  )

  return new Promise(resolve => {
    window.onTwitterAuth = auth => {
      try {
        delete window.onTwitterAuth

        const authData = JSON.parse(JSON.stringify(auth))

        if (authData && authData.account_id) {
          return resolve(authData)
        }

        resolve(null)
      } catch (err) {
        resolve(null)
      } finally {
        auth_window.close()
      }
    }
  })
}

/**
 * 管理しているアカウント一覧を取得
 */
async function getAccounts(
  project_id: number,
  account_id: string,
  access_token: string,
  access_token_secret: string
): Promise<TAccount[]> {
  const params: TGetOauthTwitterAccountsParams = {
    project_id,
    account_id,
    access_token
  }
  const response = await API.get<TGetOauthTwitterAccountsResponses>('oauth/twitter/accounts', {
    params
  })

  if (!response.data.data) {
    return null
  }

  return response.data.data.map(v => ({ ...v, access_token_secret }))
}
