import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import event from '@/client/utils/event'

type TChartWordCloudOptions = {
  series: {
    name: string
    weight: number
  }[]
}

@Component({
  name: 'ChartWordCloud'
})
export default class ChartWordCloud extends Vue {
  @Prop({ type: Object, required: true })
  options: TChartWordCloudOptions

  is_show = false

  async mounted() {
    this.is_show = false
    await this.$nextTick()
    await event.delay(0)
    this.is_show = true
  }

  get chart_options() {
    const clickWord = this.clickWord

    return {
      chart: {
        animation: true,
        type: 'wordcloud'
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      tooltip: {
        shared: false,
        borderColor: '#b5b5b5',
        style: {
          fontSize: '13px'
        },
        pointFormatter() {
          return `<span style="color:${this.color}">●</span>
           <b>${this.weight.toLocaleString()}</b><br/>`
        }
      },
      loading: false,
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      series: [
        {
          name: '',
          showInLegend: false,
          minFontSize: 6,
          rotation: {
            from: 0,
            to: 0
          },
          data: this.options.series,
          cursor: 'pointer',
          point: {
            events: {
              click() {
                clickWord(this.name)
              }
            }
          }
        }
      ]
    }
  }

  @Emit('click-word')
  clickWord(event: any) {
    return event
  }
}
