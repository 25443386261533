import { render, staticRenderFns } from "./FacebookComparisonAccountDialog.vue?vue&type=template&id=4b726781&scoped=true"
import script from "./FacebookComparisonAccountDialog.ts?vue&type=script&lang=ts&external"
export * from "./FacebookComparisonAccountDialog.ts?vue&type=script&lang=ts&external"
import style0 from "./FacebookComparisonAccountDialog.vue?vue&type=style&index=0&id=4b726781&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b726781",
  null
  
)

export default component.exports