
import { computed, defineComponent, ref } from 'vue'
import { mapActions, mapState } from 'vuex'

import { SnsButton } from '@/client/components/atoms'
import { TwitterEnterpriseAuthService } from '@/client/components-old/_utils/twitter_enterprise_authentication'
import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import SnsRegisterItem from '@/client/components-old/molecules/SnsRegisterItem'
import { TrackingService } from '@/client/services'
import { TPostFacebookAccountsResponses } from '@/client/utils/api/facebook_accounts'
import { TPostInstagramAccountsResponses } from '@/client/utils/api/instagram_accounts'
import { TPostTwitterAccountsResponses } from '@/client/utils/api/twitter_accounts'
import { getTranslateText as t } from '@/client/utils/filters'

export default defineComponent({
  name: 'GroupSettingMonitoringAddDialog',
  components: {
    Button,
    SnsButton,
    Dialog,
    Message,
    Icon,
    SnsRegisterItem
  },
  setup() {
    const visible = ref(false)
    const loading = ref(false)
    const is_screen_select = ref(true)
    const sns_type = ref('')
    const account_id = ref('')

    const is_disable_add = computed(() => {
      return loading.value || account_id.value === ''
    })

    const close = () => {
      visible.value = false
      loading.value = false
      sns_type.value = ''
      is_screen_select.value = true
      account_id.value = ''
    }

    return {
      close,
      loading,
      visible,
      sns_type,
      account_id,
      is_screen_select,
      is_disable_add,
      t
    }
  },
  computed: {
    ...mapState(['project']),
    ...mapState('accounts', ['facebook', 'instagram', 'twitter']),

    account_info() {
      let accounts = this[this.sns_type]
        .filter(v => !v.is_use_monitoring)
        .map(v => ({
          account_id: v.id,
          name: v.name,
          username: v.username,
          image_url: v.image_url,
          is_exist: false,
          is_same_project: false,
          is_enterprise_verified: v.is_enterprise_verified
        }))

      this.selectSnsAccount(accounts[0]?.account_id || '')

      return accounts
    },

    monitoring_free_count() {
      switch (this.sns_type) {
        case 'facebook': {
          return this.project.fb_monitoring_free_count + 1
        }

        case 'instagram': {
          return this.project.in_monitoring_free_count + 1
        }

        case 'twitter': {
          return this.project.tw_monitoring_free_count + 1
        }

        default: {
          return 1
        }
      }
    },

    charge_monitoring() {
      switch (this.sns_type) {
        case 'facebook': {
          return this.project.charge_fb_monitoring
        }

        case 'instagram': {
          return this.project.charge_in_monitoring
        }

        case 'twitter': {
          return this.project.charge_tw_monitoring
        }

        default: {
          return 0
        }
      }
    }
  },
  methods: {
    ...mapActions('accounts', [
      'createFacebookAccountMonitoring',
      'createInstagramAccountMonitoring',
      'createTwitterAccountMonitoring',
      'fetchSnsAccounts'
    ]),
    ...mapActions('notification', ['showNotification']),

    /**
     * ダイアログの表示
     */
    open() {
      this.visible = true

      TrackingService.sendEvent('click:グループ設定>モニタリング|モニタリングアカウント|追加')
    },

    selectSnsType(sns_type: string) {
      switch (sns_type) {
        case 'facebook': {
          TrackingService.sendEvent('click:モニタリングアカウント追加|FB選択')

          break
        }

        case 'instagram': {
          TrackingService.sendEvent('click:モニタリングアカウント追加|IG選択')

          break
        }

        case 'twitter': {
          TrackingService.sendEvent('モニタリングアカウント追加|TW選択')

          break
        }
      }

      this.sns_type = sns_type
      this.is_screen_select = false
    },

    selectCancel() {
      this.sns_type = ''
      this.is_screen_select = true

      TrackingService.sendEvent('click:モニタリングアカウント追加|キャンセル')
    },

    selectSnsAccount(account_id: string) {
      this.account_id = account_id
    },

    /**
     * モニタリングアカウントを作成
     */
    async addMonitoringAccount() {
      if (!this.account_id) return

      switch (this.sns_type) {
        case 'facebook': {
          this.loading = true

          const result: TPostFacebookAccountsResponses = await this.createFacebookAccountMonitoring(
            { account_id: this.account_id }
          )

          this.loading = false

          if (result.data) {
            TrackingService.sendEvent('click:モニタリングアカウント追加|アカウントを追加')
            await this.fetchSnsAccounts()
            await this.showNotification({ title: t('モニタリングアカウントを追加しました。') })
            this.close()
            return
          }

          await this.showNotification({
            title: t('モニタリングアカウントの追加に失敗しました。'),
            type: 'error'
          })

          return
        }

        case 'twitter': {
          this.loading = true

          const is_enterprise_verified = await this.authTwitterEnterpriseAccount()

          if (!is_enterprise_verified) {
            this.loading = false

            await this.showNotification({
              title: t('モニタリングアカウントの追加に失敗しました。'),
              type: 'error'
            })

            return
          }

          const result: TPostTwitterAccountsResponses = await this.createTwitterAccountMonitoring({
            account_id: this.account_id
          })

          this.loading = false

          if (result.data) {
            TrackingService.sendEvent('click:モニタリングアカウント追加|アカウントを追加')
            await this.fetchSnsAccounts()
            await this.showNotification({ title: t('モニタリングアカウントを追加しました。') })
            this.close()
            return
          }

          await this.showNotification({
            title: t('モニタリングアカウントの追加に失敗しました。'),
            type: 'error'
          })

          return
        }

        case 'instagram': {
          this.loading = true

          const result: TPostInstagramAccountsResponses =
            await this.createInstagramAccountMonitoring({ account_id: this.account_id })

          this.loading = false

          if (result.data) {
            TrackingService.sendEvent('click:モニタリングアカウント追加|アカウントを追加')
            await this.fetchSnsAccounts()
            await this.showNotification({ title: t('モニタリングアカウントを追加しました。') })
            this.close()
            return
          }

          await this.showNotification({
            title: t('モニタリングアカウントの追加に失敗しました。'),
            type: 'error'
          })

          return
        }

        default:
          await this.showNotification({
            title: t('モニタリングアカウントの追加に失敗しました。'),
            type: 'error'
          })

          return
      }
    },

    /**
     * NTT AAA認証をする
     */
    async authTwitterEnterpriseAccount(): Promise<boolean> {
      const response = await TwitterEnterpriseAuthService.openAuthWindow(this.account_id)

      if (response.type === 'SUCCESS') {
        await this.showNotification({
          title: '認証が完了しました。',
          message: 'データの収集が始まります。',
          type: 'success'
        })

        return true
      }

      if (response.type === 'AUTHENTICATION_FAILED') {
        await this.showNotification({
          title: '認証に失敗しました。',
          message: '恐れ入りますが、時間をおいて再度お試しください。',
          type: 'error'
        })

        return false
      }

      if (response.type === 'INVALID_ACCOUNT') {
        await this.showNotification({
          title: 'アカウントが一致していません。',
          message: '認証したいアカウントにログインしてください。',
          type: 'error'
        })

        return false
      }

      await this.showNotification({
        title: '認証に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })

      return false
    }
  }
})
