import twttr from 'twitter-text'

// 半角英数字と記号を含む8文字以上60文字以下
export const PASSWORD_CHARACTER = /^[a-zA-Z0-9!"#$%&''()\-^@[;:\],./\\|`{+*}<>?_]{8,60}$/

// 半角英小文字大文字数字をそれぞれ1種類以上含む8文字以上60文字以下
export const PASSWORD_REQUIRED = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[^]{8,60}$/

export const MAIL_FORMAT = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const MENTION_FORMAT = /\B@([^\n]+)\n/gi

export const SHORT_URL_FORMAT = /^https?:\/\/[^\s("<,>]*\.[^\s"><,]+(?:\?.*)?$/gm

export const INSTAGRAM_URL_FORMAT = /^https:\/\/www.instagram.com\/(p|reel)\/(\w+)/

export const INSTAGRAM_STORY_URL_FORMAT = /^https:\/\/(?:www\.)?instagram.com\/stories\/(\w+)/

export const INSTAGRAM_INTERNAL_URL_FORMAT = /www.instagram.com\/(\w+)/

export const FACEBOOK_INTERNAL_URL_FORMAT = /www.facebook.com\/(\w+)/

export const ZIP_CODE_FORMAT = /^([A-Z0-9]{1,5}((-|\s)?[A-Z0-9]{1,4}))$/

export const TELEPHONE_FORMAT = /^([+]?[0-9]{1,5}([-]?[0-9]{1,5}){1,2}([-]?[0-9]{4}){1,2})$/

export const LINE_FORMAT = /(?:\r\n|\r|\n)/g

export const AUTH_CODE_FORMAT = /^\d{6}/g

export const TWITTER_STATUS_URL_FORMAT = /^https:\/\/(?:x.com)\/\w+\/status\/\d+/

/**
 * Instagramのメンションを変換
 */
export function autoInstagramMentions(
  text: string,
  options: {
    usernameUrlBase: string
    usernameClass: string
  }
): string {
  const format = /(^|\s|\u3000)(@)([\w|.]+)/g

  const template = `$1<a href="${options.usernameUrlBase}$3" class="${options.usernameClass}" target="_blank">$2$3</a>`

  return text.replace(format, template)
}

/**
 * 正規表現用に文字列をエスケープする
 */
export function regExpEscape(text: string): string {
  return text.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&')
}

/**
 * URL以外のテキストをエスケープする
 */
export function htmlEscapeNotUrl(text: string): string {
  if (!text) return ''

  const urls: string[] = twttr.extractUrls(text)

  if (!urls.length) return twttr.htmlEscape(text)

  const format = new RegExp(`(${urls.map(regExpEscape).join('|')})`, 'g')

  return text.split(format).reduce((p, c) => p + (format.test(c) ? c : twttr.htmlEscape(c)), '')
}

/**
 * プロダクト内でのメッセージ内容を変換
 */
export function convertMessage(text: string): string {
  let message = htmlEscapeNotUrl(text)

  message = twttr.autoLinkUrlsCustom(message, { targetBlank: true })

  return message
}

/**
 * プロダクト内でのメモのメッセージ内容を変換
 */
export function convertMemoMessage(text: string): string {
  const message = convertMessage(text)

  return message.replace(MENTION_FORMAT, '<div class="mention">@$1</div>')
}
