import { saveAs } from 'file-saver'
import moment from 'moment-timezone'
import { unparse } from 'papaparse'

/**
 * CSV用の日付形式変換
 */
export function format(date: moment.MomentInput) {
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}

/**
 * CSVのダウンロード
 */
export function download(data: any, filename: string) {
  const csv = unparse(data)

  const bom = '\ufeff'

  const blob = new Blob([bom, csv], { type: 'text/csv' })

  saveAs(blob, `${filename}.csv`)
}

/**
 * 口コミ分析の日付ラベルを取得
 */
export function getGraphLabel(options: {
  start_date: moment.MomentInput
  end_date: moment.MomentInput
  interval: string
}) {
  switch (options.interval) {
    case 'hour':
      return moment(options.start_date).format('YYYY/MM/DD HH:00')
    case 'day':
      return moment(options.start_date).format('YYYY/MM/DD')
    case 'week':
      return `${moment(options.start_date).format('M/D')} - ${moment(options.end_date).format(
        'M/D'
      )}`
    case 'month':
      return moment(options.start_date).format('MMM')
    default:
      return ''
  }
}

export default { format, download, getGraphLabel }
