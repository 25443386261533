import { Component, Prop, Vue } from 'vue-property-decorator'

import event from '@/client/utils/event'

type TChartSingleBarOptions = {
  series: number[]
  colors: string[]
  display_total: boolean
  total: number
  max: number
}

@Component({
  name: 'ChartSingleBar'
})
export default class ChartSingleBar extends Vue {
  @Prop({ type: Object, required: true })
  options: TChartSingleBarOptions

  is_show = false

  async mounted() {
    this.is_show = false
    await this.$nextTick()
    await event.delay(0)
    this.is_show = true
  }

  get is_total() {
    return this.options.display_total
  }

  get total() {
    return this.options.total
  }

  get chart_options() {
    return {
      chart: {
        type: 'bar',
        height: 25,
        borderWidth: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.0)'
      },
      credits: {
        enabled: false
      },
      colors: this.options.colors,
      title: {
        text: null
      },
      xAxis: {
        categories: [''],
        lineWidth: 0,
        gridLineWidth: 0,
        tickWidth: 0
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
        labels: {
          enabled: false
        },
        gridLineWidth: 0,
        max: this.options.max
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      plotOptions: {
        series: {
          pointWidth: 30,
          borderWidth: 1,
          stacking: 'normal',
          animation: false
        }
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      series: this.options.series.map((data, index) => ({
        data: [data],
        index: this.options.series.length - index - 1 // seriesの配列順にデータが並ぶようにするため、逆順にindexをつけていく
      }))
    }
  }
}
