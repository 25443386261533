import { render, staticRenderFns } from "./CalendarTrendItem.vue?vue&type=template&id=0d34f6c1&scoped=true"
import script from "./CalendarTrendItem.ts?vue&type=script&lang=ts&external"
export * from "./CalendarTrendItem.ts?vue&type=script&lang=ts&external"
import style0 from "./CalendarTrendItem.vue?vue&type=style&index=0&id=0d34f6c1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d34f6c1",
  null
  
)

export default component.exports