
import { computed, defineComponent, PropType } from 'vue'

type UserProps = {
  id: number
  name: string
  picture_url: string | null
}

type SizeProps = 'small' | 'normal' | 'large'

export default defineComponent({
  name: 'AvatarUser',
  props: {
    user: {
      type: Object as PropType<UserProps>,
      required: true
    },
    size: {
      type: String as PropType<SizeProps>,
      default: 'normal'
    }
  },
  setup(props) {
    const initial = computed(() => props.user.name.charAt(0))

    return {
      initial
    }
  }
})
