import moment from 'moment-timezone'

import {
  IInstagramInsightsAccounts,
  IInstagramInsightsPosts
} from '@/client/utils/api/instagram_insights'

import * as calculation from './calculation'

// ? Instagramは複数の動画形式があるため
const VIDEO_TYPES = ['video', 'reels']

/**
 * 分析データから値を取得
 * @param {any} insight 分析データ（単体）
 * @param {string} metric 指標名
 * @returns {R} 値
 */
export function getMetricValue<R>(insight: any, metric: string): R {
  return insight[metric] ? insight[metric] : null
}

/**
 * 分析データから値の配列を取得
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {R[]} 指標データの配列
 */
export function getMetricArray<R>(insights: any[], metric: string): R[] {
  return insights.map(insight => getMetricValue(insight, metric))
}

/**
 * 分析データから値の合計を取得
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {number} 指標データの合計
 */
export function getMetricTotal(insights: any[], metric: string): number {
  switch (metric) {
    // 率の指標は合計が存在しない
    case 'reactions_rate':
    case 'engagements_rate':
      return null

    // 累計数の場合、最終日の値を返す
    case 'followers_count':
    case 'follows_count': {
      const metric_array = getMetricArray<number>(insights, metric)
      return metric_array.length > 0 ? metric_array[0] : 0
    }

    default:
      return calculation.addition(getMetricArray(insights, metric))
  }
}

/**
 * 分析データから値の平均を取得
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {number} 指標データの平均
 */
export function getMetricAverage(insights: any[], metric: string): number {
  let numerator = 0
  let denominator = 0

  switch (metric) {
    // 反応率
    case 'reactions_rate':
      numerator = getMetricTotal(insights, 'reactions')
      denominator = getMetricTotal(insights, 'followers_at_that_time')
      return calculation.percentage(numerator, denominator)

    // エンゲージメント率
    case 'engagements_rate':
      numerator = getMetricTotal(insights, 'engagements')
      denominator = getMetricTotal(insights, 'impressions_unique')
      return calculation.percentage(numerator, denominator)

    // 累計数
    case 'followers_count':
    case 'follows_count':
      numerator = calculation.addition(getMetricArray(insights, metric))
      denominator = insights.length
      return calculation.division(numerator, denominator, 1)

    // 動画系 (投稿テーブルの場合を考慮)
    case 'video_views':
      numerator = getMetricTotal(insights, metric)
      denominator = insights.filter(v => VIDEO_TYPES.includes(v.type)).length
      return calculation.division(numerator, denominator, 1)

    // その他の指標
    default:
      numerator = getMetricTotal(insights, metric)
      denominator = insights.length
      return calculation.division(numerator, denominator, 1)
  }
}

/**
 * ページ分析をキーによってフィルタリング
 * @param {IInstagramInsightsAccounts[]} daily_data ページ分析データ
 * @param {IInstagramInsightsPosts[]} post_data 投稿分析データ
 * @param {string} key 取得するキー
 * @returns {IInstagramInsightsAccounts[]} 合計値
 */
export function filterDailyData(
  daily_data: IInstagramInsightsAccounts[],
  post_data: IInstagramInsightsPosts[],
  key: string
): IInstagramInsightsAccounts[] {
  switch (key) {
    case 'post_count':
      return daily_data.map(account => {
        const post_count = post_data.filter(post =>
          moment(post.created_time).isSame(moment(account.end_time), 'day')
        ).length

        return { ...account, post_count }
      })

    default:
      return [...daily_data]
  }
}

/**
 * 投稿分析をキーによってフィルタリング
 * @param {IInstagramInsightsPosts[]} post_data 投稿分析データ
 * @param {string} key 取得するキー
 * @returns {IInstagramInsightsPosts[]} 合計値
 */
export function filterPostData(
  post_data: IInstagramInsightsPosts[],
  key: string
): IInstagramInsightsPosts[] {
  switch (key) {
    case 'video_views':
      return post_data.filter(post => VIDEO_TYPES.includes(post.type))
    default:
      return [...post_data]
  }
}

/**
 * 指標によって値の表示方法を変換する
 * @param {number} value 値
 * @param {string} metric 指標
 * @returns {string} 加工した値文字列
 */
export function convertValueWithMetric(value: number, metric: string): string {
  switch (metric) {
    case 'reactions_rate':
    case 'engagements_rate':
      if (!value) return '0%'
      return `${value.toLocaleString()}%`
    case 'followers_count_up_down':
    case 'follows_count_up_down':
      if (!value) return '0'
      if (Math.sign(value) === 1) return `+${value.toLocaleString()}`
      return value.toLocaleString()
    default:
      if (!value) return '0'
      return value.toLocaleString()
  }
}
