
import { defineComponent, ref } from 'vue'
import { mapActions, mapState } from 'vuex'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import { TrackingService } from '@/client/services'
import API from '@/client/utils/api'
import { getTranslateText as t } from '@/client/utils/filters'

type TPostCategorySettingDialogOptions = {
  sns_post_id: number
  category_ids: number[]
}

export default defineComponent({
  name: 'PostTagsSnsPostSettingDialog',
  components: {
    Button,
    Dialog,
    SelectMulti
  },
  setup() {
    const visible = ref(false)
    const is_submit = ref(false)
    const sns_post_id = ref(null)
    const category_ids = ref([])

    const hide = () => {
      visible.value = false

      TrackingService.sendEvent('click:投稿タグの設定|キャンセル')
    }

    return {
      visible,
      is_submit,
      sns_post_id,
      category_ids,
      hide,
      t
    }
  },
  computed: {
    ...mapState('categories', ['api_post_categories']),

    category_options() {
      return this.api_post_categories.map(v => ({ value: v.id, text: v.name }))
    }
  },
  methods: {
    ...mapActions('notification', ['showNotification']),

    /**
     * ダイアログの表示
     */
    open(options: TPostCategorySettingDialogOptions): void {
      this.visible = true
      this.sns_post_id = options.sns_post_id
      this.category_ids = options.category_ids
    },

    /**
     * フォームの送信
     */
    async submit(): Promise<void> {
      if (this.is_submit) return

      TrackingService.sendEvent('click:投稿タグの設定|保存')

      this.is_submit = true

      const params = {
        sns_post_id: this.sns_post_id,
        category_ids: this.category_ids
      }

      const response = await API.post('sns_post_categories', params)

      setTimeout(() => {
        this.is_submit = false
      }, 2000)

      if (response.data.data) {
        this.hide()

        this.showNotification({ title: 'タグ設定を保存しました。' })

        const categories = this.api_post_categories.filter(
          v => params.category_ids.indexOf(v.id) >= 0
        )

        this.$emit('setting-category', { categories })
      } else if (response.data.error && response.data.error.type === 'MAX_CATEGORY_OVER') {
        this.showNotification({ title: 'タグを10個以下に設定してください。', type: 'error' })
      } else {
        this.showNotification({ title: 'タグ設定の保存に失敗しました。', type: 'error' })
      }
    }
  }
})
