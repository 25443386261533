
import { Component, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import { namespace } from 'vuex-class'

import { VideoThumbnailDialog } from '@/client/components/molecules'
import { getBlobData } from '@/client/components-old/_utils/cloud_storage'
import Button from '@/client/components-old/atoms/Button'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Panel from '@/client/components-old/atoms/Panel'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import ImageEditorDialog from '@/client/components-old/organisms/ImageEditorDialog'
import PostCreateCollageDialog from '@/client/components-old/organisms/PostCreateCollageDialog'
import PostCreateMediaStorage from '@/client/components-old/organisms/PostCreateMediaStorage'
import StockphotoDialog from '@/client/components-old/organisms/StockphotoDialog'
import { NO_IMAGE_URL } from '@/client/constants/media'
import { CanvaService, TrackingService } from '@/client/services'
import {
  IGetter as IPostCreateGetter,
  IState as IPostCreateState
} from '@/client/store/modules/post_create'
import { isRequestTimeout } from '@/client/utils/api'
import { IPostImage, IPostVideo } from '@/client/utils/api/posts'
import { IVideo } from '@/client/utils/api/videos'
import i18n from '@/client/utils/i18n'
import * as validate from '@/client/utils/validate'
import { SnsType } from '@/common/types'

interface DraggableDragEvent {
  moved: {
    element: {
      media: Media
    }
    newIndex: number
    oldIndex: number
  }
}

interface Media {
  media_type: MediaType
  media_url: string
  media_thumbnail_url?: string
  sequence_no: number
  sns_types: SnsType[]
}

type MediaType = 'image' | 'video'

const post_create = namespace('post_create')
const notification = namespace('notification')

@Component({
  name: 'PostCreateMedia',
  components: {
    draggable,
    Button,
    Dropdown,
    Icon,
    Input,
    SwitchButton,
    Panel,
    Tooltip,
    ImageEditorDialog,
    PostCreateCollageDialog,
    PostCreateMediaStorage,
    StockphotoDialog,
    VideoThumbnailDialog
  }
})
export default class PostCreateMedia extends Vue {
  @post_create.State('accounts') accounts!: IPostCreateState['accounts']
  @post_create.State('images') images!: IPostCreateState['images']
  @post_create.State('videos') videos!: IPostCreateState['videos']
  @post_create.State('is_media_uploading')
  is_media_uploading!: IPostCreateState['is_media_uploading']
  @post_create.State('instagram_share_to_feed')
  instagram_share_to_feed!: IPostCreateState['instagram_share_to_feed']
  @post_create.State('instagram_post_type')
  instagram_post_type!: IPostCreateState['instagram_post_type']
  @post_create.Getter('use_facebook') use_facebook!: IPostCreateGetter['use_facebook']
  @post_create.Getter('use_twitter') use_twitter!: IPostCreateGetter['use_twitter']
  @post_create.Getter('use_instagram') use_instagram!: IPostCreateGetter['use_instagram']
  @post_create.Getter('use_tiktok') use_tiktok!: IPostCreateGetter['use_tiktok']
  @post_create.Mutation('SET_TYPE') setType!: any
  @post_create.Mutation('SET_IMAGES') setImages!: (images: IPostImage[]) => void
  @post_create.Mutation('SET_VIDEOS') setVideos!: (videos: IPostVideo[]) => void
  @post_create.Mutation('SET_IS_MEDIA_UPLOADING') setIsMediaUploading!: (payload: boolean) => void
  @post_create.Action('postImageUpload') postImageUpload!: any
  @post_create.Action('postVideoUpload') postVideoUpload!: any
  @post_create.Action('changeInstagramShareToFeed') changeInstagramShareToFeed!: any
  @notification.Action('showNotification') showNotification!: any

  $refs!: {
    media_button: HTMLButtonElement
    media_add_button: HTMLButtonElement
    media_file: HTMLInputElement
    replace_file: HTMLInputElement
    thumbnail_image: HTMLInputElement
    StockphotoDialog: any
    PostCreateCollageDialog: any
    VideoThumbnailDialog: any
    ImageEditorDialog: any
  }

  current_index = 0

  /**
   * アップロード可能なメディアのファイルタイプ
   */
  get accept() {
    return validate.IMAGE_TYPES.concat(validate.VIDEO_TYPES)
  }

  get is_media_edit() {
    return this.$mq !== 'sm' && !this.is_selected_canva && !this.is_selected_video
  }

  get is_media_edit_disabled() {
    return !this.images.length || this.images.some(v => v.is_animation_gif)
  }

  get is_canva_edit() {
    return this.$mq !== 'sm' && this.is_selected_canva
  }

  get is_facebook_thumbnail_change() {
    return this.use_facebook && this.video
  }

  get media(): Media[] {
    if (!this.images.length && !this.videos.length) return []

    const images = this.images.map<Media>(v => ({
      media_type: 'image',
      media_url: v.image_url,
      media_thumbnail_url: v.image_url,
      sequence_no: v.sequence_no,
      sns_types: this.getSnsTypes(v.sequence_no - 1)
    }))

    const videos = this.videos.map<Media>(v => ({
      media_type: 'video',
      media_url: v.video_url,
      media_thumbnail_url: v.video_thumbnail || '',
      sequence_no: v.sequence_no,
      sns_types: this.getSnsTypes(v.sequence_no - 1)
    }))

    const media = images.concat(videos).sort((a, b) => a.sequence_no - b.sequence_no)

    return media
  }

  set media(value) {
    const sort_value = value.map((v, i) => ({ ...v, sequence_no: i + 1 }))

    const images = this.getImagesFromMedia(sort_value, this.images)

    this.setImages(images)

    const videos = this.getVideosFromMedia(sort_value, this.videos)

    this.setVideos(videos)
  }

  get no_image() {
    return NO_IMAGE_URL
  }

  getSnsTypes(index: number) {
    const types: SnsType[] = []

    if (this.use_facebook && index < 10) types.push('facebook')
    if (this.use_twitter && index < 4) types.push('twitter')
    if (this.use_instagram && index < 10) types.push('instagram')
    if (this.use_tiktok && index < 1) types.push('tiktok')

    return types
  }

  getImagesFromMedia(media: Media[], images: IPostImage[]) {
    return media
      .map<IPostImage>(v => {
        const target = images.find(image => v.media_url === image.image_url)

        return {
          sequence_no: v.sequence_no,
          image_url: target?.image_url || '',
          is_animation_gif: target?.is_animation_gif || false,
          canva_id: target?.canva_id || null
        }
      })
      .filter(v => v.image_url)
  }

  getVideosFromMedia(media: Media[], videos: IPostVideo[]) {
    return media
      .map<IPostVideo>(v => {
        const target = videos.find(video => v.media_url === video.video_url)

        return {
          sequence_no: v.sequence_no,
          video_url: target?.video_url || '',
          video_title: target?.video_title || null,
          video_thumbnail: target?.video_thumbnail || null,
          video_type: target?.video_type || '',
          video_size: target?.video_size || 0,
          video_duration: target?.video_duration || null,
          video_frame_rate: target?.video_frame_rate || null,
          video_audio_channels: target?.video_audio_channels || null,
          video_audio_format: target?.video_audio_format || null
        }
      })
      .filter(v => v.video_url)
  }

  get is_selected_video() {
    const current_index = this.media[this.current_index]

    return current_index && current_index.media_type === 'video'
  }

  get is_selected_canva() {
    const current_index = this.media[this.current_index]

    if (!current_index) return false

    const image = this.images.find(v => v.sequence_no === current_index.sequence_no)

    return image && !!image.canva_id
  }

  get is_media_add_button() {
    if (this.use_facebook) {
      if (this.images.length) {
        return this.images.length < 10 && !this.images.some(v => v.is_animation_gif)
      }

      if (this.videos.length) {
        return this.videos.length < 1
      }
    }

    if (this.use_twitter) {
      if (this.images.length) {
        return this.images.length < 4 && !this.images.some(v => v.is_animation_gif)
      }

      if (this.videos.length) {
        return this.videos.length < 1
      }
    }

    if (this.use_instagram) {
      if (this.instagram_post_type === 'post') {
        return this.images.length + this.videos.length < 10
      }

      if (this.instagram_post_type === 'story') {
        return this.images.length + this.videos.length < 1
      }
    }

    return false
  }

  get facebook_thumbnail_options() {
    const options = [
      {
        icon: 'image',
        text: i18n.t('画像を選択'),
        value: 'upload'
      },
      {
        icon: 'folder-images',
        text: i18n.t('ストックフォト'),
        value: 'stockphoto'
      }
    ]

    if (this.$mq === 'sm') {
      return options
    }

    options.push({
      icon: 'border-all',
      text: i18n.t('コラージュ画像を作成'),
      value: 'collage'
    })

    const ua = navigator.userAgent.toLowerCase()

    // Safariの場合、動画からキャプチャをセットしない
    if (ua.indexOf('safari') > 0 && ua.indexOf('chrome') < 0) {
      return options
    }

    options.unshift({
      icon: 'videocam',
      text: i18n.t('動画からキャプチャ'),
      value: 'capture'
    })

    return options
  }

  get video() {
    return this.videos.find(v => v.sequence_no === 1)
  }

  get facebook_video_title() {
    return this.video?.video_title ?? ''
  }

  set facebook_video_title(value: string) {
    const videos = this.videos.map(v => (v.sequence_no === 1 ? { ...v, video_title: value } : v))

    this.setVideos(videos)
  }

  get is_single_video_only() {
    return (
      this.images.length === 0 &&
      this.videos.length === 1 &&
      this.videos.every(v => v.sequence_no === 1)
    )
  }

  get is_facebook_video_setting() {
    return this.use_facebook && this.is_single_video_only
  }

  get is_instagram_video_setting() {
    return this.use_instagram && this.instagram_post_type === 'post' && this.is_single_video_only
  }

  get max_media_count() {
    if (this.use_facebook) return 10
    if (this.use_twitter) return 4
    if (this.use_instagram && this.instagram_post_type === 'post') return 10
    if (this.use_instagram && this.instagram_post_type === 'story') return 1
    if (this.use_tiktok) return 1
    return 0
  }

  get is_cross_post() {
    return (
      this.accounts.some(v => v.sns === 'facebook') && this.accounts.some(v => v.sns === 'twitter')
    )
  }

  /**
   * メディアの追加
   */
  async addMedia(): Promise<void> {
    TrackingService.sendEvent('click:投稿作成|メディア挿入|ファイル選択')

    const input = this.$refs.media_file

    const files = input.files ? Array.from(input.files) : []

    await this.uploadMedia(files)

    input.value = ''
  }

  /**
   * メディアのアップロード
   */
  async uploadMedia(files: (File | Blob)[]) {
    if (!files.length) return

    this.setIsMediaUploading(true)

    for (const file of files) {
      const total_length = this.images.length + this.videos.length
      if (this.max_media_count <= total_length) break

      if (file.type.match('image/*')) {
        if (this.use_tiktok) {
          this.showNotification({
            title: 'アップロードに失敗しました。',
            message: 'TikTokは動画のみアップロード可能です。',
            type: 'error'
          })
          continue
        }
        const result = await this.addImage({ file })

        if (!result) continue
      }

      if (file.type.match('video/*')) {
        if (!this.validAddVideo(files.length)) {
          this.showNotification({
            title: 'アップロードに失敗しました。',
            message: '複数メディア投稿の場合は、画像のみアップロード可能です。',
            type: 'error'
          })
          continue
        }
        const result = await this.addVideo({ file })

        if (!result) continue
      }

      this.setType(this.setCreatePostType())
    }

    this.setIsMediaUploading(false)
  }

  /**
   * 投稿タイプの作成
   */
  setCreatePostType() {
    if (this.use_instagram) {
      if (this.instagram_post_type === 'story') return 'story'

      if (this.instagram_post_type === 'post' && this.images.length + this.videos.length > 1)
        return 'carousel'
    }

    if (this.images.length > 0) return 'image'

    if (this.videos.length > 0) return 'video'

    return 'text'
  }

  /**
   * Canvaのアップロード
   */
  async uploadFromCanva(payload: { file: Blob; canva_id: string }): Promise<void> {
    this.setIsMediaUploading(true)

    if (this.validAddImage()) {
      await this.addImage(payload)
    }

    // ? 画像追加処理の結果に関わらずローディングはOFFにする
    this.setIsMediaUploading(false)
  }

  /**
   * 追加される画像の検証
   * ? アップロード済みのメディアが上限件数まで達している場合は追加アップロードできない
   */
  validAddImage() {
    const total_length = this.images.length + this.videos.length

    if (this.use_facebook) {
      if (this.images.length >= 10 || this.videos.length >= 1) return false
    } else if (this.use_twitter) {
      if (this.images.length >= 4 || this.videos.length >= 1) return false
    }

    if (this.use_instagram) {
      if (this.instagram_post_type === 'post' && total_length >= 10) return false
      if (this.instagram_post_type === 'story' && total_length >= 1) return false
    }

    return true
  }

  /**
   * 画像を追加する
   */
  async addImage(payload: { file: File | Blob; canva_id?: string }): Promise<boolean> {
    const media_count = this.images.length + this.videos.length

    const response = await this.postImageUpload({
      file: payload.file,
      type: 'normal'
    })

    let temp_images = this.images.map(v => v)

    if (response.data) {
      const post_image: IPostImage = {
        sequence_no: Math.max(0, media_count + 1),
        image_url: response.data.image_url,
        is_animation_gif: response.data.is_animation_gif,
        canva_id: payload.canva_id ?? null
      }

      temp_images = temp_images.concat(post_image)

      this.setImages(temp_images)
      return true
    } else if (isRequestTimeout(response.error)) {
      await this.notifyRequestTimeout()
      return false
    } else if (response.error) {
      this.showNotification({ ...response.error, type: 'error' })
      return false
    }

    this.showNotification({ title: 'アップロードに失敗しました。', type: 'error' })
    return false
  }

  /**
   * 追加される動画の検証
   * ? Facebook・Xの場合は動画を一つしかアップロードできない。ただし単一画像の差し替えの場合は許容する
   */
  validAddVideo(length: number) {
    const media_count = this.images.length + this.videos.length

    if (this.use_facebook || this.use_twitter) {
      if (length + media_count > 1) {
        return false
      }
    }

    return true
  }

  /**
   * 動画を追加する
   */
  async addVideo(payload: { file: File | Blob; sequence_no?: number }): Promise<boolean> {
    const temp_images = this.images.map(v => v)
    let temp_videos = this.videos.map(v => v)
    const temp_total_length = temp_images.length + temp_videos.length

    const sequence_no = payload.sequence_no || Math.max(0, temp_total_length + 1)

    const video = await this.uploadVideo({
      file: payload.file,
      is_change: false
    })

    if (isRequestTimeout(video.error)) {
      await this.notifyRequestTimeout()
      return false
    } else if (video.error) {
      this.showNotification({ ...video.error, type: 'error' })
      return false
    }

    if (video.data) {
      const post_video: IPostVideo = {
        sequence_no,
        video_url: video.data.video_url,
        video_title: null,
        video_thumbnail: video.data.video_thumbnail,
        video_type: video.data.video_type,
        video_size: video.data.video_size,
        video_duration: video.data.video_duration,
        video_frame_rate: video.data.video_frame_rate,
        video_audio_channels: video.data.video_audio_channels,
        video_audio_format: video.data.video_audio_format
      }

      temp_videos = temp_videos.concat(post_video)

      this.setVideos(temp_videos)
      return true
    }

    this.showNotification({ title: 'アップロードに失敗しました。', type: 'error' })
    return false
  }

  /**
   * 動画をアップロードする
   */
  async uploadVideo(payload: {
    file: File | Blob
    is_change?: boolean
  }): Promise<{ data?: IVideo; error?: any }> {
    const response = await this.postVideoUpload({
      file: payload.file,
      is_change: payload.is_change
    })

    if (response.data) {
      return { data: response.data }
    } else if (response.error) {
      return { error: response.error }
    } else {
      return { error: { title: 'アップロードに失敗しました。', type: 'error' } }
    }
  }

  /**
   * 「ファイルを選択」にドラッグしたとき
   */
  dragoverSelectButton(): void {
    this.$refs.media_button.classList.add('drag')
  }

  /**
   * 「ファイルを選択」のドラッグを外したとき
   */
  dragleaveSelectButton(): void {
    this.$refs.media_button.classList.remove('drag')
  }

  /**
   * 「ファイルを選択」にドロップしたとき
   */
  async dropSelectButton(event: DragEvent): Promise<void> {
    TrackingService.sendEvent('click:投稿作成|メディア挿入|ファイル選択')

    const files = event.dataTransfer ? Array.from(event.dataTransfer.files) : []

    this.dragleaveSelectButton()

    await this.uploadMedia(files)
  }

  /**
   * 「追加」にドラッグしたとき
   */
  dragoverAddButton(): void {
    this.$refs.media_add_button.classList.add('drag')
  }

  /**
   * 「追加」のドラッグを外したとき
   */
  dragleaveAddButton(): void {
    this.$refs.media_add_button.classList.remove('drag')
  }

  /**
   * 「追加」にドロップしたとき
   */
  async dropAddButton(event: DragEvent): Promise<void> {
    const files = event.dataTransfer ? Array.from(event.dataTransfer.files) : []

    this.dragleaveAddButton()

    await this.uploadMedia(files)
  }

  /**
   * メディアを初期化する
   */
  clearMedia(): void {
    this.setType('text')
    this.setImages([])
    this.setVideos([])
  }

  /**
   * メディアを選択したとき
   */
  selectedMedia(index: number): void {
    this.current_index = index
  }

  /**
   * メディアの順番を変更したとき
   */
  changeSortMedia(drag: DraggableDragEvent): void {
    this.current_index = drag.moved.newIndex
  }

  /**
   * メディアを削除する
   */
  removeMedia(index: number): void {
    if (this.current_index === index) this.current_index = 0
    if (this.current_index > index) this.current_index--

    const target = this.media[index]

    const images = this.images
      .filter(v => v.sequence_no !== target.sequence_no)
      .map<IPostImage>(v => ({
        ...v,
        sequence_no: target.sequence_no < v.sequence_no ? v.sequence_no - 1 : v.sequence_no
      }))
      .sort((a, b) => a.sequence_no - b.sequence_no)

    const videos = this.videos
      .filter(v => v.sequence_no !== target.sequence_no)
      .map<IPostVideo>(v => ({
        ...v,
        sequence_no: target.sequence_no < v.sequence_no ? v.sequence_no - 1 : v.sequence_no
      }))
      .sort((a, b) => a.sequence_no - b.sequence_no)

    this.setImages(images)
    this.setVideos(videos)

    this.setType(this.setCreatePostType())
  }

  /**
   * メディアを変更する
   */
  async replaceMedia(): Promise<void> {
    const input = this.$refs.replace_file

    const files = input.files ? Array.from(input.files) : []

    if (!files.length) return

    const [file] = files

    // ? Facebook, Xは動画を1つまでしかアップロードできない
    if (this.use_facebook || this.use_twitter) {
      if (file.type.match('video/*') && !this.validAddVideo(0)) {
        return this.showNotification({
          title: 'アップロードに失敗しました。',
          message: '複数メディア投稿の場合は、画像のみアップロード可能です。',
          type: 'error'
        })
      }
    }

    this.setIsMediaUploading(true)

    let media: Media[] = []

    let temp_images = this.images.map(v => v)
    let temp_videos = this.videos.map(v => v)

    if (file.type.match('image/*')) {
      if (this.use_tiktok) {
        this.showNotification({
          title: 'アップロードに失敗しました。',
          message: 'TikTokは動画のみアップロード可能です。',
          type: 'error'
        })
        this.setIsMediaUploading(false)

        return
      }

      const response = await this.postImageUpload({
        file,
        type: 'normal',
        is_change: true
      })

      if (response.data) {
        media = this.media.map<Media>((medium, i) => {
          const media_type: MediaType = 'image'

          const changed_media: Media = {
            media_type,
            media_url: response.data.image_url,
            media_thumbnail_url: response.data.image_url,
            sequence_no: medium.sequence_no,
            sns_types: medium.sns_types
          }

          return this.current_index === i ? changed_media : medium
        })

        const post_image: IPostImage = {
          sequence_no: this.media[this.current_index].sequence_no,
          image_url: response.data.image_url,
          is_animation_gif: response.data.is_animation_gif,
          canva_id: null
        }

        temp_images = temp_images.concat(post_image)
      } else if (isRequestTimeout(response.error)) {
        await this.notifyRequestTimeout()
      } else if (response.error) {
        this.showNotification({ ...response.error, type: 'error' })
      } else {
        this.showNotification({ title: 'アップロードに失敗しました。', type: 'error' })
      }
    } else if (file.type.match('video/*')) {
      const sequence_no = this.media[this.current_index].sequence_no

      const video = await this.uploadVideo({
        file,
        is_change: true
      })

      if (isRequestTimeout(video.error)) {
        await this.notifyRequestTimeout()
      } else if (video.error) {
        this.showNotification({ ...video.error, type: 'error' })
      }

      if (video.data) {
        // ? 以前の入力が残っている場合は引き継ぐ
        const video_title =
          sequence_no === 1 && this.use_facebook && this.video ? this.video.video_title : null

        const post_video: IPostVideo = {
          sequence_no,
          video_url: video.data.video_url,
          video_title,
          video_thumbnail: video.data.video_thumbnail,
          video_type: video.data.video_type,
          video_size: video.data.video_size,
          video_duration: video.data.video_duration,
          video_frame_rate: video.data.video_frame_rate,
          video_audio_channels: video.data.video_audio_channels,
          video_audio_format: video.data.video_audio_format
        }

        media = this.media.map<Media>((meduim, index) => {
          const media_type: MediaType = 'video'

          const changed_media: Media = {
            media_type,
            media_url: post_video.video_url,
            sequence_no: meduim.sequence_no,
            sns_types: meduim.sns_types
          }

          if (post_video.video_thumbnail) {
            changed_media.media_thumbnail_url = post_video.video_thumbnail
          }

          return this.current_index === index ? changed_media : meduim
        })

        temp_videos = temp_videos.concat(post_video)
      }
    }

    if (media.length) {
      const images = this.getImagesFromMedia(media, temp_images)
      const videos = this.getVideosFromMedia(media, temp_videos)

      this.setImages(images)
      this.setVideos(videos)

      this.setType(this.setCreatePostType())
    }

    this.setIsMediaUploading(false)

    input.value = ''
  }

  /**
   * 画像編集の保存
   */
  async saveImageEditor(blob: Blob): Promise<void> {
    this.setIsMediaUploading(true)

    const response = await this.postImageUpload({
      file: blob,
      type: 'normal'
    })

    if (response.data) {
      const target = this.media[this.current_index]

      const images = this.images.map(image =>
        target.sequence_no === image.sequence_no
          ? {
              sequence_no: image.sequence_no,
              image_url: response.data.image_url,
              is_animation_gif: response.data.is_animation_gif,
              canva_id: image.canva_id
            }
          : image
      )

      this.setImages(images)
    } else if (isRequestTimeout(response.error)) {
      await this.notifyRequestTimeout()
    } else if (response.error) {
      this.showNotification({ ...response.error, type: 'error' })
    } else {
      this.showNotification({ title: 'アップロードに失敗しました。', type: 'error' })
    }

    this.setIsMediaUploading(false)
  }

  /**
   * Canvaのダイアログを開く
   */
  async openCanvaEditor() {
    const media = this.media[this.current_index]
    const image = this.images.find(v => v.sequence_no === media.sequence_no)

    if (!image) return

    CanvaService.editDesign({
      editor: {
        publishLabel: i18n.t('編集を終了')
      },
      design: {
        id: image.canva_id
      },
      onDesignPublish: async (options: { designId: string; exportUrl: string }) => {
        this.setIsMediaUploading(true)

        const blob = await getBlobData(options.exportUrl)

        const response = await this.postImageUpload({
          file: blob,
          type: 'normal'
        })

        if (response.data) {
          const images = this.images.map(v =>
            v.sequence_no === image.sequence_no
              ? {
                  sequence_no: v.sequence_no,
                  image_url: response.data.image_url,
                  is_animation_gif: response.data.is_animation_gif,
                  canva_id: options.designId
                }
              : v
          )

          this.setImages(images)
        } else if (isRequestTimeout(response.error)) {
          await this.notifyRequestTimeout()
        } else if (response.error) {
          this.showNotification({ ...response.error, type: 'error' })
        } else {
          this.showNotification({ title: 'アップロードに失敗しました。', type: 'error' })
        }

        this.setIsMediaUploading(false)
      }
    })
  }

  /**
   * サムネイルを変更する ※Facebookのみ
   */
  async changeThumbnail(value: string): Promise<void> {
    switch (value) {
      case 'capture':
        TrackingService.sendEvent('click:投稿作成|メディア挿入>FB|サムネイル変更:動画キャプチャ')

        this.$refs.VideoThumbnailDialog.open({ video_url: this.video?.video_url ?? '' })
        break

      case 'upload':
        TrackingService.sendEvent('click:投稿作成|メディア挿入>FB|サムネイル変更:画像選択')

        this.$refs.thumbnail_image.click()
        break

      case 'stockphoto':
        TrackingService.sendEvent('click:投稿作成|メディア挿入>FB|サムネイル変更:ストックフォト')

        this.$refs.StockphotoDialog.open()
        break

      case 'collage':
        TrackingService.sendEvent('click:投稿作成|メディア挿入>FB|サムネイル変更:コラージュ画像')

        this.$refs.PostCreateCollageDialog.open()
        break
    }
  }

  /**
   * 動画キャプチャからサムネイルを生成する
   */
  async changeThumbnailFromCapture(blob: Blob): Promise<void> {
    if (blob === null) {
      return this.showNotification({ title: 'サムネイル作成に失敗しました。', type: 'error' })
    }

    this.setIsMediaUploading(true)

    const response = await this.postImageUpload({
      file: blob,
      type: 'normal',
      is_thumbnail: true
    })

    if (response.data) {
      const videos = this.videos.map<IPostVideo>(v =>
        v.sequence_no === 1 ? { ...v, video_thumbnail: response.data.image_url } : v
      )

      this.setVideos(videos)
    } else if (isRequestTimeout(response.error)) {
      await this.notifyRequestTimeout()
    } else if (response.error) {
      this.showNotification({ ...response.error, type: 'error' })
    } else {
      this.showNotification({ title: 'サムネイル保存に失敗しました。', type: 'error' })
    }

    this.setIsMediaUploading(false)
  }

  /**
   * サムネイルを画像に変更する ※Facebookのみ
   */
  async changeThumbnailImage(): Promise<void> {
    const input = this.$refs.thumbnail_image

    const files = input.files ? Array.from(input.files) : []

    if (!files.length) return

    this.setIsMediaUploading(true)

    const response = await this.postImageUpload({
      file: files[0],
      type: 'normal',
      is_thumbnail: true
    })

    if (response.data && !response.data.is_animation_gif) {
      const videos = this.videos.map<IPostVideo>(v =>
        v.sequence_no === 1 ? { ...v, video_thumbnail: response.data.image_url } : v
      )

      this.setVideos(videos)
    } else if (isRequestTimeout(response.error)) {
      await this.notifyRequestTimeout()
    } else if (response.error) {
      this.showNotification({ ...response.error, type: 'error' })
    } else if (response.data.is_animation_gif) {
      this.showNotification({
        title: 'サムネイル保存に失敗しました。',
        message: 'サムネイルにアニメーションGIFは設定できません。',
        type: 'error'
      })
    } else {
      this.showNotification({ title: 'サムネイル保存に失敗しました。', type: 'error' })
    }

    this.setIsMediaUploading(false)

    input.value = ''
  }

  /**
   * サムネイルをストックフォトに変更する ※Facebookのみ
   */
  async changeThumbnailStockphoto(thumbnail: string): Promise<void> {
    const videos = this.videos.map(v =>
      v.sequence_no === 1 ? { ...v, video_thumbnail: thumbnail } : v
    )

    this.setVideos(videos)
  }

  /**
   * サムネイルをコラージュに変更する ※Facebookのみ
   */
  async changeThumbnailCollage(blob: Blob): Promise<void> {
    this.setIsMediaUploading(true)

    const response = await this.postImageUpload({
      file: blob,
      type: 'normal',
      is_thumbnail: true
    })

    if (response.data) {
      const videos = this.videos.map(v =>
        v.sequence_no === 1 ? { ...v, video_thumbnail: response.data.image_url } : v
      )

      this.setVideos(videos)
    } else if (isRequestTimeout(response.error)) {
      await this.notifyRequestTimeout()
    } else if (response.error) {
      this.showNotification({ ...response.error, type: 'error' })
    } else {
      this.showNotification({ title: 'サムネイル保存に失敗しました。', type: 'error' })
    }

    this.setIsMediaUploading(false)
  }

  /**
   * フィードでもシェアを設定
   * @description Instagramでリール動画の場合のみ
   */
  async switchInstagramShareToFeed(payload: boolean) {
    TrackingService.sendEvent('click:投稿作成|メディア挿入>IG|フィードシェア')

    await this.changeInstagramShareToFeed(payload)
  }

  private notifyRequestTimeout(): Promise<void> {
    return this.showNotification({
      title: 'アップロードに失敗しました。',
      message: '時間をおいて再度お試しください。',
      type: 'error'
    })
  }
}
