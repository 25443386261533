import moment from 'moment-timezone'
import twttr from 'twitter-text'
import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Box from '@/client/components-old/atoms/Box'
import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Dialog from '@/client/components-old/atoms/Dialog'
import ExternalLink from '@/client/components-old/atoms/ExternalLink'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import InputNumber from '@/client/components-old/atoms/InputNumber'
import Label from '@/client/components-old/atoms/Label'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AccountSelect from '@/client/components-old/molecules/AccountSelect'
import InputKeyword from '@/client/components-old/molecules/InputKeyword'
import { IRootState } from '@/client/store/global'
import { IState as IAccountState } from '@/client/store/modules/accounts'
import { IState as IHashtagState } from '@/client/store/modules/instagram_hashtag'
import API from '@/client/utils/api'
import {
  IGetInstagramHashtagsParams,
  IGetInstagramHashtagsResponse
} from '@/client/utils/api/instagram_hashtags'
import i18n from '@/client/utils/i18n'

const accounts = namespace('accounts')
const instagram_hashtag = namespace('instagram_hashtag')
const notification = namespace('notification')

type TInstagramHashtagDialogOptions = {
  hashtag_id: number
}

@Component({
  name: 'InstagramHashtagDialog',
  components: {
    Button,
    ButtonLink,
    Box,
    Dialog,
    ExternalLink,
    Icon,
    Input,
    InputNumber,
    Label,
    Message,
    Panel,
    Select,
    SwitchButton,
    Tooltip,
    AccountSelect,
    InputKeyword
  }
})
export default class InstagramHashtagDialog extends Vue {
  @State('user') user: IRootState['user']
  @State('project') project: IRootState['project']
  @accounts.State('instagram') instagram_accounts: IAccountState['instagram']
  @instagram_hashtag.State('api_settings') api_settings: IHashtagState['api_settings']
  @instagram_hashtag.Action('createInstagramHashtag') createInstagramHashtag
  @instagram_hashtag.Action('updateInstagramHashtag') updateInstagramHashtag
  @instagram_hashtag.Action('deleteInstagramHashtag') deleteInstagramHashtag
  @notification.Action('showNotification') showNotification

  visible = false
  is_submit = false
  is_error_hashtag_name = false

  hashtag_id: number | null = null

  form: {
    name: string
    start_date: string | null
    end_date: string | null
    hashtag: string[]
    and_keywords: string[]
    or_keywords: string[]
    not_keywords: string[]
    account_id: string
    is_alert: boolean
    alert_post_count: number
  } = {
    name: '',
    start_date: null,
    end_date: null,
    hashtag: [],
    and_keywords: [],
    or_keywords: [],
    not_keywords: [],
    account_id: '',
    is_alert: false,
    alert_post_count: 1
  }

  temp_form: {
    name: string
    start_date: string | null
    end_date: string | null
    hashtag: string[]
    and_keywords: string[]
    or_keywords: string[]
    not_keywords: string[]
    account_id: string
    is_alert: boolean
    alert_post_count: number
  }

  temp_date: {
    start_date: string
  } = {
    start_date: null
  }

  get is_edit() {
    return Boolean(this.hashtag_id)
  }

  get title() {
    return this.is_edit ? 'キーワード分類の編集' : 'キーワード分類の追加'
  }

  get start_date_options() {
    const start_date = moment().isBefore(this.temp_date.start_date)
      ? moment()
      : this.temp_date.start_date

    const end_date = this.form.end_date

    return {
      disabledDate(time: Date): boolean {
        if (moment().isBefore(start_date, 'day')) {
          return !moment(time).isBetween(moment(), end_date, 'day', '[]')
        }

        if (end_date) {
          return !moment(time).isBetween(start_date, end_date, 'day', '[]')
        }

        return moment(time).isBefore(start_date, 'day')
      }
    }
  }

  get end_date_options() {
    const start_date = moment().isAfter(this.form.start_date) ? moment() : this.form.start_date

    return {
      disabledDate(time: Date): boolean {
        return moment(time).isBefore(start_date, 'day')
      }
    }
  }

  get account_options() {
    return this.instagram_accounts.map(v => ({
      id: v.id,
      name: v.name,
      img: v.image_url,
      type: 'instagram'
    }))
  }

  get alert_post_count() {
    return this.form.is_alert ? this.form.alert_post_count : 0
  }

  get alert_post_count_max() {
    return this.project.keyword_hashtag_max_post_count
  }

  get is_no_hashtag() {
    return !this.form.hashtag[0]
  }

  get is_error_hashtag_content() {
    if (!this.form.hashtag[0]) return false

    const hashtags = twttr.extractHashtagsWithIndices(this.form.hashtag[0])

    return !hashtags[0] || '#' + hashtags[0].hashtag !== this.form.hashtag[0]
  }

  get is_error_account_expired() {
    const account = this.instagram_accounts.find(v => v.id === this.form.account_id)

    return account ? account.token_expired : false
  }

  get is_error_account_canceled() {
    const account = this.instagram_accounts.find(v => v.id === this.form.account_id)

    return account ? account.is_canceled : false
  }

  get is_error_account_deleted() {
    return !this.instagram_accounts.map(v => v.id).includes(this.form.account_id)
  }

  get is_disabled() {
    return (
      !this.form.name.trim() ||
      !this.form.start_date ||
      !this.form.account_id ||
      this.is_no_hashtag ||
      this.is_error_hashtag_content ||
      this.is_error_hashtag_name ||
      this.is_error_account_deleted ||
      this.is_submit
    )
  }

  get is_form_changed() {
    return (
      this.form.name !== this.temp_form.name ||
      this.form.start_date !== this.temp_form.start_date ||
      this.form.end_date !== this.temp_form.end_date ||
      this.form.hashtag.toString() !== this.temp_form.hashtag.toString() ||
      this.form.and_keywords.toString() !== this.temp_form.and_keywords.toString() ||
      this.form.or_keywords.toString() !== this.temp_form.or_keywords.toString() ||
      this.form.not_keywords.toString() !== this.temp_form.not_keywords.toString() ||
      this.form.account_id !== this.temp_form.account_id ||
      this.form.is_alert !== this.temp_form.is_alert ||
      this.form.alert_post_count !== this.temp_form.alert_post_count
    )
  }

  /**
   * ダイアログを開く
   */
  async open(options: TInstagramHashtagDialogOptions) {
    this.hashtag_id = options ? options.hashtag_id : null

    if (!this.hashtag_id) {
      this.form.name = ''
      this.form.start_date = moment().format('YYYY-MM-DD')
      this.form.end_date = null
      this.form.hashtag = []
      this.form.and_keywords = []
      this.form.or_keywords = []
      this.form.not_keywords = []
      this.form.account_id = this.instagram_accounts[0] ? this.instagram_accounts[0].id : ''
      this.form.is_alert = false
      this.form.alert_post_count = 1

      this.temp_date.start_date = this.form.start_date
      this.temp_form = { ...this.form }

      this.visible = true

      return
    }

    const params: IGetInstagramHashtagsParams = {
      project_id: this.project.id
    }

    const response = await API.get<IGetInstagramHashtagsResponse>(
      `instagram_hashtags/${this.hashtag_id}`,
      { params }
    )

    if (response.data.data) {
      const in_hashtag = response.data.data

      this.form.name = in_hashtag.name
      this.form.start_date = in_hashtag.start_date
      this.form.end_date = in_hashtag.end_date
      this.form.hashtag = ['#' + in_hashtag.hashtag]
      this.form.and_keywords = in_hashtag.and_keywords
      this.form.or_keywords = in_hashtag.or_keywords
      this.form.not_keywords = in_hashtag.not_keywords
      this.form.account_id = in_hashtag.in_account.account_id
      this.form.is_alert = Boolean(in_hashtag.alert_post_count)
      this.form.alert_post_count = this.form.is_alert ? in_hashtag.alert_post_count : 1

      this.temp_date.start_date = this.form.start_date
      this.temp_form = { ...this.form }

      this.visible = true

      return
    }

    if (response.data.error && response.data.error.type === 'NOT_EXIST') {
      this.showNotification({
        title: '対象のキーワード分類はすでに削除されています。',
        type: 'error'
      })

      await this.deleteInstagramHashtag(this.hashtag_id)

      return
    }

    this.showNotification({
      title: 'Instagramハッシュタグの取得に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * ダイアログを閉じる
   */
  close() {
    if (this.is_form_changed) {
      const message =
        i18n.t('編集内容が保存されていません。') +
        '\n' +
        i18n.t('ダイアログを閉じてよろしいですか？')

      const is_confirm = confirm(message)

      if (!is_confirm) {
        this.visible = true
        return
      }
    }

    this.visible = false
  }

  /**
   * ハッシュタグを保存
   */
  async submit() {
    if (this.is_edit) {
      await this.updateHashtag()
    } else {
      await this.createHashtag()
    }
  }

  /**
   * キーワードの作成
   */
  async createHashtag() {
    if (this.is_disabled) return

    const payload = {
      account_id: this.form.account_id,
      name: this.form.name,
      start_date: this.form.start_date || null,
      end_date: this.form.end_date || null,
      hashtag: this.form.hashtag[0].replace('#', ''),
      and_keywords: this.form.and_keywords,
      or_keywords: this.form.or_keywords,
      not_keywords: this.form.not_keywords,
      alert_post_count: this.alert_post_count ? this.alert_post_count : 0
    }

    this.is_submit = true

    const result = await this.createInstagramHashtag(payload)

    this.is_submit = false

    if (result.data) {
      this.showNotification({
        title: 'Instagramハッシュタグを追加しました。'
      })

      this.visible = false

      return
    }

    if (result.error && result.error.type === 'DUPLICATE') {
      this.showNotification({
        title: '入力したハッシュタグはすでに登録されています。',
        message: '登録済みのハッシュタグは指定できません。',
        type: 'error'
      })

      return
    }

    if (result.error && result.error.type === 'CATEGORY_NAME_DUPLICATE') {
      this.showNotification({
        title: '入力した分類名はすでに登録されています。',
        message: '登録済みの分類名は指定できません。',
        type: 'error'
      })

      return
    }

    if (result.error && result.error.type === 'MAX_INSTAGRAM_HASHTAG_OVER') {
      this.showNotification({
        title: '登録数の上限数を超えています。',
        message: '上限数を変更したい場合は、画面右下のサポートチャットからお問い合わせください。',
        type: 'error'
      })

      this.visible = false

      return
    }

    if (result.error && result.error.type === 'INSTAGRAM_HASHTAG_ID_NOT_AVAILABLE') {
      this.showNotification({
        title: '存在しないハッシュタグのため収集ができません。',
        type: 'error'
      })

      return
    }

    if (result.error && result.error.type === 'INSTAGRAM_HASHTAG_ID_RATE_LIMIT') {
      this.showNotification({
        title:
          '7日間に検索できるハッシュタグ数の上限を超えているため、ハッシュタグを追加できません。',
        message: '連携アカウントを変更するか、7日後に再度ハッシュタグの追加をお試しください。',
        type: 'error'
      })

      return
    }

    this.showNotification({
      title: 'Instagramハッシュタグの追加に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * キーワードの変更
   */
  async updateHashtag() {
    if (this.is_disabled) return

    const payload = {
      hashtag_id: this.hashtag_id,
      account_id: this.form.account_id,
      name: this.form.name,
      start_date: this.form.start_date || null,
      end_date: this.form.end_date || null,
      hashtag: this.form.hashtag[0].replace('#', ''),
      and_keywords: this.form.and_keywords,
      or_keywords: this.form.or_keywords,
      not_keywords: this.form.not_keywords,
      alert_post_count: this.alert_post_count ? this.alert_post_count : 0
    }

    this.is_submit = true

    const result = await this.updateInstagramHashtag(payload)

    this.is_submit = false

    if (result.data) {
      this.showNotification({
        title: 'Instagramハッシュタグを変更しました。'
      })

      this.visible = false

      return
    }

    if (result.error && result.error.type === 'CATEGORY_NAME_DUPLICATE') {
      this.showNotification({
        title: '入力した分類名はすでに登録されています。',
        message: '登録済みの分類名は指定できません。',
        type: 'error'
      })

      return
    }

    if (result.error && result.error.type === 'MAX_INSTAGRAM_HASHTAG_OVER') {
      this.showNotification({
        title: '登録数の上限数を超えています。',
        message: '上限数を変更したい場合は、画面右下のサポートチャットからお問い合わせください。',
        type: 'error'
      })

      return
    }

    if (result.error && result.error.type === 'INSTAGRAM_HASHTAG_ID_NOT_AVAILABLE') {
      this.showNotification({
        title: '存在しないハッシュタグのため収集ができません。',
        type: 'error'
      })

      return
    }

    if (result.error && result.error.type === 'INSTAGRAM_HASHTAG_ID_RATE_LIMIT') {
      this.showNotification({
        title:
          '7日間に検索できるハッシュタグ数の上限を超えているため、ハッシュタグを追加できません。',
        message: '連携アカウントを変更するか、7日後に再度ハッシュタグの追加をお試しください。',
        type: 'error'
      })

      return
    }

    this.showNotification({
      title: 'Instagramハッシュタグの変更に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * キーワードの削除
   */
  async deleteHashtag() {
    if (this.is_submit) return

    let message = ''
    message += i18n.t('キーワード分類を削除してよろしいですか？') + '\n'
    message += i18n.t('この操作は取り消しできません。') + '\n'
    message += i18n.t('収集件数は取り消されません。')

    const is_confirm = confirm(message)

    if (!is_confirm) return

    this.is_submit = true

    const result = await this.deleteInstagramHashtag(this.hashtag_id)

    this.is_submit = false

    if (result.data) {
      this.showNotification({
        title: 'Instagramハッシュタグを削除しました。'
      })

      this.visible = false

      return
    }

    if (result.error && result.error.type === 'NOT_EXISTS') {
      this.showNotification({
        title: '対象のキーワード分類はすでに削除されています。',
        type: 'error'
      })

      this.visible = false

      return
    }

    this.showNotification({
      title: 'Instagramハッシュタグの削除に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * キーワード分類名の重複チェック
   */
  doubleCheckCategoryName() {
    if (
      this.api_settings
        .filter(v => v.id !== this.hashtag_id)
        .map(v => v.name)
        .includes(this.form.name.trim())
    ) {
      this.is_error_hashtag_name = true
    } else {
      this.is_error_hashtag_name = false
    }
  }
}
