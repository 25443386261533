import i18next from 'i18next'

import * as facebook_country from './facebook_country.json'
import * as facebook_language from './facebook_language.json'
import * as facebook_region from './facebook_region.json'
import * as help from './help.json'
import * as language from './language.json'
import * as locale from './locale.json'
import * as tiktok_country from './tiktok_country.json'

const ja = { translation: {} }
const en = { translation: {} }

Object.keys(facebook_country).forEach(key => {
  ja.translation[key] = key
  if (facebook_country[key].en) {
    en.translation[key] = facebook_country[key].en
  }
})

Object.keys(facebook_language).forEach(key => {
  ja.translation[key] = key
  if (facebook_language[key].en) {
    en.translation[key] = facebook_language[key].en
  }
})

Object.keys(facebook_region).forEach(key => {
  ja.translation[key] = key
  if (facebook_region[key].en) {
    en.translation[key] = facebook_region[key].en
  }
})

Object.keys(help.ja).forEach(key => {
  ja.translation[key] = help.ja[key]
})

Object.keys(help.en).forEach(key => {
  en.translation[key] = help.en[key]
})

Object.keys(language).forEach(key => {
  ja.translation[key] = language[key].ja
  en.translation[key] = language[key].en
})

Object.keys(locale).forEach(key => {
  ja.translation[key] = key
  if (locale[key].en) {
    en.translation[key] = locale[key].en
  }
})

Object.keys(tiktok_country).forEach(key => {
  ja.translation[key] = tiktok_country[key].ja
  en.translation[key] = tiktok_country[key].en
})

const i18n = i18next.init({
  fallbackLng: 'ja',
  resources: { ja, en },
  keySeparator: '>',
  nsSeparator: '|',
  interpolation: {
    prefix: '[[',
    suffix: ']]',
    escapeValue: false
  }
})

export default i18n
