import { Component, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import TextareaEmoji from '@/client/components-old/molecules/TextareaEmoji'
import { IState as IPostTemplatesState } from '@/client/store/modules/post_templates'
import { IPostTemplate } from '@/client/utils/api/post_templates'

const post_templates = namespace('post_templates')
const notification = namespace('notification')

@Component({
  name: 'PostTemplateDialog',
  components: {
    draggable,
    Button,
    Dialog,
    Flex,
    Icon,
    Input,
    Message,
    Tooltip,
    TextareaEmoji
  }
})
export default class PostTemplateDialog extends Vue {
  @post_templates.State('templates') templates: IPostTemplatesState['templates']
  @post_templates.Action('createTemplate') createTemplate
  @post_templates.Action('updateTemplate') updateTemplate
  @post_templates.Action('removeTemplate') removeTemplate
  @post_templates.Action('syncTemplateOrder') syncTemplateOrder
  @notification.Action('showNotification') showNotification

  visible = false
  create_mode = false
  create_template_name = ''
  create_template_content = ''
  edit_template_id = 0
  edit_template_name = ''
  edit_template_content = ''

  get edit_mode() {
    return Boolean(this.edit_template_id)
  }

  get create_disabled() {
    const name = this.create_template_name.trim()
    const content = this.create_template_content.trim()
    return !name.length || !content.length
  }

  get edit_disabled() {
    const name = this.edit_template_name.trim()
    const content = this.edit_template_content.trim()
    return !name.length || !content.length
  }

  get is_editable() {
    return this.create_mode || this.edit_mode
  }

  /**
   * ダイアログを開く
   * @returns {void}
   */
  open(): void {
    this.visible = true
    this.create_mode = false
    this.create_template_name = ''
    this.create_template_content = ''
    this.edit_template_id = 0
    this.edit_template_name = ''
    this.edit_template_content = ''
  }

  /**
   * ダイアログを非表示
   * @returns {void}
   */
  hide(): void {
    this.visible = false
  }

  /**
   * 作成モードの開始
   * @returns {void}
   */
  startCreateMode(): void {
    this.create_mode = true
  }

  /**
   * 作成モードの終了
   * @returns {void}
   */
  cancelCreateMode(): void {
    this.create_mode = false
  }

  /**
   * 編集モードのチェック
   * @param {number} template_id
   * @returns {boolean} 真偽値
   */
  checkEditMode(template_id: number): boolean {
    return this.edit_template_id === template_id
  }

  /**
   * 編集フォームを表示
   * @param {number} template_id
   * @returns {void}
   */
  startEditMode(template_id: number): void {
    const template = this.templates.find(v => v.id === template_id)

    if (!template) return

    this.edit_template_id = template.id
    this.edit_template_name = template.name
    this.edit_template_content = template.content
  }

  /**
   * 編集モードをキャンセル
   * @returns {void}
   */
  cancelEditMode(): void {
    this.edit_template_id = 0
    this.edit_template_name = ''
    this.edit_template_content = ''
  }

  /**
   * 作成フォームの送信
   * @returns {Promise<void>} void
   */
  async submitCreateForm(): Promise<void> {
    this.create_template_name = this.create_template_name.trim()
    this.create_template_content = this.create_template_content.trim()

    if (!this.create_template_name || !this.create_template_content) return

    const result = await this.createTemplate({
      name: this.create_template_name,
      content: this.create_template_content
    })

    if (result.data) {
      this.create_template_name = ''
      this.create_template_content = ''

      this.cancelCreateMode()

      return this.showNotification({ title: 'テンプレートを保存しました。' })
    }

    if (result.error && result.error.type === 'DUPLICATE') {
      return this.showNotification({
        title: '同じテンプレート名が存在するため、他のテンプレート名を入力してください。',
        type: 'error'
      })
    }

    this.showNotification({ title: 'テンプレートの保存に失敗しました。', type: 'error' })
  }

  /**
   * 編集フォームの送信
   * @returns {Promise<void>} void
   */
  async submitEditForm(): Promise<void> {
    if (!this.edit_template_id) return

    this.edit_template_name = this.edit_template_name.trim()
    this.edit_template_content = this.edit_template_content.trim()

    if (!this.edit_template_name || !this.edit_template_content) return

    const result = await this.updateTemplate({
      template_id: this.edit_template_id,
      name: this.edit_template_name,
      content: this.edit_template_content
    })

    if (result.data) {
      this.showNotification({
        title: this.$options.filters.translate('[[name]]を変更しました。', {
          name: this.edit_template_name
        })
      })

      this.cancelEditMode()

      return
    }

    if (result && result.error && result.error.type === 'DUPLICATE') {
      return this.showNotification({
        title: '同じテンプレート名が存在するため、他のテンプレート名を入力してください。',
        type: 'error'
      })
    }

    if (result && result.error && result.error.type === 'NOT_EXISTS') {
      this.cancelEditMode()

      return this.showNotification({
        title: 'テンプレートがすでに削除されています。',
        type: 'error'
      })
    }

    this.showNotification({ title: 'テンプレートの変更に失敗しました。', type: 'error' })
  }

  /**
   * テンプレート削除のチェック
   * @param {number} template_id テンプレートID
   * @returns {Promise<void>} void
   */
  async checkRemoveTemplate(template_id: number): Promise<void> {
    const template = this.templates.find(v => v.id === template_id)

    if (!template) return

    const confirm = window.confirm(
      this.$options.filters.translate('[[name]]を削除してよろしいですか？', {
        name: template.name
      })
    )

    if (!confirm) return

    const result = await this.removeTemplate({ template_id: template.id })

    if (result.data) {
      return this.showNotification({
        title: this.$options.filters.translate('[[name]]を削除しました。', { name: template.name })
      })
    }

    if (result.error && result.error.type === 'NOT_EXISTS') {
      return this.showNotification({
        title: 'テンプレートがすでに削除されています。',
        type: 'error'
      })
    }

    this.showNotification({ title: 'テンプレートの削除に失敗しました。', type: 'error' })
  }

  /**
   * ドラッグ＆ドロップで変更した場合
   * @param {IPostTemplate[]} payload
   * @returns {Promise<void>} void
   */
  async changeDraggable(payload: IPostTemplate[]): Promise<void> {
    const result = await this.syncTemplateOrder(payload)

    if (result.data) {
      return this.showNotification({ title: 'テンプレートの順番を保存しました。' })
    }

    this.showNotification({ title: 'テンプレートの順番の保存に失敗しました。', type: 'error' })
  }
}
