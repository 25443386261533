const scope = [
  'business_management',
  'pages_manage_metadata',
  'pages_read_engagement',
  'pages_read_user_content',
  'pages_manage_posts',
  'pages_manage_engagement',
  'pages_messaging',
  'read_insights',
  'instagram_basic',
  'instagram_content_publish',
  'instagram_manage_insights',
  'instagram_manage_comments'
]

export function api<T = any>(path: string) {
  return new Promise<T>(resolve => {
    FB.api<T>(path, response => resolve(response))
  })
}

export function getLoginStatus() {
  return new Promise<fb.StatusResponse>(resolve => {
    FB.getLoginStatus(response => resolve(response), true)
  })
}

export function login() {
  return new Promise<fb.StatusResponse>(resolve => {
    FB.login(response => resolve(response), { scope: scope.join() })
  })
}
