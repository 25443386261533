import API from '@/client/utils/api'
import {
  IGetPostCategoriesParams,
  IGetPostCategoriesResponse
} from '@/client/utils/api/post_categories'

export async function getPostCategories(query: IGetPostCategoriesParams) {
  const response = await API.get<IGetPostCategoriesResponse>('post_categories', {
    params: query
  })

  return response.data
}
