import Vue from 'vue'
import VueRouter from 'vue-router'

import { sns_analytics } from '@/client/features/sns_analytics/router'
import { analytics_tt } from '@/client/features/tiktok_analytics/router'
import { report_tt } from '@/client/features/tiktok_analytics_report/router'
import { twitter_viral } from '@/client/features/twitter_viral/router'
import { CrispService, TrackingService } from '@/client/services'
import { store } from '@/client/store'
import i18n from '@/client/utils/i18n'
import { checkPagePermissions } from '@/client/utils/permissions'
import title from '@/client/utils/title'

import { analytics_fb } from './routes/analytics_fb'
import { analytics_in } from './routes/analytics_in'
import { analytics_tw } from './routes/analytics_tw'
import { bitly_callback } from './routes/bitly_callback'
import { comparison_fb } from './routes/comparison_fb'
import { comparison_in } from './routes/comparison_in'
import { comparison_tw } from './routes/comparison_tw'
import { dashboard } from './routes/dashboard'
import { google_drive_callback } from './routes/google_drive_callback'
import { instagram_hashtag } from './routes/instagram_hashtag'
import { invitation } from './routes/invitation'
import { library_engagement_ranking } from './routes/library_engagement_ranking'
import { library_twitter_trend } from './routes/library_twitter_trend'
import { login } from './routes/login'
import { monitoring } from './routes/monitoring'
import { password_request } from './routes/password_request'
import { password_reset } from './routes/password_reset'
import { posts } from './routes/posts'
import { posts_create } from './routes/posts_create'
import { posts_detail_copy } from './routes/posts_detail_copy'
import { posts_detail_edit } from './routes/posts_detail_edit'
import { report_fb } from './routes/report_fb'
import { report_in } from './routes/report_in'
import { report_tw } from './routes/report_tw'
import { setting_billing_info } from './routes/setting_billing_info'
import { setting_group } from './routes/setting_group'
import { setting_personal } from './routes/setting_personal'
import { signup } from './routes/signup'
import { tiktok_callback } from './routes/tiktok_callback'
import { twitter_callback } from './routes/twitter_callback'
import { twitter_enterprise_callback } from './routes/twitter_enterprise_callback'

Vue.use(VueRouter)

export const router = new VueRouter({
  mode: process.env.NODE_ENV === 'test' ? 'abstract' : 'history',
  routes: [
    {
      path: '/',
      redirect: { name: 'login' }
    },
    analytics_fb,
    analytics_in,
    analytics_tw,
    analytics_tt,
    bitly_callback,
    comparison_fb,
    comparison_in,
    comparison_tw,
    dashboard,
    google_drive_callback,
    instagram_hashtag,
    invitation,
    library_engagement_ranking,
    library_twitter_trend,
    login,
    monitoring,
    password_request,
    password_reset,
    posts_create,
    posts_detail_copy,
    posts_detail_edit,
    posts,
    report_fb,
    report_in,
    report_tw,
    report_tt,
    setting_billing_info,
    setting_personal,
    setting_group,
    signup,
    sns_analytics,
    tiktok_callback,
    twitter_callback,
    twitter_enterprise_callback,
    twitter_viral,
    {
      path: '*',
      redirect: { name: 'dashboard' }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  // 招待ページの場合、どの状態でもアクセス可能な為
  if (to.name === 'invitation') return next()

  const is_login = await store.dispatch('checkSession')
  const is_auth_page = to.meta.auth || false

  // ログイン中で認証必須ページに居る場合
  if (is_login && is_auth_page) {
    const is_post_editing =
      ['posts/create', 'posts/detail/edit', 'posts/detail/copy'].includes(from.name) &&
      store.getters['post_create/is_post_change']

    if (is_post_editing) {
      const message =
        i18n.t('編集内容が保存されていません。') + '\n' + i18n.t('ページを移動しますか？')

      const is_confirm = confirm(message)

      if (!is_confirm) {
        return next(false)
      }
    }

    if (!checkPagePermissions(to.name)) {
      return next({ name: 'dashboard' })
    }

    return next()
  }

  // ログイン中で認証不要ページに居る場合
  if (is_login && !is_auth_page) {
    await store.dispatch('login')

    return next({ name: 'dashboard' })
  }

  // ログアウト中で認証必須ページに居る場合
  if (!is_login && is_auth_page) {
    await store.dispatch('notification/showNotification', {
      title: 'ログインセッションが切れました。',
      message: '恐れ入りますが、再度ログインしてセッションを有効にしてください。',
      type: 'error',
      duration: 60 * 1000
    })

    await store.dispatch('logout')

    return next({ name: 'login', query: { redirect: to.fullPath } })
  }

  // ログアウト中で認証不要ページに居る場合
  if (!is_login && !is_auth_page) {
    return next()
  }

  return next()
})

router.afterEach(to => {
  title.changePageTitle(to.meta.title)
  TrackingService.sendLoginData(to.path)
  CrispService.sendLoginData()
})

/**
 * セッション状態を確認
 */
async function checkSessionState() {
  // 招待ページの場合、どの状態でもアクセス可能な為
  if (router.currentRoute.name === 'invitation') return

  const is_login = await store.dispatch('checkSession')
  const is_auth_page = router.currentRoute.meta.auth || false

  // ログイン中で認証不要ページに居る場合
  if (is_login && !is_auth_page) {
    await store.dispatch('login')

    await router.push({ name: 'dashboard' })
  }

  // ログアウト中で認証必須ページに居る場合
  if (!is_login && is_auth_page) {
    await store.dispatch('notification/showNotification', {
      title: 'ログインセッションが切れました。',
      message: '恐れ入りますが、再度ログインしてセッションを有効にしてください。',
      type: 'error',
      duration: 60 * 1000
    })

    await store.dispatch('logout')

    await router.push({ name: 'login', query: { redirect: router.currentRoute.fullPath } })
  }
}

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible' && navigator.onLine) {
    checkSessionState()
  }
})
