import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import { IState as IStatePersonalSetting } from '@/client/store/modules/personal_setting'
import * as regex from '@/client/utils/regex'
import * as validate from '@/client/utils/validate'

interface HTMLElementEvent<T extends HTMLElement> extends Event {
  target: T
}

const personal_setting = namespace('personal_setting')
const notification = namespace('notification')

@Component({
  name: 'PersonalSettingProfile',
  components: {
    Button,
    Panel,
    Icon,
    Input,
    Select,
    Message,
    Tooltip,
    Account
  }
})
export default class PersonalSettingProfile extends Vue {
  @personal_setting.State('api_user') api_user: IStatePersonalSetting['api_user']
  @personal_setting.State('api_timezones') api_timezones: IStatePersonalSetting['api_timezones']
  @personal_setting.Action('postProfileUpload') postProfileUpload
  @personal_setting.Action('putUser') putUser
  @notification.Action('showNotification') showNotification

  name = ''
  mail = ''
  password = ''
  picture_url = ''
  picture_file: null | File = null
  language = ''
  timezone = ''

  @Watch('api_user', { immediate: true, deep: true })
  watchApiUser() {
    if (this.api_user) {
      this.name = this.api_user.name
      this.mail = this.api_user.mail
      this.picture_url = this.api_user.picture_url
      this.language = this.api_user.language
      this.timezone = this.api_user.timezone
    }
  }

  get accept() {
    return validate.IMAGE_TYPES
  }

  get disabled() {
    return (
      this.is_error_name ||
      this.is_error_mail ||
      this.is_error_mail_format ||
      this.is_error_password ||
      this.is_error_password_character ||
      this.is_error_password_required
    )
  }

  get is_error_name() {
    if (this.api_user && this.api_user.name === this.name) return false
    return this.name === ''
  }

  get is_error_mail() {
    if (this.api_user && this.api_user.mail === this.mail) return false
    return this.mail === ''
  }

  get is_error_mail_format() {
    if (this.api_user && this.api_user.mail === this.mail) return false
    return !regex.MAIL_FORMAT.test(this.mail)
  }

  get is_error_password() {
    if (this.password === '') return false
    return !(8 <= this.password.length && this.password.length <= 60)
  }

  get is_error_password_character() {
    if (this.password === '') return false
    return !regex.PASSWORD_CHARACTER.test(this.password)
  }

  get is_error_password_required() {
    if (this.password === '') return false
    return !regex.PASSWORD_REQUIRED.test(this.password)
  }

  get langugage_options() {
    return [
      { text: '日本語', value: 'ja' },
      { text: 'English', value: 'en' }
    ]
  }

  get timezones_options() {
    return this.api_timezones.map(timezone => ({
      text: timezone.name,
      value: timezone.value
    }))
  }

  /**
   * 画像をアップロードする
   */
  async uploadPicture(e: HTMLElementEvent<HTMLInputElement>): Promise<void> {
    if (e.target === null || !e.target.files || !e.target.files[0]) return

    this.showNotification({ title: '画像をアップロードしています。', loading: true })

    const response = await this.postProfileUpload(e.target.files[0])

    if (response && response.data) {
      this.picture_url = response.data.image_url

      return this.showNotification({ title: '画像をアップロードしました。' })
    }

    this.showNotification({ title: 'アップロードに失敗しました。', type: 'error' })
  }

  /**
   * プロフィールを保存する
   */
  async saveProfile(): Promise<void> {
    const params: {
      mail: string
      name: string
      password?: string
      picture_url: string
      language: string
      timezone: string
    } = {
      mail: this.mail,
      name: this.name,
      picture_url: this.picture_url,
      language: this.language,
      timezone: this.timezone
    }

    if (this.password !== '') {
      params.password = this.password
    }

    const response = await this.putUser(params)

    if (response && response.data) {
      return this.showNotification({ title: 'プロフィールを更新しました。' })
    }

    this.showNotification({
      title: 'プロフィールの更新に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }
}
