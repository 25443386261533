import moment from 'moment-timezone'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import { MIN_DATE } from '@/client/components-old/_constants/global'
import Badge from '@/client/components-old/atoms/Badge'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Select from '@/client/components-old/atoms/Select'
import Account from '@/client/components-old/molecules/Account'
import AnalyticsList from '@/client/components-old/molecules/AnalyticsList'
import ChartSingleBar from '@/client/components-old/molecules/ChartSingleBar'
import SwitchSns from '@/client/components-old/molecules/SwitchSns'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'

@Component({
  name: 'LibraryEngagementRankingTable',
  components: {
    Badge,
    Flex,
    Icon,
    Message,
    Select,
    Account,
    AnalyticsList,
    ChartSingleBar,
    SwitchSns
  }
})
export default class LibraryEngagementRankingTable extends Vue {
  @State('user') user: IRootState['user']

  @Prop({ type: Number, default: null })
  height: number

  sns_type: 'facebook' | 'twitter' | 'instagram' = 'facebook'

  category = ''
  sub_category = ''

  month: string = moment().subtract(1, 'month').format('YYYY-MM')

  categories = []
  category_options = []
  sub_category_options = []

  data = []
  table_data = []

  get is_facebook() {
    return this.sns_type === 'facebook'
  }

  get is_twitter() {
    return this.sns_type === 'twitter'
  }

  get is_instagram() {
    return this.sns_type === 'instagram'
  }

  get month_options() {
    const month_options = []
    const format = this.user.language === 'ja' ? 'YYYY年MM月' : 'YYYY-MM'

    const month = moment().subtract(1, 'month')

    while (month.isSameOrAfter(MIN_DATE, 'month')) {
      month_options.push({
        text: month.format(format),
        value: month.format('YYYY-MM')
      })

      month.subtract(1, 'month')
    }

    return month_options
  }

  /**
   * ページ表示時
   * @returns {Promise<void>} void
   */
  async created(): Promise<void> {
    await this.getCategories()
    await this.getEngagementRanking()
  }

  /**
   * エンゲージメントランキングを取得
   * @returns {Promise<void>} void
   */
  async getEngagementRanking(): Promise<void> {
    const params = {
      month: this.month,
      sns_type: this.sns_type
    }

    const { data } = await API.get('engagement_rankings', { params })
    if (!data.data) {
      this.table_data = []
      return
    }

    this.data = data.data
    this.filterDataByCategory()
  }

  /**
   * カテゴリリストを取得
   * @returns {Promise<void>} void
   */
  async getCategories(): Promise<void> {
    const { data } = await API.get('engagement_rankings/categories')
    if (!data.data) {
      return
    }

    this.categories = data.data
    this.category_options = this.categories.map(category => ({
      text: category.category,
      value: category.category
    }))
  }

  /**
   * Get sub_category by category
   * @returns {void}
   */
  changeCategory() {
    this.sub_category = ''
    this.filterDataByCategory()

    const sub_category = this.categories.find(category => category.category === this.category)
    if (!sub_category) {
      return
    }

    this.sub_category_options = sub_category.sub_categories.map(sub_category => ({
      text: sub_category,
      value: sub_category
    }))
  }

  /**
   * Filter data by category
   * @returns {void}
   */
  filterDataByCategory() {
    const category = this.category === '' ? null : this.category
    const sub_category = this.sub_category === '' ? null : this.sub_category

    const accounts = this.data.find(
      data => data.category === category && data.sub_category === sub_category
    )

    if (!accounts) {
      this.table_data = []
      return
    }

    const max_reactions = Math.max.apply(
      null,
      accounts.data.map(v => {
        switch (this.sns_type) {
          case 'facebook':
            return Number(v.likes_count) + Number(v.comments_count) + Number(v.shares_count)
          case 'twitter':
            return Number(v.retweets_count) + Number(v.likes_count)
          case 'instagram':
            return Number(v.likes_count) + Number(v.comments_count)
          default:
            return null
        }
      })
    )

    this.table_data = accounts.data.map((account, index) => {
      account.ranking = index + 1
      account.reactions = this.getReactions(account, max_reactions)
      return account
    })
  }

  /**
   * 反応数
   * @param {any} account
   * @param {number} max_reactions
   * @returns {{chart: any, list: any}} 反応数
   */
  getReactions(account: any, max_reactions: number) {
    const chart_colors = ['#1c84c6', '#ed5565', '#2ec881']

    let total = 0
    let series = []
    let options = []

    switch (this.sns_type) {
      case 'facebook':
        total =
          Number(account.likes_count) +
          Number(account.comments_count) +
          Number(account.shares_count)
        series = [account.likes_count, account.comments_count, account.shares_count]
        options = [
          {
            key: 'いいね！',
            value: account.likes_count,
            point: { type: 'color', value: chart_colors[0] }
          },
          {
            key: 'コメント',
            value: account.comments_count,
            point: { type: 'color', value: chart_colors[1] }
          },
          {
            key: 'シェア',
            value: account.shares_count,
            point: { type: 'color', value: chart_colors[2] }
          }
        ]
        break
      case 'twitter':
        total = Number(account.retweets_count) + Number(account.likes_count)
        series = [account.retweets_count, account.likes_count]
        options = [
          {
            key: 'リポスト',
            value: account.retweets_count,
            point: { type: 'color', value: chart_colors[0] }
          },
          {
            key: 'いいね',
            value: account.likes_count,
            point: { type: 'color', value: chart_colors[1] }
          }
        ]
        break
      case 'instagram':
        total = Number(account.likes_count) + Number(account.comments_count)
        series = [account.likes_count, account.comments_count]
        options = [
          {
            key: 'いいね！',
            value: account.likes_count,
            point: { type: 'color', value: chart_colors[0] }
          },
          {
            key: 'コメント',
            value: account.comments_count,
            point: { type: 'color', value: chart_colors[1] }
          }
        ]
        break
    }

    return {
      chart: {
        series,
        colors: chart_colors,
        display_total: true,
        total,
        max: max_reactions
      },
      list: [
        {
          title: '反応数',
          options: options
        }
      ]
    }
  }

  /**
   * Remove sort symbol
   * @returns {string} class
   */
  removeSort(): string {
    return 'not-sort'
  }
}
