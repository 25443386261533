
import { Component, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import TwitterExcelReport from '@/client/components-old/organisms/TwitterExcelReport'

@Component({
  name: 'TwitterReport',
  components: {
    Scroll,
    TwitterExcelReport
  }
})
export default class TwitterReport extends Vue {}
