import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import CategoryList from '@/client/components-old/molecules/CategoryList'
import PostMedia from '@/client/components-old/molecules/PostMedia'

type TAnalyticsRankingResult = {
  key: string
  value: string
  tooltip?: string
}

type TweetType = 'tweet' | 'quote_tweet' | 'mention' | 'reply'

@Component({
  name: 'AnalyticsRanking',
  components: {
    Icon,
    Tooltip,
    CategoryList,
    PostMedia
  }
})
export default class AnalyticsRanking extends Vue {
  @Prop({ type: String, default: '' })
  screenName: string

  @Prop({ type: String, default: '' })
  screenNameUrl: string

  @Prop({ type: String, default: '' })
  mediaSrc: string

  @Prop({ type: Number, default: 0 })
  mediaCount: number

  @Prop({ type: Number, default: 0 })
  rankNo: number

  @Prop({ type: Boolean, default: true })
  showLink: boolean

  @Prop({ type: String, default: 'status' })
  type:
    | 'none'
    | 'status'
    | 'photo'
    | 'link'
    | 'video'
    | 'shared'
    | 'text'
    | 'animation-gif'
    | 'image'
    | 'carousel'

  @Prop({ type: String, default: null })
  tweetType: TweetType

  @Prop({ type: Boolean, default: false })
  isTopMedia: boolean

  @Prop({ type: Number, default: 0 })
  datetime: number

  @Prop({ type: String, default: '' })
  message: string

  @Prop({ type: Array, default: () => [] })
  results: TAnalyticsRankingResult[]

  @Prop({ type: Array, default: () => [] })
  tags: string[]

  get filter_results() {
    return this.results.slice(0, 11)
  }

  get convert_datetime() {
    return moment(this.datetime).format('lll')
  }

  @Emit('open-embed')
  clickMessage(event: Event) {
    return event
  }

  @Emit('open-embed')
  clickMedia(event: Event) {
    return event
  }
}
