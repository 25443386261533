import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import HelpLink from '@/client/components/molecules/HelpLink'
import * as constants from '@/client/components-old/_constants/twitter_analytics'
import * as calculation from '@/client/components-old/_utils/calculation'
import * as twitter_insight from '@/client/components-old/_utils/twitter_insight'
import Badge from '@/client/components-old/atoms/Badge'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartVertical from '@/client/components-old/molecules/ChartVertical'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as ITwitterState } from '@/client/store/modules/twitter_analytics'
import csv from '@/client/utils/csv'

type TTwitterAnalyticsChartVerticalOptions = {
  data_type: 'daily_data' | 'post_data'
  metric: string
  interval: '1_day' | '7_day' | '14_day' | '28_day' | '1_month' | '3_month' | '6_month'
  unit: 'total' | 'average'
  post_types?: string[]
  post_tags?: number[]
  is_change_interval?: boolean
}

type TTwitterAnalyticsChartVerticalSeries = {
  name: string
  metric: string
  data?: number[]
  index?: number
}

const analytics = namespace('analytics')
const twitter = namespace('twitter_analytics')
const categories = namespace('categories')

@Component({
  name: 'TwitterAnalyticsChartVertical',
  components: {
    AnalyticsPanel,
    AnalyticsPanelHeader,
    ChartVertical,
    Badge,
    ButtonGroup,
    HelpLink,
    Icon,
    Message,
    Tooltip
  }
})
export default class TwitterAnalyticsChartVertical extends Vue {
  @State('user') user: IRootState['user']
  @categories.State('api_post_categories')
  api_post_categories: ICategoriesState['api_post_categories']
  @categories.State('api_category_posts') api_category_posts: ICategoriesState['api_category_posts']
  @twitter.State('api_accounts') daily_data: ITwitterState['api_accounts']
  @twitter.State('api_posts') post_data: ITwitterState['api_posts']
  @twitter.State('screen_name') screen_name: ITwitterState['screen_name']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TTwitterAnalyticsChartVerticalOptions

  @Prop({ type: Boolean, default: false })
  isPreview: boolean

  @Prop({ type: Boolean, default: false })
  isEdit: boolean

  interval = '1_day'

  @Watch('options', { immediate: true, deep: true })
  changeOptions() {
    this.interval = this.options.interval
  }

  get analytics(): any[] {
    const { data_type, post_types, post_tags } = this.options

    switch (data_type) {
      case 'daily_data':
        return twitter_insight.filterDailyData(this.daily_data, this.post_data, this.options.metric)
      case 'post_data': {
        let temp_insights = twitter_insight.filterPostData(this.post_data, this.options.metric)

        if (post_types?.length) {
          temp_insights = temp_insights.filter(post => post_types.includes(post.type))
        }
        if (post_tags?.length) {
          temp_insights = temp_insights.filter(post => {
            const post_tag_ids = this.getSnsPostTags(post.post_id)
            return post_tag_ids.some(post_tag_id => post_tags.includes(post_tag_id))
          })
        }

        return temp_insights
      }
      default:
        return []
    }
  }

  get posts_date() {
    const posts = twitter_insight.filterPostData(this.post_data, this.options.metric)
    return posts.map(post => moment(post.created_time).format('YYYY-MM-DD'))
  }

  get is_csv_metric() {
    return constants.POST_CSV_IMPORT_METRICS.includes(this.options.metric)
  }

  get is_csv_metric_not_exist_data() {
    const is_csv_import = this.post_data.some(post => post.is_csv_import)

    return this.is_csv_metric && !is_csv_import
  }

  get is_not_get_non_public_metrics() {
    const is_not_get_non_public_metrics = this.post_data.every(
      post => post.is_not_get_non_public_metrics
    )

    return (
      constants.POST_PRIVATE_METRICS.includes(this.options.metric) && is_not_get_non_public_metrics
    )
  }

  get is_data() {
    return this.analytics.length > 0 && !this.is_not_get_non_public_metrics
  }

  get is_download() {
    return this.is_data && !this.isPreview && !this.is_csv_metric_not_exist_data
  }

  get is_edit() {
    return !this.isPreview && this.isEdit
  }

  get intervals() {
    if (this.user.language === 'en') {
      return [
        { text: '1D', value: '1_day' },
        { text: '7D', value: '7_day' },
        { text: '14D', value: '14_day' },
        { text: '28D', value: '28_day' },
        { text: '1M', value: '1_month' },
        { text: '3M', value: '3_month' },
        { text: '6M', value: '6_month' }
      ]
    }

    return [
      { text: '1日', value: '1_day' },
      { text: '7日', value: '7_day' },
      { text: '14日', value: '14_day' },
      { text: '28日', value: '28_day' },
      { text: '1ヶ月', value: '1_month' },
      { text: '3ヶ月', value: '3_month' },
      { text: '6ヶ月', value: '6_month' }
    ]
  }

  get metrics() {
    switch (this.options.data_type) {
      case 'daily_data':
        return constants.CHANGE_CHART_DAILY_DATA_METRICS
      case 'post_data':
        return constants.CHANGE_CHART_POST_DATA_METRICS
      default:
        return []
    }
  }

  get metric_name() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    const metric_name = metric ? this.$options.filters.translate(metric.text) : ''

    const unit = constants.UNITS.find(unit => unit.value === this.options.unit)
    const unit_name = unit ? this.$options.filters.translate(unit.text) : ''
    const unit_value = unit ? unit.value : ''

    if (
      unit_name === '' ||
      unit_value === '' ||
      this.options.metric === 'followers_count' ||
      this.options.metric === 'friends_count' ||
      this.options.metric === 'listed_count'
    ) {
      return metric_name
    }

    return `${metric_name} [${unit_name}]`
  }

  get metric_tooltip() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? metric.tooltip : ''
  }

  get show_post_types() {
    if (this.options.data_type !== 'post_data') return []

    return ['text', 'photo', 'link', 'video', 'animation-gif'].filter(v =>
      this.options.post_types.includes(v)
    )
  }

  get post_tags_names() {
    if (this.options.data_type !== 'post_data') return []

    return this.getPostTagNames(this.options.post_tags)
  }

  get panel_type() {
    const data_type = constants.DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }

  get is_button_group() {
    return this.options.is_change_interval && this.is_data
  }

  get time_range() {
    const time_range = []
    const add_number = Number(this.interval.match(/\d+/g))

    let time_end
    let time_start = moment(moment(this.start_date).format('YYYY-MM-DD 00:00:00')).unix()

    const end_date_unix = moment(moment(this.end_date).format('YYYY-MM-DD 23:59:59')).unix()

    while (time_start < end_date_unix) {
      if (
        this.interval === '1_day' ||
        this.interval === '7_day' ||
        this.interval === '14_day' ||
        this.interval === '28_day'
      ) {
        // 日数のunix値: 日数 x 一日のunix値 (一日のunix値: 86400)
        time_end = time_start + add_number * 86400 - 1
      } else if (
        this.interval === '1_month' ||
        this.interval === '3_month' ||
        this.interval === '6_month'
      ) {
        time_end = moment.unix(time_start).add(add_number, 'months').startOf('month').unix() - 1
      }

      if (time_end > end_date_unix) {
        time_end = end_date_unix
      }

      time_range.push({
        start: time_start,
        end: time_end
      })

      time_start = time_end + 1
    }

    return time_range
  }

  get categories() {
    switch (this.interval) {
      case '1_day':
        return this.time_range.map(time => {
          return moment.unix(time.start).format('YYYY/MM/DD')
        })
      case '7_day':
      case '14_day':
      case '28_day':
        return this.time_range.map(time => {
          return `${moment.unix(time.start).format('M/D')}-${moment.unix(time.end).format('M/D')}`
        })
      case '1_month':
      case '3_month':
      case '6_month':
        return this.time_range.map(time => {
          const start = moment.unix(time.start).month() + 1 + this.$options.filters.translate('月')
          const end = moment.unix(time.end).month() + 1 + this.$options.filters.translate('月')

          return start !== end ? `${start}-${end}` : start
        })
      default:
        return this.time_range
    }
  }

  get chart_options() {
    const blue_color = '#1c84c6'
    const red_color = '#ed5565'
    const green_color = '#2ec881'
    const light_blue_color = '#24cbe5'
    const yellow_color = '#eeae67'

    switch (this.options.metric) {
      case 'impressions':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'インプレッション(オーガニック)',
              metric: 'impressions_organic',
              index: 2
            },
            {
              name: 'インプレッション(広告)',
              metric: 'impressions_paid',
              index: 1
            }
          ])
        }

      case 'other_actions':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'その他アクション(オーガニック)',
              metric: 'other_actions_organic',
              index: 2
            },
            {
              name: 'その他アクション(広告)',
              metric: 'other_actions_paid',
              index: 1
            }
          ])
        }
      case 'reactions':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color, yellow_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'リポスト',
              metric: 'retweets',
              index: 3
            },
            {
              name: '引用',
              metric: 'quotes',
              index: 2
            },
            {
              name: 'いいね',
              metric: 'favorites',
              index: 1
            },
            {
              name: '返信',
              metric: 'replies',
              index: 0
            }
          ])
        }

      case 'engagements':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color, yellow_color, light_blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'リポスト',
              metric: 'retweets',
              index: 4
            },
            {
              name: '引用',
              metric: 'quotes',
              index: 3
            },
            {
              name: 'いいね',
              metric: 'favorites',
              index: 2
            },
            {
              name: '返信',
              metric: 'replies',
              index: 1
            },
            {
              name: 'その他アクション',
              metric: 'other_actions',
              index: 0
            }
          ])
        }
      case 'url_clicks':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'URLクリック数(オーガニック)',
              metric: 'url_clicks_organic',
              index: 2
            },
            {
              name: 'URLクリック数(広告)',
              metric: 'url_clicks_paid',
              index: 1
            }
          ])
        }
      case 'permalink_clicks':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: '固定リンククリック数(オーガニック)',
              metric: 'permalink_clicks_organic',
              index: 2
            },
            {
              name: '固定リンククリック数(広告)',
              metric: 'permalink_clicks_paid',
              index: 1
            }
          ])
        }
      case 'hashtag_clicks':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'ハッシュタグクリック数(オーガニック)',
              metric: 'hashtag_clicks_organic',
              index: 2
            },
            {
              name: 'ハッシュタグクリック数(広告)',
              metric: 'hashtag_clicks_paid',
              index: 1
            }
          ])
        }
      case 'user_profile_clicks':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'ユーザープロフィールクリック数(オーガニック)',
              metric: 'user_profile_clicks_organic',
              index: 2
            },
            {
              name: 'ユーザープロフィールクリック数(広告)',
              metric: 'user_profile_clicks_paid',
              index: 1
            }
          ])
        }
      case 'detail_expands':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: '詳細クリック数(オーガニック)',
              metric: 'detail_expands_organic',
              index: 2
            },
            {
              name: '詳細クリック数(広告)',
              metric: 'detail_expands_paid',
              index: 1
            }
          ])
        }
      case 'app_opens':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'アプリ表示(オーガニック)',
              metric: 'app_opens_organic',
              index: 2
            },
            {
              name: 'アプリ表示(広告)',
              metric: 'app_opens_paid',
              index: 1
            }
          ])
        }
      case 'app_installs':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'アプリインストール(オーガニック)',
              metric: 'app_installs_organic',
              index: 2
            },
            {
              name: 'アプリインストール(広告)',
              metric: 'app_installs_paid',
              index: 1
            }
          ])
        }
      case 'follows':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'フォロワー獲得(オーガニック)',
              metric: 'follows_organic',
              index: 2
            },
            {
              name: 'フォロワー獲得(広告)',
              metric: 'follows_paid',
              index: 1
            }
          ])
        }

      case 'followers_count':
      case 'friends_count':
      case 'listed_count':
        return {
          chart_type: 'line',
          y_label: this.$options.filters.translate('人数'),
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }

      case 'followers_count_up_down':
      case 'friends_count_up_down':
      case 'listed_count_up_down':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('人数'),
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }
      case 'reactions_rate':
      case 'reactions_organic_rate':
      case 'reactions_paid_rate':
      case 'engagements_rate':
      case 'engagements_organic_rate':
      case 'engagements_paid_rate':
        return {
          chart_type: 'line',
          y_label: '%',
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }

      default:
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }
    }
  }

  get display_type() {
    return `chart_vertical_${this.chart_options.chart_type}`
  }

  @Emit('edit')
  onEdit() {
    return this.options
  }

  @Emit('delete')
  onDelete(event) {
    return event
  }

  getPostTagNames(post_tag_ids?: number[]): string[] {
    if (post_tag_ids === undefined || !post_tag_ids.length) return []

    return (
      this.api_post_categories.filter(tag => post_tag_ids.includes(tag.id)).map(v => v.name) || []
    )
  }

  getSnsPostTags(post_id: string): number[] {
    const post_tag = this.api_category_posts.find(post_tag => post_tag.post_id === post_id)
    return post_tag ? post_tag.category_ids : []
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    TrackingService.sendEvent(
      `click:自社分析(TW)>${this.screen_name}|${this.options.metric}:CSVダウンロード`
    )

    const fields = [
      this.$options.filters.translate('期間'),
      this.$options.filters.translate(this.metric_name)
    ]

    if (this.chart_options.series.length > 1) {
      fields.push(...this.chart_options.series.map(element => element.name))
    }

    const data = this.categories.map((time, key) => {
      if (this.chart_options.series.length === 1) {
        return [time, ...this.chart_options.series.map(element => element.data[key])]
      }

      // 複数ターゲットの場合、合計を計算
      const total = calculation.addition(
        this.chart_options.series.map(element => element.data[key])
      )

      return [
        time,
        total ? total : null,
        ...this.chart_options.series.map(element => element.data[key])
      ]
    })

    const csv_data = { fields, data }

    const component_name = this.$options.filters.translate('グラフ')

    const csv_filename = [component_name, this.metric_name, this.start_date, this.end_date].join(
      '_'
    )

    csv.download(csv_data, csv_filename)
  }

  /**
   * グラフのシリーズデータを取得
   * @param {TTwitterAnalyticsChartVerticalSeries[]} series
   * @returns {TTwitterAnalyticsChartVerticalSeries[]} シリーズデータ
   */
  getSeries(
    series: TTwitterAnalyticsChartVerticalSeries[]
  ): TTwitterAnalyticsChartVerticalSeries[] {
    series = series.map(element => {
      return {
        name: this.$options.filters.translate(element.name),
        metric: element.metric,
        data: [],
        index: element.index
      }
    })

    this.time_range.forEach(time => {
      // 該当する期間の分析データを取得
      const analytics = this.analytics.filter(analytic => {
        switch (this.options.data_type) {
          case 'daily_data':
            return analytic.end_time / 1000 >= time.start && analytic.end_time / 1000 <= time.end
          case 'post_data':
            return (
              moment(analytic.created_time).unix() >= time.start &&
              moment(analytic.created_time).unix() <= time.end
            )
          default:
            return false
        }
      })

      // 該当する期間のデータがない場合、nullにセット
      if (analytics.length === 0) {
        series = series.map(element => {
          element.data.push(null)
          return element
        })
        return
      }

      series.forEach(element => {
        switch (this.options.unit) {
          case 'total':
            element.data.push(twitter_insight.getMetricTotal(analytics, element.metric))
            break
          case 'average':
            element.data.push(twitter_insight.getMetricAverage(analytics, element.metric))
            break
          default:
            element.data.push(null)
            break
        }
      })
    })

    return series
  }

  changeInteval(value: string) {
    TrackingService.sendEvent(
      `select:自社分析(TW)>${this.screen_name}|${this.options.metric}:${value}`
    )
  }
}
