import hljs from 'highlight.js'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { EmojiPicker } from '@/client/components/atoms'

interface IHighlightOptions {
  check_double_byte_alphanumeric: boolean
  check_double_byte_symbol: boolean
  check_kana: boolean
  check_punctuation: boolean
  check_space: boolean
}

@Component({
  name: 'TextareaLineNumber',
  components: {
    EmojiPicker
  }
})
export default class TextareaLineNumber extends Vue {
  @Prop({ type: String })
  value: string

  @Prop({ type: String, default: '' })
  placeholder: string

  @Prop({ type: Boolean, default: false })
  disabled: boolean

  @Prop({ type: Boolean, default: false })
  readonly: boolean

  @Prop({
    type: Object,
    default: () => ({
      check_double_byte_alphanumeric: false,
      check_double_byte_symbol: false,
      check_kana: false,
      check_punctuation: false,
      check_space: false
    })
  })
  highlightOptions: IHighlightOptions

  @Prop({ type: Array, default: [] })
  highlightWords: string[]

  @Prop({ type: Boolean, default: false })
  showCharacterCount: boolean

  $refs: {
    line: HTMLDivElement
    helper: HTMLDivElement
    preview: HTMLDivElement
    inline: HTMLDivElement
    textarea: HTMLTextAreaElement
    container: HTMLDivElement
  }

  lines: string[] = []

  get helper() {
    if (!this.value) return []

    return this.value.split('\n').map(line => (line === '' ? '&nbsp;' : line))
  }

  get textarea() {
    return this.value
  }

  set textarea(val) {
    this.$emit('input', val)
  }

  get is_emoji() {
    return !this.disabled && !this.readonly && this.$mq !== 'sm'
  }

  get contains() {
    const options: hljs.IModeBase[] = []

    if (this.highlightOptions.check_double_byte_alphanumeric) {
      options.push({
        className: 'matched',
        begin: '[ａ-ｚＡ-Ｚ０-９]'
      })
    }

    if (this.highlightOptions.check_double_byte_symbol) {
      options.push({
        className: 'matched',
        begin:
          '[・：；？！゛゜´｀¨＾￣＿（）〔〕［］｛｝〈〉《》「」“”『』【】＋－±×÷＝＜＞￥＄￠，．％＃]'
      })
    }

    if (this.highlightOptions.check_kana) {
      options.push({
        className: 'matched',
        begin: '[ｦ-ﾟ]'
      })
    }

    if (this.highlightOptions.check_punctuation) {
      options.push({
        className: 'matched',
        begin: '[｡､]'
      })
    }

    if (this.highlightOptions.check_space) {
      options.push({
        className: 'matched',
        begin: '[\x20　]'
      })
    }

    const words: hljs.IModeBase[] = this.highlightWords.map(word => ({
      className: 'matched',
      begin: word
    }))

    return [...options, ...words]
  }

  get character_count() {
    return this.value ? this.value.length : 0
  }

  @Watch('value')
  async watchValue() {
    await this.changeTextarea()
  }

  @Watch('highlightOptions', { deep: true })
  async watchHightlightOptions() {
    hljs.registerLanguage('post', (): hljs.IMode => ({ contains: this.contains }))

    await this.changeTextarea()
  }

  @Watch('highlightWords', { deep: true })
  async watchHighlightKeywords() {
    hljs.registerLanguage('post', (): hljs.IMode => ({ contains: this.contains }))

    await this.changeTextarea()
  }

  @Watch('disabled', { immediate: true })
  async watchDisabled() {
    await this.$nextTick()

    if (this.disabled) {
      this.$refs.container.classList.add('disabled')
    } else {
      this.$refs.container.classList.remove('disabled')
    }
  }

  /**
   * ページ表示時
   * @returns {Promise<void>} void
   */
  async created(): Promise<void> {
    hljs.configure({ classPrefix: '', languages: ['post'] })

    hljs.registerLanguage('post', (): hljs.IMode => ({ contains: this.contains }))

    await this.changeTextarea()

    this.$refs.preview.style.height = `${this.$refs.textarea.clientHeight}px`
  }

  /**
   * テキストエリアの変更時
   * @returns {Promise<void>} void
   */
  async changeTextarea(): Promise<void> {
    await this.$nextTick()

    // ハイライト用のテキスト、最終行がbrの時の表示を考慮して空白を追加
    this.$refs.inline.innerText = this.value + '&nbsp;'

    this.setPreviewWidth()

    hljs.highlightBlock(this.$refs.inline)

    const styles = getComputedStyle(this.$refs.helper)

    this.lines = []

    const node_list = this.$refs.helper.querySelectorAll('div')

    for (let index = 0; index < node_list.length; index = index + 1) {
      const line_height = Number(styles.lineHeight.replace('px', ''))

      const line_number = Math.round(node_list[index].clientHeight / line_height)

      this.lines.push(String(index + 1))

      if (line_number > 1) {
        this.lines = [...this.lines, ...Array(line_number - 1).fill('&nbsp;')]
      }
    }
  }

  /**
   * テキストエリアのスクロール変更時
   * @param {*} e
   * @returns {Promise<void>} void
   */
  async scrollTextarea(e: any): Promise<void> {
    await this.$nextTick()

    this.$refs.preview.scrollTop = e.target.scrollTop

    this.$refs.line.style.transform = `translateY(${-e.target.scrollTop}px)`
  }

  /**
   * テキストエリアのリサイズ時
   * @returns {Promise<void>} void
   */
  async resizeTextarea(): Promise<void> {
    await this.$nextTick()

    if (!this.$refs.textarea) return

    const old_height = this.$refs.textarea.clientHeight

    const detect: EventListener = () => {
      if (!this.$refs.textarea || !this.$refs.preview) return

      const new_height = this.$refs.textarea.clientHeight

      if (old_height !== new_height) {
        this.$refs.preview.style.height = `${new_height}px`
      }

      this.setPreviewWidth()
    }

    const stop: EventListener = () => {
      if (!this.$refs.textarea || !this.$refs.preview) return

      this.$refs.preview.style.height = `${this.$refs.textarea.clientHeight}px`

      document.removeEventListener('mousemove', detect)
      document.removeEventListener('mouseup', stop)
    }

    document.addEventListener('mousemove', detect, false)
    document.addEventListener('mouseup', stop, false)
  }

  /**
   * 絵文字を追加
   */
  async addEmoji(emoji: string): Promise<void> {
    const startPos = this.$refs.textarea.selectionStart

    const value =
      this.value.substring(0, startPos) + emoji + this.value.substring(startPos, this.value.length)

    this.$emit('input', value)

    await this.$nextTick()

    this.$refs.textarea.selectionStart = startPos + emoji.length
  }

  /**
   * textareaのスクロールバーの有無をチェックし、previewとhelperも同じ幅に変
   * @returns {void}
   */
  setPreviewWidth(): void {
    const hasScrollbar = this.$refs.textarea.clientWidth !== this.$refs.textarea.offsetWidth

    if (hasScrollbar) {
      const scrollbarWidth = this.$refs.textarea.offsetWidth - this.$refs.textarea.clientWidth
      this.$refs.preview.style.width = `calc(100% - ${scrollbarWidth}px)`
      this.$refs.helper.style.width = `calc(100% - ${scrollbarWidth}px)`
    } else {
      this.$refs.preview.style.width = '100%'
      this.$refs.helper.style.width = '100%'
    }
  }

  /**
   * テキストエリアをフォーカス
   * @returns {Promise<void>} void
   */
  async focusTextarea(): Promise<void> {
    await this.$nextTick()

    if (this.$refs.container) {
      this.$refs.container.classList.add('focus')
    }
  }

  /**
   * テキストエリアをブラー
   * @param {Event} event
   * @returns {Promise<void>} void
   */
  async blurTextarea(event: Event): Promise<void> {
    await this.$nextTick()

    if (this.$refs.container) {
      this.$refs.container.classList.remove('focus')
    }

    this.$emit('blur', event)
  }
}
