
import { Component, Prop, Vue } from 'vue-property-decorator'

type TLegendList = {
  text: string
  color: 'info' | 'success' | 'warning' | 'danger' | 'default'
}

@Component({
  name: 'Legend'
})
export default class Legend extends Vue {
  @Prop({ type: Array, required: true })
  data!: TLegendList[]
}
