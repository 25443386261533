import { distanceInWordsStrict } from 'date-fns' // eslint-disable-line
import en from 'date-fns/locale/en' // eslint-disable-line
import ja from 'date-fns/locale/ja' // eslint-disable-line
import moment from 'moment-timezone'

/**
 * 現在時刻との差を取得
 */
export function getTimeAgo(datetime: Date | string): string {
  return distanceInWordsStrict(Date.now(), moment(datetime).toDate(), {
    locale: moment.locale() === 'ja' ? ja : en,
    addSuffix: true
  })
}
