
import { Component, Emit, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Panel from '@/client/components-old/atoms/Panel'
import PasswordResetForm from '@/client/components-old/organisms/PasswordResetForm'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import { IGetPasswordsResetByIdResponse } from '@/client/utils/api/passwords'

@Component({
  name: 'PasswordReset',
  components: {
    ButtonLink,
    Panel,
    PasswordResetForm
  }
})
export default class PasswordReset extends Vue {
  @State('user') user!: IRootState['user']
  @Action('fetchLanguage') fetchLanguage: any
  @Action('forceReRender') forceReRender: any

  is_expired = false

  get disabled_ja() {
    return this.user.language === 'ja'
  }

  get disabled_en() {
    return this.user.language === 'en'
  }

  get color_ja() {
    return this.disabled_ja ? 'default' : 'info'
  }

  get color_en() {
    return this.disabled_en ? 'default' : 'info'
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  /**
   * ページ表示時
   */
  async created() {
    const response = await API.get<IGetPasswordsResetByIdResponse>(
      `passwords/resets/${this.$route.params.reset_id}`
    )

    if (response.data.data) {
      this.is_expired = false

      return
    }

    if (response.data.error && response.data.error.type === 'PASSWORD_RESET_EXPIRED') {
      this.is_expired = true

      return
    }

    this.$router.replace({ name: 'login' })
  }

  /**
   * 言語の変更
   */
  async changeLanguage(language: 'ja' | 'en') {
    await this.fetchLanguage(language)
    await this.forceReRender()
  }
}
