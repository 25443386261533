import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import UserList from '@/client/components/molecules/UserList'
import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import TextareaEmoji from '@/client/components-old/molecules/TextareaEmoji'
import UserMultiSelect from '@/client/components-old/molecules/UserMultiSelect'
import {
  IGetter as IPostCreateGetter,
  IState as IPostCreateState
} from '@/client/store/modules/post_create'
import { IState as IPostManagementState } from '@/client/store/modules/post_management'
import storage from '@/client/utils/storage'

const post_create = namespace('post_create')
const post_management = namespace('post_management')

@Component({
  name: 'PostApprovalRequestDialog',
  components: {
    Dialog,
    Button,
    Message,
    Flex,
    Icon,
    Input,
    SwitchButton,
    Tooltip,
    TextareaEmoji,
    UserMultiSelect,
    UserList
  }
})
export default class PostApprovalRequestDialog extends Vue {
  @post_create.State('api_post') api_post: IPostCreateState['api_post']
  @post_create.State('page_type') page_type: IPostCreateState['page_type']
  @post_create.State('project_post_approval_flow_id')
  project_post_approval_flow_id: IPostCreateState['project_post_approval_flow_id']
  @post_create.State('api_post_approval_flows')
  api_post_approval_flows: IPostCreateState['api_post_approval_flows']
  @post_create.Getter('persons_options') persons_options: IPostCreateGetter['persons_options']
  @post_create.Mutation('SET_RESET_APPROVE_STEP') setResetApproveStep
  @post_management.State('memo_cache') memo_cache: IPostManagementState['memo_cache']
  @post_management.Action('changePostMemoCache') changePostMemoCache

  message = ''
  to_user_ids: number[] = []
  visible = false
  reset_approve_step = false

  @Watch('api_post', { immediate: true, deep: true })
  changeApiPost() {
    if (
      this.api_post &&
      this.api_post.status === 'scheduled' &&
      this.api_post.post_approval_flow &&
      this.api_post.post_approval_flow.current_step > this.api_post.post_approval_flow.total_step
    ) {
      this.reset_approve_step = true
    }
  }

  get project_post_approval_flow() {
    return this.api_post_approval_flows.find(item => item.id == this.project_post_approval_flow_id)
  }

  get is_show_reset() {
    if (this.page_type !== 'edit') return false
    if (!this.api_post || !this.api_post.post_approval_flow) return false
    if (
      this.api_post.status === 'scheduled' &&
      this.api_post.post_approval_flow.current_step > this.api_post.post_approval_flow.total_step
    ) {
      return false
    }

    return (
      this.api_post.post_approval_flow.current_step > 1 &&
      this.api_post.project_post_approval_flow?.id === this.project_post_approval_flow_id
    )
  }

  @Emit('submit')
  submit() {
    this.setResetApproveStep(this.reset_approve_step)

    this.hide()

    const event = {
      message: this.message.trim(),
      users: this.to_user_ids
    }

    return event.message ? event : null
  }

  /**
   * ダイアログの表示
   */
  async open() {
    this.visible = true
    this.message = ''
    this.to_user_ids = []

    const sns_post_id = this.api_post ? this.api_post.id : 0

    if (
      this.memo_cache.memo_type === 'approval_request' &&
      this.memo_cache.sns_post_id === sns_post_id
    ) {
      this.message = this.memo_cache.message
    }

    const local: { to_user_ids?: number[] } = storage.get('post_management')

    // ストレージに保存されていたら使用する
    if (local && local.to_user_ids && Array.isArray(local.to_user_ids)) {
      this.to_user_ids = local.to_user_ids.filter(v =>
        this.persons_options.map(v => v.id).includes(v)
      )
    }
  }

  /**
   * ダイアログの非表示
   */
  hide() {
    this.visible = false
  }

  /**
   * メモの保持
   */
  async savePostMemoCache(message: string) {
    await this.changePostMemoCache({
      sns_post_id: this.api_post ? this.api_post.id : 0,
      memo_type: 'approval_request',
      message
    })
  }
}
