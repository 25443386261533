
import { Component, Prop, Vue } from 'vue-property-decorator'

type TIconSize = 'inherit' | 'normal' | 'small' | 'large' | 'x-large'
type TIconSpace = 'none' | 'left' | 'right' | 'both' | 'left-small' | 'right-small' | 'both-small'

@Component({
  name: 'Icon'
})
export default class Icon extends Vue {
  @Prop({ type: String, default: '' })
  name!: string // 量が多いので簡略化

  @Prop({ type: String, default: 'inherit' })
  size!: TIconSize

  @Prop({ type: String, default: 'none' })
  space!: TIconSpace
}
