import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import {
  PostTagsManagementDialog,
  PostTagsSnsAnalyticsSettingDialog
} from '@/client/components/organisms/Tags'
import * as constants from '@/client/components-old/_constants/twitter_analytics'
import Box from '@/client/components-old/atoms/Box'
import Button from '@/client/components-old/atoms/Button'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Legend from '@/client/components-old/atoms/Legend'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import Select from '@/client/components-old/atoms/Select'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import Search from '@/client/components-old/molecules/Search'
import SnsEmbedDialog from '@/client/components-old/organisms/SnsEmbedDialog'
import TableSettingDialog from '@/client/components-old/organisms/TableSettingDialog'
import TwitterAnalyticsDataImportDialog from '@/client/components-old/organisms/TwitterAnalyticsDataImportDialog'
import TwitterAnalyticsTable from '@/client/components-old/organisms/TwitterAnalyticsTable'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as ITwitterState } from '@/client/store/modules/twitter_analytics'

const categories = namespace('categories')
const twitter = namespace('twitter_analytics')

@Component({
  name: 'TwitterAnalyticsPost',
  components: {
    PostTagsManagementDialog,
    PostTagsSnsAnalyticsSettingDialog,
    TwitterAnalyticsDataImportDialog,
    TwitterAnalyticsTable,
    SnsEmbedDialog,
    TableSettingDialog,
    Search,
    Legend,
    Box,
    Button,
    Flex,
    Icon,
    Message,
    Select,
    SelectMulti,
    Scroll
  }
})
export default class TwitterAnalyticsPost extends Vue {
  @State('project_role') project_role: IRootState['project_role']
  @categories.State('api_post_categories') categories: ICategoriesState['api_post_categories']
  @twitter.State('api_posts') posts: ITwitterState['api_posts']
  @twitter.State('api_table_setting') table_setting: ITwitterState['api_table_setting']
  @twitter.State('screen_name') screen_name: ITwitterState['screen_name']
  @twitter.State('search_type') search_type: ITwitterState['search_type']
  @twitter.State('search_category') search_category: ITwitterState['search_category']
  @twitter.State('search_message') search_message: ITwitterState['search_message']
  @twitter.Mutation('SET_SEARCH_TYPE') setSearchType
  @twitter.Mutation('SET_SEARCH_CATEGORY') setSearchCategory
  @twitter.Mutation('SET_SEARCH_MESSAGE') setSearchMessage

  table_height: number | null = null
  message = ''

  $refs: {
    PostTagsManagementDialog: any
    TableSettingDialog: any
  }

  @Watch('screen_name', { immediate: true })
  changeScreenName() {
    this.message = this.search_message
  }

  get legend_data() {
    return constants.DATA_TYPES.map(type => ({ text: type.text, color: type.color }))
  }

  get table_tabs_options() {
    return constants.POST_TABLE_TOTAL
  }

  get search_type_options() {
    return constants.POST_TYPE.map(type => ({
      text: this.$options.filters.translate(type.text),
      value: type.value
    }))
  }

  get search_category_options() {
    return this.categories.map(v => ({ text: v.name, value: v.id }))
  }

  get table_content_height() {
    return this.table_height - 128
  }

  get is_role() {
    return this.project_role.role_analytics_setting
  }

  /**
   * 投稿内容の検索
   */
  submitSearchMessage(): void {
    TrackingService.sendEvent('click:自社分析(TW)>投稿|タブ内ヘッダー|キーワード検索')

    this.setSearchMessage(this.message)
  }

  /**
   * スクロールの変更
   */
  changeHeight(height: number): void {
    this.table_height = height
  }

  /**
   * タイプの変更
   */
  changeTypes(types: string[]) {
    TrackingService.sendEvent('select:自社分析(TW)>投稿|タブ内ヘッダー|投稿タイプ')

    this.setSearchType(types)
  }

  /**
   * タグの変更
   */
  changeCategories(categories: number[]) {
    TrackingService.sendEvent('select:自社分析(TW)>投稿|タブ内ヘッダー|タグ')

    this.setSearchCategory(categories)
  }

  openPostTagsManagementDialog() {
    TrackingService.sendEvent('click:自社分析(TW)>投稿|タブ内ヘッダー|タグの管理')

    this.$refs.PostTagsManagementDialog.open()
  }

  openTableSettingDialog() {
    TrackingService.sendEvent('click:自社分析(TW)>投稿|タブ内ヘッダー|表示内容のカスタマイズ')

    this.$refs.TableSettingDialog.open(this.table_setting.columns)
  }
}
