
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import GroupSettingMonitoringItem from '@/client/components/molecules/GroupSettingMonitoringItem'
import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { getTranslateText as t } from '@/client/utils/filters'

export default defineComponent({
  name: 'GroupSettingMonitoringList',
  components: {
    Icon,
    Message,
    Panel,
    Tooltip,
    Button,
    ButtonLink,
    GroupSettingMonitoringItem
  },
  setup() {
    return {
      t
    }
  },
  computed: {
    ...mapState('accounts', ['facebook', 'instagram', 'twitter']),

    monitoring_accounts() {
      return [
        ...this.facebook
          .filter(v => v.is_use_monitoring)
          .map(account => ({ ...account, sns_type: 'facebook' })),
        ...this.twitter
          .filter(v => v.is_use_monitoring)
          .map(account => ({ ...account, sns_type: 'twitter' })),
        ...this.instagram
          .filter(v => v.is_use_monitoring)
          .map(account => ({ ...account, sns_type: 'instagram' }))
      ]
    }
  }
})
