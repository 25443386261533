/**
 * 小文字で受け取ったSNS名を公式の表記にして返す
 */
export function getSnsName(str: string): string {
  switch (str) {
    case 'facebook':
      return 'Facebook'
    case 'twitter':
      return 'X'
    case 'instagram':
      return 'Instagram'
    case 'tiktok':
      return 'TikTok'
    default:
      return ''
  }
}
