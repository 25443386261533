declare const google: any
declare const OneDrive: any
declare const BoxSelect: any
declare const Dropbox: any

interface IBoxFile {
  id: number
  url: string
  size: number
  name: string
}

/**
 * GoogleDrive認証を行う
 * @returns {any} GoogleDrive認証情報
 */
export function execGoogleAuth() {
  const auth_window = window.open(
    '/api/v1/oauth/google_drive',
    'google_oauth',
    'width=600, height=500'
  )

  return new Promise<{ access_token: string } | null>(resolve => {
    window.onGoogleDriveAuth = auth => {
      delete window.onGoogleDriveAuth

      const google_auth = auth ? JSON.parse(JSON.stringify(auth)) : null

      if (google_auth && google_auth.access_token) {
        resolve(google_auth)
      } else {
        resolve(null)
      }

      auth_window.close()
    }
  })
}

/**
 * ファイルURLからBlobデータを取得
 */
export function getBlobData(url: string, token: string | null = null) {
  return new Promise<Blob>((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open('GET', url)

    if (token) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + token)
    }

    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.response) {
        return resolve(xhr.response)
      }
      reject(new Error('ERROR_GET_BLOB_DATA'))
    }
    xhr.send()
  })
}

/**
 * Google Driveのファイル管理
 */
export function initGoogleDrive(access_token: string, accept_file_type: string[]) {
  return new Promise<File[]>(resolve => {
    const view = new google.picker.View(google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS)
    view.setMimeTypes(accept_file_type.join())

    const docs_view = new google.picker.DocsView()
    docs_view.setIncludeFolders(true)

    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .addView(view)
      .addView(docs_view)
      .setOAuthToken(access_token)
      .setCallback(async data => {
        if (data.action !== google.picker.Action.PICKED || !data.docs) {
          return
        }

        const files = data.docs.filter(
          file =>
            file.sizeBytes <= 300 * 1024 * 1024 &&
            (file.mimeType.match('image/*') || file.mimeType.match('video/*'))
        )

        if (!files.length) {
          return resolve([])
        }

        resolve(files.slice(0, 10))
      })
      .build()

    picker.setVisible(true)
  })
}

/**
 * Dropboxのファイル管理
 * @returns {Promise<{ link: string }[]>} files
 */
export function initDropbox() {
  return new Promise<{ link: string }[]>(resolve => {
    Dropbox.init({ appKey: 'ar6xh1eon0r009z' })
    Dropbox.choose({
      success: selected_files => {
        const files = selected_files.filter(file => file.bytes <= 300 * 1024 * 1024)

        const import_files = files.filter(file => isImage(file.name) || isVideo(file.name))
        return resolve(import_files.slice(0, 10))
      },
      linkType: 'direct',
      multiselect: true,
      extensions: ['.jpg', '.jpeg', '.png', '.gif', '.pjpeg', '.mp4', '.m4v', '.mov']
    })
  })
}

/**
 * OneDriveのファイル管理
 */
export function initOneDrive() {
  return new Promise<{ '@microsoft.graph.downloadUrl': string }[]>(resolve => {
    let redirect_uri = window.location.protocol + '//' + window.location.hostname

    if (window.location.port && window.location.port !== '80') {
      redirect_uri = redirect_uri + ':' + window.location.port
    }

    OneDrive.open({
      clientId: '1a9be60f-7675-4100-81da-6bc4985000d0',
      action: 'download',
      advanced: {
        redirectUri: redirect_uri
      },
      multiSelect: true,
      success: (selected_files: any) => {
        const files = selected_files.value.filter((file: any) => file.size <= 300 * 1024 * 1024)

        const import_files = files.filter((file: any) => isImage(file.name) || isVideo(file.name))
        return resolve(import_files.slice(0, 10))
      }
    })
  })
}

/**
 * Boxのファイル管理
 */
export function initBox() {
  const box_select = new BoxSelect({
    clientId: 'uw3sylgsvcg9xu56yyd2uqgpevme8nr3',
    linkType: 'direct',
    multiselect: true
  })

  return new Promise<any>(resolve => {
    box_select.success(async files => {
      files = files.filter(file => isImage(file.name) || isVideo(file.name))

      if (!files.length) {
        return resolve([])
      }

      resolve(files.slice(0, 10))
    })

    // Boxのファイル管理ダイアログを開く
    box_select.launchPopup()
  })
}

/**
 * Googleドライブからアップロード
 */
export async function uploadFileFromGoogleDrive(files: any[], access_token: string) {
  const requests: Promise<Blob>[] = files
    .map(file => `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`)
    .map(file_url => getBlobData(file_url, access_token))

  const blobs = await Promise.all(requests)

  return blobs
}

/**
 * Dropboxからのファイルアップロード
 */
export async function uploadFileFromDropbox(files: { link: string }[]): Promise<Blob[]> {
  const downloads = files.map(file => getBlobData(file.link))
  const blobs = await Promise.all(downloads)

  return blobs
}

/**
 * Boxからアップロード
 */
export async function uploadFileFromBox(files: IBoxFile[]): Promise<Blob[]> {
  const size_limit = 300 * 1024 * 1024
  const requests: Promise<Blob>[] = files
    .filter(file => file.size <= size_limit)
    .map(file => getBlobData(file.url))

  let blobs = await Promise.all(requests)

  blobs = blobs.filter(blob => blob.type.match('image/*') || blob.type.match('video/*'))

  if (!blobs.length) {
    return []
  }

  return blobs
}

/**
 * Check if file is image
 * @param {string} file_name
 * @returns {boolean} result
 */
function isImage(file_name: string): boolean {
  const image_extensions = ['.jpg', '.jpeg', '.png', '.gif', '.pjpeg']

  return image_extensions.some(ext => file_name.indexOf(ext) > -1)
}

/**
 * Check if file is video
 */
function isVideo(file_name: string): boolean {
  const image_extensions = ['.mp4', '.m4v', '.mov']

  return image_extensions.some(ext => file_name.indexOf(ext) > -1)
}
