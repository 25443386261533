import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'

type TAnalyticsListOptions = {
  key: string
  value: number
  point?: {
    type: 'color' | 'image'
    value: string
  }
}

@Component({
  name: 'AnalyticsList',
  components: {
    Icon
  }
})
export default class AnalyticsList extends Vue {
  @Prop({ type: String, default: null })
  title: string

  @Prop({ type: Array, default: () => [] })
  options: TAnalyticsListOptions[]

  isMetricColor(metric: TAnalyticsListOptions): boolean {
    return metric.point && metric.point.type === 'color'
  }

  isMetricImage(metric: TAnalyticsListOptions): boolean {
    return metric.point && metric.point.type === 'image'
  }
}
