
import { Component, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import LibraryEngagementRankingTable from '@/client/components-old/organisms/LibraryEngagementRankingTable'

@Component({
  name: 'LibraryEngagementRanking',
  components: {
    Scroll,
    LibraryEngagementRankingTable
  }
})
export default class LibraryEngagementRanking extends Vue {
  height = 0

  /**
   * スクロールの高さ変更
   */
  changeHeight(height: number) {
    this.height = height - 130
  }
}
