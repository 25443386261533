
import { defineComponent, PropType, ref } from 'vue'
import { mapActions, mapState } from 'vuex'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import { TrackingService } from '@/client/services'
import { getTranslateText as t } from '@/client/utils/filters'
import { SnsType } from '@/common/types'

type TSnsPostCategoryDialogOptions = {
  account_id: string
  post_id: string
  category_ids: number[]
}

export default defineComponent({
  name: 'PostTagsSnsAnalyticsSettingDialog',
  components: {
    Button,
    Dialog,
    SelectMulti
  },
  props: {
    snsType: {
      type: String as PropType<SnsType>,
      required: true
    }
  },
  setup() {
    const visible = ref(false)
    const is_submit = ref(false)
    const account_id = ref('')
    const post_id = ref('')
    const category_ids = ref([])

    return {
      visible,
      is_submit,
      account_id,
      post_id,
      category_ids,
      t
    }
  },
  computed: {
    ...mapState('categories', ['api_post_categories']),

    category_options() {
      return this.api_post_categories.map(v => ({ value: v.id, text: v.name }))
    }
  },
  methods: {
    ...mapActions('categories', ['updatePostAnalysisCategory']),
    ...mapActions('notification', ['showNotification']),

    /**
     * ダイアログの表示
     */
    open(options: TSnsPostCategoryDialogOptions): void {
      if (!options || !options.account_id || !options.post_id) return

      this.visible = true
      this.account_id = options.account_id
      this.post_id = options.post_id
      this.category_ids = options.category_ids
    },

    /**
     * ダイアログの非表示
     */
    hide(): void {
      TrackingService.sendEvent('click:投稿タグの設定|キャンセル')

      this.visible = false
      this.account_id = ''
      this.post_id = ''
      this.category_ids = []
    },

    /**
     * フォームの送信
     */
    async submit(): Promise<void> {
      if (this.is_submit) return

      TrackingService.sendEvent('click:投稿タグの設定|保存')

      this.is_submit = true

      const result = await this.updatePostAnalysisCategory({
        account_id: this.account_id,
        sns_type: this.snsType,
        post_id: this.post_id,
        category_ids: this.category_ids
      })

      setTimeout(() => {
        this.is_submit = false
      }, 2000)

      if (result.data) {
        this.showNotification({ title: 'タグ設定を保存しました。' })
        this.$emit('update')
        this.hide()
      } else if (result.error && result.error.type === 'MAX_CATEGORY_OVER') {
        this.showNotification({ title: 'タグを10個以下に設定してください。', type: 'error' })
      } else {
        this.showNotification({ title: 'タグ設定の保存に失敗しました。', type: 'error' })
      }
    }
  }
})
