import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import Steps from '@/client/components-old/atoms/Steps'
import PaymentContractAbout from '@/client/components-old/organisms/PaymentContractAbout'
import PaymentContractCompleted from '@/client/components-old/organisms/PaymentContractCompleted'
import PaymentContractConfirm from '@/client/components-old/organisms/PaymentContractConfirm'
import PaymentContractForm from '@/client/components-old/organisms/PaymentContractForm'
import PaymentContractMoney from '@/client/components-old/organisms/PaymentContractMoney'
import { IState as IPaymentContractState } from '@/client/store/modules/payment_contract'

const payment_contract = namespace('payment_contract')

@Component({
  name: 'PaymentContractLayout',
  components: {
    Scroll,
    Steps,
    Message,
    PaymentContractAbout,
    PaymentContractCompleted,
    PaymentContractConfirm,
    PaymentContractForm,
    PaymentContractMoney
  }
})
export default class PaymentContractLayout extends Vue {
  @payment_contract.State('payment_group_id')
  payment_group_id: IPaymentContractState['payment_group_id']
  @payment_contract.State('payment_step') payment_step: IPaymentContractState['payment_step']

  $refs: {
    Scroll: any
  }

  get is_about() {
    return this.payment_step === 1
  }

  get is_form() {
    return this.payment_step === 2
  }

  get is_confirm() {
    return this.payment_step === 3
  }

  get is_complete() {
    return this.payment_step === 4
  }

  get options() {
    return [
      { label: 'ご利用金額の確認' },
      { label: '契約情報の入力' },
      { label: '契約内容の確認' },
      { label: '登録完了' }
    ]
  }

  @Watch('payment_step')
  watchPaymentStep() {
    this.$refs.Scroll.scrollTop()
  }

  @Emit('change-project')
  changeProject(payload: any) {
    return payload
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
