
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import MonitoringHeader from '@/client/components-old/organisms/MonitoringHeader'
import MonitoringList from '@/client/components-old/templates/MonitoringList'
import { IGetter as IAccountGetter } from '@/client/store/modules/accounts'
import { IState as IMonitoringState } from '@/client/store/modules/monitoring'
import storage from '@/client/utils/storage'

const accounts = namespace('accounts')
const monitoring = namespace('monitoring')
const monitoring_templates = namespace('monitoring_templates')

@Component({
  name: 'Monitoring',
  components: {
    MonitoringHeader,
    MonitoringList
  }
})
export default class Monitoring extends Vue {
  @accounts.Getter('monitoring') accounts!: IAccountGetter['monitoring']
  @monitoring.State('is_loading') is_loading!: IMonitoringState['is_loading']
  @monitoring.State('is_bulk_loading') is_bulk_loading!: IMonitoringState['is_bulk_loading']
  @monitoring.State('is_csv_loading') is_csv_loading!: IMonitoringState['is_csv_loading']
  @monitoring.Mutation('SET_FILTER_ACCOUNT') setAccount!: any
  @monitoring.Action('createdMonitoringPage') createdMonitoringPage!: any
  @monitoring.Action('destroyedMonitoringPage') destroyedMonitoringPage!: any
  @monitoring_templates.Action('fetchTemplate') fetchTemplate!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  get is_show_loading() {
    return this.is_loading || this.is_bulk_loading || this.is_csv_loading
  }

  get loading_text() {
    if (this.is_bulk_loading) {
      return '一括処理中です。しばらくお待ちください。'
    }

    if (this.is_csv_loading) {
      return 'CSVファイルの作成中です。しばらくお待ちください。'
    }

    return 'データを取得中です。しばらくお待ちください。'
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  @Watch('group')
  async watchGroup() {
    await this.destroyedMonitoringPage()

    await this.initialize()
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.initialize()
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedMonitoringPage()
  }

  /**
   * 初期化
   */
  async initialize() {
    const local: { account_id: string; account_sns: string } = storage.get('monitoring')

    let account_id = ''
    let account_sns = ''

    // ストレージに保存されていたら使用する
    if (local && local.account_id && local.account_sns) {
      const account = this.accounts.find(
        v => v.id === local.account_id && v.sns === local.account_sns
      )

      if (account) {
        account_id = local.account_id
        account_sns = local.account_sns
      }
    }

    await this.setAccount({ account_id, account_sns })
    await this.createdMonitoringPage()
    await this.fetchTemplate()
  }
}
