import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/facebook_comparison_analytics'
import * as facebook_comparison_insight from '@/client/components-old/_utils/facebook_comparison_insight'
import Button from '@/client/components-old/atoms/Button'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import AnalyticsList from '@/client/components-old/molecules/AnalyticsList'
import CategoryList from '@/client/components-old/molecules/CategoryList'
import ChartSingleBar from '@/client/components-old/molecules/ChartSingleBar'
import PaginationTable from '@/client/components-old/molecules/PaginationTable'
import PostMedia from '@/client/components-old/molecules/PostMedia'
import { TrackingService } from '@/client/services'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as IFacebookState } from '@/client/store/modules/facebook_comparison_analytics'
import csv from '@/client/utils/csv'

type TFacebookComparisonAnalyticsPostTableCustom = {
  chart: {
    series: number[]
    colors: string[]
    display_total: boolean
    total: number
    max: number
  }
  list: {
    title: string
    options: {
      key: string
      value: number
      point?: {
        type: string
        value: string
      }
    }[]
  }[]
}

const categories = namespace('categories')
const analytics = namespace('analytics')
const facebook = namespace('facebook_comparison_analytics')

@Component({
  name: 'FacebookComparisonAnalyticsPostTable',
  components: {
    Account,
    AnalyticsList,
    CategoryList,
    ChartSingleBar,
    PaginationTable,
    PostMedia,
    Button,
    ButtonGroup,
    ButtonLink,
    Icon,
    Message,
    Panel,
    Select,
    Tooltip
  }
})
export default class FacebookComparisonAnalyticsPostTable extends Vue {
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']
  @categories.State('api_post_categories') categories: ICategoriesState['api_post_categories']
  @categories.State('api_category_posts') category_posts: ICategoriesState['api_category_posts']
  @facebook.State('api_sns_comparison_accounts')
  account_data: IFacebookState['api_sns_comparison_accounts']
  @facebook.State('api_posts') post_data: IFacebookState['api_posts']
  @facebook.State('search_type') search_type: IFacebookState['search_type']
  @facebook.State('search_category') search_category: IFacebookState['search_category']
  @facebook.State('search_message') search_message: IFacebookState['search_message']
  @facebook.State('account_ids') account_ids: IFacebookState['account_ids']
  @facebook.State('post_display_format') post_display_format: IFacebookState['post_display_format']
  @facebook.State('post_display_count') post_display_count: IFacebookState['post_display_count']
  @facebook.Action('changePostDisplayCount') changePostDisplayCount
  @facebook.Action('changePostDisplayFormat') changePostDisplayFormat

  @Prop({ type: Number, default: null })
  height: number

  pagination = 1

  sort: {
    metric: string
    order: 'ascending' | 'descending'
  } = {
    metric: 'created_time',
    order: 'descending'
  }

  panel_tab: 'none' | 'type' | 'hashtag' | 'category' | 'character_count' = 'none'

  @Watch('panel_tab', { immediate: true })
  changePanelTab() {
    this.switchSortMetric()
  }

  @Watch('posts.length')
  watchPostsLength() {
    this.pagination = 1
  }

  get table_columns() {
    const columns = ['reactions', 'reactions_rate', 'likes', 'comments', 'shares']

    switch (this.panel_tab) {
      case 'none':
        columns.push('page_fans_at_that_time')
        return columns

      default:
        return columns
    }
  }

  get posts() {
    const post_data = this.post_data.map(data => data.data)
    let posts = [].concat(...post_data)

    posts = posts.filter(post => this.account_ids.indexOf(post.account_id) > -1)

    if (this.search_type.length > 0) {
      posts = posts.filter(post => this.search_type.indexOf(post.type) >= 0)
    }

    if (this.search_category.length) {
      posts = posts.filter(post => {
        const category_ids = this.getSnsPostCategory(post.post_id)

        return category_ids.some(category_id => this.search_category.indexOf(category_id) >= 0)
      })
    }

    if (this.search_message !== '') {
      posts = posts.filter(
        post => post.message.toLowerCase().match(this.search_message.toLowerCase()) !== null
      )
    }

    switch (this.panel_tab) {
      case 'type':
        posts = this.createPostsByType(posts)
        break
      case 'hashtag':
        posts = this.createPostsByHashtag(posts)
        break
      case 'category':
        posts = this.createPostsByCategory(posts)
        break
      case 'character_count':
        posts = this.createPostsByCountMessage(posts)
        break
    }

    return posts
  }

  get panel_tabs() {
    return constants.POST_TABLE_TOTAL
  }

  get total_tabs() {
    return constants.TABLE_TABS
  }

  get pagination_total() {
    return this.posts.length
  }

  get is_none() {
    return this.panel_tab === 'none'
  }

  get is_type() {
    return this.panel_tab === 'type'
  }

  get is_hashtag() {
    return this.panel_tab === 'hashtag'
  }

  get is_category() {
    return this.panel_tab === 'category'
  }

  get is_data() {
    return this.pagination_total > 0
  }

  get is_character_count() {
    return this.panel_tab === 'character_count'
  }

  get post_limit_options() {
    return [
      { text: '10', value: 10 },
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ]
  }

  get table_posts() {
    const chart_colors = ['#1c84c6', '#ed5565', '#2ec881']

    const page_begin = (this.pagination - 1) * this.post_display_count
    const page_end = page_begin + this.post_display_count

    let posts = this.posts

    const max = key =>
      Math.max.apply(
        null,
        posts.map(post => post[key])
      )
    const max_reactions = max('reactions')

    posts = posts.sort((a, b) => {
      switch (this.sort.order) {
        case 'ascending':
          return a[this.sort.metric] - b[this.sort.metric]
        case 'descending':
          return b[this.sort.metric] - a[this.sort.metric]
        default:
          return 0
      }
    })

    posts = posts.slice(page_begin, page_end)

    return posts.map(post => {
      const account = this.account_data.find(account => account.id === post.account_id)
      const account_name = account.name
      const account_id = account.id
      const account_img = account.img

      // 反応数
      const reactions: TFacebookComparisonAnalyticsPostTableCustom = {
        chart: {
          series: [post.likes, post.comments, post.shares],
          colors: chart_colors,
          display_total: true,
          total: post.reactions,
          max: max_reactions
        },
        list: [
          {
            title: '反応数',
            options: [
              {
                key: 'いいね！',
                value: post.likes,
                point: { type: 'color', value: chart_colors[0] }
              },
              {
                key: 'コメント',
                value: post.comments,
                point: { type: 'color', value: chart_colors[1] }
              },
              {
                key: 'シェア',
                value: post.shares,
                point: { type: 'color', value: chart_colors[2] }
              }
            ]
          }
        ]
      }

      return {
        ...post,
        account_id,
        account_name,
        account_img,
        reactions
      }
    })
  }

  /**
   * ソート変更時
   * @returns {void}
   */
  switchSortMetric(): void {
    switch (this.panel_tab) {
      case 'type':
      case 'hashtag':
      case 'category':
        this.sort.metric = 'post_count'
        break
      case 'character_count':
        this.sort.metric = 'reactions'
        break
      default:
        this.sort.metric = 'created_time'
    }
  }

  /**
   * アカウントIDの一覧を取得
   * @param {any[]} posts
   * @returns {string[]} account id
   */
  getAccountIds(posts: any[]): string[] {
    return posts
      .map(post => post.account_id)
      .filter((post, index, arr) => arr.indexOf(post) === index)
  }

  /**
   * 投稿タイプの投稿データを作成
   * @param {any[]} filter_posts フィルタリング後の投稿データ
   * @returns {any[]} 集計した投稿データ
   */
  createPostsByType(filter_posts: any[]): any[] {
    const metrics = filter_posts.length > 0 ? Object.keys(filter_posts[0]) : []
    const types = ['status', 'photo', 'link', 'video', 'shared']
    const account_ids = this.getAccountIds(filter_posts)
    const posts = []

    account_ids.forEach(account_id => {
      const post = types
        .map(type => {
          const posts = filter_posts.filter(p => p.type === type && p.account_id === account_id)

          const temp = {
            type: this.getPostType(type),
            post_count: posts.length,
            account_id: account_id
          }

          metrics.forEach(metric => {
            if (constants.POST_TABLE_SORT_BLACKLIST.indexOf(metric) === -1) {
              temp[metric] = this.getMetricValue(posts, metric)
            }
          })

          return temp
        })
        .filter(post => post.post_count > 0)

      posts.push(post)
    })

    return [].concat(...posts)
  }

  /**
   * ハッシュタグの投稿データを作成
   * @param {any[]} filter_posts フィルタリング後の投稿データ
   * @returns {any[]} 集計した投稿データ
   */
  createPostsByHashtag(filter_posts: any[]): any[] {
    const metrics = filter_posts.length > 0 ? Object.keys(filter_posts[0]) : []
    const hashtags =
      filter_posts.length > 0
        ? filter_posts
            .map(p => p.tags)
            .reduce((a, b) => a.concat(b), [])
            .filter((x, i, self) => self.indexOf(x) === i)
        : []
    const account_ids = this.getAccountIds(filter_posts)
    const posts = []

    account_ids.forEach(account_id => {
      const post = hashtags
        .map(hashtag => {
          const posts = filter_posts.filter(
            p => p.tags.indexOf(hashtag) >= 0 && p.account_id === account_id
          )

          const temp = { hashtag, post_count: posts.length, account_id }

          metrics.forEach(metric => {
            if (constants.POST_TABLE_SORT_BLACKLIST.indexOf(metric) === -1) {
              temp[metric] = this.getMetricValue(posts, metric)
            }
          })

          return temp
        })
        .filter(post => post.post_count > 0)

      posts.push(post)
    })

    return [].concat(...posts)
  }

  /**
   * タグの投稿データを作成
   * @param {any[]} filter_posts フィルタリング後の投稿データ
   * @returns {any[]} 集計した投稿データ
   */
  createPostsByCategory(filter_posts: any[]): any[] {
    const metrics = filter_posts.length > 0 ? Object.keys(filter_posts[0]) : []
    const account_ids = this.getAccountIds(filter_posts)
    const posts = []

    account_ids.forEach(account_id => {
      const post = this.categories
        .map(category => {
          const posts = filter_posts.filter(p => {
            const category_ids = this.getSnsPostCategory(p.post_id)

            return category_ids.indexOf(category.id) >= 0 && p.account_id === account_id
          })

          const temp = { category: category.name, post_count: posts.length, account_id }

          metrics.forEach(metric => {
            if (constants.POST_TABLE_SORT_BLACKLIST.indexOf(metric) === -1) {
              temp[metric] = this.getMetricValue(posts, metric)
            }
          })

          return temp
        })
        .filter(post => post.post_count > 0)

      posts.push(post)
    })

    return [].concat(...posts)
  }

  /**
   * 文字数の投稿データを作成
   * @param {any[]} filter_posts フィルタリング後の投稿データ
   * @returns {any[]} 集計した投稿データ
   */
  createPostsByCountMessage(filter_posts: any[]): any[] {
    const metrics = filter_posts.length > 0 ? Object.keys(filter_posts[0]) : []

    const account_ids = this.getAccountIds(filter_posts)
    const posts = []

    account_ids.forEach(account_id => {
      const post = constants.CHARACTER_RANGE.map(range => {
        const posts = filter_posts.filter(p => {
          if (!range.less_than) {
            return (
              p.message && p.message.length >= range.greater_than && p.account_id === account_id
            )
          }

          return (
            p.message &&
            p.message.length >= range.greater_than &&
            p.message.length < range.less_than &&
            p.account_id === account_id
          )
        })

        const temp = {
          character_range: this.$options.filters.translate(range.title),
          post_count: posts.length,
          account_id
        }

        metrics.forEach(metric => {
          if (constants.POST_TABLE_SORT_BLACKLIST.indexOf(metric) === -1) {
            temp[metric] = this.getMetricValue(posts, metric)
          }
        })

        return temp
      }).filter(post => post.post_count > 0)

      posts.push(post)
    })

    return [].concat(...posts)
  }

  /**
   * 指標から合計・平均した値を取得
   * @param {any[]} posts 投稿データ
   * @param {string} metric 指標
   * @returns {number} 値
   */
  getMetricValue(posts: any[], metric: string): number {
    if (this.post_display_format === 'average') {
      return facebook_comparison_insight.getMetricAverage(posts, metric)
    }

    switch (metric) {
      case 'reactions_rate':
        return null

      default:
        return facebook_comparison_insight.getMetricTotal(posts, metric)
    }
  }

  /**
   * ラベルの取得
   * @param {string} val 指標
   * @returns {string} ラベル
   */
  getLabel(val: string): string {
    let metrics = []

    switch (this.panel_tab) {
      case 'type':
        metrics = constants.POST_TABLE_TYPE_METRICS
        break
      case 'hashtag':
        metrics = constants.POST_TABLE_HASHTAG_METRICS
        break
      case 'category':
        metrics = constants.POST_TABLE_CATEGORY_METRICS
        break
      case 'character_count':
        metrics = constants.POST_TABLE_CHARACTER_COUNT_METRICS
        break
      default:
        metrics = constants.POST_COMPARISON_TABLE_METRICS
        break
    }

    const metric = metrics.find(b => b.value === val)
    return metric ? metric.text : ''
  }

  /**
   * 投稿タイプ名の取得
   * @param {string} val 投稿タイプ
   * @returns {string} 投稿タイプ名
   */
  getPostType(val: string): string {
    const type = constants.POST_TYPE.find(b => b.value === val)
    return type ? type.text : ''
  }

  /**
   * タグの取得
   * @param {string} post_id 投稿ID
   * @returns {number[]} タグIDs
   */
  getSnsPostCategory(post_id: string): number[] {
    const category = this.category_posts.find(category_post => category_post.post_id === post_id)

    return category ? category.category_ids : []
  }

  /**
   * タグ名の取得
   * @param {string} post_id 投稿ID
   * @returns {string[]} タグ名
   */
  getCategoryName(post_id: string): string[] {
    const category_ids = this.getSnsPostCategory(post_id)

    if (!category_ids.length) return []

    return category_ids
      .map(category_id => {
        const value = this.categories.find(category => category.id === category_id)

        return value ? value.name : null
      })
      .filter(category_name => category_name)
  }

  /**
   * タグの判別
   * @param {string} post_id 投稿ID
   * @returns {boolean} 判定
   */
  isCategory(post_id: string): boolean {
    const category_ids = this.getSnsPostCategory(post_id)

    return category_ids.length > 0
  }

  /**
   * チャートの判別
   * @param {string} val 指標
   * @returns {boolean} 判定
   */
  isChart(val: string): boolean {
    switch (val) {
      case 'reactions':
        return true
      default:
        return false
    }
  }

  /**
   * パーセントの判別
   * @param {string} val 指標
   * @returns {boolean} 判定
   */
  isPercent(val: string): boolean {
    switch (val) {
      case 'reactions_rate':
        return true
      default:
        return false
    }
  }

  /**
   * テーブルのスタイル追加
   * @param {any} val テーブルデータ
   * @returns {string} クラス文字列
   */
  styleSort(val: any): string {
    const columns = ['message', 'type', 'hashtag', 'category', 'account', 'character_range']
    if (columns.indexOf(val.column.property) >= 0) {
      return 'not-sort'
    }

    if (this.sort.metric === val.column.property) {
      return `sort-metric sort-${this.sort.order}`
    }

    return ''
  }

  /**
   * ソート変更時
   * @param {TFacebookComparisonAnalyticsPostTableSort} val
   * @returns {void}
   */
  onSort(val: any): void {
    const columns = ['message', 'type', 'hashtag', 'category', 'account', 'character_range']
    if (columns.indexOf(val.property) >= 0) {
      return
    }

    if (val.property === this.sort.metric) {
      this.sort.order = this.sort.order === 'ascending' ? 'descending' : 'ascending'
    } else {
      this.sort = { metric: val.property, order: 'descending' }
      this.pagination = 1
    }

    TrackingService.sendEvent(
      `sort:競合比較(FB)>投稿|公開された投稿:${this.getLabel(val.property)}`
    )
  }

  @Emit('open-embed')
  openEmbed(account_id: string, post_id: string) {
    TrackingService.sendEvent(`click:競合比較(FB)>投稿|公開された投稿:プレビュー`)

    return { account_id, post_id }
  }

  @Emit('click-category-setting')
  onClickCategorySetting(account_id: string, post_id: string) {
    TrackingService.sendEvent(`click:競合比較(FB)>投稿|公開された投稿:タグ設定`)

    const category_ids = this.getSnsPostCategory(post_id)
    return {
      account_id,
      post_id,
      category_ids
    }
  }

  /**
   * SNSで確認をクリック
   */
  onClickDetail(account_id: string, post_id: string): void {
    TrackingService.sendEvent(`click:競合比較(FB)>投稿|公開された投稿:SNSで確認`)

    open(`https://www.facebook.com/${account_id}_${post_id}`, '_blank')
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    let columns = [
      'created_time',
      'account_name',
      'message',
      'type',
      'post_id',
      'post_url',
      'picture',
      'video_url',
      'link_name',
      'link_description',
      'link_caption',
      'link_link',
      'category_name'
    ]

    switch (this.panel_tab) {
      case 'type':
        columns = ['account_name', 'type', 'post_count']
        break
      case 'hashtag':
        columns = ['account_name', 'hashtag', 'post_count']
        break
      case 'category':
        columns = ['account_name', 'category', 'post_count']
        break
      case 'character_count':
        columns = ['account_name', 'character_range', 'post_count']
        break
    }

    columns = columns.concat(this.table_columns)

    const fields = columns.map(v => this.$options.filters.translate(this.getLabel(v)))
    const data = this.posts.map(post => {
      const account = this.account_data.find(account => account.id === post.account_id)
      const account_name = account.name

      return columns.map(column => {
        if (column === 'account_name') {
          return account_name
        }

        if (column === 'created_time') {
          return csv.format(post[column])
        }

        if (column === 'type') {
          return post[column] === 'status' ? 'text' : post[column]
        }

        if (column === 'post_id') {
          return `${post.account_id}_${post.post_id}`
        }

        if (column === 'post_url') {
          return `https://www.facebook.com/${post.account_id}_${post.post_id}`
        }

        if (column === 'video_url') {
          if (post.type === 'video' && post.object_id !== '') {
            return `https://www.facebook.com/video/embed?video_id=${post.object_id}`
          } else {
            return ''
          }
        }

        if (column === 'link_name') {
          return post.link_data.name
        }
        if (column === 'link_description') {
          return post.link_data.description
        }
        if (column === 'link_caption') {
          return post.link_data.caption
        }
        if (column === 'link_link') {
          return post.link_data.link
        }

        if (column === 'category_name') {
          return this.getCategoryName(post.post_id)
        }

        return post[column]
      })
    })

    const csv_data = { fields, data }

    const total = constants.POST_TABLE_TOTAL.find(b => b.value === this.panel_tab)
    const component_name = this.$options.filters.translate(total ? total.text : '')

    let csv_filename = [component_name, this.start_date, this.end_date]

    switch (this.panel_tab) {
      case 'type':
      case 'hashtag':
      case 'category':
      case 'character_count': {
        const total_tab = constants.TABLE_TABS.find(v => v.value === this.post_display_format)
        const total_name = this.$options.filters.translate(total_tab ? total_tab.text : '')

        csv_filename = [component_name, total_name, this.start_date, this.end_date]
        break
      }
    }

    csv.download(csv_data, csv_filename.join('_'))

    TrackingService.sendEvent(`click:競合比較(FB)>投稿|${this.panel_tab}:CSVダウンロード`)
  }

  /**
   * 投稿の表示件数の変更
   */
  async changeDisplayCount(display_count: number) {
    this.pagination = 1
    await this.changePostDisplayCount(display_count)
  }

  /**
   * スクロール位置を最上部に変更する
   * @returns {void}
   */
  scrollTop(): void {
    // el-table のスクロール位置を変更するため、DOM要素を取得する
    const element = document.getElementsByClassName('el-table__body-wrapper')
    if (element && element.length) {
      element[0].scrollTop = 0
    }
  }

  /**
   * 投稿の表示形式を変更
   */
  async onChangePostDisplayFormat(payload: string) {
    TrackingService.sendEvent(
      `click:競合比較(FB)>投稿|${this.panel_tab}:${this.post_display_format}`
    )
    await this.changePostDisplayFormat(payload)
  }
}
