import { diffChars } from 'diff'
import twttr from 'twitter-text'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { LINE_FORMAT } from '@/client/utils/regex'

@Component({
  name: 'TextareaDifference'
})
export default class TextareaDifference extends Vue {
  @Prop({ type: String, required: true })
  newText: string

  @Prop({ type: String, required: true })
  oldText: string

  $refs: {
    helper: HTMLDivElement
  }

  lines = []

  get diff() {
    return diffChars(this.oldText, this.newText)
  }

  get helper() {
    const text = this.diff.map(v => v.value).join('')

    return text.split('\n').map(line => (line === '' ? '&nbsp;' : line))
  }

  get display_text() {
    let text = ''

    for (const part of this.diff) {
      const name = part.added ? 'added' : part.removed ? 'removed' : 'normal'

      const value: string = twttr.htmlEscape(part.value)

      text += `<span class="${name}">${value.replace(LINE_FORMAT, '<br />')}</span>`
    }

    return text
  }

  @Watch('helper', { immediate: true })
  async watchLine() {
    await this.$nextTick()

    let lines = []

    const styles = getComputedStyle(this.$refs.helper)

    const node_list = this.$refs.helper.querySelectorAll('div')

    for (let index = 0; index < node_list.length; index = index + 1) {
      const line_height = Number(styles.lineHeight.replace('px', ''))
      const line_number = Math.round(node_list[index].clientHeight / line_height)

      lines.push(String(index + 1))

      if (line_number > 1) {
        lines = [...lines, ...Array(line_number - 1).fill('&nbsp;')]
      }
    }

    this.lines = lines
  }
}
