import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import {
  PostTagsManagementDialog,
  PostTagsSnsAnalyticsSettingDialog
} from '@/client/components/organisms/Tags'
import * as constants from '@/client/components-old/_constants/facebook_analytics'
import Box from '@/client/components-old/atoms/Box'
import Button from '@/client/components-old/atoms/Button'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Legend from '@/client/components-old/atoms/Legend'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import Select from '@/client/components-old/atoms/Select'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import Search from '@/client/components-old/molecules/Search'
import FacebookAnalyticsTable from '@/client/components-old/organisms/FacebookAnalyticsTable'
import SnsEmbedDialog from '@/client/components-old/organisms/SnsEmbedDialog'
import TableSettingDialog from '@/client/components-old/organisms/TableSettingDialog'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as IFacebookState } from '@/client/store/modules/facebook_analytics'

const categories = namespace('categories')
const facebook = namespace('facebook_analytics')

@Component({
  name: 'FacebookAnalyticsPost',
  components: {
    PostTagsManagementDialog,
    PostTagsSnsAnalyticsSettingDialog,
    FacebookAnalyticsTable,
    SnsEmbedDialog,
    TableSettingDialog,
    Search,
    Legend,
    Box,
    Button,
    Flex,
    Icon,
    Message,
    Select,
    SelectMulti,
    Scroll
  }
})
export default class FacebookAnalyticsPost extends Vue {
  @State('project_role') project_role: IRootState['project_role']
  @categories.State('api_post_categories') categories: ICategoriesState['api_post_categories']
  @facebook.State('api_table_setting') table_setting: IFacebookState['api_table_setting']
  @facebook.State('screen_name') screen_name: IFacebookState['screen_name']
  @facebook.State('search_type') search_type: IFacebookState['search_type']
  @facebook.State('search_category') search_category: IFacebookState['search_category']
  @facebook.State('search_message') search_message: IFacebookState['search_message']
  @facebook.Mutation('SET_SEARCH_TYPE') setSearchType
  @facebook.Mutation('SET_SEARCH_CATEGORY') setSearchCategory
  @facebook.Mutation('SET_SEARCH_MESSAGE') setSearchMessage

  table_height: number | null = null
  message = ''

  $refs: {
    PostTagsManagementDialog: any
    TableSettingDialog: any
  }

  @Watch('screen_name', { immediate: true })
  changeScreenName() {
    this.message = this.search_message
  }

  get is_role() {
    return this.project_role.role_analytics_setting
  }

  get legend_data() {
    return constants.DATA_TYPES.map(type => ({ text: type.text, color: type.color }))
  }

  get search_type_options() {
    return constants.POST_TYPE.map(type => ({
      text: this.$options.filters.translate(type.text),
      value: type.value
    }))
  }

  get search_category_options() {
    return this.categories.map(v => ({ text: v.name, value: v.id }))
  }

  get table_content_height() {
    return this.table_height - 128
  }

  /**
   * 投稿内容の検索
   * @returns {void}
   */
  submitSearchMessage(): void {
    TrackingService.sendEvent('click:自社分析(FB)>投稿|タブ内ヘッダー|キーワード検索')

    this.setSearchMessage(this.message)
  }

  /**
   * スクロールの変更
   * @param {number} height 内部の高さ
   * @returns {void}
   */
  changeHeight(height: number): void {
    this.table_height = height
  }

  /**
   * 投稿タイプの変更
   */
  setPostTypes(types: string[]) {
    TrackingService.sendEvent('select:自社分析(FB)>投稿|タブ内ヘッダー|投稿タイプ')

    this.setSearchType(types)
  }

  /**
   * タグの変更
   */
  setTags(tags: string[]) {
    TrackingService.sendEvent('select:自社分析(FB)>投稿|タブ内ヘッダー|タグ')

    this.setSearchCategory(tags)
  }

  /**
   * タグの管理
   */
  openPostTagsManagementDialog() {
    TrackingService.sendEvent('click:自社分析(FB)>投稿|タブ内ヘッダー|タグの管理')

    this.$refs.PostTagsManagementDialog.open()
  }

  /**
   * 表示内容のカスタマイズ
   */
  openTableSettingDialog(columns: string[]) {
    TrackingService.sendEvent('click:自社分析(FB)>投稿|タブ内ヘッダー|表示内容のカスタマイズ')

    this.$refs.TableSettingDialog.open(columns)
  }
}
