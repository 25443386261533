import { RouteConfig } from 'vue-router'

export const tiktok_callback: RouteConfig = {
  path: '/tiktok_callback',
  name: 'tiktok_callback',
  meta: {
    auth: true
  },
  beforeEnter(to, from, next) {
    window.opener.onTikTokAuth(to.query)
    next()
  }
}
