
import { Component, Prop, Vue } from 'vue-property-decorator'

type TPanelType = 'default' | 'info' | 'success' | 'warning' | 'danger'

@Component({
  name: 'Panel'
})
export default class Panel extends Vue {
  @Prop({ type: Boolean, default: false })
  isAbsolute!: boolean

  @Prop({ type: Boolean, default: false })
  isTransparentMobile!: boolean

  @Prop({ type: String, default: null })
  type!: TPanelType

  get panel_classes() {
    const classes = []

    if (this.isAbsolute) {
      classes.push('panel-absolute')
    }

    if (this.type !== null) {
      classes.push(`color-${this.type}`)
    }

    if (this.$mq === 'sm' && this.isTransparentMobile) {
      classes.push('is-transparent')
    }

    return classes
  }
}
