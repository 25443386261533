
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Dialog'
})
export default class Dialog extends Vue {
  @Prop({ type: Boolean, required: true })
  visible!: boolean

  @Prop({ type: String, default: 'normal' })
  size!: 'normal' | 'large'

  @Prop({ type: Boolean, default: true })
  showClose!: boolean

  @Prop({ type: Function, default: (done: any) => done() })
  beforeClose!: any

  @Prop({ type: Boolean, default: true })
  closeOnClickModal!: boolean

  get dialog() {
    return this.visible
  }

  set dialog(val) {
    this.$emit('update:visible', val)
  }

  get width() {
    switch (this.size) {
      case 'large':
        return '90%'
      case 'normal':
        return '50%'
      default:
        return '50%'
    }
  }

  @Emit('open')
  open(event: any) {
    return event
  }

  @Emit('opened')
  opened(event: any) {
    return event
  }

  @Emit('close')
  close(event: any) {
    return event
  }

  @Emit('closed')
  closed(event: any) {
    return event
  }
}
