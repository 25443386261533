import { RouteConfig } from 'vue-router'

import Invitation from '../pages/Invitation.vue'

export const invitation: RouteConfig = {
  component: Invitation,
  path: '/invitation/:invitation_id',
  name: 'invitation',
  meta: {
    auth: false,
    title: 'グループ招待'
  }
}
