import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { CONTRACT_STATUS_TRIAL } from '@/client/components-old/_constants/project'
import ButtonCta from '@/client/components-old/atoms/ButtonCta'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import { IRootState } from '@/client/store/global'

const dashboard = namespace('dashboard')

@Component({
  name: 'DashboardTutorial',
  components: {
    ButtonCta,
    Icon,
    Panel
  }
})
export default class DashboardTutorial extends Vue {
  @State('project') project: IRootState['project']
  @dashboard.Action('closeTutorialHelp') closeTutorialHelp

  get is_trial() {
    return this.project.contract_status === CONTRACT_STATUS_TRIAL
  }

  /**
   * チュートリアルを閉じる
   */
  async closeTutorial() {
    const message = [
      this.$options.filters.translate('チュートリアルとヘルプを非表示にしてよろしいですか？'),
      this.$options.filters.translate('これらの情報は、ヘルプからいつでも確認できます。')
    ].join('\n')

    const confirm = window.confirm(message)

    if (!confirm) return

    await this.closeTutorialHelp()
  }
}
