import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/twitter_analytics'
import * as twitter_insight from '@/client/components-old/_utils/twitter_insight'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartDonutHalf from '@/client/components-old/molecules/ChartDonutHalf'
import { TrackingService } from '@/client/services'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as ITwitterState } from '@/client/store/modules/twitter_analytics'
import csv from '@/client/utils/csv'
import { getTranslateText as t } from '@/client/utils/filters'

type TPostData = {
  data_type: 'post_data'
  metric: 'reactions' | 'engagements' | 'other_actions'
}

type TTwitterAnalyticsChartDonutHalfOptions = TPostData

const analytics = namespace('analytics')
const twitter = namespace('twitter_analytics')

@Component({
  name: 'TwitterAnalyticsChartDonutHalf',
  components: {
    ChartDonutHalf,
    AnalyticsPanel,
    AnalyticsPanelHeader,
    Icon,
    Tooltip
  }
})
export default class TwitterAnalyticsChartDonutHalf extends Vue {
  @twitter.State('api_posts') post_data: ITwitterState['api_posts']
  @twitter.State('screen_name') screen_name: ITwitterState['screen_name']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TTwitterAnalyticsChartDonutHalfOptions

  get is_csv_import() {
    return this.post_data.some(post => post.is_csv_import)
  }

  get is_data() {
    switch (this.options.data_type) {
      case 'post_data':
        return this.post_data.length > 0
      default:
        return false
    }
  }

  get metrics() {
    switch (this.options.data_type) {
      case 'post_data':
        return constants.HALF_DOUGHNUT_CHART_POST_DATA_METRICS
      default:
        return []
    }
  }

  get metric_name() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? t(metric.text) : ''
  }

  get metric_tooltip() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? metric.tooltip : ''
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }

  get panel_type() {
    const data_type = constants.DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get reactions_data() {
    return [
      [t('リポスト'), twitter_insight.getMetricTotal(this.post_data, 'retweets')],
      [t('引用'), twitter_insight.getMetricTotal(this.post_data, 'quotes')],
      [t('いいね'), twitter_insight.getMetricTotal(this.post_data, 'favorites')],
      [t('返信'), twitter_insight.getMetricTotal(this.post_data, 'replies')]
    ]
  }

  get engagements_data() {
    return this.reactions_data.concat([
      [t('その他アクション'), twitter_insight.getMetricTotal(this.post_data, 'other_actions')]
    ])
  }

  get other_actions_data() {
    const url_clicks = twitter_insight.getMetricTotal(this.post_data, 'url_clicks')
    const user_profile_clicks = twitter_insight.getMetricTotal(
      this.post_data,
      'user_profile_clicks'
    )

    if (!this.is_csv_import) {
      return [
        [t('URLクリック数'), url_clicks],
        [t('ユーザープロフィールクリック数'), user_profile_clicks]
      ]
    }

    const permalink_clicks = twitter_insight.getMetricTotal(this.post_data, 'permalink_clicks')
    const hashtag_clicks = twitter_insight.getMetricTotal(this.post_data, 'hashtag_clicks')
    const detail_expands = twitter_insight.getMetricTotal(this.post_data, 'detail_expands')
    const app_opens = twitter_insight.getMetricTotal(this.post_data, 'app_opens')
    const app_installs = twitter_insight.getMetricTotal(this.post_data, 'app_installs')
    const follows = twitter_insight.getMetricTotal(this.post_data, 'follows')
    const media_engagements = twitter_insight.getMetricTotal(this.post_data, 'media_engagements')

    return [
      [t('URLクリック数'), url_clicks],
      [t('固定リンククリック数'), permalink_clicks],
      [t('ハッシュタグクリック数'), hashtag_clicks],
      [t('ユーザープロフィールクリック数'), user_profile_clicks],
      [t('詳細クリック数'), detail_expands],
      [t('アプリ表示'), app_opens],
      [t('アプリインストール'), app_installs],
      [t('フォロワー獲得'), follows],
      [t('メディアのエンゲージメント数'), media_engagements]
    ]
  }

  get chart_options() {
    const chart_color1 = '#1c84c6'
    const chart_color2 = '#ed5565'
    const chart_color3 = '#2ec881'
    const chart_color4 = '#24cbe5'
    const chart_color5 = '#f8ac59'
    const chart_color6 = '#5e5e5e'
    const chart_color7 = '#9b9b9b'
    const chart_color8 = '#b2dfdb'
    const chart_color9 = '#9c27b0'

    switch (this.options.metric) {
      case 'reactions':
        return {
          title: t('反応数'),
          colors: [chart_color1, chart_color2, chart_color3, chart_color4],
          data: this.reactions_data
        }

      case 'engagements':
        return {
          title: t('エンゲージメント数'),
          colors: [chart_color1, chart_color2, chart_color3, chart_color4, chart_color5],
          data: this.engagements_data
        }

      case 'other_actions':
        return {
          title: t('その他アクション'),
          colors: [
            chart_color1,
            chart_color2,
            chart_color3,
            chart_color4,
            chart_color5,
            chart_color6,
            chart_color7,
            chart_color8,
            chart_color9
          ],
          data: this.other_actions_data
        }

      default:
        return {
          title: '',
          colors: [],
          data: []
        }
    }
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    TrackingService.sendEvent(
      `click:自社分析(TW)>${this.screen_name}|${this.options.metric}:CSVダウンロード`
    )

    const fields = [t('種別'), t('回数')]

    const data = this.chart_options.data

    const csv_data = { fields, data }

    const component_name = t('グラフ')
    const metric_name = t(this.metric_name)

    const csv_filename = [component_name, metric_name, this.start_date, this.end_date].join('_')

    csv.download(csv_data, csv_filename)
  }
}
