
import { computed, defineComponent, PropType } from 'vue'

import HelpLink from '@/client/components/molecules/HelpLink'
import ExternalLink from '@/client/components-old/atoms/ExternalLink'
import Message from '@/client/components-old/atoms/Message'
import Tips from '@/client/components-old/atoms/Tips'

export type DialogType = 'register' | 'update'

type SnsType = 'facebook' | 'twitter' | 'instagram' | 'tiktok' | null

export type ErrorType =
  | 'authentication_canceled'
  | 'permission_denied'
  | 'account_protected'
  | 'facebook_page_not_exist'
  | 'instagram_account_not_exist'
  | 'different_account'
  | 'oauth_failed'
  | 'add_account_failed'
  | 'access_token_update_failed'
  | null

export default defineComponent({
  name: 'SnsAccountError',
  components: {
    ExternalLink,
    Message,
    Tips,
    HelpLink
  },
  props: {
    dialogType: {
      type: String as PropType<DialogType>,
      required: true
    },
    snsType: {
      type: String as PropType<SnsType>,
      default: null
    },
    errorType: {
      type: String as PropType<ErrorType>,
      default: null
    }
  },
  setup(props) {
    const is_register = computed(() => props.dialogType === 'register')

    const is_update = computed(() => props.dialogType === 'update')

    const is_facebook = computed(() => props.snsType === 'facebook')

    const is_twitter = computed(() => props.snsType === 'twitter')

    const is_instagram = computed(() => props.snsType === 'instagram')

    const is_tiktok = computed(() => props.snsType === 'tiktok')

    const is_authentication_canceled = computed(() => props.errorType === 'authentication_canceled')

    const is_permission_denied = computed(() => props.errorType === 'permission_denied')

    const is_protected = computed(() => props.errorType === 'account_protected')

    const is_facebook_page_not_exist = computed(() => props.errorType === 'facebook_page_not_exist')

    const is_instagram_account_not_exist = computed(
      () => props.errorType === 'instagram_account_not_exist'
    )

    const is_different_account = computed(() => props.errorType === 'different_account')

    const is_oauth_failed = computed(() => props.errorType === 'oauth_failed')

    const is_add_account_failed = computed(() => props.errorType === 'add_account_failed')

    const is_access_token_update_failed = computed(
      () => props.errorType === 'access_token_update_failed'
    )

    return {
      is_register,
      is_update,
      is_facebook,
      is_twitter,
      is_instagram,
      is_tiktok,
      is_authentication_canceled,
      is_permission_denied,
      is_protected,
      is_facebook_page_not_exist,
      is_instagram_account_not_exist,
      is_different_account,
      is_oauth_failed,
      is_add_account_failed,
      is_access_token_update_failed
    }
  }
})
