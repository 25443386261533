import { distanceInWordsStrict } from 'date-fns' // eslint-disable-line
import timeagoJa from 'date-fns/locale/ja' // eslint-disable-line
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale'
import ja from 'element-ui/lib/locale/lang/ja'
import { TranslationOptions } from 'i18next'
import moment from 'moment-timezone'
import Vue from 'vue'
import VueHighcharts from 'vue-highcharts'
import VueMq from 'vue-mq'
import VueTimeago from 'vue-timeago'

import i18n from './i18n'
import Device from './plugins/device'

Vue.use(Device)

locale.use(ja)

Vue.use(ElementUI, { locale, zIndex: 99999 })
Vue.use(VueHighcharts, { Highcharts })
Vue.use(VueMq, { breakpoints: { sm: 600, md: 1201, lg: Infinity }, defaultBreakpoint: 'lg' })
Vue.use(VueTimeago, {
  locales: {
    ja: timeagoJa
  },
  converter(date, locale, { addSuffix = true }) {
    return distanceInWordsStrict(Date.now(), date, { locale, addSuffix })
  }
})

Vue.filter('date', (value: string | number | Date, format: string): string => {
  return moment(value).format(format)
})

Vue.filter('percent', (value: number): string => {
  if (!value) return '0%'
  return `${value.toLocaleString()}%`
})

Vue.filter('second', (value: number): string => {
  if (!value) return '0s'
  return `${value.toLocaleString()}s`
})

Vue.filter('updown', (value: number): string => {
  if (!value) return '0'
  if (Math.sign(value) === 1) return `+${value.toLocaleString()}`
  return value.toLocaleString()
})

Vue.filter('number', (value: number): string => {
  if (!value) return '0'
  return value.toLocaleString()
})

Vue.filter('hashtag', (value: string): string => {
  return `#${value}`
})

Vue.filter('translate', (value: string, options?: TranslationOptions): string => {
  return options ? i18n.t(value, options) : i18n.t(value)
})
