import { RouteConfig } from 'vue-router'

import InstagramAnalytics from '../pages/InstagramAnalytics.vue'

export const analytics_in: RouteConfig = {
  component: InstagramAnalytics,
  path: '/analytics_in',
  name: 'analytics_in',
  meta: {
    auth: true,
    title: '自社アカウント分析 (Instagram)',
    description: '選択したアカウントの分析結果を確認できます。',
    color: 'warning',
    type: 'CHECK_OWN_ANALYSIS'
  }
}
