import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import Icon from '@/client/components-old/atoms/Icon'
import Label from '@/client/components-old/atoms/Label'
import Account from '@/client/components-old/molecules/Account'

@Component({
  name: 'GroupSettingOperatorItem',
  components: {
    ButtonLink,
    Dropdown,
    Icon,
    Label,
    Account
  }
})
export default class GroupSettingOperatorItem extends Vue {
  @Prop({ type: Boolean, required: true })
  isDisabledCancel: boolean

  @Prop({ type: Object, required: true })
  operator: {
    id: number
    name: string
    picture_url: string
    is_admin: boolean
  }

  get dropdown_options() {
    const options = [
      {
        icon: 'shield',
        text: this.$options.filters.translate('権限設定'),
        value: 'role'
      },
      {
        icon: 'email',
        text: this.$options.filters.translate('通知メール設定'),
        value: 'notification'
      }
    ]

    if (!this.isDisabledCancel) {
      options.push({
        icon: 'clear',
        text: this.$options.filters.translate('登録解除'),
        value: 'cancel'
      })
    }

    return options
  }

  /**
   * コマンドを実行
   */
  execCommand(value: string): void {
    switch (value) {
      case 'role':
        this.openRoleDialog()
        break
      case 'notification':
        this.openNotificationDialog()
        break
      case 'cancel':
        this.cancelOperator()
        break
    }
  }

  /**
   * オペレーターの登録解除
   */
  cancelOperator(): void {
    let message = ''

    message += this.$options.filters.translate(
      '[[name]]さんのグループ登録を解除してよろしいですか？',
      { name: this.operator.name }
    )
    message += '\n'
    message += this.$options.filters.translate('この操作は取り消しできません。')

    const confirm = window.confirm(message)

    if (!confirm) return

    this.cancel()
  }

  @Emit('cancel')
  cancel() {
    return this.operator
  }

  @Emit('open-role-dialog')
  openRoleDialog() {
    return this.operator
  }

  @Emit('open-notification-dialog')
  openNotificationDialog() {
    return this.operator
  }
}
