import moment from 'moment-timezone'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { DATE_RANGE_LIMIT, MIN_DATE } from '@/client/components-old/_constants/global'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import i18n from '@/client/utils/i18n'

type Shortcut = { text: string; onClick: (picker: any) => void }

const analytics = namespace('analytics')

@Component({
  name: 'AnalyticsDateRangePicker'
})
export default class AnalyticsDateRangePicker extends Vue {
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']
  @analytics.Action('changeAnalyticsDateRange') changeAnalyticsDateRange

  selected_date = null

  get options() {
    const shortcuts: Shortcut[] = [
      {
        text: i18n.t('今月'),
        onClick(picker) {
          const start = moment().startOf('month')

          const end = moment().subtract(1, 'day').endOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('先週'),
        onClick(picker) {
          const start = moment().subtract(1, 'week').startOf('isoWeek')

          const end = moment().subtract(1, 'week').endOf('isoWeek')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('先月'),
        onClick(picker) {
          const start = moment().subtract(1, 'month').startOf('month')

          const end = moment().subtract(1, 'month').endOf('month')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: `${i18n.t('7日前')} - ${i18n.t('昨日')}`,
        onClick: picker => {
          const start = moment().subtract(7, 'day').startOf('day')

          const end = moment().subtract(1, 'day').endOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: `${i18n.t('30日前')} - ${i18n.t('昨日')}`,
        onClick: picker => {
          const start = moment().subtract(30, 'day').startOf('day')

          const end = moment().subtract(1, 'day').endOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: `${i18n.t('3ヶ月前')} - ${i18n.t('昨日')}`,
        onClick: picker => {
          const start = moment().subtract(3, 'month').startOf('day')

          const end = moment().subtract(1, 'day').endOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: `${i18n.t('6ヶ月前')} - ${i18n.t('昨日')}`,
        onClick: picker => {
          const start = moment().subtract(6, 'month').startOf('day')

          const end = moment().subtract(1, 'day').endOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      }
    ]

    // 月初の場合、今月の選択範囲が存在しない為、今月を削除する
    if (moment().date() === 1) {
      shortcuts.shift()
    }

    return {
      shortcuts,
      onPick: ({ maxDate, minDate }) => {
        this.selected_date = minDate.getTime()

        if (maxDate) {
          this.selected_date = null
        }
      },
      disabledDate: (time: Date) => {
        const start = moment(MIN_DATE).startOf('day')
        const end = moment().subtract(1, 'day').endOf('day')
        const date = moment(time).tz('Asia/Tokyo').startOf('day')

        if (!moment(date).isBetween(start, end, 'day', '[]')) return true

        if (this.selected_date) {
          const minTime = this.selected_date - DATE_RANGE_LIMIT
          const maxTime = this.selected_date + DATE_RANGE_LIMIT

          return time.getTime() < minTime || time.getTime() > maxTime
        }
      }
    }
  }

  @Emit('change')
  async changeDateRange(value: [string, string]): Promise<[string, string]> {
    await this.changeAnalyticsDateRange({
      start_date: value[0],
      end_date: value[1]
    })

    return value
  }

  /**
   * DateRangePickerを開く
   */
  openDateRange() {
    this.selected_date = null
  }
}
