/**
 * リサイズの遅延発火
 */
export function resize(): void {
  if (typeof Event === 'function') {
    // modern browsers
    window.dispatchEvent(new Event('resize'))
  } else {
    // for IE and other old browsers
    // causes deprecation warning on modern browsers
    const evt: any = window.document.createEvent('UIEvents')
    evt.initUIEvent('resize', true, false, window, 0)
    window.dispatchEvent(evt)
  }
}

/**
 * 遅延処理
 */
export function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * クリップボードにコピーする
 */
export function copy(message: string): void {
  const el = document.createElement('textarea')
  el.value = message
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export default { resize, delay, copy }
