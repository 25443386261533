import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  IPostSignupExistsParams,
  IPostSignupExistsResponse,
  IPostSignupParams,
  IPostSignupResponse
} from '@/client/utils/api/signup'
import { IGetUserWorkResponse, IUserWork } from '@/client/utils/api/user_works'

interface FormData {
  email: string
  password: string
  name: string
  company_name: string
  telephone: string
  user_work_ids: number[]
  terms_of_service: boolean
}

export interface IState {
  api_user_works: IUserWork[]
  form_data: FormData
  form_step: number
}

const init_form_data = {
  email: '',
  password: '',
  name: '',
  company_name: '',
  telephone: '',
  user_work_ids: [],
  terms_of_service: false
}

const state: IState = {
  api_user_works: [],
  form_data: init_form_data,
  form_step: 0
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  SET_API_USER_WORKS(state, payload: IUserWork[]) {
    state.api_user_works = payload
  },
  SET_FORM_DATA(state, payload: FormData) {
    state.form_data = payload
  },
  SET_FORM_STEP(state, payload: number) {
    state.form_step = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * ページ表示時の処理
   */
  async createdSignupPage(context) {
    await context.dispatch('fetchUserWorks')
  },

  /**
   * ページ離脱時の処理
   */
  async destroyedSignupPage(context) {
    context.commit('SET_API_USER_WORKS', [])
    context.commit('SET_FORM_DATA', init_form_data)
    context.commit('SET_FORM_STEP', 0)
  },

  /**
   * 主な業務の取得
   */
  async fetchUserWorks(context) {
    const response = await API.get<IGetUserWorkResponse>('user_works')

    if (response.data.data) {
      context.commit('SET_API_USER_WORKS', response.data.data)
    }
  },

  /**
   * フォーム内容の確認
   */
  async confirmFormData(context, payload: FormData) {
    context.commit('SET_FORM_DATA', { ...payload })

    const params: IPostSignupExistsParams = {
      email: context.state.form_data.email
    }

    const response = await API.post<IPostSignupExistsResponse>('signup/exists', params)

    if (!response.data.data) {
      context.commit('SET_FORM_STEP', 1)
    }

    return response.data
  },

  /**
   * フォーム内容のやり直し
   */
  async cancelFormData(context) {
    context.commit('SET_FORM_STEP', 0)
  },

  /**
   * フォーム内容の送信
   */
  async submitFormData(context) {
    if (!context.state.form_data.terms_of_service) return

    const params: IPostSignupParams = {
      email: context.state.form_data.email,
      password: context.state.form_data.password,
      name: context.state.form_data.name,
      language: context.rootState.user.language,
      timezone: context.rootState.user.timezone,
      company_name: context.state.form_data.company_name,
      telephone: context.state.form_data.telephone,
      user_work_ids: context.state.form_data.user_work_ids
    }

    const response = await API.post<IPostSignupResponse>('signup', params)

    if (response.data.data) {
      context.commit('SET_FORM_STEP', 2)
    }

    return response.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
