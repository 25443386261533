import { Component, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'
import Account from '@/client/components-old/molecules/Account'

type TAccountSelectOptions = {
  id: string
  name: string
  username?: string
  is_use_monitoring?: boolean
  img: string
  type?: 'facebook' | 'instagram' | 'twitter'
}

@Component({
  name: 'AccountSelect',
  components: {
    Icon,
    Account
  }
})
export default class AccountSelect extends Vue {
  @Prop({ type: String, required: true })
  value: string

  @Prop({ type: Array, required: true })
  options: TAccountSelectOptions[]

  @Prop({ type: String, default: 'primary' })
  color: 'primary' | 'white'

  @Prop({ type: Boolean, default: false })
  isMiniSizeInSm: boolean

  is_show = false

  get local_value() {
    const find = this.options.find(account => account.id === this.value)
    return find ? find : { id: '', name: '', img: '' }
  }

  /**
   * アカウントを追加
   */
  selectAccount(account_id: string): void {
    this.$emit('input', account_id)
    this.close()
  }

  /**
   * 表示する
   */
  open(): void {
    if (this.is_show) return this.close()
    this.is_show = true
    document.addEventListener('click', this.handleClick, false)
    document.addEventListener('touchstart', this.handleClick, false)
    document.addEventListener('keyup', this.handleKeyUp, false)
  }

  /**
   * 閉じる
   */
  close(): void {
    this.is_show = false
    document.removeEventListener('click', this.handleClick)
    document.removeEventListener('touchstart', this.handleClick)
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  /**
   * クリックイベント
   */
  handleClick(event: any): void {
    if (!this.$el.contains(event.target)) {
      this.close()
    }
  }

  /**
   * キーボードイベント
   */
  handleKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close()
    }
  }
}
