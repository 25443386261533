
import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { VideoThumbnailDialog } from '@/client/components/molecules'
import {
  execGoogleAuth,
  getBlobData,
  initBox,
  initDropbox,
  initGoogleDrive,
  initOneDrive,
  uploadFileFromBox,
  uploadFileFromDropbox,
  uploadFileFromGoogleDrive
} from '@/client/components-old/_utils/cloud_storage'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import Icon from '@/client/components-old/atoms/Icon'
import PostCreateCollageDialog from '@/client/components-old/organisms/PostCreateCollageDialog'
import StockphotoDialog from '@/client/components-old/organisms/StockphotoDialog'
import { CanvaService, TrackingService } from '@/client/services'
import {
  IGetter as IPostCreateGetter,
  IState as IPostCreateState
} from '@/client/store/modules/post_create'
import { IPostImage } from '@/client/utils/api/posts'
import { initGapi } from '@/client/utils/gapi'
import i18n from '@/client/utils/i18n'
import * as validate from '@/client/utils/validate'

const post_create = namespace('post_create')
const notification = namespace('notification')

@Component({
  name: 'PostCreateMediaStorage',
  components: {
    ButtonLink,
    Dropdown,
    Icon,
    PostCreateCollageDialog,
    StockphotoDialog,
    VideoThumbnailDialog
  }
})
export default class PostCreateMediaStorage extends Vue {
  @post_create.State('images') images!: IPostCreateState['images']
  @post_create.Getter('use_tiktok') use_tiktok!: IPostCreateGetter['use_tiktok']
  @post_create.Mutation('SET_TYPE') setType!: any
  @post_create.Mutation('SET_IMAGES') setImages!: (images: IPostImage[]) => void
  @post_create.Mutation('SET_IS_MEDIA_UPLOADING') setIsMediaUploading!: (payload: boolean) => void
  @notification.Action('showNotification') showNotification!: any

  $refs!: {
    StockphotoDialog: any
    PostCreateCollageDialog: any
  }

  is_expanded = false

  get accept() {
    return validate.IMAGE_TYPES.concat(validate.VIDEO_TYPES)
  }

  get canva_options() {
    return [
      { text: '1080 × 1080 px', value: '1080x1080', sns: ['twitter', 'facebook', 'instagram'] },
      { text: '1080 × 566 px', value: '1080x566', sns: ['facebook', 'instagram'] },
      { text: '1200 × 632 px', value: '1200x632', sns: ['facebook', 'instagram'] },
      { text: '1200 × 675 px', value: '1200x675', sns: ['twitter'] },
      { text: '1080 × 1350 px', value: '1080x1350', sns: ['instagram'] },
      { text: '1080 × 1920 px', value: '1080x1920', sns: ['facebook'] }
    ]
  }

  get is_show_canva() {
    return !this.use_tiktok && this.$mq !== 'sm'
  }

  get is_show_stockphoto() {
    return !this.use_tiktok
  }

  get is_show_collage() {
    return !this.use_tiktok && this.$mq !== 'sm'
  }

  get cloud_options() {
    return [
      { icon: 'google-drive', text: 'Google Drive', value: 'google_drive' },
      { icon: 'dropbox', text: 'Dropbox', value: 'dropbox' },
      { icon: 'onedrive', text: 'OneDrive', value: 'one_drive' },
      { icon: 'box', text: 'Box', value: 'box' }
    ]
  }

  @Emit('upload-canva')
  uploadCanva(payload: { file: Blob; canva_id: string }) {
    return payload
  }

  @Emit('upload-media')
  uploadMedia(blobs: Blob[]) {
    return blobs
  }

  mounted() {
    initGapi()
  }

  /**
   * ストックフォト画像を追加
   */
  setImageStockphoto(image_url: string): void {
    this.setType('image')

    const image: IPostImage = {
      sequence_no: 1,
      image_url,
      is_animation_gif: false,
      canva_id: null
    }

    this.setImages([image])
  }

  /**
   * ストックフォトを開く
   */
  openStockphotoDialog(event: any) {
    this.$refs.StockphotoDialog.open(event)

    TrackingService.sendEvent('click:投稿作成|メディア挿入|ストックフォト')
  }

  /**
   * コラージュを開く
   */
  openCollageDialog(event: any) {
    this.$refs.PostCreateCollageDialog.open(event)

    TrackingService.sendEvent('click:投稿作成|メディア挿入|コラージュ画像')
  }

  /**
   * コラージュ画像を追加
   */
  async addImageCollage(blob: Blob): Promise<void> {
    this.setType('image')

    this.uploadMedia([blob])
  }

  /**
   * Canvaのダイアログを開く
   */
  async uploadFromCanva(type: string) {
    let width = 1080
    let height = 1080

    switch (type) {
      case '1080x1080':
        width = 1080
        height = 1080

        TrackingService.sendEvent('select:投稿作成|メディア挿入|Canva:1080×1080')
        break
      case '1080x566':
        width = 1080
        height = 566

        TrackingService.sendEvent('select:投稿作成|メディア挿入|Canva:1080×566')
        break
      case '1200x632':
        width = 1200
        height = 632

        TrackingService.sendEvent('select:投稿作成|メディア挿入|Canva:1200×632')
        break
      case '1200x675':
        width = 1200
        height = 675

        TrackingService.sendEvent('select:投稿作成|メディア挿入|Canva:1200×675')
        break
      case '1080x1350':
        width = 1080
        height = 1350

        TrackingService.sendEvent('select:投稿作成|メディア挿入|Canva:1080×1350')
        break
      case '1080x1920':
        width = 1080
        height = 1920

        TrackingService.sendEvent('select:投稿作成|メディア挿入|Canva:1080×1920')
        break
    }

    CanvaService.createDesign({
      editor: {
        publishLabel: i18n.t('編集を終了')
      },
      design: {
        dimensions: {
          width,
          height
        }
      },
      onDesignPublish: async (options: { designId: string; exportUrl: string }) => {
        // ? 生成に時間がかかるため
        this.setIsMediaUploading(true)

        const blob = await getBlobData(options.exportUrl)

        this.uploadCanva({ file: blob, canva_id: options.designId })
      }
    })
  }

  /**
   * クラウドストレージから選択
   */
  async uploadFromCloud(type: string): Promise<void> {
    switch (type) {
      case 'google_drive':
        return this.uploadFromGoogleDrive()
      case 'one_drive':
        return this.uploadFromOneDrive()
      case 'box':
        return this.uploadFromBox()
      case 'dropbox':
        return this.uploadFromDropbox()
    }
  }

  /**
   * Google Driveからアップロード
   */
  async uploadFromGoogleDrive(): Promise<void> {
    TrackingService.sendEvent('select:投稿作成|メディア挿入|クラウドストレージ:GoogleDrive')

    const result = await execGoogleAuth()

    if (!result || !result.access_token) {
      return this.showNotification({ title: 'Google認証に失敗しました', type: 'error' })
    }

    const files = await initGoogleDrive(result.access_token, this.accept)

    if (!files || !files.length) return

    // ? 準備に時間がかかるため
    this.setIsMediaUploading(true)

    const blobs = await uploadFileFromGoogleDrive(files, result.access_token)

    this.uploadMedia(blobs)
  }

  /**
   * OneDriveからのファイルアップロード
   */
  async uploadFromOneDrive(): Promise<void> {
    TrackingService.sendEvent('select:投稿作成|メディア挿入|クラウドストレージ:OneDrive')

    const files = await initOneDrive()

    if (!files.length) return

    // ? 準備に時間がかかるため
    this.setIsMediaUploading(true)

    const blobs = await Promise.all(
      files.map(file => getBlobData(file['@microsoft.graph.downloadUrl']))
    )

    this.uploadMedia(blobs)
  }

  /**
   * Dropboxからのファイルアップロード
   */
  async uploadFromDropbox(): Promise<void> {
    TrackingService.sendEvent('select:投稿作成|メディア挿入|クラウドストレージ:Dropbox')

    const files = await initDropbox()

    if (!files.length) return

    // ? 準備に時間がかかるため
    this.setIsMediaUploading(true)

    const blobs = await uploadFileFromDropbox(files)

    this.uploadMedia(blobs)
  }

  /**
   * Boxからのファイルアップロード
   */
  async uploadFromBox(): Promise<void> {
    TrackingService.sendEvent('select:投稿作成|メディア挿入|クラウドストレージ:Box')

    const files = await initBox()

    // ? 準備に時間がかかるため
    this.setIsMediaUploading(true)

    const blobs = await uploadFileFromBox(files)

    this.uploadMedia(blobs)
  }

  /**
   * 全て表示
   */
  expand() {
    this.is_expanded = true

    TrackingService.sendEvent('click:投稿作成|メディア挿入|全て表示')
  }

  /**
   * 折りたたむ
   */
  collapse() {
    this.is_expanded = false

    TrackingService.sendEvent('click:投稿作成|メディア挿入|折りたたむ')
  }
}
