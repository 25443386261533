
import { computed, defineComponent, ref } from 'vue'
import { mapActions, mapState } from 'vuex'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Radio from '@/client/components-old/atoms/Radio'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import UserMultiSelect from '@/client/components-old/molecules/UserMultiSelect'
import { getTranslateText as t } from '@/client/utils/filters'

type PostApprovalFlowOptions = {
  id: number
  name: string
  project_id: number
  step_users: {
    id: number
    name: string
    picture_url: string
  }[][]
}

export default defineComponent({
  name: 'GroupSettingPostApprovalFlowDetailDialog',
  components: {
    Button,
    Dialog,
    Icon,
    Input,
    Radio,
    Tooltip,
    Message,
    UserMultiSelect
  },
  setup() {
    const visible = ref(false)
    const loading = ref(false)
    const is_change_name = ref(false)
    const flow_id = ref(0)
    const name = ref('')
    const number_steps = ref(1)
    const old_steps = ref(1)
    const step_1_user_ids = ref<number[]>([])
    const step_2_user_ids = ref<number[]>([])
    const step_3_user_ids = ref<number[]>([])

    const is_step_1_invalid = computed(() => {
      return !step_1_user_ids.value.length
    })

    const is_step_2_invalid = computed(() => {
      return number_steps.value >= 2 && !step_2_user_ids.value.length
    })

    const is_step_3_invalid = computed(() => {
      return number_steps.value >= 3 && !step_3_user_ids.value.length
    })

    const is_title_empty = computed(() => {
      return is_change_name.value && !name.value.trim()
    })

    const is_disabled_submit = computed(
      () =>
        loading.value ||
        !step_1_user_ids.value.length ||
        (number_steps.value >= 2 && !step_2_user_ids.value.length) ||
        (number_steps.value >= 3 && !step_3_user_ids.value.length) ||
        !(name.value && name.value.length)
    )

    const close = () => {
      flow_id.value = 0
      is_change_name.value = false
      name.value = ''
      number_steps.value = 1
      step_1_user_ids.value = []
      step_2_user_ids.value = []
      step_3_user_ids.value = []
      visible.value = false
    }

    return {
      visible,
      loading,
      is_disabled_submit,
      is_change_name,
      number_steps,
      old_steps,
      flow_id,
      name,
      step_1_user_ids,
      step_2_user_ids,
      step_3_user_ids,
      is_step_1_invalid,
      is_step_2_invalid,
      is_step_3_invalid,
      is_title_empty,
      close,
      t
    }
  },
  computed: {
    ...mapState('group_setting', ['api_operators']),

    operator_options() {
      return this.api_operators.map(operator => operator.user)
    }
  },
  methods: {
    ...mapActions('group_setting', ['createPostApprovalFlow', 'updatePostApprovalFlow']),
    ...mapActions('notification', ['showNotification']),

    async open(options?: PostApprovalFlowOptions) {
      this.visible = true

      if (options) {
        this.flow_id = options.id
        this.name = options.name
        this.number_steps = options.step_users.length
        this.old_steps = options.step_users.length

        if (options.step_users.length) {
          const [step_1, step_2, step_3] = options.step_users
          this.step_1_user_ids = step_1?.map(item => item.id) ?? []
          this.step_2_user_ids = step_2?.map(item => item.id) ?? []
          this.step_3_user_ids = step_3?.map(item => item.id) ?? []
        }
      }
    },

    /**
     * 変化
     */
    changeName() {
      this.is_change_name = true
    },

    /**
     * フォームの送信
     */
    async saveApprovalFlow() {
      this.name = this.name.trim()

      if (this.is_disabled_submit) return

      const step_user_ids = []

      if (this.number_steps >= 1) {
        step_user_ids.push(this.step_1_user_ids)
      }

      if (this.number_steps >= 2) {
        step_user_ids.push(this.step_2_user_ids)
      }

      if (this.number_steps === 3) {
        step_user_ids.push(this.step_3_user_ids)
      }

      this.loading = true

      if (this.flow_id) {
        if (step_user_ids.length < this.old_steps) {
          const message = t(
            '保存をすると、この承認フローを使用している投稿の承認が最初からやり直しになります。よろしいですか？'
          )
          const confirm = window.confirm(message)

          if (!confirm) {
            this.loading = false
            this.close()

            return
          }
        }

        const result = await this.updatePostApprovalFlow({
          flow_id: this.flow_id,
          name: this.name,
          step_user_ids
        })

        setTimeout(() => {
          this.loading = false
        }, 1000)

        if (result.data) {
          this.showNotification({ title: '承認フローを保存しました。' })
          this.$emit('update')
          this.close()
        } else if (result.error && result.error.type === 'DUPLICATE') {
          this.showNotification({
            title: '承認フロー名を変更してください。',
            message: '同じ名前の承認フロー名がすでに存在しています。',
            type: 'error'
          })
        } else {
          this.showNotification({
            title: '承認フローの保存に失敗しました。',
            message: '恐れ入りますが、時間をおいて再度お試しください。',
            type: 'error'
          })
        }
      } else {
        const result = await this.createPostApprovalFlow({ name: this.name, step_user_ids })

        setTimeout(() => {
          this.loading = false
        }, 1000)

        if (result.data) {
          this.showNotification({ title: '承認フローを追加しました。' })
          this.$emit('create')
          this.close()
        } else if (result.error && result.error.type === 'DUPLICATE') {
          this.showNotification({
            title: '承認フロー名を変更してください。',
            message: '同じ名前の承認フロー名がすでに存在しています。',
            type: 'error'
          })
        } else {
          this.showNotification({
            title: '承認フローの追加に失敗しました。',
            message: '恐れ入りますが、時間をおいて再度お試しください。',
            type: 'error'
          })
        }
      }
    }
  }
})
