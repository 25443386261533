import { Component, Emit, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import GroupContractCancelDialog from '@/client/components-old/organisms/GroupContractCancelDialog'
import GroupSettingBilling from '@/client/components-old/organisms/GroupSettingBilling'
import GroupSettingContract from '@/client/components-old/organisms/GroupSettingContract'

@Component({
  name: 'GroupSettingContractLayout',
  components: {
    Scroll,
    GroupSettingBilling,
    GroupSettingContract,
    GroupContractCancelDialog
  }
})
export default class GroupSettingContractLayout extends Vue {
  $refs: {
    GroupContractCancelDialog: any
  }

  @Emit('change-vue')
  changeVue(payload: any) {
    return payload
  }

  /**
   * グループの解約ダイアログの表示
   */
  openGroupContractCancelDialog(payload: any) {
    this.$refs.GroupContractCancelDialog.open(payload)
  }
}
