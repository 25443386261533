import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import {
  CONTRACT_STATUS_CANCEL,
  CONTRACT_STATUS_CONTRACT,
  CONTRACT_STATUS_TRIAL
} from '@/client/components-old/_constants/project'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import { TCountry, TGetCountriesResponse } from '@/client/utils/api/countries'
import {
  TDeleteMonitoringExcludeKeywordsResponse,
  TGetMonitoringExcludeKeywordsParams,
  TGetMonitoringExcludeKeywordsResponse,
  TMonitoringExcludeKeyword,
  TMonitoringExcludeKeywordSnsType,
  TPostMonitoringExcludeKeywordsParams,
  TPostMonitoringExcludeKeywordsResponse,
  TPutMonitoringExcludeKeywordsParams,
  TPutMonitoringExcludeKeywordsResponse
} from '@/client/utils/api/monitoring_exclude_keywords'
import {
  TDeleteMonitoringKeywordsResponse,
  TGetMonitoringKeywordResponse,
  TGetMonitoringKeywordsParams,
  TGetMonitoringKeywordsResponse,
  TMonitoringKeyword,
  TMonitoringKeywordType,
  TPostMonitoringKeywordsParams,
  TPostMonitoringKeywordsResponse,
  TPutMonitoringKeywordsParams,
  TPutMonitoringKeywordsResponse
} from '@/client/utils/api/monitoring_keywords'
import {
  TGetMonitoringSettingsResponse,
  TMonitoringSetting,
  TPutMonitoringSettingsParams,
  TPutMonitoringSettingsResponse
} from '@/client/utils/api/monitoring_settings'
import {
  TDeletePostKeywordsResponse,
  TGetPostKeywordsParams,
  TGetPostKeywordsResponse,
  TPostKeyword,
  TPostPostKeywordsParams,
  TPostPostKeywordsResponse,
  TPutPostKeywordsParams,
  TPutPostKeywordsResponse
} from '@/client/utils/api/post_keywords'
import {
  TGetPostSettingsResponse,
  TPostSetting,
  TPutPostSettingsParams,
  TPutPostSettingsResponse
} from '@/client/utils/api/post_settings'
import {
  TGetProjectBillingsResponse,
  TPostProjectBillingsCancelParams,
  TPostProjectBillingsCancelResponse,
  TProjectBilling
} from '@/client/utils/api/project_billings'
import {
  DeleteProjectPostApprovalFlowsParams,
  DeleteProjectPostApprovalFlowsResponse,
  GetProjectPostApprovalFlowsParams,
  GetProjectPostApprovalFlowsResponse,
  PostProjectPostApprovalFlowsParams,
  PostProjectPostApprovalFlowsResponse,
  ProjectPostApprovalFlow,
  PutProjectPostApprovalFlowsChangeOrderNoParams,
  PutProjectPostApprovalFlowsChangeOrderNoResponse,
  PutProjectPostApprovalFlowsParams,
  PutProjectPostApprovalFlowsResponse
} from '@/client/utils/api/project_post_approval_flows'
import {
  TDomainType,
  TGetProjectShortUrlDomainsResponse,
  TProjectShortUrlDomain,
  TPutProjectShortUrlDomainsParams,
  TPutProjectShortUrlDomainsResponse
} from '@/client/utils/api/project_short_url_domains'
import { TPutProjectParams, TPutProjectResponse } from '@/client/utils/api/projects'
import { UsersRoles } from '@/client/utils/api/users'
import { SnsType } from '@/common/types'

const init_post_settings: TPostSetting = {
  check_2byte1: false,
  check_2byte2: false,
  check_kana: false,
  check_punctuation: false,
  check_space: false,
  instagram_comment_enabled: true,
  tiktok_is_duet: true,
  tiktok_is_comment: true,
  tiktok_is_stitch: true,
  tiktok_is_brand_organic: false,
  tiktok_is_branded_content: false
}

const init_short_url_domains: TProjectShortUrlDomain = {
  domain: '',
  type: 'lnky'
}

const init_monitoring_settings: TMonitoringSetting = {
  hide_fb_post: false,
  hide_fb_post_own: false,
  hide_fb_comment: false,
  hide_fb_comment_own: false,
  hide_fb_message: false,
  hide_fb_message_own: false,
  hide_tw_reply: false,
  hide_tw_reply_own: false,
  hide_tw_mention: false,
  hide_tw_mention_own: false,
  hide_tw_message: false,
  hide_tw_message_own: false,
  hide_in_comment: false,
  hide_in_comment_own: false,
  reply_to_read: false,
  like_to_read: false,
  hide_to_read: false,
  delete_to_read: false,
  observer_allow_reply: false,
  observer_allow_like: false,
  observer_allow_retweet: false,
  observer_allow_block: false,
  observer_allow_hidden: false,
  observer_allow_delete: false,
  is_valid_global_keywords: false
}

const init_group_billings: TProjectBilling = {
  id: 0,
  company_name: '',
  depart_name: null,
  person_name: '',
  email: '',
  telephone: '',
  country_code: '',
  zip_code: '',
  address: ''
}

export interface IState {
  api_operators: UsersRoles[]
  api_post_settings: TPostSetting
  api_post_keywords: TPostKeyword[]
  api_post_approval_flows: ProjectPostApprovalFlow[]
  api_short_url_domains: TProjectShortUrlDomain
  api_monitoring_settings: TMonitoringSetting
  api_monitoring_keywords: TMonitoringKeyword[]
  api_monitoring_exclude_keywords: TMonitoringExcludeKeyword[]
  api_group_billings: TProjectBilling
  api_countries: TCountry[]
  screen_name: 'account' | 'operator' | 'function' | 'contract'
  function_tab_name: 'post' | 'analytics' | 'monitoring' | 'group'
  function_sns_type: TMonitoringExcludeKeywordSnsType
  is_loading: boolean
}

const state: IState = {
  api_operators: [],
  api_post_settings: init_post_settings,
  api_post_keywords: [],
  api_post_approval_flows: [],
  api_short_url_domains: init_short_url_domains,
  api_monitoring_settings: init_monitoring_settings,
  api_monitoring_keywords: [],
  api_monitoring_exclude_keywords: [],
  api_group_billings: init_group_billings,
  api_countries: [],
  screen_name: 'account',
  function_tab_name: 'post',
  function_sns_type: 'facebook',
  is_loading: true
}

export interface IGetter {
  monitoring_keyword_categories: string[]
}

const getters: GetterTree<IState, IRootState> = {
  monitoring_keyword_categories(state): IGetter['monitoring_keyword_categories'] {
    return state.api_monitoring_keywords
      .map(v => v.category)
      .filter(v => v)
      .filter((v, i, self) => self.indexOf(v) === i)
  }
}

const mutations: MutationTree<IState> = {
  SET_API_OPERATORS(state, payload) {
    state.api_operators = payload
  },
  SET_API_POST_SETTINGS(state, payload) {
    state.api_post_settings = payload
  },
  SET_API_POST_KEYWORDS(state, payload) {
    state.api_post_keywords = payload
  },
  SET_API_POST_APPROVAL_FLOWS(state, payload) {
    state.api_post_approval_flows = payload
  },
  SET_API_SHORT_URL_DOMAINS(state, payload) {
    state.api_short_url_domains = payload
  },
  SET_API_MONITORING_SETTINGS(state, payload) {
    state.api_monitoring_settings = payload
  },
  SET_API_MONITORING_KEYWORDS(state, payload) {
    state.api_monitoring_keywords = payload
  },
  SET_API_MONITORING_EXCLUDE_KEYWORDS(state, payload) {
    state.api_monitoring_exclude_keywords = payload
  },
  SET_API_GROUP_BILLINGS(state, payload) {
    state.api_group_billings = payload
  },
  SET_API_COUNTRIES(state, payload) {
    state.api_countries = payload
  },
  SET_SCREEN_NAME(state, payload) {
    state.screen_name = payload
  },
  SET_FUNCTION_TAB_NAME(state, payload) {
    state.function_tab_name = payload
  },
  SET_FUNCTION_SNS_TYPE(state, payload) {
    state.function_sns_type = payload
  },
  SET_IS_LOADING(state, payload) {
    state.is_loading = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * ページ表示時の処理
   */
  async createdGroupSetting(context) {
    const types = [CONTRACT_STATUS_CONTRACT, CONTRACT_STATUS_CANCEL]

    // 契約中または解約待ち以外の場合に契約内容のタブに居る場合
    if (
      !types.includes(context.rootState.project.contract_status) &&
      context.state.screen_name === 'contract'
    ) {
      await context.dispatch('changeScreenName', 'account')
    }

    context.commit('SET_IS_LOADING', true)

    const promises = [
      context.dispatch('fetchOperators'),
      context.dispatch('fetchPostSettings'),
      context.dispatch('fetchPostKeywords'),
      context.dispatch('fetchPostApprovalFlows'),
      context.dispatch('fetchShortUrlDomains'),
      context.dispatch('fetchMonitoringSettings'),
      context.dispatch('fetchMonitoringKeywords'),
      context.dispatch('fetchMonitoringExcludeKeywords'),
      context.dispatch('fetchCountries')
    ]

    if (context.rootState.project.contract_status !== CONTRACT_STATUS_TRIAL) {
      promises.push(context.dispatch('fetchGroupBillings'))
    }

    await Promise.all(promises)

    context.commit('SET_IS_LOADING', false)
  },

  /**
   * ページ離脱時の処理
   */
  async destroyedGroupSetting(context) {
    context.commit('SET_API_OPERATORS', [])
    context.commit('SET_API_POST_SETTINGS', init_post_settings)
    context.commit('SET_API_POST_KEYWORDS', [])
    context.commit('SET_API_POST_APPROVAL_FLOWS', [])
    context.commit('SET_API_SHORT_URL_DOMAINS', init_short_url_domains)
    context.commit('SET_API_MONITORING_SETTINGS', init_monitoring_settings)
    context.commit('SET_API_MONITORING_KEYWORDS', [])
    context.commit('SET_API_MONITORING_EXCLUDE_KEYWORDS', [])
    context.commit('SET_API_GROUP_BILLINGS', init_group_billings)
    context.commit('SET_API_COUNTRIES', [])
  },

  /**
   * 表示タブを変更
   */
  async changeScreenName(context, payload: string) {
    context.commit('SET_SCREEN_NAME', payload)
  },

  /**
   * 機能設定の表示タブを変更
   */
  async changeFunctionTabName(context, payload: string) {
    context.commit('SET_FUNCTION_TAB_NAME', payload)
  },

  /**
   * 機能設定のSNSタブを変更
   */
  async changeFunctionSnsType(context, payload: string) {
    context.commit('SET_FUNCTION_SNS_TYPE', payload)

    await context.dispatch('fetchMonitoringExcludeKeywords')
  },

  /**
   * オペレーターの一覧を取得
   */
  async fetchOperators(context) {
    const params = {
      project_id: context.rootState.project.id
    }

    const response = await API.get('users/roles', { params })

    if (response.data.data) {
      context.commit('SET_API_OPERATORS', response.data.data)
    }
  },

  /**
   * オペレーターの権限を取得
   */
  async fetchOperatorRoles(context, payload: { user_id: number }) {
    const params = {
      project_id: context.rootState.project.id
    }

    const response = await API.get(`users/${payload.user_id}/roles`, { params })

    return response.data
  },

  /**
   * オペレーターのグループ権限を更新
   */
  async updateOperatorGroupRoles(
    context,
    payload: {
      user_id: number
      role_admin: boolean
      role_analytics_setting: boolean
      role_comparison: boolean
      role_keyword: boolean
      role_viral: boolean
    }
  ) {
    const params = {
      project_id: context.rootState.project.id,
      role_admin: payload.role_admin,
      role_analytics_setting: payload.role_analytics_setting,
      role_comparison: payload.role_comparison,
      role_keyword: payload.role_keyword,
      role_viral: payload.role_viral
    }

    const response = await API.put(`users/${payload.user_id}/group_roles`, params)

    if (response.data.data) {
      await context.dispatch('fetchOperators')
    }

    return response.data
  },

  /**
   * オペレーターのグループ権限を解除
   */
  async removeOperatorGroupRoles(context, payload: { user_id: number }) {
    const params = {
      project_id: context.rootState.project.id
    }

    const response = await API.delete(`users/${payload.user_id}/group_roles`, { params })

    if (response.data.data) {
      await context.dispatch('fetchOperators')
    }

    return response.data
  },

  /**
   * オペレーターのSNS権限を更新
   */
  async updateOperatorSnsRoles(
    context,
    payload: {
      user_id: number
      sns_type: SnsType
      account_id: string
      role_post: string
      role_analytics: string
      role_monitoring: string
    }
  ) {
    const params = {
      user_id: payload.user_id,
      role_post: payload.role_post,
      role_analytics: payload.role_analytics,
      role_monitoring: payload.role_monitoring
    }

    const response = await API.put(
      `${payload.sns_type}_accounts/${payload.account_id}/roles`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchOperators')
    }

    return response.data
  },

  /**
   * 投稿の設定を取得
   */
  async fetchPostSettings(context) {
    const response = await API.get<TGetPostSettingsResponse>(
      `post_settings/${context.rootState.project.id}`
    )

    if (response.data.data) {
      context.commit('SET_API_POST_SETTINGS', response.data.data)
    }
  },

  /**
   * 投稿の設定を更新
   */
  async updatePostSettings(context, payload: TPutPostSettingsParams) {
    const params: TPutPostSettingsParams = {
      ...payload
    }

    const response = await API.put<TPutPostSettingsResponse>(
      `post_settings/${context.rootState.project.id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchPostSettings')
    }

    return response.data
  },

  /**
   * 投稿キーワードの設定を取得
   */
  async fetchPostKeywords(context) {
    const params: TGetPostKeywordsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<TGetPostKeywordsResponse>('post_keywords', { params })

    if (response.data.data) {
      context.commit('SET_API_POST_KEYWORDS', response.data.data)
    }
  },

  /**
   * 投稿キーワードの設定を作成
   */
  async createPostKeywords(context, payload: { keywords: string[] }) {
    const params: TPostPostKeywordsParams = {
      project_id: context.rootState.project.id,
      ...payload
    }

    const response = await API.post<TPostPostKeywordsResponse>('post_keywords', params)

    if (response.data.data) {
      await context.dispatch('fetchPostKeywords')
    }

    return response.data
  },

  /**
   * 投稿キーワードの設定を更新
   */
  async updatePostKeywords(
    context,
    payload: {
      keyword_id: number
      is_valid: boolean
    }
  ) {
    const params: TPutPostKeywordsParams = {
      is_valid: payload.is_valid
    }

    const response = await API.put<TPutPostKeywordsResponse>(
      `post_keywords/${payload.keyword_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchPostKeywords')
    }

    return response.data
  },

  /**
   * 投稿キーワードの設定を削除
   */
  async removePostKeywords(context, payload: { keyword_id: number }) {
    const response = await API.delete<TDeletePostKeywordsResponse>(
      `post_keywords/${payload.keyword_id}`
    )

    if (response.data.data) {
      await context.dispatch('fetchPostKeywords')
    }

    return response.data
  },

  /**
   * 短縮URLの設定を取得
   */
  async fetchShortUrlDomains(context) {
    const response = await API.get<TGetProjectShortUrlDomainsResponse>(
      `project_short_url_domains/${context.rootState.project.id}`
    )

    if (response.data.data) {
      context.commit('SET_API_SHORT_URL_DOMAINS', response.data.data)
    }
  },

  /**
   * 短縮URLの設定を更新
   */
  async updateShortUrlDomains(context, payload: { domain: string; type: TDomainType }) {
    const params: TPutProjectShortUrlDomainsParams = {
      ...payload
    }

    const response = await API.put<TPutProjectShortUrlDomainsResponse>(
      `project_short_url_domains/${context.rootState.project.id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchShortUrlDomains')
    }

    return response.data
  },

  /**
   * クチコミ分析の設定を更新
   */
  async updateAnalyticsKeywordHashtagSettings(
    context,
    payload: { keyword_hashtag_max_post_count: number }
  ) {
    const params: TPutProjectParams = {
      ...payload
    }

    const response = await API.put<TPutProjectResponse>(
      `projects/${context.rootState.project.id}`,
      params
    )

    if (response.data) {
      await context.dispatch('fetchProjects', null, { root: true })
      await context.dispatch('fetchProjectData', context.rootState.project.id, { root: true })
    }

    return response.data
  },

  /**
   * モニタリングの設定を取得
   */
  async fetchMonitoringSettings(context) {
    const response = await API.get<TGetMonitoringSettingsResponse>(
      `monitoring_settings/${context.rootState.project.id}`
    )

    if (response.data.data) {
      context.commit('SET_API_MONITORING_SETTINGS', response.data.data)
    }
  },

  /**
   * モニタリングの設定を更新
   */
  async updateMonitoringSettings(context, payload: TPutMonitoringSettingsParams) {
    const params: TPutMonitoringSettingsParams = {
      ...payload
    }

    const response = await API.put<TPutMonitoringSettingsResponse>(
      `monitoring_settings/${context.rootState.project.id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchMonitoringSettings')
    }

    return response.data
  },

  /**
   * モニタリングの監視キーワード一覧を取得
   */
  async fetchMonitoringKeywords(context) {
    const params: TGetMonitoringKeywordsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<TGetMonitoringKeywordsResponse>('monitoring_keywords', {
      params
    })

    if (response.data.data) {
      context.commit('SET_API_MONITORING_KEYWORDS', response.data.data)
    }
  },

  /**
   * モニタリングの監視キーワードを取得
   */
  async fetchMonitoringKeyword(context, payload: { keyword_id: number }) {
    const response = await API.get<TGetMonitoringKeywordResponse>(
      `monitoring_keywords/${payload.keyword_id}`
    )

    return response.data
  },

  /**
   * モニタリングの監視キーワードを作成
   */
  async createMonitoringKeyword(
    context,
    payload: {
      keywords: string[]
      exclude_keywords: string[]
      category: string | null
      type: TMonitoringKeywordType
    }
  ) {
    const params: TPostMonitoringKeywordsParams = {
      project_id: context.rootState.project.id,
      ...payload
    }

    const response = await API.post<TPostMonitoringKeywordsResponse>('monitoring_keywords', params)

    if (response.data.data) {
      await context.dispatch('fetchMonitoringKeywords')
    }

    return response.data
  },

  /**
   * モニタリングの監視キーワードを更新
   */
  async updateMonitoringKeyword(
    context,
    payload: {
      keyword_id: number
      keywords: string[]
      exclude_keywords: string[]
      category: string | null
      is_valid: boolean
      type: TMonitoringKeywordType
    }
  ) {
    const params: TPutMonitoringKeywordsParams = {
      keywords: payload.keywords,
      exclude_keywords: payload.exclude_keywords,
      category: payload.category,
      is_valid: payload.is_valid,
      type: payload.type
    }

    const response = await API.put<TPutMonitoringKeywordsResponse>(
      `monitoring_keywords/${payload.keyword_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchMonitoringKeywords')
    }

    return response.data
  },

  /**
   * モニタリングの監視キーワードを削除
   */
  async removeMonitoringKeyword(context, payload: { keyword_id: number }) {
    const response = await API.delete<TDeleteMonitoringKeywordsResponse>(
      `monitoring_keywords/${payload.keyword_id}`
    )

    if (response.data.data) {
      await context.dispatch('fetchMonitoringKeywords')
    }

    return response.data
  },

  /**
   * モニタリングの除外キーワード一覧を取得
   */
  async fetchMonitoringExcludeKeywords(context) {
    const params: TGetMonitoringExcludeKeywordsParams = {
      project_id: context.rootState.project.id,
      sns_type: context.state.function_sns_type
    }

    const response = await API.get<TGetMonitoringExcludeKeywordsResponse>(
      'monitoring_exclude_keywords',
      { params }
    )

    if (response.data.data) {
      context.commit('SET_API_MONITORING_EXCLUDE_KEYWORDS', response.data.data)
    }
  },

  /**
   * モニタリングの除外キーワードを作成
   */
  async createMonitoringExcludeKeyword(context, payload: { keywords: string[] }) {
    const params: TPostMonitoringExcludeKeywordsParams = {
      project_id: context.rootState.project.id,
      sns_type: context.state.function_sns_type,
      ...payload
    }

    const response = await API.post<TPostMonitoringExcludeKeywordsResponse>(
      'monitoring_exclude_keywords',
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchMonitoringExcludeKeywords')
    }

    return response.data
  },

  /**
   * モニタリングの除外キーワードを更新
   */
  async updateMonitoringExcludeKeyword(
    context,
    payload: {
      keyword_id: number
      is_valid: boolean
    }
  ) {
    const params: TPutMonitoringExcludeKeywordsParams = {
      is_valid: payload.is_valid
    }

    const response = await API.put<TPutMonitoringExcludeKeywordsResponse>(
      `monitoring_exclude_keywords/${payload.keyword_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchMonitoringExcludeKeywords')
    }

    return response.data
  },

  /**
   * モニタリングの除外キーワードを削除
   */
  async removeMonitoringExcludeKeyword(context, payload: { keyword_id: number }) {
    const response = await API.delete<TDeleteMonitoringExcludeKeywordsResponse>(
      `monitoring_exclude_keywords/${payload.keyword_id}`
    )

    if (response.data.data) {
      await context.dispatch('fetchMonitoringExcludeKeywords')
    }

    return response.data
  },

  /**
   * グループの設定を更新
   */
  async updateGroupSettings(
    context,
    payload: {
      project_name?: string
      allow_ip_addresses?: string[]
    }
  ) {
    const params: TPutProjectParams = {
      ...payload
    }

    const response = await API.put<TPutProjectResponse>(
      `projects/${context.rootState.project.id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchProjects', null, { root: true })
      await context.dispatch('fetchProjectData', context.rootState.project.id, { root: true })
    }

    return response.data
  },

  /**
   * グループの契約内容を取得
   */
  async fetchGroupBillings(context) {
    const response = await API.get<TGetProjectBillingsResponse>(
      `project_billings/${context.rootState.project.id}`
    )

    if (response.data.data) {
      context.commit('SET_API_GROUP_BILLINGS', response.data.data)
    }
  },

  /**
   * グループの契約を解約
   */
  async cancelGroupBillings(context, payload: TPostProjectBillingsCancelParams) {
    const params: TPostProjectBillingsCancelParams = {
      ...payload
    }

    const response = await API.post<TPostProjectBillingsCancelResponse>(
      `project_billings/${context.rootState.project.id}/cancel`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchGroupBillings')
    }

    return response.data
  },

  /**
   * 国の一覧を取得
   */
  async fetchCountries(context) {
    const response = await API.get<TGetCountriesResponse>('countries')

    if (response.data.data) {
      context.commit('SET_API_COUNTRIES', response.data.data)
    }
  },

  /**
   * 承認フロー一覧を取得
   */
  async fetchPostApprovalFlows(context) {
    const params: GetProjectPostApprovalFlowsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<GetProjectPostApprovalFlowsResponse>(
      'project_post_approval_flows',
      {
        params
      }
    )

    if (response.data.data) {
      context.commit('SET_API_POST_APPROVAL_FLOWS', response.data.data)
    }
  },

  /**
   * 承認フローを作成
   */
  async createPostApprovalFlow(context, payload: { name: string; step_user_ids: number[][] }) {
    const params: PostProjectPostApprovalFlowsParams = {
      project_id: context.rootState.project.id,
      ...payload
    }

    const response = await API.post<PostProjectPostApprovalFlowsResponse>(
      'project_post_approval_flows',
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchPostApprovalFlows')
    }

    return response.data
  },

  /**
   * 承認フローを編集
   */
  async updatePostApprovalFlow(
    context,
    payload: {
      flow_id: number
      name: string
      step_user_ids: number[][]
    }
  ) {
    const params: PutProjectPostApprovalFlowsParams = {
      project_id: context.rootState.project.id,
      name: payload.name,
      step_user_ids: payload.step_user_ids
    }

    const response = await API.put<PutProjectPostApprovalFlowsResponse>(
      `project_post_approval_flows/${payload.flow_id}`,
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchPostApprovalFlows')
    }

    return response.data
  },

  /**
   * 承認フローを削除
   */
  async removePostApprovalFlow(context, payload: { flow_id: number }) {
    const params: DeleteProjectPostApprovalFlowsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.delete<DeleteProjectPostApprovalFlowsResponse>(
      `project_post_approval_flows/${payload.flow_id}`,
      {
        params
      }
    )

    if (response.data.data) {
      await context.dispatch('fetchPostApprovalFlows')
    }

    return response.data
  },

  /**
   * 承認フローの順番を変更
   */
  async changePostApprovalFlowOrder(context, payload: ProjectPostApprovalFlow[]) {
    // ? 更新までの間に古い状態が描画されるのを防ぐため、一度commitする
    context.commit('SET_API_POST_APPROVAL_FLOWS', payload)

    const params: PutProjectPostApprovalFlowsChangeOrderNoParams = {
      project_id: context.rootState.project.id,
      flow_ids: payload.map(v => v.id)
    }

    const response = await API.put<PutProjectPostApprovalFlowsChangeOrderNoResponse>(
      `project_post_approval_flows/change_order_no`,
      params
    )

    await context.dispatch('fetchPostApprovalFlows')

    return response.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
