import { ref } from 'vue'

import API from '@/client/utils/api'
import {
  TDeleteSnsAccountCategoryResponses,
  TGetSnsAccountCategoryParams,
  TGetSnsAccountCategoryResponses,
  TPostSnsAccountCategoryParams,
  TPostSnsAccountCategoryResponses,
  TPutSnsAccountCategoryChangeOrderNoParams,
  TPutSnsAccountCategoryChangeOrderNoResponses,
  TPutSnsAccountCategoryParams,
  TPutSnsAccountCategoryResponses,
  TSnsAccountCategory
} from '@/client/utils/api/sns_account_categories'

export const useAccountsTag = (getProjectId: () => number) => {
  const project_id = getProjectId()
  const sns_account_categories = ref<TSnsAccountCategory[]>([])
  const is_accounts_loading = ref(false)

  const fetchSnsAccountCategories = async () => {
    const params: TGetSnsAccountCategoryParams = {
      project_id
    }

    is_accounts_loading.value = true

    const response = await API.get<TGetSnsAccountCategoryResponses>('sns_account_categories', {
      params
    })

    is_accounts_loading.value = false

    if (response.data) {
      sns_account_categories.value = response.data.data
    }

    return response
  }

  const createSnsAccountCategory = async (name: string) => {
    const params: TPostSnsAccountCategoryParams = {
      project_id,
      name
    }

    const response = await API.post<TPostSnsAccountCategoryResponses>(
      'sns_account_categories',
      params
    )
    if (response.data) {
      await fetchSnsAccountCategories()
    }

    return response
  }

  const updateSnsAccountCategory = async (category_id: number, name: string) => {
    const params: TPutSnsAccountCategoryParams = { name }

    const response = await API.put<TPutSnsAccountCategoryResponses>(
      `sns_account_categories/${category_id}`,
      params
    )

    await fetchSnsAccountCategories()

    return response
  }

  const updateSnsAccountCategoryOrder = async (categories: TSnsAccountCategory[]) => {
    sns_account_categories.value = categories

    const category_ids = categories.map(category => category.id)

    const params: TPutSnsAccountCategoryChangeOrderNoParams = {
      project_id,
      category_ids: category_ids
    }

    const response = await API.put<TPutSnsAccountCategoryChangeOrderNoResponses>(
      'sns_account_categories/change_order_no',
      params
    )

    await fetchSnsAccountCategories()

    return response
  }

  const removeSnsAccountCategory = async (category_id: number) => {
    const response = await API.delete<TDeleteSnsAccountCategoryResponses>(
      `sns_account_categories/${category_id}`
    )

    await fetchSnsAccountCategories()

    return response
  }

  return {
    is_accounts_loading,
    sns_account_categories,
    fetchSnsAccountCategories,
    createSnsAccountCategory,
    updateSnsAccountCategory,
    updateSnsAccountCategoryOrder,
    removeSnsAccountCategory
  }
}
