import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { PostTagsManagementDialog } from '@/client/components/organisms/Tags'
import Button from '@/client/components-old/atoms/Button'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { TrackingService } from '@/client/services'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as IPostCreateState } from '@/client/store/modules/post_create'

const categories = namespace('categories')
const post_create = namespace('post_create')

@Component({
  name: 'PostCreateCategory',
  components: {
    PostTagsManagementDialog,
    Button,
    Flex,
    Icon,
    Panel,
    Tooltip,
    SelectMulti
  }
})
export default class PostCreateCategory extends Vue {
  @categories.State('api_post_categories') post_categories: ICategoriesState['api_post_categories']
  @post_create.State('categories') categories: IPostCreateState['categories']
  @post_create.Mutation('SET_CATEGORIES') setCategories

  $refs: {
    PostTagsManagementDialog: any
  }

  @Watch('post_categories', { immediate: true })
  changeCategories() {
    const post_categories = this.post_categories.map(v => v.id)
    const categories = this.categories.filter(v => post_categories.indexOf(v) > -1)

    this.setCategories(categories)
  }

  get options() {
    return this.post_categories.map(category => ({
      value: category.id,
      text: category.name
    }))
  }

  /**
   * タグを指定
   */
  updateCategories(categories) {
    TrackingService.sendEvent('click:投稿作成|タグ|タグを指定')

    this.setCategories(categories)
  }

  /**
   * タグ管理を開く
   */
  openTagDialog(event: any) {
    TrackingService.sendEvent('click:投稿作成|タグ|タグの管理')

    this.$refs.PostTagsManagementDialog.open(event)
  }
}
