import moment from 'moment-timezone'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  IDeleteTwitterKeywordParams,
  IDeleteTwitterKeywordResponse,
  IGetTwitterKeywordsGraphParams,
  IGetTwitterKeywordsGraphResponse,
  IGetTwitterKeywordsHashtagsParams,
  IGetTwitterKeywordsHashtagsResponse,
  IGetTwitterKeywordsHeatmapsParams,
  IGetTwitterKeywordsHeatmapsResponse,
  IGetTwitterKeywordsParams,
  IGetTwitterKeywordsPostsParams,
  IGetTwitterKeywordsPostsResponse,
  IGetTwitterKeywordsRankingParams,
  IGetTwitterKeywordsRankingResponse,
  IGetTwitterKeywordsResponse,
  IGetTwitterKeywordsSentimentCountParams,
  IGetTwitterKeywordsSentimentCountResponse,
  IGetTwitterKeywordsSummaryParams,
  IGetTwitterKeywordsSummaryResponse,
  IGetTwitterKeywordsWordsParams,
  IGetTwitterKeywordsWordsResponse,
  IPostTwitterKeywordPostCategoriesParams,
  IPostTwitterKeywordPostCategoriesResponse,
  IPostTwitterKeywordsParams,
  IPostTwitterKeywordsResponse,
  IPutTwitterKeywordParams,
  IPutTwitterKeywordResponse,
  IPutTwitterKeywordsChangeOrderNoParams,
  IPutTwitterKeywordsChangeOrderNoResponse,
  IPutTwitterKeywordStatusParams,
  IPutTwitterKeywordStatusResponse,
  ITwitterKeyword,
  ITwitterKeywordsGraph,
  ITwitterKeywordsHashtag,
  ITwitterKeywordsPosts,
  ITwitterKeywordsRanking,
  ITwitterKeywordsSentimentCount,
  ITwitterKeywordsSummary,
  ITwitterKeywordsWord,
  TwitterKeywordsHeatmap
} from '@/client/utils/api/twitter_keywords'
import storage from '@/client/utils/storage'

export type ScreenName = 'summary' | 'post' | 'management'
export type DisplayFormat = 'average' | 'total'
export type GraphInterval = 'hour' | 'day' | 'week' | 'month'
export type PostDisplayTab = 'table' | 'grid'
export type RankingSort =
  | 'reaction_count'
  | 'follower_count'
  | 'retweet_count'
  | 'quote_count'
  | 'like_count'
  | 'reply_count'

export type HeatmapMetric =
  | 'post_count'
  | 'reaction_count'
  | 'follower_count'
  | 'retweet_count'
  | 'quote_count'
  | 'like_count'
  | 'reply_count'

export interface IState {
  api_settings: ITwitterKeyword[]
  api_summary: ITwitterKeywordsSummary[]
  api_post_count: ITwitterKeywordsGraph[]
  api_sentiment_count: ITwitterKeywordsSentimentCount | null
  api_follower_count: ITwitterKeywordsGraph[]
  api_heatmaps: TwitterKeywordsHeatmap[]
  api_word_cloud: ITwitterKeywordsWord[]
  api_words: ITwitterKeywordsWord[]
  api_hashtags: ITwitterKeywordsHashtag[]
  api_ranking: ITwitterKeywordsRanking[]
  api_posts: ITwitterKeywordsPosts
  keyword_ids: number[]
  start_date: string
  end_date: string
  screen_name: ScreenName
  summary_display_order: string
  summary_display_format: DisplayFormat
  post_count_interval: GraphInterval
  post_count_index: number
  sentiment_count_interval: GraphInterval
  sentiment_count_index: number
  follower_count_interval: GraphInterval
  follower_count_index: number
  heatmap_keyword_id: number | ''
  heatmap_metric: HeatmapMetric
  heatmap_display_format: DisplayFormat
  word_cloud_keyword_id: number | ''
  word_cloud_size: 25 | 50 | 75 | 100
  words_keyword_id: number | ''
  words_size: 25 | 50 | 75 | 100
  hashtags_keyword_id: number | ''
  hashtags_size: 25 | 50 | 75 | 100
  ranking_sort: RankingSort
  ranking_size: 5 | 10 | 25 | 50
  filter_tweet_types: string[]
  filter_post_types: string[]
  filter_sentiment: string
  filter_category_ids: number[]
  filter_search_keyword: string
  post_display_tab: PostDisplayTab
  post_display_order: string
  post_display_offset: number
  post_display_count: 10 | 25 | 50 | 100
  is_loading: boolean
  is_summary_loading: boolean
  is_post_count_loading: boolean
  is_sentiment_count_loading: boolean
  is_follower_count_loading: boolean
  is_heatmap_loading: boolean
  is_word_cloud_loading: boolean
  is_words_loading: boolean
  is_hashtags_loading: boolean
  is_ranking_loading: boolean
  is_posts_loading: boolean
}

export interface IGetter {
  post_count_date_range: { start_date: string; end_date: string }
  sentiment_count_date_range: { start_date: string; end_date: string }
  follower_count_date_range: { start_date: string; end_date: string }
  is_data: boolean
  is_fetch_loading: boolean
}

const state: IState = {
  api_settings: [],
  api_summary: [],
  api_post_count: [],
  api_sentiment_count: null,
  api_follower_count: [],
  api_heatmaps: [],
  api_word_cloud: [],
  api_words: [],
  api_hashtags: [],
  api_ranking: [],
  api_posts: { posts: [], total: 0 },
  keyword_ids: [],
  start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  end_date: moment().startOf('day').format('YYYY-MM-DD'),
  screen_name: 'summary',
  summary_display_order: 'keyword_name',
  summary_display_format: 'total',
  post_count_interval: 'day',
  post_count_index: 0,
  sentiment_count_interval: 'day',
  sentiment_count_index: 0,
  follower_count_interval: 'day',
  follower_count_index: 0,
  heatmap_keyword_id: '',
  heatmap_metric: 'post_count',
  heatmap_display_format: 'total',
  word_cloud_keyword_id: '',
  word_cloud_size: 50,
  words_keyword_id: '',
  words_size: 25,
  hashtags_keyword_id: '',
  hashtags_size: 25,
  ranking_sort: 'reaction_count',
  ranking_size: 5,
  filter_tweet_types: [],
  filter_post_types: [],
  filter_sentiment: '',
  filter_category_ids: [],
  filter_search_keyword: '',
  post_display_tab: 'table',
  post_display_order: '-reaction_count',
  post_display_offset: 0,
  post_display_count: 25,
  is_loading: false,
  is_summary_loading: false,
  is_post_count_loading: false,
  is_sentiment_count_loading: false,
  is_follower_count_loading: false,
  is_heatmap_loading: false,
  is_word_cloud_loading: false,
  is_words_loading: false,
  is_hashtags_loading: false,
  is_ranking_loading: false,
  is_posts_loading: false
}

const getters: GetterTree<IState, IRootState> = {
  post_count_date_range(state): IGetter['post_count_date_range'] {
    let subtract_day = 0

    switch (state.post_count_interval) {
      case 'hour':
        subtract_day = state.post_count_index * 7
        break
      case 'day':
        subtract_day = state.post_count_index * 31
        break
      case 'week':
        subtract_day = state.post_count_index * 31 * 7
        break
      case 'month':
        subtract_day = state.post_count_index * 13 * 31
    }

    const end_date = moment(state.end_date).subtract(subtract_day, 'day')

    let start_date = end_date.clone().subtract(30, 'day')

    switch (state.post_count_interval) {
      case 'hour':
        start_date = end_date.clone().subtract(6, 'day')
        break
      case 'day':
        start_date = end_date.clone().subtract(30, 'day')
        break
      case 'week':
        start_date = end_date.clone().subtract(31 * 7 - 1, 'day')
        break
      case 'month':
        start_date = moment(state.start_date)
    }

    if (start_date.isBefore(moment(state.start_date))) {
      start_date = moment(state.start_date)
    }

    return {
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD')
    }
  },
  sentiment_count_date_range(state): IGetter['sentiment_count_date_range'] {
    let subtract_day = 0

    switch (state.sentiment_count_interval) {
      case 'hour':
        subtract_day = state.sentiment_count_index * 7
        break
      case 'day':
        subtract_day = state.sentiment_count_index * 31
        break
      case 'week':
        subtract_day = state.sentiment_count_index * 31 * 7
        break
      case 'month':
        subtract_day = state.sentiment_count_index * 13 * 31
    }

    const end_date = moment(state.end_date).subtract(subtract_day, 'day')

    let start_date = end_date.clone().subtract(30, 'day')

    switch (state.sentiment_count_interval) {
      case 'hour':
        start_date = end_date.clone().subtract(6, 'day')
        break
      case 'day':
        start_date = end_date.clone().subtract(30, 'day')
        break
      case 'week':
        start_date = end_date.clone().subtract(31 * 7 - 1, 'day')
        break
      case 'month':
        start_date = moment(state.start_date)
    }

    if (start_date.isBefore(moment(state.start_date))) {
      start_date = moment(state.start_date)
    }

    return {
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD')
    }
  },
  follower_count_date_range(state): IGetter['follower_count_date_range'] {
    let subtract_day = 0

    switch (state.follower_count_interval) {
      case 'hour':
        subtract_day = state.follower_count_index * 7
        break
      case 'day':
        subtract_day = state.follower_count_index * 31
        break
      case 'week':
        subtract_day = state.follower_count_index * 31 * 7
        break
      case 'month':
        subtract_day = state.follower_count_index * 13 * 31
    }

    const end_date = moment(state.end_date).subtract(subtract_day, 'day')

    let start_date = end_date.clone().subtract(30, 'day')

    switch (state.follower_count_interval) {
      case 'hour':
        start_date = end_date.clone().subtract(6, 'day')
        break
      case 'day':
        start_date = end_date.clone().subtract(30, 'day')
        break
      case 'week':
        start_date = end_date.clone().subtract(31 * 7 - 1, 'day')
        break
      case 'month':
        start_date = moment(state.start_date)
    }

    if (start_date.isBefore(moment(state.start_date))) {
      start_date = moment(state.start_date)
    }

    return {
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD')
    }
  },
  is_data(state): IGetter['is_data'] {
    return state.api_summary.some(v => v.post_count > 0)
  },
  is_fetch_loading(state): IGetter['is_fetch_loading'] {
    return (
      state.is_loading ||
      state.is_summary_loading ||
      state.is_post_count_loading ||
      state.is_sentiment_count_loading ||
      state.is_follower_count_loading ||
      state.is_heatmap_loading ||
      state.is_word_cloud_loading ||
      state.is_words_loading ||
      state.is_hashtags_loading ||
      state.is_ranking_loading ||
      state.is_posts_loading
    )
  }
}

const mutations: MutationTree<IState> = {
  SET_API_SETTINGS(state, payload: ITwitterKeyword[]) {
    state.api_settings = payload
  },
  SET_API_SUMMARY(state, payload: ITwitterKeywordsSummary[]) {
    state.api_summary = payload
  },
  SET_API_POST_COUNT(state, payload: ITwitterKeywordsGraph[]) {
    state.api_post_count = payload
  },
  SET_API_SENTIMENT_COUNT(state, payload: ITwitterKeywordsSentimentCount) {
    state.api_sentiment_count = payload
  },
  SET_API_FOLLOWER_COUNT(state, payload: ITwitterKeywordsGraph[]) {
    state.api_follower_count = payload
  },
  SET_API_HEATMAP(state, payload: TwitterKeywordsHeatmap[]) {
    state.api_heatmaps = payload
  },
  SET_API_WORD_CLOUD(state, payload: ITwitterKeywordsWord[]) {
    state.api_word_cloud = payload
  },
  SET_API_WORDS(state, payload: ITwitterKeywordsWord[]) {
    state.api_words = payload
  },
  SET_API_HASHTAGS(state, payload: ITwitterKeywordsHashtag[]) {
    state.api_hashtags = payload
  },
  SET_API_RANKING(state, payload: ITwitterKeywordsRanking[]) {
    state.api_ranking = payload
  },
  SET_API_POSTS(state, payload: ITwitterKeywordsPosts) {
    state.api_posts = payload
  },
  SET_KEYWORD_IDS(state, payload: number[]) {
    state.keyword_ids = payload

    storage.set('twitter_keyword', { keyword_ids: payload })
  },
  SET_DATE_RANGE(state, payload: [string, string]) {
    state.start_date = payload[0]
    state.end_date = payload[1]
  },
  SET_SCREEN_NAME(state, payload: ScreenName) {
    state.screen_name = payload
  },
  SET_SUMMARY_DISPLAY_ORDER(state, payload: string) {
    state.summary_display_order = payload
  },
  SET_SUMMARY_DISPLAY_FORMAT(state, payload: DisplayFormat) {
    state.summary_display_format = payload
  },
  SET_POST_COUNT_INTERVAL(state, payload: GraphInterval) {
    state.post_count_interval = payload
  },
  SET_POST_COUNT_INDEX(state, payload: number) {
    state.post_count_index = payload
  },
  SET_SENTIMENT_COUNT_INTERVAL(state, payload: GraphInterval) {
    state.sentiment_count_interval = payload
  },
  SET_SENTIMENT_COUNT_INDEX(state, payload: number) {
    state.sentiment_count_index = payload
  },
  SET_FOLLOWER_COUNT_INTERVAL(state, payload: GraphInterval) {
    state.follower_count_interval = payload
  },
  SET_FOLLOWER_COUNT_INDEX(state, payload: number) {
    state.follower_count_index = payload
  },
  SET_HEATMAP_KEYWORD_ID(state, payload: number | '') {
    state.heatmap_keyword_id = payload
  },
  SET_HEATMAP_METRIC(state, payload: HeatmapMetric) {
    state.heatmap_metric = payload
  },
  SET_HEATMAP_DISPLAY_FORMAT(state, payload: DisplayFormat) {
    state.heatmap_display_format = payload
  },
  SET_WORD_CLOUD_KEYWORD_ID(state, payload: number | '') {
    state.word_cloud_keyword_id = payload
  },
  SET_WORD_CLOUD_SIZE(state, payload: 25 | 50 | 75 | 100) {
    state.word_cloud_size = payload
  },
  SET_WORDS_KEYWORD_ID(state, payload: number | '') {
    state.words_keyword_id = payload
  },
  SET_WORDS_SIZE(state, payload: 25 | 50 | 75 | 100) {
    state.words_size = payload
  },
  SET_HASHTAGS_KEYWORD_ID(state, payload: number | '') {
    state.hashtags_keyword_id = payload
  },
  SET_HASHTAGS_SIZE(state, payload: 25 | 50 | 75 | 100) {
    state.hashtags_size = payload
  },
  SET_RANKING_SORT(state, payload: RankingSort) {
    state.ranking_sort = payload
  },
  SET_RANKING_SIZE(state, payload: 5 | 10 | 25 | 50) {
    state.ranking_size = payload
  },
  SET_FILTER_TWEET_TYPE(state, payload: string[]) {
    state.filter_tweet_types = payload
  },
  SET_FILTER_POST_TYPE(state, payload: string[]) {
    state.filter_post_types = payload
  },
  SET_FILTER_SENTIMENT(state, payload: string) {
    state.filter_sentiment = payload
  },
  SET_FILTER_CATEGORY_IDS(state, payload: number[]) {
    state.filter_category_ids = payload
  },
  SET_FILTER_SEARCH_KEYWORD(state, payload: string) {
    state.filter_search_keyword = payload
  },
  SET_POST_DISPLAY_TAB(state, payload: PostDisplayTab) {
    state.post_display_tab = payload
  },
  SET_POST_DISPLAY_ORDER(state, payload: string) {
    state.post_display_order = payload
  },
  SET_POST_DISPLAY_OFFSET(state, payload: number) {
    state.post_display_offset = payload
  },
  SET_POST_DISPLAY_COUNT(state, payload: 10 | 25 | 50 | 100) {
    state.post_display_count = payload
  },
  SET_IS_LOADING(state, payload: boolean) {
    state.is_loading = payload
  },
  SET_IS_SUMMARY_LOADING(state, payload: boolean) {
    state.is_summary_loading = payload
  },
  SET_IS_POST_COUNT_LOADING(state, payload: boolean) {
    state.is_post_count_loading = payload
  },
  SET_IS_SENTIMENT_COUNT_LOADING(state, payload: boolean) {
    state.is_sentiment_count_loading = payload
  },
  SET_IS_FOLLOWER_COUNT_LOADING(state, payload: boolean) {
    state.is_follower_count_loading = payload
  },
  SET_IS_HEATMAP_LOADING(state, payload: boolean) {
    state.is_heatmap_loading = payload
  },
  SET_IS_WORD_CLOUD_LOADING(state, payload: boolean) {
    state.is_word_cloud_loading = payload
  },
  SET_IS_WORDS_LOADING(state, payload: boolean) {
    state.is_words_loading = payload
  },
  SET_IS_HASHTAGS_LOADING(state, payload: boolean) {
    state.is_hashtags_loading = payload
  },
  SET_IS_RANKING_LOADING(state, payload: boolean) {
    state.is_ranking_loading = payload
  },
  SET_IS_POSTS_LOADING(state, payload: boolean) {
    state.is_posts_loading = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * ページ表示時の処理
   */
  async createdTwitterKeywordPage(context) {
    context.commit('SET_IS_LOADING', true)

    await context.dispatch('fetchTwitterKeywords')

    context.commit('SET_IS_LOADING', false)

    if (!context.state.api_settings.length) {
      context.commit('SET_SCREEN_NAME', 'summary')
    }

    const local: { keyword_ids?: number[] } = storage.get('twitter_keyword')

    let keyword_ids: number[] = []

    // ストレージに保存されていたら使用する
    if (local && local.keyword_ids && Array.isArray(local.keyword_ids)) {
      keyword_ids = local.keyword_ids.filter(keyword =>
        context.state.api_settings.map(v => v.id).includes(keyword)
      )
    }

    context.commit('SET_KEYWORD_IDS', keyword_ids)

    if (!keyword_ids.length) return

    await Promise.all([
      context.dispatch('fetchKeywordSummary'),
      context.dispatch('fetchKeywordPostCount'),
      context.dispatch('fetchKeywordSentimentCount'),
      context.dispatch('fetchKeywordFollowerCount'),
      context.dispatch('fetchKeywordHeatmap'),
      context.dispatch('fetchKeywordWordCloud'),
      context.dispatch('fetchKeywordWords'),
      context.dispatch('fetchKeywordHashtags'),
      context.dispatch('fetchKeywordRanking'),
      context.dispatch('fetchKeywordPosts'),
      context.dispatch('fetchProjectData', context.rootState.project.id, { root: true })
    ])
  },

  /**
   * ページ離脱時の処理
   */
  destroyedTwitterKeywordPage(context) {
    context.commit('SET_API_SETTINGS', [])
    context.commit('SET_API_SUMMARY', [])
    context.commit('SET_API_POST_COUNT', [])
    context.commit('SET_API_SENTIMENT_COUNT', [])
    context.commit('SET_API_FOLLOWER_COUNT', [])
    context.commit('SET_API_HEATMAP', [])
    context.commit('SET_API_WORD_CLOUD', [])
    context.commit('SET_API_WORDS', [])
    context.commit('SET_API_HASHTAGS', [])
    context.commit('SET_API_RANKING', [])
    context.commit('SET_API_POSTS', { posts: [], total: 0 })
    context.commit('SET_POST_COUNT_INTERVAL', 'day')
    context.commit('SET_POST_COUNT_INDEX', 0)
    context.commit('SET_SENTIMENT_COUNT_INTERVAL', 'day')
    context.commit('SET_SENTIMENT_COUNT_INDEX', 0)
    context.commit('SET_FOLLOWER_COUNT_INTERVAL', 'day')
    context.commit('SET_FOLLOWER_COUNT_INDEX', 0)
    context.commit('SET_POST_DISPLAY_OFFSET', 0)
    context.commit('SET_HEATMAP_KEYWORD_ID', '')
    context.commit('SET_WORD_CLOUD_KEYWORD_ID', '')
    context.commit('SET_WORDS_KEYWORD_ID', '')
    context.commit('SET_HASHTAGS_KEYWORD_ID', '')
  },

  /**
   * グループ変更時の処理
   */
  async changeGroupTwitterKeywordPage(context) {
    await context.dispatch('destroyedTwitterKeywordPage')

    context.commit('SET_FILTER_TWEET_TYPE', [])
    context.commit('SET_FILTER_POST_TYPE', [])
    context.commit('SET_FILTER_CATEGORY_IDS', [])
    context.commit('SET_FILTER_SEARCH_KEYWORD', '')

    await context.dispatch('createdTwitterKeywordPage')
  },

  /**
   * 分析データの更新
   */
  async refreshTwitterKeyword(context) {
    if (!state.keyword_ids.length) return

    await Promise.all([
      context.dispatch('fetchTwitterKeywords'),
      context.dispatch('fetchKeywordSummary'),
      context.dispatch('fetchKeywordPostCount'),
      context.dispatch('fetchKeywordSentimentCount'),
      context.dispatch('fetchKeywordFollowerCount'),
      context.dispatch('fetchKeywordHeatmap'),
      context.dispatch('fetchKeywordWordCloud'),
      context.dispatch('fetchKeywordWords'),
      context.dispatch('fetchKeywordHashtags'),
      context.dispatch('fetchKeywordRanking'),
      context.dispatch('fetchKeywordPosts'),
      context.dispatch('fetchProjectData', context.rootState.project.id, { root: true })
    ])
  },

  /**
   * 基本データの取得
   */
  async fetchKeywordSummary(context) {
    const params: IGetTwitterKeywordsSummaryParams = {
      project_id: context.rootState.project.id,
      keyword_ids: context.state.keyword_ids.join(','),
      start_date: context.state.start_date,
      end_date: context.state.end_date
    }

    context.commit('SET_IS_SUMMARY_LOADING', true)

    const response = await API.get<IGetTwitterKeywordsSummaryResponse>('twitter_keywords/summary', {
      params
    })

    context.commit('SET_IS_SUMMARY_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_SUMMARY', response.data.data)
    }

    return response
  },

  /**
   * 投稿数の取得
   */
  async fetchKeywordPostCount(context) {
    const { start_date, end_date }: IGetter['post_count_date_range'] =
      context.getters.post_count_date_range

    const params: IGetTwitterKeywordsGraphParams = {
      project_id: context.rootState.project.id,
      keyword_ids: context.state.keyword_ids.join(','),
      start_date,
      end_date,
      interval: context.state.post_count_interval,
      metric: 'post_count'
    }

    context.commit('SET_IS_POST_COUNT_LOADING', true)

    const response = await API.get<IGetTwitterKeywordsGraphResponse>('twitter_keywords/graph', {
      params
    })

    context.commit('SET_IS_POST_COUNT_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_POST_COUNT', response.data.data)
    }

    return response
  },

  /**
   * 投稿数のCSVのデータを取得する
   */
  async fetchKeywordPostCountCsv(context) {
    const params: IGetTwitterKeywordsGraphParams = {
      project_id: context.rootState.project.id,
      keyword_ids: context.state.keyword_ids.join(','),
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      interval: context.state.post_count_interval,
      metric: 'post_count'
    }

    const response = await API.get<IGetTwitterKeywordsGraphResponse>('twitter_keywords/graph', {
      params
    })

    return response.data
  },

  /**
   * センチメントの取得
   */
  async fetchKeywordSentimentCount(context) {
    const { start_date, end_date }: IGetter['sentiment_count_date_range'] =
      context.getters.sentiment_count_date_range

    const params: IGetTwitterKeywordsSentimentCountParams = {
      project_id: context.rootState.project.id,
      keyword_ids: context.state.keyword_ids.join(','),
      start_date,
      end_date,
      interval: context.state.sentiment_count_interval
    }

    context.commit('SET_IS_SENTIMENT_COUNT_LOADING', true)

    const response = await API.get<IGetTwitterKeywordsSentimentCountResponse>(
      'twitter_keywords/sentiment_count',
      { params }
    )

    context.commit('SET_IS_SENTIMENT_COUNT_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_SENTIMENT_COUNT', response.data.data)
    }

    return response
  },

  /**
   * センチメントのCSVのデータを取得する
   */
  async fetchKeywordSentimentCountCsv(context) {
    const params: IGetTwitterKeywordsSentimentCountParams = {
      project_id: context.rootState.project.id,
      keyword_ids: context.state.keyword_ids.join(','),
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      interval: context.state.sentiment_count_interval
    }

    const response = await API.get<IGetTwitterKeywordsSentimentCountResponse>(
      'twitter_keywords/sentiment_count',
      { params }
    )

    return response.data
  },

  /**
   * フォロワー数の取得
   */
  async fetchKeywordFollowerCount(context) {
    const { start_date, end_date }: IGetter['follower_count_date_range'] =
      context.getters.follower_count_date_range

    const params: IGetTwitterKeywordsGraphParams = {
      project_id: context.rootState.project.id,
      keyword_ids: context.state.keyword_ids.join(','),
      start_date,
      end_date,
      interval: context.state.follower_count_interval,
      metric: 'follower_count'
    }

    context.commit('SET_IS_FOLLOWER_COUNT_LOADING', true)

    const response = await API.get<IGetTwitterKeywordsGraphResponse>('twitter_keywords/graph', {
      params
    })

    context.commit('SET_IS_FOLLOWER_COUNT_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_FOLLOWER_COUNT', response.data.data)
    }

    return response
  },

  /**
   * フォロワー数のCSVのデータを取得する
   */
  async fetchKeywordFollowerCountCsv(context) {
    const params: IGetTwitterKeywordsGraphParams = {
      project_id: context.rootState.project.id,
      keyword_ids: context.state.keyword_ids.join(','),
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      interval: context.state.follower_count_interval,
      metric: 'follower_count'
    }

    const response = await API.get<IGetTwitterKeywordsGraphResponse>('twitter_keywords/graph', {
      params
    })

    return response.data
  },

  /**
   * ワードクラウドの取得
   */
  async fetchKeywordWordCloud(context) {
    const keyword_ids =
      String(context.state.word_cloud_keyword_id) || context.state.keyword_ids.join(',')

    const params: IGetTwitterKeywordsWordsParams = {
      project_id: context.rootState.project.id,
      keyword_ids,
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      size: context.state.word_cloud_size
    }

    context.commit('SET_IS_WORD_CLOUD_LOADING', true)

    const response = await API.get<IGetTwitterKeywordsWordsResponse>('twitter_keywords/words', {
      params
    })

    context.commit('SET_IS_WORD_CLOUD_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_WORD_CLOUD', response.data.data)
    }

    return response
  },

  /**
   * ヒートマップの取得
   */
  async fetchKeywordHeatmap(context) {
    const keyword_ids =
      String(context.state.heatmap_keyword_id) || context.state.keyword_ids.join(',')

    const params: IGetTwitterKeywordsHeatmapsParams = {
      project_id: context.rootState.project.id,
      keyword_ids,
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      metric: context.state.heatmap_metric,
      aggregate: context.state.heatmap_display_format
    }

    context.commit('SET_IS_HEATMAP_LOADING', true)

    const response = await API.get<IGetTwitterKeywordsHeatmapsResponse>(
      'twitter_keywords/heatmaps',
      { params }
    )

    context.commit('SET_IS_HEATMAP_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_HEATMAP', response.data.data)
    }

    return response
  },

  /**
   * ワード一覧の取得
   */
  async fetchKeywordWords(context) {
    const keyword_ids =
      String(context.state.words_keyword_id) || context.state.keyword_ids.join(',')

    const params: IGetTwitterKeywordsWordsParams = {
      project_id: context.rootState.project.id,
      keyword_ids: keyword_ids,
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      size: context.state.words_size
    }

    context.commit('SET_IS_WORDS_LOADING', true)

    const response = await API.get<IGetTwitterKeywordsWordsResponse>('twitter_keywords/words', {
      params
    })

    context.commit('SET_IS_WORDS_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_WORDS', response.data.data)
    }

    return response
  },

  /**
   * ハッシュタグ一覧の取得
   */
  async fetchKeywordHashtags(context) {
    const keyword_ids =
      String(context.state.hashtags_keyword_id) || context.state.keyword_ids.join(',')

    const params: IGetTwitterKeywordsHashtagsParams = {
      project_id: context.rootState.project.id,
      keyword_ids: keyword_ids,
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      size: context.state.hashtags_size
    }

    context.commit('SET_IS_HASHTAGS_LOADING', true)

    const response = await API.get<IGetTwitterKeywordsHashtagsResponse>(
      'twitter_keywords/hashtags',
      { params }
    )

    context.commit('SET_IS_HASHTAGS_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_HASHTAGS', response.data.data)
    }

    return response
  },

  /**
   * ランキングの取得
   */
  async fetchKeywordRanking(context) {
    const params: IGetTwitterKeywordsRankingParams = {
      project_id: context.rootState.project.id,
      keyword_ids: context.state.keyword_ids.join(','),
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      sort: context.state.ranking_sort,
      size: context.state.ranking_size
    }

    context.commit('SET_IS_RANKING_LOADING', true)

    const response = await API.get<IGetTwitterKeywordsRankingResponse>('twitter_keywords/ranking', {
      params
    })

    context.commit('SET_IS_RANKING_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_RANKING', response.data.data)
    }

    return response
  },

  /**
   * 投稿の取得
   */
  async fetchKeywordPosts(context) {
    const params: IGetTwitterKeywordsPostsParams = {
      project_id: context.rootState.project.id,
      keyword_ids: context.state.keyword_ids.join(','),
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      post_types: context.state.filter_post_types.join(','),
      sentiment: context.state.filter_sentiment,
      tweet_types: context.state.filter_tweet_types.join(','),
      post_category_ids: context.state.filter_category_ids.join(','),
      order: context.state.post_display_order,
      keyword: context.state.filter_search_keyword,
      from: context.state.post_display_offset,
      size: context.state.post_display_count,
      is_scroll: false,
      scroll_id: ''
    }

    context.commit('SET_IS_POSTS_LOADING', true)

    const response = await API.get<IGetTwitterKeywordsPostsResponse>('twitter_keywords/posts', {
      params
    })

    context.commit('SET_IS_POSTS_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_POSTS', response.data.data)
    }
  },

  /**
   * クチコミ設定の取得
   */
  async fetchTwitterKeywords(context) {
    const params: IGetTwitterKeywordsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<IGetTwitterKeywordsResponse>('twitter_keywords', {
      params
    })

    if (response.data.data) {
      context.commit('SET_API_SETTINGS', response.data.data)
    }
  },

  /**
   * クチコミ設定の追加
   */
  async createTwitterKeyword(
    context,
    payload: {
      account_id: string
      name: string
      start_date: string
      end_date: string | null
      keywords: string[]
      or_keywords: string[]
      not_keywords: string[]
      language: string | null
      is_exclude_link_account: boolean
      is_exclude_reply: boolean
      alert_post_count: number
    }
  ) {
    const params: IPostTwitterKeywordsParams = {
      project_id: context.rootState.project.id,
      account_id: payload.account_id,
      name: payload.name,
      start_date: payload.start_date,
      end_date: payload.end_date,
      keywords: payload.keywords,
      or_keywords: payload.or_keywords,
      not_keywords: payload.not_keywords,
      language: payload.language,
      is_exclude_link_account: payload.is_exclude_link_account,
      is_exclude_reply: payload.is_exclude_reply,
      alert_post_count: payload.alert_post_count
    }

    const response = await API.post<IPostTwitterKeywordsResponse>('twitter_keywords', params)

    if (response.data) {
      await context.dispatch('fetchTwitterKeywords')

      const keyword_ids = [...context.state.keyword_ids, response.data.data.id]

      await context.dispatch('changeKeywordIds', keyword_ids)
    }

    return response.data
  },

  /**
   * クチコミ設定の更新
   */
  async updateTwitterKeyword(
    context,
    payload: {
      keyword_id: number
      account_id: string
      name: string
      start_date: string
      end_date: string | null
      keywords: string[]
      or_keywords: string[]
      not_keywords: string[]
      language: string | null
      is_exclude_link_account: boolean
      is_exclude_reply: boolean
      alert_post_count: number
    }
  ) {
    const params: IPutTwitterKeywordParams = {
      project_id: context.rootState.project.id,
      account_id: payload.account_id,
      name: payload.name,
      start_date: payload.start_date,
      end_date: payload.end_date,
      keywords: payload.keywords,
      or_keywords: payload.or_keywords,
      not_keywords: payload.not_keywords,
      language: payload.language,
      is_exclude_link_account: payload.is_exclude_link_account,
      is_exclude_reply: payload.is_exclude_reply,
      alert_post_count: payload.alert_post_count
    }

    const response = await API.put<IPutTwitterKeywordResponse>(
      `twitter_keywords/${payload.keyword_id}`,
      params
    )

    if (response.data) {
      await context.dispatch('fetchTwitterKeywords')
    }

    return response.data
  },

  /**
   * クチコミ設定のステータス更新
   */
  async updateTwitterKeywordStatus(
    context,
    payload: {
      keyword_id: number
      is_active: boolean
    }
  ) {
    const params: IPutTwitterKeywordStatusParams = {
      project_id: context.rootState.project.id,
      is_active: payload.is_active
    }

    const response = await API.put<IPutTwitterKeywordStatusResponse>(
      `twitter_keywords/${payload.keyword_id}/status`,
      params
    )

    if (response.data) {
      await context.dispatch('fetchTwitterKeywords')
    }

    return response.data
  },

  /**
   * クチコミ設定の削除
   */
  async deleteTwitterKeyword(context, payload: number) {
    const params: IDeleteTwitterKeywordParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.delete<IDeleteTwitterKeywordResponse>(
      `twitter_keywords/${payload}`,
      {
        params
      }
    )

    if (response.data) {
      await context.dispatch('fetchTwitterKeywords')

      if (context.state.keyword_ids.includes(payload)) {
        const keyword_ids = context.state.keyword_ids.filter(v => v !== payload)

        await context.dispatch('changeKeywordIds', keyword_ids)
      }
    }

    return response.data
  },

  /**
   * 投稿のタグ設定を変更
   */
  async updatePostCategory(context, payload: IPostTwitterKeywordPostCategoriesParams) {
    const params: IPostTwitterKeywordPostCategoriesParams = {
      keyword_id: payload.keyword_id,
      es_tw_keyword_post_id: payload.es_tw_keyword_post_id,
      category_ids: payload.category_ids
    }

    const response = await API.post<IPostTwitterKeywordPostCategoriesResponse>(
      'twitter_keywords/post_categories',
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchKeywordPosts')
    }

    return response.data
  },

  /**
   * クチコミ設定の順序を変更
   */
  async changeOrderTwitterKeywords(context, payload: number[]) {
    const params: IPutTwitterKeywordsChangeOrderNoParams = {
      project_id: context.rootState.project.id,
      keyword_ids: payload
    }

    const response = await API.put<IPutTwitterKeywordsChangeOrderNoResponse>(
      'twitter_keywords/change_order_no',
      params
    )

    await context.dispatch('fetchTwitterKeywords')

    await context.dispatch('changeKeywordIds', context.state.keyword_ids)

    return response.data
  },

  /**
   * キーワードID一覧の変更
   */
  async changeKeywordIds(context, payload: number[]) {
    // ソート順に調整
    const keyword_ids = context.state.api_settings
      .filter(v => payload.includes(v.id))
      .map(v => v.id)

    context.commit('SET_KEYWORD_IDS', keyword_ids)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)
    context.commit('SET_HEATMAP_KEYWORD_ID', '')
    context.commit('SET_WORD_CLOUD_KEYWORD_ID', '')
    context.commit('SET_WORDS_KEYWORD_ID', '')
    context.commit('SET_HASHTAGS_KEYWORD_ID', '')

    if (!keyword_ids.length) {
      context.commit('SET_API_SUMMARY', [])
      context.commit('SET_API_POST_COUNT', [])
      context.commit('SET_API_HEATMAP', [])
      context.commit('SET_API_WORD_CLOUD', [])
      context.commit('SET_API_WORDS', [])
      context.commit('SET_API_HASHTAGS', [])
      context.commit('SET_API_RANKING', [])
      context.commit('SET_API_POSTS', { posts: [], total: 0 })

      return
    }

    await Promise.all([
      context.dispatch('fetchKeywordSummary'),
      context.dispatch('fetchKeywordPostCount'),
      context.dispatch('fetchKeywordSentimentCount'),
      context.dispatch('fetchKeywordFollowerCount'),
      context.dispatch('fetchKeywordHeatmap'),
      context.dispatch('fetchKeywordWordCloud'),
      context.dispatch('fetchKeywordWords'),
      context.dispatch('fetchKeywordHashtags'),
      context.dispatch('fetchKeywordRanking'),
      context.dispatch('fetchKeywordPosts')
    ])
  },

  /**
   * 日付範囲の変更
   */
  async changeDateRange(context, payload: [string, string]) {
    context.commit('SET_DATE_RANGE', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)
    context.commit('SET_POST_COUNT_INDEX', 0)
    context.commit('SET_SENTIMENT_COUNT_INDEX', 0)
    context.commit('SET_FOLLOWER_COUNT_INDEX', 0)

    if (!state.keyword_ids.length) return

    await Promise.all([
      context.dispatch('fetchKeywordSummary'),
      context.dispatch('fetchKeywordPostCount'),
      context.dispatch('fetchKeywordSentimentCount'),
      context.dispatch('fetchKeywordFollowerCount'),
      context.dispatch('fetchKeywordHeatmap'),
      context.dispatch('fetchKeywordWordCloud'),
      context.dispatch('fetchKeywordWords'),
      context.dispatch('fetchKeywordHashtags'),
      context.dispatch('fetchKeywordRanking'),
      context.dispatch('fetchKeywordPosts')
    ])
  },

  /**
   * 表示するタブの変更
   */
  async changeScreenName(context, payload: ScreenName) {
    context.commit('SET_SCREEN_NAME', payload)
  },

  /**
   * サマリーの基本データのソート順序の変更
   */
  async changeSummaryDisplayOrder(context, payload: string) {
    context.commit('SET_SUMMARY_DISPLAY_ORDER', payload)
  },

  /**
   * サマリーの基本データの平均・合計の切り替え
   */
  async changeSummaryDisplayFormat(context, payload: DisplayFormat) {
    context.commit('SET_SUMMARY_DISPLAY_FORMAT', payload)
  },

  /**
   * サマリーの投稿数の集計期間の変更
   */
  async changePostCountInterval(context, payload: GraphInterval) {
    context.commit('SET_POST_COUNT_INTERVAL', payload)

    context.commit('SET_POST_COUNT_INDEX', 0)

    await context.dispatch('fetchKeywordPostCount')
  },

  /**
   * サマリーの投稿数のページングの変更
   */
  async changePostCountIndex(context, payload: number) {
    context.commit('SET_POST_COUNT_INDEX', payload)

    await context.dispatch('fetchKeywordPostCount')
  },

  /**
   * サマリーのセンチメントの集計期間の変更
   */
  async changeSentimentCountInterval(context, payload: GraphInterval) {
    context.commit('SET_SENTIMENT_COUNT_INTERVAL', payload)

    context.commit('SET_SENTIMENT_COUNT_INDEX', 0)

    await context.dispatch('fetchKeywordSentimentCount')
  },

  /**
   * サマリーのセンチメントのページングの変更
   */
  async changeSentimentCountIndex(context, payload: number) {
    context.commit('SET_SENTIMENT_COUNT_INDEX', payload)

    await context.dispatch('fetchKeywordSentimentCount')
  },

  /**
   * サマリーのフォロワー数の集計期間の変更
   */
  async changeFollowerCountInterval(context, payload: GraphInterval) {
    context.commit('SET_FOLLOWER_COUNT_INTERVAL', payload)

    context.commit('SET_FOLLOWER_COUNT_INDEX', 0)

    await context.dispatch('fetchKeywordFollowerCount')
  },

  /**
   * サマリーのフォロワー数のページングの変更
   */
  async changeFollowerCountIndex(context, payload: number) {
    context.commit('SET_FOLLOWER_COUNT_INDEX', payload)

    await context.dispatch('fetchKeywordFollowerCount')
  },

  /**
   * ヒートマップで指定しているキーワードの変更
   */
  async changeHeatmapKeywordId(context, payload: number | '') {
    context.commit('SET_HEATMAP_KEYWORD_ID', payload)

    await context.dispatch('fetchKeywordHeatmap')
  },

  /**
   * ヒートマップで指定している指標の変更
   */
  async changeHeatmapMetric(context, payload: HeatmapMetric) {
    context.commit('SET_HEATMAP_METRIC', payload)

    await context.dispatch('fetchKeywordHeatmap')
  },

  /**
   * ヒートマップの平均・合計の切り替え
   */
  async changeHeatmapDisplayFormat(context, payload: DisplayFormat) {
    context.commit('SET_HEATMAP_DISPLAY_FORMAT', payload)

    await context.dispatch('fetchKeywordHeatmap')
  },

  /**
   * ワードクラウドで指定しているキーワードの変更
   */
  async changeWordCloudKeywordId(context, payload: number | '') {
    context.commit('SET_WORD_CLOUD_KEYWORD_ID', payload)

    await context.dispatch('fetchKeywordWordCloud')
  },

  /**
   * ワードクラウドの件数変更
   */
  async changeWordCloudSize(context, payload: number) {
    context.commit('SET_WORD_CLOUD_SIZE', payload)

    await context.dispatch('fetchKeywordWordCloud')
  },

  /**
   * ワード一覧で指定しているキーワードの変更
   */
  async changeWordsKeywordId(context, payload: number | '') {
    context.commit('SET_WORDS_KEYWORD_ID', payload)

    await context.dispatch('fetchKeywordWords')
  },

  /**
   * ワード一覧の件数変更
   */
  async changeWordsSize(context, payload: number) {
    context.commit('SET_WORDS_SIZE', payload)

    await context.dispatch('fetchKeywordWords')
  },

  /**
   * ハッシュタグ一覧で指定しているキーワードの変更
   */
  async changeHashtagsKeywordId(context, payload: number | '') {
    context.commit('SET_HASHTAGS_KEYWORD_ID', payload)

    await context.dispatch('fetchKeywordHashtags')
  },

  /**
   * ハッシュタグ一覧の件数変更
   */
  async changeHashtagsSize(context, payload: number) {
    context.commit('SET_HASHTAGS_SIZE', payload)

    await context.dispatch('fetchKeywordHashtags')
  },

  /**
   * ワード一覧の投稿を確認
   */
  async checkKeywordPosts(context, payload: string) {
    await context.dispatch('changeScreenName', 'post')
    await context.dispatch('changeSearchkeyword', payload)
  },

  /**
   * ランキングのソート変更
   */
  async changeRankingSort(context, payload: RankingSort) {
    context.commit('SET_RANKING_SORT', payload)

    await context.dispatch('fetchKeywordRanking')
  },

  /**
   * ランキングの件数変更
   */
  async changeRankingSize(context, payload: number) {
    context.commit('SET_RANKING_SIZE', payload)

    await context.dispatch('fetchKeywordRanking')
  },

  /**
   * 投稿種別の変更
   */
  async changeTweetTypes(context, payload: string[]) {
    context.commit('SET_FILTER_TWEET_TYPE', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)

    await context.dispatch('fetchKeywordPosts')
  },

  /**
   * 投稿タイプの変更
   */
  async changePostTypes(context, payload: string[]) {
    context.commit('SET_FILTER_POST_TYPE', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)

    await context.dispatch('fetchKeywordPosts')
  },

  /**
   * センチメントフィルターの変更
   */
  async changeSentiment(context, payload: string) {
    context.commit('SET_FILTER_SENTIMENT', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)

    await context.dispatch('fetchKeywordPosts')
  },

  /**
   * タグの変更
   */
  async changeCategoryIds(context, payload: number[]) {
    context.commit('SET_FILTER_CATEGORY_IDS', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)

    await context.dispatch('fetchKeywordPosts')
  },

  /**
   * 検索キーワードの変更
   */
  async changeSearchkeyword(context, payload: string) {
    context.commit('SET_FILTER_SEARCH_KEYWORD', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)

    await context.dispatch('fetchKeywordPosts')
  },

  /**
   * 投稿の表示タブの変更
   */
  async changePostDisplayTab(context, payload: PostDisplayTab) {
    context.commit('SET_POST_DISPLAY_TAB', payload)
  },

  /**
   * 投稿のソート順序の変更
   */
  async changePostDisplayOrder(context, payload: string) {
    context.commit('SET_POST_DISPLAY_ORDER', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)

    await context.dispatch('fetchKeywordPosts')
  },

  /**
   * 投稿の表示オフセットの変更
   */
  async changePostDisplayOffset(context, payload: number) {
    context.commit('SET_POST_DISPLAY_OFFSET', payload)

    await context.dispatch('fetchKeywordPosts')
  },

  /**
   * 投稿の表示件数の変更
   */
  async changePostDisplayCount(context, payload: number) {
    context.commit('SET_POST_DISPLAY_OFFSET', 0)
    context.commit('SET_POST_DISPLAY_COUNT', payload)

    await context.dispatch('fetchKeywordPosts')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
