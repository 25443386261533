import $ from 'jquery'
import moment from 'moment-timezone'
import twttr from 'twitter-text'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

import { ZoomBotton } from '@/client/components/atoms'
import { IRootState } from '@/client/store/global'
import { InstagramGallery } from '@/client/utils/api/posts'
import { autoInstagramMentions } from '@/client/utils/regex'

interface PreviewInstagramOptions {
  screen_name: null | string
  user_name: null | string
  profile_image_url: null | string
  release_date: null | string
  message: string
  first_comment_message: string | null
  image_urls: {
    sequence_no: number
    media_url: string
  }[]
  video_urls: {
    sequence_no: number
    media_url: string
    media_thumbnail_url: string | null
  }[]
  galleries: InstagramGallery[]
}

@Component({
  name: 'PreviewInstagram',
  components: {
    ZoomBotton
  }
})
export default class PreviewInstagram extends Vue {
  @State('user') user!: IRootState['user']

  @Prop({ type: String, default: 'app' })
  mode!: 'app' | 'web' | 'gallery'

  @Prop({ type: Boolean, default: false })
  isReadMore!: boolean

  @Prop({ type: Boolean, default: true })
  isMediaTrimming!: boolean

  @Prop({ type: Object, required: true })
  options!: PreviewInstagramOptions

  $refs!: {
    container: HTMLDivElement
    scroll: HTMLDivElement
    text: HTMLDivElement
  }

  read_more_show = false
  read_more_style: { maxHeight: string | null } = { maxHeight: null }

  carousel_step = 0
  is_video_icon = true

  get is_app() {
    return ['app', 'gallery'].includes(this.mode)
  }

  get is_web() {
    return this.mode === 'web'
  }

  get is_gallery() {
    return this.mode === 'gallery'
  }

  get media_count() {
    return this.options.image_urls.length + this.options.video_urls.length
  }

  get is_alert() {
    return this.media_count === 0
  }

  get is_reels() {
    return this.options.image_urls.length === 0 && this.options.video_urls.length === 1
  }

  get is_carousel() {
    return this.media_count > 1
  }

  get is_carousel_prev() {
    return this.carousel_step > 0
  }

  get is_carousel_next() {
    return this.carousel_step < this.media_count - 1
  }

  get media_position() {
    const width = this.is_app ? 375 : 470 - 2 // ? Web版は外縁をマイナスする
    const left = `${this.carousel_step * -width}px`
    return { transform: `translate(${left}, 0)` }
  }

  get media() {
    const temp: {
      type: 'image' | 'video'
      url: string
      thumbnail_url?: string
    }[] = []

    for (let i = 0; i < 10; i++) {
      const image_url = this.options.image_urls.find(v => v.sequence_no === i + 1)
      const video_url = this.options.video_urls.find(v => v.sequence_no === i + 1)

      if (image_url) {
        temp.push({ type: 'image', url: image_url.media_url })
      }

      if (video_url) {
        temp.push({
          type: 'video',
          url: video_url.media_url,
          thumbnail_url: video_url.media_thumbnail_url || ''
        })
      }
    }

    return temp
  }

  get message() {
    this.read_more_show = false

    let message = this.options.message

    message = this.multilineEscape(message)

    if (this.is_app) {
      this.read_more_style.maxHeight = null

      // DOMの高さが3行を超えたらCSSでカットする
      // もっと見る表示は再現できないので下に表示する
      if (this.isReadMore) {
        this.$nextTick(() => {
          const height = 54

          this.read_more_show = this.$refs.text.clientHeight > height

          this.read_more_style.maxHeight = `${height}px`
        })
      }
    }

    if (this.is_web && this.isReadMore) {
      const temp_message = message
      const max_length = 120
      const max_lines = 1

      // 120文字を超えたらカット
      if (message.length > max_length) {
        message = message.slice(0, max_length)
      }

      const words = message.split('\n')

      // 1行を超えたらカット
      if (words.length > max_lines) {
        message = words.slice(0, max_lines).join('\n')
      }

      this.read_more_show = message !== temp_message
    }

    message = this.htmlEscape(message)

    message = autoInstagramMentions(message, {
      usernameUrlBase: 'https://instagram.com/',
      usernameClass: 'instagram-url username'
    })

    message = twttr.autoLinkHashtags(message, {
      hashtagUrlBase: 'https://instagram.com/explore/tags/',
      hashtagClass: 'instagram-url hashtag',
      targetBlank: true
    })

    // 改行コードを変換
    message = message.replace(/\r?\n/g, '<br>')

    return message
  }

  get first_comment_message() {
    if (!this.options.first_comment_message) {
      return ''
    }

    let message = this.options.first_comment_message

    message = this.multilineEscape(message)

    message = this.htmlEscape(message)

    message = autoInstagramMentions(message, {
      usernameUrlBase: 'https://instagram.com/',
      usernameClass: 'instagram-url username'
    })

    message = twttr.autoLinkHashtags(message, {
      hashtagUrlBase: 'https://instagram.com/explore/tags/',
      hashtagClass: 'instagram-url hashtag',
      targetBlank: true
    })

    // 改行コードを変換
    message = message.replace(/\r?\n/g, '<br>')

    return message
  }

  get release_date() {
    const format = this.user.language === 'ja' ? 'YYYY年M月D日' : 'MMMM D, YYYY'

    return moment(this.options.release_date).format(format)
  }

  get text_see_more() {
    return this.user.language === 'ja' ? '続きを読む' : 'more'
  }

  @Emit('click-media')
  async clickMedia(index: number) {
    this.is_video_icon = true

    await this.stopVideoPlaying()

    return {
      index,
      media: this.media
    }
  }

  @Watch('media')
  async watchMedia() {
    this.carousel_step = 0

    await this.syncVideoPlaying()
  }

  /**
   * マウント時
   */
  async mounted(): Promise<void> {
    if (this.is_app) {
      $(this.$refs.scroll).slimScroll({
        height: this.$refs.container.clientHeight + 'px',
        size: '6px',
        wheelStep: 10,
        touchScrollStep: 50
      })
    }
  }

  /**
   * マルチ改行を除去する
   */
  multilineEscape(text: string): string {
    return text.replace(/\n\n\s*\n/g, '\n\n')
  }

  /**
   * HTMLで使用する特殊文字を除去する
   */
  htmlEscape(text: string): string {
    const entities = {
      '>': '&gt;',
      '<': '&lt;',
      '"': '&quot;',
      "'": '&#39;'
    }

    return text.replace(/["'><]/g, character => entities[character])
  }

  /**
   * 前のカルーセルに戻る
   */
  async prevCarouselStep() {
    this.carousel_step = this.carousel_step - 1

    await this.syncVideoPlaying()
  }

  /**
   * 次のカルーセルに進む
   */
  async nextCarouselStep() {
    this.carousel_step = this.carousel_step + 1

    await this.syncVideoPlaying()
  }

  /**
   * カルーセルの位置を変更
   */
  async moveCarouselStep(index: number) {
    this.carousel_step = index

    await this.syncVideoPlaying()
  }

  /**
   * 動画の再生状態を切り替える
   */
  async toggleVideoPlaying() {
    this.is_video_icon = !this.is_video_icon

    await this.syncVideoPlaying()
  }

  /**
   * 動画の再生状況を同期する
   */
  async syncVideoPlaying() {
    for (const video of Array.from(this.$el.getElementsByTagName('video'))) {
      if (video.dataset['index'] === this.carousel_step.toString()) {
        if (this.is_video_icon) {
          video.pause()
        } else {
          await video.play()
        }
      } else {
        video.pause()
      }
    }
  }

  /**
   * 動画の再生状況を停止する
   */
  async stopVideoPlaying() {
    for (const video of Array.from(this.$el.getElementsByTagName('video'))) {
      video.pause()
    }
  }
}
