import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  IGetPostTemplatesParams,
  IGetPostTemplatesResponse,
  IPostPostTemplatesParams,
  IPostPostTemplatesResponse,
  IPostTemplate,
  IPutPostTemplatesChangeOrderNoParams,
  IPutPostTemplatesChangeOrderNoResponse,
  IPutPostTemplatesParams,
  IPutPostTemplatesResponse
} from '@/client/utils/api/post_templates'

export interface IState {
  templates: IPostTemplate[]
}

const state: IState = {
  templates: []
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  SET_TEMPLATES(state, payload: IPostTemplate[]) {
    state.templates = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /*
   * テンプレートの取得
   */
  async fetchTemplate(context) {
    const params: IGetPostTemplatesParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<IGetPostTemplatesResponse>('post_templates', { params })

    if (response.data.data) {
      context.commit('SET_TEMPLATES', response.data.data)
    }
  },

  /*
   * テンプレートの作成
   */
  async createTemplate(
    context,
    payload: {
      name: string
      content: string
    }
  ) {
    const params: IPostPostTemplatesParams = {
      project_id: context.rootState.project.id,
      name: payload.name,
      content: payload.content
    }

    const response = await API.post<IPostPostTemplatesResponse>('post_templates', params)

    if (response.data.data) {
      const template = {
        id: response.data.data.id,
        name: payload.name,
        content: payload.content
      }

      const data = context.state.templates.concat([template])

      context.commit('SET_TEMPLATES', data)
    }

    return response.data
  },

  /*
   * テンプレートの更新
   */
  async updateTemplate(
    context,
    payload: {
      template_id: number
      name: string
      content: string
    }
  ) {
    const params: IPutPostTemplatesParams = {
      name: payload.name,
      content: payload.content
    }

    const response = await API.put<IPutPostTemplatesResponse>(
      `post_templates/${payload.template_id}`,
      params
    )

    if (response.data.data) {
      const data = context.state.templates.map(v => {
        if (payload.template_id === v.id) {
          return { id: v.id, name: payload.name, content: payload.content }
        }

        return { ...v }
      })

      context.commit('SET_TEMPLATES', data)
    }

    if (response.data.error && response.data.error.type === 'NOT_EXISTS') {
      await context.dispatch('fetchTemplate')
    }

    return response.data
  },

  /*
   * テンプレートの削除
   */
  async removeTemplate(context, payload: { template_id: number }) {
    const response = await API.delete(`post_templates/${payload.template_id}`)

    if (response.data.data) {
      const data = context.state.templates.filter(v => v.id !== payload.template_id)

      context.commit('SET_TEMPLATES', data)
    }

    if (response.data.error && response.data.error.type === 'NOT_EXISTS') {
      await context.dispatch('fetchTemplate')
    }

    return response.data
  },

  /*
   * テンプレートの順番を同期
   */
  async syncTemplateOrder(context, payload: IPostTemplate[]) {
    context.commit('SET_TEMPLATES', payload)

    const params: IPutPostTemplatesChangeOrderNoParams = {
      project_id: context.rootState.project.id,
      template_ids: payload.map(v => v.id)
    }

    const response = await API.put<IPutPostTemplatesChangeOrderNoResponse>(
      `post_templates/change_order_no`,
      params
    )

    if (!response.data.data) {
      await context.dispatch('fetchTemplate')
    }

    return response.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
