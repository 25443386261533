import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/facebook_analytics'
import * as calculation from '@/client/components-old/_utils/calculation'
import * as facebook_insight from '@/client/components-old/_utils/facebook_insight'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsNumbers from '@/client/components-old/molecules/AnalyticsNumbers'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import { IRootState } from '@/client/store/global'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as IFacebookState } from '@/client/store/modules/facebook_analytics'
import { getTranslateText as t } from '@/client/utils/filters'

type TFacebookAnalyticsTotalOptions = {
  data_type: 'daily_data' | 'post_data'
  metric: string
  unit: 'total' | 'average' | 'major' | 'past_week'
  is_view_extra: boolean
  post_types?: string[]
  post_tags?: number[]
}

const facebook = namespace('facebook_analytics')
const categories = namespace('categories')

@Component({
  name: 'FacebookAnalyticsTotal',
  components: {
    Icon,
    Tooltip,
    AnalyticsPanel,
    AnalyticsPanelHeader,
    AnalyticsNumbers
  }
})
export default class FacebookAnalyticsTotal extends Vue {
  @State('user') user: IRootState['user']
  @categories.State('api_post_categories')
  api_post_categories: ICategoriesState['api_post_categories']
  @categories.State('api_category_posts') api_category_posts: ICategoriesState['api_category_posts']
  @facebook.State('api_accounts') daily_data: IFacebookState['api_accounts']
  @facebook.State('api_posts') post_data: IFacebookState['api_posts']

  @Prop({ type: Object, required: true })
  options: TFacebookAnalyticsTotalOptions

  @Prop({ type: Boolean, default: false })
  isPreview: boolean

  @Prop({ type: Boolean, default: false })
  isEdit: boolean

  @Emit('edit')
  onEdit() {
    return this.options
  }

  @Emit('delete')
  onDelete(event) {
    return event
  }

  getPostTagNames(post_tag_ids?: number[]): string[] {
    if (post_tag_ids === undefined || !post_tag_ids.length) return []

    return (
      this.api_post_categories.filter(tag => post_tag_ids.includes(tag.id)).map(v => v.name) || []
    )
  }

  getSnsPostTags(post_id: string): number[] {
    const post_tag = this.api_category_posts.find(post_tag => post_tag.post_id === post_id)
    return post_tag ? post_tag.category_ids : []
  }

  get analytics(): any[] {
    const { data_type, post_types, post_tags } = this.options

    switch (data_type) {
      case 'daily_data':
        return facebook_insight.filterDailyData(
          this.daily_data,
          this.post_data,
          this.options.metric
        )
      case 'post_data': {
        let temp_insights = facebook_insight.filterPostData(this.post_data, this.options.metric)

        if (post_types?.length) {
          temp_insights = temp_insights.filter(post => post_types.includes(post.type))
        }
        if (post_tags?.length) {
          temp_insights = temp_insights.filter(post => {
            const post_tag_ids = this.getSnsPostTags(post.post_id)
            return post_tag_ids.some(post_tag_id => post_tags.includes(post_tag_id))
          })
        }
        return temp_insights
      }
      default:
        return []
    }
  }

  get is_data() {
    switch (this.options.metric) {
      case 'page_fans_gender_age':
      case 'post_video_view_time_by_age_bucket_and_gender':
      case 'page_fans_country':
      case 'page_fans_city':
      case 'page_fans_online':
        return this.analytics.some(v => Object.keys(v[this.options.metric]).length > 0)

      default:
        return this.analytics.length > 0
    }
  }

  get is_edit() {
    return !this.isPreview && this.isEdit
  }

  get show_post_types() {
    if (this.options.data_type !== 'post_data') return []

    return ['status', 'photo', 'link', 'video', 'shared'].filter(v =>
      this.options.post_types.includes(v)
    )
  }

  get post_tags_names() {
    if (this.options.data_type !== 'post_data') return []

    return this.getPostTagNames(this.options.post_tags)
  }

  get panel_type() {
    const data_type = constants.DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get metrics() {
    switch (this.options.data_type) {
      case 'daily_data':
        return constants.TOTAL_DAILY_DATA_METRICS
      case 'post_data':
        return constants.TOTAL_POST_DATA_METRICS
      default:
        return []
    }
  }

  get metric_name() {
    const metric = this.metrics.find(options => options.value === this.options.metric)
    const metric_name = metric ? t(metric.text) : ''

    const unit = constants.UNITS.find(unit => unit.value === this.options.unit)
    const unit_name = unit ? t(unit.text) : ''
    const unit_value = unit ? unit.value : ''

    if (
      unit_name === '' ||
      unit_value === '' ||
      unit_value === 'major' ||
      unit_value === 'past_week' ||
      this.options.metric === 'page_fans' ||
      this.options.metric === 'post_video_avg_time_watched'
    ) {
      return metric_name
    }

    return `${metric_name} [${unit_name}]`
  }

  get result() {
    switch (this.options.unit) {
      case 'total':
        return facebook_insight.convertValueWithMetric(this.total, this.options.metric)
      case 'average':
        return facebook_insight.convertValueWithMetric(this.average, this.options.metric)
      case 'major':
      case 'past_week':
        return this.setMajorKeyView(this.major)
    }
  }

  get extras() {
    if (!this.options.is_view_extra) {
      return []
    }

    switch (this.options.unit) {
      case 'total':
        return [this.extras_max, this.extras_min, this.extras_average]

      case 'average':
        return [this.extras_max, this.extras_min]

      case 'major':
      case 'past_week':
        if (this.options.metric === 'post_video_view_time_by_age_bucket_and_gender') {
          return [this.extras_percentage]
        }

        return [this.extras_total, this.extras_percentage]
    }
  }

  get total() {
    switch (this.options.metric) {
      case 'page_fans_online': {
        const page_fan_datas = this.getArrayByMetric(this.options.metric)
        // 7日分の合計値なので1日の平均値にする
        return Math.round(Math.max.apply(null, page_fan_datas) / 7)
      }

      case 'page_fans_gender_age':
      case 'page_fans_country':
      case 'page_fans_city':
      case 'page_fans_online_per_day': {
        const datas = this.getArrayByMetric(this.options.metric)

        return Math.max.apply(null, datas)
      }

      case 'post_video_view_time_by_age_bucket_and_gender': {
        const video_view_data = facebook_insight.getDataByMetric(
          this.analytics,
          this.options.metric,
          this.user.timezone
        )

        if (!video_view_data.length) return []

        return this.getMajorMetric(video_view_data).value
      }

      default:
        return facebook_insight.getMetricTotal(this.analytics, this.options.metric)
    }
  }

  get average() {
    return facebook_insight.getMetricAverage(this.analytics, this.options.metric)
  }

  get major() {
    const data = facebook_insight.getDataByMetric(
      this.analytics,
      this.options.metric,
      this.user.timezone
    )

    if (!data) return ''

    // 主な都道府県の場合、対象を日本のみに限定する
    if (this.options.metric === 'page_fans_city') {
      const cities = Highcharts.maps['countries/jp/jp-all'].features
      Object.keys(data).forEach(key => {
        const city = cities.map(f => f.properties).find(p => p['name'] === key)
        if (!city) {
          delete data[key]
        }
      })
    }

    switch (this.options.metric) {
      case 'post_video_view_time_by_age_bucket_and_gender': {
        if (!data.length) return ''

        const majorMetric = this.getMajorMetric(data)

        return majorMetric.key
      }

      default: {
        const metric_datas = Object.keys(data).map(key => data[key])

        const major_value = Math.max.apply(null, metric_datas)
        const major_key = Object.keys(data)[metric_datas.indexOf(major_value)]

        return major_key
      }
    }
  }

  get extras_total() {
    return { title: '合計', value: this.$options.filters.number(this.total) }
  }

  get extras_max() {
    const metric_array = facebook_insight.getMetricArrayNumber(this.analytics, this.options.metric)
    const max = metric_array.length ? Math.max.apply(null, metric_array) : 0

    return {
      title: '最大',
      value: facebook_insight.convertValueWithMetric(max, this.options.metric)
    }
  }

  get extras_min() {
    const metric_array = facebook_insight.getMetricArrayNumber(this.analytics, this.options.metric)
    const min = metric_array.length ? Math.min.apply(null, metric_array) : 0

    return {
      title: '最小',
      value: facebook_insight.convertValueWithMetric(min, this.options.metric)
    }
  }

  get extras_average() {
    let average_text = ''

    switch (this.options.data_type) {
      case 'daily_data':
        average_text = '1日平均'
        break

      case 'post_data':
        average_text = '1投稿あたり'
        break
    }

    return {
      title: average_text,
      value: facebook_insight.convertValueWithMetric(this.average, this.options.metric)
    }
  }

  get extras_percentage() {
    let parentValue

    switch (this.options.metric) {
      case 'post_video_view_time_by_age_bucket_and_gender': {
        const data = facebook_insight.getDataByMetric(
          this.analytics,
          this.options.metric,
          this.user.timezone
        )

        if (!data || !data.length) return []

        // 年齢別の動画再生数合計を求める
        parentValue = 0
        data.forEach(v => {
          Object.keys(v).forEach(key => {
            parentValue += v[key]
          })
        })
        break
      }

      default:
        parentValue = facebook_insight.getMetricTotal(this.analytics, 'page_fans')
        break
    }

    const percentage = calculation.percentage(this.total, parentValue)

    return { title: '率', value: this.$options.filters.percent(percentage) }
  }

  /**
   * 主要な指標を返す
   * @param {any[]} analytic
   * @returns {object} 主な指標
   */
  getMajorMetric(analytic: any[]): any {
    // 指標ごとの合計を求める
    const total_array = {}

    analytic.forEach(v => {
      switch (this.options.metric) {
        // undefined性別を解除
        case 'post_video_view_time_by_age_bucket_and_gender':
          Object.keys(v).forEach(key => {
            if (key.match('U')) {
              delete analytic[key]
            }
          })
          break

        // 主な都道府県の場合、対象を日本のみに限定する
        case 'page_fans_city': {
          const cities = Highcharts.maps['countries/jp/jp-all'].features
          Object.keys(v).forEach(key => {
            const city = cities.map(f => f.properties).find(p => p['name'] === key)
            if (!city) {
              delete analytic[key]
            }
          })
          break
        }
      }

      Object.keys(v).map(key => {
        total_array[key] = total_array[key] ? total_array[key] + v[key] : v[key]
      })
    })

    // 最大値のキーを求める
    let majorKey
    Object.keys(total_array).forEach(key => {
      if (majorKey) {
        majorKey = total_array[key] > total_array[majorKey] ? key : majorKey
      } else {
        majorKey = key
      }
    })

    return { key: majorKey, value: total_array[majorKey] }
  }

  /**
   * 主なキーの表示をセット
   * @param {string} key 主なキー
   * @returns {string} 主なキー
   */
  setMajorKeyView(key: string): string {
    switch (this.options.metric) {
      case 'page_fans_gender_age':
      case 'post_video_view_time_by_age_bucket_and_gender': {
        const gender_age_text = {
          M: ['歳', '男性'].map(value => t(value)).join(' '),
          F: ['歳', '女性'].map(value => t(value)).join(' ')
        }

        return key.slice(2) + gender_age_text[key.slice(0, 1)]
      }

      case 'page_fans_country': {
        const countries = Highcharts.maps['custom/world'].features
        const country = countries.map(f => f.properties).find(p => p['hc-a2'] === key)

        if (this.user.language === 'ja') {
          return country ? country['jp-name'] : ''
        } else {
          return country ? country['name'] : ''
        }
      }

      case 'page_fans_city': {
        const cities = Highcharts.maps['countries/jp/jp-all'].features
        const city = cities.map(f => f.properties).find(p => p['name'] === key)

        if (this.user.language === 'ja') {
          return city ? city['jp-name'] : ''
        } else {
          return city ? city['name'] : ''
        }
      }

      case 'page_fans_online':
        return Number(key) < 10 ? `0${key}:00` : `${key}:00`

      case 'page_fans_online_per_day': {
        const days = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日']
        return t(days[Number(key)])
      }

      default:
        return ''
    }
  }

  /**
   * 分析データから特定の値の配列を取得
   * @param {string} key 指標
   * @returns {number} 配列
   */
  getArrayByMetric(key: string): number[] {
    const data = facebook_insight.getDataByMetric(this.analytics, key, this.user.timezone)

    if (!data) return []

    return Object.keys(data).map(key => data[key])
  }

  get metric_tooltip() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? t(metric.tooltip) : ''
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }
}
