export const SET_API_INSIGHTS = 'SET_API_INSIGHTS'
export const SET_API_WIDGET_SETTINGS = 'SET_API_WIDGET_SETTINGS'
export const SET_API_TABLE_SETTING = 'SET_API_TABLE_SETTING'
export const SET_PARAMS = 'SET_PARAMS'
export const SET_SCREEN = 'SET_SCREEN'
export const SET_SEARCH_POST_TYPE = 'SET_SEARCH_POST_TYPE'
export const SET_SEARCH_CATEGORY = 'SET_SEARCH_CATEGORY'
export const SET_SEARCH_MESSAGE = 'SET_SEARCH_MESSAGE'
export const SET_LOADING = 'SET_LOADING'
export const SET_POST_DISPLAY_COUNT = 'SET_POST_DISPLAY_COUNT'
