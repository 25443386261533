import moment from 'moment-timezone'
import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Checkbox from '@/client/components-old/atoms/Checkbox'
import Icon from '@/client/components-old/atoms/Icon'
import Select from '@/client/components-old/atoms/Select'
import SelectMulti from '@/client/components-old/atoms/SelectMulti'
import Tabs from '@/client/components-old/atoms/Tabs'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AccountSelect from '@/client/components-old/molecules/AccountSelect'
import DateRangePicker from '@/client/components-old/molecules/DateRangePicker'
import Search from '@/client/components-old/molecules/Search'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IGetter as IAccountGetter } from '@/client/store/modules/accounts'
import { IState as IMonitoringState } from '@/client/store/modules/monitoring'
import { TMonitoring } from '@/client/utils/api/monitorings'
import { getSnsName } from '@/client/utils/character'
import csv from '@/client/utils/csv'
import i18n from '@/client/utils/i18n'
import { convertHelpMessage } from '@/client/utils/notification'

const accounts = namespace('accounts')
const monitoring = namespace('monitoring')
const notification = namespace('notification')

@Component({
  name: 'MonitoringHeader',
  components: {
    Button,
    ButtonLink,
    Checkbox,
    Icon,
    Select,
    SelectMulti,
    Tabs,
    Tooltip,
    AccountSelect,
    DateRangePicker,
    Search
  }
})
export default class MonitoringHeader extends Vue {
  @State('user') user: IRootState['user']
  @State('project_role') project_role: IRootState['project_role']
  @accounts.Getter('monitoring') accounts: IAccountGetter['monitoring']
  @monitoring.State('api_counts') counts: IMonitoringState['api_counts']
  @monitoring.State('api_setting') api_setting: IMonitoringState['api_setting']
  @monitoring.State('api_persons') api_persons: IMonitoringState['api_persons']
  @monitoring.State('status') status: IMonitoringState['status']
  @monitoring.State('filter_account_id') account_id: IMonitoringState['filter_account_id']
  @monitoring.State('filter_account_sns') sns_type: IMonitoringState['filter_account_sns']
  @monitoring.State('filter_types') monitoring_types: IMonitoringState['filter_types']
  @monitoring.State('filter_persons') filter_persons: IMonitoringState['filter_persons']
  @monitoring.State('filter_keyword') keyword: IMonitoringState['filter_keyword']
  @monitoring.State('start_date') start_date: IMonitoringState['start_date']
  @monitoring.State('end_date') end_date: IMonitoringState['end_date']
  @monitoring.State('checked_monitorings')
  checked_monitorings: IMonitoringState['checked_monitorings']
  @monitoring.State('is_checked_all')
  is_checked_all: IMonitoringState['is_checked_all']
  @monitoring.Mutation('SET_FILTER_KEYWORD') setFilterKeyword
  @monitoring.Action('createdMonitoringPage') createdMonitoringPage
  @monitoring.Action('changeMonitoringAccount') changeMonitoringAccount
  @monitoring.Action('changeMonitoringTypes') changeMonitoringTypes
  @monitoring.Action('changeMonitoringPersons') changeMonitoringPersons
  @monitoring.Action('changeMonitoringKeyword') changeMonitoringKeyword
  @monitoring.Action('changeMonitoringDate') changeMonitoringDate
  @monitoring.Action('changeMonitoringStatus') changeMonitoringStatus
  @monitoring.Action('refreshMonitoringPage') refreshMonitoringPage
  @monitoring.Action('downloadMonitoringCSV') downloadMonitoringCSV
  @monitoring.Action('selectAllMonitoring') selectAllMonitoring
  @monitoring.Action('bulkMonitoringStatus') bulkMonitoringStatus
  @notification.Action('showNotification') showNotification

  is_active = false
  is_all = false
  date_range_selected = 'before_30_day'

  get role_admin() {
    return this.project_role.role_admin
  }

  get is_facebook() {
    return this.accounts.some(v => v.sns === 'facebook')
  }

  get is_twitter() {
    return this.accounts.some(v => v.sns === 'twitter')
  }

  get is_instagram() {
    return this.accounts.some(v => v.sns === 'instagram')
  }

  get date_range() {
    return [this.start_date, this.end_date]
  }

  get status_options() {
    return [
      {
        value: 'unread',
        text: `${i18n.t('未読')} (${this.counts.unread})`,
        icon: 'inbox'
      },
      {
        value: 'read',
        text: `${i18n.t('既読')} (${this.counts.read})`,
        icon: 'done'
      },
      {
        value: 'pending',
        text: `${i18n.t('保留')} (${this.counts.pending})`,
        icon: 'flag'
      },
      {
        value: 'all',
        text: `${i18n.t('全て')} (${this.counts.all})`,
        icon: 'language'
      }
    ]
  }

  get calendar_text() {
    const start_date = moment(this.start_date, 'YYYY-MM-DD')
    const end_date = moment(this.end_date, 'YYYY-MM-DD')

    if (start_date.month() === end_date.month()) {
      const format = this.user.language === 'ja' ? 'YYYY年M月' : 'MMM YYYY'

      return start_date.format(format)
    }

    const format_year = this.user.language === 'ja' ? 'YYYY年' : 'YYYY'
    const format_month = this.user.language === 'ja' ? 'M月' : 'MMM'

    if (start_date.year() === end_date.year()) {
      // YYYY年M月 - M月
      if (this.user.language === 'ja') {
        return (
          start_date.format(format_year) +
          start_date.format(format_month) +
          ' - ' +
          end_date.format(format_month)
        )
      }

      // MMM - MMM YYYY
      return (
        start_date.format(format_month) +
        ' - ' +
        end_date.format(format_month) +
        ' ' +
        start_date.format(format_year)
      )
    }

    if (this.user.language === 'ja') {
      // YYYY年M月 - YYYY年M月
      return (
        start_date.format(format_year) +
        start_date.format(format_month) +
        ' - ' +
        end_date.format(format_year) +
        end_date.format(format_month)
      )
    }

    // YYYY MMM - YYYY MMMM
    return (
      start_date.format(format_month) +
      start_date.format(format_year) +
      ' - ' +
      end_date.format(format_month) +
      ' ' +
      end_date.format(format_year)
    )
  }

  get account_options() {
    const accounts = this.accounts.map(account => ({
      id: account.id,
      img: account.img,
      name: account.name,
      username: account.username,
      is_use_monitoring: account.is_use_monitoring,
      type: account.sns,
      expired: account.expired
    }))

    const monitoring_accounts = accounts.filter(v => v.is_use_monitoring)
    const not_monitoring_accounts = accounts.filter(v => !v.is_use_monitoring)

    const default_account = {
      id: '',
      name: i18n.t('全アカウント'),
      is_use_monitoring: false,
      img: require('@/client/assets/images/icon_sns_all.svg'),
      type: '',
      expired: false
    }

    return [default_account, ...monitoring_accounts, ...not_monitoring_accounts]
  }

  get monitoring_type_options() {
    let options = []

    if (this.is_facebook && this.api_setting) {
      if (!this.api_setting.hide_fb_comment) {
        options = options.concat([{ value: 'facebook_comment', text: i18n.t('Facebookコメント') }])
      }

      if (!this.api_setting.hide_fb_message) {
        options = options.concat([
          { value: 'facebook_message', text: i18n.t('Facebookメッセージ') }
        ])
      }

      if (!this.api_setting.hide_fb_post) {
        options = options.concat([{ value: 'facebook_post', text: i18n.t('Facebook投稿') }])
      }
    }

    if (this.is_twitter && this.api_setting) {
      if (!this.api_setting.hide_tw_reply) {
        options = options.concat([{ value: 'twitter_reply', text: i18n.t('Xリプライ') }])
      }

      if (!this.api_setting.hide_tw_mention) {
        options = options.concat([{ value: 'twitter_mention', text: i18n.t('Xメンション') }])
      }

      if (!this.api_setting.hide_tw_message) {
        options = options.concat([{ value: 'twitter_message', text: i18n.t('Xメッセージ') }])
      }
    }

    if (this.is_instagram && this.api_setting && !this.api_setting.hide_in_comment) {
      options = options.concat([{ value: 'instagram_comment', text: i18n.t('Instagramコメント') }])
    }

    return options
  }

  get mobile_date_range_options() {
    return [
      {
        value: 'before_30_day',
        text: `${i18n.t('30日前')} - ${i18n.t('今日')}`
      },
      {
        value: 'today',
        text: i18n.t('今日')
      },
      {
        value: 'current_week',
        text: i18n.t('今週')
      },
      {
        value: 'current_month',
        text: i18n.t('今月')
      },
      {
        value: 'last_week',
        text: i18n.t('先週')
      },
      {
        value: 'last_month',
        text: i18n.t('先月')
      },
      {
        value: '1_year_ago',
        text: i18n.t('1年前')
      }
    ]
  }

  get disabled_read() {
    return this.status === 'read'
  }

  get disabled_pending() {
    return this.status === 'pending'
  }

  get disabled_unread() {
    return this.status === 'unread'
  }

  get person_options() {
    return this.api_persons.map(v => v.user).map(v => ({ value: v.id, text: v.name }))
  }

  /**
   * アカウント選択
   */
  async changeAccount(account_id: string): Promise<void> {
    TrackingService.sendEvent('select:モニタリング|画面内ヘッダー|アカウント選択')

    const account = this.account_options.find(account => account.id === account_id)

    if (account.expired) {
      this.showNotification({
        type: 'error',
        title:
          'アクセストークンが失効しているため、データ取得および返信などの操作ができない状態です。',
        message: convertHelpMessage({
          message: '至急、アクセストークンを更新してください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    }

    await this.changeMonitoringAccount({
      account_id: account.id,
      account_sns: account.type
    })
  }

  /**
   * タイプを変更
   */
  async changeTypes(types: string[]) {
    TrackingService.sendEvent('select:モニタリング|画面内ヘッダー|タイプ選択')

    await this.changeMonitoringTypes(types)
  }

  /**
   * 担当者を変更
   */
  async changePersons(persons: number[]) {
    TrackingService.sendEvent('select:モニタリング|画面内ヘッダー|担当者選択')

    await this.changeMonitoringPersons(persons)
  }

  /**
   * キーワードを変更
   */
  async changeKeyword(keyword: string) {
    TrackingService.sendEvent('click:モニタリング|画面内ヘッダー|検索')

    await this.changeMonitoringKeyword(keyword)
  }

  /**
   * モニタリングページの更新
   */
  async refresh() {
    TrackingService.sendEvent('click:モニタリング|画面内ヘッダー|更新')

    await this.refreshMonitoringPage()
  }

  /**
   * 選択期間の変更
   */
  async changeDateRangePicker(date_range: [string, string]): Promise<void> {
    TrackingService.sendEvent('select:モニタリング|画面内ヘッダー|期間指定')

    await this.changeMonitoringDate({
      start_date: date_range[0],
      end_date: date_range[1]
    })
  }

  /**
   * モニタリングタブの変更
   */
  async changeTab(tab: string) {
    TrackingService.sendEvent(`click:モニタリング|タブ:${tab}`)

    await this.changeMonitoringStatus(tab)
  }

  /**
   * スマホの選択期間の変更
   */
  async changeDateRangeSelect(date_range: string): Promise<void> {
    const start_date = moment()
    const end_date = moment()

    switch (date_range) {
      case 'before_30_day':
        start_date.subtract(30, 'day')
        break
      case 'current_week':
        start_date.startOf('isoWeek')
        break
      case 'current_month':
        start_date.startOf('month')
        break
      case 'last_week':
        start_date.subtract(1, 'week').startOf('isoWeek')
        end_date.subtract(1, 'week').endOf('isoWeek')
        break
      case 'last_month':
        start_date.subtract(1, 'month').startOf('month')
        end_date.subtract(1, 'month').endOf('month')
        break
      case '1_year_ago':
        start_date.subtract(1, 'year')
        break
    }

    await this.changeMonitoringDate({
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD')
    })
  }

  /**
   * CSVダウンロード処理
   */
  async downloadCsv(): Promise<void> {
    try {
      TrackingService.sendEvent('click:モニタリング|タブ内ヘッダー|CSVダウンロード')

      const monitorings = await this.downloadMonitoringCSV()

      const csv_filename = ['monitoring', moment().format('YYYY-MM-DD')].join('_')
      const csv_data = []

      csv_data.push([
        i18n.t('日時'),
        i18n.t('ステータス'),
        i18n.t('SNS種別'),
        i18n.t('タイプ'),
        i18n.t('送信元アカウント'),
        `${i18n.t('送信元アカウントユーザーネーム')}(@)`,
        i18n.t('送信元アカウントURL'),
        i18n.t('送信先アカウント'),
        i18n.t('内容'),
        i18n.t('画像URL'),
        i18n.t('SNS URL'),
        i18n.t('リプライされたURL') + '(X)',
        i18n.t('SNS側のステータス')
      ])

      for (const monitoring of monitorings) {
        csv_data.push(this.setCsvData(monitoring))
      }

      csv.download(csv_data, csv_filename)
    } catch (error) {
      this.showNotification({ title: 'CSVダウンロードに失敗しました。', type: 'error' })
    }
  }

  /**
   * CSVのデータをセット
   */
  setCsvData(monitoring: TMonitoring): any {
    const datetime = csv.format(monitoring.datetime)
    const status = this.getStatus(monitoring.status)
    const sns_name = getSnsName(monitoring.sns) // ? 公式SNSの表記に合わせる
    const type = this.getType(monitoring.type)

    const from_id = monitoring.from_account ? monitoring.from_account.id : null
    const from_name = monitoring.from_account ? monitoring.from_account.name : null
    const from_username = monitoring.from_account ? monitoring.from_account.username : null
    const to_name = monitoring.to_account ? monitoring.to_account.name : null
    let from_url = ''
    let sns_status = ''

    switch (monitoring.sns) {
      case 'facebook':
        from_url = `https://www.facebook.com/${from_id}`
        break
      case 'twitter':
        from_url = `https://x.com/${from_username}`
        break
      case 'instagram':
        from_url = `https://instagram.com/${from_username}`
        break
      default:
        break
    }

    if (monitoring.is_like) {
      sns_status = i18n.t('いいね済み')
    }

    if (monitoring.is_retweet) {
      sns_status = i18n.t('リポスト済み')
    }

    if (monitoring.is_hidden) {
      sns_status = i18n.t('非表示')
    }

    if (monitoring.is_deleted) {
      sns_status = i18n.t('削除済み')
    }

    return [
      datetime,
      status,
      sns_name,
      type,
      from_name,
      from_username,
      from_url,
      to_name,
      monitoring.message,
      monitoring.image_url,
      monitoring.sns_url,
      monitoring.reply_url,
      sns_status
    ]
  }

  /**
   * ステータスをセット
   */
  getStatus(status: string): string {
    switch (status) {
      case 'unread':
        return i18n.t('未読')
      case 'read':
        return i18n.t('既読')
      case 'pending':
        return i18n.t('保留')
      default:
        return ''
    }
  }

  /**
   * タイプをセット
   */
  getType(type: string): string {
    switch (type) {
      case 'post':
        return i18n.t('投稿')
      case 'comment':
        return i18n.t('コメント')
      case 'message':
        return i18n.t('メッセージ')
      case 'mention':
        return i18n.t('メンション')
      case 'reply':
        return i18n.t('リプライ')
      default:
        return ''
    }
  }

  /**
   * フィルタアクティブボタン
   */
  filterActive(): void {
    this.is_active = !this.is_active
  }

  /**
   * 一括で既読にする
   */
  async bulkStatusRead() {
    TrackingService.sendEvent('click:モニタリング|タブ内ヘッダー|ステータス変更:既読')

    const message = i18n.t('一括処理を実行してよろしいですか？\n(通知メールは送信されません)')

    const confirm = window.confirm(message)

    if (!confirm) return

    const result = await this.bulkMonitoringStatus({ status: 'read' })

    if (result.data) {
      this.showNotification({ title: '既読に変更しました。' })
    } else {
      this.showNotification({
        title: '既読の変更に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * 一括で保留にする
   */
  async bulkStatusPending() {
    TrackingService.sendEvent('click:モニタリング|タブ内ヘッダー|ステータス変更:保留')

    const message = i18n.t('一括処理を実行してよろしいですか？\n(通知メールは送信されません)')

    const confirm = window.confirm(message)

    if (!confirm) return

    const result = await this.bulkMonitoringStatus({ status: 'pending' })

    if (result.data) {
      this.showNotification({ title: '保留に変更しました。' })
    } else {
      this.showNotification({
        title: '保留の変更に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * 一括で未読にする
   */
  async bulkStatusUnread() {
    TrackingService.sendEvent('click:モニタリング|タブ内ヘッダー|ステータス変更:未読')

    const message = i18n.t('一括処理を実行してよろしいですか？\n(通知メールは送信されません)')

    const confirm = window.confirm(message)

    if (!confirm) return

    const result = await this.bulkMonitoringStatus({ status: 'unread' })

    if (result.data) {
      this.showNotification({ title: '未読に変更しました。' })
    } else {
      this.showNotification({
        title: '未読の変更に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * グループ設定に移動
   */
  changeRouteGroupSetting() {
    TrackingService.sendEvent('click:モニタリング|タブ内ヘッダー|機能設定')

    this.changeRoute({
      name: 'setting_group',
      query: { screen_name: 'function', function_tab_name: 'monitoring' }
    })
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
