import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import MonitoringKeywordItem from '@/client/components-old/molecules/MonitoringKeywordItem'
import PaginationTable from '@/client/components-old/molecules/PaginationTable'
import Search from '@/client/components-old/molecules/Search'
import {
  IGetter as IGroupSettingGetter,
  IState as IGroupSettingState
} from '@/client/store/modules/group_setting'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

@Component({
  name: 'GroupSettingMonitoringKeyword',
  components: {
    Button,
    ButtonLink,
    Icon,
    Message,
    Panel,
    Tooltip,
    Search,
    Select,
    MonitoringKeywordItem,
    PaginationTable
  }
})
export default class GroupSettingMonitoringKeyword extends Vue {
  @group_setting.State('api_monitoring_keywords')
  api_monitoring_keywords: IGroupSettingState['api_monitoring_keywords']
  @group_setting.Getter('monitoring_keyword_categories')
  monitoring_keyword_categories: IGroupSettingGetter['monitoring_keyword_categories']
  @group_setting.Action('updateMonitoringKeyword') updateMonitoringKeyword
  @notification.Action('showNotification') showNotification

  keyword_page = 1
  keyword_search = ''
  keyword_category = ''

  get keyword_page_size() {
    return 5
  }

  get keywords() {
    return this.api_monitoring_keywords
  }

  get filter_keywords() {
    return this.keywords
      .filter(
        v =>
          v.keywords.some(v => v.match(this.keyword_search)) ||
          v.exclude_keywords.some(v => v.match(this.keyword_search))
      )
      .filter(v => !this.keyword_category || v.category === this.keyword_category)
  }

  get display_keywords() {
    const start = (this.keyword_page - 1) * this.keyword_page_size
    const end = start + this.keyword_page_size

    return this.filter_keywords.slice(start, end)
  }

  get categories() {
    const categories = this.monitoring_keyword_categories.map(v => ({ text: v, value: v }))

    categories.unshift({ value: '', text: this.$options.filters.translate('全てのカテゴリ') })

    return categories
  }

  @Emit('open-keyword-create-dialog')
  openKeywordCreateDialog(payload: any) {
    return payload
  }

  @Emit('open-keyword-edit-dialog')
  openKeywordEditDialog(payload: any) {
    return payload
  }

  /**
   * 有効/無効スイッチの切り替え
   */
  async changeValid(keyword: { keyword_id: number; is_valid: boolean }): Promise<void> {
    const result = await this.updateMonitoringKeyword(keyword)

    if (result.data) {
      return this.showNotification({ title: '設定を保存しました。' })
    }

    this.showNotification({
      title: '設定の保存に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }
}
