
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Message from '@/client/components-old/atoms/Message'
import Tabs from '@/client/components-old/atoms/Tabs'
import PostDetailApprovalForm from '@/client/components-old/organisms/PostDetailApprovalForm'
import PostDetailHistory from '@/client/components-old/organisms/PostDetailHistory'
import PostDetailInstagramPublish from '@/client/components-old/organisms/PostDetailInstagramPublish'
import PostDetailMemo from '@/client/components-old/organisms/PostDetailMemo'
import PostDetailPreview from '@/client/components-old/organisms/PostDetailPreview'
import { IRootState } from '@/client/store/global'
import { IState as IPostCreateState } from '@/client/store/modules/post_create'
import { IGetter, IState } from '@/client/store/modules/post_management'
import i18n from '@/client/utils/i18n'
import { convertHelpMessage } from '@/client/utils/notification/index'
import { SnsType } from '@/common/types'

type TMode = 'create' | 'preview' | 'approval' | 'release_procedure'

const post_create = namespace('post_create')
const post_management = namespace('post_management')
const notification = namespace('notification')

@Component({
  name: 'PostDetail',
  components: {
    Tabs,
    Message,
    PostDetailHistory,
    PostDetailMemo,
    PostDetailPreview,
    PostDetailApprovalForm,
    PostDetailInstagramPublish
  }
})
export default class PostDetail extends Vue {
  @State('project') project: IRootState['project']
  @post_create.State('page_type') page_type: IPostCreateState['page_type']
  @post_create.State('selected_message') selected_message: IPostCreateState['selected_message']
  @post_management.State('api_post') api_post: IState['api_post']
  @post_management.State('api_post_memos') api_post_memos: IState['api_post_memos']
  @post_management.State('api_post_histories') api_post_histories: IState['api_post_histories']
  @post_management.State('dialog_post_mode') dialog_post_mode: IState['dialog_post_mode']
  @post_management.State('dialog_post_tab') dialog_post_tab: IState['dialog_post_tab']
  @post_management.State('dialog_post_error') dialog_post_error: IState['dialog_post_error']
  @post_management.State('is_dialog_loading') is_dialog_loading: IState['is_dialog_loading']
  @post_management.State('is_publishing') is_publishing: IState['is_publishing']
  @post_management.Getter('post') post: IGetter['post']
  @post_management.Action('getAPIPostKeywords') getAPIPostKeywords
  @post_management.Action('getAPIPostSettings') getAPIPostSettings
  @post_management.Action('getAPIPersons') getAPIPersons
  @post_management.Action('postRepublish') postRepublish
  @post_management.Action('changePostDialogTab') changePostDialogTab
  @notification.Action('showNotification') showNotification

  @Prop({ type: Number, default: null })
  snsPostId: number | null

  @Prop({ type: String, default: 'create' })
  mode: TMode

  @Watch('project.id', { immediate: true })
  async watchProjectId() {
    if (this.project.id) {
      await this.getAPIPersons()

      if (this.snsPostId) {
        await this.getAPIPostKeywords()
        await this.getAPIPostSettings()
      }
    }
  }

  get is_loading() {
    return this.is_dialog_loading || this.is_publishing
  }

  get loading_text() {
    if (this.is_publishing) {
      return i18n.t('投稿を公開しています。')
    }

    return ''
  }

  get tab_options() {
    const memo_count = this.is_post_create_new ? 0 : this.api_post_memos.length
    const history_count = this.is_post_create_new ? 0 : this.histories.length

    const memo = i18n.t('メモ') + ` (${memo_count})`
    const history = i18n.t('履歴') + ` (${history_count})`
    const preview = this.getPreviewText()

    return [
      { value: 'preview', text: preview },
      { value: 'memo', text: memo },
      { value: 'history', text: history }
    ]
  }

  get is_post_create_new() {
    return this.dialog_post_mode === 'create' && ['create', 'copy'].includes(this.page_type)
  }

  get is_preview() {
    return this.dialog_post_tab === 'preview'
  }

  get is_memo() {
    return this.dialog_post_tab === 'memo'
  }

  get is_history() {
    return this.dialog_post_tab === 'history'
  }

  get is_approval_mode() {
    return (
      this.mode === 'approval' &&
      this.api_post &&
      this.api_post.status === 'approval' &&
      this.$mq !== 'sm'
    )
  }

  get is_release_procedure_mode() {
    return this.mode === 'release_procedure' && this.$mq !== 'sm'
  }

  get is_not_permission() {
    return this.dialog_post_error === 'PERMISSION_DENIED'
  }

  get is_not_exists() {
    return this.dialog_post_error === 'NOT_EXISTS'
  }

  get histories() {
    return this.is_post_create_new ? [] : this.api_post_histories
  }

  /**
   * 再投稿処理
   */
  async republish(account: { account_id: string; sns: SnsType }): Promise<void> {
    const result = await this.postRepublish({
      account_id: account.account_id,
      sns: account.sns,
      post: this.post
    })

    if (result.error) {
      let title = result.error.title
      let message = result.error.message

      if (result.error.type === 'TOKEN_EXPIRED') {
        title = 'アクセストークンが失効しています。'
        message = convertHelpMessage({
          message: 'アクセストークンを更新してください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      }

      this.showNotification({ type: 'error', title, message })
    } else {
      // ? TikTok投稿のみ外部APIの仕様で公開指示までしか出せないため、公開時のメッセージを変更する
      if (account.sns === 'tiktok') {
        this.showNotification({
          title: '投稿を公開します。',
          message: 'TikTokの投稿は約1分ほど公開処理に時間がかかります。'
        })
      } else {
        this.showNotification({ title: '投稿の公開が完了しました。' })
      }
    }

    this.$emit('republish')
  }

  /**
   * プレビューのテキストを取得
   */
  getPreviewText() {
    if (this.mode === 'approval' && this.$mq === 'sm') {
      return '承認'
    }

    if (this.mode === 'release_procedure' && this.$mq === 'sm') {
      return '公開手続'
    }

    return 'プレビュー'
  }

  @Emit('close-dialog')
  closeDialog(event: any) {
    return event
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  @Emit('publish-instagram-post')
  onPublishPost(event: any) {
    return event
  }
}
