
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import FacebookComparisonAnalyticsHeader from '@/client/components-old/organisms/FacebookComparisonAnalyticsHeader'
import FacebookComparisonAnalyticsBenchmark from '@/client/components-old/templates/FacebookComparisonAnalyticsBenchmark'
import FacebookComparisonAnalyticsManagement from '@/client/components-old/templates/FacebookComparisonAnalyticsManagement'
import FacebookComparisonAnalyticsNodata from '@/client/components-old/templates/FacebookComparisonAnalyticsNodata'
import FacebookComparisonAnalyticsPost from '@/client/components-old/templates/FacebookComparisonAnalyticsPost'
import { IState as IFacebookState } from '@/client/store/modules/facebook_comparison_analytics'
import event from '@/client/utils/event'

const facebook = namespace('facebook_comparison_analytics')

@Component({
  name: 'FacebookComparisonAnalytics',
  components: {
    FacebookComparisonAnalyticsHeader,
    FacebookComparisonAnalyticsNodata,
    FacebookComparisonAnalyticsBenchmark,
    FacebookComparisonAnalyticsPost,
    FacebookComparisonAnalyticsManagement
  }
})
export default class FacebookComparisonAnalytics extends Vue {
  @facebook.State('api_sns_comparison_accounts')
  accounts!: IFacebookState['api_sns_comparison_accounts']
  @facebook.State('account_ids') account_ids!: IFacebookState['account_ids']
  @facebook.State('screen_name') screen_name!: IFacebookState['screen_name']
  @facebook.State('is_loading') is_loading!: IFacebookState['is_loading']
  @facebook.Action('createdFacebookComparisonAnalyticsPage')
  createdFacebookComparisonAnalyticsPage!: any
  @facebook.Action('destroyedFacebookComparisonAnalyticsPage')
  destroyedFacebookComparisonAnalyticsPage!: any
  @facebook.Action('changeGroupFacebookComparisonAnalyticsPage')
  changeGroupFacebookComparisonAnalyticsPage!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  get is_empty() {
    if (this.is_management) return false

    if (!this.account_ids.length) return true

    return this.account_ids.every(account_id => {
      const account = this.accounts.find(account => account.id === account_id)

      return account ? Boolean(account.is_empty) : false
    })
  }

  get is_benchmark() {
    return this.screen_name === 'benchmark'
  }

  get is_post() {
    return this.screen_name === 'post'
  }

  get is_management() {
    return this.screen_name === 'management'
  }

  @Watch('is_loading', { immediate: true })
  async changeIsLoading() {
    await this.$nextTick()
    event.resize()
  }

  @Watch('screen_name', { immediate: true })
  async changeScreenName() {
    await this.$nextTick()
    event.resize()
  }

  @Watch('group')
  async watchGroup() {
    await this.changeGroupFacebookComparisonAnalyticsPage()
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.createdFacebookComparisonAnalyticsPage()
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedFacebookComparisonAnalyticsPage()
  }
}
