import { Component, Prop, Vue } from 'vue-property-decorator'

type TAnalyticsNumbersExtra = {
  title: string
  value: string
}

@Component({
  name: 'AnalyticsNumbers'
})
export default class AnalyticsNumbers extends Vue {
  @Prop({ type: String, required: true })
  result: string

  @Prop({ type: Array, default: () => [] })
  extras: TAnalyticsNumbersExtra[]

  get is_view_extra() {
    return this.extras.length > 0
  }
}
