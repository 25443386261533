import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import { IPostImagesActionProfileUploadParams } from '@/client/utils/api/images'
import {
  IGetNotificationSettingsMeResponse,
  INotificationSettings
} from '@/client/utils/api/notification_settings'
import { IGetTimezonesResponse, ITimezone } from '@/client/utils/api/timezones'
import {
  TGetUserDashboardSettingsResponse,
  TPutUserDashboardSettingsChangeOrderNoParams,
  TPutUserDashboardSettingsChangeOrderNoResponse,
  TPutUserDashboardSettingsParams,
  TPutUserDashboardSettingsResponse,
  TUserDashboardSettings
} from '@/client/utils/api/user_dashboard_settings'
import { IGetUserSettingsResponse, IUserSettings } from '@/client/utils/api/user_settings'
import { GetUserRoleParams, GetUserRoleResponse, UserRole } from '@/client/utils/api/users'

export interface IState {
  api_user: IUserSettings | null
  api_role: UserRole | null
  api_notification: INotificationSettings | null
  api_timezones: ITimezone[]
  api_dashboard_settings: TUserDashboardSettings[]
  screen_name: 'user' | 'display' | 'notification' | 'role'
  is_loading: boolean
}

const state: IState = {
  api_user: null,
  api_role: null,
  api_notification: null,
  api_timezones: [],
  api_dashboard_settings: [],
  screen_name: 'user',
  is_loading: false
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  SET_API_USER(state, payload: IGetUserSettingsResponse) {
    state.api_user = payload.data || null
  },
  SET_API_ROLE(state, payload: GetUserRoleResponse) {
    state.api_role = payload.data || null
  },
  SET_API_NOTIFICATION(state, payload: IGetNotificationSettingsMeResponse) {
    state.api_notification = payload.data || null
  },
  SET_API_TIMEZONES(state, payload: IGetTimezonesResponse) {
    state.api_timezones = payload.data || []
  },
  SET_API_DASHBOARD_SETTINGS(state, payload: TGetUserDashboardSettingsResponse) {
    state.api_dashboard_settings = payload.data || []
  },
  SET_SCREEN_NAME(state, payload: 'user' | 'display' | 'notification' | 'role') {
    state.screen_name = payload
  },
  SET_IS_LOADING(state, payload: boolean) {
    state.is_loading = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /*
   * 初期データを取得する
   */
  async fetchInitialData(context) {
    context.commit('SET_IS_LOADING', true)

    await Promise.all([
      context.dispatch('getTimezones'),
      context.dispatch('getUser'),
      context.dispatch('getDashboardSettings'),
      context.dispatch('getNotification'),
      context.dispatch('fetchRole')
    ])

    context.commit('SET_IS_LOADING', false)
  },

  /*
   * ユーザー情報の取得
   */
  async getUser(context) {
    const response = await API.get<IGetUserSettingsResponse>('user_settings')

    if (response.data.data) {
      context.commit('SET_API_USER', response.data)

      if (
        response.data.data.name !== context.rootState.user.name ||
        response.data.data.mail !== context.rootState.user.mail ||
        response.data.data.picture_url !== context.rootState.user.picture_url ||
        response.data.data.language !== context.rootState.user.language ||
        response.data.data.timezone !== context.rootState.user.timezone
      ) {
        await context.dispatch('fetchUserData', null, { root: true })
        await context.dispatch('forceReRender', null, { root: true })
      }
    }
  },

  /*
   * ユーザー情報の更新
   */
  async putUser(
    context,
    payload: {
      mail: string
      name: string
      password?: string
      language: string
      timezone: string
      picture_url: string
    }
  ) {
    const response = await API.put('user_settings', payload)

    if (response.data.data) {
      await context.dispatch('getUser')
    }

    return response.data
  },

  /*
   * 画像アップロード
   */
  async postProfileUpload(context, payload: File) {
    const params: IPostImagesActionProfileUploadParams = {
      file: payload
    }

    const data = new FormData()

    Object.entries(params).forEach(([key, value]) => {
      data.append(key, value)
    })

    const response = await API.post('images?action=profile_upload', data)

    return response.data
  },

  /**
   * Facebook連携をする
   */
  async postFacebookConnection(context, payload: string) {
    const params = {
      access_token: payload
    }

    const response = await API.post('facebook_connections', params)

    if (response.data.data) {
      await context.dispatch('getUser')
    }

    return response.data
  },

  /*
   * Facebook連携を解除する
   */
  async deleteFacebookConnection(context) {
    const response = await API.delete('facebook_connections')

    if (response.data.data) {
      await context.dispatch('getUser')
    }

    return response.data
  },

  /*
   * 2段階認証のQRコードを取得する
   */
  async postTwoFactorSecret() {
    const response = await API.post('two_factor/secret')

    return response.data
  },

  /*
   * 2段階認証の認証コードを設定する
   */
  async postTwoFactorApproval(context, payload: string) {
    const params = {
      auth_code: payload
    }

    const response = await API.post('two_factor/approval', params)

    if (response.data.data) {
      await context.dispatch('getUser')
    }

    return response.data
  },

  /*
   * 2段階認証を無効化する
   */
  async postTwoFactorRelease(context) {
    const response = await API.post('two_factor/release')

    if (response.data.data) {
      await context.dispatch('getUser')
    }

    return response.data
  },

  /*
   * 通知メール設定の取得
   */
  async getNotification(context) {
    const project_id = context.rootState.project.id

    const params = { project_id }

    const response = await API.get<IGetNotificationSettingsMeResponse>('notification_settings/me', {
      params
    })

    if (response.data.data) {
      context.commit('SET_API_NOTIFICATION', response.data)
    }
  },

  /*
   * 通知メール設定の更新
   */
  async putNotification(context, payload: any) {
    const project_id = context.rootState.project.id

    const params = { project_id, ...payload }

    const response = await API.put('notification_settings/me', params)

    if (response.data.data) {
      await context.dispatch('getNotification')
    }

    return response.data
  },

  /*
   * タイムゾーンの取得
   */
  async getTimezones(context) {
    const response = await API.get<IGetTimezonesResponse>('timezones')

    if (response.data.data) {
      context.commit('SET_API_TIMEZONES', response.data)
    }
  },

  /**
   * ユーザ権限情報を取得
   */
  async fetchRole(context) {
    const user_id = context.rootState.user.id
    const project_id = context.rootState.project.id

    const params: GetUserRoleParams = { project_id }

    const response = await API.get<GetUserRoleResponse>(`users/${user_id}/roles`, { params })

    if (response.data.data) {
      context.commit('SET_API_ROLE', response.data)
    }
  },

  /*
   * 選択中の表示を変更
   */
  async changeScreenName(context, payload: 'user' | 'display' | 'notification' | 'role') {
    context.commit('SET_SCREEN_NAME', payload)

    switch (payload) {
      case 'user':
        await context.dispatch('getUser')
        break
      case 'display':
        await context.dispatch('getDashboardSettings')
        break
      case 'notification':
        await context.dispatch('getNotification')
        break
      case 'role':
        await context.dispatch('fetchRole')
        break
    }
  },

  /**
   * ダッシュボード設定の取得
   */
  async getDashboardSettings(context) {
    const response = await API.get<TGetUserDashboardSettingsResponse>('user_dashboard_settings')

    if (response.data.data) {
      context.commit('SET_API_DASHBOARD_SETTINGS', response.data)
    }
  },

  /**
   * ダッシュボード設定の更新
   */
  async putDashboardSettings(context, payload: TPutUserDashboardSettingsParams) {
    const response = await API.put<TPutUserDashboardSettingsResponse>(
      'user_dashboard_settings',
      payload
    )

    if (response.data.data) {
      await context.dispatch('getDashboardSettings')
    }

    return response.data
  },

  /**
   * ダッシュボード設定の並び替え
   */
  async putDashboardSettingsSort(context, payload: TUserDashboardSettings[]) {
    context.commit('SET_API_DASHBOARD_SETTINGS', { data: payload })

    const params: TPutUserDashboardSettingsChangeOrderNoParams = { items: payload.map(p => p.item) }

    const response = await API.put<TPutUserDashboardSettingsChangeOrderNoResponse>(
      'user_dashboard_settings/change_order_no',
      params
    )

    if (!response.data.data) {
      await context.dispatch('getDashboardSettings')
    }

    return response.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
