
import { defineComponent } from 'vue'

import { getTranslateText as t } from '@/client/utils/filters'

export default defineComponent({
  name: 'PostListSubItemBase',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    return {
      t
    }
  }
})
