import API from '@/client/utils/api'

import {
  GetTikTokInsightsAccountsParams,
  GetTikTokInsightsAccountsResponse
} from '../../tiktok_analytics/api'

export async function getTikTokInsightAccounts(query: GetTikTokInsightsAccountsParams) {
  const response = await API.get<GetTikTokInsightsAccountsResponse>('tiktok_insights/accounts', {
    params: query
  })

  return response.data
}
