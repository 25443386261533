import { render, staticRenderFns } from "./DashboardPostItem.vue?vue&type=template&id=098bcae1&scoped=true"
import script from "./DashboardPostItem.ts?vue&type=script&lang=ts&external"
export * from "./DashboardPostItem.ts?vue&type=script&lang=ts&external"
import style0 from "./DashboardPostItem.vue?vue&type=style&index=0&id=098bcae1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "098bcae1",
  null
  
)

export default component.exports