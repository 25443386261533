import { RouteConfig } from 'vue-router'

import FacebookComparisonAnalytics from '../pages/FacebookComparisonAnalytics.vue'

export const comparison_fb: RouteConfig = {
  component: FacebookComparisonAnalytics,
  path: '/comparison_fb',
  name: 'comparison_fb',
  meta: {
    auth: true,
    title: '競合比較 (Facebook)',
    description: '複数アカウントの分析結果を同時に10アカウントまで比較できます。',
    color: 'warning',
    type: 'CHECK_COMPETITOR_ANALYSIS'
  }
}
