// コンポーネント種別
export const TYPES = [
  {
    value: 'total',
    text: '集計'
  },
  {
    value: 'chart',
    text: 'グラフ'
  },
  {
    value: 'post_ranking',
    text: '投稿ランキング'
  },
  {
    value: 'goal',
    text: '目標'
  },
  {
    value: 'table',
    text: 'テーブル'
  }
]

// データ種別
export const DATA_TYPES = [
  {
    value: 'daily_data',
    text: '日次データ',
    color: 'info'
  },
  {
    value: 'post_data',
    text: '投稿データ(公開日で集計)',
    color: 'success'
  }
]

// 間隔種別
export const INTERVALS = [
  { text: '1日', value: '1_day' },
  { text: '7日', value: '7_day' },
  { text: '14日', value: '14_day' },
  { text: '28日', value: '28_day' },
  { text: '1ヶ月', value: '1_month' },
  { text: '3ヶ月', value: '3_month' },
  { text: '6ヶ月', value: '6_month' }
]

// 集計種別
export const UNITS = [
  { text: '期間合計', value: 'total' },
  { text: '1投稿あたり', value: 'average' }
]

// 順序種別
export const POST_RANKING_SORTS = [
  { value: 'ascending', text: 'WORST' },
  { value: 'descending', text: 'BEST' }
]

// 件数種別
export const POST_RANKING_LIMITS = [
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 }
]

// 日次データ指標オプション
export const DAILY_DATA_METRICS = [
  {
    text: 'フォロワー数(累計)',
    value: 'followers_count',
    tooltip: 'アカウント登録より前の値は取得できません。'
  },
  { text: 'フォロワー数(増減)', value: 'followers_count_up_down', tooltip: '' },
  {
    text: 'フォロー数(累計)',
    value: 'friends_count',
    tooltip: 'アカウント登録より前の値は取得できません。'
  },
  { text: 'フォロー数(増減)', value: 'friends_count_up_down', tooltip: '' },
  {
    text: 'リスト数(累計)',
    value: 'listed_count',
    tooltip: 'アカウント登録より前の値は取得できません。'
  },
  { text: 'リスト数(増減)', value: 'listed_count_up_down', tooltip: '' },
  { text: '投稿数', value: 'post_count', tooltip: '' }
]

// 投稿のCSVインポートの指標リスト
export const POST_CSV_IMPORT_METRICS = [
  'permalink_clicks',
  'permalink_clicks_organic',
  'permalink_clicks_paid',
  'hashtag_clicks',
  'hashtag_clicks_organic',
  'hashtag_clicks_paid',
  'detail_expands',
  'detail_expands_organic',
  'detail_expands_paid',
  'app_opens',
  'app_opens_organic',
  'app_opens_paid',
  'app_installs',
  'app_installs_organic',
  'app_installs_paid',
  'follows',
  'follows_organic',
  'follows_paid',
  'media_engagements',
  'media_engagements_organic',
  'media_engagements_paid'
]

// 投稿のプライベートの指標リスト
export const POST_PRIVATE_METRICS = [
  'impressions',
  'impressions_organic',
  'impressions_paid',
  'retweets_organic',
  'retweets_paid',
  'favorites_organic',
  'favorites_paid',
  'replies_organic',
  'replies_paid',
  'reactions_organic',
  'reactions_paid',
  'reactions_organic_rate',
  'reactions_paid_rate',
  'engagements_organic',
  'engagements_paid',
  'engagements_organic_rate',
  'engagements_paid_rate',
  'url_clicks',
  'url_clicks_organic',
  'url_clicks_paid',
  'user_profile_clicks',
  'user_profile_clicks_organic',
  'user_profile_clicks_paid',
  'video_views_organic',
  'video_views_paid',
  'video_playback_0_count',
  'video_playback_0_count_organic',
  'video_playback_0_count_paid',
  'video_playback_25_count',
  'video_playback_25_count_organic',
  'video_playback_25_count_paid',
  'video_playback_50_count',
  'video_playback_50_count_organic',
  'video_playback_50_count_paid',
  'video_playback_75_count',
  'video_playback_75_count_organic',
  'video_playback_75_count_paid',
  'video_playback_100_count',
  'video_playback_100_count_organic',
  'video_playback_100_count_paid'
]

// 投稿データ指標オプション
export const POST_DATA_METRICS = [
  {
    text: 'インプレッション',
    value: 'impressions',
    tooltip: '各ユーザーのタイムラインまたは検索結果に投稿が表示された回数です。'
  },
  { text: 'インプレッション(オーガニック)', value: 'impressions_organic', tooltip: '' },
  { text: 'インプレッション(広告)', value: 'impressions_paid', tooltip: '' },
  { text: 'リポスト', value: 'retweets', tooltip: '' },
  { text: 'リポスト(オーガニック)', value: 'retweets_organic', tooltip: '' },
  { text: 'リポスト(広告)', value: 'retweets_paid', tooltip: '' },
  { text: '引用', value: 'quotes', tooltip: '' },
  { text: 'いいね', value: 'favorites', tooltip: '' },
  { text: 'いいね(オーガニック)', value: 'favorites_organic', tooltip: '' },
  { text: 'いいね(広告)', value: 'favorites_paid', tooltip: '' },
  { text: '返信', value: 'replies', tooltip: '' },
  { text: '返信(オーガニック)', value: 'replies_organic', tooltip: '' },
  { text: '返信(広告)', value: 'replies_paid', tooltip: '' },
  {
    text: 'その他アクション',
    value: 'other_actions',
    tooltip: 'リポスト、引用、いいね、返信を除くアクション数です。'
  },
  { text: 'その他アクション(オーガニック)', value: 'other_actions_organic', tooltip: '' },
  { text: 'その他アクション(広告)', value: 'other_actions_paid', tooltip: '' },
  {
    text: '反応数',
    value: 'reactions',
    tooltip: 'リポスト・引用・いいね・返信の合計数です。'
  },
  { text: '反応数(オーガニック)', value: 'reactions_organic', tooltip: '' },
  { text: '反応数(広告)', value: 'reactions_paid', tooltip: '' },
  { text: '反応率', value: 'reactions_rate', tooltip: '反応数 ÷ 公開日のフォロワー数です。' },
  { text: '反応率(オーガニック)', value: 'reactions_organic_rate', tooltip: '' },
  { text: '反応率(広告)', value: 'reactions_paid_rate', tooltip: '' },
  {
    text: 'エンゲージメント数',
    value: 'engagements',
    tooltip: '投稿のリポスト・引用・いいね・返信・その他アクションの合計数です。'
  },
  {
    text: 'エンゲージメント数(オーガニック)',
    value: 'engagements_organic',
    tooltip: ''
  },
  { text: 'エンゲージメント数(広告)', value: 'engagements_paid', tooltip: '' },
  {
    text: 'エンゲージメント率',
    value: 'engagements_rate',
    tooltip: 'エンゲージメント数 ÷ インプレッションです。'
  },
  { text: 'エンゲージメント率(オーガニック)', value: 'engagements_organic_rate', tooltip: '' },
  { text: 'エンゲージメント率(広告)', value: 'engagements_paid_rate', tooltip: '' },
  {
    text: 'URLクリック数',
    value: 'url_clicks',
    tooltip: '投稿内のURLがクリックされた回数です。Xカードのクリック数は集計されません。'
  },
  { text: 'URLクリック数(オーガニック)', value: 'url_clicks_organic', tooltip: '' },
  { text: 'URLクリック数(広告)', value: 'url_clicks_paid', tooltip: '' },
  {
    text: '固定リンククリック数',
    value: 'permalink_clicks',
    tooltip: '投稿にアクセスするURLがクリックされた回数です。'
  },
  { text: '固定リンククリック数(オーガニック)', value: 'permalink_clicks_organic', tooltip: '' },
  { text: '固定リンククリック数(広告)', value: 'permalink_clicks_paid', tooltip: '' },
  { text: 'ハッシュタグクリック数', value: 'hashtag_clicks', tooltip: '' },
  { text: 'ハッシュタグクリック数(オーガニック)', value: 'hashtag_clicks_organic', tooltip: '' },
  { text: 'ハッシュタグクリック数(広告)', value: 'hashtag_clicks_paid', tooltip: '' },
  {
    text: 'ユーザープロフィールクリック数',
    value: 'user_profile_clicks',
    tooltip: '投稿からプロフィールをクリックされた回数です。'
  },
  {
    text: 'ユーザープロフィールクリック数(オーガニック)',
    value: 'user_profile_clicks_organic',
    tooltip: ''
  },
  { text: 'ユーザープロフィールクリック数(広告)', value: 'user_profile_clicks_paid', tooltip: '' },
  { text: '詳細クリック数', value: 'detail_expands', tooltip: '' },
  { text: '詳細クリック数(オーガニック)', value: 'detail_expands_organic', tooltip: '' },
  { text: '詳細クリック数(広告)', value: 'detail_expands_paid', tooltip: '' },
  { text: 'アプリ表示', value: 'app_opens', tooltip: '' },
  { text: 'アプリ表示(オーガニック)', value: 'app_opens_organic', tooltip: '' },
  { text: 'アプリ表示(広告)', value: 'app_opens_paid', tooltip: '' },
  { text: 'アプリインストール', value: 'app_installs', tooltip: '' },
  { text: 'アプリインストール(オーガニック)', value: 'app_installs_organic', tooltip: '' },
  { text: 'アプリインストール(広告)', value: 'app_installs_paid', tooltip: '' },
  { text: 'フォロワー獲得', value: 'follows', tooltip: '' },
  { text: 'フォロワー獲得(オーガニック)', value: 'follows_organic', tooltip: '' },
  { text: 'フォロワー獲得(広告)', value: 'follows_paid', tooltip: '' },
  {
    text: 'メディアのエンゲージメント数',
    value: 'media_engagements',
    tooltip: '投稿の動画が、2秒以上再生または画像がクリックされた回数です。'
  },
  {
    text: 'メディアのエンゲージメント数(オーガニック)',
    value: 'media_engagements_organic',
    tooltip: ''
  },
  { text: 'メディアのエンゲージメント数(広告)', value: 'media_engagements_paid', tooltip: '' },
  {
    text: 'メディアの再生数',
    value: 'video_views',
    tooltip: '投稿の動画が2秒以上再生された回数です。'
  },
  { text: 'メディアの再生数(オーガニック)', value: 'video_views_organic', tooltip: '' },
  { text: 'メディアの再生数(広告)', value: 'video_views_paid', tooltip: '' },
  { text: '動画0%再生数', value: 'video_playback_0_count', tooltip: '' },
  { text: '動画0%再生数(オーガニック)', value: 'video_playback_0_count_organic', tooltip: '' },
  { text: '動画0%再生数(広告)', value: 'video_playback_0_count_paid', tooltip: '' },
  { text: '動画25%再生数', value: 'video_playback_25_count', tooltip: '' },
  { text: '動画25%再生数(オーガニック)', value: 'video_playback_25_count_organic', tooltip: '' },
  { text: '動画25%再生数(広告)', value: 'video_playback_25_count_paid', tooltip: '' },
  { text: '動画50%再生数', value: 'video_playback_50_count', tooltip: '' },
  { text: '動画50%再生数(オーガニック)', value: 'video_playback_50_count_organic', tooltip: '' },
  { text: '動画50%再生数(広告)', value: 'video_playback_50_count_paid', tooltip: '' },
  { text: '動画75%再生数', value: 'video_playback_75_count', tooltip: '' },
  { text: '動画75%再生数(オーガニック)', value: 'video_playback_75_count_organic', tooltip: '' },
  { text: '動画75%再生数(広告)', value: 'video_playback_75_count_paid', tooltip: '' },
  { text: '動画100%再生数', value: 'video_playback_100_count', tooltip: '' },
  { text: '動画100%再生数(オーガニック)', value: 'video_playback_100_count_organic', tooltip: '' },
  { text: '動画100%再生数(広告)', value: 'video_playback_100_count_paid', tooltip: '' },
  { text: '短縮URLクリック数', value: 'short_url_clicks', tooltip: '' }
]

// 集計用日次データ指標オプション
export const TOTAL_DAILY_DATA_METRICS = DAILY_DATA_METRICS

// 集計用投稿データ指標オプション
export const TOTAL_POST_DATA_METRICS = POST_DATA_METRICS

// 目標用日次データ指標オプション
export const GOAL_DAILY_DATA_METRICS = DAILY_DATA_METRICS

// 目標用投稿データ指標オプション
export const GOAL_POST_DATA_METRICS = POST_DATA_METRICS

// 表の日次データ指標オプション
export const TABLE_DAILY_DATA_METRICS = [...DAILY_DATA_METRICS]

// 表の投稿データ指標オプション
export const TABLE_POST_DATA_METRICS = [...POST_DATA_METRICS]

// 垂直チャートの日次データ指標オプション
export const CHANGE_CHART_DAILY_DATA_METRICS = DAILY_DATA_METRICS

// 垂直チャートの投稿データ指標オプション
export const CHANGE_CHART_POST_DATA_METRICS = POST_DATA_METRICS

// 投稿ランキング指標オプション
export const POST_RANKING_MAIN_METRICS = [
  ...POST_DATA_METRICS,
  { text: '公開日時', value: 'created_time', tooltip: '' },
  { text: '公開日のフォロワー数', value: 'followers_at_that_time', tooltip: '' }
]

export const POST_RANKING_SUB_METRICS = [...POST_RANKING_MAIN_METRICS]

export const POST_TABLE_TOTAL = [
  { text: '公開された投稿', value: 'none' },
  { text: '投稿タイプ', value: 'type' },
  { text: 'ハッシュタグ', value: 'hashtag' },
  { text: 'タグ', value: 'category' },
  { text: '文字数', value: 'character_count' }
]

export const POST_TABLE_TABS = [
  { text: '平均', value: 'average' },
  { text: '合計', value: 'total' }
]

export const POST_TABLE_SORT_BLACKLIST = [
  'account_id',
  'created_time',
  'message',
  'type',
  'post_id',
  'post_url',
  'picture_url',
  'video_url',
  'link_title',
  'link_description',
  'link_url'
]

export const HALF_DOUGHNUT_CHART_POST_DATA_METRICS = [
  {
    text: '反応数',
    value: 'reactions',
    tooltip: ''
  },
  {
    text: 'エンゲージメント数',
    value: 'engagements',
    tooltip: ''
  },
  {
    text: 'その他アクション',
    value: 'other_actions',
    tooltip: ''
  }
]

export const TABLE_COLUMN_METRICS = [
  { text: 'インプレッション', value: 'impressions' },
  { text: 'インプレッション(オーガニック)', value: 'impressions_organic' },
  { text: 'インプレッション(広告)', value: 'impressions_paid' },
  { text: 'リポスト', value: 'retweets' },
  { text: 'リポスト(オーガニック)', value: 'retweets_organic' },
  { text: 'リポスト(広告)', value: 'retweets_paid' },
  { text: '引用', value: 'quotes' },
  { text: 'いいね', value: 'favorites' },
  { text: 'いいね(オーガニック)', value: 'favorites_organic' },
  { text: 'いいね(広告)', value: 'favorites_paid' },
  { text: '返信', value: 'replies' },
  { text: '返信(オーガニック)', value: 'replies_organic' },
  { text: '返信(広告)', value: 'replies_paid' },
  { text: 'その他アクション', value: 'other_actions' },
  { text: 'その他アクション(オーガニック)', value: 'other_actions_organic' },
  { text: 'その他アクション(広告)', value: 'other_actions_paid' },
  { text: '反応数', value: 'reactions' },
  { text: '反応数(オーガニック)', value: 'reactions_organic' },
  { text: '反応数(広告)', value: 'reactions_paid' },
  { text: '反応率', value: 'reactions_rate' },
  { text: '反応率(オーガニック)', value: 'reactions_organic_rate' },
  { text: '反応率(広告)', value: 'reactions_paid_rate' },
  { text: 'エンゲージメント数', value: 'engagements' },
  { text: 'エンゲージメント数(オーガニック)', value: 'engagements_organic' },
  { text: 'エンゲージメント数(広告)', value: 'engagements_paid' },
  { text: 'エンゲージメント率', value: 'engagements_rate' },
  { text: 'エンゲージメント率(オーガニック)', value: 'engagements_organic_rate' },
  { text: 'エンゲージメント率(広告)', value: 'engagements_paid_rate' },
  { text: 'URLクリック数', value: 'url_clicks' },
  { text: 'URLクリック数(オーガニック)', value: 'url_clicks_organic' },
  { text: 'URLクリック数(広告)', value: 'url_clicks_paid' },
  { text: '固定リンククリック数', value: 'permalink_clicks' },
  { text: '固定リンククリック数(オーガニック)', value: 'permalink_clicks_organic' },
  { text: '固定リンククリック数(広告)', value: 'permalink_clicks_paid' },
  { text: 'ハッシュタグクリック数', value: 'hashtag_clicks' },
  { text: 'ハッシュタグクリック数(オーガニック)', value: 'hashtag_clicks_organic' },
  { text: 'ハッシュタグクリック数(広告)', value: 'hashtag_clicks_paid' },
  { text: 'ユーザープロフィールクリック数', value: 'user_profile_clicks' },
  { text: 'ユーザープロフィールクリック数(オーガニック)', value: 'user_profile_clicks_organic' },
  { text: 'ユーザープロフィールクリック数(広告)', value: 'user_profile_clicks_paid' },
  { text: '詳細クリック数', value: 'detail_expands' },
  { text: '詳細クリック数(オーガニック)', value: 'detail_expands_organic' },
  { text: '詳細クリック数(広告)', value: 'detail_expands_paid' },
  { text: 'アプリ表示', value: 'app_opens' },
  { text: 'アプリ表示(オーガニック)', value: 'app_opens_organic' },
  { text: 'アプリ表示(広告)', value: 'app_opens_paid' },
  { text: 'アプリインストール', value: 'app_installs' },
  { text: 'アプリインストール(オーガニック)', value: 'app_installs_organic' },
  { text: 'アプリインストール(広告)', value: 'app_installs_paid' },
  { text: 'フォロワー獲得', value: 'follows' },
  { text: 'フォロワー獲得(オーガニック)', value: 'follows_organic' },
  { text: 'フォロワー獲得(広告)', value: 'follows_paid' },
  { text: 'メディアのエンゲージメント数', value: 'media_engagements' },
  { text: 'メディアのエンゲージメント数(オーガニック)', value: 'media_engagements_organic' },
  { text: 'メディアのエンゲージメント数(広告)', value: 'media_engagements_paid' },
  { text: 'メディアの再生数', value: 'video_views' },
  { text: 'メディアの再生数(オーガニック)', value: 'video_views_organic' },
  { text: 'メディアの再生数(広告)', value: 'video_views_paid' },
  { text: '動画0%再生数', value: 'video_playback_0_count' },
  { text: '動画0%再生数(オーガニック)', value: 'video_playback_0_count_organic' },
  { text: '動画0%再生数(広告)', value: 'video_playback_0_count_paid' },
  { text: '動画25%再生数', value: 'video_playback_25_count' },
  { text: '動画25%再生数(オーガニック)', value: 'video_playback_25_count_organic' },
  { text: '動画25%再生数(広告)', value: 'video_playback_25_count_paid' },
  { text: '動画50%再生数', value: 'video_playback_50_count' },
  { text: '動画50%再生数(オーガニック)', value: 'video_playback_50_count_organic' },
  { text: '動画50%再生数(広告)', value: 'video_playback_50_count_paid' },
  { text: '動画75%再生数', value: 'video_playback_75_count' },
  { text: '動画75%再生数(オーガニック)', value: 'video_playback_75_count_organic' },
  { text: '動画75%再生数(広告)', value: 'video_playback_75_count_paid' },
  { text: '動画100%再生数', value: 'video_playback_100_count' },
  { text: '動画100%再生数(オーガニック)', value: 'video_playback_100_count_organic' },
  { text: '動画100%再生数(広告)', value: 'video_playback_100_count_paid' },
  { text: '短縮URLクリック数', value: 'short_url_clicks' },
  { text: '公開日のフォロワー数', value: 'followers_at_that_time' }
]

export const POST_TABLE_METRICS = [
  { text: '公開日時', value: 'created_time' },
  { text: '投稿内容', value: 'message' },
  { text: '投稿タイプ', value: 'type' },
  { text: '投稿ID', value: 'post_id' },
  { text: '投稿URL', value: 'post_url' },
  { text: 'サムネイルURL', value: 'picture_url' },
  { text: '動画URL', value: 'video_url' },
  { text: 'リンクタイトル', value: 'link_title' },
  { text: 'リンク説明', value: 'link_description' },
  { text: 'リンクURL', value: 'link_url' },
  { text: 'タグ', value: 'category_name' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_TYPE_METRICS = [
  { text: '投稿タイプ', value: 'type' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_HASHTAG_METRICS = [
  { text: 'ハッシュタグ', value: 'hashtag' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_CATEGORY_METRICS = [
  { text: 'タグ', value: 'category' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_CHARACTER_COUNT_METRICS = [
  { text: '文字数レンジ', value: 'character_range' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TYPE = [
  { text: 'テキスト', value: 'text' },
  { text: '画像', value: 'photo' },
  { text: 'リンク', value: 'link' },
  { text: '動画', value: 'video' },
  { text: 'アニメーションGIF', value: 'animation-gif' }
]

export const CHARACTER_RANGE = [
  {
    title: '10文字未満',
    greater_than: 0,
    less_than: 10
  },
  {
    title: '10文字以上 - 50文字未満',
    greater_than: 10,
    less_than: 50
  },
  {
    title: '50文字以上 - 100文字未満',
    greater_than: 50,
    less_than: 100
  },
  {
    title: '100文字以上 - 200文字未満',
    greater_than: 100,
    less_than: 200
  },
  {
    title: '200文字以上',
    greater_than: 200,
    less_than: null
  }
]

export const DISPLAY_TYPE = [
  { text: 'テーブル', value: 'table' },
  { text: 'グリッド', value: 'grid' }
]
