import { Component, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import TextareaEmoji from '@/client/components-old/molecules/TextareaEmoji'
import { IRootState } from '@/client/store/global'
import { IState as IMonitoringTemplatesState } from '@/client/store/modules/monitoring_templates'
import { IMonitoringTemplate } from '@/client/utils/api/monitoring_templates'
import event from '@/client/utils/event'

const monitoring_templates = namespace('monitoring_templates')
const notification = namespace('notification')

@Component({
  name: 'MonitoringTemplateDialog',
  components: {
    draggable,
    Button,
    Dialog,
    Flex,
    Icon,
    Input,
    Message,
    Tooltip,
    TextareaEmoji
  }
})
export default class MonitoringTemplateDialog extends Vue {
  @State('user') user: IRootState['user']
  @monitoring_templates.State('templates') templates: IMonitoringTemplatesState['templates']
  @monitoring_templates.Action('createTemplate') createTemplate
  @monitoring_templates.Action('updateTemplate') updateTemplate
  @monitoring_templates.Action('removeTemplate') removeTemplate
  @monitoring_templates.Action('syncTemplateOrder') syncTemplateOrder
  @notification.Action('showNotification') showNotification

  visible = false
  create_mode = false
  create_template_name = ''
  create_template_content = ''
  edit_template_id = 0
  edit_template_name = ''
  edit_template_content = ''

  get edit_mode() {
    return Boolean(this.edit_template_id)
  }

  get create_disabled() {
    const name = this.create_template_name.trim()
    const content = this.create_template_content.trim()

    return !name.length || !content.length
  }

  get edit_disabled() {
    const name = this.edit_template_name.trim()
    const content = this.edit_template_content.trim()

    return !name.length || !content.length
  }

  /**
   * ダイアログを開く
   */
  open(): void {
    this.visible = true
    this.create_mode = false
    this.create_template_name = ''
    this.create_template_content = ''
    this.edit_template_id = 0
    this.edit_template_name = ''
    this.edit_template_content = ''
  }

  /**
   * ダイアログを非表示
   */
  hide(): void {
    this.visible = false
  }

  /**
   * 作成モードの開始
   */
  startCreateMode(): void {
    this.create_mode = true
  }

  /**
   * 作成モードの終了
   */
  cancelCreateMode(): void {
    this.create_mode = false
  }

  /**
   * 編集モードのチェック
   */
  checkEditMode(template_id: number): boolean {
    return this.edit_template_id === template_id
  }

  /**
   * 編集フォームを表示
   */
  startEditMode(template_id: number): void {
    const template = this.templates.find(v => v.id === template_id)

    if (!template) return

    this.edit_template_id = template.id
    this.edit_template_name = template.name
    this.edit_template_content = template.content
  }

  /**
   * 編集モードをキャンセル
   */
  cancelEditMode(): void {
    this.edit_template_id = 0
    this.edit_template_name = ''
    this.edit_template_content = ''
  }

  copyMessage() {
    event.copy('__username__')
    this.showNotification({ title: 'クリップボードにコピーしました。' })
  }

  /**
   * 作成フォームの送信
   */
  async submitCreateForm(): Promise<void> {
    this.create_template_name = this.create_template_name.trim()
    this.create_template_content = this.create_template_content.trim()

    if (!this.create_template_name || !this.create_template_content) return

    const result = await this.createTemplate({
      name: this.create_template_name,
      content: this.create_template_content
    })

    if (result.data) {
      this.create_template_name = ''
      this.create_template_content = ''

      this.cancelCreateMode()

      return this.showNotification({ title: 'テンプレートを保存しました。' })
    }

    if (result.error && result.error.type === 'DUPLICATE') {
      return this.showNotification({
        title: '同じテンプレート名が存在するため、他のテンプレート名を入力してください。',
        type: 'error'
      })
    }

    this.showNotification({ title: 'テンプレートを保存に失敗しました。', type: 'error' })
  }

  /**
   * 編集フォームの送信
   */
  async submitEditForm(): Promise<void> {
    if (!this.edit_template_id) return

    this.edit_template_name = this.edit_template_name.trim()
    this.edit_template_content = this.edit_template_content.trim()

    if (!this.edit_template_name || !this.edit_template_content) return

    const result = await this.updateTemplate({
      template_id: this.edit_template_id,
      name: this.edit_template_name,
      content: this.edit_template_content
    })

    if (result.data) {
      this.showNotification({
        title: this.$options.filters.translate('[[name]]を変更しました。', {
          name: this.edit_template_name
        })
      })

      this.cancelEditMode()

      return
    }

    if (result && result.error && result.error.type === 'DUPLICATE') {
      return this.showNotification({
        title: '同じテンプレート名が存在するため、他のテンプレート名を入力してください。',
        type: 'error'
      })
    }

    if (result && result.error && result.error.type === 'NOT_EXISTS') {
      this.cancelEditMode()

      return this.showNotification({
        title: 'テンプレートがすでに削除されています。',
        type: 'error'
      })
    }

    this.showNotification({ title: 'テンプレートを変更に失敗しました。', type: 'error' })
  }

  /**
   * テンプレート削除のチェック
   */
  async checkRemoveTemplate(template_id: number): Promise<void> {
    const template = this.templates.find(v => v.id === template_id)

    if (!template) return

    const confirm = window.confirm(
      this.$options.filters.translate('[[name]]を削除してよろしいですか？', {
        name: template.name
      })
    )

    if (!confirm) return

    const result = await this.removeTemplate({ template_id: template.id })

    if (result.data) {
      return this.showNotification({
        title: this.$options.filters.translate('[[name]]を削除しました。', { name: template.name })
      })
    }

    if (result.error && result.error.type === 'NOT_EXISTS') {
      return this.showNotification({
        title: 'テンプレートがすでに削除されています。',
        type: 'error'
      })
    }

    this.showNotification({ title: 'テンプレートの削除に失敗しました。', type: 'error' })
  }

  /**
   * ドラッグ＆ドロップで変更した場合
   */
  async changeDraggable(payload: IMonitoringTemplate[]): Promise<void> {
    const result = await this.syncTemplateOrder(payload)

    if (result.data) {
      return this.showNotification({ title: 'テンプレートの順番を保存しました。' })
    }

    this.showNotification({ title: 'テンプレートの順番の保存に失敗しました。', type: 'error' })
  }
}
