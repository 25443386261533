import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Tabs from '@/client/components-old/atoms/Tabs'
import AccountSelect from '@/client/components-old/molecules/AccountSelect'
import AnalyticsDateRangePicker from '@/client/components-old/organisms/AnalyticsDateRangePicker'
import { TrackingService } from '@/client/services'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import { IState as ITwitterState } from '@/client/store/modules/twitter_analytics'
import { convertHelpMessage } from '@/client/utils/notification'

const accounts = namespace('accounts')
const twitter = namespace('twitter_analytics')
const notification = namespace('notification')

@Component({
  name: 'TwitterAnalyticsHeader',
  components: {
    Tabs,
    AccountSelect,
    AnalyticsDateRangePicker
  }
})
export default class TwitterAnalyticsHeader extends Vue {
  @accounts.Getter('twitter_analytics') sns_accounts: IAccountsGetter['twitter_analytics']
  @twitter.State('screen_name') screen_name: ITwitterState['screen_name']
  @twitter.State('account_id') account_id: ITwitterState['account_id']
  @twitter.Action('getInsights') getInsights
  @twitter.Action('getScreen') getScreen
  @notification.Action('showNotification') showNotification

  @Watch('account_id', { immediate: true })
  watchAccountId() {
    const account = this.sns_accounts.find(account => account.id === this.account_id)

    if (account && account.expired) {
      this.showNotification({
        type: 'error',
        title: 'アクセストークンが失効しているため、データ取得ができない状態です。',
        message: convertHelpMessage({
          message: '至急、アクセストークンを更新してください。',
          help_color: 'warning',
          help_type: 'ABOUT_ACCESS_TOKEN'
        })
      })
    }
  }

  get is_empty() {
    const account = this.sns_accounts.find(account => account.id === this.account_id)
    return account ? account.is_empty : false
  }

  get accounts_options() {
    return this.sns_accounts
  }

  get screens_options() {
    return [
      { value: 'summary', text: 'サマリー', icon: 'dashboard' },
      { value: 'post', text: '投稿', icon: 'rate-review' },
      { value: 'follower', text: 'フォロワー', icon: 'group' },
      { value: 'action', text: 'アクション', icon: 'action' },
      { value: 'reach', text: 'リーチ', icon: 'megaphone' }
    ]
  }

  /**
   * アカウントIDの変更
   * @param {string} account_id アカウントID
   * @returns {void}
   */
  async changeAccountId(account_id: string): Promise<void> {
    TrackingService.sendEvent('select:自社分析(TW)|画面内ヘッダー|アカウント選択')

    await this.getInsights({ account_id })
  }

  /**
   * 日付範囲の変更
   *  @returns {void}
   */
  async changeDateRange(): Promise<void> {
    TrackingService.sendEvent('select:自社分析(TW)|画面内ヘッダー|期間指定')

    await this.getInsights({ account_id: this.account_id })
  }

  /**
   * タブの変更
   */
  changeTab(tab: string) {
    TrackingService.sendEvent(`click:自社分析(TW)|タブ:${tab}`)

    this.getScreen(tab)
  }
}
