
import { Component, Emit, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import PasswordRequestForm from '@/client/components-old/organisms/PasswordRequestForm'
import { IRootState } from '@/client/store/global'

@Component({
  name: 'PasswordRequest',
  components: {
    ButtonLink,
    PasswordRequestForm
  }
})
export default class PasswordRequest extends Vue {
  @State('user') user!: IRootState['user']
  @Action('fetchLanguage') fetchLanguage: any
  @Action('forceReRender') forceReRender: any

  get disabled_ja() {
    return this.user.language === 'ja'
  }

  get disabled_en() {
    return this.user.language === 'en'
  }

  get color_ja() {
    return this.disabled_ja ? 'default' : 'info'
  }

  get color_en() {
    return this.disabled_en ? 'default' : 'info'
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  /**
   * 言語の変更
   */
  async changeLanguage(language: 'ja' | 'en') {
    await this.fetchLanguage(language)
    await this.forceReRender()
  }
}
