import twttr from 'twitter-text'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import SwitchSns from '@/client/components-old/molecules/SwitchSns'
import TextareaLineNumber from '@/client/components-old/molecules/TextareaLineNumber'
import PostTemplateDialog from '@/client/components-old/organisms/PostTemplateDialog'
import { TrackingService } from '@/client/services'
import {
  IGetter as IPostCreateGetter,
  IState as IPostCreateState
} from '@/client/store/modules/post_create'
import { IState as IPostTemplateState } from '@/client/store/modules/post_templates'

const post_create = namespace('post_create')
const post_templates = namespace('post_templates')

@Component({
  name: 'PostCreateContent',
  components: {
    Button,
    ButtonLink,
    Dropdown,
    Flex,
    Icon,
    Message,
    Panel,
    Select,
    Tooltip,
    SwitchButton,
    SwitchSns,
    TextareaLineNumber,
    PostTemplateDialog
  }
})
export default class PostCreateContent extends Vue {
  @post_create.State('api_post') api_post: IPostCreateState['api_post']
  @post_create.State('api_post_setting') api_post_setting: IPostCreateState['api_post_setting']
  @post_create.State('api_post_keywords') api_post_keywords: IPostCreateState['api_post_keywords']
  @post_create.State('accounts') accounts: IPostCreateState['accounts']
  @post_create.State('message') message: IPostCreateState['message']
  @post_create.State('master_sync') master_sync: IPostCreateState['master_sync']
  @post_create.State('selected_message') selected_message: IPostCreateState['selected_message']
  @post_create.State('is_loading')
  is_loading: IPostCreateState['is_loading']
  @post_create.Getter('is_twitter_count_over')
  is_twitter_count_over: IPostCreateGetter['is_twitter_count_over']
  @post_create.Getter('is_instagram_count_over')
  is_instagram_count_over: IPostCreateGetter['is_instagram_count_over']
  @post_create.Getter('is_tiktok_count_over')
  is_tiktok_count_over: IPostCreateGetter['is_tiktok_count_over']
  @post_create.Getter('use_facebook') use_facebook: IPostCreateGetter['use_facebook']
  @post_create.Getter('use_twitter') use_twitter: IPostCreateGetter['use_twitter']
  @post_create.Getter('use_instagram') use_instagram: IPostCreateGetter['use_instagram']
  @post_create.Getter('use_tiktok') use_tiktok: IPostCreateGetter['use_tiktok']
  @post_create.Getter('is_single_sns') is_single_sns: IPostCreateGetter['is_single_sns']
  @post_create.Mutation('SET_SELECTED_MESSAGE') setSelectedMessage
  @post_create.Mutation('SET_MESSAGE') setMessage
  @post_create.Mutation('SET_MASTER_SYNC') setMasterSync
  @post_create.Action('getFacebookLink') getFacebookLink
  @post_templates.State('templates') templates: IPostTemplateState['templates']

  $refs: {
    PostTemplateDialog: any
  }

  template: string | number = ''

  @Watch('master_sync.facebook')
  async watchMasterSyncFacebook() {
    await this.getFacebookLink()
  }

  @Watch('templates')
  watchTemplates() {
    if (!this.templates.some(template => template.id === this.template)) {
      this.template = ''
    }
  }

  @Watch('is_single_sns')
  changeMasterSync() {
    if (!this.is_single_sns) {
      return
    }

    const message = { ...this.message }

    if (this.use_facebook) {
      message.master = message.facebook
    }

    if (this.use_instagram) {
      message.master = message.instagram
    }

    if (this.use_twitter) {
      message.master = message.twitter
    }

    if (this.use_tiktok) {
      message.master = message.tiktok
    }

    this.setMessage(message)
    this.setSelectedMessage('master')
    this.setMasterSync({
      facebook: true,
      instagram: true,
      twitter: true,
      tiktok: true
    })
  }

  get is_disabled_message() {
    return this.sns_master_sync || this.is_loading
  }

  get is_master() {
    return this.selected_message === 'master' && !this.is_single_sns
  }

  get is_facebook() {
    return (
      this.selected_message === 'facebook' ||
      (this.accounts.length && this.accounts.every(v => v.sns === 'facebook'))
    )
  }

  get is_twitter() {
    return (
      this.selected_message === 'twitter' ||
      (this.accounts.length && this.accounts.every(v => v.sns === 'twitter'))
    )
  }

  get is_instagram() {
    return (
      this.selected_message === 'instagram' ||
      (this.accounts.length && this.accounts.every(v => v.sns === 'instagram'))
    )
  }

  get is_tiktok() {
    return (
      this.selected_message === 'tiktok' ||
      (this.accounts.length && this.accounts.every(v => v.sns === 'tiktok'))
    )
  }

  get highlight_options() {
    if (!this.api_post_setting) {
      return {
        check_double_byte_alphanumeric: false,
        check_double_byte_symbol: false,
        check_kana: false,
        check_punctuation: false,
        check_space: false
      }
    }

    return {
      check_double_byte_alphanumeric: this.api_post_setting.check_2byte1,
      check_double_byte_symbol: this.api_post_setting.check_2byte2,
      check_kana: this.api_post_setting.check_kana,
      check_punctuation: this.api_post_setting.check_punctuation,
      check_space: this.api_post_setting.check_space
    }
  }

  get highlight_words() {
    return this.api_post_keywords
      .filter(keyword => keyword.is_valid)
      .map(keyword => keyword.keyword)
      .filter(word => word.trim())
  }

  get is_sync() {
    return this.selected_message !== 'master'
  }

  get sns_message() {
    switch (this.selected_message) {
      case 'facebook':
        return this.message.facebook || ''
      case 'twitter':
        return this.message.twitter || ''
      case 'instagram':
        return this.message.instagram || ''
      case 'tiktok':
        return this.message.tiktok || ''
      default:
        return this.message.master || ''
    }
  }

  set sns_message(value) {
    const message = { ...this.message }

    switch (this.selected_message) {
      case 'facebook':
        message.facebook = value
        break
      case 'twitter':
        message.twitter = value
        break
      case 'instagram':
        message.instagram = value
        break
      case 'tiktok':
        message.tiktok = value
        break
      default:
        message.master = value
        break
    }

    const is_facebook = this.accounts.some(v => v.sns === 'facebook')
    const is_twitter = this.accounts.some(v => v.sns === 'twitter')
    const is_instagram = this.accounts.some(v => v.sns === 'instagram')
    const is_tiktok = this.accounts.some(v => v.sns === 'tiktok')

    if (this.selected_message === 'master') {
      if (this.master_sync.facebook && is_facebook) message.facebook = value
      if (this.master_sync.twitter && is_twitter) message.twitter = value
      if (this.master_sync.instagram && is_instagram) message.instagram = value
      if (this.master_sync.tiktok && is_tiktok) message.tiktok = value
    }

    this.setMessage(message)
  }

  get sns_master_sync() {
    switch (this.selected_message) {
      case 'facebook':
        return this.master_sync.facebook
      case 'twitter':
        return this.master_sync.twitter
      case 'instagram':
        return this.master_sync.instagram
      case 'tiktok':
        return this.master_sync.tiktok
      default:
        return false
    }
  }

  set sns_master_sync(value) {
    const message = { ...this.message }
    const master_sync = { ...this.master_sync }

    switch (this.selected_message) {
      case 'facebook':
        master_sync.facebook = value
        break
      case 'twitter':
        master_sync.twitter = value
        break
      case 'instagram':
        master_sync.instagram = value
        break
      case 'tiktok':
        master_sync.tiktok = value
        break
    }

    const is_facebook = this.accounts.some(v => v.sns === 'facebook')
    const is_twitter = this.accounts.some(v => v.sns === 'twitter')
    const is_instagram = this.accounts.some(v => v.sns === 'instagram')
    const is_tiktok = this.accounts.some(v => v.sns === 'tiktok')

    if (master_sync.facebook && is_facebook) message.facebook = message.master
    if (master_sync.twitter && is_twitter) message.twitter = message.master
    if (master_sync.instagram && is_instagram) message.instagram = message.master
    if (master_sync.tiktok && is_tiktok) message.tiktok = message.master

    this.setMessage(message)
    this.setMasterSync(master_sync)
  }

  get word_count() {
    const message = this.sns_message.trim()

    switch (this.selected_message) {
      case 'twitter':
        return twttr.parseTweet(message).weightedLength
      default:
        return message.length
    }
  }

  get word_error() {
    switch (this.selected_message) {
      case 'twitter':
        return this.is_twitter_count_over
      case 'instagram':
        return this.is_instagram_count_over
      case 'tiktok':
        return this.is_tiktok_count_over
      default:
        return false
    }
  }

  get templates_options() {
    return this.templates.map(template => ({
      text: template.name,
      value: template.id,
      content: template.content
    }))
  }

  /**
   * 投稿内容でエンターを入力した場合
   * @returns {Promise<void>} void
   */
  async enterMessage(): Promise<void> {
    await this.getFacebookLink()
  }

  /**
   * テキストエリアをブラー
   * @returns {Promise<void>} void
   */
  async blurMessage(): Promise<void> {
    await this.getFacebookLink()
  }

  /**
   * 投稿内容でペーストした場合
   * @returns {Promise<void>} void
   */
  async pasteMessage(): Promise<void> {
    await this.$nextTick()
    await this.getFacebookLink()
  }

  /**
   * テンプレートの内容を投稿内容に追加する
   * @returns {void}
   */
  changeTemplate(): void {
    TrackingService.sendEvent('click:投稿作成|投稿内容|テンプレートの挿入')

    const message = { ...this.message }

    const template = this.templates.find(v => v.id === this.template)
    const new_line = '\n'
    const template_content = template ? template.content : ''

    if (
      !this.accounts.length ||
      this.is_master ||
      (this.master_sync.facebook && this.is_facebook) ||
      (this.master_sync.twitter && this.is_twitter) ||
      (this.master_sync.instagram && this.is_instagram) ||
      (this.master_sync.tiktok && this.is_tiktok)
    ) {
      message.master
        ? (message.master += new_line + template_content)
        : (message.master = template_content)
    }

    if (this.is_facebook || (this.master_sync.facebook && this.is_master)) {
      message.facebook
        ? (message.facebook += new_line + template_content)
        : (message.facebook = template_content)
    }

    if (this.is_twitter || (this.master_sync.twitter && this.is_master)) {
      message.twitter
        ? (message.twitter += new_line + template_content)
        : (message.twitter = template_content)
    }

    if (this.is_instagram) {
      message.instagram
        ? (message.instagram += new_line + template_content)
        : (message.instagram = template_content)
    }

    if (this.is_tiktok) {
      message.tiktok
        ? (message.tiktok += new_line + template_content)
        : (message.tiktok = template_content)
    }

    this.setMessage(message)
  }

  /**
   * テンプレートの管理を開く
   */
  openPostTemplateDialog(event: any) {
    TrackingService.sendEvent('click:投稿作成|投稿内容|テンプレートの管理')

    this.$refs.PostTemplateDialog.open(event)
  }
}
