import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Tabs from '@/client/components-old/atoms/Tabs'
import { IRootState } from '@/client/store/global'
import { IState as ISettingState } from '@/client/store/modules/personal_setting'

const setting = namespace('personal_setting')

@Component({
  name: 'PersonalSettingHeader',
  components: {
    Tabs
  }
})
export default class PersonalSettingHeader extends Vue {
  @State('projects') projects: IRootState['projects']
  @setting.State('screen_name') screen_name: ISettingState['screen_name']
  @setting.Action('changeScreenName') changeScreenName

  get screens_options() {
    return [
      {
        value: 'user',
        text: '基本設定',
        icon: 'account-box'
      },
      {
        value: 'display',
        text: '表示設定',
        icon: 'v-card'
      },
      {
        value: 'notification',
        text: '通知メール',
        icon: 'email',
        disabled: this.projects.length === 0
      },
      {
        value: 'role',
        text: '権限',
        icon: 'shield',
        disabled: this.projects.length === 0
      }
    ]
  }
}
