import { RouteConfig } from 'vue-router'

import InstagramReport from '../pages/InstagramReport.vue'

export const report_in: RouteConfig = {
  component: InstagramReport,
  path: '/report_in',
  name: 'report_in',
  meta: {
    auth: true,
    title: 'Excelレポート (Instagram)',
    description: 'SNSアカウントのExcelレポートがダウンロードできます。',
    color: 'warning',
    type: 'DOWNLOAD_EXCEL_REPORT_OWN_ANALYSIS'
  }
}
