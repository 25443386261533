import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { IState as IGroupSettingState } from '@/client/store/modules/group_setting'

const group_setting = namespace('group_setting')

@Component({
  name: 'GroupSettingPostShortUrl',
  components: {
    ButtonLink,
    Icon,
    Panel,
    Tooltip
  }
})
export default class GroupSettingPostShortUrl extends Vue {
  @group_setting.State('api_short_url_domains')
  api_short_url_domains: IGroupSettingState['api_short_url_domains']

  @Emit('open-short-url-setting-dialog')
  open(payload: any) {
    return payload
  }
}
