import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import SwitchRow from '@/client/components-old/molecules/SwitchRow'
import { IState as IGroupSettingState } from '@/client/store/modules/group_setting'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

@Component({
  name: 'GroupSettingMonitoringFlag',
  components: {
    Icon,
    Panel,
    Tooltip,
    SwitchRow
  }
})
export default class GroupSettingMonitoringFlag extends Vue {
  @group_setting.State('api_monitoring_settings')
  api_monitoring_settings: IGroupSettingState['api_monitoring_settings']
  @group_setting.Action('updateMonitoringSettings') updateMonitoringSettings
  @notification.Action('showNotification') showNotification

  @Prop({ type: String, default: 'observer_allow' }) type: 'observer_allow' | 'auto_to_read'

  get is_observer_allow() {
    return this.type === 'observer_allow'
  }

  get is_auto_to_read() {
    return this.type === 'auto_to_read'
  }

  get group_settings() {
    const setting = this.api_monitoring_settings

    if (this.is_observer_allow) {
      return [
        {
          text: 'コメント・メッセージ返信',
          value: setting.observer_allow_reply,
          field: 'observer_allow_reply'
        },
        {
          text: 'いいね！',
          value: setting.observer_allow_like,
          field: 'observer_allow_like'
        },
        {
          text: 'リポスト',
          value: setting.observer_allow_retweet,
          field: 'observer_allow_retweet'
        },
        {
          text: 'コメント・投稿の非表示',
          value: setting.observer_allow_hidden,
          field: 'observer_allow_hidden'
        },
        {
          text: 'コメント・投稿の削除',
          value: setting.observer_allow_delete,
          field: 'observer_allow_delete'
        },
        {
          text: 'アカウントのブロック',
          value: setting.observer_allow_block,
          field: 'observer_allow_block'
        }
      ]
    }

    if (this.is_auto_to_read) {
      return [
        {
          text: '返信',
          value: setting.reply_to_read,
          field: 'reply_to_read'
        },
        {
          text: 'いいね！',
          value: setting.like_to_read,
          field: 'like_to_read'
        },
        {
          text: '非表示',
          value: setting.hide_to_read,
          field: 'hide_to_read'
        },
        {
          text: '削除',
          value: setting.delete_to_read,
          field: 'delete_to_read'
        }
      ]
    }

    return []
  }

  /**
   * 各設定の有効フラグを変更
   */
  async updateMonitoringSetting(field: string, value: boolean) {
    const params = {
      [field]: value
    }

    const result = await this.updateMonitoringSettings(params)

    if (result.data) {
      return this.showNotification({ title: '設定を保存しました。' })
    }

    this.showNotification({ title: '設定の保存に失敗しました。', type: 'error' })
  }
}
