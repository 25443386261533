import { render, staticRenderFns } from "./EmbedTwitter.vue?vue&type=template&id=69be3c41&scoped=true"
import script from "./EmbedTwitter.vue?vue&type=script&lang=ts"
export * from "./EmbedTwitter.vue?vue&type=script&lang=ts"
import style0 from "./EmbedTwitter.vue?vue&type=style&index=0&id=69be3c41&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69be3c41",
  null
  
)

export default component.exports