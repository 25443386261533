import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { CONTRACT_STATUS_TRIAL } from '@/client/components-old/_constants/project'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import { TCountry, TGetCountriesResponse } from '@/client/utils/api/countries'
import {
  TGetPaymentContractMoneyParams,
  TGetPaymentContractMoneyResponse,
  TPaymentContractMoney,
  TPostPaymentContractResponse
} from '@/client/utils/api/payment_contracts'

type TPaymentForm = {
  project_name: string
  company_name: string
  depart_name: string
  person_name: string
  email: string
  telephone: string
  country_code: string
  zip_code: string
  address: string
}

const init_payment_money: TPaymentContractMoney = {
  tax_rate: 0,
  first_billing: {
    start_date: '',
    end_date: '',
    charge_init: 0,
    charge_monthly: 0,
    charge_tax: 0,
    charge_total: 0
  },
  after_billing: {
    charge_monthly: 0,
    charge_tax: 0,
    charge_total: 0
  }
}

const init_payment_form: TPaymentForm = {
  project_name: '',
  company_name: '',
  depart_name: '',
  person_name: '',
  email: '',
  telephone: '',
  country_code: 'JP',
  zip_code: '',
  address: ''
}

export interface IState {
  api_payment_money: TPaymentContractMoney
  api_countries: TCountry[]
  payment_group_id: number | null
  payment_form: TPaymentForm
  payment_step: number
  is_loading: boolean
  is_submit: boolean
}

const state: IState = {
  api_payment_money: init_payment_money,
  api_countries: [],
  payment_group_id: null,
  payment_form: init_payment_form,
  payment_step: 1,
  is_loading: true,
  is_submit: false
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  SET_API_PAYMENT_MONEY(state, payload) {
    state.api_payment_money = payload
  },
  SET_API_COUNTRIES(state, payload) {
    state.api_countries = payload
  },
  SET_PAYMENT_GROUP_ID(state, payload) {
    state.payment_group_id = payload
  },
  SET_PAYMENT_FORM(state, payload) {
    state.payment_form = payload
  },
  SET_PAYMENT_STEP(state, payload) {
    state.payment_step = payload
  },
  SET_IS_LOADING(state, payload) {
    state.is_loading = payload
  },
  SET_IS_SUBMIT(state, payload) {
    state.is_submit = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * ページ表示時の処理
   */
  async createdPaymentContract(context) {
    if (context.rootState.project.contract_status === CONTRACT_STATUS_TRIAL) {
      context.commit('SET_PAYMENT_GROUP_ID', context.rootState.project.id)
    }

    context.commit('SET_IS_LOADING', true)

    await Promise.all([
      context.dispatch('fetchPaymentContractMoney'),
      context.dispatch('fetchCountries')
    ])

    context.commit('SET_IS_LOADING', false)
  },

  /**
   * ページ離脱時の処理
   */
  async destroyedPaymentContract(context) {
    context.commit('SET_PAYMENT_GROUP_ID', null)
    context.commit('SET_PAYMENT_STEP', 1)
  },

  /**
   * ご契約手続きのフォームを戻る
   */
  async prevPaymentContract(context) {
    if (context.state.payment_step <= 1) return

    context.commit('SET_PAYMENT_STEP', context.state.payment_step - 1)
  },

  /**
   * ご契約手続きのフォームを進む
   */
  async nextPaymentContract(context) {
    if (context.state.payment_step >= 4) return

    context.commit('SET_PAYMENT_STEP', context.state.payment_step + 1)
  },

  /**
   * ご契約情報の変更
   */
  async changePaymentForm(context, payload: TPaymentForm) {
    context.commit('SET_PAYMENT_FORM', payload)
  },

  /**
   * ご契約手続きの登録
   */
  async createPaymentContract(context) {
    context.commit('SET_IS_SUBMIT', true)

    const payment_form = context.state.payment_form

    const params = {
      project_id: context.state.payment_group_id,
      ...payment_form
    }

    const response = await API.post<TPostPaymentContractResponse>('payment_contracts', params)

    context.commit('SET_IS_SUBMIT', false)

    return response.data
  },

  /**
   * お支払い金額の取得
   */
  async fetchPaymentContractMoney(context) {
    const params: TGetPaymentContractMoneyParams = {
      project_id: context.state.payment_group_id
    }

    const response = await API.get<TGetPaymentContractMoneyResponse>('payment_contracts/money', {
      params
    })

    if (response.data.data) {
      context.commit('SET_API_PAYMENT_MONEY', response.data.data)
    }
  },

  /**
   * 国の一覧を取得
   */
  async fetchCountries(context) {
    const response = await API.get<TGetCountriesResponse>('countries')

    if (response.data.data) {
      context.commit('SET_API_COUNTRIES', response.data.data)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
