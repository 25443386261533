import { render, staticRenderFns } from "./SnsAccountAbout.vue?vue&type=template&id=1ba2e7d5&scoped=true"
import script from "./SnsAccountAbout.vue?vue&type=script&lang=ts"
export * from "./SnsAccountAbout.vue?vue&type=script&lang=ts"
import style0 from "./SnsAccountAbout.vue?vue&type=style&index=0&id=1ba2e7d5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ba2e7d5",
  null
  
)

export default component.exports