
import { defineComponent, PropType } from 'vue'
import { mapActions, mapState } from 'vuex'

import HelpLink from '@/client/components/molecules/HelpLink'
import { TwitterEnterpriseAuthService } from '@/client/components-old/_utils/twitter_enterprise_authentication'
import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Account from '@/client/components-old/molecules/Account'
import { TFacebookAccount } from '@/client/utils/api/facebook_accounts'
import { TInstagramAccount } from '@/client/utils/api/instagram_accounts'
import { TTwitterAccount } from '@/client/utils/api/twitter_accounts'
import { getTranslateText as t } from '@/client/utils/filters'

export default defineComponent({
  name: 'GroupSettingMonitoringItem',
  components: {
    Button,
    ButtonLink,
    Account,
    Icon,
    Message,
    HelpLink
  },
  props: {
    account: {
      type: Object as PropType<TFacebookAccount & TInstagramAccount & TTwitterAccount>,
      required: true
    },
    snsType: {
      type: String as PropType<'facebook' | 'twitter' | 'instagram'>,
      required: true
    }
  },
  setup() {
    return {
      t
    }
  },
  computed: {
    ...mapState(['project'])
  },
  methods: {
    ...mapActions('notification', ['showNotification']),
    ...mapActions('accounts', ['updateTwitterEnterpriseVerified']),

    /**
     * NTT AAA認証をする
     */
    async authTwitterEnterpriseAccount() {
      const response = await TwitterEnterpriseAuthService.openAuthWindow(this.account.id)

      if (response.type === 'SUCCESS') {
        await this.updateTwitterEnterpriseVerified({ account_id: this.account.id })

        return this.showNotification({
          title: '認証が完了しました。',
          message: 'データの収集が始まります。',
          type: 'success'
        })
      }

      if (response.type === 'AUTHENTICATION_FAILED') {
        return this.showNotification({
          title: '認証に失敗しました。',
          message: '恐れ入りますが、時間をおいて再度お試しください。',
          type: 'error'
        })
      }

      if (response.type === 'INVALID_ACCOUNT') {
        return this.showNotification({
          title: 'アカウントが一致していません。',
          message: '認証したいアカウントにログインしてください。',
          type: 'error'
        })
      }

      return this.showNotification({
        title: '認証に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }
})
