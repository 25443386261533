import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import MultiSwitchesRow from '@/client/components-old/molecules/MultiSwitchesRow'
import SwitchRow from '@/client/components-old/molecules/SwitchRow'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  IGetNotificationSettingsResponse,
  INotificationSettings
} from '@/client/utils/api/notification_settings'

type NotificationSettingDialogOptions = {
  id: number
  name: string
  picture_url: string
  is_admin: boolean
}

const notification = namespace('notification')

@Component({
  name: 'NotificationSettingDialog',
  components: {
    Button,
    Dialog,
    Panel,
    Icon,
    Tooltip,
    Account,
    MultiSwitchesRow,
    SwitchRow
  }
})
export default class NotificationSettingDialog extends Vue {
  @State('project') project: IRootState['project']
  @notification.Action('showNotification') showNotification

  visible = false
  operator: NotificationSettingDialogOptions = null
  notification_setting: INotificationSettings | null = null

  /**
   * ダイアログを表示
   */
  async open(options: NotificationSettingDialogOptions): Promise<void> {
    this.visible = true
    this.operator = options
    await this.getNotificationSetting()
  }

  /**
   * ダイアログを非表示
   */
  close(): void {
    this.visible = false
  }

  get settings() {
    return {
      all: [
        {
          text: '自分宛に作業メモが追加されたとき',
          field: 'global_memo_to_me',
          value: this.notification_setting ? this.notification_setting.global_memo_to_me : false
        },
        {
          text: '作業メモが追加されたとき',
          field: 'global_memo',
          value: this.notification_setting ? this.notification_setting.global_memo : false
        },
        {
          text: '担当者に設定されたとき',
          field: 'global_person_in_charge',
          value: this.notification_setting
            ? this.notification_setting.global_person_in_charge
            : false
        },
        {
          text: 'SNSアカウントが追加されたとき',
          field: 'global_add_sns',
          value: this.notification_setting ? this.notification_setting.global_add_sns : false
        },
        {
          text: 'オペレーターを追加したとき',
          field: 'global_add_opperator',
          value: this.notification_setting ? this.notification_setting.global_add_opperator : false
        },
        {
          text: '請求金額が変更されたとき',
          field: 'global_payment',
          value: this.notification_setting ? this.notification_setting.global_payment : false
        }
      ],

      post: [
        {
          text: '公開されたとき',
          field: 'post_publish',
          value: this.notification_setting ? this.notification_setting.post_publish : false
        },
        {
          text: '予約されたとき',
          field: 'post_scheduled',
          value: this.notification_setting ? this.notification_setting.post_scheduled : false
        },
        {
          text: '下書き保存したとき',
          field: 'post_draft',
          value: this.notification_setting ? this.notification_setting.post_draft : false
        },
        {
          text: '公開日時24時間前の下書き投稿があるとき',
          field: 'post_draft_remind_24_hour',
          value: this.notification_setting
            ? this.notification_setting.post_draft_remind_24_hour
            : false
        },
        {
          text: '承認申請があったとき',
          field: 'post_approve_request',
          description: '承認フローのメンバーが通知対象となります。',
          value: this.notification_setting ? this.notification_setting.post_approve_request : false
        },
        {
          text: '承認されたとき',
          field: 'post_approved',
          description: '承認フローのメンバーが通知対象となります。',
          value: this.notification_setting ? this.notification_setting.post_approved : false
        },
        {
          text: '差し戻しされたとき',
          field: 'post_rejected',
          description: '承認申請者と承認フローのメンバーが通知対象となります。',
          value: this.notification_setting ? this.notification_setting.post_rejected : false
        },
        {
          text: '予約投稿に失敗したとき',
          field: 'post_publish_failed',
          value: this.notification_setting ? this.notification_setting.post_publish_failed : false
        }
      ],

      instagram_post: {
        text: 'Instagram投稿の手動公開時刻が近づいたとき',
        value: [
          {
            name: 'post_publish_remind_5_minute',
            label: '5分前',
            value: this.notification_setting
              ? this.notification_setting.post_publish_remind_5_minute
              : false
          },
          {
            name: 'post_publish_remind_15_minute',
            label: '15分前',
            value: this.notification_setting
              ? this.notification_setting.post_publish_remind_15_minute
              : false
          },
          {
            name: 'post_publish_remind_30_minute',
            label: '30分前',
            value: this.notification_setting
              ? this.notification_setting.post_publish_remind_30_minute
              : false
          }
        ]
      },

      analytics: [
        {
          text: '分析週次レポート(毎週火曜日配信)',
          field: 'analytics_report_weekly',
          value: this.notification_setting
            ? this.notification_setting.analytics_report_weekly
            : false
        },
        {
          text: '分析月次レポート(毎月5日配信)',
          field: 'analytics_report_monthly',
          value: this.notification_setting
            ? this.notification_setting.analytics_report_monthly
            : false
        },
        {
          text: 'クチコミ分析アラート',
          field: 'analytics_keyword_hashtag_alert_post_count',
          description:
            '[クチコミ分析] で登録したキーワードの収集数に関するアラートメールを送信します。',
          value: this.notification_setting
            ? this.notification_setting.analytics_keyword_hashtag_alert_post_count
            : false
        },
        {
          text: 'クチコミ推移アラート',
          field: 'analytics_twitter_viral_alert_post_count',
          description:
            '[クチコミ推移] で登録したキーワードの収集数に関するアラートメールを送信します。',
          value: this.notification_setting
            ? this.notification_setting.analytics_twitter_viral_alert_post_count
            : false
        }
      ],

      monitoring: [
        {
          text: '既読に変更したとき',
          field: 'monitoring_read',
          value: this.notification_setting ? this.notification_setting.monitoring_read : false
        },
        {
          text: '保留にしたとき',
          field: 'monitoring_pending',
          value: this.notification_setting ? this.notification_setting.monitoring_pending : false
        },
        {
          text: '非表示にしたとき',
          field: 'monitoring_hide',
          value: this.notification_setting ? this.notification_setting.monitoring_hide : false
        },
        {
          text: '非表示を解除したとき',
          field: 'monitoring_unhide',
          value: this.notification_setting ? this.notification_setting.monitoring_unhide : false
        },
        {
          text: '削除したとき',
          field: 'monitoring_deleted',
          value: this.notification_setting ? this.notification_setting.monitoring_deleted : false
        },
        {
          text: '新規コメントがあったとき',
          field: 'monitoring_comment_new',
          value: this.notification_setting
            ? this.notification_setting.monitoring_comment_new
            : false
        },
        {
          text: '新規メッセージがあったとき',
          field: 'monitoring_message_new',
          value: this.notification_setting
            ? this.notification_setting.monitoring_message_new
            : false
        },
        {
          text: '新規投稿があったとき',
          field: 'monitoring_post_new',
          value: this.notification_setting ? this.notification_setting.monitoring_post_new : false
        },
        {
          text: 'ポジティブワード検出時',
          field: 'monitoring_keyword_positive',
          value: this.notification_setting
            ? this.notification_setting.monitoring_keyword_positive
            : false
        },
        {
          text: 'ネガティブワード検出時',
          field: 'monitoring_keyword_negative',
          value: this.notification_setting
            ? this.notification_setting.monitoring_keyword_negative
            : false
        },
        {
          text: 'その他のワード検出時',
          field: 'monitoring_keyword_other',
          value: this.notification_setting
            ? this.notification_setting.monitoring_keyword_other
            : false
        },
        {
          text: '標準NGワード検出時',
          field: 'monitoring_keyword_global',
          value: this.notification_setting
            ? this.notification_setting.monitoring_keyword_global
            : false
        },
        {
          text: 'モニタリング日報',
          field: 'monitoring_report',
          description:
            'ステータスごとの集計やコメント履歴など、モニタリングの日次報告を配信します。',
          value: this.notification_setting ? this.notification_setting.monitoring_report : false
        }
      ]
    }
  }

  /**
   * 通知メール設定の取得
   */
  async getNotificationSetting(): Promise<void> {
    if (!this.operator || !this.operator.id) return

    const params = {
      project_id: this.project.id
    }

    const response = await API.get<IGetNotificationSettingsResponse>(
      `notification_settings/${this.operator.id}`,
      { params }
    )

    if (response.data.data) {
      this.notification_setting = response.data.data
    }
  }

  /**
   * 通知メール設定の切り替え
   */
  async changeSetting(field: string): Promise<void> {
    if (!this.operator || !this.operator.id) return

    this.notification_setting[field] = !this.notification_setting[field]

    const params = {
      project_id: this.project.id,
      [field]: this.notification_setting[field]
    }

    const response = await API.put<IGetNotificationSettingsResponse>(
      `notification_settings/${this.operator.id}`,
      params
    )

    if (response.data.data) {
      return this.showNotification({ title: '通知メール設定を更新しました。' })
    }

    this.showNotification({ title: '通知メール設定の更新に失敗しました。', type: 'error' })
  }

  /**
   * 指定された通知メール設定を全て切り替え
   */
  async changeAllSettings(type: string, value: boolean): Promise<void> {
    if (!this.operator || !this.operator.id) return

    const params: any = {
      project_id: this.project.id
    }

    this.settings[type].forEach(setting => {
      this.notification_setting[setting.field] = value

      params[setting.field] = value
    })

    if (type === 'post') {
      this.notification_setting.post_publish_remind_5_minute = value
      this.notification_setting.post_publish_remind_15_minute = value
      this.notification_setting.post_publish_remind_30_minute = value

      params.post_publish_remind_5_minute = value
      params.post_publish_remind_15_minute = value
      params.post_publish_remind_30_minute = value
    }

    const response = await API.put<IGetNotificationSettingsResponse>(
      `notification_settings/${this.operator.id}`,
      params
    )

    if (response.data.data) {
      return this.showNotification({ title: '通知メール設定を更新しました。' })
    }

    this.showNotification({ title: '通知メール設定の更新に失敗しました。', type: 'error' })
  }
}
