// コンポーネント種別
export const TYPES = [
  {
    value: 'total',
    text: '集計'
  },
  {
    value: 'chart',
    text: 'グラフ'
  },
  {
    value: 'post_ranking',
    text: '投稿ランキング'
  },
  {
    value: 'goal',
    text: '目標'
  },
  {
    value: 'table',
    text: 'テーブル'
  }
]

// データ種別
export const DATA_TYPES = [
  {
    value: 'daily_data',
    text: '日次データ',
    color: 'info'
  },
  {
    value: 'post_data',
    text: '投稿データ(公開日で集計)',
    color: 'success'
  }
]

// 間隔種別
export const INTERVALS = [
  { text: '1日', value: '1_day' },
  { text: '7日', value: '7_day' },
  { text: '14日', value: '14_day' },
  { text: '28日', value: '28_day' },
  { text: '1ヶ月', value: '1_month' },
  { text: '3ヶ月', value: '3_month' },
  { text: '6ヶ月', value: '6_month' },
  { text: '曜日', value: 'weekday' },
  { text: '時間帯', value: 'hour' }
]

// 集計種別
export const UNITS = [
  { text: '期間合計', value: 'total' },
  { text: '1投稿あたり', value: 'average' },
  { text: '主要', value: 'major' },
  { text: '過去1週間', value: 'past_week' }
]

// 順序種別
export const POST_RANKING_SORTS = [
  { value: 'ascending', text: 'WORST' },
  { value: 'descending', text: 'BEST' }
]

// 件数種別
export const POST_RANKING_LIMITS = [
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 }
]

// 日次データ指標オプション
export const DAILY_DATA_METRICS = [
  { text: 'ファン数(累計)', value: 'page_fans', tooltip: '' },
  { text: 'ファン数(増減)', value: 'page_fan_up_down', tooltip: '' },
  {
    text: '全投稿リーチ',
    value: 'page_posts_impressions_unique',
    tooltip:
      'オーガニック・クチコミ・広告でそれぞれ獲得した投稿リーチ数の合計です。同一人物が各項目で重複カウントされている場合があります。'
  },
  {
    text: '全投稿リーチ(オーガニック)',
    value: 'page_posts_impressions_organic_unique',
    tooltip: ''
  },
  { text: '全投稿リーチ(クチコミ)', value: 'page_posts_impressions_viral_unique', tooltip: '' },
  { text: '全投稿リーチ(広告)', value: 'page_posts_impressions_paid_unique', tooltip: '' },
  { text: '全投稿インプレッション', value: 'page_posts_impressions', tooltip: '' },
  {
    text: '全投稿インプレッション(オーガニック)',
    value: 'page_posts_impressions_organic',
    tooltip: ''
  },
  { text: '全投稿インプレッション(クチコミ)', value: 'page_posts_impressions_viral', tooltip: '' },
  { text: '全投稿インプレッション(広告)', value: 'page_posts_impressions_paid', tooltip: '' },
  {
    text: '全体リーチ',
    value: 'page_impressions_unique',
    tooltip:
      'オーガニック・クチコミ・広告でFacebookページのコンテンツを見た人の数の合計です。同一人物が各項目で重複カウントされている場合があります。'
  },
  { text: '全体リーチ(オーガニック)', value: 'page_impressions_organic_unique', tooltip: '' },
  { text: '全体リーチ(クチコミ)', value: 'page_impressions_viral_unique', tooltip: '' },
  { text: '全体リーチ(広告)', value: 'page_impressions_paid_unique', tooltip: '' },
  { text: '全体インプレッション', value: 'page_impressions', tooltip: '' },
  { text: '全体インプレッション(オーガニック)', value: 'page_impressions_organic', tooltip: '' },
  { text: '全体インプレッション(クチコミ)', value: 'page_impressions_viral', tooltip: '' },
  { text: '全体インプレッション(広告)', value: 'page_impressions_paid', tooltip: '' },
  { text: '反応数', value: 'page_positive_feedback_by_type_unique_reaction', tooltip: '' },
  { text: 'いいね！', value: 'page_positive_feedback_by_type_unique_like', tooltip: '' },
  { text: 'コメント', value: 'page_positive_feedback_by_type_unique_comment', tooltip: '' },
  { text: 'シェア', value: 'page_positive_feedback_by_type_unique_share', tooltip: '' },
  {
    text: 'エンゲージメント数',
    value: 'page_engaged_users',
    tooltip: '投稿へのリアクションやコメント、シェアなどのアクションの合計数です。'
  },
  { text: '否定的な反応', value: 'page_negative_feedback_by_type_unique', tooltip: '' },
  { text: '外部リンクからの流入', value: 'page_views_external_referrals', tooltip: '' },
  { text: '動画3秒再生数', value: 'page_video_views', tooltip: '' },
  { text: '動画3秒再生数(オーガニック)', value: 'page_video_views_organic', tooltip: '' },
  { text: '動画3秒再生数(広告)', value: 'page_video_views_paid', tooltip: '' },
  {
    text: '動画3秒再生人数',
    value: 'page_video_views_unique',
    tooltip:
      'オーガニック・広告で投稿の動画を3秒以上再生した人数の合計です。同一人物が各項目で重複カウントされている場合があります。'
  },
  { text: '動画10秒再生数', value: 'page_video_views_10s', tooltip: '' },
  {
    text: '動画10秒再生人数',
    value: 'page_video_views_10s_unique',
    tooltip:
      'オーガニック・広告で投稿の動画を10秒以上再生した人数の合計です。同一人物が各項目で重複カウントされている場合があります。'
  },
  { text: '動画30秒再生数', value: 'page_video_complete_views_30s', tooltip: '' },
  {
    text: '動画30秒再生人数',
    value: 'page_video_complete_views_30s_unique',
    tooltip:
      'オーガニック・広告で投稿の動画を30秒以上再生した人数の合計です。同一人物が各項目で重複カウントされている場合があります。'
  },
  { text: '動画自動再生数', value: 'page_video_views_autoplayed', tooltip: '' },
  { text: '動画クリック再生数', value: 'page_video_views_click_to_play', tooltip: '' },
  { text: '視聴秒数', value: 'page_video_view_time', tooltip: '' },
  { text: '投稿数', value: 'post_count', tooltip: '' }
]

// 集計用のファンデータ指標オプション
const FAN_TOTAL_DATA_METRICS = [
  {
    text: '主な性別・年代',
    value: 'page_fans_gender_age',
    tooltip:
      '2024年3月以降のデータは取得されません。それ以前のデータは2024年9月以降に閲覧できなくなります。'
  },
  { text: '主な国', value: 'page_fans_country', tooltip: '' },
  { text: '主な都道府県', value: 'page_fans_city', tooltip: '' },
  {
    text: 'アクティブな時間帯',
    value: 'page_fans_online',
    tooltip: ''
  },
  {
    text: 'アクティブな曜日',
    value: 'page_fans_online_per_day',
    tooltip: ''
  }
]

// グラフ用のファンデータ指標オプション
const FAN_CHART_DATA_METRICS = [
  {
    text: 'オンラインの時間帯',
    value: 'page_fans_online',
    tooltip:
      '対象期間の最終日から過去1週間でFacebookにログインしたファンの合計値を1時間ごとに分類した値。この指標は、最大5日間のデータが存在しない場合があります。データが存在しない場合は、対象期間を広げてご確認ください。'
  },
  {
    text: 'オンラインの曜日',
    value: 'page_fans_online_per_day',
    tooltip:
      '対象期間の最終日から過去1週間でFacebookにログインしたファンの合計値を曜日ごとに分類した値。この指標は、最大5日間のデータが存在しない場合があります。データが存在しない場合は、対象期間を広げてご確認ください。'
  }
]

// 投稿データ指標オプション
export const POST_DATA_METRICS = [
  {
    text: 'リーチ',
    value: 'post_impressions_unique',
    tooltip:
      'オーガニック・クチコミ・広告でそれぞれ獲得したリーチ数の合計です。同一人物が各項目で重複カウントされている場合があります。'
  },
  { text: 'リーチ(オーガニック)', value: 'post_impressions_organic_unique', tooltip: '' },
  { text: 'リーチ(クチコミ)', value: 'post_impressions_viral_unique', tooltip: '' },
  { text: 'リーチ(広告)', value: 'post_impressions_paid_unique', tooltip: '' },
  { text: 'インプレッション', value: 'post_impressions', tooltip: '' },
  { text: 'インプレッション(オーガニック)', value: 'post_impressions_organic', tooltip: '' },
  { text: 'インプレッション(クチコミ)', value: 'post_impressions_viral', tooltip: '' },
  { text: 'インプレッション(広告)', value: 'post_impressions_paid', tooltip: '' },
  {
    text: '反応数',
    value: 'reactions',
    tooltip: 'いいね・コメント・シェアの合計数です。'
  },
  { text: '反応数(自社投稿)', value: 'reactions_feed', tooltip: '' },
  { text: '反応数(クチコミ先)', value: 'reactions_viral', tooltip: '' },
  {
    text: '反応率',
    value: 'reactions_rate',
    tooltip: '反応数 ÷ 公開日のファン数 です。'
  },
  { text: 'いいね！', value: 'likes_insights', tooltip: '' },
  { text: 'コメント', value: 'comments_insights', tooltip: '' },
  { text: 'シェア', value: 'shares_insights', tooltip: '' },
  {
    text: 'エンゲージメント数',
    value: 'post_engaged_users',
    tooltip: '投稿にリアクションやコメント、シェアなどのアクションをした人数です。'
  },
  {
    text: 'エンゲージメント率',
    value: 'post_engaged_users_rate',
    tooltip: 'エンゲージメント数 ÷ リーチです。'
  },
  {
    text: 'クリック人数',
    value: 'post_consumptions_unique',
    tooltip:
      '投稿の写真・動画・リンクなどをクリックした人数の合計です。同一人物が各項目で重複カウントされている場合があります。'
  },
  {
    text: 'クリック率',
    value: 'post_consumptions_unique_rate',
    tooltip: 'クリック人数 ÷ リーチです。'
  },
  { text: 'ファンのリーチ数', value: 'post_impressions_fan_unique', tooltip: '' },
  { text: 'ファンのリーチ率', value: 'post_impressions_fan_unique_rate', tooltip: '' },
  {
    text: 'ファンのエンゲージメント数',
    value: 'post_engaged_fan',
    tooltip: 'ページに「いいね！」をして、投稿にアクションをした人数です。'
  },
  { text: 'ファンのエンゲージメント率', value: 'post_engaged_fan_rate', tooltip: '' },
  { text: '否定的な反応', value: 'post_negative_feedback_by_type_unique', tooltip: '' },
  { text: '動画3秒再生数', value: 'post_video_views', tooltip: '' },
  { text: '動画3秒再生数(オーガニック)', value: 'post_video_views_organic', tooltip: '' },
  { text: '動画3秒再生数(広告)', value: 'post_video_views_paid', tooltip: '' },
  {
    text: '動画3秒再生人数',
    value: 'post_video_views_unique',
    tooltip:
      'オーガニック・広告で投稿の動画を3秒以上再生した人数の合計です。同一人物が各項目で重複カウントされている場合があります。'
  },
  { text: '動画3秒再生人数(オーガニック)', value: 'post_video_views_organic_unique', tooltip: '' },
  { text: '動画3秒再生人数(広告)', value: 'post_video_views_paid_unique', tooltip: '' },
  { text: '動画再生完了数', value: 'post_video_complete_views', tooltip: '' },
  { text: '動画再生完了人数', value: 'post_video_complete_views_unique', tooltip: '' },
  {
    text: '動画再生完了人数(オーガニック)',
    value: 'post_video_complete_views_organic_unique',
    tooltip: ''
  },
  { text: '動画再生完了人数(広告)', value: 'post_video_complete_views_paid_unique', tooltip: '' },

  { text: '動画自動再生数', value: 'post_video_views_autoplayed', tooltip: '' },
  { text: '動画クリック再生数', value: 'post_video_views_clicked_to_play', tooltip: '' },
  { text: '平均視聴秒数', value: 'post_video_avg_time_watched', tooltip: '' },
  { text: '短縮URLクリック数', value: 'short_url_clicks', tooltip: '' }
]

// 投稿ランキング指標オプション
export const POST_RANKING_MAIN_METRICS = [
  ...POST_DATA_METRICS,
  { text: '公開日時', value: 'created_time', tooltip: '' },
  { text: '公開日のファン数', value: 'page_fans_at_that_time', tooltip: '' }
]

export const POST_RANKING_SUB_METRICS = [...POST_RANKING_MAIN_METRICS]

const VIDEO_TOTAL_POST_DATA_METRICS = [
  {
    text: '主な性別・年代(視聴秒数)',
    value: 'post_video_view_time_by_age_bucket_and_gender',
    tooltip: ''
  }
]

// 累計の日次データ指標オプション
export const TOTAL_DAILY_DATA_METRICS = [...DAILY_DATA_METRICS, ...FAN_TOTAL_DATA_METRICS]

// 累計の投稿データ指標オプション
export const TOTAL_POST_DATA_METRICS = [...POST_DATA_METRICS, ...VIDEO_TOTAL_POST_DATA_METRICS]

// 目標の日次データ指標オプション
export const GOAL_DAILY_DATA_METRICS = DAILY_DATA_METRICS

// 目標の投稿データ指標オプション
export const GOAL_POST_DATA_METRICS = POST_DATA_METRICS

// 表の日次データ指標オプション
export const TABLE_DAILY_DATA_METRICS = [...DAILY_DATA_METRICS]

// 表の投稿データ指標オプション
export const TABLE_POST_DATA_METRICS = [...POST_DATA_METRICS]

// 垂直チャートの日次データ指標オプション
export const CHANGE_CHART_DAILY_DATA_METRICS = [...DAILY_DATA_METRICS, ...FAN_CHART_DATA_METRICS]

// 垂直チャートの投稿データ指標オプション
export const CHANGE_CHART_POST_DATA_METRICS = POST_DATA_METRICS

// 水平チャートの日次データ指標オプション
export const HORIZONTAL_CHART_DAILY_DATA_METRICS = [
  {
    text: 'ページへのいいね！の発生箇所',
    value: 'page_fans_by_like_source_unique',
    tooltip:
      '2024年3月以降のデータは取得されません。それ以前のデータは2024年9月以降に閲覧できなくなります。'
  },
  {
    text: '性別・年代',
    value: 'page_fans_gender_age',
    tooltip:
      '対象期間の最終日時点のファンの性別、年代別の内訳です。2024年3月以降のデータは取得されません。それ以前のデータは2024年9月以降に閲覧できなくなります。'
  },
  {
    text: 'ファン数(場所別ベスト10)',
    value: 'page_fans_city',
    tooltip:
      '対象期間の最終日時点のファンの場所別の内訳。この指標は、最大5日間のデータが存在しない場合があります。データが存在しない場合は、対象期間を広げてご確認ください。'
  },
  {
    text: 'リーチの種類(全投稿・全体)',
    value: 'page_reach_type',
    tooltip: '投稿リーチと全体リーチの種類別内訳'
  },
  {
    text: 'インプレッションの種類(全投稿・全体)',
    value: 'page_impression_type',
    tooltip: '投稿インプレッションと全体インプレッションの種類別内訳'
  },
  {
    text: '全体リーチ(性別・年代)',
    value: 'page_impressions_by_age_gender_unique',
    tooltip:
      '投稿、他のユーザーの投稿、ページヘの「いいね！」広告、言及、チェックインなど、ページに関連する何らかのコンテンツを見た人の性別・年代別の合計数。この指標は、最大5日間のデータが存在しない場合があります。データが存在しない場合は、対象期間を広げてご確認ください。'
  },
  {
    text: '全体リーチ(場所別ベスト10)',
    value: 'page_impressions_by_city_unique',
    tooltip:
      '投稿、他のユーザーの投稿、ページヘの「いいね！」広告、言及、チェックインなど、ページに関連する何らかのコンテンツを見た人の場所別の合計数'
  },
  {
    text: 'ページとタブのアクセス',
    value: 'page_tab_views_login_top_unique',
    tooltip: 'ページの各タブが表示された合計数'
  },
  {
    text: '外部リンクからの流入',
    value: 'page_views_external_referrals',
    tooltip:
      '2024年3月以降のデータは取得されません。それ以前のデータは2024年9月以降に閲覧できなくなります。'
  },
  {
    text: 'オーガニック再生数と広告再生数',
    value: 'page_video_views_organic_vs_paid',
    tooltip: ''
  },
  {
    text: '自動再生数とクリック再生数',
    value: 'page_video_views_autoplayed_vs_click_to_play',
    tooltip: ''
  },
  {
    text: '視聴秒数ごとの再生数',
    value: 'page_video_views_by_time',
    tooltip: ''
  },
  {
    text: '視聴秒数ごとの再生人数',
    value: 'page_video_views_unique_by_time',
    tooltip: ''
  },
  {
    text: '投稿リーチ(クチコミ)の発生箇所',
    value: 'page_impressions_by_story_type_unique',
    tooltip: ''
  }
]

// 水平チャートの投稿データ指標オプション
export const HORIZONTAL_CHART_POST_DATA_METRICS = [
  {
    text: '反応数(場所別)',
    value: 'post_reaction_place',
    tooltip: 'いいね！数、コメント数、シェア数の反応場所ごとの合計数'
  },
  {
    text: 'オーガニック再生数と広告再生数',
    value: 'post_video_views_organic_vs_paid',
    tooltip: ''
  },
  {
    text: '自動再生数とクリック再生数',
    value: 'post_video_views_autoplayed_vs_click_to_play',
    tooltip: ''
  },
  {
    text: '視聴秒数(性別・年代)',
    value: 'post_video_view_time_by_age_bucket_and_gender',
    tooltip: ''
  },
  {
    text: 'リーチの種類(ファン・ファン以外)',
    value: 'post_impressions_fan_unique_type',
    tooltip: ''
  }
]

export const MAP_CHART_DAILY_DATA_METRICS = [
  {
    text: 'ファン数(国)',
    value: 'page_fans_country',
    tooltip:
      '対象期間の最終日時点のファンの国別の内訳。この指標は、最大5日間のデータが存在しない場合があります。データが存在しない場合は、対象期間を広げてご確認ください。'
  },
  {
    text: 'ファン数(都道府県)',
    value: 'page_fans_city',
    tooltip:
      '対象期間最終日時点での、ファン数の都道府県別内訳(Facebookの仕様上、ファン数の少ない都道府県の表示は省略されます。)。この指標は、最大5日間のデータが存在しない場合があります。データが存在しない場合は、対象期間を広げてご確認ください。'
  },
  {
    text: '全体リーチ(国)',
    value: 'page_impressions_by_country_unique',
    tooltip:
      '投稿、他のユーザーの投稿、ページヘの「いいね！」広告、言及、チェックインなど、ページに関連する何らかのコンテンツを見た人の国別の合計数'
  },
  {
    text: '全体リーチ(都道府県)',
    value: 'page_impressions_by_city_unique',
    tooltip:
      '対象期間最終日時点での、全体リーチ数の都道府県別内訳(Facebookの仕様上、リーチ数の少ない都道府県の表示は省略されます。)。この指標は、最大5日間のデータが存在しない場合があります。データが存在しない場合は、対象期間を広げてご確認ください。'
  }
]

export const HALF_DOUGHNUT_CHART_DAILY_DATA_METRICS = [
  {
    text: '反応数の内訳',
    value: 'page_positive_feedback_by_type_unique',
    tooltip:
      '投稿へのいいね・コメント・シェアの合計数です。2024年3月以降のデータは取得されません。それ以前のデータは2024年9月以降に閲覧できなくなります。'
  },
  {
    text: '否定的な反応の内訳',
    value: 'page_negative_feedback_by_type_unique',
    tooltip: '投稿を非表示、全投稿を非表示、いいね！取消、スパムとして報告の種別ごとの合計数'
  }
]

export const HALF_DOUGHNUT_CHART_POST_DATA_METRICS = [
  {
    text: 'クリック人数の内訳',
    value: 'post_consumptions_by_type_unique',
    tooltip: '投稿クリックの種別ごとの合計数'
  },
  {
    text: 'いいね！数の内訳',
    value: 'likes_insights',
    tooltip: '投稿のリアクションの種別ごとの合計数'
  }
]

export const TABLE_COLUMN_METRICS = [
  { text: 'リーチ', value: 'post_impressions_unique' },
  { text: 'リーチ(オーガニック)', value: 'post_impressions_organic_unique' },
  { text: 'リーチ(クチコミ)', value: 'post_impressions_viral_unique' },
  { text: 'リーチ(広告)', value: 'post_impressions_paid_unique' },
  { text: 'インプレッション', value: 'post_impressions' },
  { text: 'インプレッション(オーガニック)', value: 'post_impressions_organic' },
  { text: 'インプレッション(クチコミ)', value: 'post_impressions_viral' },
  { text: 'インプレッション(広告)', value: 'post_impressions_paid' },
  { text: '反応数', value: 'reactions' },
  { text: '反応数(自社投稿)', value: 'reactions_feed' },
  { text: '反応数(クチコミ先)', value: 'reactions_viral' },
  { text: '反応率', value: 'reactions_rate' },
  { text: 'いいね！', value: 'likes_insights' },
  { text: 'コメント', value: 'comments_insights' },
  { text: 'シェア', value: 'shares_insights' },
  { text: 'エンゲージメント数', value: 'post_engaged_users' },
  { text: 'エンゲージメント率', value: 'post_engaged_users_rate' },
  { text: 'クリック数', value: 'post_clicks' },
  { text: 'クリック数（写真）', value: 'post_clicks_photo_view' },
  { text: 'クリック数（リンク）', value: 'post_clicks_link_clicks' },
  { text: 'クリック数（動画再生）', value: 'post_clicks_video_play' },
  { text: 'クリック数（その他）', value: 'post_clicks_other_clicks' },
  { text: 'クリック人数', value: 'post_consumptions_unique' },
  { text: 'クリック人数（写真）', value: 'post_consumptions_unique_photo_view' },
  { text: 'クリック人数（リンク）', value: 'post_consumptions_unique_link_clicks' },
  { text: 'クリック人数（動画再生）', value: 'post_consumptions_unique_video_play' },
  { text: 'クリック人数（その他）', value: 'post_consumptions_unique_other_clicks' },
  { text: 'クリック率', value: 'post_consumptions_unique_rate' },
  { text: 'ファンのリーチ数', value: 'post_impressions_fan_unique' },
  { text: 'ファンのリーチ率', value: 'post_impressions_fan_unique_rate' },
  { text: 'ファン以外のリーチ数', value: 'post_impressions_fan_other_unique' },
  { text: 'ファンのエンゲージメント数', value: 'post_engaged_fan' },
  { text: 'ファンのエンゲージメント率', value: 'post_engaged_fan_rate' },
  { text: '否定的な反応', value: 'post_negative_feedback_by_type_unique' },
  { text: '動画3秒再生数', value: 'post_video_views' },
  { text: '動画3秒再生数(オーガニック)', value: 'post_video_views_organic' },
  { text: '動画3秒再生数(広告)', value: 'post_video_views_paid' },
  { text: '動画3秒再生人数', value: 'post_video_views_unique' },
  { text: '動画3秒再生人数(オーガニック)', value: 'post_video_views_organic_unique' },
  { text: '動画3秒再生人数(広告)', value: 'post_video_views_paid_unique' },
  { text: '動画再生完了数', value: 'post_video_complete_views' },
  { text: '動画再生完了数(オーガニック)', value: 'post_video_complete_views_organic' },
  { text: '動画再生完了数(広告)', value: 'post_video_complete_views_paid' },
  { text: '動画再生完了人数', value: 'post_video_complete_views_unique' },
  { text: '動画再生完了人数(オーガニック)', value: 'post_video_complete_views_organic_unique' },
  { text: '動画再生完了人数(広告)', value: 'post_video_complete_views_paid_unique' },
  { text: '動画自動再生数', value: 'post_video_views_autoplayed' },
  { text: '動画クリック再生数', value: 'post_video_views_clicked_to_play' },
  { text: '平均視聴秒数', value: 'post_video_avg_time_watched' },
  { text: '短縮URLクリック数', value: 'short_url_clicks' },
  { text: '公開日のファン数', value: 'page_fans_at_that_time' }
]

export const POST_TABLE_METRICS = [
  { text: '公開日時', value: 'created_time' },
  { text: '投稿内容', value: 'message' },
  { text: '投稿タイプ', value: 'type' },
  { text: '投稿ID', value: 'post_id' },
  { text: '投稿URL', value: 'post_url' },
  { text: 'サムネイルURL', value: 'picture' },
  { text: '動画URL', value: 'video_url' },
  { text: 'リンクタイトル', value: 'link_name' },
  { text: 'リンク説明文', value: 'link_description' },
  { text: 'リンクドメイン', value: 'link_caption' },
  { text: 'リンクURL', value: 'link_link' },
  { text: 'タグ', value: 'category_name' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_TYPE_METRICS = [
  { text: '投稿タイプ', value: 'type' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_HASHTAG_METRICS = [
  { text: 'ハッシュタグ', value: 'hashtag' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_CATEGORY_METRICS = [
  { text: 'タグ', value: 'category' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_CHARACTER_COUNT_METRICS = [
  { text: '文字数レンジ', value: 'character_range' },
  { text: '投稿数', value: 'post_count' },
  ...TABLE_COLUMN_METRICS
]

export const POST_TABLE_TOTAL = [
  { text: '公開された投稿', value: 'none' },
  { text: '投稿タイプ', value: 'type' },
  { text: 'ハッシュタグ', value: 'hashtag' },
  { text: 'タグ', value: 'category' },
  { text: '文字数', value: 'character_count' }
]

export const POST_TABLE_TABS = [
  { text: '平均', value: 'average' },
  { text: '合計', value: 'total' }
]

export const POST_TABLE_SORT_BLACKLIST = [
  'account_id',
  'post_id',
  'object_id',
  'message',
  'created_time',
  'type',
  'tags',
  'picture',
  'attachments_url',
  'post_video_view_time_by_age_bucket_and_gender',
  'use_short_url'
]

export const POST_TYPE = [
  { text: 'テキスト', value: 'status' },
  { text: '画像', value: 'photo' },
  { text: 'リンク', value: 'link' },
  { text: '動画', value: 'video' },
  { text: 'シェア', value: 'shared' }
]

export const CHARACTER_RANGE = [
  {
    title: '10文字未満',
    greater_than: 0,
    less_than: 10
  },
  {
    title: '10文字以上 - 50文字未満',
    greater_than: 10,
    less_than: 50
  },
  {
    title: '50文字以上 - 100文字未満',
    greater_than: 50,
    less_than: 100
  },
  {
    title: '100文字以上 - 200文字未満',
    greater_than: 100,
    less_than: 200
  },
  {
    title: '200文字以上 - 500文字未満',
    greater_than: 200,
    less_than: 500
  },
  {
    title: '500文字以上 - 1,000文字未満',
    greater_than: 500,
    less_than: 1000
  },
  {
    title: '1,000文字以上',
    greater_than: 1000,
    less_than: null
  }
]
