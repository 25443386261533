import { RouteConfig } from 'vue-router'

import PasswordRequest from '../pages/PasswordRequest.vue'

export const password_request: RouteConfig = {
  component: PasswordRequest,
  path: '/password_request',
  name: 'password_request',
  meta: {
    auth: false,
    title: 'パスワードリセットの申請'
  }
}
