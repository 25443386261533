import * as calculation from '@/client/components-old/_utils/calculation'

import { TikTokInsightsPosts } from '../api'

export function getAggregateAudienceCountriesByPost(posts: TikTokInsightsPosts[]) {
  const audience_countries = posts
    .flatMap(v => v.audience_countries)
    .map<[string, number]>(v => [v.country, v.percentage])
    .reduce<Record<string, number>>((p, [k, v]) => ({ ...p, [k]: p[k] ? p[k] + v : v }), {})

  // ? 国情報が存在しないと率がズレるため件数を抑える
  const filter_post = posts.filter(v => v.audience_countries.length)
  const video_views = filter_post.flatMap(v => v.video_views).reduce((p, c) => p + c, 0)

  // ? 集計した率を元にして実数を割り出す
  const aggregate_countries = Object.entries(audience_countries).map(([k, v]) => {
    const percentage = calculation.division(v, filter_post.length, 4)
    const count = Math.round(video_views * percentage)

    return { country: k, percentage, count }
  })

  // ? 実数が多い順にする
  aggregate_countries.sort((a, b) => b.count - a.count)

  return aggregate_countries
}
