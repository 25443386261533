import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as IPaymentContractState } from '@/client/store/modules/payment_contract'
import { TPostPaymentContractResponse } from '@/client/utils/api/payment_contracts'

const payment_contract = namespace('payment_contract')

@Component({
  name: 'PaymentContractConfirm',
  components: {
    Button,
    Icon,
    Message,
    Panel
  }
})
export default class PaymentContractConfirm extends Vue {
  @State('user') user: IRootState['user']
  @State('projects') projects: IRootState['projects']
  @payment_contract.State('api_countries') api_countries: IPaymentContractState['api_countries']
  @payment_contract.State('payment_form') payment_form: IPaymentContractState['payment_form']
  @payment_contract.State('payment_group_id')
  payment_group_id: IPaymentContractState['payment_group_id']
  @payment_contract.Action('createPaymentContract') createPaymentContract
  @payment_contract.Action('prevPaymentContract') prevPaymentContract
  @payment_contract.Action('nextPaymentContract') nextPaymentContract

  get group_name(): string {
    if (this.payment_group_id) {
      const project = this.projects.find(project => project.id === this.payment_group_id)

      return project ? project.project_name : ''
    }

    return this.payment_form.project_name
  }

  get country_name(): string {
    const country = this.api_countries.find(
      country => country.code === this.payment_form.country_code
    )

    if (!country) return ''

    return this.user.language === 'ja' ? country.name : country.name_en
  }

  @Emit('change-project')
  changeProject(payload: any) {
    return payload
  }

  /**
   * ご契約手続の登録
   */
  async createPayment() {
    const result: TPostPaymentContractResponse = await this.createPaymentContract()

    if (result.data) {
      this.changeProject({ project_id: result.data.project_id })

      TrackingService.sendEvent('Payment')

      await this.nextPaymentContract()
    }
  }
}
