
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Icon from '@/client/components-old/atoms/Icon'

type TTabsOption = {
  value: string
  text: string
  icon?: string
  disabled?: boolean
}

@Component({
  name: 'Tabs',
  components: {
    Icon
  }
})
export default class Tabs extends Vue {
  @Prop({ type: String, required: true })
  value!: string

  @Prop({ type: Array, required: true })
  options!: TTabsOption[]

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: Boolean, default: false })
  isMobileNotText: boolean

  @Emit('input')
  onChange(val: string) {
    return val
  }

  get is_mobile_not_text() {
    return this.$mq === 'sm' && this.isMobileNotText
  }

  /**
   * 現在表示しているタブかチェック
   */
  isActive(option: TTabsOption): boolean {
    return this.value === option.value
  }
}
