import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import HelpLink from '@/client/components/molecules/HelpLink'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Account from '@/client/components-old/molecules/Account'
import OperatorRoleSns from '@/client/components-old/molecules/OperatorRoleSns'
import SwitchRow from '@/client/components-old/molecules/SwitchRow'
import { IRootState } from '@/client/store/global'
import { IState as IGroupSettingState } from '@/client/store/modules/group_setting'
import { UserRole } from '@/client/utils/api/users'
import i18n from '@/client/utils/i18n'
import { SnsType } from '@/common/types'

const accounts = namespace('accounts')
const group_setting = namespace('group_setting')
const notification = namespace('notification')

type TOperatorRoleSettingDialogOptions = {
  id: number
  name: string
  picture_url: string
  is_admin: boolean
}

@Component({
  name: 'OperatorRoleSettingDialog',
  components: {
    Dialog,
    HelpLink,
    Icon,
    Message,
    Panel,
    Account,
    OperatorRoleSns,
    SwitchRow
  }
})
export default class OperatorRoleSettingDialog extends Vue {
  @State('user') user!: IRootState['user']
  @State('project') project!: IRootState['project']
  @group_setting.State('api_operators') api_operators!: IGroupSettingState['api_operators']
  @accounts.Action('fetchFacebookAccounts') fetchFacebookAccounts
  @accounts.Action('fetchTwitterAccounts') fetchTwitterAccounts
  @accounts.Action('fetchInstagramAccounts') fetchInstagramAccounts
  @accounts.Action('fetchTikTokAccounts') fetchTikTokAccounts
  @group_setting.Action('fetchOperatorRoles') fetchOperatorRoles
  @group_setting.Action('updateOperatorGroupRoles') updateOperatorGroupRoles
  @group_setting.Action('updateOperatorSnsRoles') updateOperatorSnsRoles
  @notification.Action('showNotification') showNotification

  visible = false
  operator: UserRole | null = null

  get role() {
    return this.operator ? this.operator.role : null
  }

  get accounts() {
    if (!this.operator) return []

    return [
      ...this.operator.fb_accounts.map(account => ({ account, sns_type: 'facebook' })),
      ...this.operator.tw_accounts.map(account => ({ account, sns_type: 'twitter' })),
      ...this.operator.in_accounts.map(account => ({ account, sns_type: 'instagram' })),
      ...this.operator.tt_accounts.map(account => ({ account, sns_type: 'tiktok' }))
    ]
  }

  get is_disabled_role_admin() {
    return this.api_operators.filter(v => v.role.role_admin).length <= 1 && this.role.role_admin
  }

  get is_use_tw_viral() {
    return this.project.is_use_analytics && this.project.is_use_tw_viral
  }

  /**
   * ダイアログの表示
   */
  async open(options: TOperatorRoleSettingDialogOptions): Promise<void> {
    this.visible = true

    const result = await this.fetchOperatorRoles({ user_id: options.id })

    if (result.data) {
      this.operator = result.data
    } else {
      this.operator = null
    }
  }

  /**
   * ダイアログの非表示
   */
  close(): void {
    this.visible = false
  }

  /**
   * オペレーターのグループ権限設定の変更
   */
  async updateGroupRoles(role_type: string, is_role: boolean): Promise<void> {
    if (!is_role && role_type === 'role_admin' && this.user.id === this.operator.user.id) {
      let message = ''

      message +=
        i18n.t('グループ管理者をOFFにすると、[グループ設定] の閲覧ができなくなります。') + '\n'
      message += i18n.t('グループ管理者をOFFにしてよろしいですか？')

      if (!window.confirm(message)) return
    }

    const payload = {
      user_id: this.operator.user.id,
      ...this.role
    }

    payload[role_type] = is_role

    const result = await this.updateOperatorGroupRoles(payload)

    if (result.data) {
      if (this.user.id === payload.user_id) {
        this.changeVue(payload)
      }

      const result = await this.fetchOperatorRoles({ user_id: payload.user_id })

      if (result.data) {
        this.operator = result.data
      } else {
        this.operator = null
      }

      return this.showNotification({ title: 'グループの権限設定を変更しました。' })
    }

    if (result.error && result.error.type === 'NO_PROJECT_ADMIN') {
      this.operator.role.role_admin = true

      return this.showNotification({
        title: '管理者が1人しかいないので、グループ管理者を変更できません。',
        type: 'error'
      })
    }

    this.showNotification({
      title: 'グループの権限設定の変更に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * オペレーターのSNSアカウント権限設定の変更
   */
  async updateSnsRoles(
    sns_type: SnsType,
    payload: {
      account_id: string
      role_post: string
      role_analytics: string
      role_monitoring: string
    }
  ): Promise<void> {
    const result = await this.updateOperatorSnsRoles({
      sns_type,
      user_id: this.operator.user.id,
      ...payload
    })

    if (result.data) {
      if (this.operator.user.id === this.user.id) {
        switch (sns_type) {
          case 'facebook':
            await this.fetchFacebookAccounts()
            break
          case 'twitter':
            await this.fetchTwitterAccounts()
            break
          case 'instagram':
            await this.fetchInstagramAccounts()
            break
          case 'tiktok':
            await this.fetchTikTokAccounts()
            break
        }
      }

      return this.showNotification({ title: 'オペレーターのSNSアカウント権限設定を変更しました。' })
    }

    this.showNotification({
      title: 'オペレーターのSNSアカウント権限設定の変更に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  @Emit('change-vue')
  changeVue(payload: any) {
    return payload
  }
}
