import moment from 'moment-timezone'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import { IState as IPaymentContractState } from '@/client/store/modules/payment_contract'

const payment_contract = namespace('payment_contract')

@Component({
  name: 'PaymentContractMoney',
  components: {
    Button,
    Icon,
    Panel
  }
})
export default class PaymentContractMoney extends Vue {
  @payment_contract.State('api_payment_money')
  api_payment_money: IPaymentContractState['api_payment_money']
  @payment_contract.Action('nextPaymentContract') nextPaymentContract

  /**
   * 日付を変換
   */
  convertDate(date: string): string {
    return moment(date).format('ll')
  }

  /**
   * 金額を変換
   */
  convertAmount(value: number): string {
    return '¥' + value.toLocaleString()
  }
}
