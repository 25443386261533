
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import i18n from '@/client/utils/i18n'

type TNotificationType = 'success' | 'error' | 'info' | 'warning' | 'wait'

@Component({
  name: 'Notification'
})
export default class Notification extends Vue {
  @Prop({ type: String, default: '' })
  title!: string

  @Prop({ type: String, default: '' })
  message!: string

  @Prop({ type: String, default: 'success' })
  type!: TNotificationType

  @Prop({ type: Boolean, default: false })
  loading!: boolean

  get title_text() {
    return i18n.t(this.title)
  }

  get message_text() {
    return i18n.t(this.message)
  }

  @Emit('click')
  onClick(event: Event) {
    return event
  }
}
