import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/instagram_hashtags'
import Message from '@/client/components-old/atoms/Message'
import PostMediaGrid from '@/client/components-old/molecules/PostMediaGrid'
import { TrackingService } from '@/client/services'
import { IState as IInstagramHashtagState } from '@/client/store/modules/instagram_hashtag'
import i18n from '@/client/utils/i18n'

const instagram_hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagPostGrid',
  components: {
    Message,
    PostMediaGrid
  }
})
export default class InstagramHashtagPostGrid extends Vue {
  @instagram_hashtag.State('api_settings') api_settings: IInstagramHashtagState['api_settings']
  @instagram_hashtag.State('api_posts') api_posts: IInstagramHashtagState['api_posts']
  @instagram_hashtag.State('post_display_order')
  post_display_order: IInstagramHashtagState['post_display_order']

  @Prop({ type: Number, default: null })
  height: number | null

  get style() {
    return this.height ? { height: `${this.height - 16}px`, overflow: 'scroll' } : {}
  }

  get grid_options() {
    return this.api_posts.posts.map(post => {
      const hashtag = this.api_settings.find(v => v.id === post.hashtag_id)
      const like_count = post.is_like_hidden ? '-' : post.like_count

      const details = [
        {
          key: i18n.t(constants.REACTION_COUNT.text),
          value: post.reaction_count
        },
        {
          key: i18n.t(constants.LIKE_COUNT.text),
          value: like_count
        },
        {
          key: i18n.t(constants.COMMENT_COUNT.text),
          value: post.comment_count
        }
      ]

      const order_field = this.post_display_order.replace('-', '')
      const main_metric_value = order_field === 'like_count' ? like_count : post[order_field]

      return {
        keyword_name: hashtag ? hashtag.name : '',
        post_id: post.post_id,
        post_type: post.post_type,
        post_url: post.post_url,
        thumbnail_url: post.thumbnail_url,
        thumbnail_count: post.media_count,
        is_top_media: !!post.is_top_media,
        timestamp: post.timestamp,
        main_metric: {
          key: order_field,
          value: main_metric_value
        },
        details
      }
    })
  }

  @Emit('click-post-grid')
  clickPostGrid(payload: any) {
    TrackingService.sendEvent(`click:クチコミ(IG)>投稿|グリッド:プレビュー`)

    return payload
  }
}
