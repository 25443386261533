import loadImage from 'blueimp-load-image'

import { createBlob } from './createBlob'
import { optimizeSize } from './optimizeSize'

/**
 * バックエンドでも扱いやすいように事前に画像を変換する
 */
export async function getImageByBlob(file: File) {
  // ? GIFはアニメーションGIFも存在するので変換しない
  if (file.type === 'image/gif') return file

  // ? カメラやスマホなどで画像に回転情報がある場合は変換する
  let canvas = await loadImage(file, {
    canvas: true,
    orientation: true
  })

  if (canvas.originalHeight && canvas.originalWidth) {
    // ? 極端なサイズの画像はリサイズして最適化する
    const size = optimizeSize({
      height: canvas.originalHeight,
      width: canvas.originalWidth
    })

    canvas = await loadImage(file, {
      canvas: true,
      orientation: true,
      maxHeight: size.height,
      maxWidth: size.width
    })
  }

  if (canvas.image instanceof HTMLCanvasElement) {
    return await createBlob(canvas.image)
  } else {
    return file
  }
}
