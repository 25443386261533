export const HEATMAP_HOUR_OPTIONS = [
  '0:00',
  '1:00',
  '2:00',
  '3:00',
  '4:00',
  '5:00',
  '6:00',
  '7:00',
  '8:00',
  '9:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00'
]

export const HEATMAP_DAY_OF_WEEK_OPTIONS = [
  '月曜日',
  '火曜日',
  '水曜日',
  '木曜日',
  '金曜日',
  '土曜日',
  '日曜日'
]

export const DISPLAY_TAB_OPTIONS = [
  { text: 'サマリー', value: 'summary', icon: 'line-graph' },
  { text: '収集設定', value: 'setting', icon: 'settings' }
]

export const STATS_OPTIONS = [
  { text: '平均', value: 'average' },
  { text: '合計', value: 'total' }
]

export const INTERVAL_OPTIONS = [
  { text: '時間', value: 'hour' },
  { text: '日', value: 'day' },
  { text: '週', value: 'week' },
  { text: '月', value: 'month' }
]
