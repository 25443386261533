import { render, staticRenderFns } from "./TwitterViralTrial.vue?vue&type=template&id=54b48982&scoped=true"
import script from "./TwitterViralTrial.vue?vue&type=script&lang=ts&setup=true"
export * from "./TwitterViralTrial.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./TwitterViralTrial.vue?vue&type=style&index=0&id=54b48982&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b48982",
  null
  
)

export default component.exports