import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'

export interface IState {
  title: string
  message: string
  type: 'success' | 'error' | 'info' | 'warning' | 'waiting'
  loading: boolean
  is_show: boolean
  time_event: number | null
}

const state: IState = {
  title: '',
  message: '',
  type: 'success',
  loading: false,
  is_show: false,
  time_event: null
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  SET_SHOW(state, payload) {
    state.title = payload.title
    state.message = payload.message
    state.type = payload.type
    state.loading = payload.loading
    state.is_show = true
  },
  SET_HIDE(state) {
    state.is_show = false
  },
  SET_TIME(state, payload) {
    state.time_event = payload.time_event
  }
}

const actions: ActionTree<IState, IRootState> = {
  /*
   * 通知を表示する
   */
  showNotification(context, payload) {
    if (state.time_event) {
      window.clearTimeout(state.time_event)
    }

    const params = {
      title: payload.title ? payload.title : '',
      message: payload.message ? payload.message : '',
      type: payload.type ? payload.type : 'success',
      loading: payload.loading ? payload.loading : false
    }

    context.commit('SET_SHOW', params)

    // 一定時間経過後、非表示にする
    if (typeof payload.duration === 'undefined' || payload.duration > 0) {
      const duration = payload.duration ? payload.duration : 7000

      // 一定時間待機する
      const time_event = window.setTimeout(() => {
        context.commit('SET_TIME', { time_event: null })
        context.commit('SET_HIDE')
      }, duration)

      context.commit('SET_TIME', { time_event })
    }
  },

  /*
   * 通知を非表示にする
   */
  hideNotification(context) {
    context.commit('SET_HIDE')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
