
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Action, namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import API from '@/client/utils/api'
import { IPostLoginTwoFactorParams, IPostLoginTwoFactorResponse } from '@/client/utils/api/login'
import { AUTH_CODE_FORMAT } from '@/client/utils/regex'

const notification = namespace('notification')

@Component({
  name: 'LoginTwoStepForm',
  components: {
    Button,
    Icon,
    Input,
    Message,
    Panel
  }
})
export default class LoginTwoStepForm extends Vue {
  @Action('login') login!: any
  @notification.Action('showNotification') showNotification: any

  @Prop({ type: String, required: true })
  email!: string

  @Prop({ type: String, default: null })
  redirect!: string | null

  form: {
    auth_code: string
  } = {
    auth_code: ''
  }

  is_auth_code_error = false
  is_auth_code_format_error = false

  loading = false

  get error_auth_code() {
    return this.is_auth_code_error || this.is_auth_code_format_error
  }

  get disabled() {
    const is_auth_code = !this.form.auth_code || this.error_auth_code

    return this.loading || is_auth_code
  }

  /**
   * 認証コードを入力
   */
  changeAuthCode(value: string) {
    this.is_auth_code_error = !value
    this.is_auth_code_format_error = !AUTH_CODE_FORMAT.test(value)
  }

  /**
   * パスワードリセットを申請
   */
  async loginTwoFactorForm() {
    if (this.disabled) return

    const params: IPostLoginTwoFactorParams = {
      ...this.form,
      email: this.email
    }

    this.loading = true

    const response = await API.post<IPostLoginTwoFactorResponse>('login/two_factor', params)

    this.loading = false

    if (response.data.data) {
      if (response.data.data?.status === 'success') {
        await this.login()

        await this.showNotification({ title: 'ログインしました。' })

        await this.changeRoute(this.redirect ? this.redirect : { name: 'dashboard' })

        return
      }
      if (response.data.data?.status === 'lock') {
        return this.showNotification({
          title: 'ログインを一時的にロックしました。',
          message: 'しばらく時間を空けるか、パスワードを再設定してからログインしてください。',
          type: 'error'
        })
      }
    }

    this.showNotification({
      title: '2段階認証に失敗しました。',
      message: '認証コードが正しくありません。',
      type: 'error'
    })
  }

  @Emit('change-route')
  changeRoute(payload: any): any {
    return payload
  }

  @Emit('change-login')
  changeLogin(payload: any): any {
    return payload
  }
}
