
import { computed, defineComponent, PropType } from 'vue'

import HelpLink from '@/client/components/molecules/HelpLink'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Tips from '@/client/components-old/atoms/Tips'

export type DialogType = 'register' | 'update'

type SnsType = 'facebook' | 'twitter' | 'instagram' | 'tiktok' | null

export default defineComponent({
  name: 'SnsAccountAbout',
  components: {
    Message,
    Tips,
    HelpLink,
    Icon
  },
  props: {
    dialogType: {
      type: String as PropType<DialogType>,
      required: true
    },
    snsType: {
      type: String as PropType<SnsType>,
      default: null
    },
    step: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const is_register = computed(() => props.dialogType === 'register')

    const is_update = computed(() => props.dialogType === 'update')

    const is_facebook = computed(() => props.snsType === 'facebook')

    const is_twitter = computed(() => props.snsType === 'twitter')

    const is_instagram = computed(() => props.snsType === 'instagram')

    const is_tiktok = computed(() => props.snsType === 'tiktok')

    const current_step = computed(() => props.step)

    return {
      is_register,
      is_update,
      is_facebook,
      is_twitter,
      is_instagram,
      is_tiktok,
      current_step
    }
  }
})
