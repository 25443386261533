import { Component, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import { namespace } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import SwitchRow from '@/client/components-old/molecules/SwitchRow'
import { IState as IPersonalSettingState } from '@/client/store/modules/personal_setting'

const setting = namespace('personal_setting')
const notification = namespace('notification')

@Component({
  name: 'PersonalSettingDashboard',
  components: {
    draggable,
    Icon,
    Panel,
    Tooltip,
    SwitchRow
  }
})
export default class PersonalSettingDashboard extends Vue {
  @setting.State('api_dashboard_settings')
  api_dashboard_settings: IPersonalSettingState['api_dashboard_settings']
  @setting.Action('putDashboardSettings') putDashboardSettings
  @setting.Action('putDashboardSettingsSort') putDashboardSettingsSort
  @notification.Action('showNotification') showNotification

  get is_mobile() {
    return this.$mq === 'sm'
  }

  get tutorial_help() {
    return this.api_dashboard_settings.find(v => v.item === 'tutorial_help')
  }

  get filter_settings() {
    return this.api_dashboard_settings.filter(v => v.item !== 'tutorial_help')
  }

  /**
   * 表示項目からテキストに変換
   */
  convertItemText(item: string) {
    switch (item) {
      case 'post_scheduled':
        return '予約投稿'
      case 'post_approve':
        return '承認待ち投稿'
      case 'post_reject':
        return '差し戻し投稿'
      case 'post_published':
        return '公開済み投稿'
      case 'monitoring_unread':
        return '未読アクション'
      case 'monitoring_pending':
        return '保留中アクション'
      case 'post_memo':
        return 'メモ (投稿)'
      case 'post_history':
        return '履歴 (投稿)'
      case 'monitoring_memo':
        return 'メモ (モニタリング)'
      case 'monitoring_history':
        return '履歴 (モニタリング)'
      case 'tutorial_help':
        return 'チュートリアルとヘルプ'
      default:
        return ''
    }
  }

  /**
   * 表示項目設定を変更する
   */
  async changeDashboardSetting(item: string) {
    const setting = this.api_dashboard_settings.find(setting => setting.item === item)

    if (!setting) return

    const payload = { item, is_show: !setting.is_show }

    const response = await this.putDashboardSettings(payload)

    if (response && response.data) {
      return this.showNotification({ title: 'ダッシュボードの表示項目設定を更新しました。' })
    }

    this.showNotification({
      title: 'ダッシュボードの表示項目設定の更新に失敗しました。',
      type: 'error'
    })
  }

  /**
   * 表示項目設定の順番を変更
   */
  async changeDashboardDraggable(settings: IPersonalSettingState['api_dashboard_settings']) {
    // チュートリアルを上部に固定にする
    const payload = [this.tutorial_help].concat(settings)

    const response = await this.putDashboardSettingsSort(payload)

    if (response && response.data) {
      return this.showNotification({ title: '表示順を変更しました。' })
    }

    this.showNotification({
      title: '表示順の変更に失敗しました。',
      type: 'error'
    })
  }
}
