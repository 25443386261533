import Vue from 'vue'

import { CONTRACT_STATUS_TRIAL } from '@/client/components-old/_constants/project'
import { store } from '@/client/store'

/**
 * ページ権限があるか確認
 */
export function checkPagePermissions(name: string | null | undefined): boolean {
  const {
    is_use_post,
    is_use_monitoring,
    is_use_analytics,
    is_use_tw_keyword,
    is_use_in_hashtag,
    is_use_tw_viral
  } = store.state.project
  const { role_admin, role_comparison, role_keyword, role_viral } = store.state.project_role
  const { is_restricted } = store.getters

  const group_count = store.state.projects.length
  const account_count: number = store.getters['accounts/account_count']

  const post_account_count: number = store.getters['accounts/post'].length
  const monitoring_account_count: number = store.getters['accounts/monitoring'].length

  const facebook_analytics_account_count: number =
    store.getters['accounts/facebook_analytics'].length
  const twitter_analytics_account_count: number = store.getters['accounts/twitter_analytics'].length
  const instagram_analytics_account_count: number =
    store.getters['accounts/instagram_analytics'].length
  const tiktok_analytics_account_count: number = store.getters['accounts/tiktok_analytics'].length

  const is_trial = store.state.project.contract_status === CONTRACT_STATUS_TRIAL
  const is_mobile = new Vue().$mq === 'sm'

  switch (name) {
    // 投稿権限
    case 'posts':
    case 'posts/create':
    case 'posts/detail':
    case 'posts/detail/edit':
    case 'posts/detail/copy':
      return is_use_post && post_account_count > 0 && !is_restricted

    // モニタリング権限
    case 'monitoring':
      return is_use_monitoring && monitoring_account_count > 0 && !is_restricted

    // 全アカウント一括分析権限
    case 'sns_analytics':
      return (
        is_use_analytics &&
        (facebook_analytics_account_count > 0 ||
          twitter_analytics_account_count > 0 ||
          instagram_analytics_account_count > 0 ||
          tiktok_analytics_account_count > 0) &&
        !is_restricted &&
        !is_mobile
      )

    // Facebook分析権限
    case 'analytics_fb':
    case 'report_fb':
      return (
        is_use_analytics && facebook_analytics_account_count > 0 && !is_restricted && !is_mobile
      )

    // Twitter分析権限
    case 'analytics_tw':
    case 'report_tw':
      return is_use_analytics && twitter_analytics_account_count > 0 && !is_restricted && !is_mobile

    // Instagram分析権限
    case 'analytics_in':
    case 'report_in':
      return (
        is_use_analytics && instagram_analytics_account_count > 0 && !is_restricted && !is_mobile
      )

    // TikTok分析権限
    case 'analytics_tt':
    case 'report_tt':
      return is_use_analytics && tiktok_analytics_account_count > 0 && !is_restricted && !is_mobile

    // Facebook競合比較権限
    case 'comparison_fb':
      return (
        is_use_analytics &&
        role_comparison &&
        facebook_analytics_account_count > 0 &&
        !is_restricted &&
        !is_mobile
      )

    // Twitter競合比較権限
    case 'comparison_tw':
      return (
        is_use_analytics &&
        role_comparison &&
        twitter_analytics_account_count > 0 &&
        !is_restricted &&
        !is_mobile
      )

    // Instagram競合比較権限
    case 'comparison_in':
      return (
        is_use_analytics &&
        role_comparison &&
        instagram_analytics_account_count > 0 &&
        !is_restricted &&
        !is_mobile
      )

    // Xクチコミ分析権限
    case 'twitter_keyword':
      return (
        is_use_analytics &&
        is_use_tw_keyword &&
        role_keyword &&
        twitter_analytics_account_count > 0 &&
        !is_restricted &&
        !is_mobile
      )

    // Instagramクチコミ分析権限
    case 'instagram_hashtag':
      return (
        is_use_analytics &&
        is_use_in_hashtag &&
        role_keyword &&
        instagram_analytics_account_count > 0 &&
        !is_restricted &&
        !is_mobile
      )

    // クチコミ推移権限
    case 'twitter_viral':
      return is_use_analytics && is_use_tw_viral && role_viral && !is_restricted && !is_mobile

    // ライブラリ権限
    case 'library_twitter_trend':
    case 'library_engagement_ranking':
      return group_count > 0 && account_count > 0 && !is_restricted && !is_mobile

    // 個人設定
    case 'setting_personal':
      return !is_restricted

    // グループ権限
    case 'setting_group':
      return role_admin && !is_restricted

    // お支払手続権限
    case 'setting_billing_info':
      return (role_admin && !is_restricted && is_trial) || !is_trial

    default:
      return true
  }
}
