
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Input'
})
export default class Input extends Vue {
  @Prop({ type: String, default: '' })
  value!: string

  @Prop({ type: String, default: '' })
  name!: string

  @Prop({ type: String, default: 'text' })
  type!: 'text' | 'email' | 'password'

  @Prop({ type: String, default: 'normal' })
  size!: 'normal' | 'full'

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: String, default: null })
  maxlength!: string

  @Prop({ type: String, default: '' })
  placeholder!: string

  @Prop({ type: Boolean, default: false })
  isError!: boolean

  $refs!: {
    el_input: any
    dummy_input: HTMLDivElement
  }

  get input_value() {
    return this.value
  }

  set input_value(val) {
    this.$emit('input', val)

    // PC以外の場合、Inputで入力した値がうまく反映されないため、反映できるようにダミーテキストを変更
    if (this.$device !== 'desktop') {
      this.$refs.dummy_input.innerText = this.$refs.dummy_input.innerText === '0' ? '1' : '0'
    }
  }

  get classes() {
    const temp = ['size-' + this.size]

    if (this.isError && !this.disabled) {
      temp.push('error')
    }

    return temp
  }

  /**
   * フォーカスイベント
   */
  focus(): void {
    this.$refs.el_input.focus()
  }

  /**
   * ブラーイベント
   */
  blur(): void {
    this.$refs.el_input.blur()
  }
}
