import { render, staticRenderFns } from "./Collage.vue?vue&type=template&id=3301f684&scoped=true"
import script from "./Collage.ts?vue&type=script&lang=ts&external"
export * from "./Collage.ts?vue&type=script&lang=ts&external"
import style0 from "./Collage.vue?vue&type=style&index=0&id=3301f684&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3301f684",
  null
  
)

export default component.exports