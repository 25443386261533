
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

import UserList from '@/client/components/molecules/UserList'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { TrackingService } from '@/client/services'
import { getTranslateText as t } from '@/client/utils/filters'

export default defineComponent({
  name: 'PostCreateApprovalSetting',
  components: {
    Flex,
    Icon,
    Panel,
    Tooltip,
    Select,
    UserList
  },
  setup() {
    return {
      t
    }
  },
  computed: {
    ...mapState('post_create', ['api_post_approval_flows']),
    ...mapState('post_create', ['accounts']),
    ...mapState('post_create', ['project_post_approval_flow_id']),

    approval_flow_options(): { value: number | string; text: string }[] {
      const options = [{ text: t('指定なし'), value: '' }]

      const flows = this.api_post_approval_flows.map(template => ({
        text: template.name,
        value: template.id
      }))

      return options.concat(flows)
    },
    step_users() {
      const api_post_approval_flow = this.api_post_approval_flows.find(
        item => item.id == this.project_post_approval_flow_id
      )

      return api_post_approval_flow ? api_post_approval_flow.step_users : []
    }
  },
  methods: {
    ...mapActions('post_create', ['changeProjectPostApprovalFlowId']),

    async onSelectApprovalFlowId(flow_id: number | string) {
      TrackingService.sendEvent('click:投稿作成|タグ|承認フロー選択')

      await this.changeProjectPostApprovalFlowId(flow_id)
    }
  }
})
