import { RouteConfig } from 'vue-router'

import PersonalSetting from '../pages/PersonalSetting.vue'

export const setting_personal: RouteConfig = {
  component: PersonalSetting,
  path: '/setting_personal',
  name: 'setting_personal',
  meta: {
    auth: true,
    title: '個人設定'
  }
}
