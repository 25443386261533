type TAuth = {
  domain: string
  access_token: string
  error: {
    type: string
  }
}

/**
 * Bitly認証
 */
export async function execAuth(): Promise<TAuth> {
  const width = 600
  const height = 800
  const top = (screen.height - height) / 4
  const left = (screen.width - width) / 2

  const auth_window = window.open(
    '/api/v1/oauth/bitly',
    'bitly_oauth',
    `width=${width}, height=${height}, top=${top}, left=${left}`
  )

  return new Promise(resolve => {
    window.onBitlyAuth = auth => {
      try {
        delete window.onBitlyAuth

        const data = JSON.parse(JSON.stringify(auth))

        resolve(data)
      } catch (err) {
        resolve(null)
      } finally {
        auth_window.close()
      }
    }
  })
}
