import { RouteConfig } from 'vue-router'

import LibraryEngagementRanking from '../pages/LibraryEngagementRanking.vue'

export const library_engagement_ranking: RouteConfig = {
  component: LibraryEngagementRanking,
  path: '/library_engagement_ranking',
  name: 'library_engagement_ranking',
  meta: {
    auth: true,
    title: 'エンゲージメントランキング',
    description: '業界ごとのエンゲージメントランキングを確認できます。'
  }
}
