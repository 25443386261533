import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

import { useTwitterViralGroup } from './useTwitterViralGroup'
import { useTwitterViralPostCount } from './useTwitterViralPostCount'
import { useTwitterViralSetting } from './useTwitterViralSetting'

type DisplayTab = 'summary' | 'setting'
type Interval = 'hour' | 'day' | 'week' | 'month'
type Stats = 'average' | 'total'

export const useTwitterViralStore = defineStore('twitter_viral', () => {
  const { api_group, is_group_loading, fetchTwitterViralGroupById } = useTwitterViralGroup()

  const {
    api_settings,
    is_setting_loading,
    collection_count,
    fetchTwitterViralSettings,
    createTwitterViralSetting,
    updateTwitterViralSetting,
    deleteTwitterViralSetting,
    syncTwitterViralSettingOrders
  } = useTwitterViralSetting()

  const { api_post_counts, is_post_count_loading, setting_ids, date_range } =
    useTwitterViralPostCount()

  const is_loading = computed(
    () => is_group_loading.value || is_post_count_loading.value || is_setting_loading.value
  )

  const display_tab = ref<DisplayTab>('summary')

  const post_count_setting_id = ref(0)

  watch(setting_ids, value => {
    if (!value.includes(post_count_setting_id.value)) {
      post_count_setting_id.value = value[0] ?? 0
    }
  })

  const post_count_interval = ref<Interval>('day')
  const post_count_pagination = ref(0)

  watch(post_count_interval, () => {
    post_count_pagination.value = 0
  })

  const heatmap_setting_id = ref(0)
  const heatmap_stats = ref<Stats>('total')

  watch(setting_ids, value => {
    if (!value.includes(heatmap_setting_id.value)) {
      heatmap_setting_id.value = value[0] ?? 0
    }
  })

  async function mountedPage() {
    await Promise.all([fetchTwitterViralSettings(), fetchTwitterViralGroupById()])
  }

  async function unmountedPage() {
    api_post_counts.value = []
    api_settings.value = []
  }

  async function changeGroupId() {
    unmountedPage()
    setting_ids.value = []
    await mountedPage()
  }

  async function reloadPage() {
    await Promise.all([fetchTwitterViralSettings(), fetchTwitterViralGroupById()])
  }

  return {
    api_settings,
    api_post_counts,
    collection_count,
    api_group,
    is_loading,
    setting_ids,
    date_range,
    display_tab,
    post_count_setting_id,
    post_count_interval,
    post_count_pagination,
    heatmap_setting_id,
    heatmap_stats,
    mountedPage,
    unmountedPage,
    changeGroupId,
    reloadPage,
    createTwitterViralSetting,
    updateTwitterViralSetting,
    deleteTwitterViralSetting,
    syncTwitterViralSettingOrders
  }
})
