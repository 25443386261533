import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { IRootState } from '@/client/store/global'
import { IState as IStatePersonalSetting } from '@/client/store/modules/personal_setting'

const personal_setting = namespace('personal_setting')

type Setting = {
  text: string
  description?: string
  value: string
  is_visible: boolean
}

@Component({
  name: 'PersonalSettingRoleGroup',
  components: {
    Icon,
    Panel,
    Tooltip
  }
})
export default class PersonalSettingRoleGroup extends Vue {
  @State('project') project!: IRootState['project']
  @personal_setting.State('api_role') api_role!: IStatePersonalSetting['api_role']

  get settings(): Setting[] {
    if (!this.api_role) {
      return []
    }

    const is_role_analytics_setting = 'role_analytics_setting' in this.api_role.role

    // TODO Twitterクチコミ分析廃止を考慮して名称をInstagramに寄せている。 このコメントはTwitterクチコミ分析廃止時に削除する。
    const is_use_in_hashtag = this.project.is_use_tw_keyword || this.project.is_use_in_hashtag

    const settings = [
      {
        text: 'グループ管理者',
        description: 'グループ設定を変更できます。',
        value: this.convertRole(this.api_role.role.role_admin),
        is_visible: true
      },
      {
        text: '分析: レイアウト変更',
        value: this.convertRole(this.api_role.role.role_analytics_setting),
        is_visible: is_role_analytics_setting
      },
      {
        text: '分析: 競合比較へのアクセス',
        description:
          '競合比較にアクセスするには、分析の管理者権限を持つアカウントが1つ以上必要になります。',
        value: this.convertRole(this.api_role.role.role_comparison),
        is_visible: is_role_analytics_setting
      },
      {
        text: '分析: クチコミ分析へのアクセス',
        value: this.convertRole(this.api_role.role.role_keyword),
        is_visible: is_use_in_hashtag
      },
      {
        text: '分析: クチコミ推移へのアクセス',
        value: this.convertRole(this.api_role.role.role_viral),
        is_visible: this.project.is_use_tw_viral
      }
    ]

    return settings.filter(v => v.is_visible)
  }

  /**
   * 権限の表示名を取得
   */
  convertRole(role: boolean) {
    return role ? '権限あり' : '権限なし'
  }

  /**
   * 権限のカラーを変換
   */
  convertColor(value: string) {
    if (value === '権限なし') {
      return 'color-disabled'
    }

    return ''
  }
}
