import moment from 'moment-timezone'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  IDeleteInstagramHashtagParams,
  IDeleteInstagramHashtagResponse,
  IGetInstagramHashtagsHashtagsParams,
  IGetInstagramHashtagsHashtagsResponse,
  IGetInstagramHashtagsHeatmapsParams,
  IGetInstagramHashtagsHeatmapsResponse,
  IGetInstagramHashtagsParams,
  IGetInstagramHashtagsPostCountParams,
  IGetInstagramHashtagsPostCountResponse,
  IGetInstagramHashtagsPostsParams,
  IGetInstagramHashtagsPostsResponse,
  IGetInstagramHashtagsRankingParams,
  IGetInstagramHashtagsRankingResponse,
  IGetInstagramHashtagsResponse,
  IGetInstagramHashtagsSentimentCountParams,
  IGetInstagramHashtagsSentimentCountResponse,
  IGetInstagramHashtagsSummaryParams,
  IGetInstagramHashtagsSummaryResponse,
  IGetInstagramHashtagsWordsParams,
  IGetInstagramHashtagsWordsResponse,
  IInstagramHashtag,
  IInstagramHashtagsHashtag,
  IInstagramHashtagsPostCount,
  IInstagramHashtagsPosts,
  IInstagramHashtagsRanking,
  IInstagramHashtagsSentimentCount,
  IInstagramHashtagsSummary,
  IInstagramHashtagsWord,
  InstagramHashtagsHeatmap,
  IPostInstagramHashtagParams,
  IPostInstagramHashtagPostCategoriesParams,
  IPostInstagramHashtagPostCategoriesResponse,
  IPostInstagramHashtagResponse,
  IPutInstagramHashtagParams,
  IPutInstagramHashtagResponse,
  IPutInstagramHashtagsChangeOrderNoParams,
  IPutInstagramHashtagsChangeOrderNoResponse,
  IPutInstagramHashtagStatusParams,
  IPutInstagramHashtagStatusResponse
} from '@/client/utils/api/instagram_hashtags'
import storage from '@/client/utils/storage'

export type ScreenName = 'summary' | 'post' | 'management'
export type DisplayFormat = 'average' | 'total'
export type PostCountInterval = 'hour' | 'day' | 'week' | 'month'
export type SentimenCountInterval = 'hour' | 'day' | 'week' | 'month'
export type PostDisplayTab = 'table' | 'grid'
export type RankingSort = 'reaction_count' | 'like_count' | 'comment_count'
export type HeatmapMetric = 'post_count' | 'reaction_count' | 'like_count' | 'comment_count'

export interface IState {
  api_settings: IInstagramHashtag[]
  api_summary: IInstagramHashtagsSummary[]
  api_post_count: IInstagramHashtagsPostCount[]
  api_sentiment_count: IInstagramHashtagsSentimentCount | null
  api_heatmaps: InstagramHashtagsHeatmap[]
  api_word_cloud: IInstagramHashtagsWord[]
  api_words: IInstagramHashtagsWord[]
  api_hashtags: IInstagramHashtagsHashtag[]
  api_ranking: IInstagramHashtagsRanking[]
  api_posts: IInstagramHashtagsPosts
  hashtag_ids: number[]
  start_date: string
  end_date: string
  screen_name: ScreenName
  summary_display_order: string
  summary_display_format: DisplayFormat
  post_count_interval: PostCountInterval
  post_count_index: number
  sentiment_count_interval: SentimenCountInterval
  sentiment_count_index: number
  heatmap_hashtag_id: number | ''
  heatmap_metric: HeatmapMetric
  heatmap_display_format: DisplayFormat
  word_cloud_hashtag_id: number | ''
  word_cloud_size: 25 | 50 | 75 | 100
  words_hashtag_id: number | ''
  words_size: 25 | 50 | 75 | 100
  hashtags_hashtag_id: number | ''
  hashtags_size: 25 | 50 | 75 | 100
  ranking_sort: RankingSort
  ranking_size: 5 | 10 | 25 | 50
  is_top_media: boolean
  filter_post_types: string[]
  filter_sentiment: string
  filter_category_ids: number[]
  filter_search_keyword: string
  post_display_tab: PostDisplayTab
  post_display_order: string
  post_display_offset: number
  post_display_count: 10 | 25 | 50 | 100
  is_loading: boolean
  is_summary_loading: boolean
  is_post_count_loading: boolean
  is_sentiment_count_loading: boolean
  is_heatmap_loading: boolean
  is_word_cloud_loading: boolean
  is_words_loading: boolean
  is_hashtags_loading: boolean
  is_ranking_loading: boolean
  is_posts_loading: boolean
}

export interface IGetter {
  post_count_date_range: { start_date: string; end_date: string }
  sentiment_count_date_range: { start_date: string; end_date: string }
  is_fetch_loading: boolean
  is_data: boolean
}

const state: IState = {
  api_settings: [],
  api_summary: [],
  api_post_count: [],
  api_sentiment_count: null,
  api_heatmaps: [],
  api_word_cloud: [],
  api_words: [],
  api_hashtags: [],
  api_ranking: [],
  api_posts: { posts: [], total: 0 },
  hashtag_ids: [],
  start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  end_date: moment().startOf('day').format('YYYY-MM-DD'),
  screen_name: 'summary',
  summary_display_order: 'hashtag_name',
  summary_display_format: 'total',
  post_count_interval: 'day',
  post_count_index: 0,
  sentiment_count_interval: 'day',
  sentiment_count_index: 0,
  heatmap_hashtag_id: '',
  heatmap_metric: 'post_count',
  heatmap_display_format: 'total',
  word_cloud_hashtag_id: '',
  word_cloud_size: 50,
  words_hashtag_id: '',
  words_size: 25,
  hashtags_hashtag_id: '',
  hashtags_size: 25,
  ranking_sort: 'reaction_count',
  ranking_size: 5,
  is_top_media: false,
  filter_post_types: [],
  filter_sentiment: '',
  filter_category_ids: [],
  filter_search_keyword: '',
  post_display_tab: 'table',
  post_display_order: '-reaction_count',
  post_display_offset: 0,
  post_display_count: 25,
  is_loading: false,
  is_summary_loading: false,
  is_post_count_loading: false,
  is_sentiment_count_loading: false,
  is_heatmap_loading: false,
  is_word_cloud_loading: false,
  is_words_loading: false,
  is_hashtags_loading: false,
  is_ranking_loading: false,
  is_posts_loading: false
}

const getters: GetterTree<IState, IRootState> = {
  post_count_date_range(state): IGetter['post_count_date_range'] {
    let subtract_day = 0

    switch (state.post_count_interval) {
      case 'hour':
        subtract_day = state.post_count_index * 7
        break
      case 'day':
        subtract_day = state.post_count_index * 31
        break
      case 'week':
        subtract_day = state.post_count_index * 31 * 7
        break
      case 'month':
        subtract_day = state.post_count_index * 13 * 31
    }

    const end_date = moment(state.end_date).subtract(subtract_day, 'day')

    let start_date = end_date.clone().subtract(30, 'day')

    switch (state.post_count_interval) {
      case 'hour':
        start_date = end_date.clone().subtract(6, 'day')
        break
      case 'day':
        start_date = end_date.clone().subtract(30, 'day')
        break
      case 'week':
        start_date = end_date.clone().subtract(31 * 7 - 1, 'day')
        break
      case 'month':
        start_date = moment(state.start_date)
    }

    if (start_date.isBefore(moment(state.start_date))) {
      start_date = moment(state.start_date)
    }

    return {
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD')
    }
  },
  sentiment_count_date_range(state): IGetter['sentiment_count_date_range'] {
    let subtract_day = 0

    switch (state.sentiment_count_interval) {
      case 'hour':
        subtract_day = state.sentiment_count_index * 7
        break
      case 'day':
        subtract_day = state.sentiment_count_index * 31
        break
      case 'week':
        subtract_day = state.sentiment_count_index * 31 * 7
        break
      case 'month':
        subtract_day = state.sentiment_count_index * 13 * 31
    }

    const end_date = moment(state.end_date).subtract(subtract_day, 'day')

    let start_date = end_date.clone().subtract(30, 'day')

    switch (state.sentiment_count_interval) {
      case 'hour':
        start_date = end_date.clone().subtract(6, 'day')
        break
      case 'day':
        start_date = end_date.clone().subtract(30, 'day')
        break
      case 'week':
        start_date = end_date.clone().subtract(31 * 7 - 1, 'day')
        break
      case 'month':
        start_date = moment(state.start_date)
    }

    if (start_date.isBefore(moment(state.start_date))) {
      start_date = moment(state.start_date)
    }

    return {
      start_date: start_date.format('YYYY-MM-DD'),
      end_date: end_date.format('YYYY-MM-DD')
    }
  },
  is_data(state): IGetter['is_data'] {
    return state.api_summary.some(v => v.post_count > 0)
  },
  is_fetch_loading(state): IGetter['is_fetch_loading'] {
    return (
      state.is_loading ||
      state.is_summary_loading ||
      state.is_post_count_loading ||
      state.is_sentiment_count_loading ||
      state.is_heatmap_loading ||
      state.is_word_cloud_loading ||
      state.is_words_loading ||
      state.is_hashtags_loading ||
      state.is_ranking_loading ||
      state.is_posts_loading
    )
  }
}

const mutations: MutationTree<IState> = {
  SET_API_SETTINGS(state, payload: IInstagramHashtag[]) {
    state.api_settings = payload
  },
  SET_API_SUMMARY(state, payload: IInstagramHashtagsSummary[]) {
    state.api_summary = payload
  },
  SET_API_POST_COUNT(state, payload: IInstagramHashtagsPostCount[]) {
    state.api_post_count = payload
  },
  SET_API_SENTIMENT_COUNT(state, payload: IInstagramHashtagsSentimentCount) {
    state.api_sentiment_count = payload
  },
  SET_API_HEATMAP(state, payload: InstagramHashtagsHeatmap[]) {
    state.api_heatmaps = payload
  },
  SET_API_WORD_CLOUD(state, payload: IInstagramHashtagsWord[]) {
    state.api_word_cloud = payload
  },
  SET_API_WORDS(state, payload: IInstagramHashtagsWord[]) {
    state.api_words = payload
  },
  SET_API_HASHTAGS(state, payload: IInstagramHashtagsHashtag[]) {
    state.api_hashtags = payload
  },
  SET_API_RANKING(state, payload: IInstagramHashtagsRanking[]) {
    state.api_ranking = payload
  },
  SET_API_POSTS(state, payload: IInstagramHashtagsPosts) {
    state.api_posts = payload
  },
  SET_HASHTAG_IDS(state, payload: number[]) {
    state.hashtag_ids = payload

    storage.set('instagram_hashtag', { hashtag_ids: payload })
  },
  SET_DATE_RANGE(state, payload: [string, string]) {
    state.start_date = payload[0]
    state.end_date = payload[1]
  },
  SET_SCREEN_NAME(state, payload: ScreenName) {
    state.screen_name = payload
  },
  SET_SUMMARY_DISPLAY_ORDER(state, payload: string) {
    state.summary_display_order = payload
  },
  SET_SUMMARY_DISPLAY_FORMAT(state, payload: DisplayFormat) {
    state.summary_display_format = payload
  },
  SET_POST_COUNT_INTERVAL(state, payload: PostCountInterval) {
    state.post_count_interval = payload
  },
  SET_POST_COUNT_INDEX(state, payload: number) {
    state.post_count_index = payload
  },
  SET_SENTIMENT_COUNT_INTERVAL(state, payload: SentimenCountInterval) {
    state.sentiment_count_interval = payload
  },
  SET_SENTIMENT_COUNT_INDEX(state, payload: number) {
    state.sentiment_count_index = payload
  },
  SET_HEATMAP_HASHTAG_ID(state, payload: number | '') {
    state.heatmap_hashtag_id = payload
  },
  SET_HEATMAP_METRIC(state, payload: HeatmapMetric) {
    state.heatmap_metric = payload
  },
  SET_HEATMAP_DISPLAY_FORMAT(state, payload: DisplayFormat) {
    state.heatmap_display_format = payload
  },
  SET_WORD_CLOUD_HASHTAG_ID(state, payload: number | '') {
    state.word_cloud_hashtag_id = payload
  },
  SET_WORD_CLOUD_SIZE(state, payload: 25 | 50 | 75 | 100) {
    state.word_cloud_size = payload
  },
  SET_WORDS_HASHTAG_ID(state, payload: number | '') {
    state.words_hashtag_id = payload
  },
  SET_WORDS_SIZE(state, payload: 25 | 50 | 75 | 100) {
    state.words_size = payload
  },
  SET_HASHTAGS_HASHTAG_ID(state, payload: number | '') {
    state.hashtags_hashtag_id = payload
  },
  SET_HASHTAGS_SIZE(state, payload: 25 | 50 | 75 | 100) {
    state.hashtags_size = payload
  },
  SET_RANKING_SORT(state, payload: RankingSort) {
    state.ranking_sort = payload
  },
  SET_RANKING_SIZE(state, payload: 5 | 10 | 25 | 50) {
    state.ranking_size = payload
  },
  SET_IS_TOP_MEDIA(state, payload: boolean) {
    state.is_top_media = payload
  },
  SET_FILTER_POST_TYPE(state, payload: string[]) {
    state.filter_post_types = payload
  },
  SET_FILTER_SENTIMENT(state, payload: string) {
    state.filter_sentiment = payload
  },
  SET_FILTER_CATEGORY_IDS(state, payload: number[]) {
    state.filter_category_ids = payload
  },
  SET_FILTER_SEARCH_KEYWORD(state, payload: string) {
    state.filter_search_keyword = payload
  },
  SET_POST_DISPLAY_TAB(state, payload: PostDisplayTab) {
    state.post_display_tab = payload
  },
  SET_POST_DISPLAY_ORDER(state, payload: string) {
    state.post_display_order = payload
  },
  SET_POST_DISPLAY_OFFSET(state, payload: number) {
    state.post_display_offset = payload
  },
  SET_POST_DISPLAY_COUNT(state, payload: 10 | 25 | 50 | 100) {
    state.post_display_count = payload
  },
  SET_IS_LOADING(state, payload: boolean) {
    state.is_loading = payload
  },
  SET_IS_SUMMARY_LOADING(state, payload: boolean) {
    state.is_summary_loading = payload
  },
  SET_IS_POST_COUNT_LOADING(state, payload: boolean) {
    state.is_post_count_loading = payload
  },
  SET_IS_SENTIMENT_COUNT_LOADING(state, payload: boolean) {
    state.is_sentiment_count_loading = payload
  },
  SET_IS_HEATMAP_LOADING(state, payload: boolean) {
    state.is_heatmap_loading = payload
  },
  SET_IS_WORD_CLOUD_LOADING(state, payload: boolean) {
    state.is_word_cloud_loading = payload
  },
  SET_IS_WORDS_LOADING(state, payload: boolean) {
    state.is_words_loading = payload
  },
  SET_IS_HASHTAGS_LOADING(state, payload: boolean) {
    state.is_hashtags_loading = payload
  },
  SET_IS_RANKING_LOADING(state, payload: boolean) {
    state.is_ranking_loading = payload
  },
  SET_IS_POSTS_LOADING(state, payload: boolean) {
    state.is_posts_loading = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * ページ表示時の処理
   */
  async createdInstagramHashtagPage(context) {
    context.commit('SET_IS_LOADING', true)

    await context.dispatch('fetchInstagramHashtags')

    context.commit('SET_IS_LOADING', false)

    if (!context.state.api_settings.length) {
      context.commit('SET_SCREEN_NAME', 'summary')
    }

    const local: { hashtag_ids?: number[] } = storage.get('instagram_hashtag')

    let hashtag_ids: number[] = []

    if (local && local.hashtag_ids && Array.isArray(local.hashtag_ids)) {
      hashtag_ids = local.hashtag_ids.filter(hashtag =>
        context.state.api_settings.map(v => v.id).includes(hashtag)
      )
    }

    context.commit('SET_HASHTAG_IDS', hashtag_ids)

    if (!hashtag_ids.length) return

    await Promise.all([
      context.dispatch('fetchHashtagSummary'),
      context.dispatch('fetchHashtagPostCount'),
      context.dispatch('fetchHashtagSentimentCount'),
      context.dispatch('fetchHashtagHeatmap'),
      context.dispatch('fetchHashtagWordCloud'),
      context.dispatch('fetchHashtagWords'),
      context.dispatch('fetchHashtagHashtags'),
      context.dispatch('fetchHashtagRanking'),
      context.dispatch('fetchHashtagPosts'),
      context.dispatch('fetchProjectData', context.rootState.project.id, { root: true })
    ])
  },

  /**
   * ページ離脱時の処理
   */
  destroyedInstagramHashtagPage(context) {
    context.commit('SET_API_SETTINGS', [])
    context.commit('SET_API_SUMMARY', [])
    context.commit('SET_API_POST_COUNT', [])
    context.commit('SET_API_SENTIMENT_COUNT', [])
    context.commit('SET_API_HEATMAP', [])
    context.commit('SET_API_WORD_CLOUD', [])
    context.commit('SET_API_WORDS', [])
    context.commit('SET_API_HASHTAGS', [])
    context.commit('SET_API_RANKING', [])
    context.commit('SET_API_POSTS', { posts: [], total: 0 })
    context.commit('SET_POST_COUNT_INTERVAL', 'day')
    context.commit('SET_POST_COUNT_INDEX', 0)
    context.commit('SET_SENTIMENT_COUNT_INTERVAL', 'day')
    context.commit('SET_SENTIMENT_COUNT_INDEX', 0)
    context.commit('SET_POST_DISPLAY_OFFSET', 0)
    context.commit('SET_HEATMAP_HASHTAG_ID', '')
    context.commit('SET_WORD_CLOUD_HASHTAG_ID', '')
    context.commit('SET_WORDS_HASHTAG_ID', '')
    context.commit('SET_HASHTAGS_HASHTAG_ID', '')
  },

  /**
   * グループ変更時の処理
   */
  async changeGroupInstagramHashtagPage(context) {
    await context.dispatch('destroyedInstagramHashtagPage')

    context.commit('SET_IS_TOP_MEDIA', false)
    context.commit('SET_FILTER_POST_TYPE', [])
    context.commit('SET_FILTER_CATEGORY_IDS', [])
    context.commit('SET_FILTER_SEARCH_KEYWORD', '')

    await context.dispatch('createdInstagramHashtagPage')
  },

  /**
   * 分析データの更新
   */
  async refreshInstagramHashtag(context) {
    if (!state.hashtag_ids.length) return

    await Promise.all([
      context.dispatch('fetchInstagramHashtags'),
      context.dispatch('fetchHashtagSummary'),
      context.dispatch('fetchHashtagPostCount'),
      context.dispatch('fetchHashtagSentimentCount'),
      context.dispatch('fetchHashtagHeatmap'),
      context.dispatch('fetchHashtagWordCloud'),
      context.dispatch('fetchHashtagWords'),
      context.dispatch('fetchHashtagHashtags'),
      context.dispatch('fetchHashtagRanking'),
      context.dispatch('fetchHashtagPosts'),
      context.dispatch('fetchProjectData', context.rootState.project.id, { root: true })
    ])
  },

  /**
   * 基本データの取得
   */
  async fetchHashtagSummary(context) {
    const params: IGetInstagramHashtagsSummaryParams = {
      project_id: context.rootState.project.id,
      hashtag_ids: context.state.hashtag_ids.join(','),
      start_date: context.state.start_date,
      end_date: context.state.end_date
    }

    context.commit('SET_IS_SUMMARY_LOADING', true)

    const response = await API.get<IGetInstagramHashtagsSummaryResponse>(
      'instagram_hashtags/summary',
      { params }
    )

    context.commit('SET_IS_SUMMARY_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_SUMMARY', response.data.data)
    }

    return response
  },

  /**
   * 投稿数の取得
   */
  async fetchHashtagPostCount(context) {
    const { start_date, end_date }: IGetter['post_count_date_range'] =
      context.getters.post_count_date_range

    const params: IGetInstagramHashtagsPostCountParams = {
      project_id: context.rootState.project.id,
      hashtag_ids: context.state.hashtag_ids.join(','),
      start_date,
      end_date,
      interval: context.state.post_count_interval,
      metric: 'post_count'
    }

    context.commit('SET_IS_POST_COUNT_LOADING', true)

    const response = await API.get<IGetInstagramHashtagsPostCountResponse>(
      'instagram_hashtags/graph',
      { params }
    )

    context.commit('SET_IS_POST_COUNT_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_POST_COUNT', response.data.data)
    }

    return response
  },

  /**
   * 投稿数のCSVのデータを取得する
   */
  async fetchHashtagPostCountCsv(context) {
    const params: IGetInstagramHashtagsPostCountParams = {
      project_id: context.rootState.project.id,
      hashtag_ids: context.state.hashtag_ids.join(','),
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      interval: context.state.post_count_interval,
      metric: 'post_count'
    }

    const response = await API.get<IGetInstagramHashtagsPostCountResponse>(
      'instagram_hashtags/graph',
      { params }
    )

    return response.data
  },

  /**
   * センチメントの取得
   */
  async fetchHashtagSentimentCount(context) {
    const { start_date, end_date }: IGetter['sentiment_count_date_range'] =
      context.getters.sentiment_count_date_range

    const params: IGetInstagramHashtagsSentimentCountParams = {
      project_id: context.rootState.project.id,
      hashtag_ids: context.state.hashtag_ids.join(','),
      start_date,
      end_date,
      interval: context.state.sentiment_count_interval
    }

    context.commit('SET_IS_SENTIMENT_COUNT_LOADING', true)

    const response = await API.get<IGetInstagramHashtagsSentimentCountResponse>(
      'instagram_hashtags/sentiment_count',
      {
        params
      }
    )

    context.commit('SET_IS_SENTIMENT_COUNT_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_SENTIMENT_COUNT', response.data.data)
    }

    return response
  },

  /**
   * センチメントのCSVのデータを取得する
   */
  async fetchHashtagSentimentCountCsv(context) {
    const params: IGetInstagramHashtagsSentimentCountParams = {
      project_id: context.rootState.project.id,
      hashtag_ids: context.state.hashtag_ids.join(','),
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      interval: context.state.sentiment_count_interval
    }

    const response = await API.get<IGetInstagramHashtagsSentimentCountResponse>(
      'instagram_hashtags/sentiment_count',
      {
        params
      }
    )

    return response.data
  },

  /**
   * ヒートマップの取得
   */
  async fetchHashtagHeatmap(context) {
    const hashtag_ids =
      String(context.state.heatmap_hashtag_id) || context.state.hashtag_ids.join(',')

    const params: IGetInstagramHashtagsHeatmapsParams = {
      project_id: context.rootState.project.id,
      hashtag_ids,
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      metric: context.state.heatmap_metric,
      aggregate: context.state.heatmap_display_format
    }

    context.commit('SET_IS_HEATMAP_LOADING', true)

    const response = await API.get<IGetInstagramHashtagsHeatmapsResponse>(
      'instagram_hashtags/heatmaps',
      {
        params
      }
    )

    context.commit('SET_IS_HEATMAP_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_HEATMAP', response.data.data)
    }

    return response
  },

  /**
   * ワードクラウドの取得
   */
  async fetchHashtagWordCloud(context) {
    const hashtag_ids =
      String(context.state.word_cloud_hashtag_id) || context.state.hashtag_ids.join(',')

    const params: IGetInstagramHashtagsWordsParams = {
      project_id: context.rootState.project.id,
      hashtag_ids,
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      size: context.state.word_cloud_size
    }

    context.commit('SET_IS_WORD_CLOUD_LOADING', true)

    const response = await API.get<IGetInstagramHashtagsWordsResponse>('instagram_hashtags/words', {
      params
    })

    context.commit('SET_IS_WORD_CLOUD_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_WORD_CLOUD', response.data.data)
    }

    return response
  },

  /**
   * ワード一覧の取得
   */
  async fetchHashtagWords(context) {
    const hashtag_ids =
      String(context.state.words_hashtag_id) || context.state.hashtag_ids.join(',')

    const params: IGetInstagramHashtagsWordsParams = {
      project_id: context.rootState.project.id,
      hashtag_ids: hashtag_ids,
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      size: context.state.words_size
    }

    context.commit('SET_IS_WORDS_LOADING', true)

    const response = await API.get<IGetInstagramHashtagsWordsResponse>('instagram_hashtags/words', {
      params
    })

    context.commit('SET_IS_WORDS_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_WORDS', response.data.data)
    }

    return response
  },

  /**
   * ハッシュタグ一覧の取得
   */
  async fetchHashtagHashtags(context) {
    const hashtag_ids =
      String(context.state.hashtags_hashtag_id) || context.state.hashtag_ids.join(',')

    const params: IGetInstagramHashtagsHashtagsParams = {
      project_id: context.rootState.project.id,
      hashtag_ids: hashtag_ids,
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      size: context.state.hashtags_size
    }

    context.commit('SET_IS_HASHTAGS_LOADING', true)

    const response = await API.get<IGetInstagramHashtagsHashtagsResponse>(
      'instagram_hashtags/hashtags',
      { params }
    )

    context.commit('SET_IS_HASHTAGS_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_HASHTAGS', response.data.data)
    }

    return response
  },

  /**
   * ランキングの取得
   */
  async fetchHashtagRanking(context) {
    const params: IGetInstagramHashtagsRankingParams = {
      project_id: context.rootState.project.id,
      hashtag_ids: context.state.hashtag_ids.join(','),
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      sort: context.state.ranking_sort,
      size: context.state.ranking_size
    }

    context.commit('SET_IS_RANKING_LOADING', true)

    const response = await API.get<IGetInstagramHashtagsRankingResponse>(
      'instagram_hashtags/ranking',
      { params }
    )

    context.commit('SET_IS_RANKING_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_RANKING', response.data.data)
    }

    return response
  },

  /**
   * 投稿の取得
   */
  async fetchHashtagPosts(context) {
    const params: IGetInstagramHashtagsPostsParams = {
      project_id: context.rootState.project.id,
      hashtag_ids: context.state.hashtag_ids.join(','),
      start_date: context.state.start_date,
      end_date: context.state.end_date,
      is_top_media: context.state.is_top_media,
      post_types: context.state.filter_post_types.join(','),
      sentiment: context.state.filter_sentiment,
      post_category_ids: context.state.filter_category_ids.join(','),
      keyword: context.state.filter_search_keyword,
      order: context.state.post_display_order,
      from: context.state.post_display_offset,
      size: context.state.post_display_count,
      is_scroll: false,
      scroll_id: ''
    }

    context.commit('SET_IS_POSTS_LOADING', true)

    const response = await API.get<IGetInstagramHashtagsPostsResponse>('instagram_hashtags/posts', {
      params
    })

    context.commit('SET_IS_POSTS_LOADING', false)

    if (response.data.data) {
      context.commit('SET_API_POSTS', response.data.data)
    }
  },

  /**
   * クチコミ設定の取得
   */
  async fetchInstagramHashtags(context) {
    const params: IGetInstagramHashtagsParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.get<IGetInstagramHashtagsResponse>('instagram_hashtags', {
      params
    })

    if (response.data.data) {
      context.commit('SET_API_SETTINGS', response.data.data)
    }
  },

  /**
   * クチコミ設定の追加
   */
  async createInstagramHashtag(
    context,
    payload: {
      account_id: string
      name: string
      start_date: string
      end_date: string | null
      hashtag: string
      and_keywords: string[]
      or_keywords: string[]
      not_keywords: string[]
      alert_post_count: number
    }
  ) {
    const params: IPostInstagramHashtagParams = {
      project_id: context.rootState.project.id,
      account_id: payload.account_id,
      name: payload.name,
      start_date: payload.start_date,
      end_date: payload.end_date,
      hashtag: payload.hashtag,
      and_keywords: payload.and_keywords,
      or_keywords: payload.or_keywords,
      not_keywords: payload.not_keywords,
      alert_post_count: payload.alert_post_count
    }

    const response = await API.post<IPostInstagramHashtagResponse>('instagram_hashtags', params)

    if (response.data) {
      await context.dispatch('fetchInstagramHashtags')

      const hashtag_ids = [...context.state.hashtag_ids, response.data.data]

      await context.dispatch('changeHashtagIds', hashtag_ids)
    }

    return response.data
  },

  /**
   * クチコミ設定の更新
   */
  async updateInstagramHashtag(
    context,
    payload: {
      hashtag_id: number
      account_id: string
      name: string
      start_date: string
      end_date: string | null
      hashtag: string
      and_keywords: string[]
      or_keywords: string[]
      not_keywords: string[]
      alert_post_count: number
    }
  ) {
    const params: IPutInstagramHashtagParams = {
      project_id: context.rootState.project.id,
      account_id: payload.account_id,
      name: payload.name,
      start_date: payload.start_date,
      end_date: payload.end_date,
      hashtag: payload.hashtag,
      and_keywords: payload.and_keywords,
      or_keywords: payload.or_keywords,
      not_keywords: payload.not_keywords,
      alert_post_count: payload.alert_post_count
    }

    const response = await API.put<IPutInstagramHashtagResponse>(
      `instagram_hashtags/${payload.hashtag_id}`,
      params
    )

    if (response.data) {
      await context.dispatch('fetchInstagramHashtags')
    }

    return response.data
  },

  /**
   * クチコミ設定のステータス更新
   */
  async updateInstagramHashtagStatus(
    context,
    payload: {
      hashtag_id: number
      is_active: boolean
    }
  ) {
    const params: IPutInstagramHashtagStatusParams = {
      project_id: context.rootState.project.id,
      is_active: payload.is_active
    }

    const response = await API.put<IPutInstagramHashtagStatusResponse>(
      `instagram_hashtags/${payload.hashtag_id}/status`,
      params
    )

    if (response.data) {
      await context.dispatch('fetchInstagramHashtags')
    }

    return response.data
  },

  /**
   * クチコミ設定の削除
   */
  async deleteInstagramHashtag(context, payload: number) {
    const params: IDeleteInstagramHashtagParams = {
      project_id: context.rootState.project.id
    }

    const response = await API.delete<IDeleteInstagramHashtagResponse>(
      `instagram_hashtags/${payload}`,
      {
        params
      }
    )

    if (response.data) {
      await context.dispatch('fetchInstagramHashtags')

      if (context.state.hashtag_ids.includes(payload)) {
        const hashtag_ids = context.state.hashtag_ids.filter(v => v !== payload)

        await context.dispatch('changeHashtagIds', hashtag_ids)
      }
    }

    return response.data
  },

  /**
   * 投稿のタグ設定を変更
   */
  async updatePostCategory(context, payload: IPostInstagramHashtagPostCategoriesParams) {
    const params: IPostInstagramHashtagPostCategoriesParams = {
      hashtag_id: payload.hashtag_id,
      es_in_hashtag_post_id: payload.es_in_hashtag_post_id,
      category_ids: payload.category_ids
    }

    const response = await API.post<IPostInstagramHashtagPostCategoriesResponse>(
      'instagram_hashtags/post_categories',
      params
    )

    if (response.data.data) {
      await context.dispatch('fetchHashtagPosts')
    }

    return response.data
  },

  /**
   * クチコミ設定の順序を変更
   */
  async changeOrderInstagramHashtags(context, payload: number[]) {
    const params: IPutInstagramHashtagsChangeOrderNoParams = {
      project_id: context.rootState.project.id,
      hashtag_ids: payload
    }

    const response = await API.put<IPutInstagramHashtagsChangeOrderNoResponse>(
      'instagram_hashtags/change_order_no',
      params
    )

    await context.dispatch('fetchInstagramHashtags')

    await context.dispatch('changeHashtagIds', context.state.hashtag_ids)

    return response.data
  },

  /**
   * ハッシュタグID一覧の変更
   */
  async changeHashtagIds(context, payload: number[]) {
    // ソート順に調整
    const hashtag_ids = context.state.api_settings
      .filter(v => payload.includes(v.id))
      .map(v => v.id)

    context.commit('SET_HASHTAG_IDS', hashtag_ids)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)
    context.commit('SET_HEATMAP_HASHTAG_ID', '')
    context.commit('SET_WORD_CLOUD_HASHTAG_ID', '')
    context.commit('SET_WORDS_HASHTAG_ID', '')
    context.commit('SET_HASHTAGS_HASHTAG_ID', '')

    if (!hashtag_ids.length) {
      context.commit('SET_API_SUMMARY', [])
      context.commit('SET_API_POST_COUNT', [])
      context.commit('SET_API_HEATMAP', [])
      context.commit('SET_API_WORD_CLOUD', [])
      context.commit('SET_API_WORDS', [])
      context.commit('SET_API_HASHTAGS', [])
      context.commit('SET_API_RANKING', [])
      context.commit('SET_API_POSTS', { posts: [], total: 0 })

      return
    }

    await Promise.all([
      context.dispatch('fetchHashtagSummary'),
      context.dispatch('fetchHashtagPostCount'),
      context.dispatch('fetchHashtagSentimentCount'),
      context.dispatch('fetchHashtagHeatmap'),
      context.dispatch('fetchHashtagWordCloud'),
      context.dispatch('fetchHashtagWords'),
      context.dispatch('fetchHashtagHashtags'),
      context.dispatch('fetchHashtagRanking'),
      context.dispatch('fetchHashtagPosts')
    ])
  },

  /**
   * 日付範囲の変更
   */
  async changeDateRange(context, payload: [string, string]) {
    context.commit('SET_DATE_RANGE', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)
    context.commit('SET_POST_COUNT_INDEX', 0)
    context.commit('SET_SENTIMENT_COUNT_INDEX', 0)

    if (!state.hashtag_ids.length) return

    await Promise.all([
      context.dispatch('fetchHashtagSummary'),
      context.dispatch('fetchHashtagPostCount'),
      context.dispatch('fetchHashtagSentimentCount'),
      context.dispatch('fetchHashtagHeatmap'),
      context.dispatch('fetchHashtagWordCloud'),
      context.dispatch('fetchHashtagWords'),
      context.dispatch('fetchHashtagHashtags'),
      context.dispatch('fetchHashtagRanking'),
      context.dispatch('fetchHashtagPosts')
    ])
  },

  /**
   * 表示するタブの変更
   */
  async changeScreenName(context, payload: ScreenName) {
    context.commit('SET_SCREEN_NAME', payload)
  },

  /**
   * サマリーの基本データのソート順序の変更
   */
  async changeSummaryDisplayOrder(context, payload: string) {
    context.commit('SET_SUMMARY_DISPLAY_ORDER', payload)
  },

  /**
   * サマリーの基本データの平均・合計の切り替え
   */
  async changeSummaryDisplayFormat(context, payload: DisplayFormat) {
    context.commit('SET_SUMMARY_DISPLAY_FORMAT', payload)
  },

  /**
   * サマリーの投稿数の集計期間の変更
   */
  async changePostCountInterval(context, payload: PostCountInterval) {
    context.commit('SET_POST_COUNT_INTERVAL', payload)

    context.commit('SET_POST_COUNT_INDEX', 0)

    await context.dispatch('fetchHashtagPostCount')
  },

  /**
   * サマリーの投稿数のページングの変更
   */
  async changePostCountIndex(context, payload: number) {
    context.commit('SET_POST_COUNT_INDEX', payload)

    await context.dispatch('fetchHashtagPostCount')
  },

  /**
   * サマリーのセンチメントの集計期間の変更
   */
  async changeSentimentCountInterval(context, payload: SentimenCountInterval) {
    context.commit('SET_SENTIMENT_COUNT_INTERVAL', payload)

    context.commit('SET_SENTIMENT_COUNT_INDEX', 0)

    await context.dispatch('fetchHashtagSentimentCount')
  },

  /**
   * サマリーのセンチメントのページングの変更
   */
  async changeSentimentCountIndex(context, payload: number) {
    context.commit('SET_SENTIMENT_COUNT_INDEX', payload)

    await context.dispatch('fetchHashtagSentimentCount')
  },

  /**
   * ヒートマップで指定しているハッシュタグの変更
   */
  async changeHeatmapHashtagId(context, payload: number | '') {
    context.commit('SET_HEATMAP_HASHTAG_ID', payload)

    await context.dispatch('fetchHashtagHeatmap')
  },

  /**
   * ヒートマップで指定している指標の変更
   */
  async changeHeatmapMetric(context, payload: HeatmapMetric) {
    context.commit('SET_HEATMAP_METRIC', payload)

    await context.dispatch('fetchHashtagHeatmap')
  },

  /**
   * ヒートマップの平均・合計の切り替え
   */
  async changeHeatmapDisplayFormat(context, payload: DisplayFormat) {
    context.commit('SET_HEATMAP_DISPLAY_FORMAT', payload)

    await context.dispatch('fetchHashtagHeatmap')
  },

  /**
   * ワードクラウドで指定しているハッシュタグの変更
   */
  async changeWordCloudHashtagId(context, payload: number | '') {
    context.commit('SET_WORD_CLOUD_HASHTAG_ID', payload)

    await context.dispatch('fetchHashtagWordCloud')
  },

  /**
   * ワードクラウドの件数変更
   */
  async changeWordCloudSize(context, payload: number) {
    context.commit('SET_WORD_CLOUD_SIZE', payload)

    await context.dispatch('fetchHashtagWordCloud')
  },

  /**
   * ワード一覧で指定しているハッシュタグの変更
   */
  async changeWordsHashtagId(context, payload: number | '') {
    context.commit('SET_WORDS_HASHTAG_ID', payload)

    await context.dispatch('fetchHashtagWords')
  },

  /**
   * ワード一覧の件数変更
   */
  async changeWordsSize(context, payload: number) {
    context.commit('SET_WORDS_SIZE', payload)

    await context.dispatch('fetchHashtagWords')
  },

  /**
   * ハッシュタグ一覧で指定しているハッシュタグの変更
   */
  async changeHashtagsHashtagId(context, payload: number | '') {
    context.commit('SET_HASHTAGS_HASHTAG_ID', payload)

    await context.dispatch('fetchHashtagHashtags')
  },

  /**
   * ハッシュタグ一覧の件数変更
   */
  async changeHashtagsSize(context, payload: number) {
    context.commit('SET_HASHTAGS_SIZE', payload)

    await context.dispatch('fetchHashtagHashtags')
  },

  /**
   * 指定ハッシュタグの投稿の確認
   */
  async checkHashtagPosts(context, payload: string) {
    await context.dispatch('changeScreenName', 'post')
    await context.dispatch('changeSearchkeyword', payload)
  },

  /**
   * ランキングのソート変更
   */
  async changeRankingSort(context, payload: RankingSort) {
    context.commit('SET_RANKING_SORT', payload)

    await context.dispatch('fetchHashtagRanking')
  },

  /**
   * ランキングの件数変更
   */
  async changeRankingSize(context, payload: number) {
    context.commit('SET_RANKING_SIZE', payload)

    await context.dispatch('fetchHashtagRanking')
  },

  /**
   * 人気投稿フラグの変更
   */
  async changeIsTopMedia(context, payload: boolean | '') {
    context.commit('SET_IS_TOP_MEDIA', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)

    await context.dispatch('fetchHashtagPosts')
  },

  /**
   * 投稿タイプの変更
   */
  async changePostTypes(context, payload: string[]) {
    context.commit('SET_FILTER_POST_TYPE', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)

    await context.dispatch('fetchHashtagPosts')
  },

  /**
   * センチメントの変更
   */
  async changeSentiment(context, payload: string) {
    context.commit('SET_FILTER_SENTIMENT', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)

    await context.dispatch('fetchHashtagPosts')
  },

  /**
   * タグの変更
   */
  async changeCategoryIds(context, payload: number[]) {
    context.commit('SET_FILTER_CATEGORY_IDS', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)

    await context.dispatch('fetchHashtagPosts')
  },

  /**
   * 検索キーワードの変更
   */
  async changeSearchkeyword(context, payload: string) {
    context.commit('SET_FILTER_SEARCH_KEYWORD', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)

    await context.dispatch('fetchHashtagPosts')
  },

  /**
   * 投稿の表示タブの変更
   */
  async changePostDisplayTab(context, payload: PostDisplayTab) {
    context.commit('SET_POST_DISPLAY_TAB', payload)
  },

  /**
   * 投稿のソート順序の変更
   */
  async changePostDisplayOrder(context, payload: string) {
    context.commit('SET_POST_DISPLAY_ORDER', payload)

    context.commit('SET_POST_DISPLAY_OFFSET', 0)

    await context.dispatch('fetchHashtagPosts')
  },

  /**
   * 投稿の表示オフセットの変更
   */
  async changePostDisplayOffset(context, payload: number) {
    context.commit('SET_POST_DISPLAY_OFFSET', payload)

    await context.dispatch('fetchHashtagPosts')
  },

  /**
   * 投稿の表示件数の変更
   */
  async changePostDisplayCount(context, payload: number) {
    context.commit('SET_POST_DISPLAY_OFFSET', 0)
    context.commit('SET_POST_DISPLAY_COUNT', payload)

    await context.dispatch('fetchHashtagPosts')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
