import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/instagram_hashtags'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Label from '@/client/components-old/atoms/Label'
import AnalyticsList from '@/client/components-old/molecules/AnalyticsList'
import CategoryList from '@/client/components-old/molecules/CategoryList'
import ChartSingleBar from '@/client/components-old/molecules/ChartSingleBar'
import PostMedia from '@/client/components-old/molecules/PostMedia'
import { TrackingService } from '@/client/services'
import { IState as IInstagramHashtagState } from '@/client/store/modules/instagram_hashtag'
import i18n from '@/client/utils/i18n'
import { getHeaderClass, getTableSetting } from '@/client/utils/table'

const instagram_hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtagPostTable',
  components: {
    ButtonLink,
    Icon,
    Label,
    AnalyticsList,
    CategoryList,
    ChartSingleBar,
    PostMedia
  }
})
export default class InstagramHashtagPostTable extends Vue {
  @instagram_hashtag.State('api_settings') api_settings: IInstagramHashtagState['api_settings']
  @instagram_hashtag.State('api_posts') api_posts: IInstagramHashtagState['api_posts']
  @instagram_hashtag.State('post_display_order')
  post_display_order: IInstagramHashtagState['post_display_order']
  @instagram_hashtag.Action('changePostDisplayOrder') changePostDisplayOrder

  @Prop({ type: Number, default: null })
  height: number

  get display_data() {
    const chart_color = ['#1c84c6', '#ed5565']

    const max_reaction_count =
      this.api_posts.posts.length > 0
        ? this.api_posts.posts.map(v => v.reaction_count).reduce((v, c) => Math.max(v, c))
        : 0

    return this.api_posts.posts.map(post => {
      const hashtag = this.api_settings.find(setting => setting.id === post.hashtag_id)
      const like_count = post.is_like_hidden ? '-' : post.like_count

      const reactions = {
        chart: {
          series: [like_count, post.comment_count],
          colors: chart_color,
          display_total: true,
          total: post.reaction_count,
          max: max_reaction_count
        },
        list: {
          title: constants.REACTION_COUNT.text,
          options: [
            {
              key: constants.LIKE_COUNT.text,
              value: like_count,
              point: { type: 'color', value: chart_color[0] }
            },
            {
              key: constants.COMMENT_COUNT.text,
              value: post.comment_count,
              point: { type: 'color', value: chart_color[1] }
            }
          ]
        }
      }

      return {
        hashtag_id: post.hashtag_id,
        hashtag_name: hashtag.name,
        es_in_hashtag_post_id: post._id,
        post_id: post.post_id,
        post_url: post.post_url,
        media_src: post.thumbnail_url,
        media_count: post.media_count,
        type: post.post_type,
        sentiment: post.sentiment,
        datetime: post.timestamp,
        message: post.message,
        categories: post.post_categories.map(v => v.name),
        category_ids: post.post_categories.map(v => v.id),
        reactions,
        reaction_count: post.reaction_count,
        like_count: like_count,
        comment_count: post.comment_count,
        is_top_media: !!post.is_top_media,
        timestamp: post.timestamp
      }
    })
  }

  get table_columns() {
    return constants.POST_TABLE_COLUMNS
  }

  get sort_label() {
    return constants.POST_SORT_LABELS
  }

  get post_metrics() {
    return constants.POST_METRICS
  }

  /**
   * 指標の表示名を取得
   */
  getMetricName(val: string): string {
    const metric = this.post_metrics.find(v => v.value === val)

    return metric ? i18n.t(metric.text) : ''
  }

  /**
   * テーブルの順序を変更
   */
  async changeSortTable(val: { property: string }) {
    if (!this.sort_label.includes(val.property)) {
      return
    }

    const setting = getTableSetting(val.property, this.post_display_order)

    await this.changePostDisplayOrder(setting.sort)

    TrackingService.sendEvent(`sort:クチコミ(IG)>投稿|テーブル:${val.property}`)
  }

  /**
   * ソートによってテーブルのスタイル追加
   */
  addSortStyle(val: { column: { property: string } }) {
    let sort_metric: string = this.post_display_order
    let sort_order: 'descending' | 'ascending' = 'ascending'

    if (this.post_display_order.slice(0, 1) === '-') {
      sort_metric = this.post_display_order.slice(1)
      sort_order = 'descending'
    }

    return getHeaderClass(this.sort_label, val.column.property, sort_metric, sort_order)
  }

  /**
   * 投稿URLをクリック
   */
  openPostUrl(post_url: string) {
    window.open(post_url, '_blank')
  }

  @Emit('click-category-setting')
  clickCategorySetting(post: any) {
    return {
      hashtag_id: post.hashtag_id,
      es_in_hashtag_post_id: post.es_in_hashtag_post_id,
      category_ids: post.category_ids
    }
  }

  @Emit('open-embed')
  openEmbed(sns_link: string) {
    return { sns_link }
  }
}
