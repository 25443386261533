
import { extractHashtags } from 'instagram-pattern'
import moment from 'moment-timezone'
import twttr from 'twitter-text'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import {
  HelpLink,
  MediaPreviewDialog,
  PreviewInstagramStory,
  PreviewTikTok
} from '@/client/components/molecules'
import { PostTagsSnsPostSettingDialog } from '@/client/components/organisms/Tags'
import Button from '@/client/components-old/atoms/Button'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import ApproveFlow from '@/client/components-old/molecules/ApproveFlow'
import MessagePostStatus from '@/client/components-old/molecules/MessagePostStatus'
import PreviewFacebook from '@/client/components-old/molecules/PreviewFacebook'
import PreviewInstagram from '@/client/components-old/molecules/PreviewInstagram'
import PreviewTwitter from '@/client/components-old/molecules/PreviewTwitter'
import SwitchSns from '@/client/components-old/molecules/SwitchSns'
import TextareaLineNumber from '@/client/components-old/molecules/TextareaLineNumber'
import PostDetailApprovalForm from '@/client/components-old/organisms/PostDetailApprovalForm'
import PostDetailInstagramPublish from '@/client/components-old/organisms/PostDetailInstagramPublish'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import {
  IGetter as IPostCreateGetter,
  IState as IPostCreateState
} from '@/client/store/modules/post_create'
import { IState } from '@/client/store/modules/post_management'
import API from '@/client/utils/api'
import {
  IGetPostInstagramGalleriesParams,
  IGetPostInstagramGalleriesResponse,
  InstagramGallery,
  IPost,
  IPostCategory
} from '@/client/utils/api/posts'
import {
  IGetTwitterCardsParams,
  IGetTwitterCardsResponse,
  ITwitterCard
} from '@/client/utils/api/twitter_cards'
import { getSnsName } from '@/client/utils/character'
import i18n from '@/client/utils/i18n'
import { SnsMessageType, SnsType } from '@/common/types'

type TDeviceType = 'app' | 'web' | 'gallery' | 'text'

const accounts = namespace('accounts')
const post_create = namespace('post_create')
const post_management = namespace('post_management')
const notification = namespace('notification')

@Component({
  name: 'PostDetailPreview',
  components: {
    PostTagsSnsPostSettingDialog,
    ButtonGroup,
    HelpLink,
    Flex,
    Icon,
    Message,
    SwitchButton,
    Tooltip,
    Button,
    Scroll,
    Dropdown,
    ApproveFlow,
    MediaPreviewDialog,
    PreviewFacebook,
    PreviewInstagram,
    PreviewInstagramStory,
    PreviewTikTok,
    PreviewTwitter,
    SwitchSns,
    TextareaLineNumber,
    Account,
    MessagePostStatus,
    PostDetailApprovalForm,
    PostDetailInstagramPublish
  }
})
export default class PostDetailPreview extends Vue {
  @State('user') user!: IRootState['user']
  @accounts.Getter('post') role_post!: IAccountsGetter['post']
  @post_create.State('instagram_post_type')
  instagram_post_type!: IPostCreateState['instagram_post_type']
  @post_create.State('page_type') page_type!: IPostCreateState['page_type']
  @post_create.State('api_twitter_quote_posts')
  api_twitter_quote_posts!: IPostCreateState['api_twitter_quote_posts']
  @post_create.State('api_twitter_reply_posts')
  api_twitter_reply_posts!: IPostCreateState['api_twitter_reply_posts']
  @post_create.State('twitter_quote_target_tw_post_id')
  twitter_quote_target_tw_post_id!: IPostCreateState['twitter_quote_target_tw_post_id']
  @post_create.State('twitter_reply_target_tw_post_id')
  twitter_reply_target_tw_post_id!: IPostCreateState['twitter_reply_target_tw_post_id']
  @post_create.State('twitter_quote_target_tweet')
  twitter_quote_target_tweet!: IPostCreateState['twitter_quote_target_tweet']
  @post_create.State('twitter_reply_target_tweet')
  twitter_reply_target_tweet!: IPostCreateState['twitter_reply_target_tweet']
  @post_create.Getter('is_twitter_quote_post_unpublish')
  is_twitter_quote_post_unpublish!: IPostCreateGetter['is_twitter_quote_post_unpublish']
  @post_create.Getter('is_twitter_reply_post_unpublish')
  is_twitter_reply_post_unpublish!: IPostCreateGetter['is_twitter_reply_post_unpublish']
  @post_management.State('api_post_setting') api_post_setting!: IState['api_post_setting']
  @post_management.State('api_post_keywords') api_post_keywords!: IState['api_post_keywords']
  @post_management.State('dialog_post_mode') dialog_post_mode!: IState['dialog_post_mode']
  @post_management.Action('cancelScheduledPost') cancelScheduledPost!: any
  @post_management.Action('cancelApprove') cancelApprove!: any
  @post_management.Action('checkPostStatus') checkPostStatus!: any
  @post_management.Action('deletePost') deletePost!: any
  @post_management.Action('updateCategories') updateCategories!: any
  @post_management.Action('updatePostDialog') updatePostDialog!: any
  @post_management.Action('closePostDetailDialog') closePostDetailDialog!: any
  @notification.Action('showNotification') showNotification!: any

  @Prop({ type: Object, default: null })
  post!: IPost | null

  @Prop({ type: String, default: 'master' })
  snsType!: SnsMessageType

  sns_type: SnsMessageType = 'master'
  device_type: TDeviceType = 'app'
  twitter_card: ITwitterCard | null = null
  twitter_card_cache = ''
  instagram_galleries: InstagramGallery[] = []
  readmore_facebook = false
  readmore_instagram = false
  readmore_tiktok = true // ? TikTokの場合のみ省略表示がデフォルトでオンにする
  is_loading = false

  $refs!: {
    PostTagsSnsPostSettingDialog: any
    MediaPreviewDialog: any
  }

  get device_options() {
    const options = [{ value: 'app', icon: 'phone-iphone' }]

    if (this.is_instagram_story || this.is_tiktok) return options

    if (this.$mq !== 'sm') {
      options.push({ value: 'web', icon: 'desktop-mac' })
    }

    if (this.sns_type === 'instagram') {
      options.push({ value: 'gallery', icon: 'grid' })
    }

    if (
      ((this.dialog_post_mode === 'preview' || this.dialog_post_mode === 'approval') &&
        this.sns_type !== 'instagram') ||
      ((this.dialog_post_mode === 'preview' || this.dialog_post_mode === 'approval') &&
        this.sns_type === 'instagram' &&
        this.$mq !== 'sm') ||
      (this.dialog_post_mode === 'release_procedure' &&
        this.sns_type === 'instagram' &&
        this.$mq !== 'sm')
    ) {
      options.push({ value: 'text', icon: 'format-text' })
    }

    return options
  }

  get dropdown_options() {
    const options = []

    if (
      this.post?.status === 'approval' &&
      this.role !== 'no_authority' &&
      this.post?.post_approval_flow?.current_step === 1 &&
      (this.role === 'editor' || !this.is_approver)
    ) {
      options.push({
        value: 'approve_cancel',
        text: i18n.t('申請取消'),
        icon: 'assignment-reject'
      })
    }

    if (this.is_edit) {
      options.push({
        value: 'edit',
        text: i18n.t('投稿を編集'),
        icon: 'new-message'
      })
    }

    if (this.post?.status === 'scheduled' && !this.is_disable_cancel_schedule) {
      options.push({
        value: 'post_scheduled_canceled',
        text: i18n.t('予約取消'),
        icon: 'alarm-off'
      })
    }

    if (!this.is_published && !this.is_failure) {
      options.push({
        value: 'category_setting',
        text: i18n.t('タグ設定'),
        icon: 'local-offer'
      })
    }

    options.push({
      value: 'copy',
      text: i18n.t('複製'),
      icon: 'my-library-books'
    })

    if (this.role === 'admin' || (this.post?.status === 'draft' && this.role === 'editor')) {
      options.push({
        value: 'delete',
        text: i18n.t('削除'),
        icon: 'delete'
      })
    }

    return options
  }

  get highlight_options() {
    if (!this.api_post_setting) {
      return {
        check_double_byte_alphanumeric: false,
        check_double_byte_symbol: false,
        check_kana: false,
        check_punctuation: false,
        check_space: false
      }
    }

    return {
      check_double_byte_alphanumeric: this.api_post_setting.check_2byte1,
      check_double_byte_symbol: this.api_post_setting.check_2byte2,
      check_kana: this.api_post_setting.check_kana,
      check_punctuation: this.api_post_setting.check_punctuation,
      check_space: this.api_post_setting.check_space
    }
  }

  get highlight_words() {
    return this.api_post_keywords
      .filter(keyword => keyword.is_valid)
      .map(keyword => keyword.keyword)
      .filter(word => word.trim())
  }

  get is_sm() {
    return this.$mq === 'sm'
  }

  get is_header() {
    if (!this.post || !this.post.post_persons) return false

    const is_data =
      this.post?.post_persons.length > 0 ||
      Boolean(this.post?.submit_user) ||
      Boolean(this.post?.create_user) ||
      Boolean(this.post?.post_approval_flow)

    return this.dialog_post_mode === 'preview' && is_data
  }

  get is_header_users() {
    return this.dialog_post_mode !== 'create'
  }

  get is_header_single() {
    const is_mode =
      (this.dialog_post_mode === 'create' && this.page_type === 'edit') ||
      (this.dialog_post_mode === 'preview' && this.$mq === 'sm')

    return is_mode && Boolean(this.post?.post_approval_flow)
  }

  get is_approval() {
    return this.dialog_post_mode === 'approval'
  }

  get is_release_procedure() {
    return this.dialog_post_mode === 'release_procedure'
  }

  get is_dropdown() {
    return this.dialog_post_mode !== 'create' && this.$mq === 'sm'
  }

  get is_info_button() {
    return (
      (this.dialog_post_mode === 'preview' ||
        this.dialog_post_mode === 'approval' ||
        this.dialog_post_mode === 'release_procedure') &&
      this.$mq !== 'sm'
    )
  }

  get is_preview_status() {
    return this.dialog_post_mode === 'preview' && !!this.post
  }

  get is_preview_text() {
    return (
      (this.dialog_post_mode === 'preview' ||
        this.dialog_post_mode === 'approval' ||
        this.dialog_post_mode === 'release_procedure') &&
      this.device_type === 'text'
    )
  }

  get is_master_warning() {
    return this.sns_type === 'master'
  }

  get is_preview_media_warning() {
    return (
      this.sns_type !== 'master' &&
      this.post &&
      this.post.post_images.length + this.post.post_videos.length > 0
    )
  }

  get is_instagram_danger() {
    return this.is_instagram && !this.post?.is_auto_publish && this.dialog_post_mode === 'create'
  }

  get is_instagram_hashtag_danger() {
    return this.is_instagram_post && this.hashtag_error
  }

  get is_no_text() {
    return !this.post?.message
  }

  get is_instagram_gallery_warning() {
    return this.is_instagram_post && !this.post?.action_datetime && this.device_type === 'gallery'
  }

  get is_facebook() {
    return this.sns_type === 'facebook'
  }

  get is_twitter() {
    return this.sns_type === 'twitter'
  }

  get is_instagram() {
    return this.sns_type === 'instagram'
  }

  get is_tiktok() {
    return this.sns_type === 'tiktok'
  }

  get is_instagram_post() {
    if (!this.is_instagram) return false

    // ? 投稿が保存済みの場合、投稿の内容で判定する
    if (this.post && !!this.post.id) return this.post.in_posts.length

    return this.instagram_post_type === 'post'
  }

  get is_instagram_story() {
    if (!this.is_instagram) return false

    // ? 投稿が保存済みの場合、投稿の内容で判定する
    if (this.post && !!this.post.id) return this.post.in_stories.length

    return this.instagram_post_type === 'story'
  }

  get is_text_post() {
    return this.is_facebook || this.is_twitter || this.is_instagram_post || this.is_tiktok
  }

  get is_twitter_quote_target_tw_post() {
    let post_status = null

    if (
      this.post !== null &&
      this.post.tw_posts[0] &&
      this.api_twitter_quote_posts.length &&
      this.twitter_quote_target_tw_post_id
    ) {
      const quote_post = this.api_twitter_quote_posts.find(
        v => v.tw_post_id === this.twitter_quote_target_tw_post_id
      )

      if (quote_post) {
        post_status = quote_post.status
      }
    }

    return (
      this.is_twitter &&
      post_status !== null &&
      post_status !== 'published' &&
      typeof post_status !== 'undefined'
    )
  }

  get is_twitter_reply_target_tw_post() {
    let post_status = null

    if (
      this.post !== null &&
      this.post.tw_posts[0] &&
      this.api_twitter_reply_posts.length &&
      this.twitter_reply_target_tw_post_id
    ) {
      const twitter_reply_post = this.api_twitter_reply_posts.find(
        v => v.tw_post_id === this.twitter_reply_target_tw_post_id
      )

      if (twitter_reply_post) {
        post_status = twitter_reply_post.status
      }
    }

    return (
      this.is_twitter &&
      post_status !== null &&
      post_status !== 'published' &&
      typeof post_status !== 'undefined'
    )
  }

  get show_facebook() {
    return this.post && this.post.fb_posts.length > 0
  }

  get show_twitter() {
    return this.post && this.post.tw_posts.length > 0
  }

  get show_instagram() {
    return this.post && (this.post.in_posts.length > 0 || this.post.in_stories.length > 0)
  }

  get show_tiktok() {
    return this.post && this.post.tt_posts.length > 0
  }

  get is_readmore() {
    return this.is_readmore_facebook || this.is_readmore_instagram || this.is_readmore_tiktok
  }

  get is_readmore_facebook() {
    return this.is_facebook && this.device_type !== 'text'
  }

  get is_readmore_instagram() {
    return this.is_instagram_post && this.device_type !== 'text' && this.device_type !== 'gallery'
  }

  get is_readmore_tiktok() {
    return this.is_tiktok && this.device_type !== 'text'
  }

  get word_count() {
    let message = ''

    if (this.sns_type === 'facebook' && this.post && this.post.fb_posts[0]) {
      message = this.post.fb_posts[0].message || ''
    }

    if (this.sns_type === 'twitter' && this.post && this.post.tw_posts[0]) {
      message = this.post.tw_posts[0].message || ''
    }

    if (this.sns_type === 'instagram' && this.post && this.instagram_post_type === 'post') {
      message = this.post.in_posts[0].message || ''
    }

    if (this.sns_type === 'tiktok' && this.post && this.post.tt_posts[0]) {
      message = this.post.tt_posts[0].message || ''
    }

    message = message.trim()

    switch (this.sns_type) {
      case 'twitter':
        return twttr.parseTweet(message).weightedLength
      default:
        return message.length
    }
  }

  get word_error(): boolean {
    switch (this.sns_type) {
      case 'twitter':
        return this.word_count > 280

      case 'instagram':
        return this.word_count > 2200

      case 'tiktok':
        return this.word_count > 2200

      default:
        return false
    }
  }

  // TODO tiktokのハッシュタグの処理を実装する
  get hashtags(): string[] {
    let message = ''

    if (this.sns_type === 'facebook' && this.post && this.post.fb_posts[0]) {
      message = this.post.fb_posts[0].message || ''
    }

    if (this.sns_type === 'twitter' && this.post && this.post.tw_posts[0]) {
      message = this.post.tw_posts[0].message || ''
    }

    if (this.sns_type === 'instagram' && this.post && this.post.in_posts[0]) {
      message = this.post.in_posts[0].message || ''

      if (this.post.in_posts[0].first_comment_message) {
        message = `${message} ${this.post.in_posts[0].first_comment_message}`
      }
    }

    return this.sns_type === 'instagram' ? extractHashtags(message) : twttr.extractHashtags(message)
  }

  get hashtag_all_count(): number {
    return this.hashtags.length
  }

  get hashtag_type_count(): number {
    return [...new Set(this.hashtags)].length
  }

  get hashtag_error(): boolean {
    if (this.sns_type !== 'instagram') return false

    return this.hashtag_type_count > 30
  }

  get value_message() {
    switch (this.sns_type) {
      case 'facebook':
        return this.post?.fb_posts[0] ? this.post?.fb_posts[0].message : ''
      case 'twitter':
        return this.post?.tw_posts[0] ? this.post?.tw_posts[0].message : ''
      case 'instagram':
        return this.post?.in_posts[0] ? this.post?.in_posts[0].message : ''
      case 'tiktok':
        return this.post?.tt_posts[0] ? this.post?.tt_posts[0].message : ''
      default:
        return this.post?.message || ''
    }
  }

  get options_facebook() {
    const option: any = {
      screen_name: null,
      profile_image_url: null,
      release_date: null,
      type: 'status', // status | photo | link | video
      message: '',
      image_urls: [],
      video_url: null,
      video_thumbnail_url: null,
      link_url: null,
      link_card: false,
      links: []
    }

    if (this.post === null) return option

    if (this.post.fb_posts.length) {
      const [fb_post] = this.post.fb_posts

      option.release_date = fb_post.publish_datetime || this.post.action_datetime
      option.screen_name = fb_post.account.name
      option.profile_image_url = fb_post.account.image_url
      option.message = fb_post.message ? fb_post.message.trim() : ''
    }

    if (this.post.post_images.length) {
      const post_images = this.post.post_images.filter(v => v.sequence_no <= 10)

      option.type = 'photo'
      option.image_urls = post_images.map(v => v.image_url)
    }

    if (this.post.post_videos.length) {
      const post_video = this.post.post_videos.find(v => v.sequence_no === 1)

      option.type = 'video'
      option.video_url = post_video ? post_video.video_url : null
      option.video_thumbnail_url = post_video ? post_video.video_thumbnail : null
    }

    if (this.post.post_links.length) {
      const [post_link] = this.post.post_links

      option.type = 'link'
      option.link_url = post_link.url
      option.link_card = post_link.end_card
      option.links = this.post.post_links.map(link => ({
        call_to_action: link.call_to_action,
        description: link.description,
        domain: link.domain,
        image: link.image_url,
        title: link.title,
        url: link.url
      }))
    }

    return option
  }

  get options_twitter() {
    const option: any = {
      screen_name: null,
      user_name: null,
      profile_image_url: null,
      release_date: null,
      type: 'text', // text | photo | link | video | animation-gif
      message: '',
      image_urls: [],
      video_url: null,
      link_url: null,
      link: null,
      twitter_quote: null,
      twitter_reply: null
    }

    if (this.post === null) return option

    if (this.post.tw_posts.length) {
      const [tw_post] = this.post.tw_posts

      option.release_date = tw_post.publish_datetime || this.post.action_datetime
      option.screen_name = tw_post.account.name
      option.user_name = tw_post.account.username
      option.profile_image_url = tw_post.account.image_url
      option.message = tw_post.message ? tw_post.message.trim() : ''
      option.twitter_quote = tw_post.twitter_quote
      option.twitter_reply = tw_post.twitter_reply

      if (this.dialog_post_mode === 'create') {
        option.twitter_quote = { target_tweet: this.twitter_quote_target_tweet }
        option.twitter_reply = { target_tweet: this.twitter_reply_target_tweet }

        if (this.api_twitter_quote_posts.length && this.twitter_quote_target_tw_post_id) {
          const twitter_quote_post = this.api_twitter_quote_posts.find(
            v => v.tw_post_id === this.twitter_quote_target_tw_post_id
          )

          option.twitter_quote = { target_tw_post: twitter_quote_post }
        }

        if (this.api_twitter_reply_posts.length && this.twitter_reply_target_tw_post_id) {
          const twitter_reply_post = this.api_twitter_reply_posts.find(
            v => v.tw_post_id === this.twitter_reply_target_tw_post_id
          )

          option.twitter_reply = { target_tw_post: twitter_reply_post }
        }
      }
    }

    if (this.twitter_card && this.twitter_card.url) {
      option.type = 'link'
      option.link_url = this.twitter_card.url
      option.link = this.twitter_card
    }

    if (this.post.post_images.length) {
      const post_images = this.post.post_images.filter(v => v.sequence_no <= 4)

      option.type = 'photo'
      option.link_url = null
      option.link = null
      option.image_urls = post_images.map(v => v.image_url)

      if (post_images.some(v => v.is_animation_gif)) {
        option.type = 'animation-gif'
      }
    }

    if (this.post.post_videos.length) {
      const post_video = this.post.post_videos.find(v => v.sequence_no === 1)

      option.type = 'video'
      option.video_url = post_video ? post_video.video_url : null
      option.link_url = null
      option.link = null
    }

    return option
  }

  get options_instagram_post() {
    const option: any = {
      screen_name: null,
      user_name: null,
      profile_image_url: null,
      release_date: null,
      message: '',
      first_comment_message: null,
      image_urls: [],
      video_urls: [],
      galleries: this.instagram_galleries
    }

    if (this.post === null) return option

    if (this.post.in_posts.length) {
      const [in_post] = this.post.in_posts

      option.release_date = in_post.publish_datetime || this.post.action_datetime
      option.screen_name = in_post.account.name
      option.user_name = in_post.account.username
      option.profile_image_url = in_post.account.image_url

      const message = in_post.message || this.post.message
      option.message = message ? message.trim() : ''

      option.first_comment_message = in_post.first_comment_message
        ? in_post.first_comment_message.trim()
        : ''
    }

    if (this.post.post_images.length) {
      const post_images = this.post.post_images.filter(v => v.sequence_no <= 10)

      option.image_urls = post_images.map(v => ({
        sequence_no: v.sequence_no,
        media_url: v.image_url
      }))
    }

    if (this.post.post_videos.length) {
      const post_videos = this.post.post_videos.filter(v => v.sequence_no <= 10)

      option.video_urls = post_videos.map(v => ({
        sequence_no: v.sequence_no,
        media_url: v.video_url,
        media_thumbnail_url: v.video_thumbnail
      }))
    }

    return option
  }

  get options_instagram_story() {
    const option: {
      username?: string
      profile_image_url?: string
      release_date?: string | null
      image_url?: string
      video_url?: string
    } = {}

    if (this.post === null) return option

    if (this.post.in_stories.length) {
      const [in_story] = this.post.in_stories
      const post_image = this.post.post_images.find(v => v.sequence_no === 1)
      const post_video = this.post.post_videos.find(v => v.sequence_no === 1)

      option.release_date = in_story?.publish_datetime || this.post.action_datetime
      if (in_story?.account?.username) option.username = in_story.account.username
      if (in_story?.account?.image_url) option.profile_image_url = in_story.account.image_url
      if (post_image) option.image_url = post_image.image_url
      if (post_video) option.video_url = post_video.video_url
    }

    return option
  }

  get options_tiktok() {
    const option: Partial<{
      display_name: string | null
      profile_image: string | null
      release_date: string | Date | null
      video_url: string | null
      caption: string | null
      is_brand_organic: boolean
      is_branded_content: boolean
    }> = {}

    if (this.post === null) return option

    if (this.post.tt_posts.length) {
      const [tt_post] = this.post.tt_posts
      const post_video = this.post.post_videos.find(v => v.sequence_no === 1)

      option.display_name = tt_post.account.name
      option.profile_image = tt_post.account.image_url
      option.release_date = tt_post.publish_datetime || this.post.action_datetime
      if (post_video) option.video_url = post_video.video_url
      option.caption = tt_post.message
      option.is_brand_organic = tt_post.is_brand_organic
      option.is_branded_content = tt_post.is_branded_content
    }

    return option
  }

  get role(): 'no_authority' | 'editor' | 'admin' {
    return this.post.role
  }

  get role_admin(): { account_id: string; sns: string }[] {
    const fb_posts = this.post.fb_posts
      ? this.post.fb_posts.map(post => ({ ...post.account, symbol: 'facebook' }))
      : []
    const tw_posts = this.post.tw_posts
      ? this.post.tw_posts.map(post => ({ ...post.account, symbol: 'twitter' }))
      : []
    const in_posts = this.post.in_posts
      ? this.post.in_posts.map(post => ({ ...post.account, symbol: 'instagram' }))
      : []
    const in_stories = this.post?.in_stories
      ? this.post.in_stories.map(post => ({ ...post.account, symbol: 'instagram' }))
      : []
    const tt_posts = this.post.tt_posts
      ? this.post.tt_posts.map(post => ({ ...post.account, symbol: 'tiktok' }))
      : []

    const post_accounts = [...fb_posts, ...tw_posts, ...in_posts, ...in_stories, ...tt_posts]

    return post_accounts
      .filter(
        account =>
          !!this.role_post.find(
            role => role.id === account.id && role.sns === account.symbol && role.role === 'admin'
          )
      )
      .map(v => {
        return { account_id: v.id, sns: v.symbol }
      })
  }

  get is_edit() {
    return (
      this.role !== 'no_authority' &&
      this.post.status !== 'published' &&
      this.post.status !== 'failure' &&
      this.post.status !== 'scheduled' &&
      (this.post.status !== 'approval' || (this.post.status === 'approval' && this.is_admin_role))
    )
  }

  get is_published() {
    return this.post.status === 'published'
  }

  get is_failure() {
    return this.post.status === 'failure'
  }

  get is_cancel_schedule() {
    return this.post.status === 'scheduled'
  }

  get is_disable_cancel_schedule() {
    if (!this.post) return false

    if (this.post.status !== 'scheduled') return false

    if (!this.post.is_auto_publish) return false

    return (
      this.role !== 'admin' ||
      !this.post.action_datetime ||
      moment().isAfter(this.post.action_datetime)
    )
  }

  get is_delete() {
    return this.role === 'admin' || (this.post.status === 'draft' && this.role === 'editor')
  }

  get public_text() {
    if (this.post.status === 'scheduled') {
      return '予約'
    }

    return '公開'
  }

  get is_show_publish_text() {
    return (
      this.post.submit_user &&
      (this.post.status === 'published' ||
        this.post.status === 'failure' ||
        this.post.status === 'scheduled')
    )
  }

  get is_admin_role() {
    return this.role === 'admin'
  }

  get is_show_approve_cancel() {
    return (
      this.role !== 'no_authority' &&
      this.post?.status === 'approval' &&
      (this.role === 'editor' || !this.is_approver)
    )
  }

  get is_approver() {
    const current_step = this.post?.post_approval_flow?.current_step ?? 0
    const steps = this.post?.project_post_approval_flow?.project_post_approval_steps ?? []

    return steps.some(v => v.step === current_step && v.user_id === this.user.id)
  }

  get is_approve_cancel_disabled() {
    const current_step = this.post?.post_approval_flow?.current_step ?? 0

    return current_step > 1
  }

  @Watch('post', { immediate: true, deep: true })
  async watchPost() {
    this.readmore_facebook = false
    this.readmore_instagram = false
    this.readmore_tiktok = true // ? TikTokの場合のみ省略表示がデフォルトでオンにする

    if (this.sns_type === 'facebook') {
      if (this.post && !this.post.fb_posts.length) this.sns_type = 'master'
    }

    if (this.sns_type === 'twitter') {
      if (this.post && !this.post.tw_posts.length) this.sns_type = 'master'
    }

    if (this.sns_type === 'instagram') {
      if (this.post && !this.post.in_posts.length) this.sns_type = 'master'
    }

    if (this.sns_type === 'tiktok') {
      if (this.post && !this.post.tt_posts.length) this.sns_type = 'master'
    }

    if (this.sns_type === 'master') {
      if (this.post && this.post.in_posts.length) this.sns_type = 'instagram'
      if (this.post && this.post.in_stories.length) this.sns_type = 'instagram'
      if (this.post && this.post.tw_posts.length) this.sns_type = 'twitter'
      if (this.post && this.post.fb_posts.length) this.sns_type = 'facebook'
      if (this.post && this.post.tt_posts.length) this.sns_type = 'tiktok'
    }

    if (this.post && this.post.tw_posts.length) {
      await this.checkTwitterUrl(this.post.tw_posts[0].message)
    }

    if (this.post && this.post.in_posts.length) {
      await this.getInstagramGalleries(this.post.in_posts[0].account.id, this.post.action_datetime)
    }
  }

  @Watch('snsType', { immediate: true })
  watchSnsType(val: SnsMessageType) {
    switch (this.snsType) {
      case 'instagram':
      case 'twitter':
      case 'facebook':
      case 'tiktok':
        this.sns_type = val
        break
    }
  }

  @Watch('sns_type')
  watchSnsTypeLocal(val: SnsMessageType, oldVal: SnsMessageType) {
    if (val !== 'instagram' && oldVal === 'instagram' && this.device_type === 'gallery') {
      this.device_type = 'app'
    }
  }

  @Watch('instagram_post_type')
  watchInstagramPostType() {
    this.device_type = 'app'
  }

  /**
   * デバイスタイプ変更を監視
   */
  sendDeviceTypeEvent() {
    if (this.sns_type === 'facebook' && this.device_type === 'app') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>FB|スマホ')
    }
    if (this.sns_type === 'facebook' && this.device_type === 'web') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>FB|PC')
    }
    if (this.sns_type === 'facebook' && this.device_type === 'text') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>FB|テキスト')
    }
    if (this.sns_type === 'twitter' && this.device_type === 'app') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>TW|スマホ')
    }
    if (this.sns_type === 'twitter' && this.device_type === 'web') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>TW|PC')
    }
    if (this.sns_type === 'twitter' && this.device_type === 'text') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>TW|テキスト')
    }
    if (this.sns_type === 'instagram' && this.device_type === 'app') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>IG|スマホ')
    }
    if (this.sns_type === 'instagram' && this.device_type === 'web') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>IG|PC')
    }
    if (this.sns_type === 'instagram' && this.device_type === 'gallary') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>IG|グリッド')
    }
    if (this.sns_type === 'instagram' && this.device_type === 'text') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>IG|テキスト')
    }
  }

  /**
   * 省略表示イベント(Facebook)を送信
   */
  sendReadMoreFacebookEvent() {
    TrackingService.sendEvent('switch:投稿(ダイアログ)|プレビュー>FB|省略表示')
  }

  /**
   * 省略表示イベント(Instagrm)を送信
   */
  sendReadMoreInstagramEvent() {
    TrackingService.sendEvent('switch:投稿(ダイアログ)|プレビュー>IG|省略表示')
  }

  sendReadMoreTikTokEvent() {
    TrackingService.sendEvent('switch:投稿(ダイアログ)|プレビュー>TT|省略表示')
  }

  /**
   * メディアプレビューイベントを送信
   */
  sendMediaPreviewEvent(event: any) {
    if (this.sns_type === 'facebook' && this.device_type === 'app') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>FB|メディア')
    }
    if (this.sns_type === 'twitter' && this.device_type === 'app') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>TW|メディア')
    }
    if (this.sns_type === 'instagram' && this.device_type === 'app') {
      TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー>IG|メディア')
    }

    this.$refs.MediaPreviewDialog.open(event)
  }

  /**
   * 投稿内容のURLを検証
   * @param {string|null} message 投稿内容
   * @returns {Promise<void>} void
   */
  async checkTwitterUrl(message: string | null): Promise<void> {
    const urls: string[] = twttr.extractUrls(message ? message : '')

    if (urls.length) {
      const url = urls[urls.length - 1]

      if (this.twitter_card_cache !== url) {
        await this.getTwitterCards(url)
      }
    } else {
      this.twitter_card = null
      this.twitter_card_cache = ''
    }
  }

  /**
   * Twitterカードの取得
   */
  async getTwitterCards(url: string): Promise<void> {
    const params: IGetTwitterCardsParams = { url }

    this.twitter_card_cache = url

    const response = await API.get<IGetTwitterCardsResponse>('twitter_cards', { params })

    if (response.data.data) {
      this.twitter_card = response.data.data
    } else {
      this.twitter_card = null
    }
  }

  /**
   * Instagramのギャラリー情報を取得
   */
  async getInstagramGalleries(account_id: string, datetime: string | null): Promise<void> {
    this.instagram_galleries = []

    const action_datetime = moment(datetime, 'YYYY-MM-DD HH:mm', true)

    if (!datetime || !action_datetime.isValid()) return

    const query: IGetPostInstagramGalleriesParams = {
      in_account_id: account_id,
      action_datetime: datetime
    }

    const response = await API.get<IGetPostInstagramGalleriesResponse>(
      'posts/instagram_galleries',
      { params: query }
    )

    if (response.data.data) {
      this.instagram_galleries = response.data.data
    }
  }

  /**
   * 投稿削除処理
   */
  async removePost(): Promise<void> {
    TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー|削除')

    let messages: string[] = []

    if (this.post?.status === 'published') {
      if (this.post?.in_posts.length || this.post?.in_stories.length) {
        messages = [
          i18n.t('本サービス上で投稿を削除しても、[[sns]]上の投稿は削除されません。', {
            sns: getSnsName('instagram')
          })
        ]
      } else if (this.post?.tt_posts.length) {
        messages = [
          i18n.t('本サービス上で投稿を削除しても、[[sns]]上の投稿は削除されません。', {
            sns: getSnsName('tiktok')
          })
        ]
      } else {
        messages = [i18n.t('SNS上に公開した投稿も削除されます。')]
      }
    }

    messages.push(i18n.t('投稿を削除してよろしいですか？'))
    messages.push(i18n.t('この操作は取り消しできません。'))

    const confirm = window.confirm(messages.join('\n'))

    if (!confirm) return

    this.is_loading = true

    const result = await this.deletePost({ sns_post_id: this.post?.id, status: this.post?.status })

    this.is_loading = false

    if (result.error) {
      if (result.error.type === 'NOT_EXISTS') {
        this.showNotification({ type: 'error', title: '投稿はすでに削除されました。' })

        this.$emit('delete')
        return
      }

      if (result.error.type === 'PERMISSION_DENIED') {
        this.showNotification({ type: 'error', title: '投稿の権限がありません。' })

        return
      }

      if (result.error.type === 'INVALID_TWITTER_QUOTE_TARGET') {
        this.showNotification({
          type: 'error',
          title: '投稿の削除に失敗しました。',
          message:
            '本投稿が、引用元の投稿に選択されています。まずは、他投稿からの引用元の指定を解除してください。'
        })

        return
      }

      if (result.error.type === 'INVALID_TWITTER_REPLY_TARGET') {
        this.showNotification({
          type: 'error',
          title: '投稿の削除に失敗しました。',
          message:
            '本投稿が、返信先の投稿に選択されています。まずは、他投稿からの返信先の指定を解除してください。'
        })

        return
      }

      this.showNotification({ type: 'error', title: '投稿の削除に失敗しました。' })

      return
    }

    this.showNotification({ title: '投稿を削除しました。' })

    this.$emit('delete')
  }

  /**
   * 再投稿
   */
  republish(payload: { account_id: string; sns: SnsType }): void {
    this.$emit('republish', payload)
  }

  /**
   * actionを変更する
   * @param {string} value
   * @returns { Promise<void> } void
   */
  async actionDropdownMenu(value: string): Promise<void> {
    switch (value) {
      case 'copy':
        this.changePostCopy()
        break

      case 'delete':
        await this.removePost()
        break

      case 'edit':
        await this.changePostEdit()
        break

      case 'post_scheduled_canceled':
        await this.cancelPost()
        break

      case 'category_setting':
        this.openSettingCategoryDialog()
        break

      case 'approve_cancel':
        await this.cancelApprovalPost()
        break
    }
  }

  /**
   * 投稿編集に移動
   */
  async changePostEdit() {
    TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー|編集')

    const result = await this.checkPostStatus({
      sns_post_id: this.post.id,
      status: this.post.status
    })

    if (result.data && result.data.status === 'published') {
      await this.updatePostDialog()
      this.showNotification({ type: 'error', title: '該当の投稿はすでに公開されています。' })

      return
    }

    if (result.data && result.data.status === 'scheduled') {
      await this.updatePostDialog()
      this.showNotification({
        type: 'error',
        title: '該当の投稿はすでに予約されています。',
        message: '編集する場合は、先に予約取消をしてください。'
      })

      return
    }

    if (result.data && result.data.status === 'failure') {
      await this.updatePostDialog()
      this.showNotification({ type: 'error', title: '該当の投稿はすでに投稿に失敗しています。' })

      return
    }

    if (result.data && result.data.status === 'approval' && result.data.role === 'editor') {
      if (result.data.post_approval_flow.current_step === 1) {
        await this.updatePostDialog()

        this.showNotification({
          type: 'error',
          title: '該当の投稿はすでに承認申請されています。',
          message: '編集する場合は、先に申請取消をしてください。'
        })

        return
      }

      if (result.data.post_approval_flow.current_step > 1) {
        await this.updatePostDialog()

        this.showNotification({
          type: 'error',
          title: '該当の投稿はすでに承認されているため、変更できません。'
        })

        return
      }
    }

    if (result.error && result.error.type === 'NOT_EXISTS') {
      this.showNotification({ type: 'error', title: '投稿はすでに削除されました。' })

      this.$emit('delete')

      return
    }

    this.changeRoute({
      name: 'posts/detail/edit',
      params: { sns_post_id: this.post.id }
    })

    await this.closePostDetailDialog()

    return
  }

  /**
   * 予約投稿をキャンセル
   */
  async cancelPost() {
    TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー|予約取消')

    let message = ''

    message +=
      this.$options.filters.translate('予約がキャンセルされ、投稿が下書き状態となります。') + '\n'
    message += this.$options.filters.translate('この操作は取り消しできません。') + '\n'
    message += this.$options.filters.translate('よろしければOKをクリックしてください。')

    const confirm = window.confirm(message)

    if (!confirm) {
      return
    }

    const result = await this.cancelScheduledPost(this.post.id)

    if (result.data) {
      this.showNotification({ title: '投稿の予約を取り消しました。' })

      this.$emit('scheduled-cancel')

      await this.updatePostDialog()

      return
    }

    if (result.error) {
      if (result.error.type === 'POST_PUBLISHED') {
        this.showNotification({ type: 'error', title: '該当の投稿はすでに公開されています。' })

        await this.updatePostDialog()

        return
      }

      if (result.error.type === 'POST_IS_PUBLISHING') {
        this.showNotification({
          type: 'error',
          title: '該当の投稿は公開処理中のため取消できません。'
        })

        await this.updatePostDialog()

        return
      }

      if (['POST_DRAFTED', 'POST_APPROVED', 'POST_REJECTED'].includes(result.error.type)) {
        this.showNotification({ type: 'error', title: '該当の投稿はすでに予約取消されています。' })

        await this.updatePostDialog()

        return
      }

      if (result.error.type === 'NOT_EXISTS') {
        this.showNotification({ type: 'error', title: '投稿はすでに削除されました。' })

        this.$emit('delete')

        return
      }

      this.showNotification({
        type: 'error',
        title: '投稿の予約取消に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。'
      })
    }
  }

  /**
   * ダイアログを開く
   */
  openSettingCategoryDialog(): void {
    if (this.is_published || this.is_failure) return

    TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー|タグ設定')

    this.$refs.PostTagsSnsPostSettingDialog.open({
      sns_post_id: this.post.id,
      category_ids: this.post.post_categories.map(category => category.id)
    })
  }

  /**
   * 投稿の承認申請取り消し
   */
  async cancelApprovalPost() {
    let message = ''

    message +=
      this.$options.filters.translate(
        '承認申請を取り消すと、下書き状態に戻りますがよろしいですか？'
      ) + '\n'
    message += this.$options.filters.translate('この操作は取り消しできません。')

    const confirm = window.confirm(message)

    if (!confirm) {
      return
    }

    const result = await this.cancelApprove(this.post.id)

    if (result.data) {
      this.showNotification({ title: '投稿の承認申請を取り消しました。' })

      this.$emit('approve-cancel')

      await this.updatePostDialog()

      return
    }

    if (result.error) {
      if (result.error.type === 'POST_PUBLISHED') {
        this.showNotification({ type: 'error', title: '該当の投稿はすでに公開されています。' })

        await this.updatePostDialog()

        return
      }

      if (result.error.type === 'POST_DRAFTED') {
        this.showNotification({ type: 'error', title: '該当の投稿はすでに申請取消されています。' })

        await this.updatePostDialog()

        return
      }

      if (result.error.type === 'POST_FAILED') {
        this.showNotification({ type: 'error', title: '該当の投稿はすでに投稿に失敗しています。' })

        await this.updatePostDialog()

        return
      }

      if (result.error.type === 'MAX_APPROVAL_STEP_OVER') {
        this.showNotification({
          type: 'error',
          title: '該当の投稿はすでに承認されているため、申請を取り消しできません。'
        })

        await this.updatePostDialog()

        return
      }

      if (result.error.type === 'NOT_EXISTS') {
        this.showNotification({ type: 'error', title: '投稿はすでに削除されました。' })

        this.$emit('delete')

        return
      }

      if (result.error.type === 'PERMISSION_DENIED') {
        this.showNotification({ type: 'error', title: '申請取消の権限がありません。' })

        return
      }
    }

    this.showNotification({
      type: 'error',
      title: '投稿の申請取消に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。'
    })
  }

  /**
   * タグを設定
   */
  async settingCategory(payload: { categories: IPostCategory[] }): Promise<void> {
    await this.updatePostDialog()
    await this.updateCategories({ sns_post_id: this.post.id, categories: payload.categories })
  }

  /**
   * 投稿複製に移動
   */
  async changePostCopy() {
    TrackingService.sendEvent('click:投稿(ダイアログ)|プレビュー|複製')

    this.changeRoute({
      name: 'posts/detail/copy',
      params: { sns_post_id: this.post.id }
    })

    await this.closePostDetailDialog()
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }
}
