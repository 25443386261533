// ? SNSに関する型定義

/**
 * cMSで扱うSNSの種類
 */
export type SnsType = 'facebook' | 'twitter' | 'instagram' | 'tiktok'

/**
 * 投稿内容の種類
 */
export type SnsMessageType = SnsType | 'master'
