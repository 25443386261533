/**
 * Canva
 */
export class CanvaService {
  static API_KEY = 'xdWOgl1SK5tukujyaP9RvLwn'
  static CanvaButton: CanvaButtonApi | null = null

  /**
   * 初期化処理
   */
  static async init() {
    if (this.CanvaButton) return

    const result = await window.Canva.DesignButton.initialize({ apiKey: this.API_KEY })

    this.CanvaButton = result
  }

  /**
   * 新規作成ダイアログを開く
   */
  static async createDesign(options) {
    await this.init()

    this.CanvaButton.createDesign(options)
  }

  /**
   * 編集ダイアログを開く
   */
  static async editDesign(options) {
    await this.init()

    this.CanvaButton.editDesign(options)
  }
}
