import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsHashtagItem from '@/client/components-old/molecules/AnalyticsHashtagItem'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as IHashtagState } from '@/client/store/modules/instagram_hashtag'
import { IInstagramHashtag } from '@/client/utils/api/instagram_hashtags'
import i18n from '@/client/utils/i18n'

const hashtag = namespace('instagram_hashtag')
const notification = namespace('notification')

@Component({
  name: 'InstagramHashtagManagement',
  components: {
    draggable,
    Button,
    Icon,
    Message,
    Panel,
    Tooltip,
    AnalyticsHashtagItem
  }
})
export default class InstagramHashtagManagement extends Vue {
  @State('project') project: IRootState['project']
  @hashtag.State('api_settings') api_settings: IHashtagState['api_settings']
  @hashtag.Action('updateInstagramHashtagStatus') updateInstagramHashtagStatus
  @hashtag.Action('changeOrderInstagramHashtags') changeOrderInstagramHashtags
  @notification.Action('showNotification') showNotification

  hashtags: IInstagramHashtag[] = []

  get remain_count() {
    return this.project.in_hashtag_max_count - this.api_settings.length > 0
      ? this.project.in_hashtag_max_count - this.api_settings.length
      : 0
  }

  get active_count() {
    return this.api_settings.filter(v => v.is_active).length
  }

  get remain_count_active() {
    return this.project.in_hashtag_active_max_count - this.active_count
  }

  get is_no_hashtag() {
    return !this.api_settings.length
  }

  @Watch('api_settings', { immediate: true, deep: true })
  watchAPIHashtags() {
    this.hashtags = [...this.api_settings]
  }

  @Emit('add-hashtag')
  addHashtag(event: any) {
    TrackingService.sendEvent(`click:クチコミ(IG)>収集設定|KW分類の追加`)

    return event
  }

  @Emit('edit-hashtag')
  editHashtag(event: any) {
    TrackingService.sendEvent(`click:クチコミ(IG)>収集設定|編集`)

    return event
  }

  /**
   * レイアウトの変更
   */
  async changeLayout(): Promise<void> {
    const hashtag_ids = this.hashtags.map(v => v.id)

    const result = await this.changeOrderInstagramHashtags(hashtag_ids)

    if (result.data) {
      return this.showNotification({ title: 'ハッシュタグの並び順を更新しました。' })
    }

    this.showNotification({ title: 'ハッシュタグの並び順の更新に失敗しました。', type: 'error' })
  }

  /**
   * 有効フラグの更新
   */
  async updateActiveStatus(payload: { hashtag_id: number; is_active: boolean }): Promise<void> {
    TrackingService.sendEvent(`switch:クチコミ(IG)>収集設定|収集ON/OFF`)

    if (payload.is_active) {
      const message = i18n.t('過去分のデータは取得されませんがよろしいですか？')

      const confirm = window.confirm(message)

      if (!confirm) return
    }

    const result = await this.updateInstagramHashtagStatus(payload)

    if (result.data) {
      return this.showNotification({ title: '設定を保存しました。' })
    }

    if (result.error && result.error.type === 'MAX_INSTAGRAM_HASHTAG_ACTIVATE_OVER') {
      return this.showNotification({
        title: 'アクティブ登録数の上限数を超えています。',
        message: '上限数を変更したい場合は、画面右下のサポートチャットからお問い合わせください。',
        type: 'error'
      })
    }

    this.showNotification({
      title: '設定の保存に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * ハッシュタグのアクティブボタンのdisabled判定
   * アクティブ数上限まで登録済みの場合は新しくアクティブにできないように制御する
   */
  isDisabled(is_active: boolean) {
    if (is_active) return false

    return this.remain_count_active <= 0
  }
}
