import { GridItem, GridLayout } from 'vue-grid-layout'
import { Component, Vue } from 'vue-property-decorator'

import * as constants from '@/client/components-old/_constants/facebook_analytics'
import Box from '@/client/components-old/atoms/Box'
import Legend from '@/client/components-old/atoms/Legend'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import FacebookAnalyticsChartDonutHalf from '@/client/components-old/organisms/FacebookAnalyticsChartDonutHalf'
import FacebookAnalyticsChartHorizontal from '@/client/components-old/organisms/FacebookAnalyticsChartHorizontal'
import FacebookAnalyticsChartVertical from '@/client/components-old/organisms/FacebookAnalyticsChartVertical'
import FacebookAnalyticsTotal from '@/client/components-old/organisms/FacebookAnalyticsTotal'

@Component({
  name: 'FacebookAnalyticsAction',
  components: {
    GridLayout,
    GridItem,
    FacebookAnalyticsChartDonutHalf,
    FacebookAnalyticsChartHorizontal,
    FacebookAnalyticsChartVertical,
    FacebookAnalyticsTotal,
    Legend,
    Box,
    Message,
    Scroll
  }
})
export default class FacebookAnalyticsAction extends Vue {
  get legend_data() {
    return constants.DATA_TYPES.map(type => ({ text: type.text, color: type.color }))
  }

  get grid_layout() {
    return [
      {
        x: 0,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:エンゲージメント数',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_engaged_users',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 3,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:反応数',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_positive_feedback_by_type_unique_reaction',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 6,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:否定的な反応',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_negative_feedback_by_type_unique',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 9,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:エンゲージメント数',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'post_engaged_users',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:エンゲージメント率',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'post_engaged_users_rate',
          unit: 'average',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 3,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:反応数',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'reactions',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 6,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:反応率',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'reactions_rate',
          unit: 'average',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 9,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:クリック人数',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'post_consumptions_unique',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 4,
        w: 12,
        h: 4,
        i: '垂直チャート:エンゲージメント数',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_engaged_users',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 8,
        w: 12,
        h: 4,
        i: '垂直チャート:エンゲージメント数',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'post_engaged_users',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 12,
        w: 12,
        h: 4,
        i: '垂直チャート:エンゲージメント率',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'post_engaged_users_rate',
          interval: '1_day',
          unit: 'average',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 16,
        w: 12,
        h: 4,
        i: '垂直チャート:反応数',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_positive_feedback_by_type_unique_reaction',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 20,
        w: 12,
        h: 4,
        i: '垂直チャート:反応数',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'reactions',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 24,
        w: 12,
        h: 4,
        i: '垂直チャート:反応率',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'reactions_rate',
          interval: '1_day',
          unit: 'average',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 28,
        w: 12,
        h: 4,
        i: '垂直チャート:否定的な反応',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_negative_feedback_by_type_unique',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 32,
        w: 12,
        h: 4,
        i: '垂直チャート:クリック人数',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'post_consumptions_unique',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 40,
        w: 6,
        h: 4,
        i: '半円チャート:反応数の内訳',
        name: 'FacebookAnalyticsChartDonutHalf',
        options: {
          data_type: 'daily_data',
          metric: 'page_positive_feedback_by_type_unique'
        }
      },
      {
        x: 6,
        y: 36,
        w: 6,
        h: 4,
        i: '半円チャート:否定的な反応の内訳',
        name: 'FacebookAnalyticsChartDonutHalf',
        options: {
          data_type: 'daily_data',
          metric: 'page_negative_feedback_by_type_unique'
        }
      },
      {
        x: 0,
        y: 36,
        w: 6,
        h: 4,
        i: '半円チャート:いいね！数の内訳',
        name: 'FacebookAnalyticsChartDonutHalf',
        options: {
          data_type: 'post_data',
          metric: 'likes_insights'
        }
      },
      {
        x: 6,
        y: 40,
        w: 6,
        h: 4,
        i: '半円チャート:クリック人数の内訳',
        name: 'FacebookAnalyticsChartDonutHalf',
        options: {
          data_type: 'post_data',
          metric: 'post_consumptions_by_type_unique'
        }
      },
      {
        x: 0,
        y: 44,
        w: 6,
        h: 3,
        i: '水平チャート:反応数(場所別)',
        name: 'FacebookAnalyticsChartHorizontal',
        options: {
          data_type: 'post_data',
          metric: 'post_reaction_place'
        }
      }
    ]
  }
}
