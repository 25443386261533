import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import MultiSwitchesRow from '@/client/components-old/molecules/MultiSwitchesRow'
import SwitchRow from '@/client/components-old/molecules/SwitchRow'
import { IRootState } from '@/client/store/global'
import { IState as IPersonalSettingState } from '@/client/store/modules/personal_setting'
const personal_setting = namespace('personal_setting')
const notification = namespace('notification')

@Component({
  name: 'PersonalSettingNotificationList',
  components: {
    MultiSwitchesRow,
    SwitchRow
  }
})
export default class PersonalSettingNotificationList extends Vue {
  @State('project') project: IRootState['project']
  @personal_setting.State('api_notification')
  api_notification: IPersonalSettingState['api_notification']
  @personal_setting.Action('putNotification') putNotification
  @notification.Action('showNotification') showNotification

  @Prop({ type: String, default: 'all' })
  type: string

  get is_all() {
    return this.type === 'all'
  }

  get is_post() {
    return this.type === 'post'
  }

  get is_analytics() {
    return this.type === 'analytics'
  }

  get is_monitoring() {
    return this.type === 'monitoring'
  }

  get settings() {
    return {
      all: [
        {
          text: '自分宛に作業メモが追加されたとき',
          field: 'global_memo_to_me',
          value: this.api_notification ? this.api_notification.global_memo_to_me : false
        },
        {
          text: '作業メモが追加されたとき',
          field: 'global_memo',
          value: this.api_notification ? this.api_notification.global_memo : false
        },
        {
          text: '担当者に設定されたとき',
          field: 'global_person_in_charge',
          value: this.api_notification ? this.api_notification.global_person_in_charge : false
        },
        {
          text: 'SNSアカウントが追加されたとき',
          field: 'global_add_sns',
          value: this.api_notification ? this.api_notification.global_add_sns : false
        },
        {
          text: 'オペレーターを追加したとき',
          field: 'global_add_opperator',
          value: this.api_notification ? this.api_notification.global_add_opperator : false
        },
        {
          text: '請求金額が変更されたとき',
          field: 'global_payment',
          value: this.api_notification ? this.api_notification.global_payment : false
        }
      ],

      post: [
        {
          text: '公開されたとき',
          field: 'post_publish',
          value: this.api_notification ? this.api_notification.post_publish : false
        },
        {
          text: '予約されたとき',
          field: 'post_scheduled',
          value: this.api_notification ? this.api_notification.post_scheduled : false
        },
        {
          text: '下書き保存したとき',
          field: 'post_draft',
          value: this.api_notification ? this.api_notification.post_draft : false
        },
        {
          text: '公開日時24時間前の下書き投稿があるとき',
          field: 'post_draft_remind_24_hour',
          value: this.api_notification ? this.api_notification.post_draft_remind_24_hour : false
        },
        {
          text: '承認申請があったとき',
          field: 'post_approve_request',
          description: '承認フローのメンバーが通知対象となります。',
          value: this.api_notification ? this.api_notification.post_approve_request : false
        },
        {
          text: '承認されたとき',
          field: 'post_approved',
          description: '承認フローのメンバーが通知対象となります。',
          value: this.api_notification ? this.api_notification.post_approved : false
        },
        {
          text: '差し戻しされたとき',
          field: 'post_rejected',
          description: '承認申請者と承認フローのメンバーが通知対象となります。',
          value: this.api_notification ? this.api_notification.post_rejected : false
        },
        {
          text: '予約投稿に失敗したとき',
          field: 'post_publish_failed',
          value: this.api_notification ? this.api_notification.post_publish_failed : false
        }
      ],

      analytics: [
        {
          text: '分析週次レポート(毎週火曜日配信)',
          field: 'analytics_report_weekly',
          value: this.api_notification ? this.api_notification.analytics_report_weekly : false
        },
        {
          text: '分析月次レポート(毎月5日配信)',
          field: 'analytics_report_monthly',
          value: this.api_notification ? this.api_notification.analytics_report_monthly : false
        },
        {
          text: 'クチコミ分析アラート',
          field: 'analytics_keyword_hashtag_alert_post_count',
          description:
            '[クチコミ分析] で登録したキーワードの収集数に関するアラートメールを送信します。',
          value: this.api_notification
            ? this.api_notification.analytics_keyword_hashtag_alert_post_count
            : false
        },
        {
          text: 'クチコミ推移アラート',
          field: 'analytics_twitter_viral_alert_post_count',
          description:
            '[クチコミ推移] で登録したキーワードの収集数に関するアラートメールを送信します。',
          value: this.api_notification
            ? this.api_notification.analytics_twitter_viral_alert_post_count
            : false
        }
      ],

      monitoring: [
        {
          text: '既読に変更したとき',
          field: 'monitoring_read',
          value: this.api_notification ? this.api_notification.monitoring_read : false
        },
        {
          text: '保留にしたとき',
          field: 'monitoring_pending',
          value: this.api_notification ? this.api_notification.monitoring_pending : false
        },
        {
          text: '非表示にしたとき',
          field: 'monitoring_hide',
          value: this.api_notification ? this.api_notification.monitoring_hide : false
        },
        {
          text: '非表示を解除したとき',
          field: 'monitoring_unhide',
          value: this.api_notification ? this.api_notification.monitoring_unhide : false
        },
        {
          text: '削除したとき',
          field: 'monitoring_deleted',
          value: this.api_notification ? this.api_notification.monitoring_deleted : false
        },
        {
          text: '新規コメントがあったとき',
          field: 'monitoring_comment_new',
          value: this.api_notification ? this.api_notification.monitoring_comment_new : false
        },
        {
          text: '新規メッセージがあったとき',
          field: 'monitoring_message_new',
          value: this.api_notification ? this.api_notification.monitoring_message_new : false
        },
        {
          text: '新規投稿があったとき',
          field: 'monitoring_post_new',
          value: this.api_notification ? this.api_notification.monitoring_post_new : false
        },
        {
          text: 'ポジティブワード検出時',
          field: 'monitoring_keyword_positive',
          value: this.api_notification ? this.api_notification.monitoring_keyword_positive : false
        },
        {
          text: 'ネガティブワード検出時',
          field: 'monitoring_keyword_negative',
          value: this.api_notification ? this.api_notification.monitoring_keyword_negative : false
        },
        {
          text: 'その他のワード検出時',
          field: 'monitoring_keyword_other',
          value: this.api_notification ? this.api_notification.monitoring_keyword_other : false
        },
        {
          text: '標準NGワード検出時',
          field: 'monitoring_keyword_global',
          value: this.api_notification ? this.api_notification.monitoring_keyword_global : false
        },
        {
          text: 'モニタリング日報',
          field: 'monitoring_report',
          description:
            'ステータスごとの集計やコメント履歴など、モニタリングの日次報告を配信します。',
          value: this.api_notification ? this.api_notification.monitoring_report : false
        }
      ],
      instagram_post: {
        text: 'Instagram投稿の手動公開時刻が近づいたとき',
        value: [
          {
            name: 'post_publish_remind_5_minute',
            label: '5分前',
            value: this.api_notification.post_publish_remind_5_minute
          },
          {
            name: 'post_publish_remind_15_minute',
            label: '15分前',
            value: this.api_notification.post_publish_remind_15_minute
          },
          {
            name: 'post_publish_remind_30_minute',
            label: '30分前',
            value: this.api_notification.post_publish_remind_30_minute
          }
        ]
      }
    }
  }

  /**
   * 通知メール設定を変更する
   */
  async changeSetting(field: string): Promise<void> {
    const payload = {
      [field]: !this.api_notification[field]
    }

    const response = await this.putNotification(payload)

    if (response && response.data) {
      return this.showNotification({ title: '通知メール設定を更新しました。' })
    }

    this.showNotification({ title: '通知メール設定の更新に失敗しました。', type: 'error' })
  }
}
