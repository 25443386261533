import { Component, Prop, Vue } from 'vue-property-decorator'

import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'

@Component({
  name: 'CategoryList',
  components: {
    Flex,
    Icon,
    Tooltip
  }
})
export default class CategoryList extends Vue {
  @Prop({ type: Array, default: [] })
  names: string[]

  @Prop({ type: Boolean, default: false })
  isShowIcon: boolean

  get list1() {
    return this.names.slice(0, 2)
  }

  get list2() {
    if (this.names.length > 2) {
      return this.names.slice(2, this.names.length)
    }

    return []
  }
}
