import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Scroll from '@/client/components-old/atoms/Scroll'
import FacebookComparisonAccountManagement from '@/client/components-old/organisms/FacebookComparisonAccountManagement'
import PresetManagement from '@/client/components-old/organisms/PresetManagement'
import { TrackingService } from '@/client/services'
import {
  IGetter as IFacebookGetter,
  IState as IFacebookState
} from '@/client/store/modules/facebook_comparison_analytics'

const facebook = namespace('facebook_comparison_analytics')
const notification = namespace('notification')

@Component({
  name: 'FacebookComparisonAnalyticsManagement',
  components: {
    Button,
    ButtonGroup,
    Icon,
    Message,
    Panel,
    Scroll,
    FacebookComparisonAccountManagement,
    PresetManagement
  }
})
export default class FacebookComparisonAnalyticsManagement extends Vue {
  @facebook.State('management_tab_name') management_tab_name: IFacebookState['management_tab_name']
  @facebook.Getter('is_unregister_comparison')
  is_unregister_comparison: IFacebookGetter['is_unregister_comparison']
  @facebook.Action('getManagementTab') getManagementTab
  @facebook.Action('postSamplePreset') postSamplePreset
  @notification.Action('showNotification') showNotification

  get tabs() {
    return [
      { value: 'account', text: 'アカウント' },
      { value: 'preset', text: 'プリセット' }
    ]
  }

  get is_account() {
    return this.management_tab_name === 'account'
  }

  get is_preset() {
    return this.management_tab_name === 'preset'
  }

  /**
   * サンプルプリセットを追加
   */
  async addSamplePresets(): Promise<void> {
    const result = await this.postSamplePreset()

    if (result && result.data) {
      this.showNotification({ title: 'サンプルプリセットを追加しました。' })
    } else {
      this.showNotification({ title: 'サンプルプリセットの追加に失敗しました。', type: 'error' })
    }
  }

  /**
   * アカウントデータの表示形式を変更
   */
  async onGetManagementTab(payload: string) {
    const tab_name = this.tabs.find(tab => tab.value === payload)?.text
    TrackingService.sendEvent(`click:競合比較(FB)>アカウント管理|表示切替:${tab_name}`)

    await this.getManagementTab(payload)
  }
}
