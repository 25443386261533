
import { defineComponent } from 'vue'

import HelpLink from '@/client/components/molecules/HelpLink'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import PersonalSettingRoleGroup from '@/client/components-old/organisms/PersonalSettingRoleGroup'
import PersonalSettingRoleSnsAccount from '@/client/components-old/organisms/PersonalSettingRoleSnsAccount'

export default defineComponent({
  name: 'PersonalSettingRole',
  components: {
    HelpLink,
    Message,
    Scroll,
    PersonalSettingRoleGroup,
    PersonalSettingRoleSnsAccount
  }
})
