import { render, staticRenderFns } from "./PaymentContractForm.vue?vue&type=template&id=4bdb1315&scoped=true"
import script from "./PaymentContractForm.ts?vue&type=script&lang=ts&external"
export * from "./PaymentContractForm.ts?vue&type=script&lang=ts&external"
import style0 from "./PaymentContractForm.vue?vue&type=style&index=0&id=4bdb1315&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bdb1315",
  null
  
)

export default component.exports