import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/facebook_analytics'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartHorizontal from '@/client/components-old/molecules/ChartHorizontal'
import ChartMap from '@/client/components-old/molecules/ChartMap'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as IFacebookState } from '@/client/store/modules/facebook_analytics'
import csv from '@/client/utils/csv'

type TFacebookAnalyticsChartMapOptions = {
  metric:
    | 'page_fans_country'
    | 'page_fans_city'
    | 'page_impressions_by_country_unique'
    | 'page_impressions_by_city_unique'
}

const analytics = namespace('analytics')
const facebook = namespace('facebook_analytics')

@Component({
  name: 'FacebookAnalyticsChartMap',
  components: {
    AnalyticsPanel,
    ButtonGroup,
    ChartMap,
    ChartHorizontal,
    Icon,
    Tooltip
  }
})
export default class FacebookAnalyticsChartMap extends Vue {
  @State('user') user: IRootState['user']
  @facebook.State('api_accounts') daily_data: IFacebookState['api_accounts']
  @facebook.State('screen_name') screen_name: IFacebookState['screen_name']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TFacebookAnalyticsChartMapOptions

  chart_button = 'map'

  get metric() {
    const metrics = constants.MAP_CHART_DAILY_DATA_METRICS

    return metrics.find(metric => metric.value === this.options.metric)
  }

  get metric_name() {
    return this.metric ? this.metric.text : ''
  }

  get metric_tooltip() {
    return this.metric ? this.metric.tooltip : ''
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }

  get is_data() {
    switch (this.options.metric) {
      case 'page_fans_country':
      case 'page_fans_city':
        return Object.keys(this.daily_data_last).length > 0
      case 'page_impressions_by_country_unique':
      case 'page_impressions_by_city_unique':
        return Object.keys(this.daily_data_total).length > 0
      default:
        return this.daily_data.length > 0
    }
  }

  get chart_options() {
    return {
      name: this.metric_name,
      type: this.chart_type,
      lang: this.user.language,
      metric_type: 'positive',
      data: this.chart_data
    }
  }

  get chart_type() {
    switch (this.options.metric) {
      case 'page_fans_country':
      case 'page_impressions_by_country_unique':
        return 'custom/world'
      case 'page_fans_city':
      case 'page_impressions_by_city_unique':
        return 'countries/jp/jp-all'
      default:
        return ''
    }
  }

  get display_type() {
    switch (this.chart_type) {
      case 'custom/world':
        return 'chart_map_world'
      case 'countries/jp/jp-all':
        return 'chart_map_japan'
      default:
        return ''
    }
  }

  get daily_data_last() {
    const api = this.daily_data
      .filter(account => Object.keys(account[this.options.metric]).length > 0)
      .map(account => account[this.options.metric])
      .shift()

    return api ? api : {}
  }

  get daily_data_total() {
    const api = this.daily_data
      .filter(account => Object.keys(account[this.options.metric]).length > 0)
      .map(account => account[this.options.metric])

    const total = {}

    api.forEach(value => {
      Object.keys(value).forEach(key => {
        total[key] = total[key] ? total[key] + value[key] : value[key]
      })
    })

    return total
  }

  get chart_data() {
    switch (this.options.metric) {
      case 'page_fans_country':
        return this.getCountryMap(this.daily_data_last)
      case 'page_fans_city':
        return this.getJapanMap(this.daily_data_last)
      case 'page_impressions_by_country_unique':
        return this.getCountryMap(this.daily_data_total)
      case 'page_impressions_by_city_unique':
        return this.getJapanMap(this.daily_data_total)
      default:
        return []
    }
  }

  get map_names() {
    const { features } = Highcharts.maps[this.chart_type]
    const properties = features.map(f => f.properties)
    return properties
  }

  get csv_name() {
    switch (this.options.metric) {
      case 'page_fans_country':
      case 'page_impressions_by_country_unique':
        return this.$options.filters.translate('国')
      case 'page_fans_city':
      case 'page_impressions_by_city_unique':
        return this.$options.filters.translate('都道府県')
      default:
        return ''
    }
  }

  get csv_metric() {
    switch (this.options.metric) {
      case 'page_fans_country':
      case 'page_fans_city':
        return this.$options.filters.translate('ファン数')
      case 'page_impressions_by_country_unique':
      case 'page_impressions_by_city_unique':
        return this.$options.filters.translate('全体リーチ')
      default:
        return ''
    }
  }

  get graph_options() {
    const countries = ['page_fans_country', 'page_impressions_by_country_unique']

    const categories = this.chart_data.map(data => {
      const country = this.map_names.find(name => {
        if (countries.includes(this.options.metric)) {
          return name['hc-a2'] === data[0]
        }

        return name['name'] === data[0]
      })

      if (!country) {
        return data[0]
      }

      if (this.user.language === 'ja') {
        return country['jp-name']
      }

      return country['name']
    })

    const data = this.chart_data.map(data => data[1])

    return {
      display_value: true,
      display_legend: false,
      categories,
      colors: ['#1c84c6'],
      series: [
        {
          name: this.$options.filters.translate(this.metric_name),
          data
        }
      ]
    }
  }

  get chart_button_options() {
    return [
      {
        value: 'map',
        text: '地図'
      },
      {
        value: 'graph',
        text: 'グラフ'
      }
    ]
  }

  get is_map() {
    return this.chart_button === 'map'
  }

  get is_graph() {
    return this.chart_button === 'graph'
  }

  /**
   * 国のマップ配列を取得
   * @param {any} api
   * @returns {any[]} マップ配列
   */
  getCountryMap(api: any): any[][] {
    if (Object.keys(api).length === 0) return []

    const country_keys = this.map_names.map(v => v['hc-a2'])
    const country = {}

    Object.keys(api).forEach(key => {
      if (country_keys.indexOf(key) >= 0) {
        country[key] = country[key] ? country[key] + api[key] : api[key]
      }
    })

    return Object.keys(country)
      .map(key => [key, country[key]])
      .sort((a, b) => (a[1] < b[1] ? 1 : -1))
  }

  /**
   * 日本のマップ配列を取得
   * @param {any} api
   * @returns {any[]} マップ配列
   */
  getJapanMap(api: any): any[][] {
    if (Object.keys(api).length === 0) return []

    const japan_keys = this.map_names.map(v => v['name'])
    const japan = {}

    Object.keys(api).forEach(key => {
      if (japan_keys.indexOf(key) >= 0) {
        japan[key] = japan[key] ? japan[key] + api[key] : api[key]
      }
    })

    return Object.keys(japan)
      .map(key => [key, japan[key]])
      .sort((a, b) => (a[1] < b[1] ? 1 : -1))
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    const fields = [
      this.$options.filters.translate(this.csv_name),
      this.$options.filters.translate(this.csv_metric)
    ]

    const data = this.chart_data.map(d => {
      switch (this.options.metric) {
        case 'page_fans_country':
        case 'page_impressions_by_country_unique': {
          const country = this.map_names.find(v => v['hc-a2'] === d[0])

          if (this.user.language === 'ja') {
            return country ? [country['jp-name'], d[1]] : d
          } else {
            return country ? [country['name'], d[1]] : d
          }
        }

        case 'page_fans_city':
        case 'page_impressions_by_city_unique': {
          const city = this.map_names.find(v => v['name'] === d[0])

          if (this.user.language === 'ja') {
            return city ? [city['jp-name'], d[1]] : d
          } else {
            return city ? [city['name'], d[1]] : d
          }
        }

        default:
          return d
      }
    })

    const csv_data = { fields, data }

    const component_name = this.$options.filters.translate('グラフ')
    const metric_name = this.$options.filters.translate(this.metric_name)

    const csv_filename = [component_name, metric_name, this.start_date, this.end_date].join('_')

    csv.download(csv_data, csv_filename)
  }

  changeChartOptions(type: string) {
    TrackingService.sendEvent(
      `select:自社分析(FB)> ${this.screen_name} | ${this.metric_name} : ${type}`
    )
  }
}
