import { Component, Prop, Vue } from 'vue-property-decorator'

import * as utils from '@/client/components-old/_utils/monitoring'
import Message from '@/client/components-old/atoms/Message'
import PostMedia from '@/client/components-old/molecules/PostMedia'
import { TMonitoringWord } from '@/client/utils/api/monitoring_words'
import { getSnsName } from '@/client/utils/character'

@Component({
  name: 'MonitoringContent',
  components: {
    Message,
    PostMedia
  }
})
export default class MonitoringContent extends Vue {
  @Prop({ type: String, required: true })
  type: string

  @Prop({ type: String, required: true })
  sns: string

  @Prop({ type: String, default: '' })
  message: string

  @Prop({ type: String, default: '' })
  imageUrl: string

  @Prop({ type: Boolean, default: false })
  isHidden: boolean

  @Prop({ type: Boolean, default: false })
  isHiddenApiLimit: boolean

  @Prop({ type: Boolean, default: false })
  isDeleted: boolean

  @Prop({ type: Boolean, default: false })
  isEdited: boolean

  @Prop({ type: Array, default: () => [] })
  words: TMonitoringWord[]

  get convert_message() {
    return utils.convertMonitoringMessage(this.message, this.sns, this.words)
  }

  get sns_message_type() {
    if (this.isEdited) {
      return 'warning'
    }

    if (this.isDeleted) {
      return 'danger'
    }

    if (this.isHidden) {
      return 'warning'
    }

    if (this.isHiddenApiLimit) {
      return 'danger'
    }

    return 'default'
  }

  get sns_message() {
    if (
      (this.sns === 'twitter' && this.type !== 'reply') ||
      (this.sns === 'instagram' && this.type !== 'comment') ||
      (this.sns === 'facebook' && !['post', 'comment', 'reply'].includes(this.type))
    ) {
      return ''
    }

    let type = ''

    switch (this.type) {
      case 'post':
        type = '投稿'
        break
      case 'comment':
        type = 'コメント'
        break
      case 'reply':
        type = '返信'
        break
    }

    const sns = getSnsName(this.sns)

    if (this.isEdited) {
      return this.$options.filters.translate('コメント内容の変更を検出しました。')
    }

    if (this.isDeleted) {
      return this.$options.filters.translate(`この${type}は${sns}から削除されています。`)
    }

    if (this.isHidden) {
      return this.$options.filters.translate(`この${type}は${sns}から非表示にされています。`)
    }

    if (this.isHiddenApiLimit) {
      return this.$options.filters.translate(
        'API回数制限により、ブロックアカウントからの投稿の非表示に失敗しました。'
      )
    }

    return ''
  }
}
