export const KEYWORD_NAME = { value: 'keyword_name', text: '分類名' }
export const KEYWORD_ORDER_NO = { value: 'keyword_order_no', text: 'キーワード分類順' }
export const POST_COUNT = { value: 'post_count', text: '投稿数' }
export const POSITIVE_COUNT = { value: 'positive_count', text: 'ポジティブ' }
export const NEGATIVE_COUNT = { value: 'negative_count', text: 'ネガティブ' }
export const REACTION_COUNT = { value: 'reaction_count', text: '反応数' }
export const FOLLOWER_COUNT = { value: 'follower_count', text: 'フォロワー数' }
export const RETWEET_COUNT = { value: 'retweet_count', text: 'リポスト' }
export const QUOTE_COUNT = { value: 'quote_count', text: '引用' }
export const LIKE_COUNT = { value: 'like_count', text: 'いいね' }
export const REPLY_COUNT = { value: 'reply_count', text: '返信' }
export const TIMESTAMP = { value: 'timestamp', text: '公開日時' }
export const ACCOUNT_ID = { value: 'account_id', text: 'アカウントID' }
export const ACCOUNT_NAME = { value: 'account_name', text: 'アカウント名' }
export const POST_TYPE = { value: 'post_type', text: '投稿タイプ' }
export const TWEET_TYPE = { value: 'tweet_type', text: '投稿種別' }
export const MESSAGE = { value: 'message', text: '投稿内容' }
export const THUMBNAIL_URL = { value: 'thumbnail_url', text: 'サムネイルURL' }
export const POST_URL = { value: 'post_url', text: '投稿URL' }
export const POST_ID = { value: 'post_id', text: '投稿ID' }
export const SCREEN_NAME = { value: 'screen_name', text: 'スクリーンネーム' }
export const MEDIA_COUNT = { value: 'media_count', text: 'メディアの数' }
export const CATEGORY = { value: 'category', text: 'タグ' }
export const WORD_NAME = { value: 'category', text: 'ワード名' }

export const SUMMARY_METRICS = [
  KEYWORD_NAME,
  POST_COUNT,
  POSITIVE_COUNT,
  NEGATIVE_COUNT,
  REACTION_COUNT,
  FOLLOWER_COUNT,
  RETWEET_COUNT,
  QUOTE_COUNT,
  LIKE_COUNT,
  REPLY_COUNT
]

export const SUMMARY_TABLE_COLUMNS = [
  KEYWORD_NAME.value,
  POST_COUNT.value,
  POSITIVE_COUNT.value,
  NEGATIVE_COUNT.value,
  REACTION_COUNT.value,
  FOLLOWER_COUNT.value,
  RETWEET_COUNT.value,
  QUOTE_COUNT.value,
  LIKE_COUNT.value,
  REPLY_COUNT.value
]

export const POST_METRICS = [
  TIMESTAMP,
  MESSAGE,
  REACTION_COUNT,
  FOLLOWER_COUNT,
  RETWEET_COUNT,
  QUOTE_COUNT,
  LIKE_COUNT,
  REPLY_COUNT
]

export const POST_TABLE_COLUMNS = [
  REACTION_COUNT.value,
  FOLLOWER_COUNT.value,
  RETWEET_COUNT.value,
  QUOTE_COUNT.value,
  LIKE_COUNT.value,
  REPLY_COUNT.value
]

export const POST_SORT_LABELS = [
  TIMESTAMP.value,
  REACTION_COUNT.value,
  FOLLOWER_COUNT.value,
  RETWEET_COUNT.value,
  QUOTE_COUNT.value,
  LIKE_COUNT.value,
  REPLY_COUNT.value
]

export const DISPLAY_OPTIONS = [
  { text: '平均', value: 'average' },
  { text: '合計', value: 'total' }
]

export const POST_TYPE_OPTIONS = [
  { text: 'テキスト', value: 'text' },
  { text: '画像', value: 'photo' },
  { text: 'リンク', value: 'link' },
  { text: '動画', value: 'video' },
  { text: 'アニメーションGIF', value: 'animation-gif' }
]

export const SENTIMENT_OPTIONS = [
  { text: 'ポジティブ', value: 'positive' },
  { text: 'ネガティブ', value: 'negative' },
  { text: 'ニュートラル', value: 'neutral' }
]

export const TWEET_TYPE_OPTIONS = [
  { text: '投稿', value: 'tweet' },
  { text: '引用', value: 'quote_tweet' },
  { text: '返信', value: 'reply' },
  { text: 'メンション', value: 'mention' }
]

export const POST_LAYOUT_DISPLAY_TYPE = [
  { text: 'テーブル', value: 'table' },
  { text: 'グリッド', value: 'grid' }
]

export const TWITTER_LANGUAGE_OPTIONS = [
  { value: '', ja: 'すべての言語', en: 'Any language' },
  { value: 'ja', ja: '日本語', en: 'Japanese' },
  { value: 'en', ja: '英語', en: 'English' },
  { value: 'zh-cn', ja: '簡体中国語', en: 'Simplified Chinese' },
  { value: 'zh-tw', ja: '繁体中国語', en: 'Traditional Chinese' },
  { value: 'ko', ja: '韓国語', en: 'Korean' },
  { value: 'ar', ja: 'アラビア語', en: 'Arabic' },
  { value: 'it', ja: 'イタリア語', en: 'Italian' },
  { value: 'id', ja: 'インドネシア語', en: 'Indonesian' },
  { value: 'uk', ja: 'ウクライナ語', en: 'Ukrainian' },
  { value: 'ur', ja: 'ウルドゥー語', en: 'Urdu' },
  { value: 'nl', ja: 'オランダ語', en: 'Dutch' },
  { value: 'ca', ja: 'カタロニア語', en: 'Catalan' },
  { value: 'kn', ja: 'カンナダ語', en: 'Kannada' },
  { value: 'el', ja: 'ギリシャ語', en: 'Greek' },
  { value: 'gu', ja: 'グジャラート語', en: 'Gujarati' },
  { value: 'hr', ja: 'クロアチア語', en: 'Croatian' },
  { value: 'sv', ja: 'スウェーデン語', en: 'Swedish' },
  { value: 'es', ja: 'スペイン語', en: 'Spanish' },
  { value: 'sk', ja: 'スロバキア語', en: 'Slovak' },
  { value: 'sr', ja: 'セルビア語', en: 'Serbian' },
  { value: 'th', ja: 'タイ語', en: 'Thai' },
  { value: 'ta', ja: 'タミル語', en: 'Tamil' },
  { value: 'cs', ja: 'チェコ語', en: 'Czech' },
  { value: 'da', ja: 'デンマーク語', en: 'Danish' },
  { value: 'de', ja: 'ドイツ語', en: 'German' },
  { value: 'tr', ja: 'トルコ語', en: 'Turkish' },
  { value: 'no', ja: 'ノルウェー語', en: 'Norwegian' },
  { value: 'eu', ja: 'バスク語', en: 'Basque' },
  { value: 'hu', ja: 'ハンガリー語', en: 'Hungarian' },
  { value: 'hi', ja: 'ヒンディー語', en: 'Hindi' },
  { value: 'fi', ja: 'フィンランド語', en: 'Finnish' },
  { value: 'fr', ja: 'フランス語', en: 'French' },
  { value: 'bg', ja: 'ブルガリア語', en: 'Bulgarian' },
  { value: 'vi', ja: 'ベトナム語', en: 'Vietnamese' },
  { value: 'he', ja: 'ヘブライ語', en: 'Hebrew' },
  { value: 'fa', ja: 'ペルシア語', en: 'Persian' },
  { value: 'bn', ja: 'ベンガル語', en: 'Bangla' },
  { value: 'pl', ja: 'ポーランド語', en: 'Polish' },
  { value: 'pt', ja: 'ポルトガル語', en: 'Portuguese' },
  { value: 'mr', ja: 'マラーティー語', en: 'Marathi' },
  { value: 'ro', ja: 'ルーマニア語', en: 'Romanian' },
  { value: 'ru', ja: 'ロシア語', en: 'Russian' }
]

export const RANKING_SORT_OPTIONS = [
  REACTION_COUNT,
  FOLLOWER_COUNT,
  RETWEET_COUNT,
  QUOTE_COUNT,
  LIKE_COUNT,
  REPLY_COUNT
]

export const RANKING_SIZE_OPTIONS = [5, 10, 25, 50]

export const HASHTAG_SIZE_OPTIONS = [25, 50, 75, 100]

export const WORD_SIZE_OPTIONS = [25, 50, 75, 100]

export const WORD_CLOUD_SIZE_OPTIONS = [25, 50, 75, 100]

export const HEATMAP_HOUR_OPTIONS = [
  '0:00',
  '1:00',
  '2:00',
  '3:00',
  '4:00',
  '5:00',
  '6:00',
  '7:00',
  '8:00',
  '9:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00'
]

export const HEATMAP_DAY_OF_WEEK_OPTIONS = [
  '月曜日',
  '火曜日',
  '水曜日',
  '木曜日',
  '金曜日',
  '土曜日',
  '日曜日'
]

export const HEATMAP_METRICS = [
  POST_COUNT,
  REACTION_COUNT,
  FOLLOWER_COUNT,
  RETWEET_COUNT,
  QUOTE_COUNT,
  LIKE_COUNT,
  REPLY_COUNT
]
