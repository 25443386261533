
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { TrackingService } from '@/client/services'
import { IPost } from '@/client/utils/api/posts'

@Component({
  name: 'PostManagementMonthListPost',
  components: {
    Button,
    Icon,
    Tooltip
  }
})
export default class PostManagementMonthListPost extends Vue {
  @Prop({ type: Array, default: () => [] })
  posts: IPost[]

  /**
   * クラスネームの取得
   */
  getClassName(post: IPost): string {
    if (post.type === 'fb_scheduled') {
      return 'post-fb-scheduled'
    }

    return `post-${post.status}`
  }

  /**
   * 投稿内容をクリック
   */
  clickPostContent(post: IPost): void {
    TrackingService.sendEvent('click:投稿管理|月|プレビュー')

    if (post.type === 'fb_scheduled') {
      open(
        `https://www.facebook.com/${post.fb_schedule.account_id}_${post.fb_schedule.fb_post_id}`,
        '_blank'
      )
    } else {
      this.openPost(post.id)
    }
  }

  @Emit('open-post')
  openPost(post_id: number) {
    return {
      sns_post_id: post_id,
      mode: 'approval',
      tab: 'preview'
    }
  }
}
