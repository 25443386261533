import moment from 'moment-timezone'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import HelpLink from '@/client/components/molecules/HelpLink'
import Button from '@/client/components-old/atoms/Button'
import ExternalLink from '@/client/components-old/atoms/ExternalLink'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import PostInstagramUpdateUrlDialog from '@/client/components-old/organisms/PostInstagramUpdateUrlDialog'
import { TrackingService } from '@/client/services'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import { IPost } from '@/client/utils/api/posts'
import { createTikTokVideoUrl } from '@/client/utils/tiktok'
import { SnsType } from '@/common/types'

const accounts = namespace('accounts')

type TRole = { account_id: string; sns: SnsType }

@Component({
  name: 'MessagePostStatus',
  components: {
    Account,
    Flex,
    Icon,
    ExternalLink,
    Tooltip,
    Message,
    Button,
    PostInstagramUpdateUrlDialog,
    HelpLink
  }
})
export default class MessagePostStatus extends Vue {
  @accounts.Getter('post') sns_accounts!: IAccountsGetter['post']

  @Prop({ type: String, default: null })
  type!: 'preview' | 'list'

  @Prop({ type: String, default: null })
  snsType!: SnsType

  @Prop({ type: Array, default: () => [] })
  roleAdmin!: TRole[]

  @Prop({ type: Object, default: () => null })
  post!: IPost | null

  @Prop({ type: String, default: null })
  deviceType!: 'app' | 'web' | 'text'

  $refs!: {
    PostInstagramUpdateUrlDialog: any
  }

  get is_list() {
    return this.type === 'list'
  }

  get is_preview() {
    return this.type === 'preview'
  }

  get is_expired() {
    if (!this.post) {
      return false
    }

    const fb_accounts = this.post.fb_posts.map(post => ({
      id: post.account.id,
      name: post.account.name,
      image_url: post.account.image_url,
      sns: 'facebook'
    }))

    const tw_accounts = this.post.tw_posts.map(post => ({
      id: post.account.id,
      name: post.account.name,
      image_url: post.account.image_url,
      sns: 'twitter'
    }))

    const in_accounts_from_posts = this.post.in_posts.map(post => ({
      id: post.account.id,
      name: post.account.name,
      image_url: post.account.image_url,
      sns: 'instagram'
    }))

    const in_accounts_from_stories = this.post.in_stories.map(post => ({
      id: post.account.id,
      name: post.account.name,
      image_url: post.account.image_url,
      sns: 'instagram'
    }))

    const tt_accounts_from_posts = this.post.tt_posts.map(post => ({
      id: post.account.id,
      name: post.account.name,
      image_url: post.account.image_url,
      sns: 'tiktok'
    }))

    const accounts = [
      ...fb_accounts,
      ...tw_accounts,
      ...in_accounts_from_posts,
      ...in_accounts_from_stories,
      ...tt_accounts_from_posts
    ]

    return this.sns_accounts.some(
      sns_account =>
        accounts.findIndex(account => sns_account.id === account.id && sns_account.expired) > -1
    )
  }

  get is_instagram() {
    if (!this.post) return false

    return this.post.in_posts.length > 0 || this.post.in_stories.length > 0
  }

  get is_instagram_manual_publish() {
    return this.is_instagram && !this.post?.is_auto_publish
  }

  get is_instagram_draft_message() {
    return this.is_instagram_manual_publish && this.post?.status === 'draft'
  }

  get is_instagram_scheduled() {
    return this.is_instagram_manual_publish && this.is_schedule_post
  }

  get is_action_before_now() {
    if (!this.post?.action_datetime) {
      return false
    }

    return moment(this.post.action_datetime).isSameOrBefore(moment())
  }

  get is_action_after_now() {
    if (!this.post?.action_datetime) {
      return false
    }

    return moment(this.post.action_datetime).isAfter(moment())
  }

  get is_schedule_post() {
    return this.post?.status === 'scheduled'
  }

  get is_schedule_post_publishing() {
    if (!this.is_schedule_post) return false

    if (!this.post?.is_auto_publish) return false

    return !this.post?.action_datetime || moment().isAfter(this.post?.action_datetime)
  }

  get post_list_options() {
    const fb_posts = this.post?.fb_posts ?? []
    const tw_posts = this.post?.tw_posts ?? []
    const in_posts = this.post?.in_posts ?? []
    const in_stories = this.post?.in_stories ?? []
    const tt_posts = this.post?.tt_posts ?? []

    const facebook_posts = fb_posts.map(post => {
      const insight = post.insight

      return {
        type: post.result === 'failure' ? 'error' : post.result,
        sns: 'facebook',
        error: post.error,
        account_id: post.account.id,
        account_name: post.account.name,
        account_image_url: post.account.image_url,
        post_url: post.fb_posted_id ? `https://www.facebook.com/${post.fb_posted_id}` : '',
        insights: insight
          ? [
              {
                text: 'いいね！',
                value: insight.likes_insights ? insight.likes_insights.toLocaleString() : 0,
                icon: 'thumbs-up'
              },
              {
                text: 'コメント',
                value: insight.comments_insights ? insight.comments_insights.toLocaleString() : 0,
                icon: 'message'
              },
              {
                text: 'シェア',
                value: insight.shares_insights ? insight.shares_insights.toLocaleString() : 0,
                icon: 'export'
              },
              {
                text: '反応率',
                value: insight.reactions_rate
                  ? this.$options.filters?.percent(insight.reactions_rate)
                  : 0,
                icon: 'engagement'
              },
              {
                text: '投稿リーチ',
                value: insight.post_impressions_unique
                  ? insight.post_impressions_unique.toLocaleString()
                  : 0,
                icon: 'megaphone'
              },
              {
                text: 'エンゲージメント数',
                value: insight.post_engaged_users ? insight.post_engaged_users.toLocaleString() : 0,
                icon: 'spellcheck'
              },
              {
                text: 'エンゲージメント率',
                value: insight.post_engaged_users_rate
                  ? this.$options.filters?.percent(insight.post_engaged_users_rate)
                  : 0,
                icon: 'action'
              },
              {
                text: '短縮URLクリック',
                value: insight.short_url_clicks ? insight.short_url_clicks.toLocaleString() : '-',
                icon: 'link'
              }
            ]
          : null
      }
    })

    const twitter_posts = tw_posts.map(post => {
      const insight = post.insight

      return {
        type: post.result === 'failure' ? 'error' : post.result,
        sns: 'twitter',
        error: post.error,
        account_id: post.account.id,
        account_name: post.account.name,
        account_image_url: post.account.image_url,
        post_url: post.tw_posted_id
          ? `https://x.com/${post.account.id}/status/${post.tw_posted_id}`
          : '',
        insights: insight
          ? [
              {
                text: 'リポスト',
                value: insight.retweets ? insight.retweets.toLocaleString() : 0,
                icon: 'retweet'
              },
              {
                text: '引用',
                value: insight.quotes ? insight.quotes.toLocaleString() : 0,
                icon: 'export'
              },
              {
                text: 'いいね',
                value: insight.favorites ? insight.favorites.toLocaleString() : 0,
                icon: 'favorite'
              },
              {
                text: '返信',
                value: insight.replies ? insight.replies.toLocaleString() : 0,
                icon: 'message'
              },
              {
                text: '反応率',
                value: insight ? this.$options.filters?.percent(insight.reactions_rate) : 0,
                icon: 'engagement'
              },
              {
                text: 'インプレッション',
                value: insight.impressions ? insight.impressions.toLocaleString() : 0,
                icon: 'megaphone'
              },
              {
                text: '短縮URLクリック',
                value: insight.short_url_clicks ? insight.short_url_clicks.toLocaleString() : '-',
                icon: 'link'
              }
            ]
          : null
      }
    })

    const instagram_posts = in_posts.map(post => {
      const insight = post.insight
      let type = post.result === 'failure' ? 'error' : post.result

      if (this.is_instagram_scheduled && this.is_action_after_now) {
        type = 'warning'
      }

      if (this.post?.status === 'published' && !post.link_url) {
        type = 'warning'
      }

      if (this.is_instagram_scheduled && this.is_action_before_now) {
        type = 'error'
      }

      return {
        type,
        sns: 'instagram',
        error: post.error,
        account_id: post.account.id,
        account_name: post.account.name,
        account_image_url: post.account.image_url,
        post_url: post.link_url ?? '',
        insights: insight
          ? [
              {
                text: 'いいね！',
                value: insight.likes ? insight.likes : 0,
                icon: 'favorite'
              },
              {
                text: 'コメント',
                value: insight.comments ? insight.comments : 0,
                icon: 'message'
              },
              {
                text: '反応率',
                value: insight.reactions_rate
                  ? this.$options.filters?.percent(insight.reactions_rate)
                  : this.$options.filters?.percent(0),
                icon: 'engagement'
              },
              {
                text: '保存',
                value: insight.saved_count ? insight.saved_count.toLocaleString() : 0,
                icon: 'save'
              },
              {
                text: 'リーチ',
                value: insight.impressions_unique ? insight.impressions_unique.toLocaleString() : 0,
                icon: 'megaphone'
              },
              {
                text: 'エンゲージメント数',
                value: insight.engagements ? insight.engagements.toLocaleString() : 0,
                icon: 'spellcheck'
              },
              {
                text: 'エンゲージメント率',
                value: insight.engagements_rate
                  ? this.$options.filters?.percent(insight.engagements_rate)
                  : this.$options.filters?.percent(0),
                icon: 'action'
              }
            ]
          : null
      }
    })

    const instagram_stories = in_stories.map(post => {
      const insight = post.insight
      let type = post.result === 'failure' ? 'error' : post.result

      if (this.is_instagram_scheduled && this.is_action_after_now) {
        type = 'warning'
      }

      if (
        this.post?.status === 'published' &&
        this.is_instagram_manual_publish &&
        !this.post?.in_stories?.[0]?.in_posted_id
      ) {
        type = 'warning'
      }

      if (this.is_instagram_scheduled && this.is_action_before_now) {
        type = 'error'
      }

      return {
        type,
        sns: 'instagram',
        error: post.error,
        account_id: post.account.id,
        account_name: post.account.name,
        account_image_url: post.account.image_url,
        post_url: post.permalink ?? '',
        insights: insight
          ? [
              {
                text: 'リーチ',
                value: insight.reach ? insight.reach.toLocaleString() : 0,
                icon: 'megaphone'
              },
              {
                text: '前へ',
                value: insight.taps_back ? insight.taps_back.toLocaleString() : 0,
                icon: 'arrow-left'
              },
              {
                text: '次へ',
                value: insight.taps_forward ? insight.taps_forward.toLocaleString() : 0,
                icon: 'arrow-right'
              },
              {
                text: 'ストーリーズから移動',
                value: insight.exits ? insight.exits.toLocaleString() : 0,
                icon: 'exit-to-app'
              }
            ]
          : null
      }
    })

    const tiktok_posts = tt_posts.map(post => {
      const type = post.result === 'failure' ? 'error' : post.result
      const insight = post.insight

      return {
        type,
        sns: 'tiktok',
        error: post.error,
        account_id: post.account.id,
        account_name: post.account.name,
        account_image_url: post.account.image_url,
        insights: insight
          ? [
              {
                text: '動画再生数',
                value: insight.video_views ? insight.video_views.toLocaleString() : 0,
                icon: 'play-arrow'
              },
              {
                text: 'リーチ',
                value: insight.reach ? insight.reach.toLocaleString() : 0,
                icon: 'megaphone'
              },
              {
                text: '視聴完了率',
                value: insight.full_video_watched_rate
                  ? this.$options.filters?.percent(insight.full_video_watched_rate.toLocaleString())
                  : this.$options.filters?.percent(0),
                icon: 'done-rate'
              },
              {
                text: '平均視聴秒数',
                value: insight.average_time_watched
                  ? `${insight.average_time_watched.toLocaleString()}s`
                  : '0s',
                icon: 'average'
              },
              {
                text: '合計視聴秒数',
                value: insight.total_time_watched
                  ? `${insight.total_time_watched.toLocaleString()}s`
                  : '0s',
                icon: 'sum-sign'
              },
              {
                text: 'いいね',
                value: insight.likes ? insight.likes.toLocaleString() : 0,
                icon: 'favorite'
              },
              {
                text: 'コメント',
                value: insight.comments ? insight.comments.toLocaleString() : 0,
                icon: 'message'
              },
              {
                text: 'シェア',
                value: insight.shares ? insight.shares.toLocaleString() : 0,
                icon: 'export'
              },
              {
                text: '反応数',
                value: insight.reactions ? insight.reactions.toLocaleString() : 0,
                icon: 'reactions'
              },
              {
                text: '反応率',
                value: insight.reactions_rate
                  ? this.$options.filters?.percent(insight.reactions_rate.toLocaleString())
                  : this.$options.filters?.percent(0),
                icon: 'engagement'
              },
              {
                text: 'エンゲージメント率',
                value: insight.engagements_rate
                  ? this.$options.filters?.percent(insight.engagements_rate.toLocaleString())
                  : this.$options.filters?.percent(0),
                icon: 'action'
              }
            ]
          : null,
        post_url: post.tt_posted_id
          ? createTikTokVideoUrl(post.account.username, post.tt_posted_id)
          : ''
      }
    })

    return [
      ...facebook_posts,
      ...twitter_posts,
      ...instagram_posts,
      ...instagram_stories,
      ...tiktok_posts
    ]
  }

  get post_preview_options() {
    switch (this.snsType) {
      case 'facebook':
        return this.post && this.post.fb_posts ? this.post.fb_posts : []
      case 'twitter':
        return this.post && this.post.tw_posts ? this.post.tw_posts : []
      case 'instagram': {
        if (this.post?.in_posts?.length) return this.post.in_posts
        if (this.post?.in_stories.length) return this.post.in_stories
        return []
      }
      case 'tiktok': {
        return this.post && this.post.tt_posts ? this.post.tt_posts : []
      }
      default:
        return []
    }
  }

  get post_preview_options_success() {
    return [...this.post_preview_options].filter(post => post.result === 'success')
  }

  get post_preview_options_failture() {
    return [...this.post_preview_options].filter(post =>
      ['failure', 'unposted'].includes(post.result)
    )
  }

  get show_scheduled_message() {
    return this.is_schedule_post && this.post_preview_options.length
  }

  get show_published_message() {
    if (!this.post) return false

    return (
      (['published', 'failure'].includes(this.post.status) ||
        (this.is_schedule_post && !this.is_instagram)) &&
      this.post_preview_options_success.length
    )
  }

  get show_failure_message() {
    return (
      (this.post?.status === 'published' || this.post?.status === 'failure') &&
      this.post_preview_options_failture.length
    )
  }

  get show_approval_message() {
    return this.post && this.post.status === 'approval' && this.post_preview_options.length
  }

  get show_approval_message_content() {
    if (!this.post) return false

    const current_time = moment()

    return this.post.action_datetime && moment(this.post?.action_datetime).isAfter(current_time)
  }

  get is_approval_message() {
    if (this.is_instagram_manual_publish) {
      return 'この投稿は承認後に公開手続きをすると公開されます。'
    }

    return 'この投稿は承認後に公開されます。'
  }

  get is_instagram_url_unset() {
    if (!this.is_instagram_manual_publish) return false

    if (this.post?.status !== 'published') return false

    if (this.post.in_posts.length) {
      return (
        !this.post.in_posts[0].link_url &&
        moment().subtract(30, 'days').isBefore(this.post.in_posts[0].publish_datetime)
      )
    }

    if (this.post.in_stories.length) {
      return (
        !this.post.in_stories?.[0]?.in_posted_id &&
        moment().subtract(1, 'days').isBefore(this.post.in_stories[0].publish_datetime)
      )
    }

    return false
  }

  get is_link_button() {
    if (!this.is_instagram) return true

    const in_post = this.post?.in_posts?.[0]
    const in_story = this.post?.in_stories?.[0]

    return Boolean(in_post?.link_url || in_story?.permalink)
  }

  /**
   * 権限をチェック
   */
  isRoleAdmin(account_id: string, sns: SnsType): boolean {
    return !!this.roleAdmin.find(role => role.account_id === account_id && role.sns === sns)
  }

  /**
   * 再投稿ボタン表示フラグ
   */
  isRepublish(account_id: string, sns: SnsType, type: string) {
    return (
      !this.is_instagram_manual_publish && type === 'error' && this.isRoleAdmin(account_id, sns)
    )
  }

  /**
   * エラー内容を表示
   */
  getErrorMessage(error: string): string {
    if (this.is_instagram_scheduled && this.is_action_after_now) {
      return this.$options.filters?.translate('予約時間に公開手続をしてください。')
    }

    if (this.is_instagram_scheduled && this.is_action_before_now) {
      return this.$options.filters?.translate('予約時間を過ぎています。公開手続をしてください。')
    }

    if (
      this.is_instagram &&
      this.post?.status === 'published' &&
      (!this.post?.in_posts?.[0]?.link_url || !this.post.in_stories?.[0]?.in_posted_id)
    ) {
      return this.$options.filters?.translate('投稿URLが未設定のため、分析データを表示できません。')
    }

    switch (error) {
      case 'ACCOUNT_TOKEN_NOT_FOUND':
        return this.$options.filters?.translate(
          'アクセストークンが失効しているため、公開に失敗しました。'
        )
      default:
        return this.$options.filters?.translate('投稿の公開に失敗しました。')
    }
  }

  /**
   * ダイアログ側の公開URLリンクを表示
   * ? この関数は使われていない。利用するとリンクが正常に動作しない。
   */
  getLinkPost(post: any): string {
    switch (this.snsType) {
      case 'facebook':
        return `https://www.facebook.com/${post.fb_posted_id}`
      case 'twitter':
        return `https://x.com/${post.account.username}/status/${post.tw_posted_id}`
      case 'instagram': {
        return post.link_url ?? post.permalink ?? ''
      }
      case 'tiktok': {
        return createTikTokVideoUrl(post.account.username, post.tt_posted_id)
      }
    }
  }

  /**
   * 再投稿
   */
  republish(account_id: string, sns: SnsType): void {
    TrackingService.sendEvent('click:投稿管理|リスト>レコード|再投稿')

    const message = this.$options.filters?.translate(
      'この投稿を再投稿してよろしいですか？投稿はすぐに公開されます。'
    )

    const confirm = window.confirm(message)

    if (!confirm) return

    this.$emit('republish', { account_id, sns })
  }

  /**
   * Instagram投稿の公開のURLのダイアログを開く
   */
  openInstagramUpdateUrlDialog() {
    if (!this.post) return

    this.$refs.PostInstagramUpdateUrlDialog.open({
      sns_post_id: this.post.id,
      post_type: this.post.type
    })
  }
}
