import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import { DATE_RANGE_LIMIT, MIN_DATE } from '@/client/components-old/_constants/global'
import i18n from '@/client/utils/i18n'

type Shortcut = { text: string; onClick: (picker: any) => void }

@Component({
  name: 'DateRangePicker'
})
export default class DateRangePicker extends Vue {
  @Prop({ type: Array, default: () => [] })
  value: [string, string]

  @Prop({ type: String, default: 'other' })
  type: 'post' | 'monitoring' | 'other'

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  selected_date = null

  get options() {
    const type = this.type

    const shortcuts = []

    switch (type) {
      case 'post':
        shortcuts.push(...this.getShortcutPost())
        break
      case 'monitoring':
        shortcuts.push(...this.getShortcutMonitoring())
        break
      default:
        shortcuts.push(...this.getShortcutOther())
        break
    }

    return {
      shortcuts,
      onPick: ({ maxDate, minDate }) => {
        this.selected_date = minDate.getTime()

        if (maxDate) {
          this.selected_date = null
        }
      },
      disabledDate: (time: Date) => {
        const start = moment(MIN_DATE).startOf('day')
        const end = moment().startOf('day')
        const date = moment(time).tz('Asia/Tokyo').startOf('day')

        if (type === 'post') {
          end.add(6, 'month')
        }

        if (!moment(date).isBetween(start, end, 'day', '[]')) return true

        if (this.selected_date) {
          const minTime = this.selected_date - DATE_RANGE_LIMIT
          const maxTime = this.selected_date + DATE_RANGE_LIMIT

          return time.getTime() < minTime || time.getTime() > maxTime
        }
      }
    }
  }

  @Emit('input')
  changeDateRange(event: any) {
    return event
  }

  /**
   * DateRangePickerを開く
   */
  openDateRange() {
    this.selected_date = null
  }

  /**
   * ショートカット設定（投稿）
   */
  getShortcutPost(): Shortcut[] {
    return [
      {
        text: `${i18n.t('30日前')} - ${i18n.t('60日後')}`,
        onClick(picker) {
          const start = moment().startOf('day').subtract(30, 'day')

          const end = moment().startOf('day').add(60, 'day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('今日'),
        onClick(picker) {
          const start = moment().startOf('day')

          const end = moment().endOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('今週'),
        onClick(picker) {
          const start = moment().startOf('isoWeek')

          const end = moment().endOf('isoWeek')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('今月'),
        onClick(picker) {
          const start = moment().startOf('month')

          const end = moment().endOf('month')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('来週'),
        onClick(picker) {
          const start = moment().add(1, 'week').startOf('isoWeek')

          const end = moment().add(1, 'week').endOf('isoWeek')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('来月'),
        onClick(picker) {
          const start = moment().add(1, 'month').startOf('month')

          const end = moment().add(1, 'month').endOf('month')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('先週'),
        onClick(picker) {
          const start = moment().subtract(1, 'week').startOf('isoWeek')

          const end = moment().subtract(1, 'week').endOf('isoWeek')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('先月'),
        onClick(picker) {
          const start = moment().subtract(1, 'month').startOf('month')

          const end = moment().subtract(1, 'month').endOf('month')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      }
    ]
  }

  /**
   * ショートカット設定（モニタリング）
   */
  getShortcutMonitoring(): Shortcut[] {
    return [
      {
        text: `${i18n.t('30日前')} - ${i18n.t('今日')}`,
        onClick(picker) {
          const start = moment().subtract(30, 'day').startOf('day')

          const end = moment().startOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('今日'),
        onClick(picker) {
          const start = moment().startOf('day')

          const end = moment().endOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('今週'),
        onClick(picker) {
          const start = moment().startOf('isoWeek')

          const end = moment().endOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('今月'),
        onClick(picker) {
          const start = moment().startOf('month')

          const end = moment().endOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('先週'),
        onClick(picker) {
          const start = moment().subtract(1, 'week').startOf('isoWeek')

          const end = moment().subtract(1, 'week').endOf('isoWeek')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('先月'),
        onClick(picker) {
          const start = moment().subtract(1, 'month').startOf('month')

          const end = moment().subtract(1, 'month').endOf('month')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      }
    ]
  }

  /**
   * ショートカット設定（その他）
   */
  getShortcutOther(): Shortcut[] {
    return [
      {
        text: i18n.t('今月'),
        onClick(picker) {
          const start = moment().startOf('month')

          const end = moment().endOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('先週'),
        onClick(picker) {
          const start = moment().subtract(1, 'week').startOf('isoWeek')

          const end = moment().subtract(1, 'week').endOf('isoWeek')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: i18n.t('先月'),
        onClick(picker) {
          const start = moment().subtract(1, 'month').startOf('month')

          const end = moment().subtract(1, 'month').endOf('month')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: `${i18n.t('7日前')} - ${i18n.t('今日')}`,
        onClick(picker) {
          const start = moment().subtract(7, 'day').startOf('day')

          const end = moment().startOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: `${i18n.t('30日前')} - ${i18n.t('今日')}`,
        onClick(picker) {
          const start = moment().subtract(30, 'day').startOf('day')

          const end = moment().startOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: `${i18n.t('3ヶ月前')} - ${i18n.t('今日')}`,
        onClick(picker) {
          const start = moment().subtract(3, 'month').startOf('month')

          const end = moment().startOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      },
      {
        text: `${i18n.t('6ヶ月前')} - ${i18n.t('今日')}`,
        onClick(picker) {
          const start = moment().subtract(6, 'month').startOf('month')

          const end = moment().startOf('day')

          picker.$emit('pick', [start.toDate(), end.toDate()])
        }
      }
    ]
  }
}
