
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

import Box from '@/client/components-old/atoms/Box'
import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Scroll from '@/client/components-old/atoms/Scroll'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import PersonalSettingNotificationList from '@/client/components-old/organisms/PersonalSettingNotificationList'

export default defineComponent({
  name: 'PersonalSettingNotification',
  components: {
    Box,
    Button,
    Icon,
    Panel,
    Scroll,
    Tooltip,
    PersonalSettingNotificationList
  },
  computed: {
    ...mapState(['project'])
  },
  methods: {
    ...mapActions('personal_setting', ['putNotification']),
    ...mapActions('notification', ['showNotification']),

    /**
     * すべての設定を変更する
     */
    async changeAllSettings(value: boolean): Promise<void> {
      const response = await this.putNotification({
        global_memo_to_me: value,
        global_memo: value,
        global_person_in_charge: value,
        global_add_sns: value,
        global_add_opperator: value,
        global_payment: value,
        post_publish: value,
        post_scheduled: value,
        post_draft: value,
        post_approve_request: value,
        post_approved: value,
        post_rejected: value,
        post_publish_failed: value,
        post_draft_remind_24_hour: value,
        post_publish_remind_5_minute: value,
        post_publish_remind_15_minute: value,
        post_publish_remind_30_minute: value,
        analytics_report_weekly: value,
        analytics_report_monthly: value,
        analytics_keyword_hashtag_alert_post_count: value,
        analytics_twitter_viral_alert_post_count: value,
        monitoring_comment_new: value,
        monitoring_message_new: value,
        monitoring_post_new: value,
        monitoring_read: value,
        monitoring_pending: value,
        monitoring_hide: value,
        monitoring_unhide: value,
        monitoring_deleted: value,
        monitoring_keyword_positive: value,
        monitoring_keyword_negative: value,
        monitoring_keyword_other: value,
        monitoring_keyword_global: value,
        monitoring_report: value
      })

      if (response.data) {
        return this.showNotification({ title: '通知メール設定を更新しました。', duration: 1000 })
      }

      this.showNotification({ title: '通知メール設定の更新に失敗しました。', type: 'error' })
    }
  }
})
