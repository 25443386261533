import { RouteConfig } from 'vue-router'

import TwitterReport from '../pages/TwitterReport.vue'

export const report_tw: RouteConfig = {
  component: TwitterReport,
  path: '/report_tw',
  name: 'report_tw',
  meta: {
    auth: true,
    title: 'Excelレポート (X)',
    description: 'SNSアカウントのExcelレポートがダウンロードできます。',
    color: 'warning',
    type: 'DOWNLOAD_EXCEL_REPORT_OWN_ANALYSIS'
  }
}
