import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  IGetInstagramComparisonInsightsAccountsParams,
  IGetInstagramComparisonInsightsAccountsResponse,
  IGetInstagramComparisonInsightsPostsParams,
  IGetInstagramComparisonInsightsPostsResponse,
  IInstagramComparisonInsightsAccounts,
  IInstagramComparisonInsightsPosts,
  IPresets,
  ISnsComparisonAccounts
} from '@/client/utils/api/instagram_comparison_insights'
import storage from '@/client/utils/storage'

type DisplayFormat = 'total' | 'average'

export interface IState {
  api_accounts: {
    account_id: string
    params: IGetInstagramComparisonInsightsAccountsParams
    data: IInstagramComparisonInsightsAccounts[]
  }[]
  api_posts: {
    account_id: string
    data: IInstagramComparisonInsightsPosts[]
  }[]
  api_presets: IPresets[]
  api_sns_comparison_accounts: ISnsComparisonAccounts[]
  account_ids: string[]
  screen_name: 'benchmark' | 'post' | 'management'
  management_tab_name: 'account' | 'preset'
  preset_id: '' | number
  search_type: string[]
  search_category: number[]
  search_message: string
  is_loading: boolean
  post_display_count: 10 | 25 | 50 | 100
  post_display_format: DisplayFormat
  basic_display_format: DisplayFormat
  positioning_display_format: DisplayFormat
}

const state: IState = {
  api_accounts: [],
  api_posts: [],
  api_presets: [],
  api_sns_comparison_accounts: [],
  account_ids: [],
  screen_name: 'benchmark',
  management_tab_name: 'account',
  preset_id: '',
  search_type: [],
  search_category: [],
  search_message: '',
  is_loading: false,
  post_display_count: 25,
  post_display_format: 'average',
  basic_display_format: 'average',
  positioning_display_format: 'average'
}

export interface IGetter {
  is_unregister_comparison: boolean
}

const getters: GetterTree<IState, IRootState> = {
  is_unregister_comparison(state): IGetter['is_unregister_comparison'] {
    const accounts = state.api_sns_comparison_accounts.filter(account => !account.is_own)

    return !accounts.length
  }
}

const mutations: MutationTree<IState> = {
  SET_API_ACCOUNTS(state, payload) {
    state.api_accounts = payload
  },
  SET_API_POSTS(state, payload) {
    state.api_posts = payload
  },
  SET_API_PRESETS(state, payload: IPresets[]) {
    state.api_presets = payload
  },
  SET_API_SNS_COMPARISON_ACCOUNTS(state, payload: ISnsComparisonAccounts[]) {
    state.api_sns_comparison_accounts = payload
  },
  SET_PARAMS(state, payload) {
    state.account_ids = payload
    storage.set('instagram_comparison_analytics', { account_ids: payload })
  },
  SET_SCREEN(state, payload) {
    state.screen_name = payload
  },
  SET_MANAGEMENT_TAB(state, payload) {
    state.management_tab_name = payload
  },
  SET_PRESET_ID(state, payload) {
    state.preset_id = payload
  },
  SET_SEARCH_TYPE(state, payload) {
    state.search_type = payload
  },
  SET_SEARCH_CATEGORY(state, payload) {
    state.search_category = payload
  },
  SET_SEARCH_MESSAGE(state, payload) {
    state.search_message = payload
  },
  SET_LOADING(state, payload) {
    state.is_loading = payload
  },
  SET_POST_DISPLAY_COUNT(state, payload: 10 | 25 | 50 | 100) {
    state.post_display_count = payload
  },
  SET_POST_DISPLAY_FORMAT(state, payload) {
    state.post_display_format = payload
  },
  SET_BASIC_DISPLAY_FORMAT(state, payload) {
    state.basic_display_format = payload
  },
  SET_POSITIONING_DISPLAY_FORMAT(state, payload) {
    state.positioning_display_format = payload
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * ページ表示時の処理
   */
  async createdInstagramComparisonAnalyticsPage(context) {
    await context.dispatch('getSnsComparisonAccounts')
    await context.dispatch('getPresets')

    if (context.getters.is_unregister_comparison) {
      await context.dispatch('getScreen', 'management')
    }

    const api = context.state.api_sns_comparison_accounts.map(account => account.id)
    const local: { account_ids?: string[] } = storage.get('instagram_comparison_analytics')

    let account_ids: string[] = []

    // ストレージに保存されていたら使用する
    if (local && local.account_ids && Array.isArray(local.account_ids)) {
      account_ids = local.account_ids.filter(account_id => api.includes(account_id))
    }

    await context.dispatch('getComparisonInsights', { account_ids })
  },

  /**
   * ページ離脱時の処理
   */
  async destroyedInstagramComparisonAnalyticsPage(context) {
    context.commit('SET_API_ACCOUNTS', [])
    context.commit('SET_API_POSTS', [])
    context.commit('SET_API_PRESETS', [])
    context.commit('SET_API_SNS_COMPARISON_ACCOUNTS', [])
  },

  /**
   * グループ変更時の処理
   */
  async changeGroupInstagramComparisonAnalyticsPage(context) {
    await context.dispatch('destroyedInstagramComparisonAnalyticsPage')

    context.commit('SET_PRESET_ID', '')
    context.commit('SET_SEARCH_TYPE', [])
    context.commit('SET_SEARCH_CATEGORY', [])
    context.commit('SET_SEARCH_MESSAGE', '')

    await context.dispatch('createdInstagramComparisonAnalyticsPage')
  },

  /**
   * 投稿の表示件数の変更
   */
  changePostDisplayCount(context, payload: number) {
    context.commit('SET_POST_DISPLAY_COUNT', payload)
  },

  /**
   * 競合分析データの取得
   */
  async getComparisonInsights(context, payload) {
    const account_ids = [...payload.account_ids]

    account_ids.sort((a, b) => {
      const order_no_a = context.state.api_sns_comparison_accounts.find(account => account.id === a)
      const order_no_b = context.state.api_sns_comparison_accounts.find(account => account.id === b)

      const order_a = order_no_a ? order_no_a.order_no : 0
      const order_b = order_no_b ? order_no_b.order_no : 0

      return order_a - order_b
    })

    context.commit('SET_PARAMS', account_ids)

    if (context.state.account_ids.length === 0) return

    context.commit('SET_LOADING', true)

    const category = { target: 'instagram_comparison', account_ids: context.state.account_ids }

    await context.dispatch('categories/fetchCategoryAnalysisPost', category, { root: true })

    const api_accounts = []
    const api_posts = []

    for (const account_id of context.state.account_ids) {
      const params:
        | IGetInstagramComparisonInsightsAccountsParams
        | IGetInstagramComparisonInsightsPostsParams = {
        account_id,
        project_id: context.rootState.project.id,
        start_date: context.rootState.analytics.start_date,
        end_date: context.rootState.analytics.end_date
      }

      const api_account = context.state.api_accounts.find(
        account => account.account_id === account_id
      )

      const api_post = context.state.api_posts.find(account => account.account_id === account_id)

      // 対象アカウントのデータがすでに取得されている場合、取得したデータをセット
      if (
        api_account &&
        api_account.params.account_id === params.account_id &&
        api_account.params.start_date === params.start_date &&
        api_account.params.end_date === params.end_date
      ) {
        api_accounts.push(api_account)
        api_posts.push(api_post)
      } else {
        // 取得されていない場合、APIでデータ取得を行う
        const [accounts, posts] = await Promise.all([
          API.get<IGetInstagramComparisonInsightsAccountsResponse>(
            'instagram_comparison_insights/accounts',
            { params }
          ),
          API.get<IGetInstagramComparisonInsightsPostsResponse>(
            'instagram_comparison_insights/posts',
            { params }
          )
        ])

        api_accounts.push({ account_id, params, data: accounts.data.data })
        api_posts.push({ account_id, data: posts.data.data })
      }
    }

    context.commit('SET_API_ACCOUNTS', api_accounts)
    context.commit('SET_API_POSTS', api_posts)
    context.commit('SET_LOADING', false)
  },

  /**
   * スクリーン情報の取得
   */
  async getScreen(context, payload) {
    context.commit('SET_SCREEN', payload)
  },

  /**
   * 管理ページタブ情報の取得
   */
  async getManagementTab(context, payload) {
    context.commit('SET_MANAGEMENT_TAB', payload)
  },

  /**
   * プリセットID情報の取得
   */
  async getPresetId(context, payload) {
    context.commit('SET_PRESET_ID', payload)

    if (context.state.preset_id !== '') {
      const preset = context.state.api_presets.find(preset => preset.id === context.state.preset_id)

      await context.dispatch('getComparisonInsights', { account_ids: preset.data })

      TrackingService.sendEvent('select:競合比較(IG)|画面内ヘッダー|プリセット選択')
    }
  },

  /**
   * プリセット一覧の取得
   */
  async getPresets(context) {
    const params = {
      project_id: context.rootState.project.id
    }

    const { data } = await API.get('instagram_comparison_insights/presets', { params })

    context.commit('SET_API_PRESETS', data.data)
  },

  /**
   * IDからプリセットの取得
   */
  async getPresetById(context, payload) {
    const { preset_id } = payload

    const { data } = await API.get(`instagram_comparison_insights/presets/${preset_id}`)

    // 取得に失敗した場合
    if (!data.data) {
      await context.dispatch('getPresets')
    }

    return data
  },

  /**
   * プリセットを追加
   */
  async postPresets(context, payload) {
    const params = {
      project_id: context.rootState.project.id,
      name: payload.name,
      data: payload.data
    }

    const { data } = await API.post('instagram_comparison_insights/presets', params)

    // 更新に成功した場合
    if (data.data) {
      await context.dispatch('getPresets')
    }

    return data
  },

  /**
   * サンプルプリセットを追加
   */
  async postSamplePreset(context) {
    const params = {
      project_id: context.rootState.project.id
    }

    const { data } = await API.post('instagram_comparison_insights/presets/sample', params)

    // 更新に成功した場合
    if (data.data) {
      await context.dispatch('getSnsComparisonAccounts')
      await context.dispatch('getPresets')
      await context.dispatch('getPresetId', data.data.id)
      await context.dispatch('getScreen', 'benchmark')
    }

    return data
  },

  /**
   * プリセットを編集
   */
  async putPresets(context, payload) {
    const params = {
      name: payload.name,
      data: payload.data
    }

    const { data } = await API.put(`instagram_comparison_insights/presets/${payload.id}`, params)

    // 更新に成功した場合、またはすでに削除されている場合
    if (data.data || (data.error && data.error.type === 'NOT_EXISTS')) {
      await context.dispatch('getPresets')
    }

    // 更新したプリセットを選択している場合
    if (context.state.preset_id === payload.id) {
      const preset = context.state.api_presets.find(preset => preset.id === context.state.preset_id)

      await context.dispatch('getComparisonInsights', { account_ids: preset.data })
    }

    return data
  },

  /**
   * プリセットを削除
   */
  async deletePresets(context, payload) {
    const { data } = await API.delete(`instagram_comparison_insights/presets/${payload.id}`)

    // 削除に成功した場合、またはすでに削除されている場合
    if (data.data || (data.error && data.error.type === 'NOT_EXISTS')) {
      await context.dispatch('getPresets')
    }

    // 削除したプリセットを選択している場合
    if (context.state.preset_id === payload.id) {
      context.commit('SET_PRESET_ID', '')

      await context.dispatch('getComparisonInsights', { account_ids: [] })
    }

    return data
  },

  /**
   * プリセット一覧のドラッグ&ドロップ
   */
  async changeOrderNoPresets(context, payload: { preset_ids: number[] }) {
    const params = {
      project_id: context.rootState.project.id,
      preset_ids: payload.preset_ids
    }

    const { data } = await API.put('instagram_comparison_insights/presets/change_order_no', params)

    await context.dispatch('getPresets')

    return data
  },

  /**
   * 競合アカウント一覧の取得
   */
  async getSnsComparisonAccounts(context) {
    const params = {
      project_id: context.rootState.project.id
    }

    const { data } = await API.get('instagram_comparison_insights/sns_comparison_accounts', {
      params
    })

    context.commit('SET_API_SNS_COMPARISON_ACCOUNTS', data.data)
  },

  /**
   * 競合アカウントの追加
   */
  async postSnsComparisonAccounts(context, payload) {
    const params = {
      project_id: context.rootState.project.id,
      account_id: payload.account_id,
      in_business_account_id: payload.in_business_account_id,
      account_name: payload.account_name,
      user_name: payload.user_name,
      image_url: payload.image_url
    }

    const { data } = await API.post('instagram_comparison_insights/sns_comparison_accounts', params)

    // 更新に成功した場合
    if (data.data || (data.error && data.error.type === 'EXISTS_ACCOUNT')) {
      await context.dispatch('getSnsComparisonAccounts')
    }

    return data
  },

  /**
   * 競合アカウントの削除
   */
  async deleteSnsComparisonAccounts(context, payload) {
    const { account_id } = payload

    const params = {
      project_id: context.rootState.project.id
    }

    const { data } = await API.delete(
      `instagram_comparison_insights/sns_comparison_accounts/${account_id}?project_id=${params.project_id}`
    )

    // 削除に成功した場合、またはすでに削除されている場合
    if (data.data || (data.error && data.error.type === 'NOT_EXISTS')) {
      await context.dispatch('getPresets')
      await context.dispatch('getSnsComparisonAccounts')
    }

    // 選択中のプリセットが削除された場合
    if (!context.state.api_presets.some(v => v.id === context.state.preset_id)) {
      context.commit('SET_PRESET_ID', '')
    }

    // 削除したアカウントが選択中の場合
    if (context.state.account_ids.includes(account_id)) {
      const account_ids = context.state.account_ids.filter(v => v !== account_id)

      await context.dispatch('getComparisonInsights', { account_ids })
    }

    return data
  },

  /**
   * 競合アカウント一覧のドラッグ&ドロップ
   */
  async changeOrderNoSnsComparisonAccounts(context, payload: { account_ids: string[] }) {
    const params = {
      project_id: context.rootState.project.id,
      account_ids: payload.account_ids
    }

    const { data } = await API.put(
      'instagram_comparison_insights/sns_comparison_accounts/change_order_no',
      params
    )

    await context.dispatch('getSnsComparisonAccounts')

    const account_ids = [...context.state.account_ids].sort((a, b) => {
      const account_a = context.state.api_sns_comparison_accounts.find(v => v.id === a)
      const account_b = context.state.api_sns_comparison_accounts.find(v => v.id === b)
      return account_a.order_no - account_b.order_no
    })

    await context.dispatch('getComparisonInsights', { account_ids })

    return data
  },

  /**
   * 競合アカウントの検索
   */
  async searchSnsAccount(context, payload) {
    const params = {
      project_id: context.rootState.project.id,
      url: payload.url
    }

    const { data } = await API.get(`instagram_comparison_insights/sns_comparison_accounts/search`, {
      params
    })

    return data
  },

  /**
   * 投稿データの表示形式を変更
   */
  changePostDisplayFormat(context, payload) {
    context.commit('SET_POST_DISPLAY_FORMAT', payload)
  },

  /**
   * アカウントデータの表示形式を変更
   */
  changeBasicDisplayFormat(context, payload) {
    context.commit('SET_BASIC_DISPLAY_FORMAT', payload)
  },

  /**
   * ポジショニングマップデータの表示形式を変更
   */
  changePositioningDisplayFormat(context, payload) {
    context.commit('SET_POSITIONING_DISPLAY_FORMAT', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
