import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Flex from '@/client/components-old/atoms/Flex'
import Select from '@/client/components-old/atoms/Select'
import Tabs from '@/client/components-old/atoms/Tabs'
import SnsMultiSelect from '@/client/components-old/molecules/SnsMultiSelect'
import AnalyticsDateRangePicker from '@/client/components-old/organisms/AnalyticsDateRangePicker'
import { TrackingService } from '@/client/services'
import { IState as IFacebookState } from '@/client/store/modules/facebook_comparison_analytics'

const facebook = namespace('facebook_comparison_analytics')

@Component({
  name: 'FacebookComparisonAnalyticsHeader',
  components: {
    SnsMultiSelect,
    AnalyticsDateRangePicker,
    Flex,
    Tabs,
    Select
  }
})
export default class FacebookComparisonAnalyticsHeader extends Vue {
  @facebook.State('api_sns_comparison_accounts')
  api_sns_comparison_accounts: IFacebookState['api_sns_comparison_accounts']
  @facebook.State('api_presets') api_presets: IFacebookState['api_presets']
  @facebook.State('account_ids') account_ids: IFacebookState['account_ids']
  @facebook.State('screen_name') screen_name: IFacebookState['screen_name']
  @facebook.State('preset_id') preset_id: IFacebookState['preset_id']
  @facebook.Action('getComparisonInsights') getComparisonInsights
  @facebook.Action('getScreen') getScreen
  @facebook.Action('getPresetId') getPresetId

  get accounts_values() {
    return this.account_ids.map(id => ({
      id,
      sns: 'facebook'
    }))
  }

  get accounts_options() {
    return this.api_sns_comparison_accounts.map(account => ({
      id: account.id,
      img: account.img,
      name: account.name,
      sns: 'facebook'
    }))
  }

  get presets_options() {
    return this.api_presets.map(preset => ({ text: preset.name, value: preset.id }))
  }

  get screens_options() {
    return [
      {
        value: 'benchmark',
        text: 'ベンチマーク',
        icon: 'line-graph'
      },
      {
        value: 'post',
        text: '投稿',
        icon: 'rate-review'
      },
      {
        value: 'management',
        text: 'アカウント管理',
        icon: 'facebook-with-circle'
      }
    ]
  }

  /**
   * アカウントID一覧の変更
   */
  async changeAccountIds(
    accounts: {
      id: string
      name: string
      img: string
      sns: 'facebook'
    }[]
  ): Promise<void> {
    const account_ids = accounts.map(account => account.id)
    await this.getPresetId('')
    await this.getComparisonInsights({ account_ids })

    TrackingService.sendEvent(`select:競合比較(FB)|画面内ヘッダー|アカウント選択`)
  }

  /**
   * 日付範囲の変更
   */
  async changeDateRange(): Promise<void> {
    await this.getComparisonInsights({ account_ids: this.account_ids })

    TrackingService.sendEvent(`select:競合比較(FB)|画面内ヘッダー|期間指定`)
  }

  /**
   * プリセット選択
   */
  async onSelectPresetId(payload: string): Promise<void> {
    await this.getPresetId(payload)

    TrackingService.sendEvent(`select:競合比較(FB)|画面内ヘッダー|プリセット選択`)
  }

  /**
   * タブ選択
   */
  async onGetScreen(tab: string): Promise<void> {
    await this.getScreen(tab)

    TrackingService.sendEvent(`click:競合比較(FB)|タブ:${tab}`)
  }
}
