interface Options {
  keywords: string[]
  or_keywords: string[]
  not_keywords: string[]
  is_exclude_reply: boolean
  exclude_username: string | null
  is_twitter_api_v2: boolean
}

export function createTwitterSearchQuery(options: Options) {
  const logic: string[] = []

  const convert = (v: string) => (v.startsWith('#') ? v : `"${v}"`)

  const and = options.keywords.map(v => convert(v))
  const or = options.or_keywords.map(v => convert(v))
  const not = options.not_keywords.map(v => '-' + convert(v))

  if (and.length) logic.push(and.join(' '))
  if (or.length) logic.push(`(${or.join(' OR ')})`)
  if (not.length) logic.push(not.join(' '))

  if (options.is_twitter_api_v2) logic.push('-is:retweet')

  if (options.is_exclude_reply) {
    logic.push(options.is_twitter_api_v2 ? '-is:reply' : '-filter:replies')
  }

  if (options.exclude_username) {
    logic.push(`-from:${options.exclude_username}`)
  }

  return logic.join(' ')
}
