import moment from 'moment-timezone'

import * as constants from '@/client/components-old/_constants/twitter_analytics'
import {
  ITwitterInsightsAccounts,
  ITwitterInsightsPosts
} from '@/client/utils/api/twitter_insights'

import * as calculation from './calculation'

/**
 * 分析データから値を取得
 * @param {any} insight 分析データ（単体）
 * @param {string} metric 指標名
 * @returns {R} 値
 */
export function getMetricValue<R>(insight: any, metric: string): R {
  return insight[metric] ? insight[metric] : null
}

/**
 * 分析データから値の配列を取得
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {R[]} 指標データの配列
 */
export function getMetricArray<R>(insights: any[], metric: string): R[] {
  return insights.map(insight => getMetricValue(insight, metric))
}

function isCsvOrPrivateMetric(insights: any[], metric: string) {
  const is_csv_metric = constants.POST_CSV_IMPORT_METRICS.includes(metric)
  const is_csv_import = insights.some(post => post.is_csv_import)

  const is_not_get_non_public_metrics =
    insights.every(post => post.is_not_get_non_public_metrics) &&
    constants.POST_PRIVATE_METRICS.includes(metric)

  return (is_csv_metric && !is_csv_import) || is_not_get_non_public_metrics
}

/**
 * 分析データから値の合計を取得
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {number || null} 指標データの合計
 */
export function getMetricTotal(insights: any[], metric: string): number | null {
  if (isCsvOrPrivateMetric(insights, metric)) return null

  switch (metric) {
    // 率の指標は合計が存在しない
    case 'reactions_rate':
    case 'reactions_organic_rate':
    case 'reactions_paid_rate':
    case 'engagements_rate':
    case 'engagements_organic_rate':
    case 'engagements_paid_rate':
      return null

    // 累計数の場合、最終日の値を返す
    case 'followers_count':
    case 'friends_count':
    case 'listed_count': {
      const metric_array = getMetricArray<number>(insights, metric)
      return metric_array.length > 0 ? metric_array[0] : 0
    }

    default:
      return calculation.addition(getMetricArray(insights, metric))
  }
}

/**
 * 分析データから値の平均を取得
 * @param {any[]} insights 分析データ（配列）
 * @param {string} metric 指標名
 * @returns {number || null} 指標データの平均
 */
export function getMetricAverage(insights: any[], metric: string): number | null {
  if (isCsvOrPrivateMetric(insights, metric)) return null

  let numerator = 0
  let denominator = 0

  switch (metric) {
    // 反応率
    case 'reactions_rate':
      numerator = getMetricTotal(insights, 'reactions')
      denominator = getMetricTotal(insights, 'followers_at_that_time')
      return calculation.percentage(numerator, denominator)

    // 反応率(オーガニック)
    case 'reactions_organic_rate':
      numerator = getMetricTotal(insights, 'reactions_organic')
      denominator = getMetricTotal(insights, 'followers_at_that_time')
      return calculation.percentage(numerator, denominator)

    // 反応率(広告)
    case 'reactions_paid_rate':
      numerator = getMetricTotal(insights, 'reactions_paid')
      denominator = getMetricTotal(insights, 'followers_at_that_time')
      return calculation.percentage(numerator, denominator)

    // エンゲージメント率
    case 'engagements_rate':
      numerator = getMetricTotal(insights, 'engagements')
      denominator = getMetricTotal(insights, 'impressions')
      return calculation.percentage(numerator, denominator)

    // エンゲージメント率(オーガニック)
    case 'engagements_organic_rate':
      numerator = getMetricTotal(insights, 'engagements_organic')
      denominator = getMetricTotal(insights, 'impressions_organic')
      return calculation.percentage(numerator, denominator)

    // エンゲージメント率(広告)
    case 'engagements_paid_rate':
      numerator = getMetricTotal(insights, 'engagements_paid')
      denominator = getMetricTotal(insights, 'impressions_paid')
      return calculation.percentage(numerator, denominator)

    // 累計数
    case 'followers_count':
    case 'friends_count':
    case 'listed_count':
      numerator = calculation.addition(getMetricArray(insights, metric))
      denominator = insights.length
      return calculation.division(numerator, denominator, 1)

    // 動画系 (投稿テーブルの場合を考慮)
    case 'video_views':
    case 'video_views_organic':
    case 'video_views_paid':
    case 'video_playback_0_count':
    case 'video_playback_0_count_organic':
    case 'video_playback_0_count_paid':
    case 'video_playback_25_count':
    case 'video_playback_25_count_organic':
    case 'video_playback_25_count_paid':
    case 'video_playback_50_count':
    case 'video_playback_50_count_organic':
    case 'video_playback_50_count_paid':
    case 'video_playback_75_count':
    case 'video_playback_75_count_organic':
    case 'video_playback_75_count_paid':
    case 'video_playback_100_count':
    case 'video_playback_100_count_organic':
    case 'video_playback_100_count_paid':
      numerator = getMetricTotal(insights, metric)
      denominator = insights.filter((v: any) => v.type === 'video').length
      return calculation.division(numerator, denominator, 1)

    // 短縮URLクリック数 (投稿テーブルの場合を考慮)
    case 'short_url_clicks':
      numerator = getMetricTotal(insights, metric)
      denominator = insights.filter((v: any) => v.use_short_url).length
      return calculation.division(numerator, denominator, 1)

    // その他の指標
    default:
      numerator = getMetricTotal(insights, metric)
      denominator = insights.length
      return calculation.division(numerator, denominator, 1)
  }
}

/**
 * ページ分析をキーによってフィルタリング
 * @param {ITwitterInsightsAccounts[]} daily_data ページ分析データ
 * @param {ITwitterInsightsPosts[]} post_data 投稿分析データ
 * @param {string} key 取得するキー
 * @returns {ITwitterInsightsAccounts[]} 合計値
 */
export function filterDailyData(
  daily_data: ITwitterInsightsAccounts[],
  post_data: ITwitterInsightsPosts[],
  key: string
): ITwitterInsightsAccounts[] {
  switch (key) {
    case 'post_count':
      return daily_data.map(account => {
        const post_count = post_data.filter(post =>
          moment(post.created_time).isSame(moment(account.end_time), 'day')
        ).length

        return { ...account, post_count }
      })

    default:
      return [...daily_data]
  }
}

/**
 * 投稿分析をキーによってフィルタリング
 * @param {ITwitterInsightsPosts[]} post_data 投稿分析データ
 * @param {string} key 取得するキー
 * @returns {ITwitterInsightsPosts[]} 合計値
 */
export function filterPostData(
  post_data: ITwitterInsightsPosts[],
  key: string
): ITwitterInsightsPosts[] {
  switch (key) {
    case 'video_views':
    case 'video_views_organic':
    case 'video_views_paid':
    case 'video_playback_0_count':
    case 'video_playback_0_count_organic':
    case 'video_playback_0_count_paid':
    case 'video_playback_25_count':
    case 'video_playback_25_count_organic':
    case 'video_playback_25_count_paid':
    case 'video_playback_50_count':
    case 'video_playback_50_count_organic':
    case 'video_playback_50_count_paid':
    case 'video_playback_75_count':
    case 'video_playback_75_count_organic':
    case 'video_playback_75_count_paid':
    case 'video_playback_100_count':
    case 'video_playback_100_count_organic':
    case 'video_playback_100_count_paid':
      return post_data.filter(post => post.type === 'video')
    case 'short_url_clicks':
      return post_data.filter(post => post.use_short_url)
    default:
      return [...post_data]
  }
}

/**
 * 指標によって値の表示方法を変換する
 * @param {number} value 値
 * @param {string} metric 指標
 * @returns {string} 加工した値文字列
 */
export function convertValueWithMetric(value: number, metric: string): string {
  switch (metric) {
    case 'reactions_rate':
    case 'reactions_organic_rate':
    case 'reactions_paid_rate':
    case 'engagements_rate':
    case 'engagements_organic_rate':
    case 'engagements_paid_rate':
      if (!value) return '0%'
      return `${value.toLocaleString()}%`
    case 'followers_count_up_down':
    case 'friends_count_up_down':
    case 'listed_count_up_down':
      if (!value) return '0'
      if (Math.sign(value) === 1) return `+${value.toLocaleString()}`
      return value.toLocaleString()
    default:
      if (!value) return '0'
      return value.toLocaleString()
  }
}
