import { GridItem, GridLayout } from 'vue-grid-layout'
import { Component, Vue } from 'vue-property-decorator'

import * as constants from '@/client/components-old/_constants/instagram_analytics'
import Box from '@/client/components-old/atoms/Box'
import Legend from '@/client/components-old/atoms/Legend'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import InstagramAnalyticsChartHorizontal from '@/client/components-old/organisms/InstagramAnalyticsChartHorizontal'
import InstagramAnalyticsChartMap from '@/client/components-old/organisms/InstagramAnalyticsChartMap'
import InstagramAnalyticsChartVertical from '@/client/components-old/organisms/InstagramAnalyticsChartVertical'
import InstagramAnalyticsTotal from '@/client/components-old/organisms/InstagramAnalyticsTotal'

@Component({
  name: 'InstagramAnalyticsFollower',
  components: {
    GridLayout,
    GridItem,
    InstagramAnalyticsChartVertical,
    InstagramAnalyticsTotal,
    InstagramAnalyticsChartHorizontal,
    InstagramAnalyticsChartMap,
    Legend,
    Box,
    Message,
    Scroll
  }
})
export default class InstagramAnalyticsFollower extends Vue {
  get legend_data() {
    return constants.DATA_TYPES.map(type => ({ text: type.text, color: type.color }))
  }

  get grid_layout() {
    return [
      {
        x: 0,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:フォロワー数(累計)',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'followers_count',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 3,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:フォロワー数(増減)',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'followers_count_up_down',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 6,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:主な性別・年代',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'audience_gender_age',
          unit: 'major',
          is_view_extra: true
        }
      },
      {
        x: 9,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:主な国',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'audience_country',
          unit: 'major',
          is_view_extra: true
        }
      },
      {
        x: 0,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:主な都道府県',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'audience_city',
          unit: 'major',
          is_view_extra: true
        }
      },
      {
        x: 3,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:アクティブな時間帯',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'online_followers',
          unit: 'major',
          is_view_extra: true
        }
      },
      {
        x: 0,
        y: 4,
        w: 12,
        h: 4,
        i: '垂直チャート:フォロワー数(累計)',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'followers_count',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 8,
        w: 12,
        h: 4,
        i: '垂直チャート:フォロワー数(増減)',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'followers_count_up_down',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 12,
        w: 12,
        h: 4,
        i: '垂直チャート:フォロー数(累計)',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'follows_count',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 16,
        w: 6,
        h: 5,
        i: '水平チャート:性別・年代',
        name: 'InstagramAnalyticsChartHorizontal',
        options: {
          data_type: 'daily_data',
          metric: 'audience_gender_age'
        }
      },
      {
        x: 0,
        y: 21,
        w: 6,
        h: 6,
        i: '水平チャート:フォロワー数(場所別ベスト10)',
        name: 'InstagramAnalyticsChartHorizontal',
        options: {
          data_type: 'daily_data',
          metric: 'audience_location'
        }
      },
      {
        x: 6,
        y: 16,
        w: 6,
        h: 4,
        i: '垂直チャート:オンラインの時間帯',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'online_followers',
          interval: 'hour',
          unit: '',
          is_change_interval: false
        }
      },
      {
        x: 6,
        y: 20,
        w: 6,
        h: 5,
        i: 'マップチャート:フォロワー数(国)',
        name: 'InstagramAnalyticsChartMap',
        options: {
          metric: 'audience_country'
        }
      },
      {
        x: 6,
        y: 25,
        w: 6,
        h: 5,
        i: 'フォロワー数(都道府県)',
        name: 'InstagramAnalyticsChartMap',
        options: {
          metric: 'audience_city'
        }
      }
    ]
  }
}
