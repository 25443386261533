import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import * as regex from '@/client/utils/regex'

const setting = namespace('personal_setting')
const notification = namespace('notification')

@Component({
  name: 'PersonalSettingTwoFactorDialog',
  components: {
    Button,
    Dialog,
    Input,
    Message
  }
})
export default class PersonalSettingTwoFactorDialog extends Vue {
  @setting.Action('postTwoFactorSecret') postTwoFactorSecret
  @setting.Action('postTwoFactorApproval') postTwoFactorApproval
  @notification.Action('showNotification') showNotification

  visible = false
  qrcode_url = ''
  auth_code = ''

  get is_qrcode() {
    return this.qrcode_url !== ''
  }

  get disabled() {
    return !regex.AUTH_CODE_FORMAT.test(this.auth_code)
  }

  /**
   * ダイアログの表示
   */
  async open(): Promise<void> {
    this.visible = true
    this.qrcode_url = ''
    this.auth_code = ''
    await this.getQRCode()
  }

  /**
   * ダイアログの非表示
   */
  hide(): void {
    this.visible = false
  }

  /**
   * ダイアログの送信
   */
  async submit(): Promise<void> {
    await this.setTwoFactor()
  }

  /**
   * QRコードを取得する
   */
  async getQRCode(): Promise<void> {
    const response = await this.postTwoFactorSecret()

    if (response && response.data) {
      this.qrcode_url = response.data.qrcode_url
      return
    }

    this.showNotification({
      title: 'QRコードの生成に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })

    this.hide()
  }

  /**
   * 2段階認証の設定をする
   */
  async setTwoFactor(): Promise<void> {
    const response = await this.postTwoFactorApproval(this.auth_code)

    if (response && response.data) {
      this.showNotification({ title: '2段階認証を設定しました。' })

      return this.hide()
    }

    this.showNotification({
      title: '2段階認証の設定に失敗しました。',
      message: 'コードを確認してください。',
      type: 'error'
    })
  }
}
