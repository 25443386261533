import { Component, Prop, Vue } from 'vue-property-decorator'

import * as constants from '@/client/components-old/_constants/facebook_target'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'

type TTarget = {
  age_min: number
  countries: string[]
  regions: number[]
}

type TConstant = {
  text: string
  value: string | number
}

@Component({
  name: 'LabelFacebookTarget',
  components: {
    Icon,
    Flex,
    Tooltip
  }
})
export default class LabelFacebookTarget extends Vue {
  @Prop({ type: Object, required: true })
  target: TTarget

  get age() {
    return this.target.age_min ? `${this.target.age_min}+` : ''
  }

  get countries() {
    return this.getText(this.target.countries, constants.COUNTRIES)
  }

  get regions() {
    return this.getText(this.target.regions, constants.REGIONS)
  }

  /**
   * テキストを取得
   * @param {any} target
   * @param {TConstant[]} constant
   * @returns {string} テキスト
   */
  getText(target: any, constant: TConstant[]): string {
    const texts = []

    if (!target || !target.length) {
      return ''
    }

    target.forEach(t => {
      const text = constant.find(c => c.value === t)

      if (!text) {
        return
      }

      texts.push(this.$options.filters.translate(text.text))
    })

    return texts.join(', ')
  }
}
