
import { defineComponent } from 'vue'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { getTranslateText as t } from '@/client/utils/filters'

export default defineComponent({
  name: 'GroupSettingPostApproval',
  components: {
    Icon,
    Panel,
    Tooltip,
    ButtonLink
  },
  setup() {
    return {
      t
    }
  }
})
