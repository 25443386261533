export function createMention(mention: string): string {
  return `<a href="https://www.tiktok.com/${mention}" class="mention" target="_blank">${mention}</a>`
}

export function autoLinkTikTokMentions(text: string): string {
  // ? API経由だとユーザーネームのみメンションできる
  return text.replace(/@[^@#/\s]+/g, mention => {
    const username = mention.replace(/^@/, '')
    if (username.length < 1 || username.length > 24) return mention
    if (/[^\w\\.]+/.test(username) || username.endsWith('.')) return mention
    return createMention(mention)
  })
}
