
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import { TrackingService } from '@/client/services'
import { getTranslateText as t } from '@/client/utils/filters'

export default defineComponent({
  name: 'PostCreateInstagramCommentSetting',
  components: {
    Icon,
    Panel,
    Tooltip,
    SwitchButton
  },
  setup() {
    return {
      t
    }
  },
  computed: {
    ...mapState('post_create', ['instagram_comment_enabled', 'instagram_first_comment_message'])
  },
  methods: {
    ...mapActions('post_create', [
      'changeInstagramFirstCommentMessage',
      'changeInstagramCommentEnabled'
    ]),

    /**
     * Instagramコメント制限を変更
     */
    changeInstagramCommentDisabled(instagram_comment_disabled: boolean) {
      TrackingService.sendEvent('switch:投稿作成|IGコメントを制限')

      if (instagram_comment_disabled && this.instagram_first_comment_message !== null) {
        const message =
          t('コメント制限をONにすると、最初のコメントがリセットされます。') +
          '\n' +
          t('ONにしてよろしいですか?')

        if (!confirm(message)) return

        this.changeInstagramFirstCommentMessage(null)
      }

      this.changeInstagramCommentEnabled(!instagram_comment_disabled)
    }
  }
})
