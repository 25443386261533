import { Component, Prop, Vue } from 'vue-property-decorator'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'

@Component({
  name: 'InputKeyValue',
  components: {
    Button,
    Icon,
    Input
  }
})
export default class InputKeyValue extends Vue {
  @Prop({ type: Object, required: true })
  value: { [x: string]: string }

  list: { key: string; value: string }[] = []

  created() {
    this.list = Object.keys(this.value).map(key => ({ key: key, value: this.value[key] }))
  }

  get input_value() {
    return this.value
  }

  set input_value(value) {
    this.$emit('input', value)
  }

  /**
   * 項目の追加
   * @returns {void}
   */
  addItem(): void {
    this.list.push({ key: '', value: '' })
  }

  /**
   * 項目の削除
   * @param {number} index
   * @returns {void}
   */
  removeItem(index: number): void {
    this.list = this.list.filter((key, idx) => index !== idx)
    this.setValue()
  }

  /**
   * 値の変更
   * @returns {void}
   */
  setValue(): void {
    const input_value = this.list
      .filter(key => key.key.trim() !== '')
      .map(key => ({ [key.key]: key.value }))

    this.input_value = Object.assign({}, ...input_value)
  }
}
