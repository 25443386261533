import { RouteConfig } from 'vue-router'

import TikTokAnalyticsReport from './pages/TikTokAnalyticsReport.vue'

export const report_tt: RouteConfig = {
  component: TikTokAnalyticsReport,
  path: '/report_tt',
  name: 'report_tt',
  meta: {
    auth: true,
    title: 'Excelレポート (TikTok)',
    description: 'SNSアカウントのExcelレポートがダウンロードできます。',
    color: 'warning',
    type: 'DOWNLOAD_EXCEL_REPORT_OWN_ANALYSIS'
  }
}
