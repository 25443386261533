import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Checkbox from '@/client/components-old/atoms/Checkbox'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import MonitoringContent from '@/client/components-old/molecules/MonitoringContent'
import RecentMemo from '@/client/components-old/molecules/RecentMemo'
import MonitoringAction from '@/client/components-old/organisms/MonitoringAction'
import MonitoringPending from '@/client/components-old/organisms/MonitoringPending'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IGetter as IAccountGetter } from '@/client/store/modules/accounts'
import { IState as IMonitoringState } from '@/client/store/modules/monitoring'
import { TMonitoring } from '@/client/utils/api/monitorings'

const accounts = namespace('accounts')
const monitoring = namespace('monitoring')
const notification = namespace('notification')

@Component({
  name: 'MonitoringItem',
  components: {
    ButtonLink,
    Checkbox,
    Flex,
    Icon,
    Message,
    Panel,
    Tooltip,
    Account,
    MonitoringContent,
    RecentMemo,
    MonitoringAction,
    MonitoringPending
  }
})
export default class MonitoringItem extends Vue {
  @State('user') user: IRootState['user']
  @accounts.Getter('monitoring') accounts: IAccountGetter['monitoring']
  @monitoring.State('checked_monitorings')
  checked_monitorings: IMonitoringState['checked_monitorings']
  @monitoring.State('api_words') words: IMonitoringState['api_words']
  @monitoring.State('api_setting') setting: IMonitoringState['api_setting']
  @monitoring.Action('createAccountBlock') createAccountBlock
  @monitoring.Action('removeAccountBlock') removeAccountBlock
  @monitoring.Action('selectMonitoring') selectMonitoring
  @monitoring.Action('updateMonitoringStatus') updateMonitoringStatus
  @notification.Action('showNotification') showNotification

  @Prop({ type: Object, required: true })
  monitoring: TMonitoring

  @Prop({ type: String, default: 'list' })
  mode: 'list' | 'preview'

  loading = false

  visible_pending = false

  current_date = new Date().toUTCString()

  mounted() {
    setInterval(() => {
      this.current_date = new Date().toUTCString()
    }, 60 * 1000)
  }

  get checked() {
    return this.checked_monitorings.some(
      v => v.monitoring_id === this.monitoring.id && v.sns_type === this.monitoring.sns
    )
  }

  set checked(val) {
    this.selectMonitoring({
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      is_checked: val
    })
  }

  get is_expired() {
    const account = this.accounts.find(
      account => account.id === this.monitoring.account_id && account.sns === this.monitoring.sns
    )

    if (!account) {
      return false
    }

    return account.expired
  }

  get is_preview() {
    return this.mode === 'preview'
  }

  get is_list() {
    return this.mode === 'list'
  }

  get recent_memo() {
    if (!this.monitoring.memo) return null

    return {
      message: this.monitoring.memo.message,
      created: this.monitoring.memo.created,
      name: this.monitoring.memo.user.name,
      picture_url: this.monitoring.memo.user.picture_url
    }
  }

  get is_admin() {
    const account = this.accounts.find(
      account =>
        account.id === this.monitoring.own_account.id && account.sns === this.monitoring.sns
    )

    if (account && account.role === 'admin') {
      return true
    }

    return false
  }

  get is_facebook() {
    return this.monitoring.sns === 'facebook'
  }

  get is_twitter() {
    return this.monitoring.sns === 'twitter'
  }

  get is_instagram() {
    return this.monitoring.sns === 'instagram'
  }

  get is_same_account() {
    const { from_account, own_account } = this.monitoring

    const same_account_instagram =
      this.is_instagram && from_account.username === own_account.username
    const same_account_other = !this.is_instagram && from_account.id === own_account.id

    return same_account_instagram || same_account_other
  }

  get is_comment() {
    return this.monitoring.type === 'comment'
  }

  get is_read() {
    return this.monitoring.status === 'read'
  }

  get is_pending() {
    return this.monitoring.status === 'pending'
  }

  get is_reply() {
    return this.monitoring.is_reply === true
  }

  get is_conversation_history() {
    let valid_types = []

    switch (this.monitoring.sns) {
      case 'facebook':
        valid_types = ['comment', 'message']
        break
      case 'twitter':
        valid_types = ['mention', 'reply', 'message']
        break
      case 'instagram':
        valid_types = ['comment']
        break
      default:
        return false
    }

    return valid_types.includes(this.monitoring.type) && !this.is_same_account
  }

  get is_can_block() {
    const is_role = this.is_admin || this.setting.observer_allow_block

    return this.is_show_block && is_role
  }

  get is_show_block() {
    return !this.is_same_account
  }

  get is_monitoring_action() {
    return !(
      this.monitoring.sns === 'twitter' &&
      this.monitoring.type === 'message' &&
      this.setting &&
      !this.setting.observer_allow_reply &&
      !this.is_admin
    )
  }

  get is_facebook_timeout() {
    const LIMIT_HOUR = 24

    return (
      this.monitoring.sns === 'facebook' &&
      this.monitoring.type === 'message' &&
      moment(this.current_date).diff(this.monitoring.datetime, 'hour') >= LIMIT_HOUR
    )
  }

  get icon_type() {
    switch (this.monitoring.type) {
      case 'comment':
      case 'reply':
        return 'chat'
      case 'post':
        return 'description'
      case 'message':
        return 'email'
      case 'mention':
        return 'at'
      default:
        return ''
    }
  }

  get icon_tooltip() {
    switch (this.monitoring.type) {
      case 'comment':
        return 'コメント'
      case 'post':
        return '投稿'
      case 'message':
        return 'メッセージ'
      case 'mention':
        return 'メンション'
      case 'reply':
        return 'リプライ'
      default:
        return ''
    }
  }

  get read_color() {
    return this.is_read ? 'success' : 'default'
  }

  get pending_color() {
    return this.is_pending ? 'warning' : 'default'
  }

  get panel_type() {
    if (this.mode === 'preview') {
      return ''
    }

    switch (this.monitoring.status) {
      case 'unread':
        return 'info'
      case 'read':
        return 'success'
      case 'pending':
        return 'warning'
      default:
        return ''
    }
  }

  @Emit('update-monitoring')
  updateMonitoring(payload: any) {
    return payload
  }

  @Emit('open-detail')
  openDetailDialog(tab: 'memo' | 'history') {
    TrackingService.sendEvent(`click:モニタリング|レコード|${tab}`)

    return {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      tab
    }
  }

  @Emit('open-post')
  openPostDialog() {
    return {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns
    }
  }

  @Emit('open-conversation')
  openConversationDialog() {
    TrackingService.sendEvent('click:モニタリング|レコード|会話履歴')

    return {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns,
      type: this.monitoring.type
    }
  }

  @Emit('open-template')
  openTemplateDialog(event: Event) {
    return event
  }

  /**
   * SNSのプロフィールを表示
   */
  openSnsProfile() {
    TrackingService.sendEvent('click:モニタリング|レコード|プロフィール')

    switch (this.monitoring.sns) {
      case 'twitter':
        window.open(`http://x.com/${this.monitoring.from_account.username}`, '_blank')
        break
      case 'instagram':
        window.open(`http://instagram.com/${this.monitoring.from_account.username}/`, '_blank')
        break
      default:
        break
    }
  }

  /**
   * モニタリングの保留を閉じる
   */
  closeMonitoringPending() {
    this.visible_pending = false
  }

  /**
   * 既読にする
   */
  async changeRead() {
    TrackingService.sendEvent('click:モニタリング|レコード|ステータス変更:既読')

    const params = {
      monitoring_id: this.monitoring.id,
      status: 'read',
      sns_type: this.monitoring.sns
    }

    this.loading = true

    const result = await this.updateMonitoringStatus(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.updateMonitoring(params)

      this.showNotification({ title: '既読に変更しました。' })
    } else {
      this.showNotification({
        title: '既読の変更に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * 保留のクリック
   */
  handlePendingClick() {
    TrackingService.sendEvent('click:モニタリング|レコード|ステータス変更:保留')
  }

  /**
   * アカウントをブロックする
   */
  async blockAccount() {
    TrackingService.sendEvent('click:モニタリング|レコード|ブロック')

    if (this.monitoring.from_account.is_block) return

    const facebook_message = this.$options.filters.translate(
      'ブロックすると対象アカウントからの投稿・コメントが自動的に非表示になります。 よろしければ、[OK] をクリックしてください。'
    )

    const instagram_message = this.$options.filters.translate(
      'ブロックすると対象アカウントからのコメントが自動的に非表示になります。 よろしければ、[OK] をクリックしてください。'
    )

    const twitter_message = this.$options.filters.translate(
      'ブロックすると対象アカウントからの返信が自動的に非表示になります。 よろしければ、[OK] をクリックしてください。'
    )

    let message = ''

    if (this.is_facebook) {
      message = facebook_message
    } else if (this.is_instagram) {
      message = instagram_message
    } else if (this.is_twitter) {
      message = twitter_message
    }

    const confirm = window.confirm(message)

    if (!confirm) return

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns
    }

    this.loading = true

    const result = await this.createAccountBlock(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.updateMonitoring(params)

      this.showNotification({ title: 'アカウントをブロックしました。' })
    } else {
      this.showNotification({
        title: 'アカウントのブロックに失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }

  /**
   * アカウントをブロック解除する
   */
  async unblockAccount() {
    if (!this.monitoring.from_account.is_block) return

    const params = {
      monitoring_id: this.monitoring.id,
      sns_type: this.monitoring.sns
    }

    this.loading = true

    const result = await this.removeAccountBlock(params)

    this.$nextTick(() => {
      this.loading = false
    })

    if (result.data) {
      this.updateMonitoring(params)

      this.showNotification({ title: 'アカウントをブロック解除しました。' })
    } else {
      this.showNotification({
        title: 'アカウントのブロック解除に失敗しました。',
        message: '恐れ入りますが、時間をおいて再度お試しください。',
        type: 'error'
      })
    }
  }
}
