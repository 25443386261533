import { render, staticRenderFns } from "./FacebookComparisonAnalyticsAccountTable.vue?vue&type=template&id=247582ec&scoped=true"
import script from "./FacebookComparisonAnalyticsAccountTable.ts?vue&type=script&lang=ts&external"
export * from "./FacebookComparisonAnalyticsAccountTable.ts?vue&type=script&lang=ts&external"
import style0 from "./FacebookComparisonAnalyticsAccountTable.vue?vue&type=style&index=0&id=247582ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247582ec",
  null
  
)

export default component.exports