import { Component, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import GroupSettingAnalyticsTwitterKeyword from '@/client/components-old/organisms/GroupSettingAnalyticsTwitterKeyword'

@Component({
  name: 'GroupSettingAnalyticsLayout',
  components: {
    Scroll,
    GroupSettingAnalyticsTwitterKeyword
  }
})
export default class GroupSettingAnalyticsLayout extends Vue {}
