
import { Component, Prop, Vue } from 'vue-property-decorator'

import Tooltip from '@/client/components-old/atoms/Tooltip'

@Component({
  name: 'EmbedFacebook',
  components: {
    Tooltip
  }
})
export default class EmbedFacebook extends Vue {
  @Prop({ type: String, required: true })
  pageId!: string

  @Prop({ type: String, required: true })
  postId!: string

  @Prop({ type: String, default: '500' })
  width!: string

  $refs!: {
    embed: HTMLDivElement
  }

  get url() {
    // FB.XFBMLで表示するためURL形式を古くする
    return `https://www.facebook.com/${this.pageId}/posts/${this.postId}`
  }

  mounted() {
    this.displayFacebookPost()
  }

  /**
   * Facebook投稿を表示
   */
  displayFacebookPost() {
    if (!Object.prototype.hasOwnProperty.call(window, 'FB')) return

    window.FB.XFBML.parse(this.$refs.embed)
  }
}
