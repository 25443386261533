import API from '@/client/utils/api'
import {
  TFacebookAccount,
  TGetOauthFacebookAccountsParams,
  TGetOauthFacebookAccountsResponses
} from '@/client/utils/api/oauth'
import { api, getLoginStatus, login } from '@/client/utils/facebook-sdk'

type TFacebookUser = {
  id: string
  name: string
}

type TAccount = TFacebookAccount & {
  user: TFacebookUser
}

// TODO: dataの構造を統一する必要がある
// src/client/components-old/_utils/instagram_authentication.ts
type TAuthResponse = {
  data?: {
    accounts?: TAccount[]
    invalid_accounts: {
      account_id: string
      sns_type: string
      name: string
      image_url: string
      fb_user_name: string
      project_name: string
    }[]
    user_access_token?: string
  }
  error?: {
    type: string
    title: string
  }
}

/**
 * Facebook認証
 */
export async function execAuth(project_id: number): Promise<TAuthResponse> {
  const result = await auth()

  if (!result || !result.status || result.status !== 'connected') {
    return {
      error: {
        type: 'AUTHENTICATION_CANCELED',
        title: 'Facebook認証が取り消されました。'
      }
    }
  }

  const scope = await getScope(project_id, result.authResponse.accessToken)

  const response = await getAccounts(project_id, result.authResponse.accessToken)

  if (response.error && response.error.type === 'OAUTH_FACEBOOK_PAGE_NOT_EXIST') {
    return {
      error: {
        type: 'OAUTH_FACEBOOK_PAGE_NOT_EXIST',
        title: 'Facebookページが存在しません。'
      },
      data: {
        invalid_accounts: scope.invalid_accounts
      }
    }
  } else if (!response.data) {
    return {
      error: {
        type: 'OAUTH_FAILED',
        title: 'アカウント取得に失敗しました。'
      },
      data: {
        invalid_accounts: scope.invalid_accounts
      }
    }
  }

  if (!scope || scope.invalid_scopes.length) {
    return {
      error: {
        type: 'PERMISSION_DENIED',
        title: 'comnicoに許可されていないアクセス設定があります。'
      },
      data: {
        invalid_accounts: scope.invalid_accounts
      }
    }
  }

  const user = await api<TFacebookUser>('/me?fields=id,name')

  const accounts = response.data.map(v => ({ ...v, user }))

  return {
    data: {
      accounts,
      invalid_accounts: scope.invalid_accounts,
      user_access_token: result.authResponse.accessToken
    }
  }
}

/**
 * Facebookのページ管理の許可認証を行う
 */
async function auth() {
  let res = await getLoginStatus()

  if (res.status !== 'connected') {
    res = await login()
  }

  return res
}

/**
 * Facebook認証権限をチェック
 */
async function getScope(project_id: number, access_token: string) {
  const response = await API.post('facebook_accounts/scope_check', {
    project_id,
    access_token
  })

  if (!response.data || !response.data.data) {
    return null
  }

  return response.data.data
}

/**
 * 管理しているアカウント一覧を取得
 */
async function getAccounts(
  project_id: number,
  access_token: string
): Promise<TGetOauthFacebookAccountsResponses> {
  const params: TGetOauthFacebookAccountsParams = {
    project_id,
    access_token
  }
  const response = await API.get<TGetOauthFacebookAccountsResponses>('oauth/facebook/accounts', {
    params
  })

  return response.data
}
