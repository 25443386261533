import $ from 'jquery'
import moment from 'moment-timezone'
import twttr from 'twitter-text'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

import { ZoomBotton } from '@/client/components/atoms'
import { IRootState } from '@/client/store/global'

type TCallToActions =
  | 'NO_BUTTON'
  | 'LEARN_MORE'
  | 'OPEN_LINK'
  | 'DOWNLOAD'
  | 'SIGN_UP'
  | 'BOOK_TRAVEL'
  | 'USE_APP'
  | 'INSTALL_APP'
  | 'LISTEN_MUSIC'
  | 'WATCH_VIDEO'
  | 'WATCH_MORE'

@Component({
  name: 'PreviewFacebook',
  components: {
    ZoomBotton
  }
})
export default class PreviewFacebook extends Vue {
  @State('user') user: IRootState['user']

  @Prop({ type: String, default: 'app' })
  mode: 'app' | 'web'

  @Prop({ type: Boolean, default: false })
  isReadMore: boolean

  @Prop({ type: Object, required: true })
  options: {
    screen_name: null | string
    user_name: null | string
    profile_image_url: null | string
    release_date: null | string
    type: 'status' | 'photo' | 'link' | 'video'
    message: string
    image_urls: string[]
    video_url: null | string
    link_url: null | string
    link_card: boolean
    links: {
      domain: string
      url: string
      image: string
      title: string
      description: string
      call_to_action: TCallToActions
    }[]
  }

  $refs: {
    container: HTMLDivElement
    scroll: HTMLDivElement
    text: HTMLDivElement
    video: HTMLVideoElement
  }

  is_video_button = true
  is_link_small = false

  read_more_show = false
  read_more_style: { maxHeight: string | null } = { maxHeight: null }

  carousel_step = 0

  get is_app() {
    return this.mode === 'app'
  }

  get is_web() {
    return this.mode === 'web'
  }

  get is_image() {
    return this.options.type === 'photo' && this.options.image_urls.length > 0
  }

  get is_video() {
    return (
      this.options.type === 'video' &&
      this.options.video_url !== null &&
      this.options.video_url !== ''
    )
  }

  get is_link() {
    return (
      this.options.type === 'link' &&
      this.options.link_url !== null &&
      this.options.link_url !== '' &&
      this.options.links.length === 1
    )
  }

  get is_carousel() {
    return (
      this.options.type === 'link' &&
      this.options.link_url !== null &&
      this.options.link_url !== '' &&
      this.options.links.length > 1
    )
  }

  get message() {
    this.read_more_show = false

    let message = this.options.message

    message = this.multilineEscape(message)

    if (this.is_app) {
      this.read_more_style.maxHeight = null

      if (this.isReadMore) {
        // DOMの高さが12行を超えたらCSSでカットする
        // 画像や動画やリンクの場合は3行の高さ
        // もっと見る表示は再現できないので下に表示する
        this.$nextTick(() => {
          const height =
            this.is_image || this.is_video || this.is_link || this.is_carousel ? 56 : 240

          this.read_more_show = this.$refs.text.clientHeight > height

          this.read_more_style.maxHeight = `${height}px`
        })
      }
    }

    if (this.is_web && this.isReadMore) {
      const temp_message = message
      const max_length = 480
      const max_lines = 5

      // 480文字を超えたらカット
      if (message.length > max_length) {
        message = message.slice(0, max_length)
      }

      const words = message.split('\n')

      // 5行を超えたらカット
      if (words.length > max_lines) {
        message = words.slice(0, max_lines).join('\n')
      }

      this.read_more_show = message !== temp_message
    }

    message = this.htmlEscape(message)

    // スペースが無い場合にTwitterと処理が違う部分があるので独自に対応する
    message = this.autoLinkUrlsFacebook(message)

    message = twttr.autoLinkHashtags(message, {
      hashtagUrlBase: 'https://www.facebook.com/hashtag/',
      hashtagClass: 'facebook-url hashtag',
      targetBlank: true
    })

    return message
  }

  get carousel_count() {
    return this.options.link_card ? this.options.links.length : this.options.links.length - 1
  }

  get is_carousel_prev() {
    return this.carousel_step > 0
  }

  get is_carousel_next() {
    return this.carousel_step < this.carousel_count
  }

  get carousel_style() {
    let container_width = 0
    let container_margin = 0
    let image_width = 0
    let image_margin = 0
    let translate_x = 0

    if (this.is_app) {
      container_width = 375
      container_margin = 12
      image_width = 258
      image_margin = 12
    }

    if (this.is_web) {
      container_width = 500
      container_margin = 12
      image_width = 300
      image_margin = 6
    }

    // 最後
    if (this.carousel_step === this.carousel_count) {
      translate_x =
        (image_width + image_margin) * this.carousel_step -
        (container_width - image_width) +
        container_margin * 2 +
        container_margin
    }

    // 途中
    if (this.carousel_step > 0 && this.carousel_step < this.carousel_count) {
      translate_x =
        (image_width + image_margin) * this.carousel_step -
        (container_width - image_width) / 2 +
        container_margin
    }

    return { transform: `translateX(-${translate_x}px)` }
  }

  get release_date() {
    const format = this.user.language === 'ja' ? 'M月D日 H:mm' : 'D MMMM [at] H:mm'

    return moment(this.options.release_date).format(format)
  }

  get text_see_more() {
    return this.user.language === 'ja' ? 'もっと見る' : 'See more'
  }

  get text_see_more_at() {
    if (!this.is_carousel) return ''

    let domain = this.getDomain(this.options.links[0].domain)

    if (this.is_app) {
      return this.user.language === 'ja' ? `もっと見る: ${domain}` : `See more at: ${domain}`
    }

    domain = domain.toUpperCase()

    return this.user.language === 'ja' ? `もっと見る<br>${domain}` : `See more at<br>${domain}`
  }

  get text_like() {
    return this.user.language === 'ja' ? 'いいね！' : 'Like'
  }

  get text_comment() {
    if (this.user.language === 'ja' && this.is_app) return 'コメントする'

    return this.user.language === 'ja' ? 'コメント' : 'Comment'
  }

  get text_share() {
    return this.user.language === 'ja' ? 'シェア' : 'Share'
  }

  @Emit('click-media')
  clickMedia(index: number) {
    return {
      index,
      media: this.options.image_urls.map(url => ({ type: 'image', url }))
    }
  }

  /**
   * マウント時
   */
  async mounted(): Promise<void> {
    if (this.is_app) {
      $(this.$refs.scroll).slimScroll({
        height: this.$refs.container.clientHeight + 'px',
        size: '6px',
        wheelStep: 10,
        touchScrollStep: 50
      })
    }
  }

  /**
   * マルチ改行を除去する
   */
  multilineEscape(text: string): string {
    return text.replace(/\n\n\s*\n/g, '\n\n')
  }

  /**
   * HTMLで使用する特殊文字を除去する
   */
  htmlEscape(text: string): string {
    const entities = {
      '>': '&gt;',
      '<': '&lt;',
      '"': '&quot;',
      "'": '&#39;'
    }

    return text.replace(/["'><]/g, character => entities[character])
  }

  /**
   * 背景画像用のスタイル生成L
   */
  styleBackgroundImage(image_url?: string | null): { 'background-image': string } | null {
    if (!image_url || image_url === '') return null
    return { 'background-image': 'url(' + image_url + ')' }
  }

  /**
   * 動画のクリックイベント
   */
  async handleClickVideo(): Promise<void> {
    this.is_video_button = !this.$refs.video.paused

    await this.$refs.video.play()

    if (this.is_video_button) {
      this.$refs.video.pause()
    }
  }

  /**
   * ドメインの文字列を取得
   */
  getDomain(value: string | null): string {
    if (!value) return ''
    return value.replace(/^www\./, '')
  }

  /**
   * CTAの文字列を取得
   */
  callToAction(value: TCallToActions): string {
    switch (value) {
      case 'NO_BUTTON':
        return 'ボタンなし'
      case 'LEARN_MORE':
        return '詳しくはこちら'
      case 'OPEN_LINK':
        return 'リンクを開く'
      case 'DOWNLOAD':
        return 'ダウンロード'
      case 'SIGN_UP':
        return '登録する'
      case 'BOOK_TRAVEL':
        return '予約する'
      case 'USE_APP':
        return 'アプリを利用'
      case 'INSTALL_APP':
        return 'アプリをインストール'
      case 'LISTEN_MUSIC':
        return '音楽を聴く'
      case 'WATCH_VIDEO':
        return '動画を見る'
      case 'WATCH_MORE':
        return '他の動画を視聴'
      default:
        return ''
    }
  }

  /**
   * 画像読み込み
   */
  loadImage(image_url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image()

      image.onload = () => {
        resolve(image)
      }

      image.onerror = e => {
        reject(e)
      }

      image.src = image_url
    })
  }

  @Watch('options.links', { immediate: true, deep: true })
  async watchOptionsLinks() {
    const link_image = this.options.links[0] ? this.options.links[0].image : ''

    this.is_link_small = false

    try {
      const image = await this.loadImage(link_image)

      if (this.is_app) {
        // 画像サイズの横幅が280px以下または高さが147px以下の場合、小さいサムネイル表示にする
        this.is_link_small = image.width < 280 || image.height < 147
      }

      if (this.is_web) {
        // 画像サイズの横幅が382px以下または高さが200px以下の場合、小さいサムネイル表示にする。
        this.is_link_small = image.width < 382 || image.height < 200
      }
    } catch (e) {
      this.is_link_small = false
    }
  }

  /**
   * カルーセルを戻す
   */
  carouselPrev(): void {
    this.carousel_step = this.carousel_step - 1
  }

  /**
   * カルーセルを進める
   */
  carouselNext(): void {
    this.carousel_step = this.carousel_step + 1
  }

  /**
   * カルーセルを進める
   */
  carouselClick(index: number): void {
    if (this.carousel_step === index) {
      let url = ''

      if (this.options.links[this.carousel_step]) {
        url = this.options.links[this.carousel_step].url
      } else {
        url = this.options.link_url
      }

      url = url.indexOf('://') === -1 ? `http://${url}` : url

      window.open(url, '_blank')
    } else {
      this.carousel_step = index
    }
  }

  @Watch('carousel_count', { immediate: true })
  watchCarouselCount() {
    if (this.carousel_step > this.carousel_count) {
      this.carousel_step = 0
    }
  }

  /**
   * Facebook用のURL自動リンク処理
   */
  autoLinkUrlsFacebook(str: string): string {
    return str.replace(
      /(^|\s)(https?:\/\/\S+\.\S+)/g,
      '$1<a href="$2" target="_blank" rel="nofollow">$2</a>'
    )
  }

  onClickMedia() {
    if (this.$refs.video && !this.$refs.video.paused) {
      this.$refs.video.pause()
      this.is_video_button = true
    }

    if (this.options.video_url) {
      this.$emit('click-media', {
        index: 0,
        media: [{ type: 'video', url: this.options.video_url }]
      })
    }
  }
}
