import { RouteConfig } from 'vue-router'

import FacebookAnalytics from '../pages/FacebookAnalytics.vue'

export const analytics_fb: RouteConfig = {
  component: FacebookAnalytics,
  path: '/analytics_fb',
  name: 'analytics_fb',
  meta: {
    auth: true,
    title: '自社アカウント分析 (Facebook)',
    description: '選択したアカウントの分析結果を確認できます。',
    color: 'warning',
    type: 'CHECK_OWN_ANALYSIS'
  }
}
