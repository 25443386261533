
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'SwitchButton'
})
export default class SwitchButton extends Vue {
  @Prop({ type: Boolean, default: false })
  value!: boolean

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: String, default: null })
  activeText!: string

  get input_value() {
    return this.value
  }

  set input_value(val) {
    this.$emit('input', val)
  }
}
