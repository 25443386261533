/**
 * 文字列配列に変換（重複除去）
 */
export function toStringArray(value: string): string[] {
  return Array.from(
    new Set(
      value
        .trim()
        .split('\n')
        .map(v => v.trim())
        .filter(v => v.length)
    )
  )
}

/**
 * 改行文字列に変換（重複除去）
 */
export function toLineFeedString(value: string[]): string {
  return Array.from(new Set(value))
    .filter(v => v.length)
    .join('\n')
}
