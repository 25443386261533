import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import HelpLink from '@/client/components/molecules/HelpLink'
import Dialog from '@/client/components-old/atoms/Dialog'
import Message from '@/client/components-old/atoms/Message'
import Account from '@/client/components-old/molecules/Account'
import SnsRoleUser from '@/client/components-old/molecules/SnsRoleUser'
import { IState as IAccountState } from '@/client/store/modules/accounts'
import { TFacebookAccountRole } from '@/client/utils/api/facebook_accounts'
import { TInstagramAccountRole } from '@/client/utils/api/instagram_accounts'
import { TikTokAccountRole } from '@/client/utils/api/tiktok_accounts'
import { TTwitterAccountRole } from '@/client/utils/api/twitter_accounts'

type TSnsRoleSettingDialog = {
  account_id: string
  sns_type: string
}

const accounts = namespace('accounts')
const notification = namespace('notification')

@Component({
  name: 'SnsRoleSettingDialog',
  components: {
    Dialog,
    HelpLink,
    Message,
    Account,
    SnsRoleUser
  }
})
export default class SnsRoleSettingDialog extends Vue {
  @accounts.State('facebook') facebook: IAccountState['facebook']
  @accounts.State('twitter') twitter: IAccountState['twitter']
  @accounts.State('instagram') instagram: IAccountState['instagram']
  @accounts.State('tiktok') tiktok: IAccountState['tiktok']
  @accounts.Action('fetchSnsAccountsRoles') fetchSnsAccountsRoles
  @accounts.Action('updateSnsAccountRole') updateSnsAccountRole
  @notification.Action('showNotification') showNotification

  visible = false
  account_id = ''
  sns_type = ''
  accounts_roles:
    | TFacebookAccountRole[]
    | TTwitterAccountRole[]
    | TInstagramAccountRole[]
    | TikTokAccountRole[] = []

  get account() {
    switch (this.sns_type) {
      case 'facebook': {
        return this.facebook.find(account => account.id === this.account_id) || null
      }

      case 'instagram': {
        return this.instagram.find(account => account.id === this.account_id) || null
      }

      case 'twitter': {
        return this.twitter.find(account => account.id === this.account_id) || null
      }

      case 'tiktok': {
        return this.tiktok.find(account => account.id === this.account_id) || null
      }

      default: {
        return null
      }
    }
  }

  get convert_roles() {
    return this.accounts_roles.map(data => ({
      user_id: data.user.id,
      user_name: data.user.name,
      picture_url: data.user.picture_url,
      ...data.role
    }))
  }

  get description() {
    return this.$options.filters.translate('アクセストークン発行者: [[name]]', {
      name: this.account.token_name
    })
  }

  /**
   * ダイアログの表示
   */
  async open(options: TSnsRoleSettingDialog): Promise<void> {
    this.visible = true
    this.sns_type = options.sns_type
    this.account_id = options.account_id

    if (!this.account) {
      return
    }

    const result = await this.fetchSnsAccountsRoles({
      account_id: this.account.id,
      sns_type: this.sns_type
    })

    if (!result.data) {
      return
    }

    this.accounts_roles = result.data
  }

  /**
   * ダイアログの非表示
   */
  close(): void {
    this.visible = false
    this.account_id = ''
    this.sns_type = ''
    this.accounts_roles = []
  }

  /**
   * SNSアカウント権限設定の変更
   */
  async changeRole(payload: {
    user_id: number
    role_post: string
    role_analytics: string
    role_monitoring: string
  }): Promise<void> {
    const params = { account_id: this.account.id, sns_type: this.sns_type, ...payload }

    const result = await this.updateSnsAccountRole(params)

    if (result.data) {
      return this.showNotification({
        title: 'SNSアカウントの権限設定を変更しました。'
      })
    }

    return this.showNotification({
      type: 'error',
      title: 'SNSアカウントの権限設定の変更に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。'
    })
  }
}
