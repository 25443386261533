import { getNumberLocale, getNumberPercent } from '@/client/utils/filters'

/**
 * 指標データの表示用整形
 */
export function formatMetric(metric: string, value: number | null) {
  if (value === null) return '-'
  if (metric.endsWith('_rate')) return getNumberPercent(value)
  return getNumberLocale(value)
}
