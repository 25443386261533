import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/instagram_comparison_analytics'
import * as instagram_comparison_insight from '@/client/components-old/_utils/instagram_comparison_insight'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Flex from '@/client/components-old/atoms/Flex'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import ChartPositioningMap from '@/client/components-old/molecules/ChartPositioningMap'
import { TrackingService } from '@/client/services'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as IInstagramState } from '@/client/store/modules/instagram_comparison_analytics'

const analytics = namespace('analytics')
const instagram = namespace('instagram_comparison_analytics')

@Component({
  name: 'InstagramComparisonAnalyticsChartPositioningMap',
  components: {
    ChartPositioningMap,
    Select,
    ButtonGroup,
    Flex,
    Panel
  }
})
export default class InstagramComparisonAnalyticsChartPositioningMap extends Vue {
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']
  @instagram.State('api_accounts') api_accounts: IInstagramState['api_accounts']
  @instagram.State('api_sns_comparison_accounts')
  comparison_accounts: IInstagramState['api_sns_comparison_accounts']
  @instagram.State('api_posts') posts_data: IInstagramState['api_posts']
  @instagram.State('positioning_display_format')
  positioning_display_format: IInstagramState['positioning_display_format']
  @instagram.Action('changePositioningDisplayFormat') changePositioningDisplayFormat

  y_axis_selected = 'followers_count'
  x_axis_selected = 'reactions_rate'
  z_axis_selected = 'post_count'

  total_tabs = [
    { text: '平均', value: 'average' },
    { text: '合計', value: 'total' }
  ]

  indicators = [
    ...constants.CHART_POSITIONING_MAP_POST_DATA_METRICS,
    ...constants.CHART_POSITIONING_MAP_DAILY_DATA_METRICS
  ]

  @Watch('y_axis_selected')
  watchYAxisSelected() {
    if (this.y_axis_selected !== '') {
      const y_label =
        this.indicators.find(indicator => indicator.value === this.y_axis_selected)?.text || ''

      TrackingService.sendEvent(
        `select:競合比較(IG)>BM|PM:Y軸:${y_label}
          }`
      )
    }
  }

  @Watch('x_axis_selected')
  watchXAxisSelected() {
    if (this.x_axis_selected !== '') {
      const x_label =
        this.indicators.find(indicator => indicator.value === this.x_axis_selected)?.text || ''

      TrackingService.sendEvent(
        `select:競合比較(IG)>BM|PM:X軸:${x_label}
          }`
      )
    }
  }

  @Watch('z_axis_selected')
  watchZAxisSelected() {
    if (this.z_axis_selected !== '') {
      const z_label =
        this.indicators.find(indicator => indicator.value === this.z_axis_selected)?.text || ''

      TrackingService.sendEvent(
        `select:競合比較(IG)>BM|PM:円の大きさ:${z_label}
          }`
      )
    }
  }

  get is_data() {
    return this.comparison_accounts.length > 0
  }

  get axis_options() {
    return this.translateOptions(this.indicators)
  }

  /**
   * 選択オプションの項目のを翻訳する汎用関数
   * @param {any[]} options textがあるオブジェクト配列
   * @returns {any[]} 翻訳されたオプション
   */
  translateOptions(options: any[]): { [key: string]: string }[] {
    return options.map(el => ({ ...el, text: this.$options.filters.translate(el.text) }))
  }

  get chart_options() {
    const chart_color = [
      '#1c84c6',
      '#ed5565',
      '#2ec881',
      '#24cbe5',
      '#f8ac59',
      '#5e5e5e',
      '#9b9b9b',
      '#b2dfdb',
      '#9c27b0',
      '#2d552d'
    ]

    const account_ids = this.api_accounts.map(account => account.account_id)
    const comparison_accounts = this.comparison_accounts.filter(
      account => account_ids.indexOf(account.id) !== -1
    )

    const series = comparison_accounts.map(account => {
      return {
        data: [
          {
            name: this.makeNameAccount(account.name),
            full_name: account.name,
            x: this.calculationDataMetric(
              this.x_axis_selected,
              this.makeDataMetric(account.id, this.x_axis_selected)
            ),
            y: this.calculationDataMetric(
              this.y_axis_selected,
              this.makeDataMetric(account.id, this.y_axis_selected)
            ),
            z: this.calculationDataMetric(
              this.z_axis_selected,
              this.makeDataMetric(account.id, this.z_axis_selected)
            )
          }
        ]
      }
    })

    return {
      x_format: this.getAxisFormat(this.x_axis_selected),
      y_format: this.getAxisFormat(this.y_axis_selected),
      z_format: this.getAxisFormat(this.z_axis_selected),
      x_label: this.makeLabelAxis(this.x_axis_selected),
      y_label: this.makeLabelAxis(this.y_axis_selected),
      z_label: this.makeLabelAxis(this.z_axis_selected),
      colors: chart_color,
      series: series
    }
  }

  /**
   * ラベルを作る
   * @param {string} metric metric
   * @returns {string} name
   */
  makeLabelAxis(metric: string) {
    return this.$options.filters.translate(this.indicators.find(i => i.value === metric).text)
  }

  /**
   * データを作る
   * @param {string} account_id account id
   * @param {string} metric metric
   * @returns {any[]} data metric
   */
  makeDataMetric(account_id: string, metric: string) {
    const is_daily_data = constants.CHART_POSITIONING_MAP_DAILY_DATA_METRICS.find(
      i => i.value === metric
    )

    const daily_data = this.api_accounts.find(i => i.account_id === account_id)
      ? this.api_accounts.find(i => i.account_id === account_id).data
      : []

    if (is_daily_data) {
      return daily_data
    }

    const posts_data = this.posts_data.find(i => i.account_id === account_id)
      ? this.posts_data.find(i => i.account_id === account_id).data
      : []

    return posts_data
  }

  /**
   * コンディション(平均を取得)
   * @param {string} metric account id
   * @param {any[]} insights insights
   * @returns {number} value
   */
  calculationDataMetric(metric: string, insights: any[]): number {
    switch (metric) {
      case 'post_count':
        return insights.length
      case 'reactions_rate':
        return instagram_comparison_insight.getMetricAverage(insights, metric)
      case 'followers_count':
      case 'followers_count_up_down':
        return instagram_comparison_insight.getMetricTotal(insights, metric)
      default:
        if (this.positioning_display_format === 'average') {
          return instagram_comparison_insight.getMetricAverage(insights, metric)
        }
        return instagram_comparison_insight.getMetricTotal(insights, metric)
    }
  }

  /**
   * アカウント名
   * @param {string} name account id
   * @returns {string} name
   */
  makeNameAccount(name: string): string {
    if (name && name.length > 5) {
      return name.substr(0, 5) + '...'
    }
    return name
  }

  /**
   * 軸フォーマットを取得する
   * @param {string} metric 指標
   * @returns {string} 軸フォーマット
   */
  getAxisFormat(metric: string): string {
    switch (metric) {
      case 'reactions_rate':
        return 'percent'
      case 'followers_count_up_down':
        return 'up_down'
      default:
        return ''
    }
  }

  /**
   * ポジショニングマップデータの表示形式を変更
   */
  async onChangePositioningDisplayFormat(payload: string) {
    TrackingService.sendEvent(`click:競合比較(IG)>BM|PM:${payload}`)

    await this.changePositioningDisplayFormat(payload)
  }
}
