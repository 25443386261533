
import { Component, Vue } from 'vue-property-decorator'
import { Action, namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import { TrackingService } from '@/client/services'
import { IState as ISignupState } from '@/client/store/modules/signup'
import { IPostSignupResponse } from '@/client/utils/api/signup'

const signup = namespace('signup')
const notification = namespace('notification')

@Component({
  name: 'SignupConfirm',
  components: {
    Button,
    Message,
    Panel
  }
})
export default class SignupConfirm extends Vue {
  @Action('login') login!: any
  @signup.State('api_user_works') api_user_works!: ISignupState['api_user_works']
  @signup.State('form_data') form_data!: ISignupState['form_data']
  @signup.Action('submitFormData') submitFormData!: any
  @notification.Action('showNotification') showNotification!: any

  loading = false

  get password() {
    return '*'.repeat(this.form_data.password.length)
  }

  get user_works() {
    const user_works = this.api_user_works.filter(work =>
      this.form_data.user_work_ids.includes(work.id)
    )

    if (!user_works.length) return ['指定なし']

    return user_works.map(work => work.name)
  }

  /**
   * トライアル登録
   */
  async trialRegistration() {
    this.loading = true

    const response: IPostSignupResponse = await this.submitFormData()

    if (response.data) {
      TrackingService.sendTrialRegistration(this.form_data.company_name, this.form_data.name)

      await this.login()

      await this.showNotification({ title: 'トライアル登録しました。' })

      return
    }

    this.loading = false

    if (response.error && response.error.type === 'DUPLICATE') {
      return this.showNotification({
        title: '登録済のメールアドレスです。',
        message: 'ログイン画面からログインしてください。',
        type: 'error'
      })
    }

    this.showNotification({
      title: 'トライアル登録に失敗しました。',
      message: '時間をおいて再度お試しください。',
      type: 'error'
    })
  }
}
