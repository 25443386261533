
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter, namespace, State } from 'vuex-class'

import MonitoringConversationDialog from '@/client/components-old/organisms/MonitoringConversationDialog'
import MonitoringDetailDialog from '@/client/components-old/organisms/MonitoringDetailDialog'
import MonitoringPostDialog from '@/client/components-old/organisms/MonitoringPostDialog'
import MonitoringTemplateDialog from '@/client/components-old/organisms/MonitoringTemplateDialog'
import PostDetailDialog from '@/client/components-old/organisms/PostDetailDialog'
import DashboardLayout from '@/client/components-old/templates/DashboardLayout'
import { IRootGetter, IRootState } from '@/client/store/global'
import { IState as IDashboardState } from '@/client/store/modules/dashboard'

const dashboard = namespace('dashboard')
const monitoring = namespace('monitoring')
const monitoring_templates = namespace('monitoring_templates')
const post_management = namespace('post_management')

@Component({
  name: 'Dashboard',
  components: {
    MonitoringConversationDialog,
    MonitoringDetailDialog,
    MonitoringPostDialog,
    MonitoringTemplateDialog,
    PostDetailDialog,
    DashboardLayout
  }
})
export default class Dashboard extends Vue {
  @State('project') project!: IRootState['project']
  @Getter('is_restricted') is_restricted!: IRootGetter['is_restricted']
  @dashboard.State('is_loading') is_loading!: IDashboardState['is_loading']
  @dashboard.Action('createdDashboardPage') createdDashboardPage!: any
  @dashboard.Action('destroyedDashboardPage') destroyedDashboardPage!: any
  @dashboard.Action('changeGroupDashboardPage') changeGroupDashboardPage!: any
  @dashboard.Action('updatePostDialog') updatePostDialog!: any
  @dashboard.Action('approvedPostDialog') approvedPostDialog!: any
  @dashboard.Action('updateMonitoringDialog') updateMonitoringDialog!: any
  @monitoring.Action('openDetailDialog') openDetailDialog!: any
  @monitoring_templates.Action('fetchTemplate') fetchTemplate!: any
  @post_management.Action('openPostDetailDialog') openPostDetailDialog!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  $refs!: {
    MonitoringConversationDialog: any
    MonitoringPostDialog: any
    MonitoringTemplateDialog: any
  }

  @Emit('change-project')
  changeProject(payload: any) {
    return payload
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  @Watch('group')
  async watchGroup() {
    await this.changeGroupDashboardPage()
  }

  /**
   * ページ表示時
   */
  async created() {
    await this.initialize()
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedDashboardPage()
  }

  /**
   * 初期化
   */
  async initialize() {
    await this.fetchTemplate()
    await this.createdDashboardPage()

    if (this.is_restricted) return

    if (this.$route.query.type === 'monitoring') {
      await this.openDetailDialog({
        monitoring_id: Number(this.$route.query.monitoring_id),
        sns_type: this.$route.query.sns,
        tab: 'preview'
      })
    }

    if (this.$route.query.type === 'post') {
      await this.openPostDetailDialog({
        sns_post_id: Number(this.$route.query.sns_post_id),
        mode: 'approval', // クエリにモード指定が無い為アクション内で調整する
        tab: 'preview'
      })
    }
  }

  /**
   * 投稿ダイアログの表示
   */
  async openPostDialog(payload: any) {
    if (payload.project_id !== this.project.id) {
      await this.changeProject({ project_id: payload.project_id })
    }

    await this.openPostDetailDialog({
      sns_post_id: payload.sns_post_id,
      mode: payload.mode,
      tab: payload.tab
    })
  }

  /**
   * モニタリングダイアログの表示
   */
  async openMonitoringDialog(payload: any) {
    if (payload.project_id !== this.project.id) {
      await this.changeProject({ project_id: payload.project_id })
    }

    await this.openDetailDialog({
      monitoring_id: payload.monitoring_id,
      sns_type: payload.monitoring_sns,
      tab: payload.tab
    })
  }

  /**
   * 会話履歴ダイアログを表示
   */
  async openMonitoringConversationDialog(payload: any) {
    await this.$refs.MonitoringConversationDialog.open(payload)
  }

  /**
   * 親投稿ダイアログを表示
   */
  async openMonitoringPostDialog(payload: any) {
    await this.$refs.MonitoringPostDialog.open(payload)
  }

  /**
   * 定型文ダイアログを表示
   */
  openMonitoringTemplateDialog() {
    this.$refs.MonitoringTemplateDialog.open()
  }
}
