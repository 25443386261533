import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Message from '@/client/components-old/atoms/Message'
import Account from '@/client/components-old/molecules/Account'
import { IState as IAccountState } from '@/client/store/modules/accounts'

type TSnsUnregisterDialog = {
  account_id: string
  sns_type: string
}

const accounts = namespace('accounts')
const notification = namespace('notification')

@Component({
  name: 'SnsUnregisterDialog',
  components: {
    Button,
    Dialog,
    Message,
    Account
  }
})
export default class SnsUnregisterDialog extends Vue {
  @accounts.State('facebook') facebook: IAccountState['facebook']
  @accounts.State('twitter') twitter: IAccountState['twitter']
  @accounts.State('instagram') instagram: IAccountState['instagram']
  @accounts.State('tiktok') tiktok: IAccountState['tiktok']
  @accounts.Action('removeSnsAccount') removeSnsAccount
  @notification.Action('showNotification') showNotification

  visible = false
  loading = false
  account_id = ''
  sns_type = ''

  get account() {
    switch (this.sns_type) {
      case 'facebook': {
        return this.facebook.find(account => account.id === this.account_id) || null
      }

      case 'instagram': {
        return this.instagram.find(account => account.id === this.account_id) || null
      }

      case 'twitter': {
        return this.twitter.find(account => account.id === this.account_id) || null
      }

      case 'tiktok': {
        return this.tiktok.find(account => account.id === this.account_id) || null
      }

      default: {
        return null
      }
    }
  }

  get description() {
    if (!this.account) return ''

    return this.$options.filters.translate('アクセストークン発行者: [[name]]', {
      name: this.account.token_name
    })
  }

  /**
   * ダイアログの表示
   */
  open(options: TSnsUnregisterDialog) {
    this.visible = true
    this.loading = false
    this.sns_type = options.sns_type
    this.account_id = options.account_id
  }

  /**
   * ダイアログの非表示
   */
  close() {
    this.visible = false
  }

  /**
   * SNSアカウントの登録解除
   */
  async cancelSnsAccount(): Promise<void> {
    if (!this.account) return

    this.loading = true

    const result = await this.removeSnsAccount({
      account_id: this.account.id,
      sns_type: this.sns_type
    })

    this.loading = false

    if (result.data) {
      this.close()
      return this.showNotification({ title: 'SNSアカウントの登録を解除しました。' })
    }

    return this.showNotification({
      type: 'error',
      title: 'SNSアカウントの登録解除に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。'
    })
  }
}
