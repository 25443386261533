import moment from 'moment-timezone'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import { AnalyticsPanelHeader } from '@/client/components/molecules'
import * as constants from '@/client/components-old/_constants/instagram_analytics'
import * as calculation from '@/client/components-old/_utils/calculation'
import * as instagram_insight from '@/client/components-old/_utils/instagram_insight'
import Button from '@/client/components-old/atoms/Button'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import AnalyticsPanel from '@/client/components-old/molecules/AnalyticsPanel'
import ChartVertical from '@/client/components-old/molecules/ChartVertical'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as ICategoriesState } from '@/client/store/modules/categories'
import { IState as IInstagramState } from '@/client/store/modules/instagram_analytics'
import csv from '@/client/utils/csv'

type TInstagramAnalyticsChartVerticalOptions = {
  data_type: 'daily_data' | 'post_data' | 'stories_data'
  metric: string
  interval: '1_day' | '7_day' | '14_day' | '28_day' | '1_month' | '3_month' | '6_month' | 'hour'
  unit: 'total' | 'average'
  post_types?: string[]
  post_tags?: number[]
  is_change_interval?: boolean
}

type TInstagramAnalyticsChartVerticalSeries = {
  name: string
  metric: string
  data?: number[]
  index?: number
}

const analytics = namespace('analytics')
const instagram = namespace('instagram_analytics')
const categories = namespace('categories')

@Component({
  name: 'InstagramAnalyticsChartVertical',
  components: {
    AnalyticsPanel,
    AnalyticsPanelHeader,
    ChartVertical,
    Button,
    ButtonGroup,
    Icon,
    Tooltip
  }
})
export default class InstagramAnalyticsChartVertical extends Vue {
  @State('user') user: IRootState['user']
  @categories.State('api_post_categories')
  api_post_categories: ICategoriesState['api_post_categories']
  @categories.State('api_category_posts') api_category_posts: ICategoriesState['api_category_posts']
  @instagram.State('api_accounts')
  daily_data: IInstagramState['api_accounts']
  @instagram.State('api_posts') post_data: IInstagramState['api_posts']
  @instagram.State('api_stories') stories_data: IInstagramState['api_stories']
  @instagram.State('screen_name') screen_name: IInstagramState['screen_name']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']

  @Prop({ type: Object, required: true })
  options: TInstagramAnalyticsChartVerticalOptions

  @Prop({ type: Boolean, default: false })
  isPreview: boolean

  @Prop({ type: Boolean, default: false })
  isEdit: boolean

  interval = '1_day'

  target_date = ''

  @Watch('options', { immediate: true, deep: true })
  changeOptions() {
    this.interval = this.options.interval
  }

  @Watch('analytics', { immediate: true, deep: true })
  changeTargetDate() {
    const data = this.analytics.find(v => v.online_followers && v.online_followers.length)
    if (!data) return

    this.target_date = moment(data.end_time).format('YYYY-MM-DD')
  }

  get analytics(): any[] {
    const { data_type, post_types, post_tags } = this.options

    switch (data_type) {
      case 'daily_data':
        return instagram_insight.filterDailyData(
          this.daily_data,
          this.post_data,
          this.options.metric
        )
      case 'post_data': {
        let temp_insights = instagram_insight.filterPostData(this.post_data, this.options.metric)

        if (post_types?.length) {
          temp_insights = temp_insights.filter(post => post_types.includes(post.type))
        }
        if (post_tags?.length) {
          temp_insights = temp_insights.filter(post => {
            const post_tag_ids = this.getSnsPostTags(post.post_id)
            return post_tag_ids.some(post_tag_id => post_tags.includes(post_tag_id))
          })
        }

        return temp_insights
      }
      case 'stories_data': {
        let temp_insights = [...this.stories_data]

        if (post_types?.length) {
          temp_insights = temp_insights.filter(post => post_types.includes(post.type))
        }
        if (post_tags?.length) {
          temp_insights = temp_insights.filter(story => {
            const story_tag_ids = this.getSnsPostTags(story.stories_id)
            return story_tag_ids.some(story_tag_id => post_tags.includes(story_tag_id))
          })
        }

        return temp_insights
      }
      default:
        return []
    }
  }

  get posts_date() {
    const posts = instagram_insight.filterPostData(this.post_data, this.options.metric)
    return posts.map(post => moment(post.created_time).format('YYYY-MM-DD'))
  }

  get is_data() {
    if (this.options.metric === 'online_followers') {
      return this.online_followers_is_data_exists && this.online_followers_is_target_date_exists
    }
    return this.analytics.length > 0
  }

  get no_data_message() {
    if (
      this.options.metric === 'online_followers' &&
      this.online_followers_is_data_exists &&
      !this.online_followers_is_target_date_exists
    ) {
      return '対象日のデータがありません。'
    }
  }

  get is_download() {
    if (this.options.metric === 'online_followers') {
      return this.online_followers_is_data_exists && !this.isPreview
    }

    return this.is_data && !this.isPreview
  }

  get is_edit() {
    return !this.isPreview && this.isEdit
  }

  get intervals() {
    if (this.user.language === 'en') {
      return [
        { text: '1D', value: '1_day' },
        { text: '7D', value: '7_day' },
        { text: '14D', value: '14_day' },
        { text: '28D', value: '28_day' },
        { text: '1M', value: '1_month' },
        { text: '3M', value: '3_month' },
        { text: '6M', value: '6_month' }
      ]
    }

    return [
      { text: '1日', value: '1_day' },
      { text: '7日', value: '7_day' },
      { text: '14日', value: '14_day' },
      { text: '28日', value: '28_day' },
      { text: '1ヶ月', value: '1_month' },
      { text: '3ヶ月', value: '3_month' },
      { text: '6ヶ月', value: '6_month' }
    ]
  }

  get metrics() {
    switch (this.options.data_type) {
      case 'daily_data':
        return constants.CHANGE_CHART_DAILY_DATA_METRICS
      case 'post_data':
        return constants.CHANGE_CHART_POST_DATA_METRICS
      case 'stories_data':
        return constants.CHANGE_CHART_STORIES_DATA_METRICS
      default:
        return []
    }
  }

  get metric_name() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    const metric_name = metric ? this.$options.filters.translate(metric.text) : ''

    const unit = constants.UNITS.find(unit => unit.value === this.options.unit)
    const unit_name = unit ? this.$options.filters.translate(unit.text) : ''
    const unit_value = unit ? unit.value : ''

    if (unit_name === '' || unit_value === '') {
      return metric_name
    }

    switch (this.options.metric) {
      case 'followers_count':
      case 'followers_count_up_down':
      case 'follows_count':
      case 'follows_count_up_down':
        return metric_name
      default:
        return `${metric_name} [${unit_name}]`
    }
  }

  get metric_tooltip() {
    const metric = this.metrics.find(metric => metric.value === this.options.metric)
    return metric ? metric.tooltip : ''
  }

  get show_post_types() {
    switch (this.options.data_type) {
      case 'post_data':
        return ['image', 'video', 'carousel', 'reels'].filter(v =>
          this.options.post_types.includes(v)
        )

      case 'stories_data':
        return ['image', 'video'].filter(v => this.options.post_types.includes(v))

      default:
        return []
    }
  }

  get post_tags_names() {
    if (this.options.data_type === 'daily_data') return []

    return this.getPostTagNames(this.options.post_tags)
  }

  get panel_type() {
    const data_type = constants.IN_DATA_TYPES.find(type => type.value === this.options.data_type)
    return data_type ? data_type.color : null
  }

  get is_tooltip() {
    return this.metric_tooltip !== ''
  }

  get is_button_group() {
    return this.options.is_change_interval && this.is_data
  }

  get time_range() {
    const time_range = []
    const add_number = Number(this.interval.match(/\d+/g))

    let time_end
    let time_start = moment(moment(this.start_date).format('YYYY-MM-DD 00:00:00')).unix()

    const end_date_unix = moment(moment(this.end_date).format('YYYY-MM-DD 23:59:59')).unix()

    while (time_start < end_date_unix) {
      if (
        this.interval === '1_day' ||
        this.interval === '7_day' ||
        this.interval === '14_day' ||
        this.interval === '28_day'
      ) {
        // 日数のunix値: 日数 x 一日のunix値 (一日のunix値: 86400)
        time_end = time_start + add_number * 86400 - 1
      } else if (
        this.interval === '1_month' ||
        this.interval === '3_month' ||
        this.interval === '6_month'
      ) {
        time_end = moment.unix(time_start).add(add_number, 'months').startOf('month').unix() - 1
      } else if (this.interval === 'hour') {
        time_end = end_date_unix
      }

      if (time_end > end_date_unix) {
        time_end = end_date_unix
      }

      time_range.push({
        start: time_start,
        end: time_end
      })

      time_start = time_end + 1
    }

    return time_range
  }

  get categories() {
    switch (this.interval) {
      case 'hour':
        return [
          this.$options.filters.translate('深夜'),
          '1am',
          '2am',
          '3am',
          '4am',
          '5am',
          '6am',
          '7am',
          '8am',
          '9am',
          '10am',
          '11am',
          this.$options.filters.translate('正午'),
          '1pm',
          '2pm',
          '3pm',
          '4pm',
          '5pm',
          '6pm',
          '7pm',
          '8pm',
          '9pm',
          '10pm',
          '11pm',
          this.$options.filters.translate('深夜')
        ]
      case '1_day':
        return this.time_range.map(time => {
          return moment.unix(time.start).format('YYYY/MM/DD')
        })
      case '7_day':
      case '14_day':
      case '28_day':
        return this.time_range.map(time => {
          return `${moment.unix(time.start).format('M/D')}-${moment.unix(time.end).format('M/D')}`
        })
      case '1_month':
      case '3_month':
      case '6_month':
        return this.time_range.map(time => {
          const start = moment.unix(time.start).month() + 1 + this.$options.filters.translate('月')
          const end = moment.unix(time.end).month() + 1 + this.$options.filters.translate('月')

          return start !== end ? `${start}-${end}` : start
        })
      default:
        return this.time_range
    }
  }

  get chart_options() {
    const blue_color = '#1c84c6'
    const red_color = '#ed5565'
    const green_color = '#2ec881'
    const orange_color = '#f8ac59'
    const cyan_color = '#24cbe5'

    switch (this.options.metric) {
      case 'reactions':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'いいね！',
              metric: 'likes',
              index: 1
            },
            {
              name: 'コメント',
              metric: 'comments',
              index: 0
            }
          ])
        }

      case 'engagements':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color, orange_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'いいね！',
              metric: 'likes',
              index: 3
            },
            {
              name: 'コメント',
              metric: 'comments',
              index: 2
            },
            {
              name: '保存',
              metric: 'saved_count',
              index: 1
            },
            {
              name: 'シェア(リール)',
              metric: 'shares',
              index: 0
            }
          ])
        }

      case 'profile_clicks':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: true,
          display_value: false,
          display_line: true,
          colors: [blue_color, red_color, green_color, orange_color, cyan_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: 'ウェブサイト',
              metric: 'website_clicks',
              index: 4
            },
            {
              name: 'メッセージ',
              metric: 'text_message_clicks',
              index: 3
            },
            {
              name: '道順',
              metric: 'get_directions_clicks',
              index: 2
            },
            {
              name: '電話',
              metric: 'phone_call_clicks',
              index: 1
            },
            {
              name: 'メール',
              metric: 'email_contacts_clicks',
              index: 0
            }
          ])
        }

      case 'followers_count':
      case 'follows_count':
        return {
          chart_type: 'line',
          y_label: this.$options.filters.translate('人数'),
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }
      case 'followers_count_up_down':
      case 'follows_count_up_down':
      case 'impressions_unique':
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('人数'),
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }
      case 'reactions_rate':
      case 'engagements_rate':
        return {
          chart_type: 'line',
          y_label: '%',
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }
      case 'online_followers':
        return {
          chart_type: 'area',
          y_label: '',
          display_legend: false,
          display_value: false,
          display_line: false,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }

      default:
        return {
          chart_type: 'column',
          y_label: this.$options.filters.translate('回数'),
          display_legend: false,
          display_value: false,
          display_line: true,
          colors: [blue_color],
          categories: this.categories,
          series: this.getSeries([
            {
              name: this.metric_name,
              metric: this.options.metric
            }
          ])
        }
    }
  }

  get display_type() {
    return `chart_vertical_${this.chart_options.chart_type}`
  }

  get target_date_show() {
    return moment(this.target_date).format('MMMDo(ddd)')
  }

  get online_followers_is_data_exists() {
    return this.analytics.some(v => v.online_followers.length)
  }

  get online_followers_is_target_date_exists() {
    const target_data = this.analytics.find(data =>
      moment(data.end_time).isSame(moment(this.target_date), 'day')
    )

    return target_data && target_data.online_followers.length > 0
  }

  get is_show_select_date() {
    return this.options.metric === 'online_followers' && this.online_followers_is_data_exists
  }

  get is_disabled_date_prev() {
    return moment(this.start_date).isSameOrAfter(moment(this.target_date))
  }

  get is_disabled_date_next() {
    return moment(this.end_date).isSameOrBefore(moment(this.target_date))
  }

  @Emit('edit')
  onEdit() {
    return this.options
  }

  @Emit('delete')
  onDelete(event) {
    return event
  }

  getPostTagNames(post_tag_ids?: number[]): string[] {
    if (post_tag_ids === undefined || !post_tag_ids.length) return []

    return (
      this.api_post_categories.filter(tag => post_tag_ids.includes(tag.id)).map(v => v.name) || []
    )
  }

  getSnsPostTags(post_id: string): number[] {
    const post_tag = this.api_category_posts.find(post_tag => post_tag.post_id === post_id)
    return post_tag ? post_tag.category_ids : []
  }

  changeInteval(value: string) {
    TrackingService.sendEvent(
      `select:自社分析(IG)>${this.screen_name}|${this.options.metric}:${value}`
    )
  }

  onDatePrev(): void {
    this.target_date = moment(this.target_date).subtract(1, 'days').format('YYYY-MM-DD')

    TrackingService.sendEvent(`select:自社分析(IG)>${this.screen_name}|${this.options.metric}:前日`)
  }

  onDateNext(): void {
    this.target_date = moment(this.target_date).add(1, 'days').format('YYYY-MM-DD')

    TrackingService.sendEvent(`select:自社分析(IG)>${this.screen_name}|${this.options.metric}:次日`)
  }

  /**
   * ダウンロードボタンを押した時
   * @returns {void}
   */
  onDownload(): void {
    TrackingService.sendEvent(
      `click:自社分析(IG)>${this.screen_name}|${this.options.metric}:CSVダウンロード`
    )

    if (this.options.metric === 'online_followers') {
      this.onDownloadOnlineFollowers()
      return
    }

    const fields = [
      this.$options.filters.translate('期間'),
      this.$options.filters.translate(this.metric_name)
    ]

    if (this.chart_options.series.length > 1) {
      fields.push(...this.chart_options.series.map(element => element.name))
    }

    const data = this.categories.map((time, key) => {
      if (this.chart_options.series.length === 1) {
        return [time, ...this.chart_options.series.map(element => element.data[key])]
      }

      // 複数ターゲットの場合、合計を計算
      const total = calculation.addition(
        this.chart_options.series.map(element => element.data[key])
      )

      return [
        time,
        total ? total : null,
        ...this.chart_options.series.map(element => element.data[key])
      ]
    })

    const csv_data = { fields, data }

    const component_name = this.$options.filters.translate('グラフ')

    const csv_filename = [component_name, this.metric_name, this.start_date, this.end_date].join(
      '_'
    )

    csv.download(csv_data, csv_filename)
  }

  /**
   * オンライン時間帯のcsvダウンロード
   */
  onDownloadOnlineFollowers(): void {
    const fields = [
      this.$options.filters.translate('日'),
      '00:00',
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00'
    ]

    const data = this.analytics
      .filter(v => v.online_followers.length)
      .map(analytic => {
        return [
          moment(analytic.end_time).format('YYYY-MM-DD'),
          analytic.online_followers[0],
          analytic.online_followers[1],
          analytic.online_followers[2],
          analytic.online_followers[3],
          analytic.online_followers[4],
          analytic.online_followers[5],
          analytic.online_followers[6],
          analytic.online_followers[7],
          analytic.online_followers[8],
          analytic.online_followers[9],
          analytic.online_followers[10],
          analytic.online_followers[11],
          analytic.online_followers[12],
          analytic.online_followers[13],
          analytic.online_followers[14],
          analytic.online_followers[15],
          analytic.online_followers[16],
          analytic.online_followers[17],
          analytic.online_followers[18],
          analytic.online_followers[19],
          analytic.online_followers[20],
          analytic.online_followers[21],
          analytic.online_followers[22],
          analytic.online_followers[23]
        ]
      })

    const csv_data = { fields, data }

    const component_name = this.$options.filters.translate('グラフ')

    const csv_filename = [component_name, this.metric_name, this.start_date, this.end_date].join(
      '_'
    )

    csv.download(csv_data, csv_filename)
  }

  /**
   * グラフのシリーズデータを取得
   * @param {TInstagramAnalyticsChartVerticalSeries[]} series
   * @returns {TInstagramAnalyticsChartVerticalSeries[]} シリーズデータ
   */
  getSeries(
    series: TInstagramAnalyticsChartVerticalSeries[]
  ): TInstagramAnalyticsChartVerticalSeries[] {
    series = series.map(element => {
      return {
        name: this.$options.filters.translate(element.name),
        metric: element.metric,
        data: [],
        index: element.index
      }
    })

    this.time_range.forEach(time => {
      // 該当する期間の分析データを取得
      const analytics = this.analytics.filter(analytic => {
        switch (this.options.data_type) {
          case 'daily_data':
            return analytic.end_time / 1000 >= time.start && analytic.end_time / 1000 <= time.end
          case 'post_data':
            return (
              moment(analytic.created_time).unix() >= time.start &&
              moment(analytic.created_time).unix() <= time.end
            )
          case 'stories_data':
            return (
              analytic.created_time / 1000 >= time.start && analytic.created_time / 1000 <= time.end
            )
          default:
            return false
        }
      })

      // 該当する期間のデータがない場合、nullにセット
      if (analytics.length === 0) {
        series = series.map(element => {
          element.data.push(null)
          return element
        })
        return
      }

      series.forEach(element => {
        // 期間と指標に該当する数字を計算
        if (element.metric === 'online_followers') {
          const target_data = this.analytics.find(v =>
            moment(v.end_time).isSame(moment(this.target_date), 'day')
          )

          element.data = target_data ? target_data.online_followers : []
        } else {
          switch (this.options.unit) {
            case 'total':
              element.data.push(instagram_insight.getMetricTotal(analytics, element.metric))
              break
            case 'average':
              element.data.push(instagram_insight.getMetricAverage(analytics, element.metric))
              break
            default:
              element.data.push(null)
              break
          }
        }
      })
    })

    return series
  }
}
