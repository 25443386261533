import moment from 'moment-timezone'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import DateTimePicker from '@/client/components-old/molecules/DateTimePicker'
import SnsMultiSelect from '@/client/components-old/molecules/SnsMultiSelect'
import PostCreateAutoPublishSetting from '@/client/components-old/organisms/PostCreateAutoPublishSetting'
import { TrackingService } from '@/client/services'
import { IGetter as IAccountsGetter } from '@/client/store/modules/accounts'
import {
  IGetter as IPostCreateGetter,
  IState as IPostCreateState
} from '@/client/store/modules/post_create'
import { IPostImage, IPostVideo } from '@/client/utils/api/posts'
import { IVideo } from '@/client/utils/api/videos'
import i18n from '@/client/utils/i18n'
import * as validate from '@/client/utils/validate'
import { SnsType } from '@/common/types'

const accounts = namespace('accounts')
const post_create = namespace('post_create')
const notification = namespace('notification')

@Component({
  name: 'PostCreateSetting',
  components: {
    Icon,
    Tooltip,
    SnsMultiSelect,
    DateTimePicker,
    PostCreateAutoPublishSetting
  }
})
export default class PostCreateSetting extends Vue {
  @accounts.Getter('post') sns_accounts!: IAccountsGetter['post']
  @post_create.State('accounts') accounts!: IPostCreateState['accounts']
  @post_create.State('scheduled_datetime')
  scheduled_datetime!: IPostCreateState['scheduled_datetime']
  @post_create.State('images') images!: IPostCreateState['images']
  @post_create.State('videos') videos!: IPostCreateState['videos']
  @post_create.State('instagram_post_type')
  instagram_post_type!: IPostCreateState['instagram_post_type']
  @post_create.State('is_auto_publish')
  is_auto_publish!: IPostCreateState['is_auto_publish']
  @post_create.State('api_post')
  api_post!: IPostCreateState['api_post']
  @post_create.Mutation('SET_SCHEDULED_DATETIME') setScheduledDatetime!: any
  @post_create.Mutation('SET_TYPE') setType!: any
  @post_create.Mutation('SET_IMAGES') setImages!: (images: IPostImage[]) => void
  @post_create.Mutation('SET_VIDEOS') setVideos!: (images: IPostVideo[]) => void
  @post_create.Mutation('SET_INSTAGRAM_POST_TYPE') setInstagramPostType!: any
  @post_create.Getter('is_twitter_quote')
  is_twitter_quote!: IPostCreateGetter['is_twitter_quote']
  @post_create.Getter('is_twitter_reply')
  is_twitter_reply!: IPostCreateGetter['is_twitter_reply']
  @post_create.Action('changeAccounts') changeAccounts!: any
  @post_create.Action('changeInstagramAutoPublishStatus') changeInstagramAutoPublishStatus!: any
  @post_create.Action('getIncidentEvents') getIncidentEvents!: any
  @notification.Action('showNotification') showNotification!: any
  @post_create.Getter('use_instagram') use_instagram!: IPostCreateGetter['use_instagram']

  get accounts_options() {
    return this.sns_accounts.map(account => ({
      id: account.id,
      img: account.img,
      username: account.username,
      name: account.name,
      sns: account.sns
    }))
  }

  get is_error_datetime() {
    if (!this.scheduled_datetime) return false
    return (
      !moment(this.scheduled_datetime).isValid() ||
      moment(this.scheduled_datetime).isBefore(moment().add(3, 'minutes'))
    )
  }

  /**
   * 公開日時を変更
   */
  async changeScheduledDatetime(scheduled_datetime: string) {
    TrackingService.sendEvent('select:投稿作成|画面内ヘッダー|公開日時')

    const is_3_minutes = moment().add(3, 'minute')
    const is_6_months = moment().add(6, 'month')

    if (
      scheduled_datetime &&
      !moment(scheduled_datetime).isBetween(is_3_minutes, is_6_months, 'minute', '()')
    ) {
      this.showNotification({
        title: '公開日時は4分後〜6ヶ月の間で指定できます。',
        type: 'error'
      })
    }

    this.setScheduledDatetime(scheduled_datetime)

    await this.getIncidentEvents()
  }

  /**
   * アカウントの追加と検証
   */
  async checkAccounts(
    account: {
      id: string
      name: string
      img: string
      sns: SnsType
    }[]
  ): Promise<void> {
    let accounts = account

    if (this.api_post?.tw_posts?.[0]?.is_in_reply_to) {
      this.showNotification({
        type: 'error',
        message:
          '本投稿が、返信先の投稿に選択されています。まずは、他投稿からの返信先の指定を解除してください。'
      })

      return
    }

    if (this.api_post?.tw_posts?.[0]?.is_in_quote_to) {
      this.showNotification({
        type: 'error',
        message:
          '本投稿が、引用元の投稿に選択されています。まずは、他投稿からの引用元の指定を解除してください。'
      })

      return
    }

    if (
      this.accounts.length === 1 &&
      this.accounts[0].sns === 'twitter' &&
      (this.is_twitter_quote || this.is_twitter_reply) &&
      (account.every(v => v.sns !== 'instagram') ||
        account.filter(v => v.sns === 'twitter').length !== 1)
    ) {
      const message = [
        i18n.t('返信・スレッド・引用機能は、複数アカウントでの投稿はできません。'),
        i18n.t('アカウントを切り替えると、返信・スレッド・引用の設定が解除されます。'),
        i18n.t('公開先アカウントを切り替えてよろしいですか？')
      ]

      if (!confirm(message.join('\n'))) return
    }

    // クロスポストできるアカウントからクロスポストできないアカウントに変更した場合
    if (
      this.accounts.some(v => v.sns === 'facebook' || v.sns === 'twitter') &&
      account.some(v => v.sns === 'instagram' || v.sns === 'tiktok')
    ) {
      const is_use_instagram = account.some(v => v.sns === 'instagram')

      const message = [i18n.t('現在のアカウント選択状態を解除してよろしいですか？')]

      if (is_use_instagram) {
        message.unshift(i18n.t('Instagramでの複数アカウント投稿はできません。'))
      } else {
        message.unshift(i18n.t('TikTokでの複数アカウント投稿はできません。'))
      }

      const is_confirm = window.confirm(message.join('\n'))

      if (is_confirm) {
        accounts = account.filter(v => v.sns === 'instagram' || v.sns === 'tiktok')
        this.setImages([])
        this.setVideos([])
        this.setType('text')
        this.setInstagramPostType('post')
      } else {
        accounts = account.filter(a => this.accounts.some(b => a.id === b.id && a.sns === b.sns))
      }
    }

    // Instagramから他のSNSに変更した場合
    if (
      this.accounts.some(v => v.sns === 'instagram') &&
      account.some(v => v.sns === 'facebook' || v.sns === 'twitter' || v.sns === 'tiktok')
    ) {
      const message = [
        i18n.t('Instagramでの複数アカウント投稿はできません。'),
        i18n.t('現在のアカウント選択状態を解除してよろしいですか？')
      ]
      const is_confirm = window.confirm(message.join('\n'))

      if (is_confirm) {
        accounts = account.filter(v => v.sns !== 'instagram')
        this.setImages([])
        this.setVideos([])
        this.setType('text')
        this.setInstagramPostType('post')
      } else {
        accounts = account.filter(a => this.accounts.some(b => a.id === b.id && a.sns === b.sns))
      }
    }

    // TikTokから他のSNSに変更した場合
    if (
      this.accounts.some(v => v.sns === 'tiktok') &&
      account.some(v => v.sns === 'facebook' || v.sns === 'twitter' || v.sns === 'instagram')
    ) {
      const message = [
        i18n.t('TikTokでの複数アカウント投稿はできません。'),
        i18n.t('現在のアカウント選択状態を解除してよろしいですか？')
      ]
      const is_confirm = window.confirm(message.join('\n'))

      if (is_confirm) {
        accounts = account.filter(v => v.sns !== 'tiktok')
        this.setImages([])
        this.setVideos([])
        this.setType('text')
        this.setInstagramPostType('post')
      } else {
        accounts = account.filter(a => this.accounts.some(b => a.id === b.id && a.sns === b.sns))
      }
    }

    // ? Instagramの公開権限のスコープがあるか確認(自動公開実装前に登録されたアカウントの場合、公開権限がない可能性があるため)
    const is_instagram_publish = this.sns_accounts
      .filter(a => accounts.some(b => a.id === b.id && a.sns === b.sns))
      .some(v => v.is_publish_permission)

    if (this.is_auto_publish && !is_instagram_publish) {
      await this.changeInstagramAutoPublishStatus(false)
    } else if (is_instagram_publish) {
      await this.changeInstagramAutoPublishStatus(true)
    }

    // ? アカウント変更による既存画像の再チェック
    for (const image of this.images) {
      const result = await validate.checkImageAccountChange(
        image.image_url,
        this.images.some(v => v.is_animation_gif),
        accounts.some(v => v.sns === 'twitter')
      )

      if (result !== '') {
        if (!confirm(result)) return

        this.setImages([])
        this.setType('text')
      }
    }

    // ? アカウント変更による既存動画の再チェック
    for (const video of this.videos) {
      // ? 検証する為にAPIの形を再現する
      const api_video: IVideo = {
        id: '',
        video_url: video.video_url,
        video_thumbnail: video.video_thumbnail ?? '',
        video_type: video.video_type,
        video_size: video.video_size,
        video_duration: 0,
        video_width: null,
        video_height: null,
        video_bit_rate: null,
        video_frame_rate: video.video_frame_rate,
        video_audio_channels: video.video_audio_channels,
        video_audio_format: video.video_audio_format,
        video_audio_bit_rate: null
      }

      const is_instagram_reels = this.videos.some(v => v.video_duration && v.video_duration >= 60)

      const is_instagram_carousel = this.images.length + this.videos.length > 1

      const result = await validate.checkVideoAccountChange(
        api_video,
        accounts.some(v => v.sns === 'facebook'),
        accounts.some(v => v.sns === 'twitter'),
        accounts.some(v => v.sns === 'instagram'),
        accounts.some(v => v.sns === 'tiktok'),
        this.instagram_post_type,
        is_instagram_reels,
        is_instagram_carousel
      )

      if (result !== '') {
        if (!confirm(result)) return

        this.setVideos([])
        this.setType('text')
      }
    }

    TrackingService.sendEvent('select:投稿作成|画面内ヘッダー|公開先')

    await this.changeAccounts(accounts)
  }
}
