import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import HelpLink from '@/client/components/molecules/HelpLink'
import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import GroupSettingOperatorItem from '@/client/components-old/molecules/GroupSettingOperatorItem'
import Search from '@/client/components-old/molecules/Search'
import { IRootState } from '@/client/store/global'
import { IState as IGroupSettingState } from '@/client/store/modules/group_setting'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

@Component({
  name: 'GroupSettingOperator',
  components: {
    Button,
    ButtonLink,
    HelpLink,
    Icon,
    Message,
    Panel,
    Tooltip,
    GroupSettingOperatorItem,
    Search
  }
})
export default class GroupSettingOperator extends Vue {
  @State('user') user: IRootState['user']
  @State('project') project: IRootState['project']
  @group_setting.State('api_operators') api_operators: IGroupSettingState['api_operators']
  @group_setting.Action('removeOperatorGroupRoles') removeOperatorGroupRoles
  @notification.Action('showNotification') showNotification

  keyword = ''
  keyword_search = ''

  get remain_operator() {
    const remain = this.project.operator_max_count - this.api_operators.length

    return remain > 0 ? remain : 0
  }

  get disabled() {
    return this.remain_operator === 0
  }

  get operators() {
    const admins = this.api_operators.filter(operator => operator.role && operator.role.role_admin)

    const login_user = this.api_operators.filter(v => v.user.id === this.user.id)
    const without_login_users = this.api_operators.filter(v => v.user.id !== this.user.id)

    const users = [...login_user, ...without_login_users].map(operator => {
      const is_admin = operator.role ? operator.role.role_admin : 0

      return {
        ...operator.user,
        is_admin,
        is_disabled_cancel: is_admin && admins.length === 1
      }
    })

    return users.filter(v => v.name.toUpperCase().match(this.keyword_search.toUpperCase()))
  }

  /*
   * オペレーターの絞り込み
   */
  submitSearchOperator(keyword: string) {
    this.keyword_search = keyword
  }

  /*
   * オペレーター登録解除
   */
  async cancelOperator(payload: { id: number }): Promise<void> {
    const result = await this.removeOperatorGroupRoles({ user_id: payload.id })

    if (result.data) {
      if (this.user.id === payload.id) {
        this.changeVue(payload)
      }

      return this.showNotification({ title: 'オペレーターの登録を解除しました。' })
    }

    if (result?.error?.type === 'USER_IN_APPROVAL_FLOW') {
      return this.showNotification({
        type: 'error',
        title: 'オペレーターの登録解除に失敗しました。',
        message: '承認フローの承認者の設定から外すと、登録解除できます。'
      })
    }

    return this.showNotification({
      type: 'error',
      title: 'オペレーターの登録解除に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。'
    })
  }

  @Emit('open-invitation-dialog')
  openInvitationDialog(payload: any) {
    return payload
  }

  @Emit('open-role-dialog')
  openRoleDialog(payload: any) {
    return payload
  }

  @Emit('open-notification-dialog')
  openNotificationDialog(payload: any) {
    return payload
  }

  @Emit('change-vue')
  changeVue(payload: any) {
    return payload
  }
}
