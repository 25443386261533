import { RouteConfig } from 'vue-router'

import TwitterViral from './pages/TwitterViral.vue'

export const twitter_viral: RouteConfig = {
  component: TwitterViral,
  path: '/twitter_viral',
  name: 'twitter_viral',
  meta: {
    auth: true,
    title: 'クチコミ推移 (X)',
    description: '登録したキーワードを言及した投稿数の推移を確認できます。',
    color: 'warning',
    type: 'CHECK_VIRAL'
  }
}
