import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import MonitoringConversationDialog from '@/client/components-old/organisms/MonitoringConversationDialog'
import MonitoringDetailDialog from '@/client/components-old/organisms/MonitoringDetailDialog'
import MonitoringItem from '@/client/components-old/organisms/MonitoringItem'
import MonitoringPostDialog from '@/client/components-old/organisms/MonitoringPostDialog'
import MonitoringTemplateDialog from '@/client/components-old/organisms/MonitoringTemplateDialog'
import { IGetter as IAccountGetter } from '@/client/store/modules/accounts'
import { IState as IMonitoring } from '@/client/store/modules/monitoring'

const monitoring = namespace('monitoring')
const accounts = namespace('accounts')

@Component({
  name: 'MonitoringList',
  components: {
    Message,
    Scroll,
    MonitoringConversationDialog,
    MonitoringDetailDialog,
    MonitoringPostDialog,
    MonitoringTemplateDialog,
    MonitoringItem
  }
})
export default class MonitoringList extends Vue {
  @monitoring.State('api_counts') api_counts: IMonitoring['api_counts']
  @monitoring.State('api_monitorings') api_monitorings: IMonitoring['api_monitorings']
  @monitoring.State('status') status: IMonitoring['status']
  @monitoring.State('filter_account_id') filter_account_id: IMonitoring['filter_account_id']
  @monitoring.State('filter_account_sns') filter_account_sns: IMonitoring['filter_account_sns']
  @monitoring.State('filter_types') filter_types: IMonitoring['filter_types']
  @monitoring.State('filter_keyword') filter_keyword: IMonitoring['filter_keyword']
  @monitoring.State('is_loading') is_loading: IMonitoring['is_loading']
  @monitoring.State('is_scroll') is_scroll: IMonitoring['is_scroll']
  @monitoring.Action('scrollMonitoringPage') scrollMonitoringPage
  @monitoring.Action('refreshMonitoringPage') refreshMonitoringPage
  @monitoring.Action('openDetailDialog') openDetailDialog
  @accounts.Getter('monitoring') monitoring_accounts: IAccountGetter['monitoring']

  $refs: {
    MonitoringConversationDialog: any
    MonitoringPostDialog: any
    MonitoringTemplateDialog: any
    Scroll: any
  }

  @Watch('status')
  @Watch('filter_account_id')
  @Watch('filter_account_sns')
  @Watch('filter_types')
  @Watch('filter_keyword')
  resetScrollTop() {
    this.$refs.Scroll.scrollTop()
  }

  @Watch('monitorings')
  watchMonitorings() {
    if (!this.monitorings.length && this.api_counts[this.status]) {
      this.refreshMonitoringPage()
    }
  }

  get monitorings() {
    if (this.status === 'all') return this.api_monitorings

    return this.api_monitorings.filter(v => v.status === this.status)
  }

  get is_not_twitter_enterprise_verified() {
    const account_info = this.monitoring_accounts.find(
      account => account.id === this.filter_account_id && account.sns === 'twitter'
    )

    return account_info && !account_info.is_enterprise_verified
  }

  /**
   * もっと読み込む
   */
  async loadMore(event: any) {
    if (event.target.scrollTop + event.target.clientHeight + 50 >= event.target.scrollHeight) {
      await this.scrollMonitoringPage()
    }
  }

  /**
   * 会話履歴ダイアログを表示
   */
  openConversationDialog(payload: any) {
    this.$refs.MonitoringConversationDialog.open(payload)
  }

  /**
   * 親投稿ダイアログを表示
   */
  openPostDialog(payload: any) {
    this.$refs.MonitoringPostDialog.open(payload)
  }

  /**
   * 定型文ダイアログを表示
   */
  openTemplateDialog() {
    this.$refs.MonitoringTemplateDialog.open()
  }
}
