
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'InputNumber'
})
export default class InputNumber extends Vue {
  @Prop({ type: Number, default: 0 })
  value!: number

  @Prop({ type: Number, default: -Infinity })
  min!: number

  @Prop({ type: Number, default: Infinity })
  max!: number

  @Prop({ type: Number, default: 1 })
  step!: number

  @Prop({ type: Boolean, default: false })
  stepStrictly!: boolean

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: String, default: '' })
  placeholder!: string

  @Emit('input')
  changeInputNumber(val: number) {
    return val
  }
}
