/** Over capacity */
export const ERROR_CODE_130 = 130

/** Internal error */
export const ERROR_CODE_131 = 131

/** URL数超過 */
export const ERROR_CODE_372 = 372

/** ハッシュタグの文字数超過 */
export const ERROR_CODE_384 = 384

/** Internal Server Error */
export const ERROR_CODE_500 = 500

/** Bad Gateway */
export const ERROR_CODE_502 = 502

/** Service Unavailable */
export const ERROR_CODE_503 = 503

/** Gateway Timeout */
export const ERROR_CODE_504 = 504
