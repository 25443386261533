export const HASHTAG_NAME = { value: 'hashtag_name', text: '分類名' }
export const HASHTAG_ORDER_NO = { value: 'hashtag_order_no', text: 'キーワード分類順' }
export const POST_COUNT = { value: 'post_count', text: '投稿数' }
export const POSITIVE_COUNT = { value: 'positive_count', text: 'ポジティブ' }
export const NEGATIVE_COUNT = { value: 'negative_count', text: 'ネガティブ' }
export const REACTION_COUNT = { value: 'reaction_count', text: '反応数' }
export const LIKE_COUNT = { value: 'like_count', text: 'いいね！' }
export const COMMENT_COUNT = { value: 'comment_count', text: 'コメント' }
export const TIMESTAMP = { value: 'timestamp', text: '公開日時' }
export const MESSAGE = { value: 'message', text: '投稿内容' }
export const THUMBNAIL_URL = { value: 'thumbnail_url', text: 'サムネイルURL' }
export const POST_URL = { value: 'post_url', text: '投稿URL' }
export const POST_ID = { value: 'post_id', text: '投稿ID' }
export const POST_TYPE = { value: 'post_type', text: '投稿タイプ' }
export const MEDIA_COUNT = { value: 'media_count', text: 'メディアの数' }
export const CATEGORY = { value: 'category', text: 'タグ' }
export const IS_TOP_MEDIA = { value: 'is_top_media', text: '人気投稿' }

export const DISPLAY_OPTIONS = [
  { text: '平均', value: 'average' },
  { text: '合計', value: 'total' }
]

export const POST_TYPE_OPTIONS = [
  { text: '画像', value: 'image' },
  { text: '動画', value: 'video' },
  { text: 'カルーセル', value: 'carousel' },
  { text: 'リール', value: 'reels' }
]

export const SENTIMENT_OPTIONS = [
  { text: 'ポジティブ', value: 'positive' },
  { text: 'ネガティブ', value: 'negative' },
  { text: 'ニュートラル', value: 'neutral' }
]

export const POST_LAYOUT_DISPLAY_TYPE = [
  { text: 'テーブル', value: 'table' },
  { text: 'グリッド', value: 'grid' }
]

export const SUMMARY_METRICS = [
  HASHTAG_NAME,
  POST_COUNT,
  POSITIVE_COUNT,
  NEGATIVE_COUNT,
  REACTION_COUNT,
  LIKE_COUNT,
  COMMENT_COUNT
]

export const SUMMARY_TABLE_COLUMNS = [
  HASHTAG_NAME.value,
  POST_COUNT.value,
  POSITIVE_COUNT.value,
  NEGATIVE_COUNT.value,
  REACTION_COUNT.value,
  LIKE_COUNT.value,
  COMMENT_COUNT.value
]

export const POST_METRICS = [TIMESTAMP, MESSAGE, REACTION_COUNT, LIKE_COUNT, COMMENT_COUNT]

export const POST_TABLE_COLUMNS = [REACTION_COUNT.value, LIKE_COUNT.value, COMMENT_COUNT.value]

export const POST_SORT_LABELS = [
  TIMESTAMP.value,
  REACTION_COUNT.value,
  LIKE_COUNT.value,
  COMMENT_COUNT.value
]

export const HASHTAG_SIZE_OPTIONS = [25, 50, 75, 100]

export const WORD_CLOUD_SIZE_OPTIONS = [25, 50, 75, 100]

export const RANKING_SORT_OPTIONS = [REACTION_COUNT, LIKE_COUNT, COMMENT_COUNT]

export const RANKING_SIZE_OPTIONS = [5, 10, 25, 50]

export const HEATMAP_HOUR_OPTIONS = [
  '0:00',
  '1:00',
  '2:00',
  '3:00',
  '4:00',
  '5:00',
  '6:00',
  '7:00',
  '8:00',
  '9:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00'
]

export const HEATMAP_DAY_OF_WEEK_OPTIONS = [
  '月曜日',
  '火曜日',
  '水曜日',
  '木曜日',
  '金曜日',
  '土曜日',
  '日曜日'
]

export const HEATMAP_METRICS = [POST_COUNT, REACTION_COUNT, LIKE_COUNT, COMMENT_COUNT]
