
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import InstagramHashtagHeader from '@/client/components-old/organisms/InstagramHashtagHeader'
import InstagramHashtagManagementLayout from '@/client/components-old/templates/InstagramHashtagManagementLayout'
import InstagramHashtagNodataLayout from '@/client/components-old/templates/InstagramHashtagNodataLayout'
import InstagramHashtagPostLayout from '@/client/components-old/templates/InstagramHashtagPostLayout'
import InstagramHashtagSummaryLayout from '@/client/components-old/templates/InstagramHashtagSummaryLayout'
import { IRootState } from '@/client/store/global'
import { IState as IInstagramHashtagState } from '@/client/store/modules/instagram_hashtag'

const instagram_hashtag = namespace('instagram_hashtag')

@Component({
  name: 'InstagramHashtag',
  components: {
    InstagramHashtagHeader,
    InstagramHashtagNodataLayout,
    InstagramHashtagSummaryLayout,
    InstagramHashtagPostLayout,
    InstagramHashtagManagementLayout
  }
})
export default class InstagramHashtag extends Vue {
  @State('project') project!: IRootState['project']
  @instagram_hashtag.State('screen_name') screen_name!: IInstagramHashtagState['screen_name']
  @instagram_hashtag.State('hashtag_ids') hashtag_ids!: IInstagramHashtagState['hashtag_ids']
  @instagram_hashtag.State('is_loading') is_loading!: IInstagramHashtagState['is_loading']
  @instagram_hashtag.Action('createdInstagramHashtagPage') createdInstagramHashtagPage!: any
  @instagram_hashtag.Action('destroyedInstagramHashtagPage') destroyedInstagramHashtagPage!: any
  @instagram_hashtag.Action('changeGroupInstagramHashtagPage') changeGroupInstagramHashtagPage!: any

  @Prop({ type: Number, default: 0 })
  group!: number

  @Emit('change-project')
  changeProject(payload: any) {
    return payload
  }

  @Emit('change-route')
  changeRoute(payload: any) {
    return payload
  }

  get is_empty() {
    if (this.is_loading || this.is_management) return false

    return !this.hashtag_ids.length
  }

  get is_summary() {
    return this.screen_name === 'summary'
  }

  get is_post() {
    return this.screen_name === 'post'
  }

  get is_management() {
    return this.screen_name === 'management'
  }

  @Watch('group')
  async watchGroup() {
    await this.changeGroupInstagramHashtagPage()
  }

  /**
   * ページ表示時
   */
  async created() {
    if (this.$route.query.project_id && Number(this.$route.query.project_id) !== this.project.id) {
      this.changeProject({ project_id: Number(this.$route.query.project_id) })
      return
    }

    await this.createdInstagramHashtagPage()
  }

  /**
   * ページ離脱時
   */
  async beforeDestroy() {
    await this.destroyedInstagramHashtagPage()
  }
}
