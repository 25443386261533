/** Application does not have permission for this action */
export const ERROR_CODE_10 = 10

/** API Permission */
export const ERROR_CODE_200 = 200

/** API Permission */
export const ERROR_CODE_299 = 299

/** Missing or invalid image file */
export const ERROR_CODE_324 = 324

/** Duplicate Post */
export const ERROR_CODE_504 = 504

/** Duplicate Post */
export const ERROR_CODE_506 = 506
