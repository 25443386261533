
import { Component, Emit, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import AnalyticsNodata from '@/client/components-old/organisms/AnalyticsNodata'

@Component({
  name: 'InstagramAnalyticsNodata',
  components: {
    Scroll,
    AnalyticsNodata
  }
})
export default class InstagramAnalyticsNodata extends Vue {
  @Emit('change-route')
  changeRoute(payload: any): any {
    return payload
  }
}
