
import { computed, defineComponent, PropType } from 'vue'

import { AppTooltip, AvatarService, AvatarUser } from '@/client/components/atoms'
import Badge from '@/client/components-old/atoms/Badge'
import { IPostHistory } from '@/client/utils/api/post_histories'
import { getTimeFormat as f, getTranslateText as t } from '@/client/utils/filters'

import { getHistoryTypeColor, getHistoryTypeText } from './utils'

export default defineComponent({
  name: 'PostHistoryItem',
  components: {
    AppTooltip,
    AvatarService,
    AvatarUser,
    Badge
  },
  props: {
    history: {
      type: Object as PropType<IPostHistory>,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const is_system = computed(() => !props.history.user)
    const badge_color = computed(() => getHistoryTypeColor(props.history.type))
    const badge_text = computed(() => getHistoryTypeText(props.history.type))

    function onClick() {
      context.emit('click', props.history.id)
    }

    return {
      is_system,
      badge_color,
      badge_text,
      onClick,
      f,
      t
    }
  }
})
