import $ from 'jquery'
import moment from 'moment-timezone'
import twttr from 'twitter-text'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

import { ZoomBotton } from '@/client/components/atoms'
import TwitterPost from '@/client/components-old/molecules/TwitterPost'
import TwitterTweet from '@/client/components-old/molecules/TwitterTweet'
import { IRootState } from '@/client/store/global'
import { ITwitterPost, ITwitterTweet } from '@/client/utils/api/posts'
import { ITwitterCard } from '@/client/utils/api/twitter_cards'

import { checkYouTubeLink } from './utils'

@Component({
  name: 'PreviewTwitter',
  components: {
    ZoomBotton,
    TwitterPost,
    TwitterTweet
  }
})
export default class PreviewTwitter extends Vue {
  @State('user') user: IRootState['user']

  @Prop({ type: String, default: 'app' })
  mode: 'app' | 'web'

  @Prop({ type: Object, required: true })
  options: {
    screen_name: null | string
    user_name: null | string
    profile_image_url: null | string
    release_date: null | string
    type: 'text' | 'photo' | 'link' | 'video' | 'animation-gif'
    message: string
    image_urls: string[]
    video_url: null | string
    link_url: null | string
    link: null | ITwitterCard
    twitter_quote: {
      target_tw_post: ITwitterPost | null
      target_tweet: ITwitterTweet | null
    } | null
    twitter_reply: {
      target_tw_post: ITwitterPost | null
      target_tweet: ITwitterTweet | null
    } | null
  }

  $refs: {
    container: HTMLDivElement
    scroll: HTMLDivElement
    video: HTMLVideoElement
  }

  is_video_button = true

  get is_app() {
    return this.mode === 'app'
  }

  get is_web() {
    return this.mode === 'web'
  }

  get is_image() {
    return (
      (this.options.type === 'photo' && this.options.image_urls.length > 0) ||
      (this.options.type === 'animation-gif' && this.options.image_urls.length === 1)
    )
  }

  get is_video() {
    return (
      this.options.type === 'video' &&
      this.options.video_url !== null &&
      this.options.video_url !== ''
    )
  }

  get is_link() {
    return (
      this.options.type === 'link' &&
      this.options.link_url !== null &&
      this.options.link_url !== '' &&
      this.options.link !== null
    )
  }

  get is_app_youtube_link() {
    return this.is_link && checkYouTubeLink(this.options.link.url) && this.is_app
  }

  get class_images() {
    if (!this.is_image) return ''

    if (this.options.type === 'animation-gif') {
      return 'is-animation-gif'
    }

    return `is-${this.options.image_urls.length}`
  }

  get message() {
    let message = this.options.message

    // TwitterCardが表示されてる場合、テキストの後ろにあるTwitterCardのURLを除去する
    if (this.options.type === 'link' && this.options.link) {
      const urls: string[] = twttr.extractUrls(message)

      if (urls.length && !this.is_app_youtube_link) {
        const url = urls[urls.length - 1] // 最後のURLのみ
        const pattern = url.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&') // 記号をエスケープ
        const search = new RegExp(pattern + '$')
        message = message.replace(search, '')
      }
    }

    message = this.multilineEscape(message)

    message = this.htmlEscape(message)

    const entities = twttr.extractEntitiesWithIndices(message, {
      extractUrlsWithoutProtocol: true
    })

    message = twttr.autoLinkEntities(message, entities, {
      hashtagUrlBase: 'https://x.com/hashtag/',
      usernameUrlBase: 'https://x.com/',
      usernameIncludeSymbol: true,
      targetBlank: true
    })

    return message
  }

  get release_date() {
    let format = 'YYYY/MM/DD'

    if (this.is_web) {
      format = this.user.language === 'ja' ? 'YYYY年M月D日' : 'MMM D, YYYY'
    }

    return moment(this.options.release_date).format(format)
  }

  get link_domain() {
    return this.options.link.domain.replace('www.', '')
  }

  @Emit('click-media')
  clickMedia(index: number) {
    return {
      index,
      media: this.options.image_urls.map(url => ({ type: 'image', url }))
    }
  }

  /**
   * マウント時
   */
  async mounted(): Promise<void> {
    if (this.is_app) {
      $(this.$refs.scroll).slimScroll({
        height: this.$refs.container.clientHeight + 'px',
        size: '6px',
        wheelStep: 10,
        touchScrollStep: 50
      })
    }
  }

  /**
   * マルチ改行を除去する
   */
  multilineEscape(text: string): string {
    return text.replace(/\n\n\s*\n/g, '\n\n')
  }

  /**
   * HTMLで使用する特殊文字を除去する
   */
  htmlEscape(text: string): string {
    const entities = {
      '>': '&gt;',
      '<': '&lt;',
      '"': '&quot;',
      "'": '&#39;'
    }

    return text.replace(/["'><]/g, character => entities[character])
  }

  /**
   * 背景画像用のスタイル生成
   */
  styleBackgroundImage(image_url?: string | null): { 'background-image': string } | null {
    if (!image_url || image_url === '') return null
    return { 'background-image': 'url(' + image_url + ')' }
  }

  /**
   * 動画のクリックイベント
   */
  async handleClickVideo(): Promise<void> {
    this.is_video_button = !this.$refs.video.paused

    await this.$refs.video.play()

    if (this.is_video_button) {
      this.$refs.video.pause()
    }
  }

  onClickMedia() {
    if (this.$refs.video && !this.$refs.video.paused) {
      this.$refs.video.pause()
      this.is_video_button = true
    }

    if (this.options.video_url) {
      this.$emit('click-media', {
        index: 0,
        media: [{ type: 'video', url: this.options.video_url }]
      })
    }
  }
}
