import * as FACEBOOK from '@/client/constants/facebook'
import * as INSTAGRAM from '@/client/constants/instagram'
import * as TIKTOK from '@/client/constants/tiktok'
import * as TWITTER from '@/client/constants/twitter'
import API from '@/client/utils/api'
import {
  PostMediaFbPostsByIdResponse,
  PostMediaInPostsByIdResponse,
  PostMediaInStoryByIdResponse,
  PostMediaTwPostsByIdResponse
} from '@/client/utils/api/media'
import {
  IGetMediasTwitterProgressResponse,
  IPostMediasFacebookUploadParams,
  IPostMediasFacebookUploadResponse,
  IPostMediasTwitterUploadParams,
  IPostMediasTwitterUploadResponse
} from '@/client/utils/api/medias'
import {
  IPost,
  IPublishPostParams,
  IPublishPostResponse,
  IRepublishPostParams,
  IRepublishPostResponse
} from '@/client/utils/api/posts'
import { delay } from '@/client/utils/event'
import { convertHelpMessage } from '@/client/utils/notification/index'
import { SnsType } from '@/common/types'

/**
 * 承認申請完了後の投稿公開
 */
export async function approvePublish(post: IPost) {
  // TODO: 型定義がひどくて辛いので見直す
  let media_ids: any[] = []

  const is_animation_gif = post.post_images.some(v => v.is_animation_gif)

  // TODO: 将来的に POST /api/v1/media/fb_posts/:fb_post_id が処理を行うようにする
  if (post.fb_posts.length && post.post_images.length && !is_animation_gif) {
    const account_ids = post.fb_posts.map(post => post.account.id)
    const image_urls = post.post_images.map(image => image.image_url)

    try {
      media_ids = await uploadMediaFacebook(account_ids, image_urls)
    } catch (err) {
      await API.post(`posts/${post.id}/restore_post_approval_flow`)

      return err
    }
  }

  // TODO: 将来的に POST /api/v1/media/tw_posts/:tw_post_id が処理を行うようにする
  if (post.tw_posts.length && post.post_images.length + post.post_videos.length > 0) {
    const account_ids = post.tw_posts.map(post => post.account.id)

    const media_urls = post.post_videos.length
      ? post.post_videos.filter(v => v.sequence_no === 1).map(v => v.video_url)
      : post.post_images.filter(v => v.sequence_no <= 4).map(image => image.image_url)

    let media_type = 'image'
    if (post.post_videos.length) media_type = 'video'
    if (is_animation_gif) media_type = 'animation_gif'

    try {
      // TODO: 型定義がズレてる
      const twitter_media_ids = await uploadMediaTwitter(account_ids, media_urls, media_type)

      media_ids = [...media_ids, ...twitter_media_ids]
    } catch (err) {
      await API.post(`posts/${post.id}/restore_post_approval_flow`)

      return err
    }
  }

  const params: IPublishPostParams = {
    type: 'approve_publish',
    medias: media_ids,
    is_edit_media: false
  }

  const { data } = await API.post<IPublishPostResponse>(`posts/${post.id}/publish`, params)

  if (data && data.data && data.data.status !== 'ALL_ACCOUNT_SUCCESS') {
    const result = data.data

    if (result.error && result.error.length && result.error[0].error === 'TOKEN_EXPIRED') {
      return {
        error: {
          type: 'TOKEN_EXPIRED',
          title: 'アクセストークンが失効しています。',
          message: convertHelpMessage({
            message: 'アクセストークンを更新してください。',
            help_color: 'warning',
            help_type: 'ABOUT_ACCESS_TOKEN'
          })
        }
      }
    }

    if (result.error && result.error.length && result.error[0].error === 'PUBLISH_RATE_LIMIT') {
      return {
        error: {
          title: '1日の最大投稿数(25件)に到達しています。',
          message: '明日以降に再投稿するか、公式Instagramから公開してください。'
        }
      }
    }

    if (result.error && result.error.length && result.error[0].error === 'PERMISSION_DENIED') {
      return {
        error: {
          title: '投稿権限がないため、公開に失敗しました。'
        }
      }
    }

    if (result.error && result.error.length && result.error[0].error === 'POST_STATUS_DUPLICATED') {
      return {
        error: {
          title: '投稿の公開に失敗しました。',
          message: '同じ内容の投稿がすでに存在しています。投稿内容を変更してください。'
        }
      }
    }

    if (result.error && result.error.length && result.error[0].error === 'TWITTER_REPLY_DELETED') {
      return {
        error: {
          title: '返信先の投稿が削除されています。'
        }
      }
    }

    if (
      result.error &&
      result.error.length &&
      result.error[0].error === 'TWITTER_REPLY_UNPUBLISH'
    ) {
      return {
        error: {
          title: '返信先の投稿が公開されていません。'
        }
      }
    }

    if (result.error && result.error.length && result.error[0].error === 'TWITTER_REPLY_INVALID') {
      return {
        error: {
          title: '返信先の投稿が存在しません。'
        }
      }
    }

    if (result.error && result.error.length && result.error[0].error === 'TWITTER_REPLY_EDITED') {
      return {
        error: {
          title: '返信先の投稿が編集されているため投稿を公開できません。'
        }
      }
    }

    if (result.error && result.error.length && result.error[0].error === 'TWITTER_QUOTE_DELETED') {
      return {
        error: {
          title: '引用先の投稿が削除されています。'
        }
      }
    }

    if (
      result.error &&
      result.error.length &&
      result.error[0].error === 'TWITTER_QUOTE_UNPUBLISH'
    ) {
      return {
        error: {
          title: '引用先の投稿が公開されていません。'
        }
      }
    }

    if (result.error && result.error.length && result.error[0].error === 'TWITTER_QUOTE_INVALID') {
      return {
        error: {
          title: '引用元の投稿が存在しません。'
        }
      }
    }

    if (result.error && result.error.length && result.error[0].error === 'TWITTER_QUOTE_EDITED') {
      return {
        error: {
          title: '引用元の投稿が編集されているため投稿を公開できません。'
        }
      }
    }

    if (result.status === 'HAS_ACCOUNT_FAILED') {
      return {
        error: {
          title: '投稿の公開に失敗したアカウントがあります。'
        }
      }
    }

    if (
      result.error &&
      result.error.length &&
      result.error[0].error === 'FACEBOOK_INTERNAL_SERVER_ERROR'
    ) {
      return {
        error: {
          title: '投稿の公開に失敗しました。',
          message:
            'Facebookの一時的なサーバー不調により、投稿に失敗しました。時間をあけて、再投稿をお願いします。'
        }
      }
    }

    if (
      result.error &&
      result.error.length &&
      result.error[0].error === 'TWITTER_INTERNAL_SERVER_ERROR'
    ) {
      return {
        error: {
          title: '投稿の公開に失敗しました。',
          message:
            'Xの一時的なサーバー不調により、投稿に失敗しました。時間をおいて、再投稿をお願いします。'
        }
      }
    }

    if (
      result.error &&
      result.error.length &&
      result.error[0].error === 'TWITTER_SPAM_TWEET_ERROR'
    ) {
      return {
        error: {
          title: '投稿の公開に失敗しました。',
          message: convertHelpMessage({
            message: 'アカウントが凍結されているか、投稿内容に問題がある可能性があります。',
            help_color: 'warning',
            help_type: 'ACCOUNT_SUSPEND'
          })
        }
      }
    }

    if (result.error && result.error.length) {
      const { sns, error_code } = result.error[0]

      if (sns === 'facebook') {
        return createErrorNotificationByFacebook(error_code)
      }

      if (sns === 'instagram') {
        return createErrorNotificationByInstagram(error_code)
      }

      if (sns === 'twitter') {
        return createErrorNotificationByTwitter(error_code)
      }

      if (sns === 'tiktok') {
        return createErrorNotificationByTikTok(error_code)
      }
    }

    if (result.status === 'ALL_ACCOUNT_FAILED') {
      return {
        error: {
          title: '通信エラーが発生しました。',
          message: '恐れ入りますが、時間をおいて再度お試しください。'
        }
      }
    }
  }

  return data
}

/**
 * 再投稿を行う
 */
export async function republish(sns: SnsType, account_id: string, post: IPost): Promise<any> {
  await preUploadMedia({
    ...post,
    fb_posts: post.fb_posts.filter(v => sns === 'facebook' && v.account.id === account_id),
    tw_posts: post.tw_posts.filter(v => sns === 'twitter' && v.account.id === account_id),
    in_posts: post.in_posts.filter(v => sns === 'instagram' && v.account.id === account_id),
    in_stories: post.in_stories.filter(v => sns === 'instagram' && v.account.id === account_id)
  })

  // TODO: 型定義がズレてる
  let media_ids: any = []

  const is_animation_gif = post.post_images.some(v => v.is_animation_gif)

  // TODO: 将来的に POST /api/v1/media/fb_posts/:fb_post_id が処理を行うようにする
  if (sns === 'facebook' && post.post_images.length && !is_animation_gif) {
    const account_ids = [account_id]
    const image_urls = post.post_images.map(image => image.image_url)

    try {
      media_ids = await uploadMediaFacebook(account_ids, image_urls)
    } catch (err) {
      return err
    }
  }

  // TODO: 将来的に POST /api/v1/media/tw_posts/:tw_post_id が処理を行うようにする
  if (sns === 'twitter' && post.post_images.length + post.post_videos.length > 0) {
    const account_ids = [account_id]

    const media_urls = post.post_videos.length
      ? post.post_videos.filter(v => v.sequence_no === 1).map(v => v.video_url)
      : post.post_images.filter(v => v.sequence_no <= 4).map(image => image.image_url)

    let media_type = 'image'
    if (post.post_videos.length) media_type = 'video'
    if (is_animation_gif) media_type = 'animation_gif'

    try {
      media_ids = await uploadMediaTwitter(account_ids, media_urls, media_type)
    } catch (err) {
      return err
    }
  }

  if (!media_ids) {
    return {
      error: {
        title: '投稿の公開に失敗しました。'
      }
    }
  }

  const params: IRepublishPostParams = {
    sns_post_id: post.id,
    sns,
    account_id,
    media_ids: media_ids.reduce((acc: any, cur: any) => [...acc, ...cur.media_ids], [])
  }

  const { data } = await API.post<IRepublishPostResponse>(`posts/${post.id}/republish`, params)

  if (data.error) {
    if (data.error.type === 'TOKEN_EXPIRED') {
      return {
        error: {
          type: 'TOKEN_EXPIRED',
          title: 'アクセストークンが失効しています。',
          message: convertHelpMessage({
            message: 'アクセストークンを更新してください。',
            help_color: 'warning',
            help_type: 'ABOUT_ACCESS_TOKEN'
          })
        }
      }
    }

    if (data.error.type === 'PUBLISH_RATE_LIMIT') {
      return {
        error: {
          title: '1日の最大投稿数(25件)に到達しています。',
          message: '明日以降に再投稿するか、公式Instagramから公開してください。'
        }
      }
    }

    if (data.error.type === 'POST_STATUS_DUPLICATED') {
      return {
        error: {
          title: '投稿の公開に失敗しました。',
          message: '同じ内容の投稿がすでに存在しています。投稿内容を変更してください。'
        }
      }
    }

    if (data.error.type === 'PERMISSION_DENIED') {
      return {
        error: {
          title: '投稿権限がないため、公開に失敗しました。'
        }
      }
    }

    if (data.error.type === 'NOT_EXISTS') {
      return {
        error: {
          title: '投稿はすでに削除されました。'
        }
      }
    }

    if (data.error.type === 'TWITTER_REPLY_DELETED') {
      return {
        error: {
          title: '返信先の投稿が削除されています。'
        }
      }
    }

    if (data.error.type === 'TWITTER_REPLY_UNPUBLISH') {
      return {
        error: {
          title: '返信先の投稿が公開されていません。'
        }
      }
    }

    if (data.error.type === 'TWITTER_REPLY_EDITED') {
      return {
        error: {
          title: '返信先の投稿が編集されているため投稿を公開できません。'
        }
      }
    }

    if (data.error.type === 'TWITTER_QUOTE_DELETED') {
      return {
        error: {
          title: '引用先の投稿が削除されています。'
        }
      }
    }

    if (data.error.type === 'TWITTER_QUOTE_UNPUBLISH') {
      return {
        error: {
          title: '引用先の投稿が公開されていません。'
        }
      }
    }

    if (data.error.type === 'TWITTER_QUOTE_EDITED') {
      return {
        error: {
          title: '引用元の投稿が編集されているため投稿を公開できません。'
        }
      }
    }

    if (data.error.type === 'FACEBOOK_INTERNAL_SERVER_ERROR') {
      return {
        error: {
          title: '投稿の公開に失敗しました。',
          message:
            'Facebookの一時的なサーバー不調により、投稿に失敗しました。時間をあけて、再投稿をお願いします。'
        }
      }
    }

    if (data.error.type === 'TWITTER_INTERNAL_SERVER_ERROR') {
      return {
        error: {
          title: '投稿の公開に失敗しました。',
          message:
            'Xの一時的なサーバー不調により、投稿に失敗しました。時間をおいて、再投稿をお願いします。'
        }
      }
    }

    if (data.error.type === 'TWITTER_SPAM_TWEET_ERROR') {
      return {
        error: {
          title: '投稿の公開に失敗しました。',
          message: convertHelpMessage({
            message: 'アカウントが凍結されているか、投稿内容に問題がある可能性があります。',
            help_color: 'warning',
            help_type: 'ACCOUNT_SUSPEND'
          })
        }
      }
    }

    switch (sns) {
      case 'facebook':
        return createErrorNotificationByFacebook(data.error.error_code)
      case 'twitter':
        return createErrorNotificationByTwitter(data.error.error_code)
      case 'instagram':
        return createErrorNotificationByInstagram(data.error.error_code)
      case 'tiktok':
        return createErrorNotificationByTikTok(data.error.error_code)
      default:
        break
    }
  }

  return { data: true }
}

export async function createErrorNotificationByFacebook(error_code: number) {
  switch (error_code) {
    case FACEBOOK.ERROR_CODE_10:
      return {
        error: {
          title: 'Facebookページへのアクセスが許可されていません。',
          message: convertHelpMessage({
            message: 'アクセストークンを更新してください。',
            help_color: 'warning',
            help_type: 'ABOUT_ACCESS_TOKEN'
          })
        }
      }
    case FACEBOOK.ERROR_CODE_324:
      return {
        error: {
          title: '画像または動画の形式に問題があります。ヘルプをご確認ください。',
          message: convertHelpMessage({
            help_color: 'warning',
            help_type: 'POST_IMAGE'
          })
        }
      }
    case FACEBOOK.ERROR_CODE_504:
    case FACEBOOK.ERROR_CODE_506:
      return {
        error: {
          title: '同じ内容の投稿がすでに存在しています。',
          message: '投稿内容を変更してください。'
        }
      }
    default:
      if (error_code >= FACEBOOK.ERROR_CODE_200 && error_code < FACEBOOK.ERROR_CODE_299) {
        return {
          error: {
            title: 'Facebookページの編集者または管理者権限がありません。',
            message: convertHelpMessage({
              message: 'アクセストークンを更新してください。',
              help_color: 'warning',
              help_type: 'ABOUT_ACCESS_TOKEN'
            })
          }
        }
      }

      return {
        error: {
          title: '通信エラーが発生しました。',
          message: '恐れ入りますが、時間をおいて再度お試しください。'
        }
      }
  }
}

export async function createErrorNotificationByInstagram(error_code: number) {
  switch (error_code) {
    case INSTAGRAM.ERROR_CODE_25:
      return {
        error: {
          title: 'アカウントが制限されている、またはプロアカウントに設定されていません。',
          message: '公式Instagramに正常にログインできるか、プロアカウントであるかをご確認ください。'
        }
      }
    case INSTAGRAM.ERROR_CODE_324:
      return {
        error: {
          title: '画像または動画の形式に問題があります。ヘルプをご確認ください。',
          message: convertHelpMessage({
            help_color: 'warning',
            help_type: 'POST_IMAGE'
          })
        }
      }
    case INSTAGRAM.ERROR_CODE_352:
      return {
        error: {
          title: '動画の形式に問題があります。',
          message: convertHelpMessage({
            help_color: 'warning',
            help_type: 'POST_VIDEO'
          })
        }
      }
    case INSTAGRAM.ERROR_CODE_36000:
    case INSTAGRAM.ERROR_CODE_36003:
      return {
        error: {
          title: '画像の形式に問題があります。',
          message: convertHelpMessage({
            help_color: 'warning',
            help_type: 'POST_IMAGE'
          })
        }
      }

    default:
      return {
        error: {
          title: 'Instagramの一時的なサーバー不調が起きています。',
          message: '恐れ入りますが、時間をおいて再度お試しください。'
        }
      }
  }
}

export async function createErrorNotificationByTwitter(error_code: number) {
  switch (error_code) {
    case TWITTER.ERROR_CODE_130:
    case TWITTER.ERROR_CODE_131:
    case TWITTER.ERROR_CODE_500:
    case TWITTER.ERROR_CODE_502:
    case TWITTER.ERROR_CODE_503:
    case TWITTER.ERROR_CODE_504:
      return {
        error: {
          title: 'Xの一時的なサーバー不調が起きています。',
          message: '恐れ入りますが、時間をおいて再度お試しください。'
        }
      }
    case TWITTER.ERROR_CODE_372:
      return {
        error: {
          title: 'URLの数を10個以内にしてください。'
        }
      }
    case TWITTER.ERROR_CODE_384:
      return {
        error: {
          title: 'ハッシュタグの文字数を100文字以内にしてください。'
        }
      }
    default:
      return {
        error: {
          title: '通信エラーが発生しました。',
          message: '恐れ入りますが、時間をおいて再度お試しください。'
        }
      }
  }
}

export async function createErrorNotificationByTikTok(error_code: number) {
  // ? 公開申請の時のエラーしか受け取れないので注意（webhookのエラーは受け取れない）
  switch (error_code) {
    case TIKTOK.ERROR_CODE_40002:
      return {
        error: {
          title: '投稿内容に問題があります。',
          message: convertHelpMessage({
            help_color: 'warning',
            help_type: 'POST_PUBLISH_FAILED'
          })
        }
      }
    case TIKTOK.ERROR_CODE_40016:
    case TIKTOK.ERROR_CODE_40100:
    case TIKTOK.ERROR_CODE_40133:
      return {
        error: {
          title: '公式APIの投稿数制限に達しています。',
          message: '時間をおいて再投稿するか、公式TikTokから公開してください。'
        }
      }
    default:
      return {
        error: {
          title: '通信エラーが発生しました。',
          message: '恐れ入りますが、時間をおいて再度お試しください。'
        }
      }
  }
}

/**
 * Facebookメディアアップロード
 * TODO: 将来的に POST /api/v1/media/fb_posts/:fb_post_id が処理を行うようにする
 */
async function uploadMediaFacebook(account_ids: string[], image_urls: string[]) {
  const result = []

  for (const account_id of account_ids) {
    const tasks = image_urls.map(image_url => {
      const params: IPostMediasFacebookUploadParams = {
        account_id,
        image_url
      }

      return API.post<IPostMediasFacebookUploadResponse>('medias?sns=facebook', params)
    })

    const responses = await Promise.all(tasks)

    const media_ids = responses
      .filter(response => response.data.data)
      .map(response => response.data.data?.media_id)

    if (!media_ids.length) {
      throw {
        error: {
          title: 'アップロードに失敗しました。'
        }
      }
    }

    result.push({
      account_id,
      media_ids,
      sns: 'facebook'
    })
  }

  return result
}

/**
 * Twitterメディアアップロード
 * TODO: 将来的に POST /api/v1/media/tw_posts/:tw_post_id が処理を行うようにする
 */
async function uploadMediaTwitter(account_ids: string[], media_urls: string[], media_type: string) {
  const upload = async (media_url: string) => {
    const params: IPostMediasTwitterUploadParams = {
      account_ids,
      media_url,
      media_type
    }

    const { data } = await API.post<IPostMediasTwitterUploadResponse>(`medias?sns=twitter`, params)

    if (!data.data || !data.data.id) {
      return null
    }

    const id = data.data.id

    const LOOP = true

    while (LOOP) {
      // TODO: APIの型がエラーがするのでanyにしてる
      const progress = await API.get<IGetMediasTwitterProgressResponse>(`medias/${id}/progress`, {
        params: { sns: 'twitter' }
      })

      if (progress.status === 502) {
        await delay(3000)
      } else if (progress.data.data && progress.data.data === 'pending') {
        await delay(1000)
      } else if (progress.data.data) {
        const result = progress.data.data

        const has_error_media = result.find((v: any) => !v.media_id)
        if (has_error_media) {
          throw {
            error: {
              type: 'MEDIA_UPLOAD_FAILED'
            }
          }
        }

        return result.map((v: any) => ({
          account_id: v.account_id,
          media_ids: [v.media_id],
          sns: 'twitter'
        }))
      } else {
        throw {
          error: {
            title: 'アップロードに失敗しました。'
          }
        }
      }
    }
  }

  const requests = media_urls.map(media_url => upload(media_url))

  const result = await Promise.all(requests)

  return result.reduce((medias, value) => {
    if (!medias?.length) {
      return value
    }

    for (const media of medias) {
      const t = value?.find((v: any) => media.account_id === v.account_id)

      if (t) {
        media.media_ids = [...media.media_ids, ...t.media_ids]
      }
    }

    return medias
  }, [])
}

/**
 * メディアをSNSに事前アップロードする
 */
export async function preUploadMedia(post: IPost) {
  await Promise.all(
    post.fb_posts.map(v => API.post<PostMediaFbPostsByIdResponse>(`media/fb_posts/${v.id}`))
  )

  await Promise.all(
    post.tw_posts.map(v => API.post<PostMediaTwPostsByIdResponse>(`media/tw_posts/${v.id}`))
  )

  if (post.is_auto_publish) {
    await Promise.all(
      post.in_posts.map(v => API.post<PostMediaInPostsByIdResponse>(`media/in_posts/${v.id}`))
    )

    await Promise.all(
      post.in_stories.map(v => API.post<PostMediaInStoryByIdResponse>(`media/in_stories/${v.id}`))
    )
  }
}
