import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'

@Component({
  name: 'AnalyticsPanel',
  components: {
    Button,
    Icon,
    Message,
    Panel,
    Tooltip
  }
})
export default class AnalyticsPanel extends Vue {
  @Prop({ type: Boolean, default: false })
  isData!: boolean

  @Prop({ type: Boolean, default: false })
  isDownload!: boolean

  @Prop({ type: Boolean, default: false })
  isEdit!: boolean

  @Prop({ type: Boolean, default: false })
  isPreview!: boolean

  @Prop({ type: String, default: null })
  panelType!: 'default' | 'info' | 'success' | 'warning' | 'danger'

  @Prop({ type: String, default: null })
  displayType!:
    | 'chart_donut_half'
    | 'chart_horizontal'
    | 'chart_map_world'
    | 'chart_map_japan'
    | 'chart_vertical_area'
    | 'chart_vertical_column'
    | 'chart_vertical_line'
    | 'chart_heatmap'
    | 'goal'
    | 'ranking'
    | 'table'
    | 'total'

  @Prop({ type: String, default: '対象期間のデータがありません。' })
  noDataMessage!: string

  get is_button() {
    return this.isDownload || this.isEdit
  }

  get is_absolute() {
    return !this.isPreview
  }

  @Emit('download')
  onDownload(event) {
    return event
  }

  @Emit('edit')
  onEdit(event) {
    return event
  }

  @Emit('delete')
  onDelete(event) {
    return event
  }
}
