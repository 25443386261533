import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import * as constants from '@/client/components-old/_constants/twitter_comparison_analytics'
import * as twitter_comparison_insight from '@/client/components-old/_utils/twitter_comparison_insight'
import Button from '@/client/components-old/atoms/Button'
import ButtonGroup from '@/client/components-old/atoms/ButtonGroup'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import AnalyticsList from '@/client/components-old/molecules/AnalyticsList'
import ChartSingleBar from '@/client/components-old/molecules/ChartSingleBar'
import { TrackingService } from '@/client/services'
import { IState as IAnalyticsState } from '@/client/store/modules/analytics'
import { IState as ITwitterState } from '@/client/store/modules/twitter_comparison_analytics'
import csv from '@/client/utils/csv'

const analytics = namespace('analytics')
const twitter = namespace('twitter_comparison_analytics')

@Component({
  name: 'TwitterComparisonAnalyticsAccountTable',
  components: {
    AnalyticsList,
    ChartSingleBar,
    Account,
    Panel,
    Button,
    ButtonGroup,
    ButtonLink,
    Message,
    Tooltip,
    Flex,
    Icon
  }
})
export default class TwitterComparisonAnalyticsAccountTable extends Vue {
  @twitter.State('api_sns_comparison_accounts')
  sns_comparison_accounts: ITwitterState['api_sns_comparison_accounts']
  @twitter.State('api_accounts') account_data: ITwitterState['api_accounts']
  @twitter.State('api_posts') post_data: ITwitterState['api_posts']
  @twitter.State('basic_display_format') basic_display_format: ITwitterState['basic_display_format']
  @analytics.State('start_date') start_date: IAnalyticsState['start_date']
  @analytics.State('end_date') end_date: IAnalyticsState['end_date']
  @twitter.Action('changeBasicDisplayFormat') changeBasicDisplayFormat

  @Prop({ type: Number, default: null })
  height: number

  sort: {
    metric: string
    order: 'ascending' | 'descending'
  } = {
    metric: 'order_no',
    order: 'ascending'
  }

  get total_tabs() {
    return constants.TABLE_TABS
  }

  get is_data() {
    return (
      (this.account_data.length > 0 &&
        this.account_data.some(account => account.data.length > 0)) ||
      (this.post_data.length > 0 && this.post_data.some(post => post.data.length > 0))
    )
  }

  get table_datas() {
    const data = []

    const impression_chart_colors = ['#fcdab7']
    const reaction_chart_colors = ['#1c84c6', '#ed5565', '#2ec881', '#eeae67']

    const account_ids = this.account_data.map(account => account.account_id)

    const sns_accounts = this.sns_comparison_accounts.filter(
      account => account_ids.indexOf(account.id) !== -1
    )

    for (const account of sns_accounts) {
      const account_data = this.account_data.find(v => v.account_id === account.id)
      const post_data = this.post_data.find(v => v.account_id === account.id)

      // daily data
      let followers_count = null
      let followers_count_up_down = null
      let listed_count = null
      let listed_count_up_down = null
      let friends_count = null
      let friends_count_up_down = null
      // post data
      let post_count = null
      let impressions_count = null
      let reaction_count = null
      let reactions_rate = null
      let retweets_count = null
      let quotes_count = null
      let favorites_count = null
      let replies_count = null

      if (account_data && account_data.data.length > 0) {
        followers_count = twitter_comparison_insight.getMetricTotal(
          account_data.data,
          'followers_count'
        )
        followers_count_up_down = twitter_comparison_insight.getMetricTotal(
          account_data.data,
          'followers_count_up_down'
        )
        listed_count = twitter_comparison_insight.getMetricTotal(account_data.data, 'listed_count')
        listed_count_up_down = twitter_comparison_insight.getMetricTotal(
          account_data.data,
          'listed_count_up_down'
        )
        friends_count = twitter_comparison_insight.getMetricTotal(
          account_data.data,
          'friends_count'
        )
        friends_count_up_down = twitter_comparison_insight.getMetricTotal(
          account_data.data,
          'friends_count_up_down'
        )
      }

      if (post_data && post_data.data.length > 0) {
        post_count = post_data.data.length
        impressions_count = this.getMetricValue(post_data.data, 'impressions')
        reaction_count = this.getMetricValue(post_data.data, 'reactions')
        reactions_rate = this.getMetricValue(post_data.data, 'reactions_rate')
        retweets_count = this.getMetricValue(post_data.data, 'retweets')
        quotes_count = this.getMetricValue(post_data.data, 'quotes')
        favorites_count = this.getMetricValue(post_data.data, 'favorites')
        replies_count = this.getMetricValue(post_data.data, 'replies')
      }

      const impressions_chart_series = [impressions_count]
      const impressions_chart_total = impressions_chart_series.reduce((v, c) => v + c, 0)

      const impressions_chart = {
        chart: {
          series: impressions_chart_series,
          colors: impression_chart_colors,
          display_total: true,
          total: impressions_chart_total,
          max: impressions_chart_total
        },
        list: [
          {
            title: 'インプレッション',
            options: [
              {
                key: 'インプレッション',
                value: impressions_count,
                point: { type: 'color', value: impression_chart_colors[0] }
              }
            ]
          }
        ]
      }

      const reaction_chart_series = [retweets_count, quotes_count, favorites_count, replies_count]
      const reaction_chart_total = reaction_chart_series.reduce((v, c) => v + c, 0)

      const reaction_chart = {
        chart: {
          series: reaction_chart_series,
          colors: reaction_chart_colors,
          display_total: true,
          total: reaction_chart_total,
          max: reaction_chart_total
        },
        list: [
          {
            title: '反応数',
            options: [
              {
                key: 'リポスト',
                value: retweets_count,
                point: { type: 'color', value: reaction_chart_colors[0] }
              },
              {
                key: '引用',
                value: quotes_count,
                point: { type: 'color', value: reaction_chart_colors[1] }
              },
              {
                key: 'いいね',
                value: favorites_count,
                point: { type: 'color', value: reaction_chart_colors[2] }
              },
              {
                key: '返信',
                value: replies_count,
                point: { type: 'color', value: reaction_chart_colors[3] }
              }
            ]
          }
        ]
      }

      data.push({
        account_name: account.name,
        account_img: account.img,
        account_id: account.id,
        account_user_name: account.user_name,
        is_empty: account.is_empty,
        order_no: account.order_no,
        followers_count,
        followers_count_up_down,
        followers_count_up_down_converted: twitter_comparison_insight.convertValueWithMetric(
          followers_count_up_down,
          'followers_count_up_down'
        ),
        listed_count,
        listed_count_up_down,
        friends_count,
        friends_count_up_down,
        post_count,
        impressions_chart,
        impressions_count,
        reaction_chart,
        reaction_count,
        reactions_rate,
        retweets_count,
        quotes_count,
        favorites_count,
        replies_count
      })
    }

    const reaction_chart_max_value = Math.max(...data.map(v => v.reaction_chart.chart.max))
    const impressions_chart_max_value = Math.max(...data.map(v => v.impressions_chart.chart.max))

    return data
      .map(v => {
        v.reaction_chart.chart.max = reaction_chart_max_value
        v.impressions_chart.chart.max = impressions_chart_max_value

        return v
      })
      .sort((a, b) => {
        switch (this.sort.order) {
          case 'ascending':
            return a[this.sort.metric] - b[this.sort.metric]
          case 'descending':
            return b[this.sort.metric] - a[this.sort.metric]
          default:
            return 0
        }
      })
  }

  /**
   * 指標から合計・平均した値を取得
   */
  getMetricValue(posts: any[], metric: string): number | null {
    if (posts.every(v => v[metric] === null)) return null

    if (this.basic_display_format === 'average') {
      return twitter_comparison_insight.getMetricAverage(posts, metric)
    }

    switch (metric) {
      case 'reactions_rate':
        return null

      default:
        return twitter_comparison_insight.getMetricTotal(posts, metric)
    }
  }

  /**
   * テーブルのスタイル追加
   */
  styleSort(val: any): string {
    if (this.sort.metric === val.column.property) {
      return `sort-metric sort-${this.sort.order}`
    }

    return ''
  }

  /**
   * ソート変更時
   */
  onSort(val: any): void {
    if (val.property === this.sort.metric) {
      this.sort.order = this.sort.order === 'ascending' ? 'descending' : 'ascending'
    } else {
      this.sort = { metric: val.property, order: 'descending' }
    }
  }

  /**
   * CSVダウンロード
   */
  onDownload(): void {
    const fields = [
      this.$options.filters.translate('アカウント名'),
      this.$options.filters.translate('アカウント画像'),
      this.$options.filters.translate('フォロワー数(累計)'),
      this.$options.filters.translate('フォロワー数(増減)'),
      this.$options.filters.translate('リスト数(累計)'),
      this.$options.filters.translate('リスト数(増減)'),
      this.$options.filters.translate('フォロー数(累計)'),
      this.$options.filters.translate('フォロー数(増減)'),
      this.$options.filters.translate('投稿数'),
      this.$options.filters.translate('インプレッション'),
      this.$options.filters.translate('リポスト'),
      this.$options.filters.translate('引用'),
      this.$options.filters.translate('いいね'),
      this.$options.filters.translate('返信'),
      this.$options.filters.translate('反応数'),
      this.$options.filters.translate('反応率')
    ]

    const data = this.table_datas.map(table => [
      table.account_name,
      table.account_img,
      table.followers_count,
      table.followers_count_up_down,
      table.listed_count,
      table.listed_count_up_down,
      table.friends_count,
      table.friends_count_up_down,
      table.post_count,
      table.impressions_count,
      table.retweets_count,
      table.quotes_count,
      table.favorites_count,
      table.replies_count,
      table.reaction_count,
      table.reactions_rate
    ])

    const csv_data = { fields, data }

    const component_name = this.$options.filters.translate('基本データ')

    const total_tab = constants.TABLE_TABS.find(v => v.value === this.basic_display_format)
    const total_name = this.$options.filters.translate(total_tab ? total_tab.text : '')

    const csv_filename = [component_name, total_name, this.start_date, this.end_date].join('_')
    csv.download(csv_data, csv_filename)

    TrackingService.sendEvent(`click:競合比較(TW)>BM|基本データ:CSVダウンロード`)
  }

  /**
   * アカウントデータの表示形式を変更
   */
  async onChangeBasicDisplayFormat(payload: string) {
    TrackingService.sendEvent(`click:競合比較(TW)>BM|基本データ:${payload}`)

    await this.changeBasicDisplayFormat(payload)
  }
}
