import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import * as bitly_util from '@/client/components-old/_utils/bitly_authentication'
import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Label from '@/client/components-old/atoms/Label'
import Message from '@/client/components-old/atoms/Message'
import Radio from '@/client/components-old/atoms/Radio'
import { IRootState } from '@/client/store/global'
import { IState as IGroupSettingState } from '@/client/store/modules/group_setting'
import API from '@/client/utils/api'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

type TUpdateUrlParams = {
  domain: string
  type: string
}

@Component({
  name: 'ShortUrlSettingDialog',
  components: {
    Button,
    ButtonLink,
    Dialog,
    Icon,
    Input,
    Label,
    Message,
    Radio
  }
})
export default class ShortUrlSettingDialog extends Vue {
  @State('project') project: IRootState['project']
  @group_setting.State('api_short_url_domains')
  api_short_url_domains: IGroupSettingState['api_short_url_domains']
  @group_setting.Action('updateShortUrlDomains') updateShortUrlDomains
  @notification.Action('showNotification') showNotification

  visible = false
  selected_domain = ''
  subdomain = ''
  bitly_domain = ''
  show_subdomain_form = false

  get is_subdomain() {
    return (
      this.api_short_url_domains.type === 'lnky' && this.api_short_url_domains.domain !== 'lnky.jp'
    )
  }

  get is_subdomain_disabled() {
    return !this.subdomain.match(/^[a-zA-Z0-9_\-.]+$/)
  }

  /**
   * ダイアログの表示
   */
  async open(): Promise<void> {
    this.selected_domain = this.api_short_url_domains.domain
    this.visible = true

    const response = await API.get('bitly_domains', { params: { project_id: this.project.id } })

    if (response.data.data && response.data.data.length) {
      this.bitly_domain = response.data.data[0].domain
    }
  }

  /**
   * ダイアログの非表示
   */
  close(): void {
    this.visible = false
    this.show_subdomain_form = false
  }

  /**
   * ドメイン設定変更
   */
  async submit(): Promise<void> {
    const params = {
      domain: this.selected_domain,
      type: this.selected_domain.includes('lnky') ? 'lnky' : 'bitly'
    }

    await this.updateDomainSetting(params)

    this.close()
  }

  /**
   * カスタムサブドメイン登録を非表示
   */
  showCreateSubdomain(): void {
    this.subdomain = ''
    this.show_subdomain_form = true
  }

  /**
   * サブドメインの変更を表示
   */
  showEditSubdomain(): void {
    this.subdomain = this.api_short_url_domains.domain.replace('.lnky.jp', '')
    this.show_subdomain_form = true
  }

  /**
   * カスタムサブドメインを非表示
   */
  hideSubdomainForm(): void {
    this.show_subdomain_form = false
  }

  /**
   * カスタムサブドメイン登録解除
   */
  deleteSubdomain(): void {
    let message = ''

    message += this.$options.filters.translate('サブドメインの登録を解除してよろしいですか？')
    message += '\n'
    message += this.$options.filters.translate('この操作は取り消しできません。')

    const confirm = window.confirm(message)

    if (!confirm) return

    const params = {
      domain: 'lnky.jp',
      type: 'lnky'
    }

    this.updateDomainSetting(params)
  }

  /**
   * サブドメインを保存
   */
  async saveSubdomain(): Promise<void> {
    const params = {
      domain: `${this.subdomain}.lnky.jp`,
      type: 'lnky'
    }

    const result = await this.updateDomainSetting(params)

    if (result) {
      this.hideSubdomainForm()
    }
  }

  /**
   * ドメイン設定変更
   */
  async updateDomainSetting(params: TUpdateUrlParams): Promise<boolean> {
    const result = await this.updateShortUrlDomains(params)

    if (result.data) {
      this.selected_domain = this.api_short_url_domains.domain
      this.showNotification({ title: '設定を保存しました。' })
      return true
    }

    this.showNotification({ title: '設定の保存に失敗しました。', type: 'error' })
    return false
  }

  /**
   * Bitlyアカウントと連携
   */
  async connectBitly(): Promise<void> {
    const auth = await bitly_util.execAuth()

    if (auth && !auth.domain) {
      return this.showNotification({ title: 'ドメインが存在しません。', type: 'error' })
    }

    if (!auth || auth.error) {
      return this.showNotification({ title: 'Bitly認証に失敗しました。', type: 'error' })
    }

    const params = {
      project_id: this.project.id,
      domain: auth.domain,
      access_token: auth.access_token
    }

    const response = await API.post('bitly_domains', params)

    if (response.data.data) {
      this.bitly_domain = auth.domain
      return this.showNotification({ title: 'Bitlyアカウントと連携しました。' })
    }

    this.showNotification({ title: 'Bitlyアカウントと連携に失敗しました。', type: 'error' })
  }

  /**
   * Bitly連携解除
   */
  async disconnectBitly(): Promise<void> {
    let message = ''

    message += this.$options.filters.translate('Bitlyドメインの連携を解除してよろしいですか？')
    message += '\n'
    message += this.$options.filters.translate('この操作は取り消しできません。')

    const confirm = window.confirm(message)

    if (!confirm) return

    const response = await API.delete(`bitly_domains?project_id=${this.project.id}`)

    if (!response.data.data) return

    this.selected_domain =
      this.selected_domain === this.bitly_domain ? 'lnky.jp' : this.selected_domain

    if (this.api_short_url_domains.domain !== this.bitly_domain) {
      this.bitly_domain = ''
      return
    }

    this.bitly_domain = ''

    const params = {
      domain: 'lnky.jp',
      type: 'lnky'
    }

    this.updateDomainSetting(params)
  }
}
