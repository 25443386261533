import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { Action, namespace, State } from 'vuex-class'

import Badge from '@/client/components-old/atoms/Badge'
import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Tabs from '@/client/components-old/atoms/Tabs'
import Search from '@/client/components-old/molecules/Search'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import {
  IGetStockphotosParams,
  IGetStockphotosResponse,
  IGetStockphotosSearchParams,
  IGetStockphotosSearchResponse,
  IPostStockphotosParams,
  IPostStockphotosResponse,
  IStockphoto
} from '@/client/utils/api/stockphotos'
import event from '@/client/utils/event'

const notification = namespace('notification')

@Component({
  name: 'StockphotoDialog',
  components: {
    Badge,
    Button,
    Dialog,
    Icon,
    Message,
    Tabs,
    Search
  }
})
export default class StockphotoDialog extends Vue {
  @State('user') user: IRootState['user']
  @State('project') project: IRootState['project']
  @Action('fetchProjectData') fetchProjectData
  @notification.Action('showNotification') showNotification

  stockphoto: IStockphoto | null = null
  search_stockphotos: IStockphoto[] = []
  usage_stockphotos: IStockphoto[] = []

  tab = 'search'
  keyword = ''
  page = 1

  visible = false
  is_submit = false
  is_exist_search_result = false

  get tab_options() {
    return [
      { value: 'search', text: '新しい写真を探す' },
      { value: 'usage', text: '購入した写真' }
    ]
  }

  get is_search() {
    return this.tab === 'search'
  }

  get is_usage() {
    return this.tab === 'usage'
  }

  get is_exist_photo() {
    if (!this.stockphoto) return false

    return this.usage_stockphotos.some(v => v.id === this.stockphoto.id)
  }

  get stockphoto_count() {
    return this.project.stockphoto_count
  }

  @Emit('select-stockphoto')
  selectStockphoto(image_url: string) {
    return image_url
  }

  @Watch('tab', { immediate: true })
  async watchTab() {
    await this.$nextTick()
    event.resize()
  }

  /**
   * ダイアログを表示
   * @returns {Promise<void>} void
   */
  async open(): Promise<void> {
    this.visible = true
    this.is_submit = false
    this.is_exist_search_result = false
    this.tab = 'search'
    this.keyword = ''
    this.stockphoto = null
    this.search_stockphotos = []
    await this.getUsageStockphotos()
  }

  /**
   * ダイアログを非表示
   * @returns {void}
   */
  hide(): void {
    this.visible = false
  }

  /**
   * ストックフォト一覧に戻る
   * @returns {Promise<void>} void
   */
  async back(): Promise<void> {
    this.stockphoto = null
    await this.$nextTick()
    event.resize()
  }

  /**
   * キーワードで検索
   * @returns {Promise<void>} void
   */
  async submitSearch(): Promise<any> {
    this.page = 1

    await this.getSearchStockphotos()

    this.is_exist_search_result = true
  }

  /**
   * スクロールで検索
   * @param {any} event イベント
   * @returns {Promise<void>} void
   */
  async scrollSearch({ target }: { target: HTMLDivElement }): Promise<void> {
    if (this.is_submit || this.search_stockphotos.length < this.page * 20) return

    if (target.scrollTop + target.clientHeight + 20 >= target.scrollHeight) {
      this.page++
      this.is_submit = true

      await this.getSearchStockphotos(true)

      this.is_submit = false
    }
  }

  /**
   * ストックフォトを選択
   * @param {IStockphoto} stockphoto ストックフォト
   * @returns {void}
   */
  clickStockphoto(stockphoto: IStockphoto): void {
    if (this.stockphoto_count <= 0 && this.is_search) return

    this.stockphoto = stockphoto
  }

  /**
   * ストックフォトを利用する
   * @returns {Promise<void>} void
   */
  async useStockphoto(): Promise<void> {
    if (!this.is_exist_photo) {
      await this.postStockphotos()
    } else {
      this.selectStockphoto(this.stockphoto.img)
    }

    this.hide()
  }

  /**
   * ストックフォトを取得
   * @returns {Promise<void>} void
   */
  async getUsageStockphotos(): Promise<void> {
    const params: IGetStockphotosParams = {
      project_id: this.project.id
    }

    const response = await API.get<IGetStockphotosResponse>('stockphotos', { params })

    if (response.status === 200 && response.data.data.length > 0) {
      this.usage_stockphotos = response.data.data
    } else {
      this.usage_stockphotos = []
    }
  }

  /**
   * ストックフォトを検索
   * @param {boolean} is_add 追加フラグ
   * @returns {Promise<void>} void
   */
  async getSearchStockphotos(is_add = false): Promise<void> {
    this.keyword = this.keyword.replace('　', ' ').trim()

    if (!this.keyword.length) {
      this.search_stockphotos = []
      return
    }

    const params: IGetStockphotosSearchParams = {
      keyword: this.keyword,
      language: this.user.language,
      page: this.page,
      limit: 20
    }

    const response = await API.get<IGetStockphotosSearchResponse>('stockphotos/search', { params })

    if (response.data.data && response.data.data.length > 0) {
      if (is_add) {
        this.search_stockphotos = this.search_stockphotos.concat(response.data.data)
      } else {
        this.search_stockphotos = response.data.data
      }
    } else {
      this.search_stockphotos = []
    }
  }

  /**
   * ストックフォトを購入する
   * @returns {Promise<void>} void
   */
  async postStockphotos(): Promise<void> {
    const params: IPostStockphotosParams = {
      id: this.stockphoto.id,
      project_id: this.project.id
    }

    const response = await API.post<IPostStockphotosResponse>('stockphotos', params)

    if (response.status === 200 && response.data.data) {
      this.selectStockphoto(response.data.data.image_url)

      await this.fetchProjectData(this.project.id)
    }
  }
}
