import { render, staticRenderFns } from "./FacebookAnalyticsAction.vue?vue&type=template&id=7c2953a2&scoped=true"
import script from "./FacebookAnalyticsAction.ts?vue&type=script&lang=ts&external"
export * from "./FacebookAnalyticsAction.ts?vue&type=script&lang=ts&external"
import style0 from "./FacebookAnalyticsAction.vue?vue&type=style&index=0&id=7c2953a2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c2953a2",
  null
  
)

export default component.exports