import moment from 'moment-timezone'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

import { IRootState } from '@/client/store/global'

export interface IState {
  start_date: string
  end_date: string
}

const state: IState = {
  start_date: moment().subtract(30, 'day').format('YYYY-MM-DD'),
  end_date: moment().subtract(1, 'day').format('YYYY-MM-DD')
}

const getters: GetterTree<IState, IRootState> = {}

const mutations: MutationTree<IState> = {
  SET_DATE_RANGE(
    state,
    payload: {
      start_date: string
      end_date: string
    }
  ) {
    state.start_date = payload.start_date
    state.end_date = payload.end_date
  }
}

const actions: ActionTree<IState, IRootState> = {
  /**
   * 分析の日付範囲を変更
   */
  async changeAnalyticsDateRange(
    context,
    payload: {
      start_date: string
      end_date: string
    }
  ) {
    context.commit('SET_DATE_RANGE', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<IState, IRootState>
