import { Component, Emit, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Icon from '@/client/components-old/atoms/Icon'
import Label from '@/client/components-old/atoms/Label'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import PaginationTable from '@/client/components-old/molecules/PaginationTable'
import Search from '@/client/components-old/molecules/Search'
import SwitchRow from '@/client/components-old/molecules/SwitchRow'
import SwitchSns from '@/client/components-old/molecules/SwitchSns'
import { IState as IGroupSettingState } from '@/client/store/modules/group_setting'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

@Component({
  name: 'GroupSettingMonitoringExclude',
  components: {
    Button,
    ButtonLink,
    Icon,
    Label,
    Message,
    Panel,
    Tooltip,
    Search,
    SwitchButton,
    PaginationTable,
    SwitchSns,
    SwitchRow
  }
})
export default class GroupSettingMonitoringExclude extends Vue {
  @group_setting.State('api_monitoring_exclude_keywords')
  api_monitoring_exclude_keywords: IGroupSettingState['api_monitoring_exclude_keywords']
  @group_setting.State('function_sns_type')
  function_sns_type: IGroupSettingState['function_sns_type']
  @group_setting.State('api_monitoring_settings')
  api_monitoring_settings: IGroupSettingState['api_monitoring_settings']
  @group_setting.Action('changeFunctionSnsType') changeFunctionSnsType
  @group_setting.Action('updateMonitoringExcludeKeyword') updateMonitoringExcludeKeyword
  @group_setting.Action('removeMonitoringExcludeKeyword') removeMonitoringExcludeKeyword
  @group_setting.Action('updateMonitoringSettings') updateMonitoringSettings
  @notification.Action('showNotification') showNotification

  keyword_page = 1
  keyword_search = ''

  get keyword_page_size() {
    return 5
  }

  get keywords() {
    return this.api_monitoring_exclude_keywords
  }

  get filter_keywords() {
    return this.keywords.filter(v => v.keyword.match(this.keyword_search))
  }

  get display_keywords() {
    const start = (this.keyword_page - 1) * this.keyword_page_size
    const end = start + this.keyword_page_size

    return this.filter_keywords.slice(start, end)
  }

  get settings() {
    const settings = this.api_monitoring_settings

    switch (this.function_sns_type) {
      case 'facebook':
        return [
          {
            value: settings.hide_fb_post,
            text: '全てのウォール投稿',
            field: 'hide_fb_post'
          },
          {
            value: settings.hide_fb_post_own,
            text: '自アカウントのウォール投稿',
            field: 'hide_fb_post_own'
          },
          {
            value: settings.hide_fb_comment,
            text: '全てのコメントと返信',
            field: 'hide_fb_comment'
          },
          {
            value: settings.hide_fb_comment_own,
            text: '自アカウントのコメントと返信',
            field: 'hide_fb_comment_own'
          },
          {
            value: settings.hide_fb_message,
            text: '全てのメッセージ',
            field: 'hide_fb_message'
          },
          {
            value: settings.hide_fb_message_own,
            text: '自アカウントが返信したメッセージ',
            field: 'hide_fb_message_own'
          }
        ]

      case 'twitter':
        return [
          {
            value: settings.hide_tw_reply,
            text: '全ての返信',
            field: 'hide_tw_reply'
          },
          {
            value: settings.hide_tw_reply_own,
            text: '自アカウントの返信',
            field: 'hide_tw_reply_own'
          },
          {
            value: settings.hide_tw_mention,
            text: '全てのメンション',
            field: 'hide_tw_mention'
          },
          {
            value: settings.hide_tw_mention_own,
            text: '自アカウントが送信した自アカウントへのメンション',
            field: 'hide_tw_mention_own'
          },
          {
            value: settings.hide_tw_message,
            text: '全てのDM',
            field: 'hide_tw_message'
          },
          {
            value: settings.hide_tw_message_own,
            text: '自アカウントが送信したDM',
            field: 'hide_tw_message_own'
          }
        ]

      case 'instagram':
        return [
          {
            value: settings.hide_in_comment,
            text: '全てのコメントと返信',
            field: 'hide_in_comment'
          },
          {
            value: settings.hide_in_comment_own,
            text: '自アカウントのコメントと返信',
            field: 'hide_in_comment_own'
          }
        ]

      default:
        return []
    }
  }

  @Emit('open-exclude-keyword-dialog')
  openExcludeKeywordDialog(payload: any) {
    return payload
  }

  /**
   * 監視除外項目の変更
   */
  async updateExcludeSetting(field: string, value: boolean) {
    const params = {
      [field]: value
    }

    const result = await this.updateMonitoringSettings(params)

    if (result.data) {
      return this.showNotification({ title: '設定を保存しました。' })
    }

    this.showNotification({ title: '設定の保存に失敗しました。', type: 'error' })
  }

  /**
   * 除外キーワードの有効フラグ変更
   */
  async updateExcludeKeyword(keyword_id: number, is_valid: boolean) {
    const params = {
      keyword_id,
      is_valid
    }

    const result = await this.updateMonitoringExcludeKeyword(params)

    if (result.data) {
      return this.showNotification({ title: '設定を保存しました。' })
    }

    this.showNotification({ title: '設定の保存に失敗しました。', type: 'error' })
  }

  /**
   * 除外キーワードの削除
   */
  async deleteExcludeKeyword(keyword_id: number) {
    const result = await this.removeMonitoringExcludeKeyword({ keyword_id })

    if (result.data) {
      return this.showNotification({ title: '設定を削除しました。' })
    }

    this.showNotification({ title: '設定の削除に失敗しました。', type: 'error' })
  }
}
