
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Box'
})
export default class Box extends Vue {
  @Prop({ type: String, default: 'normal' })
  padding!: 'normal' | 'small' | 'large' | 'none'

  @Prop({ type: String, default: 'all' })
  border!: 'all' | 'top' | 'bottom' | 'left' | 'right' | 'none'
}
