import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import PersonalSettingTwoFactorDialog from '@/client/components-old/organisms/PersonalSettingTwoFactorDialog'
import { IState as ISettingState } from '@/client/store/modules/personal_setting'

const setting = namespace('personal_setting')
const notification = namespace('notification')

@Component({
  name: 'PersonalSettingTwoFactor',
  components: {
    Button,
    Flex,
    Icon,
    Panel,
    Tooltip,
    PersonalSettingTwoFactorDialog
  }
})
export default class PersonalSettingTwoFactor extends Vue {
  @setting.State('api_user') api_user: ISettingState['api_user']
  @setting.Action('postTwoFactorRelease') postTwoFactorRelease
  @notification.Action('showNotification') showNotification

  get is_two_factor() {
    return this.api_user && this.api_user.is_two_factor
  }

  /**
   * 2段階認証を解除する
   * @returns {Promise<void>} void
   */
  async cancelTwoFactor(): Promise<void> {
    const confirm = window.confirm(
      this.$options.filters.translate('2段階認証を解除します。よろしいですか？')
    )

    if (!confirm) return

    const response = await this.postTwoFactorRelease()

    if (response && response.data) {
      return this.showNotification({ title: '2段階認証を解除しました。' })
    }

    this.showNotification({
      title: '2段階認証の解除に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }
}
