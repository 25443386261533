import { Component, Vue, Watch } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Icon from '@/client/components-old/atoms/Icon'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import Account from '@/client/components-old/molecules/Account'
import InstagramComparisonAccountDialog from '@/client/components-old/organisms/InstagramComparisonAccountDialog'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import { IState as IInstagramState } from '@/client/store/modules/instagram_comparison_analytics'

const instagram = namespace('instagram_comparison_analytics')
const notification = namespace('notification')

@Component({
  name: 'InstagramComparisonAccountManagement',
  components: {
    draggable,
    InstagramComparisonAccountDialog,
    Account,
    Button,
    Icon,
    Tooltip
  }
})
export default class InstagramComparisonAccountManagement extends Vue {
  @State('project') project: IRootState['project']
  @instagram.State('api_sns_comparison_accounts')
  sns_comparison_accounts: IInstagramState['api_sns_comparison_accounts']
  @instagram.Action('deleteSnsComparisonAccounts') deleteSnsComparisonAccounts
  @instagram.Action('changeOrderNoSnsComparisonAccounts') changeOrderNoSnsComparisonAccounts
  @notification.Action('showNotification') showNotification

  $refs: {
    InstagramComparisonAccountDialog: any
  }

  layout = []

  get limit() {
    const limit_count =
      this.project.in_comparison_count - this.sns_comparison_accounts.filter(v => !v.is_own).length

    return limit_count > 0 ? limit_count : 0
  }

  get disabled() {
    return this.project.in_comparison_count !== 0 && !this.limit
  }

  get has_max_in_comparison_count() {
    return this.project.in_comparison_count !== 0
  }

  get account_ids() {
    return [...this.sns_comparison_accounts].sort((a, b) => a.order_no - b.order_no).map(v => v.id)
  }

  @Watch('account_ids', { immediate: true })
  changeAccountIds() {
    const accounts = [...this.sns_comparison_accounts].sort((a, b) => a.order_no - b.order_no)

    this.layout = accounts.map(account => ({
      id: account.id,
      name: account.name,
      img: account.img,
      is_own: account.is_own,
      is_bussiness: account.is_bussiness,
      is_available: account.is_available,
      post_last_processed_datetime: account.post_last_processed_datetime
    }))
  }

  /**
   * 競合アカウントを削除
   * @param {number} account_id
   * @returns {void}
   */
  async deleteAccount(account_id: number): Promise<void> {
    TrackingService.sendEvent(`click:競合比較(IG)>アカウント管理|競合アカウント削除`)

    const message =
      this.$options.filters.translate('アカウントを削除してよろしいですか？') +
      '\n' +
      this.$options.filters.translate('この操作は取り消しできません。')

    const confirm = window.confirm(message)

    if (!confirm) {
      TrackingService.sendEvent(`click:競合比較(IG)>アカウント管理|競合アカウントの追加|キャンセル`)
      return
    }

    const result = await this.deleteSnsComparisonAccounts({ account_id })

    if (result.data) {
      return this.showNotification({ title: 'アカウントを削除しました。' })
    }

    this.showNotification({ title: 'アカウントの削除に失敗しました。', type: 'error' })

    TrackingService.sendEvent(`click:競合比較(IG)>アカウント管理|競合アカウントの追加|保存`)
  }

  /**
   * 競合アカウント一覧のドラッグ&ドロップ
   * @returns {void}
   */
  async changeLayout(): Promise<void> {
    const account_ids = this.layout.map(v => v.id)

    const result = await this.changeOrderNoSnsComparisonAccounts({ account_ids })

    if (result.data) {
      return this.showNotification({ title: '競合アカウント一覧を更新しました。' })
    }

    this.showNotification({ title: '競合アカウント一覧の更新に失敗しました。', type: 'error' })
  }

  /**
   * アカウント管理ダイアログを開く
   */
  async openAccountDialog() {
    TrackingService.sendEvent(`click:競合比較(IG)>アカウント管理|競合アカウントの追加`)

    this.$refs.InstagramComparisonAccountDialog.open()
  }
}
