import qs from 'qs'
import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Dropdown from '@/client/components-old/atoms/Dropdown'
import Flex from '@/client/components-old/atoms/Flex'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import SwitchButton from '@/client/components-old/atoms/SwitchButton'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import InputKeyValue from '@/client/components-old/molecules/InputKeyValue'
import { TrackingService } from '@/client/services'
import { IRootState } from '@/client/store/global'
import API from '@/client/utils/api'
import { IPostShortUrlResponse } from '@/client/utils/api/short_urls'
import event from '@/client/utils/event'
import i18n from '@/client/utils/i18n'
import * as regex from '@/client/utils/regex'
import storage from '@/client/utils/storage'

const post_create = namespace('post_create')
const notification = namespace('notification')

@Component({
  name: 'PostCreateShortUrl',
  components: {
    Button,
    Dropdown,
    Flex,
    Icon,
    Input,
    Message,
    Panel,
    SwitchButton,
    Tooltip,
    InputKeyValue
  }
})
export default class PostCreateShortUrl extends Vue {
  @State('project') project: IRootState['project']
  @post_create.Action('createShortUrl') createShortUrl
  @post_create.Action('insertShortUrlToMessage') insertShortUrlToMessage
  @notification.Action('showNotification') showNotification

  short_url = ''
  is_params = false
  is_submit = false

  params: { [key: string]: string } = {
    utm_source: '',
    utm_medium: '',
    utm_campaign: ''
  }

  get disabled() {
    return this.is_submit || !this.short_url.match(regex.SHORT_URL_FORMAT)
  }

  get dropdown_options() {
    return [
      { value: 'insert', text: i18n.t('末尾に追加'), icon: 'add' },
      { value: 'copy', text: i18n.t('コピー'), icon: 'my-library-books' }
    ]
  }

  /**
   * コンポーネント設定の保存
   * @returns {void}
   */
  created() {
    const params = storage.get('short_url_parameter')

    if (params) {
      this.params = params
    }
  }

  /**
   * パラメータの設定を変更
   */
  changeParams() {
    TrackingService.sendEvent('switch:投稿作成|短縮URL|パラメータを設定')
  }

  /**
   * 短縮URLを生成
   */
  async generateShortUrl() {
    const short_url = document.createElement('a')

    short_url.href = this.short_url.trim()

    if (this.is_params && Object.keys(this.params).length > 0) {
      short_url.search += short_url.search
        ? `&${qs.stringify(this.params)}`
        : `?${qs.stringify(this.params)}`
    }

    const response = await API.post<IPostShortUrlResponse>('short_urls', {
      origin_url: short_url.href,
      project_id: this.project.id
    })

    if (response.data.data) {
      this.short_url = ''
      storage.remove('short_url_parameter')
      storage.set('short_url_parameter', this.params)

      return response.data.data.short_url
    } else {
      this.showNotification({ title: '短縮URLの生成に失敗しました。', type: 'error' })
      return null
    }
  }

  /**
   * 短縮URLをクリップボードにコピーする
   */
  async copyShortUrl(short_url: string) {
    event.copy(short_url)

    this.showNotification({ title: 'クリップボードにコピーしました。' })
  }

  /**
   * コマンドを実行
   */
  async execCommand(value: string) {
    if (this.disabled) return

    this.is_submit = true

    const short_url = await this.generateShortUrl()

    setTimeout(() => {
      this.is_submit = false
    }, 1000)

    if (!short_url) return

    switch (value) {
      case 'insert':
        TrackingService.sendEvent('click:投稿作成|短縮URL|末尾に追加')
        this.insertShortUrlToMessage(short_url)
        break
      case 'copy':
        TrackingService.sendEvent('click:投稿作成|短縮URL|コピー')
        this.copyShortUrl(short_url)
        break
    }
  }
}
