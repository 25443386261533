import { RouteConfig } from 'vue-router'

import Dashboard from '../pages/Dashboard.vue'

export const dashboard: RouteConfig = {
  component: Dashboard,
  path: '/dashboard',
  name: 'dashboard',
  meta: {
    auth: true,
    title: 'ダッシュボード'
  }
}
