import { RouteConfig } from 'vue-router'

export const bitly_callback: RouteConfig = {
  path: '/bitly_callback',
  name: 'bitly_callback',
  meta: {
    auth: true
  },
  beforeEnter(to, from, next) {
    if (to.query.type === 'error') {
      window.opener.onBitlyAuth({
        error: {
          type: 'AUTHENTICATION_FAILED'
        }
      })
    } else {
      window.opener.onBitlyAuth({
        domain: to.query.domain,
        access_token: to.query.access_token
      })
    }

    next()
  }
}
