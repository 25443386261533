import { Component, Prop, Vue } from 'vue-property-decorator'

import event from '@/client/utils/event'

@Component({
  name: 'ChartPercent'
})
export default class ChartPercent extends Vue {
  @Prop({ type: Number, required: true })
  value: number

  is_show = false

  async mounted() {
    this.is_show = false
    await this.$nextTick()
    await event.delay(0)
    this.is_show = true
  }

  get chart_options() {
    return {
      chart: {
        type: 'solidgauge'
      },
      title: null,
      pane: {
        center: ['50%', '50%'],
        size: '100%',
        startAngle: 0,
        endAngle: 360,
        background: {
          backgroundColor: '#e6e6e6',
          innerRadius: '85%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },
      tooltip: {
        enabled: false
      },
      yAxis: {
        min: 0,
        max: 100,
        title: null,
        stops: [
          [0.3, '#ed5565'],
          [0.5, '#2ec881'],
          [0.9, '#1c84c6']
        ],
        lineWidth: 0,
        minorTickInterval: null,
        tickWidth: 0,
        labels: {
          enabled: false
        }
      },
      plotOptions: {
        series: {
          connectNulls: true,
          animation: false
        },
        solidgauge: {
          innerRadius: '85%',
          borderWidth: 0,
          dataLabels: {
            borderWidth: 0,
            useHTML: true,
            verticalAlign: 'middle',
            format:
              '<div style="text-align:center"><span style="font-size:16px; color:#676a6c;">{y}%</span></div>'
          }
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      series: [
        {
          data: [this.value]
        }
      ]
    }
  }
}
