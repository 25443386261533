
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Steps from '@/client/components-old/atoms/Steps'
import { IState as ISignupState } from '@/client/store/modules/signup'

const signup = namespace('signup')

@Component({
  name: 'SignupHeader',
  components: {
    Steps
  }
})
export default class SignupHeader extends Vue {
  @signup.State('form_step') form_step!: ISignupState['form_step']

  get step() {
    return this.form_step + 1
  }

  get options() {
    return [{ label: 'ユーザー情報の入力' }, { label: '登録内容の確認' }, { label: '登録完了' }]
  }
}
