import { GridItem, GridLayout } from 'vue-grid-layout'
import { Component, Vue } from 'vue-property-decorator'

import * as constants from '@/client/components-old/_constants/instagram_analytics'
import Box from '@/client/components-old/atoms/Box'
import Legend from '@/client/components-old/atoms/Legend'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import InstagramAnalyticsChartVertical from '@/client/components-old/organisms/InstagramAnalyticsChartVertical'
import InstagramAnalyticsTotal from '@/client/components-old/organisms/InstagramAnalyticsTotal'

@Component({
  name: 'InstagramAnalyticsReach',
  components: {
    GridLayout,
    GridItem,
    InstagramAnalyticsChartVertical,
    InstagramAnalyticsTotal,
    Legend,
    Box,
    Message,
    Scroll
  }
})
export default class InstagramAnalyticsReach extends Vue {
  get legend_data() {
    return constants.IN_DATA_TYPES.map(type => ({ text: type.text, color: type.color }))
  }

  get grid_layout() {
    return [
      {
        x: 0,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:リーチ（日次データ）',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'impressions_unique',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 3,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:インプレッション（日次データ）',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'impressions',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 6,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:リーチ（投稿データ）',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'impressions_unique',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 9,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:インプレッション（投稿データ）',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'impressions',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:プロフィールビュー',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'profile_views',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 3,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:動画再生数',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'video_views',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 6,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:リーチ（ストーリーズデータ）',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'stories_data',
          metric: 'reach',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 9,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:インプレッション（ストーリーズデータ）',
        name: 'InstagramAnalyticsTotal',
        options: {
          data_type: 'stories_data',
          metric: 'impressions',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 4,
        w: 12,
        h: 4,
        i: '垂直チャート:リーチ（日次データ）',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'impressions_unique',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 8,
        w: 12,
        h: 4,
        i: '垂直チャート:インプレッション',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'impressions',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 12,
        w: 12,
        h: 4,
        i: '垂直チャート:プロフィールビュー',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'profile_views',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 16,
        w: 12,
        h: 4,
        i: '垂直チャート:リーチ（投稿データ）',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'impressions_unique',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 20,
        w: 12,
        h: 4,
        i: '垂直チャート:インプレッション（投稿データ）',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'impressions',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 24,
        w: 12,
        h: 4,
        i: '垂直チャート:動画再生数',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'video_views',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 28,
        w: 12,
        h: 4,
        i: '垂直チャート:リーチ（ストーリーズデータ）',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'stories_data',
          metric: 'reach',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 32,
        w: 12,
        h: 4,
        i: '垂直チャート:インプレッション（ストーリーズデータ）',
        name: 'InstagramAnalyticsChartVertical',
        options: {
          data_type: 'stories_data',
          metric: 'impressions',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      }
    ]
  }
}
