import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import { TrackingService } from '@/client/services'
import i18n from '@/client/utils/i18n'
import { INSTAGRAM_STORY_URL_FORMAT, INSTAGRAM_URL_FORMAT } from '@/client/utils/regex'

const post_management = namespace('post_management')
const notification = namespace('notification')

type TPostInstagramUpdateUrlDialogOptions = {
  sns_post_id: number
  post_type: 'post' | 'story'
}

@Component({
  name: 'PostInstagramUpdateUrlDialog',
  components: {
    Button,
    Dialog,
    Icon,
    Input,
    Message
  }
})
export default class PostInstagramUpdateUrlDialog extends Vue {
  @post_management.Action('updateInstagramUrl') updateInstagramUrl!: any
  @notification.Action('showNotification') showNotification!: any

  visible = false
  sns_post_id = 0
  post_url = ''
  post_url_change = false
  post_type = ''

  get post_url_error() {
    if (this.post_type === 'story') {
      return !!this.post_url && !INSTAGRAM_STORY_URL_FORMAT.test(this.post_url)
    }

    return !!this.post_url && !INSTAGRAM_URL_FORMAT.test(this.post_url)
  }

  get is_post_url_error() {
    return this.post_url_error && this.post_url_change
  }

  get disabled() {
    return this.post_url_error
  }

  get step2_text() {
    if (this.post_type === 'story') {
      return i18n.t('ブラウザのアドレスバーからURLをコピー')
    }

    const svg = `
      <svg fill="#262626" height="16" viewBox="0 0 48 48" width="16">
        <circle clip-rule="evenodd" cx="8" cy="36" fill-rule="evenodd" r="4.5" />
        <circle clip-rule="evenodd" cx="24" cy="36" fill-rule="evenodd" r="4.5" />
        <circle clip-rule="evenodd" cx="40" cy="36" fill-rule="evenodd" r="4.5" />
      </svg>
    `

    return i18n.t('該当の投稿の右上にある [[svg]] から [リンクをコピー] を選択してURLをコピー', {
      svg
    })
  }

  /**
   * ダイアログを表示
   */
  open(options: TPostInstagramUpdateUrlDialogOptions) {
    this.sns_post_id = options.sns_post_id
    this.post_type = options.post_type
    this.post_url = ''
    this.post_url_change = false
    this.visible = true
  }

  /**
   * ダイアログを非表示
   */
  close() {
    TrackingService.sendEvent('click:IG公開URL設定ダイアログ|キャンセル')

    this.visible = false
  }

  /**
   * 投稿URLを変更
   */
  changePostUrl() {
    this.post_url_change = true
  }

  /**
   * 投稿URLの更新
   */
  async updatePostUrl() {
    TrackingService.sendEvent('click:IG公開URL設定ダイアログ|保存')

    const result = await this.updateInstagramUrl({
      sns_post_id: this.sns_post_id,
      post_url: this.post_url
    })

    if (result && result.data) {
      this.showNotification({ title: '設定を保存しました。' })
      this.close()
      return
    }

    if (result && result.error && result.error.type === 'INSTAGRAM_POST_NOT_EXISTS') {
      return this.showNotification({
        title: '設定の保存に失敗しました。',
        message: 'URLに一致する投稿がありません。',
        type: 'error'
      })
    }

    return this.showNotification({
      title: '設定の保存に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }
}
