import {
  RoleAnalytics,
  RoleAnalyticsName,
  RoleMonitoring,
  RoleMonitoringName,
  RolePost,
  RolePostName
} from '../types/role'

/**
 * DBに保存された権限をUIで使用するための文字列に変換する
 */
export function convertPostRoleToString(role?: number): RolePostName {
  if (role === 0) return 'no_authority'
  if (role === 1) return 'admin'
  if (role === 2) return 'editor'
  return 'no_authority'
}

/**
 * DBに保存する時に数字に変換する
 */
export function convertPostRoleToNumber(role: RolePostName): RolePost {
  if (role === 'no_authority') return 0
  if (role === 'admin') return 1
  if (role === 'editor') return 2
  return 0
}

/**
 * DBに保存された権限をUIで使用するための文字列に変換する
 */
export function convertAnalyticsRoleToString(role?: number): RoleAnalyticsName {
  if (role === 0) return 'no_authority'
  if (role === 1) return 'admin'
  return 'no_authority'
}

/**
 * DBに保存する時に数字に変換する
 */
export function convertAnalyticsRoleToNumber(role: RoleAnalyticsName): RoleAnalytics {
  if (role === 'no_authority') return 0
  if (role === 'admin') return 1
  return 0
}

/**
 * DBに保存された権限をUIで使用するための文字列に変換する
 */
export function convertMonitoringRoleToString(role?: number): RoleMonitoringName {
  if (role === 0) return 'no_authority'
  if (role === 1) return 'admin'
  if (role === 2) return 'observer'
  return 'no_authority'
}

/**
 * DBに保存する時に数字に変換する
 */
export function convertMonitoringRoleToNumber(role: RoleMonitoringName): RoleMonitoring {
  if (role === 'no_authority') return 0
  if (role === 'admin') return 1
  if (role === 'observer') return 2
  return 0
}
