import API from '@/client/utils/api'
import {
  IGetSnsCategoryPostsParams,
  IGetSnsCategoryPostsResponse
} from '@/client/utils/api/sns_category_posts'

export async function getTikTokSnsCategoryPosts(query: IGetSnsCategoryPostsParams) {
  const response = await API.get<IGetSnsCategoryPostsResponse>('sns_category_posts', {
    params: query
  })

  return response.data
}
