import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Flex from '@/client/components-old/atoms/Flex'
import Select from '@/client/components-old/atoms/Select'
import Tabs from '@/client/components-old/atoms/Tabs'
import SnsMultiSelect from '@/client/components-old/molecules/SnsMultiSelect'
import AnalyticsDateRangePicker from '@/client/components-old/organisms/AnalyticsDateRangePicker'
import { TrackingService } from '@/client/services'
import { IState as IInstagramState } from '@/client/store/modules/instagram_comparison_analytics'

const instagram = namespace('instagram_comparison_analytics')

@Component({
  name: 'InstagramComparisonAnalyticsHeader',
  components: {
    SnsMultiSelect,
    AnalyticsDateRangePicker,
    Flex,
    Tabs,
    Select
  }
})
export default class InstagramComparisonAnalyticsHeader extends Vue {
  @instagram.State('api_sns_comparison_accounts')
  api_sns_comparison_accounts: IInstagramState['api_sns_comparison_accounts']
  @instagram.State('api_presets') api_presets: IInstagramState['api_presets']
  @instagram.State('account_ids') account_ids: IInstagramState['account_ids']
  @instagram.State('screen_name') screen_name: IInstagramState['screen_name']
  @instagram.State('preset_id') preset_id: IInstagramState['preset_id']
  @instagram.Action('getComparisonInsights') getComparisonInsights
  @instagram.Action('getScreen') getScreen
  @instagram.Action('getPresetId') getPresetId

  get accounts_values() {
    return this.account_ids.map(id => ({
      id,
      sns: 'instagram'
    }))
  }

  get accounts_options() {
    return this.api_sns_comparison_accounts.map(account => ({
      id: account.id,
      img: account.img,
      name: account.name,
      sns: 'instagram'
    }))
  }

  get presets_options() {
    return this.api_presets.map(preset => ({ text: preset.name, value: preset.id }))
  }

  get screens_options() {
    return [
      {
        value: 'benchmark',
        text: 'ベンチマーク',
        icon: 'line-graph'
      },
      {
        value: 'post',
        text: '投稿',
        icon: 'rate-review'
      },
      {
        value: 'management',
        text: 'アカウント管理',
        icon: 'instagram-with-circle'
      }
    ]
  }

  /**
   * アカウントID一覧の変更
   */
  async changeAccountIds(
    accounts: {
      id: string
      name: string
      img: string
      sns: 'instagram'
    }[]
  ): Promise<void> {
    const account_ids = accounts.map(account => account.id)
    await this.getPresetId('')
    await this.getComparisonInsights({ account_ids })

    TrackingService.sendEvent('select:競合比較(IG)|画面内ヘッダー|アカウント選択')
  }

  /**
   * 日付範囲の変更
   */
  async changeDateRange(): Promise<void> {
    await this.getComparisonInsights({ account_ids: this.account_ids })

    TrackingService.sendEvent('select:競合比較(IG)|画面内ヘッダー|期間指定')
  }
}
