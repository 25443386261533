import { Component, Emit, Vue } from 'vue-property-decorator'

import Scroll from '@/client/components-old/atoms/Scroll'
import GroupSettingOperator from '@/client/components-old/organisms/GroupSettingOperator'
import NotificationSettingDialog from '@/client/components-old/organisms/NotificationSettingDialog'
import OperatorInvitationDialog from '@/client/components-old/organisms/OperatorInvitationDialog'
import OperatorRoleSettingDialog from '@/client/components-old/organisms/OperatorRoleSettingDialog'

@Component({
  name: 'GroupSettingOperatorLayout',
  components: {
    Scroll,
    GroupSettingOperator,
    OperatorInvitationDialog,
    OperatorRoleSettingDialog,
    NotificationSettingDialog
  }
})
export default class GroupSettingOperatorLayout extends Vue {
  $refs: {
    OperatorInvitationDialog: any
    OperatorRoleSettingDialog: any
    NotificationSettingDialog: any
  }

  @Emit('change-vue')
  changeVue(payload: any) {
    return payload
  }

  /**
   * オペレーター追加ダイアログを表示
   */
  openOperatorInvitationDialog() {
    this.$refs.OperatorInvitationDialog.open()
  }

  /**
   * オペレーター権限設定ダイアログを表示
   */
  openOperatorRoleDialog(payload: any) {
    this.$refs.OperatorRoleSettingDialog.open(payload)
  }

  /**
   * オペレーター通知メール設定ダイアログを表示
   */
  openOperatorNotificationDialog(payload: any) {
    this.$refs.NotificationSettingDialog.open(payload)
  }
}
