import { GridItem, GridLayout } from 'vue-grid-layout'
import { Component, Vue } from 'vue-property-decorator'

import * as constants from '@/client/components-old/_constants/twitter_analytics'
import Box from '@/client/components-old/atoms/Box'
import Legend from '@/client/components-old/atoms/Legend'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import TwitterAnalyticsChartVertical from '@/client/components-old/organisms/TwitterAnalyticsChartVertical'
import TwitterAnalyticsTotal from '@/client/components-old/organisms/TwitterAnalyticsTotal'

@Component({
  name: 'TwitterAnalyticsFollower',
  components: {
    GridLayout,
    GridItem,
    TwitterAnalyticsChartVertical,
    TwitterAnalyticsTotal,
    Legend,
    Box,
    Message,
    Scroll
  }
})
export default class TwitterAnalyticsFollower extends Vue {
  get legend_data() {
    return constants.DATA_TYPES.map(type => ({ text: type.text, color: type.color }))
  }

  get grid_layout() {
    return [
      {
        x: 0,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:フォロワー数(累計)',
        name: 'TwitterAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'followers_count',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 3,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:フォロワー数(増減)',
        name: 'TwitterAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'followers_count_up_down',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 6,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:フォロー数(累計)',
        name: 'TwitterAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'friends_count',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 9,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:フォロー数(増減)',
        name: 'TwitterAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'friends_count_up_down',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 0,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:リスト数(累計)',
        name: 'TwitterAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'listed_count',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 3,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:リスト数(増減)',
        name: 'TwitterAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'listed_count_up_down',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 0,
        y: 4,
        w: 12,
        h: 4,
        i: '垂直チャート:フォロワー数(累計)',
        name: 'TwitterAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'followers_count',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 8,
        w: 12,
        h: 4,
        i: '垂直チャート:フォロワー数(増減)',
        name: 'TwitterAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'followers_count_up_down',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 12,
        w: 12,
        h: 4,
        i: '垂直チャート:フォロー数(累計)',
        name: 'TwitterAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'friends_count',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 16,
        w: 12,
        h: 4,
        i: '垂直チャート:リスト数(累計)',
        name: 'TwitterAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'listed_count',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      }
    ]
  }
}
