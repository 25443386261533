import twttr from 'twitter-text'

import { TMonitoringWord } from '@/client/utils/api/monitoring_words'
import { autoInstagramMentions, htmlEscapeNotUrl, regExpEscape } from '@/client/utils/regex'

/**
 * モニタリング内容のリンク変換
 */
function convertLinkMessage(text: string, sns_type: string) {
  let temp = text

  switch (sns_type) {
    case 'facebook': {
      temp = twttr.autoLinkUrlsCustom(temp, {
        targetBlank: true
      })

      return twttr.autoLinkHashtags(temp, {
        hashtagUrlBase: 'https://www.facebook.com/hashtag/',
        hashtagClass: 'facebook-url hashtag',
        targetBlank: true
      })
    }

    case 'twitter': {
      const entities = twttr.extractEntitiesWithIndices(temp, {
        extractUrlsWithoutProtocol: true
      })

      return twttr.autoLinkEntities(temp, entities, {
        hashtagUrlBase: 'https://x.com/hashtag/',
        usernameUrlBase: 'https://x.com/',
        usernameIncludeSymbol: true,
        targetBlank: true
      })
    }

    case 'instagram': {
      // InstagramはURLをリンクしないがモニタリングでの表示なのでURLも変換する
      temp = twttr.autoLinkUrlsCustom(temp, {
        targetBlank: true
      })

      temp = autoInstagramMentions(temp, {
        usernameUrlBase: 'https://instagram.com/',
        usernameClass: 'instagram-url username'
      })

      return twttr.autoLinkHashtags(temp, {
        hashtagUrlBase: 'https://instagram.com/explore/tags/',
        hashtagClass: 'instagram-url hashtag',
        targetBlank: true
      })
    }

    default: {
      return temp
    }
  }
}

/**
 * モニタリング内容のハイライト変換
 */
function convertHighlightMessage(text: string, name: string, words: TMonitoringWord[]) {
  let temp = text

  for (let i = 0; i < words.length; i++) {
    const word = words[i]

    const excludes = word.excludes.map(v => new RegExp(regExpEscape(v), 'g'))

    // 一度でも引っかかった場合はハイライトしない
    if (excludes.some(v => v.test(text))) {
      continue
    }

    const includes = word.includes.map(v => new RegExp(regExpEscape(v), 'g'))

    // 全てマッチしてない場合はハイライトしない
    if (!includes.every(v => v.test(text))) {
      continue
    }

    for (let j = 0; j < includes.length; j++) {
      const word = includes[j]

      // HTMLタグと普通のテキストに分割
      let text_list = temp.split(/(<\/?[^>]+>)|([^<]+)/).filter(v => v)

      // HTMLタグはそのままでそれ以外は変換
      text_list = text_list.map(v =>
        /(<\/?[^>]+>)/g.test(v)
          ? v
          : v.replace(word, match => `<span class="${name}">${match}</span>`)
      )

      // HTMLタグ含めて結合
      temp = text_list.join('')
    }
  }

  return temp
}

/**
 * モニタリングの返信内容の変換
 */
export function convertMonitoringReply(text: string, sns_type: string) {
  let message = htmlEscapeNotUrl(text)

  message = convertLinkMessage(message, sns_type)

  return message
}

/**
 * モニタリング内容の変換
 */
export function convertMonitoringMessage(text: string, sns_type: string, words: TMonitoringWord[]) {
  let message = htmlEscapeNotUrl(text)

  message = convertLinkMessage(message, sns_type)

  const positive = words.filter(v => v.type === 'positive')
  const negative = words.filter(v => v.type === 'negative')
  const other = words.filter(v => v.type === 'other')
  const ng = words.filter(v => v.type === 'ng')

  message = convertHighlightMessage(message, 'positive', positive)
  message = convertHighlightMessage(message, 'negative', negative)
  message = convertHighlightMessage(message, 'other', other)
  message = convertHighlightMessage(message, 'ng', ng)

  return message
}
