
import { defineComponent } from 'vue'

import Scroll from '@/client/components-old/atoms/Scroll'
import PersonalSettingDashboard from '@/client/components-old/organisms/PersonalSettingDashboard'

export default defineComponent({
  name: 'PersonalSettingDisplay',
  components: {
    Scroll,
    PersonalSettingDashboard
  }
})
