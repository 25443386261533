import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Autocomplete from '@/client/components-old/atoms/Autocomplete'
import Button from '@/client/components-old/atoms/Button'
import ButtonLink from '@/client/components-old/atoms/ButtonLink'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Label from '@/client/components-old/atoms/Label'
import Panel from '@/client/components-old/atoms/Panel'
import Select from '@/client/components-old/atoms/Select'
import InputKeyword from '@/client/components-old/molecules/InputKeyword'
import { IGetter as IGroupSettingGetter } from '@/client/store/modules/group_setting'
import i18n from '@/client/utils/i18n'

const group_setting = namespace('group_setting')
const notification = namespace('notification')

type MonitoringKeywordDialogOptions = {
  keyword_id?: number
}

@Component({
  name: 'MonitoringKeywordDialog',
  components: {
    Autocomplete,
    Label,
    Button,
    ButtonLink,
    Dialog,
    Icon,
    Input,
    Panel,
    Select,
    InputKeyword
  }
})
export default class MonitoringKeywordDialog extends Vue {
  @group_setting.Getter('monitoring_keyword_categories')
  monitoring_keyword_categories: IGroupSettingGetter['monitoring_keyword_categories']
  @group_setting.Action('fetchMonitoringKeyword') fetchMonitoringKeyword
  @group_setting.Action('createMonitoringKeyword') createMonitoringKeyword
  @group_setting.Action('updateMonitoringKeyword') updateMonitoringKeyword
  @group_setting.Action('removeMonitoringKeyword') removeMonitoringKeyword
  @notification.Action('showNotification') showNotification

  is_submit = false
  visible = false
  keyword_id = 0
  keywords: string[] = []
  exclude_keywords: string[] = []
  type = 'negative'
  category = ''
  temp_form: {
    keywords: string[]
    exclude_keywords: string[]
    type: string
    category: string
  } = {
    keywords: [],
    exclude_keywords: [],
    type: 'negative',
    category: ''
  }

  get title() {
    return this.is_edit ? '監視ワードの編集' : '監視ワードの追加'
  }

  get is_edit() {
    return !!this.keyword_id
  }

  get is_form_changed() {
    return (
      this.keywords.toString() !== this.temp_form.keywords.toString() ||
      this.exclude_keywords.toString() !== this.temp_form.exclude_keywords.toString() ||
      this.type !== this.temp_form.type ||
      this.category !== this.temp_form.category
    )
  }

  get options() {
    return [
      { value: 'negative', text: this.$options.filters.translate('ネガティブワード') },
      { value: 'positive', text: this.$options.filters.translate('ポジティブワード') },
      { value: 'other', text: this.$options.filters.translate('その他') }
    ]
  }

  get disabled() {
    return this.is_submit || !this.keywords.length
  }

  /**
   * ダイアログの表示
   */
  async open(options: MonitoringKeywordDialogOptions): Promise<void> {
    if (!options || !options.keyword_id) {
      this.visible = true
      this.reset()
      return
    }

    const result = await this.fetchMonitoringKeyword({ keyword_id: options.keyword_id })

    if (!result.data) return

    this.keyword_id = options.keyword_id
    this.keywords = result.data.keywords
    this.exclude_keywords = result.data.exclude_keywords
    this.type = result.data.type
    this.category = result.data.category ? result.data.category : ''

    this.temp_form.keywords = result.data.keywords
    this.temp_form.exclude_keywords = result.data.exclude_keywords
    this.temp_form.type = result.data.type
    this.temp_form.category = result.data.category ? result.data.category : ''

    this.visible = true
  }

  /**
   * ダイアログの非表示
   */
  close(): void {
    if (this.is_form_changed) {
      const message =
        i18n.t('編集内容が保存されていません。') +
        '\n' +
        i18n.t('ダイアログを閉じてよろしいですか？')

      const is_confirm = confirm(message)

      if (!is_confirm) {
        this.visible = true
        return
      }
    }

    this.visible = false
  }

  /**
   * 初期化
   */
  reset(): void {
    this.keyword_id = 0
    this.keywords = []
    this.exclude_keywords = []
    this.type = 'negative'
    this.category = ''
    this.temp_form.keywords = []
    this.temp_form.exclude_keywords = []
    this.temp_form.type = 'negative'
    this.temp_form.category = ''
  }

  /**
   * 監視ワードの保存
   */
  async save(): Promise<void> {
    this.is_submit = true

    if (this.is_edit) {
      await this.updateKeyword()
    } else {
      await this.createKeyword()
    }

    this.is_submit = false
  }

  /**
   * 監視ワードの追加
   */
  async createKeyword(): Promise<void> {
    const category = this.category.trim()

    const params = {
      keywords: this.keywords,
      exclude_keywords: this.exclude_keywords,
      category: category ? category : null,
      type: this.type
    }

    const result = await this.createMonitoringKeyword(params)

    if (result.data) {
      this.visible = false

      return this.showNotification({ title: '監視ワードを追加しました。' })
    }

    this.showNotification({
      title: '監視ワードの追加に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * 監視ワードの更新
   */
  async updateKeyword(): Promise<void> {
    const category = this.category.trim()

    const params = {
      keyword_id: this.keyword_id,
      keywords: this.keywords,
      exclude_keywords: this.exclude_keywords,
      category: category ? category : null,
      type: this.type
    }

    const result = await this.updateMonitoringKeyword(params)

    if (result.data) {
      this.visible = false

      return this.showNotification({ title: '監視ワードを更新しました。' })
    }

    this.showNotification({
      title: '監視ワードの更新に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * 監視ワードの削除
   */
  async deleteKeyword(): Promise<void> {
    let message = ''

    message += this.$options.filters.translate('監視ワードを削除してよろしいですか？')
    message += '\n'
    message += this.$options.filters.translate('この操作は取り消しできません。')

    const is_confirm = confirm(message)

    if (!is_confirm) return

    const result = await this.removeMonitoringKeyword({ keyword_id: this.keyword_id })

    if (result.data) {
      this.visible = false

      return this.showNotification({ title: '監視ワードを削除しました。' })
    }

    this.showNotification({
      title: '監視ワードの削除に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }
}
