import { GridItem, GridLayout } from 'vue-grid-layout'
import { Component, Vue } from 'vue-property-decorator'

import * as constants from '@/client/components-old/_constants/facebook_analytics'
import Box from '@/client/components-old/atoms/Box'
import Legend from '@/client/components-old/atoms/Legend'
import Message from '@/client/components-old/atoms/Message'
import Scroll from '@/client/components-old/atoms/Scroll'
import FacebookAnalyticsChartHorizontal from '@/client/components-old/organisms/FacebookAnalyticsChartHorizontal'
import FacebookAnalyticsChartMap from '@/client/components-old/organisms/FacebookAnalyticsChartMap'
import FacebookAnalyticsChartVertical from '@/client/components-old/organisms/FacebookAnalyticsChartVertical'
import FacebookAnalyticsTotal from '@/client/components-old/organisms/FacebookAnalyticsTotal'

@Component({
  name: 'FacebookAnalyticsReach',
  components: {
    GridLayout,
    GridItem,
    FacebookAnalyticsChartHorizontal,
    FacebookAnalyticsChartMap,
    FacebookAnalyticsChartVertical,
    FacebookAnalyticsTotal,
    Legend,
    Box,
    Message,
    Scroll
  }
})
export default class FacebookAnalyticsReach extends Vue {
  get legend_data() {
    return constants.DATA_TYPES.map(type => ({ text: type.text, color: type.color }))
  }

  get grid_layout() {
    return [
      {
        x: 0,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:全投稿リーチ',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_posts_impressions_unique',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 3,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:全体リーチ',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_impressions_unique',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 6,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:全投稿インプレッション',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_posts_impressions',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 9,
        y: 0,
        w: 3,
        h: 2,
        i: '集計:全体インプレッション',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'daily_data',
          metric: 'page_impressions',
          unit: 'total',
          is_view_extra: true
        }
      },
      {
        x: 0,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:リーチ',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'post_impressions_unique',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 3,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:インプレッション',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'post_impressions',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 6,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:ファンのリーチ数',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'post_impressions_fan_unique',
          unit: 'total',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 9,
        y: 2,
        w: 3,
        h: 2,
        i: '集計:ファンのリーチ率',
        name: 'FacebookAnalyticsTotal',
        options: {
          data_type: 'post_data',
          metric: 'post_impressions_fan_unique_rate',
          unit: 'average',
          is_view_extra: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 4,
        w: 12,
        h: 4,
        i: '垂直チャート:全投稿リーチ',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_posts_impressions_unique',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 8,
        w: 12,
        h: 4,
        i: '垂直チャート:全体リーチ',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_impressions_unique',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 12,
        w: 12,
        h: 4,
        i: '垂直チャート:全投稿インプレッション',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_posts_impressions',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 16,
        w: 12,
        h: 4,
        i: '垂直チャート:全体インプレッション',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'daily_data',
          metric: 'page_impressions',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true
        }
      },
      {
        x: 0,
        y: 20,
        w: 12,
        h: 4,
        i: '垂直チャート:投稿リーチ',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'post_impressions_unique',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 24,
        w: 12,
        h: 4,
        i: '垂直チャート:投稿インプレッション',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'post_impressions',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 28,
        w: 12,
        h: 4,
        i: '垂直チャート:ファンのリーチ数',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'post_impressions_fan_unique',
          interval: '1_day',
          unit: 'total',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 32,
        w: 12,
        h: 4,
        i: '垂直チャート:ファンのリーチ率',
        name: 'FacebookAnalyticsChartVertical',
        options: {
          data_type: 'post_data',
          metric: 'post_impressions_fan_unique_rate',
          interval: '1_day',
          unit: 'average',
          is_change_interval: true,
          post_types: [],
          post_tags: []
        }
      },
      {
        x: 0,
        y: 36,
        w: 6,
        h: 3,
        i: '水平チャート:リーチの種類(全投稿・全体)',
        name: 'FacebookAnalyticsChartHorizontal',
        options: { data_type: 'daily_data', metric: 'page_reach_type' }
      },
      {
        x: 0,
        y: 39,
        w: 6,
        h: 2,
        i: '水平チャート:リーチの種類(ファン・ファン以外)',
        name: 'FacebookAnalyticsChartHorizontal',
        options: {
          data_type: 'post_data',
          metric: 'post_impressions_fan_unique_type'
        }
      },
      {
        x: 0,
        y: 41,
        w: 6,
        h: 5,
        i: '水平チャート:全体リーチ(性別・年代)',
        name: 'FacebookAnalyticsChartHorizontal',
        options: { data_type: 'daily_data', metric: 'page_impressions_by_age_gender_unique' }
      },
      {
        x: 0,
        y: 46,
        w: 6,
        h: 6,
        i: '水平チャート:全体リーチ(場所別ベスト10)',
        name: 'FacebookAnalyticsChartHorizontal',
        options: { data_type: 'daily_data', metric: 'page_impressions_by_city_unique' }
      },
      {
        x: 0,
        y: 52,
        w: 6,
        h: 6,
        i: '水平チャート:ページとタブのアクセス',
        name: 'FacebookAnalyticsChartHorizontal',
        options: { data_type: 'daily_data', metric: 'page_tab_views_login_top_unique' }
      },
      {
        x: 6,
        y: 36,
        w: 6,
        h: 3,
        i: '水平チャート:インプレッションの種類(全投稿・全体)',
        name: 'FacebookAnalyticsChartHorizontal',
        options: { data_type: 'daily_data', metric: 'page_impression_type' }
      },
      {
        x: 6,
        y: 39,
        w: 6,
        h: 5,
        i: '水平チャート:投稿リーチ(クチコミ)の発生箇所',
        name: 'FacebookAnalyticsChartHorizontal',
        options: { data_type: 'daily_data', metric: 'page_impressions_by_story_type_unique' }
      },
      {
        x: 6,
        y: 44,
        w: 6,
        h: 5,
        i: 'マップチャート:全体リーチ(国)',
        name: 'FacebookAnalyticsChartMap',
        options: { metric: 'page_impressions_by_country_unique' }
      },
      {
        x: 6,
        y: 49,
        w: 6,
        h: 5,
        i: 'マップチャート:全体リーチ(都道府県)',
        name: 'FacebookAnalyticsChartMap',
        options: { metric: 'page_impressions_by_city_unique' }
      },
      {
        x: 6,
        y: 54,
        w: 6,
        h: 6,
        i: '水平チャート:外部リンクからの流入',
        name: 'FacebookAnalyticsChartHorizontal',
        options: { data_type: 'daily_data', metric: 'page_views_external_referrals' }
      }
    ]
  }
}
