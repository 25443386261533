import { Component, Vue } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import HelpLink from '@/client/components/molecules/HelpLink'
import Button from '@/client/components-old/atoms/Button'
import Dialog from '@/client/components-old/atoms/Dialog'
import Icon from '@/client/components-old/atoms/Icon'
import Input from '@/client/components-old/atoms/Input'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import OperatorRoleSns from '@/client/components-old/molecules/OperatorRoleSns'
import SwitchRow from '@/client/components-old/molecules/SwitchRow'
import { IRootState } from '@/client/store/global'
import { IState as IAccountsState } from '@/client/store/modules/accounts'
import API from '@/client/utils/api'
import { TFacebookAccount } from '@/client/utils/api/facebook_accounts'
import { TInstagramAccount } from '@/client/utils/api/instagram_accounts'
import {
  IPostInvitationsExistsParams,
  IPostInvitationsExistsResponse,
  IPostInvitationsParams,
  IPostInvitationsResponse
} from '@/client/utils/api/invitations'
import { TikTokAccount } from '@/client/utils/api/tiktok_accounts'
import { TTwitterAccount } from '@/client/utils/api/twitter_accounts'
import * as regex from '@/client/utils/regex'
import { RoleAnalyticsName, RoleMonitoringName, RolePostName } from '@/common/types'

const accounts = namespace('accounts')
const notification = namespace('notification')

type TAccount = {
  account_id: string
  name: string
  user_name: string
  image_url: string
  is_use_monitoring: boolean
  role_analytics?: RoleAnalyticsName
  role_monitoring?: RoleMonitoringName
  role_post?: RolePostName
}

@Component({
  name: 'OperatorInvitationDialog',
  components: {
    Button,
    Dialog,
    HelpLink,
    Icon,
    Input,
    Message,
    Panel,
    OperatorRoleSns,
    SwitchRow
  }
})
export default class OperatorInvitationDialog extends Vue {
  @State('project') project!: IRootState['project']
  @accounts.State('facebook') facebook!: IAccountsState['facebook']
  @accounts.State('twitter') twitter!: IAccountsState['twitter']
  @accounts.State('instagram') instagram!: IAccountsState['instagram']
  @accounts.State('tiktok') tiktok!: IAccountsState['tiktok']
  @notification.Action('showNotification') showNotification!: any

  visible = false
  loading = false
  is_first_step = true
  email = ''
  role_admin = true
  role_analytics_setting = true
  role_comparison = true
  role_keyword = true
  role_viral = true
  facebook_accounts: TAccount[] = []
  twitter_accounts: TAccount[] = []
  instagram_accounts: TAccount[] = []
  tiktok_accounts: TAccount[] = []

  get is_email() {
    return !regex.MAIL_FORMAT.test(this.email)
  }

  get accounts() {
    // ? TikTokはモニタリング機能未対応のため、暫定的にモニタリング利用のみのグループではTikTokを表示しない
    const is_use_monitoring_only =
      this.project.is_use_monitoring && !this.project.is_use_analytics && !this.project.is_use_post

    const tiktok_accounts = is_use_monitoring_only
      ? []
      : this.tiktok_accounts.map(account => ({ account, sns_type: 'tiktok' }))

    return [
      ...this.facebook_accounts.map(account => ({ account, sns_type: 'facebook' })),
      ...this.twitter_accounts.map(account => ({ account, sns_type: 'twitter' })),
      ...this.instagram_accounts.map(account => ({ account, sns_type: 'instagram' })),
      ...tiktok_accounts
    ]
  }

  get is_use_tw_viral() {
    return this.project.is_use_analytics && this.project.is_use_tw_viral
  }

  /**
   * ダイアログの表示
   */
  open() {
    const convert = (
      account: TFacebookAccount | TTwitterAccount | TInstagramAccount | TikTokAccount
    ): TAccount => {
      let data: TAccount = {
        account_id: account.id,
        name: account.name,
        user_name: account.username,
        image_url: account.image_url,
        is_use_monitoring: account.is_use_monitoring
      }

      if (this.project.is_use_analytics) {
        data = { ...data, role_analytics: 'no_authority' }
      }
      if (this.project.is_use_monitoring) {
        data = { ...data, role_monitoring: 'no_authority' }
      }
      if (this.project.is_use_post) {
        data = { ...data, role_post: 'no_authority' }
      }

      return data
    }

    this.facebook_accounts = this.facebook.map(convert)
    this.twitter_accounts = this.twitter.map(convert)
    this.instagram_accounts = this.instagram.map(convert)
    this.tiktok_accounts = this.tiktok.map(convert)
    this.visible = true
    this.loading = false

    this.role_analytics_setting = this.project.is_use_analytics
    this.role_comparison = this.project.is_use_analytics
    this.role_keyword = this.project.is_use_analytics
    this.role_viral = this.is_use_tw_viral
  }

  /**
   * ダイアログの非表示
   */
  close() {
    this.email = ''
    this.role_admin = true
    this.role_analytics_setting = this.project.is_use_analytics
    this.role_comparison = this.project.is_use_analytics
    this.role_keyword = this.project.is_use_analytics
    this.role_viral = this.is_use_tw_viral
    this.is_first_step = true
    this.visible = false
  }

  /**
   * 画面を戻す
   */
  prevStep() {
    this.role_admin = true
    this.role_analytics_setting = this.project.is_use_analytics
    this.role_comparison = this.project.is_use_analytics
    this.role_keyword = this.project.is_use_analytics
    this.role_viral = this.is_use_tw_viral
    this.is_first_step = true
  }

  /**
   * 画面を進める
   */
  async nextStep() {
    const params: IPostInvitationsExistsParams = {
      project_id: this.project.id,
      email: this.email
    }

    this.loading = true

    const response = await API.post<IPostInvitationsExistsResponse>('invitations/exists', params)

    this.loading = false

    if (response.data.data) {
      return this.showNotification({
        title: '対象のオペレーターはすでに追加済みです。',
        type: 'error'
      })
    }

    this.is_first_step = false
  }

  /**
   * 招待メールを送信する
   */
  async sendInvitationEmail() {
    const convert = (account: TAccount) => ({
      account_id: account.account_id,
      role_post: account.role_post || 'no_authority',
      role_analytics: account.role_analytics || 'no_authority',
      role_monitoring: account.role_monitoring || 'no_authority'
    })

    const params: IPostInvitationsParams = {
      email: this.email,
      project_id: this.project.id,
      role_admin: this.role_admin,
      role_comparison: this.role_comparison,
      role_keyword: this.role_keyword,
      role_viral: this.role_viral,
      role_analytics_setting: this.role_analytics_setting,
      fb_roles: this.facebook_accounts.map(convert),
      tw_roles: this.twitter_accounts.map(convert),
      in_roles: this.instagram_accounts.map(convert),
      tt_roles: this.tiktok_accounts.map(convert)
    }

    this.loading = true

    const response = await API.post<IPostInvitationsResponse>('invitations', params)

    this.loading = false

    if (response.data.data) {
      this.showNotification({ title: 'オペレーターに招待メールを送信しました。' })

      return this.close()
    }

    if (response.data.error && response.data.error.type === 'DUPLICATE') {
      return this.showNotification({
        title: '対象のオペレーターはすでに追加済みです。',
        type: 'error'
      })
    }

    if (response.data.error && response.data.error.type === 'INVITATION_MAX_OPERATOR_OVER') {
      return this.showNotification({
        title: '登録可能数を超えているため、招待できません。',
        type: 'error'
      })
    }

    this.showNotification({
      title: 'オペレーターの招待に失敗しました。',
      message: '恐れ入りますが、時間をおいて再度お試しください。',
      type: 'error'
    })
  }

  /**
   * 権限設定を変更する
   */
  changeRole(
    sns_type: string,
    payload: {
      account_id: string
      role_post: RolePostName
      role_analytics: RoleAnalyticsName
      role_monitoring: RoleMonitoringName
    }
  ) {
    if (sns_type === 'tiktok') {
      const account = this.tiktok_accounts.find(v => v.account_id === payload.account_id)
      if (!account) return

      account.role_post = payload.role_post
      account.role_analytics = payload.role_analytics

      return
    }

    let account: TAccount | undefined = undefined

    switch (sns_type) {
      case 'facebook':
        account = this.facebook_accounts.find(v => v.account_id === payload.account_id)
        break
      case 'twitter':
        account = this.twitter_accounts.find(v => v.account_id === payload.account_id)
        break
      case 'instagram':
        account = this.instagram_accounts.find(v => v.account_id === payload.account_id)
        break
    }

    if (!account) return

    account.role_post = payload.role_post
    account.role_analytics = payload.role_analytics
    account.role_monitoring = payload.role_monitoring
  }
}
