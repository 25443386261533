
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Label'
})
export default class Label extends Vue {
  @Prop({ type: String, default: 'default' })
  type!: 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger'

  @Prop({ type: String, default: 'normal' })
  size!: 'normal' | 'small'

  @Prop({ type: Number, default: null })
  maxWidth!: number

  get label_class() {
    return ['label-root', `label-root-${this.type}`]
  }

  get container_class() {
    return `size-${this.size}`
  }

  get max_width() {
    return this.maxWidth
      ? {
          overflow: 'hidden',
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap',
          'max-width': `${this.maxWidth}px`
        }
      : {}
  }
}
