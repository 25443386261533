import { getTranslateText as t } from '@/client/utils/filters'

/**
 * ステータス状態からテキストを取得
 */
export function getPostStatus(status: string): string {
  switch (status) {
    case 'draft':
      return t('下書き')
    case 'scheduled':
      return t('予約')
    case 'published':
      return t('公開')
    case 'approval':
      return t('承認待ち')
    case 'reject':
      return t('差し戻し')
    default:
      return ''
  }
}

/**
 * メッセージを省略する
 */
export function convertTextToEllipsis(text: string): string {
  let count = 0
  let temp = ''

  for (const char of text) {
    // 全角は1文字、半角は0.5文字としてカウントする
    count += char.match(/[ -~]/) ? 0.5 : 1

    if (count > 20) {
      temp += '...'
      break
    }

    temp += char
  }

  return temp
}
