import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'

import Icon from '@/client/components-old/atoms/Icon'
import Message from '@/client/components-old/atoms/Message'
import Panel from '@/client/components-old/atoms/Panel'
import Tooltip from '@/client/components-old/atoms/Tooltip'
import DashboardMonitoringItem from '@/client/components-old/molecules/DashboardMonitoringItem'
import { IRootState } from '@/client/store/global'
import { IState as IDashboardState } from '@/client/store/modules/dashboard'

const dashboard = namespace('dashboard')

@Component({
  name: 'DashboardMonitoringUnread',
  components: {
    Icon,
    Message,
    Panel,
    Tooltip,
    DashboardMonitoringItem
  }
})
export default class DashboardMonitoringUnread extends Vue {
  @State('project') project: IRootState['project']
  @dashboard.State('api_monitoring_unread') monitorings: IDashboardState['api_monitoring_unread']
  @dashboard.State('is_scroll_monitoring_unread')
  is_scroll: IDashboardState['is_scroll_monitoring_unread']
  @dashboard.State('is_monitoring_unread_loading')
  is_loading: IDashboardState['is_monitoring_unread_loading']
  @dashboard.State('filter_project_all') filter_project_all: IDashboardState['filter_project_all']
  @dashboard.Action('scrollMonitoringUnread') scrollMonitoringUnread

  $refs: {
    scroll: HTMLDivElement
  }

  get is_empty() {
    return !this.monitorings.length
  }

  @Emit('open-monitoring')
  openMonitoring(payload: any) {
    return { ...payload, tab: 'preview' }
  }

  @Watch('is_scroll')
  initialize() {
    if (this.is_scroll && this.$refs.scroll) {
      this.$refs.scroll.scrollTop = 0
    }
  }

  /**
   * もっと読み込む
   */
  async loadMore(event: any) {
    const SCALING_MEASURES = 5

    const is_bottom =
      event.target.scrollHeight <=
      event.target.scrollTop + event.target.clientHeight + SCALING_MEASURES

    if (this.is_loading || !this.is_scroll || !is_bottom) return

    await this.scrollMonitoringUnread()
  }
}
