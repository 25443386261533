import device from 'current-device'
import { VueConstructor } from 'vue'

export default {
  install: (Vue: VueConstructor) => {
    Vue.mixin({
      computed: {
        $device() {
          return device.type
        }
      }
    })
  }
}
